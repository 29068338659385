import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { updateYSelected } from 'src/workspaces/redux/reducers/Workspace';
import { HierarchicalSelect } from 'src/workspaces/components/HierarchicalSelect';
import { useQuery } from 'react-query';
import apiWorkspace from 'src/workspaces/service/api';
import ms from 'ms';

import { Container } from './styles';
import { SelectedSerie, SerieOptions, YsInfo } from './types';

export const DependentVariable: React.FC = () => {
  const [levelsQtty, setLevelsQtty] = useState<number>();

  const {
    workspace: { releaseSelected, ySelected, id: workspaceId },
    sideBar: { isExpanded },
  } = useSelector((state: RootState) => state);

  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  const { data: ysInfoData } = useQuery(
    ['workspace', 'ys info', workspaceId, releaseSelected?.id],
    async () => {
      const { data } = await apiWorkspace.get<YsInfo>(
        `workspaces/${workspaceId}/releases/${releaseSelected?.id}/ys`,
      );

      return data;
    },
    {
      enabled: !!workspaceId && !!releaseSelected?.id,
      staleTime: ms('1 day'),
    },
  );

  useEffect(() => {
    if (!ySelected && ysInfoData) {
      dispatch(updateYSelected(ysInfoData.ys[0]));
    }
  }, [ySelected, ysInfoData, dispatch]);

  const serieOptions: SerieOptions[] = useMemo(() => {
    const options: SerieOptions[] = [];

    if (ysInfoData) {
      ysInfoData.ys.forEach((y) => {
        if (y.source === 'projects') {
          let currentLevelOptions = options;

          let level = 1;

          if (ysInfoData?.hierarchies.length) {
            const yType =
              y.type.toLowerCase() === 'marketsize'
                ? 'Market Size'
                : y.type.toLowerCase() === 'sellout'
                ? 'Sell Out'
                : y.type;

            const yTypeLabel =
              y.type_label.toLowerCase() === 'marketsize'
                ? 'Market Size'
                : y.type_label.toLowerCase() === 'sellout'
                ? 'Sell Out'
                : y.type_label;

            const existsOption = currentLevelOptions.find(
              (option) => option.value === yType,
            );

            if (existsOption) {
              currentLevelOptions = existsOption.children ?? [];
            } else {
              const newOption = {
                label: yTypeLabel,
                value: yType,
                level: 1,
                children: [],
                canSelect: false,
              };

              currentLevelOptions.push(newOption);
              currentLevelOptions = newOption.children;
            }

            level++;
          }

          y.hierarchy.forEach((filter, index) => {
            const existsOption = currentLevelOptions.find(
              (option) => option.value === filter.value,
            );

            if (existsOption) {
              currentLevelOptions = existsOption.children ?? [];
            } else {
              const newOption = {
                label: filter.value,
                value: filter.value,
                level: index + (ysInfoData?.hierarchies.length ? 2 : 1),
                children: [],
                canSelect: false,
              };

              currentLevelOptions.push(newOption);
              currentLevelOptions = newOption.children;
            }

            level++;
          });

          currentLevelOptions.push({
            label: y.y_label,
            value: y.y_label,
            level,
            children: [],
            canSelect: true,
          });

          if (!levelsQtty) {
            setLevelsQtty(level);
          }
        }
      });
    }

    return options;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ysInfoData]);

  const optionSelected = (selected: SelectedSerie) => {
    if (selected.label !== ySelected?.y_label) {
      const y = ysInfoData?.ys.find(
        ({ y_label }) => y_label === selected.label,
      );

      if (y) {
        dispatch(updateYSelected(y));
      }
    }
  };

  const getSelectedValue = useCallback(
    (yLabel: string): string[] => {
      const y = ysInfoData?.ys.find(({ y_label }) => y_label === yLabel);

      if (y) {
        const selectedValue = [
          ...y.hierarchy.map(({ value }) => value),
          yLabel,
        ];

        if (!y.hierarchy.length) {
          return selectedValue;
        }

        const yType =
          y.type.toLowerCase() === 'marketsize'
            ? 'Market Size'
            : y.type.toLowerCase() === 'sellout'
            ? 'Sell Out'
            : y.type;

        return [yType, ...selectedValue];
      }

      return [yLabel];
    },
    [ysInfoData],
  );

  if (isExpanded) {
    return (
      <Container
        style={{ width: '16.938rem' }}
        data-testid="dependent-variable"
      >
        <HierarchicalSelect
          label={translate('serie')}
          isRadioButton
          placeholder={!serieOptions.length ? `${translate('loading')}...` : ''}
          options={serieOptions}
          selected={
            ySelected
              ? [
                  {
                    label: ySelected.y_label,
                    value: getSelectedValue(ySelected.y_label),
                  },
                ]
              : []
          }
          setSelected={() => null}
          optionRemoved={() => null}
          optionAdded={optionSelected}
          loadingOptions={!serieOptions.length}
          data-testid={
            !serieOptions.length
              ? 'loading-select-options'
              : 'hierarchical-select-serie'
          }
          maxNumberOptionsSelected={2}
        />
      </Container>
    );
  }

  return null;
};
