/* eslint-disable prettier/prettier */
import React, { ChangeEvent, MouseEvent, useEffect, useState } from 'react';

import uploadProjectSvg from 'src/assets/uploadProject.svg';
import xlsxFile from 'src/assets/xlsx-file.svg';
import { Button } from 'src/components/Button';
import { Question, UploadSimple, X } from 'phosphor-react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Input } from 'src/components/Input';
import { useTranslation } from 'react-i18next';
import { SerieData } from 'src/models/hooks/useQuerySeries';
import apiSeries from 'src/models/service/apiSeries';
import { AxiosError } from 'axios';
import { createPortal } from 'react-dom';
import { FailedModal } from 'src/components/Modal/Failed';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';

import {
  ButtonSave,
  ContentUpload,
  InputFileMessageError,
  InputUploadFileContainer,
  Overlay,
  SeriesUploaded,
  UploadFileContainer,
} from './styles';
import { OverwriteModal } from '../OverwriteModal';
import { RequirementsModal } from '../RequirementsModal';

const ALLOWED_FORMAT = '.xlsx';

type Props = {
  addSeries: (seriesData: SerieData[]) => Promise<void>;
};

type FormProps = {
  tag: string;
  file: File;
};

type ResponseUpload = {
  [key: string]: {
    series_id: string;
    metadata_id: number;
    update: boolean;
  };
};

type ResponseError = {
  detail?: {
    description: {
      'en-us'?: string;
      'pt-br'?: string;
    };
  };
};

type MappedErrors = {
  message: string;
  series: string[];
  isOverwrite: boolean;
};

const schemaValidation = yup.object().shape({
  tag: yup
    .string()
    .trim()
    .required('requiredField')
    .min(3, 'projectErrorMinCharacters')
    .max(50, 'projectErrorMaxCharacters')
    .matches(new RegExp('^[A-Za-z0-9_ ]*$'), 'mySeriesNoEspecialCharacters'),
  file: yup
    .mixed<File>()
    .required('requiredField')
    .test(
      'fileFormat',
      'mySeriesInvalidFormatFile',
      (value) => !!value?.name.endsWith(ALLOWED_FORMAT),
    ),
});

export const UploadFile: React.FC<Props> = ({ addSeries }) => {
  const [showUpload, setShowUpload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mappedErrors, setMappedErrors] = useState<MappedErrors | null>(null);

  const [showRequirements, setShowRequirements] = useState(false);

  const { user } = useSelector((state: RootState) => state.auth);

  const drop = React.useRef<HTMLDivElement>(null);

  const {
    register,
    handleSubmit,
    formState,
    setValue,
    watch,
    trigger,
    reset,
    getValues,
  } = useForm<FormProps>({
    resolver: yupResolver(schemaValidation),
  });

  const { t } = useTranslation();

  const file = watch('file');

  useEffect(() => {
    const preventDefaultStopPropagation = (e: Event) => {
      e.preventDefault();
      e.stopPropagation();
    };
    const handleDragEnter = (e: DragEvent) => {
      preventDefaultStopPropagation(e);
    };
    const handleDragLeave = (e: DragEvent) => {
      preventDefaultStopPropagation(e);
    };
    const handleDragOver = (e: DragEvent) => {
      preventDefaultStopPropagation(e);
    };
    const handleDrop = (e: DragEvent) => {
      preventDefaultStopPropagation(e);
      const { files: filesAux } = e.dataTransfer as DataTransfer;
      if (filesAux && filesAux.length) {
        setValue('file', filesAux[0]);
        trigger('file');
      }
    };
    const dropDiv = drop.current;
    if (dropDiv) {
      dropDiv.addEventListener('dragover', handleDragOver);
      dropDiv.addEventListener('dragenter', handleDragEnter);
      dropDiv.addEventListener('dragleave', handleDragLeave);
      dropDiv.addEventListener('drop', handleDrop);
      return () => {
        dropDiv.removeEventListener('dragover', handleDragOver);
        dropDiv.removeEventListener('dragenter', handleDragEnter);
        dropDiv.removeEventListener('dragleave', handleDragLeave);
        dropDiv.removeEventListener('drop', handleDrop);
      };
    }
  }, [setValue, trigger]);

  function handleUploadFile(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files?.length) {
      setValue('file', e.target.files[0]);
      trigger('file');
    }
  }

  function handleShowUpload() {
    setShowUpload(true);
  }

  function handleCloseUpload(skipLoading?: boolean) {
    if (!isLoading || skipLoading) {
      setShowUpload(false);
      reset();
    }
  }

  function handleError(dataError: ResponseError | undefined) {
    if (
      dataError?.detail?.description?.['en-us']?.includes(
        'series already available',
      )
    ) {
      setMappedErrors({
        isOverwrite: true,
        message: dataError?.detail?.description?.['en-us'].split(':')[0].trim(),
        series: dataError?.detail?.description?.['en-us']
          .split(':')[1]
          .trim()
          .split(','),
      });

      return;
    }

    setMappedErrors({
      isOverwrite: false,
      message:
        dataError?.detail?.description?.[user.language] ??
        t('mySeriesUploadErrorDefault'),
      series: [],
    });

    setIsLoading(false);
  }

  async function save(data: FormProps, overwrite?: boolean) {
    setIsLoading(true);

    const requestPayload = new FormData();
    requestPayload.append('file', data.file);
    requestPayload.append('tag', data.tag);
    requestPayload.append('frequency', 'monthly');

    if (overwrite) {
      requestPayload.append('overwrite', 'true');
    }

    try {
      const { data: serieData } = await apiSeries.post<ResponseUpload>(
        '/series/upload',
        requestPayload,
      );

      const newSeries: SerieData[] = [];

      Object.keys(serieData).forEach((key) => {
        const serie: SerieData = {
          id: serieData[key].series_id,
          frequency: 'monthly',
          name: key,
          tag: data.tag,
          status: 'created',
          created: new Date().toISOString(),
          last_updated: new Date().toISOString(),
        };

        newSeries.push(serie);
      });

      await addSeries(newSeries);

      setIsLoading(false);
      reset();
      handleCloseUpload(true);
    } catch (e) {
      const error = e as AxiosError<ResponseError>;
      handleError(error.response?.data);
    }
  }

  function handleOverwrite() {
    setMappedErrors(null);
    save(getValues(), true);
  }

  function handleRemoveFile() {
    reset({
      file: undefined,
    });
  }

  function closeModalOverwrite() {
    setMappedErrors(null);

    setIsLoading(false);
    reset();
    handleCloseUpload(true);
  }

  function closeModalFailed() {
    setMappedErrors(null);
  }

  function handleShowRequirements(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    setShowRequirements(true);
  }

  return (
    <UploadFileContainer>
      <Button
        buttonType="primary"
        icon={<UploadSimple />}
        data-testid="button-upload-series"
        data-cy="button-upload-series"
        onClick={handleShowUpload}
      >
        {t('mySeriesButtonUpload')}
      </Button>

      {showUpload && (
        <>
          <Overlay onClick={() => handleCloseUpload(false)} />
          <InputUploadFileContainer>
            <ContentUpload
              ref={drop}
              data-testid="file-drop"
              data-cy="file-drop"
              isError={!!formState.errors.file?.message}
            >
              <input
                type="file"
                accept=".xlsx"
                onClick={(e) => {
                  e.currentTarget.value = '';
                }}
                onChange={handleUploadFile}
                data-cy="my-series-input-file"
                data-testid="my-series-input-file"
              />
              {!file && <img src={uploadProjectSvg} alt="" />}

              <h4>{t('mySeriesUploadFileUploadYourFile')}</h4>
              <p>{t('mySeriesUploadFileDragItHere')}</p>

              {file && (
                <SeriesUploaded>
                  <div>
                    <img src={xlsxFile} alt="" />
                    <span>{file.name}</span>
                  </div>
                  <button
                    type="button"
                    onClick={handleRemoveFile}
                    data-testid="my-series-button-remove-file"
                    data-cy="my-series-button-remove-file"
                    disabled={isLoading}
                  >
                    <X weight="bold" />
                  </button>
                </SeriesUploaded>
              )}

              <InputFileMessageError show={!!formState.errors.file?.message}>
                {t(formState.errors.file?.message ?? '')}
              </InputFileMessageError>

              {!file && (
                <Button
                  buttonType="naked"
                  icon={<Question />}
                  onClick={handleShowRequirements}
                >
                  {t('mySeriesUploadFileViewRequirements')}
                </Button>
              )}
            </ContentUpload>
            <Input
              label="TAG"
              placeholder={t('mySeriesUploadFileEnterTheTag')}
              error={
                formState.errors.tag?.message &&
                t(formState.errors.tag?.message)
              }
              testid="my-series-input-tag"
              {...register('tag')}
            />
            {file && (
              <ButtonSave
                buttonType="primary"
                onClick={handleSubmit((data) => save(data))}
                loading={isLoading}
                disabled={isLoading}
                data-testid="my-series-button-save"
                data-cy="my-series-button-save"
              >
                {t('save')}
              </ButtonSave>
            )}
          </InputUploadFileContainer>
        </>
      )}

      {mappedErrors
        ? mappedErrors.isOverwrite
          ? createPortal(
              // eslint-disable-next-line react/jsx-indent
              <OverwriteModal
                onCancel={closeModalOverwrite}
                onContinue={handleOverwrite}
                series={mappedErrors.series}
              />,
              document.body,
            )
          : createPortal(
              // eslint-disable-next-line react/jsx-indent
              <FailedModal
                visible
                setVisible={closeModalFailed}
                errorInfo={{
                  title: t('mySeriesUploadErrorTitle'),
                  description: mappedErrors.message,
                }}
              />,
              document.body,
            )
        : null}

      {showRequirements && (
        <RequirementsModal setVisible={setShowRequirements} />
      )}
    </UploadFileContainer>
  );
};
