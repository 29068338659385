export const AISelectionExplanatoryVariablesENTranslate = {
  AISelectionExplanatoryVariablesTitle: 'Projection of Explanatory Variables',
  AISelectionExplanatoryVariablesVariable: 'Variable',
  AISelectionExplanatoryNoVariables: 'No explanatory variable to be displayed.',
  AISelectionExplanatoryVariablesLevel: 'Level',
  AISelectionExplanatoryVariablesVariation: 'Variation',
  AISelectionExplanatoryVariablesFrequency: 'Frequency',
  AISelectionExplanatoryVariablesTransformation: 'Transformation',
  AISelectionExplanatoryVariablesOriginal: 'Original',
  AISelectionExplanatoryVariablesMonthly: 'Monthly',
  AISelectionExplanatoryVariablesQuarterly: 'Quarterly',
  AISelectionExplanatoryVariablesAnnual: 'Annual',
  AISelectionExplanatoryVariablesVariationOriginal:
    'Variation (compared to the previous period)',
  AISelectionExplanatoryVariablesVariationMonthlyQuarterly:
    'Variation (compared to the same period in the previous year)',
  AISelectionExplanatoryVariablesVariationAnnual:
    'Variation (compared to the previous year)',
  AISelectionExplanatoryVariablesNotEnoughObservationsVariation:
    'Not enough observations to calculate variation.',
};
