import React, { CSSProperties } from 'react';

import { Container } from './styles';

export type CheckBoxProps = {
  label?: string;
  style?: CSSProperties;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const CheckBox: React.FC<CheckBoxProps> = ({
  label,
  style,
  className,
  disabled = false,
  ...rest
}) => (
  <Container style={style} className={className} disabled={disabled}>
    {label && <p>{label}</p>}
    <input
      type="checkbox"
      data-cy={`checkbox-${label?.replaceAll(' ', '-').toLocaleLowerCase()}`}
      data-testid={`checkbox-${label
        ?.replaceAll(' ', '-')
        .toLocaleLowerCase()}`}
      disabled={disabled}
      {...rest}
    />
    <span />
  </Container>
);
