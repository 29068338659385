const suffixes = new Map([
  ['one', 'st'],
  ['two', 'nd'],
  ['few', 'rd'],
  ['other', 'th'],
]);

export function formatOrdinals(value: number, language: string): string {
  if (language === 'pt-br') {
    return `${value}º`;
  }

  const pr = new Intl.PluralRules('en-US', { type: 'ordinal' });

  const rule = pr.select(value);
  const suffix = suffixes.get(rule);

  return `${value}${suffix}`;
}
