import React from 'react';

import { format } from 'date-fns';

import { ModelUpdateOptionLabelProps } from './types';

export const ModelUpdateOptionLabel: React.FC<ModelUpdateOptionLabelProps> = ({
  label,
  dateFormat,
}) => (
  <div>
    {label
      .toString()
      .split('\n')
      .map((line, index) => (
        <span
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          style={{ display: 'block' }}
          className={index === 0 ? 'model-update-name' : 'model-update-date'}
        >
          {index === 0 ? line : format(new Date(line), `${dateFormat}, HH:mm`)}
        </span>
      ))}
  </div>
);
