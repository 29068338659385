export const FeatureStoreVariablesENUSTranslate = {
  featureStoreVariablesTitle: 'Feature Store Variables',
  featureStoreVariablesDescription:
    'See which explanatory variables were used from the Feature Store for your chosen dependent variable:',
  featureStoreVariablesSearchVariables: 'Search variable',
  featureStoreVariablesRegion: 'Region',
  featureStoreVariablesPrimT: 'Prim. T.',
  featureStoreVariablesSecT: 'Sec. T.',
  featureStoreVariablesCouldNotFind:
    'Could not find any variable containing "xxx".',
  featureStoreVariablesUnableLoad:
    'Unable to load the variables from the Feature Store, please try again',
  featureStoreVariablesSomeVariables:
    'Some variables from the Feature Store were unable to load, please try again.',
  featureStoreVariablesNotFind: `We couldn't find any variable containing `,
  featureStoreVariablesIndicatorNotAvailable: 'Indicator not available.',
  featureStoreVariablesNoVariables:
    'No Feature Store variables in your project.',
};
