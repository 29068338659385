import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Card } from 'src/components/Card';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Select } from 'src/components/Select';
import api from 'src/models/service/api';
import { RootState } from 'src/redux/store';
import { getChartColor } from 'src/utils/colors/getChartColor';
import { queryClient } from 'src/service/queryClient';
import { HCharts, HChartsOptions, HChartsSeries } from 'src/components/HCharts';
import { formatCompactNotation } from 'src/utils/numbers/formatCompactNotation';
import { ToggleSwitch } from 'src/components/ToggleSwitch';
import { frequencyLatestData } from 'src/utils/charts/getLatestData';
import light from 'src/styles/themes/light';

import {
  ContentLatestData,
  ForecastDispersionContainerWarning,
  Options,
  ForecastDispersionContainer,
} from './styles';

interface ForecastDispersionProps {
  model: 'arima' | 'forecast-combination';
  hasSelect: boolean;
  quantityModels: number;
}

interface AggregateBestModel {
  data_tidy: string[];
  y: number[];
  model_number: string;
}

interface ResponseAggregate {
  limit: number;
  skip: number;
  total: number;
  data: AggregateBestModel[];
}

export const ForecastDispersion: React.FC<ForecastDispersionProps> = ({
  model,
  hasSelect,
  quantityModels,
}) => {
  const [selectQuantityAggregate, setSelectQuantityAggregate] =
    useState<number>(1);

  const [dispersions, setDispersions] = useState<AggregateBestModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorDispersion, setIsErrorDispersion] = useState(false);

  const [isLatestDataActive, setIsLatestDataActive] = useState<boolean>(true);
  const [isLatestDataDisabled, setIsLatestDataDisabled] = useState(false);

  const { project } = useSelector((state: RootState) => state);

  useEffect(() => {
    if (hasSelect && quantityModels) {
      setSelectQuantityAggregate(quantityModels >= 10 ? 10 : quantityModels);
    }
  }, [hasSelect, quantityModels]);

  const { t: translate } = useTranslation();

  const loadDispersion = useCallback(
    async (index: number, limit: number) => {
      let dataAux: AggregateBestModel[];

      const keys = [
        `${model} dispersion`,
        project.id,
        project.selectedY?.id,
        index,
      ];

      const contentQuery = queryClient.getQueryData<AggregateBestModel[]>(keys);

      if (contentQuery) {
        dataAux = contentQuery;
      } else {
        dataAux = await queryClient.fetchQuery<AggregateBestModel[]>(
          keys,
          async () => {
            const { data } = await api.get<ResponseAggregate>(
              `/projects/${project.id}/${project.selectedY?.id}/models/${model}/aggregate_projections?skip=${index}&limit=${limit}`,
            );

            return data.data;
          },
          {
            staleTime: 1000 * 60 * 20,
          },
        );
      }

      return dataAux;
    },
    [model, project.id, project.selectedY?.id],
  );

  useEffect(() => {
    let canContinue = true;
    async function load() {
      setIsLoading(true);
      const quantity = hasSelect ? selectQuantityAggregate : quantityModels;

      const limit = model === 'forecast-combination' ? 14 : 10;

      for (let i = 0; i < quantity; i += limit) {
        try {
          const data = await loadDispersion(i, limit);

          if (canContinue) {
            setDispersions((state) => {
              if (state.length === 0) {
                return [...data];
              }
              if (data.length > 1) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const [_, ...rest] = data;
                return [...state, ...rest];
              }

              return state;
            });
          }
        } catch {
          // foi colocado dentro do loop pq caso falhe por exemplo 50 a 60 porem
          // as outras funciona
          // para ele carregar as outras
          setIsErrorDispersion(true);
        }
      }
      setIsLoading(false);
    }

    if (project.id && project.selectedY?.id && quantityModels) {
      load();
    }

    return () => {
      canContinue = false;
      setDispersions([]);
    };
  }, [
    hasSelect,
    loadDispersion,
    model,
    project.id,
    project.selectedY?.id,
    quantityModels,
    selectQuantityAggregate,
  ]);

  useEffect(() => {
    if (isErrorDispersion) {
      setIsLatestDataDisabled(true);
      setIsLatestDataActive(false);
      return;
    }

    if (!dispersions.length || !project.selectedY) {
      return;
    }

    if (!project.selectedY?.info?.frequency) {
      setIsLatestDataDisabled(true);
      setIsLatestDataActive(false);
      return;
    }

    const historicalLength = dispersions[0].data_tidy.length;

    const modelsLength =
      dispersions.length > 1 ? dispersions[1].data_tidy.length : 0;

    const quantity = historicalLength + modelsLength;

    if (quantity <= frequencyLatestData[project.selectedY.info.frequency]) {
      setIsLatestDataDisabled(true);
      setIsLatestDataActive(false);
      return;
    }

    setIsLatestDataDisabled(false);
  }, [dispersions, isErrorDispersion, project]);

  function generateOptions() {
    if (!quantityModels) {
      return [];
    }

    const values = Array.from(
      {
        length: quantityModels / 10,
      },
      (_, number) => (number + 1) * 10,
    );

    if (!values.includes(quantityModels)) {
      values.push(quantityModels);
    }

    return values.map((value) => ({
      label: value,
      value,
    }));
  }

  function handleSelect(value: number) {
    if (value !== selectQuantityAggregate) {
      setDispersions([]);
      setSelectQuantityAggregate(value);
    }
  }

  function handleActiveLatestData(value: boolean) {
    setIsLatestDataActive(value);
  }

  const getLatestData = (
    data: AggregateBestModel[] | undefined,
    frequency:
      | 'daily'
      | 'weekly'
      | 'fortnightly'
      | 'monthly'
      | 'bimonthly'
      | 'quarterly'
      | 'half-year'
      | 'annual',
  ): AggregateBestModel[] => {
    if (data) {
      const quantity = frequencyLatestData[frequency];

      if (!data.length) return data;

      const historicalLength = data[0].data_tidy.length;

      const modelsLength = data.length > 1 ? data[1].data_tidy.length : 0;

      if (quantity > historicalLength && quantity > modelsLength) {
        return data;
      }

      let lastModelsIndex = 0;
      if (modelsLength >= quantity / 3) {
        lastModelsIndex = quantity / 3;
      } else if (data.length > 1 && modelsLength !== 0) {
        lastModelsIndex = modelsLength - 1;
      }

      const historicalDate = data[0].data_tidy.slice(
        historicalLength - quantity + lastModelsIndex,
        historicalLength,
      );

      const historicalValue = data[0].y.slice(
        historicalLength - quantity + lastModelsIndex,
        historicalLength,
      );

      return [
        {
          data_tidy: historicalDate,
          model_number: data[0].model_number,
          y: historicalValue,
        },
      ]
        .concat(
          data
            .filter((_, index) => index !== 0)
            .map((modelData) => ({
              data_tidy: modelData.data_tidy.slice(0, quantity / 3 + 1),
              model_number: modelData.model_number,
              y: modelData.y.slice(0, quantity / 3 + 1),
            })),
        )
        .flat();
    }

    return [];
  };

  const series: HChartsSeries[] = useMemo(() => {
    const dispersionsAdjusted =
      project.selectedY?.info?.frequency && isLatestDataActive
        ? getLatestData(dispersions, project.selectedY?.info?.frequency)
        : dispersions;

    return dispersionsAdjusted
      ? dispersionsAdjusted.map((bestModel, index) => {
          const name =
            index === 0
              ? translate('Historical')
              : translate('model') + bestModel.model_number.split('Model')[1];

          return {
            type: 'line',
            name,
            showInLegend: dispersionsAdjusted.length === 1 ? true : index !== 0,
            color: getChartColor(index),
            data: bestModel.y.map((y, yIndex) => ({
              x: new Date(`${bestModel.data_tidy[yIndex]}T00:00`).getTime(),
              y,
              custom: {
                value: formatCompactNotation(y),
              },
            })),
            marker: {
              symbol: 'circle',
            },
          };
        })
      : [];
  }, [
    dispersions,
    isLatestDataActive,
    project.selectedY?.info?.frequency,
    translate,
  ]);

  const options: HChartsOptions = useMemo(
    () => ({
      chart: {
        height: 300,
        animation: false,
        events: {
          render() {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const chart = this;

            const legend = chart.legend;

            //@ts-expect-error:ignora
            if (legend?.nav) {
              //@ts-expect-error:ignora
              const legendWidth = legend.legendWidth;

              //@ts-expect-error:ignora
              const nav = legend.nav;
              const y = nav.translateY;
              const navWidth = nav.getBBox().width;

              const x = legendWidth / 2 + nav.translateX - navWidth / 2 + 3;

              nav.attr({
                style: `transform: translate(${x}px, ${y}px)`,
              });
            }
          },
        },
      },
      tooltip: {
        pointFormat:
          `<tr><td><b>${translate('date')}:</b> </td>` +
          `<td style="text-align: right">{point.x: ${
            project.selectedY?.info?.frequency === 'annual' ? '%Y' : ' %d/%m/%Y'
          }}</td></tr>` +
          `<tr><td><b>${translate('value')}:</b> </td>` +
          '<td style="text-align: right">{point.custom.value}</td></tr>',
      },
      legend: {
        align: 'right',
        layout: 'vertical',
        verticalAlign: 'middle',
        navigation: {
          enabled: true,
          inactiveColor: light.colors.gray3,
          arrowSize: 12,
          activeColor: light.colors.gray4,
          style: {
            textAlign: 'center',
            paddingLeft: '12px',
            padding: '12px',
            color: light.colors.gray6,
          },
        },
      },
    }),
    [project.selectedY?.info?.frequency, translate],
  );

  const totalError = !!isErrorDispersion && dispersions.length === 0;
  const parcialError = isErrorDispersion && dispersions.length !== 0;

  return (
    <ForecastDispersionContainer className="containerLinear">
      <Card
        textCard={translate('forecastDispersionComponentTitle')}
        textDescription={translate('forecastDispersionComponentDescription')}
      />
      <Options>
        {hasSelect && (
          <Select
            label={translate('forecastDispersionComponentShowNBestModels')}
            options={generateOptions()}
            onChange={(select: any) => handleSelect(select.value)}
            value={{
              label: selectQuantityAggregate,
              value: selectQuantityAggregate,
            }}
            isLoading={!quantityModels || isLoading}
            isDisabled={
              selectQuantityAggregate === 0 ||
              !!totalError ||
              project.projectError ||
              !project.model?.model_list ||
              !dispersions.length
            }
            style={{ width: '320px' }}
          />
        )}
        <ContentLatestData>
          <ToggleSwitch
            label={translate('latestData')}
            checked={isLatestDataActive}
            onChange={(e) => handleActiveLatestData(e.target.checked)}
            disabled={
              isLatestDataDisabled ||
              !!totalError ||
              project.projectError ||
              !project.model?.model_list ||
              !dispersions.length
            }
            data-cy="explanatory-variables-latest-data"
          />
        </ContentLatestData>
      </Options>
      {project.projectError ? (
        <ContainerMaintenance
          content="chart"
          data-testid="container-forecast-dispersion-error"
        />
      ) : !project.model?.model_list ? (
        // eslint-disable-next-line react/jsx-indent
        <ContainerSkeleton data-testid="container-forecast-dispersion-loading" />
      ) : totalError ? (
        <ContainerMaintenance content="chart" />
      ) : !dispersions.length ? (
        // eslint-disable-next-line react/jsx-indent
        <ContainerSkeleton data-testid="forecast-dispersion-loading" />
      ) : (
        <>
          <HCharts
            series={series}
            options={options}
            frequency={project.selectedY?.info?.frequency}
            dataCy="forecast-dispersion-chart"
            resizeWidthWithSidebar
          />
        </>
      )}

      {parcialError && (
        <ForecastDispersionContainerWarning
          visible
          hasIcon
          text={translate('forecastDispersionComponentErrorLoadPartial')}
        />
      )}
    </ForecastDispersionContainer>
  );
};
