const correlationMatrixPTBR = {
  correlation_matrix: 'Matriz de Correlação',
  data_view: 'Visualizar Dados',
  correlationMatrixNoExplanatoryVariableSelected:
    'Nenhuma variável explicativa selecionada.',
  warningMessageLimit:
    'A matriz de correlação foi limitada para apresentar as 100 variáveis com maior correlação em relação à variável dependente escolhida.',
};

export default correlationMatrixPTBR;
