import React from 'react';

import { Loading } from '../Loading';
import { Container } from './styles';

export type ContainerSkeletonProps = {
  boxShadow?: boolean;
  withLoading?: boolean;
} & React.HTMLAttributes<HTMLInputElement>;

export const ContainerSkeleton: React.FC<ContainerSkeletonProps> = ({
  boxShadow = false,
  withLoading = true,
  ...rest
}) => (
  <Container hasLoading={withLoading} boxShadow={boxShadow} {...rest}>
    {withLoading && <Loading style={{ width: '70px', height: '70px' }} />}
  </Container>
);
