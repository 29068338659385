const confusionMatrixPTBRTranslations = {
  confusionMatrixTitle: 'Matriz de confusão',
  confusionMatrixDescription:
    'Avalie a performance do seu modelo por meio de sua matriz de confusão.',
  truePositive: 'Verdadeiro Positivo',
  trueNegative: 'Verdadeiro Negativo',
  falsePositive: 'Falso Positivo',
  falseNegative: 'Falso Negativo',
  confusionMatrixActual: 'Real',
  confusionMatrixPredicted: 'Previsto',
  confusionMatrixCount: 'Valores exibidos na matriz',
  confusionMatrixPositiveClass: 'Classe positiva',
  confusionMatrixPercentage: 'Percentagem',
  confusionMatrixMulticlassDisabled:
    'Visualização disponível apenas para casos binários.',
};

export default confusionMatrixPTBRTranslations;
