const Step3ClaaSENUSTranslations = {
  claasStep3Description: 'Give it a go, or fine tune modeling details.',
  claasStep3InformativeTitle: 'We are ready to go',
  claasStep3InformativeDescription:
    'We have all the information needed to generate great predictions. In future releases, you will be able to fine-tune the modeling process if you need more control of the final results.',
  claasModelingConclusionTitle: 'Modeling started',
  claasStep3ConclusionDescription: `You'll receive an email informing when the job is finished.`,
  claasConclusionSettingsButton: 'Begin',

  claasWarningTitle: 'Data processing...',
  claasRemovedColsTypeWarning:
    'Variables will be removed due to excess of unique values:',
  claasRemovedColsMissingWarning:
    'Variables will be removed due to misleading missing values:',
  claasRemovedRowsWarning:
    '"X" lines will be removed due to misleading missing values.',
  claasRemovedClassesWarning:
    'The following values appear less than the minimum amount required (6 times) on the target column and will be removed:',
};

export default Step3ClaaSENUSTranslations;
