import React from 'react';

import { useTranslation } from 'react-i18next';
import { Modal } from 'src/components/Modal';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import { Button } from 'src/components/Button';
import { Status } from 'src/components/Status';
import ErrorImg from 'src/assets/error.svg';

import { Container, YsContainer } from './styles';
import { UnsavedYsModalProps } from './types';

export const UnsavedYsModal: React.FC<UnsavedYsModalProps> = ({
  ys,
  setVisible,
}) => {
  const { t: translate } = useTranslation();

  return (
    <Modal visible style={{ width: '30rem' }}>
      <Container>
        <Status
          type="other"
          title={translate('workspaceOverviewResultsErrorToSaveTitle')}
          description={translate(
            'workspaceOverviewResultsErrorToSaveDescription',
          )}
          img={ErrorImg}
          isModal
          dataCy="error-to-save-modal"
        />

        <YsContainer>
          {ys.map((label) => (
            <p
              key={label}
              data-testid={`y-error-${label
                .replaceAll(' ', '-')
                .toLowerCase()}`}
            >
              {label}
            </p>
          ))}
        </YsContainer>
      </Container>

      <ModalFooter>
        <Button
          buttonType="primary"
          onClick={() => setVisible(false)}
          data-testid="save-error-ok-button"
        >
          Ok
        </Button>
      </ModalFooter>
    </Modal>
  );
};
