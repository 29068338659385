import React, { useEffect, useState } from 'react';

import { format } from 'date-fns';
import { useQuery } from 'react-query';
import { Card } from 'src/components/Card';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Head } from 'src/components/Head';
import { Pagination } from 'src/components/Pagination';
import { Table, Tbody, Td, Th, Thead, Tr } from 'src/components/Table';
import { apiAdmin } from 'src/service/apiAdmin';

import { Container } from './styles';

interface UserAccess {
  email: string;
  last_login: Date;
  permissions: string;
}

interface RequestUsersAccess {
  limit: number;
  users: UserAccess[];
  total: number;
}

const QUANTITY_ITEMS_PAGE = 8;

const Admin: React.FC = () => {
  const [userInfo, setUserInfo] = useState<RequestUsersAccess>();
  const [page, setPage] = useState(1);
  const { data, isLoading, isFetching, isError } = useQuery(
    ['SearchAdminPage', page],
    async () => {
      const response = await apiAdmin.get<RequestUsersAccess>(
        `/users?page=${page - 1}&limit=${QUANTITY_ITEMS_PAGE}`,
      );

      return response.data;
    },
  );

  useEffect(() => {
    if (data) {
      setUserInfo(data);
    }
  }, [data]);
  return (
    <Container>
      <Head title="4CastHub Admin" />
      <div className="containerLinear">
        <Card
          textCard="4CastHub Admin"
          textDescription="See all users registred on 4CastHub and know more information about them."
          style={{ marginBottom: 0 }}
        />
      </div>
      <div className="containerLinear">
        {/* <DivSearchBox> */}
        <Card textCard="Users" textDescription="List of all 4CastHub users." />
        {/* <Input
            icon={<MagnifyingGlass size={20} />}
            testid="searchProject"
            placeholder="Search user"
          /> */}
        {/* </DivSearchBox> */}
        {isError ? (
          <ContainerMaintenance
            content="projects"
            text="Unable to load user info"
          />
        ) : isLoading || isFetching || !userInfo ? (
          <>
            <Table
              style={{ marginTop: 8, marginBottom: 32 }}
              data-testid="loading-table-projects"
            >
              <Thead>
                <Tr>
                  <Th width="30%">Name</Th>
                  <Th width="30%">Dependent Variable</Th>
                  <Th>Last Updated</Th>
                  <Th />
                  <Th />
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {Array.from({ length: 8 }, (_, number) => number).map(
                  (number) => (
                    <Tr key={`table-loading-${number}`} height="72px">
                      <Td>
                        <ContainerSkeleton
                          withLoading={false}
                          style={{
                            height: '16px',
                            width: '60%',
                          }}
                        />
                      </Td>
                      <Td>
                        <ContainerSkeleton
                          withLoading={false}
                          style={{
                            height: '16px',
                            width: '60%',
                          }}
                        />
                      </Td>
                      <Td>
                        <ContainerSkeleton
                          withLoading={false}
                          style={{
                            height: '16px',
                            width: '130px',
                          }}
                        />
                      </Td>
                    </Tr>
                  ),
                )}
              </Tbody>
            </Table>
            {userInfo?.total && (
              <Pagination
                page={page}
                setPage={setPage}
                total={userInfo.total}
                quantityItemsPerPage={QUANTITY_ITEMS_PAGE}
                name="userInfo"
              />
            )}
          </>
        ) : userInfo.total > 0 ? (
          <>
            <Table
              data-testid="table-myprojects"
              style={{ marginTop: 8, marginBottom: 32 }}
            >
              <Thead>
                <Tr>
                  <Th width="30%">Name</Th>
                  <Th width="30%">Last Access</Th>
                  <Th>Permissions</Th>
                  <Th />
                  <Th />
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {userInfo &&
                  userInfo.users.map((users, index) => (
                    <Tr key={`list-project-${index.toString()}`} height="72px">
                      <Td data-testid={users.email}>{users.email}</Td>
                      <Td
                        data-testid={format(
                          new Date(users.last_login),
                          'MM/dd/yyyy',
                        )}
                      >
                        {format(new Date(users.last_login), 'MM/dd/yyyy')}
                      </Td>
                      <Td>
                        {users.permissions.length > 0
                          ? users.permissions.toString()
                          : '-'}
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
            {userInfo?.total && (
              <Pagination
                page={page}
                setPage={setPage}
                total={userInfo.total}
                quantityItemsPerPage={QUANTITY_ITEMS_PAGE}
                name="users"
              />
            )}
          </>
        ) : null}
      </div>
    </Container>
  );
};

export default Admin;
