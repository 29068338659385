import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  padding: 1rem;

  > div + div {
    margin-top: 1.5rem;
  }
`;

export const Content = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  gap: 1.5rem;
`;

export const Icon = styled.div`
  width: 2rem;
  height: 2rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: ${({ theme }) => theme.colors.primary};
  border-radius: 100%;

  svg {
    width: 1rem;
    height: 1rem;

    color: ${({ theme }) => theme.colors.white};
  }
`;

export const TextContent = styled.div`
  flex: 1;

  h3 {
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.6875rem;

    white-space: pre-wrap;

    color: ${({ theme }) => theme.colors.gray6};
  }

  > p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;

    color: ${({ theme }) => theme.colors.gray5};

    margin-top: 0.5rem;
  }

  @media (max-width: 1366px) {
    h3 {
      font-size: 1rem;
    }

    > p {
      font-size: 0.875rem;

      margin-top: 0.25rem;
    }
  }
`;

export const IndicatorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  margin-top: 1.5rem;
`;

export const LimitErrorContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 1.5rem 0;

  h2 {
    color: ${({ theme }) => theme.colors.gray6};

    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.6875rem;
  }

  p {
    color: ${({ theme }) => theme.colors.gray5};

    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;

    margin-top: 0.5rem;
  }

  img {
    height: 12.5rem;
  }
`;
