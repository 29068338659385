import styled from 'styled-components';

export const PotentialImpactsContainer = styled.div`
  > p {
    margin-top: 1rem;

    text-align: justify;
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray5};
  }
`;

interface ButtonSeeMoreImpactsProps {
  seeMoreImpacts: boolean;
}

export const ButtonSeeMoreImpacts = styled.button<ButtonSeeMoreImpactsProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  color: ${({ theme }) => theme.colors.primary};

  transition: color 0.2s ease-in-out;

  margin-top: 1.5rem;

  &:hover {
    color: ${({ theme }) => theme.colors.primaryDark};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.gray3};
  }

  span {
    font-weight: 600;
    font-size: 1rem;
  }

  svg {
    transition: transform 0.5s ease-in;
    transform: ${({ seeMoreImpacts }) =>
      seeMoreImpacts ? 'scaleY(-1)' : 'scaleY(1)'};
  }
`;

export const ContentLoadingImpacts = styled.div`
  margin-top: 1rem;
  > div {
    width: 100%;
    height: 1.5rem;

    margin-bottom: 0.25rem;
    &:last-child {
      width: 33%;
      margin-bottom: 1.5rem;
    }
  }
`;

interface ContentChartProps {
  quantityVariables: number;
}

export const ContentChart = styled.div<ContentChartProps>`
  max-height: ${({ quantityVariables }) => quantityVariables * 60 + 24}px;
  overflow: hidden;

  transition: max-height 0.5s linear;
`;
