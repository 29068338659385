const ROCCurveENUSTranslations = {
  rocCurveTitle: 'ROC Curve',
  rocCurveDescription:
    'Evaluate the performance of your model according to its ROC curve on the training data.',
  rocCurvePositiveClassLabel: 'Positive Class',
  rocCurveSelectedModel: 'Selected model',
  rocCurveRandomModel: 'Random model',
  rocCurveXChartLabel: 'False Positive Rate',
  rocCurveYChartLabel: 'True Positive Rate',
  rocCurveAUCLabel: 'Area under the curve',
};

export default ROCCurveENUSTranslations;
