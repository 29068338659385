import styled, { keyframes } from 'styled-components';

interface MenuProps {
  visible: boolean;
  isHomePage?: boolean;
}

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(320px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

const disappearToLeft = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to{
    
    opacity: 0;
    transform: translateX(320px);
  }
`;

export const Overlay = styled.div<MenuProps>`
  width: 100%;
  height: 100%;

  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
`;

export const Container = styled.div<MenuProps>`
  display: flex;
  max-width: 25.25rem;
  padding: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  border-radius: 0.5rem;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0rem 0.5rem 1.5rem 0rem rgba(113, 128, 150, 0.24);
  position: absolute;
  top: ${({ isHomePage }) => (isHomePage ? '4.875rem' : '4.25rem')};
  right: ${({ isHomePage }) => (isHomePage ? '15.75rem' : '2rem')};
  z-index: 1001;
  animation: ${({ visible }) => (visible ? appearFromLeft : disappearToLeft)} 1s;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};

  h4 {
    color: ${({ theme }) => theme.colors.gray7};
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.6875rem;
  }
`;

export const ContainerExtensor = styled.div<MenuProps>`
  width: 1.875rem;
  height: 1.875rem;
  box-shadow: 0rem 0.5rem 1.5rem 0rem rgba(113, 128, 150, 0.24);
  background: ${({ theme }) => theme.colors.white};
  animation: ${({ visible }) => (visible ? appearFromLeft : disappearToLeft)} 1s;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};

  position: absolute;
  top: ${({ isHomePage }) => (isHomePage ? '4.625rem' : '4rem')};
  right: ${({ isHomePage }) => (isHomePage ? '17.9375rem' : '4.1875rem')};
  transform: rotate(45deg);
`;

interface ActionButton {
  iconBackground: string;
  selected?: boolean;
}

export const ActionButton = styled.button<ActionButton>`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  width: 100%;

  > div:first-child {
    display: flex;
    width: 3rem;
    height: 3rem;
    padding: 0.5rem;
    justify-content: center;
    align-items: center;

    border-radius: 0.5rem;
    background: ${({ selected, iconBackground }) =>
      selected ? 'rgba(104, 211, 145, 0.16)' : iconBackground};
  }

  > div:last-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;

    h5 {
      color: ${({ theme }) => theme.colors.gray6};
      font-family: Inter;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.6875rem;
    }

    p {
      color: ${({ theme }) => theme.colors.gray6};
      font-family: Inter;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }

  transition: 0.3s;

  :hover {
    div {
      h5,
      p {
        color: ${({ theme }) => theme.colors.gray7};
      }
    }
  }
`;

interface InfoContainerProps {
  visible: boolean;
}

export const InfoContainer = styled.div<InfoContainerProps>`
  display: flex;
  padding: ${({ visible }) => (visible ? '0.5rem 0.75rem' : '0rem')};
  justify-content: center;
  align-items: flex-end;
  gap: 0.625rem;
  align-self: stretch;
  border-radius: 0.375rem;
  background: ${({ theme }) => theme.colors.gray0};
  transition: max-height 0.8s;
  max-height: ${({ visible }) => (visible ? 'auto' : '0rem')};

  p {
    color: ${({ theme }) => theme.colors.gray5};
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;
    display: ${({ visible }) => !visible && 'none'};
  }

  strong {
    color: ${({ theme }) => theme.colors.gray5};
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.125rem;
  }

  span {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.125rem;
    transition: color 0.3s;

    :hover {
      color: ${({ theme }) => theme.colors.primaryDark};
    }
  }
`;
