/* eslint-disable react/no-this-in-sfc */
import React, { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Card } from 'src/components/Card';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { ToggleSwitch } from 'src/components/ToggleSwitch';
import { useQuery } from 'react-query';
import { frequencyLatestData } from 'src/utils/charts/getLatestData';
import { HCharts, HChartsOptions, HChartsSeries } from 'src/components/HCharts';
import { formatCompactNotation } from 'src/utils/numbers/formatCompactNotation';
import api from 'src/models/service/api';
import { getChartColor } from 'src/utils/colors/getChartColor';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { isAfter } from 'date-fns';

import { ContainerToggle } from '../RealVsForecast/styes';
import { getLatestData } from '../../utils/getLatestData';

interface ProjectionVariationData {
  dates: string[];
  values: number[];
  label: string;
}

export const ProjectionsVariation: React.FC = () => {
  const { t: translate } = useTranslation();
  const { project } = useSelector((state: RootState) => state);

  const [isLatestDataActive, setIsLatestDataActive] = useState<boolean>(true);
  const [isLatestDataDisabled, setIsLatestDataDisabled] = useState(false);

  const {
    data: projectionsVariationData,
    isError,
    isFetching,
    isLoading,
  } = useQuery<ProjectionVariationData[]>(
    [
      'variable performance',
      'forecast variation',
      project.id,
      project.selectedY,
    ],
    async () => {
      const { data } = await api.get<ProjectionVariationData[]>(
        `/projects/${project.id}/${project.selectedY?.id}/performance/forecast-variation`,
      );

      return data.map((serie, index) => {
        if (index !== data.length - 1) {
          return serie;
        }

        const historicalLastIndex = data[0].dates.length - 1;

        if (
          isAfter(
            new Date(`${serie.dates[0]}T00:00`),
            new Date(`${data[0].dates[historicalLastIndex]}T00:00`),
          )
        ) {
          return {
            ...serie,
            dates: [data[0].dates[historicalLastIndex], ...serie.dates],
            values: [data[0].values[historicalLastIndex], ...serie.values],
          };
        }

        return serie;
      });
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled: !!project.id && !!project.selectedY,
    },
  );

  useEffect(() => {
    if (projectionsVariationData && project.selectedY?.info?.frequency) {
      const dates = projectionsVariationData[0].dates;

      const otherDates = projectionsVariationData.flatMap((info) =>
        info.dates.filter((date) => !dates.includes(date)),
      );

      const quantity = dates.length + otherDates.length;

      if (quantity <= frequencyLatestData[project.selectedY.info.frequency]) {
        setIsLatestDataDisabled(true);
        setIsLatestDataActive(false);
        return;
      }
    }

    if (isError) {
      setIsLatestDataDisabled(true);
      setIsLatestDataActive(false);
      return;
    }

    if (!isError || !project.selectedY) {
      return;
    }

    if (!project.selectedY?.info?.frequency) {
      setIsLatestDataDisabled(true);
      setIsLatestDataActive(false);
      return;
    }

    setIsLatestDataDisabled(false);
  }, [projectionsVariationData, isError, project]);

  function handleActiveLatestData(value: boolean) {
    setIsLatestDataActive(value);
  }

  const series: HChartsSeries[] = useMemo(() => {
    const seriesAux: HChartsSeries[] = [];

    if (
      isLatestDataActive &&
      project.selectedY?.info?.frequency &&
      projectionsVariationData
    ) {
      const adjustedData = getLatestData(
        projectionsVariationData,
        project.selectedY?.info?.frequency,
      );

      adjustedData?.forEach((data, index) => {
        seriesAux.push({
          name: data.label.includes('Historical')
            ? translate('historicalData')
            : data.label.includes('Original')
            ? translate('originalForecast')
            : data.label.includes('Manual Adjust')
            ? translate('manualAdjust')
            : data.label.includes('Update')
            ? `${data.label} (${translate('Forecast')})`
            : '',
          type: 'line',
          marker: {
            symbol: 'circle',
          },
          color: getChartColor(index),
          dashStyle: data.label.includes('Historical Data') ? 'Solid' : 'Dash',
          data: data.dates.map((date, dateIndex) => ({
            x: new Date(`${date}T00:00`).getTime(),
            y: data.values[dateIndex] ?? null,
            custom: {
              value: formatCompactNotation(data.values[dateIndex]),
            },
          })),
          visible: index === 0 ? true : adjustedData.length - 1 - 3 < index,
        });
      });

      return seriesAux;
    }

    projectionsVariationData?.forEach((data, index) => {
      seriesAux.push({
        name: data.label.includes('Historical')
          ? translate('historicalData')
          : data.label.includes('Original')
          ? translate('originalForecast')
          : data.label.includes('Manual Adjust')
          ? translate('manualAdjust')
          : data.label.includes('Update')
          ? `${data.label} (${translate('Forecast')})`
          : '',
        type: 'line',
        zIndex: index === 0 ? 1 : -1,
        marker: {
          symbol: 'circle',
        },
        color: getChartColor(index),
        dashStyle: data.label.includes('Historical Data') ? 'Solid' : 'Dash',
        data: data.dates.map((date, dateIndex) => ({
          x: new Date(`${date}T00:00`).getTime(),
          y: data.values[dateIndex] ?? null,
          custom: {
            value: formatCompactNotation(data.values[dateIndex]),
          },
        })),
        visible:
          index === 0 ? true : projectionsVariationData.length - 1 - 3 < index,
      });
    });

    return seriesAux;
  }, [
    isLatestDataActive,
    project.selectedY?.info?.frequency,
    projectionsVariationData,
    translate,
  ]);

  const options: HChartsOptions = useMemo(
    () => ({
      chart: {
        height: 300,
      },
      tooltip: {
        pointFormat:
          `<tr><td><b>${translate('date')}:</b> </td>` +
          `<td style="text-align: right">{point.x: ${
            project.selectedY?.info?.frequency === 'annual' ? '%Y' : ' %d/%m/%Y'
          }}</td></tr>` +
          `<tr><td><b>${translate('value')}:</b> </td>` +
          '<td style="text-align: right">{point.custom.value}</td></tr>',
      },
      plotOptions: {
        series: {
          events: {
            legendItemClick() {
              if (projectionsVariationData) {
                const legendClicked = this.name;
                const isShowing = this.visible;
                const data = projectionsVariationData[0];

                if (legendClicked === translate('historicalData')) {
                  if (isShowing) {
                    this.chart.series?.at(-1)?.removePoint(0, false);
                  } else {
                    const lastHistoricalDate = data.dates.at(-1);
                    const lastHistoricalValue = data.values.at(-1);
                    if (lastHistoricalDate && lastHistoricalValue) {
                      this.chart.series?.at(-1)?.addPoint(
                        {
                          x: new Date(lastHistoricalDate).getTime(),
                          y: lastHistoricalValue,
                          marker: undefined,
                          custom: {
                            value: formatCompactNotation(lastHistoricalValue),
                          },
                        },
                        false,
                      );
                    }
                  }
                }
              }
            },
          },
        },
      },
    }),
    [project.selectedY?.info?.frequency, projectionsVariationData, translate],
  );

  return (
    <div className="containerLinear">
      <Card
        textCard={translate('projectionsVariationTitle')}
        textDescription={
          project.selectedY?.label &&
          translate('projectionsVariationDescription').replace(
            '"Y"',
            project.selectedY?.label,
          )
        }
      />
      <ContainerToggle>
        <ToggleSwitch
          label={translate('latestData')}
          checked={isLatestDataActive}
          onChange={(event) => handleActiveLatestData(event.target.checked)}
          disabled={isLatestDataDisabled}
          data-testid="projections-variation-toggle-latest-data"
        />
      </ContainerToggle>
      {isError || !project ? (
        <ContainerMaintenance
          content="chart"
          data-testid="projections-variation-error"
        />
      ) : isLoading || isFetching || !projectionsVariationData ? (
        <ContainerSkeleton data-testid="chart-projections-variation-loading" />
      ) : (
        <HCharts
          series={series}
          options={options}
          dataCy={
            isLatestDataActive
              ? 'chart-projections-latest'
              : 'chart-projections-complete'
          }
          resizeWidthWithSidebar
        />
      )}
    </div>
  );
};
