import styled, { css, keyframes } from 'styled-components';

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-20px);

  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

interface SidebarProps {
  visible: boolean;
}

export const SideBar = styled.aside<SidebarProps>`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};

  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  position: sticky;

  height: calc(100vh - 4.625rem);
  min-width: 23.75rem !important;
  top: 10.625rem;

  margin-top: 2rem;
  margin-bottom: 3.125rem;

  overflow-y: auto;

  padding-left: 5rem;

  @media (max-width: 1550px) {
    padding-left: 1.5rem;
  }

  > div {
    width: 100%;
    padding-right: ${({ visible }) => (visible ? '1.5rem' : '0')};
  }

  border-right: 1px solid ${({ theme }) => theme.colors.gray2};
  background: ${({ theme }) => theme.colors.white};
  animation: ${({ visible }) => visible && appearFromLeft} 0.4s;

  @media (min-width: 1450px) {
    min-width: ${({ visible }) => (visible ? '20rem' : '0')};
    max-width: ${({ visible }) => (visible ? '20rem' : '0')};
  }

  ${({ visible }) =>
    !visible &&
    css`
      border-right: 0px;

      *,
      button {
        display: none;
      }
    `}

  ::-webkit-scrollbar {
    width: 0.313rem;
    height: 0.313rem;
    padding: 0.063rem;
  }

  ::-webkit-scrollbar-track {
    transition: 0.2s;
    margin: 0.188rem;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }
`;
