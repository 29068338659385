import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { MagnifyingGlass } from 'phosphor-react';
import { differenceInMonths, format } from 'date-fns';
import { enUS, ptBR } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { Card } from 'src/components/Card';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Pagination } from 'src/components/Pagination';
import { RadioButton } from 'src/components/RadioButton';
import api from 'src/models/service/api';
import { RootState } from 'src/redux/store';
import { queryClient } from 'src/service/queryClient';
import { Input } from 'src/components/Input';
import { transformUppercaseFirstLetter } from 'src/utils/strings/transformUppercaseFirstLetter';
import { Y } from 'src/models/redux/reducers/Project';
import { formatCompactNotation } from 'src/utils/numbers/formatCompactNotation';
import { escapeRegExp } from 'src/utils/escapeRegExp';
import { Status } from 'src/components/Status';

import { ProjectOverviewContext } from '../..';
import { ExportStart } from '../ExportStart';
import {
  Legend,
  ProjectionsContainer,
  ContentTable,
  Td,
  ProjectionTdFixed,
  OptionsTable,
  ThYearly,
  ThMonthly,
  TrComplete,
  FooterTable,
  ActionsContainer,
} from './styles';

export interface AnnualSerieLevel {
  forecast?: {
    type: string;
    y_all: number[];
    year: string[];
  };
  historical: {
    type: string;
    y_all: number[];
    year: string[];
  };
}

export interface AnnualSerieVariation {
  forecast?: {
    percent: number[];
    type: string;
    year: string[];
  };
  historical: {
    percent: number[];
    type: string;
    year: string[];
  };
}

interface Dates {
  value: string;
  isProjection: boolean;
}

interface Result {
  date: string;
  value: string;
  isProjection: boolean;
}

interface Serie {
  name: string;
  results: Result[];
}

interface ProjectionsAnnualProps {
  hasData: boolean;
  series: Serie[];
}

interface SerieToFillProjections {
  historical: {
    dates: string[];
    values: number[];
  };
  forecast: {
    dates: string[];
    values: number[];
  };
}

interface ActualForecast {
  level: {
    forecast?: {
      x: string[];
      y: number[];
      type: string;
    };
    historical: {
      x: string[];
      y: number[];
      type: string;
    };
  };
  variation: {
    forecast?: {
      x: string[];
      y: number[];
      type: string;
    };
    historical: {
      x: string[];
      y: number[];
      type: string;
    };
  };
}

interface ActualForecastFillProjections {
  level: SerieToFillProjections;
  variation: SerieToFillProjections;
}

interface ExtractYearFromDates extends Dates {
  numberOfMonthsThisYear: number;
}

interface ErrorProps {
  message: string;
  quantityLetters: number;
}

interface AllDatesProps {
  original: {
    level: Dates[];
    variation: Dates[];
  };
  yearly: {
    level: Dates[];
    variation: Dates[];
  };
}

type Frequency = 'original' | 'yearly';

type Transformation = 'level' | 'variation';

const QUANTITY_ITEM_PER_PAGE = 10;

export const Projections: React.FC = () => {
  const [frequency, setFrequency] = useState<Frequency>('yearly');
  const [transformation, setTransformation] = useState<Transformation>('level');
  const [page, setPage] = useState<number>(1);
  const [isFrequencyMonthly, setIsFrequencyMonthly] = useState<boolean | null>(
    null,
  );

  const [searchValue, setSearchValue] = useState('');
  const [searchTimer, setSearchTimer] = useState(250);
  const [searchError, setSearchError] = useState<ErrorProps>();
  const [searchAllowed, setSearchAllowed] = useState(false);
  const [timeOutActive, setTimeOutActive] = useState(false);
  const [lastSearch, setLastSearch] = useState('');

  const [dependentVariables, setDependentVariables] = useState<Y[]>([]);

  const [loadingFirstTime, setLoadingFirstTime] = useState(true);

  const [allDates, setAllDates] = useState<AllDatesProps>({
    original: {
      level: [],
      variation: [],
    },
    yearly: {
      level: [],
      variation: [],
    },
  });

  const {
    project,
    auth: { user },
  } = useSelector((state: RootState) => state);

  const { projectYsSuccess } = useContext(ProjectOverviewContext);

  const [projections, setProjections] = useState<ProjectionsAnnualProps>({
    hasData: false,
    series: [],
  });

  const { t: translate } = useTranslation();

  const loadAnnualLevel = useCallback(
    async (index: number): Promise<SerieToFillProjections> => {
      let dataAux: SerieToFillProjections;

      const key = [
        'project overview',
        'projections-annual-level',
        project.id,
        dependentVariables[index].id,
      ];

      const contentQuery =
        queryClient.getQueryData<SerieToFillProjections>(key);

      try {
        if (contentQuery) {
          dataAux = contentQuery;
        } else {
          dataAux = await queryClient.fetchQuery<SerieToFillProjections>(
            key,
            async () => {
              const { data } = await api.get<AnnualSerieLevel>(
                `/projects/${project.id}/${dependentVariables[index].id}/model-in-production/annual_series`,
              );

              return {
                historical: {
                  dates: data.historical.year,
                  values: data.historical?.y_all,
                },
                forecast: {
                  dates: data.forecast?.year ?? [],
                  values: data.forecast?.y_all ?? [],
                },
              };
            },
            {
              staleTime: 1000 * 60 * 20,
            },
          );
        }
      } catch (err) {
        return {
          historical: {
            dates: [],
            values: [],
          },
          forecast: {
            dates: [],
            values: [],
          },
        };
      }

      return dataAux;
    },
    [project.id, dependentVariables],
  );

  const loadAnnualVariation = useCallback(
    async (index: number): Promise<SerieToFillProjections> => {
      let dataAux: SerieToFillProjections;

      const key = [
        'project overview',
        'projections-annual-variation',
        project.id,
        dependentVariables[index].id,
      ];

      const contentQuery =
        queryClient.getQueryData<SerieToFillProjections>(key);

      try {
        if (contentQuery) {
          dataAux = contentQuery;
        } else {
          dataAux = await queryClient.fetchQuery<SerieToFillProjections>(
            key,
            async () => {
              const { data } = await api.get<AnnualSerieVariation>(
                `/projects/${project.id}/${dependentVariables[index].id}/model-in-production/annual_growth_rate`,
              );

              return {
                historical: {
                  dates: data.historical.year,
                  values: data.historical?.percent,
                },
                forecast: {
                  dates: data.forecast?.year ?? [],
                  values: data.forecast?.percent ?? [],
                },
              };
            },
            {
              staleTime: 1000 * 60 * 20,
            },
          );
        }
      } catch (err) {
        return {
          historical: {
            dates: [],
            values: [],
          },
          forecast: {
            dates: [],
            values: [],
          },
        };
      }

      return dataAux;
    },
    [project.id, dependentVariables],
  );

  const loadOriginal = useCallback(
    async (index: number): Promise<ActualForecastFillProjections> => {
      let dataAux: ActualForecastFillProjections;

      const key = [
        'project overview',
        'projections-actual_forecast',
        project.id,
        dependentVariables[index].id,
      ];

      const contentQuery =
        queryClient.getQueryData<ActualForecastFillProjections>(key);

      try {
        if (contentQuery) {
          dataAux = contentQuery;
        } else {
          dataAux = await queryClient.fetchQuery<ActualForecastFillProjections>(
            key,
            async () => {
              const { data } = await api.get<ActualForecast>(
                `/projects/${project.id}/${dependentVariables[index].id}/model-in-production/actual_forecast`,
              );

              return {
                level: {
                  historical: {
                    dates: data.level.historical.x,
                    values: data.level.historical?.y,
                  },
                  forecast: {
                    dates: data.level.forecast?.x ?? [],
                    values: data.level.forecast?.y ?? [],
                  },
                },
                variation: {
                  historical: {
                    dates: data.variation.historical.x,
                    values: data.variation.historical?.y,
                  },
                  forecast: {
                    dates: data.variation.forecast?.x ?? [],
                    values: data.variation.forecast?.y ?? [],
                  },
                },
              };
            },
            {
              staleTime: 1000 * 60 * 20,
            },
          );
        }
      } catch (err) {
        return {
          level: {
            historical: {
              dates: [],
              values: [],
            },
            forecast: {
              dates: [],
              values: [],
            },
          },
          variation: {
            historical: {
              dates: [],
              values: [],
            },
            forecast: {
              dates: [],
              values: [],
            },
          },
        };
      }
      return dataAux;
    },
    [project.id, dependentVariables],
  );

  useEffect(() => {
    async function findIfFrequencyIsMonthly() {
      if (dependentVariables.length > 1) {
        if (dependentVariables[0]?.info?.frequency) {
          setIsFrequencyMonthly(
            dependentVariables.every(
              (dependentVariable) =>
                dependentVariable?.info?.frequency === 'monthly',
            ),
          );
        } // projeto antigo nao a propriedade info
        const original = await loadOriginal(0); // arrumar depois

        const firstSerie = original.level;

        if (firstSerie.historical.dates.length >= 2) {
          if (
            differenceInMonths(
              new Date(`${firstSerie.historical.dates[1]}T00:00`),
              new Date(`${firstSerie.historical.dates[0]}T00:00`),
            ) === 1
          ) {
            setIsFrequencyMonthly(true);
          } else {
            setIsFrequencyMonthly(false);
          }
        }
      }
    }

    findIfFrequencyIsMonthly();
  }, [dependentVariables, loadOriginal]);

  const sortByDate = useCallback(
    (dates: Dates[]) => {
      if (frequency === 'yearly') {
        return dates.sort((a, b) => {
          if (a.value < b.value) {
            return -1;
          }
          if (a.value > b.value) {
            return 1;
          }
          return 0;
        });
      }

      return dates.sort((a, b) => {
        if (new Date(`${a.value}T00:00`) < new Date(`${b.value}T00:00`)) {
          return -1;
        }
        if (new Date(`${a.value}T00:00`) > new Date(`${b.value}T00:00`)) {
          return 1;
        }
        return 0;
      });
    },
    [frequency],
  );

  useEffect(() => {
    let canLoad = true;

    async function load() {
      const initialIndex = (page - 1) * QUANTITY_ITEM_PER_PAGE;
      const finalIndex =
        page * QUANTITY_ITEM_PER_PAGE < dependentVariables.length
          ? page * QUANTITY_ITEM_PER_PAGE
          : dependentVariables.length;

      if (dependentVariables.length) setLoadingFirstTime(false);

      for (let i = initialIndex; i < finalIndex; i++) {
        let dataAux: SerieToFillProjections;
        if (frequency === 'yearly') {
          if (transformation === 'variation') {
            dataAux = await loadAnnualVariation(i);
          } else if (transformation === 'level') {
            dataAux = await loadAnnualLevel(i);
          }
        }
        if (frequency === 'original') {
          const original = await loadOriginal(i);
          if (transformation === 'variation') {
            dataAux = original.variation;
          } else {
            dataAux = original.level;
          }
        }

        if (!canLoad) {
          return;
        }

        setAllDates((state) => {
          let dates: Dates[] = [];

          dates = [...state[frequency][transformation]];

          dataAux.historical.dates.forEach((date) => {
            if (!dates.some((dateAux) => dateAux.value === date)) {
              dates.push({
                value: date,
                isProjection: false,
              });
            }
          });

          dataAux.forecast.dates.forEach((date) => {
            if (!dates.some((dateAux) => dateAux.value === date)) {
              dates.push({
                value: date,
                isProjection: true,
              });
            }
          });

          return {
            ...state,
            [frequency]: {
              ...state[frequency],
              [transformation]: sortByDate(dates),
            },
          };
        });

        setProjections((state) => {
          let hasData = state.hasData;

          const serie: Serie = {
            name: dependentVariables[i].label,
            results: [],
          };

          dataAux.historical.values.forEach((y, index) => {
            hasData = true;
            serie.results.push({
              isProjection: false,
              value: formatCompactNotation(y),
              date: dataAux.historical.dates[index] ?? '--',
            });
          });

          dataAux.forecast.values.forEach((y, index) => {
            hasData = true;
            serie.results.push({
              isProjection: true,
              value: formatCompactNotation(y),
              date: dataAux.forecast.dates[index] ?? '--',
            });
          });

          return {
            hasData,
            series: [...state.series, serie],
          };
        });
      }
    }

    load();

    return () => {
      canLoad = false;
      setProjections({
        hasData: false,
        series: [],
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    frequency,
    dependentVariables,
    dependentVariables.length,
    transformation,
    page,
  ]);

  function handleSelectFrequency(frequencyClicked: Frequency): void {
    setFrequency(frequencyClicked);
  }

  function handleSelectTransformation(
    transformationClicked: Transformation,
  ): void {
    setTransformation(transformationClicked);
  }

  function extractYears(dates: Dates[]): ExtractYearFromDates[] {
    const onlyYears: Dates[] = dates.map((date) => ({
      isProjection: date.isProjection,
      value: format(new Date(`${date.value}T00:00`), 'yyyy'),
    }));

    const yearsWithoutRepeating: ExtractYearFromDates[] = [];

    onlyYears.forEach((year) => {
      const searchIndex = yearsWithoutRepeating.findIndex(
        (yearAux) => yearAux.value === year.value,
      );

      if (searchIndex === -1) {
        yearsWithoutRepeating.push({
          value: year.value,
          isProjection: year.isProjection,
          numberOfMonthsThisYear: 1,
        });
      } else {
        yearsWithoutRepeating.splice(searchIndex, 1, {
          isProjection: year.isProjection,
          value: year.value,
          numberOfMonthsThisYear:
            yearsWithoutRepeating[searchIndex].numberOfMonthsThisYear + 1,
        });
      }
    });
    return yearsWithoutRepeating;
  }

  const adjustSerieValueAccordingToDates = useCallback(
    (serieValues: Result[]): Result[] => {
      const results: Result[] = [];

      allDates[frequency][transformation].forEach((date) => {
        const index = serieValues.findIndex(
          (serieValue) => serieValue.date === date.value,
        );
        if (index !== -1) {
          results.push(serieValues[index]);
        } else {
          results.push({
            date: date.value,
            isProjection:
              date.isProjection === false && results.length > 0
                ? results[results.length - 1].isProjection
                : date.isProjection,
            value: '--',
          });
        }
      });

      return results;
    },
    [allDates, frequency, transformation],
  );

  function handleChangePage(pageAux: number): void {
    setPage(pageAux);
  }

  const numberOfSeriesWillLoad = useMemo(
    () =>
      page * QUANTITY_ITEM_PER_PAGE >= dependentVariables.length
        ? dependentVariables.length - (page - 1) * QUANTITY_ITEM_PER_PAGE
        : QUANTITY_ITEM_PER_PAGE,
    [page, dependentVariables.length],
  );

  function handleSearchDependentVariable(value: string) {
    setSearchValue(value);

    if (value.length > 50) {
      setSearchError({
        message: 'searchMaxCharactersError',
        quantityLetters: 50,
      });
      return;
    }

    setSearchError({
      message: '',
      quantityLetters: 0,
    });

    if (value !== searchValue) {
      setSearchTimer(250);
      setTimeOutActive(true);
    }
  }

  useEffect(() => {
    if (timeOutActive) {
      setTimeout(() => {
        if (searchTimer > 0) {
          setSearchTimer(searchTimer - 250);
        } else {
          setTimeOutActive(false);
        }
      }, 250);
    } else {
      searchTimer === 0 && setSearchAllowed(true);
    }
  }, [searchTimer, searchValue, timeOutActive]);

  useEffect(() => {
    if (!searchError?.message && searchAllowed) {
      setPage(1);

      const regex = new RegExp(escapeRegExp(searchValue), 'i');

      const updatedVariables = searchValue.length
        ? projectYsSuccess.filter(({ label }) => regex.test(label))
        : projectYsSuccess;
      setDependentVariables(updatedVariables);

      setSearchAllowed(false);
      setTimeOutActive(false);
      setSearchTimer(250);
      setLastSearch(searchValue);
    }
  }, [searchError, searchAllowed, searchValue, projectYsSuccess]);

  useEffect(() => {
    setDependentVariables(projectYsSuccess);
  }, [projectYsSuccess]);

  const loading =
    page * QUANTITY_ITEM_PER_PAGE >= dependentVariables.length
      ? (page - 1) * QUANTITY_ITEM_PER_PAGE + projections?.series.length !==
        dependentVariables.length
      : projections?.series.length !== QUANTITY_ITEM_PER_PAGE;

  const showLoading =
    (loading && !projections.hasData) ||
    (allDates[frequency][transformation].length &&
      !projections.hasData &&
      !searchValue) ||
    loadingFirstTime;

  return (
    <ProjectionsContainer className="containerLinear">
      <Card
        textCard={translate('ProjectOverviewProjectionsTitle')}
        textDescription={translate('ProjectOverviewProjectionsDescription')}
      />

      <ActionsContainer>
        <OptionsTable>
          <div>
            <span>{translate('ProjectOverviewProjectionsFrequency')}</span>
            <div>
              <Tooltip
                id="radio-button-tooltip"
                className="customTooltipTheme"
              />
              <RadioButton
                label={translate('ProjectOverviewProjectionsOriginal')}
                dataCy="projections-radio-button-original"
                onChange={() => handleSelectFrequency('original')}
                checked={frequency === 'original'}
                dataTip={
                  isFrequencyMonthly === null
                    ? translate('loading')
                    : isFrequencyMonthly === false
                    ? translate(
                        'ProjectOverviewProjectionsOnlyForMonthlyFrequency',
                      )
                    : undefined
                }
                disabled={!isFrequencyMonthly}
              />
              <RadioButton
                label={translate('ProjectOverviewProjectionsAnnual')}
                dataCy="projections-radio-button-yearly"
                onChange={() => handleSelectFrequency('yearly')}
                checked={frequency === 'yearly'}
              />
            </div>
          </div>

          <div>
            <span>{translate('ProjectOverviewProjectionsTransformation')}</span>
            <div>
              <RadioButton
                label={translate('ProjectOverviewProjectionsLevel')}
                dataCy="projections-radio-button-level"
                onChange={() => handleSelectTransformation('level')}
                checked={transformation === 'level'}
              />
              <RadioButton
                label={translate('ProjectOverviewProjectionsVariation')}
                dataCy="projections-radio-button-variation"
                onChange={() => handleSelectTransformation('variation')}
                checked={transformation === 'variation'}
              />
            </div>
          </div>
        </OptionsTable>

        <Input
          icon={<MagnifyingGlass size="1.25rem" />}
          testid="search-dependent-variables-projection"
          placeholder={translate(
            'projectOverviewSearchDependentVariablePlaceholder',
          )}
          onChange={(event) => {
            handleSearchDependentVariable(event.target.value);
          }}
          error={
            searchError?.message &&
            translate(searchError.message).replace(
              'XX',
              String(searchError.quantityLetters),
            )
          }
        />
      </ActionsContainer>

      {showLoading ? (
        <ContainerSkeleton data-testid="projections-loading" />
      ) : !projections.hasData &&
        searchValue.length >= 1 &&
        !dependentVariables.length ? (
        // eslint-disable-next-line react/jsx-indent
        <Status
          type="noSearchResults"
          title={`${translate(
            'projectOverviewSearchDependentVariableNotFound',
          )} "${lastSearch}".`}
          dataCy="projection-variable-not-found"
        />
      ) : !projections.hasData &&
        !allDates[frequency][transformation].length ? (
        // eslint-disable-next-line react/jsx-indent
        <ContainerMaintenance
          content={translate('ProjectOverviewProjectionsTable')}
          data-testid="projections-container-maintenance"
        />
      ) : (
        <>
          <ContentTable
            hasMonths={frequency === 'original'}
            data-testid="projections-content-table"
          >
            <table>
              <thead>
                <tr>
                  <ThYearly
                    isProjection={false}
                    hasMonths={frequency === 'original'}
                  >
                    {frequency === 'yearly'
                      ? translate('projectOverviewFirstColumn')
                      : ''}
                  </ThYearly>

                  {frequency === 'yearly'
                    ? // eslint-disable-next-line react/jsx-indent-props
                      allDates[frequency][transformation].map((date, index) => (
                        <ThYearly
                          isProjection={false}
                          hasMonths={false}
                          key={`th-annual-${date.value}-${index.toString()}`}
                        >
                          {date.value}
                        </ThYearly>
                      ))
                    : // eslint-disable-next-line react/jsx-indent-props
                      extractYears(
                        allDates[frequency][transformation] ?? [],
                      ).map((date, index) => (
                        <ThYearly
                          isProjection={false}
                          hasMonths
                          colSpan={date.numberOfMonthsThisYear}
                          key={`th-annual-${date.value}-${index.toString()}`}
                        >
                          {format(new Date(`${date.value}T00:00`), 'yyyy')}
                        </ThYearly>
                      ))}
                </tr>
                {frequency === 'original' && (
                  <tr>
                    <ThMonthly isProjection={false} separateYear={false}>
                      {translate('projectOverviewFirstColumn')}
                    </ThMonthly>
                    {allDates[frequency][transformation].map((date) => (
                      <ThMonthly
                        isProjection={date.isProjection}
                        key={`th-${date.value}`}
                        separateYear={
                          format(new Date(`${date.value}T00:00`), 'MMM') ===
                          'Dec'
                        }
                      >
                        <div>
                          {transformUppercaseFirstLetter(
                            format(new Date(`${date.value}T00:00`), 'MMM', {
                              locale: user.language === 'en-us' ? enUS : ptBR,
                            }),
                          )}
                        </div>
                      </ThMonthly>
                    ))}
                  </tr>
                )}
              </thead>
              <tbody>
                <>
                  {projections?.series.map((serie) => (
                    <tr key={`td-${serie.name}`}>
                      <ProjectionTdFixed
                        data-cy={`projections-${serie.name
                          .toLowerCase()
                          .replaceAll(' ', '-')}-variable`}
                        data-testid={`projections-${serie.name
                          .toLowerCase()
                          .replaceAll(' ', '-')}-variable`}
                      >
                        {serie.name}
                      </ProjectionTdFixed>
                      {adjustSerieValueAccordingToDates(serie.results).map(
                        (result, index) => (
                          <Td
                            key={`td-${serie.name}-${
                              result.value
                            }-${index.toString()}`}
                            isProjection={result.isProjection}
                          >
                            <div>
                              <span>{result.value}</span>
                            </div>
                          </Td>
                        ),
                      )}
                    </tr>
                  ))}

                  {Array.from(
                    {
                      length:
                        numberOfSeriesWillLoad - projections.series.length,
                    },
                    (_, index) => (
                      <tr
                        key={`tr-loading-${index.toString()}`}
                        data-testid={`tr-loading-${index.toString()}`}
                      >
                        {Array.from(
                          {
                            length:
                              allDates[frequency][transformation].length + 1,
                          },
                          (__, indexAux) => (
                            <Td
                              key={`td-loading-${index.toString()}-${indexAux.toString()}`}
                              isProjection={false}
                            >
                              <ContainerSkeleton
                                withLoading={false}
                                style={{
                                  height: '28px',
                                }}
                              />
                            </Td>
                          ),
                        )}
                      </tr>
                    ),
                  )}

                  {Array.from(
                    { length: QUANTITY_ITEM_PER_PAGE - numberOfSeriesWillLoad },
                    (_, index) => (
                      <TrComplete key={`tr-complete-${index.toString()}`} />
                    ),
                  )}
                </>
              </tbody>
            </table>
          </ContentTable>

          <Legend>
            <div>
              <div />
              <span>{translate('ProjectOverviewProjectionsHistorical')}</span>
            </div>

            <div>
              <div />
              <span>{translate('ProjectOverviewProjectionsForecast')}</span>
            </div>
          </Legend>

          <FooterTable>
            <ExportStart />

            <Pagination
              name={translate('ProjectOverviewProjectionsVariables')}
              page={page}
              quantityItemsPerPage={QUANTITY_ITEM_PER_PAGE}
              total={dependentVariables.length}
              setPage={handleChangePage}
            />
          </FooterTable>
        </>
      )}
    </ProjectionsContainer>
  );
};
