export const getTextWidthWithLineBreaks = (
  text: string,
  font: string,
  maxWidth: number,
): { width: number; lineBreaks: number } => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  if (context) {
    context.font = font || getComputedStyle(document.body).font;
    context.textBaseline = 'top'; // Define a linha de base para o topo

    const words = text.split(' ');
    let currentLine = '';
    let currentLineWidth = 0;
    let totalWidth = 0;
    let lineBreaks = 0;

    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      const wordWidth = context.measureText(word).width;

      if (currentLineWidth + wordWidth <= maxWidth) {
        currentLine += (currentLine === '' ? '' : ' ') + word;
        currentLineWidth += wordWidth;
      } else {
        totalWidth = Math.max(totalWidth, currentLineWidth);
        currentLine = word;
        currentLineWidth = wordWidth;
        lineBreaks++;
      }
    }

    totalWidth = Math.max(totalWidth, currentLineWidth);
    lineBreaks++;

    return { width: totalWidth, lineBreaks };
  }

  return { width: 0, lineBreaks: 0 };
};
