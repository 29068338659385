import styled from 'styled-components';

export const AnnualSummaryContainer = styled.div``;

export const ContentTable = styled.div`
  overflow-x: auto;
  padding-bottom: 1.5rem;
  margin-bottom: 0.5rem;

  table {
    width: 100%;

    border-spacing: unset;
  }
`;

interface ThProps {
  isProjection: boolean;
}

export const Th = styled.th<ThProps>`
  /* position: sticky; */

  background: ${({ theme }) => theme.colors.white};

  font-size: 1rem;
  font-weight: 700;

  line-height: 150%;
  color: ${({ theme, isProjection }) =>
    isProjection ? theme.colors.secondary : theme.colors.gray5};

  padding-bottom: 1rem;

  &:not(:last-child) {
    padding-right: 0.75rem;
  }

  &:not(:first-child) {
    opacity: 0.8;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray2};
  }

  &:first-child {
    position: sticky;
    left: 0;
    background-color: ${({ theme }) => theme.colors.white};
    z-index: 1;
  }
`;

const TdBase = styled.td`
  padding-top: 1.5rem;

  &:first-child {
    position: sticky;
    left: 0px;
    background-color: ${({ theme }) => theme.colors.white};
    z-index: 1;
  }

  &:not(:last-child) {
    padding-right: 0.75rem;
  }
`;

export const TdLabel = styled(TdBase)`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: ${({ theme }) => theme.colors.gray5};
`;

interface TdValueProps {
  isProjection: boolean;
}

export const TdValue = styled(TdBase)<TdValueProps>`
  div {
    min-width: 3.75rem;
    height: 1.75rem;

    padding: 0.125rem 0.75rem;
    background-color: ${({ isProjection }) =>
      isProjection ? 'rgba(255, 171, 211, 0.12)' : 'rgba(129, 179, 255, 0.08)'};
    border-radius: 8px;

    text-align: center;

    span {
      font-weight: 400;
      font-size: 0.75rem;
      color: ${({ theme, isProjection }) =>
        isProjection ? theme.colors.secondary : theme.colors.primary};
    }
  }
`;

export const Legend = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1.5rem;

  > div {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    > div {
      width: 0.75rem;
      height: 0.75rem;
      background-color: ${({ theme }) => theme.colors.primary};
      border-radius: 2px;
    }

    span {
      font-weight: 400;
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors.gray5};
    }

    &:nth-child(2) {
      div {
        background-color: ${({ theme }) => theme.colors.secondary};
      }
    }
  }
`;
