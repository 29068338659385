import React from 'react';

import { useTranslation } from 'react-i18next';
import { Modal } from 'src/components/Modal';
import { Button } from 'src/components/Button';
import { ModalLoading } from 'src/components/Modal/Loading';
import Error from 'src/assets/error.svg';
import parseHTML from 'html-react-parser';

import { ReleaseErrorModalProps } from './types';
import { Container, ContentButton, YsContainer } from './styles';

export const ReleaseErrorModal: React.FC<ReleaseErrorModalProps> = ({
  setVisible,
  isPreview,
  publishedBy,
  ys,
  loadingError,
}) => {
  const { t: translate } = useTranslation();

  if (loadingError) {
    return <ModalLoading visible style={{ width: '32rem' }} />;
  }

  return (
    <Modal visible setVisible={setVisible} style={{ padding: 0 }}>
      <Container data-cy="fail-modal" data-testid="fail-modal">
        <img src={Error} alt="error" data-testid="cloud-error-img" />

        {!ys.length ? (
          <>
            <h1>{translate('workspaceControlPanelReleaseErrorTitle')}</h1>
            <p data-testid="release-error-description">
              {parseHTML(
                translate('workspaceControlPanelReleaseErrorDescription')
                  .replace(
                    'XXX',
                    translate(
                      isPreview
                        ? 'workspaceControlPanelReleaseErrorPreview'
                        : 'workspaceControlPanelReleaseErrorCurrent',
                    ),
                  )
                  .replace('YYY', publishedBy),
              )}
            </p>
          </>
        ) : (
          <>
            <h1>{translate('workspaceControlPanelReleaseErrorTitle')}</h1>
            <p data-testid="release-error-description">
              {parseHTML(
                translate('workspaceControlPanelReleaseYsErrorDescription')
                  .replace(
                    'XXX',
                    translate(
                      isPreview
                        ? 'workspaceControlPanelReleaseErrorPreview'
                        : 'workspaceControlPanelReleaseErrorCurrent',
                    ),
                  )
                  .replace('YYY', publishedBy),
              )}
            </p>

            <YsContainer>
              {ys.map(({ y_label }) => (
                <p
                  key={y_label}
                  data-testid={`y-error-${y_label
                    .replaceAll(' ', '-')
                    .toLowerCase()}`}
                >
                  {y_label}
                </p>
              ))}
            </YsContainer>
          </>
        )}

        <ContentButton>
          <Button
            buttonType="primary"
            data-testid="modalFailedButtonOk"
            onClick={setVisible}
            data-cy="button-modal-failed-ok"
          >
            {translate('back')}
          </Button>
        </ContentButton>
      </Container>
    </Modal>
  );
};
