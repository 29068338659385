const groupsENTranslate = {
  groupsMyFavoritesTitle: 'My Favorites',
  groupsMyFavoritesDescription:
    'View your favorite series by groups created by you',
  groups4iFavoritesTitle: '4i Favorites',
  groups4iFavoritesDescription:
    'View series from groups created and updated by 4intelligence',
  groupsSearchIndicatorsTitleText: 'Search indicators',
  groupsSearchIndicatorsDescriptionText:
    'Browse our database, explore thousands of variables and create your own groups of series',
  groupsSearchIndicatorsButtonText: 'Search',
  groupsSeriesGroupsTitleText: 'Series Groups',
  groupsSeriesGroupsDescriptionText:
    'View series from one of your groups or from a group created and updated by 4intelligence.',
  groupsSeriesGroupsButtonPlaceholder: 'Search group',
  groupsSeriesGroupsButtonText: 'Create new group',
  groupsSeriesGroupsPreDefinedText: '4i favorites',
  groupsSeriesGroupsMyGroupsText: 'My favorites',
  groupSeriesEditRequestFailedTitle: 'Something went wrong',
  groupSeriesEditRequestFailedDescription:
    'Unfortunately, we were not able to edit your group. Please, try again. If the problem persists, contact our support.',
  groupSeriesCreateRequestFailedTitle: 'Something went wrong',
  groupSeriesCreateRequestFailedDescription:
    'Unfortunately, we were not able to create your group. Please, try again. If the problem persists, contact our support.',
  groupSeriesErrorMessageInput3Caracteres:
    'Search must be at least 3 characters.',
  groupSeriesErrorMessageInputMax30Caracteres:
    'Search must be at most 30 characters.',
  groupSeriesErrorRequestLoadMyGroups: 'Unable to load your favorites groups.',
  groupSeriesErrorRequestLoadPreDefinedGroups:
    'Unable to load 4i favorites groups.',
  groupSeriesErrorSearchGroupFail: `We couldn't find any group containing`,
  groupSeriesErrorNoGroupFound: 'No groups found, start by creating one.',
  groupsSeriesPaginationGroupsText: 'groups',
  groupPremiumTitle: 'Groups Premium',
  groupPremiumDescription: 'Indicators from group premium',
  groupPremiumError: 'No indicator was found for this group.',
  createdGroupByMe: 'Created by me',
};

export default groupsENTranslate;
