import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  @media (min-width: 1450px) {
    justify-content: center;
  }

  .containerLinear {
    border: none !important;
    padding: 0 !important;
  }
`;

export const IndicatorsContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 63rem;
  max-width: 63.125rem;

  div h2 {
    color: ${({ theme }) => theme.colors.gray6};
    font-family: Inter;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  div p {
    color: ${({ theme }) => theme.colors.gray5};
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
`;

export const ContainerWithoutIndicators = styled.div`
  width: 100%;
  height: 37.188rem;
  padding: 1.5rem 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ListIndicatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;
