import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30rem;
  padding: 2rem 2rem !important;
`;

export const ContentButtons = styled.div`
  margin-top: 3rem;
  width: calc(100% + 4rem);
  background: ${({ theme }) => theme.colors.gray0};
  border-top: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 0 0 8px 8px;

  > div {
    padding: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5rem;
  }
`;
