import styled from 'styled-components';

type IndicatorForAnalyzeProps = {
  isActive?: boolean;
};

export const IndicatorForAnalyzeContainer = styled.button<IndicatorForAnalyzeProps>`
  display: flex;
  min-width: 200px;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;

  text-align: left;

  border-radius: 0.5rem;
  border: 1px solid
    ${({ theme, isActive }) =>
      isActive ? theme.colors.primary : theme.colors.gray2};
  background: ${({ theme }) => theme.colors.white};

  cursor: pointer;

  &:hover:not(:disabled) {
    span {
      color: ${({ theme }) => theme.colors.gray8};
    }
  }

  &:disabled {
    cursor: not-allowed;
  }

  .customTooltipTheme {
    max-width: 250px !important;
  }
`;

export const TitleIndicator = styled.div`
  flex: 1 0 0;
  align-self: stretch;

  span {
    color: ${({ theme }) => theme.colors.gray6};
    font-family: Inter;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
`;
