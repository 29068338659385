export const workspaceProjectionsExplanatoryVariablesENTranslate = {
  workspaceProjectionsExplanatoryVariablesTitle:
    'Projection of Explanatory Variables',
  workspaceProjectionsExplanatoryVariablesVariable: 'Variable',
  workspaceProjectionsExplanatoryNoVariables:
    'No explanatory variable to be displayed.',
  workspaceProjectionsExplanatoryVariablesMonthly: 'Monthly',
  workspaceProjectionsExplanatoryVariablesQuarterly: 'Quarterly',
  workspaceProjectionsExplanatoryVariablesVariationOriginal:
    'Variation (compared to the previous period)',
  workspaceProjectionsExplanatoryVariablesVariationMonthlyQuarterly:
    'Variation (compared to the same period in the previous year)',
  workspaceProjectionsExplanatoryVariablesVariationAnnual:
    'Variation (compared to the previous year)',
  workspaceProjectionsExplanatoryVariableTooltipInfo:
    'The projections of the explanatory variables are not affected by inflation. Inflation is calculated separately, directly based on the results of the projections.',
};
