import React from 'react';

import { CrownSimple, FolderOpen } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { CategoriesTitle, TitleContainer } from './styles';

export const IndicatorsGroupPremium: React.FC = () => {
  const { t: translate } = useTranslation();

  const { pathname } = useLocation();

  return (
    <TitleContainer>
      <CategoriesTitle
        isActive={pathname.includes('indicators')}
        style={{ marginTop: '0.5rem' }}
      >
        <FolderOpen size="1rem" />
        <Link
          to="/feature-store/indicators"
          data-testid="navigate-to-indicators"
        >
          <h5 data-testid="title-indicators">
            {translate('categoriesBoxSidebarTitle')}
          </h5>
        </Link>
      </CategoriesTitle>

      <CategoriesTitle style={{ marginTop: '0.5rem' }}>
        <h5>/</h5>
      </CategoriesTitle>

      <CategoriesTitle
        isActive={pathname.includes('premium')}
        style={{ marginTop: '0.5rem' }}
      >
        <CrownSimple size="1rem" />
        <Link to="/feature-store/premium" data-testid="navigate-to-premium">
          <h5 data-testid="title-premium">PREMIUM</h5>
        </Link>
      </CategoriesTitle>
    </TitleContainer>
  );
};
