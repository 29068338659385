import React, { memo, useEffect, useState } from 'react';

import { User } from 'phosphor-react';
import { useSelector } from 'react-redux';
import { format, subMinutes } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { ReactComponent as AIChatIcon } from 'src/assets/ai-chat-icon.svg';
import { RootState } from 'src/redux/store';
import { IndicatorCard } from 'src/feature-store/components/IndicatorCard';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import api from 'src/feature-store/service/api';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import QuestionLimitImg from 'src/assets/question-limit.svg';

import {
  Container,
  Icon,
  Content,
  TextContent,
  IndicatorsContainer,
  LimitErrorContainer,
} from './styles';
import { IndicatorProps, ResponseProps } from './types';

export const Response: React.FC<ResponseProps> = memo(
  ({
    id = '',
    question = '',
    answer = '',
    indicators = '',
    dateTime = '',
    answerLoading = false,
    answerErrorCode,
    loadingCard = false,
  }) => {
    const [indicatorIndex, setIndicatorIndex] = useState(0);
    const [indicatorsInfo, setIndicatorsInfo] = useState<IndicatorProps[]>([]);

    const { language } = useSelector((state: RootState) => state.auth.user);
    const { t: translate } = useTranslation();

    const dateTimeFormat =
      language === 'pt-br' ? 'dd/MM/yyyy, HH:mm' : 'MM/dd/yyyy, HH:mm';

    const {
      data: dataIndicatorsInfo,
      isLoading,
      isFetching,
      isError,
    } = useQuery<IndicatorProps>(
      ['data indicators info', indicators?.[indicatorIndex]],
      async () => {
        const { data } = await api.get(
          `/indicators/${indicators?.[indicatorIndex]}`,
        );

        return data;
      },
      {
        staleTime: 1000 * 60 * 20,
        enabled: !!indicators?.length && indicatorIndex < indicators.length,
      },
    );

    useEffect(() => {
      if (!isLoading && !isFetching && (dataIndicatorsInfo || isError)) {
        if (!isError && dataIndicatorsInfo?.is_active)
          setIndicatorsInfo([...indicatorsInfo, dataIndicatorsInfo]);

        setIndicatorIndex(indicatorIndex + 1);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataIndicatorsInfo, isLoading, isFetching, isError]);

    if (loadingCard) {
      return (
        <Container
          className="ai-chat-container"
          data-testid="ai-chat-response-loading"
        >
          <Content>
            <Icon>
              <ContainerSkeleton withLoading={false} />
            </Icon>
            <TextContent>
              <ContainerSkeleton
                withLoading={false}
                style={{ width: '100%', height: '1.5rem' }}
              />
              <ContainerSkeleton
                withLoading={false}
                style={{ width: '10rem', height: '1rem', marginTop: '0.25rem' }}
              />
            </TextContent>
          </Content>

          <Content>
            <ContainerSkeleton
              withLoading={false}
              style={{ width: '2rem', height: '2rem' }}
            />

            <TextContent>
              <ContainerSkeleton
                withLoading={false}
                style={{ width: '100%', height: '1.5rem' }}
              />
              <ContainerSkeleton
                withLoading={false}
                style={{
                  width: '20rem',
                  height: '1.5rem',
                  marginTop: '0.25rem',
                }}
              />
            </TextContent>
          </Content>
        </Container>
      );
    }

    return (
      <Container
        className="ai-chat-container"
        data-testid={`ai-chat-response-${id}`}
      >
        <Content>
          <Icon>
            <User weight="bold" />
          </Icon>
          <TextContent>
            <h3 data-testid={`question-${id}`}>{question}</h3>
            <p data-testid={`date-time-${id}`}>
              {dateTime &&
                format(subMinutes(new Date(dateTime), 180), dateTimeFormat)}
            </p>
          </TextContent>
        </Content>

        <Content>
          {answerLoading ? (
            <ContainerSkeleton
              style={{ height: '15rem', width: '100%' }}
              data-testid="answer-loading"
            />
          ) : answerErrorCode && answerErrorCode !== 429 ? (
            // eslint-disable-next-line react/jsx-indent
            <TextContent>
              <ContainerMaintenance
                content="chat error"
                text={translate('aiChatTitleError')}
                description={translate('aiChatDescriptionError')}
                data-testid="answer-error"
              />
            </TextContent>
          ) : answerErrorCode ? (
            <LimitErrorContainer>
              <img src={QuestionLimitImg} alt="Answer limit representation" />

              <h2>{translate('aiChatQuestionLimitTitle')}</h2>

              <p>{translate('aiChatQuestionLimitDescription')}</p>
            </LimitErrorContainer>
          ) : (
            <>
              <AIChatIcon width="2rem" height="2rem" />

              <TextContent>
                <h3 style={{ fontWeight: '400' }} data-testid={`answer-${id}`}>
                  {answer ===
                    translate('aiChatNoDataResponse', { lng: 'en-us' }) &&
                  language === 'pt-br'
                    ? translate('aiChatNoDataResponse')
                    : answer}
                </h3>

                {!!indicators?.length && (
                  <IndicatorsContainer>
                    {!!indicatorsInfo.length &&
                      indicatorsInfo.map((indicator) => (
                        <IndicatorCard
                          key={indicator.indicator_code + id}
                          indicator={indicator}
                          openIndicatorInNewTab
                        />
                      ))}

                    {indicatorIndex < indicators.length && (
                      <IndicatorCard loading />
                    )}
                  </IndicatorsContainer>
                )}
              </TextContent>
            </>
          )}
        </Content>
      </Container>
    );
  },
);
