import React, { useState } from 'react';

import { CaretDown, CaretUp } from 'phosphor-react';
import { useTranslation } from 'react-i18next';

import {
  ButtonClean,
  MenuContainerStyled,
  MenuOptionAdjusted,
  ModelIdContent,
  Overlay,
} from '../ModelIdMenu/styles';

type Props = {
  projectId: string;
  onClick: (value: 'ai-selection' | 'user-selection') => void;
  value: string | null;
  isDisabled: boolean;
};

export const ProjectModelIdMenu: React.FC<Props> = ({
  projectId,
  onClick,
  value,
  isDisabled,
}) => {
  const [visible, setVisible] = useState(false);

  const { t: translate } = useTranslation();

  function handleSelect(modelIdAux: 'ai-selection' | 'user-selection') {
    onClick(modelIdAux);
    setVisible(false);
  }

  function handleOpenOrCloseMenu() {
    setVisible((state) => !state);
  }

  const modelIdAux = isDisabled
    ? `${translate('createWorkspaceSelect')}...`
    : value === 'ai-selection'
    ? translate('createWorkspaceAISelection')
    : value === 'user-selection'
    ? translate('createWorkspaceUserSelection')
    : `${translate('createWorkspaceOthers')}`;

  return (
    <ModelIdContent>
      <ButtonClean
        type="button"
        onClick={handleOpenOrCloseMenu}
        data-testid={`model-id-menu-project-${projectId}-button-open-close`}
        disabled={isDisabled}
        isProject
      >
        <div data-testid={`model-id-menu-project-${projectId}-value`}>
          {modelIdAux}
        </div>

        {visible ? (
          <CaretUp weight="bold" size="1rem" />
        ) : (
          <CaretDown weight="bold" size="1rem" />
        )}
      </ButtonClean>
      {visible && (
        <MenuContainerStyled visible>
          <Overlay
            onClick={handleOpenOrCloseMenu}
            data-testid={`model-id-menu-project-${projectId}-overlay`}
          />
          <MenuOptionAdjusted
            type="button"
            data-testid={`model-id-menu-project-${projectId}-button-ai-selection`}
            onClick={() => handleSelect('ai-selection')}
            position="middle"
            selected={modelIdAux === translate('createWorkspaceAISelection')}
          >
            <p>{translate('createWorkspaceAISelection')}</p>
          </MenuOptionAdjusted>
          <MenuOptionAdjusted
            type="button"
            data-testid={`model-id-menu-project-${projectId}-button-user-selection`}
            onClick={() => handleSelect('user-selection')}
            position="middle"
            selected={modelIdAux === translate('createWorkspaceUserSelection')}
          >
            <p>{translate('createWorkspaceUserSelection')}</p>
          </MenuOptionAdjusted>
        </MenuContainerStyled>
      )}
    </ModelIdContent>
  );
};
