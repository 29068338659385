import React from 'react';

import { Button } from 'src/components/Button';
import { CaretLeft, CaretRight } from 'phosphor-react';

import { StepFooterProps } from './types';
import { Circle, CirclesContainer, Container } from './styles';

export const StepFooter: React.FC<StepFooterProps> = ({
  stepQtty,
  selectedStep,
  backStep,
  nextStep,
  discardButton,
}) => (
  <Container>
    <Button
      buttonType="naked"
      icon={<CaretLeft />}
      onClick={backStep.onClick}
      disabled={backStep.disabled}
      data-testid="button-back-step"
    >
      {backStep.text}
    </Button>

    <CirclesContainer>
      {Array.from({ length: stepQtty }, (_, index) => index + 1).map(
        (value) => (
          <Circle
            key={value}
            selected={value === selectedStep}
            data-testid={`circle-${value}`}
          />
        ),
      )}
    </CirclesContainer>

    <div>
      {discardButton && (
        <Button
          buttonType="naked"
          iconInvert
          onClick={discardButton.onClick}
          disabled={discardButton.disabled}
          data-testid="button-discard"
          style={{ marginRight: '1.5rem' }}
        >
          {discardButton.text}
        </Button>
      )}

      <Button
        buttonType="primary"
        icon={!nextStep.removeIcon ? <CaretRight /> : undefined}
        iconInvert
        onClick={nextStep.onClick}
        disabled={nextStep.disabled}
        data-testid="button-next-step"
      >
        {nextStep.text}
      </Button>
    </div>
  </Container>
);
