import React from 'react';

import parseHTML from 'html-react-parser';
import { ArrowRight, CheckCircle, DownloadSimple } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';

import { ButtonsContent, Container, RequirementRow } from './styles';

interface RequirementsModalProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
}

export const RequirementsModal: React.FC<RequirementsModalProps> = ({
  visible,
  setVisible,
}) => {
  const { t: translate } = useTranslation();

  return (
    <Modal visible={visible} setVisible={setVisible} style={{ padding: 0 }}>
      <Container>
        <div>
          <h4>{translate('claasRequirementsTitle')}</h4>
          <p>{translate('claasRequirementsDescription')}</p>
          <div>
            <RequirementRow>
              <div>
                <CheckCircle size="1rem" weight="fill" />
              </div>
              <p>{parseHTML(translate('claasRequirementsFirsRow'))}</p>
            </RequirementRow>
            <RequirementRow>
              <div>
                <CheckCircle size="1rem" weight="fill" />
              </div>
              <p>{translate('claasRequirementsDatasetSize')}</p>
            </RequirementRow>
            <RequirementRow>
              <div>
                <CheckCircle size="1rem" weight="fill" />
              </div>
              <p>{translate('claasRequirementsRowsVsColumns')}</p>
            </RequirementRow>
            <RequirementRow>
              <div>
                <CheckCircle size="1rem" weight="fill" />
              </div>
              <p>{parseHTML(translate('claasRequirementsColumnsAndLines'))}</p>
            </RequirementRow>
            <RequirementRow>
              <div>
                <CheckCircle size="1rem" weight="fill" />
              </div>
              <p>{parseHTML(translate('claasRequirementsTargetVariable'))}</p>
            </RequirementRow>
            <RequirementRow subItem>
              <div>
                <ArrowRight size="1rem" weight="fill" />
              </div>
              <p>{translate('claasRequirementsTargetInfoZero')}</p>
            </RequirementRow>
            <RequirementRow subItem>
              <div>
                <ArrowRight size="1rem" weight="fill" />
              </div>
              <p>{translate('claasRequirementsTargetInfoOne')}</p>
            </RequirementRow>
            <RequirementRow subItem>
              <div>
                <ArrowRight size="1rem" weight="fill" />
              </div>
              <p>{translate('claasRequirementsTargetInfoTwo')}</p>
            </RequirementRow>
            <RequirementRow subItem>
              <div>
                <ArrowRight size="1rem" weight="fill" />
              </div>
              <p>{translate('claasRequirementsTargetInfoThree')}</p>
            </RequirementRow>
            <RequirementRow>
              <div>
                <CheckCircle size="1rem" weight="fill" />
              </div>
              <p>
                {parseHTML(translate('claasRequirementsSuggestedPractices'))}
              </p>
            </RequirementRow>
            <RequirementRow subItem>
              <div>
                <ArrowRight size="1rem" weight="fill" />
              </div>
              <p>{translate('claasRequirementsPracticesInfoOne')}</p>
            </RequirementRow>
            <RequirementRow subItem>
              <div>
                <ArrowRight size="1rem" weight="fill" />
              </div>
              <p>{translate('claasRequirementsPracticesInfoTwo')}</p>
            </RequirementRow>
          </div>
        </div>
      </Container>
      <ButtonsContent>
        <a
          href="https://storage.googleapis.com/bkt-prod-4casthub/datasets/titanic_example.xlsx"
          data-cy="download-example"
          data-testid="download-example"
        >
          <DownloadSimple size="1.125rem" />
          {translate('claasRequirementsDownload')}
        </a>
        <Button
          buttonType="primary"
          onClick={() => setVisible(false)}
          data-testid="button-ok"
          data-cy="button-ok"
        >
          Ok
        </Button>
      </ButtonsContent>
    </Modal>
  );
};
