import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { Button } from 'src/components/Button';
import { Input } from 'src/components/Input';
import { Modal } from 'src/components/Modal';
import { FailedModal } from 'src/components/Modal/Failed';
import { ModalLoading } from 'src/components/Modal/Loading';
import api from 'src/feature-store/service/api';
import { queryClient } from 'src/service/queryClient';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import { Select } from 'src/components/Select';
import { Loading } from 'src/components/Loading';
import { ModalIcons } from 'src/components/Modal/ModalIcons';

import { TextArea } from '../../../components/TextArea';
import {
  ContentInputsModal,
  ContentModal,
  IconContainer,
  IconLabelContainer,
} from './styles';
import { DataIndicatorsPremium } from '../..';

interface GroupPremiumProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  groupEditInfo: DataIndicatorsPremium;
}

interface EditGroupErrorProps {
  title?: string;
  description?: string;
}

interface FormEditGroupPremium {
  access_type: string;
  icon_url: string;
  nameEN: string;
  namePT: string;
  descriptionEN: string;
  descriptionPT: string;
}

const editGroupPremium = Yup.object().shape({
  icon_url: Yup.string().required('createGroupModalRequiredField'),
  nameEN: Yup.string().trim().required('accessRequiredField'),
  namePT: Yup.string().trim().required('accessRequiredField'),
  descriptionEN: Yup.string().trim().required('accessRequiredField'),
  descriptionPT: Yup.string().trim().required('accessRequiredField'),
});

export const EditGroupPremium: React.FC<GroupPremiumProps> = ({
  visible,
  setVisible,
  groupEditInfo,
}) => {
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<FormEditGroupPremium>({
    resolver: yupResolver(editGroupPremium),
    defaultValues: groupEditInfo
      ? {
          access_type: groupEditInfo.access_type,
          icon_url: groupEditInfo.icon_url,
          namePT: groupEditInfo.name['pt-br'],
          nameEN: groupEditInfo.name['en-us'],
          descriptionPT: groupEditInfo.description['pt-br'],
          descriptionEN: groupEditInfo.description['en-us'],
        }
      : undefined,
  });
  const [editGroupError, setEditGroupError] = useState<EditGroupErrorProps>();
  const [failedModalVisible, setFailedModalVisible] = useState<boolean>(false);
  const [responseLoading, setResponseLoading] = useState<boolean>(false);
  const [showModalIcon, setShowModalIcon] = useState<boolean>(false);
  const [, setIcon] = useState<string | null>(null);

  const { t: translate } = useTranslation();

  const handleEditGroupPremium = async () => {
    const form = getValues();
    setResponseLoading(true);

    try {
      await api.put(`/access-groups/${groupEditInfo.id}`, {
        access_type: form.access_type,
        icon_url: form.icon_url,
        name: {
          'en-us': form.nameEN,
          'pt-br': form.namePT,
        },
        description: {
          'en-us': form.descriptionEN,
          'pt-br': form.descriptionPT,
        },
      });

      queryClient.removeQueries(['groupsPremium']);
      setResponseLoading(false);
      setVisible(false);
    } catch (err) {
      setEditGroupError({
        title: translate('accessError'),
        description: translate('editErrorDescription'),
      });
      setResponseLoading(false);
      setFailedModalVisible(true);
    }
  };

  return (
    <>
      <Modal visible={visible} setVisible={setVisible}>
        <ContentModal data-testid="modal-edit-group-premium">
          <ContentInputsModal>
            <h4>{translate('accessEditGroup')}</h4>
            <p>{translate('accessRegisterGroupDescription')}</p>

            <IconLabelContainer>
              <p>{translate('createGroupModalGroupIcon')}</p>
            </IconLabelContainer>
            <IconContainer
              data-testid="select-icon-button"
              onClick={() => setShowModalIcon(true)}
            >
              {getValues('icon_url') ? (
                <img
                  data-testid="selected-icon"
                  src={getValues('icon_url') ?? ''}
                  alt="selected-icon"
                />
              ) : (
                <Loading
                  style={{ width: '50px', height: '50px', margin: '14px 14px' }}
                />
              )}
            </IconContainer>

            <Controller
              name="access_type"
              key="access_type"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  data-testid="access-type-select"
                  label={translate('adminAccessType')}
                  options={[
                    {
                      value: groupEditInfo.access_type,
                      label:
                        groupEditInfo.access_type === 'premium'
                          ? 'Premium'
                          : translate('privateContent'),
                    },
                  ]}
                  onChange={(select: any) => onChange(select.value)}
                  value={
                    value === 'premium'
                      ? {
                          label: 'Premium',
                          value,
                        }
                      : value === 'private'
                      ? {
                          label: translate('privateContent'),
                          value,
                        }
                      : undefined
                  }
                  placeholder={translate('selectTheAccessType')}
                  error={errors.access_type?.message}
                />
              )}
            />

            <Controller
              name="namePT"
              key="namePT"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Input
                  label={translate('accessRegisterNamePT')}
                  onChange={onChange}
                  value={value}
                  placeholder={translate('accessRegisterNamePTPlaceHolder')}
                  //@ts-expect-error typescript bugando
                  error={translate(errors.namePT?.message)}
                  data-testid="input-name-premium-pt"
                />
              )}
            />

            <Controller
              name="descriptionPT"
              key="descriptionPT"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextArea
                  label={translate('accessRegisterDescriptionPT')}
                  onChange={onChange}
                  value={value}
                  style={{ marginBottom: '32px' }}
                  placeholder={translate(
                    'accessRegisterDescriptionPTPlaceHolder',
                  )}
                  //@ts-expect-error typescript bugando
                  error={translate(errors.descriptionPT?.message)}
                  data-testid="input-description-premium-pt"
                />
              )}
            />

            <Controller
              name="nameEN"
              key="nameEN"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Input
                  label={translate('accessRegisterNameEN')}
                  onChange={onChange}
                  value={value}
                  placeholder={translate('accessRegisterNameENPlaceHolder')}
                  //@ts-expect-error typescript bugando
                  error={translate(errors.nameEN?.message)}
                  data-testid="input-name-premium-en"
                />
              )}
            />

            <Controller
              name="descriptionEN"
              key="descriptionEN"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextArea
                  label={translate('accessRegisterDescriptionEN')}
                  onChange={onChange}
                  style={{ marginBottom: '32px' }}
                  value={value}
                  placeholder={translate(
                    'accessRegisterDescriptionENPlaceHolder',
                  )}
                  //@ts-expect-error typescript bugando
                  error={translate(errors.descriptionEN?.message)}
                  data-testid="input-description-premium-en"
                />
              )}
            />
          </ContentInputsModal>
        </ContentModal>

        <ModalFooter>
          <Button
            buttonType="naked"
            onClick={() => setVisible(false)}
            data-testid="button-cancel"
            data-cy="button-cancelar"
          >
            {translate('cancel')}
          </Button>

          <Button
            buttonType="primary"
            onClick={handleSubmit(() => handleEditGroupPremium())}
            data-testid="button-continue"
            data-cy="button-continue"
          >
            {translate('accessContinue')}
          </Button>
        </ModalFooter>

        {responseLoading && (
          <ModalLoading
            data-testid="loading-edit-group-premium"
            visible={responseLoading}
          />
        )}
        {editGroupError && (
          <FailedModal
            visible={failedModalVisible}
            errorInfo={editGroupError}
            setVisible={setFailedModalVisible}
          />
        )}
      </Modal>

      <Controller
        name="icon_url"
        key="icon_url"
        control={control}
        render={({ field: { onChange, value } }) => (
          <ModalIcons
            visible={showModalIcon}
            setIcon={setIcon}
            setVisible={setShowModalIcon}
            onChangHookForm={onChange}
            value={value}
            isAccessGroupIcons
            content={{
              title: translate('createGroupModalChooseIconTitle'),
              description: translate('createGroupModalChooseIconDescrip'),
            }}
          />
        )}
      />
    </>
  );
};
