import React, { useState } from 'react';

import { Trash, Warning, XCircle } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';
import { ModalLoading } from 'src/components/Modal/Loading';
import { apiAdminV2 } from 'src/service/apiAdminV2';
import { queryClient } from 'src/service/queryClient';
import { ModalFooter } from 'src/components/Modal/Footer/styles';

import { DeleteUserProp } from '../..';
import { Container, ContainerError, FooterModalDelete } from './styles';

type ModalDeleteUserClientProps = {
  user?: DeleteUserProp;
  visible: boolean;
  setVisible: (value: boolean) => void;
};

export const ModalDeleteUserClient: React.FC<ModalDeleteUserClientProps> = ({
  user,
  visible,
  setVisible,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const { t: translate } = useTranslation();

  const handleDeleteUserClient = async () => {
    try {
      setLoading(true);
      await apiAdminV2.delete(
        `/clients/${user?.client_id}/users/${user?.user_id}`,
      );
      setLoading(false);
      queryClient.removeQueries('list users admin painel');
      queryClient.removeQueries('customer info');
      setVisible(false);
    } catch (err) {
      setLoading(false);
      setError(true);
    }
  };

  if (loading) {
    return (
      <ModalLoading
        visible
        style={{ padding: 0, width: '280px' }}
        data-testid="container-loading"
      />
    );
  }

  if (error) {
    return (
      <Modal
        visible
        setVisible={() => setVisible(false)}
        style={{ padding: 0 }}
      >
        <ContainerError data-testid="container-error">
          <XCircle size="3rem" />
          <h4>{translate('deleteUserFailedTitleAdminModal')}</h4>
          <p>{translate('deleteUserFailedDescriptionAdminModal')}</p>
        </ContainerError>
        <FooterModalDelete>
          <Button
            buttonType="primary"
            onClick={() => setVisible(false)}
            data-testid="button-back"
            data-cy="button-back"
          >
            {translate('back')}
          </Button>
        </FooterModalDelete>
      </Modal>
    );
  }

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <Container data-testid="container-modal-delete-user">
        <Warning size="2.5rem" />
        <h2>{translate('preDefinedDeleteTitle')}</h2>
        <p>{translate('deleteUserDescriptionAdminModal')}</p>
      </Container>

      <ModalFooter>
        <Button
          buttonType="naked"
          onClick={() => setVisible(false)}
          data-testid="button-cancel"
          data-cy="button-cancel"
        >
          {translate('cancel')}
        </Button>
        <Button
          buttonType="primary"
          icon={<Trash size="1.125rem" />}
          onClick={() => handleDeleteUserClient()}
          data-testid="button-delete-modal-delete-group"
          data-cy="button-delete-modal-delete-group"
        >
          {translate('delete')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
