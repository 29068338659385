import styled from 'styled-components';

export const Container = styled.div`
  .categorizationTooltip.categorizationTooltip {
    max-width: 30rem !important;
  }

  .naked-button:not(:disabled) {
    color: ${({ theme }) => theme.colors.primary};

    :hover {
      color: ${({ theme }) => theme.colors.primaryDark};
    }
  }

  .ag-theme-quartz {
    > div {
      height: 20rem !important;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
`;

export const CategorizationActions = styled.div`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  gap: 0.5rem;
  z-index: 2;
`;

export const UploadContainer = styled.div`
  width: 100%;

  > div > div:last-of-type {
    margin-top: 0;
  }

  img {
    margin-bottom: 0 !important;
  }

  p,
  h1 {
    width: 20rem;
  }

  .selected-file {
    width: 60%;
  }
`;

export const FileButtons = styled.div`
  display: flex;
  gap: 1rem;

  margin-top: 1rem;

  z-index: 2;

  button:first-of-type:hover {
    color: ${({ theme }) => theme.colors.gray6};
  }
`;

export const TableContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 0.5rem;

  > div {
    width: 100%;

    flex: 1;

    z-index: 2001;
  }

  .ag-theme-quartz {
    z-index: 1 !important;
  }

  .ag-grid-table-container,
  .ag-root-wrapper {
    max-height: 20rem;
  }

  .ag-cell-value {
    text-align: start;

    > div {
      word-break: break-all;
      white-space: break-spaces;
      line-height: 150%;
    }
  }

  .ag-header-cell-label {
    justify-content: start !important;
  }

  .ag-cell {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
`;

export const Subtitle = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start !important;
  gap: 0.5rem !important;

  > div {
    width: 1.75rem;
    height: 1.75rem;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 100%;
    padding: 0.5rem;

    font-size: 1rem;
    font-weight: 600;
    line-height: normal;
    color: ${({ theme }) => theme.colors.primary};

    background-color: ${({ theme }) => `${theme.colors.primary}1F`};
  }

  h3 {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: normal;

    color: ${({ theme }) => theme.colors.gray6};
  }
`;

export const MarketShareToggle = styled.div`
  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    > div {
      margin: 0;

      p {
        margin-right: 0.5rem;
      }
    }
  }
`;
