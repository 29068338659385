const exportSeriesENTranslate = {
  exportSeriesErrorDownload: 'There was an error downloading file.',
  exportSeries: 'When finished, click on “Export” to export a series.',
  exportSeriesLimitExport:
    'It is not possible to export more than 150 series at the same time.',
  exportSeriesMaintenance:
    'One or more series from the set selected for export will not be available due to maintenance.',
  warningToUpgrade:
    'You do not have access to all series. <strong>Upgrade</strong> to unlock.',
};

export default exportSeriesENTranslate;
