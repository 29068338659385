export const workspacePerformanceByPeriodTableENUSTranslations = {
  workspacePerformanceByPeriodTitle: 'Performance by period',
  workspacePerformanceByPeriodDescription:
    'Analyze the performance of the <b>chosen</b> models for the workspace variables.',
  workspacePerformanceByPeriodDepVariable: 'Dependent variable',
  workspacePerformanceByPeriodSearchPlaceholder: 'Search dependent variable',
  workspacePerformanceByPeriodMetricLabel: 'Metric',
  workspacePerformanceByPeriodTooltip:
    'Each column reflects the mean performance of all models that have sufficient out-of-sample dates for the referred period.',
  workspacePerformanceByPeriodVariableTooltip: 'Variable not updated.',
  workspacePerformanceByPeriodNoResults: `We couldn't find any variable containing "SEARCH".`,
};
