import styled from 'styled-components';

export const ContainerChart = styled.div`
  > div:first-child {
    display: flex;
    justify-content: space-between;

    h4 {
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.688rem;
      color: ${({ theme }) => theme.colors.gray6};
    }
  }
`;
