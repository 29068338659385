import React, { useCallback } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'src/components/Button';
import noAccessImg from 'src/assets/no-access.svg';
import { logout } from 'src/redux/reducers/Auth';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Container } from './styles';

export const UnauthorizedSSO: React.FC = () => {
  const dispatch = useDispatch();
  const auth0 = useAuth0();

  const handleLogout = useCallback(() => {
    dispatch(logout());
    auth0.logout({ logoutParams: { returnTo: window.location.origin } });
  }, [auth0, dispatch]);

  const { t: translate } = useTranslation();

  return (
    <Container>
      <img
        src={noAccessImg}
        alt="Imagem de uma porta sendo bloqueado por faixas"
      />

      <h2> {translate('unauthorizedSso')} </h2>
      <p>{translate('unauthorizedSsoSupport')}</p>

      <Button
        buttonType="tertiary"
        data-testid="navigate-to-home-page-button"
        data-cy="navigate-to-home-page-button"
        onClick={handleLogout}
      >
        {translate('unauthorizedSsoBackButton')}
      </Button>
    </Container>
  );
};
