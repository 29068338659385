import React from 'react';

import { useTranslation } from 'react-i18next';
import { ArrowsLeftRight, Clock } from 'phosphor-react';
import parseHTML from 'html-react-parser';

import { Container, CurrentVersionContent } from './styles';
import { VersionInfoProps } from './types';
import { Button } from '../Button';

export const VersionInfo: React.FC<VersionInfoProps> = ({
  versionName,
  changeVersionDisabled = false,
  showOpenSidebarButton = true,
  handleOpenSidebar,
}) => {
  const { t: translate } = useTranslation();

  return (
    <Container>
      <CurrentVersionContent>
        <div>
          <Clock size="1.125rem" weight="bold" />
        </div>
        <p data-testid="version-viewing">
          {parseHTML(
            translate('versionInfoViewing').replace(
              'VERSION_NAME',
              versionName,
            ),
          )}
        </p>
      </CurrentVersionContent>

      {showOpenSidebarButton && (
        <Button
          buttonType="naked"
          type="button"
          onClick={handleOpenSidebar}
          data-testid="reopen-update-history-button"
          disabled={changeVersionDisabled}
          icon={<ArrowsLeftRight />}
        />
      )}
    </Container>
  );
};
