import React from 'react';

import { ArrowRight, ChartLine } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Button';
import { HCharts, HChartsSeries } from 'src/components/HCharts';

import {
  Container,
  FakeIconTypeChart,
  HeaderChart,
  ResourcePaid,
} from './styles';

const SERIES_DATA: HChartsSeries[] = [
  {
    name: 'Histórico',
    type: 'line',
    color: '#81B3FF',
    opacity: 1,
    data: [
      {
        x: 1648954800000,
        y: Math.round(Math.random() * (1000 - 500)),
      },
      {
        x: 1649041200000,
        y: Math.round(Math.random() * (1000 - 500)),
      },
      {
        x: 1649127600000,
        y: Math.round(Math.random() * (1000 - 500)),
      },
      {
        x: 1649214000000,
        y: Math.round(Math.random() * (1000 - 500)),
      },
      {
        x: 1649300400000,
        y: Math.round(Math.random() * (1000 - 500)),
      },
      {
        x: 1649386800000,
        y: Math.round(Math.random() * (1000 - 500)),
      },
      {
        x: 1649473200000,
        y: Math.round(Math.random() * (1000 - 500)),
      },
      {
        x: 1649559600000,
        y: Math.round(Math.random() * (1000 - 500)),
      },
      {
        x: 1649646000000,
        y: Math.round(Math.random() * (1000 - 500)),
      },
      {
        x: 1649732400000,
        y: Math.round(Math.random() * (1000 - 500)),
      },
      {
        x: 1649818800000,
        y: Math.round(Math.random() * (1000 - 500)),
      },
      {
        x: 1649905200000,
        y: Math.round(Math.random() * (1000 - 500)),
      },
      {
        x: 1649991600000,
        y: Math.round(Math.random() * (1000 - 500)),
      },
      {
        x: 1650078000000,
        y: Math.round(Math.random() * (1000 - 500)),
      },
      {
        x: 1650164400000,
        y: Math.round(Math.random() * (1000 - 500)),
      },
      {
        x: 1650250800000,
        y: Math.round(Math.random() * (1000 - 500)),
      },
      {
        x: 1650337200000,
        y: Math.round(Math.random() * (1000 - 500)),
      },
      {
        x: 1650423600000,
        y: Math.round(Math.random() * (1000 - 500)),
      },
      {
        x: 1650510000000,
        y: Math.round(Math.random() * (1000 - 500)),
      },
      {
        x: 1650596400000,
        y: Math.round(Math.random() * (1000 - 500)),
      },
      {
        x: 1650682800000,
        y: Math.round(Math.random() * (1000 - 500)),
      },
      {
        x: 1650769200000,
        y: Math.round(Math.random() * (1000 - 500)),
      },
      {
        x: 1650855600000,
        y: Math.round(Math.random() * (1000 - 500)),
      },
      {
        x: 1650942000000,
        y: Math.round(Math.random() * (1000 - 500)),
      },
      {
        x: 1651028400000,
        y: Math.round(Math.random() * (1000 - 500)),
      },
      {
        x: 1651114800000,
        y: 100,
      },
    ],
  },
  {
    name: 'Projeção',
    type: 'line',
    color: '#FFABD3',
    opacity: 1,
    data: [
      {
        x: 1651114800000,
        y: 100,
      },
      {
        x: 1651287600000,
        y: Math.round(Math.random() * (1000 - 500)),
      },
      {
        x: 1651374000000,
        y: Math.round(Math.random() * (1000 - 500)),
      },
      {
        x: 1651460400000,
        y: Math.round(Math.random() * (1000 - 500)),
      },
      {
        x: 1651546800000,
        y: Math.round(Math.random() * (1000 - 500)),
      },
      {
        x: 1651633200000,
        y: Math.round(Math.random() * (1000 - 500)),
      },
      {
        x: 1651719600000,
        y: Math.round(Math.random() * (1000 - 500)),
      },
      {
        x: 1651806000000,
        y: Math.round(Math.random() * (1000 - 500)),
      },
      {
        x: 1651892400000,
        y: Math.round(Math.random() * (1000 - 500)),
      },
    ],
  },
];

export const BlurChart: React.FC = () => {
  const { t: translate } = useTranslation();

  return (
    <Container
      data-cy="graph-blur-container"
      data-testid="graph-blur-container"
    >
      <div style={{ filter: 'blur(4px)' }}>
        <HeaderChart>
          <h4>This a fake random data chart</h4>

          <div>
            <FakeIconTypeChart>
              <ChartLine size="1rem" />
            </FakeIconTypeChart>
            <FakeIconTypeChart>
              <ChartLine size="1rem" />
            </FakeIconTypeChart>
            <FakeIconTypeChart>
              <ChartLine size="1rem" />
            </FakeIconTypeChart>
          </div>
        </HeaderChart>
        <HCharts
          series={SERIES_DATA}
          dataCy="blur-chart-resource-paid"
          useStockChart
          options={{
            tooltip: {
              enabled: false,
            },
            navigator: {
              enabled: true,
              handles: {
                enabled: false,
              },
            },
            chart: {
              zooming: {
                type: undefined,
              },
              animation: false,
            },
            plotOptions: {
              series: {
                enableMouseTracking: false,
                events: {
                  legendItemClick(e) {
                    e.preventDefault();
                  },
                },
                states: {
                  hover: { enabled: false },
                },
              },
            },
          }}
        />
      </div>

      <ResourcePaid
        data-cy="resource-paid-warning"
        data-testid="resource-paid-warning"
        className="container-resource-paid-warning"
      >
        <p>{translate('resourcePaid')}</p>
        <strong>
          <p>{translate('upgradeToAccess')}</p>
        </strong>
        <Button
          buttonType="primary"
          icon={<ArrowRight size="1.125rem" />}
          iconInvert
          data-cy="button-go-site-upgrade-plan"
          data-testid="button-go-site-upgrade-plan"
          onClick={() =>
            window.open(`https://4intelligence.ai/freemium/upgrade`, '_blank')
          }
        >
          {translate('contactUs')}
        </Button>
      </ResourcePaid>
    </Container>
  );
};
