import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.813rem;
  position: relative;
`;

export const ChartRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  > div:first-child {
    display: flex;
    width: 20%;
    justify-content: flex-end;
    margin-right: 0.75rem;

    p {
      text-align: right;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.375rem;
      color: ${({ theme }) => theme.colors.gray5};
      word-break: break-word;
    }
  }

  > div:last-child {
    display: flex;
    width: 80%;
    position: relative;

    > div:last-child {
      margin-left: -1.75rem;
    }
  }
`;

interface ChartProps {
  signal: 'positive' | 'negative';
  percent: number;
  orientation: 'left' | 'right';
  currentWidth: number;
}

export const ChartLine = styled.div<ChartProps>`
  position: relative;
  width: 45% !important;
  height: 0.063rem;
  display: flex;
  align-items: center;

  div {
    width: 100%;
    height: 0px;
    opacity: 0.58;
    border-top: 2px dashed ${({ theme }) => theme.colors.gray5};
  }

  svg:first-child {
    color: ${({ theme }) => theme.colors.white};
    opacity: 1;
  }

  svg {
    z-index: 3;
    position: absolute;
    right: ${({ percent, orientation }) =>
      orientation === 'left' && `${percent * 0.95 * -1}%`};
    left: ${({ percent, orientation }) =>
      orientation === 'right' && `${percent * 0.95}%`};

    ${({ signal }) =>
      signal === 'negative' &&
      css`
        transform: rotate(180deg);
      `};
    color: ${({ theme, signal }) =>
      signal === 'positive' ? theme.colors.primary : theme.colors.secondary};
    opacity: 1;
    z-index: 2;
  }

  .chart-tooltip {
    /* right: ${({ percent, orientation, currentWidth }) =>
      orientation === 'left' &&
      `${(percent * 0.95 - ((32 - 210) / currentWidth) * 100) * -1}%`};
    left: ${({ percent, orientation, currentWidth }) =>
      orientation === 'right' &&
      `${percent * 0.95 + ((32 + 10) / currentWidth) * 100}%`}; */
    bottom: 1.563rem;
    right: ${({ percent, orientation, currentWidth }) =>
      orientation === 'left' &&
      `${(percent * 0.95 - ((32 - 120) / currentWidth) * 100) * -1}%`};
    left: ${({ percent, orientation, currentWidth }) =>
      orientation === 'right' &&
      `${percent * 0.95 + ((32 - 120) / currentWidth) * 100}%`};
  }
`;

export const ChartDivisor = styled.div`
  position: absolute;
  left: 55%;
  width: 0.75rem;
  height: 100%;
  z-index: 1;
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
`;

export const ChartSubtitle = styled.div`
  width: 80% !important;
  /* display: flex; */
  /* justify-content: center; */
  align-items: center;
  /* margin-top: 16px; */
  margin-top: 1.625rem;
  gap: 1.5rem;
  margin-left: 15%;
  /* margin-left: 85px; */

  /* @media (max-width: 1600px) {
    margin-left: 67px;
  }

  @media (max-width: 1366px) {
    margin-left: 57px;
  } */

  z-index: 3;
  bottom: 1.5rem;

  /* > div { */
  /* width: 500px; */
  display: flex;
  justify-content: space-between !important;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;

    p {
      font-weight: 500;
      font-size: 1rem;
      line-height: 150%;
      color: ${({ theme }) => theme.colors.gray4};
      text-align: center !important;
    }

    > div {
      svg {
        color: ${({ theme }) => theme.colors.gray4};
      }
    }
  }
  /* } */
`;
