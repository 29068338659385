import styled from 'styled-components';

export const ContainerFineTuning = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 0;
  height: 19.25rem;

  img {
    width: 9.625rem;
    height: 8.75rem;
    margin-bottom: 2rem;
  }

  h3 {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: center;
    color: ${({ theme }) => theme.colors.gray6};
    margin-bottom: 1rem;
  }

  p {
    width: 80%;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;

    text-align: center;
    color: ${({ theme }) => theme.colors.gray5};
  }
`;
