import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { HCharts, HChartsOptions, HChartsSeries } from 'src/components/HCharts';
import { RootState } from 'src/redux/store';
import { formatCompactNotation } from 'src/utils/numbers/formatCompactNotation';
import { getChartColor } from 'src/utils/colors/getChartColor';

interface ResidualsChartProps {
  residuals: {
    x: string[];
    y: number[];
    type: string;
  };
}

export const ResidualsChart: React.FC<ResidualsChartProps> = ({
  residuals,
}) => {
  const { project } = useSelector((state: RootState) => state);

  const { t: translate } = useTranslation();

  const series: HChartsSeries = useMemo(
    () => ({
      name: translate('modelSpecSeriesResiduals'),
      type: 'line',
      marker: {
        symbol: 'circle',
      },
      color: getChartColor(0),
      showInLegend: false,
      data: residuals.x.map((date, dateIndex) => ({
        x: new Date(`${date}T00:00`).getTime(),
        y: residuals.y[dateIndex],
        custom: {
          value: formatCompactNotation(residuals.y[dateIndex]),
        },
      })),
    }),
    [residuals, translate],
  );

  const options: HChartsOptions = useMemo(
    () => ({
      chart: {
        height: 300,
      },
      yAxis: {
        allowDecimals: true,
      },
      xAxis: {
        title: {
          text: translate('time'),
        },
      },
      tooltip: {
        pointFormat:
          `<tr><td><b>${translate('date')}:</b> </td>` +
          `<td style="text-align: right">{point.x: ${
            project.selectedY?.info?.frequency === 'annual' ? '%Y' : ' %d/%m/%Y'
          }}</td></tr>` +
          `<tr><td><b>${translate('value')}:</b> </td>` +
          '<td style="text-align: right">{point.custom.value}</td></tr>',
      },
    }),
    [project.selectedY?.info?.frequency, translate],
  );

  return (
    <HCharts
      series={[series]}
      options={options}
      dataCy="chart-check-residual-1"
      resizeWidthWithSidebar
      sleepTimerToResize={false}
      animationToResize
      removeWidthToResize={20 * 16 + 28}
    />
  );
};
