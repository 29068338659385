import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Card } from 'src/components/Card';
import { Head } from 'src/components/Head';
import { RootState } from 'src/redux/store';

import { Container } from '../../styles';
import { ForecastDispersion } from '../components/ForecastDispersion';

export const FCModelDispersion: React.FC = () => {
  const project = useSelector((state: RootState) => state.project);

  const { t: translate } = useTranslation();

  const quantityModels = useMemo(
    () =>
      project?.model?.model_list.find(
        (model) => model.type === 'Forecast Combination',
      )?.quantity ?? 1,
    [project],
  );

  return (
    <Container>
      <Head title={translate('forecastCombinationPageTitle')} />
      <Card
        textCard={translate('forecastCombinationTitle')}
        textDescription={translate('forecastCombinationDescription')}
        className="containerLinear"
      />

      <ForecastDispersion
        model="forecast-combination"
        quantityModels={quantityModels}
        hasSelect={false}
      />
    </Container>
  );
};
