import React from 'react';

import { useTranslation } from 'react-i18next';
import { Card } from 'src/components/Card';
import { Head } from 'src/components/Head';

import { MetricsByPeriod } from './components/MetricsByPeriod';
import { ProjectionsVariation } from './components/ProjectionsVariation';
import { RealVsForecast } from './components/RealVsForecast';
import { Container } from './styles';

export const VariablePerformance: React.FC = () => {
  const { t: translate } = useTranslation();

  return (
    <Container>
      <Head title={translate('labelVariablePerformance')} />

      <div className="containerLinear page-title">
        <Card
          textCard={translate('labelVariablePerformance')}
          textDescription={translate('labelVariablePerformanceDescription')}
        />
      </div>

      <RealVsForecast />
      <ProjectionsVariation />
      <MetricsByPeriod />
    </Container>
  );
};
