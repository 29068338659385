import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';

export const useFormatDateLanguage = (): string => {
  const { language } = useSelector((state: RootState) => state.auth.user);
  let formatLanguage = '';
  if (language === 'pt-br') {
    formatLanguage = 'dd/MM/yyyy';
    return formatLanguage;
  }
  formatLanguage = 'MM/dd/yyyy';
  return formatLanguage;
};
