const AccessGroupPTBR = {
  accessRequiredField: 'Campo obrigatório',
  accessGroup: 'grupos',
  accessError: 'Erro',
  accessGroupTitle: 'Feature Store Grupo de Acesso',
  accessGroupDescription:
    'Crie, edite, delete e veja todos os Grupos da Feature Store.',
  accessGroupList: 'Lista de Grupos',
  accessGroupCreateGroup: 'Criar novo grupo',
  accessGroupError: 'Não foi possível criar o grupo.',
  accessCode: 'Código',
  accessName: 'Nome',
  accessActions: 'Ações',
  accessIndicators: 'indicadores',
  accessIndicatorsList: 'Indicadores',
  accessDescription: 'Descrição',
  accessSource: 'Fonte',
  AccessClients: 'Lista de Clientes',
  accessErrorTitle: 'Erro',
  accessNoGroupTitle:
    'Adicione o primeiro indicador neste grupo clicando no botão acima.',
  accessNoGroupDescription: 'Adicione indicadores para esse grupo premium',
  accessErrorDescription: 'Não foi possível carregar os grupos.',
  accessRegisterGroup: 'Cadastrar novo grupo',
  accessEditGroup: 'Editar grupo',
  accessRegisterGroupDescription:
    'Escreva o nome e descrição do Grupo Premium ou Privado, em português e inglês.',
  accessRegisterNamePT: 'Nome em Português (Português)',
  accessRegisterNameEN: 'Nome em Inglês (Inglês)',
  accessRegisterNamePTPlaceHolder: 'Digite o nome do grupo (Português)',
  accessRegisterNameENPlaceHolder: 'Digite o nome do grupo (Inglês)',
  accessRegisterDescriptionPTPlaceHolder:
    'Digite a descrição do grupo (Português)',
  accessRegisterDescriptionENPlaceHolder:
    'Digite a descrição do grupo (Inglês)',
  accessRegisterDescriptionPT: 'Descrição do grupo em Português (Português)',
  accessRegisterDescriptionEN: 'Descrição do grupo em Inglês (Inglês)',
  accessContinue: 'Avançar',
  accessAddIndicatorInGroupTitle: 'Inserir indicador no grupo',
  accessAddIndicatorInGroupDescription:
    'Pesquise e adicione os indicadores ao Grupo XX.',
  accessLoadIndicatorsError: 'Erro ao carregar indicadores premium.',
  accessSearchIndicator: 'Procurar Indicador',
  accessNewUserTitle: 'Adicionar novo usuário ao grupo',
  accessNewUserDescription:
    'Adicione os clientes com acesso permitido ao Grupo Premium.',
  accessNewUser: 'Clientes',
  accessInputPlaceHolder: 'Digite aqui o e-mail do cliente',
  accessAddIndicators: 'Adicionar Indicadores',
  accessAddUser: 'Adicionar Usuário',
  accessErrorIndicators:
    'Não foi possível carregar os indicadores deste grupo.',
  accessErrorUsers: 'Não foi possível carregar os usuários deste grupo.',
  accessDelete: 'Deletar',
  accessUnableToFindIndicator: 'Esse grupo não possui nenhum indicador.',
  accessUnableToFindUser: 'Esse grupo não possui nenhum usuário.',
  accessUsers: 'usuários',
  deleteUserTitle: 'Você tem certeza?',
  deleteUserDescription:
    'Isso excluirá permanentemente este usuário deste grupo.',
  deleteUserDescriptionPlural:
    'Isso excluirá permanentemente estes usuários deste grupo.',
  deleteGroupTitle: 'Você tem certeza?',
  deleteGroupDescription: 'Isso excluirá permanentemente este grupo.',
  deleteUserFailedTitle: 'Falha ao excluir usuário.',
  deleteUserFailedDescription:
    'Não foi possível excluir o usuário do grupo, por favor tente novamente.',
  deleteIndicatorTitle: 'Você tem certeza?',
  deleteIndicatorDescription:
    'Isso excluirá permanentemente este indicador deste grupo.',
  deleteIndicatorDescriptionPlural:
    'Isso excluirá permanentemente estes indicadores deste grupo.',
  deleteIndicatorFailedTitle: 'Falha ao excluir indicador.',
  deleteIndicatorFailedDescription:
    'Não foi possível excluir o indicador do grupo, por favor tente novamente.',
  deleteGroupFailedTitle: 'Falha ao excluir grupo.',
  deleteGroupFailedDescription:
    'Não foi possível excluir o grupo, por favor tente novamente.',
  editErrorDescription: 'Não foi possível editar esse grupo.',
};

export default AccessGroupPTBR;
