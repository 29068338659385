import styled, { css } from 'styled-components';

export const ContainerWithoutGroups = styled.div`
  width: 100%;
  height: 37.188rem;
  padding: 1.5rem 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 5rem;
  margin-right: 5rem;

  table tr td {
    font-size: 0.75rem !important;
    padding: 0.3125rem 0.3125rem;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;

  transition: all 0.2s;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-self: stretch;
  justify-content: space-between;

  padding-top: 16px;
  gap: 12px;

  height: 95px;
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;

  gap: 1rem;

  h4 {
    color: ${({ theme }) => theme.colors.gray6};

    font-family: Inter;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .customTooltipTheme {
    max-width: 250px !important;
  }
`;

export const HeaderSkeletonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;

  gap: 2rem;
`;

export const Description = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;

  margin-top: 1rem;

  color: ${({ theme }) => theme.colors.gray5};
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

interface ButtonIconProps {
  disabled?: boolean;
}

export const ButtonIcon = styled.button<ButtonIconProps>`
  float: right;
  ${({ disabled }) =>
    !disabled
      ? css`
          color: ${({ theme }) => theme.colors.gray5};
          transition: 0.2s color;
          &:hover {
            color: ${({ theme }) => theme.colors.gray6};
          }
        `
      : css`
          color: ${({ theme }) => theme.colors.gray4};
          cursor: not-allowed;
        `};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

interface MenuOverlayProps {
  visible: boolean;
}

export const MenuOverlay = styled.div<MenuOverlayProps>`
  width: 100vw;
  height: 100vh;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
`;

export const ContentTable = styled.div`
  overflow-y: hidden;
  overflow-x: auto;
`;

export const RoundedButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  gap: 0.5rem;
`;

export const ExportButton = styled.button`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;

  gap: 0.5rem;
  padding: 0.5rem 0.75rem;
  height: 2.375rem;

  border-radius: 624.9375rem;
  border: 1px solid ${({ theme }) => theme.colors.gray2};

  color: ${({ theme }) => theme.colors.gray5};

  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  svg {
    width: 1rem;
    height: 1rem;

    color: ${({ theme }) => theme.colors.gray4};
  }

  :disabled {
    cursor: not-allowed;
  }

  :hover {
    background: ${({ theme }) => theme.colors.gray0};
    color: ${({ theme }) => theme.colors.gray6};

    svg {
      color: ${({ theme }) => theme.colors.gray6};
    }
  }
`;

export const ContentButtonExport = styled.div`
  position: relative;
`;
