import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  svg {
    width: 1rem;
    height: 1rem;
  }

  p {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.375rem;

    flex: 1;
  }
`;
