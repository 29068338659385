import React, { Fragment, useContext, useEffect, useState } from 'react';

import { ArrowsOutSimple } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery, useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { ContainerMaintenance as Maintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { PaginationV2 } from 'src/components/Pagination/V2';
import { FavoriteStatus } from 'src/feature-store/components/FavoriteStatus';
import { FeatureStoreSidebarContext } from 'src/feature-store/Contexts/NavigationContext';
import api from 'src/feature-store/service/api';

import { CreateGroupModal } from '../../Groups/components/CreateGroupModal';
import { MyGroupsProps, PreDefinedGroupsProps } from '../../Groups/types';
import { GroupSeries } from '../../GroupView/types';
import { Favorite } from '../Favorite';
import { SerieInfo } from '../SerieInfo';
import { fakeSerie } from './fakeSerie';
import {
  FavoriteOption,
  Favorites,
  HeaderFavorites,
  ListFavoritesContainer,
  MaintenanceContainer,
  NavigateToFavPage,
  PaginateContainer,
  SeriesFavoriteContainer,
  Slash,
} from './styles';

export const ListFavorites: React.FC = () => {
  const QUANTITY_FAVORITES_PAGE = 15;
  const QUANTITY_SERIES_PAGE = 5;

  const [page, setPage] = useState(1);
  const [summary, setSummary] = useState('');
  const [createFavoriteModalIsVisible, setCreateFavoriteModalIsVisible] =
    useState(false);

  const {
    favoriteTypeHome,
    setterFavoriteTypeHome,
    setterFavoriteType,
    idUserFavoriteViewingHome,
    idHighlightsFavoriteViewingHome,
    setterIdUserFavoriteViewingHome,
    setterIdHighlightsFavoriteViewingHome,
  } = useContext(FeatureStoreSidebarContext);

  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  const {
    data: userFavorites,
    isFetching: isUserFavoriteFetching,
    isLoading: isUserFavoriteLoading,
    isError: isUserFavoriteErrored,
    fetchNextPage: fetchNextPageUserFavorites,
    hasNextPage: isUserFavoriteHasNextPage,
    isFetchingNextPage: isUserFavoriteFetchingNextPage,
  } = useInfiniteQuery(
    ['user favorites'],
    async ({ pageParam = 0 }) => {
      const { data } = await api.get<MyGroupsProps>(
        `/groups?skip=${
          pageParam * QUANTITY_FAVORITES_PAGE
        }&limit=${QUANTITY_FAVORITES_PAGE}`,
      );

      if (data.total === 0) {
        setterFavoriteTypeHome('highlights');
      }

      idUserFavoriteViewingHome === '' &&
        setterIdUserFavoriteViewingHome(data?.data[0].id);

      return data;
    },
    {
      staleTime: 1000 * 60,
      getNextPageParam: (lastPage, pages) => {
        if (pages.length * QUANTITY_FAVORITES_PAGE < lastPage.total) {
          return pages.length;
        }
        return undefined;
      },
    },
  );

  const {
    data: favorites4i,
    isFetching: is4iFavoriteFetching,
    isLoading: is4iFavoriteLoading,
    isError: is4iFavoriteErrored,
    fetchNextPage: fetchNextPage4iFavorites,
    hasNextPage: is4iFavoritesHasNextPage,
    isFetchingNextPage: is4iFavoritesFetchingNextPage,
  } = useInfiniteQuery(
    ['highlights favorites'],
    async ({ pageParam = 0 }) => {
      const { data } = await api.get<PreDefinedGroupsProps>(
        `/groups/predefineds?category=highlights&skip=${
          pageParam * QUANTITY_FAVORITES_PAGE
        }&limit=${QUANTITY_FAVORITES_PAGE}`,
      );

      idHighlightsFavoriteViewingHome === '' &&
        setterIdHighlightsFavoriteViewingHome(data?.data[0].id);

      return data;
    },
    {
      staleTime: 1000 * 60,
      getNextPageParam: (lastPage, pages) => {
        if (pages.length * QUANTITY_FAVORITES_PAGE < lastPage.total) {
          return pages.length;
        }
        return undefined;
      },
    },
  );

  const userHasFavorites = !!userFavorites?.pages[0].total;

  const id =
    favoriteTypeHome === 'user'
      ? idUserFavoriteViewingHome
      : idHighlightsFavoriteViewingHome;

  const {
    data: seriesOnFavorite,
    isLoading: isLoadingSeriesOnFavorite,
    isError: isSeriesOnFavoriteErrored,
  } = useQuery(
    ['list series on favorite', id, page],
    async () => {
      const { data } = await api.get<GroupSeries>(
        `/groups/${id}/series?skip=${
          (page - 1) * QUANTITY_SERIES_PAGE
        }&limit=${QUANTITY_SERIES_PAGE}`,
      );

      return data;
    },
    {
      enabled: !!id,
      staleTime: 1000 * 60,
    },
  );

  const handleOnScroll = (event: React.UIEvent<HTMLDivElement>): void => {
    const isEnd =
      Math.ceil(
        event.currentTarget.scrollWidth - event.currentTarget.scrollLeft,
      ) === event.currentTarget.clientWidth;

    if (
      isEnd &&
      !isUserFavoriteLoading &&
      !isUserFavoriteFetching &&
      !isUserFavoriteErrored &&
      isUserFavoriteHasNextPage &&
      favoriteTypeHome === 'user'
    ) {
      fetchNextPageUserFavorites();
    }

    if (
      isEnd &&
      !is4iFavoriteLoading &&
      !is4iFavoriteFetching &&
      !is4iFavoriteErrored &&
      is4iFavoritesHasNextPage &&
      favoriteTypeHome === 'highlights'
    ) {
      fetchNextPage4iFavorites();
    }
  };

  useEffect(() => {
    setPage(1);
  }, [
    idUserFavoriteViewingHome,
    idHighlightsFavoriteViewingHome,
    favoriteTypeHome,
  ]);

  const array = Array.from(Array(8), (_, i) => i);
  const favoritesListIsLoading = array.map((i) => (
    <ContainerSkeleton
      key={i + 1}
      withLoading={false}
      style={{
        width: `${Math.random() * (270 - 120) + 120}px`,
        height: '39px',
      }}
    />
  ));

  const arraySeries = Array.from(Array(5), (_, i) => i);
  const seriesListIsLoading = arraySeries.map((i) => (
    <SerieInfo serie={fakeSerie} key={i} isLoading />
  ));

  const showPaginate =
    !isLoadingSeriesOnFavorite &&
    seriesOnFavorite &&
    seriesOnFavorite.total > 0;

  return (
    <Favorites>
      <HeaderFavorites>
        {isUserFavoriteLoading ? (
          <ContainerSkeleton
            withLoading={false}
            style={{
              width: '12.5rem',
              height: '22px',
            }}
          />
        ) : (
          <>
            <FavoriteOption
              key="4iFavorite"
              isActive={favoriteTypeHome === 'highlights'}
              onClick={() => {
                setterFavoriteTypeHome('highlights');
                setterFavoriteType('highlights');
              }}
              data-testid="text-highlights-favorite"
            >
              {translate('homeFeatureStoreHighlights')}
            </FavoriteOption>

            <Slash key="slash">/</Slash>

            <FavoriteOption
              key="myFavorite"
              isActive={favoriteTypeHome === 'user'}
              onClick={() => {
                setterFavoriteTypeHome('user');
                setterFavoriteType('user');
              }}
              data-testid="text-user-favorite"
            >
              {translate('homeFeatureStoreMyFavorites')}
            </FavoriteOption>
          </>
        )}
      </HeaderFavorites>

      <ListFavoritesContainer onScroll={handleOnScroll}>
        {isUserFavoriteLoading && favoriteTypeHome === 'user'
          ? favoritesListIsLoading
          : favoriteTypeHome === 'user' &&
            userFavorites?.pages.map((favorite, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={index + 1}>
                {favorite.data.map((userFavorite, i) => (
                  <Favorite
                    isActive={
                      idUserFavoriteViewingHome === userFavorite.id ||
                      (idUserFavoriteViewingHome === '' && i === 0)
                    }
                    isLoading={isUserFavoriteFetchingNextPage}
                    idFavoriteViewing={userFavorite.id}
                    name={userFavorite.name}
                    favoriteType="user"
                    key={userFavorite.id}
                  />
                ))}
              </Fragment>
            ))}

        {is4iFavoriteLoading && favoriteTypeHome === 'highlights'
          ? favoritesListIsLoading
          : favoriteTypeHome === 'highlights' &&
            favorites4i?.pages.map((favorite, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={index + 1}>
                {favorite.data.map((highlightsFavorite, i) => (
                  <Favorite
                    isActive={
                      idHighlightsFavoriteViewingHome ===
                        highlightsFavorite.id ||
                      (idHighlightsFavoriteViewingHome === '' && i === 0)
                    }
                    isLoading={is4iFavoritesFetchingNextPage}
                    idFavoriteViewing={highlightsFavorite.id}
                    name={highlightsFavorite.name}
                    favoriteType="highths"
                    key={highlightsFavorite.id}
                  />
                ))}
              </Fragment>
            ))}
      </ListFavoritesContainer>

      {isLoadingSeriesOnFavorite ||
      isUserFavoriteLoading ||
      id === undefined ||
      is4iFavoriteLoading ? (
        seriesListIsLoading
      ) : seriesOnFavorite && seriesOnFavorite?.total > 0 ? (
        // eslint-disable-next-line react/jsx-indent
        <SeriesFavoriteContainer data-testid="container-series-favorite">
          {seriesOnFavorite.data.map((serie) => (
            <SerieInfo
              serie={serie}
              key={serie.code}
              isActiveFirstSerieInfo={seriesOnFavorite.data.length < 2}
            />
          ))}
        </SeriesFavoriteContainer>
      ) : !isSeriesOnFavoriteErrored && seriesOnFavorite?.total === 0 ? (
        <FavoriteStatus
          buttonType="empty"
          onClick={() => navigate('/feature-store/indicators')}
        />
      ) : (
        isSeriesOnFavoriteErrored && (
          <MaintenanceContainer>
            <Maintenance
              content="data"
              text={translate('preDefinedUnableGroup')}
            />
          </MaintenanceContainer>
        )
      )}

      {favoriteTypeHome === 'user' &&
        !isUserFavoriteLoading &&
        !userHasFavorites && (
          <FavoriteStatus
            buttonType="nonexistent"
            onClick={() => setCreateFavoriteModalIsVisible(true)}
          />
        )}

      {showPaginate && (
        <PaginateContainer data-testid="container-paginate">
          <Link
            to={`/feature-store/favorites/${id}`}
            onClick={() => setterFavoriteType(favoriteTypeHome)}
          >
            <NavigateToFavPage>
              <ArrowsOutSimple />
              {translate('listFavoritesGoToAllFavorites')}
            </NavigateToFavPage>
          </Link>

          <span>{summary}</span>

          <PaginationV2
            page={page}
            setPage={setPage}
            setSummary={setSummary}
            total={seriesOnFavorite?.total || 0}
            quantityItemsPerPage={QUANTITY_SERIES_PAGE}
            name={translate('preDefinedSeriesTitle').toLocaleLowerCase()}
            isLoading={isLoadingSeriesOnFavorite}
          />
        </PaginateContainer>
      )}

      {createFavoriteModalIsVisible && (
        <CreateGroupModal
          visible
          categoryType={favoriteTypeHome}
          setVisible={() => setCreateFavoriteModalIsVisible(false)}
          predefined={favoriteTypeHome !== 'user'}
        />
      )}
    </Favorites>
  );
};
