const modelCrossValidationPTBRTranslations = {
  crossValidationTitle: 'Validação Cruzada',
  crossValidationDescription:
    'Examine a precisão do modelo analisando suas previsões em várias janelas de teste.',
  modelInfoTitle: 'Informações do Modelo',
  modelInfoForecastHorizonTitle: 'Horizonte de projeções',
  modelInfoTestWindowsTitle: 'Janelas de teste',
  modelInfoMAPETitle: 'MAPE',
  modelInfoWMAPETitle: 'WMAPE',
  modelInfoMPETitle: 'MPE',
  modelInfoRMSETitle: 'RMSE',
  modelInfoMASETitle: 'MASE',
  modelInfoMASEsTitle: 'MASEs',
  crossValidationWindowsTitle: 'Janelas da Validação Cruzada',
  crossValidationWindowsLegendActual: 'Realizado',
  crossValidationWindowsLegendForecast: 'Projeção',
  crossValidationPercentageErrorMetrics: 'Métricas de Erro Percentual',
  crossValidationScaledErrorMetrics: 'Métricas de Erro Escalonado ',
  crossValidationXAXISTitleWindow: 'Janela',
  crossValidationMeanPercMPE: 'Erro Percentual Médio (MPE)',
  crossValidationLevelErrorMetric: 'Métrica de Erro em Nível',
  crossValidationMeansAbsoluteScaledMASE: 'Erro Médio Escalado Absoluto (MASE)',
  crossValidationMetrics: 'Métricas',
  crossValidationPercentageErrorMetricsDescription:
    'Métricas que representam o desvio percentual das previsões em relação aos valores observados, fornecendo perspectivas distintas com base no método de cálculo.',
  crossValidationScaledErrorMetricsDescription:
    'Métricas de erro escalonadas comparam a precisão do modelo com um modelo de referência e são independentes de escala: 1 indica que o modelo tem desempenho igual, enquanto um valor menor que 1 significa que supera o modelo de referência.',
  crossValidationLevelErrorMetricDescription:
    'Uma métrica expressa na mesma unidade que os valores previstos e observados, o que significa que comparar os valores entre conjuntos de dados com escalas diferentes pode levar a conclusões equivocadas.',
  crossValidationWindowsDescription:
    'Valores realizados e projeções para cada uma das janelas de validação cruzada, incluindo suas medidas de acurácia.',
};

export default modelCrossValidationPTBRTranslations;
