import styled from 'styled-components';

export const Container = styled.div`
  width: 31.25rem;
  max-height: 70vh;
  overflow-y: auto;

  @media (max-height: 879px) {
    padding-right: 1rem;
  }
  h4 {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2rem;
    color: ${({ theme }) => theme.colors.gray6};
    margin-bottom: 1.5rem;
  }
  h5 {
    font-size: 1rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray6};
    margin-bottom: 0.75rem;
  }
  > div {
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const ContentTable = styled.div`
  height: 10rem;
  max-height: 12.5rem;
  overflow-y: auto;

  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 8px;
  padding: 0.5rem;

  ::-webkit-scrollbar-track {
    margin-top: 2.813rem;
  }

  table {
    padding-right: 0.625rem;
  }
  tbody {
    td:last-child {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
    }
  }

  @media (max-width: 700px) {
    overflow-x: scroll;
    overflow-y: hidden;
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  margin-top: 0.625rem;
  height: 9.375rem;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
`;

export const ButtonAddManager = styled.button`
  display: flex;
  align-items: flex-start;
  margin-top: 1.25rem;
  p {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const ContainerError = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  span {
    font-weight: 500;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.gray5};
  }

  img {
    height: 8.125rem;
    width: 8.125rem;
  }
`;
