import React, { useContext, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { RadioButton } from 'src/components/RadioButton';
import { CheckBox } from 'src/components/CheckBox';
import { Tooltip } from 'react-tooltip';
import {
  AIUserSelectionFrequency,
  AIUserSelectionTransformation,
} from 'src/models/redux/reducers/AIUserSelectionOptions';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';

import { Options } from './styles';
import { AISelectionResultsContext } from '..';

export const ResultsOptions: React.FC = () => {
  const { selectFrequency, selectTransformation, dataLevel, dataVariation } =
    useContext(AISelectionResultsContext);

  const { selectedY } = useSelector((state: RootState) => state.project);

  const {
    AISelection: {
      results: { frequency, transformations },
    },
  } = useSelector((state: RootState) => state.AIUserSelectionOptions);

  const { t: translate } = useTranslation();

  useEffect(() => {
    if (frequency === 'monthly' && selectedY?.info?.frequency !== 'daily') {
      selectFrequency('original');
    }
  }, [frequency, selectFrequency, selectedY?.info?.frequency]);

  function handleSelectFrequency(frequencyAux: AIUserSelectionFrequency) {
    selectFrequency(frequencyAux);
  }

  function handleSelectTransformation(
    transformationAux: AIUserSelectionTransformation,
  ) {
    selectTransformation(transformationAux);
  }

  const isQuarterlyFrequencyAvailable =
    selectedY?.info?.frequency !== 'bimonthly' &&
    selectedY?.info?.frequency !== 'half-year' &&
    selectedY?.info?.frequency !== 'annual';

  const isAnnualFrequencyAvailable = selectedY?.info?.frequency !== 'annual';

  const hasLevel = !!(
    (dataLevel?.historical.date.length ?? 0) +
    (dataLevel?.forecast?.date.length ?? 0)
  );

  const hasVariation = !!(
    (dataVariation?.historical.date.length ?? 0) +
    (dataVariation?.forecast?.date.length ?? 0)
  );

  return (
    <Options>
      <Tooltip id="results-variation-tooltip" className="customTooltipTheme" />
      <div>
        <span>{translate('AISelectionResultFrequency')}</span>
        <div>
          <RadioButton
            label={translate('AISelectionResultOriginal')}
            onChange={() => handleSelectFrequency('original')}
            checked={frequency === 'original'}
          />

          {selectedY?.info?.frequency === 'daily' && (
            <RadioButton
              label={translate('AISelectionResultMonthly')}
              onChange={() => handleSelectFrequency('monthly')}
              checked={frequency === 'monthly'}
            />
          )}

          {isQuarterlyFrequencyAvailable && (
            <RadioButton
              label={translate('AISelectionResultQuarterly')}
              onChange={() => handleSelectFrequency('quarterly')}
              checked={frequency === 'quarterly'}
            />
          )}

          {isAnnualFrequencyAvailable && (
            <RadioButton
              label={translate('AISelectionResultAnnual')}
              onChange={() => handleSelectFrequency('yearly')}
              checked={frequency === 'yearly'}
            />
          )}
        </div>
      </div>

      <div>
        <span>{translate('AISelectionResultTransformation')}</span>
        <div>
          <CheckBox
            label={translate('AISelectionResultLevel')}
            onChange={() => handleSelectTransformation('Level')}
            checked={transformations.includes('Level')}
            disabled={
              (transformations.includes('Level') &&
                transformations.length === 1) ||
              !hasLevel
            }
          />

          <div
            data-tooltip-id="results-variation-tooltip"
            data-tooltip-html={
              !hasVariation
                ? translate('AISelectionResultsNotEnoughObservationsVariation')
                : undefined
            }
          >
            <CheckBox
              label={translate('AISelectionResultVariation')}
              onChange={() => handleSelectTransformation('Variation')}
              checked={transformations.includes('Variation')}
              disabled={
                (transformations.includes('Variation') &&
                  transformations.length === 1) ||
                !hasVariation
              }
            />
          </div>
        </div>
      </div>
    </Options>
  );
};
