import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: 100%;
  max-width: 80rem;
  margin: 0 auto;
  @media (max-width: 1328px) {
    margin: 0 1.5rem;
  }
`;

export const DivSearchBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  svg:first-child {
    margin-left: 0.125rem !important;
    color: ${({ theme }) => theme.colors.gray4} !important;
  }

  input {
    width: 15.625rem;
  }
`;

export const DivTr = styled.div`
  display: flex;
`;

export const TheadAdmin = styled.thead`
  width: 100%;
  border-bottom: black solid;
`;

interface ThProps {
  width?: string | number;
  valueWidthInMediaQueryAuto?: string | number;
}

export const ThAdmin = styled.th<ThProps>`
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: left;
  padding-bottom: 1rem;
  color: ${({ theme }) => theme.colors.gray7};
  opacity: 0.8;
  position: relative;
  width: ${({ width }) => width ?? 'auto'};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray2};

  ${({ valueWidthInMediaQueryAuto }) => css`
    @media (max-width: ${valueWidthInMediaQueryAuto}) {
      width: auto !important;
    }
  `}

  padding-left: 1rem;
  &:first-child {
    padding-left: 0;
  }
  div {
    display: flex;
    justify-content: space-between;
    margin-right: 3.125rem;
  }
`;
