import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormUpload } from 'src/models/pages/Modelling/TimeSeries/types';

interface SerieChecked {
  code: string;
  tag: string;
}

interface SerieFS {
  seriesGroup: SerieChecked[];
  seriesPickForMe: SerieChecked[];
  junctionWithoutRepetition: SerieChecked[];
}

interface GroupFS {
  id: string;
  series: SerieChecked[];
}

export type PayloadDataModelling = {
  step: number | null;
  form: FormUpload | null;
  file: File | null;
  seriesFS: SerieFS;
  groupFS: GroupFS[];
  dummiesFromFS: string[];
  lagVariables: string[];
};

const initialState: PayloadDataModelling = {
  step: null,
  form: null,
  file: null,
  seriesFS: {
    seriesGroup: [],
    seriesPickForMe: [],
    junctionWithoutRepetition: [],
  },
  groupFS: [],
  dummiesFromFS: [],
  lagVariables: [],
};

export const modellingState = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    insert: (state, action: PayloadAction<PayloadDataModelling>) => {
      state.step = action.payload.step;
      state.form = action.payload.form;
      state.file = action.payload.file;
      state.seriesFS = action.payload.seriesFS;
      state.groupFS = action.payload.groupFS;
      state.dummiesFromFS = action.payload.dummiesFromFS;
      state.lagVariables = action.payload.lagVariables;
    },
    clear: (state) => {
      state.step = null;
      state.form = null;
      state.file = null;
      state.seriesFS = {
        seriesGroup: [],
        seriesPickForMe: [],
        junctionWithoutRepetition: [],
      };
      state.groupFS = [];
      state.dummiesFromFS = [];
      state.lagVariables = [];
    },
  },
});

export const { insert, clear } = modellingState.actions;

export default modellingState.reducer;
