import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LevelFilters, Y } from '../Project';

type ErrorProps = {
  message: string;
  quantityLetters: number;
};

export type FilterOption = {
  id: string;
  name: string;
  yIds: string[];
  error: ErrorProps;
};

export type FilterProps = {
  name?: string;
  options: FilterOption[];
};

type FilterLevel = {
  level1: FilterProps;
  level2: FilterProps;
  level3: FilterProps;
};

export interface PayloadDependentVariablesConfig {
  filters: FilterLevel;
  quantity: number;
  deletedAllFilters?: boolean;
  hasChanges?: boolean;
}

const initialState: PayloadDependentVariablesConfig = {
  filters: {
    level1: {
      name: undefined,
      options: [],
    },
    level2: {
      name: undefined,
      options: [],
    },
    level3: {
      name: undefined,
      options: [],
    },
  },
  quantity: 0,
  deletedAllFilters: false,
  hasChanges: false,
};

type ChangeFilterName = {
  filterLevel: 'level1' | 'level2' | 'level3';
  value: string;
  changed?: boolean;
};

type ChangeFilterOption = {
  filterLevel: 'level1' | 'level2' | 'level3';
  value: FilterOption[];
  changed?: boolean;
};

type ChangeFilters = {
  filters: {
    level1: FilterProps;
    level2: FilterProps;
    level3: FilterProps;
  };
  quantity: number;
  changed?: boolean;
};

type ChangeQuantity = {
  value: number;
  changed?: boolean;
};

type InitialFilters = {
  filters?: LevelFilters;
  y: Y[];
};

export const DependentVariablesConfigState = createSlice({
  name: 'dependentVariablesConfig',
  initialState,
  reducers: {
    changeFilterName: (state, action: PayloadAction<ChangeFilterName>) => {
      state.filters[action.payload.filterLevel].name = action.payload.value;

      if (action.payload.changed) {
        state.hasChanges = action.payload.changed;
      }
    },
    changeFilterOptions: (state, action: PayloadAction<ChangeFilterOption>) => {
      state.filters[action.payload.filterLevel].options = action.payload.value;

      if (action.payload.changed) {
        state.hasChanges = action.payload.changed;
      }
    },
    changeFilterQuantity: (state, action: PayloadAction<ChangeQuantity>) => {
      state.quantity = action.payload.value;

      if (action.payload.changed) {
        state.hasChanges = action.payload.changed;
      }
    },
    changeFilters: (state, action: PayloadAction<ChangeFilters>) => {
      state.filters = action.payload.filters;
      state.quantity = action.payload.quantity;

      if (action.payload.changed) {
        state.hasChanges = action.payload.changed;
      }
    },
    deletedAllFilters: (state, action: PayloadAction<boolean>) => {
      state.deletedAllFilters = action.payload;
    },
    setHasChanges: (state, action: PayloadAction<boolean>) => {
      state.hasChanges = action.payload;
    },
    clearFilters: (state) => {
      state.filters = {
        level1: {
          name: undefined,
          options: [],
        },
        level2: {
          name: undefined,
          options: [],
        },
        level3: {
          name: undefined,
          options: [],
        },
      };
      state.quantity = 0;
      state.deletedAllFilters = false;
      state.hasChanges = false;
    },
    setInitialFilters: (state, action: PayloadAction<InitialFilters>) => {
      const getOptions = (
        options: string[],
        level: 'level_1' | 'level_2' | 'level_3',
      ) =>
        options.map((option, index) => ({
          id: `${level}-${index}`,
          name: option,
          yIds: action.payload.y
            .filter((y) => y.filters?.[level] === option)
            .map((y) => y.id),
          error: { message: '', quantityLetters: 0 },
        }));

      const updatedFilters: FilterLevel = {
        level1: { name: undefined, options: [] },
        level2: { name: undefined, options: [] },
        level3: { name: undefined, options: [] },
      };
      let filterQuantity = 0;

      const projectFilters = action.payload.filters;

      if (projectFilters?.level_1) {
        updatedFilters.level1 = {
          name: projectFilters.level_1.name,
          options: getOptions(projectFilters.level_1.options, 'level_1'),
        };
        filterQuantity++;
      }
      if (projectFilters?.level_2) {
        updatedFilters.level2 = {
          name: projectFilters.level_2.name,
          options: getOptions(projectFilters.level_2.options, 'level_2'),
        };
        filterQuantity++;
      }
      if (projectFilters?.level_3) {
        updatedFilters.level3 = {
          name: projectFilters.level_3.name,
          options: getOptions(projectFilters.level_3.options, 'level_3'),
        };
        filterQuantity++;
      }

      state.filters = updatedFilters;
      state.quantity = filterQuantity;
    },
  },
});

export const {
  changeFilterName,
  changeFilterOptions,
  changeFilterQuantity,
  changeFilters,
  clearFilters,
  deletedAllFilters,
  setHasChanges,
  setInitialFilters,
} = DependentVariablesConfigState.actions;
export default DependentVariablesConfigState.reducer;
