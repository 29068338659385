import styled from 'styled-components';

export const ContainerFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  border-top: 1px solid ${({ theme }) => theme.colors.gray2};
  background: ${({ theme }) => theme.colors.white};
  z-index: 2000;

  > div {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 7.875rem;
    padding: 2.5rem 1.5rem;

    @media (max-width: 835px) {
      height: auto;
      flex-direction: column;
    }

    > img {
      width: 11rem;
      height: 2.5rem;

      @media (max-width: 835px) {
        margin-bottom: 1.5rem;
      }
    }

    > p {
      color: ${({ theme }) => theme.colors.gray5};
      width: 40%;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;
      text-align: center;
      margin-left: 0.938rem;

      b {
        font-weight: 500;
      }

      @media (max-width: 835px) {
        width: 80%;
        margin-bottom: 1.5rem;
      }
    }

    div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 16.25rem;
      p {
        margin-right: 1.5rem;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 150%;
        color: ${({ theme }) => theme.colors.gray5};
      }
      a {
        svg {
          height: 1.313rem;
          width: 1.313rem;

          path {
            transition: all 0.2s;
          }

          :hover {
            path {
              fill: ${({ theme }) => theme.colors.gray6};
            }
          }
        }

        &:last-child {
          margin-left: 1rem;
        }
      }
    }
  }
`;
