import React, { useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import { Head } from 'src/components/Head';
import apiWorkspace from 'src/workspaces/service/api';
import { FailedModal } from 'src/components/Modal/Failed';
import { queryClient } from 'src/service/queryClient';
import {
  useLocation,
  useNavigate,
  useNavigationType,
  useParams,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  insert,
  updateReleaseSelected,
} from 'src/workspaces/redux/reducers/Workspace';
import { RootState } from 'src/redux/store';
import { Action } from 'history';
import { AxiosError } from 'axios';
import ms from 'ms';
import { ModalLoading } from 'src/components/Modal/Loading';
import { WorkspaceConfigContext } from 'src/workspaces/contexts/WorkspaceConfigContext';
import { useQueryYTypes } from 'src/workspaces/hooks/useQueryYTypes';

import { Container, StepsContainer, StepsItem, StepsNumber } from './styles';
import {
  CategorizationError,
  CreateWorkspaceResponse,
  CategorizationResponse,
  Filters,
  ParamsProps,
  YFilters,
  Ys,
  YsHierarchiesProps,
} from './types';
import { NoPermissionToEditModal } from '../../components/NoPermissionToEdit';
import { SessionExpiredModal } from '../../components/SessionExpired';
import { CategorizationErrorModal } from './components/Modal/CategorizationError';
import { Step1 } from './components/Step1';
import { Step2 } from './components/Step2';
import { Step3 } from './components/Step3';
import { Step4 } from './components/Step4';

export const CreateWorkspace: React.FC = () => {
  const [showModalError, setShowModalError] = useState(false);

  const [showUserEditingModal, setShowUserEditingModal] = useState('');
  const [navigateToControlPanel, setNavigateToControlPanel] = useState(false);

  const [editionExpired, setEditionExpired] = useState(false);
  const [loadingBlockEdition, setLoadingBlockEdition] = useState(false);
  const [canEdit, setCanEdit] = useState(true);

  const [isSavingWorkspace, setIsSavingWorkpsace] = useState(false);

  const [savingStep, setSavingStep] = useState<
    'variables' | 'hierarchies' | undefined
  >();

  const [categorizationError, setCategorizationError] =
    useState<CategorizationError>();

  const { t: translate } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const navType = useNavigationType();

  const { id: workspaceId } = useParams<ParamsProps>();

  const isEdition = location.pathname.includes('/control-panel/edition');

  const [step, setStep] = useState(isEdition ? 3 : 1);

  const { workspace } = useSelector((state: RootState) => state);

  const {
    name,
    description,
    iconUrl,
    series,
    levelAggregation,
    enableMarketShare,
    hasSeriesChanges,
    stagingAreaData,
    file,
    categorization,
    temporalAggregation,
  } = useContext(WorkspaceConfigContext);

  const { data: typesData } = useQueryYTypes();

  const createWorkspace = async (
    ys: Ys[],
  ): Promise<CreateWorkspaceResponse> => {
    const hasLevelAggregation = levelAggregation.some(
      (level) => level.frequency_method !== 'not_aggregated',
    );

    const { data } = await apiWorkspace.post<CreateWorkspaceResponse>(
      '/workspaces',
      {
        name,
        description,
        icon_url: iconUrl,
        data: {
          ys,
          aggregation: {
            enable: hasLevelAggregation,
          },
          market_share: {
            enable: enableMarketShare,
          },
        },
      },
    );

    return data;
  };

  const updateVariablesInfo = async (ys: Ys[]) => {
    const hasLevelAggregation = levelAggregation.some(
      (level) => level.frequency_method !== 'not_aggregated',
    );

    const data: any = {
      ys,
      aggregation: {
        enable: hasLevelAggregation,
      },
      market_share: {
        enable: enableMarketShare,
      },
      filters: stagingAreaData?.data.filters ?? null,
      y_filters: stagingAreaData?.data.y_filters ?? null,
    };

    if (!categorization?.hierarchies.length && !file) {
      data.filters = null;
      data.y_filters = null;
    }

    await apiWorkspace.put<CreateWorkspaceResponse>(
      `/workspaces/${workspace?.id}/staging-area`,
      {
        data,
      },
    );
  };

  const updateManualHierarchies = async (id: string) => {
    const filters: Filters = {};
    const yFilters: YFilters = {};

    if (categorization?.hierarchies.length) {
      const marketShareColumn = ['tipo', 'type'];
      let marketShareIndex = -1;

      const hierarchies = categorization.hierarchies.filter((col, index) => {
        if (marketShareColumn.includes(col.toLowerCase())) {
          marketShareIndex = index;

          return false;
        }

        return true;
      });

      hierarchies.forEach((hierarchy, i) => {
        if (filters) {
          filters[`level_${i + 1}`] = { name: hierarchy, options: [] };
        }
      });

      const ysLabel = Object.keys(categorization.ys);

      const ysCategorization: YsHierarchiesProps = JSON.parse(
        JSON.stringify(categorization.ys),
      );

      if (marketShareIndex !== -1) {
        ysLabel.forEach((y) => {
          ysCategorization[y].splice(marketShareIndex, 1);
        });
      }

      for (let i = 0; i < ysLabel.length; i++) {
        const label = ysLabel[i];

        ysCategorization[label].forEach((option, index) => {
          const hierarchyOption = new Set(
            filters?.[`level_${index + 1}`]?.options,
          );

          if (option.value) {
            hierarchyOption.add(option.value);
          }

          if (filters && filters[`level_${index + 1}`]?.name) {
            filters[`level_${index + 1}`] = {
              name: filters[`level_${index + 1}`]?.name ?? '',
              options: Array.from(hierarchyOption),
            };
          }

          if (yFilters) {
            if (index === 0) {
              yFilters[label] = {
                level_1: option.value ?? '',
              };
            } else if (option.value) {
              yFilters[label][`level_${index + 1}`] = option.value;
            }
          }
        });
      }

      const { data } = await apiWorkspace.patch<CategorizationResponse>(
        `/workspaces/${id}/staging-area/hierarchies`,
        {
          data: {
            filters,
            y_filters: yFilters,
          },
        },
      );

      return data;
    }

    const { data } = await apiWorkspace.patch<CategorizationResponse>(
      `/workspaces/${id}/staging-area/hierarchies`,
      {
        data: {
          filters: null,
          y_filters: null,
        },
      },
    );

    return data;
  };

  const updateExcelHierarchies = async (id: string) => {
    if (file) {
      const fileAux = new FormData();
      fileAux.append('file', file);

      const { data } = await apiWorkspace.patch<CategorizationResponse>(
        `/workspaces/${id}/staging-area/hierarchies/excel`,
        fileAux,
      );

      return data;
    }
  };

  const getTypeCode = (label: string) => {
    const yTypeDefault = {
      MarketSize: 'marketsize',
      SellOut: 'sellout',
      Others: 'others',
      Outros: 'others',
      outros: 'others',
    } as { [key: string]: string };

    if (yTypeDefault[label]) {
      return yTypeDefault[label];
    }

    return typesData?.find((type) => type.label === label)?.code ?? label;
  };

  const updateAggregations = async (id: string) => {
    await apiWorkspace.patch(`/workspaces/${id}/staging-area/aggregation`, {
      temporal: temporalAggregation.map((agg) => ({
        ...agg,
        y_type: getTypeCode(agg.y_type),
      })),
      hierarchical: levelAggregation.map((agg) => ({
        ...agg,
        y_type: getTypeCode(agg.y_type),
      })),
    });
  };

  const saveWorkspace = async () => {
    setIsSavingWorkpsace(true);
    setCategorizationError(undefined);

    const ys: Ys[] = [];

    series.forEach((serie) => {
      if (serie.selected) {
        if (serie.source === 'external_series') {
          ys.push({
            project_id: '',
            y: serie.yName,
            y_label: serie.yLabel,
            model_id: serie.modelId.model_id,
            is_inflated: serie.isInflated,
            type: serie.type ?? 'others',
            type_label: serie.type_label ?? serie.type ?? 'others',
            series_id: serie.seriesId,
            source: serie.source,
            tag: serie.serieTag,
          });
        } else {
          ys.push({
            project_id: serie.modelUpdate!.value,
            y: serie?.modelUpdate?.y ?? serie.y,
            y_label: serie.yLabel,
            model_id: serie.modelId.model_id,
            is_inflated: serie.isInflated,
            type: serie.type ?? 'others',
            type_label: serie.type_label ?? serie.type ?? 'others',
            series_id: '',
            source: serie.source,
            tag: '',
          });
        }
      }
    });

    let id = workspace?.id ?? undefined;

    let categorizationResponse: CategorizationResponse | undefined;

    try {
      if (!workspace?.id) {
        setSavingStep('variables');

        const data = await createWorkspace(ys);

        id = data.id;

        dispatch(
          insert({
            id: data.id,
            name: data.name,
            description: data.description,
            icon: data.icon_url,
            userRole: 'manager',
            createdBy: data.created_by,
            lastUpdated: data.last_updated,
            frequency: null,
            releaseCurrent: null,
            releasePreview: null,
            status: data.status,
            canSyncAdjust: true,
          }),
        );

        dispatch(
          updateReleaseSelected({ releaseSelected: null, ySelected: null }),
        );

        if (id) {
          await blockEdition(false, id);

          if (file || categorization?.hierarchies.length) {
            setSavingStep('hierarchies');

            if (file) {
              categorizationResponse = await updateExcelHierarchies(id);
            } else if (categorization?.hierarchies.length) {
              categorizationResponse = await updateManualHierarchies(id);
            }
          }

          await updateAggregations(id);

          if (!categorizationResponse?.warnings?.length) {
            try {
              await apiWorkspace.delete(`/workspaces/${id}/edit`);
              // eslint-disable-next-line no-empty
            } catch {}
          }
        }
      } else {
        await blockEdition(false);

        if (hasSeriesChanges) {
          setSavingStep('variables');

          await updateVariablesInfo(ys);
        }

        if (file) {
          setSavingStep('hierarchies');

          categorizationResponse = await updateExcelHierarchies(
            workspace?.id ?? '',
          );
        } else {
          setSavingStep('hierarchies');

          categorizationResponse = await updateManualHierarchies(
            workspace?.id ?? '',
          );
        }

        await updateAggregations(workspace?.id ?? '');

        if (!categorizationResponse?.warnings?.length) {
          try {
            await apiWorkspace.delete(`/workspaces/${workspace?.id}/edit`);
            // eslint-disable-next-line no-empty
          } catch {}

          queryClient.removeQueries([
            'workspace staging area',
            workspace?.id,
            'projects',
          ]);
          queryClient.removeQueries(['workspace staging area', workspace?.id]);
          queryClient.removeQueries([
            'workspace staging area hierarchies',
            workspace?.id,
          ]);
          queryClient.removeQueries([
            'workspace staging area aggregations',
            workspace?.id,
          ]);
          queryClient.removeQueries(['workspace data', workspace?.id]);
        }
      }

      queryClient.removeQueries('workspaces');

      if (categorizationResponse?.warnings?.length) {
        setCategorizationError({
          description: categorizationResponse.warnings[0],
          warning: true,
        });
      } else {
        navigate(`/workspaces/${id}/control-panel`);
      }
    } catch (err) {
      const error = err as AxiosError;

      const url = error?.response?.config?.url ?? error?.config?.url;

      if (
        error.response?.status === 403 &&
        error.response?.data.detail.detail[0].startsWith(
          'workspace is being edited by',
        )
      ) {
        let emailEditing = error.response?.data.detail.detail[0]
          .replace('workspace is being edited by ', '')
          .trim();

        emailEditing =
          emailEditing[emailEditing?.length - 1] === '.'
            ? emailEditing?.slice(0, -1)
            : emailEditing;

        setShowUserEditingModal(emailEditing);
      } else if (url?.endsWith('/hierarchies') || url?.endsWith('/excel')) {
        let errorDescription = `<p style="margin-bottom: 0.5rem">${translate(
          'categorizationErrorGenericDescription',
        ).replace(
          'XXX',
          translate(
            isEdition
              ? 'categorizationErrorWorkspaceEdit'
              : 'categorizationErrorWorkspaceCreation',
          ),
        )}</p>`;

        let variables = '';

        const detail: string =
          error.response?.data?.detail?.detail ??
          error.response?.data?.detail ??
          '';

        if (detail) {
          if (detail.includes(translate('missingFilter'))) {
            errorDescription += translate('missingFilter');
            variables = detail.replace(translate('missingFilter'), '').trim();
          } else if (detail.includes(translate('numberVariablesIsGreater'))) {
            errorDescription += translate('numberVariablesIsGreater');
            variables = detail
              .replace(translate('numberVariablesIsGreater'), '')
              .trim();
          } else if (detail.includes(translate('numberVariablesIsSmaller'))) {
            errorDescription += translate('numberVariablesIsSmaller');
            variables = detail
              .replace(translate('numberVariablesIsSmaller'), '')
              .trim();
          } else if (detail.includes(translate('aggregationDisabled'))) {
            errorDescription += `${translate('aggregationDisabled')}:`;
            variables = detail
              .replace(translate('aggregationDisabled'), '')
              .trim()
              .replace(':', '')
              .replaceAll("'", '')
              .replaceAll('[', '')
              .replaceAll(']', '');
          } else if (detail.includes(translate('invalidTypes'))) {
            errorDescription += `${translate('invalidTypes')}`;
            variables = detail.replace(translate('invalidTypes'), '').trim();
          } else if (detail.includes(translate('mandatoryVariables'))) {
            errorDescription += translate('mandatoryHierarchy');
            variables = detail
              .replace(translate('mandatoryVariables'), '')
              .trim();
          } else {
            errorDescription += detail;
          }
        }

        setCategorizationError({
          description: errorDescription,
          variables,
        });
      } else if (url?.endsWith('/aggregation')) {
        const errorDescription = `<p style="margin-bottom: 0.5rem">${translate(
          'workspaceAggregationErrorGenericDescription',
        ).replace(
          'XXX',
          translate(
            isEdition
              ? 'categorizationErrorWorkspaceEdit'
              : 'categorizationErrorWorkspaceCreation',
          ),
        )}</p>`;

        setCategorizationError({
          description: errorDescription,
        });
      } else {
        setShowModalError(true);
      }
    }

    setSavingStep(undefined);
    setIsSavingWorkpsace(false);
  };

  const closeModalError = () => {
    setShowModalError(false);
  };

  const blockEdition = async (sendToControlPanel = true, id?: string) => {
    setLoadingBlockEdition(true);

    try {
      await apiWorkspace.patch(`/workspaces/${id ?? workspace?.id}/edit`);

      setCanEdit(true);
    } catch (err) {
      const error = err as AxiosError;
      if (
        error.response?.status === 400 &&
        error.response?.data?.detail?.detail?.startsWith(
          'Workspace already locked for editing by',
        )
      ) {
        if (sendToControlPanel) {
          setNavigateToControlPanel(true);
        }

        setCanEdit(false);
        setShowUserEditingModal(
          error.response?.data.detail.detail.replace(
            'Workspace already locked for editing by ',
            '',
          ),
        );
      }
    }

    setEditionExpired(false);
    setLoadingBlockEdition(false);
  };

  useEffect(() => {
    if (isEdition && navType === Action.Pop && workspace?.id) {
      blockEdition();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdition, navType, workspace?.id]);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (!editionExpired && isEdition && canEdit) {
      interval = setInterval(() => setEditionExpired(true), ms('5 min'));
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [editionExpired, isEdition, canEdit]);

  useEffect(() => {
    if (isEdition && workspace?.status === 'publishing') {
      navigate(`/workspaces/${workspace?.id}/control-panel`);
    }
  }, [isEdition, workspace?.status, workspace?.id, navigate]);

  return (
    <>
      <Head
        title={
          isEdition
            ? translate('editWorkspaceHeadTitle')
            : translate('createWorkspaceHeadTitle')
        }
      />

      <Container>
        <StepsContainer className="containerLinear">
          <StepsItem
            selected={step === 1}
            data-testid={`container-step-1${step === 1 ? '-selected' : ''}`}
          >
            <StepsNumber data-testid="step-1-number">1</StepsNumber>
            <p>{translate('step1')}</p>
          </StepsItem>

          <StepsItem
            selected={step === 2}
            data-testid={`container-step-2${step === 2 ? '-selected' : ''}`}
          >
            <StepsNumber data-testid="step-2-number">2</StepsNumber>
            <p>{translate('step2')}</p>
          </StepsItem>

          <StepsItem
            selected={step === 3}
            data-testid={`container-step-3${step === 3 ? '-selected' : ''}`}
          >
            <StepsNumber data-testid="step-3-number">3</StepsNumber>
            <p>{translate('step3')}</p>
          </StepsItem>

          <StepsItem
            selected={step === 4}
            data-testid={`container-step-4${step === 4 ? '-selected' : ''}`}
          >
            <StepsNumber data-testid="step-4-number">4</StepsNumber>
            <p>{translate('step4')}</p>
          </StepsItem>
        </StepsContainer>

        {step === 1 ? (
          <Step1 setStep={setStep} />
        ) : step === 2 ? (
          <Step2 setStep={setStep} />
        ) : step === 3 ? (
          <Step3 setStep={setStep} />
        ) : (
          <Step4 setStep={setStep} saveWorkspace={saveWorkspace} />
        )}

        <Tooltip
          id="config-workspace-tooltip"
          className="customTooltipTheme workspace-tooltip"
        />
      </Container>

      {showModalError && (
        <FailedModal
          errorInfo={{
            title: translate('createWorkspaceModalErrorTitle'),
            description: translate(
              'createWorkspaceModalErrorDescription',
            ).replace(
              'XXX',
              translate(
                isEdition
                  ? 'createWorkspaceModalErrorDescriptionSaveText'
                  : 'createWorkspaceModalErrorDescriptionCreateText',
              ),
            ),
          }}
          visible
          setVisible={closeModalError}
        />
      )}

      {showUserEditingModal && (
        <NoPermissionToEditModal
          setVisible={() => {
            setShowUserEditingModal('');

            if (navigateToControlPanel) {
              navigate(`/workspaces/${workspaceId}/control-panel`);
            }
          }}
          emailEditing={showUserEditingModal}
        />
      )}

      {editionExpired && canEdit && (
        <SessionExpiredModal
          handleBlockEdition={() => blockEdition(false)}
          loadingBlockEdition={loadingBlockEdition}
          workspaceId={workspaceId ?? ''}
        />
      )}

      {isSavingWorkspace && (
        <ModalLoading
          visible
          message={`${
            !savingStep
              ? translate('loading')
              : savingStep === 'variables' && !workspace?.id
              ? translate('creatingWorkspace')
              : savingStep === 'variables'
              ? translate('savingWorkspace')
              : translate('savingHierarchies')
          }...`}
        />
      )}

      {!!categorizationError?.description && (
        <CategorizationErrorModal
          setVisible={() => setCategorizationError(undefined)}
          workspaceId={workspace?.id ?? ''}
          title={
            categorizationError.warning
              ? translate('categorizationWarningTitle')
              : translate('categorizationErrorTile')
          }
          description={categorizationError?.description}
          variables={categorizationError?.variables}
          isWarning={!!categorizationError.warning}
        />
      )}
    </>
  );
};
