import { Graph, Trophy } from 'phosphor-react';

import { Menu } from '.';

export const classificationMenus: Menu[] = [
  {
    label: 'modelInProductionLabel',
    Icon: Trophy,
    url: '/models/classification/:id/model-in-production',
  },
  {
    label: 'modelExplorerLabel',
    Icon: Graph,
    url: '/models/classification/:id/model-explorer',
  },
];
