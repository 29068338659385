const modellingENTranslations = {
  modelingTitleHeader: 'Create Project',
  backButtons: 'Back',
  step1BeginProject: 'Begin your Project',
  step1BeginProjectDescr:
    'Start by naming your project and selecting a dataset for upload',
  step1ProjectIcon: 'Project Icon',
  step1ProjectIconTootlip:
    'This icon will help you easily identify your project further on.',
  step1ProjectNameInputLabel: 'Project Name',
  step1ProjectNameInputInfo: 'Do not use special characters',
  step1ProjectNameInputPlaceholder: 'Enter the project name',
  step1UploadDatasetInputTitle: 'Upload your dataset',
  step1UploadDatasetInputLabel: 'Dataset',
  step1UploadDatasetInputInfo: `Only '.rds', '.xlsx' and '.csv' extension available.`,
  step1SelectVariablesButton: 'Select Variables',
  step1IconModalTitle: 'Choose your project icon',
  step1IconModalDescr:
    'This icon will help you to easily identify your project later on.',
  step1MessageErrorCouldNotBeOpened: 'The file could not be opened.',
  step1MessageErrorMustHave1DataVariable:
    'Dataset must have at least 1 possible date variable',
  step1MessageErrorMustHave1Column1:
    'Dataset must have more than 1 column, one for date and one y',
  // Step 2 Texts
  step2SelectVariablesTitle: 'Select Variables',
  step2SelectVariablesDescr:
    'Select which variables will be dependent, explanatory and time-related',
  step2DependentVariableInputLabel: 'Response variable',
  step2DependentVariableInputTooltip: 'Which variable do you want to forecast?',
  step2DateTimeFeatureLabel: 'Date/Time Feature',
  step2FormatDateSelectLabel: 'Format',
  step2FormatDateSelectInfo: `Date formats:
  <br />yyyy: 4-digit year (1982)
  <br />MM: 2-digit month (01)
  <br />dd: 2-digit day of the month (13)`,
  step2DatasetSample: 'Dataset sample',
  step2SampleFormatted: 'Formatted:',
  step2Frequency: 'Frequency:',
  step2ExplanatoryVariablesLabel: 'Explanatory Variables',
  step2ExplanatoryVariablesInfo:
    'Variables that may be able to help predict the dependent variable',
  step2LagsButton: 'Lagged Variables',
  step2DownloadDatasetButton: 'Download Dataset',
  step2FinalSettingsButton: 'Final Settings',
  step2VisualizeDataTitle: 'Visualize Data',
  step2VisualizeDataDescr:
    'Take a better look at your data and get the first insights before estimating your models.',
  step2DataviewButton: 'Visualize Data',
  step2EnrichYourDataset: 'Enrich your Dataset',
  step2EnrichYourDatasetDescr:
    "Easy access to pre-selected variables to help boosting your final predictions and overall model's quality.",
  step2OnlyAvailableForMonthlyData: 'Only available for monthly data',
  step2FSButton: 'Feature Store',
  step2MessageErrorPleaseAddMoreObservation:
    "Please add more observations to your dataset. Number of observations should be greater than (according to frequency) 'daily' -> 180, 'weekly' -> 52, 'fortnightly' -> 24, 'monthly' -> 36, 'bimonthly' -> 24, 'quarterly' -> 24, 'half-year' -> 24, 'annual' -> 12.",
  step2MessageSuccessToAddSeriesByFSTitle: 'success',
  step2MessageSuccessToAddSeriesByFSDescription:
    'All series included to be merge with success.',
  step2MessageYouDoNotHaveAccessToTheFeatureStore:
    "Sorry, you don't have access to the Feature Store",
  step2MessageErrorTryingToFormat:
    'Error trying to format date_var with date_format',
  step2MessageErrorDependentVariableWasRemoved:
    'Dependent variable was removed from the dataset',
  step2LagButtonIsDisabledHover:
    'Available when there is at least one explanatory variable',
  step2MessageErrorInvalidPrefixVariableName:
    "Invalid prefix for variable name ('d4i_' or 'do_')",

  // Step 3 Texts
  step3Title: 'Final Settings',
  step3Descr:
    'We have all the information need to start the magic and generate great forecasts. However, feel free to fine tunning the modeling process in case you need more control of the final results.',
  step3ForecastHorLabel: 'Forecast Horizon',
  step3ForecastHorPlaceholder: 'Enter the forecast horizon',
  step3ForecastHorInfo:
    'In the cross-validation, which forecast horizon will be used (if 3, 3 months ahead; if 12, 12 months ahead, etc.)',
  step3NWindows: 'No. of Cross Validation Test Sets (n_windows)',
  step3NWindowsPlaceholder: 'Enter the number of cross validation',
  step3NWindowsINfo: `How many windows the size of 'Forecast Horizon' will be evaluated during cross-validation (CV)`,
  step3ForecastWarn:
    'We recommend that ’n_steps + n_windows - 1’ should be no more than 30% of the size of your data, however, we still accept if this number is at most 50% of the size of your data.',
  step3RecommendedNWindows: 'Recommended n_windows:',
  step3ApplyLog: 'Apply log?',
  step3ApplyLogInfo:
    'If TRUE, we will apply a log transformation in the dependent and explanatory variables. Except for dummy variables or with values less than or equal to 0, which will not be log transformed.',
  step3SeasDummies: 'Add seasonal dummies?',
  step3SeasDummiesInfo: `If TRUE, seasonal dummies will be added in the models according to the frequency shown in the previous step. For a frequency 'm', 'm-1' seasonal dummies are added.`,
  step3FineTunning: 'Fine Tunning',
  step3ModelingButton: 'Modeling',
  step3ForecastOptimizedPeriod: 'Forecast Optimized Period',
  step3CustomPeriod: 'Custom period',
  step3AHead: 'ahead',
  step3SelectAPeriod: 'Select a period',
  step3ChartHistorical: 'Historical',
  step3ChartForecast: 'Forecast',
  step3ChartOptimized: 'Optimized',
  step3ChartTotal: 'Total',
  step3ModalVariablesWarningTitle: 'Data Processing...',
  step3ModalVariablesWarningErrorToProcessInput: 'Error to process input',
  step3ModalVariablesWarningRemovingVariables:
    'Removing variables due to a high percentage of missing observations:',
  step3ModalVariablesWarningVariablesThatMightBeRemoved:
    'Variables that might be removed due to misleading missing values imputation:',
  step3ModalVariablesWarningNumberOfObservations:
    'Number of observations per frequency period',
  step3ModalVariablesWarningMinimumDataRequirements: 'Minimum data requirement',
  step3ModalVariablesWarningThereIsMoreThanOneObservation:
    'There is more than one observation per frequency period, make sure that you do not have more than one.',
  step3ModalVariablesWarningLaggedVariablesThatMightBeRemoved:
    'Lagged variables that might be removed (due to insufficient data points or other pre processing issues):',
  step3ModalVariablesRemovingRedundantVariables:
    'Removing redundant variables. We can safely drop them since their information is already fully contained in other features:',
  step3ModalVariablesMessageDatasetDoesNotContainForecastPeriod:
    'Dataset does not contain a forecast period. In order to obtain forecasts, the Dependent Variable should contain empty (NA) values for the forecast period.',
  step3ModalVariablesMessageDatasetDidNotReachRecommendedNumberOfObservations:
    'Dataset did not reach the recommended number of observations(see requirements for more details). Be aware of potential misleading results.',
  step3ModalVariablesSomeExplanatoryVariablesContainMissingValues:
    "Some Explanatory Variables contain missing values in the forecast period. Review the dataset or set the fine tunning option 'Explanatory Variables Auto - Forecast' to 'ON' to use them for forecasting.",

  //Functions Texts
  resetLagsWarning: 'Lags Reseted!',
  resetLagsWarnDescription:
    'It was necessary to clear your lag variables selection. Please configure again.',
  uploadErrorDescr: 'Error on upload dataset.',
  invalidDataset: 'Invalid Dataset.',
  sendVariablesError: 'An error occurred when sending variables.',
  downloadError: 'There was an error downloading file.',
  validationError: 'There was an error validating the dataset.',
  resetLagsError: 'Failed in reset your lags, please try again!',
  modelingErrorTitle: 'Something went wrong',
  modelingErrorDescr:
    'Unfortunately, we were not able to perform your job. Please, try again. If the problem persists, contact our support.',
  featStoreResetWarnTitle: 'Feature Store Variables reseted!',
  featStoreResetWarnDescr:
    'It was necessary to clear your Feature Store variables. Please configure again.',

  //Yup Validations
  requiredField: 'Required field',
  projectErrorNoSpecialCharacters: 'No special characters allowed',
  projectErrorFirstCharacter: 'The first character must be a letter',
  projectErrorLastCharacter: 'The last character must not be a space',
  projectErrorMaxCharacters: 'Must not be more than 50 characters',
  projectErrorMinCharacters: 'Must have more than 3 characters',
  projectErrorValidFileFormat: 'Valid file format',
  projectErrorInvalideFileFormat: 'Invalid file format',
  projectErrorInvalidFile: 'Invalid file',
  projectErrorDateFormat: 'Invalid format',
  projectErrorSpecifyNumber: 'You must specify a number',
  projectErrorIntegerNumber: 'The number must be integer',
  projectErrorMinLags: 'Lag number must be equal or greater than 1',
  projectErrorMaxLags: 'Lag number must be equal or less than',
  projectErrorMinNSteps: 'Forecast Horizon must be equal or greater than 1',
  projectErrorMaxNSteps: 'Forecast Horizon must be equal or less than',
  projectErrorMinNWindows:
    'No. of Cross Validation test sets (n_windows) must be equal or greater than 1',
  projectErrorMaxNWindows:
    'No. of Cross Validation test sets (n_windows) must be equal or less than',
  projectErrorGoldenVarsMax:
    'Golden Variables must have less than or equal to 14 itens',
  modellingModalWarmingUpTheEnginesTitle: 'Warming up the engines...',
  modellingModalWarmingUpTheEnginesDescription: 'This can take a while.',
};

export default modellingENTranslations;
