import { Button } from 'src/components/Button';
import styled, { css } from 'styled-components';

export const UploadFileContainer = styled.div`
  position: relative;
`;

export const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
`;

export const InputUploadFileContainer = styled.div`
  position: absolute;

  top: calc(100% + 16px);
  right: 0px;

  display: flex;
  flex-direction: column;

  width: 30rem;
  padding: 1rem;

  background: ${({ theme }) => theme.colors.white};

  border-radius: 8px;

  box-shadow: 0px 2px 16px 0px rgba(113, 128, 150, 0.24);

  z-index: 1001;

  &::after {
    content: '';

    position: absolute;
    top: -9px;
    right: 4rem;
    width: 0;
    height: 0px;
    border-style: solid;
    border-width: 0px 6px 9px;
    border-color: transparent transparent ${({ theme }) => theme.colors.white}
      transparent;
    z-index: 1;
  }
`;

type InputFileMessageErrorProps = {
  show: boolean;
};

export const InputFileMessageError = styled.p<InputFileMessageErrorProps>`
  color: ${({ theme }) => theme.colors.red2} !important;
  line-height: 1rem;
  font-style: normal;
  font-size: ${({ show }) => (show ? '0.875rem' : '0')};
  opacity: 0;

  transition: opacity 0.3s ease-in-out;
  transition: margin-top 0.3s ease-in-out;

  ${({ show }) =>
    show &&
    css`
      margin-top: 0.5rem;
      font-size: 0.875rem;
      opacity: 1;
    `}
`;

type ContentUploadProps = {
  isError: boolean;
};

export const ContentUpload = styled.div<ContentUploadProps>`
  position: relative;

  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 20px 72px;

  border: 1px solid
    ${({ theme, isError }) =>
      isError ? theme.colors.red2 : theme.colors.gray2};
  border-radius: 8px;

  margin-bottom: 1rem;

  transition: 0.3s border-color ease-in-out;

  > input {
    position: absolute;

    width: 100%;
    height: 100%;

    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    opacity: 0;

    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }
  }

  > img {
    width: 7.5rem;
    height: 7.125rem;
  }

  > h4 {
    font-size: 18px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.gray6};
    line-height: 1.6875rem;
    margin-bottom: 8px;
  }

  h4 + p {
    font-size: 0.875rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gray4};
    line-height: 1.375rem;

    text-align: center;
  }

  > button {
    padding: 0;
    height: unset;
    margin-top: 1rem;

    z-index: 1;

    &:hover {
      color: ${({ theme }) => theme.colors.gray6};
    }
  }
`;

export const SeriesUploaded = styled.div`
  display: flex;
  gap: 1rem;

  align-items: center;

  width: 100%;
  padding: 1rem;

  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray2};

  margin-top: 1rem;

  div {
    flex: 1;

    display: flex;
    gap: 1rem;
    align-items: center;

    img {
      width: 40px;
      height: 40px;
    }

    span {
      width: 11.25rem;
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.gray5};
      line-height: 1.375rem;

      text-wrap: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 180px;
    }
  }

  button {
    width: 1rem;
    height: 1rem;

    z-index: 2;

    &:disabled {
      cursor: not-allowed;
    }

    svg {
      width: 1rem;
      height: 1rem;

      color: ${({ theme }) => theme.colors.gray3};
      &:hover {
        color: ${({ theme }) => theme.colors.gray4};
      }
    }
  }
`;

export const ButtonSave = styled(Button)`
  width: 100%;

  margin-top: 1rem;
`;
