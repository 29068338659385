const creategGroupModalPTBRTranslate = {
  createGroupModalCreateTitle: 'Criar novo grupo',
  createGroupModalEditTitle: 'Editar seu grupo',
  createGroupModalGroupIcon: 'Ícone do grupo',
  createGroupModalChooseIconTitle: 'Escolha o ícone do seu grupo',
  createGroupModalChooseIconDescrip:
    'Este ícone irá ajudá-lo a identificar facilmente seu grupo mais tarde.',
  createGroupModalPlaceholderName: 'Escreva o nome do seu novo grupo',
  createGroupModalPlaceholderDescription: 'Escreva algo que descreva seu grupo',
  createGroupModalRequiredField: 'Campo obrigatório',
  createGroupModalNameMustLeast3Characters:
    'Nome precisa ter ao menos 3 caracteres',
  createGroupModalNameMax30Caracteres: 'Nome deve ter no máximo 30 caracteres',
  createGroupModalNameFirstCharacterMustNotSpace:
    'O primeiro caractere não deve ser um espaço',
  createGroupModalNameLastCharacterMustNotSpace:
    'O último caractere não deve ser um espaço',
};

export default creategGroupModalPTBRTranslate;
