import React from 'react';

import { useTranslation } from 'react-i18next';
import { Head } from 'src/components/Head';

import notFoundImage from '../../assets/404.svg';
import { Container } from './styles';

const NotFound: React.FC = () => {
  const { t: translate } = useTranslation();

  return (
    <Container>
      <Head title="4CastHub" />
      <img src={notFoundImage} alt="404" />

      <h1>{translate('page404Title')}</h1>
      <p>{translate('page404Description')}</p>
    </Container>
  );
};

export default NotFound;
