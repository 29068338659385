import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100vh;

  img {
    width: 100%;
  }

  h2,
  p {
    text-align: center;
  }

  h2 {
    width: 65%;
    font-weight: 600;
    font-size: 2.5rem;
    color: ${({ theme }) => theme.colors.gray6};

    margin-top: -3.5rem;
    margin-bottom: 0.5rem;
  }

  p {
    width: 70%;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2.5rem;
    color: ${({ theme }) => theme.colors.gray5};

    margin-bottom: 2rem;
  }

  @media (max-width: 1366px) {
    h2 {
      font-size: 2rem;
    }

    p {
      font-size: 1.25rem;
      line-height: 150%;
    }
  }
`;
