import styled, { css } from 'styled-components';

type ButtonStyled = {
  buttonType: 'primary' | 'secondary' | 'naked' | 'tertiary';
  disabled?: boolean;
  iconInvert?: boolean;
  hasIcon?: boolean;
};

export const ButtonStyled = styled.button<ButtonStyled>`
  ${({ theme, buttonType, disabled }) =>
    buttonType === 'primary'
      ? css`
          background: ${!disabled ? theme.colors.primary : theme.colors.gray3};
          color: ${theme.colors.white};
        `
      : buttonType === 'secondary'
      ? css`
          background: ${theme.colors.white};
          border: 1px solid
            ${!disabled ? theme.colors.primary : theme.colors.gray3};
          color: ${!disabled ? theme.colors.primary : theme.colors.gray3};
        `
      : buttonType === 'naked'
      ? css`
          background: transparent;
          color: ${!disabled ? theme.colors.gray5 : theme.colors.gray3};
        `
      : buttonType === 'tertiary' &&
        css`
          background: ${!disabled
            ? `${theme.colors.primary}1F`
            : `${theme.colors.gray4}1F`};
          color: ${!disabled ? theme.colors.primary : theme.colors.gray3};
        `}

  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'not-allowed')};

  height: 2.813rem;
  border-radius: 5px;
  font-size: 0.875rem;
  font-weight: 600;
  padding: ${({ buttonType }) =>
    buttonType === 'naked' ? '0.75rem 0' : '0.75rem 1.5rem'};
  transition: all 0.2s;

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-left: ${({ iconInvert }) => iconInvert && '0.5rem'};
      margin-right: ${({ iconInvert }) => !iconInvert && '0.5rem'};
    }
  }

  &:hover {
    ${({ theme, buttonType, disabled }) =>
      buttonType === 'primary'
        ? css`
            background: ${!disabled && theme.colors.primaryDark};
          `
        : buttonType === 'secondary'
        ? css`
            color: ${!disabled && theme.colors.primaryDark};
            border: ${!disabled
              ? `1px solid ${theme.colors.primaryDark}`
              : `1px solid ${theme.colors.gray3}`};
          `
        : buttonType === 'naked'
        ? css`
            color: ${!disabled && theme.colors.primary};
          `
        : buttonType === 'tertiary' &&
          css`
            color: ${!disabled && theme.colors.primaryDark};
          `};
  }
`;

interface SpinnerProps {
  hasIcon?: boolean;
  spinnerType: 'primary' | 'secondary' | 'naked' | 'tertiary';
}

export const Spinner = styled.div<SpinnerProps>`
  margin: 0 -0.625rem 0 0.625rem;
  border: 3px solid
    ${({ theme, spinnerType }) =>
      spinnerType === 'primary'
        ? theme.colors.primary
        : spinnerType === 'secondary'
        ? theme.colors.gray3
        : spinnerType === 'tertiary' && theme.colors.gray2};
  border-left-color: ${({ theme, spinnerType }) =>
    spinnerType === 'primary'
      ? theme.colors.gray3
      : (spinnerType === 'secondary' || spinnerType === 'tertiary') &&
        theme.colors.primary};
  border-radius: 50%;
  width: 0.875rem;
  height: 0.875rem;
  animation: spin 0.8s linear infinite;

  ${({ hasIcon }) =>
    hasIcon === true &&
    css`
      margin-right: 0.125rem;
    `}

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;
