import React, { useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'src/components/Button';
import { Input } from 'src/components/Input';
import { Modal } from 'src/components/Modal';
import { changeY } from 'src/models/redux/reducers/Project';
import api from 'src/models/service/api';
import { RootState } from 'src/redux/store';
import { queryClient } from 'src/service/queryClient';
import { ModalFooter } from 'src/components/Modal/Footer/styles';

import { ProjectOverviewContext } from '../..';
import { ContainerRenameVariables, ContentVariables } from './styles';

interface Variable {
  id: string;
  name: string;
  label: string;
  newLabel: string | null;
  error: string | null;
}

interface ResponseRename {
  ys: {
    id: string;
    label: string;
    status: string;
    name: string;
  }[];
}

export const ModalRenameVariables: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [variables, setVariables] = useState<Variable[]>([]);

  const { closeModalRenameVariables, reportError } = useContext(
    ProjectOverviewContext,
  );

  const { project } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  useEffect(() => {
    const variablesAux: Variable[] = [];
    project.y.forEach((y) => {
      variablesAux.push({
        id: y.id,
        name: y.name,
        label: y.label,
        newLabel: y.label,
        error: null,
      });
    });
    setVariables(variablesAux);
  }, [project.y]);

  function closeModal() {
    if (loading) {
      return;
    }
    closeModalRenameVariables();
  }

  function validateNewLabel(newLabel: string, index: number): string | null {
    const regex = new RegExp('^[áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑA-Za-z0-9_-]*$');

    if (!newLabel.trim()) {
      return 'requiredField';
    }

    if (newLabel.trim().length < 3) {
      return 'projectOverviewRenameVariableMessageInput3Caracteres';
    }

    if (newLabel.trim().length > 50) {
      return 'projectOverviewRenameVariableInputMax50Caracteres';
    }

    const allWords = newLabel.split(' ');
    for (const word of allWords) {
      if (!regex.test(word)) return 'projectOverviewRenameVariableInputInvalid';
    }

    if (
      variables.some(
        (variable, indexAux) =>
          indexAux !== index &&
          (variable.label === newLabel || variable.newLabel === newLabel),
      )
    ) {
      return 'projectOverviewRenameVariableInputRepeated';
    }

    if (
      variables.some(
        (variable, indexAux) =>
          variable.name === newLabel && index !== indexAux,
      )
    ) {
      return 'projectOverviewRenameVariableInputSameAnotherOriginalName';
    }

    return null;
  }

  function changeLabel(newLabel: string, index: number): void {
    const variablesAux = [...variables];
    variablesAux.splice(index, 1, {
      id: variables[index].id,
      name: variables[index].name,
      label: variables[index].label,
      error: validateNewLabel(newLabel, index),
      newLabel: newLabel.length > 0 ? newLabel : null,
    });
    setVariables(variablesAux);
  }

  async function handleSave() {
    setLoading(true);
    const variablesRenamed = variables.filter(
      (variable) => variable.newLabel !== null,
    );
    if (!variablesRenamed.length) {
      setLoading(false);
      return;
    }

    const variablesRenamedAsVectorObject = variables.map((variable) => ({
      [variable.id]: variable.newLabel?.trim(),
    }));

    const body = {
      y_labels: variablesRenamedAsVectorObject.reduce(
        (accumulatorObj, currentObj) => ({
          ...accumulatorObj,
          ...currentObj,
        }),
      ),
    };

    try {
      const { data } = await api.put<ResponseRename>(
        `/projects/${project.id}/variables/labels`,
        body,
      );
      dispatch(changeY(data.ys));
      setLoading(false);
      queryClient.removeQueries('projects');
      queryClient.removeQueries('project-summary');
      queryClient.removeQueries('AI-selection');
      closeModal();
    } catch (err) {
      setLoading(false);
      reportError({
        title: translate('projectOverviewRenameVariableErrorTitle'),
        description: translate('projectOverviewRenameVariableErrorDescription'),
      });
      closeModal();
    }
  }

  function checkIfSaveButtonIsDisabled(): boolean {
    if (loading) {
      return true;
    }
    if (variables.some((variable) => variable.error)) {
      return true;
    }
    if (variables.every((variable) => variable.newLabel === null)) {
      return true;
    }
    return false;
  }

  return (
    <Modal visible style={{ padding: 0 }} setVisible={closeModal}>
      <ContainerRenameVariables data-testid="container-rename-variables">
        <h4>{translate('projectOverviewRenameVariableTitle')}</h4>
        <p>{translate('projectOverviewRenameVariableDescription')}</p>
        <ContentVariables>
          {variables.map((variable, index) => (
            <Input
              label={variable.name}
              data-testid={`input-rename-variable-${variable.name}`}
              data-cy={`input-rename-variable-${variable.name}`}
              key={`modal-rename-variable-input-${variable.name}`}
              placeholder={variable.label}
              onChange={(e) => changeLabel(e.target.value, index)}
              error={variable.error ? translate(variable.error) : undefined}
              value={variable.newLabel ?? ''}
            />
          ))}
        </ContentVariables>
        <ModalFooter>
          <Button
            buttonType="naked"
            onClick={closeModal}
            data-testid="button-cancel"
            data-cy="button-cancel"
          >
            {translate('cancel')}
          </Button>
          <Button
            buttonType="primary"
            onClick={handleSave}
            disabled={checkIfSaveButtonIsDisabled()}
            loading={loading}
            data-testid="button-save"
            data-cy="button-save"
          >
            {translate('save')}
          </Button>
        </ModalFooter>
      </ContainerRenameVariables>
    </Modal>
  );
};
