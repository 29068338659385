import React from 'react';

import EmptyFavoriteEN from 'src/assets/empty-favorite-en.png';
import EmptyFavoritePT from 'src/assets/empty-favorite-pt.png';
import NonexistentFavoriteEN from 'src/assets/nonexistent-favorite-en.png';
import NonexistentFavoritePT from 'src/assets/nonexistent-favorite-pt.png';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Button';

import {
  Container,
  ContainerMessage,
  ElementSpan,
  ImageStatus,
} from './styles';
import { FavoriteStatusProps } from './types';

export const FavoriteStatus: React.FC<FavoriteStatusProps> = ({
  buttonType,
  ...props
}) => {
  const { language } = useSelector((state: RootState) => state.auth.user);

  const { t: translate } = useTranslation();

  const image =
    language === 'en-us' && buttonType === 'empty'
      ? EmptyFavoriteEN
      : language === 'pt-br' && buttonType === 'empty'
      ? EmptyFavoritePT
      : language === 'en-us' && buttonType === 'nonexistent'
      ? NonexistentFavoriteEN
      : NonexistentFavoritePT;

  const text =
    buttonType === 'nonexistent'
      ? translate('favoriteStatusSaveSerie')
      : translate('favoriteStatusAddSerie');

  const title =
    buttonType === 'nonexistent'
      ? translate('favoriteStatusNonexistentTitle')
      : translate('favoriteStatusEmptyTitle');

  const message =
    buttonType === 'nonexistent'
      ? translate('favoriteStatusNonexistentMessage')
      : translate('favoriteStatusEmptyMessage');

  const buttonText =
    buttonType === 'nonexistent'
      ? translate('favoriteStatusButtonCreateFavorite')
      : translate('favoriteStatusButtonAddSerie');

  return (
    <Container>
      <ImageStatus
        src={image}
        alt="Icon of status message"
        isEmptyButtonType={buttonType === 'empty'}
      />

      <ElementSpan>{text}</ElementSpan>

      <ContainerMessage>
        <h4>{title}</h4>
        <p>{message}</p>
      </ContainerMessage>

      <Button
        buttonType="primary"
        {...props}
        data-testid={
          buttonType === 'nonexistent'
            ? 'button-create-first-group'
            : 'button-add-serie'
        }
      >
        {buttonText}
      </Button>
    </Container>
  );
};
