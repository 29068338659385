import React from 'react';

import * as Plotly from 'plotly.js';
//@ts-expect-error: nao tem ts
import pt from 'plotly.js-locales/pt-br';
import PlotyReact from 'react-plotly.js';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import light from 'src/styles/themes/light';
import { CSSProperties } from 'styled-components';

export type ChartPlotyProps = {
  data: Partial<Plotly.PlotData>[];
  layout?: Partial<Plotly.Layout>;
  range?: boolean;
  eixoXDate?: boolean;
  style?: CSSProperties;
  responsive?: boolean;
  dataCy?: string;
  config?: Partial<Plotly.Config>;
  largerSpaceBetweenChartAndLegend?: boolean;
  onClick?: (event: Readonly<Plotly.PlotMouseEvent>) => void;
  onHover?: (event: Readonly<Plotly.PlotMouseEvent>) => void;
  onUnhover?: (event: Readonly<Plotly.PlotMouseEvent>) => void;
};

type MyLayoutProps = Partial<Plotly.Layout> & {
  legend: Partial<Plotly.Legend> & {
    position?: string;
  };
};

export const ChartPloty: React.FC<ChartPlotyProps> = ({
  data,
  layout,
  range,
  eixoXDate,
  style,
  responsive = true,
  dataCy,
  config,
  largerSpaceBetweenChartAndLegend = false,
  ...rest
}) => {
  const { language } = useSelector((state: RootState) => state.auth.user);

  const languagePTRefatored = {
    ...pt,
    dictionary: {
      ...pt.dictionary,
      'Produced with Plotly': 'Produzido com Plotly',
    },
  };

  const myLayout: MyLayoutProps = {
    title: {
      text: '', /// e colocado em baixo com posicao absolut
      font: {
        family: '400 1rem Inter, sans-serif',
        size: 18,
        color: light.colors.gray6,
      },
      xref: 'paper',
      x: 0,
    },
    font: {
      family: 'Inter, sans-serif',
      color: light.colors.gray6,
    },
    legend: {
      x: 0,
      y: largerSpaceBetweenChartAndLegend ? -0.4 : -0.3,
      orientation: 'h',
    },
    margin: {
      t: 30,
      b: 30,
      r: 15,
      l: 15,
      pad: 5,
    },
    hovermode: 'closest',
    showlegend: true,
    paper_bgcolor: 'rgba(255,255,255,1)',
    plot_bgcolor: 'rgba(255,255,255,1)',
    xaxis: range
      ? {
          rangeslider: {},
          type: eixoXDate ? 'date' : undefined,
          automargin: true,
        }
      : {
          type: eixoXDate ? 'date' : undefined,
          automargin: true,
        },
    yaxis: range
      ? {
          fixedrange: true,
          automargin: true,
        }
      : { automargin: true },
    autosize: true,
    ...layout,
  };

  return (
    <div data-cy={dataCy} data-testid={dataCy}>
      <PlotyReact
        className="chartToAlignPrint"
        data={data}
        layout={myLayout}
        config={{
          responsive,
          locales: { 'pt-br': languagePTRefatored },
          locale: language,
          ...config,
        }}
        style={style}
        {...rest}
      />
    </div>
  );
};
