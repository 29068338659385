const UserConfigsPTBRTranslations = {
  userConfigsWidgetTitle: 'Configurações do usuário',
  userConfigsWidgedUserControls: 'Controle de usuário',
  userConfigsWidgedUserControlsDescription:
    'Acesse o módulo de controle do usuário',
  userConfigsWidgedToken: 'Copiar token para acessar a API',
  userConfigsWidgedTokenDescription:
    'Clique e copie o token para acessar a API',
  userConfigsWidgedTokenInfo:
    'Seu token foi <strong>copiado</strong> para a área de transferência e <strong>expira em um dia</strong>. Se preferir baixar o arquivo em .txt, ',
  userConfigsWidgedTokenInfoClick: 'clique aqui',
};

export default UserConfigsPTBRTranslations;
