import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 27rem;
  height: 27.813rem;
  border-radius: 8px;

  background: ${({ theme }) => theme.colors.white};

  h3 {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 150%;
    margin-bottom: 1.438rem;

    color: ${({ theme }) => theme.colors.gray6};
  }
`;

export const IconLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  width: 8.25rem;
  color: ${({ theme }) => theme.colors.gray5};

  p {
    font-weight: 500;
    font-size: 0.875rem;
  }
`;

interface IconContainerProps {
  disabled?: boolean;
}

export const IconContainer = styled.div<IconContainerProps>`
  width: 5rem;
  height: 5rem;

  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.colors.gray2}`};

  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'not-allowed')};

  transition: all 0.2s;

  img {
    border-radius: 7px;
    width: 100%;
    height: 100%;
    &:hover {
      border-radius: 6px;
    }
  }

  &:hover {
    ${({ disabled }) =>
      !disabled &&
      css`
        border: 2px solid ${({ theme }) => theme.colors.secondary};
      `}
  }
`;
