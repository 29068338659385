import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import parseHTML from 'html-react-parser';
import { ButtonCopyToClipboard } from 'src/components/ButtonCopyToClipboard';

import { UnderstandingResultText } from './styles';
import { UnderstandingResultContentSkeletons } from '../styles';

interface ResultsTextProps {
  loadingResult: boolean;
  description: string;
}

export const ResultsText: React.FC<ResultsTextProps> = ({
  loadingResult,
  description,
}) => {
  const [textToClipboard, setTextToClipboard] = useState('');

  const { t: translate } = useTranslation();

  const textToSendToClipboard =
    document.getElementById('understanding-the-result-text')?.textContent || '';

  useEffect(() => {
    setTextToClipboard(textToSendToClipboard);
  }, [textToSendToClipboard]);

  return (
    <UnderstandingResultText data-cy="ia-selection-results-secondary-content">
      {loadingResult ? (
        <UnderstandingResultContentSkeletons data-cy="ia-selection-results-secondary-loading">
          <ContainerSkeleton withLoading={false} />
          <ContainerSkeleton withLoading={false} />
          <ContainerSkeleton withLoading={false} />
          <ContainerSkeleton withLoading={false} />
        </UnderstandingResultContentSkeletons>
      ) : (
        <p
          id="understanding-the-result-text"
          data-cy="ia-selection-results-secondary-text"
        >
          {parseHTML(description)}
        </p>
      )}
      <div>
        <ButtonCopyToClipboard
          dataTestId="ai-selection-results-button-copy"
          isDisabled={!description || loadingResult}
          textToCopy={textToClipboard}
          messageBeforeCopy={translate(
            'AISelectionResultsUnderstandingCopyText',
          )}
          messageAfterCopy={translate(
            'AISelectionResultsUnderstandingTextCopied',
          )}
        />
      </div>
    </UnderstandingResultText>
  );
};
