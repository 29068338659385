import React, { useEffect, useState } from 'react';

import { CheckCircle, PlusCircle, XCircle } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Button } from 'src/components/Button';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Modal } from 'src/components/Modal';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import { Pagination } from 'src/components/Pagination';
import { Tabs, selectTab } from 'src/components/Tabs';
import { CreateGroupModal } from 'src/feature-store/pages/Groups/components/CreateGroupModal';
import api from 'src/feature-store/service/api';
import { RootState } from 'src/redux/store';
import { queryClient } from 'src/service/queryClient';
import light from 'src/styles/themes/light';

import { GroupCard } from '../GroupCard';
import { ModalSuccessFail } from '../ModalSuccessFail';
import {
  CardCreateGroupContainer,
  CardCreateGroupContent,
  Container,
  ContainerWithoutGroups,
  Content,
  DescriptionSearch,
  DivPagination,
  GroupCardLoading,
  GroupsContainer,
} from './styles';
import { MyGroups } from './types';

interface SendToGroupProps {
  visible: boolean;
  setVisible(): void;
  series?: string[];
}

export interface GroupSelectedProps {
  id: string;
  name: string;
  is4ifavoritesGroup: boolean;
}

const QUANTITY_ITEMS_PAGE = 6;

export const SendToGroupModal: React.FC<SendToGroupProps> = ({
  visible,
  setVisible,
  series,
}) => {
  const { user } = useSelector((state: RootState) => state.auth);

  const [myGroupsPage, setMyGroupsPage] = useState(1);
  const [myGroups, setMyGroups] = useState<MyGroups>();
  const [predefinedGroupsPage, setPredefinedGroupsPage] = useState(1);
  const [predefinedGroups, setPredefinedGroups] = useState<MyGroups>();
  const [tabSelected, setTabSelected] = useState<string>();

  const [groupSelected, setGroupSelected] = useState<GroupSelectedProps>();

  const [modalCreateGroupVisible, setModalCreateGroupVisible] = useState(false);
  const [createGroupPredefined, setCreateGroupPredefined] = useState(false);

  const [responseAddSeriesInGroup, setResponseAddSeriesInGroup] = useState<
    'success' | 'failed'
  >();

  const { t: translate } = useTranslation();

  const {
    data: myGroupsData,
    isFetching: isMyGroupsFetching,
    isLoading: isMyGroupsLoading,
    isError: isMyGroupsErrored,
  } = useQuery(
    ['my groups page modal', myGroupsPage],
    async () => {
      if (myGroupsPage === 1) {
        const { data } = await api.get<MyGroups>(
          `/groups?skip=${(myGroupsPage - 1) * QUANTITY_ITEMS_PAGE}&limit=${
            QUANTITY_ITEMS_PAGE - 1
          }`,
        );

        return data;
      }
      const { data } = await api.get<MyGroups>(
        `/groups?skip=${
          (myGroupsPage - 1) * QUANTITY_ITEMS_PAGE - 1
        }&limit=${QUANTITY_ITEMS_PAGE}`,
      );

      return data;
    },
    {
      staleTime: 1000 * 60,
    },
  );

  const {
    data: predefinedGroupsData,
    isFetching: isPredefinedGroupsFetching,
    isLoading: isPredefinedGroupsLoading,
    isError: isPredefinedGroupsErrored,
  } = useQuery(
    ['pre-defined groups page modal', predefinedGroupsPage],
    async () => {
      if (predefinedGroupsPage === 1) {
        const { data } = await api.get<MyGroups>(
          `/groups/predefineds?skip=${
            (predefinedGroupsPage - 1) * QUANTITY_ITEMS_PAGE
          }&limit=${QUANTITY_ITEMS_PAGE - 1}`,
        );

        return data;
      }
      const { data } = await api.get<MyGroups>(
        `/groups/predefineds?skip=${
          (predefinedGroupsPage - 1) * QUANTITY_ITEMS_PAGE - 1
        }&limit=${QUANTITY_ITEMS_PAGE}`,
      );

      return data;
    },
    {
      staleTime: 1000 * 60,
    },
  );

  useEffect(() => {
    if (myGroupsData) {
      setMyGroups(myGroupsData);
    }

    if (predefinedGroupsData) {
      setPredefinedGroups(predefinedGroupsData);
    }
  }, [myGroupsData, predefinedGroupsData]);

  useEffect(() => {
    selectTab(translate('sendMyGroupTitleTab'));
  }, [translate]);

  const sendSeriesToGroup = async () => {
    if (groupSelected) {
      try {
        await api.patch(`groups/${groupSelected.id}/series`, {
          series,
        });
        queryClient.invalidateQueries('group series');
        queryClient.invalidateQueries('group');
        queryClient.refetchQueries([
          'list series on favorite',
          groupSelected.id,
          1,
        ]);

        setResponseAddSeriesInGroup('success');
      } catch (err) {
        setResponseAddSeriesInGroup('failed');
      }
    }
  };

  function handleChangePages(selectedPage: number) {
    if (tabSelected === translate('sendPreDefinedTitleTab')) {
      setPredefinedGroupsPage(selectedPage);
    } else {
      setMyGroupsPage(selectedPage);
    }
  }

  return (
    <>
      <Modal visible={visible} setVisible={setVisible}>
        <Container>
          <Content>
            <DescriptionSearch>
              <div>
                <h3>{translate('chooseAGroup')}</h3>
                <p>{translate('clickAddToContinue')}</p>
              </div>
            </DescriptionSearch>
            <Tabs
              getSelectedTab={(tabName: string) => {
                setTabSelected(tabName);
              }}
              style={{ marginTop: '8px' }}
              data={[
                {
                  name: translate('sendPreDefinedTitleTab'),
                  disabled: user.isFsAdmin !== true,
                  render: () => (
                    <>
                      {isPredefinedGroupsErrored ? (
                        <ContainerWithoutGroups>
                          <ContainerMaintenance
                            content="data"
                            text={translate('sendUnableToLoadPreDefinedGroup')}
                          />
                        </ContainerWithoutGroups>
                      ) : isPredefinedGroupsFetching ||
                        isPredefinedGroupsLoading ? (
                        // eslint-disable-next-line react/jsx-indent
                        <>
                          <GroupsContainer>
                            {Array.from({ length: QUANTITY_ITEMS_PAGE }).map(
                              (_, index) => (
                                <GroupCardLoading
                                  key={`group-loading-${index + 1}`}
                                  data-testid={`group-loading-${index + 1}`}
                                >
                                  <ContainerSkeleton
                                    withLoading={false}
                                    style={{
                                      width: '40px',
                                      height: '40px',
                                      borderRadius: '4px',
                                    }}
                                  />
                                  <span>{translate('name')}</span>
                                  <ContainerSkeleton
                                    withLoading={false}
                                    style={{
                                      width: '150px',
                                      height: '20px',
                                      marginTop: '8px',
                                    }}
                                  />
                                  <span>{translate('createdAt')}</span>
                                  <ContainerSkeleton
                                    withLoading={false}
                                    style={{
                                      width: '154px',
                                      height: '20px',
                                      marginTop: '8px',
                                    }}
                                  />
                                  <span>{translate('lastUpdated')}</span>
                                  <ContainerSkeleton
                                    withLoading={false}
                                    style={{
                                      width: '154px',
                                      height: '20px',
                                      marginTop: '8px',
                                    }}
                                  />
                                </GroupCardLoading>
                              ),
                            )}
                          </GroupsContainer>
                          {predefinedGroups?.total && (
                            <DivPagination>
                              <Pagination
                                total={predefinedGroups?.total}
                                page={predefinedGroupsPage}
                                setPage={handleChangePages}
                                quantityItemsPerPage={QUANTITY_ITEMS_PAGE}
                                firstPageHaveDifferentElement
                                name={translate('groups')}
                              />
                            </DivPagination>
                          )}
                        </>
                      ) : predefinedGroups && predefinedGroups.total > 0 ? (
                        <>
                          <GroupsContainer>
                            {predefinedGroupsPage === 1 && (
                              <CardCreateGroupContainer>
                                <CardCreateGroupContent
                                  onClick={() => {
                                    setCreateGroupPredefined(
                                      tabSelected ===
                                        translate('sendPreDefinedTitleTab') &&
                                        user.isFsAdmin,
                                    );
                                    user.isFsAdmin &&
                                      setModalCreateGroupVisible(true);
                                  }}
                                  allowed={user.isFsAdmin}
                                  data-testid="create-new-pre-defined-group"
                                >
                                  <PlusCircle
                                    data-testid="plus-circle-icon"
                                    size="6.25rem"
                                    weight="fill"
                                  />
                                  <h3>{translate('createNewGroup')}</h3>
                                  <p>
                                    {translate(
                                      'createNewGroupAndAlreadyNewSeries',
                                    )}
                                  </p>
                                </CardCreateGroupContent>
                              </CardCreateGroupContainer>
                            )}
                            {predefinedGroups?.data?.map((group) => (
                              <GroupCard
                                key={`${group.id} ${group.name}`}
                                id={group.id}
                                name={group.name}
                                iconUrl={group.icon_url}
                                createdAt={group.created_at}
                                updatedAt={group.update_at}
                                predefined
                                groupSelected={groupSelected}
                                setGroupSelected={setGroupSelected}
                              />
                            ))}
                          </GroupsContainer>
                          {predefinedGroups?.total && (
                            <DivPagination>
                              <Pagination
                                total={predefinedGroups?.total}
                                page={predefinedGroupsPage}
                                setPage={handleChangePages}
                                quantityItemsPerPage={QUANTITY_ITEMS_PAGE}
                                firstPageHaveDifferentElement
                                name={translate('groups')}
                              />
                            </DivPagination>
                          )}
                        </>
                      ) : (
                        <GroupsContainer>
                          <CardCreateGroupContainer>
                            <CardCreateGroupContent
                              onClick={() => {
                                setCreateGroupPredefined(
                                  tabSelected ===
                                    translate('sendPreDefinedTitleTab') &&
                                    user.isFsAdmin,
                                );
                                user.isFsAdmin &&
                                  setModalCreateGroupVisible(true);
                              }}
                              allowed={user.isFsAdmin}
                              data-testid="create-new-pre-defined-group"
                            >
                              <PlusCircle
                                data-testid="plus-circle-icon"
                                size="6.25rem"
                                weight="fill"
                              />
                              <h3>{translate('createNewGroup')}</h3>
                              <p>
                                {translate('createNewGroupAndAlreadyNewSeries')}
                              </p>
                            </CardCreateGroupContent>
                          </CardCreateGroupContainer>
                        </GroupsContainer>
                      )}
                    </>
                  ),
                },
                {
                  name: translate('sendMyGroupTitleTab'),
                  render: () => (
                    <>
                      {isMyGroupsErrored ? (
                        <ContainerWithoutGroups>
                          <ContainerMaintenance
                            content="data"
                            text={translate('sendUnableToLoadYourGroup')}
                          />
                        </ContainerWithoutGroups>
                      ) : isMyGroupsFetching || isMyGroupsLoading ? (
                        <>
                          <GroupsContainer>
                            {Array.from({ length: QUANTITY_ITEMS_PAGE }).map(
                              (_, index) => (
                                <GroupCardLoading
                                  key={`group-loading-${index + 1}`}
                                  data-testid={`group-loading-${index + 1}`}
                                >
                                  <ContainerSkeleton
                                    withLoading={false}
                                    style={{
                                      width: '40px',
                                      height: '40px',
                                      borderRadius: '4px',
                                    }}
                                  />
                                  <span>{translate('name')}</span>
                                  <ContainerSkeleton
                                    withLoading={false}
                                    style={{
                                      width: '150px',
                                      height: '20px',
                                      marginTop: '8px',
                                    }}
                                  />
                                  <span>{translate('createdAt')}</span>
                                  <ContainerSkeleton
                                    withLoading={false}
                                    style={{
                                      width: '154px',
                                      height: '20px',
                                      marginTop: '8px',
                                    }}
                                  />
                                  <span>{translate('lastUpdated')}</span>
                                  <ContainerSkeleton
                                    withLoading={false}
                                    style={{
                                      width: '154px',
                                      height: '20px',
                                      marginTop: '8px',
                                    }}
                                  />
                                </GroupCardLoading>
                              ),
                            )}
                          </GroupsContainer>
                          {myGroups?.total && (
                            <DivPagination>
                              <Pagination
                                total={myGroups?.total}
                                page={myGroupsPage}
                                setPage={handleChangePages}
                                quantityItemsPerPage={QUANTITY_ITEMS_PAGE}
                                firstPageHaveDifferentElement
                                name={translate('groups')}
                              />
                            </DivPagination>
                          )}
                        </>
                      ) : myGroups && myGroups.total > 0 ? (
                        <>
                          <GroupsContainer>
                            {myGroupsPage === 1 && (
                              <CardCreateGroupContainer>
                                <CardCreateGroupContent
                                  onClick={() => {
                                    setCreateGroupPredefined(false);
                                    setModalCreateGroupVisible(true);
                                  }}
                                  allowed
                                  data-testid="create-new-my-group"
                                >
                                  <PlusCircle
                                    data-testid="plus-circle-icon"
                                    size="6.25rem"
                                    weight="fill"
                                  />
                                  <h3>{translate('createNewGroup')}</h3>
                                  <p>
                                    {translate(
                                      'createNewGroupAndAlreadyNewSeries',
                                    )}
                                  </p>
                                </CardCreateGroupContent>
                              </CardCreateGroupContainer>
                            )}
                            {myGroups?.data?.map((group) => (
                              <GroupCard
                                key={`${group.id} ${group.name}`}
                                id={group.id}
                                name={group.name}
                                iconUrl={group.icon_url}
                                createdAt={group.created_at}
                                updatedAt={group.update_at}
                                groupSelected={groupSelected}
                                setGroupSelected={setGroupSelected}
                                predefined={false}
                              />
                            ))}
                          </GroupsContainer>
                          {myGroups?.total && (
                            <DivPagination>
                              <Pagination
                                total={myGroups?.total}
                                page={myGroupsPage}
                                setPage={handleChangePages}
                                quantityItemsPerPage={QUANTITY_ITEMS_PAGE}
                                firstPageHaveDifferentElement
                                name={translate('groups')}
                              />
                            </DivPagination>
                          )}
                        </>
                      ) : (
                        <GroupsContainer>
                          <CardCreateGroupContainer>
                            <CardCreateGroupContent
                              onClick={() => {
                                setCreateGroupPredefined(false);
                                setModalCreateGroupVisible(true);
                              }}
                              allowed
                              data-testid="create-new-my-group"
                            >
                              <PlusCircle
                                data-testid="plus-circle-icon"
                                size="6.25rem"
                                weight="fill"
                              />
                              <h3>{translate('createNewGroup')}</h3>
                              <p>
                                {translate('createNewGroupAndAlreadyNewSeries')}
                              </p>
                            </CardCreateGroupContent>
                          </CardCreateGroupContainer>
                        </GroupsContainer>
                      )}
                    </>
                  ),
                },
              ]}
            />
          </Content>
          <ModalFooter>
            <Button
              buttonType="naked"
              onClick={() => setVisible()}
              data-testid="button-back"
            >
              {translate('sendBack')}
            </Button>
            <Button
              buttonType="primary"
              style={{ minWidth: '5rem' }}
              onClick={sendSeriesToGroup}
              data-testid="button-add"
              disabled={!groupSelected}
            >
              {translate('sendToGroupAdd')}
            </Button>
          </ModalFooter>
        </Container>
        {responseAddSeriesInGroup && groupSelected && (
          <ModalSuccessFail
            visible
            setVisible={() => {
              setResponseAddSeriesInGroup(undefined);
            }}
            icon={
              responseAddSeriesInGroup === 'success' ? (
                <CheckCircle
                  size="2.625rem"
                  style={{ color: light.colors.green4 }}
                />
              ) : (
                <XCircle size="2.625rem" style={{ color: light.colors.red5 }} />
              )
            }
            title={
              responseAddSeriesInGroup === 'success'
                ? `${translate('successfullyAddSeries')}`
                : `${translate('sendUnableToAddSeries')}`
            }
            message={
              responseAddSeriesInGroup === 'success'
                ? `${translate('selectedSeries').replace(
                    'XXX',
                    groupSelected ? groupSelected?.name : '',
                  )}`
                : `${translate('somethingWentWrong').replace(
                    'XXX',
                    groupSelected ? groupSelected?.name : '',
                  )}`
            }
            status={responseAddSeriesInGroup === 'success' ? 'success' : 'fail'}
            groupSelectedId={groupSelected?.id}
            setModalAction={setVisible}
            is4ifavoritesGroup={groupSelected.is4ifavoritesGroup}
          />
        )}
      </Modal>

      {modalCreateGroupVisible && (
        <CreateGroupModal
          categoryType="user"
          visible={modalCreateGroupVisible}
          setVisible={setModalCreateGroupVisible}
          setPage={setMyGroupsPage}
          predefined={createGroupPredefined}
        />
      )}
    </>
  );
};
