import styled, { css } from 'styled-components';

type PropsTextArea = {
  icon: boolean;
  disabled?: boolean;
};

export const ContainerLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.gray5};
  label {
    font-weight: 500;
    font-size: 0.875rem;
  }
`;

type PropsContainerTextArea = {
  error: boolean;
};

export const ContainerTextArea = styled.div<PropsContainerTextArea>`
  p {
    color: ${({ theme }) => theme.colors.red2};
    line-height: 1rem;
    font-style: normal;
    font-size: 0px;
    font-size: ${({ error }) => (error ? '0.875rem' : '0')};
    opacity: 0;

    transition: opacity 0.3s ease-in-out;
    transition: margin-top 0.3s ease-in-out;

    ${({ error }) =>
      error &&
      css`
        margin-top: 0.5rem;
        font-size: 0.875rem;
        opacity: 1;
      `}
  }

  > div {
    border: ${({ theme, error }) =>
      `1px solid ${error ? theme.colors.red2 : theme.colors.gray2}`};
    height: 7.5rem;
    transition: border 0.2s ease-in-out;

    &:hover {
      border: ${({ theme, error }) =>
        `1px solid ${error ? theme.colors.red2 : theme.colors.gray3}`};
    }

    svg {
      margin-left: 0.75rem;
      color: ${({ theme, error }) =>
        error ? theme.colors.red2 : theme.colors.green};
    }
  }
`;

export const ContentTextArea = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  height: 2.625rem;
`;

export const TextArea = styled.textarea<PropsTextArea>`
  width: 100%;
  font-size: 0.875rem !important;
  color: ${({ theme }) => theme.colors.gray6};
  padding-left: ${({ icon }) => (icon ? '1rem' : '0')};
  border: 0;
  outline: 0;
  font-weight: 400;
  height: 100%;
  resize: none;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray4};
  }
`;
