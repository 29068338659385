import { parseISO, parse } from 'date-fns';

// format date to yyyy-MM-dd
export function formatDateToEN(date: Date): string {
  const formatedDate = new Intl.DateTimeFormat('en-ZA', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  })
    .format(date)
    .replace(/\//g, '-');

  return formatedDate && formatedDate;
}

//format date to dd/MM/yyyy
export function formatDateToBR(date: Date): string {
  const formatedDate = new Intl.DateTimeFormat('pt-BR', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  }).format(date);

  return formatedDate && formatedDate;
}

// format date to dd/MMMM/yyyy
export function formatDateToLong(date: Date): string {
  const longDate = new Date(date).toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  });

  return longDate;
}

// format date to dd/MM/yyyy, HH:mm
export function formatDateWithHours(date: Date): string {
  const dateWithHour = new Date(date).toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: 'numeric',
  });

  const formattedDate = [
    dateWithHour.slice(0, 10),
    ',',
    dateWithHour.slice(10),
  ].join('');

  return formattedDate;
}

// get Name of month by number (0-11)
export function getNameMonthBR(index: number): string {
  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  return months[index];
}

// get Quarter of a date
export function getQuarter(dateString: string): number {
  const date = parse(dateString, 'yyyy-MM-dd', new Date());
  const month = Math.floor(date.getMonth() + 1);

  let quarter = 1;

  if (month <= 3) {
    quarter = 1;
  } else if (month > 3 && month <= 6) {
    quarter = 2;
  } else if (month > 6 && month <= 9) {
    quarter = 3;
  } else if (month > 9 && month <= 12) {
    quarter = 4;
  }
  return quarter;
}

export function getMedian(values: number[]): number {
  values.sort((a: number, b: number) => a - b);

  const half = Math.floor(values.length / 2);

  if (values.length % 2) {
    return values[half];
  }

  return (values[half - 1] + values[half]) / 2.0;
}

// A function that recognizes the desired frequency of data
export function getDateFrequency(dateSample: Date[]): string {
  // Sort the date object
  const dateSampleSorted = dateSample.sort((a: any, b: any) => a - b);

  const dateSampleTime = dateSampleSorted.map((elem: any) => elem.getTime());

  // First detect the frequency of data
  // The idea is to get the median of differences in dates from dataset and check
  // how many days it has

  const dates1 = dateSampleTime.slice(1); // 2:ultima
  const dates2 = dateSampleTime.slice(0, -1); // 1:penultima

  const datesDiff = dates1.map((num: any, idx: number) => {
    // To calculate the time difference of two dates
    const diff = num - dates2[idx];
    // To calculate the no. of days between two dates
    return diff / (1000 * 3600 * 24);
  });

  const medianDates = getMedian(datesDiff);

  let frequency;

  if (medianDates < 5) {
    frequency = 'Daily';
  } else if (medianDates >= 5 && medianDates <= 10) {
    frequency = 'Weekly';
  } else if (medianDates > 10 && medianDates < 20) {
    frequency = 'Fortnightly';
  } else if (medianDates >= 2 && medianDates < 40) {
    frequency = 'Monthly';
  } else if (medianDates >= 40 && medianDates < 80) {
    frequency = 'Bimonthly';
  } else if (medianDates >= 80 && medianDates < 110) {
    frequency = 'Quarterly';
  } else if (medianDates >= 110 && medianDates < 220) {
    frequency = 'Half-year';
  } else if (medianDates >= 220 && medianDates < 440) {
    frequency = 'Annual';
  } else {
    frequency = 'Erro!';
  }

  return frequency;
}

export function getDateInFormat(dateString: string, format: string): string {
  try {
    if (format === 'yyyy-MM-dd') {
      return formatDateToEN(parseISO(dateString));
    }
    if (format === 'dd/MM/yyyy') {
      return formatDateToBR(parseISO(dateString));
    }
    if (format === 'dd/MM/yy') {
      return parseISO(dateString).toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
      });
    }
    if (format === 'd/MM/yyyy') {
      return parseISO(dateString).toLocaleDateString('pt-BR', {
        day: 'numeric',
        month: '2-digit',
        year: 'numeric',
      });
    }
    if (format === 'd Month yyyy') {
      return parseISO(dateString).toLocaleDateString('pt-BR', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      });
    }
    if (format === 'MM/dd/yyyy') {
      return parseISO(dateString).toLocaleDateString('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    }
    if (format === 'MM/dd/yy') {
      return parseISO(dateString).toLocaleDateString('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
      });
    }
    if (format === 'MM-dd-yyyy') {
      return parseISO(dateString)
        .toLocaleDateString('en-US', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })
        .replace(/\//g, '-');
    }
    if (format === 'MM-dd-yy') {
      return parseISO(dateString)
        .toLocaleDateString('en-US', {
          day: '2-digit',
          month: '2-digit',
          year: '2-digit',
        })
        .replace(/\//g, '-');
    }
    if (format === 'dd Mon yyyy') {
      return parseISO(dateString).toLocaleDateString('en-ZA', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      });
    }
    if (format === 'yy-MM-dd') {
      const date = parseISO(dateString);
      return new Intl.DateTimeFormat('en-ZA', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
      })
        .format(date)
        .replace(/\//g, '-');
    }
    if (format === 'yy/MM/dd') {
      const date = parseISO(dateString);
      return new Intl.DateTimeFormat('en-ZA', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
      }).format(date);
    }
    if (format === 'yyyy/MM/dd') {
      const date = parseISO(dateString);
      return new Intl.DateTimeFormat('en-ZA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }).format(date);
    }
    if (format === 'dd-MM-yy') {
      const date = parseISO(dateString);
      return new Intl.DateTimeFormat('pt-BR', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
      })
        .format(date)
        .replace(/\//g, '-');
    }
    if (format === 'dd-MM-yyyy') {
      const date = parseISO(dateString);
      return new Intl.DateTimeFormat('pt-BR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
        .format(date)
        .replace(/\//g, '-');
    }
  } catch (error) {
    return 'Error';
  }

  return 'Error';
}

// format date to Jan/2024
export function getDateMonthYear(dateString: string, language: string): string {
  const date = new Date(dateString);
  const localeLang = language === 'pt-br' ? 'pt-BR' : 'en-US';

  const dateFormatted = date
    .toLocaleString(localeLang, {
      month: 'short',
      year: '2-digit',
    })
    .replaceAll('.', '');

  if (language === 'pt-br') {
    return (
      dateFormatted.charAt(0).toUpperCase() +
      dateFormatted.replace(' de ', '/').slice(1)
    );
  }

  return dateFormatted.replace(' ', '/');
}
