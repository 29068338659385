const modelDetailsENUSTRanslations = {
  claasModelDetails: 'Model Details',
  claasModelDetailsDescription: 'Steps and strategies used in the model.',
  claasModelDetailsStep: 'Step',
  claasModelDetailsStrategy: 'Strategy',
  claasFillNumValues: 'Fill Numeric Values',
  claasFillCatValues: 'Fill Categorical Values',
  claasScaling: 'Scaling',
  claasEncoding: 'Encoding',
  claasBalancing: 'Balancing',
  claasFeatureSelection: 'Feature Selection',
  claasClassfier: 'Classifier',

  fillNumValuesDesc: 'Strategy used to deal with missing numeric values.',
  fillCatValuesDesc: 'Strategy used to deal with missing categorical values.',
  scalingDesc: 'Method for scaling numerical values in the dataset.',
  encondingDesc: 'Method for encoding categorical variables.',
  balancingDesc: 'Strategy for dealing with unbalanced target variable.',
  featSelectionDesc:
    'Method for selecting the most relevant variables in the dataset.',
  classfierDesc: 'Machine Learning Classifier used to make the predictions.',

  medianHyp: 'median',
  meanHyp: 'mean',
  zeroHyp: 'zero',
  modeHyp: 'mode',
  keepHyp: 'keep',
  robustHyp: 'robust',
  minmaxHyp: 'minmax',
  noneHyp: 'none',
  weightsHyp: 'weights',
  rfeHyp: 'rfe',
  varianceHyp: 'variance',
  standardHyp: 'standard',
  oversamplingHyp: 'oversampling',
  undersamplingHyp: 'undersampling',
};

export default modelDetailsENUSTRanslations;
