const modelDetailsPTBRTRanslations = {
  claasModelDetails: 'Detalhes do modelo',
  claasModelDetailsDescription: 'Etapas e estratégias utilizadas no modelo.',
  claasModelDetailsStep: 'Etapa',
  claasModelDetailsStrategy: 'Estratégia',
  claasFillNumValues: 'Preenchimento de valores numéricos',
  claasFillCatValues: 'Preenchimento de valores categóricos',
  claasScaling: 'Ajuste de escala',
  claasEncoding: 'Codificação',
  claasBalancing: 'Balanceamento',
  claasFeatureSelection: 'Seleção de variáveis',
  claasClassfier: 'Classificador',

  fillNumValuesDesc:
    'Estratégia usada para lidar com valores numéricos ausentes.',
  fillCatValuesDesc:
    'Estratégia usada para lidar com valores categóricos ausentes.',
  scalingDesc: 'Método para escalar valores numéricos no conjunto de dados.',
  encondingDesc: 'Método de codificação de variáveis categóricas.',
  balancingDesc: 'Estratégia para lidar com variável alvo desbalanceada.',
  featSelectionDesc:
    'Método para selecionar as variáveis mais relevantes no conjunto de dados.',
  classfierDesc:
    'Classificador de aprendizado de máquina usado para fazer as previsões.',

  medianHyp: 'mediana',
  meanHyp: 'média',
  modeHyp: 'moda',
  zeroHyp: 'zero',
  keepHyp: 'manter',
  robustHyp: 'robusto',
  minmaxHyp: 'minmax',
  noneHyp: 'nenhum',
  weightsHyp: 'pesos',
  rfeHyp: 'erf',
  varianceHyp: 'variância',
  standardHyp: 'padronização',
  oversamplingHyp: 'oversampling',
  undersamplingHyp: 'undersampling',
};

export default modelDetailsPTBRTRanslations;
