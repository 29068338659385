const modelSpecificsENUSTranslations = {
  modelSpecTitle: 'Model Specifics',
  modelSpecDescription:
    'Browse relevant information regarding a particular model.',
  modelInfoTitle: 'Model Info',
  modelSpecificsCoef: 'Coefficient',
  modelSpecArimaOrder: 'ARIMA order',
  modelSpecificsPValue: 'P-Value',
  modelSpecificsSpecification: 'Model Specification',
  modelSpecCheckResiduals: 'Check Residuals',
  modelSpecConfidenceInterval: 'Confidence interval',
  modelSpecLagChartLabel: 'Lag',
  modelSpecResidualsHistogramLabel: 'Residuals',
  modelSpecSeriesResiduals: 'Residuals',
  modelSpecResidualsChartLabel: 'Residuals',
  modelSpecResidualsCountChartLabel: 'Count',
  modelSpecModelFitTitle: 'Historical and Model Fit',
  modelSpecActualForecastTitle: 'Historical and Forecast',
  modelSpecAnnualVariationTitle: 'Annual Variation (%)',
  modelSpecHistorical: 'Historical',
  modelSpecForecast: 'Forecast',
  modelSpecAnnualLevelTitle: 'Annual Level',
  modelBreakdownTitle: 'Explaining the Results',
  modelBreakdownDescription:
    'Decompose the historical results based on the main determinants identified by your preferred model.',
  modelBreakdownInfo: `The charts show the series percentage variations (line and dots) broken down in main components (bars), such that the sum of the latter is equivalent to the first. Flow variables are the opposite of stock variables. <br/>
  Technically, flow variables are measured in a certain period of time, never transcending it. <br/>
  Examples: GDP is a flow variable since it is measured in quarters, semesters or years, whereas population is a stock variable, since from one month to the next one population stock is not reseted`,
  modelBreakdownYVariation: 'Y Variation',
  modelSpecificsVarImportanceTitle: 'Variable Importance',
  modelSpecificsVarImportanceLabel: 'Show n variables',
  modelSpecificsVarImportanceExplVars: 'Explanatory variables',
  modelSpecificsArimaBreakdownIsNotAvailableFirst3Models:
    'Breakdown is only available for the first 3 arima models.',
  modelSpecificsArimaBreakdownIsNotAvailableDailyWeeklyFortnightly:
    'Breakdown is not available for daily, weekly or fortnightly data.',
  modelSpecificsArimaBreakdownOnlyAvailableForMonthlyData:
    'Only available for monthly data.',

  modelSpecErrors: 'XXX is not available for this model',
  modelSpecAnnualLevel: 'Annual Level',
  modelSpecAnnualVariation: 'Annual Variation',
  modelSpecModelBreakdown: 'Breakdown',
  textDataType: 'Data Type',
  textTitleAxisIncreaseOfTheMeanSquaredError:
    'Increase of the Mean Squared Error',
};

export default modelSpecificsENUSTranslations;
