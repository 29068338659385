import styled from 'styled-components';

export const Options = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  flex-wrap: wrap;

  > div {
    > span {
      display: block;
      margin-bottom: 0.5rem;

      font-weight: 500;
      font-size: 0.875rem;
      line-height: 150%;
      color: ${({ theme }) => theme.colors.gray5};
    }

    div {
      display: flex;
      gap: 1.5rem;
    }
  }
`;
