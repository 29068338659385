import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Modal } from 'src/components/Modal';
import { Button } from 'src/components/Button';
import { CheckCircle, Copy, File, X } from 'phosphor-react';
import { CodeBlock, github } from 'react-code-blocks';
import { useAuth0 } from '@auth0/auth0-react';
import { ModalFooter } from 'src/components/Modal/Footer/styles';

import {
  CloseModalButton,
  CodeBlockContainer,
  Container,
  CopyCodeButton,
  GetTokenButton,
  LanguageButton,
  LanguageSelector,
  ProjectIDContainer,
} from './styles';
import { sample } from './documentationCode';

interface ScriptModalProps {
  projectID: string;
  visible: boolean;
  setVisible: (value: boolean) => void;
}

const linkToDocumentation = process.env.REACT_APP_LINK_PREDICT_DOCS;

export const ScriptCallModal: React.FC<ScriptModalProps> = ({
  visible,
  setVisible,
  projectID,
}) => {
  const { t: translate } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();

  const [language, setLanguage] = useState('python');
  const [codeCopied, setCodeCopied] = useState(false);
  const [apiTokenCopied, setAPITokenCopied] = useState(false);

  const copyCodeToClipboard = async () => {
    setCodeCopied(true);
    const token = await getAccessTokenSilently();

    navigator.clipboard.writeText(
      language === 'python'
        ? sample.python
            .replace('<your project id>', projectID)
            .replace('<your auth token>', token)
        : language === 'java'
        ? sample.java
            .replace('<your project id>', projectID)
            .replace('<your auth token>', token)
        : sample.bash
            .replace('<your project id>', projectID)
            .replace('<your auth token>', token),
    );

    setTimeout(() => {
      setCodeCopied(false);
    }, 3000);
  };

  const copyAPIToken = async () => {
    setAPITokenCopied(true);
    const token = await getAccessTokenSilently();

    navigator.clipboard.writeText(token);

    setTimeout(() => {
      setAPITokenCopied(false);
    }, 3000);
  };

  const openDocumentation = () => {
    const link = document.createElement('a');
    link.setAttribute('href', linkToDocumentation ?? '');
    link.setAttribute('target', '_blank');
    link.style.visibility = 'hidden';
    link.click();
  };

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <Container>
        <h3>{translate('predictAPIModalTitle')}</h3>
        <p>{translate('predictAPIModalDescription')}</p>
        <ProjectIDContainer>
          <div>
            <p>{translate('projectID')}</p>
            <span>{projectID}</span>
          </div>
        </ProjectIDContainer>
        <LanguageSelector>
          <GetTokenButton
            copied={apiTokenCopied}
            data-testid="get-token-button"
            onClick={copyAPIToken}
          >
            {apiTokenCopied ? <CheckCircle size={20} /> : <Copy size={20} />}
            <p>
              {apiTokenCopied
                ? `API Token ${translate('copied')}!`
                : `${translate('copy')} API Token`}
            </p>
          </GetTokenButton>
          <div>
            <LanguageButton
              onClick={() => {
                setLanguage('python');
                setCodeCopied(false);
              }}
              selected={language === 'python'}
              data-testid="button-language-python"
              data-cy="button-language-python"
            >
              <p>Python</p>
            </LanguageButton>
            <LanguageButton
              onClick={() => {
                setLanguage('java');
                setCodeCopied(false);
              }}
              selected={language === 'java'}
              data-testid="button-language-java"
              data-cy="button-language-java"
            >
              <p>Java</p>
            </LanguageButton>
            <LanguageButton
              onClick={() => {
                setLanguage('bash');
                setCodeCopied(false);
              }}
              selected={language === 'bash'}
              data-testid="button-language-bash"
              data-cy="button-language-bash"
            >
              <p>Bash</p>
            </LanguageButton>
          </div>
        </LanguageSelector>
        <div />
        <div />
        <CodeBlockContainer>
          <CopyCodeButton
            onClick={copyCodeToClipboard}
            data-testid="copy-code-button"
            data-cy="copy-code-button"
            copied={codeCopied}
          >
            <div>
              {codeCopied ? <CheckCircle size={20} /> : <Copy size={20} />}
              <p>
                {codeCopied ? `${translate('copied')}!` : translate('copy')}
              </p>
            </div>
          </CopyCodeButton>
          <div>
            <CodeBlock
              language={language}
              text={
                language === 'python'
                  ? sample.python.replace('<your project id>', projectID)
                  : language === 'java'
                  ? sample.java.replace('<your project id>', projectID)
                  : sample.bash.replace('<your project id>', projectID)
              }
              showLineNumbers={false}
              theme={github}
              wrapLines
              codeBlock
            />
          </div>
        </CodeBlockContainer>
        <CloseModalButton
          type="button"
          onClick={() => setVisible(false)}
          data-cy="close-script-modal-button"
          data-testid="close-script-modal-button"
        >
          <X size="1.25rem" />
        </CloseModalButton>
      </Container>
      <ModalFooter>
        <Button
          buttonType="naked"
          onClick={openDocumentation}
          data-cy="see-documentation-button"
          data-testid="see-documentation-button"
          icon={<File size="1.125rem" />}
          className="naked-button-blue"
        >
          {translate('predictSeeDocumentation')}
        </Button>
        <Button
          buttonType="primary"
          onClick={() => setVisible(false)}
          data-cy="ok-button"
          data-testid="ok-button"
        >
          Ok
        </Button>
      </ModalFooter>
    </Modal>
  );
};
