import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;

  margin-left: 5rem;
  margin-right: 5rem;

  gap: 1rem;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-column-gap: 1rem;
`;
