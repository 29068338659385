export const AISelectionPTBRTranslate = {
  AISelectionMessageErrorServerCanNotFind:
    'O servidor não pode encontrar o recurso solicitado.',
  AISelectionMessageErrorServerRequestedResource:
    'O recurso solicitado não existe.',
  AISelectionMessageErrorEngineNotSupported:
    'Mecanismo de projeto não suportado.',
  AISelectionMessageErrorAnInternalError:
    'Ocorreu um erro interno ao processar a solicitação.',
  AISelectionLoadingTitle: 'Estamos trabalhando nisso!',
  AISelectionLoadingDescription:
    'Isso pode levar um tempo... Mas não se preocupe, o seu modelo de Seleção de IA está sendo processado e em breve estará pronto!',
  AISelectionQuarterlySeriesSummaryIsOnlyAvailable:
    'O resumo de série trimestral está disponível apenas para séries com pelo menos 1 trimestre de observação.',
  AISelectionNOfDays: 'Nº dias',
};
