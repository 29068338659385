const modellingStartENUSTranslations = {
  modellingStartTitle: 'What kind of problem are we going to solve?',
  modellingStartSubTitle:
    'Indicate whether we are dealing with a time series or a classification challenge.',
  newTimeSeriesTitle: 'Time Series',
  newTimeSeriesSubTitle:
    'Forecast the evolution of your sales, build different macroeconomic scenarios, or model any series for which dynamics over time are relevant.',
  newClassificationTitle: 'Classification',
  newClassificationSubTitle:
    'Predict the behavior of your customers, estimate the risks of your credit portfolio or build predictive models for any phenomenon that takes on categorical values.',
  modellingStartButtonText: 'Begin your project',
};

export default modellingStartENUSTranslations;
