export const PanelAdminENTranslate = {
  PanelAdminTitle: 'Global Admin',
  PanelAdminDescription:
    'View and edit user status depending on available licenses',
  PanelAdminQuantityLicensePlural: 'You’re using <b>XXX</b> of YYY licenses',
  PanelAdminQuantityLicenseSingular: 'You’re using <b>XXX</b> of YYY license',
  PanelAdminInviteNewUser: 'Invite new user',
  PainelAdminNamePagination: 'users',
  PainelAdminErrorLoad: 'Unable to load users.',
};
