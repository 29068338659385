export const AISelectionInformationENTranslate = {
  aiSelectionInformationTitle: 'AI Selection',
  aiSelectionInformationDescription:
    'Optimize your decision-making based on AI Selection with insights from the results. Access forecasts and understand the impact of variables on the behavior of the series for each data point.',
  aiSelectionInformationSeeMoreInformation:
    'See more information about the model',
  aiSelectionInformationBaseModel: 'Base Model',
  aiSelectionInformationSample: 'Sample',
  aiSelectionInformationTransformation: 'Transformation',
  aiSelectionInformationSeeLessInformation:
    'See less information about the model',
};
