import { Tr } from 'src/components/Table';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 70vw;
  height: 80vh;
  max-height: 43rem;
  max-width: 62.25rem;
  margin-bottom: -1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  img {
    width: 3rem;
    height: 3rem;
    border-radius: 4px;
    margin-left: 1.5rem;
  }
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  > svg {
    color: ${({ theme }) => theme.colors.gray4};
    transition: 0.2s color;

    &:hover {
      color: ${({ theme }) => theme.colors.gray5};
    }
  }
`;

export const DivInfoSeriesPremium = styled.div`
  display: flex;
  margin-left: 3.375rem;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;

  p {
    font-style: normal;
    font-size: 0.875rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray5};

    img {
      vertical-align: middle;
      width: 0.875rem;
      height: 0.875rem;
      margin-left: 0.313rem;
      margin-bottom: 0.188rem;
    }

    span {
      color: ${({ theme }) => theme.colors.yellow4};
      display: inline-block;
      vertical-align: middle;
      margin-left: 0.313rem;
    }

    a {
      color: ${({ theme }) => theme.colors.primary};
      text-decoration: underline;
    }
  }
`;

export const Description = styled.div`
  margin-left: 1.5rem;
  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
    color: ${({ theme }) => theme.colors.gray6};
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray5};
  }
`;

export const ContainerSeriesInfo = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;

  padding-right: 0.5rem;
  table {
    border-spacing: 0 2rem;
    margin: -2rem 0;
    thead {
      position: sticky;
      top: -0.063rem;
      z-index: 99;
      background: ${({ theme }) => theme.colors.white};
      tr {
        th {
          padding-top: 2rem;
        }
      }
    }
  }
`;

interface NameColumnProps {
  isTitle?: boolean;
}

export const NameColumn = styled.div<NameColumnProps>`
  > label {
    p {
      margin-left: 2rem;
      ${({ isTitle }) =>
        isTitle
          ? css`
              color: ${({ theme }) => theme.colors.gray6} !important;
              font-weight: 700;
              font-size: 0.75rem;
              line-height: 1.375rem;
              opacity: 0.8;
            `
          : css`
              color: ${({ theme }) => theme.colors.gray5};
            `}
    }
    span {
      margin: 0;
    }
  }
`;

export const DivPagination = styled.div`
  display: flex;
  flex: auto;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0px 1.25rem;
`;

interface TrGroupsProps {
  disabled?: boolean;
}

export const TrGroups = styled(Tr)<TrGroupsProps>`
  p,
  td {
    color: ${({ theme, disabled }) =>
      disabled ? theme.colors.gray4 : theme.colors.gray5} !important;
  }
`;

export const PremiumDiv = styled.div`
  display: flex;
  padding-left: 1.25rem;
  justify-content: flex-start;
  align-items: center;
  height: 2.75rem;
  img {
    width: 1.063rem;
    height: 1.063rem;
  }
`;

export const DivLock = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 1.75rem;
    font-size: 0.875rem !important;
    font-weight: 400;
    line-height: 1.375rem;
    padding-left: 1rem;
  }

  > img {
    margin-left: 0px;
  }
`;
