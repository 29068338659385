import React, { useEffect, useState } from 'react';

import { AxiosError } from 'axios';
import { addMinutes } from 'date-fns';
import { PaperPlaneTilt } from 'phosphor-react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import AIChatBackground from 'src/assets/ai-chat-background.svg';
import { ReactComponent as AIChatImage } from 'src/assets/ai-chat-image.svg';
import { ReactComponent as AnbimaIcon } from 'src/assets/anbima-icon.svg';
import { ReactComponent as SparkleIcon } from 'src/assets/sparkle.svg';
import { Button } from 'src/components/Button';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { BackToHome } from 'src/feature-store/components/BackToHome';
import { apiAIChat } from 'src/feature-store/service/apiAIChat';
import { DataError } from 'src/interface/axios';
import { RootState } from 'src/redux/store';
import { queryClient } from 'src/service/queryClient';
import { Head } from 'src/components/Head';

import { BigNumbers } from './components/BigNumbers';
import { Response } from './components/Response';
import {
  AnbimaButtonIcon,
  BackToHomeContainer,
  ChatContainer,
  Container,
  Content,
  GridContainer,
  IconButtonContainer,
  LoadingDots,
  QuestionForm,
  ResponseContainer,
  StatusContainer,
  TextArea,
  WelcomeContent,
  WelcomeText,
} from './styles';
import {
  BigNumbersDataProps,
  ChatResponseProps,
  HistoricalChatProps,
  HistoricalDataProps,
} from './types';

const QUANTITY_ITEMS_PAGE = 5;

export const AIChat: React.FC = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const userIsAnbima = user?.permissions?.includes('ask:questions-anbima');

  const [page, setPage] = useState(0);
  const [historical, setHistorical] = useState<HistoricalDataProps[]>([]);
  const [loadingResponse, setLoadingResponse] = useState(false);
  const [newQuestionsAmount, setNewQuestionsAmount] = useState(0);
  const [newQuestionsId, setNewQuestionsId] = useState(0);
  const [historicalTotal, setHistoricalTotal] = useState<number>(1);
  const [questionCharactersError, setQuestionCharactersError] = useState(false);
  const [bigNumbersVisible, setBigNumbersVisible] = useState<boolean>(true);

  const { control, handleSubmit, getValues, watch, setValue } = useForm();
  const { t: translate } = useTranslation();

  const {
    data: bigNumbersData,
    isLoading: isLoadingBigNumbers,
    isFetching: isFetchingBigNumbers,
    isError: isErrorBigNumbers,
  } = useQuery(['ai chat - highlights'], async () => {
    const { data } = await apiAIChat.get<BigNumbersDataProps[]>('/highlights');

    return data;
  });

  const { isLoading, isFetching, isError } = useQuery(
    ['ai chat - historical', page],
    async () => {
      const { data } = await apiAIChat.get<HistoricalChatProps>(
        `/questions?skip=${
          page * QUANTITY_ITEMS_PAGE + newQuestionsAmount
        }&limit=${QUANTITY_ITEMS_PAGE}`,
      );

      if (page === 0) {
        setHistoricalTotal(data.total);
      }

      setHistorical([...data.data.reverse(), ...historical]);
    },
  );

  const handleSendQuestion = async (formData: FieldValues) => {
    const question = formData.aiChatQuestion;

    const allHistorical = [...historical];
    allHistorical.push({
      id: newQuestionsId.toString(),
      question,
      answer: '',
      indicators: [],
      created_at: addMinutes(new Date(), 180).toISOString(),
      isLoading: true,
      errorCode: undefined,
    });

    setNewQuestionsId(newQuestionsId + 1);

    setHistorical(allHistorical);
    setNewQuestionsAmount(newQuestionsAmount + 1);

    setValue('aiChatQuestion', '');
    setLoadingResponse(true);

    try {
      const { data } = await apiAIChat.post<ChatResponseProps>('questions', {
        message: question,
      });

      allHistorical[allHistorical.length - 1].answer = data.answer;
      allHistorical[allHistorical.length - 1].indicators = data.indicators;
      allHistorical[allHistorical.length - 1].isLoading = false;

      setHistorical([...allHistorical]);
      sendScrollToBottom(true);
    } catch (error) {
      const err = error as AxiosError<DataError>;

      allHistorical[allHistorical.length - 1].isLoading = false;
      allHistorical[allHistorical.length - 1].errorCode = err?.response?.status;

      setHistorical([...allHistorical]);
    }

    setLoadingResponse(false);
  };

  const sendScrollToBottom = (smooth: boolean) => {
    const responseContainer = document.getElementById('ai-chat-responses');

    if (responseContainer && responseContainer.scrollTo) {
      responseContainer.scrollTo({
        top: responseContainer.scrollHeight,
        behavior: smooth ? 'smooth' : 'auto',
      });
    }
  };

  const handleOnScroll = (event: React.UIEvent<HTMLDivElement>): void => {
    const onTop =
      event.currentTarget.scrollHeight - event.currentTarget.scrollTop >=
      event.currentTarget.scrollHeight * 0.7;

    if (
      onTop &&
      !isLoading &&
      !isFetching &&
      !isError &&
      historicalTotal &&
      (page + 1) * QUANTITY_ITEMS_PAGE < historicalTotal
    ) {
      setPage(page + 1);
    }
  };

  const questionIsOnlyWhitespace =
    getValues('aiChatQuestion')?.split(' ').join('').length === 0;

  const question = watch('aiChatQuestion');

  useEffect(() => {
    const textArea = document.getElementById('ai-chat-textarea');
    const textAreaContainer = document.getElementById(
      'ai-chat-textarea-container',
    );

    if (textArea && textAreaContainer) {
      textArea.style.height = 'auto';
      textAreaContainer.style.height = 'auto';

      const currentHeight = textArea.scrollHeight;

      const height =
        currentHeight > 135
          ? `${135 / 16}rem`
          : `${currentHeight / 16 || 1.688}rem`;

      textArea.style.height = height;
      textAreaContainer.style.height = height;
    }
  }, [question]);

  useEffect(() => {
    if (page < 2 && isLoading === false) sendScrollToBottom(true);
  }, [isFetching, isLoading, page]);

  useEffect(() => {
    if (isError) {
      const reRunQuery = async () => {
        await queryClient.invalidateQueries('ai chat - historical');
      };

      reRunQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container data-testid="ai-chat-container">
      <Head title="Chat4i" />

      <GridContainer>
        <Content bigNumbersVisible={userIsAnbima && bigNumbersVisible}>
          <BackToHomeContainer>
            <BackToHome />
          </BackToHomeContainer>

          {(isLoading || isFetching) && page === 0 ? (
            <StatusContainer>
              <ContainerSkeleton
                style={{ height: '100%', width: '100%' }}
                className="ai-chat-container"
                data-testid="ai-chat-loading-historical"
              />
            </StatusContainer>
          ) : isError ? (
            <StatusContainer className="ai-chat-container">
              <ContainerMaintenance
                content="chat error"
                text={translate('aiChatTitleError')}
                description={translate('aiChatDescriptionError')}
                data-testid="ai-chat-historical-error"
              />
            </StatusContainer>
          ) : (
            <ChatContainer>
              {userIsAnbima && (isLoadingBigNumbers || isFetchingBigNumbers) ? (
                <StatusContainer style={{ height: '100%', width: '17.5rem' }}>
                  <ContainerSkeleton
                    style={{ height: '100%', width: '17.5rem' }}
                    className="ai-chat-container"
                    data-testid="ai-chat-loading-highlights"
                  />
                </StatusContainer>
              ) : userIsAnbima && isErrorBigNumbers ? (
                <StatusContainer
                  className="ai-chat-container"
                  style={{ height: '100%', width: '17.5rem' }}
                >
                  <ContainerMaintenance
                    style={{ height: '100%', width: '17.5rem' }}
                    content="chat error"
                    text={translate('aiChatTitleError')}
                    description={translate('aiChatDescriptionError')}
                    data-testid="ai-chat-highlights-error"
                  />
                </StatusContainer>
              ) : userIsAnbima && bigNumbersVisible ? (
                <BigNumbers
                  isVisible={bigNumbersVisible}
                  setVisible={setBigNumbersVisible}
                  bigNumbersData={bigNumbersData}
                  handleSendQuestion={handleSendQuestion}
                />
              ) : userIsAnbima && !bigNumbersVisible ? (
                <IconButtonContainer onClick={() => setBigNumbersVisible(true)}>
                  <AnbimaButtonIcon>
                    <AnbimaIcon />
                  </AnbimaButtonIcon>
                </IconButtonContainer>
              ) : null}

              <ResponseContainer
                id="ai-chat-responses"
                onScroll={handleOnScroll}
              >
                {(!historicalTotal ||
                  page * QUANTITY_ITEMS_PAGE + QUANTITY_ITEMS_PAGE >=
                    historicalTotal) && (
                  // eslint-disable-next-line react/jsx-indent
                  <WelcomeContent
                    className="ai-chat-container"
                    data-testid="ai-chat-welcome-content"
                  >
                    <img
                      src={AIChatBackground}
                      alt="background lines"
                      className="ai-chat-background"
                    />

                    <div>
                      <AIChatImage className="ai-chat-background-logo" />

                      <WelcomeText>
                        <h2>{translate('aiChatWelcomeTitle')}</h2>

                        <p>{translate('aiChatWelcomeDescription')}</p>
                      </WelcomeText>
                    </div>
                  </WelcomeContent>
                )}

                {(isLoading || isFetching) && <Response loadingCard />}

                {historical.map((response) => (
                  <Response
                    key={response.id}
                    id={response.id}
                    question={response.question}
                    answer={response.answer}
                    indicators={response.indicators}
                    dateTime={response.created_at}
                    answerLoading={response.isLoading}
                    answerErrorCode={response.errorCode}
                  />
                ))}
              </ResponseContainer>
            </ChatContainer>
          )}

          <QuestionForm
            onSubmit={handleSubmit(handleSendQuestion)}
            className="container-form-ai-chat"
          >
            <SparkleIcon />

            <Controller
              name="aiChatQuestion"
              key="aiChatQuestion"
              control={control}
              rules={{
                required: true,
                maxLength: 2000,
              }}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <div id="ai-chat-textarea-container">
                  <TextArea
                    id="ai-chat-textarea"
                    placeholder={translate('aiChatQuestionPlaceholder')}
                    onChange={(e) => {
                      onChange(e);

                      if (e.target.value.length > 2000)
                        setQuestionCharactersError(true);
                      else if (questionCharactersError) {
                        setQuestionCharactersError(false);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();

                        if (
                          value?.trim() &&
                          !isError &&
                          !questionCharactersError &&
                          !loadingResponse
                        ) {
                          handleSendQuestion({ aiChatQuestion: value });
                        }
                      }
                    }}
                    value={value}
                    rows={1}
                    disabled={isError}
                    data-testid="question-input"
                  />

                  <span
                    style={{
                      display: questionCharactersError ? 'flex' : 'none',
                    }}
                    data-testid="question-input-error"
                  >
                    {translate('aiChatQuestionCharacters')}
                  </span>
                </div>
              )}
            />

            {loadingResponse ? (
              <LoadingDots data-testid="loading-response">
                <div />
                <div />
                <div />
              </LoadingDots>
            ) : (
              <Button
                type="submit"
                buttonType="primary"
                icon={<PaperPlaneTilt weight="fill" />}
                disabled={
                  isError ||
                  questionCharactersError ||
                  !getValues('aiChatQuestion') ||
                  questionIsOnlyWhitespace
                }
                data-testid="send-question"
              />
            )}
          </QuestionForm>
        </Content>
      </GridContainer>
    </Container>
  );
};
