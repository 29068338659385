export const projectOverviewENTranslate = {
  projectOverviewTitle: 'Project Overview',
  projectOverviewDescription: 'Scan and compare all variables in your project.',
  projectOverviewTitleTable: 'Dependent Variables',
  projectOverviewFirstColumn: 'Dependent Variable',
  projectOverviewChart: 'chart',
  projectOverviewSelectAVariable: 'Select a variable',
  projectOverviewSelectAVariableDescription:
    'Select at least one variable to check the comparison of the dependent variables of your project.',
  projectOverviewOnly4Variables: 'It is possible to select only 4 variables',
  projectOverviewExportTitle: 'Export',
  projectOverviewExportDescription:
    "Export the historical series and forecasts of your project's dependent variables to a .xlsx file.",
  projectOverviewExportDownload: 'Export',
  projectOverviewExportPreparingData: 'Preparing data',
  projectOverviewFailedEditTitle: 'Something went wrong',
  projectOverviewFailedEditDescription:
    'Unfortunately, we were not able to customize your table. Please, try again. If the problem persists, contact our support.',
  projectOverviewTransformation: 'Transformation',
  projectOverviewModel: 'Model',
  projectOverviewCustomizeYourTable: 'Customize your table',
  projectOverviewRenameVariables: 'Rename variables',
  projectOverviewEditDescription:
    'Mark the checkboxes to change the columns on your variables table.',
  projectOverviewAdjustSettings: 'Adjust settings',
  projectOverviewSummaryTitle: 'Project Summary',
  projectOverviewSummaryDescription: 'Key statistics of your project.',
  projectOverviewSummaryNumberOfVariables: 'Number of variables',
  projectOverviewSummaryNumberOfEstimatedModels: 'Number of estimated models',
  projectOverviewSummaryBestAccuracy: 'Lowest error',
  projectOverviewSummaryWorstAccuracy: 'Highest error',
  projectOverviewSummaryProjectAccuracyAverage: 'Project mean error',
  projectOverviewSummaryInformation:
    'Information shown for recent projects only.',
  projectOverviewRenameVariableTitle: 'Rename Variables',
  projectOverviewRenameVariableDescription:
    'Rename the variables of your project as you wish.',
  projectOverviewRenameVariableErrorTitle: 'Something went wrong',
  projectOverviewRenameVariableErrorDescription:
    'Unfortunately, we were not able to rename the variables. Please, try again. If the problem persists, contact our support.',
  projectOverviewRenameVariableMessageInput3Caracteres:
    'Must be at least 3 characters',
  projectOverviewRenameVariableInputMax50Caracteres:
    'Must not be more than 50 characters',
  projectOverviewRenameVariableInputInvalid: 'No special characters allowed',
  projectOverviewRenameVariableInputRepeated: 'Must not be repeated',
  projectOverviewRenameVariableInputSameAnotherOriginalName:
    'Must not be the same as the original name of another variable',
  projectOverviewTooltipExport:
    'The exported file is not displayed in the same pattern that is on the screen.',
  projectOverviewSearchDependentVariablePlaceholder:
    'Search dependent variables',
  projectOverviewSearchDependentVariableNotFound: `We couldn't find any dependent variable containing`,
};
