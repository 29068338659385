const sendToGroupENTranslations = {
  chooseAGroup: 'Choose a Group to send series',
  clickAddToContinue: 'Click “Add” to continue',
  createNewGroup: 'Create new group',
  sendToGroupAdd: 'Add',
  createNewGroupAndAlreadyNewSeries:
    'Create a new group and already add new series',
  successfullyAddSeries: 'Series added successfully',
  selectedSeries: 'The selected series has been added to XXX',
  backToIndicator: 'Back to indicator',
  goToGroup: 'Go to group',
  sendBack: 'Back',
  sendToGroupSearchGroup: 'Search group',
  sendUnableToLoadPreDefinedGroup: 'Unable to load 4i favorites groups.',
  sendUnableToLoadYourGroup: 'Unable to load your favorites groups.',
  sendUnableToAddSeries: 'Unable to add series',
  somethingWentWrong:
    'Something went wrong in the process of adding the series to XXX group. Try to select fewer openings',
  sendMyGroupTitleTab: 'My favorites',
  sendPreDefinedTitleTab: '4i favorites',
};

export default sendToGroupENTranslations;
