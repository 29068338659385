import React from 'react';

import { Info } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { Card } from 'src/components/Card';
import { Head } from 'src/components/Head';
import api from 'src/models/service/api';
import { RootState } from 'src/redux/store';
import { useNavigate } from 'react-router-dom';
import { setModelProdNavigation } from 'src/models/redux/reducers/ModelProdNavigation';

import { ModelPerformance } from '../components/ModelPerformance';
import { ModelPerformanceInfo } from '../ModelExplorer/styles';
import { ModelInformation } from '../ModelExplorer/types';
import { Container, ShowModelButton } from './styles';
import { Predictions } from '../components/Predictions';

export const ClaaSModelInProduction: React.FC = () => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    id: projectId,
    modelInProduction,
    projectError,
  } = useSelector((state: RootState) => state.claasProject);

  const {
    data: modelInfoData,
    isFetching: isFetchingModelInfo,
    isLoading: isLoadingModelInfo,
    isError: isModelInfoErrored,
  } = useQuery(
    ['model in production', 'model info', projectId, modelInProduction],
    async () => {
      const { data } = await api.get<ModelInformation>(
        `/classification/projects/${projectId}/models/${modelInProduction?.type}/info`,
      );

      return data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled: !!projectId && !!modelInProduction?.type,
    },
  );

  const handleSeeModelDetails = () => {
    navigate(`/models/classification/${projectId}/model-explorer`);
    dispatch(
      setModelProdNavigation({
        userCameFromModelProd: true,
      }),
    );
  };

  return (
    <Container>
      <Head title={translate('claasModelInProductionTitle')} />
      <div className="containerLinear">
        <Card
          textCard={`${translate('claasModelInProductionTitle')} ${
            modelInProduction?.name ? `- ${modelInProduction?.name}` : ``
          }`}
          textDescription={translate('claasModelInProdPerformanceDescription')}
        />
        <ModelPerformanceInfo>
          <Info
            size="1.25rem"
            data-tooltip-id="models-classification-model-in-production"
            data-tooltip-html={translate('modelPerformanceInfo')}
          />
        </ModelPerformanceInfo>
        <ModelPerformance
          data={modelInfoData}
          isFetching={isFetchingModelInfo}
          isLoading={isLoadingModelInfo}
          isErrored={isModelInfoErrored || projectError}
        />
        <ShowModelButton
          onClick={handleSeeModelDetails}
          data-test-id="see-model-details-button"
          data-cy="see-model-details-button"
        >
          {translate('seeModelDetails')}
        </ShowModelButton>
      </div>

      <Predictions />

      <Tooltip
        id="models-classification-model-in-production"
        className="customTooltipTheme"
      />
    </Container>
  );
};
