import styled, { css, keyframes } from 'styled-components';

import { ContainerWarning } from '../../ContainerWarning';

interface FeatureStoreVariablesContainerProps {
  isOpen: boolean;
}

const open = keyframes`
  from{
    left:-22rem;
  }
  to{
    left:0px;
  }
`;

const close = keyframes`
  from{
    left:0px;
  }
  to{
    left:-22rem;
  }
`;

export const FeatureStoreVariablesContainer = styled.aside<FeatureStoreVariablesContainerProps>`
  position: fixed;
  top: 4.625rem;
  left: -22rem;
  z-index: 2;

  display: flex;
  flex-direction: column;

  @media (max-width: 835px) {
    bottom: 14.1875rem;
  }

  width: 22rem;
  padding: 1.5rem 0;

  background: ${({ theme }) => theme.colors.white};

  border-right: 1px solid ${({ theme }) => theme.colors.gray2};
  box-shadow: 5px 6px 12px 0px rgba(113, 128, 150, 0.16);

  animation: ${({ isOpen }) => (isOpen ? open : close)} 1s forwards;
`;

export const ContentSearch = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0 1.5rem;
  svg {
    color: ${({ theme }) => theme.colors.gray4} !important;
    margin-left: 0 !important;
  }

  margin: 1.5rem 0;
`;

export const FSHeader = styled.div`
  position: relative;
  padding: 0 1.5rem;

  > h2 {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2rem;

    color: ${({ theme }) => theme.colors.gray6};

    margin-bottom: 0.5rem;
  }

  > p {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.375rem;

    color: ${({ theme }) => theme.colors.gray4};

    b {
      word-break: break-all;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.gray5};
    }
  }

  > button {
    position: absolute;
    top: 0px;
    right: 1.5rem;

    color: ${({ theme }) => theme.colors.gray5};
    transition: color 0.2s ease-out;

    &:hover {
      color: ${({ theme }) => theme.colors.gray6};
    }
  }
`;

export const FSVariablesContent = styled.div`
  overflow-y: auto;
  padding: 0 1.5rem;

  ::-webkit-scrollbar {
    width: 0.313rem;
    height: 0.313rem;
    padding: 0.063rem;
  }

  ::-webkit-scrollbar-track {
    transition: 0.2s;
    margin: 0.188rem;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }

  :has(div:nth-child(4)) {
    margin-right: 2px;
  }
`;

export const FSVariableCard = styled.div`
  padding: 1.5rem;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray2};

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

interface FSVariableCardHeaderProps {
  isDisabled: boolean;
}

export const FSVariableCardHeader = styled.a<FSVariableCardHeaderProps>`
  cursor: ${({ isDisabled }) => (!isDisabled ? 'pointer' : 'not-allowed')};

  a {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    margin-bottom: 8px;

    pointer-events: ${({ isDisabled }) => (!isDisabled ? 'unset' : 'none')};

    span {
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.375rem;

      color: ${({ theme, isDisabled }) =>
        !isDisabled ? theme.colors.primary : theme.colors.gray4};

      opacity: ${({ isDisabled }) => (!isDisabled ? 1 : 0.8)};

      transition: color 0.2s ease-out;

      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: calc(100% - 1.5rem);
    }

    svg {
      color: ${({ theme, isDisabled }) =>
        !isDisabled ? theme.colors.primary : theme.colors.gray4};

      opacity: ${({ isDisabled }) => (!isDisabled ? 1 : 0.8)};

      transition: color 0.2s ease-out;
    }

    ${({ isDisabled }) =>
      !isDisabled &&
      css`
        &:hover {
          span {
            color: ${({ theme }) => theme.colors.primaryDark};
          }
          svg {
            color: ${({ theme }) => theme.colors.primaryDark};
          }
        }
      `}
  }
`;

export const FSVariableCardBody = styled.div`
  h4 {
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.6875rem;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    color: ${({ theme }) => theme.colors.gray6};

    margin-bottom: 1rem;
  }

  table {
    width: 100%;
    text-align: left;
    table-layout: fixed;

    th {
      white-space: nowrap;

      font-weight: 500;
      line-height: 1.125rem;
      font-size: 0.75rem;

      color: ${({ theme }) => theme.colors.gray4};
      opacity: 0.8;
    }

    td {
      font-weight: 400;
      line-height: 1.375rem;
      font-size: 0.875rem;
      vertical-align: baseline;

      color: ${({ theme }) => theme.colors.gray5};

      padding-top: 0.5rem;
    }

    th,
    td {
      &:not(:first-child) {
        padding-left: 0.25rem;
      }
      &:not(:last-child) {
        padding-right: 0.25rem;
      }
    }
  }
`;

export const FSVariablesContainerWarning = styled(ContainerWarning)`
  margin: 1.5rem 1.5rem 0;
`;

export const MessageError = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;

  color: ${({ theme }) => theme.colors.gray4};

  b {
    word-break: break-all;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.gray5};
  }
`;
