import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { HCharts, HChartsOptions, HChartsSeries } from 'src/components/HCharts';
import { RootState } from 'src/redux/store';
import { formatCompactNotation } from 'src/utils/numbers/formatCompactNotation';
import { getChartColor } from 'src/utils/colors/getChartColor';

interface AnnualLevelChartProps {
  data: {
    year: number[];
    y: number[];
    type: string;
  }[];
}

export const AnnualLevelChart: React.FC<AnnualLevelChartProps> = ({ data }) => {
  const { sideBar } = useSelector((state: RootState) => state);

  const { t: translate } = useTranslation();

  const series: HChartsSeries[] = useMemo(
    () =>
      data.map((chartData) => {
        const name =
          chartData.type === 'Historical'
            ? `${translate('Historical')}`
            : chartData.type === 'Forecast'
            ? `${translate('Forecast')}`
            : chartData.type;

        return {
          name,
          type: 'column',
          marker: {
            symbol: 'circle',
          },
          color:
            chartData.type === 'Forecast'
              ? `${getChartColor(0)}80`
              : getChartColor(0),
          data: chartData.year.map((year, yearIndex) => ({
            x: new Date(`${year}-01-01T00:00`).getTime(),
            y: chartData.y[yearIndex] ?? null,
            custom: {
              value: formatCompactNotation(chartData.y[yearIndex]),
            },
          })),
        };
      }),
    [data, translate],
  );

  const options: HChartsOptions = useMemo(
    () => ({
      chart: {
        height: 300,
      },
      tooltip: {
        pointFormat:
          `<tr><td><b>${translate('date')}:</b> </td>` +
          `<td style="text-align: right">{point.x: ${'%Y'}}</td></tr>` +
          `<tr><td><b>${translate('value')}:</b> </td>` +
          '<td style="text-align: right">{point.custom.value}</td></tr>',
      },
    }),
    [translate],
  );

  return (
    <HCharts
      series={series}
      options={options}
      dataCy="chart-annual-level"
      frequency="annual"
      resizeWidthWithSidebar
      sleepTimerToResize={false}
      animationToResize
      removeWidthToResize={
        (window.innerWidth - (sideBar.isExpanded ? 20 : 7) * 16) / 2 - 8
      }
    />
  );
};
