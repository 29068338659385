import styled from 'styled-components';

export const ForwardButtonsContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
`;

export const SeeDocsButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.6s;

  p {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.375rem;
    color: ${({ theme }) => theme.colors.primary};
  }

  svg {
    color: ${({ theme }) => theme.colors.primary};
  }

  :hover {
    p {
      color: ${({ theme }) => theme.colors.primaryDark};
    }

    svg {
      color: ${({ theme }) => theme.colors.primaryDark};
    }
  }
`;
