import styled from 'styled-components';

export const ContainerHead = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  > div:first-child {
    margin-bottom: 0;
  }
`;

export const TableContent = styled.div`
  display: flex;
  margin-top: 1.5rem;

  position: relative;
  overflow-y: auto;
  overflow-x: auto;
  max-height: 36.8rem;
  border-radius: 8px;

  ::-webkit-scrollbar-track {
    margin-top: 3.438rem;
  }

  ::-webkit-scrollbar {
    width: 0.313rem;
    height: 0.313rem;
  }

  table {
    border-spacing: 0;
    width: 100%;
    border-radius: 8px;

    thead {
      position: sticky;
      top: 0px;
      z-index: 99;
      background: ${({ theme }) => theme.colors.white};

      th {
        position: relative;
        background: ${({ theme }) => theme.colors.white};

        div {
          min-width: 3.75rem;
          display: flex;
          gap: 1.5rem;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;

          svg {
            min-width: 0.75rem;
            min-height: 0.75rem;
          }

          p {
            word-wrap: break-word;
            word-break: break-word;
            margin-right: 1rem;
          }
        }
      }

      th {
        min-width: 10.5rem !important;
      }

      th:first-child {
        min-width: 12.5rem !important;
      }
    }

    th {
      padding: 1rem 1.5rem;
      border-bottom: 1.5px solid ${({ theme }) => theme.colors.gray2};
      border-spacing: 0;

      font-weight: 600;
      line-height: 150%;
      color: ${({ theme }) => theme.colors.gray5};
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.375rem;
    }

    td {
      text-align: center;
      border-spacing: 0;
      height: 3.313rem;
      color: ${({ theme }) => theme.colors.gray5};
      font-style: normal;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.375rem;
      padding: 1rem 3rem;
    }

    tr {
      td:first-child {
        font-weight: 600;
        /* text-align: left; */
      }
    }
  }
`;

interface ActiveTRProps {
  active: boolean;
}

export const ActiveTR = styled.tr<ActiveTRProps>`
  td {
    color: ${({ theme, active }) =>
      active ? theme.colors.gray5 : theme.colors.gray4} !important;
  }
`;

interface TableSortButtonProps {
  active: boolean;
}

export const TableSortButton = styled.button<TableSortButtonProps>`
  position: absolute;
  top: 50;
  right: 1rem;
  width: 1.5rem;
  height: 1.5rem;

  svg {
    color: ${({ active, theme }) =>
      active ? theme.colors.primary : theme.colors.gray3};
  }
`;
