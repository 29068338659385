export const userSelectionResultsENTranslate = {
  userSelectionResultTitle: 'Results',
  userSelectionResultFrequency: 'Frequency',
  userSelectionResultTransformation: 'Transformation',
  userSelectionResultOriginal: 'Original',
  userSelectionResultMonthly: 'Monthly',
  userSelectionResultQuarterly: 'Quarterly',
  userSelectionResultAnnual: 'Annual',
  userSelectionResultLevel: 'Level',
  userSelectionResultVariation: 'Variation',
  userSelectionResultLatestData: 'Latest data',
  userSelectionResultsVariationOriginal:
    'Variation (compared to the previous period)',
  userSelectionResultsVariationMonthlyQuarterly:
    'Variation (compared to the same period in the previous year)',
  userSelectionResultsVariationAnnual:
    'Variation (compared to the previous year)',
  userSelectionNOfDays: 'N. of days',
  userSelectionResultsNotEnoughObservationsVariation:
    'Not enough observations to calculate variation.',
};
