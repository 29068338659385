import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  width: 100%;

  border-radius: 8px;
  border: 1px solid ${(prop) => prop.theme.colors.gray2};

  cursor: pointer;

  transition: all 0.2s;

  &:hover {
    border: 1px solid ${(prop) => prop.theme.colors.gray3};
  }
`;

export const IndicatorContent = styled.div`
  width: 100%;

  display: flex;
  align-items: flex-start;
  align-self: stretch;
  gap: 1.5rem;

  padding: 1.5rem;
`;

const typeIndicator = {
  controls: 'rgba(66, 153, 225, 0.12)',
  'economic activity': 'rgba(72, 187, 120, 0.12)',
  credit: 'rgba(245, 101, 101, 0.12)',
  'climatic data': 'rgba(236, 201, 75, 0.12)',
  'public finance': 'rgba(159, 122, 234, 0.12)',
  'capital and financial markets': 'rgba(56, 178, 172, 0.12)',
  'external sector': 'rgba(11, 197, 234, 0.12)',
  'environmental data': 'rgba(237, 137, 54, 0.12)',
};

type ColorIndicator = {
  typeIndicator: keyof typeof typeIndicator | string;
};

export const IconContainer = styled.div<ColorIndicator>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;

  width: 3.5625rem;
  min-width: 3.5625rem;
  height: 3.5625rem;

  margin: 0 0 1.5rem;
  border-radius: 8px;

  background: ${(props) =>
    Object.keys(typeIndicator).includes(props.typeIndicator)
      ? typeIndicator[props.typeIndicator as keyof typeof typeIndicator]
      : 'rgba(76, 148, 255, 0.12)'};

  img {
    width: 2rem;
    height: 2rem;
    flex-shrink: 0;
  }
`;

export const Content = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 0.5rem;

  span {
    align-self: stretch;

    color: ${(prop) => prop.theme.colors.gray6};

    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.6875rem;
  }
`;

export const Tags = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
`;

const typeTag = {
  controls: '#4299E1',
  'economic activity': '#48BB78',
  credit: '#F56565',
  'climatic data': '#ECC94B',
  'public finance': '#9F7AEA',
  'capital and financial markets': '#38B2AC',
  'external sector': '#0BC5EA',
  'environmental data': '#ED8936',
};

type ColorTag = {
  typeTag: keyof typeof typeTag | string;
};

export const Tag = styled.p<ColorTag>`
  color: ${(props) => props.theme.colors.gray4};

  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.375rem;

  &:nth-child(2) {
    color: ${(props) =>
      Object.keys(typeTag).includes(props.typeTag)
        ? typeTag[props.typeTag as keyof typeof typeTag]
        : props.theme.colors.gray4};
  }
`;

export const Dot = styled.label`
  color: ${(props) => props.theme.colors.gray3};

  font-size: 0.75rem;
  font-weight: 500;
  line-height: normal;

  margin-right: 0.5rem;
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;

  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  label {
    color: ${(props) => props.theme.colors.gray4};
    font-size: 0.75rem;
    font-weight: 500;
    line-height: normal;

    letter-spacing: 0.06rem;
    text-transform: uppercase;

    cursor: pointer;
  }

  span {
    color: ${(props) => props.theme.colors.gray5};

    font-size: 0.875rem;
    font-weight: 400;
    line-height: 150%;
  }

  span + label {
    margin-left: 1.5rem;
  }
`;

export const AccessTypeIconsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: stretch;
  flex: 1 0 0;
  gap: 1rem;

  position: absolute;
  bottom: 1.5rem;
  right: 1.5rem;
`;

export const IconsAccess = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;

  position: absolute;
  background: ${(prop) => prop.theme.colors.white};
`;

export const PremiumBasicIconBase = styled.div`
  height: 2rem;
  max-width: 2rem;

  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0.5rem;

  cursor: pointer;

  border-radius: 9999px;

  padding: 0.25rem 0.375rem;

  margin-bottom: -0.25rem;

  overflow: hidden;

  transition: all 1s linear;

  span {
    white-space: nowrap;

    font-size: 0.875rem;
    font-weight: 500;
  }

  &:hover {
    max-width: 31.25rem;
  }
`;

export const PremiumIcon = styled(PremiumBasicIconBase)`
  background: rgba(236, 201, 75, 0.12);

  span {
    color: ${(prop) => prop.theme.colors.yellow4};
  }
`;

export const BasicIcon = styled(PremiumBasicIconBase)`
  background: rgba(56, 178, 172, 0.12);

  span {
    color: #38b2ac;
  }
`;

export const LockIconBase = styled.div`
  height: 2rem;
  max-width: 2rem;

  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0.5rem;

  padding: 0.375rem 0.375rem;

  border-radius: 9999px;
`;

export const LockIconPremium = styled(LockIconBase)`
  svg {
    color: ${(prop) => prop.theme.colors.yellow4};
  }
`;

export const LockIconBasic = styled(LockIconBase)`
  svg {
    color: #38b2ac;
  }
`;
