import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import parseHTML from 'html-react-parser';
import { CheckCircle, Copy } from 'phosphor-react';
import { sleep } from 'src/utils/sleep';

import { ButtonCopy, UnderstandingResultText } from './styles';
import { UnderstandingResultContentSkeletons } from '../styles';

interface ResultsTextProps {
  loadingResult: boolean;
  description: string;
}

export const ResultsText: React.FC<ResultsTextProps> = ({
  loadingResult,
  description,
}) => {
  const [isTextCopied, setTextCopied] = useState(false);

  const { t: translate } = useTranslation();

  async function handleCopyText() {
    const texto = document.getElementById(
      'understanding-the-result-text',
    )?.textContent;
    if (texto) {
      navigator.clipboard.writeText(texto);
      setTextCopied(true);
      await sleep(3000);
      setTextCopied(false);
    }
  }

  return (
    <UnderstandingResultText data-cy="ia-selection-results-secondary-content">
      {loadingResult ? (
        <UnderstandingResultContentSkeletons data-cy="ia-selection-results-secondary-loading">
          <ContainerSkeleton withLoading={false} />
          <ContainerSkeleton withLoading={false} />
          <ContainerSkeleton withLoading={false} />
          <ContainerSkeleton withLoading={false} />
        </UnderstandingResultContentSkeletons>
      ) : (
        <p
          id="understanding-the-result-text"
          data-cy="ia-selection-results-secondary-text"
        >
          {parseHTML(description)}
        </p>
      )}
      <div>
        <ButtonCopy
          buttonType="naked"
          disabled={!description || loadingResult || isTextCopied}
          icon={
            isTextCopied ? (
              <CheckCircle size="1.125rem" weight="bold" />
            ) : (
              <Copy size="1.125rem" weight="bold" />
            )
          }
          onClick={handleCopyText}
          isCopied={isTextCopied}
          data-testid="ai-selection-results-button-copy"
        >
          {isTextCopied
            ? translate('workspaceProjectionsResultsUnderstandingTextCopied')
            : translate('workspaceProjectionsResultsUnderstandingCopyText')}
        </ButtonCopy>
      </div>
    </UnderstandingResultText>
  );
};
