import styled, { css } from 'styled-components';

export const ContainerTable = styled.div``;

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 1.5rem;
  margin: -1.5rem 0; //remover a margin colocada pelo border-spacin
`;

export const Thead = styled.thead`
  tr {
    vertical-align: bottom;
  }
`;

export const Tbody = styled.tbody``;

interface TrProps {
  height?: number | string;
  disabled?: boolean;
}
export const Tr = styled.tr<TrProps>`
  height: ${({ height }) => height ?? 'auto'};
  p,
  td {
    color: ${({ theme, disabled }) =>
      disabled ? theme.colors.gray4 : theme.colors.gray6} !important;
  }
`;

interface ThProps {
  width?: string | number;
  valueWidthInMediaQueryAuto?: string | number;
}

export const Th = styled.th<ThProps>`
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1.375rem;
  text-align: left;
  padding-bottom: 1rem;
  color: ${({ theme }) => theme.colors.gray7} !important;
  opacity: 0.8;
  position: relative;
  width: ${({ width }) => width ?? 'auto'};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray2};

  ${({ valueWidthInMediaQueryAuto }) => css`
    @media (max-width: ${valueWidthInMediaQueryAuto}) {
      width: auto !important;
    }
  `}

  padding-left: 1rem;
  &:first-child {
    padding-left: 0;
  }
`;

export const Td = styled.td`
  color: ${({ theme }) => theme.colors.gray6} !important;
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.375rem;
  padding-left: 1rem;
  &:first-child {
    padding-left: 0;
  }
`;
