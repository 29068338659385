import styled from 'styled-components';

interface SelectStatus {
  openModal: boolean;
}

export const SelectStatus = styled.div<SelectStatus>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 1rem;
  p {
    color: ${({ theme }) => theme.colors.gray5};
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.138rem;
  }
  > svg {
    margin-left: 0.5rem;
    color: ${({ theme }) => theme.colors.gray5};
    transition: color 0.2s;
    transform: ${({ openModal }) =>
      openModal ? 'rotate(180deg)' : 'rotate(0deg)'};

    &:hover {
      color: ${({ theme }) => theme.colors.gray6};
    }

    cursor: pointer;
  }
  > div {
    position: absolute;
  }
`;

export const MenuOverlay = styled.div`
  width: 100vw;
  height: 100vh;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
`;
