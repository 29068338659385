import styled from 'styled-components';

export const Container = styled.aside`
  min-width: 17.5rem;
  max-width: 17.5rem;
  height: calc(100vh - 23rem);

  direction: rtl;

  border-right: 1px solid ${({ theme }) => theme.colors.gray2};

  overflow-y: auto;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;

  padding: 1.5rem;

  ::-webkit-scrollbar {
    width: 0.313rem;
    height: 0.313rem;
    padding: 0.063rem;
  }

  ::-webkit-scrollbar-track {
    transition: 0.2s;
    margin: 0.188rem;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }

  @media (max-width: 1366px) {
    max-height: calc(91vh - 5.063rem);

    gap: 1rem;
  }

  @media (max-width: 950px) {
    min-width: 14.5rem;
    max-width: 14.5rem;
  }
`;

export const Content = styled.div`
  direction: ltr;
`;

type ExpandAnbimaBigNumbersProps = {
  expanded: boolean;
};

export const ExpandAnbimaBigNumbers = styled.button<ExpandAnbimaBigNumbersProps>`
  position: fixed;
  top: 25rem;
  transform: translate(14.5625rem, 0);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0.5rem;

  border-radius: 100%;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  background: ${({ theme }) => theme.colors.white};

  z-index: 100;

  transition: all 0.2s, left 0.75s;

  svg {
    width: 1rem;
    height: 1rem;

    color: ${({ theme }) => theme.colors.gray4};

    transition: all 0.2s, transform 0.75s;
  }

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.gray3};

    svg {
      color: ${({ theme }) => theme.colors.gray5};
    }
  }
`;

export const Header = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;

  .close-bigNumbers-button {
    color: ${({ theme }) => theme.colors.gray4};
    transform: rotate(180deg);

    transition: all 0.2s;

    cursor: pointer;
  }

  .close-bigNumbers-button:hover {
    color: ${({ theme }) => theme.colors.gray5};

    cursor: pointer;
  }
`;

export const HeaderTitle = styled.div`
  display: flex;
  align-items: center;

  gap: 1rem;
`;

export const ReferenceDate = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray5};
`;

export const Card = styled.button`
  width: 100%;

  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 0.5rem;

  padding: 1rem;

  background: ${({ theme }) => theme.colors.gray0};
  border-radius: 8px;

  span {
    font-size: 2.25rem;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.gray6};
  }

  hr {
    width: 100%;
    height: 1px;
    border: none;
    background: ${({ theme }) => theme.colors.gray2};
  }

  transition: 0.2s;

  &:hover {
    background: ${({ theme }) => theme.colors.gray1};

    cursor: pointer;
  }
`;

export const CardDescription = styled.p`
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: ${({ theme }) => theme.colors.gray4};
`;

export const CardQuestion = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.375rem;
  color: ${({ theme }) => theme.colors.gray5};
`;
