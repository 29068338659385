export const ListCustomersPTBRTranslate = {
  listCustomersTitle: 'Lista de clientes',
  listCustomersDescription: 'Todos os clientes',
  listCustomersButtonRegisterCustomer: 'Cadastrar novo cliente',
  listCustomersName: 'Nome',
  listCustomersManagerUserEmail: 'E-mail do usuário gerente',
  listCustomersTotalLicenses: 'Total de licenças',
  listCustomersRemainingLicenses: 'Licenças restantes',
  listCustomersNamePagination: 'clientes',
  listCustomersErrorLoad: 'Não foi possível carregar os clientes.',
};
