import React, { useEffect, useMemo, useState } from 'react';

import { ArrowLeft } from 'phosphor-react';
import { Annotations } from 'plotly.js';
import * as Plotly from 'plotly.js';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card } from 'src/components/Card';
import { ChartPloty } from 'src/components/ChartPloty';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Head } from 'src/components/Head';
import { ContainerWarning } from 'src/models/components/ContainerWarning';
import { RootState } from 'src/redux/store';
import light from 'src/styles/themes/light';
import { getChartColor } from 'src/utils/colors/getChartColor';
import { getTextWidth } from 'src/utils/getTextWidth';
import { truncateStrings } from 'src/utils/strings/truncateStrings';

import api from '../../../service/api';
import { ErrorDataView } from '../Overview/types';
import {
  Container,
  ContainerLegend,
  ContainerWhite,
  ContainerChart,
  ContenChart,
  ContentTitle,
  XAXIX,
  YAXIX,
} from './styles';

type DataProps = {
  id: number;
  values: number[][] | null[][] | undefined;
  xVariables: string[] | undefined;
  yVariables: string[] | undefined;
  filtered: boolean;
};

interface WindowScreen {
  height: number | undefined;
  width: number | undefined;
}

const font = '400 12px Inter';

export const CorrelationMatrix: React.FC = () => {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();

  const [windowSize, setWindowSize] = useState<WindowScreen>({
    width: undefined,
    height: undefined,
  });

  const id = useSelector(
    (state: RootState) =>
      state.modelling.form?.fileDataSet.dataset_id ?? state.project.id,
  );

  const { sideBar } = useSelector((state: RootState) => state);

  const { data, isLoading, isFetching, isError, error } = useQuery<
    DataProps,
    ErrorDataView
  >(
    ['dataview', 'correlation-matrix', id],
    async () => {
      const response = await api.get(`/projects/${id}/dataview/correlations`);
      return response.data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled: !!id,
    },
  );

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const widthBiggestVariable = useMemo(() => {
    if (data?.xVariables?.length) {
      return truncateStrings(data?.xVariables)?.reduce(
        (widthBiggestWord: number, word) => {
          const widthWord = getTextWidth(word.toString(), font);
          if (widthBiggestWord < widthWord) {
            return widthWord;
          }
          return widthBiggestWord;
        },
        0,
      );
    }
    return 0;
  }, [data]);

  const width = useMemo(() => {
    let widthAux = windowSize.width ?? window.screen.width;

    widthAux += sideBar.isExpanded ? 0 : 7 * 16 + 24 * 2 + 22;

    if (!data?.xVariables?.length) {
      return widthAux - 353;
    }

    if (data.xVariables.length * 35 > widthAux) {
      return data.xVariables.length * 35;
    }

    return widthAux - 425 - 76.5 - (Number(widthBiggestVariable) ?? 0);
  }, [data, widthBiggestVariable, windowSize.width, sideBar.isExpanded]);

  const height = useMemo(
    () =>
      (data?.yVariables?.length ?? 0) * 35 >
      (windowSize.height ?? window.screen.height)
        ? (data?.yVariables?.length ?? 0) * 35 ?? 0
        : windowSize.height ?? window.screen.height,
    [data?.yVariables?.length, windowSize.height],
  );

  const layout = useMemo<Partial<Plotly.Layout>>((): any => {
    const layoutAux: Partial<Plotly.Layout> = {
      annotations: [],
      xaxis: {
        ticks: '',
        side: 'top',
        automargin: true,
        tickangle: 90,
        visible: false,
      },
      yaxis: {
        ticks: '',
        ticksuffix: ' ',
        automargin: true,
        visible: false,
      },
      margin: {
        t: 0,
        r: 0,
        b: 0,
        l: 0,
        pad: 0,
      },
      width,
      height,
    };
    if (data && data?.values && data?.xVariables && data?.yVariables) {
      for (let i = 0; i < data?.yVariables.length; i++) {
        for (let j = 0; j < data?.xVariables.length; j++) {
          const result: Partial<Annotations> = {
            xref: 'x',
            yref: 'y',
            x: data?.xVariables[j],
            y: data?.yVariables[i],
            text: data.values[i][j]?.toFixed(2) ?? '0.00',
            font: {
              size: 12,
              color:
                (data.values[i][j] ?? 0) > 0.75
                  ? light.colors.white
                  : light.colors.gray6,
            },
            showarrow: false,
          };
          layoutAux.annotations?.push(result);
        }
      }
    }
    return layoutAux;
  }, [data, height, width]);

  return (
    <Container>
      <Head
        title={`${translate('correlation_matrix')} | ${translate('data_view')}`}
      />
      <ContentTitle data-testid="div-dataview" className="containerLinear">
        <ArrowLeft
          size="2rem"
          onClick={() => navigate('/models/projects/new/time-series')}
          data-cy="button-back"
          data-testid="button-back"
        />
        <Card
          textCard={`${translate('data_view')} - ${translate(
            'correlation_matrix',
          )}`}
          style={{ marginBottom: 0 }}
        />
      </ContentTitle>
      <div className="containerLinear" data-cy="correlation-matrix">
        <Card textCard={translate('correlation_matrix')} />
        {isError ? (
          <ContainerMaintenance
            content="chart"
            text={
              error?.response?.data?.detail?.description ??
              error?.response?.data?.detail?.detail
            }
            data-testid="container-error-correlation-matrix"
            data-cy="container-error-correlation-matrix"
          />
        ) : data?.values?.length === 0 ? (
          <ContainerMaintenance
            content="chart"
            text={translate('correlationMatrixNoExplanatoryVariableSelected')}
            data-testid="container-error-correlation-matrix-no-data"
            data-cy="container-error-correlation-matrix-no-data"
          />
        ) : isLoading ||
          isFetching ||
          !data ||
          !data?.yVariables ||
          !data?.xVariables ||
          !widthBiggestVariable ? (
          // eslint-disable-next-line react/jsx-indent
          <ContainerSkeleton
            // eslint-disable-next-line prettier/prettier
            data-testid="div-loading-correlation-matrix"
            data-cy="div-loading-correlation-matrix"
            // eslint-disable-next-line react/jsx-indent-props
            style={{
              height: (windowSize.height ?? window.screen.height) / 1.5,
            }}
          />
        ) : (
          <>
            <ContainerChart>
              <ContenChart
                marginLeftBottomScroll={Number(widthBiggestVariable)}
                style={{
                  height: (windowSize.height ?? window.screen.height) / 1.5,
                }}
              >
                <YAXIX style={{ height }}>
                  {truncateStrings([...data?.yVariables])
                    .reverse()
                    .map((y) => (
                      <p key={`yaxis-${y}`}>{y}</p>
                    ))}
                </YAXIX>
                <div
                  data-testid="div-chart-correlation-matrix"
                  data-cy="div-chart-correlation-matrix"
                >
                  <ChartPloty
                    data={[
                      {
                        x: data?.xVariables,
                        y: data?.yVariables,
                        z: data?.values,
                        type: 'heatmap',
                        colorscale: [
                          [0, `${getChartColor(1) ?? '#FFABD3'}FF`],
                          [0.5, 'rgba(255, 255, 255, 1)'],
                          [1, `${getChartColor(0) ?? '#81B3FF'}FF`],
                        ],
                        showscale: false,
                        colorbar: {
                          title: '<b>Corr. Coef</b>',
                          titlefont: {
                            color: light.colors.gray6,
                            family: 'Inter',
                            size: 13,
                          },
                          lenmode: 'pixels',
                          len: 150,
                          outlinecolor: '#FFF',
                        },
                      },
                    ]}
                    layout={layout}
                    dataCy="chart-correlation-matrix"
                    config={{ staticPlot: true }}
                  />
                  <XAXIX style={{ width }}>
                    {truncateStrings(data?.xVariables)?.map((x) => (
                      <p key={`xaxis-${x}`}>{x}</p>
                    ))}
                  </XAXIX>
                </div>
              </ContenChart>
              <ContainerWhite size={Number(widthBiggestVariable)} />
              <ContainerLegend>
                <b>Corr. Coef</b>
                <div>
                  <div />
                  <div>
                    <p>1</p>
                    <p>0.5</p>
                    <p>0</p>
                    <p>-0.5</p>
                    <p>-1</p>
                  </div>
                </div>
              </ContainerLegend>
            </ContainerChart>
            {data.filtered && (
              <ContainerWarning
                visible={data.filtered}
                text={translate('warningMessageLimit')}
                style={{
                  marginTop: '1.5rem',
                }}
              />
            )}
          </>
        )}
      </div>
    </Container>
  );
};
