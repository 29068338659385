import light from 'src/styles/themes/light';
import styled, { css } from 'styled-components';

type HistoricalCardProps = {
  isBaseLine: boolean;
};

export const Container = styled.div<HistoricalCardProps>`
  display: ${({ isBaseLine }) => (isBaseLine ? 'none' : 'flex')};
  flex-direction: column;

  padding: 1rem;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray2};

  gap: 0.625rem;

  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0rem;
  }
`;

const TAG_COLORS = {
  approved: light.colors.teal4,
  edition: light.colors.purple4,
  governance: light.colors.primary,
  revision: light.colors.yellow4,
  reproved: light.colors.red2,
} as const;

const TAG_BACKGROUND = {
  approved: 'rgba(56, 178, 172, 0.12)',
  edition: 'rgba(159, 122, 234, 0.12)',
  governance: 'rgba(76, 148, 255, 0.12)',
  revision:
    'linear-gradient(0deg, rgba(236, 201, 75, 0.12) 0%, rgba(236, 201, 75, 0.12) 100%), #FFF',
  reproved: 'rgba(255, 82, 82, 0.12)',
} as const;

type TagProps = {
  status: keyof typeof TAG_COLORS;
};

export const Tag = styled.div<TagProps>`
  display: flex;
  padding: 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  width: fit-content;
  border-radius: 62.4375rem;

  color: ${({ status }) => TAG_COLORS[status]};
  background: ${({ status }) => TAG_BACKGROUND[status]};

  font-family: Inter;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const CardTitle = styled.span`
  color: ${({ theme }) => theme.colors.gray6};
  font-size: 0.875rem;
  font-weight: 500;

  /* margin: 0.625rem 0; */
`;

export const Message = styled.p`
  color: ${({ theme }) => theme.colors.gray5};

  white-space: pre-line;
  word-break: break-word;

  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

type ChangesProps = {
  maxHeight: number | null;
};

export const Changes = styled.div<ChangesProps>`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;

  ${Message}

  transition: max-height 0.5s ease-in-out;

  ${({ maxHeight }) =>
    !!maxHeight &&
    css`
      max-height: ${maxHeight}px;
      overflow: hidden;
    `}
`;

export const DeadLineInfo = styled(Message)`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: start;
  align-content: center;

  margin-top: 0.625rem;

  span {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin: 0 0.25rem;
    }
  }
`;

export const YText = styled.span`
  color: ${({ theme }) => theme.colors.gray4};
  font-size: 0.625rem;
  font-weight: 500;

  &:not(:first-of-type) {
    margin-top: 0.5rem;
  }
`;

type ShowMoreButtonProps = {
  showMore: boolean;
};

export const ShowMoreButton = styled.button<ShowMoreButtonProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  color: ${({ theme }) => theme.colors.primary};
  font-size: 0.75rem;
  font-weight: 600;

  transition: color 0.2s;

  svg {
    transition: transform 0.5s ease-in-out;
    transform: ${({ showMore }) => (showMore ? 'scale(-1)' : 'scale(1)')};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primaryDark};
  }
`;

export const AdjustmentValue = styled.span`
  color: ${({ theme }) => theme.colors.gray6};
  letter-spacing: 0 !important;
`;
