import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Card } from 'src/components/Card';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { putDecimalSeparators } from 'src/utils/numbers/putDecimalSeparators';
import { Select } from 'src/components/Select';
import { RadioButton } from 'src/components/RadioButton';

import {
  ChartAxisTitle,
  ConfusionMatrixCell,
  ConfusionMatrixContainer,
  ConfusionMatrixLabel,
  Container,
  OptionsContainer,
} from './styles';

export type ConfusionMatrix = {
  labels: string[];
  values: number[][];
};

interface ConfusionMatrixProps {
  data: ConfusionMatrix | undefined;
  isFetching: boolean;
  isLoading: boolean;
  isErrored: boolean;
  count: 'absolut' | 'percentage';
  setCount: (value: 'absolut' | 'percentage') => void;
}

type ClassType =
  | 'truePositive'
  | 'falsePositive'
  | 'trueNegative'
  | 'falseNegative';

export const ConfusionMatrixChart: React.FC<ConfusionMatrixProps> = ({
  data,
  isFetching,
  isLoading,
  isErrored,
  count,
  setCount,
}) => {
  const { t: translate } = useTranslation();
  const [positiveClass, setPositiveClass] = useState<string>();

  useEffect(() => {
    data &&
      !data.labels.some((label) => label === positiveClass) &&
      setPositiveClass(data.labels[0]);
  }, [data, positiveClass]);

  function checkClasses(
    currentClass: { name: string; index: number },
    valueIndex: number,
  ): ClassType {
    if (
      currentClass.name === positiveClass &&
      valueIndex === currentClass.index
    ) {
      return 'truePositive';
    }
    if (
      currentClass.name !== positiveClass &&
      valueIndex === currentClass.index
    ) {
      return 'trueNegative';
    }
    if (
      currentClass.name === positiveClass &&
      valueIndex !== currentClass.index
    ) {
      return 'falseNegative';
    }
    if (
      currentClass.name !== positiveClass &&
      valueIndex !== currentClass.index
    ) {
      return 'falsePositive';
    }

    return 'falseNegative';
  }

  return (
    <Container className="containerLinear">
      <Card
        textCard={translate('confusionMatrixTitle')}
        textDescription={translate('confusionMatrixDescription')}
      />
      <div>
        {isErrored ? (
          <ContainerMaintenance
            content="chart"
            data-testid="confusion-matrix-fail"
          />
        ) : isLoading || isFetching || !data ? (
          <ContainerSkeleton data-testid="confusion-matrix-loading" />
        ) : data && data.labels.length > 2 ? (
          <ContainerMaintenance
            content="chart"
            text={translate('confusionMatrixMulticlassDisabled')}
            data-testid="confusion-matrix-multiclass-fail"
          />
        ) : (
          data && (
            <>
              <OptionsContainer>
                {positiveClass && (
                  <>
                    <Select
                      label={translate('confusionMatrixPositiveClass')}
                      value={{
                        label: positiveClass,
                        value: positiveClass,
                      }}
                      onChange={(opt: any) => setPositiveClass(opt.label)}
                      options={data.labels.map((label) => ({
                        label,
                        value: label,
                      }))}
                      style={{ maxWidth: '250px' }}
                      data-cy="confusion-matrix-positive-class-select"
                    />

                    <div>
                      <p>{translate('confusionMatrixCount')}</p>
                      <div>
                        <RadioButton
                          data-testid="radio-matrix-count-absolut"
                          data-cy="radio-matrix-count-absolut"
                          label={translate('absolut')}
                          checked={count === 'absolut'}
                          onClick={() => setCount('absolut')}
                          disabled={isLoading}
                        />
                        <RadioButton
                          data-testid="radio-matrix-count-percentage"
                          data-cy="radio-matrix-count-percentage"
                          label={translate('confusionMatrixPercentage')}
                          checked={count === 'percentage'}
                          onClick={() => setCount('percentage')}
                          disabled={isLoading}
                        />
                      </div>
                    </div>
                  </>
                )}
              </OptionsContainer>
              <div>
                <ChartAxisTitle>
                  {translate('confusionMatrixPredicted')}
                </ChartAxisTitle>
                <ChartAxisTitle vertical>
                  {translate('confusionMatrixActual')}
                </ChartAxisTitle>
                <ConfusionMatrixContainer>
                  <ConfusionMatrixLabel empty className="box-1" />
                  {data.labels.map((label, index) => (
                    <ConfusionMatrixLabel key={label} id={`box-${index + 2}`}>
                      <p>{label}</p>
                    </ConfusionMatrixLabel>
                  ))}
                  {data.values.map((values, classIndex) => (
                    <>
                      <ConfusionMatrixLabel
                        className={`box-${(classIndex + 1) * 3 + 1}`}
                      >
                        <p>{data.labels[classIndex]}</p>
                      </ConfusionMatrixLabel>
                      {values.map((value, valueIndex) => (
                        <ConfusionMatrixCell
                          key={`${value + valueIndex + 1}`}
                          className={`box-${
                            classIndex === 0
                              ? valueIndex + 2 + 3
                              : classIndex === 1 && valueIndex + 2 + 6
                          }`}
                          type={checkClasses(
                            {
                              name: data.labels[classIndex],
                              index: classIndex,
                            },
                            valueIndex,
                          )}
                        >
                          <span
                            data-testid={`class-${data.labels[classIndex]
                              .replaceAll(' ', '-')
                              .toLocaleLowerCase()}-${data.labels[valueIndex]
                              .replaceAll(' ', '-')
                              .toLocaleLowerCase()}-status`}
                            data-cy={`class-${data.labels[classIndex]
                              .replaceAll(' ', '-')
                              .toLocaleLowerCase()}-${data.labels[valueIndex]
                              .replaceAll(' ', '-')
                              .toLocaleLowerCase()}-status`}
                          >
                            {translate(
                              checkClasses(
                                {
                                  name: data.labels[classIndex],
                                  index: classIndex,
                                },
                                valueIndex,
                              ),
                            )}
                          </span>
                          <p
                            data-testid={`class-${data.labels[classIndex]
                              .replaceAll(' ', '-')
                              .toLocaleLowerCase()}-${data.labels[valueIndex]
                              .replaceAll(' ', '-')
                              .toLocaleLowerCase()}-value`}
                            data-cy={`class-${data.labels[classIndex]
                              .replaceAll(' ', '-')
                              .toLocaleLowerCase()}-${data.labels[valueIndex]
                              .replaceAll(' ', '-')
                              .toLocaleLowerCase()}-value`}
                          >
                            {count === 'percentage'
                              ? `${value.toFixed(2)}%`
                              : putDecimalSeparators(value, ',')}
                          </p>
                        </ConfusionMatrixCell>
                      ))}
                    </>
                  ))}
                </ConfusionMatrixContainer>
              </div>
            </>
          )
        )}
      </div>
    </Container>
  );
};
