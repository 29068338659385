import React, { useEffect, useState } from 'react';

import { MagnifyingGlass, PencilSimple, User } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Card } from 'src/components/Card';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Head } from 'src/components/Head';
import { FailedModal } from 'src/components/Modal/Failed';
import { WarningModal } from 'src/components/Modal/Warning';
import { Pagination } from 'src/components/Pagination';
import { Table, Tbody, Td, Th, Thead, Tr } from 'src/components/Table';
import { apiAdminV2 } from 'src/service/apiAdminV2';

import { ModalRegisterEditCustomer } from './components/ModalRegisterEditCustomer';
import {
  ButtonCreateNewCustomer,
  ButtonIcon,
  Container,
  Content,
  ContentTable,
} from './styles';
import { InfoFailedToRegisterOrEdit } from './types';
import { ModalManager } from './components/ModalManager';

const QUANTITY_ITEMS_PAGE = 8;

export interface Products {
  name: 'time_series' | 'classification' | 'feature_store';
  type: 'manage' | 'read' | null;
}

export interface Customer {
  id: string;
  name: string;
  total_licenses: number;
  sso_integration: boolean;
  remaining_licenses: number;
  managers: string[];
  products: Products[];
}

interface ResponseCustomersList {
  limit: number;
  skip: number;
  total: number;
  records: Customer[];
}

export const CustomersList: React.FC = () => {
  const [modalCreateEditCustomerVisible, setModalCreateEditCustomerVisible] =
    useState(false);
  const [
    infoFailedToRegisterOrEditCustomer,
    setInfoFailedToRegisterOrEditCustomer,
  ] = useState<InfoFailedToRegisterOrEdit>();
  const [customerToEdit, setCustomerToEdit] = useState<null | Customer>(null);

  const [
    infoFailedToRegisterOrEditManager,
    setInfoFailedToRegisterOrEditManager,
  ] = useState<InfoFailedToRegisterOrEdit>();
  const [customers, setCustomers] = useState<ResponseCustomersList>();
  const [page, setPage] = useState(1);
  const [manager, setCustomerToManagement] = useState('');
  const [modalManager, setModalManager] = useState<boolean>(false);

  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  const { data, isLoading, isFetching, isError } =
    useQuery<ResponseCustomersList>(['customers-list', page], async () => {
      const response = await apiAdminV2.get(
        `/clients?skip=${
          (page - 1) * QUANTITY_ITEMS_PAGE
        }&limit=${QUANTITY_ITEMS_PAGE}`,
      );

      return response.data;
    });

  useEffect(() => {
    if (data) {
      setCustomers(data);
    }
  }, [data]);

  function closeModal() {
    setModalCreateEditCustomerVisible(false);
    setCustomerToEdit(null);
  }

  function closeModalManager() {
    setModalManager(false);
    setCustomerToEdit(null);
  }

  return (
    <Container>
      <Head title="4intelligence" />
      <Content className="containerLinear">
        <Card
          textCard={translate('listCustomersTitle')}
          textDescription={translate('listCustomersDescription')}
        />

        <ButtonCreateNewCustomer
          buttonType="primary"
          data-testid="button-register-customer"
          onClick={() => setModalCreateEditCustomerVisible(true)}
          disabled={isLoading || isFetching || isError}
        >
          {translate('listCustomersButtonRegisterCustomer')}
        </ButtonCreateNewCustomer>

        {isError ? (
          <ContainerMaintenance
            content="clientes"
            text={translate('listCustomersErrorLoad')}
            data-testid="container-maintenance-costumers-list"
          />
        ) : (
          <>
            <ContentTable>
              <Table>
                <Thead>
                  <Tr>
                    <Th style={{ width: '20%' }}>ID</Th>
                    <Th> {translate('listCustomersName')}</Th>
                    <Th> {translate('listCustomersManagerUserEmail')}</Th>
                    <Th style={{ width: '15%' }}>
                      {translate('listCustomersTotalLicenses')}
                    </Th>
                    <Th style={{ width: '15%' }}>
                      {translate('listCustomersRemainingLicenses')}
                    </Th>
                    <Th />
                  </Tr>
                </Thead>
                <Tbody>
                  {isLoading || isFetching || !data ? (
                    Array.from({ length: QUANTITY_ITEMS_PAGE }, (_, index) => (
                      // eslint-disable-next-line react/jsx-indent
                      <Tr
                        key={`tr-loading-${index}`}
                        data-testid={`tr-loading-${index}`}
                      >
                        <Td>
                          <ContainerSkeleton
                            withLoading={false}
                            style={{
                              height: '24px',
                            }}
                          />
                        </Td>
                        <Td>
                          <ContainerSkeleton
                            withLoading={false}
                            style={{
                              height: '24px',
                            }}
                          />
                        </Td>
                        <Td>
                          <ContainerSkeleton
                            withLoading={false}
                            style={{
                              height: '24px',
                            }}
                          />
                        </Td>
                        <Td>
                          <ContainerSkeleton
                            withLoading={false}
                            style={{
                              height: '24px',
                            }}
                          />
                        </Td>
                        <Td>
                          <ContainerSkeleton
                            withLoading={false}
                            style={{
                              height: '24px',
                            }}
                          />
                        </Td>
                        <Td>
                          <ContainerSkeleton
                            withLoading={false}
                            style={{
                              height: '20px',
                              width: '20px',
                              float: 'right',
                            }}
                          />
                          <ContainerSkeleton
                            withLoading={false}
                            style={{
                              height: '20px',
                              width: '20px',
                              float: 'right',
                            }}
                          />
                          <ContainerSkeleton
                            withLoading={false}
                            style={{
                              height: '20px',
                              width: '20px',
                              float: 'right',
                            }}
                          />
                        </Td>
                      </Tr>
                    ))
                  ) : (
                    <>
                      {data.records.map((customer) => (
                        // eslint-disable-next-line react/jsx-indent
                        <Tr key={customer.id}>
                          <Td>{customer.id}</Td>
                          <Td>{customer.name}</Td>
                          <Td>{customer.managers[0]}</Td>
                          <Td>{customer.total_licenses}</Td>
                          <Td>{customer.remaining_licenses}</Td>
                          <Td>
                            <ButtonIcon
                              data-testid={`button-visualize-${customer.id}`}
                              data-cy={`button-visualize-${customer.id}`}
                              onClick={() =>
                                navigate(
                                  `/user-module/customers-list/${customer.id}`,
                                )
                              }
                            >
                              <MagnifyingGlass size="1.25rem" />
                            </ButtonIcon>
                            <ButtonIcon
                              data-testid={`button-edit-${customer.id}`}
                              data-cy={`button-edit-${customer.id}`}
                              onClick={() => {
                                setCustomerToEdit(customer);
                                setModalCreateEditCustomerVisible(true);
                              }}
                            >
                              <PencilSimple size="1.25rem" />
                            </ButtonIcon>
                            <ButtonIcon
                              data-testid={`button-manager-${customer.id}`}
                              data-cy={`button-manager-${customer.id}`}
                              onClick={() => {
                                setCustomerToEdit(customer);
                                setCustomerToManagement(customer.id);
                                setModalManager(true);
                              }}
                            >
                              <User size="1.25rem" />
                            </ButtonIcon>
                          </Td>
                        </Tr>
                      ))}
                      {Array.from(
                        { length: QUANTITY_ITEMS_PAGE - data?.records.length },
                        (_, index) => (
                          <Tr
                            key={`tr-complete-${index}`}
                            data-testid={`tr-complete-${index}`}
                          >
                            <Td style={{ height: '24px' }} />
                          </Tr>
                        ),
                      )}
                    </>
                  )}
                </Tbody>
              </Table>
            </ContentTable>
            {customers && (
              <Pagination
                page={page}
                setPage={setPage}
                quantityItemsPerPage={QUANTITY_ITEMS_PAGE}
                total={customers.total}
                name={translate('listCustomersNamePagination')}
              />
            )}
          </>
        )}
      </Content>
      {modalCreateEditCustomerVisible && (
        <ModalRegisterEditCustomer
          customerToEdit={customerToEdit}
          visible
          closeModal={closeModal}
          setInfoFailedToRegisterOrEditCustomer={
            setInfoFailedToRegisterOrEditCustomer
          }
          setInfoFailedToRegisterOrEditManager={
            setInfoFailedToRegisterOrEditManager
          }
        />
      )}
      {infoFailedToRegisterOrEditCustomer && (
        <FailedModal
          visible
          setVisible={() => setInfoFailedToRegisterOrEditCustomer(undefined)}
          errorInfo={{
            title: infoFailedToRegisterOrEditCustomer.title,
            description: infoFailedToRegisterOrEditCustomer.description,
          }}
        />
      )}
      {infoFailedToRegisterOrEditManager && (
        <WarningModal
          visible={!!infoFailedToRegisterOrEditManager}
          setVisible={(value) => {
            value === false && setInfoFailedToRegisterOrEditManager(undefined);
          }}
          errorInfo={infoFailedToRegisterOrEditManager}
        />
      )}
      {modalManager && (
        <ModalManager
          visible={modalManager}
          closeModalManager={closeModalManager}
          idClient={manager}
          customer={customerToEdit}
        />
      )}
    </Container>
  );
};
