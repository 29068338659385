import React, { useEffect, useState } from 'react';

import { Question } from 'phosphor-react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Button';
import { Card } from 'src/components/Card';
import Maintenance from 'src/assets/maintenance.svg';
import { RootState } from 'src/redux/store';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { changeFilterQuantity } from 'src/models/redux/reducers/DependentVariablesConfig';

import { Tutorial } from './components/Tutorial';
import { Container, Content, CreateFilterContainer } from './styles';
import { CreateFilters } from './components/CreateFilters';

export const DependentVariablesConfig: React.FC = () => {
  const [openTutorial, setOpenTutorial] = useState(false);

  const {
    project,
    dependentVariablesConfig: { quantity, deletedAllFilters },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t: translate } = useTranslation();

  useEffect(() => {
    const alreadyAccessed = localStorage.getItem(
      'tutorialConfigVariablesAccessed',
    );
    if (alreadyAccessed !== 'true') {
      setOpenTutorial(true);
      localStorage.setItem('tutorialConfigVariablesAccessed', 'true');
    }
  }, []);

  useEffect(() => {
    if (project.id) {
      const yLength = project.y.filter((y) => y.status === 'success').length;

      const isDisabledAISelection =
        project.yBusiness.length === 0 ||
        project.yBusiness.find((yBus) => yBus.y === project.selectedY?.id)
          ?.status === 'error';

      if (yLength < 5) {
        if (yLength > 1) {
          navigate(`/models/time-series/${project.id}/project-overview`);
        } else if (isDisabledAISelection) {
          navigate(`/models/time-series/${project.id}/user-selection`);
        } else {
          navigate(`/models/time-series/${project.id}/AI-selection`);
        }
      }
    }
  }, [project, navigate]);

  return (
    <Container>
      <Content className="containerLinear">
        <Card
          textCard={translate('dependentVariablesConfigTitle')}
          textDescription={translate('dependentVariablesConfigDescription')}
        />

        <Button
          buttonType="naked"
          onClick={() => setOpenTutorial(true)}
          className="tutorial-button"
          data-testid="tutorial-button"
        >
          <Question />
        </Button>

        {!project.id ||
        (project.filters?.level_1 && !quantity && !deletedAllFilters) ? (
          <ContainerSkeleton data-testid="project-loading" />
        ) : !quantity && !deletedAllFilters ? (
          <CreateFilterContainer>
            <div>
              <img src={Maintenance} alt="maintenance" />

              <p>{translate('dependentVariablesConfigNoConfig')}</p>

              <Button
                buttonType="tertiary"
                onClick={() => dispatch(changeFilterQuantity({ value: 1 }))}
                data-testid="create-filter"
              >
                {translate('dependentVariablesConfigStartConfig')}
              </Button>
            </div>
          </CreateFilterContainer>
        ) : (
          <CreateFilters />
        )}

        <Tutorial visible={openTutorial} setVisible={setOpenTutorial} />
      </Content>
    </Container>
  );
};
