import backgroundLanding from 'src/assets/backgroundLanding.svg';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  background: url(${backgroundLanding}) no-repeat center;
  background-size: cover;
  height: 100%;
`;

export const HeaderLanding = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 3.438rem 12.5rem;

  @media (max-width: 1200px) {
    padding: 2rem 12.5rem;
  }
`;

export const LogoDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img:first-child {
    margin-right: 0.75rem;
  }
`;

export const Div4i = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 12.5rem;

  p:first-child {
    width: 19.875rem;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    color: #4c94ff;
    letter-spacing: 0.02em;
  }

  p {
    width: 7.938rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.375rem;
    color: #a0aec0;
  }

  hr {
    flex: 1;
    border: 1px solid #cbd5e0;
    margin: 0 1rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    /* margin-top: 132px; */
    width: 47.563rem;
    height: 8.875rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 4rem;
    line-height: 4.438rem;
    text-align: center;
    color: #000000;

    span {
      color: rgba(242, 125, 180, 1);
    }
  }

  p {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
    width: 30.5rem;
    height: 3.375rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.688rem;
    text-align: center;
    color: #718096;
  }
`;
