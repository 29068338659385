import React, { useContext, useEffect, useState } from 'react';

import { PushPin } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'src/components/Button';
import { CheckBox } from 'src/components/CheckBox';
import { Modal } from 'src/components/Modal';
import { changeColumns, Column } from 'src/models/redux/reducers/Project';
import api from 'src/models/service/api';
import { RootState } from 'src/redux/store';
import { queryClient } from 'src/service/queryClient';
import { ModalFooter } from 'src/components/Modal/Footer/styles';

import { ProjectOverviewContext, sortedColumn } from '../..';
import {
  ContainerModalEditColumns,
  ContentCheckBoxes,
  PinDependentVariable,
} from './styles';

export const ModalEditColumns: React.FC = () => {
  const [columnsSelected, setColumnsSelected] = useState<Column[]>([]);
  const [loading, setLoading] = useState(false);

  const { closeModalEditColumns, reportError } = useContext(
    ProjectOverviewContext,
  );

  const { project } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  useEffect(() => {
    if (project.columns.length) {
      setColumnsSelected(project.columns);
    }
  }, [project.columns]);

  function addOrRemoveColumns(column: Column) {
    const columnsAux = [...columnsSelected];
    if (columnsAux.includes(column)) {
      setColumnsSelected(columnsAux.filter((col) => col !== column));
    } else {
      setColumnsSelected([...columnsAux, column]);
    }
  }

  function closeModal() {
    if (loading) {
      return;
    }
    closeModalEditColumns();
  }

  function adjustColumnName(column: string): string {
    if (column === 'Transformation') {
      return translate('projectOverviewTransformation');
    }
    if (column === 'Model') {
      return translate('projectOverviewModel');
    }
    return column;
  }

  async function handleConfirm(): Promise<void> {
    setLoading(true);
    try {
      await api.put(`/projects/${project.id}/overview/columns`, {
        columns: columnsSelected,
      });
      setLoading(false);
      dispatch(changeColumns(columnsSelected));
      queryClient.removeQueries('projects');
      closeModal();
    } catch (err) {
      setLoading(false);
      reportError({
        title: translate('projectOverviewFailedEditTitle'),
        description: translate('projectOverviewFailedEditDescription'),
      });
      closeModal();
    }
  }

  return (
    <Modal visible setVisible={closeModal} style={{ padding: 0 }}>
      <ContainerModalEditColumns data-testid="container-customize-table">
        <h4>{translate('projectOverviewCustomizeYourTable')}</h4>
        <p>{translate('projectOverviewEditDescription')}</p>

        <PinDependentVariable>
          <PushPin size="1.25rem" />
          <p>{translate('projectOverviewFirstColumn')}</p>
        </PinDependentVariable>
        <ContentCheckBoxes>
          {sortedColumn.map((column) => (
            <CheckBox
              label={adjustColumnName(column)}
              key={`column-${column}`}
              onClick={() => addOrRemoveColumns(column)}
              checked={columnsSelected.includes(column)}
            />
          ))}
        </ContentCheckBoxes>
        <ModalFooter>
          <Button
            buttonType="naked"
            onClick={closeModal}
            disabled={loading}
            data-testid="button-cancel"
            data-cy="button-cancel"
          >
            {translate('cancel')}
          </Button>
          <Button
            buttonType="primary"
            onClick={() => handleConfirm()}
            loading={loading}
            disabled={loading || !columnsSelected.length}
            data-testid="button-save"
            data-cy="button-save"
          >
            {translate('save')}
          </Button>
        </ModalFooter>
      </ContainerModalEditColumns>
    </Modal>
  );
};
