const variableImportancePTBR = {
  variable_importance: 'Importância das Variáveis',
  data_view: 'Visualizar Dados',
  show_n_variables: 'Mostrar n variáveis',
  explanatory_variables: 'Variáveis explicativas',
  increase_of_the_mean: 'Aumento do erro quadrático médio',
  variableImportanceVariable: 'Variável',
  variableImportanceMessageErrorNoExplanatoryVariables:
    'Nenhuma variável explicativa selecionada.',
  variableImportanceMessageErrorEmptyDataset:
    'Conjunto de dados vazio após a remoção dos valores ausentes.',
};

export default variableImportancePTBR;
