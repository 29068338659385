const indicatorSeriesPTBRTranslate = {
  indicatorSeriesSubTitle: 'Veja todas as séries desse indicador.',
  indicatorSeries: 'Séries',
  indicatorSeriesAddFirstSeries: 'Adicione sua primeira série',
  indicatorSeriesAddFirstSeriesText:
    'Adicione sua primeira nova série a este indicador clicando no botão acima.',
  indicatorSeriesSearchSeries: 'Procurar séries',
  indicatorSeriesAddNewSeries: 'Adicionar nova série',
  indicatorSeriesSeriesCode: 'Código da série',
  indicatorSeriesRegion: 'Região',
  indicatorSeriesAggregation: 'Agregação',
  indicatorSeriesUnit: 'Unidade',
  indicatorSeriesPrimary: 'Transformação Primária',
  indicatorSeriesSecondary: 'Transformação Secundária',
  indicatorSeriesFailedToDeleteSeries: 'Falha ao excluir série',
  indicatorSeriesErrorDeleteSerie:
    'Não foi possível excluir a série, por favor tente novamente',
  indicatorSeriesModalDelete: 'Você tem certeza?',
  indicatorSeriesModalDeleteText:
    'Isso excluirá permanentemente esta série e todas as suas informações.',
  indicatorSeriesMaintenance: 'Isso colocará a série em status de manutenção',
  indicatorSeriesActive: 'Isso colocará a série em status ativo',
};

export default indicatorSeriesPTBRTranslate;
