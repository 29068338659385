import styled from 'styled-components';

type ContainerProps = {
  isMarketShare: boolean;
};

export const Container = styled.div<ContainerProps>`
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;

    width: 100%;

    p {
      text-align: start;
      font-weight: ${({ isMarketShare }) => (isMarketShare ? 500 : 400)};
    }
  }

  button {
    transition: all 0.2s;
  }

  svg {
    width: 0.875rem;
    height: 0.875rem;

    color: ${({ theme }) => theme.colors.gray4};
  }

  p {
    flex: 1;

    font-size: 0.75rem;
    font-weight: 400;
    line-height: 150%;

    color: ${({ theme }) => theme.colors.gray7};

    white-space: break-spaces;
    word-wrap: anywhere;
    overflow: hidden;
  }
`;

export const Spinner = styled.div`
  margin: 0;
  border: 3px solid ${({ theme }) => theme.colors.primary};
  border-left-color: ${({ theme }) => theme.colors.gray3};
  border-radius: 50%;
  width: 0.875rem;
  height: 0.875rem;
  animation: spin 0.8s linear infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;
