import React, { useState } from 'react';

import { Card } from 'src/components/Card';
import { Button } from 'src/components/Button';
import { DownloadSimple } from 'phosphor-react';
import api from 'src/models/service/api';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { FailedModal } from 'src/components/Modal/Failed';

import { AISelectionExportContainer } from './styles';

interface IResponseHeadersDowload {
  'content-disposition'?: string;
}

interface DownloadError {
  detail?: {
    detail?: string;
    description?: string;
  };
}

interface Error {
  title: string;
  description: string;
}

export const AISelectionExport: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [errorInformation, setErrorInformation] = useState<Error | null>(null);

  const {
    id,
    selectedY,
    name: projectName,
  } = useSelector((state: RootState) => state.project);

  const { t: translate } = useTranslation();

  async function handleDownload() {
    try {
      setLoading(true);
      const { data, headers } = await api.get(
        `/projects/${id}/${selectedY?.id}/models/business/download`,
        {
          responseType: 'blob',
        },
      );

      if (data) {
        const fileURL = window.URL.createObjectURL(
          new Blob([data], { type: 'text/xlsx' }),
        );

        const contentDisposition = (headers as IResponseHeadersDowload)[
          'content-disposition'
        ];

        const link = document.createElement('a');

        const name =
          contentDisposition?.slice(
            contentDisposition.indexOf('filename=') + 9,
            contentDisposition.length,
          ) ?? `${projectName}-${selectedY?.label}.xlsx`;

        if (link.download !== undefined) {
          link.setAttribute('href', fileURL);
          link.setAttribute('download', name);
          link.setAttribute('data-testid', 'download-start');
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
        }
      }
    } catch (err) {
      const error: AxiosError<DownloadError> | any = err;
      const errorMessage =
        error?.response?.data?.detail?.detail ??
        error?.response?.data?.detail?.description ??
        translate('AISelectionDownloadError');

      setErrorInformation({
        title: translate('requestFailed'),
        description: errorMessage,
      });
    }
    setLoading(false);
  }

  function closeModal() {
    setErrorInformation(null);
  }

  return (
    <AISelectionExportContainer className="containerLinear">
      <Card
        textCard={translate('AISelectionExportTitle')}
        textDescription={translate('AISelectionExportDescription')}
      />
      <Button
        buttonType="secondary"
        icon={<DownloadSimple size="1.125rem" weight="bold" />}
        data-testid="ia-selection-export-button"
        data-cy="ia-selection-export-button"
        onClick={handleDownload}
        loading={loading}
        disabled={loading}
      >
        {translate('AISelectionExportDownload')}
      </Button>
      {errorInformation && (
        <FailedModal
          errorInfo={{
            title: errorInformation.title,
            description: errorInformation.description,
          }}
          setVisible={closeModal}
          visible
        />
      )}
    </AISelectionExportContainer>
  );
};
