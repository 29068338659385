/* eslint-disable prettier/prettier */
/* eslint-disable no-useless-escape */

const apiURL = process.env.REACT_APP_API_CLAAS_URL;

export const sample = {
  python: `import httpx
import asyncio
url = '${apiURL}/projects'
headers = {
  'Authorization': 'Bearer <your token>'
}
payload = {
    'var_y': '<name of target variable>',
    'project_name': '<your project name>',
    'icon_url': 'http://4i-default.com.br'
}
files = {
  'dataset': open('path to your dataset', 'rb')
}
client = httpx.AsyncClient(http2=True)
response = asyncio.run(client.post(url, headers=headers, data=payload,
                                   files=files))
print(response.text)`,
  java: `import java.io.File;
import java.io.IOException;
import java.nio.file.Files;
import java.nio.file.Paths;
import java.util.HashMap;
import java.util.Map;
import okhttp3.MediaType;
import okhttp3.MultipartBody;
import okhttp3.OkHttpClient;
import okhttp3.Request;
import okhttp3.RequestBody;
import okhttp3.Response;

public class HttpRequestExample {
    public static void main(String[] args) throws IOException {
        String url = "${apiURL}";
        String token = "<your token>";
        String targetVariable = "<name of target variable>";
        String projectName = "<your project name>";
        String datasetPath = "<path to your dataset>";
        String iconUrl = "http://4i-default.com.br";

        // Read the dataset file
        File datasetFile = new File(datasetPath);
        byte[] datasetBytes = Files.readAllBytes(Paths.get(datasetFile.getAbsolutePath()));

        // Construct the multipart request body
        RequestBody requestBody = new MultipartBody.Builder()
                .setType(MultipartBody.FORM)
                .addFormDataPart("var_y", targetVariable)
                .addFormDataPart("project_name", projectName)
                .addFormDataPart("icon_url", iconUrl)
                .addFormDataPart("dataset", datasetFile.getName(),
                        RequestBody.create(MediaType.parse("application/octet-stream"), datasetBytes))
                .build();

        // Create the request with headers and body
        Request request = new Request.Builder()
                .url(url)
                .addHeader("Authorization", "Bearer " + token)
                .post(requestBody)
                .build();

        // Create the OkHttpClient instance and execute the request
        OkHttpClient client = new OkHttpClient();
        Response response = client.newCall(request).execute();

        // Get the response body
        String responseBody = response.body().string();
        System.out.println(responseBody);
    }
}`,
  bash: `#!/bin/bash
url="${apiURL}"
token="<your token>"
targetVariable="<name of target variable>"
projectName="<your project name>"
datasetPath="<path to your dataset>"
iconUrl="http://4i-default.com.br"
response=$(nghttp -v -H "Authorization: Bearer $token" -F
"var_y=$targetVariable" -F "project_name=$projectName" -F
"icon_url=$iconUrl" -F "dataset=@$datasetPath" $url 2>&1)
response_text=$(echo "$response" | awk '/^\{/,0')
echo "$response_text"`,
};
