import React, { useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Modal } from 'src/components/Modal';
import { RootState } from 'src/redux/store';
import { Button } from 'src/components/Button';
import { useTranslation } from 'react-i18next';
import { Warning } from 'phosphor-react';
import {
  insertDailyProject1Y,
  insertDailyProjectMultipleY,
} from 'src/models/redux/reducers/ProjectWarnings';

import { ContainerModal, ContentButton } from './styles';

export const WarningDailyProject: React.FC = () => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const { project, projectWarnings } = useSelector((state: RootState) => state);

  const projectYsSuccess = useMemo(
    () => project.y.filter((y) => y.status === 'success'),
    [project.y],
  );

  if (
    !project.selectedY?.info ||
    project.selectedY?.info.frequency !== 'daily'
  ) {
    return <></>;
  }

  function setWarningWasShownOnThisY() {
    if (project.id && project.selectedY?.id) {
      dispatch(
        insertDailyProject1Y({
          projectId: project.id,
          yId: project.selectedY?.id,
        }),
      );
    }
  }

  function setWarningWasShownAllYs() {
    if (project.id) {
      dispatch(
        insertDailyProjectMultipleY({
          projectId: project.id,
          ysId: projectYsSuccess.map((y) => y.id),
        }),
      );
    }
  }

  const registeredProject = projectWarnings.dailyProject.find(
    //Verifica se o projeto aberto ja foi cadastrado no redux se ss retorna ele se nao undefined
    (projectAux) => projectAux.id === project.id,
  );

  if (pathname.includes('project-overview')) {
    //Caso o projeto ja foi registrado e ele tem a mesma quantidade de variável Y que tem no projeto quer dizer que o modal ja apareceu no overview
    if (
      registeredProject &&
      registeredProject?.ysId.length >= projectYsSuccess.length
    ) {
      return <></>;
    }

    //Ira acontecer na primeira vez que entra no overview onde vai verificar se todas as variáveis contem o valor menor do que o esperado se ss aparece o modal
    if (
      projectYsSuccess.every(
        (y) =>
          y.info &&
          y.info.frequency === 'daily' &&
          y.info.training_row_numbers < 180,
      )
    ) {
      return (
        <Modal
          visible
          setVisible={setWarningWasShownAllYs}
          style={{ padding: 0 }}
        >
          <ContainerModal data-testid="warning-daily-container">
            <Warning size="3rem" data-testid="x-circle-icon" />

            <p>{translate('warningDailyProjectMessageAllYs')}</p>

            <ContentButton>
              <Button
                buttonType="primary"
                onClick={setWarningWasShownAllYs}
                data-testid="button-warning-daily"
                data-cy="button-warning-daily"
              >
                Ok
              </Button>
            </ContentButton>
          </ContainerModal>
        </Modal>
      );
    }

    //Se Apenas algumas variáveis contem o valor menor ir cair aqui pra seguir adiante
    return <></>;
  }

  //Verifica se o modal do Y selecionado ja foi mostrado
  const warningSelectedYAlreadyShown = registeredProject?.ysId.includes(
    project.selectedY.id,
  );

  //Se o modal do Y nao for selecionado e essa Y tiver o valor menor do que esperado mostrar o modal
  if (
    !warningSelectedYAlreadyShown &&
    project.selectedY?.info &&
    project.selectedY.info.training_row_numbers < 180
  ) {
    return (
      <Modal
        visible
        setVisible={setWarningWasShownOnThisY}
        style={{ padding: 0 }}
      >
        <ContainerModal data-testid="warning-daily-container">
          <Warning size="3rem" data-testid="x-circle-icon" />

          <p>{translate('warningDailyProjectMessage1Y')}</p>

          <ContentButton>
            <Button
              buttonType="primary"
              onClick={setWarningWasShownOnThisY}
              data-testid="button-warning-daily"
              data-cy="button-warning-daily"
            >
              Ok
            </Button>
          </ContentButton>
        </ContainerModal>
      </Modal>
    );
  }

  //Caso esse Y selecionado tem o valor maior que o esperado
  return <></>;
};
