import React, { useState } from 'react';

import { AxiosError } from 'axios';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';
import { FailedModal } from 'src/components/Modal/Failed';
import { ModalLoading } from 'src/components/Modal/Loading';
import api from 'src/feature-store/service/api';
import { queryClient } from 'src/service/queryClient';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { ModalFooter } from 'src/components/Modal/Footer/styles';

import { InputTag } from './InputTag';
import { ContentInputModal, ContentModal, DivInput } from './styles';

interface RequestDataErrorProps {
  message: string;
  status: number;
}

interface ResponseDataErrorProps {
  title?: string;
  description?: string;
}

interface InsertIndicatorGroupPremiumProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  idGroup: string;
}

export const InsertUserGroupPremium: React.FC<
  InsertIndicatorGroupPremiumProps
> = ({ visible, setVisible, idGroup }) => {
  const [emails, setEmails] = useState<string[]>([]);
  const [responseLoading, setResponseLoading] = useState<boolean>(false);
  const [responseDataError, setResponseDataError] =
    useState<ResponseDataErrorProps>();
  const [failedModalVisible, setFailedModalVisible] = useState(false);
  const { t: translate } = useTranslation();
  const { language } = useSelector((state: RootState) => state.auth.user);

  const handleAddUserGroup = async () => {
    setResponseLoading(true);
    try {
      for (let i = 0; i < emails.length; i++) {
        await api.post(`/access-groups/${idGroup}/users`, {
          email: emails[i],
        });
      }

      setVisible(false);
      setResponseLoading(false);
      queryClient.removeQueries('usersFromGroup');
      queryClient.removeQueries(['groupsPremium']);
    } catch (err) {
      const error = err as AxiosError<RequestDataErrorProps>;
      if (error.response && error.response.status) {
        setResponseDataError({
          title: translate('accessError'),
          description:
            error.response.data.message === 'The resource already exists.'
              ? language === 'pt-br'
                ? 'Esse email já existe nesse grupo'
                : 'This email already exists in this group'
              : error.response.data.message,
        });
        setFailedModalVisible(true);
        setResponseLoading(false);
      }
    }
  };

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <ContentModal
        style={{ height: '764px' }}
        data-testid="modal-insert-user-group"
        data-cy="modal-insert-user-group"
      >
        <ContentInputModal>
          <h4>{translate('accessNewUserTitle')}</h4>
          <p>{translate('accessNewUserDescription')}</p>
          <DivInput>
            <p>{translate('accessNewUser')}</p>
            <InputTag
              emails={emails}
              setEmails={setEmails}
              testid="input-user"
              placeholder={translate('accessInputPlaceHolder')}
            />
          </DivInput>
        </ContentInputModal>
      </ContentModal>
      <ModalFooter>
        <Button
          buttonType="naked"
          onClick={() => setVisible(false)}
          data-testid="button-cancelar"
          data-cy="button-cancelar"
        >
          {translate('cancel')}
        </Button>
        <Button
          buttonType="primary"
          data-testid="button-add"
          data-cy="button-add"
          onClick={() => handleAddUserGroup()}
          disabled={emails.length === 0}
        >
          {translate('accessContinue')}
        </Button>
      </ModalFooter>
      {responseLoading && (
        <ModalLoading
          data-testid="loading-add-users-premium"
          visible={responseLoading}
        />
      )}
      {responseDataError && (
        <FailedModal
          visible={failedModalVisible}
          errorInfo={responseDataError}
          setVisible={setFailedModalVisible}
        />
      )}
    </Modal>
  );
};
