import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  position: relative;
`;

export const ContainerTable = styled.div`
  position: relative;
  overflow-y: auto;
  overflow-x: auto;

  ::-webkit-scrollbar-track {
    margin-top: 2rem;
  }

  > table {
    word-break: break-word;
    margin: auto;

    > thead {
      > tr {
        position: sticky;
        top: 0px;
        z-index: 99;
        background: ${({ theme }) => theme.colors.white};

        > th {
          width: 12.5rem;
          position: relative;
        }
        > th:last-child {
          min-width: 8.25rem;
          max-width: 8.25rem;
          position: relative;
        }
      }
    }
    tbody {
      > tr {
        > td {
          p {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
`;

export const ContentSearchAndButtonCreate = styled.div`
  display: flex;
  gap: 1.5rem;
  position: absolute;
  top: 2.5rem;
  right: 1.5rem;
  > div:first-child {
    svg {
      color: ${({ theme }) => theme.colors.gray4};
      margin-left: 0;
    }
    width: 20rem;
  }

  @media (max-width: 900px) {
    > div:first-child {
      width: 12.5rem;
    }
  }

  @media (max-width: 780px) {
    position: unset;
    justify-content: flex-end;
    > div:first-child {
      width: 100%;
    }
    > button:nth-child(2) {
      width: 21rem;
    }
  }
`;

export const CardFeatures = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  svg {
    color: ${({ theme }) => theme.colors.gray4};
    transition: 0.2s color;
    margin-right: 1.5rem;
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.colors.gray5};
    }
  }
`;
