import styled from 'styled-components';

export const ContainerModal = styled.div`
  width: 26.25rem;

  padding: 1.5rem 1.5rem 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  > svg {
    color: ${({ theme }) => theme.colors.yellow4};
    margin-bottom: 1.5rem;
  }

  > p {
    color: ${({ theme }) => theme.colors.gray5};
    font-weight: 500;
    font-size: 1rem;
    line-height: 150%;
    margin-bottom: 3rem;
  }
`;

export const ContentButton = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.gray2};
  background: ${({ theme }) => theme.colors.gray0};
  width: 26.25rem;
  padding: 1.5rem 1.5rem;
  border-radius: 0 0 8px 8px;

  display: flex;
  justify-content: flex-end;
`;
