const lagsModalENUSTranslations = {
  lagsModalTitle: 'Lag Search',
  lagsModalDescr: `Easily create explanatory features' lags. We will evaluate all lags up
  to the maximum value indicated, selecting the optimal lagged value for
  each variable.`,
  lagsModalDescripPreviousReplaced: 'Previous lags setting has been replaced.',
  lagNumberLabel: 'Lag number',
  lagVariablesLabel: 'Choose Variable(s)',
};

export default lagsModalENUSTranslations;
