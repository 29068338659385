import styled, { css } from 'styled-components';

export const Container = styled.div`
  .customTooltipTheme {
    max-width: 37.5rem !important;
  }
`;

export const Content = styled.div`
  position: relative;
`;

export const ContainerTable = styled.div`
  position: relative;
  overflow-y: auto;
  overflow-x: auto;

  ::-webkit-scrollbar-track {
    margin-top: 2rem;
  }

  > table {
    word-break: break-word;
    border-spacing: unset;
    margin: auto;

    > thead {
      > tr {
        position: sticky;
        top: 0px;
        z-index: 99;
        background: ${({ theme }) => theme.colors.white};

        > th {
          min-width: 12.5rem;
          max-width: 12.5rem;
          position: relative;

          p {
            font-weight: 600;
            font-size: 0.75rem;
            line-height: 1rem;
            opacity: 0.8;
          }
        }

        > th:nth-last-child(2) {
          min-width: 4.188rem;
          max-width: 4.188rem;
          position: relative;
        }

        > th:last-child {
          position: sticky;
          opacity: 1;
          right: 0;
          min-width: 8.313rem;
          max-width: 8.313rem;
          background: ${({ theme }) => theme.colors.white};
          border-left: ${({ theme }) => `1px solid ${theme.colors.gray1}`};
        }
      }
    }
    tbody {
      > tr {
        position: relative;
        &:first-child {
          td {
            padding-top: 1.5rem;
          }
        }
        > td {
          padding: 0.75rem 0 0.75rem 1rem;

          p {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          &:last-child {
            position: sticky;
            right: 0;
            border-left: ${({ theme }) => `1px solid ${theme.colors.gray1}`};
            background: ${({ theme }) => theme.colors.white};

            > div {
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: row-reverse;
            }
          }
        }
      }
    }
  }
`;

export const ContentSearchAndButtonCreate = styled.div`
  display: flex;
  gap: 1.5rem;
  position: absolute;
  top: 2.5rem;
  right: 1.5rem;
  > div:first-child {
    svg {
      color: ${({ theme }) => theme.colors.gray4};
      margin-left: 0;
    }
    width: 20rem;
  }

  @media (max-width: 900px) {
    > div:first-child {
      width: 12.5rem;
    }
  }

  @media (max-width: 780px) {
    position: unset;
    justify-content: flex-end;
    > div:first-child {
      width: 100%;
    }
    > button:nth-child(2) {
      width: 21rem;
    }
  }
`;

interface ButtonIconProps {
  disabled?: boolean;
}

export const ButtonIcon = styled.button<ButtonIconProps>`
  float: right;
  ${({ disabled }) =>
    !disabled
      ? css`
          color: ${({ theme }) => theme.colors.gray5};
          transition: 0.2s color;
          &:hover {
            color: ${({ theme }) => theme.colors.gray6};
          }
        `
      : css`
          color: ${({ theme }) => theme.colors.gray4};
          cursor: not-allowed;
        `};
`;
