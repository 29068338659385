import styled, { css } from 'styled-components';

export const ContainerProgressInDots = styled.div`
  position: absolute;
  left: calc(50% - 1.875rem);

  @media (max-width: 800px) {
    position: unset;
  }

  @media (max-width: 640px) {
    display: none;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
`;

interface Dot {
  selected: boolean;
  disabled: boolean;
}

export const Dot = styled.button<Dot>`
  border-radius: 50%;
  border: 0;
  padding: 0 !important;

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  ${({ selected }) =>
    selected
      ? css`
          width: 0.75rem;
          height: 0.75rem;
          background: ${({ theme }) => theme.colors.primary};
        `
      : css`
          width: 0.5rem;
          height: 0.5rem;
          background: ${({ theme }) => theme.colors.gray2};
        `}
`;
