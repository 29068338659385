const variableImportanceEN = {
  variable_importance: 'Variable Importance',
  data_view: 'Data View',
  show_n_variables: 'Show n variables',
  explanatory_variables: 'Explanatory variables',
  increase_of_the_mean: 'Increase of the mean squared error',
  variableImportanceVariable: 'Variable',
  variableImportanceMessageErrorNoExplanatoryVariables:
    'No explanatory variables selected.',
  variableImportanceMessageErrorEmptyDataset:
    'Empty dataset after removing missing values.',
};

export default variableImportanceEN;
