import React, { useContext } from 'react';

import { FeatureStoreSidebarContext } from '../../../Contexts/NavigationContext';
import { Categories } from '../Categories';
import { CountryFilter } from '../CountryFilter';
import { Premium } from '../Premium';
import { SideBar } from './styles';

export const SideBarFS: React.FC = () => {
  const { indicatorFiltersVisible, premiumFiltersVisible } = useContext(
    FeatureStoreSidebarContext,
  );

  return (
    <>
      <SideBar visible={indicatorFiltersVisible}>
        <CountryFilter />
        <Categories />
      </SideBar>

      <SideBar visible={premiumFiltersVisible}>
        <Premium />
      </SideBar>
    </>
  );
};
