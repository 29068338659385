import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { CaretDown } from 'phosphor-react';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Tooltip } from 'react-tooltip';

import { Container } from './styles';
import { ModelRecipe } from '../../ModelExplorer/types';

interface ModelDetailsProps {
  data: ModelRecipe | undefined;
  isFetching: boolean;
  isLoading: boolean;
  isErrored: boolean;
}

export const ModelDetails: React.FC<ModelDetailsProps> = ({
  data,
  isFetching,
  isLoading,
  isErrored,
}) => {
  const { t: translate } = useTranslation();

  const [infoVisible, setInfoVisible] = useState(false);

  return (
    <Container infoVisible={infoVisible}>
      <Tooltip
        id="models-classification-model-details"
        className="customTooltipTheme"
      />

      <div>
        <p>{translate('claasModelDetailsDescription')}</p>
        <table>
          <thead>
            <tr>
              <th>{translate('claasModelDetailsStep')}</th>
              <th
                data-tooltip-id="models-classification-model-details"
                data-tooltip-html={translate('fillNumValuesDesc')}
              >
                {translate('claasFillNumValues')}
              </th>
              <th
                data-tooltip-id="models-classification-model-details"
                data-tooltip-html={translate('fillCatValuesDesc')}
              >
                {translate('claasFillCatValues')}
              </th>
              <th
                data-tooltip-id="models-classification-model-details"
                data-tooltip-html={translate('scalingDesc')}
              >
                {translate('claasScaling')}
              </th>
              <th
                data-tooltip-id="models-classification-model-details"
                data-tooltip-html={translate('encondingDesc')}
              >
                {translate('claasEncoding')}
              </th>
              <th
                data-tooltip-id="models-classification-model-details"
                data-tooltip-html={translate('balancingDesc')}
              >
                {translate('claasBalancing')}
              </th>
              <th
                data-tooltip-id="models-classification-model-details"
                data-tooltip-html={translate('featSelectionDesc')}
              >
                {translate('claasFeatureSelection')}
              </th>
              <th
                data-tooltip-id="models-classification-model-details"
                data-tooltip-html={translate('classfierDesc')}
              >
                {translate('claasClassfier')}
              </th>
            </tr>
          </thead>
          <tbody>
            {isErrored ? (
              <tr>
                <td>{translate('claasModelDetailsStrategy')}</td>
                {Array.from({ length: 7 }).map((_, index) => (
                  <td
                    key={`errored-${index * 1}`}
                    data-testid={`error-cell-${index * 1}`}
                  >
                    --
                  </td>
                ))}
              </tr>
            ) : isLoading || isFetching ? (
              <tr>
                <td>{translate('claasModelDetailsStrategy')}</td>
                {Array.from({ length: 7 }).map((_, index) => (
                  <td key={`loading-${index * 1}`} aria-label="loading">
                    <ContainerSkeleton
                      data-testid={`loading-cell-${index * 1}`}
                      withLoading={false}
                      style={{ height: '24px' }}
                    />
                  </td>
                ))}
              </tr>
            ) : (
              data && (
                <tr>
                  <td>{translate('claasModelDetailsStrategy')}</td>
                  <td data-testid="fill-num-values" data-cy="fill-num-values">
                    {data.model_recipe.fill_numeric_values
                      ? translate(`${data.model_recipe.fill_numeric_values}Hyp`)
                      : '--'}
                  </td>
                  <td data-testid="fill-cat-values" data-cy="fill-cat-values">
                    {data.model_recipe.fill_categorical_values
                      ? translate(
                          `${data.model_recipe.fill_categorical_values}Hyp`,
                        )
                      : '--'}
                  </td>
                  <td data-testid="scaling" data-cy="scaling">
                    {data.model_recipe.scaling
                      ? translate(`${data.model_recipe.scaling}Hyp`)
                      : '--'}
                  </td>
                  <td data-testid="encoding" data-cy="encoding">
                    {data.model_recipe.encoding ?? '--'}
                  </td>
                  <td data-testid="balancing" data-cy="balancing">
                    {data.model_recipe.balancing
                      ? translate(`${data.model_recipe.balancing}Hyp`)
                      : '--'}
                  </td>
                  <td
                    data-testid="feature-selection"
                    data-cy="feature-selection"
                  >
                    {data.model_recipe.feature_selection
                      ? translate(`${data.model_recipe.feature_selection}Hyp`)
                      : '--'}
                  </td>
                  <td data-testid="classfier" data-cy="classfier">
                    {data.model_recipe.classifier ?? '--'}
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
      <button
        type="button"
        data-testid="show-table-button"
        data-cy="show-table-button"
        onClick={() => setInfoVisible((value) => !value)}
      >
        <span>{translate('claasModelDetails')}</span>
        <CaretDown size="1rem" weight="bold" />
      </button>
    </Container>
  );
};
