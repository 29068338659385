import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type PayloadSideBar = {
  isExpanded: boolean;
};

const initialState: PayloadSideBar = {
  isExpanded: true,
};

export const SideBarState = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    changeIsExpanded: (state, action: PayloadAction<boolean>) => {
      state.isExpanded = action.payload;
    },
  },
});

export const { changeIsExpanded } = SideBarState.actions;

export default SideBarState.reducer;
