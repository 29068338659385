import styled from 'styled-components';

export const ContainerPotentialOutliers = styled.div`
  color: ${({ theme }) => theme.colors.gray6};
  p {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
