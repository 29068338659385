import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { Input } from 'src/components/Input';
import { Modal } from 'src/components/Modal';
import { ModalLoading } from 'src/components/Modal/Loading';
import { ToggleSwitch } from 'src/components/ToggleSwitch';
import api from 'src/feature-store/service/api';
import { RootState } from 'src/redux/store';
import { queryClient } from 'src/service/queryClient';
import * as Yup from 'yup';
import { Select } from 'src/components/Select';
import { ModalFooter } from 'src/components/Modal/Footer/styles';

import { ISerieToEdit } from '../types';
import { ContainerModal } from './styles';

type ParamsProps = {
  id: string;
};

interface FormEditSerie {
  unitPT: string;
  unitEN: string;
  status: string;
  access_type: string;
}

interface IModalEditSerie {
  indicatorAccessType?: string;
  serieToEdit: ISerieToEdit;
  setSerieToEdit: (value: ISerieToEdit | null) => void;
  setVisibleFailed(visible: boolean): void;
}

export const ModalEditSerie: React.FC<IModalEditSerie> = ({
  indicatorAccessType,
  serieToEdit,
  setSerieToEdit,
  setVisibleFailed,
}) => {
  const [loading, setLoading] = useState(false);

  const { id: indicatorId } = useParams<ParamsProps>();
  const { t: translate } = useTranslation();

  const { language } = useSelector((state: RootState) => state.auth.user);
  const handleCancel = () => {
    setSerieToEdit(null);
  };

  const editSerie = Yup.object().shape({
    unitEN: Yup.string().trim().max(50, 'editSerieMax').min(1, 'editSerieMin'),
    unitPT: Yup.string().trim().max(50, 'editSerieMax').min(1, 'editSerieMin'),
    access_type: Yup.string().trim().required('accessRequiredField'),
  });

  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<FormEditSerie>({
    resolver: yupResolver(editSerie),
    defaultValues: serieToEdit
      ? {
          unitPT: serieToEdit.unitPT,
          unitEN: serieToEdit.unitEN,
          status: serieToEdit.status,
          access_type: serieToEdit.access_type,
        }
      : undefined,
  });

  const handlePutOrRemoveTheSerieUndedrMaintenance = async () => {
    setLoading(true);
    try {
      const form = getValues();

      await api.patch(`/indicators/${indicatorId}/series/${serieToEdit.code}`, {
        access_type: form.access_type,
        unit: {
          'en-us': form.unitEN,
          'pt-br': form.unitPT,
        },
        status: form.status,
      });
      queryClient.removeQueries(['indicator data', indicatorId]);
      setSerieToEdit(null);
    } catch (err) {
      setSerieToEdit(null);
      setVisibleFailed(true);
    }
    setLoading(false);
  };

  if (loading) {
    return (
      <ModalLoading
        visible
        style={{ padding: 0, width: '280px' }}
        data-testid="container-loading"
      />
    );
  }
  return (
    <>
      <Modal visible setVisible={() => setSerieToEdit(null)}>
        <ContainerModal>
          <h2>{translate('editSerieTitle')}</h2>

          <Controller
            name="unitPT"
            key="unitPT"
            control={control}
            defaultValue={serieToEdit.unitPT}
            render={({ field: { onChange, value } }) => (
              <Input
                label={translate('editSerieUnitPT')}
                onChange={onChange}
                value={value}
                placeholder={translate('editSerieUnitPTPlaceholder')}
                //@ts-expect-error typescript bugando
                error={translate(errors.unitPT?.message)}
                data-testid="input-unit-pt"
              />
            )}
          />
          <Controller
            name="unitEN"
            key="unitEN"
            control={control}
            defaultValue={serieToEdit.unitEN}
            render={({ field: { onChange, value } }) => (
              <Input
                label={translate('editSerieUnitEN')}
                onChange={onChange}
                value={value}
                placeholder={translate('editSerieUnitENPlaceholder')}
                //@ts-expect-error typescript bugando
                error={translate(errors.unitEN?.message)}
                data-testid="input-unit-EN"
              />
            )}
          />

          <Controller
            name="access_type"
            key="typeAccess"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                label="Tipo de Acesso"
                data-cy="access_type"
                options={
                  indicatorAccessType === 'default'
                    ? [
                        {
                          value: 'default',
                          label: 'Básico',
                        },
                      ]
                    : indicatorAccessType === 'freemium'
                    ? [
                        {
                          value: 'default',
                          label: 'Básico',
                        },
                        {
                          value: 'freemium',
                          label: 'Freemium',
                        },
                      ]
                    : indicatorAccessType === 'premium'
                    ? [
                        {
                          value: 'premium',
                          label: 'Premium',
                        },
                      ]
                    : indicatorAccessType === 'private'
                    ? [
                        {
                          value: 'private',
                          label: 'Privado',
                        },
                      ]
                    : []
                }
                onChange={(select: any) => onChange(select.value)}
                value={
                  value === 'default'
                    ? {
                        label: 'Básico',
                        value,
                      }
                    : value === 'premium'
                    ? {
                        label: 'Premium',
                        value,
                      }
                    : value === 'freemium'
                    ? {
                        label: 'Freemium',
                        value,
                      }
                    : value === 'private'
                    ? {
                        label: 'Privado',
                        value,
                      }
                    : undefined
                }
                placeholder="Tipo de acesso"
                error={errors.access_type?.message}
                data-testid="input-typeAccess"
              />
            )}
          />

          <Controller
            name="status"
            key="isActive"
            control={control}
            defaultValue={serieToEdit.status}
            render={({ field: { onChange, value } }) => (
              <ToggleSwitch
                label={translate('editSerieMaintenance')}
                isPTBR={language === 'pt-br'}
                useYesOrNo
                onChange={(e) => {
                  onChange(
                    e.target.checked === true ? 'maintenance' : 'active',
                  );
                }}
                checked={value === 'maintenance'}
                data-testid="toggle-isActive"
                data-cy="toggle-isActive"
              />
            )}
          />
        </ContainerModal>
        <ModalFooter>
          <Button
            buttonType="naked"
            onClick={() => handleCancel()}
            data-testid="button-maintenance-cancel"
            data-cy="button-maintenance-cancel"
          >
            {translate('cancel')}
          </Button>
          <Button
            buttonType="primary"
            onClick={handleSubmit(() =>
              handlePutOrRemoveTheSerieUndedrMaintenance(),
            )}
            data-testid="button-maintenance-confirm"
            data-cy="button-maintenance-confirm"
          >
            {translate('editSerieButton')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
