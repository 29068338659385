import React from 'react';

import { useTranslation } from 'react-i18next';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';

import {
  ContainerLoadingModalRegisterEditCustomer,
  LoadingInput,
} from './styles';

export const LoadingModalRegisterEditCustomer: React.FC = () => {
  const { t: translate } = useTranslation();

  return (
    <ContainerLoadingModalRegisterEditCustomer>
      <LoadingInput>
        <p>{translate('modalRegisterNewCustomerNumberOfLicense')}</p>
        <ContainerSkeleton withLoading={false} />
      </LoadingInput>
    </ContainerLoadingModalRegisterEditCustomer>
  );
};
