const createSeriesENTranslate = {
  addNewSerie: 'Add new series to XX',
  addNewSerieText:
    'When finished, click “Continue” to review all series that will be added',
  addNewSerieRegion: 'Region',
  addNewSerieAggregation: 'Frequency/Aggregation',
  addNewSeriePrimary: 'Primary transformation',
  addNewSerieSecondary: 'Secondary transformation',
  addNewSeriesWarning: 'Only 150 combinations can be selected',
  addNewSerieContinue: 'Continue',
  addNewSerieUnableToLoad: 'Unable to load',
};

export default createSeriesENTranslate;
