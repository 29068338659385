export const AISelectionResultENTranslate = {
  AISelectionResultTitle: 'Results',
  AISelectionResultDescription:
    'Select a historical period to see more details about the modeling results.',
  AISelectionResultFrequency: 'Frequency',
  AISelectionResultTransformation: 'Transformation',
  AISelectionResultOriginal: 'Original',
  AISelectionResultMonthly: 'Monthly',
  AISelectionResultQuarterly: 'Quarterly',
  AISelectionResultAnnual: 'Annual',
  AISelectionResultLevel: 'Level',
  AISelectionResultVariation: 'Variation',
  AISelectionResultLatestData: 'Latest data',
  AISelectionResultsVariationOriginal:
    'Variation (compared to the previous period)',
  AISelectionResultsVariationMonthlyQuarterly:
    'Variation (compared to the same period in the previous year)',
  AISelectionResultsVariationAnnual:
    'Variation (compared to the previous year)',
  AISelectionMonthlyAggregationIsNotAvailable:
    'Monthly aggregation is not available for dataset with missing values.',
  AISelectionResultsNotEnoughObservationsVariation:
    'Not enough observations to calculate variation.',
  AISelectionResultsThisFeatureTemporarilyUnavailable:
    'This feature is temporarily unavailable.',
};
