import React, { useState, useEffect } from 'react';

import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card } from 'src/components/Card';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Head } from 'src/components/Head';
import { ErrorObject, FailedModal } from 'src/components/Modal/Failed';
import { Select } from 'src/components/Select';
import { DataError } from 'src/interface/axios';
import { changeIsFinish } from 'src/models/redux/reducers/ProjectOverviewExportTimeSeries';
import api from 'src/models/service/api';
import { RootState } from 'src/redux/store';
import { queryClient } from 'src/service/queryClient';
import { resetUserSelectionOptions } from 'src/models/redux/reducers/AIUserSelectionOptions';
import { Tooltip } from 'react-tooltip';

import {
  Container,
  ContainerSendProduction,
  ContentButtonSendProduction,
  ModelInfoListInformation,
  ModelList,
  SendButton,
} from '../../styles';
import { ModelInfo } from '../types';
import { CrossValidationWindows } from '../components/CrossValidationWindows';
import { PercentageErrorMetrics } from '../components/PercentageErrorMetrics';
import { LevelErrorMetric } from '../components/LevelErrorMetric';
import { ScaledErrorMetrics } from '../components/ScaledErrorMetrics';

export const FCCrossValidation: React.FC = () => {
  const [modelId, setModelId] = useState(1);
  const [sendToProdDisabled, setSendToProdDisabled] = useState(true);
  const [sendToProdLoading, setSendToProdLoading] = useState(false);
  const [failedModalVisible, setFailedModalVisible] = useState(false);
  const [failedModalInfo, setFailedModalInfo] = useState<ErrorObject>();

  const { project } = useSelector((state: RootState) => state);
  const navigate = useNavigate();

  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  const {
    data: dataModelInfo,
    isLoading: isLoadingModelInfo,
    isFetching: isFetchingModelInfo,
    isError: isErrorModelInfo,
  } = useQuery(
    [
      'forecast-combination model info',
      project.id,
      project.selectedY?.id,
      modelId,
    ],
    async () => {
      const { data } = await api.get<ModelInfo>(
        `/projects/${project.id}/${project.selectedY?.id}/models/forecast-combination/${modelId}/info`,
      );

      return data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled:
        !!project.id && !!project.selectedY?.id && !!modelId && !!project.model,
    },
  );

  useEffect(() => {
    if (project.id && project.selectedY && modelId) {
      setSendToProdDisabled(false);
    } else {
      setSendToProdDisabled(true);
    }
  }, [modelId, project.id, project.selectedY]);

  async function handleSendToProduction() {
    setSendToProdLoading(true);
    setSendToProdDisabled(true);

    try {
      const { data } = await api.post(
        `/projects/${project.id}/${project.selectedY?.id}/model-in-production/forecast-combination/${modelId}`,
      );

      if (data) {
        navigate(`/models/time-series/${project.id}/user-selection`);
        queryClient.removeQueries('model production');
        queryClient.removeQueries('project overview');
        dispatch(changeIsFinish(false));
        dispatch(resetUserSelectionOptions());
      }
    } catch (err) {
      const error: AxiosError<DataError> | any = err;
      const errorMessage =
        error?.response?.data?.detail?.detail ??
        translate('sendToProdFailMessage');

      setFailedModalInfo({
        title: translate('requestFailed'),
        description: errorMessage,
      });
      setFailedModalVisible(true);
    }

    setSendToProdLoading(false);
    setSendToProdDisabled(false);
  }

  return (
    <Container>
      <Head
        title={`${translate('crossValidationTitle')} | ${translate(
          'modelExplorerText',
        )}`}
      />

      <Card
        textCard={`${translate('forecastCombinationText')} - ${translate(
          'crossValidationTitle',
        )}`}
        textDescription={translate('crossValidationDescription')}
        className="containerLinear"
      />

      <section>
        <ModelList className="containerLinear">
          <Card textCard={translate('selectModelTitle')} />
          {project.projectError ? (
            <Select
              label={translate('selectModelLabel')}
              placeholder={translate('selectModelPlaceHolder')}
              options={[{ label: 1, value: '1' }]}
              isDisabled
            />
          ) : project.model?.model_list ? (
            <Select
              label={translate('selectModelLabel')}
              placeholder={translate('selectModelPlaceHolder')}
              options={Array.from(
                {
                  length:
                    project.model?.model_list.find(
                      (model) => model.type === 'Forecast Combination',
                    )?.quantity ?? 1,
                },
                (_, number) => ({
                  value: number + 1,
                  label: number + 1,
                }),
              )}
              value={{ label: modelId, value: modelId }}
              onChange={(option: any) => {
                setModelId(option.value);
              }}
            />
          ) : (
            <ContainerSkeleton
              style={{ height: '126px' }}
              data-testid="model-list-loading"
            />
          )}
        </ModelList>

        <ContainerSendProduction className="containerLinear">
          <Card
            textCard={translate('sendModelToProdTitle')}
            textDescription={translate('sendModelToProdDescr')}
          />
          <ContentButtonSendProduction>
            <SendButton
              buttonType="primary"
              onClick={handleSendToProduction}
              disabled={sendToProdDisabled}
              loading={sendToProdLoading}
              data-testid="modelinproduction-button"
              data-cy="button-send-to-production"
            >
              {translate('sendToProductionButtonText')}
            </SendButton>
          </ContentButtonSendProduction>
        </ContainerSendProduction>
        <div className="containerLinear">
          <Tooltip
            id="forecast-combination-model-info-tooltip"
            className="customTooltipTheme"
          />

          <Card textCard={translate('modelInfoTitle')} />
          {isErrorModelInfo || project.projectError ? (
            <ModelInfoListInformation data-testid="container-model-info-error">
              <div>
                {/* <div>
                  <h4>Row ID</h4>
                  <p>--</p>
                </div> */}
                <div>
                  <h4>{translate('modelInfoForecastHorizonTitle')}</h4>
                  <p> -- </p>
                </div>
                <div>
                  <h4>{translate('modelInfoTestWindowsTitle')}</h4>
                  <p> -- </p>
                </div>
              </div>
              <div>
                <div
                  data-tooltip-id="forecast-combination-model-info-tooltip"
                  data-tooltip-html={translate('MAPEExplanationTooltip')}
                >
                  <h4>MAPE</h4>
                  <p> -- </p>
                </div>
                <div
                  data-tooltip-id="forecast-combination-model-info-tooltip"
                  data-tooltip-html={translate('WMAPEExplanationTooltip')}
                >
                  <h4>WMAPE</h4>
                  <p> -- </p>
                </div>
                <div
                  data-tooltip-id="forecast-combination-model-info-tooltip"
                  data-tooltip-html={translate('MPEExplanationTooltip')}
                >
                  <h4>MPE</h4>
                  <p> -- </p>
                </div>
              </div>
              <div>
                <div
                  data-tooltip-id="forecast-combination-model-info-tooltip"
                  data-tooltip-html={translate('RMSEExplanationTooltip')}
                >
                  <h4>RMSE</h4>
                  <p> -- </p>
                </div>
                <div
                  data-tooltip-id="forecast-combination-model-info-tooltip"
                  data-tooltip-html={translate('MASEExplanationTooltip')}
                >
                  <h4>MASE</h4>
                  <p> -- </p>
                </div>
                <div
                  data-tooltip-id="forecast-combination-model-info-tooltip"
                  data-tooltip-html={translate('MASEsExplanationTooltip')}
                >
                  <h4>MASEs</h4>
                  <p> -- </p>
                </div>
              </div>
            </ModelInfoListInformation>
          ) : isLoadingModelInfo || isFetchingModelInfo || !dataModelInfo ? (
            <ContainerSkeleton
              style={{ height: '154px' }}
              data-testid="container-model-info-loading"
            />
          ) : (
            <ModelInfoListInformation data-testid="container-model-info">
              <div>
                {/* <div>
                  <h4>Row ID</h4>
                  <p
                    data-cy="model-info-row-id"
                    data-testid="model-info-row-id"
                  >
                    {dataModelInfo.row_id ?? '--'}
                  </p>
                </div> */}
                <div>
                  <h4>{translate('modelInfoForecastHorizonTitle')}</h4>
                  <p
                    data-cy="model-info-forescast-horizon"
                    data-testid="model-info-forescast-horizon"
                  >
                    {dataModelInfo.n_steps}
                  </p>
                </div>
                <div>
                  <h4>{translate('modelInfoTestWindowsTitle')}</h4>
                  <p
                    data-cy="model-info-test-windows"
                    data-testid="model-info-test-windows"
                  >
                    {dataModelInfo.n_windows}
                  </p>
                </div>
              </div>
              <div>
                <div
                  data-tooltip-id="forecast-combination-model-info-tooltip"
                  data-tooltip-html={translate('MAPEExplanationTooltip')}
                >
                  <h4>MAPE</h4>
                  <p data-cy="model-info-mape" data-testid="model-info-mape">
                    {dataModelInfo?.MAPE
                      ? `${dataModelInfo?.MAPE.toFixed(2)}%`
                      : '--'}
                  </p>
                </div>
                <div
                  data-tooltip-id="forecast-combination-model-info-tooltip"
                  data-tooltip-html={translate('WMAPEExplanationTooltip')}
                >
                  <h4>WMAPE</h4>
                  <p data-cy="model-info-wmape" data-testid="model-info-wmape">
                    {dataModelInfo?.WMAPE
                      ? `${dataModelInfo?.WMAPE.toFixed(2)}%`
                      : '--'}
                  </p>
                </div>
                <div
                  data-tooltip-id="forecast-combination-model-info-tooltip"
                  data-tooltip-html={translate('MPEExplanationTooltip')}
                >
                  <h4>MPE</h4>
                  <p data-cy="model-info-mpe" data-testid="model-info-mpe">
                    {dataModelInfo?.MPE
                      ? `${dataModelInfo?.MPE.toFixed(2)}%`
                      : '--'}
                  </p>
                </div>
              </div>
              <div>
                <div
                  data-tooltip-id="forecast-combination-model-info-tooltip"
                  data-tooltip-html={translate('RMSEExplanationTooltip')}
                >
                  <h4>RMSE</h4>
                  <p data-cy="model-info-rmse" data-testid="model-info-rmse">
                    {dataModelInfo?.RMSE ? dataModelInfo.RMSE.toFixed(2) : '--'}
                  </p>
                </div>
                <div
                  data-tooltip-id="forecast-combination-model-info-tooltip"
                  data-tooltip-html={translate('MASEExplanationTooltip')}
                >
                  <h4>MASE</h4>
                  <p data-cy="model-info-mase" data-testid="model-info-mase">
                    {dataModelInfo?.MASE ? dataModelInfo.MASE.toFixed(2) : '--'}
                  </p>
                </div>
                <div
                  data-tooltip-id="forecast-combination-model-info-tooltip"
                  data-tooltip-html={translate('MASEsExplanationTooltip')}
                >
                  <h4>MASEs</h4>
                  <p data-cy="model-info-mases" data-testid="model-info-mases">
                    {dataModelInfo?.MASEs
                      ? dataModelInfo.MASEs.toFixed(2)
                      : '--'}
                  </p>
                </div>
              </div>
            </ModelInfoListInformation>
          )}
        </div>
      </section>

      <CrossValidationWindows
        type="forecast-combination"
        modelId={modelId}
        nWindows={dataModelInfo?.n_windows}
        isLoadingModelInfo={
          isLoadingModelInfo || isFetchingModelInfo || !dataModelInfo
        }
        isErrorModelInfo={isErrorModelInfo}
      />

      <PercentageErrorMetrics
        type="forecast-combination"
        modelId={modelId}
        isLoadingModelInfo={
          isLoadingModelInfo || isFetchingModelInfo || !dataModelInfo
        }
        isErrorModelInfo={isErrorModelInfo}
        isMAPEDisabled={!dataModelInfo?.MAPE ?? true}
        isWMAPEDisabled={!dataModelInfo?.WMAPE ?? true}
        isMPEDisabled={!dataModelInfo?.MPE ?? true}
      />

      <ScaledErrorMetrics
        type="forecast-combination"
        modelId={modelId}
        isLoadingModelInfo={
          isLoadingModelInfo || isFetchingModelInfo || !dataModelInfo
        }
        isErrorModelInfo={isErrorModelInfo}
        isMASEDisabled={!dataModelInfo?.MASE ?? true}
        isMASEsDisabled={!dataModelInfo?.MASEs ?? true}
      />

      <LevelErrorMetric
        type="forecast-combination"
        modelId={modelId}
        isLoadingModelInfo={
          isLoadingModelInfo || isFetchingModelInfo || !dataModelInfo
        }
        isErrorModelInfo={isErrorModelInfo}
        isRMSEDisabled={!dataModelInfo?.RMSE ?? true}
      />

      <FailedModal
        errorInfo={failedModalInfo}
        visible={failedModalVisible}
        setVisible={setFailedModalVisible}
      />
    </Container>
  );
};
