import styled, { css, keyframes } from 'styled-components';

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-320px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

const disappearToLeft = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to{
    
    opacity: 0;
    transform: translateX(-320px);
  }
`;

interface ContainerProps {
  visible: boolean;
  loading: boolean;
}

export const Container = styled.aside<ContainerProps>`
  position: fixed;
  left: 0;
  height: 100vh;

  width: 100%;
  min-width: ${({ visible }) => (visible ? '22rem' : '0px')};
  max-width: ${({ visible }) => (visible ? '22rem' : '0px')};
  transition: min-width 1s;
  margin-bottom: -1.5rem;

  background: ${({ theme }) => theme.colors.white};

  border-right: ${({ theme }) => `1px solid ${theme.colors.gray2}`};

  z-index: 1200;

  animation: ${({ visible }) => (visible ? appearFromLeft : disappearToLeft)} 1s;

  cursor: ${({ loading }) => (loading ? 'progress' : 'auto')};

  button {
    cursor: ${({ loading }) => (loading ? 'progress' : 'pointer')};
  }

  > div {
    display: flex;
    flex-direction: column;
    position: sticky;
    height: 100%;
    top: 4.625rem; // 74header
    overflow-y: scroll;

    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }

  ::-webkit-scrollbar {
    width: 0.313rem;
    height: 0.313rem;
    padding: 0.063rem;
  }

  ::-webkit-scrollbar-track {
    transition: 0.2s;
    margin: 0.188rem;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }
`;

interface OverlayProps {
  visible: boolean;
}

export const Overlay = styled.div<OverlayProps>`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;

  background: rgba(226, 232, 240, 0.08);
  backdrop-filter: ${({ visible }) => (visible ? 'blur(4px)' : 'none')};
  transition: backdrop-filter 1s ease-in;
  z-index: 1000;
`;

export const ContentTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;

  padding: 2rem 1.5rem 0;

  h3 {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 2rem;
    color: ${({ theme }) => theme.colors.gray7};
  }

  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray5};
  }
`;

export const UpdateHistoryList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 0 0 0 !important;

  padding: 0 1.5rem;

  gap: 2rem;
  width: 100%;

  overflow-y: auto;
`;

interface VersionProps {
  selected: boolean;
  disabled?: boolean;
}

export const Version = styled.div<VersionProps>`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0.5rem;

  display: flex;

  align-items: flex-start;
  align-self: stretch;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
    `};

  > div:first-child {
    display: flex;
    flex-direction: column;

    h4 {
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.688rem;
      color: ${({ theme, selected }) =>
        selected ? theme.colors.primary : theme.colors.gray5};
    }

    p {
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.688rem;
      color: ${({ theme }) => theme.colors.gray3};
    }
  }

  > div:last-child {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.125rem;
      height: 1.125rem;
      transition: 0.8s;

      svg {
        color: ${({ theme }) => theme.colors.gray5};
      }

      :disabled {
        cursor: not-allowed;
        svg {
          color: ${({ theme }) => theme.colors.gray4}!important;
        }
      }

      :hover {
        svg {
          color: ${({ theme }) => theme.colors.primary};
        }
      }
    }
  }
`;

export const CloseSidebarButton = styled.button`
  z-index: 2001;

  width: 2.25rem;
  height: 2.25rem;
  display: flex;
  padding: 0.5rem;
  align-items: flex-start;
  gap: 0.625rem;

  position: absolute;
  top: 2.5rem;
  right: -1.1rem;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 50%;

  svg {
    color: ${({ theme }) => theme.colors.gray4};
  }
`;
