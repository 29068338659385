import styled from 'styled-components';

interface AccuracyContentProps {
  disabled?: boolean;
}

export const AccuracyContent = styled.div<AccuracyContentProps>`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    gap: 0.5rem;

    min-width: 10rem;
    width: calc((100% - 4rem) / 5);
    height: 6.875rem;

    border: 1px solid ${({ theme }) => theme.colors.gray2};
    background: ${({ theme }) => theme.colors.white};
    border-radius: 8px;

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.375rem;
      text-align: center;
      color: ${({ theme }) => theme.colors.gray5};
    }

    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 2rem;
      line-height: 2.5rem;

      text-align: center;
      color: ${({ theme, disabled }) =>
        !disabled ? theme.colors.secondary : theme.colors.gray4};
    }
  }
`;
