import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  img {
    width: 11rem;

    margin-bottom: 0.5rem;
  }
`;

export const YsContainer = styled.div`
  width: 100%;
  max-height: 5.5rem;

  text-align: start;

  overflow-y: auto;

  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 8px;

  padding: 1rem;
  margin-top: 1.5rem;

  p {
    font-size: 0.875rem;

    color: ${({ theme }) => theme.colors.gray5};
    line-height: 1.25rem;

    word-wrap: break-word;
  }

  ::-webkit-scrollbar {
    width: 0.313rem;
    height: 0.313rem;
    padding: 0.063rem;
  }
`;
