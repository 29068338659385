import React from 'react';

import { XCircle } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';
import { ModalFooter } from 'src/components/Modal/Footer/styles';

import { Container } from './styles';
import { ErrorModalProps } from './types';

export const ErrorModal: React.FC<ErrorModalProps> = ({
  setVisible,
  title,
  description,
}) => {
  const { t: translate } = useTranslation();

  return (
    <Modal visible setVisible={setVisible} style={{ width: '33rem' }}>
      <Container data-testid="error-modal">
        <XCircle size="3rem" />
        <h2 data-testid="error-title">{title}</h2>
        <p data-testid="error-description">{description}</p>
      </Container>

      <ModalFooter>
        <Button
          buttonType="primary"
          onClick={setVisible}
          data-testid="back-button"
        >
          {translate('back')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
