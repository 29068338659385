import styled from 'styled-components';

export const Container = styled.div``;

export const FiltersContainer = styled.div`
  > div + div {
    margin-top: 3rem;

    border-top: 1px solid ${({ theme }) => theme.colors.gray2};
  }

  > div:not(:first-of-type) {
    padding-top: 3rem;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  gap: 1rem;

  margin-top: 2rem;
`;
