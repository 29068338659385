import styled, { css } from 'styled-components';

export const UnderstandingResultContainer = styled.div`
  position: relative;
  > h2 {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.5rem;
    color: ${({ theme }) => theme.colors.gray6};

    margin-bottom: 0.5rem;
  }
`;

export const UnderstandingResultContent = styled.div`
  transition: filter 0.5s linear;

  > p {
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray5};

    margin-bottom: 1.5rem;
  }
`;

interface UnderstandingResultExpandProps {
  seeMoreImpacts: boolean;
  isCardSelected: boolean;
}

export const UnderstandingResultExpand = styled.div<UnderstandingResultExpandProps>`
  max-height: ${({ seeMoreImpacts, isCardSelected }) =>
    seeMoreImpacts ? (isCardSelected ? `43.75rem` : `75rem`) : '0px'};
  transition: max-height 0.5s linear;
  overflow: hidden;
`;

interface ButtonSeeMoreImpactsProps {
  seeMoreImpacts: boolean;
}

export const ButtonSeeMoreImpacts = styled.button<ButtonSeeMoreImpactsProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  color: ${({ theme }) => theme.colors.primary};

  transition: color 0.2s ease-in-out, margin-top 0.5s ease-in;

  margin-top: ${({ seeMoreImpacts }) => (seeMoreImpacts ? '24px' : '0px')};

  &:hover {
    color: ${({ theme }) => theme.colors.primaryDark};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.gray3};
  }

  span {
    font-weight: 600;
    font-size: 1rem;
  }

  svg {
    transition: transform 0.5s ease-in;
    transform: ${({ seeMoreImpacts }) =>
      seeMoreImpacts ? 'scaleY(-1)' : 'scaleY(1)'};
  }
`;

export const UnderstandingResultContentSkeletons = styled.div`
  > div {
    width: 100%;
    height: 1.5rem;

    margin-bottom: 0.25rem;
    &:last-child {
      width: 33%;
      margin-bottom: 1.5rem;
    }
  }
`;

export const ContentSelectDate = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  width: 9.375rem;

  .select__menu-list {
    ::-webkit-scrollbar {
      width: 6px !important ;
      height: 6px !important;
      padding: 1px;
    }

    ::-webkit-scrollbar-track {
      transition: 0.2s;
      margin: 3px;
    }

    ::-webkit-scrollbar-track {
      margin-top: 3px;
      margin-bottom: 3px;
      background: #ffcee9;
      transition: 0.2s;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: ${({ theme }) => theme.colors.secondaryLight};
    }
    ::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.colors.secondary};
    }
  }

  //React-Select
  .select__indicator-separator {
    // sepadador lado esquerdo da arrow bottom |
    display: none;
  }

  .select__placeholder {
    color: ${({ theme }) => theme.colors.secondaryLight} !important;
  }

  .css-2b097c-container {
    // Container do select
    width: 100%;
  }

  .select__control {
    ///container do select
    background: ${({ theme }) => theme.colors.secondaryLight}1F;
    width: 100%;
    min-height: 1.625rem;
    border-radius: 8px;
    border: none !important;
  }

  .select__control--is-disabled {
    background: ${({ theme }) => theme.colors.gray1};
  }

  .select__control:hover {
    //container select selecionado
    border: none !important;
  }

  .select__control--is-focused {
    box-shadow: none;
    border: none;
    border-color: ${({ theme }) => theme.colors.primary};
  }

  .select__single-value {
    color: ${({ theme }) => theme.colors.secondary};
    font-weight: 600;
  }

  .select__single-value--is-disabled {
    color: ${({ theme }) => theme.colors.gray4};
  }

  .select__value-container {
    //container contendo o input de pesquisa
    cursor: text !important;
    color: ${({ theme }) => theme.colors.secondary} !important;
    font-weight: 400 !important;
    font-size: 0.875rem !important;
    padding: 0.125rem 0.5rem !important;
    .select__input {
      color: ${({ theme }) => theme.colors.secondary};
      padding: 0;
      margin: 0;
    }
  }

  .select__menu {
    ///options
    background: #fff5fa;
    z-index: 5;
    font-weight: 400 !important;
    font-size: 0.875rem !important;
    color: ${({ theme }) => theme.colors.secondary} !important;
    box-shadow: 0 0 0 1px #ffdee9;

    .select__option {
      /// div contendo o texto
      color: ${({ theme }) => theme.colors.secondary};
      padding: 0.5rem;
      background: transparent;

      &:not(.select__option--is-disabled) {
        cursor: pointer !important;
      }
    }
    .select__option:hover {
      background: #ffdee9;
    }
  }

  .select__menu-notice {
    color: ${({ theme }) => theme.colors.secondary} !important;
  }

  .select__indicator {
    //Arrow bottom do select quando nao esta com as options aberta
    cursor: pointer;
    height: 1.625rem;
    padding: 0.125rem 0.5rem;
    > svg {
      stroke-width: 0px;
      color: ${({ theme }) => theme.colors.secondary};
    }

    .select__control--is-focused {
      .select__indicator {
        //Arrow bottom do select quando esta com as options aberta
        > svg {
          transform: rotate(180deg) !important;
        }
      }
    }
  }

  .select--is-disabled {
    .select__placeholder {
      color: ${({ theme }) => theme.colors.gray4} !important;
    }

    cursor: not-allowed;
    .select__indicator {
      //Arrow bottom do select quando nao esta com as options aberta
      cursor: pointer;
      height: 1.625rem;
      padding: 0.125rem 0.5rem;
      > svg {
        stroke-width: 0px;
        color: ${({ theme }) => theme.colors.gray4};
      }
    }
  }
`;

export const UnderstandingResultError = styled.div`
  p {
    font-size: 1rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gray5};
    line-height: 150%;
    text-align: justify;
  }
`;

export const ChooseViewType = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;

  margin-bottom: 1.5rem;
`;

interface ChooseViewTypeButtonProps {
  selected: boolean;
}

export const ChooseViewTypeButton = styled.button<ChooseViewTypeButtonProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  color: ${({ theme, selected }) =>
    selected ? theme.colors.primary : theme.colors.gray4};
  font-size: 1.125rem;
  font-weight: 500;

  background: ${({ theme, selected }) =>
    selected ? `${theme.colors.primary}14` : 'transparent'};

  padding: 0.75rem 1rem;

  border: 1px solid
    ${({ theme, selected }) =>
      selected ? 'rgb(241,247,255)' : theme.colors.gray2};
  border-radius: 5px;

  transition: all 0.2s ease-in-out;

  ${({ selected }) =>
    !selected &&
    css`
      &:not(:disabled):hover {
        border-color: ${({ theme }) => theme.colors.gray3};
        color: ${({ theme }) => theme.colors.gray5};
      }

      &:disabled {
        border-color: ${({ theme }) => theme.colors.gray2};
        color: ${({ theme }) => theme.colors.gray3};
        cursor: not-allowed;
      }
    `}
`;
