import styled from 'styled-components';

export const ContainerDependentVariable = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  margin-bottom: 2rem;

  .css-165luzk-option {
    cursor: not-allowed;
  }

  .filter-name-error {
    label {
      color: ${({ theme }) => theme.colors.red2} !important;
    }
  }

  .select__menu {
    z-index: 100000000;
  }

  > div:nth-child(2) {
    width: 16.938rem;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.38rem;

  width: 16.938rem;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  h2 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.6875rem;

    color: ${({ theme }) => theme.colors.gray6};
  }

  button,
  svg {
    width: 1.125rem;
    height: 1.125rem;

    color: ${({ theme }) => theme.colors.gray5};

    transition: color 0.2s;

    :hover {
      color: ${({ theme }) => theme.colors.gray8};
    }
  }
`;

export const Tag = styled.span`
  width: fit-content;

  padding: 0rem 0.5rem;

  border-radius: 0.25rem;
  background: ${({ theme }) => `${theme.colors.primary}1F`};

  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.375rem;

  color: ${({ theme }) => theme.colors.primary};
`;

export const ContentFeatureStoreVariable = styled.div`
  width: 16.938rem;

  button {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    color: ${({ theme }) => theme.colors.gray5};

    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.375rem;

    transition: color 0.2s ease-out;

    &:not(:disabled):hover {
      color: ${({ theme }) => theme.colors.gray6};
    }

    &:disabled {
      color: ${({ theme }) => theme.colors.gray4};
      cursor: not-allowed;
    }
  }
`;
