import React, { useEffect } from 'react';

import { HeadProps } from './types';

export const Head: React.FunctionComponent<HeadProps> = ({
  title,
  description,
}) => {
  useEffect(() => {
    const metaDescription = document.querySelector('meta[name=description');

    document.title = title;
    metaDescription?.setAttribute('content', description || '');
  }, [title, description]);

  return <></>;
};
