import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > section {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 1.5rem;
  }
`;

export const ContainerModelSummary = styled.div`
  max-width: 20rem;
  flex: 1 1 20rem;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;

  @media (min-width: 1142px) {
    > div {
      flex: 1 1 18.75rem;
    }

    /* max-width: 1087px; */

    .containerLinear {
      width: 100%;
      /* max-width: 33.125rem; */
      min-width: 33.125rem;
      margin-bottom: 0 !important;
    }
  }

  h5 {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: ${({ theme }) => theme.colors.gray7};
    opacity: 0.8;
    margin-bottom: 0.5rem;
  }

  p {
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: ${({ theme }) => theme.colors.gray6};
    margin-bottom: 1rem;
  }

  table {
    margin-bottom: 1.5rem;

    th {
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.125rem;
      color: ${({ theme }) => theme.colors.gray7};
      padding-right: 1rem;
      padding-bottom: 0.5rem;
      opacity: 0.8;
    }

    td {
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 1.125rem;
      color: ${({ theme }) => theme.colors.gray6};
      margin-right: 1rem !important;
    }
  }
`;

export const PublishedData = styled.p`
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: right;
  color: ${({ theme }) => theme.colors.gray8} !important;
  opacity: 0.8;
  margin-bottom: 0.5rem !important;
`;

export const ButtonPosition = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;
