const groupsPTBRTranslate = {
  groupsMyFavoritesTitle: 'Meus favoritos',
  groupsMyFavoritesDescription:
    'Visualize as suas séries favoritas pelos grupos criados por você',
  groups4iFavoritesTitle: 'Favoritos 4i',
  groups4iFavoritesDescription:
    'Visualize as séries dos grupos feitos e atualizado pela 4intelligence',
  groupsSearchIndicatorsTitleText: 'Pesquisar indicadores',
  groupsSearchIndicatorsDescriptionText:
    'Navegue em nosso banco de dados, explore milhares de variáveis ​​e crie seus grupos de séries favoritas.',
  groupsSearchIndicatorsButtonText: 'Procurar',
  groupsSeriesGroupsTitleText: 'Séries favoritas',
  groupsSeriesGroupsDescriptionText:
    'Veja suas séries favoritas ou acesse os grupos sugeridos pela 4intelligence.',
  groupsSeriesGroupsButtonPlaceholder: 'Pesquisar favorito',
  groupsSeriesGroupsButtonText: 'Criar novo grupo',
  groupsSeriesGroupsPreDefinedText: 'Favoritos 4i',
  groupsSeriesGroupsMyGroupsText: 'Meus favoritos',
  groupSeriesEditRequestFailedTitle: 'Algo deu errado',
  groupSeriesEditRequestFailedDescription:
    'Infelizmente não foi possível editar seu grupo. Por favor, tente novamente. Se o problema persistir, entre em contato com o suporte.',
  groupSeriesCreateRequestFailedTitle: 'Algo deu errado',
  groupSeriesCreateRequestFailedDescription:
    'Infelizmente não foi possível criar seu grupo. Por favor, tente novamente. Se o problema persistir, entre em contato com o suporte.',
  groupSeriesErrorMessageInput3Caracteres:
    'Pesquisa deve ter pelo menos 3 caracteres.',
  groupSeriesErrorMessageInputMax30Caracteres:
    'A pesquisa deve ter no máximo 30 caracteres.',
  groupSeriesErrorRequestLoadMyGroups:
    'Não foi possível carregar seus grupos favoritos.',
  groupSeriesErrorRequestLoadPreDefinedGroups:
    'Não foi possível carregar grupos 4i favoritos.',
  groupSeriesErrorSearchGroupFail:
    'Não foi possível encontrar nenhum grupo contendo',
  groupSeriesErrorNoGroupFound: 'Nenhum grupo encontrado, comece criando um.',
  groupsSeriesPaginationGroupsText: 'grupos',
  groupPremiumTitle: 'Grupos Premium',
  groupPremiumDescription: 'Indicadores de grupos premium',
  groupPremiumError: 'Não foi encontrado nenhum indicador para este grupo.',
  createdGroupByMe: 'Criados por mim',
};

export default groupsPTBRTranslate;
