import styled from 'styled-components';

export const Content = styled.div``;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 1rem;

  max-height: 70vh;

  overflow-y: auto;
`;

export const Item = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 0.5rem;

  svg {
    width: 1rem;
    height: 1rem;

    color: ${({ theme }) => theme.colors.gray3};
  }

  p {
    flex: 1;

    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gray6};
  }
`;
