import styled from 'styled-components';

export const ContentTitle = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 1.5rem;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.gray4};
    transition: 0.2s color;

    &:hover {
      color: ${({ theme }) => theme.colors.gray5};
    }
  }
`;

export const Container = styled.div`
  .select {
    width: 20rem;
    margin-bottom: 1.5rem;

    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }

  h5 {
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    color: ${({ theme }) => theme.colors.gray6};
  }
`;

export const ContentChart = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    flex: 1 1 25rem;
  }

  h1 {
    font-size: 1.25rem;
    padding: 1.25rem;
  }
`;

export const DivSelect = styled.div`
  display: flex;
  max-width: 65%;
  justify-content: space-between;
`;
