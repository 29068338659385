export const getIndicatorUrlIcon = (typeIcon: string): string => {
  const iconsTypeIndicator = [
    {
      type: 'controls',
      url: 'https://storage.googleapis.com/bkt-prod-4casthub/icons/controls.svg',
    },
    {
      type: 'economic activity',
      url: 'https://storage.googleapis.com/bkt-prod-4casthub/icons/atividade-economica.svg',
    },
    {
      type: 'credit',
      url: 'https://storage.googleapis.com/bkt-prod-4casthub/icons/credito.svg',
    },
    {
      type: 'climatic data',
      url: 'https://storage.googleapis.com/bkt-prod-4casthub/icons/climatico.svg',
    },
    {
      type: 'public finance',
      url: 'https://storage.googleapis.com/bkt-prod-4casthub/icons/financas.svg',
    },
    {
      type: 'capital and financial markets',
      url: 'https://storage.googleapis.com/bkt-prod-4casthub/icons/public-finance.svg',
    },
    {
      type: 'external sector',
      url: 'https://storage.googleapis.com/bkt-prod-4casthub/icons/external-sector.svg',
    },
    {
      type: 'environmental data',
      url: 'https://storage.googleapis.com/bkt-prod-4casthub/icons/ambiental.svg',
    },
  ];

  const fallbackIcon = {
    url: 'https://storage.googleapis.com/bkt-prod-4casthub/icons/2f9f5ebb-0ac6-461d-b816-49e33149b5b8.png',
  };

  const iconUrl = iconsTypeIndicator.find(
    (icon) => icon.type === typeIcon?.trim()?.toLowerCase(),
  );

  return iconUrl?.url ?? fallbackIcon?.url;
};
