import styled from 'styled-components';

interface ContainerModalProps {
  hasImage: boolean;
}

export const ContainerModal = styled.div<ContainerModalProps>`
  width: 30rem;
  padding: 2rem 2rem 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  > svg {
    color: ${({ theme }) => theme.colors.yellow4};
    margin-bottom: 1.5rem;
  }

  > p {
    color: ${({ theme }) => theme.colors.gray5};
    font-weight: 500;
    font-size: 1rem;
    line-height: 150%;
    margin-bottom: 1.5rem;
  }
  > span {
    color: ${({ theme }) => theme.colors.gray5};
    font-weight: 500;
    font-size: 1rem;
    line-height: 150%;

    margin-bottom: 3rem;
  }
`;
