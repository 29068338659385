import React, { useEffect, useState } from 'react';

import { Trash } from 'phosphor-react';
import apiSeries from 'src/models/service/apiSeries';
import { useQuerySeriesById } from 'src/models/hooks/useQuerySeriesById';
import { Spinner } from 'src/components/Spinner';
import { queryClient } from 'src/service/queryClient';
import { createPortal } from 'react-dom';
import { FailedModal } from 'src/components/Modal/Failed';
import { useTranslation } from 'react-i18next';
import { Modal } from 'src/components/Modal';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import { Button } from 'src/components/Button';

import { DeleteButton, DeleteSerieContainer, ModalContent } from './styles';

type DeleteSerieProps = {
  serieName: string;
  serieId: string;
  removeSerie: (serieId: string) => void;
};

export const DeleteSerie: React.FC<DeleteSerieProps> = ({
  serieName,
  serieId,
  removeSerie,
}) => {
  const [deletionRequested, setDeletionRequested] = useState(false);
  const [failedModalVisible, setFailedModalVisible] = useState(false);

  const [showModalToConfirmDeletion, setShowModalToConfirmDeletion] =
    useState(false);

  const { t } = useTranslation();

  const { serieData, serieIsError, serieIsLoading } = useQuerySeriesById({
    id: serieId,
    enabled: deletionRequested,
    retryThreeSeconds: true,
  });

  useEffect(() => {
    if (deletionRequested) {
      if (serieData?.status === 'removed') {
        setDeletionRequested(false);
        removeSerie(serieId);
        queryClient.refetchQueries('series');
        queryClient.refetchQueries('serie-tags');
        queryClient.refetchQueries('series-grouped-by-tag');
      } else if (serieIsError) {
        setFailedModalVisible(true);
        setDeletionRequested(false);
      }
    }
  }, [deletionRequested, serieData, serieIsError, removeSerie, serieId]);

  async function handleDelete() {
    setShowModalToConfirmDeletion(false);
    setDeletionRequested(true);
    try {
      await apiSeries.delete(`/series/${serieId}`);

      queryClient.removeQueries(['workspace staging area']);
    } catch {
      setFailedModalVisible(true);
      setDeletionRequested(false);
    }
  }

  function closeFailedModal() {
    setFailedModalVisible(false);
  }

  const isLoading =
    deletionRequested && (serieData?.status !== 'removed' || serieIsLoading);

  return (
    <DeleteSerieContainer>
      {!isLoading ? (
        <DeleteButton
          onClick={() => setShowModalToConfirmDeletion(true)}
          data-testid={`cell-${serieName}-delete`}
          data-cy={`cell-${serieName}-delete`}
        >
          <Trash size={20} />
        </DeleteButton>
      ) : (
        <Spinner
          spinnerType="primary"
          data-testid={`cell-${serieName}-delete-loading`}
          data-cy={`cell-${serieName}-delete-loading`}
        />
      )}

      {failedModalVisible &&
        createPortal(
          <FailedModal
            errorInfo={{
              title: t('mySeriesDeleteSerieErrorTitle'),
              description: t('mySeriesDeleteSerieErrorDescription', {
                serie: serieName,
              }),
            }}
            setVisible={closeFailedModal}
            visible
          />,
          document.body,
        )}

      {showModalToConfirmDeletion &&
        createPortal(
          <Modal
            visible
            dataCy="modal-deletion-confirmation"
            style={{ width: '30rem' }}
          >
            <ModalContent>
              <h2>{t('mySeriesConfirmDeletionTitle')}</h2>
              <p>{t('mySeriesConfirmDeletionDescription')}</p>
            </ModalContent>

            <ModalFooter>
              <Button
                buttonType="naked"
                onClick={() => setShowModalToConfirmDeletion(false)}
                disabled={isLoading}
                data-testid="button-cancel"
              >
                {t('cancel')}
              </Button>

              <Button
                buttonType="primary"
                onClick={handleDelete}
                data-testid="button-confirm"
              >
                {t('yes')}
              </Button>
            </ModalFooter>
          </Modal>,
          document.body,
        )}
    </DeleteSerieContainer>
  );
};
