import React, { useState } from 'react';

import { ArrowLeft, Eye, Trash, UserCirclePlus } from 'phosphor-react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { Card } from 'src/components/Card';
import { CheckBox } from 'src/components/CheckBox';
import { Pagination } from 'src/components/Pagination';
import { Table, Tbody, Td, Th, Thead, Tr } from 'src/components/Table';
import { useQuery } from 'react-query';
import api from 'src/feature-store/service/api';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { useTranslation } from 'react-i18next';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { ContainerErrorChart } from 'src/feature-store/pages/VisualizeFeatures/styles';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { queryClient } from 'src/service/queryClient';
import light from 'src/styles/themes/light';

import { InsertIndicatorGroupPremium } from '../InsertIndicatorGroupPremium';
import {
  CardFeatures,
  Container,
  ContainerTable,
  ContentButtonAddIndicator,
  DivDeletePagination,
  TrComplete,
} from './styles';
import { ModalDeleteIndicator } from './ModalDeleteIndicator';

type ParamsProps = {
  id: string;
};

interface DataIndicatorsFromGroupProps {
  indicator_code: string;
  name: {
    'en-us': string;
    'pt-br': string;
  };
  access_type: string;
  description: {
    'en-us': string;
    'pt-br': string;
  };
  source: {
    'en-us': string;
    'pt-br': string;
  };
}

interface IndicatorsFromGroupProps {
  limit: number;
  skip: number;
  total: number;
  data: DataIndicatorsFromGroupProps[];
}

interface DataIndicatorsPremium {
  id: string;
  access_type: string;
  name: {
    'pt-br': string;
    'en-us': string;
  };
  description: {
    'pt-br': string;
    'en-us': string;
  };
}

interface IndicatorSelectedProps {
  id: string;
}

const QUANTITY_ITEMS_PAGE = 10;

export const ListIndicatorsFromGroupPremium: React.FC = () => {
  const [pages, setPages] = useState<number>(1);
  const [insertUserModal, setInsertUserModal] = useState<boolean>();
  const [modalDeleteIndicator, setModalDeleteIndicator] = useState<boolean>();
  const [indicatorSelected, setIndicatorSelected] = useState<
    IndicatorSelectedProps[]
  >([]);
  const { id } = useParams<ParamsProps>();
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const { language } = useSelector((state: RootState) => state.auth.user);

  const {
    data: indicatorsFromGroup,
    isLoading,
    isFetching,
    isError,
  } = useQuery(
    ['indicatorsFromGroup', pages, id],
    async () => {
      const { data } = await api.get<IndicatorsFromGroupProps>(
        `/access-groups/${id}/indicators?skip=${
          (pages - 1) * QUANTITY_ITEMS_PAGE
        }&limit=${QUANTITY_ITEMS_PAGE}`,
      );
      return data;
    },
    {
      staleTime: 1000 * 60 * 20,
    },
  );

  const { data: groupsPremiumInfo, isLoading: isLoadingGroupPremium } =
    useQuery(
      ['groupsPremiumInfo', pages, id],
      async () => {
        const { data } = await api.get<DataIndicatorsPremium>(
          `/access-groups/${id}?skip=${
            (pages - 1) * QUANTITY_ITEMS_PAGE
          }&limit=${QUANTITY_ITEMS_PAGE}`,
        );

        return data;
      },
      {
        staleTime: 1000 * 60 * 20,
      },
    );

  const handleSelectIndicator = (indicatorCode: string) => {
    if (indicatorSelected.some((indicator) => indicator.id === indicatorCode)) {
      setIndicatorSelected(
        indicatorSelected.filter((selected) => selected.id !== indicatorCode),
      );
    } else {
      setIndicatorSelected([
        ...indicatorSelected,
        {
          id: indicatorCode,
        },
      ]);
    }
  };

  return (
    <Container data-testid="container-list-indicators-from-group">
      {isError ? (
        <>
          <div className="containerLinear">
            <CardFeatures>
              <ArrowLeft
                size="2rem"
                onClick={() => {
                  navigate(-1);
                  queryClient.removeQueries(['groupsPremiumInfo']);
                  queryClient.removeQueries(['indicatorsFromGroup']);
                }}
                data-cy="button-back"
                data-testid="button-back"
                style={{ cursor: 'pointer' }}
              />
            </CardFeatures>
          </div>
          <div className="containerLinear">
            <ContainerErrorChart>
              <ContainerMaintenance
                content="data"
                text={translate('accessErrorIndicators')}
              />
            </ContainerErrorChart>
          </div>
        </>
      ) : isLoadingGroupPremium ||
        !groupsPremiumInfo ||
        isLoading ||
        isFetching ? (
        // eslint-disable-next-line react/jsx-indent
        <>
          <div className="containerLinear">
            <ContainerSkeleton
              withLoading={false}
              style={{
                height: '150px',
              }}
            />
          </div>
          <div className="containerLinear">
            <ContainerSkeleton
              withLoading={false}
              style={{
                height: '500px',
              }}
            />
          </div>
        </>
      ) : indicatorsFromGroup?.data.length === 0 ? (
        <>
          <div className="containerLinear">
            <CardFeatures>
              <div className="iconBack">
                <ArrowLeft
                  size="2rem"
                  onClick={() =>
                    navigate('/feature-store/admin/groups-premium')
                  }
                  data-cy="button-back"
                  data-testid="button-back"
                />
              </div>
              <div className="cardDescription">
                <Card
                  textCard={
                    groupsPremiumInfo.name[language] ??
                    groupsPremiumInfo.name['en-us']
                  }
                  textDescription={
                    groupsPremiumInfo.description[language] ??
                    groupsPremiumInfo.description['en-us']
                  }
                />
              </div>
              <ContentButtonAddIndicator>
                <Button
                  buttonType="primary"
                  icon={<UserCirclePlus size="1.125rem" color="white" />}
                  onClick={() => setInsertUserModal(true)}
                  data-testid="button-adicionar-novo-indicador"
                  data-cy="button-adicionar-novo-indicador"
                >
                  {translate('accessAddIndicators')}
                </Button>
              </ContentButtonAddIndicator>
            </CardFeatures>
          </div>
          <div className="containerLinear">
            <ContainerMaintenance
              content="data"
              text={translate('accessUnableToFindIndicator')}
            />
          </div>
        </>
      ) : (
        <>
          <div className="containerLinear">
            <CardFeatures>
              <div className="iconBack">
                <ArrowLeft
                  size="2rem"
                  onClick={() =>
                    navigate('/feature-store/admin/groups-premium')
                  }
                  data-cy="button-back"
                  data-testid="button-back"
                />
              </div>
              <div className="cardDescription">
                <Card
                  textCard={
                    groupsPremiumInfo.name[language] ??
                    groupsPremiumInfo.name['en-us']
                  }
                  textDescription={
                    groupsPremiumInfo.description[language] ??
                    groupsPremiumInfo.description['en-us']
                  }
                />
              </div>
            </CardFeatures>
            <ContentButtonAddIndicator>
              <Button
                buttonType="primary"
                icon={<UserCirclePlus size="1.125rem" />}
                onClick={() => setInsertUserModal(true)}
                data-testid="button-add-new-indicador"
                data-cy="button-add-new-indicador"
              >
                {translate('accessAddIndicators')}
              </Button>
            </ContentButtonAddIndicator>
          </div>
          <div className="containerLinear">
            <ContainerTable>
              <Table data-testid="table-list-indicators-from-group-premium">
                <Thead>
                  <Tr>
                    <Th>
                      <p>{translate('accessName')}</p>
                    </Th>
                    <Th>
                      <p>{translate('accessCode')}</p>
                    </Th>
                    <Th>
                      <p>{translate('accessDescription')}</p>
                    </Th>
                    <Th>
                      <p>{translate('accessSource')}</p>
                    </Th>
                    <Th>
                      <p>{translate('accessActions')}</p>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {indicatorsFromGroup?.data.map((indicators) => (
                    <Tr key={indicators.indicator_code}>
                      <Td style={{ maxWidth: '200px' }}>
                        <CheckBox
                          key={indicators.indicator_code}
                          data-testid={indicators.indicator_code}
                          data-cy={indicators.indicator_code}
                          label={
                            indicators.name[language] ??
                            indicators.name['en-us']
                          }
                          onChange={() =>
                            handleSelectIndicator(indicators.indicator_code)
                          }
                          checked={indicatorSelected.some(
                            (selected) =>
                              selected.id === indicators.indicator_code,
                          )}
                        />
                      </Td>
                      <Td>
                        <p>{indicators.indicator_code}</p>
                      </Td>
                      <Td style={{ maxWidth: '280px' }}>
                        <p>
                          {indicators.description[language] ??
                            indicators.description['en-us']}
                        </p>
                      </Td>
                      <Td>
                        <p>
                          {indicators.source[language] ??
                            indicators.source['en-us']}
                        </p>
                      </Td>
                      <Td>
                        <Eye
                          size="1.25rem"
                          onClick={() =>
                            navigate(
                              `/feature-store/indicators/${indicators.indicator_code}`,
                            )
                          }
                          style={{ cursor: 'pointer' }}
                        />
                      </Td>
                    </Tr>
                  ))}
                  {Array.from(
                    {
                      length:
                        QUANTITY_ITEMS_PAGE -
                        (indicatorsFromGroup
                          ? indicatorsFromGroup.data.length
                          : 10),
                    },
                    (_, index) => (
                      <TrComplete key={`tr-complete-${index.toString()}`} />
                    ),
                  )}
                </Tbody>
              </Table>
            </ContainerTable>
            <DivDeletePagination>
              <Button
                buttonType="secondary"
                data-testid="button-delete"
                data-cy="button-delete"
                icon={<Trash size="1.125rem" />}
                onClick={() => setModalDeleteIndicator(true)}
                disabled={indicatorSelected.length === 0}
                style={{
                  border: `1px solid ${light.colors.primary}`,
                  color: light.colors.primary,
                }}
              >
                {translate('accessDelete')}
              </Button>
              {indicatorsFromGroup?.total && (
                <Pagination
                  page={pages}
                  setPage={setPages}
                  total={indicatorsFromGroup.total}
                  quantityItemsPerPage={QUANTITY_ITEMS_PAGE}
                  name={translate('accessIndicators')}
                />
              )}
            </DivDeletePagination>
          </div>
        </>
      )}

      {insertUserModal && (
        <InsertIndicatorGroupPremium
          visible={insertUserModal}
          setVisible={setInsertUserModal}
          idGroup={id ?? ''}
          indicatorInfo={
            indicatorsFromGroup
              ? indicatorsFromGroup.data.map((indicator) => ({
                  indicator_code: indicator.indicator_code,
                  name: {
                    'en-us': indicator.name['en-us'],
                    'pt-br': indicator.name['pt-br'],
                  },
                }))
              : []
          }
          openModalUser={false}
          access_type={groupsPremiumInfo?.access_type ?? ''}
        />
      )}

      {modalDeleteIndicator && (
        <ModalDeleteIndicator
          indicatorSelected={indicatorSelected}
          setIndicatorSelected={setIndicatorSelected}
          setVisible={setModalDeleteIndicator}
          group={id ?? ''}
        />
      )}
    </Container>
  );
};
