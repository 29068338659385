import React from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PerformingOurMagic from 'src/assets/performing-our-magic.svg';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';
import { formatCompactNotation } from 'src/utils/numbers/formatCompactNotation';

import { ContainerModal, ContentButtons } from './styles';
import { conclusionModalENTranslations } from './translations/en-us';

type PropsModal = {
  visible: boolean;
  setVisible: (value: boolean) => void;
  title?: string;
  description: string;
  featureSelectionActive: boolean;
};

export const ConclusionModal: React.FC<PropsModal> = ({
  visible,
  setVisible,
  title,
  description,
  featureSelectionActive,
}) => {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();

  function adjustDescriptionAccordingToLanguage(): string {
    if (featureSelectionActive) {
      if (
        description.includes(
          conclusionModalENTranslations.conclusionDescriptionSuccessFeatureSelectionActive.slice(
            0,
            83,
          ),
        )
      ) {
        const combinations = Number(
          description.slice(83, description.indexOf(' specification')),
        );

        return translate(
          'conclusionDescriptionSuccessFeatureSelectionActive',
        ).replace(
          'XXX',
          combinations >= 1000
            ? formatCompactNotation(combinations)
            : combinations.toString(),
        );
      }
    } else if (
      description.includes(
        conclusionModalENTranslations.conclusionDescriptionSuccessFeatureSelectionDisabled.slice(
          0,
          77,
        ),
      )
    ) {
      const combinations = Number(
        description.slice(77, description.indexOf(' specification')),
      );

      return translate(
        'conclusionDescriptionSuccessFeatureSelectionDisabled',
      ).replace(
        'XXX',
        combinations >= 1000
          ? formatCompactNotation(combinations)
          : combinations.toString(),
      );
    }

    return description;
  }

  return (
    <Modal visible={visible} style={{ padding: 0 }}>
      <ContainerModal>
        <img src={PerformingOurMagic} alt="performing-our-magic" />

        <h1>{title ?? translate('conclusionTitle')}</h1>
        {description && (
          <p data-cy="modal-modelling-conclusion-description">
            {adjustDescriptionAccordingToLanguage()}
          </p>
        )}

        <ContentButtons>
          <Button
            data-testid="modalConclusionOkButton"
            buttonType="primary"
            onClick={() => {
              setVisible(false);
              navigate('/models/projects');
            }}
          >
            Ok
          </Button>
        </ContentButtons>
      </ContainerModal>
    </Modal>
  );
};
