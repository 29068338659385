import styled, { css } from 'styled-components';

export const ContainerChartHistoricalForecast = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;

  width: 100%;

  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 0.5rem;

    @media (max-width: 750px) {
      max-width: 12.5rem;
    }

    @media (max-width: 650px) {
      max-width: 9.375rem;
    }

    > p {
      font-weight: 600;
      font-size: 1rem;
      line-height: 150%;
      color: ${({ theme }) => theme.colors.gray6};
    }

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;

      width: 100%;
    }
  }
`;

interface Chart {
  color1: string;
  color2: string;
  hasLeftBar?: boolean;
}

export const Chart = styled.div<Chart>`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  > p {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.375rem;
    color: ${({ theme }) => theme.colors.gray5};

    margin-bottom: 0.5rem;
  }

  > div {
    position: relative;
    width: 100%;

    > div:nth-child(1) {
      position: relative;
      height: 0.25rem;
      width: 100%;
      background: ${({ color1, color2 }) =>
        `linear-gradient(90deg, ${color1} 0%, ${color2} 100%)`};

      ${({ hasLeftBar, color1 }) =>
        hasLeftBar &&
        css`
          &:before {
            content: '';
            position: absolute;
            left: 0;
            bottom: -0.5rem;
            height: 1.25rem;
            width: 0.125rem;
            background: ${color1};
          }
        `}

      &:after {
        content: '';
        position: absolute;
        right: 0;
        bottom: -0.5rem;
        height: 1.25rem;
        width: 0.125rem;
        background: ${({ color2 }) => color2};
      }
    }

    > div:nth-child(2),
    > div:nth-child(3) {
      position: absolute;
      top: 1rem;

      text-align: center;
      p {
        font-weight: 500;
        font-size: 0.75rem;
        color: ${({ theme }) => theme.colors.gray4};
      }
    }
  }
`;
