import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;

  button {
    margin-top: 1rem;
  }
`;

type ImageStatusProps = {
  isEmptyButtonType?: boolean;
};

export const ImageStatus = styled.img<ImageStatusProps>`
  padding-left: 5.3125rem;
  padding-left: ${({ isEmptyButtonType }) =>
    isEmptyButtonType ? '5.3125rem' : '0'};
`;

export const ElementSpan = styled.span`
  display: inline-flex;
  padding: 0.25rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;

  border-radius: 62.4375rem;
  background: ${({ theme }) => theme.colors.gray1};

  color: ${({ theme }) => theme.colors.gray4};

  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;

  transform: translateY(-50%);
`;

export const ContainerMessage = styled.div`
  display: flex;
  width: 28.8125rem;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  h4 {
    color: ${({ theme }) => theme.colors.gray6};

    font-family: Inter;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.6875rem;
  }

  p {
    color: ${({ theme }) => theme.colors.gray5};
    text-align: center;

    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
`;
