import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { insertProject } from 'src/models/redux/reducers/ClaasProject';
import api from 'src/models/service/api';
import { arrayIcon, checkIconURL } from 'src/utils/icons/handleProjectIcon';

import { ContainerProject, ProjectDetails } from './styles';
import { ClaasProject, Models } from './types';

interface ProjectIdProps {
  projectId: string;
  sidebarExpanded: boolean;
}

export const ProjectInfo: React.FC<ProjectIdProps> = ({
  projectId,
  sidebarExpanded,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t: translate } = useTranslation();

  const {
    data: projectData,
    isLoading,
    isFetching,
  } = useQuery(
    ['claas project', projectId],
    async () => {
      const response = await api.get<ClaasProject>(`/projects/${projectId}`);

      return response.data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled: !!projectId,
      onSuccess: (data) => {
        if (
          data &&
          (!data.id ||
            !data.name ||
            data.status !== 'success' ||
            data.type !== 'classification')
        ) {
          navigate('/models/projects');
        }
      },
      onError: () => {
        navigate('/models/projects');
      },
    },
  );

  const { data: modelsData } = useQuery(
    ['project models', projectId],
    async () => {
      const { data } = await api.get<Models>(
        `/classification/projects/${projectId}/models`,
      );

      return data;
    },
    {
      enabled: !!projectId,
      staleTime: 1000 * 60 * 20,
      onSuccess: (data) => {
        data.model_list.length === 0 && navigate('/models/projects');
      },
      onError: () => {
        dispatch(
          insertProject({
            id: null,
            name: null,
            modelList: null,
            modelInProduction: null,
            projectError: true,
            targetVariable: null,
          }),
        );
      },
    },
  );

  useEffect(() => {
    if (projectData && modelsData) {
      dispatch(
        insertProject({
          id: projectData.id,
          name: projectData.name,
          modelList: modelsData.model_list,
          modelInProduction: {
            name:
              projectData?.model_production?.name ??
              modelsData?.model_list[0].name,
            code:
              projectData?.model_production?.code ??
              modelsData?.model_list[0].type,
            type:
              modelsData?.model_list?.find(
                (model) => model.code === projectData?.model_production?.code,
              )?.type ?? modelsData?.model_list[0].type,
          },
          projectError: false,
          targetVariable: projectData?.y_series[0],
        }),
      );
    }
  }, [dispatch, modelsData, projectData]);

  return (
    <ContainerProject data-testid="container-sidebar-project">
      <ProjectDetails
        onClick={() => navigate('/models/projects')}
        expanded={sidebarExpanded}
      >
        {isLoading || isFetching ? (
          <>
            <ContainerSkeleton
              data-testid="project-loading"
              withLoading={false}
              style={{
                width: '53px',
                height: '53px',
                margin: '0 !important',
              }}
            />
            <div style={{ flex: '1' }}>
              <ContainerSkeleton
                withLoading={false}
                style={{ width: '170px', height: '24px', margin: '0' }}
              />
              <ContainerSkeleton
                withLoading={false}
                style={{ width: '170px', height: '24px', margin: '4px 0 0' }}
              />
            </div>
          </>
        ) : (
          projectData && (
            <>
              <img
                src={
                  checkIconURL(projectData?.icon_url) && projectData?.icon_url
                    ? projectData.icon_url
                    : arrayIcon[0]
                }
                alt="project-icon"
              />
              <div>
                <h4>{projectData?.name}</h4>
                <p>{translate('sideBarSelectAnotherProject')}</p>
              </div>
            </>
          )
        )}
      </ProjectDetails>
    </ContainerProject>
  );
};
