import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import parseHTML from 'html-react-parser';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { Input } from 'src/components/Input';
import { Modal } from 'src/components/Modal';
import { DataError } from 'src/interface/axios';
import { apiAdminV2 } from 'src/service/apiAdminV2';
import { queryClient } from 'src/service/queryClient';
import * as Yup from 'yup';
import { ModalFooter } from 'src/components/Modal/Footer/styles';

import { InfoToRegisterUser } from '../../types';
import { Container } from './styles';

interface ModalRegisterNewUserProps {
  visible: boolean;
  customerInfoLicense: number;
  setVisible: (value: boolean) => void;
  setInfoFailedToRegisterUser: (value: InfoToRegisterUser) => void;
  setInfoSuccessToRegisterUser: (value: InfoToRegisterUser) => void;
}

type useParamsProps = {
  client_id: string;
};

interface FormRegisterUser {
  email: string;
}

export const ModalRegisterNewUser: React.FC<ModalRegisterNewUserProps> = ({
  visible,
  setVisible,
  customerInfoLicense,
  setInfoFailedToRegisterUser,
  setInfoSuccessToRegisterUser,
}) => {
  const [loadingRegisterUser, setLoadingRegisterUser] =
    useState<boolean>(false);
  const { client_id } = useParams<useParamsProps>();
  const { t: translate } = useTranslation();

  const schema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email('modalRegisterNewCustomerAdminValidEmail')
      .required('requiredField'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormRegisterUser>({
    resolver: yupResolver(schema),
  });

  const handleRegisterUser = async ({
    email,
  }: FormRegisterUser): Promise<void> => {
    setLoadingRegisterUser(true);
    try {
      const data = await apiAdminV2.post(`/clients/${client_id}/users`, {
        email,
      });
      if (data) {
        setInfoSuccessToRegisterUser({
          title: translate('SuccessRegisterNewUserTitle'),
          description: translate('SuccessRegisterNewUserDescription'),
        });
      }

      queryClient.removeQueries('list users admin painel');
      queryClient.removeQueries('customer info');
    } catch (err) {
      const error = err as AxiosError<DataError>;

      if (
        error.response?.status === 409 &&
        error.response.data.detail?.description ===
          'The requested resource is already registered.'
      ) {
        setInfoFailedToRegisterUser({
          title: translate('ErrorRegisterNewUserTitle'),
          description: translate('RegisterNewUserMessageStatus409'),
        });
      } else {
        setInfoFailedToRegisterUser({
          title: translate('ErrorRegisterNewUserTitle'),
          description:
            error?.response?.data.detail?.description ??
            translate('ErrorRegisterNewUserDescription'),
        });
      }
    }
    setLoadingRegisterUser(false);
    setVisible(false);
  };
  return (
    <Modal visible={visible} setVisible={setVisible}>
      <Container data-testid="container-modal-invite-new-user">
        <h4>{translate('RegisterNewUserTitle')}</h4>
        <p data-testid="text-modal-invite-new-user-remaining-licenses">
          {parseHTML(
            translate(
              customerInfoLicense > 1
                ? 'RegisterNewUserDescriptionPlural'
                : 'RegisterNewUserDescriptionSingular',
            ).replace('XXX', customerInfoLicense.toString()),
          )}
        </p>

        <Controller
          name="email"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <Input
              label="E-mail"
              placeholder={translate('RegisterNewUserEmailUser')}
              testid="input-email-user"
              // @ts-expect-error typescript bug
              error={translate(errors.email?.message)}
              onChange={onChange}
              value={value}
            />
          )}
        />
      </Container>
      <ModalFooter>
        <Button
          buttonType="naked"
          onClick={() => setVisible(false)}
          data-testid="button-cancel"
          data-cy="button-cancel"
          disabled={loadingRegisterUser}
        >
          {translate('modalRegisterNewCustomerButtonCancel')}
        </Button>
        <Button
          buttonType="primary"
          onClick={handleSubmit(handleRegisterUser)}
          data-testid="button-register"
          data-cy="button-register"
          loading={loadingRegisterUser}
          disabled={loadingRegisterUser}
        >
          {translate('modalRegisterNewCustomerButtonRegister')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
