export const workspaceProjectionsResultENTranslate = {
  workspaceProjectionsResultTitle: 'Results',
  workspaceProjectionsResultDescription:
    'Select a historical period to see more details about the modeling results.',
  workspaceProjectionsResultQuarterly: 'Quarterly',
  workspaceProjectionsResultsVariationOriginal:
    'Variation (compared to the previous period)',
  workspaceProjectionsResultsVariationMonthlyQuarterly:
    'Variation (compared to the same period in the previous year)',
  workspaceProjectionsResultsVariationAnnual:
    'Variation (compared to the previous year)',
  workspaceProjectionsMonthlyAggregationIsNotAvailable:
    'Monthly aggregation is not available for dataset with missing values.',
  workspaceProjectionsResultsThisFeatureTemporarilyUnavailable:
    'This feature is temporarily unavailable.',
};
