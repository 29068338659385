import { Button } from 'src/components/Button';
import styled, { css } from 'styled-components';

type ButtonCopyProps = {
  isCopied: boolean;
};

export const ButtonCopy = styled(Button)<ButtonCopyProps>`
  ${({ isCopied }) =>
    isCopied &&
    css`
      color: ${({ theme }) => theme.colors.green4};
      cursor: pointer;
    `}
`;
