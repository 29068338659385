import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PredictPreview } from 'src/models/pages/Classification/components/Predictions/types';

export type PredictStatusProps = {
  id: string | null;
  usedModel: string | null;
  preview: PredictPreview | null;
  dataset: File | null;
};

const initialState: PredictStatusProps = {
  id: null,
  usedModel: null,
  preview: null,
  dataset: null,
};

export const predictStatus = createSlice({
  name: 'predict status',
  initialState,
  reducers: {
    insertPredictRunning: (
      state,
      action: PayloadAction<PredictStatusProps>,
    ) => {
      state.id = action.payload.id;
      state.usedModel = action.payload.usedModel;
      state.preview = action.payload.preview;
      state.dataset = action.payload.dataset;
    },
    clearPredictRunning: (state) => {
      state.id = null;
      state.usedModel = null;
      state.preview = null;
      state.dataset = null;
    },
  },
});

export const { insertPredictRunning, clearPredictRunning } =
  predictStatus.actions;

export default predictStatus.reducer;
