export const sideBarFeatureStoreENTranslate = {
  sidebarFSSearchDescription: 'Explore our entire base',
  sidebarFSFavoritesDescription: 'Access your favorite series',
  sideBarFSGroupPremiumDescription: 'Select a premium group',
  indicatorsSearchFiltersDescription:
    'Search indicators more easily using filters',
  sidebarFSSearchTitle: 'Search Indicators',
  sidebarFSFavoritesTitle: 'Groups Favorites',
  sidebarFSPremiumTitle: 'Groups Premium',
  sidebarFSSectionTitle: 'SECTIONS',
  sidebarFSOpenFilters: 'Open Filters',
  sideBarFSAIChat: '4i Chat',
  sideBarFSAIChatDescription: 'Let our AI help you find exactly what you need.',
};
