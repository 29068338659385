export const userSelectionResultsPTBRTranslate = {
  userSelectionResultTitle: 'Resultados',
  userSelectionResultFrequency: 'Frequência',
  userSelectionResultTransformation: 'Transformação',
  userSelectionResultOriginal: 'Original',
  userSelectionResultMonthly: 'Mensal',
  userSelectionResultQuarterly: 'Trimestral',
  userSelectionResultAnnual: 'Anual',
  userSelectionResultLevel: 'Nível',
  userSelectionResultVariation: 'Variação',
  userSelectionResultLatestData: 'Dados recentes',
  userSelectionResultsVariationOriginal:
    'Variação (comparado ao período anterior)',
  userSelectionResultsVariationMonthlyQuarterly:
    'Variação (comparado ao mesmo período do ano anterior)',
  userSelectionResultsVariationAnnual: 'Variação (comparado ao ano anterior)',
  userSelectionNOfDays: 'Nº dias',
  userSelectionResultsNotEnoughObservationsVariation:
    'Não há observações suficientes para calcular a variação.',
};
