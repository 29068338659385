import styled from 'styled-components';

export const ResultContainer = styled.div``;

export const ChartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  margin-bottom: 1.5rem;
`;

export const ContentLatestData = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;

  > div {
    > div {
      text-align: right;

      p {
        margin-right: 0px;
      }
    }
  }

  input {
    display: block !important;
    margin-left: auto !important;
  }
`;
