import React from 'react';

import { BarContents, Container, Content } from './styles';

export const Loading: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  ...rest
}) => (
  <Container>
    <Content {...rest}>
      <BarContents>
        <div />
        <div />
        <div />
      </BarContents>
    </Content>
  </Container>
);
