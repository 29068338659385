export const ModalRegisterEditCustomerPTTranslate = {
  modalRegisterNewCustomerTitle: 'Cadastrar novo cliente',
  modalRegisterNewCustomerTitleEdit: 'Editar cliente',
  modalRegisterNewCustomerNameMaxCharacters:
    'Não deve ter mais do que 350 caracteres',
  modalRegisterNewCustomerErrorSpecifyNumber: 'Você deve especificar um número',
  modalRegisterNewCustomerIntegerNumber: 'O número deve ser inteiro',
  modalRegisterNewCustomerLicenseMin: 'Campo deve ser maior ou igual a 1',
  modalRegisterEditCustomerLicenseErrorGreaterThanEqual:
    'Número de licenças deve ser maior ou igual a quantidade usada atualmente',
  modalRegisterEditCustomerLicenseErrorGreaterThanEqualManager:
    'Número de licenças deve ser maior ou igual a quantidade usada atualmente (cada gerente agora consome 1 licença)',
  modalRegisterNewCustomerAdminValidEmail: 'Deve ser um e-mail válido',
  modalRegisterNewCustomerName: 'Nome',
  modalRegisterNewCustomerNumberOfLicense: 'Número de licenças',
  modalRegisterNewCustomerUserManager: 'E-mail do usuário gerente',
  modalRegisterNewCustomerButtonRegister: 'Cadastrar',
  modalRegisterNewCustomerButtonConfirm: 'Confirmar',
  modalRegisterNewCustomerButtonCancel: 'Cancelar',
  modalRegisterNewCustomerErrorToRegisterClient:
    'Ocorreu um erro ao cadastrar novo cliente.',
  modalRegisterEditCustomerErrorToEditClient:
    'Ocorreu um erro ao editar o cliente.',
  modalRegisterNewCustomerErrorToRegisterManagerUser:
    'Ocorreu um erro ao cadastrar o usuário gerente.',
  modalRegisterEditCustomerErrorToEditManagerUser:
    'Ocorreu um erro ao editar a permissão de acesso aos produtos para o gerente.',
  modalRegisterNewCustomerPlaceholderName: 'Digite o nome do cliente',
  modalRegisterNewCustomerManagerWithAccess: 'Gerente com acesso aos produtos',
  modalRegisterNewCustomerPlaceholderNumberOfLicense:
    'Insira o número de licenças',
  modalRegisterNewCustomerPlaceholderManagerUser:
    'Digite o e-mail do usuário gerente',
  modalRegisterNewCustomerReadOnly: 'Apenas leitura',
  modalRegisterNewCustomerCreateAndRead: 'Criação + Leitura',
  modalRegisterNewCustomerClassification: 'Classificação',
  modalRegisterNewCustomerTimeSeries: 'Série Temporal',
  modalRegisterNewCustomerProducts: 'Produtos',
  modalRegisterNewCustomerAddManager: '+ Adicionar gerente',
  modalRegisterNewCustomerRemoveManager: '- Remover gerente',
  modalWarningManagerMessage:
    'Cliente criado com sucesso! Porém não foi possível incluir o gerente para esse cliente, configure um novo gerente posteriormente.',
  modaRegisterNewCustomerErrorMessageProduct:
    'Você precisa selecionar pelo menos um produto para esse cliente.',
};
