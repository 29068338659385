import styled from 'styled-components';

export const Container = styled.div`
  width: fit-content;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  margin-bottom: 1.5rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  padding: 0.25rem 0.75rem;

  background-color: ${({ theme }) => theme.colors.gray0};

  border-radius: 5px;

  svg {
    width: 1rem;
    height: 1rem;

    color: ${({ theme }) => theme.colors.gray4};
  }

  p {
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.375rem;

    color: ${({ theme }) => theme.colors.gray5};
  }
`;
