import styled from 'styled-components';

export const Container = styled.div`
  padding: 1.5rem;
`;

export const Content = styled.div`
  position: relative;

  .tutorial-button {
    position: absolute;
    top: 1.75rem;
    right: 1.5rem;

    width: 1.5rem;
    height: 1.5rem;

    padding: 0;

    svg {
      width: 1.5rem;
      height: 1.5rem;

      margin: 0;
    }
  }
`;

export const CreateFilterContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 5rem 0 3.5rem 0;

  > div {
    width: 25rem;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;

    text-align: center;

    color: ${({ theme }) => theme.colors.gray5};

    margin-bottom: 1.5rem;
  }

  img {
    width: 10rem;
  }

  button {
    padding: 0.75rem 3rem;
  }
`;
