const modelUpdateSidebarENUSTranslations = {
  modelUpdtSidebarTitle: 'Update History',
  modelUpdtSidebarDescription:
    'See the history of updates and the original version of your project.',
  modelUpdtProjectViewing: 'Viewing <strong>"UPDATE_NAME"</strong>',
  modelUpdtBackToLastUpdt: 'Back to latest update',
  modelUpdtLatestUpdate: 'latest update',
};

export default modelUpdateSidebarENUSTranslations;
