import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface ProjectWarningsProps {
  dailyProject: {
    id: string;
    ysId: string[];
  }[];
}

const initialState: ProjectWarningsProps = {
  dailyProject: [],
};

interface InsertDailyProject1YProps {
  projectId: string;
  yId: string;
}

interface InsertDailyProjectMultipleYProps {
  projectId: string;
  ysId: string[];
}

export const projectWarnings = createSlice({
  name: 'projectWarnings',
  initialState,
  reducers: {
    insertDailyProject1Y: (
      state,
      action: PayloadAction<InsertDailyProject1YProps>,
    ) => {
      const index = state.dailyProject.findIndex(
        (project) => project.id === action.payload.projectId,
      );
      if (index !== -1) {
        const projectsAux = [...state.dailyProject];
        projectsAux[index].ysId.push(action.payload.yId);

        state.dailyProject = projectsAux;
      } else {
        state.dailyProject.push({
          id: action.payload.projectId,
          ysId: [action.payload.yId],
        });
      }
    },
    insertDailyProjectMultipleY: (
      state,
      action: PayloadAction<InsertDailyProjectMultipleYProps>,
    ) => {
      const index = state.dailyProject.findIndex(
        (project) => project.id === action.payload.projectId,
      );
      if (index !== -1) {
        const projectsAux = [...state.dailyProject];
        projectsAux[index].ysId.push(...action.payload.ysId);

        state.dailyProject = projectsAux;
      } else {
        state.dailyProject.push({
          id: action.payload.projectId,
          ysId: action.payload.ysId,
        });
      }
    },
  },
});

export const { insertDailyProject1Y, insertDailyProjectMultipleY } =
  projectWarnings.actions;

export default projectWarnings.reducer;
