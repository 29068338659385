export const FiltersSidebarENUSTranslation = {
  filtersSidebarDescription: 'Configure full-page chart and table views.',
  filterSidebarTransformation: 'Data Transformation',
  filterSidebarTooltipTransformation:
    'The data can be viewed at level (with absolute values) or in variation (comparing with the previous XXX or the same XXX of the previous year).',
  filterSidebarTooltipTransformationToYearlyFreq:
    'The data can be viewed at level (with absolute values) or in variation (comparing with the same period of the previous year).',
  filterSidebarVariationYoY: 'Variation % (YoY)',
  filterSidebarVariationPoP: 'Variation % (PoP)',
  filterSidebarMonth: 'month',
  filterSidebarBimonth: 'bimester',
  filterSidebarQuarter: 'quarter',
  filterSidebarHalfYear: 'half-year',
  filterSidebarOther: 'period',
};
