const filterIndicatorSeriesENTranslations = {
  filterSelectOpenings: 'Select the openings',
  filterFinishedContinue: 'When finished, click “Continue” to select a group',
  filterRegion: 'Region',
  filterFrequency: 'Frequency',
  filterPrimaryTransformation: 'Primary transformation',
  filterSecondaryTransformation: 'Secondary transformation',
  filterWarningRegionNoOpening: 'XXX region has no YYY opening',
  filterNumberSeriesSelected:
    'You have selected <b>XXX</b> series from the available <b>YYY</b> for this indicator',
  filterButtonCancel: 'Cancel',
  filterButtonContinue: 'Continue',
};

export default filterIndicatorSeriesENTranslations;
