const editUnitENTranslate = {
  editSerieTitle: 'Edit a serie',
  editSerieUnitPT: 'Unit (portuguese)',
  editSerieUnitPTPlaceholder: 'Write the unit name in Portuguese',
  editSerieUnitEN: 'Unit (english)',
  editSerieUnitENPlaceholder: 'Write the unit name in English',
  editSerieMaintenance: 'Maintenance?',
  editSerieMax: 'Maximum 50 characters',
  editSerieMin: 'Must be greater than or equal to 1 digits',
  editSerieButton: 'Edit serie',
  editSerieFailedTitle: 'Unable to edit series',
  editSerieFailedText: 'Try again later',
};

export default editUnitENTranslate;
