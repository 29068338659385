export const ShareWorkSpaceENUSTranslations = {
  shareWorkSpaceChooseUserToShare: 'Choose who to share with',
  shareWorkSpaceDescriptionShare:
    'Enter the e-mail of the person you want to share the Workspace with',
  shareWorkSpaceEditor: 'Can edit',
  shareWorkSpaceVisualize: 'Can collaborate',
  shareWorkspaceInvalidEmail: 'Invalid e-mail',
  shareWorkspaceUserAlreadyInvited: 'User already has access',
  shareWorkspaceRepeatedEmail: 'Repeated e-mail',
  shareWorkspaceError:
    'An error occurred when sharing with the e-mails below. Try again later.',
};
