import React, { useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { CheckCircle, Copy, UserFocus } from 'phosphor-react';
import light from 'src/styles/themes/light';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import parseHTML from 'html-react-parser';

import {
  ActionButton,
  Container,
  ContainerExtensor,
  InfoContainer,
  Overlay,
} from './styles';

interface UserConfigProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  isHomePage?: boolean;
}

export const UserConfigs: React.FC<UserConfigProps> = ({
  visible,
  setVisible,
  isHomePage,
}) => {
  const { t: translate } = useTranslation();
  const { user } = useSelector((state: RootState) => state.auth);
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const [apiTokenCopied, setAPITokenCopied] = useState(false);

  async function copyAPIToken() {
    setAPITokenCopied(true);
    const token = await getAccessTokenSilently();

    navigator.clipboard.writeText(token);

    setTimeout(() => {
      setAPITokenCopied(false);
    }, 10000);
  }

  async function generateTokenOnTxtFile() {
    const token = await getAccessTokenSilently();

    const fileURL = window.URL.createObjectURL(
      new Blob([token], { type: 'text/plain' }),
    );
    const link = document.createElement('a');
    link.setAttribute('href', fileURL);
    link.setAttribute('download', 'api-token.txt');
    link.setAttribute('data-testid', 'token-txt-download-start');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
  }

  function handleNavigateToUserControl() {
    user.isSupport
      ? navigate('/user-module/customers-list')
      : user.managerClientId &&
        navigate(`/user-module/customers-list/${user.managerClientId}`);
  }

  return (
    <>
      {visible && (
        <Overlay visible={visible} onClick={() => setVisible(false)} />
      )}
      <ContainerExtensor visible={visible} isHomePage={isHomePage} />
      <Container visible={visible} isHomePage={isHomePage}>
        <h4>{translate('userConfigsWidgetTitle')}</h4>
        {(user.isSupport || user.managerClientId) && (
          <ActionButton
            iconBackground="rgba(183, 148, 244, 0.16)"
            data-testid="link-admin"
            data-cy="link-admin"
            onClick={handleNavigateToUserControl}
          >
            <div>
              <UserFocus size="2rem" color="#9F7AEA" />
            </div>
            <div>
              <h5>{translate('userConfigsWidgedUserControls')}</h5>
              <p>{translate('userConfigsWidgedUserControlsDescription')}</p>
            </div>
          </ActionButton>
        )}
        <ActionButton
          iconBackground="rgba(76, 148, 255, 0.12)"
          selected={apiTokenCopied}
          onClick={copyAPIToken}
          data-testid="widged-button-copy-token"
        >
          <div>
            {apiTokenCopied ? (
              <CheckCircle size="2rem" color={light.colors.green4} />
            ) : (
              <Copy size="2rem" color={light.colors.primary} />
            )}
          </div>
          <div>
            <h5>{translate('userConfigsWidgedToken')}</h5>
            <p>{translate('userConfigsWidgedTokenDescription')}</p>
          </div>
        </ActionButton>
        {apiTokenCopied && (
          <InfoContainer visible={apiTokenCopied}>
            <p>
              {parseHTML(translate('userConfigsWidgedTokenInfo'))}
              <button
                type="button"
                data-testid="generate-token-txt"
                onClick={generateTokenOnTxtFile}
              >
                <span>{translate('userConfigsWidgedTokenInfoClick')}</span>
              </button>
              .
            </p>
          </InfoContainer>
        )}
      </Container>
    </>
  );
};
