import styled from 'styled-components';

export const Container = styled.div`
  width: 31.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h4 {
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.gray6};
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  p {
    color: ${({ theme }) => theme.colors.gray5};
    font-weight: normal;
    font-size: 1rem;
    line-height: 150%;
    margin-bottom: 3rem;
  }
`;

export const FooterModal = styled.div`
  margin: 0 -1.5rem -1.5rem;
  border-top: 1px solid ${({ theme }) => theme.colors.gray2};
  background-color: ${({ theme }) => theme.colors.gray0};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  > div {
    display: flex;
    justify-content: flex-end;
    padding: 1.5rem 2rem;
  }
`;
