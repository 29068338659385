import styled, { css } from 'styled-components';

type ContainerProps = {
  isModal: boolean;
};

export const Container = styled.div<ContainerProps>`
  width: 100%;
  height: 22rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    margin-bottom: 2rem;
  }

  h3 {
    width: 70%;

    text-align: center;

    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.6875rem;

    color: ${({ theme }) => theme.colors.gray6};
  }

  p {
    width: 70%;

    text-align: center;

    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;

    color: ${({ theme }) => theme.colors.gray5};
  }

  h3 + p {
    margin-top: 0.5rem;
  }

  svg {
    width: 3rem;
    height: 3rem;

    color: ${({ theme }) => theme.colors.yellow4};

    margin-bottom: 1.5rem;
  }

  .error-status-img,
  .no-search-results-status-img {
    width: 10rem;

    margin-bottom: -0.375rem;
  }

  .cloud-warning-status-img {
    margin-bottom: 1rem;
  }

  ${({ isModal }) =>
    isModal &&
    css`
      height: fit-content;

      h3 {
        font-size: 1.5rem;
      }

      h3,
      p {
        width: 100% !important;
      }

      h3 + p {
        margin-top: 1rem;
      }
    `}
`;
