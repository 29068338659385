import React, { useState } from 'react';

import { X } from 'phosphor-react';
import Ellipse from 'src/assets/Ellipse.svg';

import { Container, Tag } from './styles';

interface InputTagProps {
  emails: string[];
  setEmails: (emails: string[]) => void;
  testid?: string;
  placeholder: string;
}

export const InputTag: React.FC<InputTagProps> = ({
  emails,
  setEmails,
  testid,
  placeholder,
}) => {
  const [inputValue, setInputValue] = useState('');

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if ((e.key === 'Enter' || e.key === ' ') && inputValue !== '') {
      setEmails([...emails, inputValue]);
      setInputValue('');
    }
  };

  const handleRemoveEmail = (index: number) => {
    const newEmails = emails.filter((_, indexMail) => indexMail !== index);
    setEmails(newEmails);
  };

  return (
    <Container>
      {emails.map((email, index) => (
        <Tag key={`${email}+${index.toString()}`}>
          <img
            src={Ellipse}
            alt="elipse"
            data-testid="elipse"
            data-cy="elipse"
          />
          <span>{email}</span>
          <button
            type="button"
            onClick={() => handleRemoveEmail(index)}
            aria-label="remove user button"
          >
            <X />
          </button>
        </Tag>
      ))}
      <input
        value={inputValue}
        onKeyDown={handleKeyDown}
        onChange={(e) => setInputValue(e.target.value)}
        data-testid={testid ?? null}
        data-cy={testid ?? null}
        placeholder={emails.length === 0 ? placeholder : ''}
      />
    </Container>
  );
};
