import React, { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Card } from 'src/components/Card';
import {
  Handshake,
  PaperPlaneTilt,
  PencilSimple,
  Plus,
  UserCircle,
  UserGear,
} from 'phosphor-react';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Button } from 'src/components/Button';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { getTextWidth } from 'src/utils/getTextWidth';
import { getUserColor } from 'src/utils/colors/getUserColor';
import { Select } from 'src/components/Select';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { useNavigate, useParams } from 'react-router-dom';
import { queryClient } from 'src/service/queryClient';
import InviteUserImg from 'src/assets/send.svg';
import apiWorkspace from 'src/workspaces/service/api';
import ms from 'ms';
import { updateUserRole } from 'src/workspaces/redux/reducers/Workspace';

import {
  Container,
  EmptyGuests,
  NoAccessContainer,
  ShareSubtitleContainer,
  UserContent,
  UserInfo,
} from './styles';
import { Subtitle } from '../../styles';
import { ParamsProps } from '../../types';
import { ShareWorkSpace } from '../Modal/ShareWorkSpace';
import { UserRoleOptionLabel } from '../UserRoleOptionLabel';
import { ErrorModal } from '../Modal/Error';
import { RemoveUserModal } from '../Modal/RemoveUser';
import {
  SelectOption,
  ShareWorkspaceProps,
  UserRole,
  WorkspaceUser,
} from './types';

export const ShareWorkspace: React.FC<ShareWorkspaceProps> = ({
  isLoading,
  userUpdateLoading,
  setUserUpdateLoading,
}) => {
  const [showShareWorkspaceModal, setShowShareWorkspaceModal] = useState(false);
  const [invitedUsers, setInvitedUsers] = useState<WorkspaceUser[]>();
  const [userUpdateError, setUserUpdateError] = useState(false);
  const [removeUser, setRemoveUser] = useState('');

  const {
    auth: {
      user: { email },
    },
    workspace,
  } = useSelector((state: RootState) => state);

  const { id: workspaceId } = useParams<ParamsProps>();
  const { t: translate } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    data: userData,
    isLoading: isLoadingUser,
    isFetching: isFetchingUser,
    isError: isErrorUser,
  } = useQuery(
    ['workspace users', workspaceId],
    async () => {
      const response = await apiWorkspace.get<WorkspaceUser[]>(
        `/workspaces/${workspaceId}/users`,
      );

      return response.data;
    },
    {
      staleTime: ms('3 min'),
      enabled: !!workspace.createdBy,
      onError: ({ response }) =>
        response?.status === 403 && navigate('/workspaces'),
    },
  );

  const handleChangeUserRole = async (user: string, role: UserRole) => {
    setUserUpdateLoading(true);
    setUserUpdateError(false);

    try {
      await apiWorkspace.put(`/workspaces/${workspaceId}/users`, {
        user,
        role,
      });

      await queryClient.invalidateQueries(['workspace users', workspaceId]);
      await queryClient.invalidateQueries(['workspaces']);
      await queryClient.invalidateQueries(['workspace data', workspaceId]);

      if (user === email) {
        dispatch(updateUserRole(role));
      }

      if (email === user && role === 'reader') {
        navigate('/workspaces');
      }
    } catch {
      setUserUpdateError(true);
    }

    setUserUpdateLoading(false);
  };

  const hasMoreThanOneManager = useMemo(
    () =>
      userData &&
      userData.reduce((managerSum, user) => {
        if (user.role === 'manager') return managerSum + 1;
        return managerSum;
      }, 0) > 1,
    [userData],
  );

  useEffect(() => {
    setInvitedUsers(
      userData?.filter(({ user }) => user !== workspace.createdBy),
    );
  }, [userData, workspace.createdBy]);

  const userRoleOptions = [
    { label: translate('workspaceControlPanelCanView'), value: 'reader' },
    { label: translate('workspaceControlPanelCanEdit'), value: 'editor' },
    { label: translate('workspaceControlPanelCanManager'), value: 'manager' },
    { label: translate('workspaceControlPanelRemove'), value: 'remove' },
  ];

  const userIsManager = workspace.userRole === 'manager';
  const creatorUser = userData?.find(
    ({ user }) => user === workspace?.createdBy,
  );

  return (
    <>
      <Container className="containerLinear">
        <Card
          textCard={translate('workspaceControlPanelShareTitle')}
          textDescription={translate('workspaceControlPanelShareDescription')}
        />

        <ShareSubtitleContainer>
          <Subtitle>
            <UserCircle size="1.25rem" />
            <h3>{translate('workspaceControlPanelCreator')}</h3>
          </Subtitle>
        </ShareSubtitleContainer>

        {isLoading && !workspace.createdBy ? (
          <UserContent data-testid="workspace-owner-loading">
            <UserInfo>
              <ContainerSkeleton
                withLoading={false}
                style={{
                  width: '1.75rem',
                  height: '1.75rem',
                  borderRadius: '100%',
                }}
              />

              <ContainerSkeleton
                withLoading={false}
                style={{
                  width: '60%',
                  height: '1rem',
                }}
              />
            </UserInfo>
          </UserContent>
        ) : (
          <UserContent data-testid="workspace-owner">
            <UserInfo>
              <div>
                <p data-testid="workspace-initial-owner-email">
                  {workspace?.createdBy?.[0]}
                </p>
              </div>

              <p data-testid="workspace-owner-email">{workspace?.createdBy}</p>
            </UserInfo>

            {userData && !creatorUser ? (
              <NoAccessContainer data-testid="owner-without-acess">
                <p>{translate('workspaceControlPanelOwnerWithoutAccess')}</p>
              </NoAccessContainer>
            ) : creatorUser?.role === 'manager' ? (
              <UserGear />
            ) : creatorUser?.role === 'editor' ? (
              <PencilSimple />
            ) : creatorUser?.role === 'reader' ? (
              <Handshake />
            ) : null}
          </UserContent>
        )}

        <ShareSubtitleContainer style={{ marginTop: '2rem' }}>
          <Subtitle>
            <PaperPlaneTilt size="1rem" />
            <h3>{translate('workspaceControlPanelGuests')}</h3>
          </Subtitle>

          {userIsManager && (
            <Button
              buttonType="naked"
              icon={<Plus />}
              onClick={() => setShowShareWorkspaceModal(true)}
              data-testid="invite-users-button"
            >
              {translate('workspaceControlPanelInvite')}
            </Button>
          )}
        </ShareSubtitleContainer>

        {isErrorUser ? (
          <ContainerMaintenance
            content="content"
            className="container-maintenance"
            data-testid="users-error"
          />
        ) : isLoadingUser || isFetchingUser || !invitedUsers ? (
          Array.from({ length: 3 }, (_, number) => number).map((number) => (
            <UserContent
              key={number}
              data-testid={`workspace-guest-loading-${number}`}
            >
              <UserInfo>
                <ContainerSkeleton
                  withLoading={false}
                  style={{
                    width: '1.75rem',
                    height: '1.75rem',
                    borderRadius: '100%',
                  }}
                />

                <ContainerSkeleton
                  withLoading={false}
                  style={{
                    width: '60%',
                    height: '1rem',
                  }}
                />
              </UserInfo>
            </UserContent>
          ))
        ) : invitedUsers.length ? (
          invitedUsers.map(({ user, role }, index) => (
            <UserContent
              key={user}
              data-testid={`workspace-guest-${user}`}
              selectedRoleWidth={
                getTextWidth(
                  role === 'manager'
                    ? translate('workspaceControlPanelCanManager')
                    : role === 'editor'
                    ? translate('workspaceControlPanelCanEdit')
                    : translate('workspaceControlPanelCanView'),
                  '500 14px Inter',
                ) /
                  16 +
                3.75
              }
              isLoading={userUpdateLoading}
            >
              <UserInfo>
                <div style={{ background: getUserColor(index) }}>
                  <p>{user[0]}</p>
                </div>

                <p>{user}</p>
              </UserInfo>

              {userIsManager ? (
                <Select
                  value={{
                    value: role,
                    label:
                      role === 'manager'
                        ? translate('workspaceControlPanelCanManager')
                        : role === 'editor'
                        ? translate('workspaceControlPanelCanEdit')
                        : translate('workspaceControlPanelCanView'),
                  }}
                  options={userRoleOptions.map((option) => {
                    const canChangeToReaderOrEdit = hasMoreThanOneManager;

                    const canRemove =
                      hasMoreThanOneManager ||
                      (!hasMoreThanOneManager && userData!.length === 1);

                    if (role !== 'manager') return option;

                    if (
                      ((option.value === 'reader' ||
                        option.value === 'editor') &&
                        !canChangeToReaderOrEdit) ||
                      (option.value === 'remove' && !canRemove)
                    ) {
                      return {
                        ...option,
                        isDisabled: true,
                      };
                    }

                    return option;
                  })}
                  menuPlacement="top"
                  isSearchable={false}
                  formatOptionLabel={({ label, value, isDisabled }: any) => {
                    let tooltipMessage: string | undefined;

                    if (isDisabled) {
                      if (value === 'remove') {
                        tooltipMessage = translate(
                          'workspaceControlPanelNoPermissionDelete',
                        );
                      } else if (value === 'reader') {
                        tooltipMessage = translate(
                          'workspaceControlPanelNoPermissionUpdateToReader',
                        );
                      } else if (value === 'editor') {
                        tooltipMessage = translate(
                          'workspaceControlPanelNoPermissionUpdateToEditor',
                        );
                      }
                    }

                    return UserRoleOptionLabel({
                      label,
                      value,
                      tooltipMessage,
                    });
                  }}
                  onChange={(option) => {
                    const value = (option as SelectOption).value;

                    if (value !== role && value !== 'remove') {
                      handleChangeUserRole(user, value as UserRole);
                    }

                    if (value === 'remove') {
                      setRemoveUser(user);
                    }
                  }}
                  dataTestid={`workspace-guest-role-${user}`}
                />
              ) : role === 'manager' ? (
                <UserGear />
              ) : role === 'editor' ? (
                <PencilSimple />
              ) : (
                <Handshake />
              )}
            </UserContent>
          ))
        ) : (
          <EmptyGuests data-testid="empty-guests">
            <img src={InviteUserImg} alt="Invite users" />

            <h2>{translate('workspaceControlPanelInviteUser')}</h2>
            <p>{translate('workspaceControlPanelInviteUserDescription')}</p>
          </EmptyGuests>
        )}
      </Container>

      {showShareWorkspaceModal && (
        <ShareWorkSpace
          setVisible={() => setShowShareWorkspaceModal(false)}
          workspaceId={workspaceId ?? ''}
          users={userData?.map(({ user }) => user) ?? []}
        />
      )}

      {userUpdateError && (
        <ErrorModal
          setVisible={() => setUserUpdateError(false)}
          title={translate('workspaceControlPanelUserUpdateErrorTitle')}
          description={translate(
            'workspaceControlPanelUserUpdateErrorDescription',
          )}
        />
      )}

      {!!removeUser && (
        <RemoveUserModal
          setVisible={() => setRemoveUser('')}
          workspaceId={workspaceId ?? ''}
          user={removeUser}
        />
      )}
    </>
  );
};
