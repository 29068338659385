import styled, { css } from 'styled-components';

interface DependentVariableChartContainerProps {
  warningCount: number;
  heightMultiplier?: number;
}

export const DependentVariableChartContainer = styled.div<DependentVariableChartContainerProps>`
  display: flex;
  flex-direction: column;
  gap: ${({ warningCount }) => (warningCount > 0 ? '1.5rem' : 0)};
  height: ${({ warningCount, heightMultiplier }) =>
    warningCount === 2
      ? `${((heightMultiplier ?? 0) * 5 + 540) / 16}rem`
      : warningCount === 1
      ? `${((heightMultiplier ?? 0) * 5 + 460) / 16}rem`
      : `${((heightMultiplier ?? 0) * 5 + 417) / 16}rem`} !important;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 6.25px;
  padding: 1.5rem;

  > div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    ${({ warningCount }) =>
      warningCount &&
      warningCount > 0 &&
      css`
        > div {
          margin-top: 0 !important;
        }
      `}
  }
`;

export const ContentItens = styled.div`
  display: flex;
  justify-content: space-between;

  margin-bottom: 1.5rem;

  > div {
    width: 45%;
  }
`;
