import React from 'react';

import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';
import { ModalFooter } from 'src/components/Modal/Footer/styles';

import { ConfirmCategorizationDiscardModalProps } from './types';
import { Content } from './styles';

export const ConfirmCategorizationDiscardModal: React.FC<
  ConfirmCategorizationDiscardModalProps
> = ({ setVisible, handleDiscard }) => {
  const { t: translate } = useTranslation();

  return (
    <Modal
      visible
      setVisible={setVisible}
      dataCy="modal-discard-categorization"
    >
      <Content>
        <h3>{translate('discardCategorizationTitle')}</h3>
        <p>{translate('discardCategorizationDescription')}</p>
      </Content>

      <ModalFooter>
        <Button
          buttonType="naked"
          onClick={() => setVisible(false)}
          data-testid="button-cancel-categorization-discard"
        >
          {translate('cancel')}
        </Button>

        <Button
          buttonType="primary"
          onClick={() => {
            handleDiscard();
            setVisible(false);
          }}
          data-testid="button-confirm-categorization-discard"
        >
          {translate('confirm')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
