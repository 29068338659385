export const FeatureStoreVariablesPTBRTranslate = {
  featureStoreVariablesTitle: 'Variáveis Feature Store',
  featureStoreVariablesDescription:
    'Veja quais variáveis explicativas foram utilizadas da Feature Store para sua variável dependente escolhida:',
  featureStoreVariablesSearchVariables: 'Procurar variável',
  featureStoreVariablesRegion: 'Região',
  featureStoreVariablesPrimT: 'T. Prim.',
  featureStoreVariablesSecT: 'T. Sec.',
  featureStoreVariablesCouldNotFind:
    'Não foi possível encontrar nenhuma variável contendo "xxx".',
  featureStoreVariablesUnableLoad:
    'Não foi possível carregar as variáveis da Feature Store, por favor tente novamente',
  featureStoreVariablesSomeVariables:
    'Não foi possível carregar algumas variáveis da Feature Store, por favor tente novamente.',
  featureStoreVariablesNotFind: `Não foi possível encontrar nenhum variável contendo `,
  featureStoreVariablesIndicatorNotAvailable: 'Indicador não disponível.',
  featureStoreVariablesNoVariables:
    'Sem variáveis da Feature Store no seu projeto.',
};
