export const getTextWidth = (text: string, font: string): number => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  if (context) {
    context.font = font || getComputedStyle(document.body).font;

    return context.measureText(text).width;
  }

  return 0;
};
