import styled from 'styled-components';

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
`;

type CategoriesTitleProps = {
  isActive?: boolean;
};

export const CategoriesTitle = styled.div<CategoriesTitleProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  gap: 0.5rem;

  svg {
    color: ${({ theme, isActive }) =>
      isActive ? theme.colors.gray6 : theme.colors.gray4};
  }

  h5 {
    color: ${({ theme, isActive }) =>
      isActive ? theme.colors.gray6 : theme.colors.gray4};
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375rem;
    letter-spacing: 0.07rem;
  }
`;
