export const AISelectionResultsUnderstandingPTBRTranslate = {
  AISelectionResultsUnderstandingGetInsights: 'Obter Insights!',
  AISelectionResultsUnderstandingTitle: 'Compreendendo o Resultado',
  AISelectionResultsUnderstandingSeeMoreImpacts: 'Veja mais impactos',
  AISelectionResultsUnderstandingSeeLessImpacts: 'Ver menos impactos',
  AISelectionResultsUnderstandingWhenYFrequencyIsInvalid:
    'A interpretação dos resultados para essa agregação está disponível apenas para dados em que a frequência original é mensal. ',
  AISelectionResultsUnderstandingCopyText: 'Copiar texto',
  AISelectionResultsUnderstandingTextCopied: 'Copiado!',
  AISelectionResultsUnderstandingWhenFrequencyIsOriginalLow:
    'A interpretação dos resultados não está disponível para dados com frequência diária, semanal ou quinzenal.',
  AISelectionResultsUnderstandingSeeOtherVariables:
    'Visualizar outras variáveis',
  AISelectionResultsUnderstandingInitialValue: 'Valor inicial',
  AISelectionResultsUnderstandingTotalValue: 'Valor total',
  AISelectionResultsUnderstandingOthers: 'outros',
  AISelectionResultsUnderstandingCards: 'Cartões',
  AISelectionResultsUnderstandingWaterfall: 'Cascata',
};
