import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  flex-direction: column;
  position: relative;

  @media (min-width: 1140px) {
    justify-content: center !important;
    align-items: center;
  }

  .containerLinear {
    position: relative;
    max-width: 1140px;

    @media (min-width: 1140px) {
      min-width: 1140px;
    }
  }
`;

export const CardFeatures = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  svg {
    color: ${({ theme }) => theme.colors.gray4};
    margin-right: 1.5rem;
    cursor: pointer;
    transition: 0.2s color;

    &:hover {
      color: ${({ theme }) => theme.colors.gray5};
    }
  }

  .cardDescription {
    max-width: 61.25rem;
    p {
      word-break: break-word;
    }
  }
`;

export const ContentButtonCreateGroup = styled.div`
  display: flex;
  gap: 1.5rem;
  position: absolute;
  top: 2.5rem;
  right: 1.5rem;
  > div:first-child {
    svg {
      color: ${({ theme }) => theme.colors.gray4};
      margin-left: 0;
    }
    width: 20rem;
  }

  @media (max-width: 900px) {
    > div:first-child {
      width: 12.5rem;
    }
  }

  @media (max-width: 780px) {
    position: unset;
    justify-content: flex-end;
    > div:first-child {
      width: 100%;
    }
    > button:nth-child(2) {
      width: 21rem;
    }
  }
`;

export const Content = styled.div`
  position: relative;
`;

export const ContainerTable = styled.div`
  max-height: 21.875rem;
  position: relative;
  overflow-y: auto;
  ::-webkit-scrollbar-track {
    margin-top: 2rem;
  }

  > table {
    word-break: break-word;
    border-spacing: unset;
    margin: auto;

    > thead {
      > tr {
        position: sticky;
        top: 0px;
        z-index: 99;
        background: ${({ theme }) => theme.colors.white};
        padding: 0.75rem 0 0.75rem 1rem;

        > th {
          p {
            font-weight: 600;
            font-size: 0.75rem;
            line-height: 1rem;
            opacity: 0.8;
          }
        }
      }
    }
    tbody {
      overflow-y: auto;
      > tr {
        &:first-child {
          td {
            padding-top: 1.5rem;
          }
        }
        > td {
          padding: 0.75rem 0 0.75rem 1rem;

          p {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
`;

export const DivDeletePagination = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
