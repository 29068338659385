import styled, { css } from 'styled-components';

type SliderProps = {
  selected: boolean;
  disabled?: boolean;
};

export const Container = styled.div`
  align-items: center;

  border-radius: 8px;
  background: transparent;

  color: ${({ theme }) => theme.colors.gray6};
`;

export const Content = styled(Container)``;

export const DivTabs = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  color: ${({ theme }) => theme.colors.gray6};

  div {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    & + div {
      /* border-left: 1px solid ${({ theme }) => theme.colors.gray3}; */
      border-left: none;
    }
  }
`;

export const Slider = styled.div<SliderProps>`
  width: 100%;

  border-bottom: ${({ selected, theme }) =>
    selected
      ? `2px solid ${theme.colors.primary}`
      : `2px solid ${theme.colors.gray1}`};

  transition: transform 0.5s;

  > button {
    outline: 0;

    width: 100%;
    height: 3rem;

    color: ${({ selected, theme }) =>
      selected ? theme.colors.gray7 : theme.colors.gray4};
    font-weight: ${({ selected }) => (selected ? 'bolder' : 'normal')};
    font-size: 0.875rem;
    line-height: 1.375rem;

    margin: 0.5rem;

    transition: color 0.3s;

    &:hover {
      ${({ disabled, theme, selected }) =>
        !selected &&
        css`
          color: ${disabled ? theme.colors.gray3 : theme.colors.gray5};
        `};
    }

    ${({ disabled, theme }) => css`
      cursor: ${disabled ? `not-allowed` : `pointer`};
      color: ${disabled && theme.colors.gray3};
    `}
  }
`;
