import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;

  img {
    margin-bottom: 0.5rem !important;
  }
`;

export const YsContainer = styled.div`
  width: 100%;
  max-height: 7.5rem;

  text-align: start;

  overflow-y: auto;

  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 8px;

  padding: 1rem;

  p {
    font-size: 0.875rem;

    color: ${({ theme }) => theme.colors.gray5};

    word-wrap: break-word;
  }

  p + p {
    margin-top: 0.5rem;
  }

  ::-webkit-scrollbar {
    width: 0.313rem;
    height: 0.313rem;
    padding: 0.063rem;
  }
`;
