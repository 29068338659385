export const ConfigFiltersPTBRTranslate = {
  configFiltersOptions: 'Opções',
  configFiltersFilter: 'Filtro',
  configFiltersFilterName: 'Nome do filtro',
  configFiltersAdd: 'Adicionar',
  configFiltersGroupVariablesTitle:
    'Agrupar variáveis para cada uma das opções',
  configFiltersSearchVariablePlaceholder: 'Pesquisar variável',
  configFiltersSelectAllVariables: 'Selecionar todas',
  configFiltersSelectAtLeastOneVariable: 'Selecione pelo menos uma variável',
  configFiltersSelectAtLeastOneOption:
    'Selecione uma opção para adicionar as variáveis marcadas',
  configFiltersOptionName: 'Nome da opção',
  configFiltersNoOptionCreated: 'Nenhuma opção criada ainda',
  configFiltersVariableError: 'Variável com erro de modelagem',
  configFiltersDeleteFilterTitle: 'Você tem certeza?',
  configFiltersDeleteFilterDescription:
    'Você perderá todas as configurações do filtro',
  nameAlreadyExists: 'Nome já existe',
};
