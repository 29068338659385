import React, { useState } from 'react';

import { Trash } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Button';
import { Input } from 'src/components/Input';
import { Status } from 'src/components/Status';

import { Modal } from '..';
import { ConfirmDeletionModalProps } from './types';
import { Container } from './styles';
import { ModalFooter } from '../Footer/styles';

export const ConfirmDeletionModal: React.FC<ConfirmDeletionModalProps> = ({
  visible,
  setVisible,
  textToConfirm,
  description,
  errorMessage,
  isLoading,
  handleDelete,
}) => {
  const [inputText, setInputText] = useState<string>();
  const [textError, setTextError] = useState<string>();
  const [confirmationDisabled, setConfirmationDisabled] = useState(true);

  const { t: translate } = useTranslation();

  function validateInputValue(value: string) {
    while (value === textToConfirm.slice(0, value.length)) {
      setTextError(undefined);

      setConfirmationDisabled(value !== textToConfirm);

      return;
    }

    setTextError(errorMessage);
    setConfirmationDisabled(true);
  }

  const handleCancel = () => {
    setInputText(undefined);
    setTextError(undefined);

    setVisible(false);
  };

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <Container data-testid="confirm-deletion-modal">
        <Status
          type="deleteConfirmation"
          title={translate('exclusionTitle')}
          description={description.replace('XXX', textToConfirm)}
          isModal
        />

        <Input
          placeholder={textToConfirm}
          data-testid="input-delete"
          style={{ marginTop: '1.5rem', width: '100%' }}
          value={inputText}
          onChange={(e) => validateInputValue(e.target.value)}
          error={textError}
        />
      </Container>

      <ModalFooter>
        <Button
          buttonType="naked"
          onClick={handleCancel}
          data-testid="cancel-delete-button"
        >
          {translate('exclusionCancelButton')}
        </Button>
        <Button
          buttonType="primary"
          icon={<Trash size="1.125rem" />}
          onClick={handleDelete}
          loading={isLoading}
          data-testid="button-delete-confirm"
          disabled={confirmationDisabled || isLoading}
        >
          {translate('exclusionDeleteButton')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
