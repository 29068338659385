import React from 'react';

import { useTranslation } from 'react-i18next';
import parseHTML from 'html-react-parser';

import { Modal } from '..';
import Error from '../../../assets/error.svg';
import { Button } from '../../Button';
import { ContainerModal, ContentButton } from './styles';

export type ErrorObject = {
  title?: string;
  description?: string;
  cloudIcon?: boolean;
};

type PropsModal = {
  visible?: boolean;
  setVisible: (value: boolean) => void;
  errorInfo?: ErrorObject;
};

export const FailedModal: React.FC<PropsModal> = ({
  visible,
  setVisible,
  errorInfo,
}) => {
  const { t: translate } = useTranslation();
  return (
    <Modal visible={visible} setVisible={setVisible} style={{ padding: 0 }}>
      <ContainerModal data-cy="fail-modal" data-testid="fail-modal">
        <img src={Error} alt="error" data-testid="cloud-error-img" />

        {errorInfo && (
          <>
            <h1>{errorInfo.title}</h1>
            <p>{parseHTML(errorInfo?.description ?? '')}</p>
          </>
        )}

        <ContentButton>
          <Button
            buttonType="primary"
            data-testid="modalFailedButtonOk"
            onClick={() => setVisible(false)}
            data-cy="button-modal-failed-ok"
          >
            {translate('back')}
          </Button>
        </ContentButton>
      </ContainerModal>
    </Modal>
  );
};
