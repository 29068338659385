export const projectOverviewPTBRTranslate = {
  projectOverviewTitle: 'Visão Geral do Projeto',
  projectOverviewDescription:
    'Explore e compare todas as variáveis em seu projeto.',
  projectOverviewTitleTable: 'Variáveis Dependentes',
  projectOverviewFirstColumn: 'Variável Dependente',
  projectOverviewChart: 'gráfico',
  projectOverviewSelectAVariable: 'Selecione uma variável',
  projectOverviewSelectAVariableDescription:
    'Selecione pelo menos uma variável para verificar a comparação das variáveis dependentes do seu projeto.',
  projectOverviewOnly4Variables: 'É possível selecionar apenas 4 variáveis',
  projectOverviewExportTitle: 'Exportação',
  projectOverviewExportDescription:
    'Exporte as série históricas e as projeções das variáveis dependentes do seu projeto para um arquivo .xlsx.',
  projectOverviewExportDownload: 'Exportar',
  projectOverviewExportPreparingData: 'Preparando dados',
  projectOverviewFailedEditTitle: 'Algo deu errado',
  projectOverviewFailedEditDescription:
    'Infelizmente não foi possível customizar sua tabela. Por favor, tente novamente. Se o problema persistir, entre em contato com o suporte.',
  projectOverviewTransformation: 'Transformação',
  projectOverviewModel: 'Modelo',
  projectOverviewCustomizeYourTable: 'Customize sua tabela',
  projectOverviewRenameVariables: 'Renomear variáveis',
  projectOverviewEditDescription:
    'Marque as caixas de seleção para alterar as colunas em sua tabela de variáveis.',
  projectOverviewAdjustSettings: 'Ajustar configurações',
  projectOverviewSummaryTitle: 'Resumo do Projeto',
  projectOverviewSummaryDescription: 'Principais estatísticas do seu projeto.',
  projectOverviewSummaryNumberOfVariables: 'Número de variáveis',
  projectOverviewSummaryNumberOfEstimatedModels: 'Número de modelos estimados',
  projectOverviewSummaryBestAccuracy: 'Menor erro',
  projectOverviewSummaryWorstAccuracy: 'Maior erro',
  projectOverviewSummaryProjectAccuracyAverage: 'Erro médio do projeto',
  projectOverviewSummaryInformation:
    'Informações apresentadas apenas para projetos recentes. ',
  projectOverviewRenameVariableTitle: 'Renomear Variáveis',
  projectOverviewRenameVariableDescription:
    'Renomeie as variáveis do seu projeto como desejar.',
  projectOverviewRenameVariableErrorTitle: 'Algo deu errado',
  projectOverviewRenameVariableErrorDescription:
    'Infelizmente não foi possível renomear as variáveis. Por favor, tente novamente. Se o problema persistir, entre em contato com o suporte.',
  projectOverviewRenameVariableMessageInput3Caracteres:
    'Deve ter ao menos 3 caracteres',
  projectOverviewRenameVariableInputMax50Caracteres:
    'Não deve ter mais do que 50 caracteres',
  projectOverviewRenameVariableInputInvalid:
    'Caracteres especiais não são permitidos',
  projectOverviewRenameVariableInputRepeated: 'Não deve ser repetido',
  projectOverviewRenameVariableInputSameAnotherOriginalName:
    'Não deve ser igual ao nome original de outra variável',
  projectOverviewTooltipExport:
    'O arquivo exportado não é apresentado no mesmo padrão que está na tela.',
  projectOverviewSearchDependentVariablePlaceholder:
    'Procurar variáveis dependentes',
  projectOverviewSearchDependentVariableNotFound: `Não foi possível encontrar nenhuma variável dependente contendo`,
};
