export function truncateStrings(
  values: string[],
  canHaveSameName = false,
  quantity = 27,
): string[] {
  const truncanteArray = values.map((string) => {
    // faz o truncate normal da string
    if (string.length > 30) {
      return `${string.substring(0, quantity)}...`;
    }
    return string;
  });

  if (canHaveSameName) {
    // verifica se pode ter nome repetido se nao retorna ja
    return truncanteArray;
  }

  const hasSomeStringSameName = truncanteArray.some(
    // verifica se tem mesmo string com mesmo nome depois do truncate
    (truncateString, index) =>
      truncanteArray.slice(index).includes(truncateString),
  );

  if (!hasSomeStringSameName) {
    // senao tem retorna ja
    return truncanteArray;
  }

  const truncanteArrayAdjustSameName = truncanteArray.map(
    // faz os ajustes necessarios
    (trucanteString, index) => {
      const sameNameIndex = truncanteArray.findIndex(
        // procura o index da string q tem mesmo nome
        (trucanteStringAux, indexAux) =>
          trucanteString === trucanteStringAux && index !== indexAux,
      );

      if (sameNameIndex === -1) {
        // se nao encontrou quer dizer q nao e essa string
        return trucanteString;
      }

      const findDiferenceIndex = values[index] // procura qual caractere tem de diferença
        .split('')
        .findIndex(
          (chart, indexAux) =>
            chart !== values[sameNameIndex].split('')[indexAux],
        );

      if (findDiferenceIndex === -1) {
        // nao tem nenhum caracter difente entao nao pode fazer nada entao ja retorna
        return trucanteString;
      }

      if (findDiferenceIndex === values[index].length - 1) {
        // verifica se o caracter de diferença e o ultimo se ss entao retorna depois dos ...
        return trucanteString + values[index].split('')[findDiferenceIndex];
      }

      // se nao e o ultimo entao coloca no meio da string para ficar string...a...
      return `${
        trucanteString + values[index].split('')[findDiferenceIndex]
      }...`;
    },
  );

  // retorna
  return truncanteArrayAdjustSameName;
}
