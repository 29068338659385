import styled from 'styled-components';

export const ContainerCard = styled.div`
  position: relative;

  display: flex;
  align-items: center;

  border-radius: 8px;
  background: transparent;

  margin-bottom: 1.5rem;

  h2 {
    color: ${({ theme }) => theme.colors.gray6};
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
  }

  p {
    margin-top: 0.5rem;
    color: ${({ theme }) => theme.colors.gray5};
    font-weight: 400;
    font-size: 1rem !important;
    line-height: 150%;
    white-space: pre-wrap;
  }

  b {
    color: ${({ theme }) => theme.colors.gray6};
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }

  @media (max-width: 400px) {
    flex-direction: column;
    margin-bottom: 0;

    > button {
      width: 100%;
      margin-top: 1.5rem;
    }
  }
`;

export const Separator = styled.div`
  width: 6.125rem !important;

  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 5px;

  background: ${({ theme }) => theme.colors.primary};

  margin-top: 0.5rem;
`;
