import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
  gap: 2rem;
  position: relative;

  background: ${({ theme }) => theme.colors.white};

  p {
    color: ${({ theme }) => theme.colors.gray4};

    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
  }

  .mappin {
    margin-left: 0.125rem !important;
    color: ${({ theme }) => theme.colors.gray4} !important;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;

  h5 {
    color: ${({ theme }) => theme.colors.gray6};
    font-family: Inter;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const IndicatorFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

export const IndicatorFilterHead = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  h3 {
    color: ${({ theme }) => theme.colors.gray4};
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 1.12px;
    text-transform: uppercase;
  }
`;

export const IndicatorFilterButtonContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

type IndicatorFilterProps = {
  isActive: boolean;
};

const IndicatorFilterButton = styled.button<IndicatorFilterProps>`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;

  border-radius: 8px;

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  text-transform: capitalize;

  border: 1px solid
    ${({ isActive, theme }) => (isActive ? 'none' : theme.colors.gray2)};

  padding: ${({ isActive }) => (isActive ? '9px' : '8px')};
`;

export const FreeButton = styled(IndicatorFilterButton)`
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.primary : theme.colors.gray4};

  background: ${({ isActive }) =>
    isActive ? 'rgba(76, 148, 255, 0.12)' : 'none'};
`;

export const BusinessButton = styled(IndicatorFilterButton)`
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.teal4 : theme.colors.gray4};

  background: ${({ isActive }) =>
    isActive ? 'rgba(56, 178, 172, 0.12)' : 'none'};
`;

export const SelectCountryFilter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100% !important;
  gap: 0.5rem;
  align-self: stretch;

  > div:first-child {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  h3 {
    color: ${({ theme }) => theme.colors.gray4};
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 1.12px;
    text-transform: uppercase;
  }
`;
