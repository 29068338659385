export const AISelectionResultPTTranslate = {
  AISelectionResultTitle: 'Resultados',
  AISelectionResultDescription:
    'Selecione um período histórico para obter mais detalhes dos resultados da modelagem.',
  AISelectionResultFrequency: 'Frequência',
  AISelectionResultTransformation: 'Transformação',
  AISelectionResultOriginal: 'Original',
  AISelectionResultMonthly: 'Mensal',
  AISelectionResultQuarterly: 'Trimestral',
  AISelectionResultAnnual: 'Anual',
  AISelectionResultLevel: 'Nível',
  AISelectionResultVariation: 'Variação',
  AISelectionResultLatestData: 'Dados recentes',
  AISelectionResultsVariationOriginal:
    'Variação (comparado ao período anterior)',
  AISelectionResultsVariationMonthlyQuarterly:
    'Variação (comparado ao mesmo período do ano anterior)',
  AISelectionResultsVariationAnnual: 'Variação (comparado ao ano anterior)',
  AISelectionMonthlyAggregationIsNotAvailable:
    'A agregação de série mensal não está disponível para dados com valores faltantes.',
  AISelectionResultsNotEnoughObservationsVariation:
    'Não há observações suficientes para calcular a variação.',
  AISelectionResultsThisFeatureTemporarilyUnavailable:
    'Este recurso está temporariamente indisponível.',
};
