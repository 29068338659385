import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type DataSetState = {
  id: string | null;
  name: string | null;
  var_y: string | null;
  date_var: string | null;
  date_format: string | null;
  var_x: string[];
};

const initialState: DataSetState = {
  id: null,
  name: null,
  var_y: null,
  date_var: null,
  date_format: null,
  var_x: [],
};

export const datasetState = createSlice({
  name: 'dataset',
  initialState,
  reducers: {
    insert: (state, action: PayloadAction<DataSetState>) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.var_y = action.payload.var_y;
      state.date_var = action.payload.date_var;
      state.date_format = action.payload.date_format;
      state.var_x = action.payload.var_x;
    },

    remove: (state) => {
      state.id = null;
      state.name = null;
      state.var_y = null;
      state.date_var = null;
      state.date_format = null;
      state.var_x = [];
    },
  },
});

export const { insert, remove } = datasetState.actions;

export default datasetState.reducer;
