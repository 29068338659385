export const PanelAdminPTBRTranslate = {
  PanelAdminTitle: 'Painel de Administração',
  PanelAdminDescription:
    'Veja e edite a situação dos usuários a depender das licenças disponíveis',
  PanelAdminQuantityLicensePlural:
    'Você está usando <b>XXX</b> das YYY licenças disponíveis ',
  PanelAdminQuantityLicenseSingular:
    'Você está usando <b>XXX</b> de YYY licença disponível',
  PanelAdminInviteNewUser: 'Convidar Novo Usuário',
  PainelAdminNamePagination: 'usuários',
  PainelAdminErrorLoad: 'Não foi possível carregar os usuários.',
};
