import {
  addDays,
  addMonths,
  format,
  isAfter,
  isBefore,
  parseISO,
  subDays,
  subMonths,
  subYears,
} from 'date-fns';
import { frequencyLatestData } from 'src/utils/charts/getLatestData';

interface ChartData {
  dates: string[];
  values: number[];
  label: string;
}

const QUANTITY_OBSERVATIONS_BEFORE_MEETING = 4;

export const getLatestDataHistoricalFourMonthly = (
  data: ChartData[] | undefined,
  frequency:
    | 'daily'
    | 'weekly'
    | 'fortnightly'
    | 'monthly'
    | 'bimonthly'
    | 'quarterly'
    | 'half-year'
    | 'annual'
    | 'yearly',
): ChartData[] => {
  if (data) {
    const frequencyCount = frequencyLatestData[frequency];

    let latestData = data[0].dates[data[0].dates.length - 1];

    data.slice(1).forEach((info) => {
      const meetingDate = info.dates.find((date) =>
        data[0].dates.includes(date),
      );

      if (
        meetingDate &&
        isBefore(parseISO(meetingDate), parseISO(latestData))
      ) {
        latestData = meetingDate;
      }
    });

    let startDate = '';
    let endDate = '';

    switch (frequency) {
      case 'daily':
        startDate = format(
          subDays(parseISO(latestData), QUANTITY_OBSERVATIONS_BEFORE_MEETING),
          'yyyy-MM-dd',
        );
        break;
      case 'weekly':
        startDate = format(
          subDays(parseISO(latestData), QUANTITY_OBSERVATIONS_BEFORE_MEETING),
          'yyyy-MM-dd',
        );
        endDate = format(
          addDays(
            parseISO(latestData),
            frequencyCount * 7 - QUANTITY_OBSERVATIONS_BEFORE_MEETING + 1,
          ),
          'yyyy-MM-dd',
        );
        break;
      case 'fortnightly':
        startDate = format(
          subDays(parseISO(latestData), QUANTITY_OBSERVATIONS_BEFORE_MEETING),
          'yyyy-MM-dd',
        );
        endDate = format(
          addDays(
            parseISO(latestData),
            frequencyCount * 15 - QUANTITY_OBSERVATIONS_BEFORE_MEETING + 1,
          ),
          'yyyy-MM-dd',
        );
        break;
      case 'monthly':
        startDate = format(
          subMonths(parseISO(latestData), QUANTITY_OBSERVATIONS_BEFORE_MEETING),
          'yyyy-MM-dd',
        );
        endDate = format(
          addMonths(
            parseISO(latestData),
            frequencyCount - QUANTITY_OBSERVATIONS_BEFORE_MEETING + 1,
          ),
          'yyyy-MM-dd',
        );
        break;
      case 'bimonthly':
        startDate = format(
          subMonths(parseISO(latestData), QUANTITY_OBSERVATIONS_BEFORE_MEETING),
          'yyyy-MM-dd',
        );
        endDate = format(
          addMonths(
            parseISO(latestData),
            frequencyCount * 2 - QUANTITY_OBSERVATIONS_BEFORE_MEETING + 1,
          ),
          'yyyy-MM-dd',
        );
        break;
      case 'quarterly':
        startDate = format(
          subMonths(parseISO(latestData), QUANTITY_OBSERVATIONS_BEFORE_MEETING),
          'yyyy-MM-dd',
        );
        endDate = format(
          addMonths(
            parseISO(latestData),
            frequencyCount * 3 - QUANTITY_OBSERVATIONS_BEFORE_MEETING + 1,
          ),
          'yyyy-MM-dd',
        );
        break;
      case 'half-year':
        startDate = format(
          subMonths(parseISO(latestData), QUANTITY_OBSERVATIONS_BEFORE_MEETING),
          'yyyy-MM-dd',
        );
        endDate = format(
          addMonths(
            parseISO(latestData),
            frequencyCount * 6 - QUANTITY_OBSERVATIONS_BEFORE_MEETING + 1,
          ),
          'yyyy-MM-dd',
        );
        break;
      case 'annual':
        startDate = format(
          subYears(parseISO(latestData), QUANTITY_OBSERVATIONS_BEFORE_MEETING),
          'yyyy-MM-dd',
        );
        endDate = format(
          addMonths(
            parseISO(latestData),
            frequencyCount - QUANTITY_OBSERVATIONS_BEFORE_MEETING + 1,
          ),
          'yyyy-MM-dd',
        );
        break;
      case 'yearly':
        startDate = format(
          subYears(parseISO(latestData), QUANTITY_OBSERVATIONS_BEFORE_MEETING),
          'yyyy-MM-dd',
        );
        endDate = format(
          addMonths(
            parseISO(latestData),
            frequencyCount - QUANTITY_OBSERVATIONS_BEFORE_MEETING + 1,
          ),
          'yyyy-MM-dd',
        );
        break;
      default:
        break;
    }

    const result = data.map((info) => {
      let startIndex = -1;
      let endIndex = -1;

      startIndex = info.dates.findIndex((date) =>
        isAfter(parseISO(date), parseISO(startDate)),
      );

      for (let i = info.dates?.length - 1; i > 0 && endIndex === -1; i--) {
        if (isBefore(parseISO(info.dates[i]), parseISO(endDate))) {
          endIndex = i;
        }
      }

      if (startIndex > endIndex) {
        endIndex = startIndex;
      }

      return {
        dates: info.dates.slice(startIndex, endIndex + 1),
        values: info.values.slice(startIndex, endIndex + 1),
        label: info.label,
      };
    });

    return result;
  }

  return [];
};
