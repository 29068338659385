import React, { useEffect, useState } from 'react';

import { List, PencilSimple, Plus, Trash, Users } from 'phosphor-react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { Card } from 'src/components/Card';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Head } from 'src/components/Head';
import { Pagination } from 'src/components/Pagination';
import { Table, Tbody, Td, Th, Thead, Tr } from 'src/components/Table';
import api from 'src/feature-store/service/api';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { TrComplete } from 'src/models/TimeSeries/ProjectOverview/components/Projections/styles';

import { CreateGroupPremium } from './CreateGroupPremium';
import {
  ButtonIcon,
  Container,
  ContainerTable,
  Content,
  ContentButtonCreateGroup,
  DivButtonActions,
} from './styles';
import { ModalDeleteGroup } from './components/ModalDeleteGroup';
import { EditGroupPremium } from './components/ModalEditGroup';

const QUANTITY_ITEMS_PAGE = 10;

export type DataIndicatorsPremium = {
  id: string;
  access_type: string;
  name: {
    'pt-br': string;
    'en-us': string;
  };
  description: {
    'pt-br': string;
    'en-us': string;
  };
  created_at?: string;
  created_by?: string;
  updated_at?: string;
  updated_by?: string;
  icon_url?: string;
};

export type IndicatorsPremium = {
  limit: number;
  skip: number;
  total: number;
  data: DataIndicatorsPremium[];
};

export type GroupProps = {
  id: string;
  delete: boolean;
};

export const GroupPremium: React.FC = () => {
  const [pages, setPages] = useState<number>(1);
  const [modalCreateGroupPremium, setModalCreateGroupPremium] =
    useState<boolean>(false);

  const [modalDeleteGroup, setModalDeleteGroup] = useState<GroupProps>();
  const [modalEditGroup, setModalEditGroup] = useState<boolean>(false);
  const [infoEditGroup, setInfoEditGroup] = useState<DataIndicatorsPremium>();
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const { language } = useSelector((state: RootState) => state.auth.user);

  const {
    data: groupsPremium,
    isLoading,
    isFetching,
    isError,
  } = useQuery(
    ['groupsPremium', pages],
    async () => {
      const { data } = await api.get<IndicatorsPremium>(
        `/access-groups?skip=${
          (pages - 1) * QUANTITY_ITEMS_PAGE
        }&limit=${QUANTITY_ITEMS_PAGE}`,
      );
      return data;
    },
    {
      staleTime: 1000 * 60 * 20,
    },
  );

  useEffect(() => {
    setPages(1);
  }, [setPages]);

  return (
    <Container data-testid="container-group-premium">
      <Head title={translate('accessGroupTitle')} />
      <Card
        textCard={translate('accessGroupTitle')}
        textDescription={translate('accessGroupDescription')}
        className="containerLinear"
      />

      {isError ? (
        <Content className="containerLinear">
          <ContainerMaintenance
            data-testid="container-maintenance"
            content="table"
            text={translate('accessErrorTitle')}
            description={translate('accessErrorDescription')}
          />
        </Content>
      ) : groupsPremium?.data.length === 0 ? (
        <>
          <Content className="containerLinear">
            <Card
              textCard={translate('accessGroupList')}
              textDescription={translate('accessGroupList')}
            />
            <ContentButtonCreateGroup>
              <Button
                buttonType="primary"
                icon={<Plus size="1.125rem" />}
                onClick={() => setModalCreateGroupPremium(true)}
                data-testid="button-create-new-group-premium"
                data-cy="button-create-new-group-premium"
                disabled={isLoading || isFetching || !groupsPremium || isError}
              >
                {translate('accessGroupCreateGroup')}
              </Button>
            </ContentButtonCreateGroup>
          </Content>
          <Content className="containerLinear">
            <ContainerMaintenance
              data-testid="container-maintenance"
              content="table"
              text={translate('accessNoGroupTitle')}
              description={translate('accessNoGroupDescription')}
            />
          </Content>
        </>
      ) : (
        <>
          <Content className="containerLinear">
            <Card
              textCard={translate('accessGroupList')}
              textDescription={translate('accessGroupList')}
            />
            <ContentButtonCreateGroup>
              <Button
                buttonType="primary"
                icon={<Plus size="1.125rem" />}
                onClick={() => {
                  setModalCreateGroupPremium(true);
                }}
                data-testid="button-create-new-group-premium"
                data-cy="button-create-new-group-premium"
                disabled={isLoading || isFetching || !groupsPremium || isError}
              >
                {translate('accessGroupCreateGroup')}
              </Button>
            </ContentButtonCreateGroup>
            <ContainerTable>
              <Table data-testid="table-group-premium">
                <Thead>
                  <Tr>
                    <Th>
                      <p>{translate('accessName')}</p>
                    </Th>
                    <Th>
                      <p>{translate('accessDescription')}</p>
                    </Th>
                    <Th
                      style={{
                        width: language === 'en-us' ? '369px' : '432px',
                        paddingRight: '8px',
                      }}
                    >
                      <p>{translate('accessActions')}</p>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {isLoading || isFetching || !groupsPremium
                    ? Array.from({ length: 10 }, (_, numberLoadingTr) => (
                        // eslint-disable-next-line react/jsx-indent
                        <Tr
                          key={`loading-tr-${numberLoadingTr + 1}`}
                          data-testid={`loading-tr-${numberLoadingTr + 1}`}
                        >
                          {Array.from({ length: 2 }, (__, numberLoadingTd) => (
                            // eslint-disable-next-line prettier/prettier
                            <Td
                              key={`loading-td-${numberLoadingTr + 1}-${
                                numberLoadingTd + 1
                              }`}
                              data-testid={`loading-td-${numberLoadingTr + 1}-${
                                numberLoadingTd + 1
                              }`}
                            >
                              <ContainerSkeleton
                                withLoading={false}
                                style={{
                                  height: '21px',
                                }}
                              />
                            </Td>
                          ))}
                          <Td style={{ display: 'flex', flexDirection: 'row' }}>
                            <ContainerSkeleton
                              withLoading={false}
                              style={{
                                height: '20px',
                                width: '100px',
                              }}
                            />
                            <ContainerSkeleton
                              withLoading={false}
                              style={{
                                height: '20px',
                                width: '100px',
                                marginLeft: '30px',
                              }}
                            />
                            <ContainerSkeleton
                              withLoading={false}
                              style={{
                                height: '20px',
                                width: '100px',
                                marginLeft: '30px',
                              }}
                            />
                          </Td>
                        </Tr>
                      ))
                    : groupsPremium?.data.map((group) => (
                        // eslint-disable-next-line react/jsx-indent
                        <Tr
                          key={group.id}
                          data-testid={group.id}
                          data-cy={group.id}
                        >
                          <Td>{group.name[language] ?? group.name['en-us']}</Td>
                          <Td>
                            {group.description[language] ??
                              group.description['en-us']}
                          </Td>
                          <Td
                            style={{
                              width: language === 'en-us' ? '369px' : '432px',
                              paddingRight: '8px',
                            }}
                          >
                            <DivButtonActions>
                              <ButtonIcon
                                data-testid={`button-go-to-indicators-${group.id}`}
                                data-cy={`button-go-to-indicators-${group.id}`}
                                onClick={() =>
                                  navigate(
                                    `/feature-store/admin/groups-premium/${group.id}/indicators/`,
                                  )
                                }
                              >
                                <List size="1.25rem" />
                                <p>{translate('accessIndicatorsList')}</p>
                              </ButtonIcon>
                              <ButtonIcon
                                data-testid={`button-go-to-user-${group.id}`}
                                data-cy={`button-go-to-user-${group.id}`}
                                style={{ marginLeft: '35px' }}
                                onClick={() =>
                                  navigate(
                                    `/feature-store/admin/groups-premium/${group.id}/users/`,
                                  )
                                }
                              >
                                <Users size="1.25rem" />
                                <p>{translate('AccessClients')}</p>
                              </ButtonIcon>
                              <ButtonIcon
                                data-cy="teste"
                                style={{ marginLeft: '35px' }}
                              >
                                <PencilSimple
                                  size="1.25rem"
                                  data-testid={`button-edit-group-${group.id}`}
                                  onClick={() => {
                                    setModalEditGroup(true);
                                    setInfoEditGroup({
                                      id: group.id,
                                      access_type: group.access_type,
                                      icon_url: group.icon_url,
                                      name: {
                                        'en-us': group.name['en-us'],
                                        'pt-br': group.name['pt-br'],
                                      },
                                      description: {
                                        'en-us': group.description['en-us'],
                                        'pt-br': group.description['pt-br'],
                                      },
                                    });
                                  }}
                                />
                                <Trash
                                  size="1.25rem"
                                  style={{ marginLeft: '20px' }}
                                  data-testid={`button-delete-group-${group.id}`}
                                  onClick={() =>
                                    setModalDeleteGroup({
                                      delete: true,
                                      id: group.id,
                                    })
                                  }
                                />
                              </ButtonIcon>
                            </DivButtonActions>
                          </Td>
                        </Tr>
                      ))}

                  {Array.from(
                    {
                      length:
                        QUANTITY_ITEMS_PAGE -
                        (groupsPremium?.data.length
                          ? groupsPremium?.data.length
                          : 10),
                    },
                    (_, index) => (
                      <TrComplete key={`tr-complete-${index.toString()}`} />
                    ),
                  )}
                </Tbody>
              </Table>
            </ContainerTable>

            {!!groupsPremium?.total && (
              <Pagination
                page={pages}
                setPage={setPages}
                total={groupsPremium.total}
                quantityItemsPerPage={QUANTITY_ITEMS_PAGE}
                name={translate('accessGroup')}
              />
            )}
          </Content>
        </>
      )}

      {modalCreateGroupPremium && (
        <CreateGroupPremium
          visible={modalCreateGroupPremium}
          setVisible={setModalCreateGroupPremium}
        />
      )}

      {modalDeleteGroup?.delete && (
        <ModalDeleteGroup
          setVisible={setModalDeleteGroup}
          group={modalDeleteGroup}
        />
      )}

      {modalEditGroup && infoEditGroup && (
        <EditGroupPremium
          visible={modalEditGroup}
          setVisible={setModalEditGroup}
          groupEditInfo={infoEditGroup}
        />
      )}
    </Container>
  );
};
