import styled, { css } from 'styled-components';

export const ContentModal = styled.div`
  width: 47rem;
  max-height: 70vh;
  overflow-y: auto;
`;

export const ContentInputsModal = styled.div`
  padding: 0.5rem 0.5rem 0;
  margin-right: 0.5rem;
  h4 {
    font-size: 1.5rem;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.gray6};
    margin-bottom: 1.5rem;
  }
  > p {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.gray5};
    margin-bottom: 2rem;
  }
  > div {
    margin-bottom: 1.5rem;
  }
`;

export const IconLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem !important;
  width: 8.25rem;
  color: ${({ theme }) => theme.colors.gray5};

  p {
    font-weight: 500;
    font-size: 0.875rem;
  }
`;

type IconContainerProps = {
  disabled?: boolean;
};

export const IconContainer = styled.div<IconContainerProps>`
  width: 5rem;
  height: 5rem;

  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.colors.gray2}`};

  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'not-allowed')};

  transition: all 0.2s;

  img {
    border-radius: 7px;
    width: 100%;
    height: 100%;
    &:hover {
      border-radius: 6px;
    }
  }

  &:hover {
    ${({ disabled }) =>
      !disabled &&
      css`
        border: 2px solid ${({ theme }) => theme.colors.secondary};
      `}
  }
`;

export const DivButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 1.5rem;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(76, 148, 255, 0.08);
    border-radius: 24px;
    width: 2.5rem;
    height: 2.5rem;

    > svg {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const DivIndicatorsSelected = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1.25rem 1.25rem 1.25rem 1.5rem;
  overflow-y: auto;
  gap: 1rem;
  width: 100%;
  max-height: 9.375rem;
  border: 1px solid ${({ theme }) => theme.colors.gray3};
  border-radius: 8px;
  box-sizing: border-box;
  flex-wrap: wrap;
`;

export const ItemSelected = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(76, 148, 255, 0.12);
  border-radius: 8px;
  max-height: 1.875rem;
  white-space: nowrap;

  p {
    font-weight: 500;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.primary};
    padding: 0.25rem 0.5rem;
    margin-right: 0.75rem;
  }

  svg {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
    margin-right: 0.625rem;
  }
`;
