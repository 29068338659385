const modelSpecificsPTBRTranslations = {
  modelSpecTitle: 'Detalhamento do Modelo',
  modelSpecDescription:
    'Explore informações relevantes sobre um modelo específico.',
  modelInfoTitle: 'Informações do Modelo',
  modelSpecificsCoef: 'Coeficiente',
  modelSpecificsPValue: 'Valor-P',
  modelSpecArimaOrder: 'Ordem do ARIMA',
  modelSpecificsSpecification: 'Especificação do Modelo',
  modelSpecCheckResiduals: 'Análise dos Resíduos',
  modelSpecConfidenceInterval: 'Intervalo de confiança',
  modelSpecLagChartLabel: 'Defasagem',
  modelSpecResidualsHistogramLabel: 'Resíduos',
  modelSpecSeriesResiduals: 'Resíduos',
  modelSpecResidualsChartLabel: 'Residuals',
  modelSpecResidualsCountChartLabel: 'Contagem',
  modelSpecModelFitTitle: 'Histórico e Ajuste do Modelo',
  modelSpecActualForecastTitle: 'Histórico e Projeção',
  modelSpecHistorical: 'Histórico',
  modelSpecForecast: 'Projeção',
  modelSpecAnnualVariationTitle: 'Variação Anual (%)',
  modelSpecAnnualLevelTitle: 'Nível Anual',
  modelBreakdownTitle: 'Entendendo os Resultados',
  modelBreakdownDescription:
    'Decomponha os resultados históricos com base nos principais determinantes identificados pelo seu modelo preferido.',
  modelBreakdownInfo: `Os gráficos mostram as variações percentuais das séries (linhas e pontos) desagregadas em componentes principais (barras), de forma que a soma desta última seja equivalente à primeira. As variáveis de fluxo são o oposto das variáveis de estoque. <br/> Tecnicamente, as variáveis de fluxo são medidas em um determinado período de tempo, nunca o transcendendo. <br/>Exemplos: o PIB é uma variável de fluxo, pois é medido em trimestres, semestres ou anos, enquanto a população é uma variável de estoque, pois de um mês para o próximo o estoque populacional não é zerado. `,
  modelBreakdownYVariation: 'Variação do Y',
  modelSpecificsVarImportanceTitle: 'Importância das Variáveis',
  modelSpecificsVarImportanceLabel: 'Mostrar n variáveis',
  modelSpecificsVarImportanceExplVars: 'Variáveis explicativas',
  modelSpecificsArimaBreakdownIsNotAvailableFirst3Models:
    'Visualização disponível apenas para os 3 primeiros modelos arima.',
  modelSpecificsArimaBreakdownIsNotAvailableDailyWeeklyFortnightly:
    'Visualização não disponível para dados com frequência diária, semanal ou quinzenal.',
  modelSpecificsArimaBreakdownOnlyAvailableForMonthlyData:
    'Disponível apenas para dados mensais.',

  modelSpecErrors: 'XXX não está disponível para este modelo',
  modelSpecAnnualLevel: 'Nível Anual',
  modelSpecAnnualVariation: 'Variação Anual',
  modelSpecModelBreakdown: 'Conteúdo',
  textDataType: 'Tipo de Dados',
  textTitleAxisIncreaseOfTheMeanSquaredError:
    'Aumento do erro quadrático médio',
};

export default modelSpecificsPTBRTranslations;
