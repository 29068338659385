import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { HCharts, HChartsOptions, HChartsSeries } from 'src/components/HCharts';
import light from 'src/styles/themes/light';
import { formatCompactNotation } from 'src/utils/numbers/formatCompactNotation';
import { getChartColor } from 'src/utils/colors/getChartColor';

interface ResiduaslsAchChartProps {
  acf: {
    x: number[];
    y: number[];
    type: string;
    confidence_interval: string[];
  };
}

export const ResidualsAcfChart: React.FC<ResiduaslsAchChartProps> = ({
  acf,
}) => {
  const { t: translate } = useTranslation();

  //@ts-expect-error:ts bugando
  const series: HChartsSeries[] = useMemo(
    () =>
      [
        {
          name: 'ACF',
          type: 'scatter',
          color: getChartColor(0),
          showInLegend: false,
          data: acf.x.map((x, xIndex) => ({
            x,
            y: acf.y[xIndex],
            custom: {
              value: formatCompactNotation(acf.y[xIndex], 3),
            },
          })),
        },
        {
          name: 'ACF',
          type: 'column',
          color: getChartColor(0),
          showInLegend: false,
          data: acf.x.map((x, xIndex) => ({
            x,
            y: acf.y[xIndex],
            custom: {
              value: formatCompactNotation(acf.y[xIndex], 3),
            },
          })),
        },
        acf?.confidence_interval
          ? {
              type: 'line',
              name: translate('modelSpecConfidenceInterval'),
              color: getChartColor(1),
              dashStyle: 'Dash',
              marker: {
                enabled: false,
                symbol: 'circle',
              },
              showInLegend: false,
              data: acf.x.map((x, xIndex) => ({
                x,
                y: Number(acf?.confidence_interval[0]),
                custom: {
                  value: formatCompactNotation(acf.y[xIndex], 3),
                },
              })),
            }
          : undefined,
        acf?.confidence_interval
          ? {
              type: 'line',
              name: translate('modelSpecConfidenceInterval'),
              color: getChartColor(1),
              dashStyle: 'Dash',
              marker: {
                enabled: false,
                symbol: 'circle',
              },
              showInLegend: false,
              data: acf.x.map((x, xIndex) => ({
                x,
                y: Number(acf?.confidence_interval[1]),
                custom: {
                  value: formatCompactNotation(acf.y[xIndex], 3),
                },
              })),
            }
          : undefined,
      ].filter((serie) => serie !== undefined),
    [acf, translate],
  );

  const options: HChartsOptions = useMemo(
    () => ({
      chart: {
        height: 300,
      },
      title: {
        text: translate('modelSpecSeriesResiduals'),
        align: 'left',
        x: 36,
        style: {
          color: light.colors.gray6,
        },
      },
      yAxis: {
        allowDecimals: true,
        title: {
          text: 'ACF',
        },
      },
      xAxis: {
        type: 'linear',
        minTickInterval: undefined,
        title: {
          text: translate('modelSpecLagChartLabel'),
        },
      },
      tooltip: {
        pointFormat:
          `<tr><td><b>${translate('modelSpecLagChartLabel')}:</b> </td>` +
          `<td style="text-align: right">{point.custom.value}</td></tr>`,
      },
      plotOptions: {
        series: {
          states: {
            inactive: {
              opacity: 1,
            },
          },
        },
        column: {
          pointWidth: 2,
        },
      },
    }),
    [translate],
  );

  return (
    <>
      <HCharts
        series={series}
        options={options}
        dataCy="chart-check-residual-2"
        resizeWidthWithSidebar
        sleepTimerToResize={false}
        animationToResize
        removeWidthToResize={20 * 16 + 28}
      />
    </>
  );
};
