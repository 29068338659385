const featureSelectionENUSTranslations = {
  featSelectionTitle: 'Feature selection activated!',
  featSelectionDescr: `Given the number of explanatory features, trying every possible
  combination would not be the smartest approach: several unnecessary
  operations would consume a lot of time to process.`,
  featSelectionDescrCont: `But don't worry! Our feature selection algorithms will reduce the
  problem dimensionality, guaranteeing the best results in a much more
  efficient way.`,
};

export default featureSelectionENUSTranslations;
