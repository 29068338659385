export const CreateWorkspacePTBRTranslations = {
  step1: 'Informações básicas',
  step2: 'Selecionar variáveis',
  step3: 'Configurar variáveis',
  step4: 'Configurar categorização',

  createWorkspaceHeadTitle: 'Criar Workspace',
  editWorkspaceHeadTitle: 'Editar Workspace',
  createWorkspaceCreateTitle: 'Crie seu Workspace',
  editWorkspaceEditTitle: 'Edite seu Workspace',
  createWorkspaceCreateDescription: 'Preencha as informações básicas.',
  createWorkspaceSelectVariablesTitle: 'Selecione as Variáveis',
  createWorkspaceSelectVariablesDescription:
    'Selecione projetos ou grupos de séries inseridas por você na plataforma para compor o seu Workspace.',
  createWorkspaceConfigureVariablesTitle: 'Configure seu Workspace',
  createWorkspaceConfigureVariablesDescription:
    'Defina quais variáveis farão parte do seu Workspace e configure modelos e updates que farão parte do seu resultado.',
  createWorkspaceIcon: 'Ícone',
  createWorkspaceIconTooltip:
    'Esse ícone vai te ajudar a identificar rapidamente o seu Workspace.',
  createWorkspaceIconModalTitle: 'Escolha seu ícone',
  createWorkspaceIconModalDescription:
    'Esse ícone vai te ajudar a identificar rapidamente o seu Workspace.',
  createWorkspaceName: 'Nome',
  createWorkspaceNamePlaceholder: 'Digite o nome do Workspace',
  createWorkspaceDescription: 'Descrição',
  createWorkspaceDescriptionPlaceholder:
    'Escreva uma descrição para seu Workspace',
  createWorkspaceCreate: 'Criar Workspace',
  createWorkspaceDescriptionErrorMaxCharacters:
    'Não deve ter mais do que 350 caracteres',
  createWorkspaceNoProjectSelectedTitle: 'Você não selecionou nenhum projeto',
  createWorkspaceNoProjectSelectedDescription:
    'Clique no botão abaixo e selecione um ou mais projetos que você criou anteriormente.',
  createWorkspaceSelectProjectsButton: 'Selecionar projetos',
  createWorkspaceAllProjects: 'Todos os projetos',
  createWorkspaceAddNewProject: 'Adicionar novo projeto',
  createWorkspaceOriginalVariable: 'Nome Original',
  createWorkspaceVariable: 'Variável',
  createWorkspaceProjectTag: 'Projeto / Tag',
  createWorkspaceMyProjectsLabel: 'Meus projetos',
  createWorkspaceMySeriesLabel: 'Minhas séries',
  createWorkspaceRepeatedVariable:
    'Essa variável se repete em outro projeto, por favor renomeie-a',
  createWorkspaceVariableNameError:
    'Por favor, verifique se há alguma variável com nome inválido.',
  createWorkspaceVariableSelectMoreThan1000Warning:
    'Você selecionou mais de 1000 variáveis. Para continuar, você deve desselecionar algumas delas.',
  createWorkspaceVariableSelectMoreThan1000:
    'Você ultrapassou o limite de 1000 variáveis por Workspace. Desselecione algumas delas para continuar.',
  createWorkspaceVariableModelIDError:
    'Por favor, verifique se há alguma variável com modelo inválido.',
  createWorkspaceModelUpdate: 'Atualização do Modelo',
  createWorkspaceModelId: 'Modelo',
  createWorkspaceModelIdRequiredField: 'Campo obrigatório',
  createWorkspaceModelIdInvalid: 'ID do Modelo inválido',
  createWorkspaceModalErrorTitle: 'Algo deu errado',
  createWorkspaceModalErrorDescription:
    'Ocorreu um erro ao XXX o seu workspace.',
  createWorkspaceVariableCategorization: 'Categorização das variáveis',
  createWorkspaceVariableCategorizationDescription:
    'Categorize suas variáveis em níveis diferentes.',
  createWorkspaceCreateVariableCategorization: 'Criar categorização',
  createWorkspaceSaveVariableCategorization: 'Salvar alterações',
  createWorkspaceConfirmCloseCategorizationTitle: 'Você tem certeza?',
  createWorkspaceConfirmCloseCategorizationDescription:
    'Você perderá todas as alterações não salvas',
  createWorkspaceEditVariableCategorization: 'Editar categorização',
  createWorkspaceVariableCategorizationDisabled:
    'É necessário que tenha pelo menos uma variável selecionada e que todas tenham um nome válido.',
  createWorkspaceSaveChangesError:
    'Verifique se tem algum nome de filtro/opção com erro.',
  createWorkspaceShowOriginalVariableName: 'Nome original da variável',
  editWorkspaceSaveChanges: 'Salvar alterações',
  createWorkspaceModalErrorDescriptionCreateText: 'criar',
  createWorkspaceModalErrorDescriptionSaveText: 'salvar',
  createWorkspaceFilterOptionName: 'Digite o nome da opção',
  editWorkspaceEditionExpired:
    'Sua sessão de edição expirou. Gostaria de renová-la por mais 5 minutos?',
  editWorkspaceExitEdition: 'Sair da edição',
  createWorkspaceAggregateResults: 'Habilitar agregação',
  createWorkspaceAggregateInformation:
    'Habilite para agregar as suas séries. A agregação é feita considerando a categorização configurada e considerando a metodologia bottom-up.',
  createWorkspaceInflateSerie: 'Inflacionar série?',
  createWorkspaceInflateSerieTooltip:
    'Recomendamos aplicar inflação apenas sobre séries de preço. Para inflacionar a série, aplicamos o IPCA sobre ela, levando como data base janeiro de 2018.',
  createWorkspaceAISelection: 'Seleção IA',
  createWorkspaceUserSelection: 'Seleção do usuário',
  createWorkspaceSelect: 'Selecionar',
  createWorkspaceOthers: 'Outros',
  createWorkspaceAnotherModels: 'Outro modelo',
  createWorkspaceTypeModelCode: 'Digite o código do modelo',
  createWorkspaceSelectAll: 'Selecionar todas',
  discardCategorizationTitle: 'Você tem certeza?',
  discardCategorizationDescription:
    'Todas as modificações feitas serão descartadas.',
  createWorkspaceEnableMarketShare: 'Habilitar Market Share',
  createWorkspaceEnableMarketShareInformation:
    'Habilite o cálculo automaticamente de Market Share para as suas séries mensais, bimestrais, trimestrais, semestrais ou anuais.<br/><br/>Ao habilitar essa opção, faça a categorização das variáveis por meio do template disponibilizado e suba o arquivo na plataforma. Garanta que o tipo das suas séries estejam sinalizadas como Market Size ou Sell Out e que os níveis das categorias estejam certos.<br/><br/>A plataforma irá relacionar as suas séries de Market Size e Sell Out pelos níveis incluídos na categorização para realizar o cálculo de Market Share. Habilite a agregação de dados quando os níveis dos dois tipos de dados não estiverem no mesmo nível categórico.',
  createWorkspaceCreateCategorizationManually: 'Criar manualmente',
  createWorkspaceCreateCategorizationUploadTitle:
    'Faça o upload do seu arquivo',
  editCategorization: 'Editar categorização',
  templateDownload: 'Download do template',
  createWorkspaceTemplateVariable: 'Variável',
  createWorkspaceTemplateLevel: 'Nivel',
  createWorkspaceTemplateOthers: 'Outros',
  createWorkspaceCancelCategorizationEdition: 'Cancelar edição',
  categorizationRequirementsTitle: 'Requisitos da categorização via arquivo',
  categorizationRequirementsDescription:
    'Por favor,garanta que seu arquivo preencha os requisitos a seguir:',
  categorizationRequirements1:
    'Utilize o template e preencha a categorização das suas séries',
  categorizationRequirements2: 'Apenas aquivos .xlsx são aceitos',
  categorizationRequirements3:
    'Todas as séries selecionadas no passo anterior devem estar no arquivo, assim como o template',
  categorizationRequirements4: 'É permitido até 10 níveis de categorização',
  categorizationRequirements5:
    'Ao realizar a categorização para uma série, preencha a categorização do nível 1 até o nível desejado',
  categorizationRequirements6:
    'Se atente a não deixar um nível sem preencher no meio da sua categorização',
  categorizationRequirements7:
    'Selecione na lista suspensa da coluna "Tipo", a seleção correspondente a série',
  categorizationRequirements8:
    'Na linha das colunas identificadas por "Nível", substitua pelo nome desejado. Você poderá adicionar até 10 níveis de categorização (ex: Nível 1=Categoria, Nível 2 = Região, etc..)',
  categorizationRequirements9: 'Não utilize acentos e caracteres especiais',
  categorizationRequirements10: 'Não altere a ordem das colunas',
  confirmMarketShareChangeTitle:
    'Tem certeza de que deseja habilitar o cálculo de Market Share?',
  confirmMarketShareChangeDescription:
    'Sua categorização atual será perdida e você deverá fazer o upload de um arquivo com a nova categorização, indicando as séries que farão parte do cálculo.',
  confirmMarketShareChangeYesButton: 'Sim, desejo habilitar o Market Share.',
  creatingWorkspace: 'Criando o Workspace',
  savingWorkspace: 'Salvando configuração das variáveis',
  savingHierarchies: 'Salvando categorização',
  categorizationErrorWorkspaceCreation: 'Seu workspace foi criado',
  categorizationErrorWorkspaceEdit: 'Sua configuração das variáveis foi salva',
  categorizationErrorTile: 'Erro ao salvar a categorização',
  categorizationErrorGenericDescription:
    'XXX, porém ocorreu o seguinte erro na categorização:',
  categorizationErrorFilterDescription:
    'Alguns ys estão com lacunas na hierarquia.',
  categorizationWarningTitle: 'Atenção',
  categorizationWarningDescription:
    'Você já configurou a categorização das suas variáveis. Caso renomeie alguma das variáveis já selecionadas, toda a configuração será perdida. Vá para a próxima etapa e exporte a categorização, se necessário.',
  fileIsEmpty: 'Arquivo está vazio.',
  invalidVariableColumn: 'Coluna de Variável Inválida.',
  invalidProjectColumn: 'Coluna de Projeto Inválida.',
  invalidVariableTypeColumn: 'Coluna de Tipo de Variável Inválida.',
  invalidColumns: 'Conteúdo inválido do arquivo. Colunas inválidas.',
  aggregationDisabled:
    "Agregação desabilitada, mas múltiplos 'Ys' com o mesmo tipo no filtro. Variáveis",
  missingFilter: 'Valor de filtro ausente nas variáveis:',
  numberVariablesIsGreater:
    'Uma ou mais variáveis estão presentes no seu arquivo, mas não estão selecionadas no Workspace. As variáveis presentes no arquivo devem ser iguais às variáveis selecionadas no Workspace. Variáveis faltantes:',
  numberVariablesIsSmaller:
    'Uma ou mais variáveis estão selecionadas no Workspace, mas não estão presentes no seu arquivo. As variáveis presentes no arquivo devem ser iguais às variáveis selecionadas no Workspace. Variáveis faltantes:',
  withoutSellOut:
    'Para calcular o Market Share, é necessário que o arquivo forneça ao menos uma série do tipo Sell Out.',
  withoutMarketSize:
    'Para calcular o Market Share, é necessário que o arquivo forneça ao menos uma série do tipo Market Size.',
  withoutHierarchy:
    'Para calcular o Market Share, é preciso existir a categorização de ao menos um nível no arquivo de upload.',
  typeRequired: 'Tipo é obrigatório para todas as variáveis.',
  invalidTypes: 'Tipos inválidos:',
  mandatoryVariables: 'Variável é obrigatória para ter hierarquia. Variável:',
  mandatoryHierarchy:
    'É obrigatório ter pelo menos um nível de hierárquia. Variáveis:',
  controlPanel: 'Painel de controle',
  proceedAnyway: 'Continuar mesmo assim',
  workspaceConfigEnableApprovalFlow: 'Habilitar fluxo de planejamento?',
  workspaceConfigEnableApprovalFlowInfoTooltip:
    'Ao ativar o fluxo de planejamento, somente projetos com frequência mensal de séries temporais serão permitidos.',

  workspaceConfigCategorization: 'Categorização',

  workspaceConfigAggregations: 'Agregações',
  workspaceConfigAggregationsIndividualConfiguration: 'Configurar por tipos',
  workspaceConfigAggregationsIndividualConfigurationDisabled:
    'Defina os tipos de dados das suas variáveis para poder configurar suas agregações individualmente.',
  workspaceConfigAggregationsLevelAggregation: 'Agregação de nível',
  workspaceConfigAggregationsTemporalAggregation: 'Agregação temporal',
  workspaceConfigAggregationsTemporalAggregationTooltip:
    'A agregação temporal define o critério de agrupamento dos seus dados em outras frequências. Exemplo: agregação de dados mensais em dados bimestrais.',
  workspaceConfigAggregationsSum: 'Soma',
  workspaceConfigAggregationsAverage: 'Média',
  workspaceConfigAggregationsEndOfPeriod: 'Fim de período',
  workspaceConfigAggregationsNoAggregation: 'Não agregar',
  workspaceConfigAggregationsIsIndividualConfiguration:
    'Configurado individualmente',
  workspaceConfigAggregationsTemporalAggregationAnnualTooltip:
    'Essa funcionalidade não está disponível para Workspaces de frequência anual.',

  workspaceAggregationErrorGenericDescription:
    'XXX, porém ocorreu um erro na agregação.',
  workspaceAggregationConfigurationTitle: 'Configure as agregações',
  workspaceAggregationConfigurationDescription:
    'Para cada valor da variável tipo, selecione os tipos de agregação que você deseja utilizar.',

  selectProjectWorkspaceIsDisabledDifferentFrequency:
    'Você só pode escolher projetos com a mesma frequência da série temporal daqueles já selecionados.',
  selectSeriesWorkspaceIsDisabledDifferentFrequency:
    'Você só pode escolher séries com a mesma frequência da série temporal daquelas já selecionadas.',
  selectProjectWorkspaceIsDisabledDifferentFrequencyOfWorkspace:
    'Você só pode escolher projetos com a mesma frequência da série temporal do seu workspace.',
  selectSeriesWorkspaceIsDisabledDifferentFrequencyOfWorkspace:
    'Você só pode escolher séries com a mesma frequência da série temporal do seu workspace.',
  selectProjectWorkspaceIsDisabledApprovalFlow:
    'Você habilitou o fluxo de planejamento, então apenas projetos com frequência mensal de séries temporais serão permitidos.',
  workspaceConfigAddDataType: 'Clique para adicionar mais',
  workspaceConfigSelectCreateDataType: 'Selecione ou crie uma opção',
  workspaceConfigCreate: 'Criar',
  workspaceConfigDataTypeMinCharacters: 'Deve ter ao menos 4 caracteres',
  workspaceConfigDataTypeMaxCharacters:
    'Não deve ter mais do que 50 caracteres',
  workspaceConfigDataTypeAlreadySelected: 'Opção já foi selecionada',
  workspaceConfigDataTypeSpecialCharacters:
    'Caracteres especiais não são permitidos',
  workspaceConfigDataTypeCreationTitleError:
    'Erro ao adicionar o novo tipo de dado',
  workspaceConfigDataTypeCreationDescriptionError:
    'Ocorreu um erro ao adicionar o novo tipo de dado. Por favor, tente novamente mais tarde',
  workspaceConfigDataTypeExistsDescriptionError:
    'O tipo de dado inserido já existe. Por favor, selecione-o se desejar usá-lo.',

  createWorkspaceCreateYourProject: 'Crie seu projeto',
  createWorkspaceYouHaveNotCreatedProject:
    'Você ainda não criou nenhum projeto',
  createWorkspaceRedirectedUploadYourSeries:
    'para ser redirecionado para o upload das suas séries.',
  createWorkspaceStartCreatingYourProject: 'para começar a criar seu projeto.',
};
