const deleteGroupModalENTranslate = {
  deleteGroupModalTitle: 'Are you absolutely sure?',
  deleteGroupModalDescription:
    'This will permanently delete this group and all series on it. Please type <b>XXX</b> to confirm.',
  deleteGroupModalMessageError: 'Must write the correct group name',
  deleteGroupModalMessageRequestFailedDescription:
    'There was an error deleting group.',
};

export default deleteGroupModalENTranslate;
