export const workspacePerformanceByPeriodTablePTBRTranslations = {
  workspacePerformanceByPeriodTitle: 'Performance por período',
  workspacePerformanceByPeriodDescription:
    'Analise a performance dos modelos <b>escolhidos</b> para as variáveis do workspace.',
  workspacePerformanceByPeriodDepVariable: 'Variável dependente',
  workspacePerformanceByPeriodSearchPlaceholder:
    'Procurar variáveis dependentes',
  workspacePerformanceByPeriodMetricLabel: 'Métrica',
  workspacePerformanceByPeriodTooltip:
    'Cada coluna reflete o desempenho médio de todos os modelos que possuem datas fora da amostra suficientes para o período referido.',
  workspacePerformanceByPeriodVariableTooltip: 'Variável não atualizada.',
  workspacePerformanceByPeriodNoResults:
    'Não foi possível encontrar nenhuma variável contendo "SEARCH".',
};
