import React, { useCallback, useMemo } from 'react';

import { add, format } from 'date-fns';
import { enUS, ptBR } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import light from 'src/styles/themes/light';
import { getTextWidth } from 'src/utils/getTextWidth';
import { transformUppercaseFirstLetter } from 'src/utils/strings/transformUppercaseFirstLetter';

import { ContainerChartHistoricalForecast, Chart } from './styles';

interface ChartHistoricalForecast {
  historicalDates: string[];
  forecastDates: string[] | string | null;
  frequency: string;
  nsteps: number | null;
}

const font = '400 12px Inter';

export const ChartHistoricalForecast: React.FC<ChartHistoricalForecast> = ({
  historicalDates,
  forecastDates,
  frequency,
  nsteps,
}) => {
  const { t: translate } = useTranslation();
  const { language } = useSelector((state: RootState) => state.auth.user);

  const firstDate = useMemo(
    () => new Date(`${historicalDates[0]}T00:00`),
    [historicalDates],
  );

  function adjustFormatDate(month: string, date: Date): string {
    if (
      frequency === 'Daily' ||
      frequency === 'Weekly' ||
      frequency === 'Fortnightly'
    ) {
      if (language === 'en-us') {
        return `${transformUppercaseFirstLetter(month)} ${format(date, 'dd', {
          locale: enUS,
        })}`;
      }
      return `${format(date, 'dd', {
        locale: ptBR,
      })} de ${month}`;
    }
    return transformUppercaseFirstLetter(month);
  }

  const firstMonth = useMemo(
    () =>
      format(firstDate, 'MMMM', {
        locale: language === 'en-us' ? enUS : ptBR,
      }),

    [firstDate, language],
  );

  const endHistoricalDate = useMemo(
    () => new Date(`${historicalDates[historicalDates.length - 1]}T00:00`),
    [historicalDates],
  );

  const endHistoricalMonth = useMemo(
    () =>
      format(endHistoricalDate, 'MMMM', {
        locale: language === 'en-us' ? enUS : ptBR,
      }),
    [endHistoricalDate, language],
  );

  const addDateByFrequencyAndNsteps = useCallback(() => {
    if (!nsteps) {
      return {};
    }
    switch (frequency) {
      case 'Daily':
        return { days: nsteps };
      case 'Weekly':
        return { weeks: nsteps };
      case 'Fortnightly':
        return { weeks: nsteps * 2 };
      case 'Monthly':
        return { months: nsteps };
      case 'Bimonthly':
        return { months: nsteps * 2 };
      case 'Quarterly':
        return { months: nsteps * 3 };
      case 'Half-year':
        return { months: nsteps * 6 };
      case 'Annual':
        return { years: nsteps };
      default:
        return {};
    }
  }, [frequency, nsteps]);

  const endOptimizedDate = useMemo(
    () =>
      add(new Date(`${historicalDates[historicalDates.length - 1]}T00:00`), {
        ...addDateByFrequencyAndNsteps(),
      }),
    [addDateByFrequencyAndNsteps, historicalDates],
  );

  const endOptimizedMonth = useMemo(
    () =>
      format(endOptimizedDate, 'MMMM', {
        locale: language === 'en-us' ? enUS : ptBR,
      }),

    [endOptimizedDate, language],
  );

  const endForecastDate = useMemo(() => {
    if (forecastDates) {
      if (Array.isArray(forecastDates)) {
        if (forecastDates.length) {
          return new Date(`${forecastDates[forecastDates.length - 1]}T00:00`);
        }
        return null;
      }
      return new Date(`${forecastDates}T00:00`);
    }
    return null;
  }, [forecastDates]);

  const endForecastMonth = useMemo(() => {
    if (endForecastDate) {
      return format(endForecastDate, 'MMMM', {
        locale: language === 'en-us' ? enUS : ptBR,
      });
    }
    return null;
  }, [endForecastDate, language]);

  return (
    <ContainerChartHistoricalForecast data-testid="container-chart-historical-forecast">
      <div>
        <p>{translate('step3ChartHistorical')}</p>
        <div>
          <Chart
            color1={light.colors.primary}
            color2={light.colors.secondary}
            hasLeftBar
          >
            <div>
              <div />
              <div
                style={{
                  left:
                    (getTextWidth(
                      adjustFormatDate(firstMonth, firstDate),
                      font,
                    ) *
                      -1) /
                    2,
                }}
              >
                <p>{adjustFormatDate(firstMonth, firstDate)}</p>
                <p>{firstDate.getFullYear()}</p>
              </div>
              <div
                style={{
                  right:
                    (getTextWidth(
                      adjustFormatDate(endHistoricalMonth, endHistoricalDate),
                      font,
                    ) *
                      -1) /
                    2,
                }}
              >
                <p>{adjustFormatDate(endHistoricalMonth, endHistoricalDate)}</p>
                <p>{endHistoricalDate.getFullYear()}</p>
              </div>
            </div>
          </Chart>
        </div>
      </div>

      {endForecastDate && endForecastMonth && (
        <div>
          <p>{translate('step3ChartForecast')}</p>
          <div>
            {endForecastDate > endOptimizedDate && nsteps && (
              <Chart color1="#4299e1" color2="#38b2ac">
                <p>{translate('step3ChartOptimized')}</p>
                <div>
                  <div />
                  <div
                    style={{
                      right:
                        (getTextWidth(
                          adjustFormatDate(endOptimizedMonth, endOptimizedDate),
                          font,
                        ) *
                          -1) /
                        2,
                    }}
                  >
                    <p>
                      {adjustFormatDate(endOptimizedMonth, endOptimizedDate)}
                    </p>
                    <p>{endOptimizedDate.getFullYear()}</p>
                  </div>
                </div>
              </Chart>
            )}

            <Chart color1="#ecc94b" color2="#f56565">
              <p>{translate('step3ChartTotal')}</p>
              <div>
                <div />
                <div
                  style={{
                    right:
                      (getTextWidth(
                        adjustFormatDate(endForecastMonth, endForecastDate),
                        font,
                      ) *
                        -1) /
                      2,
                  }}
                >
                  <p>{adjustFormatDate(endForecastMonth, endForecastDate)}</p>
                  <p>{endForecastDate.getFullYear()}</p>
                </div>
              </div>
            </Chart>
          </div>
        </div>
      )}
    </ContainerChartHistoricalForecast>
  );
};
