const reviewCreateSeriesENTranslate = {
  reviewCreateSeriesText: 'Review series to be added',
  reviewCreateSeriesSubText:
    'When finished, click “Add series” to add these series to your indicator',
  reviewCreateRegion: 'Region',
  reviewCreateAggregation: 'Aggregation',
  reviewCreatePrimary: 'Primary Tr',
  reviewCreateSecondary: 'Secondary Tr',
  reviewCreateUnitPt: 'Unit (portuguese)',
  reviewCreateUnitEn: 'Unit (english)',
  reviewCreateAddSeries: 'Add series',
  modalStatusText: 'Series creation status',
  modalStatusSubText:
    'Check out which series were created successfully and which resulted in an error in the process.',
  modaStatusSuccess: 'Success',
  modaStatusError: 'Error',
  modaStatusContinue: 'Continue',
};

export default reviewCreateSeriesENTranslate;
