import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Button';
import { CheckBox } from 'src/components/CheckBox';
import { Input } from 'src/components/Input';
import { Modal } from 'src/components/Modal';
import { RadioButton } from 'src/components/RadioButton';
import { ToggleSwitch } from 'src/components/ToggleSwitch';
import { DataError } from 'src/interface/axios';
import { apiAdminV2 } from 'src/service/apiAdminV2';
import { queryClient } from 'src/service/queryClient';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import * as Yup from 'yup';

import { Customer, Products } from '../..';
import { InfoFailedToRegisterOrEdit } from '../../types';
import { LoadingModalRegisterEditCustomer } from './LoadingModalRegisterEditCustomer';
import {
  ButtonAddManager,
  Container,
  ContentProducts,
  ContentRadioButtons,
  ErrorProducts,
} from './styles';

interface ModalRegisterEditCustomerProps {
  visible: boolean;
  customerToEdit: Customer | null;
  closeModal: () => void;
  setInfoFailedToRegisterOrEditCustomer: (
    value: InfoFailedToRegisterOrEdit,
  ) => void;
  setInfoFailedToRegisterOrEditManager: (
    value: InfoFailedToRegisterOrEdit,
  ) => void;
}

interface ResponseRegisterCostumer {
  id: string;
}

interface FormRegisterCustomer {
  name: string;
  license: number;
  admin: string;
  accessToModule: boolean;
  sso: boolean;
}

interface SelectedProducts {
  timeSeries: boolean;
  timeSeriesWrite: boolean;
  class: boolean;
  classWrite: boolean;
  featureStore: boolean;
}

const DefaultSelectedProducts: SelectedProducts = {
  timeSeries: false,
  timeSeriesWrite: true,
  class: false,
  classWrite: true,
  featureStore: false,
};

type SelectedProduct = keyof SelectedProducts;

interface userOfCustomerToEdit {
  id: string;
  email: string;
  discount_license: boolean;
  role: string;
  is_blocked: boolean;
}

interface ResponseUserList {
  limit: number;
  skip: number;
  total: number;
  records: userOfCustomerToEdit[];
}

export const ModalRegisterEditCustomer: React.FC<
  ModalRegisterEditCustomerProps
> = ({
  visible,
  customerToEdit,
  closeModal,
  setInfoFailedToRegisterOrEditCustomer,
  setInfoFailedToRegisterOrEditManager,
}) => {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState(DefaultSelectedProducts);
  const [errorLicenses, setErrorLicenses] = useState<string>('');
  const [isDisableButtonConfirmEdit, setIsDisableButtonConfirmEdit] =
    useState(false);
  const [usersOfCustomersToEdit, setUsersOfCustomersToEdit] = useState<
    userOfCustomerToEdit[] | null
  >(null);
  const [messageErrorProducts, setMessageErrorProducts] = useState<
    string | null
  >('');

  const [manager, setManager] = useState<boolean>(false);

  const { t: translate } = useTranslation();

  const schema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .max(350, 'modalRegisterNewCustomerNameMaxCharacters')
      .required('requiredField'),
    license: Yup.number()
      .typeError('modalRegisterNewCustomerErrorSpecifyNumber')
      .integer('modalRegisterNewCustomerIntegerNumber')
      .min(1, 'modalRegisterNewCustomerLicenseMin')
      .required('requiredField'),
    admin: manager
      ? Yup.string()
          .trim()
          .email('modalRegisterNewCustomerAdminValidEmail')
          .required('requiredField')
      : Yup.string().trim().email('modalRegisterNewCustomerAdminValidEmail'),
  });

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<FormRegisterCustomer>({
    resolver: yupResolver(schema),
  });

  const selectProduct = useCallback((key: SelectedProduct, value: boolean) => {
    setProducts((productsAux) => ({
      ...productsAux,
      [key]: value,
    }));
  }, []);

  useEffect(() => {
    (async () => {
      if (customerToEdit) {
        const allUsersOfCustomerToEdit: userOfCustomerToEdit[] = []; // load users
        const { data: firstData } = await apiAdminV2.get<ResponseUserList>(
          `/clients/${customerToEdit.id}/users?limit=100`,
        );

        allUsersOfCustomerToEdit.push(...firstData.records);
        for (let i = firstData.limit; i < firstData.total; i += 100) {
          const { data: dataAux } = await apiAdminV2.get<ResponseUserList>(
            `/clients/${customerToEdit.id}/users?limit=100&skip=${i}`,
          );

          allUsersOfCustomerToEdit.push(...dataAux.records);
        }

        setValue('name', customerToEdit.name);
        setValue('license', customerToEdit.total_licenses);

        setValue('sso', customerToEdit.sso_integration);

        setValue(
          'admin',
          customerToEdit.managers.length > 0 ? customerToEdit.managers[0] : '',
        );

        const userManager = allUsersOfCustomerToEdit.find(
          (user) => user.role === 'manager' && user.is_blocked === false,
        );

        if (userManager) {
          setValue('accessToModule', userManager.discount_license);
        }

        customerToEdit.products.forEach((product) => {
          if (product.name === 'time_series') {
            selectProduct('timeSeries', true);
            if (product.type === 'manage') {
              selectProduct('timeSeriesWrite', true);
            } else {
              selectProduct('timeSeriesWrite', false);
            }
          }

          if (product.name === 'classification') {
            selectProduct('class', true);
            if (product.type === 'manage') {
              selectProduct('classWrite', true);
            } else {
              selectProduct('classWrite', false);
            }
          }

          if (product.name === 'feature_store') {
            selectProduct('featureStore', true);
          }
        });

        setUsersOfCustomersToEdit(allUsersOfCustomerToEdit);
      }
    })();
  }, [customerToEdit, selectProduct, setValue]);

  const managers = useMemo(() => {
    if (Array.isArray(usersOfCustomersToEdit)) {
      return usersOfCustomersToEdit.filter(
        (user) => user.role === 'manager' && user.is_blocked === false,
      );
    }
    return [];
  }, [usersOfCustomersToEdit]);

  const handleRegisterCustomer = async ({
    name,
    license,
    sso,
    admin,
    accessToModule,
  }: FormRegisterCustomer): Promise<void> => {
    setLoading(true);
    let idCostumer = null;
    const registerProducts: Products[] = [];

    if (!products.class && !products.featureStore && !products.timeSeries) {
      setMessageErrorProducts(
        translate('modaRegisterNewCustomerErrorMessageProduct'),
      );

      setLoading(false);

      return;
    }

    if (products.timeSeries) {
      if (products.timeSeriesWrite) {
        registerProducts.push({ name: 'time_series', type: 'manage' });
      } else {
        registerProducts.push({ name: 'time_series', type: 'read' });
      }
    }

    if (products.class) {
      if (products.classWrite) {
        registerProducts.push({ name: 'classification', type: 'manage' });
      } else {
        registerProducts.push({ name: 'classification', type: 'read' });
      }
    }

    if (products.featureStore) {
      registerProducts.push({ name: 'feature_store', type: null });
    }

    try {
      const { data } = await apiAdminV2.post<ResponseRegisterCostumer>(
        '/clients',
        {
          name,
          total_licenses: license,
          sso_integration: sso,
          products: registerProducts,
        },
      );
      idCostumer = data.id;
    } catch (err) {
      const error = err as AxiosError<DataError>;
      setInfoFailedToRegisterOrEditCustomer({
        title: translate('requestFailed'),
        description:
          error.response?.data?.detail?.description ??
          translate('modalRegisterNewCustomerErrorToRegisterClient'),
      });
      setLoading(false);
      closeModal();
      return;
    }

    if (manager) {
      try {
        await apiAdminV2.post(`/clients/${idCostumer}/managers`, {
          email: admin,
          discount_license: accessToModule,
        });
      } catch (err) {
        const error = err as AxiosError<DataError>;
        if (error?.response?.status === 409) {
          setInfoFailedToRegisterOrEditManager({
            title: 'Warning',
            description: translate('modalWarningManagerMessage'),
          });
        } else {
          setInfoFailedToRegisterOrEditManager({
            title: translate('requestFailed'),
            description:
              error.response?.data?.detail?.description ??
              translate('modalRegisterNewCustomerErrorToRegisterManagerUser'),
          });
        }
      }
    }

    queryClient.removeQueries(['customers-list']);
    setLoading(false);
    closeModal();
  };

  const checkLicensesQuantityIsCorrectWhenItIsAnEdition =
    async (): Promise<void> => {
      if (usersOfCustomersToEdit && customerToEdit) {
        const isOkLicensesYup = await trigger('license');

        setIsDisableButtonConfirmEdit(true);
        if (!isOkLicensesYup) {
          return;
        }

        const licenses = Number(getValues('license'));
        const accessToModule = getValues('accessToModule');

        const quantityUsedLicenses =
          customerToEdit.total_licenses - customerToEdit.remaining_licenses;

        if (managers.length) {
          const discountLicense = managers[0].discount_license;

          if (discountLicense === accessToModule) {
            if (quantityUsedLicenses > licenses) {
              setErrorLicenses(
                translate(
                  'modalRegisterEditCustomerLicenseErrorGreaterThanEqual',
                ),
              );
              return;
            }
          } else if (accessToModule) {
            if (quantityUsedLicenses + managers.length > licenses) {
              setErrorLicenses(
                translate(
                  'modalRegisterEditCustomerLicenseErrorGreaterThanEqualManager',
                ),
              );
              return;
            }
          } else if (quantityUsedLicenses - managers.length > licenses) {
            setErrorLicenses(
              translate(
                'modalRegisterEditCustomerLicenseErrorGreaterThanEqual',
              ),
            );
            return;
          }
        } else if (quantityUsedLicenses > licenses) {
          setErrorLicenses(
            translate('modalRegisterEditCustomerLicenseErrorGreaterThanEqual'),
          );
          return;
        }
        setErrorLicenses('');
        setIsDisableButtonConfirmEdit(false);
      }
    };

  const increaseOrDecreaseLicenseQuantity = () => {
    if (customerToEdit && managers.length) {
      const license = Number(getValues('license'));
      if (getValues('accessToModule')) {
        setValue('license', license + managers.length);
      } else if (license > 1) {
        setValue('license', license - managers.length);
      }
      checkLicensesQuantityIsCorrectWhenItIsAnEdition();
    }
  };

  const handleEditCustomer = async (): Promise<void> => {
    const areValid = await Promise.all([
      trigger('accessToModule'),
      trigger('license'),
    ]);

    if (customerToEdit && !areValid.includes(false)) {
      const license = getValues('license');
      const sso = getValues('sso');
      setLoading(true);
      try {
        await apiAdminV2.patch(`/clients/${customerToEdit.id}`, {
          sso_integration: sso,
          total_licenses: license,
        });
        queryClient.removeQueries(['customers-list']);
      } catch (err) {
        const error = err as AxiosError<DataError>;
        setInfoFailedToRegisterOrEditCustomer({
          title: translate('requestFailed'),
          description:
            error.response?.data?.detail?.description ??
            translate('modalRegisterEditCustomerErrorToEditClient'),
        });
        setLoading(false);
        closeModal();
        return;
      }

      setLoading(false);
      closeModal();
    }
  };

  const isLoadingDataCustomerToEdit = customerToEdit
    ? !Array.isArray(usersOfCustomersToEdit)
    : false;

  return (
    <Modal visible={visible} setVisible={closeModal}>
      <Container hasErrorOnInputs={!!Object.keys(errors).length}>
        <h4>
          {customerToEdit
            ? translate('modalRegisterNewCustomerTitleEdit')
            : translate('modalRegisterNewCustomerTitle')}
        </h4>
        {!isLoadingDataCustomerToEdit ? (
          <>
            {!customerToEdit && (
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <Input
                    label={translate('modalRegisterNewCustomerName')}
                    placeholder={translate(
                      'modalRegisterNewCustomerPlaceholderName',
                    )}
                    testid="input-name"
                    // @ts-expect-error bug typescript
                    error={translate(errors.name?.message)}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            )}

            <Controller
              name="license"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  label={translate('modalRegisterNewCustomerNumberOfLicense')}
                  placeholder={translate(
                    'modalRegisterNewCustomerPlaceholderNumberOfLicense',
                  )}
                  testid="input-license"
                  // @ts-expect-error bug typescript
                  error={translate(errors.license?.message) || errorLicenses}
                  onChange={(val) => {
                    onChange(val);
                    checkLicensesQuantityIsCorrectWhenItIsAnEdition();
                  }}
                  value={value ?? ''}
                />
              )}
            />

            <Controller
              name="sso"
              key="sso"
              defaultValue={false}
              control={control}
              render={({ field: { onChange, value } }) => (
                <ToggleSwitch
                  data-testid="toggle-switch-sso"
                  label="SSO"
                  onChange={(val) => {
                    onChange(val);
                  }}
                  checked={value}
                  data-cy="toggle-switch-sso"
                  useYesOrNo
                />
              )}
            />

            {!customerToEdit && (
              <ContentProducts disabled={!!customerToEdit}>
                <p>{translate('modalRegisterNewCustomerProducts')}</p>
                <div>
                  <CheckBox
                    label={translate('modalRegisterNewCustomerTimeSeries')}
                    disabled={!!customerToEdit}
                    checked={products.timeSeries}
                    onChange={({ target: { checked } }) =>
                      selectProduct('timeSeries', checked)
                    }
                  />

                  <ContentRadioButtons>
                    <RadioButton
                      label={translate('modalRegisterNewCustomerReadOnly')}
                      disabled={!products.timeSeries || !!customerToEdit}
                      dataCy="radio-button-time-series-read-only"
                      checked={products.timeSeries && !products.timeSeriesWrite}
                      onChange={() =>
                        selectProduct(
                          'timeSeriesWrite',
                          !products.timeSeriesWrite,
                        )
                      }
                    />
                    <RadioButton
                      label={translate('modalRegisterNewCustomerCreateAndRead')}
                      disabled={!products.timeSeries || !!customerToEdit}
                      dataCy="radio-button-time-series-create-read"
                      checked={products.timeSeries && products.timeSeriesWrite}
                      onChange={() =>
                        selectProduct(
                          'timeSeriesWrite',
                          !products.timeSeriesWrite,
                        )
                      }
                    />
                  </ContentRadioButtons>
                </div>
                <div>
                  <CheckBox
                    label={translate('modalRegisterNewCustomerClassification')}
                    disabled={!!customerToEdit}
                    checked={products.class}
                    onChange={({ target: { checked } }) =>
                      selectProduct('class', checked)
                    }
                  />

                  <ContentRadioButtons>
                    <RadioButton
                      label={translate('modalRegisterNewCustomerReadOnly')}
                      dataCy="radio-button-classification-read-only"
                      disabled={!products.class || !!customerToEdit}
                      checked={products.class && !products.classWrite}
                      onChange={() =>
                        selectProduct('classWrite', !products.classWrite)
                      }
                    />
                    <RadioButton
                      label={translate('modalRegisterNewCustomerCreateAndRead')}
                      dataCy="radio-button-classification-create-read"
                      disabled={!products.class || !!customerToEdit}
                      checked={products.class && products.classWrite}
                      onChange={() =>
                        selectProduct('classWrite', !products.classWrite)
                      }
                    />
                  </ContentRadioButtons>
                </div>
                <div style={{ marginBottom: '21.5px' }}>
                  <CheckBox
                    label="Feature Store"
                    disabled={!!customerToEdit}
                    checked={products.featureStore}
                    onChange={({ target: { checked } }) => {
                      selectProduct('featureStore', checked);
                    }}
                  />
                </div>
                {(messageErrorProducts && products.timeSeries) ||
                  products.class ||
                  products.featureStore || (
                    <ErrorProducts>
                      <p>{messageErrorProducts}</p>
                    </ErrorProducts>
                  )}

                {!manager && (
                  <ButtonAddManager
                    type="button"
                    onClick={() => {
                      setManager(true);
                    }}
                    data-testid="buttonAddManager"
                  >
                    <p>{translate('modalRegisterNewCustomerAddManager')}</p>
                  </ButtonAddManager>
                )}

                {!customerToEdit && manager && (
                  <>
                    <Controller
                      name="admin"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value } }) => (
                        <Input
                          label={translate(
                            'modalRegisterNewCustomerUserManager',
                          )}
                          placeholder={translate(
                            'modalRegisterNewCustomerPlaceholderManagerUser',
                          )}
                          testid="input-admin"
                          // @ts-expect-error bug typescript
                          error={translate(errors.admin?.message)}
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />

                    <Controller
                      name="accessToModule"
                      key="accessToModule"
                      defaultValue
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <ToggleSwitch
                          data-testid="toggle-switch-manager-access"
                          label={translate(
                            'modalRegisterNewCustomerManagerWithAccess',
                          )}
                          onChange={(val) => {
                            onChange(val);
                            increaseOrDecreaseLicenseQuantity();
                          }}
                          checked={value}
                          data-cy="toggle-switch-manager-access"
                          useYesOrNo
                        />
                      )}
                    />
                  </>
                )}
              </ContentProducts>
            )}

            {manager && (
              <ButtonAddManager
                type="button"
                onClick={() => {
                  setManager(false);
                  setValue('admin', '');
                }}
              >
                <p>{translate('modalRegisterNewCustomerRemoveManager')}</p>
              </ButtonAddManager>
            )}
          </>
        ) : (
          <LoadingModalRegisterEditCustomer />
        )}
      </Container>
      <ModalFooter>
        <Button
          buttonType="naked"
          onClick={() => closeModal()}
          disabled={loading}
          data-testid="button-cancel"
        >
          {translate('modalRegisterNewCustomerButtonCancel')}
        </Button>
        <Button
          buttonType="primary"
          onClick={
            customerToEdit
              ? () => handleEditCustomer()
              : handleSubmit(handleRegisterCustomer)
          }
          loading={loading}
          disabled={
            loading || isLoadingDataCustomerToEdit || isDisableButtonConfirmEdit
          }
          data-testid={customerToEdit ? 'button-confirm' : 'button-register'}
          data-cy={customerToEdit ? 'button-confirm' : 'button-register'}
        >
          {customerToEdit
            ? translate('modalRegisterNewCustomerButtonConfirm')
            : translate('modalRegisterNewCustomerButtonRegister')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
