export const WorkspaceControlPanelENUSTranslations = {
  workspaceControlPanelHeadTitle: 'Workspace - Control Panel',
  workspaceControlPanelShareTitle: 'Sharing',
  workspaceControlPanelShareDescription:
    'View the users who have access to this Workspace and their permissions.',
  workspaceControlPanelEdit: 'Edit variables',
  workspaceControlPanelCreator: 'Creator',
  workspaceControlPanelGuests: 'Guests',
  workspaceControlPanelInviteUser: 'Invite a user',
  workspaceControlPanelInviteUserDescription:
    'Share this Workspace with another user so they can also see your results!',
  workspaceControlPanelCanView: 'Can collaborate',
  workspaceControlPanelCanEdit: 'Can edit',
  workspaceControlPanelCanManager: 'Can manager',
  workspaceControlPanelRemove: 'Remove',
  workspaceControlPanelUserUpdateErrorTitle: 'Failed to update',
  workspaceControlPanelUserUpdateErrorDescription:
    "An error occurred while updating the user's permission. Please try again later.",
  workspaceControlPanelUserRemoveErrorTitle: 'Failed to remove',
  workspaceControlPanelUserRemoveErrorDescription:
    'An error occurred while removing the user. Please try again later.',
  workspaceControlPanelNoPermissionDelete:
    'You are the last manager, remove all viewers before deleting the workspace from your account.',
  workspaceControlPanelNoPermissionUpdateToReader:
    'Unable to switch to viewer, each workspace requires at least one manager to manage changes.',
  workspaceControlPanelNoPermissionUpdateToEditor:
    'Unable to switch to editor, each workspace requires at least one manager to manage changes.',
  workspaceControlPanelUserRemoveTitle: 'Are you sure?',
  workspaceControlPanelUserRemoveDescription:
    'This will remove <b>XXX</b> from this workspace.',
  workspaceControlPanelOwnerWithoutAccess: '(No access)',
  workspaceControlPanelInvite: 'Invite',
  workspaceControlPanelReturnHomePage: 'Return to the home page',
  workspaceControlPanelEditionErrorDescription:
    '<b>XXX</b> is currently editing this workspace, please come back later.',
  workspaceControlPanelCreatePreview: 'Create preview',
  workspaceControlPanelPreview: 'Preview',
  workspaceControlPanelPublish: 'Publish',
  workspaceControlPanelPublishErrorTitle: 'Something went wrong',
  workspaceControlPanelPublishErrorDescription: 'An error occurred while XXX.',
  workspaceControlPanelPublishErrorPublishType: 'publishing your workspace',
  workspaceControlPanelPublishErrorPreviewType: 'creating preview',
  workspaceControlPanelPublishLocked: 'Workspace already locked for editing by',
  workspaceControlPanelPublishingNewVersion:
    'You cannot perform this action, Workspace is publishing a new version.',
  workspaceControlPanelPublishTitle: 'Processing...',
  workspaceControlPanelPreviewDescription:
    'We are creating a preview of your workspace. This process may take a few minutes, please come back later.',
  workspaceControlPanelPublishDescription:
    'We are publishing your workspace. This process may take a few minutes, please come back later.',
  workspaceControlPanelReleaseErrorTitle: 'Something went wrong',
  workspaceControlPanelReleaseErrorDescription:
    'An error occurred when creating the <b>XXX</b> requested by <b>YYY</b>',
  workspaceControlPanelReleaseErrorPreview: 'preview',
  workspaceControlPanelReleaseErrorCurrent: 'publication',
  workspaceControlPanelReleaseYsErrorDescription:
    'An error occurred in the following variables when creating the <b>XXX</b> requested by <b>YYY</b>:',
  workspaceControlPanelAccess: 'Access Workspace',
  workspaceControlPanelEditNamePlaceholder: 'Enter the Workspace name',
  workspaceControlPanelEditDescriptionPlaceholder:
    'Enter a description for your Workspace',
  workspaceControlPanelEditBasicInformationsErrorTitle: 'Something went wrong',
  workspaceControlPanelEditBasicInformationsErrorDescription:
    'An error occurred while saving the information. Please try again later.',
  workspaceControlPanelSelectedVariablesTitle: 'Selected Variables',
  workspaceControlPanelSelectedVariablesDescription:
    'See the variables being used in the latest edition.',
  workspaceControlPanelAdjustSettingsButton: 'Configure publication',
  workspaceControlPanelAdjustSettingsNoPermission:
    'Only the manager can configure the versions of this Workspace.',
  workspaceControlPanelAdjustSettingsCreatePreview:
    'Create the preview to start configuring your publication.',
  workspaceControlPanelPublishNoPermission:
    'Only the manager can publish this Workspace.',
  workspaceControlPanelConfigureNewRealease:
    'Please configure your new version before publishing.',
  workspaceControlPanelGeneratePreviewTooltip:
    'Generate the preview and check the result before publishing a version.',
  workspaceControlPanelProjectTagColumn: 'Project / Tag',
};
