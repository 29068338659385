import styled from 'styled-components';

interface ContainerProps {
  infoVisible: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;

  > div {
    max-height: ${({ infoVisible }) => (infoVisible ? `25rem` : '0px')};
    padding-bottom: ${({ infoVisible }) => (infoVisible ? '0.5rem' : 0)};
    transition: max-height 0.3s;

    overflow-y: hidden;
    overflow-x: auto;

    p {
      font-weight: 400;
      font-size: 1rem;
      line-height: 150%;
      color: ${({ theme }) => theme.colors.gray5};
      margin-bottom: 1.5rem;
    }

    table {
      width: 100%;
      text-align: center;
      border-collapse: separate;
      border: 1px solid ${({ theme }) => theme.colors.gray2};
      border-spacing: 0px;
      border-radius: 5px;

      thead {
        th {
          padding: 1rem;

          font-weight: 600;
          font-size: 0.875rem;
          line-height: 1.375rem;
          color: ${({ theme }) => theme.colors.gray6};
          border-right: 1px solid ${({ theme }) => theme.colors.gray2};
          border-bottom: 1px solid ${({ theme }) => theme.colors.gray2};

          &:last-child {
            border-right: 0;
          }
        }
      }

      tbody {
        td {
          padding: 1.313rem;

          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1.375rem;
          color: ${({ theme }) => theme.colors.gray6};

          border-right: 1px solid ${({ theme }) => theme.colors.gray2};

          &:first-child {
            font-weight: 600;
          }

          &:last-child {
            border-right: 0;
          }
        }
      }
    }
  }

  button {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    transition: margin-top 0.5s;
    margin-top: ${({ infoVisible }) => (infoVisible ? '1rem' : '0px')};

    span {
      font-weight: 600;
      font-size: 1rem;
      color: ${({ theme }) => theme.colors.primary};
    }

    svg {
      color: ${({ theme }) => theme.colors.primary};
      transition: transform 0.5s;
      transform: ${({ infoVisible }) => infoVisible && 'rotate(-180deg)'};
    }
  }
`;
