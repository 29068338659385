import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Tooltip } from 'react-tooltip';
import { Input } from 'src/components/Input';
import { ArrowUpRight, MagnifyingGlass, X } from 'phosphor-react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { useTranslation } from 'react-i18next';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { getTextWidth } from 'src/utils/getTextWidth';
import api from 'src/models/service/api';
import { queryClient } from 'src/service/queryClient';
import ms from 'ms';

import {
  FeatureStoreVariablesContext,
  FeatureStoreVariablesRequest,
  FSVariables,
} from '../contexts/FeatureStoreVariableContext';
import {
  ContentSearch,
  FSHeader,
  FSVariableCard,
  FSVariableCardBody,
  FSVariableCardHeader,
  FSVariablesContainerWarning,
  FSVariablesContent,
  FeatureStoreVariablesContainer,
  MessageError,
} from './styles';

const LIMIT = 5;

export const FeatureStoreVariables: React.FC = () => {
  const [variables, setVariables] = useState<FSVariables[]>([]);
  const [showWarning, setShowWarning] = useState(false);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');

  const { isOpen, canDeleteElement, close, total } = useContext(
    FeatureStoreVariablesContext,
  );

  const containerRef = useRef<HTMLDivElement>(null);

  const {
    auth: { user },
    project: { id, selectedY },
  } = useSelector((state: RootState) => state);

  const { t: translate } = useTranslation();

  const loadFeatureStoreVariables = useCallback(async () => {
    for (let i = 0; i < total; i += LIMIT) {
      const key = ['variable-feature-store', id, selectedY?.id, LIMIT, i];

      let dataAux: FeatureStoreVariablesRequest;

      const contentQuery =
        queryClient.getQueryData<FeatureStoreVariablesRequest>(key);

      try {
        if (contentQuery) {
          dataAux = contentQuery;
        } else {
          dataAux = await queryClient.fetchQuery<FeatureStoreVariablesRequest>(
            key,
            async () => {
              const response = await api.get(
                `/projects/${id}/${selectedY?.id}/variables/feature-store?limit=5&skip=${i}`,
              );

              return response.data;
            },
            {
              staleTime: ms('1 day'),
            },
          );
        }
        setVariables((state) => [...state, ...dataAux.variables]);
      } catch {
        setShowWarning(true);
      }
    }
    setLoading(false);
  }, [id, selectedY?.id, total]);

  useEffect(() => {
    if (isOpen) {
      setSearch('');
      setLoading(true);
      setVariables([]);
      loadFeatureStoreVariables();
    }
  }, [isOpen, loadFeatureStoreVariables]);

  async function handleClose() {
    close();
  }

  function handleSearch(text: string) {
    setSearch(text.toLowerCase());
  }

  useEffect(() => {
    function isElementVisible(element: HTMLElement) {
      const rect = element.getBoundingClientRect();
      return rect.bottom >= 0 && rect.top <= window.innerHeight;
    }

    function handleScroll() {
      const footer = document.getElementById('footer');

      if (footer) {
        if (isElementVisible(footer)) {
          if (containerRef.current) {
            containerRef.current.style.height = `calc(100vh - (100vh - ${
              footer.getBoundingClientRect().top
            }px) - 74px)`;
          }
        } else if (containerRef.current) {
          containerRef.current.style.height = `calc(100vh - 74px)`;
        }
      }
    }

    if (!canDeleteElement) {
      handleScroll(); //executa a primeira vez pra definir a
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [canDeleteElement]);

  if (canDeleteElement) {
    return null;
  }

  const filtered = search
    ? variables.filter((variable) => {
        if (
          variable.indicator.short_name?.['en-us']
            ?.toLowerCase()
            .includes(search) ||
          variable.indicator.short_name?.['pt-br']
            ?.toLowerCase()
            .includes(search)
        ) {
          return true;
        }

        if (variable.serie.tag.toLowerCase().includes(search)) {
          return true;
        }

        if (
          variable.serie.region?.['en-us']?.toLowerCase().includes(search) ||
          variable.serie.region?.['pt-br']?.toLowerCase().includes(search)
        ) {
          return true;
        }

        if (
          variable.serie.primary_transformation?.['en-us']
            ?.toLowerCase()
            .includes(search) ||
          variable.serie.primary_transformation?.['pt-br']
            ?.toLowerCase()
            .includes(search)
        ) {
          return true;
        }

        if (
          variable.serie.primary_transformation?.['en-us']
            ?.toLowerCase()
            .includes(search) ||
          variable.serie.primary_transformation?.['pt-br']
            ?.toLowerCase()
            .includes(search)
        ) {
          return true;
        }

        if (
          variable.serie.second_transformation?.['en-us']
            ?.toLowerCase()
            .includes(search) ||
          variable.serie.second_transformation?.['pt-br']
            ?.toLowerCase()
            .includes(search)
        ) {
          return true;
        }

        return false;
      })
    : variables;

  const variablesToShow = filtered.map((variable) => ({
    code: variable.indicator.code,
    name:
      variable.indicator.short_name?.[user.language] ??
      variable.indicator.short_name['en-us'],
    region: variable.serie.region,
    aggregation: variable.serie.aggregation,
    primary: variable.serie.primary_transformation,
    second: variable.serie.second_transformation,
    tag: variable.serie.tag,
    isTagHoverEnabled:
      getTextWidth(variable.serie.tag, '400 0.875rem Inter') >=
      (filtered.length >= 4 ? 222 : 224),
    isNameHoverEnabled:
      getTextWidth(
        variable.indicator.short_name?.[user.language] ??
          variable.indicator.short_name['en-us'] ??
          '',
        '400 1.125rem Inter',
      ) > (filtered.length >= 4 ? 246 : 248),
    isActive: variable.indicator.is_active && user.isFeatureStore,
  }));

  return (
    <>
      <Tooltip
        id="feature-store-variable"
        className="customTooltipTheme maxWidthUnsetImportant"
      />
      <FeatureStoreVariablesContainer
        ref={containerRef}
        isOpen={isOpen}
        data-cy="feature-store-variable-container"
      >
        <FSHeader data-cy="feature-store-variable-header">
          <h2>{translate('featureStoreVariablesTitle')}</h2>
          <p>
            {translate('featureStoreVariablesDescription')}{' '}
            <b>{selectedY?.name}</b>.
          </p>
          <button
            type="button"
            onClick={handleClose}
            data-testid="feature-store-variable-button-close"
            data-cy="feature-store-variable-button-close"
            aria-label="close feature store variables button"
          >
            <X size={20} />
          </button>
        </FSHeader>

        {showWarning && (
          <FSVariablesContainerWarning
            visible
            text={translate('featureStoreVariablesSomeVariables')}
            data-cy="feature-store-variable-warning"
            data-testid="feature-store-variable-warning"
          />
        )}

        <ContentSearch>
          <Input
            icon={<MagnifyingGlass size={16} />}
            disabled={loading}
            placeholder={translate('featureStoreVariablesSearchVariables')}
            onChange={(e) => handleSearch(e.target.value)}
            data-testid="feature-store-variable-input"
            data-cy="feature-store-variable-input"
          />
        </ContentSearch>

        <FSVariablesContent data-cy="feature-store-variable-content">
          {variablesToShow.length ? (
            variablesToShow.map((variable) => (
              <FSVariableCard
                key={variable.tag}
                data-testid={`feature-store-variable-card-${variable.tag}`}
                data-cy={`feature-store-variable-card-${variable.tag}`}
              >
                <FSVariableCardHeader
                  isDisabled={variable.isActive === false}
                  data-tooltip-id={
                    variable.isActive === false || variable.isTagHoverEnabled
                      ? 'feature-store-variable'
                      : undefined
                  }
                  data-tooltip-html={
                    variable.isActive === false
                      ? translate('featureStoreVariablesIndicatorNotAvailable')
                      : variable.isTagHoverEnabled
                      ? variable.tag
                      : undefined
                  }
                >
                  <a
                    target="_blank"
                    href={`${window.location.origin}/feature-store/indicators/${variable.code}?region=${variable.region['en-us']}&aggregation=${variable.aggregation['en-us']}&primary=${variable.primary['en-us']}&second=${variable.second['en-us']}`}
                    rel="noreferrer"
                    data-testid={`feature-store-variable-card-link-${variable.tag}`}
                    data-cy={`feature-store-variable-card-link-${variable.tag}`}
                  >
                    <span
                      data-cy={`feature-store-variable-card-tag-${variable.tag}`}
                    >
                      {variable.tag}
                    </span>
                    <ArrowUpRight />
                  </a>
                </FSVariableCardHeader>

                <FSVariableCardBody>
                  <h4
                    data-tooltip-id={
                      variable.isNameHoverEnabled
                        ? 'feature-store-variable'
                        : undefined
                    }
                    data-tooltip-html={
                      variable.isNameHoverEnabled ? variable.name : undefined
                    }
                    data-cy={`feature-store-variable-card-name-${variable.name}`}
                  >
                    {variable.name}
                  </h4>
                  <table>
                    <thead>
                      <tr>
                        <th>{translate('featureStoreVariablesRegion')}</th>
                        <th>{translate('featureStoreVariablesPrimT')}</th>
                        <th>{translate('featureStoreVariablesSecT')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {variable.region?.[user.language] ||
                            variable.region['en-us']}
                        </td>
                        <td>
                          {variable.primary?.[user.language] ||
                            variable.primary['en-us']}
                        </td>
                        <td>
                          {variable.second?.[user.language] ||
                            variable.second['en-us']}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </FSVariableCardBody>
              </FSVariableCard>
            ))
          ) : (
            <MessageError
              data-testid="feature-store-variable-message-error"
              data-cy="feature-store-variable-message-error"
            >
              {translate('featureStoreVariablesNotFind')}
              <b>{`"${search}"`}</b>.
            </MessageError>
          )}
          {loading &&
            Array.from({ length: total - variables.length }).map((_, index) => (
              // eslint-disable-next-line react/jsx-indent
              <FSVariableCard
                key={`fs-variable-card-loading-${index.toString()}`}
                data-cy={`fs-variable-card-loading-${index.toString()}`}
                data-testid={`fs-variable-card-loading-${index.toString()}`}
              >
                <FSVariableCardHeader isDisabled>
                  <a href="" aria-label="loading">
                    <ContainerSkeleton
                      style={{ width: '100%', height: '1.375rem' }}
                      withLoading={false}
                    />
                    <ArrowUpRight />
                  </a>
                </FSVariableCardHeader>

                <FSVariableCardBody>
                  <ContainerSkeleton
                    style={{
                      width: '100%',
                      height: '1.6875rem',
                      marginBottom: '1rem',
                    }}
                    withLoading={false}
                  />

                  <table>
                    <thead>
                      <tr>
                        <th>{translate('featureStoreVariablesRegion')}</th>
                        <th>{translate('featureStoreVariablesPrimT')}</th>
                        <th>{translate('featureStoreVariablesSecT')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td aria-label="loading">
                          <ContainerSkeleton
                            style={{ width: '100%', height: '1.375rem' }}
                            withLoading={false}
                          />
                        </td>
                        <td aria-label="loading">
                          <ContainerSkeleton
                            style={{ width: '100%', height: '1.375rem' }}
                            withLoading={false}
                          />
                        </td>
                        <td aria-label="loading">
                          <ContainerSkeleton
                            style={{ width: '100%', height: '1.375rem' }}
                            withLoading={false}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </FSVariableCardBody>
              </FSVariableCard>
            ))}
        </FSVariablesContent>
      </FeatureStoreVariablesContainer>
    </>
  );
};
