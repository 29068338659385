import styled, { css } from 'styled-components';

interface ContentExport {
  status: 'started' | 'success' | 'error' | 'running';
}

export const ContainerExport = styled.div`
  position: fixed;
  bottom: 1.5rem;
  left: 3rem;

  z-index: 10000;
`;

export const ContentExport = styled.div<ContentExport>`
  position: relative;
  margin: 1.5rem auto;

  display: flex;
  align-items: center;
  gap: 1.5rem;

  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 32px 8px rgba(78, 78, 78, 0.08);

  border-radius: 8px;

  transition: all 0.5s;

  ${({ status }) =>
    status === 'started'
      ? css`
          gap: 1.5rem;
          padding: 2rem;
        `
      : css`
          gap: 1rem;
          padding: 1rem;
        `}

  ${({ status }) =>
    status === 'running' &&
    css`
      overflow: hidden;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0px;
        height: 0.25rem;
        width: 100%;

        border-end-end-radius: 8px;
        border-end-start-radius: 8px;

        background: ${({ theme }) => theme.colors.gray2};
      }

      &:after {
        @keyframes progress {
          0% {
            left: -50%;
          }

          100% {
            left: 100%;
          }
        }

        content: '';
        position: absolute;
        left: 0;
        bottom: 0px;

        height: 0.25rem;
        width: 50%;

        border-radius: 9999px;

        background: ${({ theme }) => theme.colors.primary};

        animation-duration: 2s;
        animation: progress 2s linear infinite;
      }
    `}

  > div:first-child {
    height: ${({ status }) => (status === 'started' ? '5rem' : '3rem')};
    width: ${({ status }) => (status === 'started' ? '5rem' : '3rem')};

    display: flex;
    align-items: center;
    justify-content: center;

    background: ${({ theme, status }) =>
      status !== 'error'
        ? `${theme.colors.primary}14`
        : `${theme.colors.red3}14`};

    border-radius: 8px;

    svg {
      color: ${({ theme, status }) =>
        status !== 'error' ? theme.colors.primary : theme.colors.red3};
      width: ${({ status }) => (status === 'started' ? '3rem' : '1.8rem')};
      height: ${({ status }) => (status === 'started' ? '3rem' : '1.8rem')};
    }
  }

  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    h4 {
      font-weight: 600;
      font-size: ${({ status }) => (status === 'started' ? '1.5rem' : '1rem')};
      line-height: 2rem;
      color: ${({ theme }) => theme.colors.gray6};
    }

    p {
      font-weight: 400;
      font-size: 1rem;
      line-height: 150%;
      color: ${({ theme }) => theme.colors.gray5};
    }
  }
`;
