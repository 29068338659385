import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 0.375rem;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 8px;
  color: black;
  input {
    flex: 1;
    border: none;
    border-radius: 5px;
    padding: 0.875rem;
    color: ${({ theme }) => theme.colors.gray6};
  }

  input:focus {
    outline: none;
  }
`;

export const Tag = styled.div`
  display: flex;
  max-width: calc(100% - 0.625rem);
  align-items: center;
  margin: 0.438rem 0;
  margin-right: 0.625rem;
  padding: 0.25rem 0.625rem;
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.primary};
  background-color: rgba(76, 148, 255, 0.12);
  gap: 0.625rem;

  span {
    max-width: calc(100% - 3.5rem);
    max-height: 100%;
    word-wrap: break-word;
  }

  button {
    display: flex;
    padding: 0.375rem;
    border: none;
    background-color: unset;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
  }
`;
