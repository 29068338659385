import React from 'react';

import { Warning } from 'phosphor-react';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';

import { ContainerModal, ContentButton } from './styles';

export type WarningObject = {
  title?: string;
  description?: string;
};

type LagsAndFeatureStoreModalProps = {
  visible?: boolean;
  setVisible: (value: boolean) => void;
  warningInfo?: WarningObject;
};

export const LagsAndFeatureStoreModal: React.FC<
  LagsAndFeatureStoreModalProps
> = ({ visible, setVisible, warningInfo }) => (
  <Modal visible={visible} setVisible={setVisible} style={{ padding: 0 }}>
    <ContainerModal>
      <Warning size="3rem" data-testid="warning-icon" />

      {warningInfo && (
        <>
          <h1>{warningInfo.title}</h1>
          <p>{warningInfo.description}</p>
        </>
      )}

      <ContentButton>
        <Button
          buttonType="primary"
          data-testid="modalFailedButtonOk"
          onClick={() => setVisible(false)}
          data-cy="button-modal-failed-ok"
        >
          Close
        </Button>
      </ContentButton>
    </ContainerModal>
  </Modal>
);
