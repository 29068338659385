import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { Container } from '../styles';
import { ClassificationBeginProject } from './components/Step1';
import { ClassificationSendVariables } from './components/Step2';
import { Validate } from './components/Step2/types';
import { SendModeling } from './components/Step3';
import { ClassificationForm } from './types';

export const regexFirstCharacter = /^[a-zA-Z]/;
export const regexInputProjectName = /^[a-zA-Z0-9_-\s]*$/;
export const regexLastCharacter = /[a-zA-Z0-9_-]$/;

export const ClassificationModelling: React.FC = () => {
  const [step, setStep] = useState(1);
  const [datasetFile, setDatasetFile] = useState<File>();
  const [validateWarning, setValidateWarning] = useState<Validate>();

  const classificationFormSchema = Yup.object().shape({
    projectName: Yup.string()
      .matches(regexFirstCharacter, 'projectErrorFirstCharacter')
      .matches(regexInputProjectName, 'projectErrorNoSpecialCharacters')
      .matches(regexLastCharacter, 'projectErrorLastCharacter')
      .max(50, 'projectErrorMaxCharacters')
      .min(3, 'projectErrorMinCharacters')
      .required('requiredField'),
    iconUrl: Yup.string(),
    file: Yup.object()
      .test('', 'requiredField', () => !!datasetFile)
      .test(
        'projectErrorValidFileFormat',
        'projectErrorInvalideFileFormat',
        () => {
          const formatFile = datasetFile?.name.split('.')[1];
          return formatFile === 'csv' || formatFile === 'xlsx';
        },
      ),
    datasetFile: Yup.object().shape({
      dataset_id: Yup.string().required('projectErrorInvalidFile'),
      variables: Yup.object().shape({
        x_options: Yup.array(Yup.string()).min(0),
        y_options: Yup.array(Yup.string()).min(1),
      }),
      y_values_count: Yup.array(
        Yup.object().shape({
          y_name: Yup.string(),
          values_count: Yup.array(
            Yup.object().shape({
              class: Yup.string(),
              count: Yup.number(),
            }),
          ).min(0),
        }),
      ).min(1),
    }),
    dependentVariable: Yup.string().required('requiredField'),
    explanatoryVariables: Yup.array(Yup.string())
      .required('requiredField')
      .min(1, 'explanatoryVariablesValidation'),
  });

  const {
    control,
    getValues,
    setValue,
    formState: { errors },
    trigger,
    reset,
  } = useForm<ClassificationForm>({
    resolver: yupResolver(classificationFormSchema),
  });

  return (
    <Container>
      {step === 1 && (
        <ClassificationBeginProject
          control={control}
          getValues={getValues}
          setValue={setValue}
          trigger={trigger}
          reset={reset}
          errors={errors}
          datasetFile={datasetFile}
          setDatasetFile={setDatasetFile}
          setStep={setStep}
        />
      )}

      {step === 2 && (
        <ClassificationSendVariables
          control={control}
          getValues={getValues}
          setValue={setValue}
          trigger={trigger}
          reset={reset}
          errors={errors}
          setStep={setStep}
          setValidateWarning={setValidateWarning}
        />
      )}

      {step === 3 && (
        <SendModeling
          control={control}
          getValues={getValues}
          setValue={setValue}
          trigger={trigger}
          reset={reset}
          errors={errors}
          setStep={setStep}
          validateWarning={validateWarning}
          setValidateWarning={setValidateWarning}
        />
      )}
    </Container>
  );
};
