const colors = [
  '#81B3FF',
  '#FFABD3',
  '#9AE6B4',
  '#D6BCFA',
  '#81E6D9',
  '#FBD38D',
  '#DC83C8',
  '#96E9F8',
  '#C0EB75',
  '#FF8B8B',
];

// return chart colors referents to 4I design system
export function getChartColor(index: number): string | undefined {
  let chartColor;
  if (index <= 9) {
    chartColor = colors[index];
  } else {
    chartColor = undefined;
  }
  return chartColor;
}
