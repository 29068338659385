import styled, { css } from 'styled-components';

type ContainerProps = {
  active: boolean;
};

export const Container = styled.button<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 40px;
  width: 40px;

  border-radius: 100%;
  padding: 0.5rem;

  cursor: pointer;

  transition: all 0.2s;

  svg {
    fill: ${({ active, theme }) =>
      active ? theme.colors.primary : theme.colors.gray4};
  }

  img,
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  ${({ active, theme }) =>
    active
      ? css`
          background: rgba(76, 148, 255, 0.08);
          color: ${theme.colors.primary};
          path {
            fill: ${theme.colors.primary};
            stroke: ${theme.colors.primary};
          }
        `
      : css`
          background: ${theme.colors.white};
          color: ${theme.colors.gray4};
          outline: 1px solid ${theme.colors.gray2} !important;

          path {
            fill: ${theme.colors.gray4};
            stroke: ${theme.colors.gray4};
          }

          &:hover {
            color: ${theme.colors.gray5};
            outline: 1px solid ${theme.colors.gray3} !important;

            path {
              fill: ${theme.colors.gray5};
              stroke: ${theme.colors.gray5};
            }
          }
        `};
`;
