const DownloadWidgetPTBRTranslations = {
  widgetDownloadStartTitle: 'Download',
  widgetDownloadStartDescription:
    'Acompanhe o progresso por meio deste cartão.',
  widgetDownloadRunning: 'Download em progresso',
  widgetDownloadFinished: 'Download completo.',
  widgetDownloadError: 'Ocorreu um erro ao baixar o arquivo.',
};

export default DownloadWidgetPTBRTranslations;
