import React, { useState } from 'react';

import { CaretDown } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { apiAdminV2 } from 'src/service/apiAdminV2';
import { queryClient } from 'src/service/queryClient';
import { MenuContainer, MenuOption } from 'src/components/Menu/styles';

import { MenuOverlay, SelectStatus } from './styles';

interface StatusMenu {
  userId: string;
  statusIsBlocked: boolean;
  managerId: string;
  blockOptionActive: boolean;
  blockOptionBlocked: boolean;
}

export const StatusMenu: React.FC<StatusMenu> = ({
  userId,
  statusIsBlocked,
  managerId,
  blockOptionActive,
  blockOptionBlocked,
}) => {
  const [statusMenu, setStatusMenu] = useState(false);
  const [requestRunning, setRequestRunning] = useState<boolean>(false);
  const { user } = useSelector((state: RootState) => state.auth);
  const { t: translate } = useTranslation();

  const changedStatus = async (status: boolean) => {
    setStatusMenu(false);
    setRequestRunning(true);
    if (user.isSupport || user.managerClientId) {
      try {
        const response = await apiAdminV2.put(
          `/clients/${managerId}/users/${userId}/is-blocked`,
          {
            is_blocked: status,
          },
        );

        if (response.status === 200) {
          queryClient.invalidateQueries('list users admin painel');
          queryClient.invalidateQueries('customer info');
          setStatusMenu(false);
        }
        setRequestRunning(false);
      } catch {
        setRequestRunning(false);
      }
    }
  };

  return (
    <SelectStatus openModal={statusMenu}>
      <p>
        {statusIsBlocked === true ? translate('Blocked') : translate('Active')}
      </p>
      <CaretDown
        size="1rem"
        onClick={() => !requestRunning && setStatusMenu(!statusMenu)}
        data-testid={`svg-open-menu-status-${userId}`}
        data-cy={`svg-open-menu-status-${userId}`}
      />
      {statusMenu && !requestRunning && (
        <MenuContainer visible style={{ top: '1.5rem', left: '-1rem' }}>
          <MenuOverlay
            data-testid="menu-overlay"
            onClick={() => setStatusMenu(false)}
          />
          <MenuOption
            position="start"
            type="button"
            onClick={() => changedStatus(false)}
            disabled={
              requestRunning || statusIsBlocked === false || blockOptionActive
            }
            data-testid="status-option-active"
            data-cy="status-option-active"
          >
            <p>{translate('Active')}</p>
          </MenuOption>
          <MenuOption
            position="end"
            type="button"
            onClick={() => changedStatus(true)}
            data-testid="status-option-blocked"
            data-cy="status-option-blocked"
            disabled={requestRunning || statusIsBlocked || blockOptionBlocked}
          >
            <p>{translate('Blocked')}</p>
          </MenuOption>
        </MenuContainer>
      )}
    </SelectStatus>
  );
};
