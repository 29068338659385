import { CheckBox } from 'src/components/CheckBox';
import styled, { css } from 'styled-components';

interface DisableProps {
  disabled?: boolean;
}

export const Container = styled.div<DisableProps>`
  display: flex;
  justify-content: center;
  flex: 1 0 17.5rem;
  height: 15.625rem;

  position: relative;
  max-width: 19.063rem;
  cursor: pointer;

  ${({ disabled, theme }) =>
    disabled &&
    css`
      cursor: not-allowed;
      background: ${theme.colors.white} !important;
      opacity: 0.48;
      p {
        color: ${theme.colors.gray6} !important;
      }
      img {
        filter: grayscale(1) !important;
      }
      span {
        cursor: not-allowed;
        color: ${theme.colors.gray5} !important;
        pointer-events: none;
        border-color: ${theme.colors.gray4} !important;
      }
    `}
`;

export const Content = styled.div<DisableProps>`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 0.875rem 0.875rem 1.5rem;
  width: 100%;
  max-height: 15.625rem;
  border: ${({ theme }) => `1px solid ${theme.colors.gray2}}`};
  box-sizing: border-box;
  border-radius: 8px;
  word-break: break-word;

  img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 4px;
  }

  span {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray5};
    margin-top: 0.625rem;
  }

  p {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray6};
    margin-top: 0.25rem;
  }

  transition: 0.3s;
`;

export const CheckBoxFeatureStore = styled(CheckBox)`
  p {
    color: ${({ theme }) => theme.colors.gray6};
  }
  span {
    top: unset;
    margin-right: 1rem;
  }
`;

export const DivCheckBox = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 2.25rem;
  right: 1rem;
`;

export const ButtonDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: ${({ theme }) => theme.colors.gray0};
  border-top: 1px solid ${({ theme }) => theme.colors.gray2};
  padding: 1.5rem 2rem;
  margin: 1.5rem -1.5rem -1.5rem;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  @media (max-width: 1366px) {
    padding: 1rem 1.5rem;
  }
`;

export const PremiumDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  img {
    width: 1.063rem;
    height: 1.063rem;
    margin-right: 0.75rem;
  }
`;
