import styled from 'styled-components';

export const ContainerCardStep3 = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 4rem;
`;

export const ContentCardsStep3 = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  @media (max-width: 640px) {
    justify-content: center;
  }
`;

interface Card {
  isSelected: boolean;
  isError: boolean;
}

export const Card = styled.button<Card>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: ${({ isSelected }) => (isSelected ? 'calc(100% - 0.25rem)' : '100%')};
  height: 6rem;
  padding: 1rem 0;

  border-radius: 8px;
  border: ${({ theme, isSelected, isError }) =>
    isError
      ? `1px solid ${theme.colors.red2}`
      : isSelected
      ? `3px solid ${theme.colors.primary}`
      : `1px solid ${theme.colors.gray2}`};

  @media (max-width: 640px) {
    padding: 1rem;
    width: auto;
    height: auto;
  }

  > p {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2rem;
    color: ${({ theme, isSelected }) =>
      isSelected ? theme.colors.primary : theme.colors.gray6};
    opacity: ${({ isSelected }) => (isSelected ? 1 : 0.8)};

    transition: all 0.2s ease-in-out;

    @media (max-width: 640px) {
      font-size: 1rem;
    }
  }

  span {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: ${({ theme }) => theme.colors.gray4};
    opacity: ${({ isSelected }) => (isSelected ? 0.8 : 1)};

    transition: all 0.2s ease-in-out;

    @media (max-width: 640px) {
      font-size: 0.75rem;
    }
  }
`;

export const ContentError = styled.div`
  position: absolute;
  bottom: -1.563rem;

  p {
    font-size: 0.875rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.red2};
  }
`;
