const createSeriesPTBRTranslate = {
  addNewSerie: 'Adicionar novas séries à XX',
  addNewSerieText:
    'Quando terminar, clique em “Prosseguir” para revisar todas séries que serão adicionadas',
  addNewSerieRegion: 'Região',
  addNewSerieAggregation: 'Periodicidade/Agregação',
  addNewSeriePrimary: 'Transformação primária',
  addNewSerieSecondary: 'Transformação secundária',
  addNewSeriesWarning: 'É possível selecionar apenas 150 combinações',
  addNewSerieContinue: 'Prosseguir',
  addNewSerieUnableToLoad: 'Não foi possível carregar',
};

export default createSeriesPTBRTranslate;
