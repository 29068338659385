const creategGroupModalENTranslate = {
  createGroupModalCreateTitle: 'Create new group',
  createGroupModalEditTitle: 'Edit your group',
  createGroupModalGroupIcon: 'Group Icon',
  createGroupModalChooseIconTitle: 'Choose your group icon',
  createGroupModalChooseIconDescrip:
    'This icon will help you to easily identify your group later on.',
  createGroupModalPlaceholderName: 'Type the name of your new group',
  createGroupModalPlaceholderDescription:
    'Type something that describes your group',
  createGroupModalRequiredField: 'Required field',
  createGroupModalNameMustLeast3Characters:
    'Name must be at least 3 characters',
  createGroupModalNameMax30Caracteres: 'Name must be at most 30 characters',
  createGroupModalNameFirstCharacterMustNotSpace:
    'The first character must not be a space',
  createGroupModalNameLastCharacterMustNotSpace:
    'The last character must not be a space',
};

export default creategGroupModalENTranslate;
