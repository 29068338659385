const modalAdvancedENUSTranslations = {
  rankingCriteria: 'Ranking Criteria',
  rankingCriteriaDescr:
    'Customize the statistical measures used to evaluate models performance.',
  accuracyCriteriaLabel: 'Accuracy Criteria',
  accuracyCriteriaPlaceholder: 'Choose a variable',
  accuracyCriteriaInfo:
    'Which criterion to measure the accuracy of the forecast during the CV (can be MAPE, WMAPE, MPE or RMSE)',
  crossValidationSummaryLabel: 'Cross-Validation Summary',
  crossValidationSummaryPlaceholder: 'Choose a variable',
  crossValidationSummaryInfo:
    'Should we calculate the summary statistic of the accuracy measures using the mean or the median? <br />The mean is the most usual. However, the median is more robust to outliers and might be a better statistic when the cross-validation is affected by extreme situations, such as the Covid 19 pandemic.',
  crossValidationSummaryOptMean: 'Mean',
  crossValidationSummaryOptMedian: 'Median',

  explanatoryFeatures: 'Explanatory Features',
  explanatoryFeaturesDescr:
    'Additional options regarding how we threat the set of explanatory features.',
  avoidCollinearity: 'Avoid collinearity',
  avoidCollinearityInfo: `Collinearity harms inference regarding coefficients' interpretation (e.g., many times generate opposite signs than expected) but does not affect forecast values per se.<br />If two variables are highly correlated, they share a lot of information, and our modeling process takes that into account, avoiding highly correlated variables in the same estimation.`,
  explVarAutoForecast: 'Explanatory Variables Auto - Forecast',
  explVarAutoForecastInfo:
    'Forecast explanatory variables in order to avoid NAs in future values.',
  allowDrift: 'Allow drift',
  allowDriftInfo:
    'A parameter that if TRUE enables ARIMA models to search for the best specification taking into account the drift term (i.e. a constant added every period to the differenced series).',

  configureExclusion: 'Configure Exclusion',
  configureExclusionDescr:
    'Define up to 3 groups of explanatory variables that should not be in the same model. Each defined group should have at least 2 variables.',
  group1: 'Group 1',
  group2: 'Group 2',
  group3: 'Group 3',
  exclusionsPlaceholder: 'Variables',

  featureSelection: 'Feature Selection',
  featureSelectionDescr:
    'Customize the way features will be pre-selected before they are tested on models. Impact linear models only.',
  lasso: 'Lasso',
  lassoInfo:
    'Features are selected using coefficients different from zero in lasso estimation (parametric method).',
  randomForest: 'Random Forest',
  randomForestInfo:
    'Features are selected based on importance ranking (via features permutation) from Random Forest (non-parametric method).',
  correlationFilter: 'Correlation Filter',
  correlationFilterInfo: `Features are selected based on Pearson's correlation filter (parametric method).`,
  mathodsDisabledMessage: 'Select at least one method.',
  goldenVariables: 'Golden Variables',
  goldenVariablesPlaceholder: 'Variables',
  goldenVariablesInfo:
    'Here you can add variables that you would like to see for sure in at least one of the estimated models.',
  goldenVariablesWarning: `Setting golden variables may hinder final accuracy results. The impacts are stronger the more golden variables you choose.`,
  additionalConfigurationTitle: 'Additional Configuration',
  additionalConfigurationTitleDescription:
    'Define the cross-validation settings and the technical configuration of your modeling process.',
};

export default modalAdvancedENUSTranslations;
