export const MySeriesPTBRTranslate = {
  mySeriesTitle: 'Minhas Séries',
  mySeriesDescription: 'Lista de todas as séries externas que você fez upload',
  mySeriesYouHaveNotUploaded: 'Você ainda não subiu nenhuma série',
  mySeriesClickTheButtonAbove:
    'Clique no botão acima e faça o upload de suas séries modeladas externamente.',
  mySeriesInvalidFormatFile: 'Formato de arquivo inválido',
  mySeriesSearchPlaceholder: 'Procurar série',
  mySeriesSearchFail: 'Não foi possível encontrar nenhum série contendo',
  mySeriesFetchError: 'Não foi possível carregar as séries',
  mySeriesButtonUpload: 'Enviar novas séries',
  mySeriesUploadYourSeries: 'Faça o upload de suas séries',
  mySeriesUploading: 'fazendo upload',
  mySeriesCreateSerieErrorTitle: 'Falha ao criar',
  mySeriesCreateSerieErrorDescription:
    'Ocorreu um erro ao criar a série {{serie}}. Por favor, tente novamente mais tarde.',
  mySeriesDeleteSerieErrorTitle: 'Falha ao deletar',
  mySeriesDeleteSerieErrorDescription:
    'Ocorreu um erro ao deletar a série {{serie}}. Por favor, tente novamente mais tarde.',
  mySeriesUploadFileUploadYourFile: 'Faça o upload do seu arquivo',
  mySeriesUploadFileDragItHere:
    'Arraste-o aqui ou clique para selecioná-lo manualmente.',
  mySeriesUploadFileViewRequirements: 'Veja os requisitos',
  mySeriesUploadFileEnterTheTag: 'Digite a tag para essa série',
  mySeriesOverwriteTitle_one: 'Série já disponível',
  mySeriesOverwriteTitle_other: 'Séries já disponíveis',
  mySeriesOverwriteDescription_one:
    'A série {{series}} já está disponível.<br/>Deseja sobrescrever a série existente?',
  mySeriesOverwriteDescription_other:
    'As séries {{series}} já estão disponíveis.<br/>Deseja sobrescrever as séries existentes?',
  mySeriesUploadErrorTitle: 'Não é possível fazer upload',
  mySeriesUploadErrorLeast3Characters:
    'Os nomes das séries devem conter pelo menos 3 caracteres.',
  mySeriesUploadErrorMaximum50Characters:
    'Os nomes das séries devem conter no máximo 50 caracteres.',
  mySeriesUploadErrorDefault:
    'Ocorreu um erro ao fazer upload. Por favor, tente novamente mais tarde.',
  mySeriesNoEspecialCharacters: 'Caracteres especiais não são permitidos',

  mySeriesRequirementsTitle: 'Requisitos do arquivo de séries',
  mySeriesRequirements1: 'A primeira linha deve conter os nomes das colunas',
  mySeriesRequirements2:
    'Deve ter no mínimo duas colunas: uma coluna de data e uma coluna numérica com a variável e suas observações',
  mySeriesRequirements3:
    'O nome da variável deve ter até 50 caracteres e não deve utilizar caracteres especiais, números no início e espaço',
  mySeriesRequirements4:
    'A variável deve conter apenas uma observação por data (por exemplo, o conjunto de dados não deve ter duas linhas com observação para janeiro de 2020)',
  mySeriesRequirements5:
    'Não deve haver dados faltantes no meio da série temporal (nem data e nem observação)',
  mySeriesRequirements6: 'A frequência da série deve ser mensal',
  mySeriesMultipleDeletions: 'Deletar várias',
  mySeriesDeleting: 'Deletando',

  mySeriesConfirmDeletionTitle: 'Tem certeza de que deseja apagar a série?',
  mySeriesConfirmDeletionDescription:
    'Ela será removida de todas as suas visualizações, incluindo Workspaces.',

  mySeriesConfirmMultipleDeletionsTitle:
    'Tem certeza de que deseja apagar as séries?',
  mySeriesConfirmMultipleDeletionsDescription:
    'Elas serão removidas de todas as suas visualizações, incluindo Workspaces.',
};
