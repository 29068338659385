import React from 'react';

import { useTranslation } from 'react-i18next';

import { Container } from './styles';
import { ConfidenceProjectionTagProps } from './types';

export const ConfidenceProjectionTag: React.FC<
  ConfidenceProjectionTagProps
> = ({ level }) => {
  const { t: translate } = useTranslation();

  const LEVEL_TRANSLATIONS = {
    great: translate('confidenceProjectionTagGreat'),
    good: translate('confidenceProjectionTagGood'),
    average: translate('confidenceProjectionTagAverage'),
    poor: translate('confidenceProjectionTagPoor'),
  };

  return (
    <Container level={level}>
      <p data-testid="text-confidence-projection-tag">
        {LEVEL_TRANSLATIONS[level]}
      </p>
    </Container>
  );
};
