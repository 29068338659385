export const TutorialConfigPTBRTranslate = {
  tutorialOf: 'de',
  tutorialStart: 'Começar',
  tutorialEnd: 'Finalizar',
  tutorialTitleStep1: 'Configurando seus filtros',
  tutorialDescriptionStep1:
    'Descubra como configurar filtros hierárquicos e reativos que proporcionam uma organização em múltiplos níveis, ajustando-se automaticamente às suas escolhas.',
  tutorialTitleStep2: 'Informações importantes',
  tutorialFirstInfoStep2: 'É possível criar no máximo 3 filtros.',
  tutorialSecondInfoStep2:
    'Se uma variável não for adicionada a nenhuma opção do filtro, ela será automaticamente incluída na opção "Não definido".',
  tutorialThirdInfoStep2:
    'Não é possível adicionar uma variável a mais de uma opção do filtro simultaneamente. Por exemplo, um iPhone 13 não pode estar nas opções "Android" e "iOS" do filtro "Sistema Operacional" ao mesmo tempo. É necessário escolher apenas uma das opções para classificar a variável.',
  tutorialTitleStep3: 'Criação do filtro',
  tutorialDescriptionStep3:
    'Para começar, crie seu primeiro filtro definindo um nome e suas opções.',
  tutorialTitleStep4: 'Seleção de variáveis',
  tutorialDescriptionStep4:
    'Escolha quais variáveis você deseja associar a uma opção específica do filtro.',
  tutorialTitleStep5: 'Seleção da opção e envio das variáveis',
  tutorialDescriptionStep5:
    'Escolha a opção que deseja associar as variáveis e clique no botão para enviá-las.',
  tutorialTitleStep6: 'Visualização do filtro',
  tutorialDescriptionStep6:
    'Monitore o progresso em tempo real à medida que seu filtro toma forma.',
};
