import styled, { css } from 'styled-components';

interface ContainerProps {
  size: 'sm' | 'md';
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > strong {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.438rem;
    color: ${({ theme }) => theme.colors.gray6};
    text-align: center;
  }

  > p {
    text-align: center;
    color: ${({ theme }) => theme.colors.gray5};
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: 400;
  }

  ${({ size }) =>
    size === 'md'
      ? css`
          height: 18.75rem;

          img {
            height: 10rem;
            width: 10rem;
          }

          > strong {
            margin-top: 1.5rem;
          }

          > p {
            margin-top: 1rem;
          }
        `
      : css`
          padding: 0.75rem 0;

          img {
            height: 8rem;
            width: 8rem;
          }

          > strong {
            margin-top: 1.25rem;
          }

          > p {
            margin-top: 0.75rem;
          }
        `}
`;
