import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;

  gap: 1rem;
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-start;

  gap: 1rem;

  margin-bottom: 0.5rem;
`;

type FavoriteOption = {
  isActive?: boolean;
};

export const FavoriteOption = styled.span<FavoriteOption>`
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.gray6 : theme.colors.gray4};

  font-weight: ${({ isActive }) => (isActive ? 500 : 400)};

  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  line-height: normal;

  cursor: pointer;
`;

export const Slash = styled(FavoriteOption)`
  cursor: default;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  align-self: stretch;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  color: ${({ theme }) => theme.colors.gray4};

  span {
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375rem;
    letter-spacing: 0.07rem;
  }

  svg {
    width: 1rem;
    height: 1rem;
  }
`;

export const AddNew = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  color: ${({ theme }) => theme.colors.primary};
  text-align: center;

  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem;

  svg {
    width: 1.125rem;
    height: 1.125rem;
  }
`;

export const ListFavoritesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;

  max-width: 100%;

  /* white-space: nowrap; */

  padding-bottom: 0.5rem;

  margin: 1rem 0;
  gap: 1rem;

  ::-webkit-scrollbar {
    width: 0.313rem;
    height: 0.313rem;
    padding: 0.063rem;
  }

  ::-webkit-scrollbar-track {
    transition: 0.2s;
    margin: 0.188rem;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }
`;

export const ContainerMaintenance = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: flex-start;

  width: 100vw;
  max-height: 5.375rem;

  gap: 1rem;

  overflow: hidden;

  img {
    height: 5.375rem;
    width: 5.375rem;
  }
`;

export const MaintenanceInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;

  gap: 0.5rem;
  padding-top: 1.5625rem;
  height: 7.5rem;

  h5 {
    font-weight: 600;
    font-size: 0.8125rem;
    line-height: 1.438rem;
    color: ${({ theme }) => theme.colors.gray6};
  }

  p {
    color: ${({ theme }) => theme.colors.gray5};
    font-size: 0.8125rem;
    line-height: 1.125rem;
    font-weight: 400;
  }
`;
