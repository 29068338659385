import styled from 'styled-components';

export const Container = styled.div`
  section {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    > div {
      flex: 1 1 18.75rem;
    }
  }

  .select {
    width: 20rem;
    margin-bottom: 1.5rem;

    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }

  .relative {
    position: relative;
  }

  h5 {
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    color: ${({ theme }) => theme.colors.gray8};
  }

  > p {
    font-size: 1rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gray6};
  }

  .css-26l3qy-menu {
    z-index: 700;
  }
`;

export const ModelListError = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray5};
`;

export const ModelList = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 32.5rem;
  flex-basis: 20rem;
  @media only screen and (max-width: 700px) {
    max-width: 100%;
  }
  button {
    margin-top: 1.5rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  > div + div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
`;

export const ContentButton = styled.div`
  display: flex;
  flex-direction: row !important;
  justify-content: flex-end !important;

  button {
    margin-top: 0;
    max-width: 13.438rem;
  }

  @media (max-width: 1260px) {
    button {
      margin-top: 1.5rem;
    }
  }

  @media only screen and (max-width: 600px) {
    justify-content: center;
    button {
      width: 100%;
      display: flex;
      justify-content: center;

      width: 12.563rem;
      height: 2.875rem;
      font-size: 0.875rem !important;
      padding: 0.75rem 1.5rem !important;
    }
  }
`;

export const ContainerModelInfo = styled.div`
  flex-basis: 12.5rem !important;
`;

export const ModelPerformanceContainer = styled.div`
  position: relative;
`;

export const ModelPerformanceInfo = styled.div`
  display: flex;
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;

  svg {
    color: ${({ theme }) => theme.colors.gray4};
  }
`;
