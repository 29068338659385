import axios from 'axios';

const MAX_REQUEST_COUNT = 100;
const INTERVAL_MS = 100;
let PENDING_REQUESTS = 0;

const baseURL = process.env.REACT_APP_API_WORKSPACE_URL;

const apiWorkspace = axios.create({
  baseURL,
});

export const interceptorControlRequest = apiWorkspace.interceptors.request.use(
  (config) =>
    new Promise((resolve) => {
      const interval = setInterval(() => {
        if (PENDING_REQUESTS < MAX_REQUEST_COUNT) {
          PENDING_REQUESTS++;
          clearInterval(interval);
          resolve(config);
        }
      }, INTERVAL_MS);
    }),
);

export const interceptorControlResponse =
  apiWorkspace.interceptors.response.use(
    (response) => {
      PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
      return Promise.resolve(response);
    },
    (error) => {
      PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
      return Promise.reject(error);
    },
  );

export const interceptorResponseTokenExpired =
  apiWorkspace.interceptors.response.use(
    (response) => Promise.resolve(response),
    (error) => {
      if (
        error?.response?.status === 401 &&
        error?.response?.data?.detail?.detail ===
          'Authentication token is invalid or expired.'
      ) {
        window.location.reload();
      }
      return Promise.reject(error);
    },
  );

export const setDefaultWorkspaceHeaderLanguage = (language: string): void => {
  apiWorkspace.defaults.headers['Accept-Language'] = language;
};

export default apiWorkspace;
