import React, { useRef, useState } from 'react';

import format from 'date-fns/format';
import { enUS, ptBR } from 'date-fns/locale';
import { CaretDown } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Avatar } from 'src/components/Avatar';
import { RootState } from 'src/redux/store';
import { ArrowRight } from '@phosphor-icons/react';

import {
  DeadLineUpdateType,
  ManageActionUser,
  RenameStep,
} from '../Historical';
import {
  AdjustmentValue,
  CardTitle,
  Changes,
  Container,
  YText,
  DeadLineInfo,
  Message,
  ShowMoreButton,
  Tag,
} from './styles';

export type Adjustments = {
  name: string;
  ys: {
    before: number;
    after: number;
    id: number;
    date: string;
    tooltip: string;
  }[];
};

interface HistoricalCard {
  adjustments: Adjustments[];
  approvalMessage: string | null;
  awaitingApprovalMessage: string | null;
  deadLine: DeadLineUpdateType;
  disapprovalMessage: string | null;
  discardedAdjustments: boolean;
  email: string;
  hasEditionStartedMessage: boolean;
  hasStepBeenRemoved: boolean;
  isBaseLine: boolean;
  isNewStage: boolean;
  manageUser: ManageActionUser;
  renamedStep: RenameStep;
  tag: 'approved' | 'edition' | 'governance' | 'revision' | 'reproved';
  versionFinalized: boolean;
}

export const HistoricalCard: React.FC<HistoricalCard> = ({
  adjustments,
  approvalMessage,
  awaitingApprovalMessage,
  deadLine,
  disapprovalMessage,
  discardedAdjustments,
  email,
  hasEditionStartedMessage,
  hasStepBeenRemoved,
  isBaseLine,
  isNewStage,
  manageUser,
  renamedStep,
  tag,
  versionFinalized,
}) => {
  const [showMore, setShowMore] = useState(false);
  const changesRef = useRef<HTMLDivElement | null>(null);

  const { t } = useTranslation();

  const {
    auth: { user },
  } = useSelector((state: RootState) => state);

  function handleShowMore() {
    setShowMore((state) => !state);
  }

  const changes = adjustments
    ? adjustments.reduce((acc, current) => acc + current.ys.length, 0)
    : 0;

  function calcMaxHeight() {
    if (changesRef.current) {
      let quantityPTags = 0;
      let secondPTagIndex: number | null = null;
      let lastPTagIndex: number | null = null;

      for (let i = 0; i < changesRef.current.children.length; i++) {
        if (changesRef.current.children[i] instanceof HTMLParagraphElement) {
          quantityPTags++;
          if (quantityPTags === 2) {
            secondPTagIndex = i;
          }
          lastPTagIndex = i;
        }
      }

      if (secondPTagIndex && showMore === false) {
        return (
          changesRef.current.children[secondPTagIndex].getBoundingClientRect()
            .bottom - changesRef.current.getBoundingClientRect().top
        );
      }

      if (lastPTagIndex) {
        return (
          changesRef.current.children[lastPTagIndex].getBoundingClientRect()
            .bottom - changesRef.current.getBoundingClientRect().top
        );
      }
    }

    return null;
  }

  function getTitle() {
    if (approvalMessage) {
      return t('workspaceOverviewLogsApproved');
    }
    if (disapprovalMessage) {
      return t('workspaceOverviewLogsRejected');
    }
    if (awaitingApprovalMessage) {
      return t('workspaceOverviewLogsWaitingForApproval');
    }
    if (changes) {
      return `${t('workspaceOverviewLogsMade')} ${changes} ${
        changes > 1
          ? t('workspaceOverviewLogsChanges')
          : t('workspaceOverviewLogsChange')
      }`;
    }
    if (isNewStage) {
      return t('workspaceOverviewLogsCreatedNewStep');
    }

    if (discardedAdjustments) {
      return t('workspaceOverviewLogsDiscardedAdjustments');
    }

    if (hasStepBeenRemoved) {
      return t('workspaceOverviewLogsRemovedStage');
    }

    if (hasEditionStartedMessage) {
      return t('workspaceOverviewLogsEditionStarted');
    }

    if (versionFinalized) {
      return t('historicalCardEndPlanningFlow');
    }
  }

  const maxHeight = calcMaxHeight();

  const title = getTitle();

  const dateFormatterDeadLine = (date: {
    start: string;
    end: string;
  }): string => {
    const dateFormat = user.language === 'en-us' ? 'MM/dd' : 'dd/MM';

    return `${format(new Date(date.start.replace(':00Z', '')), dateFormat, {
      locale: user.language === 'en-us' ? enUS : ptBR,
    })} - ${format(new Date(date.end.replace(':00Z', '')), dateFormat, {
      locale: user.language === 'en-us' ? enUS : ptBR,
    })}`;
  };

  const message =
    approvalMessage || awaitingApprovalMessage || disapprovalMessage;

  const hasShowMore = changes > 2;

  const { approverUpdateDeadLine, editorUpdateDeadLine } = deadLine;

  const addEditorPermissionMessage =
    manageUser?.action === 'added_user_step' && manageUser.profile === 'editor'
      ? `${t('historicalCardAddEditorPermission')} ${manageUser.insertedUser}`
      : undefined;

  const removeEditorPermissionMessage =
    manageUser?.action === 'removed_user_step' &&
    manageUser.profile === 'editor'
      ? `${t('historicalCardRemoveEditorPermission')} ${manageUser.removedUser}`
      : undefined;

  const addApproverPermissionMessage =
    manageUser?.action === 'added_user_step' &&
    manageUser.profile === 'approver'
      ? `${t('historicalCardAddApproverPermission')} ${manageUser.insertedUser}`
      : undefined;

  const removeApproverPermissionMessage =
    manageUser?.action === 'removed_user_step' &&
    manageUser.profile === 'approver'
      ? `${t('historicalCardRemoveApproverPermission')} ${
          manageUser.removedUser
        }`
      : undefined;

  const renamedStepMessage = renamedStep
    ? `${t('historicalCardRenameStep')} ${renamedStep.after}`
    : undefined;

  const tagTitle = {
    approved: t('approval'),
    edition: t('edition'),
    governance: t('governance'),
    reproved: t('reproved'),
    revision: t('revision'),
  } as const;

  return (
    <Container data-testid="card-container" isBaseLine={isBaseLine}>
      <Tag status={tag} data-testid="tag-title">
        {tagTitle[tag]}
      </Tag>

      {title && <CardTitle data-testid="card-title">{title}</CardTitle>}

      {addEditorPermissionMessage && (
        <CardTitle data-testid="message-add-editor-permission">
          {addEditorPermissionMessage}
        </CardTitle>
      )}
      {removeEditorPermissionMessage && (
        <CardTitle data-testid="message-remove-editor-permission">
          {removeEditorPermissionMessage}
        </CardTitle>
      )}
      {addApproverPermissionMessage && (
        <CardTitle data-testid="message-add-approver-permission">
          {addApproverPermissionMessage}
        </CardTitle>
      )}
      {removeApproverPermissionMessage && (
        <CardTitle data-testid="message-remove-approver-permission">
          {removeApproverPermissionMessage}
        </CardTitle>
      )}
      {renamedStepMessage && (
        <CardTitle data-testid="message-renamed-step">
          {renamedStepMessage}
        </CardTitle>
      )}

      {(approverUpdateDeadLine || editorUpdateDeadLine) && (
        <div>
          {(approverUpdateDeadLine || editorUpdateDeadLine) && (
            <CardTitle>{t('historicalCardUpdateDeadLine')}</CardTitle>
          )}
          {editorUpdateDeadLine && (
            <DeadLineInfo data-testid="editor-update-deadline">
              {t('adjusted')}:
              <span>
                {dateFormatterDeadLine(editorUpdateDeadLine.from.date)}
                <ArrowRight />
                {dateFormatterDeadLine(editorUpdateDeadLine.to.date)}
              </span>
            </DeadLineInfo>
          )}
          {approverUpdateDeadLine && (
            <DeadLineInfo data-testid="approver-update-deadline">
              {t('approved')}:
              <span>
                {dateFormatterDeadLine(approverUpdateDeadLine.from.date)}
                <ArrowRight />
                {dateFormatterDeadLine(approverUpdateDeadLine.to.date)}
              </span>
            </DeadLineInfo>
          )}
        </div>
      )}

      <Changes ref={changesRef} maxHeight={maxHeight}>
        {message ? (
          <Message data-testid="card-message">{message}</Message>
        ) : (
          adjustments.map((adjustment, index) => (
            <React.Fragment key={`changes-${index.toString()}`}>
              <YText
                data-testid={`card-adjustments-y-name-${adjustment.name.toLowerCase()}`}
              >
                {adjustment.name}
              </YText>
              {adjustment.ys.map((y) => (
                <Message
                  key={y.id}
                  data-testid={`card-adjustments-${y.date.toLowerCase()}`}
                  style={{ letterSpacing: '0.05rem' }}
                >
                  {y.date.toUpperCase()}
                  {': '}
                  <AdjustmentValue>{y.tooltip}</AdjustmentValue>
                </Message>
              ))}
            </React.Fragment>
          ))
        )}
      </Changes>

      {hasShowMore && (
        <ShowMoreButton
          onClick={handleShowMore}
          showMore={showMore}
          data-testid="button-show-more-or-less"
        >
          <CaretDown size="12px" />
          {showMore
            ? t('workspaceOverviewLogsShowLess')
            : t('workspaceOverviewLogsShowMore')}
        </ShowMoreButton>
      )}

      <Avatar email={email} size="sm" />
    </Container>
  );
};
