import React from 'react';

import parseHTML from 'html-react-parser';

import { Button } from '../Button';
import { ContainerCard, Separator } from './styles';

type CardProps = {
  textCard: string;
  textDescription?: string;
  textButtonOne?: string;
  onClickButtonOne?: () => void;
  textButtonTwo?: string;
  onClickButtonTwo?: () => void;
  hrOn?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export const Card: React.FC<CardProps> = ({
  textCard,
  textDescription,
  textButtonOne,
  onClickButtonOne,
  textButtonTwo,
  onClickButtonTwo,
  hrOn = false,
  ...props
}) => (
  <ContainerCard
    data-cy={`card-${textCard?.replaceAll(' ', '-').toLocaleLowerCase()}`}
    data-testid={`card-${textCard?.replaceAll(' ', '-').toLocaleLowerCase()}`}
    {...props}
  >
    <div style={{ flexGrow: 1 }}>
      <h2>{textCard}</h2>
      {hrOn ? <Separator data-testid="card-hr" /> : null}
      {textDescription && <p>{parseHTML(textDescription)}</p>}
    </div>

    {textButtonOne && onClickButtonOne && (
      <Button
        buttonType="primary"
        onClick={onClickButtonOne}
        data-testid="card-button-one"
      >
        {textButtonOne}
      </Button>
    )}

    {textButtonTwo && onClickButtonTwo && (
      <Button
        buttonType="primary"
        onClick={onClickButtonTwo}
        data-testid="card-button-two"
        style={{ marginLeft: '1rem' }}
      >
        {textButtonTwo}
      </Button>
    )}
  </ContainerCard>
);
