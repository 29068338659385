import React, { useContext, useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { RootState } from 'src/redux/store';
import api from 'src/models/service/api';
import { arrayIcon, checkIconURL } from 'src/utils/icons/handleProjectIcon';
import {
  ArrowLeft,
  ArrowUpRight,
  Clock,
  DotsThreeVertical,
} from 'phosphor-react';
import { YBusinessStatus, insert } from 'src/models/redux/reducers/Project';
import { changeIsFinish } from 'src/models/redux/reducers/ProjectOverviewExportTimeSeries';
import { resetAIUserSelectionOptions } from 'src/models/redux/reducers/AIUserSelectionOptions';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { MenuContainer, MenuOption } from 'src/components/Menu/styles';
import { VersionInfo } from 'src/components/VersionInfo';

import {
  Container,
  MenuOverlay,
  ModelUpdateReturnButton,
  ProjectDetails,
  ProjectMenuButton,
} from './styles';
import { ModelUpdateContext } from '../../contexts/ModelUpdateContext';
import { Project } from './types';

export const ProjectDetailOnSideBar: React.FC = () => {
  const {
    project,
    sideBar: { isExpanded },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const { t: translate } = useTranslation();

  const id = location.pathname.replace('/models/time-series/', '').slice(0, 24);

  const {
    data: projectData,
    isLoading,
    isFetching,
  } = useQuery(
    ['time series project', id],
    async () => {
      const response = await api.get<Project>(`/projects/${id}`);

      return response.data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled: !!id && !project?.id,
      onError: () => {
        navigate('/models/projects');
      },
    },
  );

  useEffect(() => {
    if (
      projectData &&
      (!projectData.id ||
        !projectData.name ||
        !(
          projectData.status === 'success' ||
          projectData.status === 'partial_success'
        ))
    ) {
      navigate('/models/projects');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectData, id]);

  useEffect(() => {
    if (projectData && !project?.id) {
      const yBusinessAdjusted = Object.keys(
        projectData?.business?.y_status ?? {},
      ).map((key) => ({
        y: key,
        status: projectData?.business?.y_status?.[key] as YBusinessStatus,
      }));

      dispatch(
        insert({
          id: projectData.id,
          name: projectData.name,
          icon: projectData.icon_url,
          y: projectData.ys,
          selectedY: projectData.ys[0],
          columns: projectData.overview.columns,
          accuracyCrit:
            projectData.model_spec.original?.accuracy_crit[0] ?? null,
          yBusiness: yBusinessAdjusted,
          updatedAt: projectData.last_updated,
          parentID: projectData.parent_id,
          createdAt: projectData.created,
          filters: projectData.filters,
          selectedFilters: {},
          appInfo: projectData.app_info,
        }),
      );
      dispatch(changeIsFinish(false));
      dispatch(resetAIUserSelectionOptions());

      const url = location.pathname
        .replace('/models/time-series/', '')
        .slice(24);

      const isDisabledProjectOverview =
        projectData.ys.filter((variable) => variable.status === 'success')
          .length <= 1;

      const isDisabledAISelection =
        yBusinessAdjusted.length === 0 ||
        yBusinessAdjusted.find((yBus) => yBus.y === projectData.ys[0]?.id)
          ?.status === 'error';

      if (url === '/project-overview' && isDisabledProjectOverview) {
        if (!isDisabledAISelection) {
          navigate(`/models/time-series/${projectData.id}/AI-selection`);
        } else {
          navigate(`/models/time-series/${projectData.id}/user-selection`);
        }
      }

      if (url === '/AI-selection' && isDisabledAISelection) {
        if (!isDisabledProjectOverview) {
          navigate(`/models/time-series/${projectData.id}/project-overview`);
        } else {
          navigate(`/models/time-series/${projectData.id}/user-selection`);
        }
      }
    }
  }, [projectData, dispatch, navigate, project, location]);

  const {
    lastUpdatedAccessible,
    modelUpdateDisabled,
    setModelUpdateSidebarVisible,
    versionLabel,
    handleAccessProjectUpdate,
    modelUpdateNavigationLoading,
    parentProject,
    setModelUpdateDocsModalVisible,
  } = useContext(ModelUpdateContext);

  const modelUpdateNavVisible =
    (!!lastUpdatedAccessible &&
      !!versionLabel &&
      versionLabel !== lastUpdatedAccessible?.label) ||
    (parentProject?.id === id && !!lastUpdatedAccessible);

  const [menuVisible, setMenuVisible] = useState(false);

  return (
    <Container>
      {modelUpdateNavVisible && isExpanded && (
        <ModelUpdateReturnButton
          onClick={() =>
            handleAccessProjectUpdate(
              lastUpdatedAccessible.id,
              lastUpdatedAccessible.label,
            )
          }
          data-testid="close-update-button"
          disabled={modelUpdateNavigationLoading}
        >
          <ArrowLeft size="0.875rem" />
          <p>{translate('modelUpdtBackToLastUpdt')}</p>
        </ModelUpdateReturnButton>
      )}

      <ProjectDetails
        onClick={() => navigate('/models/projects')}
        data-testid="container-sidebar-project"
        expanded={isExpanded}
      >
        {isLoading || isFetching || !project ? (
          <>
            <ContainerSkeleton
              data-testid="project-loading"
              withLoading={false}
              style={{ width: '53px', height: '53px', margin: 0 }}
            />
            <div style={{ flex: '1' }}>
              <ContainerSkeleton
                withLoading={false}
                style={{ width: '170px', height: '24px', margin: '0' }}
              />
              <ContainerSkeleton
                withLoading={false}
                style={{ width: '170px', height: '24px', margin: '4px 0 0' }}
              />
            </div>
          </>
        ) : (
          <>
            <img
              src={
                checkIconURL(project.icon) && project.icon
                  ? project.icon
                  : arrayIcon[0]
              }
              alt="icon_project"
            />
            <div>
              <h4>{project.name}</h4>
              <p>{translate('sideBarSelectAnotherProject')}</p>
            </div>
          </>
        )}
      </ProjectDetails>

      {lastUpdatedAccessible && isExpanded && (
        <div style={{ width: '16.938rem' }}>
          <VersionInfo
            versionName={
              lastUpdatedAccessible?.id === id
                ? translate('modelUpdtLatestUpdate')
                : versionLabel || 'Original'
            }
            changeVersionDisabled={modelUpdateNavigationLoading}
            handleOpenSidebar={() => setModelUpdateSidebarVisible(true)}
          />
        </div>
      )}

      <>
        {isExpanded && (
          <ProjectMenuButton
            onClick={() => {
              setMenuVisible(true);
            }}
            // projectIsChild={!!versionLabel}
            projectIsChild={modelUpdateNavVisible!}
            data-testid="button-open-project-menu"
          >
            <DotsThreeVertical size="1.25rem" />
          </ProjectMenuButton>
        )}

        <MenuContainer
          visible={menuVisible}
          style={{ top: '1.75rem', right: '0.25rem', width: '17rem' }}
        >
          {menuVisible && <MenuOverlay onClick={() => setMenuVisible(false)} />}
          <MenuOption
            position="start"
            onClick={() => {
              setMenuVisible(false);
              setModelUpdateSidebarVisible(true);
            }}
            data-testid="button-open-update-history"
            disabled={modelUpdateDisabled}
          >
            <Clock size="1.25rem" />
            <p>{translate('modelUpdtSidebarTitle')}</p>
          </MenuOption>
          <MenuOption
            position="end"
            onClick={() => {
              setMenuVisible(false);
              setModelUpdateDocsModalVisible(true);
            }}
            data-testid="button-open-update-docs"
          >
            <ArrowUpRight size={20} />
            <p>{translate('updateDocsModalTitle')}</p>
          </MenuOption>
        </MenuContainer>
      </>
    </Container>
  );
};
