import styled from 'styled-components';

export const Container = styled.div`
  section {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    > div {
      flex: 1 1 18.75rem;
    }
  }

  .select {
    width: 20rem;
    margin-bottom: 1.5rem;

    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }

  .relative {
    position: relative;
  }

  h5 {
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    color: ${({ theme }) => theme.colors.gray8};
  }

  .css-26l3qy-menu {
    z-index: 700;
  }
`;

interface ButtonsContentProps {
  positionInvert?: boolean;
}

export const ButtonsContent = styled.div<ButtonsContentProps>`
  display: flex;
  justify-content: ${({ positionInvert }) =>
    positionInvert ? 'flex-start' : 'space-between'};
  gap: 0.5rem;
  padding-top: 1.5rem;

  > div {
    display: flex;
  }
`;

export const TableContent = styled.div`
  display: flex;
  margin-top: 1.5rem;

  position: relative;
  overflow-y: auto;
  overflow-x: auto;
  max-height: 32.5rem;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray2};

  ::-webkit-scrollbar-track {
    margin-top: 3.438rem;
  }

  ::-webkit-scrollbar {
    width: 0.313rem;
    height: 0.313rem;
  }

  table {
    border-spacing: 0;
    width: 100%;
    border-radius: 8px;

    thead {
      position: sticky;
      top: 0px;
      z-index: 99;
      background: ${({ theme }) => theme.colors.white};

      th {
        position: relative;
        background: ${({ theme }) => theme.colors.white};

        div {
          min-width: 3.75rem;
          display: flex;
          gap: 1.5rem;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          svg {
            min-width: 1.25rem;
            min-height: 1.25rem;
          }

          p {
            word-wrap: break-word;
            word-break: break-word;
            margin-right: 1rem;
          }
        }
      }

      th {
        min-width: 14.375rem !important;
      }

      th:first-child {
        min-width: 10.625rem !important;
      }
    }

    th {
      padding: 1rem 1.5rem;
      border: 1px solid ${({ theme }) => theme.colors.gray2};
      border-spacing: 0;

      font-weight: 600;
      line-height: 150%;
      color: ${({ theme }) => theme.colors.gray6};
      font-style: normal;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.375rem;
    }

    td {
      text-align: center;
      border: 1px solid ${({ theme }) => theme.colors.gray2};
      border-spacing: 0;
      height: 3.313rem;
      color: ${({ theme }) => theme.colors.gray6};
      font-style: normal;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.375rem;
      padding: 1rem 3rem;
    }
  }
`;

export const TableButtonsContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 1.5rem;
`;

export const CallWithAPIButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0 !important;
  margin-right: 1rem;

  svg {
    color: ${({ theme }) => theme.colors.primary};
    margin: 0px 0.5rem 0;
  }

  p {
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: ${({ theme }) => theme.colors.primary} !important;
  }

  :hover {
    p {
      color: ${({ theme }) => theme.colors.primaryDark} !important;
    }

    svg {
      color: ${({ theme }) => theme.colors.primaryDark};
    }
  }
`;

export const ChangeDatasetButton = styled.button`
  display: flex;
  padding-left: 0 !important;

  svg {
    color: ${({ theme }) => theme.colors.primary};
    margin: 0px 0.5rem 0;
  }

  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: ${({ theme }) => theme.colors.primary};
  }

  :hover {
    h3 {
      color: ${({ theme }) => theme.colors.primaryDark};
    }

    svg {
      color: ${({ theme }) => theme.colors.primaryDark};
    }
  }
`;

export const RowQuantityContainer = styled.div`
  display: flex;
  margin-left: 1.5rem;

  > span {
    color: ${({ theme }) => theme.colors.gray4};

    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 150%;
  }
`;

interface TableSortButtonProps {
  active: boolean;
}

export const TableSortButton = styled.button<TableSortButtonProps>`
  position: absolute;
  top: 50;
  right: 1rem;
  width: 1.5rem;
  height: 1.5rem;

  svg {
    color: ${({ active, theme }) =>
      active ? theme.colors.primary : theme.colors.gray4};
  }
`;
