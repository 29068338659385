import React from 'react';

import { useTranslation } from 'react-i18next';

import { getValuesToFillCardsThroughFrequency } from '../../utils/getValuesToFillCardsThroughFrequency';
import {
  ContentCardsStep3,
  Card,
  ContentError,
  ContainerCardStep3,
} from './styles';

interface CardStep3Props {
  frequency: string;
  nSteps: number;
  changeNSteps: (value: number) => void;
  isError: boolean;
}

export const CardStep3: React.FC<CardStep3Props> = ({
  frequency,
  nSteps,
  changeNSteps,
  isError,
}) => {
  const values = getValuesToFillCardsThroughFrequency(frequency);

  const { t: translate } = useTranslation();

  return (
    <ContainerCardStep3>
      <ContentCardsStep3>
        <Card
          isSelected={values[0].nSteps === nSteps}
          onClick={() => changeNSteps(values[0].nSteps)}
          isError={isError}
          data-testid="button-card-1"
          data-cy="button-card-1"
        >
          <p>{`${values[0].time} ${translate(values[0].period)}`}</p>
          <span>{translate('step3AHead')}</span>
        </Card>
        <Card
          isSelected={values[1].nSteps === nSteps}
          onClick={() => changeNSteps(values[1].nSteps)}
          isError={isError}
          data-testid="button-card-2"
          data-cy="button-card-2"
        >
          <p>{`${values[1].time} ${translate(values[1].period)}`}</p>
          <span>{translate('step3AHead')}</span>
        </Card>
        <Card
          isSelected={values[2].nSteps === nSteps}
          onClick={() => changeNSteps(values[2].nSteps)}
          isError={isError}
          data-testid="button-card-3"
          data-cy="button-card-3"
        >
          <p>{`${values[2].time} ${translate(values[2].period)}`}</p>
          <span>{translate('step3AHead')}</span>
        </Card>
      </ContentCardsStep3>
      {isError && (
        <ContentError>
          <p>{translate('step3SelectAPeriod')}</p>
        </ContentError>
      )}
    </ContainerCardStep3>
  );
};
