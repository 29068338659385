const modelExplorerENUSTranslations = {
  claasModelListDescription:
    'List of models organized by the ROC AUC Score. Access the most relevant information from your model, get predictions and much more.',
  modelListErrorMessage: 'Unable to load model list.',
  accuracyMetricsTitle: 'Performance',
  accuracyMetricsDescription:
    'Evaluate the key metrics of this selected model.',
  accuracyMetricsAccuracy: 'Accuracy',
  accuracyMetricsPrecision: 'Precision',
  modelPerformanceInfo: `The metrics are calculated using a method called repeated stratified k-fold cross-validation, which involves dividing the data into several random subsets, ensuring that the distribution of classes is similar in each one of them. This process is repeated multiple times to ensure that the model's performance is consistent across different data subsets.`,
  featureImportanceTitle: 'Feature Importance',
  featureImportanceDescr:
    'Understand which variables are most relevant to your model.',
  featureImportanceAlert: 'There is no such metric for this model',
  claasModelToProductionTitle: 'Model to Production',
  claasModelToProductionDescr:
    'Boost your decision-making based on the outputs of your favorite model. Access the most relevant information from your model, get predictions and more.',
  classModelToProductionButton: 'Send to Production',
  claasSendToProdFailMessage:
    'An error occurred when sending model to production.',
};

export default modelExplorerENUSTranslations;
