export const Step2ClaaSENUSTranslations = {
  step2ClaasDependentVariableInputLabel: 'Dependent Variable',
  step2SelectVariablesTitleClaas:
    'Select the target and the set of explanatory features.',
  step2DependentVariableInputTooltipClaas:
    'Variables to be predicted. Binary and multiclass variables are available.',
  step2DependentVariableChartYAxis: 'Number of observations',
  step2DependentVariableChartObservations: 'No. observations',
  step2BalanceWarning:
    'Your dataset seems to be unbalanced. Our algorithms can handle it or the data provided can be verified.',
  step2ClassExclusionWarning:
    'Classes of "TARGET" with fewer than 6 occurrences were excluded from the modeling process.',
};

export default Step2ClaaSENUSTranslations;
