import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 2.5rem;
  left: 50%;

  transform: translate(-50%, 0);

  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  width: 22rem;

  padding: 1rem;

  border-radius: 8px;
  box-shadow: 0px 2px 16px 0px rgba(113, 128, 150, 0.24);
  background: ${({ theme }) => theme.colors.white};

  > div {
    width: 100%;

    > div {
      justify-content: start;
      align-items: start;
    }
  }

  > div > div:last-of-type {
    margin-bottom: 0 !important;

    > div {
      height: 7.5rem;
    }
  }

  > button {
    width: 100%;

    > div {
      align-items: center;
      justify-content: center;
    }

    svg {
      width: 1.125rem;
      height: 1.125rem;
    }
  }

  &::before {
    content: '';

    position: absolute;
    top: -10px;
    left: 50%;

    transform: translateX(-50%);

    width: 0;
    height: 0;

    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
  }
`;
