import React, { useState } from 'react';

import { Triangle } from 'phosphor-react';
import parseHTML from 'html-react-parser';

import { ChartTooltip } from './styles';

interface ChartItemProps {
  signal: 'positive' | 'negative';
  tooltipMessage: string;
  index: number;
}

const ChartItem: React.FC<ChartItemProps> = ({
  signal,
  tooltipMessage,
  index,
}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  return (
    <>
      <Triangle size="1.75rem" weight="fill" />
      <Triangle
        size="1.75rem"
        weight="duotone"
        onMouseEnter={() => setTooltipVisible(true)}
        onMouseLeave={() => setTooltipVisible(false)}
        data-testid={`chart-item-${index + 1}-${signal}`}
      />
      <ChartTooltip
        signal={signal}
        visible={tooltipVisible}
        className="chart-tooltip"
        data-testid={`chart-tooltip-${index + 1}-${signal}`}
      >
        <div>
          <p>{parseHTML(tooltipMessage)}</p>
        </div>
      </ChartTooltip>
    </>
  );
};

export default ChartItem;
