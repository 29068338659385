const ModelUpdateDocsModalPTBRTranslations = {
  projectID: 'ID DO PROJETO',
  yourProjectID: 'ID DO SEU PROJETO',
  updateDocsModalTitle: 'Atualizar modelos via API',
  updateDocsModalDescription:
    'Atualizar modelos com informações mais recentes.',
  updateDocsModalFileComment:
    '# Os nomes dos arquivos do datasets são exemplos',
};

export default ModelUpdateDocsModalPTBRTranslations;
