import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Trash } from 'phosphor-react';
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'src/components/Button';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Input } from 'src/components/Input';
import { Modal } from 'src/components/Modal';
import { FailedModal } from 'src/components/Modal/Failed';
import { Select } from 'src/components/Select';
import { ToggleSwitch } from 'src/components/ToggleSwitch';
import { clearIndicatorsPage } from 'src/feature-store/redux/reducers/IndicatorsPage';
import api from 'src/feature-store/service/api';
import { RootState } from 'src/redux/store';
import { queryClient } from 'src/service/queryClient';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import * as Yup from 'yup';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

import { DataAdminIndicators } from '../../types';
import { TextArea } from '../TextArea';
import {
  ContentInputsModal,
  ContentModal,
  ContentSelectWithDeleteButton,
  LoadingInputSelect,
} from './styles';

interface ModalCreateIndicatorProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  setRequestAllowed: (value: boolean) => void;
  indicatorInformationToEdit: DataAdminIndicators | null;
  searchValue: string;
  page: number;
}

interface FormCreateIndicator {
  nameEN: string;
  namePT: string;
  shortNameEN: string;
  shortNamePT: string;
  descriptionEN: string;
  descriptionPT: string;
  fullDescriptionEN: string;
  fullDescriptionPT: string;
  country: string;
  sourceEN: string;
  unit: string;
  sourcePT: string;
  projections?: string;
  sector: string;
  access_type: string;
  isActive: boolean;
  ranking: number;
}

const createIndicator = Yup.object().shape({
  nameEN: Yup.string().trim().required('Campo obrigatório'),
  namePT: Yup.string().trim().required('Campo obrigatório'),
  shortNameEN: Yup.string()
    .trim()
    .max(50, 'Quantidade máxima de 50 caracteres')
    .required('Campo obrigatório'),
  shortNamePT: Yup.string()
    .trim()
    .max(50, 'Quantidade máxima de 50 caracteres')
    .required('Campo obrigatório'),
  descriptionEN: Yup.string().trim().required('Campo obrigatório'),
  descriptionPT: Yup.string().trim().required('Campo obrigatório'),
  fullDescriptionEN: Yup.string().trim().required('Campo obrigatório'),
  fullDescriptionPT: Yup.string().trim().required('Campo obrigatório'),
  country: Yup.string().trim().required('Campo obrigatório'),
  sourceEN: Yup.string().trim().required('Campo obrigatório'),
  sourcePT: Yup.string().trim().required('Campo obrigatório'),
  projections: Yup.string()
    .trim()
    .max(50, 'Quantidade máxima de 50 caracteres'),
  sector: Yup.string().trim().required('Campo obrigatório'),
  access_type: Yup.string().trim().required('Campo obrigatório'),
  isActive: Yup.boolean().required('Campo obrigatório'),
  ranking: Yup.number()
    .typeError(
      'Especifique um valor numérico (separado por ponto para decimal)',
    )
    .min(1, 'O menor valor deve ser 1')
    .required('Campo obrigatório'),
});

interface AbbreviationName {
  abbreviation: string;
  name: {
    'en-us': string;
    'pt-br': string;
  };
}
interface Tree {
  id: string;
  node: string;
  name: {
    'en-us': string;
    'pt-br': string;
  };
  children: Tree[];
}

interface Domains {
  aggregation: AbbreviationName[];
  countrys: AbbreviationName[];
  primary_transformations: AbbreviationName[];
  secondary_transformations: AbbreviationName[];
  sectors: AbbreviationName[];
  tree: Tree[];
}

type ErrorInfoProps = {
  title: string;
  description: string;
};

export const ModalCreateIndicator: React.FC<ModalCreateIndicatorProps> = ({
  visible,
  setVisible,
  indicatorInformationToEdit,
  setRequestAllowed,
  searchValue,
  page,
}) => {
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<FormCreateIndicator>({
    resolver: yupResolver(createIndicator),
    defaultValues: indicatorInformationToEdit
      ? {
          nameEN: indicatorInformationToEdit.name?.['en-us'],
          namePT: indicatorInformationToEdit.name?.['pt-br'],
          shortNameEN: indicatorInformationToEdit.short_name?.['en-us'],
          shortNamePT: indicatorInformationToEdit.short_name?.['pt-br'],
          descriptionEN: indicatorInformationToEdit.description?.['en-us'],
          descriptionPT: indicatorInformationToEdit.description?.['pt-br'],
          fullDescriptionEN:
            indicatorInformationToEdit.description_full?.['en-us'],
          fullDescriptionPT:
            indicatorInformationToEdit.description_full?.['pt-br'],
          country: indicatorInformationToEdit.country,
          sourceEN: indicatorInformationToEdit.source?.['en-us'],
          sourcePT: indicatorInformationToEdit.source?.['pt-br'],
          projections: indicatorInformationToEdit.projections,
          sector: `${indicatorInformationToEdit.sector?.code} - ${indicatorInformationToEdit.sector?.name['en-us']}`,
          access_type: indicatorInformationToEdit.access_type,
          isActive: indicatorInformationToEdit.is_active,
          ranking: indicatorInformationToEdit.ranking,
        }
      : undefined,
  });

  const [failedModalVisible, setFailedModalVisible] = useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const [categoriesIndex, setCategoriesIndex] = useState<number[]>([]);
  const [childrenOfCategories, setChildrenOfCategories] = useState<Tree[][]>(
    [],
  );
  const [step, setStep] = useState(1);
  const [errorMessage, setErrorMessage] = useState<ErrorInfoProps>();

  const { language } = useSelector((state: RootState) => state.auth.user);
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  const { data, isLoading, isFetching, isError } = useQuery(
    ['domains'],
    async () => {
      const response = await api.get<Domains>('/domains');
      return response.data;
    },
  );

  useEffect(() => {
    if (
      data &&
      indicatorInformationToEdit &&
      indicatorInformationToEdit.tree &&
      indicatorInformationToEdit.tree.length
    ) {
      const indexes: number[] = [];
      const childrenOfCategoriesAux: Tree[][] = [];

      const categoryIndex = data.tree.findIndex((tree) => {
        if (indicatorInformationToEdit && indicatorInformationToEdit.tree) {
          return tree.id === indicatorInformationToEdit.tree[0].id;
        }

        return false;
      });

      if (categoryIndex === -1) {
        return;
      }

      indexes.push(categoryIndex);

      if (indicatorInformationToEdit.tree.length > 1) {
        let childOfCategoriesAux: Tree[] = data.tree[categoryIndex].children;

        [...indicatorInformationToEdit.tree]
          .splice(1)
          .forEach(({ id: idTree }) => {
            if (childOfCategoriesAux.length) {
              childrenOfCategoriesAux.push(childOfCategoriesAux);

              const index = childOfCategoriesAux.findIndex(
                (child) => child.id === idTree,
              );

              if (index === -1) {
                return;
              }

              indexes.push(index);
              childOfCategoriesAux = childOfCategoriesAux[index].children;
            }
          });
      }

      setCategoriesIndex(indexes);
      setChildrenOfCategories(childrenOfCategoriesAux);
    }
  }, [indicatorInformationToEdit, data]);

  const handleAddSubCategory = () => {
    if (data && categoriesIndex.length) {
      const children: Tree[][] = [];

      for (let i = 0; i <= categoriesIndex.length - 1; i++) {
        if (i === 0) {
          const childrenAux = data.tree.find(
            (_, index) => index === categoriesIndex[i],
          )?.children;
          if (childrenAux && childrenAux.length) {
            children.push(childrenAux);
          }
        } else {
          const childrenAux = children[i - 1].find(
            (_, index) => index === categoriesIndex[i],
          )?.children;
          if (childrenAux && childrenAux.length) {
            children.push(childrenAux);
          }
        }
      }
      setChildrenOfCategories(children);
    }
  };

  const handleCreate = async () => {
    const form = getValues();
    setIsDisabledButton(true);
    const tree = [
      {
        id: data?.tree[categoriesIndex[0]].id,
        node: data?.tree[categoriesIndex[0]].node,
        name: data?.tree[categoriesIndex[0]].name,
      },
      ...childrenOfCategories
        .map((childOfCategory, index) => {
          const categoryAux = childOfCategory[categoriesIndex[index + 1]];
          if (categoryAux) {
            return {
              id: categoryAux.id,
              node: categoryAux.node,
              name: categoryAux.name,
            };
          }
          return null;
        })
        .filter((childOfCategory) => childOfCategory !== null),
    ];

    try {
      if (indicatorInformationToEdit) {
        await api.put(
          `/indicators/${indicatorInformationToEdit.indicator_code}`,
          {
            name: {
              'en-us': form.nameEN,
              'pt-br': form.namePT,
            },
            short_name: {
              'en-us': form.shortNameEN,
              'pt-br': form.shortNamePT,
            },
            description: {
              'en-us': form.descriptionEN,
              'pt-br': form.descriptionPT,
            },
            description_full: {
              'en-us': form.fullDescriptionEN,
              'pt-br': form.fullDescriptionPT,
            },
            country: form.country.split(' -')[0],
            projections: form.projections === '' ? ' ' : form.projections,
            source: {
              'en-us': form.sourceEN,
              'pt-br': form.sourcePT,
            },
            sector: form.sector.split(' -')[0],
            access_type: form.access_type,
            is_active: form.isActive,
            tree,
            ranking: Number(form.ranking),
          },
        );
      } else {
        await api.post('/indicators', {
          name: {
            'en-us': form.nameEN,
            'pt-br': form.namePT,
          },
          short_name: {
            'en-us': form.shortNameEN,
            'pt-br': form.shortNamePT,
          },
          description: {
            'en-us': form.descriptionEN,
            'pt-br': form.descriptionPT,
          },
          description_full: {
            'en-us': form.fullDescriptionEN,
            'pt-br': form.fullDescriptionPT,
          },

          country: form.country.split(' -')[0],
          projections: form.projections,
          source: {
            'en-us': form.sourceEN,
            'pt-br': form.sourcePT,
          },
          sector: form.sector.split(' -')[0],
          access_type: form.access_type,
          is_active: form.isActive,
          tree,
          ranking: Number(form.ranking),
        });
      }
      setRequestAllowed(true);
      queryClient.invalidateQueries(['admin', 'indicators', page, searchValue]);
      queryClient.removeQueries(['categories']);
      queryClient.removeQueries(['indicators']);
      dispatch(clearIndicatorsPage());
      setVisible(false);
    } catch (err) {
      const error = err as AxiosError;

      if (
        error.response?.data.message ===
        "The 'access_type' can't be changed because the requested resource is linked to one or more access groups."
      ) {
        setErrorMessage({
          title: translate('modalCreateIndicatorTitleError'),
          description: translate('modalCreateIndicatorCantChangeAccessType'),
        });
      } else {
        setErrorMessage({
          title: translate('modalCreateIndicatorTitleError'),
          description: translate('editSerieFailedText'),
        });
      }

      setFailedModalVisible(true);
    }
    setIsDisabledButton(false);
  };

  const handleOnChangeSubCategory = (index: number, id: string) => {
    setChildrenOfCategories(
      childrenOfCategories.filter((__, indexFill) => indexFill <= index),
    );
    const findIndex = childrenOfCategories[index].findIndex(
      (tree) => tree.id === id,
    );
    if (findIndex !== -1) {
      setCategoriesIndex(
        categoriesIndex
          .filter((__, indexFil) => indexFil < index + 1)
          .concat(findIndex),
      );
    }
  };

  return (
    <>
      <Modal visible={visible} setVisible={setVisible}>
        <ContentModal style={{ height: step === 2 ? '618px' : 'unset' }}>
          <ContentInputsModal>
            <h4>
              {step === 1
                ? indicatorInformationToEdit
                  ? 'Editar indicador'
                  : 'Criar novo indicador'
                : 'Selecionar categorias'}
            </h4>
            {isError ? (
              <ContainerMaintenance
                text="Não foi possível carregar os dados"
                description="Tente novamente mais tarde"
                content=""
                data-testid="container-create-indicator-error"
              />
            ) : isLoading || isFetching || !data ? (
              <>
                <LoadingInputSelect data-testid="loading-name-en">
                  <p>Nome (inglês)</p>
                  <ContainerSkeleton withLoading={false} />
                </LoadingInputSelect>
                <LoadingInputSelect data-testid="loading-name-pt">
                  <p>Nome (português)</p>
                  <ContainerSkeleton withLoading={false} />
                </LoadingInputSelect>
                <LoadingInputSelect data-testid="loading-short-name-en">
                  <p>Nome Curto (inglês)</p>
                  <ContainerSkeleton withLoading={false} />
                </LoadingInputSelect>
                <LoadingInputSelect data-testid="loading-short-name-pt">
                  <p>Nome Curto (português)</p>
                  <ContainerSkeleton withLoading={false} />
                </LoadingInputSelect>
                <LoadingInputSelect data-testid="loading-description-en">
                  <p>Descrição Resumida (inglês)</p>
                  <ContainerSkeleton withLoading={false} />
                </LoadingInputSelect>
                <LoadingInputSelect data-testid="loading-description-pt">
                  <p>Descrição Resumida (português)</p>
                  <ContainerSkeleton withLoading={false} />
                </LoadingInputSelect>
                <LoadingInputSelect data-testid="loading-fullDescription-en">
                  <p>Descrição Completa (inglês)</p>
                  <ContainerSkeleton
                    withLoading={false}
                    style={{ height: '120px' }}
                  />
                </LoadingInputSelect>
                <LoadingInputSelect data-testid="loading-fullDescription-pt">
                  <p>Descrição Completa (português)</p>
                  <ContainerSkeleton
                    withLoading={false}
                    style={{ height: '120px' }}
                  />
                </LoadingInputSelect>

                <LoadingInputSelect data-testid="loading-country">
                  <p>País</p>
                  <ContainerSkeleton withLoading={false} />
                </LoadingInputSelect>
                <LoadingInputSelect data-testid="loading-source-en">
                  <p>Fonte (inglês)</p>
                  <ContainerSkeleton withLoading={false} />
                </LoadingInputSelect>
                <LoadingInputSelect data-testid="loading-source-pt">
                  <p>Fonte (português)</p>
                  <ContainerSkeleton withLoading={false} />
                </LoadingInputSelect>
                <LoadingInputSelect data-testid="loading-sector">
                  <p>Setor</p>
                  <ContainerSkeleton withLoading={false} />
                </LoadingInputSelect>
                <LoadingInputSelect data-testid="loading-typeAccess">
                  <p>Tipo de Acesso</p>
                  <ContainerSkeleton withLoading={false} />
                </LoadingInputSelect>
                <LoadingInputSelect data-testid="loading-isActive">
                  <p>Ativo?</p>
                  <ContainerSkeleton
                    withLoading={false}
                    style={{ width: '48px', height: '20px' }}
                  />
                </LoadingInputSelect>
              </>
            ) : step === 1 ? (
              <>
                <Controller
                  name="nameEN"
                  key="nameEN"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <Input
                      label="Nome (inglês)"
                      onChange={onChange}
                      value={value}
                      autoFocus
                      placeholder="Escreva o nome do indicador em inglês"
                      error={errors.nameEN?.message}
                      data-testid="input-name-en"
                    />
                  )}
                />

                <Controller
                  name="namePT"
                  key="namePT"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <Input
                      label="Nome (português)"
                      onChange={onChange}
                      value={value}
                      placeholder="Escreva o nome do indicador português"
                      error={errors.namePT?.message}
                      data-testid="input-name-pt"
                    />
                  )}
                />

                <Controller
                  name="shortNameEN"
                  key="shortNameEN"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <Input
                      label="Nome Curto (inglês)"
                      onChange={onChange}
                      value={value}
                      placeholder="Escreva o nome curto do indicador em inglês"
                      error={errors.shortNameEN?.message}
                      data-testid="input-short-name-en"
                    />
                  )}
                />

                <Controller
                  name="shortNamePT"
                  key="shortNamePT"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <Input
                      label="Nome Curto (português)"
                      onChange={onChange}
                      value={value}
                      placeholder="Escreva o nome curto do indicador em português"
                      error={errors.shortNamePT?.message}
                      data-testid="input-short-name-pt"
                    />
                  )}
                />

                <Controller
                  name="descriptionEN"
                  key="descriptionEN"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <Input
                      label="Descrição Resumida (inglês)"
                      onChange={onChange}
                      value={value}
                      placeholder="Escreva um breve texto que resuma o seu indicador em inglês"
                      error={errors.descriptionEN?.message}
                      data-testid="input-description-en"
                    />
                  )}
                />

                <Controller
                  name="descriptionPT"
                  key="descriptionPT"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <Input
                      label="Descrição Resumida (português)"
                      onChange={onChange}
                      value={value}
                      placeholder="Escreva um breve texto que resuma o seu indicador em português"
                      error={errors.descriptionPT?.message}
                      data-testid="input-description-pt"
                    />
                  )}
                />

                <Controller
                  name="fullDescriptionEN"
                  key="fullDescriptionEN"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <TextArea
                      label="Descrição Completa (inglês)"
                      onChange={onChange}
                      value={value}
                      placeholder="Sinta-se livre para escrever uma descrição detalhada para o seu indicador em inglês"
                      error={errors.fullDescriptionEN?.message}
                      data-testid="input-fullDescription-en"
                    />
                  )}
                />

                <Controller
                  name="fullDescriptionPT"
                  key="fullDescriptionPT"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <TextArea
                      label="Descrição Completa (português)"
                      onChange={onChange}
                      value={value}
                      placeholder="Sinta-se livre para escrever uma descrição detalhada para o seu indicador em português"
                      error={errors.fullDescriptionEN?.message}
                      data-testid="input-fullDescription-pt"
                    />
                  )}
                />

                <Controller
                  name="country"
                  key="country"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      label="País"
                      options={data.countrys.map((country) => ({
                        value: `${country.abbreviation} - ${
                          country.name[language] ?? country.name['en-us']
                        }`,
                        label: `${country.abbreviation} - ${
                          country.name[language] ?? country.name['en-us']
                        }`,
                      }))}
                      onChange={(select: any) => onChange(select.value)}
                      value={value ? { label: value, value } : undefined}
                      isDisabled={!!indicatorInformationToEdit?.country}
                      placeholder="Selecione um país"
                      error={errors.country?.message}
                      noOptionsMessage={() => 'País não encontrado'}
                    />
                  )}
                />

                <Controller
                  name="sourceEN"
                  key="sourceEN"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <Input
                      label="Fonte (inglês)"
                      onChange={onChange}
                      value={value}
                      placeholder="Entre com a source do seu indicador em inglês"
                      error={errors.sourceEN?.message}
                      data-testid="input-source-en"
                    />
                  )}
                />

                <Controller
                  name="sourcePT"
                  key="sourcePT"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <Input
                      label="Fonte (português)"
                      onChange={onChange}
                      value={value}
                      placeholder="Entre com a source do seu indicador em português"
                      error={errors.sourcePT?.message}
                      data-testid="input-source-pt"
                    />
                  )}
                />

                <Controller
                  name="projections"
                  key="projections"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <Input
                      label="Projeção"
                      onChange={onChange}
                      value={value}
                      placeholder="Insira a projeção"
                      error={errors.projections?.message}
                      data-testid="input-projections"
                    />
                  )}
                />

                <Controller
                  name="sector"
                  key="sector"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      label="Setor"
                      options={data.sectors.map((sector) => ({
                        value: `${sector.abbreviation} - ${
                          sector.name[language] ?? sector.name['en-us']
                        }`,
                        label: `${sector.abbreviation} - ${
                          sector.name[language] ?? sector.name['en-us']
                        }`,
                      }))}
                      onChange={(select: any) => onChange(select.value)}
                      value={value ? { label: value, value } : undefined}
                      isDisabled={!!indicatorInformationToEdit?.sector?.code}
                      placeholder="Selecione um setor"
                      error={errors.sector?.message}
                      noOptionsMessage={() => 'Setor não encontrado'}
                    />
                  )}
                />

                <Controller
                  name="access_type"
                  key="typeAccess"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      label="Tipo de Acesso"
                      options={[
                        {
                          value: 'premium',
                          label: 'Premium',
                        },
                        {
                          value: 'default',
                          label: 'Básico',
                        },
                        {
                          value: 'freemium',
                          label: 'Freemium',
                        },
                        {
                          value: 'private',
                          label: 'Privado',
                        },
                      ]}
                      onChange={(select: any) => onChange(select.value)}
                      value={
                        value === 'default'
                          ? {
                              label: 'Básico',
                              value,
                            }
                          : value === 'premium'
                          ? {
                              label: 'Premium',
                              value,
                            }
                          : value === 'freemium'
                          ? {
                              label: 'Freemium',
                              value,
                            }
                          : value === 'private'
                          ? {
                              label: 'Privado',
                              value,
                            }
                          : undefined
                      }
                      placeholder="Tipo de acesso"
                      error={errors.access_type?.message}
                      data-testid="input-typeAccess"
                    />
                  )}
                />

                <Controller
                  name="ranking"
                  key="ranking"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      label="Ranking"
                      onChange={onChange}
                      value={value}
                      placeholder="Insira o Ranking"
                      error={errors.ranking?.message}
                      data-testid="input-ranking"
                    />
                  )}
                />

                <Controller
                  name="isActive"
                  key="isActive"
                  control={control}
                  defaultValue={false}
                  render={({ field: { onChange, value } }) => (
                    <ToggleSwitch
                      label="Ativo?"
                      isPTBR
                      useYesOrNo
                      onChange={(val) => {
                        onChange(val);
                      }}
                      checked={value}
                      data-testid="toggle-isActive"
                      data-cy="toggle-isActive"
                    />
                  )}
                />
              </>
            ) : (
              <>
                <Select
                  label="Categoria"
                  onChange={(select: any) => {
                    setChildrenOfCategories([]);
                    const index = data.tree.findIndex(
                      (tree) => tree.id === select.value,
                    );
                    if (index !== -1) {
                      setCategoriesIndex([index]);
                    }
                  }}
                  value={
                    categoriesIndex.length
                      ? {
                          label:
                            data.tree[categoriesIndex[0]].name[language] ??
                            data.tree[categoriesIndex[0]].name['en-us'],
                          value: data.tree[categoriesIndex[0]].id,
                        }
                      : undefined
                  }
                  options={data.tree.map((tree) => ({
                    label: tree.name[language] ?? tree.name['en-us'],
                    value: tree.id,
                  }))}
                  isDisabled={!!indicatorInformationToEdit?.country}
                  placeholder="Selecione uma categoria"
                  noOptionsMessage={() => 'Categoria não encontrada'}
                />
                {Array.from(
                  { length: childrenOfCategories.length },
                  (_, index) => (
                    <ContentSelectWithDeleteButton
                      key={`select-fill-space-${index + 1}`}
                    >
                      <Select
                        label={`Sub-categoria ${index + 1}`}
                        onChange={(select: any) =>
                          handleOnChangeSubCategory(index, select.value)
                        }
                        options={childrenOfCategories[index].map((tree) => ({
                          label: tree.name[language] ?? tree.name['en-us'],
                          value: tree.id,
                        }))}
                        value={
                          categoriesIndex.length - 1 - 1 ===
                          childrenOfCategories.length - 1
                            ? {
                                label:
                                  childrenOfCategories[index][
                                    categoriesIndex[index + 1]
                                  ].name[language] ??
                                  childrenOfCategories[index][
                                    categoriesIndex[index + 1]
                                  ].name['en-us'],
                                value:
                                  childrenOfCategories[index][
                                    categoriesIndex[index + 1]
                                  ].id,
                              }
                            : undefined
                        }
                        placeholder="Selecione uma sub-categoria"
                        noOptionsMessage={() => 'Sub-categoria não encontrada'}
                        key={`select-sub-category-${index + 1}`}
                      />
                      <button
                        type="button"
                        data-testid={`button-delete-sub-categoria-${index + 1}`}
                        onClick={() => {
                          setChildrenOfCategories(
                            childrenOfCategories.filter(
                              (__, indexFil) => indexFil < index,
                            ),
                          );
                          setCategoriesIndex(
                            categoriesIndex.filter(
                              (__, indexFil) => indexFil < index + 1,
                            ),
                          );
                        }}
                        aria-label="delete sub categoria button"
                      >
                        <Trash size="1.25rem" />
                      </button>
                    </ContentSelectWithDeleteButton>
                  ),
                )}

                <Button
                  buttonType="naked"
                  disabled={
                    categoriesIndex.length === 0 ||
                    (childrenOfCategories.length > 0 &&
                      !(
                        childrenOfCategories.length < categoriesIndex.length &&
                        !!childrenOfCategories[childrenOfCategories.length - 1][
                          categoriesIndex[categoriesIndex.length - 1]
                        ]?.children?.length
                      ))
                  }
                  data-testid="button-add-sub-categoria"
                  data-cy="button-add-sub-categoria"
                  style={{ padding: 0, height: 21 }}
                  onClick={handleAddSubCategory}
                >
                  + Adicionar sub-categoria
                </Button>
              </>
            )}
          </ContentInputsModal>
        </ContentModal>

        <ModalFooter>
          <Button
            buttonType="naked"
            onClick={() => setVisible(false)}
            data-testid="button-cancelar"
            data-cy="button-cancelar"
          >
            Cancelar
          </Button>
          {step === 1 ? (
            <Button
              buttonType="primary"
              onClick={handleSubmit(() => setStep(2))}
              disabled={isLoading || isFetching || isError || isDisabledButton}
              data-testid="button-prosseguir"
              data-cy="button-prosseguir"
            >
              Prosseguir
            </Button>
          ) : (
            <Button
              buttonType="primary"
              onClick={() => handleCreate()}
              disabled={
                isLoading ||
                isFetching ||
                isError ||
                isDisabledButton ||
                categoriesIndex.length === 0
              }
              data-testid="button-criar-indicador"
              data-cy="button-criar-indicador"
            >
              {indicatorInformationToEdit
                ? 'Editar indicador'
                : 'Criar indicador'}
            </Button>
          )}
        </ModalFooter>

        {failedModalVisible && (
          <FailedModal
            visible={failedModalVisible}
            setVisible={setFailedModalVisible}
            errorInfo={errorMessage}
          />
        )}
      </Modal>
    </>
  );
};
