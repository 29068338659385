import React from 'react';

import InvitationSend from 'src/assets/InvitationSend.svg';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';

import { Container, FooterModal } from './styles';

interface SuccessInfo {
  title: string;
  description: string;
}

interface PropsModalSuccessRegisterUser {
  visible?: boolean;
  setVisible: (value: boolean) => void;
  successInfo: SuccessInfo;
}
export const ModalSuccessRegisterUser: React.FC<
  PropsModalSuccessRegisterUser
> = ({ visible, setVisible, successInfo }) => (
  <Modal visible={visible} setVisible={setVisible}>
    <Container>
      <img
        src={InvitationSend}
        alt="Invitation Send"
        data-testid="invitation-send"
      />
      <h4>{successInfo.title}</h4>
      <p>{successInfo.description}</p>
    </Container>
    <FooterModal>
      <div>
        <Button
          buttonType="primary"
          onClick={() => setVisible(false)}
          data-testid="button-ok"
          data-cy="button-ok"
        >
          Ok
        </Button>
      </div>
    </FooterModal>
  </Modal>
);
