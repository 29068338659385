import React, { useEffect, useState } from 'react';

import { AxiosError } from 'axios';
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger,
  FieldErrors,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Button';
import { Input } from 'src/components/Input';
import { Modal } from 'src/components/Modal';
import { ErrorObject } from 'src/components/Modal/Failed';
import { Select } from 'src/components/Select';
import { DataError } from 'src/interface/axios';
import api from 'src/models/service/api';
import { ModalFooter } from 'src/components/Modal/Footer/styles';

import { FormUpload } from '../../../types';
import { Container, InputsContent } from './styles';

interface LagsModalProps {
  variables: string[];
  visible: boolean;
  setVisible: (visible: boolean) => void;
  setLagVariables: (lags: string[]) => void;
  lagVariables: string[];
  control: Control<any>;
  getValues: UseFormGetValues<any>;
  setValue: UseFormSetValue<any>;
  trigger: UseFormTrigger<FormUpload>;
  errors: FieldErrors<FormUpload>;
  setFailedModalInfo: (info: ErrorObject) => void;
  setFailedModalVisible: (visible: boolean) => void;
  setWarningModalInfo: (info: ErrorObject) => void;
  setWarningModalVisible: (visible: boolean) => void;
  dummiesFromFS: string[];
  frequency: string;
}

interface OldInputValues {
  lagVariables: string[];
  lagNumber: number;
}

export const LagsModal: React.FC<LagsModalProps> = ({
  variables,
  visible,
  setVisible,
  setLagVariables,
  lagVariables,
  control,
  getValues,
  setValue,
  trigger,
  errors,
  setFailedModalInfo,
  setFailedModalVisible,
  setWarningModalInfo,
  setWarningModalVisible,
  dummiesFromFS,
  frequency,
}) => {
  const [oldInputValues, setOldInputValues] = useState<OldInputValues>();
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(false);

  const { t: translate } = useTranslation();

  useEffect(() => {
    if (visible) {
      const oldValue = {
        lagVariables: getValues('lagVariables'),
        lagNumber: getValues('lagNumber'),
      };

      setOldInputValues(oldValue);
    }
  }, [getValues, visible]);

  function handleCancel() {
    oldInputValues?.lagVariables &&
      setValue('lagVariables', oldInputValues?.lagVariables);
    oldInputValues?.lagNumber &&
      setValue('lagNumber', oldInputValues?.lagNumber);
    setVisible(false);
    trigger('lagNumber');
  }

  async function handleConfirmLags() {
    try {
      setConfirmButtonDisabled(true);

      const lags = getValues('lagVariables')?.reduce(
        (accumulator: any, actual: string) => ({
          ...accumulator,
          [actual]: getValues('lagNumber'),
        }),
        {},
      );

      const { data } = await api.post<string[]>(
        `/variables/${getValues('fileDataSet').dataset_id}/lags`,
        {
          lags,
        },
      );

      data &&
        lagVariables.length > 0 &&
        (setWarningModalInfo({
          title: translate('lagsModalTitle'),
          description: translate('lagsModalDescripPreviousReplaced'),
        }),
        setWarningModalVisible(true));

      data &&
        (setValue(
          'explanatoryVariables',
          [
            ...getValues('explanatoryVariables')?.filter(
              (explVar: string) => !lagVariables.some((lag) => lag === explVar),
            ),
          ].concat(data),
        ),
        setLagVariables(data));

      setConfirmButtonDisabled(false);
      setVisible(false);
    } catch (err) {
      const error: AxiosError<DataError> | any = err;
      const errorMessage =
        error.response?.data?.detail?.detail ?? 'Failed to set up your lags.';

      setFailedModalInfo({
        title: 'Request Failed!',
        description: errorMessage,
      });
      setFailedModalVisible(true);

      handleCancel();
      setVisible(false);
      setConfirmButtonDisabled(false);
    }
  }

  return (
    <Modal visible={visible} setVisible={setVisible} cancel={handleCancel}>
      <Container
        data-testid={`lagged-variables-modal${visible ? '-visible' : ''}`}
        data-cy={`lagged-variables-modal${visible ? '-visible' : ''}`}
      >
        <h3>{translate('lagsModalTitle')}</h3>
        <p>{translate('lagsModalDescr')}</p>
        <InputsContent>
          <Controller
            name="lagNumber"
            key="lagNumber"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Input
                label={translate('lagNumberLabel')}
                data-testid="input-lag-number"
                data-cy="input-lag-number"
                type="number"
                min="1"
                value={value}
                onChange={({ target: { value: val } }) => {
                  onChange(val);
                  trigger('lagNumber');
                }}
                style={{ width: '520px' }}
                error={
                  errors.lagNumber?.message === 'projectErrorMaxLags'
                    ? `${translate(errors.lagNumber.message)} ${
                        frequency === 'Daily' ? 7 : 3
                      }`
                    : // @ts-expect-error typescript bug
                      translate(errors.lagNumber?.message)
                }
              />
              // `${'projectErrorMaxLags'} ${frequency === 'Daily' ? 7 : 3}`,
            )}
          />

          <Controller
            name="lagVariables"
            key="lagVariables"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                label={translate('lagVariablesLabel')}
                data-tetid="select-lag-variables"
                data-cy="select-lag-variables"
                isMulti
                placeholder={translate('select')}
                options={
                  getValues('explanatoryVariables')?.length > 0
                    ? [
                        { label: translate('selectAll'), value: 'Select all' },
                        ...variables
                          .filter(
                            (variable) =>
                              variable !== getValues('dependVariable') &&
                              getValues('explanatoryVariables')?.some(
                                (explVar: string) => explVar === variable,
                              ) &&
                              !dummiesFromFS.includes(variable),
                          )
                          .map((variable) => ({
                            label: variable,
                            value: variable,
                          })),
                      ]
                    : []
                }
                value={value?.map((val: any) => ({ label: val, value: val }))}
                onChange={(valArray: any) => {
                  valArray.find((option: any) => option.value === 'Select all')
                    ? onChange([
                        ...variables.filter(
                          (variable) =>
                            variable !== getValues('dependVariable') &&
                            getValues('explanatoryVariables')?.some(
                              (explVar: string) => explVar === variable,
                            ) &&
                            !dummiesFromFS.includes(variable),
                        ),
                      ])
                    : onChange(valArray.map((val: any) => val.value));
                }}
                style={{ width: '520px' }}
              />
            )}
          />
        </InputsContent>
      </Container>
      <ModalFooter>
        <Button
          buttonType="naked"
          data-testid="button-cancel-lags"
          data-cy="button-cancel-lags"
          onClick={handleCancel}
        >
          {translate('cancel')}
        </Button>
        <Button
          buttonType="primary"
          data-testid="button-confirm-lags"
          data-cy="button-confirm-lags"
          onClick={handleConfirmLags}
          loading={confirmButtonDisabled}
          disabled={!!errors.lagNumber?.message || confirmButtonDisabled}
        >
          {translate('confirm')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
