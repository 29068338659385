import styled from 'styled-components';

export const ContentModal = styled.div`
  width: 47rem;
  max-height: 70vh;
  overflow-y: auto;
`;

export const ContentInputsModal = styled.div`
  padding: 0.5rem 0.5rem 0;
  margin-right: 0.5rem;
  h4 {
    font-size: 1.5rem;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.gray6};
    margin-bottom: 1.5rem;
  }
  > p {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.gray5};
    margin-bottom: 2rem;
  }
  > div {
    margin-bottom: 1.5rem;
  }
`;

export const DivButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 1.5rem;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(76, 148, 255, 0.08);
    border-radius: 24px;
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
    > svg {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const DivIndicatorsSelected = styled.div`
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  padding: 1.25rem 1.25rem 1.25rem 1.5rem;
  overflow-y: auto;
  gap: 1rem;
  width: 100%;
  min-height: 10.938rem;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 8px;
  box-sizing: border-box;
  flex-wrap: wrap;
`;

interface ItemSelectedProps {
  disabled: boolean;
}

export const ItemSelected = styled.div<ItemSelectedProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ disabled, theme }) =>
    disabled ? theme.colors.gray2 : 'rgba(76, 148, 255, 0.12)'};
  border-radius: 8px;
  max-height: 1.875rem;
  white-space: nowrap;

  p {
    font-weight: 500;
    font-size: 0.875rem;
    color: ${({ theme, disabled }) =>
      disabled ? theme.colors.gray5 : theme.colors.primary};
    padding: 0.25rem 0.5rem;
    margin-right: 0.75rem;
  }

  svg {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    color: ${({ theme, disabled }) =>
      disabled ? theme.colors.gray5 : theme.colors.primary};
    margin-right: 0.625rem;
  }
`;

export const DivAreaContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 1.25rem 1.25rem 1.25rem 1.5rem;
  overflow-y: auto;
  gap: 1rem;

  width: auto;
  height: 10.625rem;

  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 8px;
  box-sizing: border-box;

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray6};
  }

  input {
    border: 1px solid ${({ theme }) => theme.colors.gray4};
    border-radius: 2px;
  }
`;

export const DivErrorGroupPremium = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > strong {
    margin-top: 0.938rem;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.438rem;
    color: ${({ theme }) => theme.colors.gray6};
  }

  > p {
    margin-top: 1rem;
    text-align: center;
    color: ${({ theme }) => theme.colors.gray5};
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: 400;
  }
  img {
    height: 7.5rem;
    width: 7.5rem;
  }
`;
