import React, { useContext, useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useFormatDateLanguage } from 'src/hooks/useFormatDateLanguage';
import { DownloadSimple, MagnifyingGlass, Share, X } from 'phosphor-react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { FailedModal } from 'src/components/Modal/Failed';

import {
  Container,
  UpdateHistoryList,
  Version,
  Overlay,
  CloseSidebarButton,
  ContentTitle,
} from './styles';
import { ModelUpdateContext } from '../contexts/ModelUpdateContext';
import { ShareUpdateModal } from '../ShareUpdateModal';
import { DownloadWidget, DownloadStatus } from '../../DownloadWidget';

export const ModelUpdateSidebar: React.FC = () => {
  const { t: translate } = useTranslation();
  const { id: projectID, parentID } = useSelector(
    (state: RootState) => state.project,
  );
  const [downloadStatus, setDownloadStatus] = useState<DownloadStatus>();

  const containerRef = useRef<HTMLDivElement>(null);

  const translateFormat = useFormatDateLanguage();

  const {
    handleAccessProjectUpdate,
    updateHistory,
    modelUpdateSideBarVisible,
    setModelUpdateSidebarVisible,
    modelUpdateNavigationLoading,
    parentProject,
    updateToShare,
    setUpdateToShare,
    errorOnUpdateAction: errorOnShareUpdate,
    setErrorOnUpdateAction: setErrorOnShareUpdate,
    handleDownloadUpdate,
  } = useContext(ModelUpdateContext);

  function startDownloadLoading(value: DownloadStatus) {
    setDownloadStatus(value);
  }

  useEffect(() => {
    function isElementVisible(element: HTMLElement) {
      const rect = element.getBoundingClientRect();
      return rect.bottom >= 0 && rect.top <= window.innerHeight;
    }

    function handleScroll() {
      const footer = document.getElementById('footer');

      if (footer) {
        if (isElementVisible(footer)) {
          if (containerRef.current) {
            containerRef.current.style.height = `calc(100vh - (100vh - ${
              footer.getBoundingClientRect().top
            }px) - 74px)`;
          }
        } else if (containerRef.current) {
          containerRef.current.style.height = `calc(100vh - 74px)`;
        }
      }
    }

    handleScroll(); //executa a primeira vez pra definir a
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    (downloadStatus === 'success' || downloadStatus === 'error') &&
      setTimeout(() => {
        setDownloadStatus(undefined);
      }, 3000);
  }, [downloadStatus]);

  return (
    <>
      {modelUpdateSideBarVisible && (
        <Overlay
          visible={modelUpdateSideBarVisible}
          style={{
            cursor: modelUpdateNavigationLoading ? 'progress' : 'auto',
          }}
        />
      )}
      <Container
        visible={modelUpdateSideBarVisible}
        ref={containerRef}
        loading={modelUpdateNavigationLoading}
      >
        {modelUpdateSideBarVisible && (
          <CloseSidebarButton
            onClick={() => setModelUpdateSidebarVisible(false)}
            data-testid="close-history-button"
            disabled={modelUpdateNavigationLoading}
          >
            <X size="1.125rem" />
          </CloseSidebarButton>
        )}
        <div>
          <ContentTitle>
            <h3>{translate('modelUpdtSidebarTitle')}</h3>
            <p>{translate('modelUpdtSidebarDescription')}</p>
          </ContentTitle>
          <UpdateHistoryList>
            {updateHistory &&
              updateHistory.updates
                .sort(
                  (a, b) =>
                    Number(new Date(b.created)) - Number(new Date(a.created)),
                )
                .map((update) => (
                  <Version
                    selected={projectID === update.id}
                    key={update.id}
                    data-testid={`update-${update.label
                      .replaceAll(' ', '-')
                      .toLowerCase()}`}
                    disabled={
                      update.status !== 'success' &&
                      update.status !== 'partial_success'
                    }
                  >
                    <div>
                      <h4>{update.label}</h4>
                      <p>
                        {format(new Date(update.created), `${translateFormat}`)}
                      </p>
                    </div>
                    <div>
                      <button
                        type="button"
                        onClick={() => {
                          handleAccessProjectUpdate(update.id, update.label);
                        }}
                        data-testid={`access-update-${update.label
                          .replaceAll(' ', '-')
                          .toLowerCase()}-button`}
                        disabled={
                          modelUpdateNavigationLoading ||
                          projectID === update.id ||
                          (update.status !== 'success' &&
                            update.status !== 'partial_success')
                        }
                        aria-label="access update button"
                      >
                        <MagnifyingGlass size="1.125rem" />
                      </button>
                      <button
                        type="button"
                        onClick={() => setUpdateToShare(update.id)}
                        data-testid={`share-update-${update.label
                          .replaceAll(' ', '-')
                          .toLowerCase()}-button`}
                        disabled={
                          (update.status !== 'success' &&
                            update.status !== 'partial_success') ||
                          update.cloned_from !== null
                        }
                        aria-label="share update button"
                      >
                        <Share size="1.125rem" />
                      </button>
                      <button
                        type="button"
                        onClick={() =>
                          handleDownloadUpdate(update.id, startDownloadLoading)
                        }
                        data-testid={`download-update-${update.label
                          .replaceAll(' ', '-')
                          .toLowerCase()}-button`}
                        disabled={
                          (update.status !== 'success' &&
                            update.status !== 'partial_success') ||
                          downloadStatus !== undefined
                        }
                        aria-label="download update button"
                      >
                        <DownloadSimple size="1.125rem" />
                      </button>
                    </div>
                  </Version>
                ))}

            {parentProject && (
              <Version selected={projectID === parentProject.id && !parentID}>
                <div>
                  <h4>Original</h4>
                  <p>
                    {parentProject?.created &&
                      format(
                        new Date(parentProject?.created),
                        `${translateFormat}`,
                      )}
                  </p>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      handleAccessProjectUpdate(
                        parentProject.id,
                        parentProject.label,
                      );
                    }}
                    data-testid="access-original-project-button"
                    disabled={
                      modelUpdateNavigationLoading ||
                      (projectID === parentProject.id && !parentID)
                    }
                    aria-label="access original project button"
                  >
                    <MagnifyingGlass size="1.125rem" />
                  </button>
                  <button
                    type="button"
                    data-testid="share-original"
                    disabled
                    aria-label="share original project button"
                  >
                    <Share size="1.125rem" />
                  </button>
                  <button
                    type="button"
                    onClick={() =>
                      handleDownloadUpdate(
                        parentProject.id,
                        startDownloadLoading,
                      )
                    }
                    data-testid="download-original"
                    disabled={downloadStatus !== undefined}
                    aria-label="download original button"
                  >
                    <DownloadSimple size="1.125rem" />
                  </button>
                </div>
              </Version>
            )}
          </UpdateHistoryList>
        </div>
      </Container>

      {downloadStatus && <DownloadWidget status={downloadStatus!} />}
      <ShareUpdateModal
        visible={!!updateToShare}
        setVisible={(visible) => !visible && setUpdateToShare('')}
      />
      <FailedModal
        errorInfo={errorOnShareUpdate}
        visible={!!errorOnShareUpdate}
        setVisible={(value) =>
          value === false && setErrorOnShareUpdate(undefined)
        }
      />
    </>
  );
};
