export const modalSeeRequirementsTimeSeriesPTBRTranslate = {
  seeRequirementsTimeSeriesTitle: 'Requisitos do arquivo de dados',
  seeRequirementsTimeSeriesDescription:
    'Por favor, certifique-se que seu arquivo de dados atende aos seguintes requisitos:',
  seeRequirementsTimeSeriesFirstRow:
    'A primeira linha deve conter os <b>nomes das colunas</b>',
  seeRequirementsTimeSeriesFileHas:
    'O arquivo deve ter no <b>mínimo duas colunas</b>: uma coluna de data e uma coluna numérica com variável alvo/dependente',
  seeRequirementsTimeSeriesDateColumn: 'Coluna de data:',
  seeRequirementsTimeSeriesItMustHave:
    'Deve conter apenas uma observação por data (por exemplo, o conjunto de dados não deve ter duas linhas com observação para janeiro de 2020)',
  seeRequirementsTimeSeriesWeAccept:
    'Aceitamos as seguintes frequências: diária, semanal, quinzenal, mensal, bimestral, trimestral, semestral e anual',
  seeRequirementsTimeSeriesDependingOnTheFrequency:
    'Dependendo da frequência, o arquivo precisa de um determinado número de dados históricos para a variável dependente (número de linhas excluindo o período de projeção)',
  seeRequirementsTimeSeriesDaily: 'Diário',
  seeRequirementsTimeSeriesWeekly: 'Semanal',
  seeRequirementsTimeSeriesFortnightly: 'Quinzenal',
  seeRequirementsTimeSeriesMonthly: 'Mensal',
  seeRequirementsTimeSeriesBimonthly: 'Bimestral',
  seeRequirementsTimeSeriesQuarterly: 'Trimestral',
  seeRequirementsTimeSeriesHalfYear: 'Semestral',
  seeRequirementsTimeSeriesAnnual: 'Anual',
  seeRequirementsTimeSeriesOptionalExtraColumns:
    '<b>Opcional:</b> Colunas extras com variáveis preditivas/explicativas (apenas numéricas)',
  seeRequirementsTimeSeriesOptionalDownloadExample: 'Baixar exemplo',
};
