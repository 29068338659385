import React, { useState } from 'react';

import { CheckCircle, Copy } from 'phosphor-react';
import { sleep } from 'src/utils/sleep';

import { ButtonCopy } from './styles';

interface ButtonCopyProps {
  isDisabled: boolean;
  textToCopy: string;
  messageBeforeCopy: string;
  messageAfterCopy: string;
  dataTestId?: string;
}

export const ButtonCopyToClipboard: React.FC<ButtonCopyProps> = ({
  isDisabled,
  textToCopy,
  messageBeforeCopy,
  messageAfterCopy,
  dataTestId = 'button-to-clipboard',
}) => {
  const [isTextCopied, setTextCopied] = useState(false);

  async function setTextToClipBoard() {
    if (textToCopy) {
      navigator.clipboard.writeText(textToCopy);
      setTextCopied(true);
      await sleep(3000);
      setTextCopied(false);
    }
  }

  return (
    <ButtonCopy
      data-testid={dataTestId}
      buttonType="naked"
      disabled={isDisabled || isTextCopied}
      icon={
        isTextCopied ? (
          <CheckCircle size="1.125rem" weight="bold" />
        ) : (
          <Copy size="1.125rem" weight="bold" />
        )
      }
      onClick={setTextToClipBoard}
      isCopied={isTextCopied}
    >
      {isTextCopied ? messageAfterCopy : messageBeforeCopy}
    </ButtonCopy>
  );
};
