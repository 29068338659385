import React, { useContext, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import {
  DotOutline,
  MagnifyingGlass,
  PencilSimple,
} from '@phosphor-icons/react';
import { Label } from 'src/components/Label';
import { Plus } from 'phosphor-react';
import { ButtonRounded } from 'src/components/ButtonRounded';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Calendar } from 'src/components/Calendar';
import { addDays, format } from 'date-fns';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import apiWorkspace from 'src/workspaces/service/api';
import ms from 'ms';

import { StatusProps, UserOption } from '../types';
import {
  Button,
  CalendarContainer,
  Container,
  DeadlineInfo,
  Icon,
  LineIcon,
  UsersContainer,
  UsersInfo,
} from '../styles';
import { UserSelect } from '../../UserSelect';
import { PlanningFlowContext } from '../..';
import { UserProps } from '../../UserSelect/types';

export const AdjustingStatus: React.FC<StatusProps> = ({
  isSelected,
  isDisabled,
  stepId,
}) => {
  const [showNewUserSelect, setShowNewUserSelect] = useState(false);

  const { t: translate } = useTranslation();

  const {
    steps,
    isConfigurationMode,
    handleAddUser,
    handleRemoveUser,
    showErrors,
    handleAccessStep,
    handleChangeDeadline,
  } = useContext(PlanningFlowContext);

  const { id } = useParams();

  const { language } = useSelector((state: RootState) => state.auth.user);

  const { data: userData } = useQuery(
    ['workspace users', id],
    async () => {
      const { data } = await apiWorkspace.get<UserProps[]>(
        `/workspaces/${id}/users`,
      );

      return data;
    },
    {
      staleTime: ms('3 min'),
      enabled: !!id && isConfigurationMode,
    },
  );

  const stepIndex = useMemo(
    () => steps.findIndex((value) => value.id === stepId),
    [steps, stepId],
  );

  const step = steps[stepIndex];

  const users = step?.users ?? [];

  const editorUsers = users
    .filter((user) => user.profile === 'editor')
    .map(({ user }) => user);

  const handleAddNewUserSelect = () => {
    setShowNewUserSelect(true);
  };

  const handleChangeSelectedUser = (
    option: UserOption,
    currentUser: string,
  ) => {
    if (option?.value !== currentUser) {
      if (!option) {
        handleRemoveUser!(stepId, currentUser, 'editor');

        if (editorUsers.length === 1) {
          setShowNewUserSelect(false);
        }
      } else {
        handleAddUser!(stepId, option.value, 'editor');

        if (currentUser) {
          handleRemoveUser!(stepId, currentUser, 'editor');
        }
      }
    }
  };

  const onCalendarClose = () => {
    if (deadline?.start && !deadline.end) {
      handleChangeDeadline!(stepId, 'editor', [
        new Date(deadline.start.replace(':00Z', '')),
        new Date(deadline.start.replace(':00Z', '')),
      ]);
    }
  };

  const userDataEditorManager = useMemo(
    () => userData?.filter((user) => user.role !== 'reader'),
    [userData],
  );

  const canEdit = step.status !== 'approved';

  const selectQtty =
    editorUsers.length +
    (showNewUserSelect ? 1 : 0) +
    (!editorUsers.length ? 1 : 0);

  const canAddMoreUsers =
    userDataEditorManager &&
    userDataEditorManager.length > selectQtty &&
    canEdit;

  const addMoreUsersDisabled = showNewUserSelect || !editorUsers.length;

  const deadline = step?.deadlines?.find(({ type }) => type === 'editor')?.date;

  const hasLastStep = stepIndex !== 0;

  const lastStepApproverDeadline = hasLastStep
    ? steps[stepIndex - 1].deadlines?.find(({ type }) => type === 'approver')
        ?.date
    : undefined;

  const stepIsApproved = step.status === 'approved';

  const showUserError = !editorUsers.length && !!showErrors && !stepIsApproved;

  const invalidDeadline =
    !!deadline?.start &&
    !!lastStepApproverDeadline?.end &&
    new Date(lastStepApproverDeadline.end.slice(0, 10)).getTime() >
      new Date(deadline.start.slice(0, 10)).getTime();

  const showDeadlineError =
    (!deadline && !!showErrors && !stepIsApproved) || invalidDeadline;

  const canNavigateToAdjusting =
    (step?.status === 'adjusting' ||
      step?.status === 'baseline' ||
      step?.status === 'created') &&
    !isConfigurationMode;

  return (
    <Container
      status="adjusting"
      selected={isSelected}
      isDisabled={isDisabled}
      isConfigurationMode={isConfigurationMode}
      data-testid="container-adjusting-state"
    >
      <Icon data-testid="icon-adjusting-state">
        <PencilSimple weight="fill" />
      </Icon>

      <LineIcon>
        {isDisabled || isSelected || !canNavigateToAdjusting ? (
          <DotOutline weight="fill" style={{ width: '2rem', height: '2rem' }} />
        ) : (
          <Button
            type="button"
            onClick={() =>
              handleAccessStep && handleAccessStep(stepId, 'adjusting')
            }
            data-testid="button-adjusting-state"
          >
            <MagnifyingGlass />
          </Button>
        )}
      </LineIcon>

      <p data-testid="text-adjusting-state">
        {translate('workspacePlanningFlowAdjusting')}
      </p>

      {isConfigurationMode ? (
        <>
          <CalendarContainer
            error={showDeadlineError}
            data-testid={
              showDeadlineError
                ? 'container-editor-deadline-error'
                : 'container-editor-deadline'
            }
          >
            <Calendar
              label={translate('date')}
              frequency="daily"
              selectsRange
              dateFormat={language === 'en-us' ? 'MM/dd/yy' : 'dd/MM/yy'}
              startDate={
                deadline?.start
                  ? new Date(deadline.start.replace(':00Z', ''))
                  : null
              }
              endDate={
                deadline?.end
                  ? new Date(deadline.end.replace(':00Z', ''))
                  : null
              }
              onChange={(date: [Date | null, Date | null]) =>
                handleChangeDeadline!(stepId, 'editor', date)
              }
              popperProps={{
                strategy: 'fixed',
              }}
              placeholderText={translate(
                'workspacePlanningFlowChooseDatePlaceholder',
              )}
              minDate={addDays(new Date(), 1)}
              inputTestId="deadline-editor"
              error={
                invalidDeadline
                  ? translate('workspacePlanningFlowEditorDeadlineError')
                  : ''
              }
              isDisabled={!canEdit}
              onCalendarClose={onCalendarClose}
            />
          </CalendarContainer>

          <UsersContainer
            error={showUserError}
            data-testid={`container-editor-user${
              showUserError ? '-error' : ''
            }`}
          >
            <Label>{translate('workspacePlanningFlowEditors')}</Label>

            {editorUsers?.map((user) => (
              <UserSelect
                key={user}
                profile="editor"
                usersAlreadySelected={editorUsers}
                selectedUser={user}
                onChange={(option) =>
                  handleChangeSelectedUser(option as UserOption, user)
                }
                isDisabled={!canEdit}
              />
            ))}

            {(showNewUserSelect || !editorUsers.length) && (
              <UserSelect
                profile="editor"
                selectedUser={undefined}
                usersAlreadySelected={editorUsers}
                onChange={(option) => {
                  setShowNewUserSelect(false);
                  handleChangeSelectedUser(option as UserOption, '');
                }}
                isDisabled={!canEdit}
              />
            )}

            {canAddMoreUsers && (
              <ButtonRounded
                type="button"
                icon={<Plus />}
                disabled={addMoreUsersDisabled}
                onClick={handleAddNewUserSelect}
                data-testid="button-add-editor-user"
              />
            )}
          </UsersContainer>
        </>
      ) : (
        <>
          {!!deadline?.start && !!deadline.end && (
            <DeadlineInfo data-testid="text-editor-deadline">
              {format(
                new Date(deadline.start.replace(':00Z', '')),
                language === 'en-us' ? 'MM/dd/yy' : 'dd/MM/yy',
              )}{' '}
              -{' '}
              {format(
                new Date(deadline.end.replace(':00Z', '')),
                language === 'en-us' ? 'MM/dd/yy' : 'dd/MM/yy',
              )}
            </DeadlineInfo>
          )}

          {!!editorUsers.length && step.status !== 'approved' && (
            <UsersInfo>
              <p
                data-tooltip-id="planning-flow-users"
                data-tooltip-content={
                  editorUsers.length > 1 || editorUsers[0].length > 20
                    ? editorUsers.toString().replaceAll(',', ', ')
                    : ''
                }
                data-testid="text-editor-users"
              >
                {editorUsers[0].length > 20
                  ? `${editorUsers[0].slice(0, 17)}...`
                  : editorUsers[0]}{' '}
                {editorUsers.length > 1 ? `+ ${editorUsers.length - 1}` : ''}
              </p>
            </UsersInfo>
          )}
        </>
      )}
    </Container>
  );
};
