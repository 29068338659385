import { useInfiniteQuery } from 'react-query';
import ms from 'ms';
import { Frequency } from 'src/workspaces/redux/reducers/Workspace';

import apiSeries from '../service/apiSeries';

export type SerieData = {
  id: string;
  name: string;
  frequency: Frequency;
  tag: string;
  created: string;
  status: string;
  last_updated: string;
};

type ResponseSeries = {
  data: SerieData[];
  limit: number;
  skip: number;
  total: number;
};

type useQuerySeriesProps = {
  searchValue: string;
  selectedTags?: string[];
  requestAllowed: boolean;
  onSuccess?: (data: ResponseSeries | undefined) => void;
  limit?: number;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useQuerySeries = ({
  requestAllowed,
  searchValue,
  selectedTags = [],
  onSuccess,
  limit = 8,
}: useQuerySeriesProps) => {
  const {
    data,
    isLoading,
    isFetching,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery<ResponseSeries>(
    ['series', searchValue, selectedTags.toString()],
    async ({ pageParam = 0 }) => {
      const queryParams = new URLSearchParams();

      queryParams.append('status', 'success');

      if (searchValue) {
        queryParams.append('filter', searchValue);
      }

      if (selectedTags.length) {
        queryParams.append('tags', selectedTags.toString());
      }

      queryParams.append('skip', (pageParam * limit).toString());
      queryParams.append('limit', limit.toString());

      const response = await apiSeries.get<ResponseSeries>(
        `/series?${queryParams.toString()}`,
      );

      return response.data;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (pages.length * limit < lastPage.total) {
          return pages.length;
        }
        return undefined;
      },
      staleTime: ms('20 min'),
      cacheTime: ms('20 min'),
      onSuccess: (successData) => {
        if (successData?.pages?.length && onSuccess) {
          onSuccess(successData.pages[0]);
        }
      },
      enabled: requestAllowed || searchValue === '',
    },
  );

  return {
    seriesData: data,
    seriesIsLoading: isLoading || isFetching,
    seriesIsError: isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  };
};
