import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  max-height: 9.375rem;

  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray2};

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0.313rem;
    height: 0.313rem;
    padding: 0.063rem;
  }

  ::-webkit-scrollbar-track {
    transition: 0.2s;
    margin: 0.188rem;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }

  width: 100%;
`;

type EmailsContainerProps = {
  selectedRoleWidth: number;
};

export const EmailsContainer = styled.div<EmailsContainerProps>`
  width: calc(
    100% - 1rem - ${({ selectedRoleWidth }) => `${selectedRoleWidth}rem`}
  );

  padding: 0.75rem 1rem;

  input {
    border: none;
    border-radius: 5px;
    color: ${({ theme }) => theme.colors.gray6};

    font-size: 0.875rem;

    width: calc(100% - 1rem);
  }

  input:focus {
    outline: none;
  }
`;

export const Tags = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.75rem;

  width: 100%;
`;

type TagProps = {
  selectedRoleWidth: number;
};

export const Tag = styled.div<TagProps>`
  display: flex;
  max-width: calc(100% - 0.625rem);
  align-items: center;
  padding: 0.25rem 0.625rem;
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.primary};
  background-color: rgba(76, 148, 255, 0.12);
  gap: 0.625rem;

  border-radius: 9999px;
  max-width: 100%;

  span {
    max-height: 100%;
    word-break: break-all;
  }

  button {
    display: flex;
    padding: 0.375rem;
    border: none;
    background-color: unset;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

type VisualizeProps = {
  selectedRoleWidth: number;
};

export const Visualize = styled.div<VisualizeProps>`
  position: absolute;
  right: 1rem;

  .select__control,
  .select__control--is-focused {
    width: ${({ selectedRoleWidth }) => `${selectedRoleWidth}rem`};

    border: none !important;
    box-shadow: none !important;

    &:hover {
      border: none !important;
    }
  }

  .select__menu {
    width: 10.5rem;
    left: ${({ selectedRoleWidth }) => `${(selectedRoleWidth ?? 0) - 10.5}rem`};
  }

  .select__value-container {
    padding: 0 !important;
  }

  .select__indicator {
    padding: 0 !important;
    padding-left: 0.5rem !important;
  }

  .select__single-value {
    cursor: pointer;

    margin: 0 !important;

    p,
    svg {
      color: ${({ theme }) => theme.colors.gray4};
    }
  }

  .select__option {
    p {
      font-weight: 400;
    }
  }

  .select__option--is-selected {
    p {
      font-weight: 600;
    }
  }
`;
