import React, { useRef } from 'react';

import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { RowGroupingModule } from 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css';
import 'ag-grid-enterprise/styles/ag-theme-quartz.css';

import { AgGridTableProps } from './types';
import { Container } from './styles';

export const AgGridTable: React.FC<AgGridTableProps> = ({
  tableRef,
  rowData,
  columnDefs,
  // checkDomLayoutType = false,
  // maxRemHeight,
  ...props
}) => {
  const { t: translate } = useTranslation();

  const agGridRef = useRef<AgGridReact>(null);

  const localeText = {
    // Textos dos filtros
    selectAll: translate('agGridTableSelectAll'),
    searchOoo: translate('agGridTableSearch'),
    noMatches: translate('agGridTableNoMatches'),

    // Textos do menu quando clica com o botão direito no body da tabela
    copy: translate('agGridTableCopy'),
  };

  // useEffect(() => {
  //   if (checkDomLayoutType) {
  //     defineDomLayoutType(tableRef ?? agGridRef, maxRemHeight);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [checkDomLayoutType, rowData.length]);

  return (
    <Container
      id="ag-grid-table-container"
      data-testid="ag-grid-table"
      className="ag-theme-quartz"
    >
      <AgGridReact
        ref={tableRef ?? agGridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        stopEditingWhenCellsLoseFocus
        singleClickEdit
        enableRangeHandle
        enableRangeSelection
        suppressMenuHide
        suppressMovableColumns
        suppressAggFilteredOnly
        suppressAggFuncInHeader
        getContextMenuItems={() => ['copy']}
        icons={{
          menu: '<span class="ag-icon-filter"/>',
          sortAscending: '<i class="fa fa-caret-down"/>',
          sortDescending: '<i class="fa fa-caret-up"/>',
          sortUnSort: '<i class="fa fa-sort"/>',
        }}
        headerHeight={42}
        rowHeight={44}
        // onRowGroupOpened={() =>
        //   defineDomLayoutType(tableRef ?? agGridRef, maxRemHeight)
        // }
        modules={[RowGroupingModule]}
        localeText={localeText}
        {...props}
      />
    </Container>
  );
};

// export const defineDomLayoutType = (
//   tableAgRef: React.RefObject<AgGridReact<any>>,
//   maxRemHeight?: number,
// ): void => {
//   const tableContainer = document.getElementById('ag-grid-table-container');

//   const pinnedLeftColumn = document.getElementsByClassName(
//     'ag-pinned-left-cols-container',
//   )?.[0];

//   if (tableContainer && pinnedLeftColumn && tableAgRef.current) {
//     const height = pinnedLeftColumn?.clientHeight;

//     const windowHeight = window.innerHeight;

//     const maxHeight = maxRemHeight || (windowHeight > 800 ? 45 : 30);

//     if (height && height > maxHeight * 16 - 84) {
//       tableContainer.style.height = `${maxHeight}rem`;
//       tableAgRef?.current?.api.setGridOption('domLayout', 'normal');
//     } else {
//       tableContainer.style.height = 'fit-content';
//       tableAgRef?.current?.api.setGridOption('domLayout', 'autoHeight');
//     }
//   }
// };
