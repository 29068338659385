import { parse, format, parseISO } from 'date-fns';

type DateFormats =
  | 'yyyy-MM-dd'
  | 'dd/MM/yyyy'
  | 'dd/MM/yy'
  | 'd/MM/yyyy'
  | 'MM/dd/yyyy'
  | 'MM/dd/yy'
  | 'MM-dd-yyyy'
  | 'MM-dd-yy'
  | 'yy-MM-dd'
  | 'yy-MM-dd'
  | 'yy/MM/dd'
  | 'yyyy/MM/dd'
  | 'dd-MM-yy'
  | 'dd-MM-yyyy';

export function getDateInFormat(
  dateString: string,
  newFormat: DateFormats,
  currentFormat?: DateFormats,
): string {
  try {
    if (currentFormat) {
      return format(parse(dateString, currentFormat, new Date()), newFormat);
    }

    return format(parseISO(dateString), newFormat);
  } catch {
    return 'Error';
  }
}
