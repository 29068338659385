import styled from 'styled-components';

export const Container = styled.div`
  width: auto;
  padding: 1.25rem 0;

  .rc-slider-handle {
    background: ${({ theme }) => theme.colors.primary};
    width: 0.938rem;
    height: 0.938rem;
    border: none;
    cursor: pointer;
  }

  .rc-slider-rail {
    height: 0.313rem;
  }

  .rc-slider-dot {
    height: 0.938rem;
    width: 0.125rem;
    background: ${({ theme }) => theme.colors.gray2};
    border: none;
    margin-left: -0.063rem !important;
    bottom: -0.625rem;
  }

  .rc-slider-track {
    display: none;
  }

  .rc-slider-mark-text {
    color: ${({ theme }) => theme.colors.gray4};
    pointer-events: none;
  }

  .rc-slider-mark-text-active {
    color: ${({ theme }) => theme.colors.gray4};
  }

  .rc-slider-disabled {
    background-color: unset;
    .rc-slider-handle {
      background: ${({ theme }) => theme.colors.gray3};
      cursor: not-allowed;
    }
  }
`;
