const commonModelExplorerPTBRTranslations = {
  selectModelTitle: 'Lista de Modelos',
  selectModelLabel: 'Número na lista',
  selectModelPlaceHolder: 'Escolha um modelo',
  sendModelToProdTitle: 'Seleção do Usuário',
  sendModelToProdDescr:
    'Otimize sua tomada de decisão com base nos resultados de seu modelo favorito. Acesse previsões, decomponha resultados históricos, simule cenários alternativos e muito mais.',
  sendToProductionButtonText: 'Enviar para seleção do usuário',
  nOfCombinedModels: 'Nº de modelos combinados',
  nOfTrees: 'Nº de árvores',
  nOfVarsRandomSampled: 'Nº de variáveis amostradas aleatoriamente',
  sendToProdFailMessage: 'Ocorreu um erro ao enviar o modelo para produção',
  modelExplorerText: 'Explorador de Modelos',
  regularizedRegressionText: 'Regressão Regularizada',
  forecastCombinationText: 'Combinação de Modelos',
  elementaryText: 'Elementar',
};

export default commonModelExplorerPTBRTranslations;
