import React, { useState } from 'react';

import { ArrowLeft, Trash, UserCirclePlus } from 'phosphor-react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { Card } from 'src/components/Card';
import { CheckBox } from 'src/components/CheckBox';
import { Pagination } from 'src/components/Pagination';
import { Table, Tbody, Td, Th, Thead, Tr } from 'src/components/Table';
import light from 'src/styles/themes/light';
import { useQuery } from 'react-query';
import api from 'src/feature-store/service/api';
import { queryClient } from 'src/service/queryClient';
import { ContainerErrorChart } from 'src/feature-store/pages/VisualizeFeatures/styles';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { useTranslation } from 'react-i18next';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';

import { InsertUserGroupPremium } from '../InsertUserGroupPremium';
import {
  CardFeatures,
  Container,
  ContainerTable,
  DivDeletePagination,
} from './styles';
import { ContentButtonAddIndicator } from '../ListIndicatorsFromGroupPremium/styles';
import { ModalDeleteUser } from './ModalDeleteUser';

type ParamsProps = {
  id: string;
};

interface DataUsersFromGroupProps {
  access_group_id: string;
  id: string;
  user: string;
}

interface IndicatorsFromGroupProps {
  limit: number;
  skip: number;
  total: number;
  data: DataUsersFromGroupProps[];
}

interface DataIndicatorsPremium {
  id: string;
  name: {
    'pt-br': string;
    'en-us': string;
  };
  description: {
    'pt-br': string;
    'en-us': string;
  };
}

interface UserSelectedProps {
  id: string;
}

const QUANTITY_ITEMS_PAGE = 10;

export const ListUsersFromGroupPremium: React.FC = () => {
  const [pages, setPages] = useState<number>(1);
  const [insertUserModal, setInsertUserModal] = useState<boolean>();
  const [modalDeleteUser, setModalDeleteUser] = useState<boolean>();
  const [userSelected, setUserSelected] = useState<UserSelectedProps[]>([]);
  const { t: translate } = useTranslation();
  const { id } = useParams<ParamsProps>();
  const { language } = useSelector((state: RootState) => state.auth.user);
  const navigate = useNavigate();

  const {
    data: usersFromGroup,
    isLoading,
    isFetching,
    isError,
  } = useQuery(
    ['usersFromGroup', pages, id],
    async () => {
      const { data } = await api.get<IndicatorsFromGroupProps>(
        `/access-groups/${id}/users?skip=${
          (pages - 1) * QUANTITY_ITEMS_PAGE
        }&limit=${QUANTITY_ITEMS_PAGE}`,
      );

      return data;
    },
    {
      staleTime: 1000 * 60 * 20,
    },
  );

  const { data: groupsPremiumInfo, isLoading: isLoadingGroupPremium } =
    useQuery(
      ['groupsPremiumInfo', pages, id],
      async () => {
        const { data } = await api.get<DataIndicatorsPremium>(
          `/access-groups/${id}?skip=${
            (pages - 1) * QUANTITY_ITEMS_PAGE
          }&limit=${QUANTITY_ITEMS_PAGE}`,
        );
        return data;
      },
      {
        staleTime: 1000 * 60 * 20,
      },
    );

  const handleSelectUser = (userId: string) => {
    if (userSelected.some((user) => user.id === userId)) {
      setUserSelected(
        userSelected.filter((selected) => selected.id !== userId),
      );
    } else {
      setUserSelected([
        ...userSelected,
        {
          id: userId,
        },
      ]);
    }
  };

  return (
    <Container data-testid="container-list-indicators-from-group">
      {isError ? (
        <>
          <div className="containerLinear">
            <CardFeatures>
              <ArrowLeft
                size="2rem"
                onClick={() => {
                  navigate(-1);
                  queryClient.removeQueries(['groupsPremiumInfo']);
                  queryClient.removeQueries(['usersFromGroup']);
                }}
                data-cy="button-back"
                data-testid="button-back"
                style={{ cursor: 'pointer' }}
              />
            </CardFeatures>
          </div>
          <div className="containerLinear">
            <ContainerErrorChart>
              <ContainerMaintenance
                content="data"
                text={translate('accessErrorUsers')}
              />
            </ContainerErrorChart>
          </div>
        </>
      ) : isLoadingGroupPremium ||
        !groupsPremiumInfo ||
        isLoading ||
        isFetching ? (
        // eslint-disable-next-line react/jsx-indent
        <>
          <div className="containerLinear">
            <ContainerSkeleton
              withLoading={false}
              style={{
                height: '150px',
              }}
            />
          </div>
          <div className="containerLinear">
            <ContainerSkeleton
              withLoading={false}
              style={{
                height: '500px',
              }}
            />
          </div>
        </>
      ) : usersFromGroup?.data.length === 0 ? (
        <>
          <div className="containerLinear">
            <CardFeatures>
              <div className="iconBack">
                <ArrowLeft
                  size="2rem"
                  onClick={() =>
                    navigate('/feature-store/admin/groups-premium')
                  }
                  data-cy="button-back"
                  data-testid="button-back"
                />
              </div>
              <div className="cardDescription">
                <Card
                  textCard={
                    groupsPremiumInfo.name[language] ??
                    groupsPremiumInfo.name['en-us']
                  }
                  textDescription={
                    groupsPremiumInfo.description[language] ??
                    groupsPremiumInfo.description['en-us']
                  }
                />
              </div>
              <ContentButtonAddIndicator>
                <Button
                  buttonType="primary"
                  icon={<UserCirclePlus size="1.125rem" color="white" />}
                  onClick={() => setInsertUserModal(true)}
                  data-testid="button-add-new-user"
                  data-cy="button-add-new-user"
                >
                  {translate('accessAddUser')}
                </Button>
              </ContentButtonAddIndicator>
            </CardFeatures>
          </div>
          <div className="containerLinear">
            <ContainerMaintenance
              content="data"
              text={translate('accessUnableToFindUser')}
            />
          </div>
        </>
      ) : (
        <>
          <div className="containerLinear">
            <CardFeatures>
              <div className="iconBack">
                <ArrowLeft
                  size="2rem"
                  onClick={() =>
                    navigate('/feature-store/admin/groups-premium')
                  }
                  data-cy="button-back"
                  data-testid="button-back"
                />
              </div>
              <div className="cardDescription">
                <Card
                  textCard={
                    groupsPremiumInfo.name[language] ??
                    groupsPremiumInfo.name['en-us']
                  }
                  textDescription={
                    groupsPremiumInfo.description[language] ??
                    groupsPremiumInfo.description['en-us']
                  }
                />
              </div>
            </CardFeatures>
            <ContentButtonAddIndicator>
              <Button
                buttonType="primary"
                icon={<UserCirclePlus size="1.125rem" />}
                onClick={() => setInsertUserModal(true)}
                data-testid="button-add-new-user"
                data-cy="button-add-new-user"
              >
                {translate('accessAddUser')}
              </Button>
            </ContentButtonAddIndicator>
          </div>
          <div className="containerLinear">
            <ContainerTable>
              <Table data-testid="table-list-users-from-group-premium">
                <Thead>
                  <Tr>
                    <Th>
                      <p>Email</p>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {usersFromGroup?.data.map((user) => (
                    <Tr key={user.id}>
                      <Td style={{ maxWidth: '200px' }}>
                        <CheckBox
                          key={user.id}
                          data-testid={user.id}
                          data-cy={user.id}
                          label={user.user}
                          onChange={() => handleSelectUser(user.id)}
                          checked={userSelected.some(
                            (selected) => selected.id === user.id,
                          )}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </ContainerTable>
            <DivDeletePagination>
              <Button
                buttonType="secondary"
                data-testid="button-delete"
                data-cy="button-delete"
                icon={<Trash size="1.125rem" />}
                onClick={() => setModalDeleteUser(true)}
                disabled={userSelected.length === 0}
                style={{
                  border: `1px solid ${light.colors.primary}`,
                  color: light.colors.primary,
                }}
              >
                {translate('accessDelete')}
              </Button>
              {usersFromGroup?.total && (
                <Pagination
                  page={pages}
                  setPage={setPages}
                  total={usersFromGroup.total}
                  quantityItemsPerPage={QUANTITY_ITEMS_PAGE}
                  name={translate('accessUsers')}
                />
              )}
            </DivDeletePagination>
          </div>
        </>
      )}

      {insertUserModal && (
        <InsertUserGroupPremium
          visible={insertUserModal}
          setVisible={setInsertUserModal}
          idGroup={id ?? ''}
        />
      )}

      {modalDeleteUser && (
        <ModalDeleteUser
          userSelected={userSelected}
          setUserSelected={setUserSelected}
          setVisible={setModalDeleteUser}
          group={id ?? ''}
        />
      )}
    </Container>
  );
};
