export const AISelectionENUSTranslate = {
  AISelectionMessageErrorServerCanNotFind:
    'The server can not find the requested resource.',
  AISelectionMessageErrorServerRequestedResource:
    'The requested resource does not exist.',
  AISelectionMessageErrorEngineNotSupported: 'Project engine not supported.',
  AISelectionMessageErrorAnInternalError:
    'An internal error has occurred while processing the request.',
  AISelectionLoadingTitle: 'We are working on it!',
  AISelectionLoadingDescription:
    'This might take a while... But don’t worry, your AI Selection model is being processed and will be done soon!',
  AISelectionQuarterlySeriesSummaryIsOnlyAvailable:
    'Quarterly series summary is only available for series with at least 1 quarter of observation.',
  AISelectionNOfDays: 'N. of days',
};
