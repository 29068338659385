import React from 'react';

import { CheckCircle } from 'phosphor-react';
import { useTranslation } from 'react-i18next';

import { StepContent } from '../styles';
import { RequirementRow } from './styles';

export const Step2: React.FC = () => {
  const { t: translate } = useTranslation();

  return (
    <StepContent data-testid="tutorial-step-2">
      <RequirementRow>
        <CheckCircle size="1rem" weight="fill" />
        <p>{translate('tutorialFirstInfoStep2')}</p>
      </RequirementRow>

      <RequirementRow>
        <CheckCircle size="1rem" weight="fill" />
        <p>{translate('tutorialSecondInfoStep2')}</p>
      </RequirementRow>

      <RequirementRow>
        <CheckCircle size="1rem" weight="fill" />
        <p>{translate('tutorialThirdInfoStep2')}</p>
      </RequirementRow>
    </StepContent>
  );
};
