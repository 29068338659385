import React from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Card } from 'src/components/Card';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import api from 'src/models/service/api';
import { RootState } from 'src/redux/store';

import { ContentTableProjectSummary } from './styles';

interface Summary {
  y_count: number;
  models_count: number;
  best_accuracy: {
    y_label: string;
    type: string;
    accuracy: number;
  };
  worst_accuracy: {
    y_label: string;
    type: string;
    accuracy: number;
  };
  average_accuracy: number;
}

export const ProjectSummary: React.FC = () => {
  const { project } = useSelector((state: RootState) => state);

  const { t: translate } = useTranslation();

  const {
    data: dataSummary,
    isLoading: isLoadingSummary,
    isFetching: isFetchingSummary,
    isError: isErrorSummary,
  } = useQuery<Summary>(
    ['project overview', 'project-summary', project.id],
    async () => {
      const { data } = await api.get(
        `/projects/${project.id}/overview/summary`,
      );
      return data;
    },
    {
      enabled: !!project.id,
      staleTime: 1000 * 60 * 20,
    },
  );

  function adjustAccuracy(accuracy: number): string {
    const notPercentage = ['MASE', 'MASEs', 'RMSE'];
    if (project.accuracyCrit && !notPercentage.includes(project.accuracyCrit)) {
      return `${accuracy.toFixed(2)}%`;
    }
    return accuracy.toFixed(2);
  }

  return (
    <>
      {!isErrorSummary ? (
        <div className="containerLinear">
          <Card
            textCard={translate('projectOverviewSummaryTitle')}
            textDescription={translate('projectOverviewSummaryDescription')}
          />

          <ContentTableProjectSummary>
            <table data-testid="table-project-summary">
              <thead>
                <tr>
                  <th>
                    {translate('projectOverviewSummaryNumberOfVariables')}
                  </th>
                  <th>
                    {translate('projectOverviewSummaryNumberOfEstimatedModels')}
                  </th>
                  <th>{translate('projectOverviewSummaryBestAccuracy')}</th>
                  <th>{translate('projectOverviewSummaryWorstAccuracy')}</th>
                  <th>
                    {translate('projectOverviewSummaryProjectAccuracyAverage')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {isLoadingSummary || isFetchingSummary || !dataSummary ? (
                  <tr>
                    {Array.from({ length: 5 }, (_, index) => (
                      <td
                        key={`project-summary-td-loading-${index}`}
                        aria-label="loading"
                      >
                        <ContainerSkeleton
                          data-testid={`project-summary-td-loading-${index}`}
                          withLoading={false}
                          style={{
                            width:
                              index === 2 || index === 3 ? '100px' : '30px',
                            height: '16px',
                            margin: '0 auto',
                          }}
                        />
                      </td>
                    ))}
                  </tr>
                ) : (
                  <tr>
                    <td>{dataSummary.y_count}</td>
                    <td>{dataSummary.models_count}</td>
                    <td>
                      {`${dataSummary.best_accuracy.y_label} (${adjustAccuracy(
                        dataSummary.best_accuracy.accuracy,
                      )})`}
                    </td>
                    <td>
                      {`${dataSummary.worst_accuracy.y_label} (${adjustAccuracy(
                        dataSummary.worst_accuracy.accuracy,
                      )})`}
                    </td>
                    <td>{`${adjustAccuracy(dataSummary.average_accuracy)}`}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </ContentTableProjectSummary>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
