export function formatCompactNotation(
  number: number,
  fractionDigits = 2,
  language: 'pt-br' | 'en-us' = 'en-us',
): string {
  return Intl.NumberFormat(language === 'pt-br' ? 'pt-BR' : 'en-US', {
    notation: 'compact',
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
  }).format(number);
}
