import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { PencilSimple } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'src/components/Card';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { SelectedFilters } from 'src/components/SelectedFilters';
import { SelectedFilterOptions } from 'src/components/SelectedFilters/types';
import { translateSomeMessagesFromBackend } from 'src/i18n';
import { WorkspaceProjectionsContext } from 'src/workspaces/contexts/WorkspaceProjectionsContext';
import {
  WorkspaceProjectionsFrequency,
  WorkspaceProjectionsTransformation,
  changeWorkspaceProjectionsIsLatestDataActive,
  changeWorkspaceProjectionsLatestDataEnabled,
  changeWorkspaceProjectionsLoadingLevelData,
  changeWorkspaceProjectionsLoadingVariationData,
  changeWorkspaceProjectionsTransformations,
  changeWorkspaceProjectionsTransformationsEnabled,
} from 'src/workspaces/redux/reducers/WorkspaceProjectionsOptions';
import apiWorkspace from 'src/workspaces/service/api';
import { RootState } from 'src/redux/store';
import {
  frequencyLatestData,
  getLatestData,
} from 'src/utils/charts/getLatestData';
import { sleep } from 'src/utils/sleep';
import { transformUppercaseFirstLetter } from 'src/utils/strings/transformUppercaseFirstLetter';

import { ResultsChart } from './ResultsChart';
import { ResultsUnderstanding } from './ResultsUnderstanding';
import { ResultContainer } from './styles';
import { useQueryReleaseData } from '../../../../hooks/useQueryReleaseData';

interface Error {
  detail?: {
    description?: string;
    detail?: string;
  };
}

interface XSelected {
  x: string;
  date: string;
}

interface Chart {
  date: (string | number)[];
  value: number[];
}

interface Result {
  forecast: Chart;
  historical: Chart;
  simulated: Chart;
}

interface ResponseResultChart {
  dates: string[];
  values: number[];
}

interface ResponseResult {
  forecast: ResponseResultChart;
  historical: ResponseResultChart;
}

interface AISelectionResultsContextTypes {
  isYFrequencyValid: boolean;
  xSelected: XSelected | null;
  isLoadingChart: boolean;
  dataLevel: Result | undefined;
  dataVariationYoY: Result | undefined;
  dataVariationPoP: Result | undefined;
  isLatestDataDisabled: boolean;
  canSelectForecast: boolean;
  selectX: (x: string) => void;
}

export const AISelectionResultsContext = createContext(
  {} as AISelectionResultsContextTypes,
);

export const Results: React.FC = () => {
  const [xSelected, setXSelected] = useState<XSelected | null>(null);

  const {
    workspace,
    auth: { user },
    workspaceProjectionsOptions: {
      frequency,
      isLatestDataActive,
      transformations,
      loadingLevelData,
      loadingVariationData,
      inflation,
      forecastType,
    },
  } = useSelector((state: RootState) => state);

  const [chartFrequency, setChartFrequency] =
    useState<WorkspaceProjectionsFrequency>(frequency);
  const [chartIsLatestDataActive, setChartIsLatestDataActive] =
    useState(isLatestDataActive);
  const [frequencyAux, setFrequencyAux] =
    useState<WorkspaceProjectionsFrequency>(frequency);

  const { listOfVariables, isLoadingListOfVariables, errorListOfVariables } =
    useContext(WorkspaceProjectionsContext);

  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  const { data: releaseData } = useQueryReleaseData(
    workspace.id,
    workspace.releaseSelected?.id,
  );

  useEffect(() => {
    (async () => {
      await sleep(50);
      setChartFrequency(frequency);
    })();
  }, [frequency]);

  useEffect(() => {
    (async () => {
      await sleep(50);
      setChartIsLatestDataActive(isLatestDataActive);
    })();
  }, [isLatestDataActive]);

  const isYFrequencyValid = useMemo(() => {
    if (!workspace.frequency) {
      return false;
    }

    const yFrequency = workspace.frequency;

    if (frequency === 'original') {
      const frequencyAvailableForOriginal = [
        'monthly',
        'bimonthly',
        'quarterly',
        'half-year',
        'annual',
      ];

      return frequencyAvailableForOriginal.includes(yFrequency);
    }

    return yFrequency === 'monthly';
  }, [frequency, workspace.frequency]);

  const reversedSteps = [...(releaseData?.data?.steps ?? [])].reverse();

  let lastStepWithData =
    reversedSteps.findIndex(({ status }) => status !== 'created') + 1;

  if (lastStepWithData === 0) {
    lastStepWithData = 1;
  }

  const {
    data: dataVariationYoY,
    isLoading: isLoadingVariationYoY,
    isFetching: isFetchingVariationYoY,
    error: errorVariationYoY,
  } = useQuery<ResponseResult, AxiosError<Error>>(
    [
      'workspace',
      'projections',
      'series data',
      workspace.id,
      workspace.releaseSelected?.id,
      workspace.ySelected?.y_label,
      forecastType,
      lastStepWithData,
      frequency,
      'variationYoY',
      inflation,
    ],
    async () => {
      const inflationAux = inflation === 'nominal' ? 'inflate' : 'original';

      const { data } = await apiWorkspace.get<ResponseResult>(
        `/workspaces/${workspace.id}/releases/${workspace.releaseSelected?.id}/series/raw/${workspace.ySelected?.y_label}?frequency=${frequency}&transformation=variation&variation_method=year_over_year&inflation=${inflationAux}&data_type=${forecastType}&step=${lastStepWithData}`,
      );

      return {
        ...data,
        serie: workspace.ySelected?.y_label,
        simulated: {
          dates: [],
          values: [],
        },
      };
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled:
        !!workspace.id &&
        !!workspace.releaseSelected?.id &&
        !!workspace.ySelected?.y_label &&
        !!workspace.ySelected?.model_id &&
        !!listOfVariables?.y,
    },
  );

  const {
    data: dataVariationPoP,
    isLoading: isLoadingVariationPoP,
    isFetching: isFetchingVariationPoP,
    error: errorVariationPoP,
  } = useQuery<ResponseResult, AxiosError<Error>>(
    [
      'workspace',
      'projections',
      'series data',
      workspace.id,
      workspace.releaseSelected?.id,
      workspace.ySelected?.y_label,
      forecastType,
      lastStepWithData,
      frequency,
      'variationPoP',
      inflation,
    ],
    async () => {
      const inflationAux = inflation === 'nominal' ? 'inflate' : 'original';

      const { data } = await apiWorkspace.get<ResponseResult>(
        `/workspaces/${workspace.id}/releases/${workspace.releaseSelected?.id}/series/raw/${workspace.ySelected?.y_label}?frequency=${frequency}&transformation=variation&variation_method=over_before&inflation=${inflationAux}&data_type=${forecastType}&step=${lastStepWithData}`,
      );

      return {
        ...data,
        serie: workspace.ySelected?.y_label,
        simulated: {
          dates: [],
          values: [],
        },
      };
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled:
        !!workspace.id &&
        !!workspace.releaseSelected?.id &&
        !!workspace.ySelected?.y_label &&
        !!workspace.ySelected?.model_id &&
        !!listOfVariables?.y,
    },
  );

  const {
    data: dataLevel,
    isLoading: isLoadingLevel,
    isFetching: isFetchingLevel,
    error: errorLevel,
  } = useQuery<ResponseResult, AxiosError<Error>>(
    [
      'workspace',
      'projections',
      'series data',
      workspace.id,
      workspace.releaseSelected?.id,
      workspace.ySelected?.y_label,
      forecastType,
      lastStepWithData,
      frequency,
      'level',
      inflation,
    ],
    async () => {
      const inflationAux = inflation === 'nominal' ? 'inflate' : 'original';

      const { data } = await apiWorkspace.get<ResponseResult>(
        `/workspaces/${workspace.id}/releases/${workspace.releaseSelected?.id}/series/raw/${workspace.ySelected?.y_label}?frequency=${frequency}&transformation=level&inflation=${inflationAux}&data_type=${forecastType}&step=${lastStepWithData}`,
      );

      return {
        ...data,
        serie: workspace.ySelected?.y_label,
        simulated: {
          dates: [],
          values: [],
        },
      };
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled:
        !!workspace.id &&
        !!workspace.releaseSelected?.id &&
        !!workspace.ySelected?.y_label &&
        !!workspace.ySelected?.model_id &&
        !!listOfVariables?.y,
    },
  );

  const isLoadingChart = useMemo(
    () =>
      isLoadingLevel ||
      isFetchingLevel ||
      isLoadingVariationYoY ||
      isFetchingVariationYoY ||
      isLoadingVariationPoP ||
      isFetchingVariationPoP ||
      isLoadingListOfVariables ||
      (!dataLevel && !dataVariationYoY && !dataVariationPoP),
    [
      isLoadingLevel,
      isFetchingLevel,
      isLoadingVariationYoY,
      isFetchingVariationYoY,
      isLoadingVariationPoP,
      isFetchingVariationPoP,
      isLoadingListOfVariables,
      dataLevel,
      dataVariationYoY,
      dataVariationPoP,
    ],
  );

  useEffect(() => {
    function checkIfTransformationIsAvailable() {
      const availableTransformation: WorkspaceProjectionsTransformation[] = [
        ...transformations,
      ];
      if (!isLoadingChart) {
        if (!dataLevel && availableTransformation.includes('level')) {
          const index = availableTransformation.findIndex(
            (transformation) => transformation === 'level',
          );
          availableTransformation.splice(index, 1);
        }
        if (
          !dataVariationYoY &&
          availableTransformation.includes('variationYoY')
        ) {
          const index = availableTransformation.findIndex(
            (transformation) => transformation === 'variationYoY',
          );
          availableTransformation.splice(index, 1);
        }
        if (
          !dataVariationPoP &&
          availableTransformation.includes('variationPoP')
        ) {
          const index = availableTransformation.findIndex(
            (transformation) => transformation === 'variationPoP',
          );
          availableTransformation.splice(index, 1);
        }

        if (availableTransformation.length !== transformations.length) {
          dispatch(
            changeWorkspaceProjectionsTransformations(availableTransformation),
          );
        }
      }
    }

    checkIfTransformationIsAvailable();
  }, [
    dataLevel,
    dataVariationPoP,
    dataVariationYoY,
    dispatch,
    isLoadingChart,
    transformations,
  ]);

  const adjustDateToXSelected = useCallback(
    (x: string | undefined): string => {
      let date = '';

      if (x) {
        if (frequency === 'quarterly') {
          const [year, quarterly] = x.split(' Q');
          const month = String(Number(quarterly) * 3).padStart(2, '0');
          date = `${year}-${month}-01`;
        } else if (frequency === 'yearly') {
          const year = x;
          date = `${year}-12-01`;
        } else {
          date = x;
        }
      }

      return date;
    },
    [frequency],
  );

  const isLatestDataDisabled = useMemo(() => {
    const total =
      (dataLevel?.historical.values.length ?? 0) +
      (dataLevel?.forecast.values.length ?? 0);

    if (frequency === 'original') {
      return (
        total <=
        frequencyLatestData[
          workspace.frequency as keyof typeof frequencyLatestData
        ]
      );
    }
    if (frequency === 'monthly') {
      return total <= frequencyLatestData.monthly;
    }
    if (frequency === 'bimonthly') {
      return total <= frequencyLatestData.bimonthly;
    }
    if (frequency === 'quarterly') {
      return total <= frequencyLatestData.quarterly;
    }
    if (frequency === 'half-year') {
      return total <= frequencyLatestData['half-year'];
    }

    if (frequency === 'yearly') {
      return total <= frequencyLatestData.annual;
    }

    return true;
  }, [
    dataLevel?.forecast.values.length,
    dataLevel?.historical.values.length,
    frequency,
    workspace.frequency,
  ]);

  const error = useMemo(
    () =>
      (!!errorLevel && !!errorVariationYoY && !!errorVariationPoP) ||
      errorListOfVariables,
    [errorLevel, errorListOfVariables, errorVariationPoP, errorVariationYoY],
  );

  useEffect(() => {
    if ((!isLoadingChart && isLatestDataDisabled) || error) {
      dispatch(changeWorkspaceProjectionsIsLatestDataActive(false));
    }
  }, [dispatch, error, isLatestDataDisabled, isLoadingChart]);

  function returnMessageError() {
    let messageError = errorLevel?.response?.data?.detail?.detail;

    if (
      messageError ===
        'Annual series summary is only available for series with at least 1 year of observation.' ||
      messageError ===
        'Quarterly series summary is only available for series with at least 1 quarter of observation.' ||
      messageError ===
        'Monthly aggregation is not available for dataset with missing values.'
    ) {
      return messageError;
    }

    messageError =
      errorListOfVariables?.response?.data?.detail?.description ??
      errorLevel?.response?.data?.detail?.description ??
      errorVariationYoY?.response?.data?.detail?.description ??
      errorVariationPoP?.response?.data?.detail?.description;

    if (
      messageError === 'No data is available.' ||
      messageError === 'The requested resource does not exist.'
    ) {
      if (frequency === 'bimonthly') {
        return 'Bimonthly Series Rate is not available for this model';
      }
      if (frequency === 'quarterly') {
        return 'Quarterly Series Rate is not available for this model';
      }
      if (frequency === 'half-year') {
        return 'Half-year Series Rate is not available for this model';
      }
      if (frequency === 'yearly') {
        return 'Annual Series Rate is not available for this model';
      }
    }

    return messageError;
  }

  const {
    dataLevelAdjusted,
    dataVariationYoYAdjusted,
    dataVariationPoPAdjusted,
  } = useMemo(() => {
    const formatDates = (dates: string[]) =>
      dates.map((date) => {
        const dateAux = new Date(date.replace('Z', ''));

        if (frequency === 'quarterly') {
          return `${dateAux.getFullYear()} Q${
            Math.floor(dateAux.getMonth() / 3) + 1
          }`;
        }

        return format(
          new Date(date.replace('Z', '')),
          frequency === 'yearly' ? 'yyyy' : 'yyyy-MM-dd',
        );
      });

    let dataLevelAdjustedAux: Result = {
      historical: {
        date: [],
        value: [],
      },
      forecast: {
        date: [],
        value: [],
      },
      simulated: {
        date: [],
        value: [],
      },
    };

    let dataVariationYoYAdjustedAux: Result = {
      historical: {
        date: [],
        value: [],
      },
      forecast: {
        date: [],
        value: [],
      },
      simulated: {
        date: [],
        value: [],
      },
    };

    let dataVariationPoPAdjustedAux: Result = {
      historical: {
        date: [],
        value: [],
      },
      forecast: {
        date: [],
        value: [],
      },
      simulated: {
        date: [],
        value: [],
      },
    };

    const dataLevelFormatted = {
      historical: {
        date: formatDates(dataLevel?.historical.dates ?? []),
        value: dataLevel?.historical.values ?? [],
      },
      forecast: {
        date: formatDates(dataLevel?.forecast.dates ?? []),
        value: dataLevel?.forecast.values ?? [],
      },
      simulated: {
        date: [],
        value: [],
      },
    };

    const dataVariationYoYFormatted = {
      historical: {
        date: formatDates(dataVariationYoY?.historical.dates ?? []),
        value: dataVariationYoY?.historical.values ?? [],
      },
      forecast: {
        date: formatDates(dataVariationYoY?.forecast.dates ?? []),
        value: dataVariationYoY?.forecast.values ?? [],
      },
      simulated: {
        date: [],
        value: [],
      },
    };

    const dataVariationPoPFormatted = {
      historical: {
        date: formatDates(dataVariationPoP?.historical.dates ?? []),
        value: dataVariationPoP?.historical.values ?? [],
      },
      forecast: {
        date: formatDates(dataVariationPoP?.forecast.dates ?? []),
        value: dataVariationPoP?.forecast.values ?? [],
      },
      simulated: {
        date: [],
        value: [],
      },
    };

    const { forecast: forecastYoY, historical: historicalYoY } =
      dataVariationYoYFormatted;
    const { forecast: forecastPoP, historical: historicalPoP } =
      dataVariationPoPFormatted;

    if (forecastYoY.date.length && forecastYoY.value.length) {
      if (historicalYoY.date.length && historicalYoY.date.length) {
        forecastYoY.date = [
          historicalYoY.date[historicalYoY.date.length - 1],
          ...forecastYoY.date,
        ];

        forecastYoY.value = [
          historicalYoY.value[historicalYoY.value.length - 1],
          ...forecastYoY.value,
        ];
      }
    }

    if (forecastPoP.date.length && forecastPoP.value.length) {
      if (historicalPoP.date.length && historicalPoP.date.length) {
        forecastPoP.date = [
          historicalPoP.date[historicalPoP.date.length - 1],
          ...forecastPoP.date,
        ];

        forecastPoP.value = [
          historicalPoP.value[historicalPoP.value.length - 1],
          ...forecastPoP.value,
        ];
      }
    }

    if (!isLatestDataActive) {
      if (dataLevel) {
        dataLevelAdjustedAux = dataLevelFormatted;
      }
      if (dataVariationYoY) {
        dataVariationYoYAdjustedAux = dataVariationYoYFormatted;
      }
      if (dataVariationPoP) {
        dataVariationPoPAdjustedAux = dataVariationPoPFormatted;
      }
    } else if (frequency === 'original') {
      switch (workspace.frequency) {
        case 'daily':
          dataLevelAdjustedAux = getLatestData(dataLevelFormatted, 'daily');
          dataVariationYoYAdjustedAux = getLatestData(
            dataVariationYoYFormatted,
            'daily',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          dataVariationPoPAdjustedAux = getLatestData(
            dataVariationPoPFormatted,
            'daily',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        case 'weekly':
          dataLevelAdjustedAux = getLatestData(dataLevelFormatted, 'weekly');
          dataVariationYoYAdjustedAux = getLatestData(
            dataVariationYoYFormatted,
            'weekly',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          dataVariationPoPAdjustedAux = getLatestData(
            dataVariationPoPFormatted,
            'weekly',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        case 'fortnightly':
          dataLevelAdjustedAux = getLatestData(
            dataLevelFormatted,
            'fortnightly',
          );
          dataVariationYoYAdjustedAux = getLatestData(
            dataVariationYoYFormatted,
            'fortnightly',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          dataVariationPoPAdjustedAux = getLatestData(
            dataVariationPoPFormatted,
            'fortnightly',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        case 'monthly':
          dataLevelAdjustedAux = getLatestData(dataLevelFormatted, 'monthly');
          dataVariationYoYAdjustedAux = getLatestData(
            dataVariationYoYFormatted,
            'monthly',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          dataVariationPoPAdjustedAux = getLatestData(
            dataVariationPoPFormatted,
            'monthly',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        case 'bimonthly':
          dataLevelAdjustedAux = getLatestData(dataLevelFormatted, 'bimonthly');
          dataVariationYoYAdjustedAux = getLatestData(
            dataVariationYoYFormatted,
            'bimonthly',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          dataVariationPoPAdjustedAux = getLatestData(
            dataVariationPoPFormatted,
            'bimonthly',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        case 'quarterly':
          dataLevelAdjustedAux = getLatestData(dataLevelFormatted, 'quarterly');
          dataVariationYoYAdjustedAux = getLatestData(
            dataVariationYoYFormatted,
            'quarterly',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          dataVariationPoPAdjustedAux = getLatestData(
            dataVariationPoPFormatted,
            'quarterly',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        case 'half-year':
          dataLevelAdjustedAux = getLatestData(dataLevelFormatted, 'half-year');
          dataVariationYoYAdjustedAux = getLatestData(
            dataVariationYoYFormatted,
            'half-year',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          dataVariationPoPAdjustedAux = getLatestData(
            dataVariationPoPFormatted,
            'half-year',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        case 'annual':
          dataLevelAdjustedAux = getLatestData(dataLevelFormatted, 'annual');
          dataVariationYoYAdjustedAux = getLatestData(
            dataVariationYoYFormatted,
            'annual',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          dataVariationPoPAdjustedAux = getLatestData(
            dataVariationPoPFormatted,
            'annual',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        default:
          if (dataLevel) {
            dataLevelAdjustedAux = dataLevelFormatted;
          }
          if (dataVariationYoY) {
            dataVariationYoYAdjustedAux = dataVariationYoYFormatted;
          }
          if (dataVariationPoP) {
            dataVariationPoPAdjustedAux = dataVariationPoPFormatted;
          }
          break;
      }
    } else if (frequency === 'monthly') {
      dataLevelAdjustedAux = getLatestData(dataLevelFormatted, 'monthly');
      dataVariationYoYAdjustedAux = getLatestData(
        dataVariationYoYFormatted,
        'monthly',
        true,
        dataLevelAdjustedAux.historical.date[0],
      );
      dataVariationPoPAdjustedAux = getLatestData(
        dataVariationPoPFormatted,
        'monthly',
        true,
        dataLevelAdjustedAux.historical.date[0],
      );
    } else if (frequency === 'bimonthly') {
      dataLevelAdjustedAux = getLatestData(dataLevelFormatted, 'bimonthly');
      dataVariationYoYAdjustedAux = getLatestData(
        dataVariationYoYFormatted,
        'bimonthly',
        true,
        dataLevelAdjustedAux.historical.date[0],
      );
      dataVariationPoPAdjustedAux = getLatestData(
        dataVariationPoPFormatted,
        'bimonthly',
        true,
        dataLevelAdjustedAux.historical.date[0],
      );
    } else if (frequency === 'quarterly') {
      dataLevelAdjustedAux = getLatestData(dataLevelFormatted, 'quarterly');
      dataVariationYoYAdjustedAux = getLatestData(
        dataVariationYoYFormatted,
        'quarterly',
        true,
        dataLevelAdjustedAux.historical.date[0],
      );
      dataVariationPoPAdjustedAux = getLatestData(
        dataVariationPoPFormatted,
        'quarterly',
        true,
        dataLevelAdjustedAux.historical.date[0],
      );
    } else if (frequency === 'half-year') {
      dataLevelAdjustedAux = getLatestData(dataLevelFormatted, 'half-year');
      dataVariationYoYAdjustedAux = getLatestData(
        dataVariationYoYFormatted,
        'half-year',
        true,
        dataLevelAdjustedAux.historical.date[0],
      );
      dataVariationPoPAdjustedAux = getLatestData(
        dataVariationYoYFormatted,
        'half-year',
        true,
        dataLevelAdjustedAux.historical.date[0],
      );
    } else {
      dataLevelAdjustedAux = getLatestData(dataLevelFormatted, 'annual');
      dataVariationYoYAdjustedAux = getLatestData(
        dataVariationYoYFormatted,
        'annual',
        true,
        dataLevelAdjustedAux.historical.date[0],
      );
      dataVariationPoPAdjustedAux = getLatestData(
        dataVariationYoYFormatted,
        'annual',
        true,
        dataLevelAdjustedAux.historical.date[0],
      );
    }

    return {
      dataLevelAdjusted: dataLevelAdjustedAux,
      dataVariationYoYAdjusted: dataVariationYoYAdjustedAux,
      dataVariationPoPAdjusted: dataVariationPoPAdjustedAux,
    };
  }, [
    dataLevel,
    dataVariationPoP,
    dataVariationYoY,
    frequency,
    isLatestDataActive,
    workspace.frequency,
  ]);

  const selectX = useCallback(
    (x: string) => {
      if (
        isYFrequencyValid &&
        (dataLevelAdjusted?.historical.date.some((date) => date === x) ||
          dataLevelAdjusted?.forecast.date.some((date) => date === x))
      ) {
        setXSelected({ x, date: adjustDateToXSelected(x) });
      }
    },
    [adjustDateToXSelected, dataLevelAdjusted, isYFrequencyValid],
  );

  useEffect(() => {
    if (frequency !== frequencyAux) {
      setFrequencyAux(frequency);
    }
  }, [frequency, frequencyAux]);

  useEffect(() => {
    function setDefaultXAndYSelected() {
      if (dataLevelAdjusted) {
        const lastIndex = dataLevelAdjusted.historical.date.length - 1;

        const x = dataLevelAdjusted.historical.date[lastIndex] as string;

        setXSelected({
          x,
          date: adjustDateToXSelected(x),
        });
      }
    }

    if (isYFrequencyValid && xSelected?.x === undefined) {
      setDefaultXAndYSelected();
    }

    if (frequency !== frequencyAux) {
      setDefaultXAndYSelected();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    adjustDateToXSelected,
    dataLevelAdjusted,
    isYFrequencyValid,
    frequency,
    frequencyAux,
  ]);

  const canSelectForecast = useMemo(() => true, []);

  useEffect(() => {
    const dataLevelQtty =
      (dataLevel?.historical.dates.length ?? 0) +
      (dataLevel?.forecast?.dates.length ?? 0);

    const dataVariationYoYQtty =
      (dataVariationYoY?.historical.dates.length ?? 0) +
      (dataVariationYoY?.forecast?.dates.length ?? 0);

    const dataVariationPoPQtty =
      (dataVariationPoP?.historical.dates.length ?? 0) +
      (dataVariationPoP?.forecast?.dates.length ?? 0);

    const transfEnabled: WorkspaceProjectionsTransformation[] = [];

    if (dataLevelQtty) {
      transfEnabled.push('level');
    }

    if (dataVariationYoYQtty) {
      transfEnabled.push('variationYoY');
    }

    if (dataVariationPoPQtty) {
      transfEnabled.push('variationPoP');
    }

    dispatch(changeWorkspaceProjectionsTransformationsEnabled(transfEnabled));
  }, [dataLevel, dataVariationYoY, dataVariationPoP, dispatch]);

  useEffect(() => {
    dispatch(
      changeWorkspaceProjectionsLatestDataEnabled(
        !isLatestDataDisabled && !error && !isLoadingChart,
      ),
    );
  }, [isLatestDataDisabled, error, isLoadingChart, dispatch]);

  useEffect(() => {
    if ((isLoadingLevel || isLoadingListOfVariables) && !loadingLevelData) {
      dispatch(changeWorkspaceProjectionsLoadingLevelData(true));
    } else if (
      !isLoadingLevel &&
      !isLoadingListOfVariables &&
      loadingLevelData
    ) {
      dispatch(changeWorkspaceProjectionsLoadingLevelData(false));
    }
  }, [isLoadingLevel, loadingLevelData, isLoadingListOfVariables, dispatch]);

  useEffect(() => {
    if (
      (isLoadingVariationYoY ||
        isLoadingVariationPoP ||
        isLoadingListOfVariables) &&
      !loadingVariationData
    ) {
      dispatch(changeWorkspaceProjectionsLoadingVariationData(true));
    } else if (
      !isLoadingVariationYoY &&
      !isLoadingVariationPoP &&
      !isLoadingListOfVariables &&
      loadingVariationData
    ) {
      dispatch(changeWorkspaceProjectionsLoadingVariationData(false));
    }
  }, [
    isLoadingVariationYoY,
    isLoadingVariationPoP,
    loadingVariationData,
    isLoadingListOfVariables,
    dispatch,
  ]);

  const selectedFilterInflation =
    user.language === 'pt-br'
      ? `${translate('value')} ${inflation}`
      : `${transformUppercaseFirstLetter(inflation)} ${translate(
          'value',
        ).toLowerCase()}`;

  const selectedFilters: SelectedFilterOptions[] = [
    { type: 'frequency', selected: translate(frequency) },
  ];

  const hasForecastLabel =
    workspace.frequency === 'monthly' &&
    workspace.releaseSelected?.id !== workspace.releasePreview;

  hasForecastLabel &&
    selectedFilters.push({
      type: 'other',
      id: 'forecast',
      icon: <PencilSimple />,
      selected: translate(
        forecastType === 'adjusted'
          ? 'workspaceProjectionsMostRecent'
          : forecastType,
      ),
    });

  const hasInflationLabel = workspace.releaseSelected?.data.ys.some(
    (y) => y.is_inflated,
  );

  hasInflationLabel &&
    selectedFilters.push({
      type: 'inflation',
      selected: selectedFilterInflation,
    });

  if (isLatestDataActive) {
    selectedFilters.push({
      type: 'latest-data',
      selected: translate('latestData'),
    });
  }

  return (
    <AISelectionResultsContext.Provider
      value={{
        isYFrequencyValid,
        xSelected,
        isLoadingChart,
        dataLevel: dataLevelAdjusted,
        dataVariationYoY: dataVariationYoYAdjusted,
        dataVariationPoP: dataVariationPoPAdjusted,
        isLatestDataDisabled,
        canSelectForecast,
        selectX,
      }}
    >
      <ResultContainer className="containerLinear">
        <Card
          textCard={translate('workspaceProjectionsResultTitle')}
          textDescription={translate('workspaceProjectionsResultDescription')}
        />

        <SelectedFilters
          filters={
            forecastType === 'adjusted' && workspace.frequency !== 'monthly'
              ? selectedFilters.filter((filter) => filter.id !== 'forecast')
              : selectedFilters
          }
          data-testid="selected-filters-results"
        />

        {error ? (
          <ContainerMaintenance
            content="chart"
            text={translateSomeMessagesFromBackend(
              returnMessageError() ?? '',
              user.language,
            )}
            size="sm"
          />
        ) : (
          <>
            {frequency === chartFrequency &&
            isLatestDataActive === chartIsLatestDataActive ? (
              <ResultsChart chartFrequency={chartFrequency} />
            ) : (
              <ContainerSkeleton data-testid="result-loading" />
            )}

            <ResultsUnderstanding />
          </>
        )}
      </ResultContainer>
    </AISelectionResultsContext.Provider>
  );
};
