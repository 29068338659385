export const projectOverviewProjectionsPTTranslate = {
  ProjectOverviewProjectionsTitle: 'Projeções',
  ProjectOverviewProjectionsDescription:
    'Acesse as projeções das suas variáveis ao longo do tempo.',
  ProjectOverviewProjectionsFrequency: 'Frequência',
  ProjectOverviewProjectionsOriginal: 'Original',
  ProjectOverviewProjectionsAnnual: 'Anual',
  ProjectOverviewProjectionsTransformation: 'Transformação',
  ProjectOverviewProjectionsLevel: 'Nível',
  ProjectOverviewProjectionsVariation: 'Variação (%)',
  ProjectOverviewProjectionsHistorical: 'Histórico',
  ProjectOverviewProjectionsForecast: 'Projeção',
  ProjectOverviewProjectionsVariables: 'variáveis',
  ProjectOverviewProjectionsOnlyForMonthlyFrequency:
    'Apenas para frequência mensal',
  ProjectOverviewProjectionsTable: 'tabela',
};
