import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 39.5rem;

  position: relative;

  h3 {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
    color: ${({ theme }) => theme.colors.gray6};
    margin-bottom: 0.5rem;
  }

  > p {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray5};
  }
`;

export const UpdateShareList = styled.div`
  width: 36.5rem;
  height: 22.688rem;
  margin-top: 1.5rem;
  overflow-y: auto;

  @media (max-height: 700px) {
    height: 16.313rem;
  }

  table {
    width: 100%;
    color: ${({ theme }) => theme.colors.gray5};
    border-spacing: 0;

    thead {
      position: sticky;
      top: 0px;

      th {
        border-bottom: 1.5px solid ${({ theme }) => theme.colors.gray2};
        background: ${({ theme }) => theme.colors.white};
        padding: 0px 1.5rem 1rem 0px;
        align-items: center;
        gap: 1rem;
        align-self: stretch;

        min-width: 15.5rem;
        padding: 0px 1.5rem 1rem 0px;

        text-align: left;
      }
    }

    tbody {
      td {
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.375rem;

        height: 4.063rem;

        > div {
          max-width: 7.813rem;
          display: flex;
        }
      }
    }
  }

  strong {
    font-size: 1rem;
  }
`;

interface StatusBoxProps {
  status: 'success' | 'error' | 'processing' | string;
}

export const StatusBox = styled.div<StatusBoxProps>`
  display: flex;
  padding: 0.125rem 0.75rem;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  max-width: 7.813rem;

  background: ${({ status }) =>
    status === 'success'
      ? 'rgba(72, 187, 120, 0.12)'
      : status === 'error'
      ? 'rgba(245, 101, 101, 0.12)'
      : status === 'processing'
      ? 'rgba(76, 148, 255, 0.12)'
      : 'rgba(237, 242, 247, 0.50)'};

  p {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375rem;

    color: ${({ status, theme }) =>
      status === 'success'
        ? theme.colors.green4
        : status === 'error'
        ? theme.colors.red4
        : status === 'processing'
        ? theme.colors.primary
        : theme.colors.gray4};
  }
`;

export const CloseModalButton = styled.button`
  position: absolute;
  right: 0rem;
  top: 0rem;

  width: 1.875rem;
  height: 1.875rem;

  svg {
    color: ${({ theme }) => theme.colors.gray4};
    transition: 0.2s;

    :hover {
      color: ${({ theme }) => theme.colors.gray5};
    }
  }
`;
