import React, { useCallback, useEffect, useState } from 'react';

import { CaretRight, MagnifyingGlass, Pencil, Trash } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { CheckBox } from 'src/components/CheckBox';
import { Input } from 'src/components/Input';
import { Tag } from 'src/components/Tag';
import { escapeRegExp } from 'src/utils/escapeRegExp';
import { Button } from 'src/components/Button';
import { RadioButton } from 'src/components/RadioButton';
import {
  FilterOption as FilterOptionProps,
  changeFilterName,
  changeFilterOptions,
} from 'src/models/redux/reducers/DependentVariablesConfig';
import Maintenance from 'src/assets/maintenance.svg';

import {
  Container,
  VariableContainer,
  FilterActions,
  FilterOption,
  VariableAndOptionContainer,
  VariableContent,
  FilterContainer,
  ButtonsContent,
  FilterOptionsContainer,
  FilterOptionsContent,
  OptionContainer,
  VariableQtty,
  FilterOptionInfo,
  OptionVariables,
  SelectAllContainer,
  CreateFilterOptionForm,
  FormError,
  NoOptionContainer,
} from './styles';
import {
  ConfigFiltersProps,
  ErrorProps,
  FilterOptionsProps,
  OptionsNameRepeatedProps,
} from './types';
import { DeleteFilterModal } from '../Modal/DeleteFilter';

export const ConfigFilters: React.FC<ConfigFiltersProps> = ({
  yIdLabel,
  yIdStatus,
  filterLevel,
}) => {
  const [variablesToSelect, setVariablesToSelect] = useState<string[]>([]);

  const [selectAllVariables, setSelectAllVariables] = useState(false);
  const [selectedVariables, setSelectedVariables] = useState<string[]>([]);

  const [variablesSearched, setVariablesSearched] = useState<string[]>([]);
  const [searchInfo, setSearchInfo] = useState({
    timeoutId: null as NodeJS.Timeout | null,
    error: {} as ErrorProps,
  });

  const [filterNameError, setFilterNameError] = useState<ErrorProps>();

  const [showCreateOptionPlaceholder, setShowCreateOptionPlaceholder] =
    useState(true);
  const [selectedOption, setSelectedOption] = useState('');

  const [lastProjectVariableSearch, setLastProjectVariableSearch] =
    useState('');

  const [optionIds, setOptionIds] = useState(0);

  const [showTooltipButton, setShowTooltipButton] = useState(false);

  const [showFilterDeleteModal, setShowFilterDeleteModal] = useState(false);

  const [optionsNameRepeated, setOptionsNameRepeated] = useState<
    OptionsNameRepeatedProps[]
  >([]);

  const {
    dependentVariablesConfig: { filters },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const { t: translate } = useTranslation();
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const filter = filters[`level${filterLevel}`];
  const filterOptions = filter.options;

  useEffect(() => {
    if (filterNameError?.message === 'nameAlreadyExists') {
      const name1 = filters.level1.name;
      const name2 = filters.level2.name;
      const name3 = filters.level3.name;

      if (
        name1 !== name2 &&
        name1 !== name3 &&
        (name2 !== name3 || name3 === undefined)
      ) {
        setFilterNameError({
          message: '',
          quantityLetters: 0,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.level1.name, filters.level2.name, filters.level3.name]);

  useEffect(() => {
    if (yIdLabel) {
      let ids = Object.keys(yIdLabel);

      for (const option of filter.options) {
        ids = ids.filter((id) => !option.yIds.includes(id));
      }

      const sortedIdByLabels = sortVariables(ids);

      setVariablesToSelect(sortedIdByLabels);
      performSearch(lastProjectVariableSearch, ids);
      setSelectAllVariables(false);
      setSelectedVariables([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yIdLabel]);

  useEffect(() => {
    if (filter.name === undefined) {
      dispatch(
        changeFilterName({
          filterLevel: `level${filterLevel}`,
          value: `${translate('configFiltersFilter')} ${filterLevel}`,
          changed: true,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filterLevel, translate]);

  const sortVariables = (ids: string[]) =>
    ids.sort((a, b) =>
      yIdLabel[a] > yIdLabel[b] ? 1 : yIdLabel[a] < yIdLabel[b] ? -1 : 0,
    );

  const handleEnableFocusInput = (id: string) => {
    const input = document.getElementById(id);
    if (input) {
      input.focus();
    }
  };

  const handleRenameFilter = (name: string) => {
    dispatch(
      changeFilterName({
        filterLevel: `level${filterLevel}`,
        value: name,
        changed: true,
      }),
    );

    if (!name) {
      setFilterNameError({
        message: 'requiredField',
        quantityLetters: 0,
      });

      return;
    }

    if (name.length > 50) {
      setFilterNameError({
        message: 'searchMaxCharactersError',
        quantityLetters: 50,
      });

      return;
    }

    let filtersName = [];

    if (filterLevel === 1) {
      filtersName = [filters.level2.name, filters.level3.name];
    } else if (filterLevel === 2) {
      filtersName = [filters.level1.name, filters.level3.name];
    } else {
      filtersName = [filters.level1.name, filters.level2.name];
    }

    if (filtersName.includes(name)) {
      setFilterNameError({
        message: 'nameAlreadyExists',
        quantityLetters: 0,
      });

      return;
    }

    setFilterNameError({
      message: '',
      quantityLetters: 0,
    });
  };

  const checkIfOptionsNameAreStillRepeated = (
    updatedOption: FilterOptionProps[],
  ) => {
    let updatedOptionsNameRepeated = optionsNameRepeated;

    for (const option of optionsNameRepeated) {
      let repeated = false;
      let optionIndex = -1;

      updatedOption.forEach((op, i) => {
        if (op.id === option.id) {
          optionIndex = i;
        }

        if (op.name === option.name && op.id !== option.id) {
          repeated = true;
        }
      });

      if (!repeated) {
        updatedOption[optionIndex] = {
          ...updatedOption[optionIndex],
          error: {
            message: '',
            quantityLetters: 0,
          },
        };
        updatedOptionsNameRepeated = updatedOptionsNameRepeated.filter(
          (op) => op.id !== option.id,
        );
      }
    }
    setOptionsNameRepeated(updatedOptionsNameRepeated);

    return updatedOption;
  };

  const handleRenameOption = (id: string, name: string) => {
    let updatedOption = [...filterOptions];

    const index = updatedOption.findIndex((option) => option.id === id);

    if (index !== -1) {
      const error: ErrorProps = { message: '', quantityLetters: 0 };

      if (!name) {
        error.message = 'requiredField';
        error.quantityLetters = 0;
      } else if (name.length > 50) {
        error.message = 'searchMaxCharactersError';
        error.quantityLetters = 50;
      } else if (!validatedOptionName(name, id)) {
        setOptionsNameRepeated([...optionsNameRepeated, { id, name }]);

        error.message = 'nameAlreadyExists';
        error.quantityLetters = 0;
      }

      updatedOption[index] = {
        ...updatedOption[index],
        name,
        error,
      };

      if (optionsNameRepeated.length >= 1) {
        updatedOption = checkIfOptionsNameAreStillRepeated(updatedOption);
      }

      dispatch(
        changeFilterOptions({
          filterLevel: `level${filterLevel}`,
          value: updatedOption,
          changed: true,
        }),
      );
    }
  };

  const sortedOptions = (options: FilterOptionsProps) => {
    let updatedFilterOptions = [...options];

    updatedFilterOptions = updatedFilterOptions.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase()
        ? 1
        : a.name.toLowerCase() < b.name.toLowerCase()
        ? -1
        : 0,
    );

    return updatedFilterOptions;
  };

  const handleCreateOptionFilter = (data: FieldValues) => {
    const name = data.filterOptionName;

    if (name) {
      let updatedFilterOptions = [...filterOptions];
      updatedFilterOptions.push({
        id: String(optionIds),
        name,
        yIds: [],
        error: { message: '', quantityLetters: 0 },
      });

      updatedFilterOptions = sortedOptions(updatedFilterOptions);

      setOptionIds(optionIds + 1);
      setValue('filterOptionName', '');
      setShowCreateOptionPlaceholder(true);

      dispatch(
        changeFilterOptions({
          filterLevel: `level${filterLevel}`,
          value: updatedFilterOptions,
          changed: true,
        }),
      );
    }
  };

  const validatedOptionName = (name: string, id?: string) => {
    const index = filterOptions.findIndex(
      (option) => option.name.toLowerCase() === name.toLowerCase(),
    );

    if (id && index !== -1) {
      return filterOptions[index].id === id;
    }

    return index === -1;
  };

  const handleSelectAllVariables = useCallback(() => {
    const selected = !selectAllVariables;
    let allVariablesSelected = new Set(selectedVariables);

    if (selected) {
      variablesSearched.forEach((id) => {
        allVariablesSelected.add(id);
      });
    } else if (variablesSearched.length === variablesToSelect?.length) {
      allVariablesSelected = new Set([]);
    } else {
      for (const id of variablesSearched) {
        allVariablesSelected.delete(id);
      }
    }

    setSelectedVariables(Array.from(allVariablesSelected));
    setSelectAllVariables(selected);
  }, [
    variablesSearched,
    selectAllVariables,
    selectedVariables,
    variablesToSelect,
  ]);

  const handleCheckVariable = useCallback(
    (id: string) => {
      const variables = [...selectedVariables];

      if (variables.includes(id)) {
        const index = variables.findIndex((varId) => varId === id);

        variables.splice(index, 1);
      } else {
        variables.push(id);
      }

      if (variables.length === variablesSearched.length) {
        setSelectAllVariables(true);
      } else {
        setSelectAllVariables(false);
      }

      setSelectedVariables(variables);
    },
    [selectedVariables, variablesSearched],
  );

  const handleSearchVariable = (value: string) => {
    if (value.length > 50) {
      setSearchInfo({
        ...searchInfo,
        error: {
          message: 'searchMaxCharactersError',
          quantityLetters: 50,
        },
      });

      return;
    }

    if (searchInfo.timeoutId) {
      clearTimeout(searchInfo.timeoutId);
    }

    setSearchInfo({
      ...searchInfo,
      error: {
        message: '',
        quantityLetters: 0,
      },
      timeoutId: setTimeout(() => {
        performSearch(value);
      }, 250),
    });
  };

  const performSearch = (value: string, ids?: string[]) => {
    const regex = new RegExp(escapeRegExp(value), 'i');

    const allVariables = ids ?? variablesToSelect;

    const updatedIds =
      (value.length
        ? allVariables?.filter((id) => regex.test(yIdLabel[id]))
        : allVariables) ?? [];

    setLastProjectVariableSearch(value);
    checkAllVariablesAreSelected(updatedIds);
    setVariablesSearched(updatedIds);
  };

  const checkAllVariablesAreSelected = (ids: string[]) => {
    let allVariablesAreSelected = true;

    for (const id of ids) {
      if (!selectedVariables.includes(id)) {
        allVariablesAreSelected = false;
        break;
      }
    }

    if (!allVariablesAreSelected || !ids.length) {
      setSelectAllVariables(false);
    } else setSelectAllVariables(true);
  };

  const removeVariablesToSelect = () => {
    let projectVariables = [...variablesToSelect];

    projectVariables = projectVariables.filter(
      (id) => !selectedVariables.includes(id),
    );

    setVariablesToSelect(projectVariables);

    const updateVariableSearched = variablesSearched.filter((id) =>
      projectVariables.includes(id),
    );
    setVariablesSearched(updateVariableSearched);
  };

  const handleSendVariablesToFilterOption = () => {
    setShowTooltipButton(false);

    const optionIndex = filterOptions.findIndex(
      ({ name }) => name === selectedOption,
    );

    const updatedFilterOption = [...filterOptions];

    if (optionIndex !== -1) {
      const yIds = [...updatedFilterOption[optionIndex].yIds];

      selectedVariables.forEach((y) => {
        yIds.push(y);
      });

      updatedFilterOption[optionIndex] = {
        ...updatedFilterOption[optionIndex],
        yIds: sortVariables(yIds),
      };

      dispatch(
        changeFilterOptions({
          filterLevel: `level${filterLevel}`,
          value: updatedFilterOption,
          changed: true,
        }),
      );

      removeVariablesToSelect();

      setSelectedVariables([]);
      setSelectAllVariables(false);
    }
  };

  const handleRemoveFilterVariable = (id: string) => {
    const updatedVariablesId = sortVariables([...variablesToSelect, id]);

    setVariablesToSelect(updatedVariablesId);

    const optionIndex = filterOptions.findIndex(
      ({ name }) => name === selectedOption,
    );

    const updatedFilterOption = [...filterOptions];

    const yIds = updatedFilterOption[optionIndex].yIds.filter(
      (option) => option !== id,
    );

    updatedFilterOption[optionIndex] = {
      ...updatedFilterOption[optionIndex],
      yIds,
    };

    dispatch(
      changeFilterOptions({
        filterLevel: `level${filterLevel}`,
        value: updatedFilterOption,
        changed: true,
      }),
    );

    performSearch(lastProjectVariableSearch, updatedVariablesId);
  };

  const handleDeleteOption = (id: string) => {
    const index = filter.options.findIndex((option) => option.id === id);

    const updatedOptions = [...filter.options];

    const ids = sortVariables([
      ...variablesToSelect,
      ...updatedOptions[index].yIds,
    ]);

    setVariablesToSelect(ids);
    performSearch(lastProjectVariableSearch, ids);

    updatedOptions.splice(index, 1);

    dispatch(
      changeFilterOptions({
        filterLevel: `level${filterLevel}`,
        value: updatedOptions,
        changed: true,
      }),
    );
  };

  useEffect(() => {
    if (!showTooltipButton) setShowTooltipButton(true);
  }, [showTooltipButton]);

  return (
    <Container data-testid={`config-filter-${filterLevel}`}>
      <Tooltip id="config-filters-tooltip" className="customTooltipTheme" />

      <FilterContainer>
        <FilterActions>
          <Input
            id={`filter-${filterLevel}-name`}
            defaultValue={`${translate('configFiltersFilter')} ${filterLevel}`}
            placeholder={translate('configFiltersFilterName')}
            value={filter.name}
            onChange={(input) => handleRenameFilter(input.target.value)}
            error={
              filterNameError?.message &&
              translate(filterNameError.message).replace(
                'XX',
                String(filterNameError.quantityLetters),
              )
            }
            tooltipError={
              filterNameError?.message &&
              translate(filterNameError.message).replace(
                'XX',
                String(filterNameError.quantityLetters),
              )
            }
            data-testid={`filter-${filterLevel}-name`}
          />

          <ButtonsContent>
            <Button
              buttonType="naked"
              icon={<Pencil />}
              onClick={() =>
                handleEnableFocusInput(`filter-${filterLevel}-name`)
              }
              data-testid={`filter-${filterLevel}-rename`}
            />

            <Button
              buttonType="naked"
              icon={<Trash />}
              onClick={() => setShowFilterDeleteModal(true)}
              data-testid={`filter-${filterLevel}-delete`}
            />
          </ButtonsContent>
        </FilterActions>

        <FilterOptionsContainer>
          <p className="label">{translate('configFiltersOptions')}</p>

          <FilterOptionsContent>
            {filterOptions.map((option, index) => (
              <FilterOption
                key={`filter-${filterLevel}-${option.id}`}
                data-testid={`filter-${filterLevel}-option-${index}`}
              >
                <FilterOptionInfo>
                  <div>
                    <VariableQtty
                      data-testid={`filter-${filterLevel}-filter-option-${index}-variables-qtty`}
                    >
                      {option.yIds.length}
                    </VariableQtty>
                    <Input
                      id={`filter-${filterLevel}-input-option-${index}`}
                      placeholder="Option name"
                      value={option.name}
                      onChange={(input) =>
                        handleRenameOption(option.id, input.target.value)
                      }
                      error={
                        option.error?.message &&
                        translate(option.error.message).replace(
                          'XX',
                          String(option.error.quantityLetters),
                        )
                      }
                      tooltipError={
                        option.error?.message &&
                        translate(option.error.message).replace(
                          'XX',
                          String(option.error.quantityLetters),
                        )
                      }
                      onBlur={() => {
                        dispatch(
                          changeFilterOptions({
                            filterLevel: `level${filterLevel}`,
                            value: sortedOptions(filterOptions),
                            changed: true,
                          }),
                        );
                      }}
                      data-testid={`filter-${filterLevel}-input-option-${index}`}
                    />
                  </div>

                  <ButtonsContent>
                    <Button
                      buttonType="naked"
                      icon={<Pencil />}
                      onClick={() =>
                        handleEnableFocusInput(
                          `filter-${filterLevel}-input-option-${index}`,
                        )
                      }
                      data-testid={`filter-${filterLevel}-rename-option-${index}`}
                    />

                    <Button
                      buttonType="naked"
                      icon={<Trash />}
                      onClick={() => handleDeleteOption(option.id)}
                      data-testid={`filter-${filterLevel}-delete-option-${index}`}
                    />
                  </ButtonsContent>
                </FilterOptionInfo>
              </FilterOption>
            ))}
          </FilterOptionsContent>

          <CreateFilterOptionForm
            onSubmit={handleSubmit(handleCreateOptionFilter)}
          >
            <Controller
              name="filterOptionName"
              key="filterOptionName"
              control={control}
              rules={{
                required: true,
                maxLength: 50,
                validate: (value) => validatedOptionName(value),
              }}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <Input
                  onChange={onChange}
                  value={value}
                  placeholder={
                    showCreateOptionPlaceholder
                      ? translate('configFiltersAdd')
                      : ''
                  }
                  onFocus={() => setShowCreateOptionPlaceholder(false)}
                  onBlur={() => {
                    if (value === '') {
                      setShowCreateOptionPlaceholder(true);
                    }
                  }}
                  data-testid={`filter-${filterLevel}-create-option-input`}
                />
              )}
            />

            {!!watch('filterOptionName') && (
              <Button
                type="submit"
                buttonType="naked"
                data-testid={`filter-${filterLevel}-create-option-button`}
              >
                {translate('configFiltersAdd')}
              </Button>
            )}
          </CreateFilterOptionForm>

          {['validate', 'maxLength'].includes(
            (errors?.filterOptionName?.type as any) ?? '',
          ) && (
            <FormError data-testid={`filter-${filterLevel}-option-name-error`}>
              {errors.filterOptionName?.type === 'maxLength'
                ? translate('searchMaxCharactersError').replace('XX', '50')
                : translate('nameAlreadyExists')}
            </FormError>
          )}
        </FilterOptionsContainer>
      </FilterContainer>

      <VariableAndOptionContainer>
        <h2>{translate('configFiltersGroupVariablesTitle')}</h2>
        <p>
          {translate('configFiltersFilter')}: {filter.name}
        </p>

        <div>
          <VariableContainer>
            <Input
              placeholder={translate('configFiltersSearchVariablePlaceholder')}
              icon={<MagnifyingGlass className="search-icon" />}
              onChange={(input) => handleSearchVariable(input.target.value)}
              error={
                searchInfo.error?.message &&
                translate(searchInfo.error.message).replace(
                  'XX',
                  String(searchInfo.error.quantityLetters),
                )
              }
              data-testid={`filter-${filterLevel}-search-variable`}
            />

            <SelectAllContainer>
              <CheckBox
                label={translate('configFiltersSelectAllVariables')}
                onChange={() => handleSelectAllVariables()}
                checked={selectAllVariables}
                data-testid={`filter-${filterLevel}-select-all-variables`}
              />
            </SelectAllContainer>
            <VariableContent>
              {variablesSearched.map((id) => (
                <Tag
                  key={`filter-${filterLevel}-${id}`}
                  label={yIdLabel[id]}
                  hasCheckbox
                  checked={selectedVariables.includes(id)}
                  onClickCheckbox={() => handleCheckVariable(id)}
                  warning={yIdStatus[id] !== 'success'}
                  warningTooltip={
                    yIdStatus[id] !== 'success'
                      ? translate('configFiltersVariableError')
                      : undefined
                  }
                  data-testid={`filter-${filterLevel}-tag-${yIdLabel[id]
                    .replaceAll(' ', '-')
                    .toLowerCase()}`}
                />
              ))}
            </VariableContent>
          </VariableContainer>

          <Button
            buttonType="naked"
            icon={<CaretRight />}
            onClick={() => handleSendVariablesToFilterOption()}
            disabled={!selectedVariables.length || !selectedOption}
            data-tooltip-id="config-filters-tooltip"
            data-tooltip-html={
              showTooltipButton && !selectedVariables.length
                ? translate('configFiltersSelectAtLeastOneVariable')
                : showTooltipButton && !selectedOption
                ? translate('configFiltersSelectAtLeastOneOption')
                : ''
            }
            data-testid={`filter-${filterLevel}-send-variables-to-filter-option`}
            className="button-caret-down"
          />

          <OptionContainer>
            {filterOptions.length ? (
              filterOptions.map((option, index) => (
                <FilterOption
                  key={`filter-${filterLevel}-${option.id}`}
                  data-testid={`filter-${filterLevel}-variables-option-${index}`}
                >
                  <FilterOptionInfo
                    className={!option.name ? 'empty-option-name' : ''}
                  >
                    <RadioButton
                      label={
                        option.name || translate('configFiltersOptionName')
                      }
                      checked={selectedOption === option.name}
                      onClick={() =>
                        setSelectedOption(
                          selectedOption === option.name ? '' : option.name,
                        )
                      }
                      data-testid={`filter-${filterLevel}-radio-variables-option-${index}`}
                    />

                    <VariableQtty
                      data-testid={`filter-${filterLevel}-option-${index}-variables-qtty`}
                    >
                      {option.yIds.length}
                    </VariableQtty>
                  </FilterOptionInfo>

                  {selectedOption === option.name && (
                    <OptionVariables>
                      {option.yIds.map((id) => (
                        <Tag
                          key={`filter-${filterLevel}-${id}`}
                          label={yIdLabel[id]}
                          hasRemoveButton
                          onClickRemoveButton={() =>
                            handleRemoveFilterVariable(id)
                          }
                          warning={yIdStatus[id] !== 'success'}
                          warningTooltip={
                            yIdStatus[id] !== 'success'
                              ? translate('configFiltersVariableError')
                              : undefined
                          }
                          data-testid={`filter-${filterLevel}-tag-${yIdLabel[id]
                            .replaceAll(' ', '-')
                            .toLowerCase()}-option-${index}`}
                        />
                      ))}
                    </OptionVariables>
                  )}
                </FilterOption>
              ))
            ) : (
              <NoOptionContainer>
                <img src={Maintenance} alt="No option created" />
                <p>{translate('configFiltersNoOptionCreated')}</p>
              </NoOptionContainer>
            )}
          </OptionContainer>
        </div>
      </VariableAndOptionContainer>

      <DeleteFilterModal
        visible={showFilterDeleteModal}
        setVisible={setShowFilterDeleteModal}
        filterLevel={`level${filterLevel}`}
      />
    </Container>
  );
};
