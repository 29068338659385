export const MySeriesENUSTranslate = {
  mySeriesTitle: 'My Series',
  mySeriesDescription: 'List of all the external series you have uploaded',
  mySeriesYouHaveNotUploaded: "You haven't uploaded any series yet",
  mySeriesClickTheButtonAbove:
    'Click the button above to upload your externally modeled series.',
  mySeriesInvalidFormatFile: 'Invalid file format',
  mySeriesSearchPlaceholder: 'Search serie',
  mySeriesSearchFail: `We couldn't find any serie containing`,
  mySeriesFetchError: 'Unable to load series',
  mySeriesButtonUpload: 'Upload new series',
  mySeriesUploadYourSeries: 'Upload your series',
  mySeriesUploading: 'uploading',
  mySeriesCreateSerieErrorTitle: 'Failed to create',
  mySeriesCreateSerieErrorDescription:
    'An error occurred while creating the series {{serie}}. Please try again later.',
  mySeriesDeleteSerieErrorTitle: 'Failed to delete',
  mySeriesDeleteSerieErrorDescription:
    'An error occurred while deleting the series {{serie}}. Please try again later.',
  mySeriesUploadFileUploadYourFile: 'Upload your file',
  mySeriesUploadFileDragItHere: 'Drag it here or click to select it manually.',
  mySeriesUploadFileViewRequirements: 'View requirements',
  mySeriesUploadFileEnterTheTag: 'Enter the tag for this series',
  mySeriesOverwriteTitle_one: 'Series already available',
  mySeriesOverwriteTitle_other: 'Series already available',
  mySeriesOverwriteDescription_one:
    'The series {{series}} is already available.<br/>Do you want to overwrite the existing series?',
  mySeriesOverwriteDescription_other:
    'The series {{series}} are already available.<br/>Do you want to overwrite the existing series?',
  mySeriesUploadErrorTitle: 'Unable to upload',
  mySeriesUploadErrorLeast3Characters:
    'Series names should contain at least 3 characters',
  mySeriesUploadErrorMaximum50Characters:
    'Series names should contain a maximum of 50 characters.',
  mySeriesUploadErrorDefault:
    'An error occurred during the upload. Please try again later.',
  mySeriesNoEspecialCharacters: 'No special characters allowed',

  mySeriesRequirementsTitle: 'Series file requirements',
  mySeriesRequirements1: 'The first row must contain the column names',
  mySeriesRequirements2:
    'There must be at least two columns: one date column and one numeric column with the variable and its observations',
  mySeriesRequirements3:
    'The variable name must be up to 50 characters long and should not use special characters, numbers at the beginning, or spaces',
  mySeriesRequirements4:
    'The variable must contain only one observation per date (for example, the dataset should not have two rows with observations for January 2020)',
  mySeriesRequirements5:
    'There must be no missing data in the middle of the time series (neither date nor observation)',
  mySeriesRequirements6: 'The series frequency should be monthly',
  mySeriesMultipleDeletions: 'Delete multiple',
  mySeriesDeleting: 'Deleting',

  mySeriesConfirmDeletionTitle: 'Are you sure you want to delete the series?',
  mySeriesConfirmDeletionDescription:
    'It will be removed from all your views, including Workspaces.',

  mySeriesConfirmMultipleDeletionsTitle:
    'Are you sure you want to delete the series?',
  mySeriesConfirmMultipleDeletionsDescription:
    'They will be removed from all your views, including Workspaces.',
};
