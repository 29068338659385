import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30rem;
  min-height: 14rem;
  padding: 2rem 2rem 3rem;

  box-shadow: 0px 16px 32px rgba(78, 78, 78, 0.08);
  border-radius: 8px;

  h2 {
    margin-top: 1.063rem;
    margin-bottom: 0.75rem;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: center;
    color: ${({ theme }) => theme.colors.gray6};
  }

  p {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray5};
  }
`;

export const ButtonDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  background: ${({ theme }) => theme.colors.gray0};
  border-top: 1px solid ${({ theme }) => theme.colors.gray2};
  padding: 1.5rem;
  gap: 0.5rem;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;
