import React from 'react';

import { ButtonStyled, Spinner } from './styles';
import { ButtonProps } from './types';

export const Button: React.FC<ButtonProps> = ({
  buttonType = 'primary',
  icon,
  children,
  disabled = false,
  loading = false,
  iconInvert = false,
  ...props
}) => (
  <ButtonStyled
    type="button"
    buttonType={buttonType}
    disabled={disabled}
    iconInvert={iconInvert}
    {...props}
  >
    {iconInvert === true ? (
      <div>
        {children}
        {loading && (
          <Spinner
            hasIcon={!!icon}
            spinnerType={buttonType}
            data-testid="loading-button"
          />
        )}
        {icon}
      </div>
    ) : (
      <div>
        {icon}
        {children}
        {loading && (
          <Spinner
            hasIcon={!!icon}
            spinnerType={buttonType}
            data-testid="loading-button"
          />
        )}
      </div>
    )}
  </ButtonStyled>
);
