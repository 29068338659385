import styled, { css } from 'styled-components';

interface UnderstandingResultCardsProps {
  type: 'primary' | 'secondary';
}

export const UnderstandingResultCards = styled.div<UnderstandingResultCardsProps>`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-bottom: ${({ type }) => (type === 'primary' ? '1.5rem' : 0)};
`;

type CardInformationColor = 'blue' | 'orange';

interface CardInformationProps {
  type: 'primary' | 'secondary';
  yValueColor?: CardInformationColor;
  percentualValueColor: CardInformationColor;
  isFirstPrimary?: boolean;
}

export const CardInformation = styled.div<CardInformationProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;

  padding: ${({ type }) => (type === 'primary' ? '1.75rem' : '1rem')};

  border: 1px solid
    ${({ theme, isFirstPrimary, percentualValueColor }) =>
      isFirstPrimary
        ? percentualValueColor === 'blue'
          ? theme.colors.primary
          : theme.colors.red4
        : theme.colors.gray2};
  border-radius: 8px;

  h4 {
    white-space: nowrap;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: ${({ theme }) => theme.colors.gray6};
  }

  h3 {
    white-space: nowrap;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.688rem;

    ${({ yValueColor }) =>
      yValueColor &&
      css`
        color: ${({ theme }) =>
          yValueColor === 'blue' ? theme.colors.primary : theme.colors.red4};
      `}

    opacity: 0.8;

    span {
      color: ${({ theme, percentualValueColor }) =>
        percentualValueColor === 'blue'
          ? theme.colors.primary
          : theme.colors.red4};
    }
  }
`;
