export const workspaceProjectionsPTBRTranslate = {
  workspaceProjectionsMessageErrorServerCanNotFind:
    'O servidor não pode encontrar o recurso solicitado.',
  workspaceProjectionsMessageErrorServerRequestedResource:
    'O recurso solicitado não existe.',
  workspaceProjectionsMessageErrorEngineNotSupported:
    'Mecanismo de projeto não suportado.',
  workspaceProjectionsMessageErrorAnInternalError:
    'Ocorreu um erro interno ao processar a solicitação.',
  workspaceProjectionsLoadingTitle: 'Estamos trabalhando nisso!',
  workspaceProjectionsLoadingDescription:
    'Isso pode levar um tempo... Mas não se preocupe, o seu modelo de Projeções está sendo processado e em breve estará pronto!',
  workspaceProjectionsQuarterlySeriesSummaryIsOnlyAvailable:
    'O resumo de série trimestral está disponível apenas para séries com pelo menos 1 trimestre de observação.',
  workspaceProjectionsNOfDays: 'Nº dias',
  workspaceProjectionsNotEnoughObservationsVariation:
    'Não há observações suficientes para calcular a variação.',
  workspaceProjectionsRadioInflationValueTooltip:
    'A visualização “Nominal” consiste nos valores apresentados aplicando-se o efeito da inflação na série. O mês de refêrencia (i.e. quando real e nominal são iguais) é janeiro de 2018 e o índice aplicado é o IPCA geral. A visualização “Real” desconsidera os efeitos da inflação sobre as variáveis.',
  workspaceProjectionsMostRecent: 'Mais recente',
};
