export const TutorialConfigENUSTranslate = {
  tutorialOf: 'of',
  tutorialStart: 'Start',
  tutorialEnd: 'Finish',
  tutorialTitleStep1: 'Setting up your filters',
  tutorialDescriptionStep1:
    'Discover how to configure hierarchical and reactive filters that provide multi-level organization and adjusting automatically to your choices.',
  tutorialTitleStep2: 'Important informations',
  tutorialFirstInfoStep2: 'You can create a maximum of 3 filters.',
  tutorialSecondInfoStep2:
    'If a variable is not added to any filter option, it will be automatically included in the "Not defined" option.',
  tutorialThirdInfoStep2:
    'It is not possible to add a variable to more than one filter option simultaneously. For example, an iPhone 13 cannot be in both the "Android" and "iOS" options in the "Operating System" filter at the same time. You need to choose only one of the options to categorize the variable.',
  tutorialTitleStep3: 'Filter creation',
  tutorialDescriptionStep3:
    'To get started, create your first filter by defining a name and its options.',
  tutorialTitleStep4: 'Variable selection',
  tutorialDescriptionStep4:
    'Choose which variables you want to assign to a specific filter option.',
  tutorialTitleStep5: 'Option selection and sending variables',
  tutorialDescriptionStep5:
    'Choose the option you want to associate the variables with and click the button to send them.',
  tutorialTitleStep6: 'Filter preview',
  tutorialDescriptionStep6:
    'Monitor real-time progress as your filter takes shape.',
};
