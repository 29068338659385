import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Container, Content, DivTabs, Slider } from './styles';

type TabsProps = {
  data: Array<DataObject>;
  shouldTranslate?: boolean;
  getSelectedTab?: (value: string) => void;
} & React.HTMLAttributes<HTMLDivElement>;

type DataObject = {
  name: string;
  disabled?: boolean;
  render: () => JSX.Element;
  icon?: JSX.Element;
  onlyIcon?: boolean;
  dataTestid?: string;
};

export const Tabs: React.FC<TabsProps> = ({
  data,
  getSelectedTab,
  shouldTranslate = false,
  ...props
}) => {
  const [tabName, setTabName] = useState(data[0]?.name);
  const handleClick = (typeData: string) => {
    setTabName(typeData);
  };

  const { t: translate } = useTranslation();

  useEffect(() => {
    getSelectedTab && getSelectedTab(tabName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabName]);

  return (
    <Container {...props}>
      <DivTabs>
        {data.map((key) => (
          <div key={key.name}>
            <Slider selected={key.name === tabName} disabled={key.disabled}>
              <button
                disabled={key.disabled}
                type="button"
                id={key.name}
                onClick={() => handleClick(key.name)}
                data-cy={`button-tab-${key.name
                  .replaceAll(' ', '-')
                  .toLocaleLowerCase()}`}
                data-testid={
                  key.dataTestid
                    ? key.dataTestid
                    : `button-tab-${key.name
                        .replaceAll(' ', '-')
                        .toLocaleLowerCase()}`
                }
              >
                {key?.onlyIcon
                  ? key?.icon
                  : shouldTranslate
                  ? translate(key.name)
                  : key.name}
              </button>
            </Slider>
          </div>
        ))}
      </DivTabs>
      <Content>{data?.find((aux) => aux.name === tabName)?.render()}</Content>
    </Container>
  );
};

// -Função para selecionar uma determinada aba através de eventos
export function selectTab(tabName: string): void {
  function eventFire(element: any, eventType: string) {
    if (element.fireEvent) {
      element.fireEvent(`on${eventType}`);
    } else {
      const tabEvent = new Event(eventType, {
        bubbles: true,
        cancelable: false,
      });
      element.dispatchEvent(tabEvent);
    }
  }

  const tabElement = document.getElementById(tabName);
  if (tabElement) {
    eventFire(tabElement, 'click');
  }
}
