const UserConfigsENUSTranslations = {
  userConfigsWidgetTitle: 'User settings',
  userConfigsWidgedUserControls: 'User control',
  userConfigsWidgedUserControlsDescription: 'Access user control module',
  userConfigsWidgedToken: 'Copy API token',
  userConfigsWidgedTokenDescription:
    'Click and copy the token to access the API',
  userConfigsWidgedTokenInfo:
    'Your token was <strong>copied</strong> to clipboard and <strong>expires in one day</strong>. If you prefer to download a .txt file, ',
  userConfigsWidgedTokenInfoClick: 'click here',
};

export default UserConfigsENUSTranslations;
