const adminPTBRTranslate = {
  adminDescription: 'Veja todos os indicadores adicionados à plataforma.',
  adminDescriptionList: 'Lista de todos os indicadores da Feature Store.',
  adminSearchIndicator: 'Procurar Indicador',
  adminCreateNewIndicator: 'Criar novo indicador',
  adminFailedSearchIndicator: 'Falha ao buscar por XX',
  adminCode: 'Código',
  adminName: 'Nome',
  adminShortName: 'Nome curto',
  adminShortDescription: 'Descrição resumida',
  adminFullDescription: 'Descrição completa',
  adminCountry: 'País',
  adminSource: 'Fonte',
  adminSector: 'Setor',
  adminRegion: 'Regiões',
  adminAccessType: 'Tipo de acesso',
  adminAGPER: 'AG/PER',
  adminTP: 'TP',
  adminTS: 'TS',
  adminActive: 'Ativo?',
  adminActions: 'Actions',
  adminIndicators: 'Indicadores',
  adminProjection: 'Projeção',
};

export default adminPTBRTranslate;
