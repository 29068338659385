import React, { useEffect } from 'react';

import { Warning } from 'phosphor-react';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';
import light from 'src/styles/themes/light';
import { translateSomeMessagesFromBackend } from 'src/i18n';
import { RootState } from 'src/redux/store';
import { useSelector } from 'react-redux';

import { ContainerModal, ContentButtons } from './styles';

interface WarningMessages {
  text: string;
  variables: string[];
}

type PropsModal = {
  visible?: boolean;
  setVisible: (value: boolean) => void;
  title?: string;
  warningMessages: WarningMessages[];
  timeOut?: number;
};

export const ModalWarningVariables: React.FC<PropsModal> = ({
  visible,
  setVisible,
  title,
  warningMessages,
  timeOut,
}) => {
  const { language } = useSelector((state: RootState) => state.auth.user);
  useEffect(() => {
    if (timeOut) {
      setTimeout(() => setVisible(false), timeOut * 1000);
    }
  }, [setVisible, timeOut]);

  return (
    <Modal visible={visible} setVisible={setVisible} style={{ padding: 0 }}>
      <ContainerModal>
        <Warning color={light.colors.yellow4} size="3rem" />
        {title && <h1>{title}</h1>}
        {warningMessages.map((message, indexAux) => (
          <>
            <p>
              <strong>
                {translateSomeMessagesFromBackend(message.text, language)}
              </strong>
            </p>
            <p>
              {message.variables.length > 15
                ? message.variables
                    .filter((_, index) => index < 15)
                    .map((variable, index) =>
                      index + 1 < 15
                        ? `${variable}, `
                        : `${variable} and more ${
                            message.variables.length - 15
                          } ${
                            message.variables.length - 15 > 1
                              ? `variables.`
                              : `variable.`
                          }`,
                    )
                : message.variables.map((variable, index) =>
                    index + 1 < message.variables.length
                      ? `${variable}, `
                      : `${variable}.`,
                  )}
            </p>
            {indexAux !== warningMessages.length - 1 && <br />}
          </>
        ))}

        <ContentButtons>
          <Button
            buttonType="primary"
            data-testid="modalWarningOKButton"
            onClick={() => setVisible(false)}
            data-cy="button-modal-failed-ok"
          >
            Ok
          </Button>
        </ContentButtons>
      </ContainerModal>
    </Modal>
  );
};
