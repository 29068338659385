export const ListCustomersENTranslate = {
  listCustomersTitle: 'Customers list',
  listCustomersDescription: 'All customers',
  listCustomersButtonRegisterCustomer: 'Register new customer',
  listCustomersName: 'Name',
  listCustomersManagerUserEmail: 'Manager user email',
  listCustomersTotalLicenses: 'Total Licenses',
  listCustomersRemainingLicenses: 'Remaining licenses',
  listCustomersNamePagination: 'customers',
  listCustomersErrorLoad: 'Unable to load customers.',
};
