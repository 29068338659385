import styled from 'styled-components';

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;
  margin-bottom: 1.5rem;
  > p {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray5};
  }
  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
  }
`;
