import styled, { css } from 'styled-components';

export const Container = styled.div`
  .customTooltipTheme {
    max-width: 37.5rem !important;
  }
`;

export const Content = styled.div`
  position: relative;
`;

export const ContentButtonCreateGroup = styled.div`
  display: flex;
  gap: 1.5rem;
  position: absolute;
  top: 40px;
  right: 1.5rem;
  > div:first-child {
    svg {
      color: ${({ theme }) => theme.colors.gray4};
      margin-left: 0;
    }
    width: 20rem;
  }

  @media (max-width: 900px) {
    > div:first-child {
      width: 12.5rem;
    }
  }

  @media (max-width: 780px) {
    position: unset;
    justify-content: flex-end;
    > div:first-child {
      width: 100%;
    }
    > button:nth-child(2) {
      width: 21rem;
    }
  }
`;

export const ContainerTable = styled.div`
  max-height: 21.875rem;
  position: relative;
  overflow-y: auto;
  ::-webkit-scrollbar-track {
    margin-top: 2rem;
  }

  > table {
    word-break: break-word;
    border-spacing: unset;
    margin: auto;

    > thead {
      > tr {
        position: sticky;
        top: 0px;
        z-index: 99;
        background: ${({ theme }) => theme.colors.white};
        padding: 0.75rem 0 0.75rem 1rem;
        > th {
          position: relative;
          p {
            font-weight: 600;
            font-size: 0.75rem;
            line-height: 1rem;
            opacity: 0.8;
          }
        }
      }
    }
    tbody {
      overflow-y: auto;
      > tr {
        &:first-child {
          td {
            padding-top: 1.5rem;
          }
        }
        > td {
          padding: 0.75rem 0 0.75rem 1rem;
          p {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
`;

interface ButtonIconProps {
  disabled?: boolean;
}

export const ButtonIcon = styled.button<ButtonIconProps>`
  float: right;
  ${({ disabled }) =>
    !disabled
      ? css`
          color: ${({ theme }) => theme.colors.gray5};
          transition: 0.2s color;
          &:hover {
            color: ${({ theme }) => theme.colors.gray6};
          }
        `
      : css`
          color: ${({ theme }) => theme.colors.gray4};
          cursor: not-allowed;
        `};
`;

export const DivButtonActions = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    display: flex;
    flex-direction: row;

    p {
      margin-left: 0.75rem;
    }
  }
`;
