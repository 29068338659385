import styled from 'styled-components';

export const AISelectionContainer = styled.div`
  padding: 1.5rem;

  > div:last-child {
    margin-bottom: 0;
  }
`;

export const ContainerLoadingStatusSuccess = styled.div`
  min-height: calc(100vh - 4.625rem - 3rem);

  padding: 4.5rem !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > div {
    width: 100%;
  }
  h4 {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.688rem;

    color: ${({ theme }) => theme.colors.gray6};
    margin-bottom: 0.5rem;
  }

  p {
    width: 70%;
    text-align: center;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;

    color: ${({ theme }) => theme.colors.gray5};
  }
`;
