const step1ClaaSENUSTranslations = {
  startProjectClaas: 'Start by naming your project and uploading a dataset.',
  claasUploadDatasetInfo: `Only '.xlsx' and '.csv' extension available.`,
  claasUploadDatasetDescription:
    'Drag and drop your file here or click to select manually.',
  claasUploadErrorDescription: 'Something went wrong. Please try again later.',
  claasUploadFileSizeTitle: 'Something went wrong',
  claasUploadFileSizeDescription:
    'Your dataset needs to be "X" or smaller in size to create your project.',
  claasCallAPIButton: 'Create project via API',
  claasAPIModalTitle: 'Create project via API',
  claasAPIModalDescription:
    'Use our API to integrate your system or train models with larger datasets.',
  claasAPIModalSeeDocs: 'See documentation',
};

export default step1ClaaSENUSTranslations;
