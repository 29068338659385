import React, { useEffect } from 'react';

import { Warning } from 'phosphor-react';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';
import light from 'src/styles/themes/light';

import { ContainerModal, ContentButtons } from './styles';

type PropsModal = {
  visible?: boolean;
  setVisible: (value: boolean) => void;
  title?: string;
  text?: string;
  variables?: string[];
  text2?: string;
  variables2?: string[];
  text3?: string;
  variables3?: string[];
  text4?: string;
  variables4?: string[];
  timeOut?: number;
};

export const ModalWarningVariables: React.FC<PropsModal> = ({
  visible,
  setVisible,
  title,
  text,
  variables,
  text2,
  variables2,
  text3,
  variables3,
  text4,
  variables4,
  timeOut,
}) => {
  useEffect(() => {
    if (timeOut) {
      setTimeout(() => setVisible(false), timeOut * 1000);
    }
  }, [setVisible, timeOut]);

  return (
    <Modal visible={visible} setVisible={setVisible} style={{ padding: 0 }}>
      <ContainerModal>
        <Warning color={light.colors.yellow4} size="3rem" />
        {title && <h1>{title}</h1>}
        {text && variables && (
          <>
            <p>
              <strong>{text}</strong>
            </p>
            <p>
              {variables.length > 15
                ? variables
                    .filter((_, index) => index < 15)
                    .map((variable, index) =>
                      index + 1 < 15
                        ? `${variable}, `
                        : `${variable} and more ${variables.length - 15} ${
                            variables.length - 15 > 1
                              ? `variables.`
                              : `variable.`
                          }`,
                    )
                : variables.map((variable, index) =>
                    index + 1 < variables.length
                      ? `${variable}, `
                      : `${variable}.`,
                  )}
            </p>
          </>
        )}
        {text2 && variables2 && (
          <>
            <br />
            <p>
              <strong>{text2}</strong>
            </p>
            <p>
              {variables2.length > 15
                ? variables2
                    .filter((_, index) => index < 15)
                    .map((variable, index) =>
                      index + 1 < 15
                        ? `${variable}, `
                        : `${variable} and more ${variables2.length - 15} ${
                            variables2.length - 15 > 1
                              ? `variables.`
                              : `variable.`
                          }`,
                    )
                : variables2.map((variable, index) =>
                    index + 1 < variables2.length
                      ? `${variable}, `
                      : `${variable}.`,
                  )}
            </p>
          </>
        )}
        {text3 && variables3 && (
          <>
            <br />
            <p>
              <strong>{text3}</strong>
            </p>
            <p>
              {variables3.length > 15
                ? variables3
                    .filter((_, index) => index < 15)
                    .map((variable, index) =>
                      index + 1 < 15
                        ? `${variable}, `
                        : `${variable} and more ${variables3.length - 15} ${
                            variables3.length - 15 > 1
                              ? `variables.`
                              : `variable.`
                          }`,
                    )
                : variables3.map((variable, index) =>
                    index + 1 < variables3.length
                      ? `${variable}, `
                      : `${variable}.`,
                  )}
            </p>
          </>
        )}
        {text4 && variables4 && (
          <>
            <br />
            <p>
              <strong>{text4}</strong>
            </p>
            <p>
              {variables4.length > 15
                ? variables4
                    .filter((_, index) => index < 15)
                    .map((variable, index) =>
                      index + 1 < 15
                        ? `${variable}, `
                        : `${variable} and more ${variables4.length - 15} ${
                            variables4.length - 15 > 1
                              ? `variables.`
                              : `variable.`
                          }`,
                    )
                : variables4.map((variable, index) =>
                    index + 1 < variables4.length
                      ? `${variable}, `
                      : `${variable}.`,
                  )}
            </p>
          </>
        )}
        <ContentButtons>
          <Button
            buttonType="primary"
            data-testid="modalWarningOKButton"
            onClick={() => setVisible(false)}
            data-cy="button-modal-failed-ok"
          >
            Ok
          </Button>
        </ContentButtons>
      </ContainerModal>
    </Modal>
  );
};
