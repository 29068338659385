import styled from 'styled-components';

export const ContainerModal = styled.div`
  width: 39.063rem;
  padding: 2.125rem 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 0.5rem;

  > svg {
    color: ${({ theme }) => theme.colors.red3};
  }

  > h1 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    margin-top: 1.875rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.gray6};
  }

  > p {
    color: ${({ theme }) => theme.colors.gray5};
    font-weight: 500;
  }
`;

export const ContentButtons = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.gray2};
  background: ${({ theme }) => theme.colors.gray0};
  width: 39.063rem;
  /* height: 88px; */
  padding: 1.5rem 1.5rem;
  border-radius: 0 0 8px 8px;
  margin-top: 3rem;

  display: flex;
  justify-content: flex-end;

  button {
    display: flex;
    justify-content: center;
  }
`;
