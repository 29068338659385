export const workspaceProjectionsENUSTranslate = {
  workspaceProjectionsMessageErrorServerCanNotFind:
    'The server can not find the requested resource.',
  workspaceProjectionsMessageErrorServerRequestedResource:
    'The requested resource does not exist.',
  workspaceProjectionsMessageErrorEngineNotSupported:
    'Project engine not supported.',
  workspaceProjectionsMessageErrorAnInternalError:
    'An internal error has occurred while processing the request.',
  workspaceProjectionsLoadingTitle: 'We are working on it!',
  workspaceProjectionsLoadingDescription:
    'This might take a while... But don’t worry, your Projections model is being processed and will be done soon!',
  workspaceProjectionsQuarterlySeriesSummaryIsOnlyAvailable:
    'Quarterly series summary is only available for series with at least 1 quarter of observation.',
  workspaceProjectionsNOfDays: 'N. of days',
  workspaceProjectionsNotEnoughObservationsVariation:
    'Not enough observations to calculate variation.',
  workspaceProjectionsRadioInflationValueTooltip:
    'The “Nominal” visualization consists of the values presented with the effect of inflation applied to the series. The reference month (i.e. when real and nominal are the same) is January 2018 and the index applied is the general Extended National Consumer Price Index (IPCA). The “Real” visualization disregards the effects of inflation on the variables.',
  workspaceProjectionsMostRecent: 'Most recent',
};
