import styled, { css, keyframes } from 'styled-components';

type WorkspaceSideBarContainerProps = {
  expanded: boolean;
};

export const WorkspaceSideBarContainer = styled.aside<WorkspaceSideBarContainerProps>`
  position: relative;
  width: 100%;
  min-width: ${({ expanded }) => (expanded ? '20rem' : '7rem')};
  max-width: ${({ expanded }) => (expanded ? '20rem' : '7rem')};
  margin-bottom: 0rem;

  transition: min-width 0.75s, max-width 0.75s;

  height: auto;
  background: ${({ theme }) => theme.colors.white};

  border-right: ${({ theme }) => `1px solid ${theme.colors.gray2}`};

  hr {
    border: 1px solid ${({ theme }) => theme.colors.gray1};
    margin-bottom: 2rem;
  }

  ::-webkit-scrollbar {
    width: 0.313rem;
    height: 0.313rem;
    padding: 0.063rem;
  }

  ::-webkit-scrollbar-track {
    transition: 0.2s;
    margin: 0.188rem;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }
`;

type ItemProps = {
  dept: number;
  hasSubMenu: boolean;
  isToggled: boolean;
  isActive: boolean;
  disabled?: boolean;
  loading?: boolean;
  expanded: boolean;
};

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Item = styled.li<ItemProps>`
  height: fit-content;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 0.75rem 1rem;

  border-radius: 8px;

  &:not(:first-child) {
    margin-top: 0.5rem;
  }

  ${({ expanded }) =>
    expanded
      ? css`
          height: 3rem;
          overflow: hidden;
          white-space: nowrap;
        `
      : css`
          p {
            width: 4rem !important;
          }
        `}

  > span {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

    width: 100%;

    display: flex;
    flex-direction: ${({ expanded }) =>
      expanded ? 'row' : 'column'} !important;
    text-align: ${({ expanded }) => (expanded ? 'start' : 'center')};
    align-items: center;

    font-size: ${({ expanded }) => (expanded ? '1rem' : '0.625rem')};
    line-height: ${({ expanded }) => (expanded ? '1.375rem' : '1rem')};
    font-weight: 400;

    transition: all 0.2s ease-in-out;

    svg {
      min-width: 1.5rem;
      width: 1.5rem;
      height: 1.5rem;

      margin-bottom: ${({ expanded }) => (expanded ? '0rem' : '0.5rem')};
      margin-right: ${({ expanded }) =>
        expanded ? '1rem' : '0rem'} !important;
    }
  }

  ${({ dept, disabled }) =>
    dept === 1 &&
    css`
      > span {
        color: ${({ theme }) =>
          disabled ? theme.colors.gray4 : theme.colors.gray5};

        /* &:hover {
          font-size: 16.3px;
        } */
      }
    `}

  ${({ hasSubMenu, isActive, isToggled, theme }) =>
    hasSubMenu &&
    css`
      &:after {
        content: '';
        cursor: pointer;

        width: 0;
        height: 0;

        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid
          ${isActive ? theme.colors.primaryDark : theme.colors.gray5};

        transition: border-top 0.2s;
        transform: ${() => (isToggled ? 'rotate(180deg)' : 'rotate(0deg)')};
      }
    `}

  ${({ loading, hasSubMenu }) =>
    loading &&
    !hasSubMenu &&
    css`
      &:after {
        content: '';
        border: 4px solid ${({ theme }) => theme.colors.gray3};
        border-top: 4px solid ${({ theme }) => theme.colors.primaryDark};
        border-radius: 50%;
        width: 0.5rem;
        height: 0.5rem;
        animation: ${spin} 0.8s linear infinite;
      }
    `}

  ${({ dept, loading, hasSubMenu, disabled }) =>
    dept > 1 &&
    css`
      height: 1.25rem;

      margin-top: 1rem !important;
      margin-left: ${`${20 * dept}px`};

      > span {
        color: ${({ theme }) =>
          (disabled || loading) && !hasSubMenu
            ? theme.colors.gray4
            : theme.colors.gray7};
        font-size: 0.875rem;
        font-weight: 400;
      }
    `}

  ${({ isActive, expanded }) =>
    isActive &&
    css`
      background: ${({ theme }) => theme.colors.gray0};

      > span {
        color: ${({ theme }) =>
          !isActive ? theme.colors.gray5 : theme.colors.gray6};
        font-weight: ${expanded ? 500 : 600};

        svg {
          color: ${({ theme }) =>
            !isActive ? theme.colors.gray5 : theme.colors.primary};
        }
      }
    `}

  &:hover {
    &:after {
      border-top: ${({ theme }) => ` 4px solid${theme.colors.primaryDark}`};
    }

    > span {
      color: ${({ theme, disabled, isActive }) =>
        !disabled && (!isActive ? theme.colors.gray6 : theme.colors.gray6)};
    }
  }
`;

type MenuSectionTitleTextProps = {
  expanded: boolean;
};

export const MenuSectionTitleText = styled.p<MenuSectionTitleTextProps>`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.375rem;

  color: ${({ theme }) => theme.colors.gray4};

  margin: ${({ expanded }) => (expanded ? '2rem 0 1.5rem' : '-1.125rem 0 0 0')};

  max-height: ${({ expanded }) => (expanded ? '1.375rem' : '0rem')} !important;
  overflow: hidden;

  transition: max-height 0.75s;
`;

type ExpandSidebarButtonProps = {
  expanded: boolean;
};

export const ExpandSidebarButton = styled.button<ExpandSidebarButtonProps>`
  width: fit-content;
  height: fit-content;

  position: fixed;
  top: 9.5rem;
  transform: translate(-50%, 0);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0.5rem;

  border-radius: 100%;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  background: ${({ theme }) => theme.colors.white};

  z-index: 100;

  transition: all 0.2s, left 0.75s;

  svg {
    width: 1rem;
    height: 1rem;

    color: ${({ theme }) => theme.colors.gray4};

    transform: ${({ expanded }) =>
      expanded ? 'rotate(0deg)' : 'rotate(180deg)'};

    transition: all 0.2s, transform 0.75s;
  }

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.gray3};

    svg {
      color: ${({ theme }) => theme.colors.gray5};
    }
  }
`;

type SidebarContentProps = {
  expanded: boolean;
};

export const SidebarContent = styled.div<SidebarContentProps>`
  position: sticky;
  height: calc(100vh - 4.625rem); // 74header
  top: 4.625rem; // 74header
  padding: ${({ expanded }) => (expanded ? '1.5rem' : '1.5rem 1rem')};
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 0.313rem;
    height: 0.313rem;
    padding: 0.063rem;
  }

  ::-webkit-scrollbar-track {
    transition: 0.2s;
    margin: 0.188rem;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }
`;
