import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30rem;
  padding: 2rem 2rem !important;

  > svg {
    color: ${({ theme }) => theme.colors.yellow6};
  }

  > h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.gray6};
    line-height: 150%;

    margin-top: 1rem;
    margin-bottom: 0.75rem;
  }

  > p {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.gray5};
    line-height: 150%;
    text-align: center;
  }
`;

export const ContainerError = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  /* width: 350px; */
  padding: 0.125rem 0.125rem 0;

  svg {
    color: ${({ theme }) => theme.colors.red5};
    margin-bottom: 1.5rem;
  }

  > h4 {
    font-size: 1.5rem;
    line-height: 150%;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.gray6};

    margin-bottom: 0.75rem;
    margin-top: -0.5rem;
  }

  > p {
    color: ${({ theme }) => theme.colors.gray5};
    font-weight: 500;
    font-size: 1rem;
    line-height: 150%;
    text-align: center;
    margin-bottom: 3rem;
  }
`;

export const FooterModalDelete = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.gray2};
  background: ${({ theme }) => theme.colors.gray0};
  height: 88px;
  padding: 1.5rem 1.5rem;
  border-radius: 0 0 8px 8px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
