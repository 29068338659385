import styled from 'styled-components';

export const Container = styled.div`
  width: 25rem;
  height: fit-content;

  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 0.75rem;

  padding: 0.75rem;

  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray2};

  z-index: 2;
`;

export const DateTime = styled.p`
  color: ${({ theme }) => theme.colors.gray4};
  font-size: 0.625rem;
  font-weight: 500;
`;

export const Content = styled.div`
  p {
    color: ${({ theme }) => theme.colors.gray6};
    font-size: 0.875rem;
    font-weight: 400;
    white-space: pre-line;
    word-break: break-word;
  }

  button {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.375rem;

    transition: all 0.2s;

    &:hover {
      color: ${({ theme }) => theme.colors.primaryDark};
    }
  }
`;

export const Author = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  div {
    width: 1rem;
    height: 1rem;

    display: flex;
    align-items: center;
    justify-content: center;

    background: ${({ theme }) => theme.colors.orange4};
    border-radius: 100%;

    color: ${({ theme }) => theme.colors.white};
    font-size: 0.44rem;
    font-weight: 700;
  }

  p {
    flex: 1;

    color: ${({ theme }) => theme.colors.gray5};
    font-size: 0.625rem;
    font-weight: 500;

    max-width: 15rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const FooterContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;
