import React from 'react';

import { useTranslation } from 'react-i18next';
import { Modal } from 'src/components/Modal';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import { Button } from 'src/components/Button';
import { Card } from 'src/components/Card';
import { CheckCircle } from 'phosphor-react';

import { FileRequirementsModalProps } from './types';
import { Content, Item, List } from './styles';

export const FileRequirementsModal: React.FC<FileRequirementsModalProps> = ({
  setVisible,
}) => {
  const { t: translate } = useTranslation();

  return (
    <Modal
      visible
      setVisible={setVisible}
      style={{ width: '50rem' }}
      dataCy="modal-see-requirements"
    >
      <Content>
        <Card
          textCard={translate('categorizationRequirementsTitle')}
          textDescription={translate('categorizationRequirementsDescription')}
        />

        <List>
          <Item>
            <CheckCircle weight="fill" />
            <p>{translate('categorizationRequirements1')}</p>
          </Item>
          <Item>
            <CheckCircle weight="fill" />
            <p>{translate('categorizationRequirements2')}</p>
          </Item>
          <Item>
            <CheckCircle weight="fill" />
            <p>{translate('categorizationRequirements3')}</p>
          </Item>
          <Item>
            <CheckCircle weight="fill" />
            <p>{translate('categorizationRequirements4')}</p>
          </Item>
          <Item>
            <CheckCircle weight="fill" />
            <p>{translate('categorizationRequirements5')}</p>
          </Item>
          <Item>
            <CheckCircle weight="fill" />
            <p>{translate('categorizationRequirements6')}</p>
          </Item>
          <Item>
            <CheckCircle weight="fill" />
            <p>{translate('categorizationRequirements7')}</p>
          </Item>
          <Item>
            <CheckCircle weight="fill" />
            <p>{translate('categorizationRequirements8')}</p>
          </Item>
          <Item>
            <CheckCircle weight="fill" />
            <p>{translate('categorizationRequirements9')}</p>
          </Item>
          <Item>
            <CheckCircle weight="fill" />
            <p>{translate('categorizationRequirements10')}</p>
          </Item>
        </List>
      </Content>
      <ModalFooter>
        <Button
          buttonType="primary"
          onClick={() => setVisible(false)}
          data-testid="button-ok-file-requirements"
        >
          Ok
        </Button>
      </ModalFooter>
    </Modal>
  );
};
