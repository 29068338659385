import React, { CSSProperties } from 'react';

import { Info, XCircle } from 'phosphor-react';
import { Tooltip } from 'react-tooltip';

import {
  ContainerLabel,
  ContainerTextArea,
  ContentTextArea,
  TextArea as TextAreaStyle,
} from './styles';

export type TextAreaProps = {
  icon?: React.ReactElement;
  label?: string;
  information?: string;
  style?: CSSProperties;
  error?: string;
  testid?: string;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

export const TextArea: React.FC<TextAreaProps> = ({
  icon,
  label,
  information,
  style,
  testid,
  error,
  placeholder,
  ...rest
}) => {
  if (label) {
    return (
      <div style={style}>
        <ContainerLabel>
          <label>{label}</label>
          {information && (
            <>
              <Info
                size="1.125rem"
                data-tooltip-id="feature-store-text-area"
                data-tooltip-html={information}
                data-cy={`text-area-information-${label?.replaceAll(' ', '-')}`}
                data-testid="textAreaInfo"
              />
              <Tooltip
                id="feature-store-text-area"
                className="customTooltipTheme"
              />
            </>
          )}
        </ContainerLabel>
        <ContainerTextArea error={!!error}>
          <ContentTextArea>
            {icon && icon}
            <TextAreaStyle
              data-testid={testid}
              icon={!!icon}
              placeholder={placeholder}
              data-cy={
                label?.replaceAll(' ', '-') ??
                placeholder?.toString().replaceAll(' ', '-') ??
                undefined
              }
              {...rest}
            />
            {error && (
              <XCircle
                size="1.25rem"
                data-testid="icon_text_area_error_validation"
              />
            )}
          </ContentTextArea>
          <p>{error}</p>
        </ContainerTextArea>
      </div>
    );
  }

  return (
    <ContainerTextArea error={!!error} style={style}>
      <ContentTextArea>
        {icon && icon}
        <TextAreaStyle
          data-testid={testid}
          placeholder={placeholder}
          icon={!!icon}
          data-cy={placeholder?.toString().replaceAll(' ', '-') ?? undefined}
          {...rest}
        />
        {error && (
          <XCircle
            size="1.25rem"
            data-testid="icon_text_area_error_validation"
          />
        )}
      </ContentTextArea>
      <p>{error}</p>
    </ContainerTextArea>
  );
};
