export const ModalRegisterEditCustomerENTranslate = {
  modalRegisterNewCustomerTitle: 'Register new customer',
  modalRegisterNewCustomerTitleEdit: 'Edit customer',
  modalRegisterNewCustomerNameMaxCharacters:
    'Must not be more than 350 characters',
  modalRegisterNewCustomerErrorSpecifyNumber: 'You must specify a number',
  modalRegisterNewCustomerIntegerNumber: 'The number must be integer',
  modalRegisterNewCustomerLicenseMin:
    'Number of licenses must be equal or greater than 1',
  modalRegisterEditCustomerLicenseErrorGreaterThanEqual:
    'Number of licenses must be greater than or equal to the currently used quantity',
  modalRegisterEditCustomerLicenseErrorGreaterThanEqualManager:
    'Number of licenses must be greater than or equal to the currently used value (each manager now consumes 1 license)',
  modalRegisterNewCustomerAdminValidEmail: 'Must be a valid e-mail address',
  modalRegisterNewCustomerName: 'Name',
  modalRegisterNewCustomerNumberOfLicense: 'Number of licenses',
  modalRegisterNewCustomerUserManager: 'Manager user email',
  modalRegisterNewCustomerButtonRegister: 'Register',
  modalRegisterNewCustomerButtonConfirm: 'Confirm',
  modalRegisterNewCustomerButtonCancel: 'Cancel',
  modalRegisterNewCustomerErrorToRegisterClient:
    'There was an error registering a new customer.',
  modalRegisterEditCustomerErrorToEditClient:
    'There was an error editing the customer.',
  modalRegisterNewCustomerErrorToRegisterManagerUser:
    'There was an error registering the manager user.',
  modalRegisterEditCustomerErrorToEditManagerUser:
    'There was an error editing the products access permission for the manager.',
  modalRegisterNewCustomerPlaceholderName: 'Enter the customer name',
  modalRegisterNewCustomerManagerWithAccess: 'Manager with access to products',
  modalRegisterNewCustomerPlaceholderNumberOfLicense:
    'Enter the number of licenses',
  modalRegisterNewCustomerPlaceholderManagerUser:
    'Enter the email of the manager user',
  modalRegisterNewCustomerReadOnly: 'Read only',
  modalRegisterNewCustomerCreateAndRead: 'Create + Read',
  modalRegisterNewCustomerClassification: 'Classification',
  modalRegisterNewCustomerTimeSeries: 'Time Series',
  modalRegisterNewCustomerProducts: 'Products',
  modalRegisterNewCustomerAddManager: '+ Add manager',
  modalRegisterNewCustomerRemoveManager: '- Remove manager',
  modalWarningManagerMessage:
    'Customer created successfully! But it was not possible to add the manager for this customer, configure a new manager later.',
  modaRegisterNewCustomerErrorMessageProduct:
    'You need to select at least one product for this customer.',
};
