import styled from 'styled-components';

export const Container = styled.div`
  width: 70vw;
  height: 70vh;
  max-width: 62.25rem;
  padding: 0.875rem;

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
    color: ${({ theme }) => theme.colors.gray6};
    margin-right: 0.938rem;
  }

  p {
    margin-bottom: 3.125rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray5};
  }

  > div {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5rem;
  }

  img {
    background: rgba(236, 201, 75, 0.12);
    padding: 0.375rem 0.375rem;
    border-radius: 9999px;
    height: 2rem;
    max-width: 2rem;
  }
`;

export const ContentTable = styled.div`
  tbody {
    td:last-child {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
    }
  }

  @media (max-height: 1150px) {
    max-height: 30rem;
    overflow-y: auto;
  }

  @media (max-height: 860px) {
    overflow-y: auto;
    max-height: 18.75rem;
  }
`;

export const ButtonIcon = styled.button`
  align-items: center;
  float: right;
  color: ${({ theme }) => theme.colors.gray5};
  transition: 0.2s color;
  &:hover {
    color: ${({ theme }) => theme.colors.gray6};
  }
`;

export const FooterModal = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.gray2};
  margin: 0.625rem -1.5rem -1.5rem;
  background-color: ${({ theme }) => theme.colors.gray0};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  > div {
    display: flex;
    justify-content: flex-end;
    padding: 1.5rem 2rem;

    button:first-child {
      margin-right: 0.5rem;
    }
  }
`;

export const DivError = styled.div`
  width: 70vw;
  height: 50vh;
  max-width: 62.25rem;
  padding: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
