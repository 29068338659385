import React, { createContext, useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { Card } from 'src/components/Card';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { useQuery } from 'react-query';
import api from 'src/models/service/api';
import { ToggleSwitch } from 'src/components/ToggleSwitch';
import { translateSomeMessagesFromBackend } from 'src/i18n';
import { AxiosError } from 'axios';
import {
  AIUserSelectionFrequency,
  AIUserSelectionTransformation,
  changeAIUserSelectionResultsFrequency,
  changeAIUserSelectionResultsIsLatestDataActive,
  changeAIUserSelectionResultsTransformations,
} from 'src/models/redux/reducers/AIUserSelectionOptions';

import { ChartHeader, ContentLatestData } from './styles';
import { ResultsChart } from './ResultsChart';
import { ResultsOptions } from './ResultsOptions';

type Chart = {
  x: string[];
  y: number[];
  aggregation_count?: number[];
};

export type HistoricalForecast = {
  forecast?: Chart;
  historical: Chart;
};

export type LevelVariation = {
  level: HistoricalForecast;
  variation?: HistoricalForecast;
};

export type QuarterlyVariation = {
  bar: Chart[];
  line: Chart[];
};

export type AnnualVariation = {
  bar: {
    forecast: Chart | undefined;
    historical: Chart;
  };
  line: {
    forecast: Chart | undefined;
    historical: Chart;
  };
};

interface Error {
  detail?: {
    description?: string;
    detail?: string;
  };
}

const frequencyLatestData = {
  daily: 180,
  weekly: 52,
  fortnightly: 24,
  monthly: 36,
  bimonthly: 18,
  quarterly: 12,
  'half-year': 12,
  annual: 6,
};

interface ResultsContextTypes {
  isLoading: boolean;
  dataResults: LevelVariation | undefined;
  frequency: AIUserSelectionFrequency;
  transformations: AIUserSelectionTransformation[];
  isLatestDataActive: boolean;
  isLatestDataDisabled: boolean;
  selectFrequency: (frequency: AIUserSelectionFrequency) => void;
  selectTransformation: (
    Transformations: AIUserSelectionTransformation,
  ) => void;
}

export const ResultsContext = createContext({} as ResultsContextTypes);

export const Results: React.FC = () => {
  const {
    project,
    auth: { user },
    AIUserSelectionOptions: {
      userSelection: {
        results: { frequency, transformations, isLatestDataActive },
      },
    },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();

  const { t: translate } = useTranslation();

  const {
    data: dataResults,
    isLoading,
    isFetching,
    isError: isErrorResults,
    error,
  } = useQuery<LevelVariation, AxiosError<Error>>(
    [
      'model production',
      'series',
      project.id,
      project.selectedY?.id,
      frequency,
    ],
    async () => {
      if (frequency === 'original') {
        const { data } = await api.get<LevelVariation>(
          `/projects/${project.id}/${project.selectedY?.id}/model-in-production/actual_forecast`,
        );

        const level = data.level;
        const variation = data?.variation;

        return {
          level: {
            historical: {
              x: level?.historical?.x ?? [],
              y: level?.historical?.y ?? [],
            },
            forecast: {
              x: level?.forecast?.x ?? [],
              y: level?.forecast?.y ?? [],
            },
          },
          variation: {
            historical: {
              x: variation?.historical?.x ?? [],
              y: variation?.historical?.y ?? [],
            },
            forecast: {
              x: variation?.forecast?.x ?? [],
              y: variation?.forecast?.y ?? [],
            },
          },
        };
      }

      if (frequency === 'monthly') {
        const { data } = await api.get<LevelVariation>(
          `/projects/${project.id}/${project.selectedY?.id}/model-in-production/monthly_series`,
        );

        return {
          level: {
            historical: {
              x: data?.level?.historical?.x ?? [],
              y: data?.level?.historical?.y ?? [],
              aggregation_count:
                data.level?.historical?.aggregation_count ?? [],
            },
            forecast: {
              x: data?.level?.forecast?.x ?? [],
              y: data?.level?.forecast?.y ?? [],
              aggregation_count: data.level?.forecast?.aggregation_count ?? [],
            },
          },
          variation: {
            historical: {
              x: data?.variation?.historical?.x ?? [],
              y: data?.variation?.historical?.y ?? [],
              aggregation_count:
                data.variation?.historical?.aggregation_count ?? [],
            },
            forecast: {
              x: data?.variation?.forecast?.x ?? [],
              y: data?.variation?.forecast?.y ?? [],
              aggregation_count:
                data.variation?.forecast?.aggregation_count ?? [],
            },
          },
        };

        return data;
      }

      if (frequency === 'quarterly') {
        const { data } = await api.get<QuarterlyVariation>(
          `/projects/${project.id}/${project.selectedY?.id}/model-in-production/quartely_series`,
        );

        return {
          level: {
            historical: {
              x: data?.bar[0]?.x ?? [],
              y: data?.bar[0]?.y ?? [],
              aggregation_count: data?.bar[0]?.aggregation_count ?? [],
            },
            forecast: {
              x: data.bar.length > 1 ? data?.bar[1]?.x ?? [] : [],
              y: data.bar.length > 1 ? data?.bar[1]?.y ?? [] : [],
              aggregation_count:
                data.bar.length > 1
                  ? data?.bar[1]?.aggregation_count ?? []
                  : [],
            },
          },
          variation: {
            historical: {
              x: data?.line[0]?.x ?? [],
              y: data?.line[0]?.y ?? [],
              aggregation_count: data?.line[0]?.aggregation_count ?? [],
            },
            forecast: {
              x: data.line.length > 1 ? data?.line[1]?.x ?? [] : [],
              y: data.line.length > 1 ? data?.line[1]?.y ?? [] : [],
              aggregation_count:
                data.line.length > 1
                  ? data?.line[1]?.aggregation_count ?? []
                  : [],
            },
          },
        };
      }

      // frequency annual
      const { data } = await api.get<AnnualVariation>(
        `/projects/${project.id}/${project.selectedY?.id}/model-in-production/annual_variation`,
      );

      return {
        level: {
          historical: {
            x: data?.bar?.historical?.x ?? [],
            y: data?.bar?.historical?.y ?? [],
            aggregation_count: data?.bar?.historical?.aggregation_count ?? [],
          },
          forecast: {
            x: data?.bar?.forecast?.x ?? [],
            y: data?.bar?.forecast?.y ?? [],
            aggregation_count: data?.bar?.forecast?.aggregation_count ?? [],
          },
        },
        variation: {
          historical: {
            x: data?.line?.historical?.x ?? [],
            y: data?.line?.historical?.y ?? [],
            aggregation_count: data?.line?.historical?.aggregation_count ?? [],
          },
          forecast: {
            x: data?.line?.forecast?.x ?? [],
            y: data?.line?.forecast?.y ?? [],
            aggregation_count: data?.line?.forecast?.aggregation_count ?? [],
          },
        },
      };
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled: !!project.id && !!project.selectedY?.id,
    },
  );

  const isLoadingChart = useMemo(
    () => isLoading || isFetching || !dataResults,
    [isLoading, isFetching, dataResults],
  );

  useEffect(() => {
    function checkIfTransformationIsAvailable() {
      const availableTransformation: AIUserSelectionTransformation[] = [
        ...transformations,
      ];

      if (!isLoadingChart) {
        if (!dataResults?.level && availableTransformation.includes('Level')) {
          const index = availableTransformation.findIndex(
            (transformation) => transformation === 'Level',
          );
          availableTransformation.splice(index, 1);
        }
        if (
          !dataResults?.variation &&
          availableTransformation.includes('Variation')
        ) {
          const index = availableTransformation.findIndex(
            (transformation) => transformation === 'Variation',
          );
          availableTransformation.splice(index, 1);
        }

        if (availableTransformation.length !== transformations.length) {
          dispatch(
            changeAIUserSelectionResultsTransformations({
              selection: 'userSelection',
              value: availableTransformation,
            }),
          );
        }
      }
    }

    checkIfTransformationIsAvailable();
  }, [
    dataResults?.level,
    dataResults?.variation,
    dispatch,
    isLoadingChart,
    transformations,
  ]);

  function selectFrequency(frequencyAux: AIUserSelectionFrequency) {
    if (frequency !== frequencyAux) {
      dispatch(
        changeAIUserSelectionResultsFrequency({
          selection: 'userSelection',
          value: frequencyAux,
        }),
      );
    }
  }

  function selectTransformation(
    transformationAux: AIUserSelectionTransformation,
  ) {
    if (transformations.includes(transformationAux)) {
      dispatch(
        changeAIUserSelectionResultsTransformations({
          selection: 'userSelection',
          value: transformations.filter(
            (transformation) => transformation !== transformationAux,
          ),
        }),
      );

      return;
    }

    dispatch(
      changeAIUserSelectionResultsTransformations({
        selection: 'userSelection',
        value: [...transformations, transformationAux],
      }),
    );
  }

  function returnMessageError() {
    let messageError = error?.response?.data?.detail?.detail;

    if (
      messageError ===
        'Annual series summary is only available for series with at least 1 year of observation.' ||
      messageError ===
        'Quarterly series summary is only available for series with at least 1 quarter of observation.' ||
      messageError ===
        'Monthly aggregation is not available for dataset with missing values.'
    ) {
      return messageError;
    }

    messageError = error?.response?.data?.detail?.description;

    if (
      messageError === 'No data is available.' ||
      messageError === 'The requested resource does not exist.'
    ) {
      if (frequency === 'quarterly') {
        return 'Quarterly Series Rate is not available for this model';
      }
      if (frequency === 'yearly') {
        return 'Annual Series Rate is not available for this model';
      }
    }

    return messageError;
  }

  const isLatestDataDisabled = useMemo(() => {
    const total =
      (dataResults?.level.historical.y.length ?? 0) +
      (dataResults?.level.forecast?.y.length ?? 0);

    if (frequency === 'original') {
      return (
        total <=
        frequencyLatestData[
          project.selectedY?.info?.frequency as keyof typeof frequencyLatestData
        ]
      );
    }
    if (frequency === 'quarterly') {
      return total <= frequencyLatestData.quarterly;
    }

    if (frequency === 'monthly') {
      return total <= frequencyLatestData.monthly;
    }

    if (frequency === 'yearly') {
      return total <= frequencyLatestData.annual;
    }

    return true;
  }, [dataResults, frequency, project.selectedY?.info?.frequency]);

  const isError = useMemo(
    () => project.projectError || isErrorResults,
    [isErrorResults, project.projectError],
  );

  useEffect(() => {
    if ((!isLoadingChart && isLatestDataDisabled) || error) {
      dispatch(
        changeAIUserSelectionResultsIsLatestDataActive({
          selection: 'userSelection',
          value: false,
        }),
      );
    }
  }, [dispatch, error, isLatestDataDisabled, isLoadingChart]);

  function handleActiveLatestData(value: boolean) {
    dispatch(
      changeAIUserSelectionResultsIsLatestDataActive({
        selection: 'userSelection',
        value,
      }),
    );
  }

  function getLatestData(
    data: HistoricalForecast | undefined,
    quantity: number,
    isVariation = false,
    initialDate?: string | number,
  ) {
    if (data) {
      const total = data.historical.y.length + (data.forecast?.y.length ?? 0);

      if (quantity > total) {
        return data;
      }

      const forecastDate =
        data.forecast?.x.slice(0, quantity / 3 + (isVariation ? 1 : 0)) ?? [];

      const forecastValue =
        data.forecast?.y.slice(0, quantity / 3 + (isVariation ? 1 : 0)) ?? [];

      const forecastAggregation =
        data.forecast?.aggregation_count?.slice(
          0,
          quantity / 3 + (isVariation ? 1 : 0),
        ) ?? [];

      const historicalLength = data.historical.x.length;

      let initialIndex = 0;

      if (initialDate) {
        initialIndex = data.historical.x.findIndex(
          (date) => date === initialDate,
        );
      } else {
        initialIndex =
          historicalLength -
          quantity +
          (forecastDate.length + (isVariation ? -1 : 0));
      }

      const historicalDate = data.historical.x.slice(
        initialIndex,
        historicalLength,
      );

      const historicalValue = data.historical.y.slice(
        initialIndex,
        historicalLength,
      );

      const historicalAggregation =
        data.historical?.aggregation_count?.slice(
          initialIndex,
          historicalLength,
        ) ?? [];

      return {
        historical: {
          x: historicalDate,
          y: historicalValue,
          aggregation_count: historicalAggregation,
        },
        forecast: {
          x: forecastDate,
          y: forecastValue,
          aggregation_count: forecastAggregation,
        },
      };
    }

    return {
      historical: {
        x: [],
        y: [],
        aggregation_count: [],
      },
      forecast: {
        x: [],
        y: [],
        aggregation_count: [],
      },
    };
  }

  let dataLevelAdjusted: HistoricalForecast = {
    historical: {
      x: [],
      y: [],
    },
    forecast: {
      x: [],
      y: [],
    },
  };

  let dataVariationAdjusted: HistoricalForecast = {
    historical: {
      x: [],
      y: [],
    },
    forecast: {
      x: [],
      y: [],
    },
  };

  if (!isLatestDataActive) {
    if (dataResults?.level) {
      dataLevelAdjusted = dataResults.level;
    }
    if (dataResults?.variation) {
      dataVariationAdjusted = dataResults.variation;
    }
  } else if (frequency === 'original') {
    switch (project.selectedY?.info?.frequency) {
      case 'daily':
        dataLevelAdjusted = getLatestData(
          dataResults?.level,
          frequencyLatestData.daily,
        );
        dataVariationAdjusted = getLatestData(
          dataResults?.variation,
          frequencyLatestData.daily,
          true,
          dataLevelAdjusted.historical.x[0],
        );
        break;
      case 'weekly':
        dataLevelAdjusted = getLatestData(
          dataResults?.level,
          frequencyLatestData.weekly,
        );
        dataVariationAdjusted = getLatestData(
          dataResults?.variation,
          frequencyLatestData.weekly,
          true,
          dataLevelAdjusted.historical.x[0],
        );
        break;
      case 'fortnightly':
        dataLevelAdjusted = getLatestData(
          dataResults?.level,
          frequencyLatestData.fortnightly,
        );
        dataVariationAdjusted = getLatestData(
          dataResults?.variation,
          frequencyLatestData.fortnightly,
          true,
          dataLevelAdjusted.historical.x[0],
        );
        break;
      case 'monthly':
        dataLevelAdjusted = getLatestData(
          dataResults?.level,
          frequencyLatestData.monthly,
        );
        dataVariationAdjusted = getLatestData(
          dataResults?.variation,
          frequencyLatestData.monthly,
          true,
          dataLevelAdjusted.historical.x[0],
        );
        break;
      case 'bimonthly':
        dataLevelAdjusted = getLatestData(
          dataResults?.level,
          frequencyLatestData.bimonthly,
        );
        dataVariationAdjusted = getLatestData(
          dataResults?.variation,
          frequencyLatestData.bimonthly,
          true,
          dataLevelAdjusted.historical.x[0],
        );
        break;
      case 'quarterly':
        dataLevelAdjusted = getLatestData(
          dataResults?.level,
          frequencyLatestData.quarterly,
        );
        dataVariationAdjusted = getLatestData(
          dataResults?.variation,
          frequencyLatestData.quarterly,
          true,
          dataLevelAdjusted.historical.x[0],
        );
        break;
      case 'half-year':
        dataLevelAdjusted = getLatestData(
          dataResults?.level,
          frequencyLatestData['half-year'],
        );
        dataVariationAdjusted = getLatestData(
          dataResults?.variation,
          frequencyLatestData['half-year'],
          true,
          dataLevelAdjusted.historical.x[0],
        );
        break;
      case 'annual':
        dataLevelAdjusted = getLatestData(
          dataResults?.level,
          frequencyLatestData.annual,
        );
        dataVariationAdjusted = getLatestData(
          dataResults?.variation,
          frequencyLatestData.annual,
          true,
          dataLevelAdjusted.historical.x[0],
        );
        break;
      default:
        if (dataResults?.level) {
          dataLevelAdjusted = dataResults.level;
        }
        if (dataResults?.variation) {
          dataVariationAdjusted = dataResults.variation;
        }
        break;
    }
  } else if (frequency === 'monthly') {
    dataLevelAdjusted = getLatestData(
      dataResults?.level,
      frequencyLatestData.monthly,
    );
    dataVariationAdjusted = getLatestData(
      dataResults?.variation,
      frequencyLatestData.monthly,
      true,
      dataLevelAdjusted.historical.x[0],
    );
  } else if (frequency === 'quarterly') {
    dataLevelAdjusted = getLatestData(
      dataResults?.level,
      frequencyLatestData.quarterly,
    );
    dataVariationAdjusted = getLatestData(
      dataResults?.variation,
      frequencyLatestData.quarterly,
      true,
      dataLevelAdjusted.historical.x[0],
    );
  } else {
    dataLevelAdjusted = getLatestData(
      dataResults?.level,
      frequencyLatestData.annual,
    );
    dataVariationAdjusted = getLatestData(
      dataResults?.variation,
      frequencyLatestData.annual,
      true,
      dataLevelAdjusted.historical.x[0],
    );
  }

  return (
    <ResultsContext.Provider
      value={{
        dataResults: {
          level: dataLevelAdjusted,
          variation: dataVariationAdjusted,
        },
        isLoading,
        frequency,
        transformations,
        isLatestDataActive,
        isLatestDataDisabled,
        selectFrequency,
        selectTransformation,
      }}
    >
      <div className="containerLinear">
        <Card textCard={translate('userSelectionResultTitle')} />

        <ChartHeader>
          <ResultsOptions />
          <ContentLatestData>
            <ToggleSwitch
              label={translate('userSelectionResultLatestData')}
              checked={isLatestDataActive}
              onChange={(e) => handleActiveLatestData(e.target.checked)}
              disabled={isLatestDataDisabled || !!isError || !!isLoadingChart}
              data-cy="results-latest-data"
            />
          </ContentLatestData>
        </ChartHeader>

        {isError ? (
          <ContainerMaintenance
            content="chart"
            size="sm"
            data-testid="results-container-error"
            text={translateSomeMessagesFromBackend(
              returnMessageError() ?? '',
              user.language,
            )}
          />
        ) : isLoadingChart ? (
          // eslint-disable-next-line react/jsx-indent
          <ContainerSkeleton data-testid="result-loading" />
        ) : (
          <ResultsChart />
        )}
      </div>
    </ResultsContext.Provider>
  );
};
