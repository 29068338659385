import styled from 'styled-components';

export const ContainerSeeRequirements = styled.div`
  width: 50rem;
  padding: 1.5rem;
`;

export const ContentHeaderAndBody = styled.div`
  overflow-y: auto;

  @media (max-height: 760px) {
    height: 70vh;
  }
`;

export const ContentHeader = styled.div`
  h4 {
    font-size: 1.5rem;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.gray6};

    margin-bottom: 0.5rem;
  }
  p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;

    color: ${({ theme }) => theme.colors.gray5};
  }
`;

export const ContentBody = styled.div`
  margin-top: 1.5rem;
  > div {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    > div {
      margin-right: 0.5rem;
      width: 1rem;
      height: 1rem;

      svg {
        color: ${({ theme }) => theme.colors.gray3};
      }
    }

    p {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.gray6};
    }

    b {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.primary};
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  table {
    width: 100%;
    margin-bottom: 1rem;

    text-align: center;
    border-collapse: separate;
    border-spacing: 0;

    th {
      width: 5.875rem;
      height: 3.375rem;

      font-weight: 600;
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors.gray6};

      border-top: 1px solid ${({ theme }) => theme.colors.gray2};
      border-right: 1px solid ${({ theme }) => theme.colors.gray2};
      border-bottom: 1px solid ${({ theme }) => theme.colors.gray2};

      &:first-child {
        border-left: 1px solid ${({ theme }) => theme.colors.gray2};
        border-top-left-radius: 5px;
      }
      &:last-child {
        border-top-right-radius: 5px;
      }
    }
    td {
      width: 5.875rem;
      height: 4rem;

      font-weight: 400;
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors.gray6};

      border-right: 1px solid ${({ theme }) => theme.colors.gray2};
      border-bottom: 1px solid ${({ theme }) => theme.colors.gray2};

      &:first-child {
        border-left: 1px solid ${({ theme }) => theme.colors.gray2};
        border-bottom-left-radius: 5px;
      }
      &:last-child {
        border-bottom-right-radius: 5px;
      }
    }
  }
`;

export const ContentFooter = styled.div`
  margin: 1.5rem -1.5rem -1.5rem;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.colors.gray2};
  padding: 1.5rem;
  background: ${({ theme }) => theme.colors.gray0};

  border-radius: 0 0 8px 8px;

  a {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    font-weight: 600;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.gray6};
    cursor: pointer;
    transition: 0.2 color ease-in-out;

    &:hover {
      color: ${({ theme }) => theme.colors.gray7};
    }
  }
`;
