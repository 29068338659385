import React, { useState } from 'react';

import { Modal } from 'src/components/Modal';
import { Table, Tbody, Td, Th, Thead, Tr } from 'src/components/Table';
import { MagnifyingGlass } from 'phosphor-react';
import { Pagination } from 'src/components/Pagination';
import { Button } from 'src/components/Button';
import api from 'src/feature-store/service/api';
import { useQuery } from 'react-query';
import { RootState } from 'src/redux/store';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CrownSimple from 'src/assets/crownSimple.svg';
import { useNavigate } from 'react-router-dom';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';

import {
  ButtonIcon,
  Container,
  ContentTable,
  DivError,
  FooterModal,
} from './styles';

interface DataIndicatorsFromGroupProps {
  indicator_code: string;
  name: {
    'en-us': string;
    'pt-br': string;
  };
  access_type: string;
  description: {
    'en-us': string;
    'pt-br': string;
  };
  source: {
    'en-us': string;
    'pt-br': string;
  };
  projections: string;
}

interface IndicatorsFromGroupProps {
  limit: number;
  skip: number;
  total: number;
  data: DataIndicatorsFromGroupProps[];
}

interface DataIndicatorsPremium {
  id: string;
  name: {
    'pt-br': string;
    'en-us': string;
  };
  description: {
    'pt-br': string;
    'en-us': string;
  };
}

interface ModalProps {
  idGroup: string;
  visible: boolean;
  setVisible(value: boolean): void;
}

const QUANTITY_ITEMS_PAGE = 8;

export const ModalInfoGroupPremium: React.FC<ModalProps> = ({
  idGroup,
  visible,
  setVisible,
}) => {
  const [pages, setPages] = useState<number>(1);
  const { t: translate } = useTranslation();
  const { language } = useSelector((state: RootState) => state.auth.user);
  const navigate = useNavigate();

  const {
    data: groupsPremiumInfo,
    isLoading: isLoadingInfoGroup,
    isFetching: isFetchingInfoGroup,
    isError: isErrorInfoGroup,
  } = useQuery(
    ['groupsPremiumInfo', pages, idGroup],
    async () => {
      const { data } = await api.get<DataIndicatorsPremium>(
        `/access-groups/${idGroup}?skip=${
          (pages - 1) * QUANTITY_ITEMS_PAGE
        }&limit=${QUANTITY_ITEMS_PAGE}`,
      );

      return data;
    },
    {
      staleTime: 1000 * 60 * 20,
    },
  );

  const {
    data: indicatorsFromGroup,
    isLoading,
    isFetching,
    isError,
  } = useQuery(
    ['indicatorsFromGroup', pages, idGroup],
    async () => {
      const { data } = await api.get<IndicatorsFromGroupProps>(
        `/access-groups/${idGroup}/indicators?skip=${
          (pages - 1) * QUANTITY_ITEMS_PAGE
        }&limit=${QUANTITY_ITEMS_PAGE}`,
      );
      return data;
    },
    {
      staleTime: 1000 * 60 * 20,
    },
  );

  return (
    <Modal visible={visible} setVisible={setVisible}>
      {isError && isErrorInfoGroup ? (
        <DivError data-testid="info-group-premium-modal-error">
          <ContainerMaintenance
            content="data"
            text={translate('modalInfoGroupError')}
            data-testid="error-groups"
          />
        </DivError>
      ) : isLoading ||
        isLoadingInfoGroup ||
        isFetchingInfoGroup ||
        isFetching ? (
        // eslint-disable-next-line react/jsx-indent
        <Container data-testid="info-group-premium-modal-loading">
          <ContainerSkeleton
            withLoading={false}
            style={{
              height: '21px',
              width: '100px',
            }}
          />
          <ContainerSkeleton
            withLoading={false}
            style={{
              height: '21px',
              width: '500px',
              marginBottom: '50px',
            }}
          />
          <ContentTable>
            <Table>
              <Thead>
                <Tr>
                  <Th style={{ width: '20%' }}>{translate('name')}</Th>
                  <Th style={{ width: '55%' }}>{translate('description')}</Th>
                  <Th>{translate('source')}</Th>
                  <Th>{translate('projection')}</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {Array.from({ length: 8 }, (_, numberLoadingTr) => (
                  // eslint-disable-next-line react/jsx-indent
                  <Tr
                    key={`loading-tr-${numberLoadingTr + 1}`}
                    data-testid={`loading-tr-${numberLoadingTr + 1}`}
                  >
                    {Array.from({ length: 4 }, (__, numberLoadingTd) => (
                      // eslint-disable-next-line prettier/prettier
                      <Td
                        key={`loading-td-${numberLoadingTr + 1}-${
                          numberLoadingTd + 1
                        }`}
                        data-testid={`loading-td-${numberLoadingTr + 1}-${
                          numberLoadingTd + 1
                        }`}
                      >
                        <ContainerSkeleton
                          withLoading={false}
                          style={{
                            height: '21px',
                          }}
                        />
                      </Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </ContentTable>
        </Container>
      ) : (
        <>
          <Container data-testid="info-group-premium-modal">
            <div>
              <h1>
                {groupsPremiumInfo?.name[language] ??
                  groupsPremiumInfo?.name['en-us']}
              </h1>
              <img src={CrownSimple} alt="crown-icon" />
            </div>
            <p>
              {groupsPremiumInfo?.description[language] ??
                groupsPremiumInfo?.description['en-us']}
            </p>
            <ContentTable>
              <Table>
                <Thead>
                  <Tr>
                    <Th style={{ width: '20%' }}>{translate('name')}</Th>
                    <Th style={{ width: '55%' }}>{translate('description')}</Th>
                    <Th>{translate('source')}</Th>
                    <Th>{translate('projection')}</Th>
                    <Th />
                  </Tr>
                </Thead>
                <Tbody>
                  {indicatorsFromGroup?.data.map((indicatorInfo) => (
                    <Tr
                      key={indicatorInfo.name['en-us']}
                      data-testid={indicatorInfo.name['en-us']}
                    >
                      <Td>
                        {indicatorInfo.name[language] ??
                          indicatorInfo.name['en-us']}
                      </Td>
                      <Td>
                        {indicatorInfo.description[language] ??
                          indicatorInfo.description['en-us']}
                      </Td>
                      <Td>
                        {indicatorInfo.source[language] ??
                          indicatorInfo.source['en-us']}
                      </Td>
                      <Td>
                        {indicatorInfo.projections
                          ? indicatorInfo.projections
                          : '-'}
                      </Td>
                      <Td style={{ margin: '12px' }}>
                        <ButtonIcon
                          data-testid={`button-visualize-indicator-${indicatorInfo.indicator_code}`}
                          data-cy={`button-visualize-indicator-${indicatorInfo.indicator_code}`}
                          onClick={() =>
                            navigate(
                              `/feature-store/indicators/${indicatorInfo.indicator_code}`,
                            )
                          }
                        >
                          <MagnifyingGlass size="1.25rem" />
                        </ButtonIcon>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </ContentTable>
          </Container>
          {indicatorsFromGroup && (
            <Pagination
              total={indicatorsFromGroup?.total}
              page={pages}
              setPage={setPages}
              quantityItemsPerPage={10}
              name={translate('indicator')}
            />
          )}
        </>
      )}
      <FooterModal>
        <div>
          <Button
            buttonType="primary"
            onClick={() => setVisible(false)}
            data-testid="button-criar-indicador"
            data-cy="button-criar-indicador"
          >
            Ok
          </Button>
        </div>
      </FooterModal>
    </Modal>
  );
};
