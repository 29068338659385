import React, { useEffect, useState } from 'react';

import { CheckBox } from 'src/components/CheckBox';
import { ArrowLineLeft, CaretDown, FunnelSimple } from 'phosphor-react';
// import { ToggleSwitch } from 'src/components/ToggleSwitch';
import { useTranslation } from 'react-i18next';

import { HierarchicalFilterProps, MenuItemProps, Options } from './types';
import {
  CheckboxContainer,
  ContentMenu,
  MenuContainer,
  // MenuFooter,
  MenuHeader,
  MinimizeMaximizeButton,
  Option,
  OptionsContainer,
  OptionsMenu,
} from './styles';

export const HierarchicalFilter: React.FC<HierarchicalFilterProps> = ({
  options,
  optionAdded,
  optionRemoved,
  selected,
  hasHierarchy,
  // onChangeSelectOptionsBellow,
  // selectOptionsBellow,
  selectOptionsDisableWhenEditionMode,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [openOptions, setOpenOptions] = useState<string[]>([]);

  useEffect(() => {
    const openOptionsAux: string[] = [];

    function addOpenOptions(option: Options, oldHierarchy: string[]) {
      if (option.children.length) {
        openOptionsAux.push(JSON.stringify(oldHierarchy));
        option.children.forEach((child) => {
          addOpenOptions(child, oldHierarchy.concat(child.name));
        });
      }
    }

    if (!hasHierarchy) {
      options.forEach((option) => {
        addOpenOptions(option, [option.name]);
      });

      setOpenOptions([...openOptionsAux]);
    }
  }, [hasHierarchy, options]);

  const { t } = useTranslation();

  const handleOpenCloseMenuItem = (tree: string) => {
    if (openOptions.includes(tree)) {
      setOpenOptions(openOptions.filter((option) => option !== tree));
    } else {
      setOpenOptions([...openOptions, tree]);
    }
  };

  const handleClickOption = (
    tree: string,
    optionLabel: string,
    optionValue: string[],
    canSelect: boolean,
    hierarchy: string | null,
  ) => {
    if (canSelect) {
      const alreadySelected = selected?.find(
        (option) => option.value.toString() === optionValue.toString(),
      );

      const optionLabelAdjusted = optionLabel.replaceAll(',', ' -> ');

      if (alreadySelected) {
        optionRemoved({
          label: optionLabelAdjusted,
          value: optionValue,
          hierarchy,
        });
      } else {
        optionAdded({
          label: optionLabelAdjusted,
          value: optionValue,
          hierarchy,
        });
      }
    } else {
      handleOpenCloseMenuItem(tree);
    }
  };

  function handleOpenOrClose() {
    setIsOpen((state) => !state);
  }

  const MenuItem = ({
    subMenu,
    level,
    name,
    tree,
    canSelect,
    hierarchy,
  }: MenuItemProps) => {
    const treeString = JSON.stringify(tree);

    const currentTreeDataTestid = tree
      .toString()
      .replaceAll(' ', '-')
      .replaceAll(',', '-')
      .toLowerCase();

    const canSelectOption = canSelect || !subMenu.length;

    const optionIsSelected = !!selected?.find(
      ({ value }) => JSON.stringify(value) === treeString,
    );

    const disabled = selectOptionsDisableWhenEditionMode;

    return (
      <OptionsContainer
        data-testid={`group-${currentTreeDataTestid}`}
        style={{
          display:
            openOptions.includes(
              JSON.stringify(tree.slice(0, tree.length - 1)),
            ) || level === 1
              ? 'flex'
              : 'none',
        }}
      >
        <Option
          key={`${level}${name}`}
          data-testid={`level-${level}-option-${currentTreeDataTestid}`}
        >
          <CheckboxContainer
            onClick={() =>
              handleClickOption(
                treeString,
                subMenu.length ? tree.toString() : name,
                tree,
                canSelectOption,
                hierarchy,
              )
            }
            level={level}
            data-testid={`checkbox-container-${currentTreeDataTestid
              .replaceAll(' ', '-')
              .toLowerCase()}`}
            hasSubMenu={!!subMenu.length}
            disabled={disabled}
          >
            {canSelectOption ? (
              <CheckBox
                label={name}
                defaultChecked={optionIsSelected}
                disabled={disabled}
              />
            ) : (
              <p
                data-testid={`without-checkbox-${name
                  .replaceAll(' ', '-')
                  .toLowerCase()}`}
              >
                {name}
              </p>
            )}
          </CheckboxContainer>

          {!!subMenu.length && (
            <button
              type="button"
              onClick={() => handleOpenCloseMenuItem(treeString)}
              className={
                openOptions.includes(treeString) ? 'close-menu' : 'open-menu'
              }
              data-testid={`show-${currentTreeDataTestid}-children`}
              aria-label="close/open menu button"
            >
              <CaretDown />
            </button>
          )}
        </Option>

        {!!subMenu.length &&
          subMenu.map((option) => (
            <MenuItem
              key={`${option.level}${option.name}`}
              subMenu={option.children}
              name={option.name}
              level={option.level}
              tree={[...tree, option.name]}
              canSelect={!!option.canSelect}
              hierarchy={option.hierarchy}
            />
          ))}
      </OptionsContainer>
    );
  };

  return (
    <MenuContainer data-testid="container-options-menu" isOpen={isOpen}>
      <MinimizeMaximizeButton
        onClick={handleOpenOrClose}
        data-testid="button-open-or-close-options-menu"
      >
        {isOpen ? <ArrowLineLeft /> : <FunnelSimple />}
      </MinimizeMaximizeButton>
      <div data-testid="container-aux-options-menu">
        <div>
          <MenuHeader>
            <h4>{t('workspaceOverviewHierarchicalTitle')}</h4>
            <p>{t('workspaceOverviewHierarchicalDescription')}</p>
          </MenuHeader>

          <ContentMenu>
            <OptionsMenu>
              {options.map((option) => (
                <MenuItem
                  key={`${option.level}${option.name}`}
                  subMenu={option.children}
                  name={option.name}
                  level={option.level}
                  tree={[option.name]}
                  canSelect={!!option.canSelect}
                  hierarchy={option.hierarchy}
                />
              ))}
            </OptionsMenu>
          </ContentMenu>

          {/* {hasHierarchy && (
            <MenuFooter>
              <ToggleSwitch
                label={t('workspaceOverviewHierarchicalFilterUncheckAll')}
                checked={selectOptionsBellow}
                onChange={(e) => onChangeSelectOptionsBellow(e.target.checked)}
                disabled={selectOptionsDisableWhenEditionMode}
              />
            </MenuFooter>
          )} */}
        </div>
      </div>
    </MenuContainer>
  );
};
