import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;

  section {
    display: flex;
    gap: 1.5rem;
  }

  .containerLinear {
    position: relative;
    width: 63.125rem !important;

    @media (max-width: 1336px) {
      width: 100% !important;
    }
  }

  .right-button {
    padding: 0.75rem 4.188rem;

    @media (min-width: 700px) {
      position: absolute;
      right: 1.5rem;
      top: 2.375rem;
    }
  }

  .customTooltipMyProjects {
    max-width: none !important;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  padding: 0px;
  justify-content: space-between;
  gap: 1.5rem;

  > div {
    display: flex;
    padding: 0px;
    align-items: flex-start;
    gap: 1.5rem;
  }
`;

type ActiveButton = {
  active: boolean;
};

export const ButtonFavorite = styled.button<ActiveButton>`
  display: flex;
  padding: 0.75rem 1rem;
  align-items: flex-start;
  gap: 0.625rem;

  border-radius: 5px;

  color: ${({ active, theme }) =>
    active ? theme.colors.primary : theme.colors.gray4};
  font-weight: ${({ active }) => (active ? '600' : '400')};
  background: ${({ active }) => (active ? 'rgba(76, 148, 255, 0.12)' : 'none')};
  outline: ${({ active, theme }) =>
    active ? 'none' : `1px solid ${theme.colors.gray2}`};

  &:hover {
    outline: ${({ active, theme }) =>
      active ? 'none' : `1px solid ${theme.colors.gray3}`};

    color: ${({ active, theme }) =>
      active ? theme.colors.primary : theme.colors.gray5};
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.gray4};
  }
`;

export const GroupCardLoading = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  min-height: 18.375rem;
  max-height: 20.188rem;
  width: 13.5rem;

  border: 1px solid ${({ theme }) => theme.colors.gray2};
  box-sizing: border-box;
  border-radius: 8px;

  position: relative;

  > div:first-child {
    width: 100%;
    min-height: 4rem;
    padding: 1.5rem 1.5rem 0 0;
    background: ${({ theme }) => theme.colors.gray3};
    border-radius: 8px 8px 0px 0px;
  }

  > div:last-child {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 2.5rem 1rem 1rem 1.625rem;

    span {
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 150%;
      color: ${({ theme }) => theme.colors.gray4};
      margin-top: 0.75rem;
    }
  }

  @media (min-width: 1337px) {
    width: 14.313rem;
  }

  img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 4px;
  }

  span {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray6};

    margin-top: 1rem;
  }

  p {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray7};

    margin-top: 0.5rem;
  }
`;

export const OptionsButton = styled.button`
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;

  width: 1.25rem;
  height: 1.25rem;

  svg {
    color: ${({ theme }) => theme.colors.gray7};
  }
`;

export const ContainerWithoutGroups = styled.div`
  width: 100%;
  height: 37.188rem;
  padding: 1.5rem 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;
