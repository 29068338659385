import React from 'react';

import { Warning, X } from 'phosphor-react';
import { Tooltip } from 'react-tooltip';

import { Checkbox, Container } from './styles';
import { TagProps } from './types';
import { Button } from '../Button';

export const Tag: React.FC<TagProps> = ({
  label,
  hasCheckbox = false,
  checked = false,
  onClickCheckbox,
  hasRemoveButton = false,
  onClickRemoveButton,
  disabled = false,
  warning = false,
  warningTooltip,
  ...props
}) => (
  <Container
    disabled={disabled}
    data-testid={`tag-${label.replaceAll(' ', '-').toLocaleLowerCase()}`}
    {...props}
  >
    <Tooltip id="tag-warning-tooltip" className="customTooltipTheme" />

    {hasCheckbox && (
      <Checkbox disabled={disabled}>
        <input
          type="checkbox"
          id={`checkbox-${label.replaceAll(' ', '-').toLocaleLowerCase()}`}
          data-testid={`checkbox-${label
            .replaceAll(' ', '-')
            .toLocaleLowerCase()}`}
          onChange={onClickCheckbox}
          checked={checked}
          disabled={disabled}
        />
        <span />
      </Checkbox>
    )}

    <p>{label}</p>

    <div>
      {warning && (
        <Warning
          data-tooltip-id="tag-warning-tooltip"
          data-tooltip-html={warningTooltip}
          data-testid={`warning-info-${label
            .replaceAll(' ', '-')
            .toLocaleLowerCase()}`}
        />
      )}

      {hasRemoveButton && (
        <Button
          buttonType="naked"
          onClick={onClickRemoveButton}
          disabled={disabled}
          data-testid={`remove-button-${label
            .replaceAll(' ', '-')
            .toLocaleLowerCase()}`}
        >
          <X />
        </Button>
      )}
    </div>
  </Container>
);
