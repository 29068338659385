export const workspaceProjectionsResultPTTranslate = {
  workspaceProjectionsResultTitle: 'Resultados',
  workspaceProjectionsResultDescription:
    'Selecione um período histórico para obter mais detalhes dos resultados da modelagem.',
  workspaceProjectionsResultQuarterly: 'Trimestral',
  workspaceProjectionsResultsVariationOriginal:
    'Variação (comparado ao período anterior)',
  workspaceProjectionsResultsVariationMonthlyQuarterly:
    'Variação (comparado ao mesmo período do ano anterior)',
  workspaceProjectionsResultsVariationAnnual:
    'Variação (comparado ao ano anterior)',
  workspaceProjectionsMonthlyAggregationIsNotAvailable:
    'A agregação de série mensal não está disponível para dados com valores faltantes.',
  workspaceProjectionsResultsThisFeatureTemporarilyUnavailable:
    'Este recurso está temporariamente indisponível.',
};
