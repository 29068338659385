export const workspaceProjectionsExplanatoryVariablesPTTranslate = {
  workspaceProjectionsExplanatoryVariablesTitle:
    'Projeção de Variáveis Explicativas',
  workspaceProjectionsExplanatoryVariablesVariable: 'Variável',
  workspaceProjectionsExplanatoryNoVariables:
    'Nenhuma variável explicativa a ser apresentada.',
  workspaceProjectionsExplanatoryVariablesMonthly: 'Mensal',
  workspaceProjectionsExplanatoryVariablesQuarterly: 'Trimestral',
  workspaceProjectionsExplanatoryVariablesVariationOriginal:
    'Variação (comparado ao período anterior)',
  workspaceProjectionsExplanatoryVariablesVariationMonthlyQuarterly:
    'Variação (comparado ao mesmo período do ano anterior)',
  workspaceProjectionsExplanatoryVariablesVariationAnnual:
    'Variação (comparado ao ano anterior)',
  workspaceProjectionsExplanatoryVariableTooltipInfo:
    'As projeções das variáveis explicativas não são afetadas pela inflação. A inflação é calculada de forma isolada, diretamente nos resultados das projeções.',
};
