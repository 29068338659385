import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

import { AxiosError } from 'axios';
import parse from 'html-react-parser';
import {
  ArrowDown,
  ArrowUp,
  ArrowUpRight,
  ArrowsCounterClockwise,
  ArrowsDownUp,
  DownloadSimple,
  Upload,
} from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import xlsxFileImg from 'src/assets/xlsx-file.svg';
import { Button } from 'src/components/Button';
import { Card } from 'src/components/Card';
import { Head } from 'src/components/Head';
import { ErrorObject, FailedModal } from 'src/components/Modal/Failed';
import {
  clearPredictRunning,
  insertPredictRunning,
} from 'src/models/redux/reducers/PredictStatus';
import api from 'src/models/service/api';
import { RootState } from 'src/redux/store';

import { InputFile } from '../../../Modelling/TimeSeries/components/InputFile';
import {
  InfoContent,
  SpreadsheetContainer,
  SpreadsheetContent,
} from '../../../Modelling/TimeSeries/components/InputFile/styles';
import { WarningModal } from './components/WarningModal';
import {
  TableContent,
  ButtonsContent,
  ChangeDatasetButton,
  TableButtonsContent,
  RowQuantityContainer,
  TableSortButton,
  CallWithAPIButton,
} from './styles';
import {
  ParamsProps,
  PredictPreview,
  PredictError,
  PredictWarning,
  Predict,
  PredictSort,
  PredictRow,
} from './types';
import { ScriptCallModal } from './components/ScriptCallModal';

export const Predictions: React.FC = () => {
  const { id: projectId } = useParams<ParamsProps>();
  const { t: translate } = useTranslation();
  const { name: projectName, modelInProduction } = useSelector(
    (state: RootState) => state.claasProject,
  );
  const {
    id: predictId,
    usedModel,
    dataset,
    preview: lastPreview,
  } = useSelector((state: RootState) => state.predictStatus);

  const dispatch = useDispatch();

  const [datasetFile, setDatasetFile] = useState<File>();
  const [predictAPIExampleVisible, setPredictAPIExampleVisible] =
    useState(false);
  const [failedModalInfo, setFailedModalInfo] = useState<ErrorObject>();
  const [warningModalInfo, setWarningModalInfo] = useState<PredictWarning>();
  const [uploadRunning, setUploadRunning] = useState(false);
  const [predictionsResult, setPredictionsResult] = useState<PredictPreview>();
  const [timer, setTimer] = useState(0);
  const [downloadDisabled, setDownloadDisabled] = useState(true);
  const [downloadRunning, setDownloadRunning] = useState(false);
  const [sortTableBy, setSortTableBy] = useState<PredictSort>();

  useEffect(() => {
    setPredictionsResult(undefined);
  }, []);

  useEffect(() => {
    dataset && setDatasetFile(dataset);
    lastPreview && setPredictionsResult(lastPreview);
  }, [dataset, lastPreview]);

  useLayoutEffect(() => {
    const getPredictStatus = async () => {
      try {
        const { data } = await api.get<Predict>(`/tasks/${predictId}`);

        data && data.status === 'success'
          ? (setDownloadDisabled(false), setTimer(2000))
          : setTimer(2000);
      } catch {
        setTimer(2000);
      }
    };

    setTimeout(() => {
      if (timer > 0) {
        setTimer(timer - 1000);
      } else {
        downloadDisabled === true && predictId !== null && getPredictStatus();
      }
    }, 1000);
  }, [downloadDisabled, predictId, timer]);

  async function handleUploadDatasetToPredict() {
    setUploadRunning(true);

    if (datasetFile && datasetFile.size > 6500000) {
      setUploadRunning(false);
      setFailedModalInfo({
        title: translate('predictFileSizeErrorTitle'),
        description: translate('predictFileSizeErrorDescription').replace(
          '"X"',
          '6.5MB',
        ),
      });

      return;
    }

    if (datasetFile) {
      const fileExtension = datasetFile.name.split('.').pop()?.toLowerCase();
      if (
        fileExtension &&
        fileExtension !== 'csv' &&
        fileExtension !== 'xlsx'
      ) {
        setUploadRunning(false);
        setFailedModalInfo({
          title: translate('requestFailed'),
          description: translate('predictFileExtensionErrorDescription'),
        });

        return;
      }
    }

    try {
      const requestPayload = new FormData();
      datasetFile && requestPayload.append('file', datasetFile);

      if (modelInProduction) {
        const [{ data: previewData }, { data: predictData }] =
          await Promise.all([
            api.post<PredictPreview>(
              `/classification/projects/${projectId}/models/${modelInProduction.type}/predict/preview`,
              requestPayload,
            ),
            api.post<Predict>(
              `/classification/projects/${projectId}/models/${modelInProduction.type}/predict`,
              requestPayload,
            ),
          ]);

        previewData &&
          previewData?.warning &&
          previewData.warning?.detail?.includes(
            'not affecting the prediction',
          ) &&
          setWarningModalInfo({
            message: translate('predictExtraColumnWarning'),
            columns: previewData.warning.extra_columns,
            isError: false,
          });

        previewData && setPredictionsResult(previewData);
        predictData &&
          previewData &&
          datasetFile &&
          dispatch(
            insertPredictRunning({
              id: predictData.id,
              usedModel: modelInProduction.name,
              preview: previewData,
              dataset: datasetFile,
            }),
          );
        setTimer(2000);
        setDownloadDisabled(true);
      } else throw new Error();
    } catch (err) {
      const error = err as AxiosError<PredictError>;

      if (
        error?.response?.data?.detail?.detail &&
        error?.response?.data?.detail?.missing_columns
      ) {
        error?.response?.data?.detail?.detail.includes('Mandatory')
          ? setWarningModalInfo({
              message: translate('predictMissingColumnWarning'),
              columns: error.response.data.detail.missing_columns,
              isError: true,
            })
          : setFailedModalInfo({
              title: translate('requestFailed'),
              description: translate('unknownRequestErrorDescription'),
            });
      } else {
        setFailedModalInfo({
          title: translate('requestFailed'),
          description: translate('unknownRequestErrorDescription'),
        });
      }
    }

    setUploadRunning(false);
  }

  async function handleDownloadPredictionResult() {
    setDownloadRunning(true);
    try {
      const response = await api.get(
        `/classification/projects/${projectId}/predict/${predictId}/download`,
        {
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      if (response.data) {
        const fileURL = window.URL.createObjectURL(
          new Blob([response.data], { type: 'text/csv' }),
        );

        const link = document.createElement('a');
        if (link.download !== undefined) {
          link.setAttribute('href', fileURL);
          link.setAttribute(
            'download',
            `${projectName
              ?.replaceAll(' ', '-')
              .toLocaleLowerCase()}-predict.csv`,
          );
          link.setAttribute('data-testid', 'csv-download-start');
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
        }
      }
    } catch {
      setFailedModalInfo({
        title: translate('requestFailed'),
        description: translate('unknownRequestErrorDescription'),
      });
    }
    setDownloadRunning(false);
  }

  const handleSortTable = (columnName: string) => {
    if (sortTableBy?.sort === 'descend' && columnName === sortTableBy?.column) {
      setSortTableBy(undefined);
      return;
    }

    if (sortTableBy?.column === columnName) {
      setSortTableBy({
        column: columnName,
        sort: 'descend',
      });
    } else {
      setSortTableBy({
        column: columnName,
        sort: 'crescent',
      });
    }
  };

  const sortPredict = useCallback(
    (
      items: PredictRow[][],
      column: string,
      order: 'crescent' | 'descend',
    ): PredictRow[][] => {
      const itemsToSort = [...items];
      const columnIndex = itemsToSort[0].findIndex(
        (item) => item.name === column,
      );

      const orderedItems = itemsToSort.sort((itemA, itemB) => {
        const aValue = itemA[columnIndex].value;
        const bValue = itemB[columnIndex].value;
        const comparison = aValue.localeCompare(bValue);

        if (aValue === bValue) {
          const aIndexItem = itemA.find((item) => item.name === 'Index');
          const bIndexItem = itemB.find((item) => item.name === 'Index');

          if (aIndexItem && bIndexItem) {
            const aIndex = parseFloat(aIndexItem.value);
            const bIndex = parseFloat(bIndexItem.value);

            return aIndex - bIndex;
          }
        }

        const aNumberValue = parseFloat(aValue);
        const bNumberValue = parseFloat(bValue);

        if (!Number.isNaN(aNumberValue) && !Number.isNaN(bNumberValue)) {
          return order === 'crescent'
            ? aNumberValue - bNumberValue
            : bNumberValue - aNumberValue;
        }

        return order === 'crescent' ? comparison : comparison * -1;
      });

      return orderedItems;
    },
    [],
  );

  const sortedPredictResult = useMemo(() => {
    try {
      if (predictionsResult && sortTableBy) {
        const sortedPredict = sortPredict(
          predictionsResult.prediction,
          sortTableBy.column,
          sortTableBy.sort,
        );

        return sortedPredict;
      }
      return undefined;
    } catch {
      setSortTableBy(undefined);
      return undefined;
    }
  }, [predictionsResult, sortPredict, sortTableBy]);

  return (
    <>
      <Head title={translate('predictionsTitle')} />
      <div className="containerLinear">
        <Card
          textCard={translate('getPredictionsTitle')}
          textDescription={translate('predictionsDescription')}
        />
        {!predictionsResult ? (
          <>
            <InputFile
              title={translate('predictUploadTitle')}
              description={parse(translate('predictUploadDescription'))}
              label={translate('predictUploadLabel')}
              file={datasetFile}
              setFile={(file) => setDatasetFile(file)}
              fileFormats={['.xlsx', '.csv']}
              dataCy="dataset-input"
              rowLayout={!!datasetFile}
            />

            <ButtonsContent>
              <Button
                buttonType="naked"
                data-testid="button-cancel"
                data-cy="button-cancel"
                disabled={uploadRunning}
                onClick={() => {
                  setPredictionsResult(undefined);
                  setDatasetFile(undefined);
                }}
              >
                {translate('cancel')}
              </Button>
              <div>
                <CallWithAPIButton
                  onClick={() => setPredictAPIExampleVisible(true)}
                  data-testid="call-via-api-button"
                  data-cy="call-via-api-button"
                >
                  <ArrowUpRight size="1.25rem" />
                  <p>{translate('predictAPIModalTitle')}</p>
                </CallWithAPIButton>
                <Button
                  buttonType="primary"
                  icon={<Upload size="1.125rem" />}
                  onClick={handleUploadDatasetToPredict}
                  loading={uploadRunning}
                  disabled={uploadRunning || !modelInProduction || !datasetFile}
                  data-testid="button-upload-dataset"
                  data-cy="button-upload-dataset"
                >
                  {translate('predictUploadButton')}
                </Button>
              </div>
            </ButtonsContent>
          </>
        ) : (
          predictionsResult &&
          datasetFile && (
            <>
              <SpreadsheetContainer
                data-testid="selected-file"
                data-cy="selected-file"
                onlyFileLayout
              >
                <SpreadsheetContent>
                  <img
                    src={xlsxFileImg}
                    alt="Imagem de um arquivo"
                    data-testid="extension-img"
                    data-cy="extension-img"
                  />

                  <InfoContent>
                    <b data-testid="file-name" data-cy="file-name">
                      {datasetFile?.name}
                    </b>
                    <p data-testid="file-size" data-cy="file-size">
                      {datasetFile?.size &&
                        (datasetFile.size >= 1000000
                          ? `${(datasetFile.size / 1000000).toFixed(2)} MB`
                          : datasetFile.size >= 1000
                          ? `${(datasetFile.size / 1000).toFixed(2)} kB`
                          : `${datasetFile.size} B`)}
                    </p>
                  </InfoContent>
                </SpreadsheetContent>
              </SpreadsheetContainer>
              <ButtonsContent positionInvert>
                <ChangeDatasetButton
                  data-cy="button-change-dataset"
                  data-testid="button-change-dataset"
                  onClick={() => {
                    setDatasetFile(undefined);
                    setPredictionsResult(undefined);
                    dispatch(clearPredictRunning());
                  }}
                >
                  <ArrowsCounterClockwise weight="bold" size="1.25rem" />
                  <h3>{translate('predictChangeDataset')}</h3>
                </ChangeDatasetButton>
              </ButtonsContent>
            </>
          )
        )}
      </div>

      {predictionsResult && (
        <div className="containerLinear">
          <Card
            textCard={translate('predictionsTitle')}
            textDescription={translate(
              'predictionsResultDescription',
            ).replaceAll('"X"', usedModel ?? modelInProduction?.name ?? '')}
          />
          <TableContent data-testid="predict-result">
            <table>
              <thead>
                <tr>
                  {predictionsResult.prediction[0].map((variable) => (
                    <th key={`${variable.name}-${variable.value}`}>
                      <div>
                        <p>
                          {variable.name.includes('Probability')
                            ? `${variable.name.replace(
                                'Probability',
                                translate('predictProbability'),
                              )} (%)`
                            : variable.name}
                        </p>
                        <TableSortButton
                          active={sortTableBy?.column === variable.name}
                          onClick={() => handleSortTable(variable.name)}
                          data-testid={`button-sort-by-${variable.name
                            .replaceAll(' ', '-')
                            .toLocaleLowerCase()}`}
                          data-cy={`button-sort-by-${variable.name
                            .replaceAll(' ', '-')
                            .toLocaleLowerCase()}`}
                        >
                          {sortTableBy?.sort === 'crescent' &&
                          sortTableBy.column === variable.name ? (
                            <ArrowDown
                              size="1rem"
                              weight="bold"
                              data-testid="sorted-by-lower"
                            />
                          ) : sortTableBy?.sort === 'descend' &&
                            sortTableBy.column === variable.name ? (
                            // eslint-disable-next-line react/jsx-indent
                            <ArrowUp
                              size="1rem"
                              weight="bold"
                              data-testid="sorted-by-higher"
                            />
                          ) : (
                            <ArrowsDownUp
                              size="1rem"
                              weight="bold"
                              data-testid="unsorted"
                            />
                          )}
                        </TableSortButton>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {sortedPredictResult &&
                  sortedPredictResult.map((predict, index) => (
                    <tr key={`${index + 1}`}>
                      {predict.map((variable) => (
                        <td key={variable.name + variable.value}>
                          {variable.name.includes('Probability')
                            ? Number(variable.value) &&
                              Number(variable.value).toFixed(2)
                            : variable.value}
                        </td>
                      ))}
                    </tr>
                  ))}
                {!sortedPredictResult &&
                  predictionsResult.prediction.map((predict, index) => (
                    <tr key={`${index + 1}`}>
                      {predict.map((variable) => (
                        <td key={variable.name + variable.value}>
                          {variable.name.includes('Probability')
                            ? Number(variable.value) &&
                              Number(variable.value).toFixed(2)
                            : variable.value}
                        </td>
                      ))}
                    </tr>
                  ))}
              </tbody>
            </table>
          </TableContent>
          <TableButtonsContent>
            <RowQuantityContainer>
              <span>
                {`${translate('previewingFirst')} ${
                  predictionsResult.preview_length
                } ${translate('of')} ${
                  predictionsResult.original_length
                } ${translate('lines')}.`}
              </span>
            </RowQuantityContainer>
            <Button
              buttonType="primary"
              onClick={handleDownloadPredictionResult}
              icon={<DownloadSimple size="1.125rem" />}
              data-testid="button-download-prediction"
              data-cy="button-download-prediction"
              disabled={downloadDisabled || downloadRunning}
              loading={downloadDisabled || downloadRunning}
            >
              {translate('predictDownload')}
            </Button>
          </TableButtonsContent>
        </div>
      )}

      <FailedModal
        visible={!!failedModalInfo}
        setVisible={(value) => value === false && setFailedModalInfo(undefined)}
        errorInfo={failedModalInfo}
      />

      <ScriptCallModal
        projectID={projectId ?? ''}
        visible={predictAPIExampleVisible}
        setVisible={setPredictAPIExampleVisible}
      />

      {warningModalInfo && (
        <WarningModal
          visible={!!warningModalInfo}
          setVisible={(value) =>
            value === false && setWarningModalInfo(undefined)
          }
          message={warningModalInfo?.message}
          columns={warningModalInfo?.columns}
          error={warningModalInfo?.isError}
        />
      )}
    </>
  );
};
