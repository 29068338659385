const sendToGroupPTBRTranslations = {
  chooseAGroup: 'Escolha um grupo para enviar séries',
  clickAddToContinue: 'Clique em “Adicionar” para continuar',
  createNewGroup: 'Criar novo grupo',
  sendToGroupAdd: 'Adicionar',
  createNewGroupAndAlreadyNewSeries:
    'Crie um novo grupo e já adicione novas séries ',
  successfullyAddSeries: 'Séries adicionadas com sucesso',
  selectedSeries: 'A série selecionada foi adicionada ao grupo XXX',
  backToIndicator: 'Voltar ao indicador',
  goToGroup: 'Ir para o grupo',
  sendBack: 'Voltar',
  sendToGroupSearchGroup: 'Procurar grupo',
  sendUnableToLoadPreDefinedGroup:
    'Não foi possível carregar os grupos 4i favoritos.',
  sendUnableToLoadYourGroup: 'Não foi possível carregar seus grupos favoritos.',
  sendUnableToAddSeries: 'Não foi possível adicionar séries',
  somethingWentWrong:
    'Algo deu errado ao adicionar séries no grupo XXX. Tente selecionar menos aberturas',
  sendPreDefinedTitleTab: 'Favoritos 4i',
  sendMyGroupTitleTab: 'Meus favoritos',
};

export default sendToGroupPTBRTranslations;
