export function concatRemovedVariables(
  arrayList: Array<string[] | undefined>,
): Array<string> {
  let result: string[] | undefined = [];

  if (arrayList && arrayList.length > 0) {
    for (let i = 0; i < arrayList.length; i++) {
      result = result.concat(arrayList[i] ?? []);
    }
  }

  return result;
}
