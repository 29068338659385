import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  margin: 2rem 0;
  padding-top: 2rem;

  border-top: 1px solid ${({ theme }) => theme.colors.gray2};

  .select__menu {
    z-index: 100000000;
  }

  > div > div {
    width: 16.938rem;
  }
`;
