import styled from 'styled-components';

export const Container = styled.div`
  height: auto;

  > div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 5.688rem);

    @media (max-width: 1545px) {
      height: calc(100% - 6.75rem);
    }

    > p {
      font-style: normal;
      font-weight: 500;
      font-size: 0.875rem !important;
      line-height: 1.375rem;
      color: ${({ theme }) => theme.colors.gray5};
      text-align: center;
    }
  }
`;
