const adminENTranslate = {
  adminDescription: 'View all indicators added to the platform.',
  adminDescriptionList: 'List of all Feature Store indicators.',
  adminSearchIndicator: 'Search Indicator',
  adminCreateNewIndicator: 'Create new indicator',
  adminFailedSearchIndicator: 'Failed to search for XX',
  adminCode: 'Code',
  adminName: 'Name',
  adminShortName: 'Short name',
  adminShortDescription: 'Short description',
  adminFullDescription: 'Full description',
  adminCountry: 'Country',
  adminSource: 'Source',
  adminSector: 'Sector',
  adminRegion: 'Region',
  adminAccessType: 'Access type',
  adminAGPER: 'AG/PER',
  adminTP: 'TP',
  adminTS: 'TS',
  adminActive: 'Active?',
  adminActions: 'Actions',
  adminIndicators: 'Indicators',
  adminProjection: 'Forecast',
};

export default adminENTranslate;
