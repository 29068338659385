import React from 'react';

import { Robot, User } from 'phosphor-react';
import { useTranslation } from 'react-i18next';

import { Container } from './styles';
import { ProjectionTagProps } from './types';

export const ProjectionTag: React.FC<ProjectionTagProps> = ({ type }) => {
  const { t: translate } = useTranslation();

  return (
    <Container type={type} data-testid={`tag-projection-by-${type}`}>
      {type === 'economists' ? (
        <>
          <User />
          <p data-testid="text-projection-tag-economists-type">
            {translate('projectionTagEconomistsTypeTitle')}
          </p>
        </>
      ) : (
        <>
          <Robot />
          <p data-testid="text-projection-tag-ai-type">
            {translate('projectionTagAITypeTitle')}
          </p>
        </>
      )}
    </Container>
  );
};
