import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: ${({ theme }) => theme.colors.gray5};

  label {
    font-weight: 500;
    font-size: 0.875rem;
  }
`;

type InputFileContainerProps = {
  isDisabled: boolean;
  dragging: boolean;
  error: boolean;
  rowLayout?: boolean;
};

export const InputFileContainer = styled.div<InputFileContainerProps>`
  display: flex;
  flex-direction: ${({ rowLayout }) => (rowLayout ? 'row' : 'column')};
  align-items: ${({ rowLayout }) => (rowLayout ? '' : 'center')};
  justify-content: ${({ rowLayout }) =>
    rowLayout ? 'space-between' : 'center'};

  text-align: center;

  position: relative;

  padding: 2rem;
  margin-top: 0.5rem;

  background: ${({ dragging, isDisabled, theme }) =>
    dragging && !isDisabled ? theme.colors.gray1 : theme.colors.white};

  transition: border 0.2s;

  border: 1px solid
    ${({ error, theme }) => (error ? theme.colors.red2 : theme.colors.gray2)};
  border-radius: 8px;

  &:hover {
    border: 1px solid
      ${({ error, theme }) => (error ? theme.colors.red2 : theme.colors.gray3)};
  }

  > span {
    color: ${({ theme }) => theme.colors.red2};
    font-size: 0.875rem;
    font-weight: 400;

    margin-top: 0.5rem;
  }

  > div {
    > img {
      height: 6.875rem;

      margin-bottom: 1.5rem;
    }

    > h1 {
      color: ${({ theme }) => theme.colors.gray5};
      font-size: 1.125rem;
      font-weight: 600;

      margin-bottom: 1rem;
    }

    > p {
      color: ${({ theme }) => theme.colors.gray4};
      font-size: 0.875rem;
      font-weight: 400;
    }

    > input {
      position: absolute;

      width: 100%;
      height: 100%;

      top: 0;
      left: 0;
      bottom: 0;
      right: 0;

      opacity: 0;

      cursor: pointer;

      &:disabled {
        cursor: not-allowed;
      }

      ${({ dragging }) =>
        dragging &&
        css`
          &:after {
            content: '';
            position: absolute;

            top: 0;
            left: 0;
            bottom: 0;
            right: 0;

            z-index: 1;
          }
        `}
    }
  }
`;

interface SpreadsheetContainerProps {
  rowLayout?: boolean;
  onlyFileLayout?: boolean;
}

export const SpreadsheetContainer = styled.div<SpreadsheetContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  ${({ rowLayout }) =>
    rowLayout === true &&
    css`
      max-width: 25rem;
      height: 5.375rem;
    `}

  ${({ onlyFileLayout }) =>
    onlyFileLayout === true &&
    css`
      max-width: 37.5rem;
      height: 5.375rem;
    `}

  padding: 1rem;
  margin-top: ${({ rowLayout }) => (rowLayout ? '0' : '1.5rem')};
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  box-shadow: 0 2px ${({ theme }) => theme.colors.gray1};

  border-radius: 8px;

  z-index: 1;

  button {
    width: 1rem;
    height: 1rem;

    margin-left: 1rem;

    &:disabled {
      cursor: not-allowed;
    }

    svg {
      width: 1rem;
      height: 1rem;

      color: ${({ theme }) => theme.colors.gray3};
      &:hover {
        color: ${({ theme }) => theme.colors.gray4};
      }
    }
  }
`;

export const SpreadsheetContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;

  img {
    width: 3rem;
    height: 3rem;

    margin-right: 1.5rem;
  }
`;

export const InfoContent = styled.div`
  text-align: start;

  b {
    color: ${({ theme }) => theme.colors.gray5};
    font-size: 0.875rem;
    font-weight: 500;

    word-break: break-word;
  }

  p {
    color: ${({ theme }) => theme.colors.gray4};
    font-size: 0.75rem;
    margin-top: 0.25rem;
  }
`;

export const ButtonSeeRequirements = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  margin-top: 1.5rem;

  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary};

  z-index: 2;
  transition: 0.2 color ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.colors.primaryDark};
  }
`;
