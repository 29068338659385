import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-top: 2.5rem;
  padding-top: 1.5rem;

  border-top: 1px solid ${({ theme }) => theme.colors.gray2};
`;

export const CirclesContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

type CirclesProps = {
  selected: boolean;
};

export const Circle = styled.div<CirclesProps>`
  width: ${({ selected }) => (selected ? '0.75rem' : '0.5rem')};
  height: ${({ selected }) => (selected ? '0.75rem' : '0.5rem')};

  border-radius: 100%;

  background: ${({ theme, selected }) =>
    selected ? theme.colors.primary : theme.colors.gray2};
`;
