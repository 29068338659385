const modelUpdateSidebarPTBRTranslations = {
  modelUpdtSidebarTitle: 'Histórico de Atualização',
  modelUpdtSidebarDescription:
    'Veja o histórico de atualizações e a versão original do seu projeto .',
  modelUpdtProjectViewing: 'Visualizando <strong>"UPDATE_NAME"</strong>',
  modelUpdtBackToLastUpdt: 'Voltar para atualização mais recente',
  modelUpdtLatestUpdate: 'última atualização',
};

export default modelUpdateSidebarPTBRTranslations;
