import React, { useState } from 'react';

import parseHTML from 'html-react-parser';
import { ArrowLeft, Trash, UserCircle } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { Card } from 'src/components/Card';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Head } from 'src/components/Head';
import { FailedModal } from 'src/components/Modal/Failed';
import { Pagination } from 'src/components/Pagination';
import { Table, Tbody, Td, Th, Thead, Tr } from 'src/components/Table';
import { RootState } from 'src/redux/store';
import { apiAdminV2 } from 'src/service/apiAdminV2';

import { ModalRegisterNewUser } from './components/ModalRegisterNewUser';
import { ModalSuccessRegisterUser } from './components/ModalSuccessRegisterUser';
import { StatusMenu } from './components/StatusMenu';
import {
  AdminPanelContainer,
  ButtonDeleteUser,
  Content,
  ContentTable,
  HeaderAdminPanel,
  MessageLicenseAndButtonAdd,
} from './styles';
import {
  InfoToRegisterUser,
  ResponseCustomerInfo,
  ResponseUsersList,
} from './types';
import { ModalDeleteUserClient } from './components/ModalDeleteUserClient';

export type DeleteUserProp = {
  client_id?: string;
  user_id?: string;
};

type useParamsProps = {
  client_id: string;
};

const QUANTITY_ITEMS_PAGE = 8;

export const AdminPanel: React.FC = () => {
  const [page, setPage] = useState(1);
  const [modalRegisterNewUser, setModalRegisterNewUser] =
    useState<boolean>(false);
  const [infoFailedToRegisterUser, setInfoFailedToRegisterUser] =
    useState<InfoToRegisterUser>();
  const [infoSuccessToRegisterUser, setInfoSuccessToRegisterUser] =
    useState<InfoToRegisterUser>();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteUser, setDeleteUser] = useState<DeleteUserProp>();

  const { client_id } = useParams<useParamsProps>();
  const { user: userRedux } = useSelector((state: RootState) => state.auth);
  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  const {
    data: listUsers,
    isLoading,
    isFetching,
    isError,
  } = useQuery<ResponseUsersList>(
    ['list users admin painel', page],
    async () => {
      const response = await apiAdminV2.get(
        `/clients/${client_id}/users?&skip=${
          (page - 1) * QUANTITY_ITEMS_PAGE
        }&limit=${QUANTITY_ITEMS_PAGE}`,
      );

      return response.data;
    },
  );

  const { data: customerInfo } = useQuery<ResponseCustomerInfo>(
    ['customer info', client_id],
    async () => {
      const response = await apiAdminV2.get(`/clients/${client_id}`);

      return response.data;
    },
  );

  return (
    <>
      <Head title="4intelligence" />
      <AdminPanelContainer>
        <Content className="containerLinear">
          <HeaderAdminPanel>
            {userRedux.isSupport && (
              <ArrowLeft
                size="2rem"
                onClick={() => navigate('/user-module/customers-list')}
                data-cy="back-button"
                data-testid="back-button"
              />
            )}

            <Card
              textCard={translate('PanelAdminTitle')}
              textDescription={translate('PanelAdminDescription')}
            />
            <MessageLicenseAndButtonAdd>
              {isError ? null : !customerInfo ? (
                <ContainerSkeleton
                  withLoading={false}
                  style={{
                    width: 200,
                    height: 18,
                    marginRight: 24,
                  }}
                />
              ) : (
                <span data-testid="text-remaining-licenses">
                  {parseHTML(
                    translate(
                      customerInfo.total_licenses > 1
                        ? 'PanelAdminQuantityLicensePlural'
                        : 'PanelAdminQuantityLicenseSingular',
                    )
                      .replace(
                        'XXX',
                        (
                          customerInfo.total_licenses -
                          customerInfo.remaining_licenses
                        ).toString(),
                      )
                      .replace('YYY', customerInfo.total_licenses.toString()),
                  )}
                </span>
              )}

              <Button
                buttonType="primary"
                icon={<UserCircle size="1.125rem" />}
                onClick={() => setModalRegisterNewUser(true)}
                disabled={
                  customerInfo && !isLoading && !isFetching && !isError
                    ? customerInfo.remaining_licenses === 0
                    : true
                }
                data-testid="button-invite-new-user"
                data-cy="button-invite-new-user"
              >
                {translate('PanelAdminInviteNewUser')}
              </Button>
            </MessageLicenseAndButtonAdd>
          </HeaderAdminPanel>
          {isError ? (
            <ContainerMaintenance
              content="users"
              text={translate('PainelAdminErrorLoad')}
              data-testid="container-maintenance-users-list"
            />
          ) : (
            <>
              <ContentTable>
                <Table>
                  <Thead>
                    <Tr>
                      <Th width="50%">{translate('email')}</Th>
                      <Th width="45%">{translate('status')}</Th>
                      <Th width="5%">{translate('actions')}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {isLoading || isFetching || !listUsers ? (
                      Array.from(
                        { length: QUANTITY_ITEMS_PAGE },
                        (_, index) => (
                          // eslint-disable-next-line react/jsx-indent
                          <Tr
                            key={`tr-loading-${index.toString()}`}
                            data-testid={`tr-loading-${index.toString()}`}
                          >
                            <Td>
                              <ContainerSkeleton
                                withLoading={false}
                                style={{
                                  height: '24px',
                                  width: '300px',
                                }}
                              />
                            </Td>
                            <Td>
                              <ContainerSkeleton
                                withLoading={false}
                                style={{
                                  height: '24px',
                                  width: '50px',
                                }}
                              />
                            </Td>
                            <Td>
                              <ContainerSkeleton
                                withLoading={false}
                                style={{
                                  height: '24px',
                                  width: '50px',
                                }}
                              />
                            </Td>
                          </Tr>
                        ),
                      )
                    ) : (
                      <>
                        {listUsers?.records.map((user) => (
                          // eslint-disable-next-line react/jsx-indent
                          <Tr
                            key={`user-${user.id.toString()}`}
                            data-testid={`user-${user.id.toString()}`}
                          >
                            <Td>{user.email}</Td>
                            <Td>
                              <StatusMenu
                                userId={user.id}
                                statusIsBlocked={user.is_blocked}
                                managerId={client_id ?? ''}
                                blockOptionActive={
                                  user.discount_license
                                    ? !customerInfo?.remaining_licenses
                                    : false
                                }
                                blockOptionBlocked={user.role === 'manager'}
                              />
                            </Td>
                            <Td
                              style={{ display: 'flex', justifyContent: 'end' }}
                            >
                              <ButtonDeleteUser
                                disabled={!userRedux.isSupport}
                                data-testid={`button-delete-user-${user.id}`}
                                onClick={() => {
                                  setDeleteModal(true);
                                  setDeleteUser({
                                    user_id: user.id,
                                    client_id: user.client_id,
                                  });
                                }}
                              >
                                <Trash size="1.25rem" />
                              </ButtonDeleteUser>
                            </Td>
                          </Tr>
                        ))}
                        {Array.from(
                          {
                            length:
                              QUANTITY_ITEMS_PAGE - listUsers?.records.length,
                          },
                          (_, index) => (
                            <Tr
                              key={`tr-complete-${index}`}
                              data-testid={`tr-complete-${index}`}
                            >
                              <Td style={{ height: '24px' }} />
                            </Tr>
                          ),
                        )}
                      </>
                    )}
                  </Tbody>
                </Table>
              </ContentTable>
              {listUsers && (
                <Pagination
                  page={page}
                  setPage={setPage}
                  name={translate('PainelAdminNamePagination')}
                  total={listUsers.total}
                  quantityItemsPerPage={QUANTITY_ITEMS_PAGE}
                />
              )}
            </>
          )}
        </Content>
        {modalRegisterNewUser && customerInfo && (
          <ModalRegisterNewUser
            visible
            customerInfoLicense={customerInfo?.remaining_licenses}
            setVisible={setModalRegisterNewUser}
            setInfoFailedToRegisterUser={setInfoFailedToRegisterUser}
            setInfoSuccessToRegisterUser={setInfoSuccessToRegisterUser}
          />
        )}
        {infoFailedToRegisterUser && (
          <FailedModal
            visible={!!infoFailedToRegisterUser}
            setVisible={(value) =>
              value === false && setInfoFailedToRegisterUser(undefined)
            }
            errorInfo={infoFailedToRegisterUser}
          />
        )}

        {infoSuccessToRegisterUser && (
          <ModalSuccessRegisterUser
            successInfo={infoSuccessToRegisterUser}
            visible
            setVisible={(value) =>
              value === false && setInfoSuccessToRegisterUser(undefined)
            }
          />
        )}

        {deleteModal && (
          <ModalDeleteUserClient
            visible
            setVisible={setDeleteModal}
            user={deleteUser}
          />
        )}
      </AdminPanelContainer>
    </>
  );
};
