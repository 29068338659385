import styled, { css } from 'styled-components';

type SubscribeToAlertProps = {
  isSubscribe: boolean;
};

export const SubscribeToAlertContainer = styled.div<SubscribeToAlertProps>`
  .button-rounded {
    padding: ${({ isSubscribe }) => (isSubscribe ? '0.5625rem' : '0.5rem')};

    background: ${({ isSubscribe }) =>
      isSubscribe ? 'rgba(76, 148, 255, 0.12)' : 'none'};

    transition: background 0.3s ease-in;

    ${({ isSubscribe }) =>
      isSubscribe &&
      css`
        border: none;
      `}

    svg {
      color: ${({ theme, isSubscribe }) =>
        isSubscribe ? theme.colors.primary : theme.colors.gray4};
    }

    &:hover {
      svg {
        color: ${({ theme, isSubscribe }) =>
          isSubscribe ? theme.colors.primary : theme.colors.gray5};
      }
    }
  }
`;

export const ContainerWithText = styled.div<SubscribeToAlertProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;

  gap: 0.5rem;
  padding: 0.5rem 0.75rem;
  height: 2.375rem;

  border-radius: 624.9375rem;
  border: 1px solid ${({ theme }) => theme.colors.gray2};

  color: ${({ theme }) => theme.colors.gray5};

  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  background: ${({ isSubscribe }) =>
    isSubscribe ? 'rgba(76, 148, 255, 0.12)' : 'none'};

  transition: background 0.3s ease-in;

  cursor: pointer;

  ${({ isSubscribe }) =>
    isSubscribe &&
    css`
      border: none;
      padding: 9px 13px;
    `}

  svg {
    color: ${({ theme, isSubscribe }) =>
      isSubscribe ? theme.colors.primary : theme.colors.gray4};
  }

  :hover {
    ${({ isSubscribe }) =>
      !isSubscribe &&
      css`
        background: ${({ theme }) => theme.colors.gray0};
        color: ${({ theme }) => theme.colors.gray6};
      `}

    svg {
      color: ${({ theme, isSubscribe }) =>
        isSubscribe ? theme.colors.primary : theme.colors.gray6};
    }
  }
`;
