export const CreateWorkspaceENUSTranslations = {
  step1: 'Basic information',
  step2: 'Select variables',
  step3: 'Configure variables',
  step4: 'Configure categorization',

  createWorkspaceHeadTitle: 'Create Workspace',
  editWorkspaceHeadTitle: 'Edit Workspace',
  createWorkspaceCreateTitle: 'Create your Workspace',
  editWorkspaceEditTitle: 'Edit your Workspace',
  createWorkspaceCreateDescription: 'Fill in the basic information.',
  createWorkspaceSelectVariablesTitle: 'Select the Variables',
  createWorkspaceSelectVariablesDescription:
    'Select projects or series groups added by you on the platform to compose your Workspace.',
  createWorkspaceConfigureVariablesTitle: 'Configure your Workspace',
  createWorkspaceConfigureVariablesDescription:
    'Define which variables will be part of your Workspace and configure templates and updates that will be included in your results.',
  createWorkspaceIcon: 'Icon',
  createWorkspaceIconTooltip:
    'This icon will help you easily identify your Workspace further on.',
  createWorkspaceIconModalTitle: 'Choose your icon',
  createWorkspaceIconModalDescription:
    'This icon will help you easily identify your Workspace further on.',
  createWorkspaceName: 'Name',
  createWorkspaceNamePlaceholder: 'Enter the Workspace name',
  createWorkspaceDescription: 'Description',
  createWorkspaceDescriptionPlaceholder:
    'Enter a description for your Workspace',
  createWorkspaceCreate: 'Create Workspace',
  createWorkspaceDescriptionErrorMaxCharacters:
    'Must not be more than 350 characters',
  createWorkspaceNoProjectSelectedTitle: "You haven't selected any project",
  createWorkspaceNoProjectSelectedDescription:
    'Click the button below and select one or more projects that you created previously.',
  createWorkspaceSelectProjectsButton: 'Select projects',
  createWorkspaceAllProjects: 'All projects',
  createWorkspaceAddNewProject: 'Add new project',
  createWorkspaceOriginalVariable: 'Original Name',
  createWorkspaceVariable: 'Variable',
  createWorkspaceProjectTag: 'Project / Tag',
  createWorkspaceMyProjectsLabel: 'My projects',
  createWorkspaceMySeriesLabel: 'My series',
  createWorkspaceRepeatedVariable:
    'This variable is duplicated in another project, please rename it',
  createWorkspaceVariableNameError:
    'Please check if there is any variable with invalid name.',
  createWorkspaceVariableModelIDError:
    'Please check if there is any variable with invalid Model.',
  createWorkspaceVariableSelectMoreThan1000Warning:
    'You have selected more than 1000 variables. To continue, you must deselect some of them.',
  createWorkspaceVariableSelectMoreThan1000:
    'You have exceeded the limit of 1000 variables per Workspace. Deselect some of them to continue.',
  createWorkspaceModelUpdate: 'Model Update',
  createWorkspaceModelId: 'Model',
  createWorkspaceModelIdRequiredField: 'Required field',
  createWorkspaceModelIdInvalid: 'Invalid Model ID',
  createWorkspaceModalErrorTitle: 'Something went wrong',
  createWorkspaceModalErrorDescription:
    'An error occurred while XXX your workspace.',
  createWorkspaceVariableCategorization: 'Variable Categorization',
  createWorkspaceVariableCategorizationDescription:
    'Categorize your variables into different levels.',
  createWorkspaceCreateVariableCategorization: 'Create categorization',
  createWorkspaceSaveVariableCategorization: 'Save changes',
  createWorkspaceConfirmCloseCategorizationTitle: 'Are you sure?',
  createWorkspaceConfirmCloseCategorizationDescription:
    'You will lose all unsaved changes',
  createWorkspaceEditVariableCategorization: 'Edit categorization',
  createWorkspaceVariableCategorizationDisabled:
    "It's necessary to have at least one variable selected and for all of them to have a valid name.",
  createWorkspaceSaveChangesError:
    'Check if there are any filter/option names with errors.',
  createWorkspaceShowOriginalVariableName: 'Original variable name',
  editWorkspaceSaveChanges: 'Save changes',
  createWorkspaceModalErrorDescriptionCreateText: 'creating',
  createWorkspaceModalErrorDescriptionSaveText: 'saving',
  createWorkspaceFilterOptionName: 'Enter the option name',
  editWorkspaceEditionExpired:
    'Your editing session has expired. Would you like to renew it for another 5 minutes?',
  editWorkspaceExitEdition: 'Exit editing',
  createWorkspaceAggregateResults: 'Enable aggregation',
  createWorkspaceAggregateInformation:
    'Enable to aggregate your series. The aggregation is done considering the configured categorization and considering the bottom-up methodology.',
  createWorkspaceInflateSerie: 'Inflate series?',
  createWorkspaceInflateSerieTooltip:
    'We recommend applying inflation only to price series. To inflate the series, the IPCA is applied to it, taking January 2018 as the base date.',
  createWorkspaceAISelection: 'AI selection',
  createWorkspaceUserSelection: 'User selection',
  createWorkspaceSelect: 'Select',
  createWorkspaceOthers: 'Others',
  createWorkspaceAnotherModels: 'Another model',
  createWorkspaceTypeModelCode: 'Type model code',
  createWorkspaceSelectAll: 'Select all',
  discardCategorizationTitle: 'Are you sure?',
  discardCategorizationDescription: 'All modifications made will be discarded.',
  createWorkspaceEnableMarketShare: 'Enable Market Share',
  createWorkspaceEnableMarketShareInformation:
    'Enable automatic calculation of Market Share for your monthly, bimonthly, quarterly, half-year or annual series.<br/><br/>By enabling this option, categorize the variables using the provided template and upload the file to the platform. Ensure that the types of your series are flagged as Market Size or Sell Out and that the category levels are correct.<br/><br/>The platform will relate your Market Size and Sell Out series by the levels included in the categorization to calculate Market Share. Enable data aggregation when the levels of the two types of data are not at the same categorical level.',
  createWorkspaceCreateCategorizationManually: 'Create manually',
  createWorkspaceCreateCategorizationUploadTitle: 'Upload your file',
  editCategorization: 'Edit categorization',
  templateDownload: 'Download the template',
  createWorkspaceTemplateVariable: 'Variable',
  createWorkspaceTemplateLevel: 'Level',
  createWorkspaceTemplateOthers: 'Others',
  createWorkspaceCancelCategorizationEdition: 'Cancel editing',
  categorizationRequirementsTitle: 'Requirements for categorization via file',
  categorizationRequirementsDescription:
    'Please ensure that your file meets the following requirements:',
  categorizationRequirements1:
    'Use the template and fill in the categorization of your series',
  categorizationRequirements2: 'Only .xlsx files are accepted',
  categorizationRequirements3:
    'All series selected in the previous step must be in the file, as well as the template',
  categorizationRequirements4: 'Up to 10 levels of categorization are allowed',
  categorizationRequirements5:
    'When categorizing a series, fill in the categorization from level 1 to the desired level',
  categorizationRequirements6:
    'Be sure not to leave a level blank in the middle of your categorization',
  categorizationRequirements7:
    'Select from the dropdown list in the "Type" column the corresponding selection for the series',
  categorizationRequirements8:
    'In the row of the columns identified as "Level", replace with the desired name. You can add up to 10 levels of categorization (e.g., Level 1 = Category, Level 2 = Region, etc..)',
  categorizationRequirements9: 'Do not use accents and special characters',
  categorizationRequirements10: 'Do not change the order of the columns',
  confirmMarketShareChangeTitle:
    'Are you sure you want to enable Market Share?',
  confirmMarketShareChangeDescription:
    'Your current categorization will be lost and you will have to upload a file with the new categorization, indicating the series that will be part of the calculation.',
  confirmMarketShareChangeYesButton: 'Yes, I want to enable Market Share.',
  creatingWorkspace: 'Creating Workspace',
  savingWorkspace: 'Saving variables configuration',
  savingHierarchies: 'Saving categorization',
  categorizationErrorWorkspaceCreation: 'Your workspace was created',
  categorizationErrorWorkspaceEdit:
    'Your variable configuration has been saved',
  categorizationErrorTile: 'Error while saving categorization',
  categorizationErrorGenericDescription:
    'XXX, but the following error occurred in the categorization:',
  categorizationErrorFilterDescription: 'Some ys have gaps in the hierarchy.',
  categorizationWarningTitle: 'Attention',
  categorizationWarningDescription:
    'You have already configured the categorization of your variables. If you rename any of the selected variables, all configurations will be lost. Proceed to the next step and export the categorization if necessary.',
  fileIsEmpty: 'File is empty.',
  invalidVariableColumn: 'Invalid Variable column.',
  invalidProjectColumn: 'Invalid Project column.',
  invalidVariableTypeColumn: 'Invalid Variable Type column.',
  invalidColumns: 'Invalid file content. Invalid columns.',
  aggregationDisabled:
    'Aggregation disable but multiple ys with same type in filter. Variables',
  missingFilter: 'Missing filter value in variables:',
  numberVariablesIsGreater:
    'One or more variables are present in your file, but they are not selected in the Workspace. The variables present in the file should match the variables selected in the Workspace.',
  numberVariablesIsSmaller:
    'One or more variables are selected in the Workspace, but they are not present in your file. The variables present in the file should match the variables selected in the Workspace.',
  withoutSellOut:
    'To calculate Market Share, the file must provide at least one Sell Out series.',
  withoutMarketSize:
    'To calculate Market Share, the file must provide at least one Market Size series.',
  withoutHierarchy:
    'To calculate Market Share, there must be at least one level of categorization in the upload file.',
  typeRequired: 'Type is required to all variables.',
  invalidTypes: 'Invalid types:',
  mandatoryVariables: 'Variable is mandatory to have hierarchy. Variable:',
  mandatoryHierarchy:
    "It's mandatory to have at least one level of hierarchy. Variables:",
  controlPanel: 'Control panel',
  proceedAnyway: 'Proceed anyway',
  workspaceConfigEnableApprovalFlow: 'Enable planning flow?',
  workspaceConfigEnableApprovalFlowInfoTooltip:
    'When activating the planning flow, only projects with a monthly time series frequency will be allowed.',

  workspaceConfigCategorization: 'Categorization',

  workspaceConfigAggregations: 'Aggregations',
  workspaceConfigAggregationsIndividualConfiguration: 'Configure by types',
  workspaceConfigAggregationsIndividualConfigurationDisabled:
    'Define the data types of your variables to be able to configure your aggregations individually.',
  workspaceConfigAggregationsLevelAggregation: 'Level aggregation',
  workspaceConfigAggregationsTemporalAggregation: 'Temporal aggregation',
  workspaceConfigAggregationsTemporalAggregationTooltip:
    'Temporal aggregation defines the rule for grouping your data at other frequencies. Example: Aggregation of monthly data into bimonthly data.',
  workspaceConfigAggregationsSum: 'Sum',
  workspaceConfigAggregationsAverage: 'Average',
  workspaceConfigAggregationsEndOfPeriod: 'End of period',
  workspaceConfigAggregationsNoAggregation: 'No aggregation',
  workspaceConfigAggregationsIsIndividualConfiguration:
    'Individually configured',
  workspaceConfigAggregationsTemporalAggregationAnnualTooltip:
    'This feature is not available for annual frequency Workspaces.',

  workspaceAggregationErrorGenericDescription:
    'XXX, but an error occurred in the aggregation.',
  workspaceAggregationConfigurationTitle: 'Configure the aggregations',
  workspaceAggregationConfigurationDescription:
    'For each value of the variable type, select the types of aggregation you want to use.',

  selectProjectWorkspaceIsDisabledDifferentFrequency:
    'You can only choose projects with the same time series frequency as those already selected.',
  selectSeriesWorkspaceIsDisabledDifferentFrequency:
    'You can only choose series with the same time series frequency as those already selected.',
  selectProjectWorkspaceIsDisabledDifferentFrequencyOfWorkspace:
    'You can only choose projects with the same time series frequency as your workspace.',
  selectSeriesWorkspaceIsDisabledDifferentFrequencyOfWorkspace:
    'You can only choose series with the same time series frequency as your workspace.',
  selectProjectWorkspaceIsDisabledApprovalFlow:
    'You have enabled the planning flow, so only projects with a monthly time series frequency will be allowed.',
  workspaceConfigCreate: 'Create',

  workspaceConfigAddDataType: 'Click to add more',
  workspaceConfigSelectCreateDataType: 'Select an option or create one',
  workspaceConfigDataTypeMinCharacters: 'Must be at least 4 characters',
  workspaceConfigDataTypeMaxCharacters: 'Must not be more than 50 characters',
  workspaceConfigDataTypeAlreadySelected: 'Option has already been selected',
  workspaceConfigDataTypeSpecialCharacters: 'No special characters allowed',
  workspaceConfigDataTypeCreationTitleError: 'Error adding the new data type',
  workspaceConfigDataTypeCreationDescriptionError:
    'An error occurred while adding the new data type. Please try again later.',
  workspaceConfigDataTypeExistsDescriptionError:
    'The data type you entered already exists. Please select it if you wish to use it.',

  createWorkspaceCreateYourProject: 'Create your project',
  createWorkspaceYouHaveNotCreatedProject:
    'You haven’t created any projects yet',
  createWorkspaceRedirectedUploadYourSeries:
    'to be redirected to upload your series.',
  createWorkspaceStartCreatingYourProject: 'to start creating your project',
};
