import styled from 'styled-components';

export const ContainerModal = styled.div`
  width: 50rem;

  @media only screen and (max-width: 800px) {
    width: auto;
  }
`;

export const ContentButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  > button:first-child {
    margin-right: 0.5rem;
  }
`;

export const ContainerTab = styled.div`
  padding: 1.5rem 1.5rem 0;
  /* height: 512px; */
  min-height: 512px;
  max-height: 512px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ContentLabel = styled.div`
  display: inline-block;
  margin-bottom: 1.5rem;
  > div {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    > h4 {
      font-style: normal;
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 2rem;
      color: ${({ theme }) => theme.colors.gray6};
    }
  }
  > p {
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray5};
  }
`;

export const DivButtons = styled.div`
  margin-top: 2.25rem;
  width: 50rem;
  margin-left: -1.5rem;
  border-top: 1px solid ${({ theme }) => theme.colors.gray2};
  background: ${({ theme }) => theme.colors.gray0};
  padding: 1.5rem 1.5rem;
  display: flex;
  justify-content: flex-end;
  border-radius: 0 0 8px 8px;
  gap: 2rem;
`;

export const ContainerToggle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DivGroup = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: 1.5rem;
  > div {
    flex: 1;

    .columnWidth {
      width: 9.375rem !important;
    }

    .css-yk16xz-control {
      /* width: 26.938rem; */
      max-height: 12rem;
      overflow-y: auto;
    }
    .css-1pahdxg-control {
      /* width: 26.938rem; */
      max-height: 12rem;
      overflow-y: auto;
    }

    .goldenVariables {
      width: 26.938rem;
    }
  }
`;

export const ErrorMethodsFlag = styled.h4`
  color: ${({ theme }) => theme.colors.red2};
  font-size: 0.938rem;
  margin-bottom: 1.5rem;
  font-weight: 400;
`;

export const ContentApplyLogAndAddDummies = styled.div`
  display: flex;
  gap: 2rem;

  margin-top: 1.5rem;

  > div {
    min-width: 12.5rem;
  }
`;
