import React, { useContext } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { FeatureStoreSidebarContext } from 'src/feature-store/Contexts/NavigationContext';

import { Tab } from './styles';
import { ParamProps, TabProps } from './types';

export const Tabs: React.FC<TabProps> = ({ text, index }) => {
  const navigate = useNavigate();
  const { tab } = useParams<ParamProps>();

  const { idFavoriteViewingPage } = useContext(FeatureStoreSidebarContext);

  return (
    <Tab
      isActive={index === tab}
      onClick={() =>
        navigate(
          `/feature-store/favorites/${idFavoriteViewingPage}/data/${index}`,
        )
      }
      data-testid={`tab-${text
        ?.replaceAll(' ', '-')
        .toLocaleLowerCase()}-${index}`}
    >
      {text}
    </Tab>
  );
};
