const layoutENTranslations = {
  layoutTextCookie:
    'We use cookies to provide a better experience, enhance performance and analyze how you interact with our website. By clicking the button on the side, you consent to cookies and have agreed to the',
  layoutTextRead: 'Also, read the',
  layoutTextPrivacyPolicy: 'Privacy Policy',
  layoutTextTerms: 'Terms of Use',
  layoutTextAcceptCookie: 'Accept cookies',
};

export default layoutENTranslations;
