const predictionsPTBRTranslations = {
  getPredictionsTitle: 'Obter Previsões',
  predictionsTitle: 'Previsões',
  predictionsDescription:
    'Traga novas observações de seus dados para obter previsões para sua variável de interesse.',
  predictUploadTitle: 'Faça upload do seu conjunto de dados',
  predictUploadLabel: 'Arquivo de dados',
  predictUploadButton: 'Enviar',
  predictUploadDescription:
    'Arraste e solte seu arquivo aqui ou clique para selecionar manualmente. <b>Você precisa trazer</br>as mesmas variáveis utilizadas no processo de modelagem.</b>',
  predictChangeDataset: 'Alterar arquivo de dados',
  predictProbability: 'Probabilidade',
  predictErrorMessage: 'Por favor tente novamente depois de alguns minutos.',
  predictDownload: 'Baixar',
  predictionsResultDescription:
    'Acesse as previsões e as probabilidades associadas para seus novos pontos de dados. Previsões utilizando o modelo "X".',
  predictMissingColumnWarning: 'Faltam colunas obrigatórias para previsão.',
  predictExtraColumnWarning:
    'Algumas colunas não estão afetando a previsão por não estarem no modelo treinado.',
  predictColumnSingular: 'Coluna',
  predictColumnPlural: 'Colunas',
  predictWarningMeaningDefinition: 'a mais',
  predictFileSizeErrorTitle: 'Algo deu errado',
  predictFileSizeErrorDescription:
    'Seu dataset precisa ter um tamanho igual ou menor a "X" para realizar a predição.',
  previewingFirst: 'Exibindo as primeiras',
  predictFileExtensionErrorDescription: `Apenas extensões '.xlsx' e '.csv' estão disponíveis.`,
  predictAPIModalTitle: 'Chamar Predict por API',
  predictAPIModalDescription:
    'Conecte as previsões em seus sistemas com nossa API',
  predictAPIModalSeeDocumentation: 'Veja a Documentação',
  predictAPIModalProjectID: 'ID DO PROJETO',
  predictSeeDocumentation: 'Veja a documentação',
};

export default predictionsPTBRTranslations;
