const step1ClaaSPTBRTranslations = {
  startProjectClaas:
    'Inicie nomeando seu projeto e carregando um arquivo de dados.',
  claasUploadDatasetInfo: `Apenas extensões '.xlsx' e '.csv' estão disponíveis.`,
  claasUploadDatasetDescription:
    'Arraste e solte seu arquivo aqui ou clique para selecionar manualmente.',
  claasUploadErrorDescription:
    'Não foi possível realizar o upload. Por favor, tente novamente.',
  claasUploadFileSizeTitle: 'Algo deu errado',
  claasUploadFileSizeDescription:
    'Seu dataset precisa ter um tamanho igual ou menor a "X" para criar seu projeto.',
  claasCallAPIButton: 'Criar projeto via API',
  claasAPIModalTitle: 'Criar projeto via API',
  claasAPIModalDescription:
    'Use nossa API para integrar seu sistema ou treinar modelos com datasets maiores.',
  claasAPIModalSeeDocs: 'Ver documentação',
};

export default step1ClaaSPTBRTranslations;
