import React from 'react';

import { Container } from './styles';
import { RadioButtonProps } from './types';

export const RadioButton: React.FC<RadioButtonProps> = ({
  label,
  disabled = false,
  dataCy,
  dataTip,
  ...rest
}) => (
  <Container
    disabled={disabled}
    data-tooltip-id="radio-button-tooltip"
    data-tooltip-html={dataTip}
    data-testid={`radio-${label
      .replaceAll(' ', '-')
      .toLocaleLowerCase()}-container`}
  >
    <p>{label}</p>

    <input
      type="radio"
      data-cy={
        dataCy ?? `radio-${label.replaceAll(' ', '-').toLocaleLowerCase()}`
      }
      data-testid={
        dataCy ?? `radio-${label.replaceAll(' ', '-').toLocaleLowerCase()}`
      }
      disabled={disabled}
      {...rest}
    />

    <span />
  </Container>
);
