import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  padding: 2rem 2rem 1.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    margin-bottom: 0.75rem;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.gray6};

    text-align: center;

    margin-bottom: 0.75rem;
  }

  > p {
    font-size: 1rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gray5};

    text-align: center;

    margin-bottom: 1.5rem;
  }

  > div {
    width: 100%;
  }

  textarea {
    width: 100%;
    height: 5rem;

    resize: none;
  }
`;

export const Footer = styled.div`
  padding: 1.5rem;
  background: ${({ theme }) => theme.colors.gray0};

  border-top: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 0 0 8px 8px;

  display: flex;
  justify-content: flex-end;
  gap: 2rem;
`;
