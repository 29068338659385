import React from 'react';

import parseHTML from 'html-react-parser';
import { ArrowRight, CheckCircle, DownloadSimple } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';

import {
  ContainerSeeRequirements,
  ContentBody,
  ContentFooter,
  ContentHeader,
  ContentHeaderAndBody,
} from './styles';

interface PropsModalSeeRequirements {
  setVisible: (value: boolean) => void;
}

export const ModalSeeRequirementsTimeSeries: React.FC<
  PropsModalSeeRequirements
> = ({ setVisible }) => {
  const { language } = useSelector((state: RootState) => state.auth.user);
  const { t: translate } = useTranslation();

  const link =
    language === 'pt-br'
      ? 'https://storage.googleapis.com/bkt-prod-4casthub/datasets/dataset-pt.xlsx'
      : 'https://storage.googleapis.com/bkt-prod-4casthub/datasets/dataset-en.xlsx';

  return (
    <Modal visible setVisible={setVisible} style={{ padding: 0 }}>
      <ContainerSeeRequirements>
        <ContentHeaderAndBody>
          <ContentHeader>
            <h4>{translate('seeRequirementsTimeSeriesTitle')}</h4>
            <p>{translate('seeRequirementsTimeSeriesDescription')}</p>
          </ContentHeader>
          <ContentBody>
            <div>
              <div>
                <CheckCircle size="1rem" weight="fill" />
              </div>
              <p>{parseHTML(translate('seeRequirementsTimeSeriesFirstRow'))}</p>
            </div>
            <div>
              <div>
                <CheckCircle size="1rem" weight="fill" />
              </div>
              <p>{parseHTML(translate('seeRequirementsTimeSeriesFileHas'))}</p>
            </div>
            <div>
              <div>
                <CheckCircle size="1rem" weight="fill" />
              </div>
              <b>{translate('seeRequirementsTimeSeriesDateColumn')}</b>
            </div>
            <div style={{ marginLeft: '1.5rem' }}>
              <div>
                <ArrowRight size="1rem" />
              </div>
              <p>{translate('seeRequirementsTimeSeriesItMustHave')}</p>
            </div>
            <div style={{ marginLeft: '1.5rem' }}>
              <div>
                <ArrowRight size="1rem" />
              </div>
              <p>{translate('seeRequirementsTimeSeriesWeAccept')}</p>
            </div>
            <div style={{ marginLeft: '1.5rem' }}>
              <div>
                <ArrowRight size="1rem" />
              </div>
              <p>
                {translate('seeRequirementsTimeSeriesDependingOnTheFrequency')}
              </p>
            </div>
            <table>
              <thead>
                <tr>
                  <th>{translate('seeRequirementsTimeSeriesDaily')}</th>
                  <th>{translate('seeRequirementsTimeSeriesWeekly')}</th>
                  <th>{translate('seeRequirementsTimeSeriesFortnightly')}</th>
                  <th>{translate('seeRequirementsTimeSeriesMonthly')}</th>
                  <th>{translate('seeRequirementsTimeSeriesBimonthly')}</th>
                  <th>{translate('seeRequirementsTimeSeriesQuarterly')}</th>
                  <th>{translate('seeRequirementsTimeSeriesHalfYear')}</th>
                  <th>{translate('seeRequirementsTimeSeriesAnnual')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>180</td>
                  <td>52</td>
                  <td>24</td>
                  <td>36</td>
                  <td>24</td>
                  <td>24</td>
                  <td>24</td>
                  <td>12</td>
                </tr>
              </tbody>
            </table>
            <div>
              <div>
                <CheckCircle size="1rem" weight="fill" />
              </div>
              <p>
                {parseHTML(
                  translate('seeRequirementsTimeSeriesOptionalExtraColumns'),
                )}
              </p>
            </div>
          </ContentBody>
        </ContentHeaderAndBody>
        <ContentFooter>
          <a href={link} data-cy="download-example">
            <DownloadSimple size="1.125rem" />
            {translate('seeRequirementsTimeSeriesOptionalDownloadExample')}
          </a>
          <Button
            buttonType="primary"
            onClick={() => setVisible(false)}
            data-cy="button-ok"
            data-testid="button-ok"
          >
            Ok
          </Button>
        </ContentFooter>
      </ContainerSeeRequirements>
    </Modal>
  );
};
