import React from 'react';

import { ChartTypeButtonProps } from './types';
import { Container } from './styles';

export const ChartTypeButton: React.FC<ChartTypeButtonProps> = ({
  icon,
  active,
  ...props
}) => (
  <Container active={active} {...props}>
    {icon}
  </Container>
);
