const VariableImpactsENUSTranslations = {
  variableImpactsTitle: 'Variable Impacts',
  variableImpactsDescription: `Shows how changes in a variable’s value impacts the model output.`,
  variableImpactsErrorMessage: 'There is no such metric for this model.',
  variableImpactsSelectLabel: 'Positive Class',

  variableImpactsVarIncreaseTargetIncreaseTooltipMessage:
    'When the value of the variable  <b>"VARIABLE"</b> increase the probability of <b>"TARGET"</b> being <b>"CLASS"</b> increases.<br><b>Value</b>: "VALUE".',
  variableImpactsVarIncreaseTargetDecreaseTooltipMessage:
    'When the value of the variable  <b>"VARIABLE"</b> increases the probability of <b>"TARGET"</b> being <b>"CLASS"</b> decreases.<br><b>Value</b>: "VALUE".',
  variableImpactsVarDecreaseTargetDecreaseTooltipMessage:
    'When the value of the variable  <b>"VARIABLE"</b> decreases the probability of <b>"TARGET"</b> being <b>"CLASS"</b> decreases.<br><b>Value</b>: "VALUE".',
  variableImpactsVarDecreaseTargetIncreaseTooltipMessage:
    'When the value of the variable  <b>"VARIABLE"</b> decreases the probability of <b>"TARGET"</b> being <b>"CLASS"</b> increases.<br><b>Value</b>: "VALUE".',
  variableImpactsVarSameValue:
    'The relationship between the values of <b>"VARIABLE"</b> and the probabilities of <b>"CLASS"</b> may not be continuous.<br><b>Value</b>: "VALUE".',
  variableImpactsIncrease: 'Increases the likelihood that "TARGET" is "CLASS"',
  variableImpactsDecrease: 'Decreases the likelihood that "TARGET" is "CLASS"',
};

export default VariableImpactsENUSTranslations;
