// Get the Projection Horizon Inputs (n_steps and n_windows)

export function getCheckedNWindowsValue(
  value: number,
  frequency: string,
): number {
  switch (frequency) {
    case 'Daily':
      if (value > 365) {
        return 365;
      }
      return value;

    case 'Weekly':
      if (value > 52) {
        return 52;
      }
      return value;

    case 'Fortnightly':
      if (value > 26) {
        return 26;
      }
      return value;

    case 'Monthly':
      if (value >= 36) {
        return 36;
      }
      if (value >= 24) {
        return 24;
      }
      if (value >= 12) {
        return 12;
      }
      return value;

    case 'Bimonthly':
      if (value >= 18) {
        return 18;
      }
      if (value >= 12) {
        return 12;
      }
      if (value >= 6) {
        return 6;
      }
      return value;

    case 'Quarterly':
      if (value >= 12) {
        return 12;
      }
      if (value >= 8) {
        return 8;
      }
      if (value >= 4) {
        return 4;
      }
      return value;

    case 'Half-year':
      return value;

    case 'Annual':
      return value;

    default:
      return value;
  }
}

export function getProjectionHorizonInputs(
  nrowsTraining: number,
  freqNum: number,
  frequency: string,
): number[] {
  // Default: 30%
  // n_steps + n_windows - 1 = _nrowsTraining * 0.3

  // Regra 1: Se 30 % de 'nrowsTraining' >= 'freqNum'
  // - n_steps = freqNum
  // - n_windows = restante dos 30 %

  // Regra 2: Se 30 % de 'nrowsTraining' < 'freqNum'
  // - n_windows = 1
  // - n_steps = restante dos 30 %
  // Por exemplo, se 30 % da base é 9, para uma frequencia de 12, n_steps = 9 e n_windows = 1.

  let defaultNSteps;
  let defaultNWwindows;

  if (nrowsTraining * 0.3 >= freqNum) {
    // 30 % do dataset de treino é maior do que o período cheio da frequência

    defaultNSteps = freqNum;

    defaultNWwindows = nrowsTraining * 0.3 - defaultNSteps + 1;
    defaultNWwindows = Math.trunc(defaultNWwindows);

    // Para frequências mensais, verificar o valor calculado para n_windows
    // Se o calculo for maior do que 12 modificar e fixar em 12
    if (freqNum === 12) {
      if (defaultNWwindows > freqNum) defaultNWwindows = 12;
    }

    // Valor mínimo de n_windows é 1
    if (defaultNWwindows < 1) defaultNWwindows = 1;
  } else {
    // Se 30 % do tamanho da base de treino for menor que a frequência dos dados

    defaultNWwindows = 1; // fixa em 1

    defaultNSteps = nrowsTraining * 0.3 + 1 - defaultNWwindows;
    defaultNSteps = Math.trunc(defaultNSteps);

    // Valor mínimo de n_steps é 1
    if (defaultNSteps < 1) defaultNSteps = 1;
  }

  // Mínimo: 1 para n_steps e n_windows
  // Máximo: 50 % do dataset de treino
  // n_steps + n_windows - 1 <= nrows_training * 0.5

  // Máximo de n_steps é quando n_windows = 1
  // Obs.: máximo do n_steps é sempre fixo

  const fixMinNWindows = 1;

  let maxNSteps = nrowsTraining * 0.5 + 1 - fixMinNWindows;

  maxNSteps = Math.trunc(maxNSteps);

  // Máximo do n_windows para o n_steps default
  // Obs.: máximo do n_windows é reativo ao input do n_steps

  let maxNWindows = 0.5 * nrowsTraining + 1 - defaultNSteps;

  maxNWindows = Math.trunc(maxNWindows);

  defaultNWwindows = getCheckedNWindowsValue(defaultNWwindows, frequency);

  return [defaultNSteps, defaultNWwindows, maxNSteps, maxNWindows];
}

// Get the best value of n_windows
// Shows a message with the highest recommended value of n_windows when a value >= 30% is chosen
export function getNWindowsRecommendation(
  nrowsTraining: number,
  nSteps: number,
  frequency: string,
): number {
  // Valor recomendado para n_windows
  let bestNWindows = 0.3 * nrowsTraining + 1 - nSteps;

  bestNWindows = Math.trunc(bestNWindows);

  // Valor mínimo de n_windows é 1
  // Força ser 1 caso a conta dê um valor abaixo
  if (bestNWindows < 1) bestNWindows = 1;

  bestNWindows = getCheckedNWindowsValue(bestNWindows, frequency);

  return bestNWindows;
}

// Get the maximun value of n_windows
// The maximum value of n_windows is 50% of the sample, even if this value is not recommended
export function updateMaxNWindows(
  nrowsTraining: number,
  nSteps: number,
): number {
  // Valor recomendado para n_windows
  let maxNWindows = 0.5 * nrowsTraining + 1 - nSteps;

  maxNWindows = Math.trunc(maxNWindows);

  if (maxNWindows < 1) {
    maxNWindows = 1;
  }

  return maxNWindows;
}

export function getForecastWarning(
  nrowsTraining: number,
  nSteps: number,
  nWindows: number,
): boolean {
  const accumulator = nSteps + nWindows - 1;
  let warning = false;

  if (accumulator > nrowsTraining * 0.3) {
    warning = true;
  }

  return warning;
}
