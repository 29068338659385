import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  padding: 0.5rem;
  align-items: center;
  gap: 1rem;
  align-self: stretch;

  border-bottom: 1px solid ${({ theme }) => theme.colors.gray1};
  background: #${({ theme }) => theme.colors.white};

  cursor: pointer;
`;

const typeIndicator = {
  controls: 'rgba(66, 153, 225, 0.12)',
  'economic activity': 'rgba(72, 187, 120, 0.12)',
  credit: 'rgba(245, 101, 101, 0.12)',
  'climatic data': 'rgba(236, 201, 75, 0.12)',
  'public finance': 'rgba(159, 122, 234, 0.12)',
  'capital and financial markets': 'rgba(56, 178, 172, 0.12)',
  'external sector': 'rgba(11, 197, 234, 0.12)',
  'environmental data': 'rgba(237, 137, 54, 0.12)',
};

type ColorIndicator = {
  typeIndicator: keyof typeof typeIndicator | string;
};

export const Icon = styled.div<ColorIndicator>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 28px;
  min-width: 28px;
  height: 28px;

  padding: 16px;
  gap: 0.625rem;

  border-radius: 8px;

  background: ${(props) =>
    Object.keys(typeIndicator).includes(props.typeIndicator)
      ? typeIndicator[props.typeIndicator as keyof typeof typeIndicator]
      : '#EDF2F7'};

  img {
    width: 1.25rem;
    height: 1.25rem;
    flex-shrink: 0;
  }
`;

export const SerieName = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  flex: 1 0 0;

  overflow: hidden;
  color: ${({ theme }) => theme.colors.gray7};
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

type ContentProp = {
  isVisible: boolean;
};

export const ToggleInfoContent = styled.button<ContentProp>`
  cursor: pointer;

  svg {
    width: 1.25rem;
    height: 1.25rem;

    color: ${({ theme }) => theme.colors.gray3};

    transition: all 0.2s;

    transform: ${({ isVisible }) => (isVisible ? 'scaleY(-1)' : 'scaleY(1)')};

    &:hover {
      color: ${({ theme }) => theme.colors.gray5};
    }
  }
`;

export const InfoContainer = styled.div<ContentProp>`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;

  transition: all 0.5s ease-in;
  max-height: ${({ isVisible }) => (isVisible ? `400px` : '0px')};
  overflow-y: hidden;
  overflow-x: auto;
`;

export const InfoContent = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;

  gap: 1rem;
`;

export const SeriesInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;

  padding: 1rem;

  min-width: 15.75rem;
  max-width: 15.75rem;
`;

export const ChartContainer = styled.div`
  width: calc(100% - 16.75rem);

  margin-top: 0.5rem;

  h4 {
    color: ${({ theme }) => theme.colors.gray6};
    font-family: Inter;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  strong {
    margin-top: -1.25rem;
  }

  .container-resource-paid-warning {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const AtributeSerieTitle = styled.span`
  color: ${({ theme }) => theme.colors.gray5};

  font-family: Inter;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
`;
export const AtributeSerieValue = styled.span`
  color: ${({ theme }) => theme.colors.gray6};

  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  gap: 0.5rem;

  margin-left: 1rem;
`;

export const Source = styled.span`
  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.colors.gray6};

  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.375rem;

  gap: 0.5rem;

  svg {
    width: 0.875rem;
    height: 0.875rem;

    color: ${({ theme }) => theme.colors.gray4};
  }
`;

export const NavigateToFavPage = styled.a`
  display: flex;
  padding: 0.5rem 0.75rem;
  align-items: center;
  gap: 0.5rem;

  cursor: pointer;

  border-radius: 1.5rem;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  box-sizing: border-box;

  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.gray5};

  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;

  &:hover {
    color: ${({ theme }) => theme.colors.gray6};

    svg {
      color: ${({ theme }) => theme.colors.gray6};
    }
  }

  svg {
    width: 1rem;
    height: 1rem;

    color: ${({ theme }) => theme.colors.gray4};
  }
`;
