import React, { useState } from 'react';

import { Trash, Warning } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';
import api from 'src/feature-store/service/api';
import { queryClient } from 'src/service/queryClient';
import { ModalFooter } from 'src/components/Modal/Footer/styles';

import { Container } from './styles';

interface DeleteModalProps {
  serieCode: string;
  visible: boolean;
  setVisible: (value: boolean) => void;
  setError: (value: boolean) => void;
}

type ParamsProps = {
  id: string;
};

export const DeleteSeriesModal: React.FC<DeleteModalProps> = ({
  serieCode,
  visible,
  setVisible,
  setError,
}) => {
  const { id } = useParams<ParamsProps>();

  const [loadingDeleteSerie, setLoadingDeleteSerie] = useState<boolean>();

  const { t: translate } = useTranslation();

  async function handleDeleteSeriesFromGroup() {
    try {
      queryClient.removeQueries('group series');
      queryClient.removeQueries(['group', id]);
      queryClient.removeQueries(['groups summary', id]);

      setLoadingDeleteSerie(true);
      const { data } = await api.delete(`/groups/${id}/series/${serieCode}`);
      if (data) {
        setVisible(false);
        queryClient.refetchQueries(['list series on favorite', id, 1]);
      }
    } catch {
      setError(true);
      setVisible(false);
    }

    setLoadingDeleteSerie(false);
  }

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <Container>
        <Warning size="2.5rem" />
        <h2>{translate('preDefinedDeleteTitle')}</h2>
        <p>{translate('preDefinedDeleteDescription')}</p>
      </Container>
      <ModalFooter>
        <Button
          buttonType="naked"
          onClick={() => setVisible(false)}
          data-testid="button-cancel-delete"
          data-cy="button-cancel-delete"
          disabled={loadingDeleteSerie}
        >
          {translate('cancel')}
        </Button>
        <Button
          buttonType="primary"
          icon={<Trash size="1.125rem" />}
          onClick={handleDeleteSeriesFromGroup}
          data-testid="button-delete-series"
          data-cy="button-delete-series"
        >
          {translate('delete')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
