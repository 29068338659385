const modellingStartPTBRTranslations = {
  modellingStartTitle: 'Que tipo de problema vamos resolver?',
  modellingStartSubTitle:
    'Indique se estamos lidando com um problema de séries de tempo ou de classificação.',
  newTimeSeriesTitle: 'Série temporal',
  newTimeSeriesSubTitle:
    'Projete a evolução de suas vendas, construa diferentes cenários macroeconômicos ou modele qualquer série para a qual o movimento ao longo do tempo seja relevante. ',
  newClassificationTitle: 'Classificação',
  newClassificationSubTitle:
    'Preveja o comportamento de seus clientes, estime os riscos de sua carteira de crédito ou construa modelos preditivos para qualquer fenômeno que assuma valores categóricos.',
  modellingStartButtonText: 'Inicie o seu projeto',
};

export default modellingStartPTBRTranslations;
