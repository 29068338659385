export const workspaceProjectionsPotencialImpactsPTTranslate = {
  workspaceProjectionsPotencialImpactsTitle: 'Impactos Potenciais',
  workspaceProjectionsPotencialImpactsDescription:
    'Estimativas dos impactos associados aos condicionantes sobre a variável de interesse',
  workspaceProjectionsPotencialImpactsSeeMore: 'Veja mais impactos',
  workspaceProjectionsPotencialImpactsLessMore: 'Ver menos impactos',
  workspaceProjectionsPotencialImpactsUnderstandingImpact:
    'Compreendendo o Impacto',
  workspaceProjectionsImpactsError0Variables:
    'Nenhuma variável explicativa a ser apresentada.',
  workspaceProjectionsImpactsTooltipInfo:
    'Se não foi aplicado logaritmo na variável dependente, impactos são inflacionados de acordo com valor do IPCA no último mês realizado (normalizado para 01-2018) ',
};
