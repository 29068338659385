export const favoriteStatusENUSTranslate = {
  favoriteStatusAddSerie: 'Add a serie',
  favoriteStatusSaveSerie: 'Save your series here',
  favoriteStatusEmptyTitle: 'Your group is still empty',
  favoriteStatusNonexistentTitle: "You don't have any favorites",
  favoriteStatusEmptyMessage:
    'Browse the platform and discover new series to save here.',
  favoriteStatusNonexistentMessage:
    'Click on the button and create a group to save your favorite series',
  favoriteStatusButtonCreateFavorite: 'Create new group',
  favoriteStatusButtonAddSerie: 'Search Indicators',
};
