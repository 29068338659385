import styled from 'styled-components';

export const ContainerModal = styled.div`
  max-width: 51.875rem;
  max-height: 37.5rem;
  padding: 2.375rem 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 0.5rem;

  > h1 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.gray6};
  }

  > p {
    color: ${({ theme }) => theme.colors.gray5};
    font-weight: 500;
    word-break: normal;
  }
`;

export const ContentButtons = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.gray2};
  background: ${({ theme }) => theme.colors.gray0};
  width: calc(100% + 4rem);
  height: 5.5rem;
  padding: 1.5rem 1.5rem;
  border-radius: 0 0 8px 8px;
  margin-top: 3rem;

  display: flex;
  justify-content: flex-end;

  button {
    display: flex;
    justify-content: center;
  }
`;
