export const formatOptions = [
  {
    value: 'dd-MM-yyyy',
    label: 'dd-MM-yyyy',
  },
  {
    value: 'dd/MM/yyyy',
    label: 'dd/MM/yyyy',
  },
  {
    value: 'MM/dd/yyyy',
    label: 'MM/dd/yyyy',
  },
  {
    value: 'MM/dd/yy',
    label: 'MM/dd/yy',
  },
  {
    value: 'dd-MM-yy',
    label: 'dd-MM-yy',
  },
  {
    value: 'dd/MM/yy',
    label: 'dd/MM/yy',
  },
  {
    value: 'd-MM-yyyy',
    label: 'd-MM-yyyy',
  },
  {
    value: 'MM-dd-yyyy',
    label: 'MM-dd-yyyy',
  },
  {
    value: 'MM-dd-yy',
    label: 'MM-dd-yy',
  },
  {
    value: 'yyyy-MM-dd',
    label: 'yyyy-MM-dd',
  },
  {
    value: 'yyyy-dd-MM',
    label: 'yyyy-dd-MM',
  },
  {
    value: 'yyyy/MM/dd',
    label: 'yyyy/MM/dd',
  },
  {
    value: 'yyyy/dd/MM',
    label: 'yyyy/dd/MM',
  },
  {
    value: 'yy/MM/dd',
    label: 'yy/MM/dd',
  },
  {
    value: 'yyyy-MM-d',
    label: 'yyyy-MM-d',
  },
  {
    value: 'yyyy/MM/d',
    label: 'yyyy/MM/d',
  },
  {
    value: 'yyyy-d-MM',
    label: 'yyyy-d-MM',
  },
  {
    value: 'yyyy/d/MM',
    label: 'yyyy/d/MM',
  },
];
