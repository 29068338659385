export const IASelectionPotencialImpactsPTTranslate = {
  IASelectionPotencialImpactsTitle: 'Impactos Potenciais',
  IASelectionPotencialImpactsDescription:
    'Estimativas dos impactos associados aos condicionantes sobre a variável de interesse',
  IASelectionPotencialImpactsSeeMore: 'Veja mais impactos',
  IASelectionPotencialImpactsLessMore: 'Ver menos impactos',
  IASelectionPotencialImpactsUnderstandingImpact: 'Compreendendo o Impacto',
  IASelectionImpactsError0Variables:
    'Nenhuma variável explicativa a ser apresentada.',
};
