import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  background-color: ${({ theme }) => theme.colors.white};
  width: 32.5rem;
  min-height: 27rem;
  border-radius: 8px 8px 0 0;

  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
    color: ${({ theme }) => theme.colors.gray6};
  }

  > p {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray5};
    margin: 0.5rem 0 1.5rem;
  }
`;

export const InputsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  /* overflow-y: auto; */

  .css-4ljt47-MenuList,
  .css-26l3qy-menu {
    z-index: 3000;
  }

  .css-yk16xz-control {
    /* width: 431px; */
    max-height: 10rem;
    overflow-y: auto;
  }
  .css-1pahdxg-control {
    /* width: 431px; */
    max-height: 10rem;
    overflow-y: auto;
  }

  .css-1n7v3ny-option:first-child,
  .css-yt9ioa-option:first-child {
    font-weight: 430;
  }
`;
