import { Combobox } from '@headlessui/react';
import styled from 'styled-components';

export const AutoCompleteComboBox = styled(Combobox)`
  position: relative;
`;

export const AutoCompleteButton = styled(Combobox.Button)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;

  display: flex;
  align-items: center;
  padding-right: 8px;
`;

export const AutoCompleteOptions = styled(Combobox.Options)`
  position: absolute;
  left: 0px;
  right: 0px;

  margin-top: 8px;
  max-height: 300px;
  overflow: auto;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 1001;
  padding: 4px 0;

  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 11px rgba(0, 0, 0, 0.1);
`;

export const AutoCompleteContentOption = styled.div`
  position: relative;
`;

interface AutoCompleteOptionProps {
  hasButtonDelete: boolean;
}

export const AutoCompleteOption = styled.div<AutoCompleteOptionProps>`
  padding: ${({ hasButtonDelete }) =>
    `12px ${hasButtonDelete ? '28px' : '16px'} 12px 16px`};

  width: 100%;

  transition: background 0.2s ease-in;

  &[data-headlessui-state='active'] {
    background: ${({ theme }) => theme.colors.gray1};
  }

  cursor: pointer;
  user-select: none;

  span {
    color: ${({ theme }) => theme.colors.gray5};

    display: block;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    font-size: 14px;
  }
`;

export const AutoCompleteButtonDelete = styled.button`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);

  color: ${({ theme }) => theme.colors.gray5};

  transition: color 0.2s ease-in;

  &:hover {
    color: ${({ theme }) => theme.colors.gray6};
  }
`;
