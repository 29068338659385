import React from 'react';

import { Info, XCircle } from 'phosphor-react';
import { Tooltip } from 'react-tooltip';

import {
  ContainerLabel,
  ContainerTextArea,
  TextArea as TextAreaStyle,
  ContentTextArea,
  Container,
} from './styles';
import { Label } from '../Label';
import { TextAreaProps } from './types';
import { ContainerSkeleton } from '../ContainerSkeleton';

export const TextArea: React.FC<TextAreaProps> = ({
  icon,
  label,
  information,
  style,
  testid,
  error,
  placeholder,
  tooltipError,
  loading = false,
  ...rest
}) => {
  if (label) {
    return (
      <Container style={style}>
        <ContainerLabel>
          <Label>{label}</Label>
          {information && (
            <>
              <Info
                size="1.125rem"
                data-tooltip-id="textarea-tooltip"
                data-tooltip-html={information}
                data-cy={`textarea-information-${label?.replaceAll(' ', '-')}`}
                data-testid="textareaInfo"
              />
              <Tooltip id="textarea-tooltip" className="customTooltipTheme" />
            </>
          )}
        </ContainerLabel>
        <ContainerTextArea
          error={!!error}
          disabled={rest.disabled}
          style={{ marginBottom: error ? '1.5rem' : 0 }}
        >
          {loading ? (
            <ContainerSkeleton
              withLoading={false}
              style={{ borderRadius: '7px' }}
            />
          ) : (
            <>
              <ContentTextArea>
                {icon && icon}
                <TextAreaStyle
                  data-testid={testid}
                  icon={!!icon}
                  placeholder={placeholder}
                  data-cy={
                    label?.replaceAll(' ', '-') ??
                    placeholder?.toString().replaceAll(' ', '-') ??
                    undefined
                  }
                  {...rest}
                />
                {error && (
                  <XCircle
                    size="1rem"
                    data-testid="icon_textarea_error_validation"
                    className="textarea-icon-error"
                  />
                )}
              </ContentTextArea>
              <p
                data-testid={`textarea-${
                  label?.replaceAll(' ', '-').toLocaleLowerCase() ??
                  placeholder
                    ?.toString()
                    .replaceAll(' ', '-')
                    .toLocaleLowerCase() ??
                  undefined
                }-error`}
              >
                {error}
              </p>
            </>
          )}
        </ContainerTextArea>
      </Container>
    );
  }

  return (
    <ContainerTextArea error={!!error} disabled={rest.disabled} style={style}>
      <Tooltip id="textarea-tooltip-error" className="customTooltipTheme" />
      {loading ? (
        <ContainerSkeleton
          withLoading={false}
          style={{ borderRadius: '7px' }}
        />
      ) : (
        <>
          <ContentTextArea>
            {icon && icon}
            <TextAreaStyle
              data-testid={testid}
              placeholder={placeholder}
              icon={!!icon}
              data-cy={
                placeholder?.toString().replaceAll(' ', '-') ?? undefined
              }
              {...rest}
            />
            {error && (
              <XCircle
                size="1rem"
                data-testid="icon_textarea_error_validation"
                data-tooltip-id="textarea-tooltip-error"
                data-tooltip-html={tooltipError}
                className="textarea-icon-error"
              />
            )}
          </ContentTextArea>
          <p data-testid="textarea-error">{error}</p>
        </>
      )}
    </ContainerTextArea>
  );
};
