import React, { useCallback, useEffect, useState } from 'react';

import { SliderComponent as Slider } from 'src/components/Slider';

import { SimulationsSliderHeader } from './styles';

type SimulationsSliderProps = {
  name: string;
  value: number | undefined;
  defaultValue: number;
  isDisabled: boolean;
  sliderRange: number;
  onChange: (name: string, value: number) => void;
};

export const SimulationsSlider: React.FC<SimulationsSliderProps> = ({
  name,
  value,
  defaultValue,
  isDisabled,
  sliderRange,
  onChange,
}) => {
  const [inputValue, setInputValue] = useState(value?.toFixed(2) ?? '');

  const getSimulatorSliderMarks = useCallback((range: number) => {
    const sliderMarks = [range * -1];

    let divisor = 3;

    if (range >= 1000) {
      divisor = 1;
    } else if (range >= 100) {
      divisor = 2;
    }

    const rest = range / divisor;

    for (let index = 0; index < divisor * 2; index++) {
      sliderMarks.push(sliderMarks[index] + rest);
    }

    return sliderMarks
      .map((number) => ({ [number]: number.toFixed(2) }))
      .reduce((obj, number) => ({
        ...obj,
        ...number,
      }));
  }, []);

  const min = sliderRange * -1;
  const max = sliderRange;

  function handleChangeInput(event: React.ChangeEvent<HTMLInputElement>) {
    const regex = new RegExp(`^-?\\d+(\\.\\d{0,2})?$`);

    const auxValue = event.target.value;

    if (auxValue === '') {
      setInputValue('');
      onChange(name, 0);
      return;
    }

    if (Number(auxValue) > max) {
      setInputValue(max.toString());
      onChange(name, max);

      return;
    }

    if (Number(auxValue) < min) {
      setInputValue(min.toString());
      onChange(name, min);

      return;
    }

    if (regex.test(auxValue)) {
      setInputValue(auxValue);
      onChange(name, Number(auxValue));
    }
  }

  function handleBlurInput() {
    if (!inputValue) {
      setInputValue(value?.toFixed(2) ?? '');
    }
  }

  useEffect(() => {
    if (value !== Number(inputValue)) {
      setInputValue(value?.toFixed(2) ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div key={name}>
      <SimulationsSliderHeader>
        <h5>{name}</h5>
        <input
          type="number"
          value={inputValue}
          min={min}
          max={max}
          onBlur={handleBlurInput}
          step="0.01"
          onChange={handleChangeInput}
          data-cy={`slider-input-${name}`}
          data-testid={`slider-input-${name}`}
        />
      </SimulationsSliderHeader>
      <Slider
        dataCy={`slider-${name}`}
        disabled={isDisabled}
        min={min}
        max={max}
        marks={getSimulatorSliderMarks(sliderRange)}
        step={0.01}
        value={value}
        defaultValue={defaultValue}
        onChange={(auxValue) => onChange(name, auxValue)}
      />
    </div>
  );
};
