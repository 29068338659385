const reviewCreateSeriesPTBRTranslate = {
  reviewCreateSeriesText: 'Revisar séries à serem adicionadas',
  reviewCreateSeriesSubText:
    ' Quando terminar, clique em “Concluir” para adicionar essas séries ao seu indicador',
  reviewCreateRegion: 'Região',
  reviewCreateAggregation: 'Agregação',
  reviewCreatePrimary: 'Tr. Primária',
  reviewCreateSecondary: 'Tr. Secundária',
  reviewCreateUnitPt: 'Unidade (português)',
  reviewCreateUnitEn: 'Unidade (inglês)',
  reviewCreateAddSeries: 'Adicionar séries',
  modalStatusText: 'Status da criação de séries',
  modalStatusSubText:
    'Confira quais séries foram criadas com sucesso e quais resultaram em erro no processo.',
  modaStatusSuccess: 'Sucesso',
  modaStatusError: 'Erro',
  modaStatusContinue: 'Continuar',
};

export default reviewCreateSeriesPTBRTranslate;
