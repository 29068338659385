/* eslint-disable react/no-this-in-sfc */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ArrowLeft } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card } from 'src/components/Card';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Head } from 'src/components/Head';
import { Select } from 'src/components/Select';
import { RootState } from 'src/redux/store';
import light from 'src/styles/themes/light';
import { translateSomeMessagesFromBackend } from 'src/i18n';
import { HCharts, HChartsOptions, HChartsSeries } from 'src/components/HCharts';
import { truncateStrings } from 'src/utils/strings/truncateStrings';
import { formatCompactNotation } from 'src/utils/numbers/formatCompactNotation';
import { getTextWidth } from 'src/utils/getTextWidth';

import api from '../../../service/api';
import { Container, ContentTitle } from './styles';
import { VarImportance, VarImportanceError } from './types';

export const VariableImportance: React.FC = () => {
  const [numberOfVariables, setNumberOfVariables] = useState(10);
  const { t: translate } = useTranslation();
  const id = useSelector(
    (state: RootState) =>
      state.modelling.form?.fileDataSet.dataset_id ?? state.project.id,
  );
  const { language } = useSelector((state: RootState) => state.auth.user);

  const navigate = useNavigate();

  const {
    data: variableImportanceData,
    isLoading: variableImportanceLoading,
    isFetching: variableImportanceFetching,
    isError: variableImportanceErrored,
    error: variableImportanceError,
  } = useQuery<VarImportance, VarImportanceError>(
    ['dataview', 'variable-importance', id],
    async () => {
      const { data } = await api.get<VarImportance>(
        `/projects/${id}/dataview/variable_importance/${numberOfVariables}`,
      );

      return {
        names: data?.names || [],
        values: data?.values ? data.values?.map((value) => Number(value)) : [],
      };
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled: !!id && !!numberOfVariables,
    },
  );

  useEffect(() => {
    if (variableImportanceData && variableImportanceData?.names?.length < 10) {
      setNumberOfVariables(variableImportanceData?.names?.length);
    }
  }, [variableImportanceData, variableImportanceData?.names?.length]);

  const filterVariableImportanceValues = useCallback(
    (value: number) => {
      if (variableImportanceData?.names && variableImportanceData.values) {
        const newNameValues = variableImportanceData?.names.filter(
          (_, index) => index < value,
        );
        const newYValues = variableImportanceData?.values.filter(
          (_, index) => index < value,
        );

        const newVarImportanceChartData = {
          values: newYValues,
          names: newNameValues,
        };

        return newVarImportanceChartData;
      }

      return null;
    },
    [variableImportanceData],
  );

  const varsToDisplay: VarImportance = useMemo(
    () =>
      filterVariableImportanceValues(numberOfVariables) ?? {
        names: variableImportanceData?.names ?? [],
        values: variableImportanceData?.values ?? [],
      },
    [filterVariableImportanceValues, numberOfVariables, variableImportanceData],
  );

  const serie: HChartsSeries = useMemo(() => {
    const chartColors = varsToDisplay.values.map((value) =>
      value >= 0 ? `${light.colors.primary}3d` : `${light.colors.red4}3d`,
    );

    const YsColumn = truncateStrings(varsToDisplay.names ?? []);

    return {
      type: 'bar',
      data: varsToDisplay.values.map((value, index) => ({
        name: YsColumn[index],
        color: chartColors[index],
        y: value,
        value,
        borderWidth: 0,
        custom: {
          key: varsToDisplay.names[index],
          value: formatCompactNotation(value),
        },
        dataLabels: {
          enabled: true,
          borderWidth: 0,
          verticalAlign: 'middle',
          inside: false,
          format: formatCompactNotation(value), // Formato do texto a ser exibido
          style: {
            color: value >= 0 ? light.colors.primary : light.colors.red4,
            fontSize: '14px',
            fontWeight: 'normal',
            fontFamily: "'Inter', sans-serif",
            textOutline: '0',
          },
        },
      })),
    };
  }, [varsToDisplay]);

  const options: HChartsOptions = useMemo(() => {
    const chartTextWidth = varsToDisplay.names
      .map((text) => getTextWidth(text, '400 14px Inter'))
      .reduce((previous, current) => {
        if (previous > current) {
          return previous;
        }
        return current + 50;
      }, 0);

    return {
      chart: {
        type: 'bar',
        height:
          60 * varsToDisplay.values.length + 8 > 500
            ? '508px'
            : `${60 * varsToDisplay.values.length + 8}px`,
        marginTop: 0,
        zooming: {
          type: undefined,
        },
        animation: false,
        events: {
          render() {
            const series = this.series[0];
            series.points.forEach((point) => {
              if (typeof point.y === 'number') {
                //@ts-expect-error:ignora
                const pointBox = point.graphic.getBBox();

                const x =
                  point.y < 0
                    ? this.plotWidth -
                      pointBox.y -
                      pointBox.height -
                      //@ts-expect-error:ignora
                      point.dataLabel.width
                    : this.plotWidth - pointBox.y;
                //@ts-expect-error:ignora
                point.dataLabels[0].attr({
                  x,
                });
              }
            });
          },
        },
      },
      xAxis: {
        type: 'category',
        minTickInterval: undefined,
        grid: {
          enabled: false,
        },
        labels: {
          y: undefined,
        },
        gridLineWidth: 0,
        lineWidth: 0,
      },
      yAxis: {
        tickPositioner() {
          const positions: number[] = []; // Inicia com a posição 0

          // Adiciona outras posições, se necessário
          let max = this.chart.series[0].dataMax ?? 0; // Evita valores negativos no máximo
          let min = this.chart.series[0].dataMin ?? 0; // Evita valores positivos no mínimo

          const quantity = Math.abs(max - min) + 1;

          const width = this.chart.plotWidth;

          const widthByPosition = width / quantity;

          const decrementMin = chartTextWidth / widthByPosition;

          const incrementMax = chartTextWidth / widthByPosition;

          if (min < 0) {
            min -= decrementMin;
            positions.push(min);
          }

          positions.push(0);

          if (max > 0) {
            max += incrementMax;
            positions.push(max);
          }

          return positions;
        },
        visible: true,
        gridLineWidth: 0,
        labels: {
          enabled: false,
        },
        minTickInterval: undefined,
        title: {
          text: `${translate('increase_of_the_mean')}`,
        },
      },

      legend: {
        enabled: false,
      },
      tooltip: {
        headerFormat: '',
        pointFormat:
          `<table><tr><th colspan="2">{point.custom.key}</th></tr>` +
          `<tr><td><b>${translate('value')}:</b> </td>` +
          '<td style="text-align: right">{point.custom.value}</td></tr>',
        useHTML: true,
      },
      plotOptions: {
        bar: {
          borderWidth: 0,
          pointPadding: 0.0,
          groupPadding: 0.05,
        },
      },
    };
  }, [translate, varsToDisplay]);

  return (
    <>
      <Head
        title={`${translate('variable_importance')} | ${translate(
          'data_view',
        )}`}
      />
      <Container>
        <ContentTitle className="containerLinear" data-testid="div-dataview">
          <ArrowLeft
            size="2rem"
            onClick={() => navigate('/models/projects/new/time-series')}
            style={{ cursor: 'pointer' }}
            data-cy="back-button"
            data-testid="back-button"
          />
          <Card
            textCard={`${translate('data_view')} - ${translate(
              'variable_importance',
            )}`}
            style={{ marginBottom: 0 }}
          />
        </ContentTitle>

        <div className="containerLinear" data-cy="variable-importance">
          <Card textCard={translate('variable_importance')} />
          {variableImportanceData?.names ? (
            <Select
              label={translate('show_n_variables')}
              style={{ width: '18.75rem', marginBottom: '1rem' }}
              options={Array.from(
                {
                  length:
                    variableImportanceData?.names.length < 10
                      ? variableImportanceData?.names.length
                      : 10,
                },
                (_, number) => ({
                  value: number + 1,
                  label: number + 1,
                }),
              )}
              onChange={(option: any) => {
                setNumberOfVariables(option.value);
              }}
              placeholder={translate('select')}
              value={{ label: numberOfVariables, value: numberOfVariables }}
            />
          ) : (
            (variableImportanceLoading || variableImportanceErrored) && (
              <Select
                label={translate('show_n_variables')}
                style={{ width: '18.75rem', marginBottom: '1.5rem' }}
                isDisabled
              />
            )
          )}

          {variableImportanceErrored ? (
            <ContainerMaintenance
              content="chart"
              text={translateSomeMessagesFromBackend(
                variableImportanceError?.response?.data?.detail?.detail ??
                  variableImportanceError?.response?.data?.detail
                    ?.description ??
                  '',
                language,
              )}
              data-testid="variable-importance-errored"
              data-cy="variable-importance-errored"
            />
          ) : variableImportanceLoading ||
            variableImportanceFetching ||
            !variableImportanceData ? (
            // eslint-disable-next-line react/jsx-indent
            <ContainerSkeleton
              style={{ height: '37.5rem' }}
              data-testid="variable-importance-loading"
              data-cy="variable-importance-loading"
            />
          ) : variableImportanceData.values.length === 0 ||
            variableImportanceData.names.length === 0 ? (
            // eslint-disable-next-line react/jsx-indent
            <ContainerMaintenance
              content="chart"
              text={translate('variableImportanceMessageErrorEmptyDataset')}
              data-testid="variable-importance-errored-with-length-0"
              data-cy="variable-importance-errored-with-length-0"
            />
          ) : (
            varsToDisplay && (
              <HCharts
                series={[serie]}
                options={options}
                dataCy="variable-importance-chart"
                resizeWidthWithSidebar
                sleepTimerToResize
              />
            )
          )}
        </div>
      </Container>
    </>
  );
};
