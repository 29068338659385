import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type step = 1 | 2 | 3 | 4;

export type PayloadProjectOverviewExportTimeSeries = {
  loading: boolean;
  visibleModal: boolean;
  step: step;
  isError: boolean;
  isFinish: boolean;
};

const initialState = {
  loading: false,
  visibleModal: false,
  step: 1,
  isError: false,
  isFinish: false,
};

export const ProjectOverviewExportTimeSeriesState = createSlice({
  name: 'projectOverviewExportTimeSeries',
  initialState,
  reducers: {
    startExport: (state) => {
      state.loading = true;
      state.visibleModal = true;
    },
    changeLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    changeStep: (state, action: PayloadAction<step>) => {
      state.step = action.payload;
    },
    changeIsError: (state, action: PayloadAction<boolean>) => {
      state.isError = action.payload;
    },
    changeIsFinish: (state, action: PayloadAction<boolean>) => {
      state.isFinish = action.payload;
    },
    cleanExport: (state) => {
      state.loading = false;
      state.visibleModal = false;
      state.step = 1;
      state.isError = false;
    },
  },
});

export const {
  startExport,
  changeLoading,
  changeStep,
  changeIsError,
  changeIsFinish,
  cleanExport,
} = ProjectOverviewExportTimeSeriesState.actions;
export default ProjectOverviewExportTimeSeriesState.reducer;
