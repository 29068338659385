import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModelDescription = styled.div`
  margin: -1.625rem 0 1.5rem;

  p {
    margin-top: 0.5rem;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray5};
  }

  b {
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 150%;
  }
`;

export const ParametersContainer = styled.div`
  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.688rem;
    color: ${({ theme }) => theme.colors.gray6};
    margin-bottom: 0.5rem;
  }

  > div {
    display: flex;
    flex-wrap: wrap;
    > div {
      display: flex;
      flex-direction: column;
      margin-right: 1rem;

      > div {
        max-width: 10rem;
      }

      h4 {
        color: ${({ theme }) => theme.colors.gray5};
        opacity: 0.8;
        margin-bottom: 0.5rem;
        font-size: 0.875rem;
      }
      p {
        color: ${({ theme }) => theme.colors.gray6};
        font-size: 0.875rem;
        margin-bottom: 1rem;
      }
      span {
        color: ${({ theme }) => theme.colors.gray5};
        font-size: 0.875rem;
      }
    }
  }
`;

export const ModelInfoError = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray5};
`;
