const preDefinedSeriesPTBR = {
  preDefinedSeriesTitle: 'Séries',
  preDefinedSeriesDescription:
    'Acesse cada série individualmente ou exporte a informação.',
  preDefinedName: 'Nome',
  preDefinedSource: 'Fonte',
  preDefinedLastUpdate: 'Última Atualização',
  preDefinedStartDate: 'Data de Início',
  preDefinedEndDate: 'Data Final',
  preDefinedFrequency: 'Frequência',
  preDefinedRegion: 'Região',
  preDefinedPrimaryTr: 'Transformação Primária',
  preDefinedSecondaryTr: 'Transformação Secundária',
  preDefinedSearchSeries: 'Procurar séries',
  preDefinedSearchIndicators: 'Procurar Indicadores',
  preDefinedDescriptionIndicator:
    'Navegue em nosso banco de dados, explore milhares de variáveis e crie seus grupos de série favoritas.',
  preDefinedSearch: 'Procurar',

  preDefinedUnableGroup: 'Não foi possível carregar as séries deste grupo.',
  preDefinedAddSeries: 'Adicione sua primeira série.',
  preDefinedAddSeriesDescription:
    'Selecione um indicador e envie novas séries para este grupo clicando no botão abaixo.',

  preDefinedExcludedError: 'Ocorreu um erro ao deletar a série.',
  preDefinedDownloadError: 'Ocorreu um erro ao baixar a série.',
  preDefinedAnySeries: 'Não encontramos nenhuma série contendo',
  preDefinedSearchMaxCharactersError:
    'A pesquisa deve ter no máximo XX caracteres.',

  preDefinedDeleteTitle: 'Você tem certeza?',
  preDefinedDeleteDescription:
    'Isso excluirá permanentemente está série do seu grupo.',
  groupViewHeaderMacroIndicator: 'Indicadores macro',
  groupViewHeaderModelSeries: 'Séries para modelagem',
  groupViewAddNew: 'Adicionar novo',
};

export default preDefinedSeriesPTBR;
