const arimaDispersionPTBR = {
  arimaDispersionPageTitle: 'ARIMA | Dispersão dos Modelos',
  arimaDispersionTitle: 'ARIMA - Dispersão dos Modelos',
  arimaDispersionDescription:
    'Explore previsões e estimativas de parâmetros de vários modelos.',

  arimaDispersionTitlePanelDeterminants: 'Painel de Determinantes',
  arimaDispersionDescriptionPanelDeterminants:
    'Explore como diferentes modelos avaliam fatores que podem ajudar a explicar o fenômeno de interesse. Verifique os valores dos parâmetros estimados e sua significância estatística.',
  arimaDispersionSelectLabelPanelDeterminants: 'Mostrar N Modelos',
  arimaDispersionVariablesLabelPanelDeterminants: 'Variáveis',
  arimaDispersionVariableLabelPanelDeterminants: 'Variável',
  arimaDispersionMetricsLabelPanelDeterminants: 'Métricas',
  arimaDispersionSignificanceLabelPanelDeterminants: 'Significância',
  arimaDispersionModelLabelPanelDeterminants: 'Modelo',
  arimaDispersionModelsLabelPanelDeterminants: 'Modelos',

  arimaDispersionTitleForecastDispersion: 'Dispersão das Projeções',
  arimaDispersionDescriptionForecastDispersion:
    'Visualize as projeções dos melhores modelos disponíveis.',

  arimaDispersionDate: 'Data',
  arimaDispersionValue: 'Valor',
  arimaNoExplanatoryVariableWasFound:
    'Nenhuma variável explicativa foi encontrada.',
};

export default arimaDispersionPTBR;
