import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type DateRange = [Date | null, Date | null];

export interface PayloadGroupNavigation {
  myFavorites: {
    page: number;
    isBackFromGroup: boolean;
    idSelected: string;
    dateRange: DateRange;
  };
  preDefinedGroups: {
    page: number;
    isBackFromGroup: boolean;
  };
}

const initialState: PayloadGroupNavigation = {
  myFavorites: {
    page: 1,
    isBackFromGroup: false,
    idSelected: '',
    dateRange: [null, null],
  },
  preDefinedGroups: {
    page: 1,
    isBackFromGroup: false,
  },
};

interface SetGroupNavigationInfoProps {
  group: 'myFavorites' | 'preDefinedGroups';
  page: number;
  isBackFromGroup: boolean;
}

interface SetMyFavoriteDateRangeProps {
  idSelected: string;
  dateRange: DateRange;
}
export const groupNavigationState = createSlice({
  name: 'group navigation',
  initialState,
  reducers: {
    setGroupNavigationInfo: (
      state,
      action: PayloadAction<SetGroupNavigationInfoProps>,
    ) => {
      state[action.payload.group].page = action.payload.page;
      state[action.payload.group].isBackFromGroup =
        action.payload.isBackFromGroup;
    },
    setMyFavoriteDateRange: (
      state,
      action: PayloadAction<SetMyFavoriteDateRangeProps>,
    ) => {
      state.myFavorites.dateRange = action.payload.dateRange;
      state.myFavorites.idSelected = action.payload.idSelected;
    },
    setClearMyFavoriteDateRangeAndIdSelected: (state) => {
      state.myFavorites.dateRange = [null, null];
      state.myFavorites.idSelected = '';
    },
    clearBackFromGroup: (state) => {
      state.myFavorites.page = 1;
      state.myFavorites.isBackFromGroup = false;
      state.myFavorites.idSelected = '';
      state.myFavorites.dateRange = [null, null];

      state.preDefinedGroups.page = 1;
      state.preDefinedGroups.isBackFromGroup = false;
    },
  },
});

export const {
  setGroupNavigationInfo,
  setMyFavoriteDateRange,
  setClearMyFavoriteDateRangeAndIdSelected,
  clearBackFromGroup,
} = groupNavigationState.actions;

export default groupNavigationState.reducer;
