import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PayloadSerieToView {
  id: string | null;
  serie: {
    code: string;
    indicatorCode: string;
    region: string;
    frequency: string;
    primaryTransformation: string;
    secondaryTransformation: string;
  } | null;
  page: number | null;
}

const initialState: PayloadSerieToView = {
  id: null,
  serie: null,
  page: null,
};

export const SerieToViewState = createSlice({
  name: 'serieToView',
  initialState,
  reducers: {
    setInfoToViewTheSerie: (
      state,
      action: PayloadAction<PayloadSerieToView>,
    ) => {
      state.id = action.payload.id;
      state.serie = action.payload.serie;
      state.page = action.payload.page;
    },
    clearSerieInfo: (state) => {
      state.serie = null;
    },
    clearAllInfo: (state) => {
      state.id = null;
      state.serie = null;
      state.page = null;
    },
  },
});

export const { setInfoToViewTheSerie, clearSerieInfo, clearAllInfo } =
  SerieToViewState.actions;

export default SerieToViewState.reducer;
