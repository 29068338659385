const modelOverViewPTBRTranslations = {
  modelOverviewTitle: 'Visão Geral da Variável',
  modelOverviewTitleDescription:
    'Analise o portfólio de modelos para uma determinada variável dependente, comparando a precisão entre os métodos testados e verificando as projeções de abordagens concorrentes.',
  modelOverviewSummary: 'Resumo dos Modelos',
  modelOverviewType: 'Tipo do Modelo',
  modelOverviewQuantity: 'Quantidade',
  modelOverviewAccuracyCriteria: 'Critério de acurácia',
  modelOverviewModelsSorting: 'Classificação dos Modelos',
  modelOverviewForecastDispersion: 'Dispersão das Projeções',
  modelOverviewForecastDispersionDescription:
    'Visualize as projeções dos melhores modelos disponíveis',
  modelOverviewShowNBestModels: 'Mostrar N melhores modelos',
};

export default modelOverViewPTBRTranslations;
