import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: auto;

  > div:first-child {
    margin-bottom: 1.5rem;
  }

  > div:last-child {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    height: calc(100% - 5.688rem);

    @media (max-width: 1545px) {
      height: calc(100% - 6.75rem);
    }
  }
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1.5rem;
  margin-bottom: 0.75rem;

  > div {
    min-width: 15.625rem;
  }

  > div:last-child {
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 150%;
      color: ${({ theme }) => theme.colors.gray5};
    }

    div {
      display: flex;
      flex-direction: row;
      margin-top: 0.5rem;
      gap: 1.5rem;

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.375rem;
        color: ${({ theme }) => theme.colors.gray6};
      }
    }
  }
`;

export const ConfusionMatrixContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin-left: 0.75rem;
  /* height: 100%; */

  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.688rem;
    color: ${({ theme }) => theme.colors.gray5};
  }

  > div {
    display: flex;
    flex-direction: row;
  }

  .box-1 {
    grid-area: 1 / 1 / 2 / 2;
  }
  .box-2 {
    grid-area: 1 / 2 / 2 / 3;
  }
  .box-3 {
    grid-area: 1 / 3 / 2 / 4;
  }
  .box-4 {
    grid-area: 2 / 1 / 3 / 2;
  }
  .box-5 {
    grid-area: 2 / 2 / 3 / 3;
  }
  .box-6 {
    grid-area: 2 / 3 / 3 / 4;
  }
  .box-7 {
    grid-area: 3 / 1 / 4 / 2;
  }
  .box-8 {
    grid-area: 3 / 2 / 4 / 3;
  }
  .box-9 {
    grid-area: 3 / 3 / 4 / 4;
  }
`;

interface ConfusionMatrixCellProps {
  type: 'truePositive' | 'falsePositive' | 'trueNegative' | 'falseNegative';
}

export const ConfusionMatrixCell = styled.div<ConfusionMatrixCellProps>`
  display: flex;
  min-width: 11.25rem;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 2.375rem;
  gap: 0.199rem;
  background: ${({ type }) =>
    type.includes('truePositive') || type.includes('trueNegative')
      ? 'rgba(76, 148, 255, 0.24)'
      : 'rgba(242, 125, 180, 0.16)'};
  color: ${({ theme, type }) =>
    type.includes('truePositive') || type.includes('trueNegative')
      ? theme.colors.primary
      : theme.colors.secondary};

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 150%;
    text-align: center;
  }

  p {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 1.75rem !important;
    line-height: 2.125rem;
    text-align: center;
    color: ${({ theme, type }) =>
      type.includes('truePositive')
        ? theme.colors.primary
        : type.includes('trueNegative')
        ? theme.colors.primary
        : theme.colors.secondary} !important;
  }
`;

interface ConfusionMatrixLabels {
  empty?: boolean;
}

export const ConfusionMatrixLabel = styled.div<ConfusionMatrixLabels>`
  text-align: center;
  display: grid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 2.375rem;
  gap: 0.199rem;
  background: ${({ theme, empty }) =>
    empty ? 'transparent' : theme.colors.gray0};
  color: ${({ theme }) => theme.colors.gray5};

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.688rem;
    word-wrap: break-word;
  }
`;

interface ChartAxisTitle {
  vertical?: boolean;
}

export const ChartAxisTitle = styled.p<ChartAxisTitle>`
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 1.375rem;
  color: ${({ theme }) => theme.colors.gray5} !important;
  text-align: center;

  ${({ vertical }) =>
    vertical
      ? css`
          transform: rotate(-90deg);
          position: absolute;
          left: 0px;
          top: 50%;
          margin-top: 5%;
        `
      : css`
          margin-left: 35%;
        `}
`;
