/* eslint-disable no-template-curly-in-string */
/* eslint-disable prettier/prettier */
/* eslint-disable no-useless-escape */

const apiURL = process.env.REACT_APP_API_PREDICT_URL;

export const sample = {
  python: `import requests
auth = "<your auth token>"
project_id = "<your project id>"
url = "${apiURL}/projects/" + project_id + "/predict"
headers = {"Authorization": f"Bearer {auth}"}
data = {
  "data": {
    "<column_a>": "value_a",
    "<column_b>": "value_b",
    "<column_c>": "value_c",
    "<column_d>": "value_d",
    "<column_e>": "value_e",
    "<column_f>": "value_f"
    }
    }
prediction = requests.post(url=url, headers=headers, json=data)
print(prediction.text)`,
  java: `import java.net.http.HttpClient;
import java.net.http.HttpRequest;
import java.net.http.HttpResponse;
import java.net.URI;
public class Main {
  public static void main(String[] args) throws Exception {
    String auth = "<your auth token>";
    String project_id = "<your project id>";
    String url = "${apiURL}/projects/" +
    project_id + "/predict";
    String requestBody = "{\\n" +
      " \\"data\\": {\\n" +
      "
      \\"<column_a>\\": \\"value_a\\",\\n" +
      "
      \\"<column_b>\\": \\"value_b\\",\\n" +
      "
      \\"<column_c>\\": \\"value_c\\",\\n" +
      "
      \\"<column_d>\\": \\"value_d\\",\\n" +
      "
      \\"<column_e>\\": \\"value_e\\",\\n" +
      "
      \\"<column_f>\\": \\"value_f\\"\\n" +
      "
      }\\n" +
      "
    }";
    HttpClient client = HttpClient.newHttpClient();
    HttpRequest request = HttpRequest.newBuilder()
      .uri(URI.create(url))
      .header("Authorization", "Bearer " + auth)
      .header("Content-Type", "application/json")
      .POST(HttpRequest.BodyPublishers.ofString(requestBody))
      .build();
    HttpResponse<String> response = client.send(request,
    HttpResponse.BodyHandlers.ofString());
    System.out.println(response.body());
  }
}`,
  bash: `#!/bin/bash
auth="<your auth token>"
project_id="<your project id>"
url="${apiURL}/projects/$project_id/predict"
headers=(
"Authorization: Bearer $auth"
"Content-Type: application/json"
)
data='{
"data": {
"<column_a>": "value_a",
"<column_b>": "value_b",
"<column_c>": "value_c",
"<column_d>": "value_d",
"<column_e>": "value_e",
"<column_f>": "value_f"
}
}'
response=$(curl -H "${'${headers[0]}'}" -H "${'${headers[1]}'}" -X POST -d "$data"
"$url")
echo "$response"`,
};
