import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface PayloadMyProjectsOptions {
  search: string;
  page: number | null;
}

const initialState: PayloadMyProjectsOptions = {
  search: '',
  page: null,
};

export const MyProjectOptions = createSlice({
  name: 'myProjectOptions',
  initialState,
  reducers: {
    saveMyProjectOptions: (
      state,
      action: PayloadAction<PayloadMyProjectsOptions>,
    ) => {
      state.page = action.payload.page;
      state.search = action.payload.search;
    },
    clearMyProjectOptions: (state) => {
      state.page = null;
      state.search = '';
    },
  },
});

export const { clearMyProjectOptions, saveMyProjectOptions } =
  MyProjectOptions.actions;

export default MyProjectOptions.reducer;
