import React, { useState } from 'react';

import { Trash, Warning, XCircle } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';
import { ModalLoading } from 'src/components/Modal/Loading';
import api from 'src/feature-store/service/api';
import { queryClient } from 'src/service/queryClient';
import { ModalFooter } from 'src/components/Modal/Footer/styles';

import { Container, ContainerError, FooterModalDelete } from './styles';

interface UserSelectedProps {
  id: string;
}

interface IModalDeleteUser {
  userSelected: UserSelectedProps[];
  setUserSelected: (value: UserSelectedProps[]) => void;
  setVisible: (value: boolean) => void;

  group: string;
}

export const ModalDeleteUser: React.FC<IModalDeleteUser> = ({
  userSelected,
  setUserSelected,
  setVisible,
  group,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const { t: translate } = useTranslation();

  const handleDeleteUsersFromGroup = async () => {
    try {
      setLoading(true);
      for (const user of userSelected) {
        await api.delete(`access-groups/${group}/users/${user.id}`);
      }
      setLoading(false);
      queryClient.removeQueries('usersFromGroup');
      setUserSelected([]);
      setVisible(false);
    } catch (err) {
      setLoading(false);
      setError(true);
    }
  };

  if (loading) {
    return (
      <ModalLoading
        visible
        style={{ padding: 0, width: '280px' }}
        data-testid="container-loading"
      />
    );
  }

  if (error) {
    return (
      <Modal
        visible
        setVisible={() => setVisible(false)}
        style={{ padding: 0 }}
      >
        <ContainerError data-testid="container-error">
          <XCircle size="3rem" />
          <h4>{translate('deleteUserFailedTitle')}</h4>
          <p>{translate('deleteUserFailedDescription')}</p>
        </ContainerError>
        <FooterModalDelete>
          <Button
            buttonType="primary"
            onClick={() => setVisible(false)}
            data-testid="button-back"
            data-cy="button-back"
          >
            {translate('back')}
          </Button>
        </FooterModalDelete>
      </Modal>
    );
  }

  return (
    <Modal visible setVisible={() => setVisible(false)}>
      <Container data-testid="modal-delete-user">
        <Warning size="2.5rem" />
        <h2>{translate('deleteUserTitle')}</h2>
        <p>
          {userSelected.length > 1
            ? translate('deleteUserDescriptionPlural')
            : translate('deleteUserDescription')}
        </p>
      </Container>
      <ModalFooter>
        <Button
          buttonType="naked"
          onClick={() => setVisible(false)}
          data-testid="button-cancel"
          data-cy="button-cancel"
        >
          {translate('cancel')}
        </Button>
        <Button
          buttonType="primary"
          icon={<Trash size="1.125rem" />}
          onClick={() => handleDeleteUsersFromGroup()}
          data-testid="button-delete-modal-delete-user"
          data-cy="button-delete"
        >
          {translate('delete')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
