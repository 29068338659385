export const AISelectionInformationPTTranslate = {
  aiSelectionInformationTitle: 'Seleção IA',
  aiSelectionInformationDescription:
    'Otimize sua tomada de decisão com base na Seleção IA com insights dos resultados. Acesse previsões e entenda o impacto das variáveis no comportamento da série para cada ponto de dados.',
  aiSelectionInformationSeeMoreInformation:
    'Ver mais informações sobre o modelo',
  aiSelectionInformationBaseModel: 'Modelo Base',
  aiSelectionInformationSample: 'Amostra',
  aiSelectionInformationTransformation: 'Transformação',
  aiSelectionInformationSeeLessInformation:
    'Ver menos informações sobre o modelo',
};
