const ClaaSRequirementsPTBRTranslations = {
  claasRequirementsTitle: 'Requisitos do arquivo de dados ',
  claasRequirementsDescription:
    'Por favor, certifique-se que seu arquivo de dados atende aos seguintes requisitos:',
  claasRequirementsFirsRow:
    'A primeira linha deve conter os <b>nomes das variáveis</b>;',
  claasRequirementsDatasetSize: 'O dataset não pode exceder 100MB;',
  claasRequirementsRowsVsColumns:
    'O dataset precisa ter mais linhas do que colunas;',
  claasRequirementsColumnsAndLines:
    'O dataset precisa ter no <b>mínimo 2 colunas</b>, uma com a variável alvo e outra com a variável explicativa. Também é necessário no mínimo 50 linhas. No entanto, um volume maior de linhas é preferível.',
  claasRequirementsTargetVariable: 'Sobre a <b>variável alvo</b>:',
  claasRequirementsTargetInfoZero:
    'A variável para a qual a previsão é desejada;',
  claasRequirementsTargetInfoOne:
    'Pode assumir valores binários (ex: 0 e 1, "a" e "b", "gatos" e "cachorros") e de multiclasses (ex: 0, 1 e 2, “a”, “b”, “c” e “d”, “gatos”, “cachorros” e “aves”;',
  claasRequirementsTargetInfoTwo:
    'A variável alvo deve conter até 7 valores únicos (para casos que ultrapassem esta limitação, ver documentação da API);',
  claasRequirementsTargetInfoThree:
    'Observações faltantes (NA) serão excluídas, exceto quando isso indicar uma das duas classes possíveis. Novas observações para as quais o usuário deseja previsões (linhas sem rótulo) deverão ser trazidas posteriormente para a aplicação em um segundo arquivo de dados.',

  claasRequirementsSuggestedPractices: '<b>Práticas sugeridas</b>',
  claasRequirementsPracticesInfoOne:
    'Evite a inclusão de variáveis que não estarão disponíveis antes que as informações da variável alvo sejam conhecidas (por exemplo, "valor de uma venda" quando o alvo for "venda realizada (S/N)") também conhecido como "vazamento de dados";',
  claasRequirementsPracticesInfoTwo:
    'Verifique se há valores preenchidos incorretamente nos dados.',
  claasRequirementsDownload: 'Baixar exemplo',
};

export default ClaaSRequirementsPTBRTranslations;
