import styled from 'styled-components';

type TagType = {
  type: 'ai' | 'economists';
};

export const Container = styled.div<TagType>`
  display: flex;
  padding: 0.25rem 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;

  border-radius: 62.4375rem;

  color: ${({ type, theme }) =>
    type === 'economists' ? theme.colors.primary : theme.colors.secondary};

  background: ${({ type }) =>
    type === 'economists'
      ? 'rgba(76, 148, 255, 0.12)'
      : 'rgba(242, 125, 180, 0.12)'};

  svg {
    width: 0.75rem;
    height: 0.75rem;
  }

  p {
    color: ${({ type, theme }) =>
      type === 'economists' ? theme.colors.primary : theme.colors.secondary};

    font-family: Inter;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
