import styled from 'styled-components';

export const Favorites = styled.div`
  min-height: 400px;
`;

export const HeaderFavorites = styled.div`
  display: flex;
  flex-direction: row;
`;

type FavoriteOption = {
  isActive?: boolean;
};

export const FavoriteOption = styled.span<FavoriteOption>`
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.gray6 : theme.colors.gray4};

  font-weight: ${({ isActive }) => (isActive ? 500 : 400)};

  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  line-height: normal;

  cursor: pointer;
`;

export const Slash = styled(FavoriteOption)`
  margin-left: 1rem;
  margin-right: 1rem;
`;

export const ListFavoritesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;

  white-space: nowrap;

  padding-bottom: 0.5rem;

  margin: 1rem 0;
  gap: 1rem;

  ::-webkit-scrollbar {
    width: 0.313rem;
    height: 0.313rem;
    padding: 0.063rem;
  }

  ::-webkit-scrollbar-track {
    transition: 0.2s;
    margin: 0.188rem;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }
`;

export const MaintenanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;

  button {
    max-width: 15rem;
  }
`;

export const PaginateContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;

  margin-top: 1rem;

  span {
    color: ${({ theme }) => theme.colors.gray4};
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.06rem;
    text-transform: uppercase;
  }
`;

export const SeriesFavoriteContainer = styled.div`
  min-height: 245px;
`;

export const NavigateToFavPage = styled.a`
  display: flex;
  padding: 0.5rem 0.75rem;
  align-items: center;
  gap: 0.5rem;

  cursor: pointer;

  border-radius: 1.5rem;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  box-sizing: border-box;

  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.gray5};

  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;

  &:hover {
    color: ${({ theme }) => theme.colors.gray6};

    svg {
      color: ${({ theme }) => theme.colors.gray6};
    }
  }

  svg {
    width: 1rem;
    height: 1rem;

    color: ${({ theme }) => theme.colors.gray4};
  }
`;
