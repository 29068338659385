import React, { useContext } from 'react';

import { Check, Share } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'src/components/Button';
import { Tooltip } from 'react-tooltip';
import {
  changeIsError,
  changeLoading,
  changeStep,
  cleanExport,
  startExport,
} from 'src/models/redux/reducers/ProjectOverviewExportTimeSeries';
import api from 'src/models/service/api';
import { RootState } from 'src/redux/store';
import { sleep } from 'src/utils/sleep';

import { ProjectOverviewContext } from '../..';
import { ExportStartContentButton } from './styles';

interface ResponseStartDownload {
  status: string;
}

export const ExportStart: React.FC = () => {
  const { project, projectOverviewExportTimeSeries } = useSelector(
    (state: RootState) => state,
  );

  const { projectYsSuccess } = useContext(ProjectOverviewContext);

  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  async function startDownload(): Promise<void> {
    if (!projectYsSuccess.length) {
      return;
    }
    dispatch(startExport());
    if (projectOverviewExportTimeSeries.isFinish) {
      dispatch(changeStep(3));
      return;
    }

    try {
      const ids = projectYsSuccess.map((y) => y.id);
      const { data } = await api.post<ResponseStartDownload>(
        `projects/${project.id}/export`,
        {
          variables: ids,
        },
      );

      if (data.status !== 'created') {
        dispatch(changeLoading(false));
        dispatch(changeIsError(true));
        return;
      }
      dispatch(changeStep(2));
    } catch {
      dispatch(changeIsError(true));
      await sleep(3000);
      dispatch(cleanExport());
    }
  }

  return (
    <ExportStartContentButton>
      <Tooltip className="customTooltipWhite" id="export-tooltip" />
      <Button
        data-tooltip-id="export-tooltip"
        data-tooltip-html={translate('projectOverviewTooltipExport')}
        buttonType="secondary"
        data-testid="button-export"
        data-cy="button-export"
        icon={
          !projectOverviewExportTimeSeries.loading ? (
            projectOverviewExportTimeSeries.isFinish ? (
              <Check size="1.125rem" weight="bold" />
            ) : (
              <Share size="1.125rem" weight="bold" />
            )
          ) : undefined
        }
        disabled={projectOverviewExportTimeSeries.loading}
        loading={projectOverviewExportTimeSeries.loading}
        onClick={() => startDownload()}
        style={{
          width: projectOverviewExportTimeSeries.loading ? '195px' : 'auto',
        }}
      >
        {projectOverviewExportTimeSeries.loading
          ? translate('projectOverviewExportPreparingData')
          : translate('projectOverviewExportDownload')}
      </Button>
    </ExportStartContentButton>
  );
};
