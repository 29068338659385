import { getMonth } from 'date-fns';

export function convertDateToQuarterly(date: Date): string {
  const month = getMonth(date);

  let quarterly = '';

  if (month <= 2) {
    quarterly = 'Q1';
  } else if (month <= 5) {
    quarterly = 'Q2';
  } else if (month <= 8) {
    quarterly = 'Q3';
  } else if (month <= 11) {
    quarterly = 'Q4';
  }

  return `${date.getFullYear()} ${quarterly}`;
}
