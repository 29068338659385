import React, { useContext, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Button';
import { GearSix, Info } from 'phosphor-react';
import { Label } from 'src/components/Label';
import { WorkspaceConfigContext } from 'src/workspaces/contexts/WorkspaceConfigContext';
import { ReactComponent as SumImg } from 'src/assets/sum.svg';
import { ReactComponent as AverageImg } from 'src/assets/average.svg';
import { ReactComponent as EndOfPeriodImg } from 'src/assets/end-of-period.svg';

import {
  AggregationOptionContainer,
  AggregationOptions,
  Container,
  IndividualConfiguration,
  SelectOptionButton,
} from './styles';
import { Subtitle } from '../Step4/styles';
import { LevelAggregation, TemporalAggregation } from './types';
import { IndividualAggregationsModal } from '../Modal/IndividualAggregations';

export const AggregationConfiguration: React.FC = () => {
  const [showIndividualAggregationsModal, setShowIndividualAggregationsModal] =
    useState(false);

  const { t: translate } = useTranslation();

  const {
    frequency,
    file,
    temporalAggregation,
    setTemporalAggregation,
    levelAggregation,
    setLevelAggregation,
    categorization,
  } = useContext(WorkspaceConfigContext);

  const handleChangeTemporalAggregation = (
    aggregation: TemporalAggregation,
  ) => {
    setTemporalAggregation(
      temporalAggregation.map((agg) => ({
        ...agg,
        frequency_method: aggregation,
      })),
    );
  };

  const handleChangeLevelAggregation = (aggregation: LevelAggregation) => {
    setLevelAggregation(
      levelAggregation.map((agg) => ({
        ...agg,
        frequency_method: aggregation,
      })),
    );
  };

  const isIndividualTemporalAggregation = useMemo(() => {
    const firstTemporalAggregation = temporalAggregation[0]?.frequency_method;

    return !!temporalAggregation.find(
      (agg) => agg.frequency_method !== firstTemporalAggregation,
    );
  }, [temporalAggregation]);

  const isIndividualLevelAggregation = useMemo(() => {
    const firstLevelAggregation = levelAggregation[0]?.frequency_method;

    return !!levelAggregation.find(
      (agg) => agg.frequency_method !== firstLevelAggregation,
    );
  }, [levelAggregation]);
  const levelAggregationDisabled = isIndividualLevelAggregation;

  const workspaceIsAnnual = frequency === 'annual' || frequency === 'yearly';

  const temporalAggregationDisabled =
    workspaceIsAnnual || isIndividualTemporalAggregation;

  const individualConfigurationDisabled =
    !file && !categorization?.hierarchies.length;

  return (
    <Container>
      <Subtitle>
        <div>3</div>
        <h3>{translate('workspaceConfigAggregations')}</h3>
      </Subtitle>

      <Button
        buttonType="naked"
        icon={<GearSix />}
        onClick={() => setShowIndividualAggregationsModal(true)}
        data-testid="button-individual-configuration"
        disabled={individualConfigurationDisabled}
        data-tooltip-content={
          individualConfigurationDisabled
            ? translate(
                'workspaceConfigAggregationsIndividualConfigurationDisabled',
              )
            : undefined
        }
        data-tooltip-id="variable-categorization-tooltip"
      >
        {translate('workspaceConfigAggregationsIndividualConfiguration')}
      </Button>

      <div>
        <AggregationOptionContainer data-testid="container-level-aggregation">
          <div>
            <Label>
              {translate('workspaceConfigAggregationsLevelAggregation')}
            </Label>

            <Info
              data-tooltip-id="variable-categorization-tooltip"
              data-tooltip-html={translate(
                'createWorkspaceAggregateInformation',
              )}
              data-testid="aggregation-information"
            />
          </div>

          <AggregationOptions
            isIndividualConfiguration={isIndividualLevelAggregation}
          >
            <SelectOptionButton
              selected={
                !levelAggregationDisabled &&
                levelAggregation[0]?.frequency_method === 'not_aggregated'
              }
              onClick={() => handleChangeLevelAggregation('not_aggregated')}
              data-testid="button-level-aggregation-no-aggregation"
            >
              {translate('workspaceConfigAggregationsNoAggregation')}
            </SelectOptionButton>

            <SelectOptionButton
              selected={
                !levelAggregationDisabled &&
                levelAggregation[0]?.frequency_method === 'sum'
              }
              disabled={!file && !categorization?.hierarchies.length}
              onClick={() => handleChangeLevelAggregation('sum')}
              data-testid="button-level-agreggation-sum"
            >
              {translate('workspaceConfigAggregationsSum')}
            </SelectOptionButton>

            <SelectOptionButton
              selected={
                !levelAggregationDisabled &&
                levelAggregation[0]?.frequency_method === 'average'
              }
              disabled={!file && !categorization?.hierarchies.length}
              onClick={() => handleChangeLevelAggregation('average')}
              data-testid="button-level-agreggation-average"
            >
              {translate('workspaceConfigAggregationsAverage')}
            </SelectOptionButton>

            {isIndividualLevelAggregation && (
              <IndividualConfiguration data-testid="container-individual-level-aggregation">
                {translate(
                  'workspaceConfigAggregationsIsIndividualConfiguration',
                )}
              </IndividualConfiguration>
            )}
          </AggregationOptions>
        </AggregationOptionContainer>

        <AggregationOptionContainer data-testid="container-temporal-aggragtion">
          <div>
            <Label style={{ opacity: workspaceIsAnnual ? '0.6' : '1' }}>
              {translate('workspaceConfigAggregationsTemporalAggregation')}
            </Label>

            <Info
              data-tooltip-id="variable-categorization-tooltip"
              data-tooltip-html={translate(
                'workspaceConfigAggregationsTemporalAggregationTooltip',
              )}
              data-testid="temporal-aggregation-information"
            />
          </div>

          <AggregationOptions
            isIndividualConfiguration={isIndividualTemporalAggregation}
            data-tooltip-content={
              workspaceIsAnnual
                ? translate(
                    'workspaceConfigAggregationsTemporalAggregationAnnualTooltip',
                  )
                : undefined
            }
            data-tooltip-id="variable-categorization-tooltip"
          >
            <SelectOptionButton
              selected={
                !temporalAggregationDisabled &&
                temporalAggregation[0]?.frequency_method === 'sum'
              }
              onClick={() => handleChangeTemporalAggregation('sum')}
              disabled={temporalAggregationDisabled}
              data-testid="button-temporal-aggregation-sum"
            >
              <SumImg />
              {translate('workspaceConfigAggregationsSum')}
            </SelectOptionButton>

            <SelectOptionButton
              selected={
                !temporalAggregationDisabled &&
                temporalAggregation[0]?.frequency_method === 'average'
              }
              onClick={() => handleChangeTemporalAggregation('average')}
              disabled={temporalAggregationDisabled}
              data-testid="button-temporal-aggregation-average"
            >
              <AverageImg />
              {translate('workspaceConfigAggregationsAverage')}
            </SelectOptionButton>

            <SelectOptionButton
              selected={
                !temporalAggregationDisabled &&
                temporalAggregation[0]?.frequency_method === 'last_of_period'
              }
              onClick={() => handleChangeTemporalAggregation('last_of_period')}
              disabled={temporalAggregationDisabled}
              data-testid="button-temporal-aggregation-end-of-period"
            >
              <EndOfPeriodImg />
              {translate('workspaceConfigAggregationsEndOfPeriod')}
            </SelectOptionButton>

            {isIndividualTemporalAggregation && (
              <IndividualConfiguration data-testid="container-individual-temporal-aggregation">
                {translate(
                  'workspaceConfigAggregationsIsIndividualConfiguration',
                )}
              </IndividualConfiguration>
            )}
          </AggregationOptions>
        </AggregationOptionContainer>
      </div>

      <IndividualAggregationsModal
        visible={showIndividualAggregationsModal}
        setVisible={setShowIndividualAggregationsModal}
      />
    </Container>
  );
};
