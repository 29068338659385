import styled from 'styled-components';

export const Container = styled.div``;

export const ContentTitle = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 1.5rem;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.gray4};
    transition: 0.2s color;

    &:hover {
      color: ${({ theme }) => theme.colors.gray5};
    }
  }
`;

export const ContentButton = styled.div`
  margin-top: 1.5rem;
  display: flex;
  justify-content: flex-end;

  @media only screen and (max-width: 600px) {
    justify-content: center;
    button {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
`;

export const ContainerChart = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface ContentChart {
  marginLeftBottomScroll: number;
}

export const ContenChart = styled.div<ContentChart>`
  overflow: auto;
  display: flex;
  position: relative;
  ::-webkit-scrollbar-track {
    margin-left: ${({ marginLeftBottomScroll }) =>
      `${(marginLeftBottomScroll + 12) / 16}rem`};
    margin-bottom: ${({ marginLeftBottomScroll }) =>
      `${(marginLeftBottomScroll + 24) / 16}rem`};
  }
`;

export const YAXIX = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-right: 0.75rem;
  position: sticky;
  left: 0;
  z-index: 400;
  background-color: white;

  p {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.colors.gray6};
    text-align: right;
  }
`;

export const XAXIX = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0.75rem 0;
  z-index: 400;
  background-color: white;
  margin-top: auto;
  position: sticky;
  bottom: -0.188rem;

  p {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.colors.gray6};
    text-align: left;
    writing-mode: vertical-rl;
  }
`;

interface ContainerWhite {
  size: number;
}

export const ContainerWhite = styled.div<ContainerWhite>`
  width: ${({ size }) => `${(size + 12) / 16}rem`};
  height: ${({ size }) => `${(size + 36) / 16}rem`};
  z-index: 1000;
  background: white;
  position: absolute;
  left: 0;
  bottom: -0.188rem;
`;

export const ContainerLegend = styled.div`
  margin-left: 1rem;
  b {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.colors.gray6};
  }
  > div {
    margin-top: 0.25rem;
    display: flex;
    flex-direction: row;
    > div:first-child {
      height: 9.375rem;
      width: 1.875rem;
      background-image: linear-gradient(#81b3ff, #fff, #ffabd3);
    }

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 0.5rem;
      font-size: 0.75rem;
      color: ${({ theme }) => theme.colors.gray6};
    }
  }
`;
