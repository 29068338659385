import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ClaasProject = {
  id: string | null;
  name: string | null;
  targetVariable: string | null;
  modelList:
    | {
        code: string;
        name: string;
        type: string;
      }[]
    | null;
  modelInProduction: {
    code: string;
    name: string;
    type: string;
  } | null;
  projectError: boolean;
};

const initialState: ClaasProject = {
  id: null,
  name: null,
  targetVariable: null,
  modelList: null,
  modelInProduction: null,
  projectError: false,
};

export const claasProjectState = createSlice({
  name: 'claasProject',
  initialState,
  reducers: {
    insertProject: (state, action: PayloadAction<ClaasProject>) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.targetVariable = action.payload.targetVariable;
      state.modelList = action.payload.modelList;
      state.modelInProduction = action.payload.modelInProduction;
      state.projectError = action.payload.projectError;
    },
    clear: (state) => {
      state.id = null;
      state.name = null;
      state.targetVariable = null;
      state.modelList = null;
      state.modelInProduction = null;
      state.projectError = false;
    },
  },
});

export const { insertProject, clear } = claasProjectState.actions;

export default claasProjectState.reducer;
