import React from 'react';

import Slider, { Handle, SliderTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';

import { Container } from './styles';
import { HandleProps, SliderPropsComponent } from './types';

export const SliderComponent: React.FC<SliderPropsComponent> = ({
  style,
  className,
  dataCy,
  ...rest
}) => {
  const handle = (props: HandleProps) => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <SliderTooltip
        {...rest}
        prefixCls="rc-slider-tooltip"
        overlay={`${value} `}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps} />
      </SliderTooltip>
    );
  };
  return (
    <>
      <Container
        style={style}
        className={className}
        data-cy={dataCy}
        data-testid={dataCy}
      >
        <Slider handle={handle} {...rest} data-testid={`div-${dataCy}`} />
      </Container>
    </>
  );
};
