import { Button } from 'src/components/Button';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  max-width: 71.25rem;
  margin: 0 auto;
`;
export const ContainerComponents = styled.div`
  border-radius: 8px;
  padding: 1.5rem;
  border: ${({ theme }) => `1px solid ${theme.colors.gray3}`};
  flex-wrap: wrap;
  background: ${({ theme }) => theme.colors.white};

  margin-bottom: 24px;
`;

export const ContainerItens = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin: 2rem auto 0;
  max-width: 45.625rem;
`;

export const RecommendedNWindows = styled.p`
  margin-top: 1rem;

  color: ${({ theme }) => theme.colors.gray6};
  font-weight: 400;
  font-size: 0.875rem;

  strong {
    font-weight: 500;
  }
`;

export const ContentIcon = styled.div`
  margin-right: 4rem;
`;

export const InputDiv = styled.div`
  margin-top: 3.125rem;
  width: 25rem;

  div {
    display: flex;
    width: auto;
    justify-content: end;
  }
  p {
    color: rgba(117, 117, 117, 1);
  }
`;

export const DivLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  width: 8.25rem;
  color: ${({ theme }) => theme.colors.gray5};
  p {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.375rem;
  }
`;

export const Input = styled.input`
  font-size: 1.125rem;
  width: 25rem;
  border-radius: 10px;
  padding: 0.625rem;
  &::placeholder {
    color: ${({ theme }) => theme.colors.gray3};
  }
`;

export const BackTypeModelling = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const ContentInput = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 33.125rem;
`;

export const DivIcon = styled.div`
  width: 8.25rem;
  height: 8.25rem;

  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.colors.gray2}`};
  background-color: ${({ theme }) => theme.colors.gray3};

  cursor: pointer;

  transition: all 0.2s;

  img {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    &:hover {
      border-radius: 7px;
    }
  }

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.gray3};
  }
`;

export const ContainerSelectVariables = styled.div`
  padding: 1.5rem;

  background: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `1px solid${theme.colors.gray3}`};
  border-radius: 8px;
  margin-bottom: 1.5rem;
`;

export const ContentItens = styled.div`
  display: flex;
  justify-content: center;

  > div {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    width: 100%;
    max-width: 33.375rem;
  }
  margin-bottom: 1.5rem;
`;

export const ContentSelectFormat = styled.div`
  position: relative;

  > span {
    position: absolute;
    top: 0.156rem;

    font-size: 0.75rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gray4};

    b {
      font-weight: 600;
    }
  }
`;

export const ContentButtons = styled.div`
  width: 100%;
  position: relative;
  margin-top: 3rem;
  border-top: 1px solid ${({ theme }) => theme.colors.gray2};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.5rem;
  gap: 0.75rem;

  > button:first-child {
    padding: 0;
  }
`;

export const ContentButtonAdvancedAndModeling = styled.div`
  display: flex;
  align-items: center;

  gap: 1.5rem;

  > button:first-child {
    /* padding: 0; */
  }
`;

export const ContainerFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;

  margin: 1.5rem 0;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 12.5rem;
    padding: 1.5rem;

    background: ${({ theme }) => theme.colors.white};
    border: ${({ theme }) => `1px solid${theme.colors.gray3}`};
    border-radius: 8px;

    > div {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
    }
  }
`;

export const Content = styled.div`
  padding: 1.5rem;
  background: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `1px solid${theme.colors.gray3}`};
  border-radius: 8px;
  margin-bottom: 1.5rem;

  > button {
    margin-top: 1.5rem;
    color: ${({ theme }) => theme.colors.primaryDark};
    font-size: 1rem;
    font-weight: 500;
    border-bottom: ${({ theme }) => `2px solid${theme.colors.primaryDark}`};
    margin-bottom: 2.25rem;
  }
`;

export const ContentOptions = styled.div`
  display: flex;
  > div {
    display: flex;
    flex-direction: column;

    &:first-child {
      width: 45%;
      margin-right: 5%;

      > div:first-child {
        margin-bottom: 1.5rem;
      }
    }

    &:nth-child(2) {
      > div:first-child {
        margin-bottom: 3rem;
      }
    }
  }
`;

export const ContentButtonsThree = styled.div`
  display: flex;
  justify-content: center;
`;

export const AdvancedOptionsButton = styled(Button)`
  ${({ theme, buttonType }) => css`
    color: ${buttonType === 'secondary'
      ? theme.colors.primary
      : theme.colors.white} !important;
  `}
  border: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 9.688rem;

  padding: 0;

  :hover {
    color: ${({ theme }) => theme.colors.primaryDark} !important;
    border: none;
  }
`;

export const ButtonScript = styled(Button)`
  background: ${({ theme }) => theme.colors.secondary};
  margin: 0 1rem;

  transition: background-color 0.2s;

  &:hover {
    background: #b85000;
  }
`;

export const ContentStep3 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 33.125rem;
  margin: 0 auto;
`;

export const ContentInputToggleStep3 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;

  width: 100%;

  margin-bottom: 1rem;

  @media (max-width: 650px) {
    justify-content: center;
  }

  p {
    font-weight: 500;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.gray5};
  }

  > div {
    display: flex;
    align-items: center;

    > div {
      margin-bottom: 0;

      p {
        margin-right: 0.5rem;
      }
    }
  }
`;
