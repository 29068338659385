import React from 'react';

import { useTranslation } from 'react-i18next';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Tooltip } from 'react-tooltip';

import { ModelInformation } from '../../ModelExplorer/types';
import { AccuracyContent } from './styles';

interface ModelPerformanceProps {
  isFetching: boolean;
  isLoading: boolean;
  isErrored: boolean;
  data: ModelInformation | undefined;
}

export const ModelPerformance: React.FC<ModelPerformanceProps> = ({
  data,
  isErrored,
  isFetching,
  isLoading,
}) => {
  const { t: translate } = useTranslation();

  return (
    <>
      <Tooltip
        id="models-classification-model-performance"
        className="customTooltipTheme"
      />

      {isErrored ? (
        <AccuracyContent data-testid="accuracy-metrics-content" disabled>
          {[
            'accuracyMetricsAccuracy',
            'accuracyMetricsPrecision',
            'Recall',
            'ROC AUC',
            'F1 Score',
          ].map((item) => (
            <div
              key={item}
              data-tooltip-id="models-classification-model-performance"
              data-tooltip-html={translate(
                `modelPerformance${item
                  .replaceAll(' ', '')
                  .replaceAll('accuracyMetrics', '')}Tooltip`,
              )}
            >
              {item === 'accuracyMetricsAccuracy' ||
              item === 'accuracyMetricsPrecision' ? (
                <>
                  <p>{translate(item)}</p>
                  <h3
                    data-testid={`${translate(item).toLocaleLowerCase()}-value`}
                    data-cy={`${translate(item).toLocaleLowerCase()}-value`}
                  >
                    --
                  </h3>
                </>
              ) : (
                <>
                  <p>{item}</p>
                  <h3
                    data-testid={`${item
                      .toLocaleLowerCase()
                      .replaceAll(' ', '-')}-value`}
                    data-cy={`${item
                      .toLocaleLowerCase()
                      .replaceAll(' ', '-')}-value`}
                  >
                    --
                  </h3>
                </>
              )}
            </div>
          ))}
        </AccuracyContent>
      ) : isFetching || isLoading || !data ? (
        // eslint-disable-next-line react/jsx-indent
        <AccuracyContent data-testid="accuracy-metrics-content" disabled>
          {[
            'accuracyMetricsAccuracy',
            'accuracyMetricsPrecision',
            'Recall',
            'ROC AUC',
            'F1 Score',
          ].map((item) => (
            <div
              data-tooltip-id="models-classification-model-performance"
              data-tooltip-html={translate(
                `modelPerformance${item
                  .replaceAll(' ', '')
                  .replaceAll('accuracyMetrics', '')}Tooltip`,
              )}
              key={item}
            >
              {item === 'accuracyMetricsAccuracy' ||
              item === 'accuracyMetricsPrecision' ? (
                <>
                  <p>{translate(item)}</p>
                  <ContainerSkeleton
                    withLoading={false}
                    style={{ height: '52px' }}
                  />
                </>
              ) : (
                <>
                  <p>{item}</p>
                  <ContainerSkeleton
                    withLoading={false}
                    style={{ height: '52px' }}
                  />
                </>
              )}
            </div>
          ))}
        </AccuracyContent>
      ) : (
        data && (
          <AccuracyContent>
            <div
              data-tooltip-id="models-classification-model-performance"
              data-tooltip-html={translate('modelPerformanceAccuracyTooltip')}
            >
              <p>{translate('accuracyMetricsAccuracy')}</p>
              <h3 data-testid="accuracy-value" data-cy="accuracy-value">
                {data?.performance?.accuracy?.value?.toFixed(2) ?? '--'}
              </h3>
            </div>
            <div
              data-tooltip-id="models-classification-model-performance"
              data-tooltip-html={translate('modelPerformancePrecisionTooltip')}
            >
              <p>{translate('accuracyMetricsPrecision')}</p>
              <h3 data-testid="precision-value" data-cy="precision-value">
                {data?.performance?.precision?.value?.toFixed(2) ?? '--'}
              </h3>
            </div>
            <div
              data-tooltip-id="models-classification-model-performance"
              data-tooltip-html={translate('modelPerformanceRecallTooltip')}
            >
              <p>Recall</p>
              <h3 data-testid="recall-value" data-cy="recall-value">
                {data?.performance?.recall?.value?.toFixed(2) ?? '--'}
              </h3>
            </div>
            <div
              data-tooltip-id="models-classification-model-performance"
              data-tooltip-html={translate('modelPerformanceROCAUCTooltip')}
            >
              <p>ROC AUC</p>
              <h3 data-testid="rocauc-value" data-cy="rocauc-value">
                {data?.performance?.roc_auc?.value?.toFixed(2) ?? '--'}
              </h3>
            </div>
            <div
              data-tooltip-id="models-classification-model-performance"
              data-tooltip-html={translate('modelPerformanceF1ScoreTooltip')}
            >
              <p>F1 Score</p>
              <h3 data-testid="f1-score-value" data-cy="f1-score-value">
                {data?.performance?.f1_score?.value.toFixed(2) ?? '--'}
              </h3>
            </div>
          </AccuracyContent>
        )
      )}
    </>
  );
};
