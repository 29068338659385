import React, { useEffect, useMemo, useState } from 'react';

import { getTextWidth } from 'src/utils/getTextWidth';

import {
  Container,
  ContentTriangulo,
  Marks,
  Slider,
  TooltipValue,
  Triangulo,
  TrianguloSelected,
} from './styles';

interface SliderNStepsProps {
  nSteps: number;
  maxNsteps: number;
  changeNSteps: (value: number) => void;
}

const font = '400 12px Inter';

export const SliderNSteps: React.FC<SliderNStepsProps> = ({
  nSteps,
  maxNsteps,
  changeNSteps,
}) => {
  const [valueSlider, setValueSlider] = useState<number>(0);
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);

  useEffect(() => {
    setValueSlider(nSteps);
  }, [nSteps]);

  const marks: number[] = useMemo(() => {
    if (maxNsteps > 10) {
      let quantitySliderNumber = 0;
      let multi = 1;
      const quantity = maxNsteps;

      if (quantity <= 20) {
        quantitySliderNumber = quantity;
      } else if (quantity <= 100) {
        multi = 5;
        quantitySliderNumber = Math.floor(quantity / multi);
      } else if (quantity > 100) {
        multi = Math.floor(quantity / 100) * 10;
        quantitySliderNumber = Math.floor(quantity / multi);
      }

      if (multi === 1) {
        return Array.from({ length: maxNsteps }, (_, number) => number + 1);
      }

      const values = Array.from(
        { length: quantitySliderNumber },
        (_, number) => (number + 1) * multi,
      );

      values.splice(0, 0, 1);

      if (!values.includes(quantity)) {
        if (quantity - values[values.length - 1] <= 15) {
          values.pop();
          values.push(quantity);
        } else {
          values.push(quantity);
        }
      }

      return values;
    }
    return Array.from({ length: maxNsteps }, (_, number) => number + 1);
  }, [maxNsteps]);

  return (
    <>
      <Container>
        <ContentTriangulo>
          <Triangulo />
          <TrianguloSelected value={valueSlider} maxValue={maxNsteps} />

          {tooltipVisible && (
            <TooltipValue
              value={valueSlider}
              maxValue={maxNsteps}
              widthText={getTextWidth(valueSlider.toString(), font)}
            >
              {valueSlider}
            </TooltipValue>
          )}

          <Slider
            value={valueSlider}
            max={maxNsteps}
            min={1}
            step={1}
            onMouseUp={(e) => {
              setTooltipVisible(false);
              //@ts-expect-error:ignora
              changeNSteps(Number(e.target.value));
            }}
            onMouseDown={() => setTooltipVisible(true)}
            onChange={(e) => setValueSlider(Number(e.target.value))}
            data-testid="input-slider-n-steps"
            data-cy="input-slider-n-steps"
          />
          <Marks>
            {marks.map((val) => (
              <button
                key={`mark-value-${val}`}
                data-testid={`button-mark-value-${val}`}
                data-cy={`button-mark-value-${val}`}
                type="button"
                onClick={() => changeNSteps(val)}
                style={{
                  left: `calc(${(val / maxNsteps) * 100}% - 0.938rem)`,
                }}
              >
                <div />
                {val}
              </button>
            ))}
          </Marks>
        </ContentTriangulo>
      </Container>
    </>
  );
};
