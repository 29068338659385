import React from 'react';

import { Warning } from 'phosphor-react';

import { WarningContainer } from './styles';

interface ContainerWaring extends React.HTMLAttributes<HTMLDivElement> {
  visible: boolean;
  text?: string;
  hasIcon?: boolean;
}

export const ContainerWarning: React.FC<ContainerWaring> = ({
  visible,
  text,
  children,
  hasIcon,
  ...rest
}) => (
  <WarningContainer visible={visible} hasIcon={hasIcon} {...rest}>
    {hasIcon && (
      <div>
        <Warning size="1.5rem" />
      </div>
    )}
    <div>{text ? <p>{text}</p> : children}</div>
  </WarningContainer>
);
