import React from 'react';

import { CheckCircle, Warning } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal/index';
import { queryClient } from 'src/service/queryClient';
import light from 'src/styles/themes/light';

import { ResponseDataErrorProps, ResponseDataSuccessProps } from '../types';
import {
  Container,
  Content,
  DivStatus,
  ButtonDiv,
  StatusContent,
  Label,
} from './styles';

interface ModalStatusSeriesProps {
  visible?: boolean;
  setVisible: (value: boolean) => void;
  responseDataSuccess: ResponseDataSuccessProps[];
  responseDataError: ResponseDataErrorProps[];
}

export const ModalStatusSeries: React.FC<ModalStatusSeriesProps> = ({
  visible,
  setVisible,
  responseDataSuccess,
  responseDataError,
}) => {
  const { t: translate } = useTranslation();
  return (
    <Modal visible={visible} style={{ padding: 0 }} setVisible={setVisible}>
      <Container data-testid="modal-status">
        <h1>{translate('modalStatusText')}</h1>
        <p>{translate('modalStatusSubText')}</p>
        <Content>
          <DivStatus>
            <div>
              <Label>
                <CheckCircle size="1.5rem" color={light.colors.green4} />
                <p>{translate('modaStatusSuccess')}</p>
              </Label>
              <StatusContent>
                {responseDataSuccess.length > 0 &&
                  responseDataSuccess.map((success) => (
                    <p
                      key={`serie-success-${success.message.slice(
                        success.message.indexOf(':') + 2,
                      )}`}
                      data-testid={`serie-success-${success.message.slice(
                        success.message.indexOf(':') + 2,
                      )}`}
                    >
                      {success.message.slice(success.message.indexOf(':') + 2)}
                    </p>
                  ))}
              </StatusContent>
            </div>
            <div>
              <Label>
                <Warning size="1.5rem" color={light.colors.red5} />
                <p>{translate('modaStatusError')}</p>
              </Label>

              <StatusContent>
                {responseDataError.map((error) => (
                  <p
                    key={`serie-error-${error.code}`}
                    data-testid={`serie-error-${error.code}`}
                  >
                    {error.code}
                  </p>
                ))}
              </StatusContent>
            </div>
          </DivStatus>
        </Content>
      </Container>
      <ButtonDiv>
        <Button
          buttonType="primary"
          data-testid="button-continue"
          onClick={() => {
            setVisible(true);
            queryClient.removeQueries('indicator data');
          }}
        >
          {translate('modaStatusContinue')}
        </Button>
      </ButtonDiv>
    </Modal>
  );
};
