const ClaaSRequirementsENUSTranslations = {
  claasRequirementsTitle: 'Dataset Requirements',
  claasRequirementsDescription:
    'Please, guarantee that your dataset fulfill the following requirements:',
  claasRequirementsFirsRow: 'First row with the <b>variable names</b>;',
  claasRequirementsDatasetSize: 'The dataset must not exceed 100MB;',
  claasRequirementsRowsVsColumns: 'Data must contain more rows than columns;',
  claasRequirementsColumnsAndLines:
    'The file must have <b>at least 2 columns</b>, with one target and one explanatory variable, and 50 rows, but preferably more;',
  claasRequirementsTargetVariable: '<b>Target Variable:</b> ',
  claasRequirementsTargetInfoZero:
    'The variable for which the prediction is desired;',
  claasRequirementsTargetInfoOne:
    'It can assume binary values (ex: 0 and 1, "a" and "b", "cats" and "dogs") and multiclass values (ex: 0, 1 and 2, “a”, “b”, “c” and “d”, “cats”, “dogs” and “birds”;',
  claasRequirementsTargetInfoTwo:
    'The target variable must contain up to 7 unique values (for cases that surpasses this limitation, see API documentation);',
  claasRequirementsTargetInfoThree:
    'Missing (NA) observation will be excluded, except if it indicates one of the two possible classes. New observations for which the user wants predictions (rows without label) should be brought later to the application in a second data file.',
  claasRequirementsSuggestedPractices: '<b>Suggested practices:</b>',
  claasRequirementsPracticesInfoOne:
    'Avoid the inclusion of variables that will not be available before the target information is known (e.g. "value of a sale" when the target is "sale done (Y/N)") also known as "data leakage";',
  claasRequirementsPracticesInfoTwo:
    'Check for wrongly filled values in the data.',
  claasRequirementsDownload: 'Download example',
};

export default ClaaSRequirementsENUSTranslations;
