type ValueFillCard = {
  period: string;
  time: number;
  nSteps: number;
};

export function getValuesToFillCardsThroughFrequency(
  frequency: string,
): ValueFillCard[] {
  switch (frequency) {
    case 'Daily':
      return [
        {
          time: 1,
          period: 'day',
          nSteps: 1,
        },
        {
          time: 7,
          period: 'days',
          nSteps: 7,
        },
        {
          time: 28,
          period: 'days',
          nSteps: 28,
        },
      ];

    case 'Weekly':
      return [
        {
          time: 1,
          period: 'week',
          nSteps: 1,
        },
        {
          time: 2,
          period: 'weeks',
          nSteps: 2,
        },
        {
          time: 4,
          period: 'weeks',
          nSteps: 4,
        },
      ];

    case 'Fortnightly':
      return [
        {
          time: 1,
          period: 'fortnight',
          nSteps: 1,
        },
        {
          time: 2,
          period: 'fortnights',
          nSteps: 2,
        },
        {
          time: 6,
          period: 'fortnights',
          nSteps: 6,
        },
      ];

    case 'Monthly':
      return [
        {
          time: 1,
          period: 'month',
          nSteps: 1,
        },
        {
          time: 3,
          period: 'months',
          nSteps: 3,
        },
        {
          time: 1,
          period: 'year',
          nSteps: 12,
        },
      ];

    case 'Bimonthly':
      return [
        {
          time: 1,
          period: 'bimonth',
          nSteps: 1,
        },
        {
          time: 1,
          period: 'halfYear',
          nSteps: 3,
        },
        {
          time: 1,
          period: 'year',
          nSteps: 6,
        },
      ];

    case 'Quarterly':
      return [
        {
          time: 1,
          period: 'quarter',
          nSteps: 1,
        },
        {
          time: 1,
          period: 'halfYear',
          nSteps: 2,
        },
        {
          time: 1,
          period: 'year',
          nSteps: 4,
        },
      ];

    case 'Half-year':
      return [
        {
          time: 1,
          period: 'halfYear',
          nSteps: 1,
        },
        {
          time: 1,
          period: 'year',
          nSteps: 2,
        },
        {
          time: 2,
          period: 'year',
          nSteps: 4,
        },
      ];

    case 'Annual':
      return [
        {
          time: 1,
          period: 'year',
          nSteps: 1,
        },
        {
          time: 2,
          period: 'years',
          nSteps: 2,
        },
        {
          time: 4,
          period: 'years',
          nSteps: 4,
        },
      ];

    default:
      return [];
  }
}
