import styled from 'styled-components';
import WorkSpaceShareHeader from 'src/assets/share-workspace.svg';

export const Header = styled.div`
  height: 133px;
  width: 554px;

  display: flex;
  justify-content: center;
  align-items: center;
  align-content: stretch;

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin: -1.5rem 0;

  background-image: url(${WorkSpaceShareHeader});
`;

export const Container = styled.div`
  display: flex;
  width: 34rem;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  margin-top: 3.125rem;

  border-radius: 8px;
  background: ${({ theme }) => theme.colors.white};
`;

export const ContentContainer = styled.div`
  display: flex;
  padding: 24px 32px 32px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  align-self: stretch;
`;

export const InfoShare = styled.div`
  display: flex;
  width: 30rem;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;

  h4 {
    color: ${({ theme }) => theme.colors.gray6};
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }

  span {
    color: ${({ theme }) => theme.colors.gray5};
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  width: 480px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  font-size: 0.875rem;

  position: relative;
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  span {
    color: ${({ theme }) => theme.colors.gray5};

    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }
`;

export const InputError = styled.div`
  p {
    color: ${({ theme }) => theme.colors.red2};
    line-height: 1rem;
    font-size: 0.875rem;
  }
`;
