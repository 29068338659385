import styled from 'styled-components';

export const ListIndicatorsForAnalyzeContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;

  margin-top: 2rem;
  margin-bottom: 2rem;

  gap: 1.5rem;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;

  gap: 0.5rem;

  span {
    color: ${({ theme }) => theme.colors.gray6};
    font-family: Inter;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  p {
    color: var(--Neutral-Gray-500, #718096);
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const IndicatorsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;

  padding-bottom: 0.5rem;

  gap: 1rem;

  ::-webkit-scrollbar {
    width: 0.313rem;
    height: 0.313rem;
    padding: 0.063rem;
  }

  ::-webkit-scrollbar-track {
    transition: 0.2s;
    margin: 0.188rem;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }
`;
