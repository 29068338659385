import React from 'react';

import parseHTML from 'html-react-parser';
import LineChart from 'src/assets/line-chart.svg';
import Error from 'src/assets/maintenance.svg';
import DeleteTrash from 'src/assets/delete.svg';
import CloudWarning from 'src/assets/error.svg';
import { Warning } from 'phosphor-react';

import { Container } from './styles';
import { DataTestid, StatusProps, Images } from './types';

export const Status: React.FC<StatusProps> = ({
  type,
  title,
  description,
  isModal = false,
  img,
  dataCy,
}) => {
  const images: Images = {
    selectLineChartFilter: LineChart,
    error: Error,
    noSearchResults: Error,
    deleteConfirmation: DeleteTrash,
    warning: '',
    cloudWarning: CloudWarning,
  };

  const dataTestid: DataTestid = {
    selectLineChartFilter: 'select-line-chart-filter-status',
    error: 'error-status',
    noSearchResults: 'no-search-results-status',
    deleteConfirmation: 'delete-confirmation-status',
    warning: 'warning-status',
    cloudWarning: 'cloud-warning-status',
  };

  return (
    <Container
      isModal={isModal}
      data-testid={type === 'other' ? dataCy : dataTestid[type]}
      data-cy={dataCy}
    >
      {type === 'warning' ? (
        <Warning />
      ) : (
        <img
          src={type === 'other' ? img : images[type]}
          alt="Status representation"
          className={`${type === 'other' ? dataCy : dataTestid[type]}-img`}
        />
      )}

      {!!title && <h3 data-testid="status-title">{parseHTML(title)}</h3>}

      {!!description && (
        <p data-testid="status-description">{parseHTML(description)}</p>
      )}
    </Container>
  );
};
