import styled from 'styled-components';

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  width: 30rem;
  > svg {
    color: ${({ theme }) => theme.colors.yellow6};
  }
  > h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.gray6};
    margin-bottom: 1.5rem;
  }
  > div + div {
    margin-top: 1.5rem;
  }
  > p {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.gray5};
    line-height: 150%;
  }
`;
