import { Button } from 'src/components/Button';
import styled from 'styled-components';

export const Container = styled.div`
  section {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    > div {
      flex: 1 1 18.75rem;
    }
  }

  .residuals-section {
    margin-top: 1.5rem;

    > div {
      flex: 1 1 100%;
    }
  }

  .select {
    width: 20rem;
    margin-bottom: 1.5rem;

    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }

  .relative {
    position: relative;
  }

  h5 {
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    color: ${({ theme }) => theme.colors.gray6};
  }

  .css-26l3qy-menu {
    z-index: 700;
  }
`;

export const ModelList = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 20rem;
  flex-basis: 20rem;
  @media only screen and (max-width: 700px) {
    max-width: 100%;
  }
  button {
    margin-top: 1.5rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const ModelInfoListInformation = styled.div`
  > div {
    display: flex;
    flex-wrap: wrap;
    > div {
      display: flex;
      flex-direction: column;
      margin-right: 1rem;

      h4 {
        color: ${({ theme }) => theme.colors.gray6};
        opacity: 0.8;
        margin-bottom: 0.5rem;
        font-size: 0.875rem;
      }
      p {
        color: ${({ theme }) => theme.colors.gray5};
        font-size: 0.875rem;
        margin-bottom: 1rem;
      }
      span {
        color: ${({ theme }) => theme.colors.gray4};
        font-size: 0.875rem;
      }
    }
  }
`;

export const ModelSpecification = styled.div`
  max-width: 20rem;
  padding-right: 0.875rem !important;
  @media only screen and (max-width: 700px) {
    max-width: 100%;
  }
`;

export const ContainerSendProduction = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  flex-basis: 12.5rem !important;
`;

export const ContainerModelInfo = styled.div`
  flex-basis: 12.5rem !important;
`;

export const ContentButton = styled.div`
  margin-top: 1.5rem;
  display: flex;
  justify-content: flex-end;
  @media only screen and (max-width: 600px) {
    justify-content: center;
    button {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
`;

export const SendButton = styled(Button)`
  height: 2.875rem;
  font-size: 0.875rem !important;
  padding: 0.75rem 1.5rem !important;

  white-space: nowrap;
`;

export const ContainerTable = styled.div`
  position: relative;
  height: 37.5rem;
  overflow: auto;

  thead {
    > tr:first-child {
      position: sticky;
      top: 0;
      background: ${({ theme }) => theme.colors.white};
    }
  }

  @media (max-width: 1380px) {
    height: 56.25rem;
  }

  @media (max-width: 986px) {
    height: 37.5rem;
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    padding: 1px;
  }
  ::-webkit-scrollbar-track {
    margin-top: 3px;
    margin-bottom: 3px;
    transition: 0.2s;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }
`;

export const VerticalText = styled.p`
  color: ${({ theme }) => theme.colors.gray6};
  font-size: 1.063rem;
  transform: rotate(-90deg);
  position: absolute;
  left: -3.125rem;
  top: 50%;
`;

export const ContentButtonSendProduction = styled.div`
  margin-top: 1.5rem;
  display: flex;
  justify-content: flex-end;
`;

export const CardTooltip = styled.div`
  max-width: 137.5rem;
  display: flex;
  justify-content: space-between;
`;

export const ContainerChartBetaView = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface ContentChart {
  marginLeftScroll: number;
  marginBottomScroll: number;
}

export const ContenChartBetaView = styled.div<ContentChart>`
  overflow: auto;
  display: flex;
  position: relative;
  ::-webkit-scrollbar-track {
    margin-left: ${({ marginLeftScroll }) => `${marginLeftScroll + 12}px`};
    margin-bottom: ${({ marginBottomScroll }) =>
      `${marginBottomScroll + 24}px`};
  }
`;

interface YAXIX {
  heights: number[];
}

export const YAXIX = styled.div<YAXIX>`
  display: flex;
  padding-right: 0.75rem;
  position: sticky;
  left: 0;
  z-index: 400;
  background-color: white;
  height: ${({ heights }) => `${(heights[0] + heights[1] + 48) / 16}rem`};
  > div:first-child {
    margin-right: 0.5rem;
    > p {
      writing-mode: vertical-rl;
      transform: rotate(-180deg);
      text-align: center;
      color: ${({ theme }) => theme.colors.gray6};

      &:nth-child(2) {
        margin-top: 3.375rem;
      }
    }
  }

  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    div {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      &:first-child {
        height: ${({ heights }) => `${heights[0] / 16}rem`};
      }

      &:nth-child(2) {
        height: ${({ heights }) => `${heights[1] / 16}rem`};
        margin-top: 3.375rem;
      }

      p {
        font-size: 0.75rem;
        color: ${({ theme }) => theme.colors.gray6};
        text-align: right;
        white-space: nowrap;
      }
    }
  }
`;

export const XAXIX = styled.div`
  padding: 0.75rem 0;
  z-index: 400;
  background-color: white;
  margin-top: auto;
  position: sticky;
  bottom: -0.188rem;

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    > p {
      font-size: 0.75rem;
      color: ${({ theme }) => theme.colors.gray6};
      text-align: left;
      width: 2.188rem;
      text-align: center;
    }
  }
  p {
    margin-top: 0.5rem;
    color: ${({ theme }) => theme.colors.gray6};
    text-align: center;
  }
`;

interface ContainerWhite {
  sizeY: number;
  sizeX: number;
}

export const ContainerWhite = styled.div<ContainerWhite>`
  width: ${({ sizeY }) => `${(sizeY + 42) / 16}rem`};
  height: ${({ sizeX }) => `${(sizeX + 64) / 16}rem`};
  z-index: 501;
  background: white;
  position: absolute;
  left: 0;
  bottom: -0.188rem;
`;

export const LegendBetaView = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  padding: 0.75rem 0;
  bottom: -0.75rem;
  z-index: 400;

  > div {
    display: flex;
    align-items: center;
    > div:first-child {
      width: 1.875rem;
      height: 0.625rem;
    }
    p {
      margin-left: 0.5rem;
      font-size: 0.75rem;
      color: ${({ theme }) => theme.colors.gray6};
    }
  }
`;

export const ContentSpecificationAndResiduals = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;

  > div {
    width: 100%;

    display: flex;
    flex-direction: column;
  }

  > div:first-child {
    min-width: 20rem;
    max-width: 20rem;
  }

  > div:nth-child(2) {
    width: calc(100% - 21.5rem);
  }
`;
