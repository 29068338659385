import React, { useState } from 'react';

import { Trash, Warning } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';
import { queryClient } from 'src/service/queryClient';
import { apiAdminV2 } from 'src/service/apiAdminV2';
import { WarningModal } from 'src/components/Modal/Warning';
import { ModalFooter } from 'src/components/Modal/Footer/styles';

import { Container } from './styles';

interface DeleteModalProps {
  idClient: string;
  visible: boolean;
  closeModalManager: () => void;
  setVisible: (visible: boolean) => void;
  managerToDelete: string;
}

interface FailedToDeleteManager {
  title: string;
  description: string;
}

export const ModalDeleteManager: React.FC<DeleteModalProps> = ({
  idClient,
  visible,
  closeModalManager,
  setVisible,
  managerToDelete,
}) => {
  const [loadingDeleteManager, setLoadingDeleteManager] = useState<boolean>();
  const [infoFailedToDeleteManager, setInfoFailedToDeleteManager] =
    useState<FailedToDeleteManager>();

  const { t: translate } = useTranslation();

  const handleDeleteManager = async () => {
    setLoadingDeleteManager(true);
    try {
      await apiAdminV2.put(
        `/clients/${idClient}/users/${managerToDelete}/is-blocked`,
        {
          is_blocked: true,
        },
      );
      queryClient.removeQueries('manager-list');
      queryClient.removeQueries('customers-list');
      setLoadingDeleteManager(false);
      closeModalManager();
    } catch (err) {
      setInfoFailedToDeleteManager({
        title: translate('modalConfirmDeleteErrorTitle'),
        description: translate('modalConfirmDeleteErrorDescription'),
      });
      setLoadingDeleteManager(false);
    }
  };

  return (
    <Modal visible={visible} setVisible={closeModalManager}>
      <Container>
        <Warning size="2.5rem" />
        <h2>{translate('preDefinedDeleteTitle')}</h2>
        <p>{translate('modalConfirmDeleteDescription')}</p>
      </Container>
      <ModalFooter>
        <Button
          buttonType="naked"
          onClick={() => setVisible(false)}
          data-testid="button-cancel-delete"
          data-cy="button-cancel-delete"
          disabled={loadingDeleteManager}
        >
          {translate('cancel')}
        </Button>
        <Button
          buttonType="primary"
          icon={<Trash size="1.125rem" />}
          onClick={() => handleDeleteManager()}
          data-testid="button-confirm-delete-manager"
          data-cy="button-confirm-delete-manager"
          loading={loadingDeleteManager}
          disabled={loadingDeleteManager}
        >
          {translate('delete')}
        </Button>
      </ModalFooter>
      {infoFailedToDeleteManager && (
        <WarningModal
          visible={!!infoFailedToDeleteManager}
          setVisible={(value) => {
            value === false && setInfoFailedToDeleteManager(undefined);
          }}
          errorInfo={infoFailedToDeleteManager}
        />
      )}
    </Modal>
  );
};
