const indicatorSeriesENTranslate = {
  indicatorSeriesSubTitle: 'See all series of this indicator.',
  indicatorSeries: 'Series',
  indicatorSeriesAddFirstSeries: 'Add your first series',
  indicatorSeriesAddFirstSeriesText:
    'Add your first new series to this indicator clicking on the button above.',
  indicatorSeriesSearchSeries: 'Search series',
  indicatorSeriesAddNewSeries: 'Add new series',
  indicatorSeriesSeriesCode: 'Series code',
  indicatorSeriesRegion: 'Region',
  indicatorSeriesAggregation: 'Aggregation',
  indicatorSeriesUnit: 'Unit',
  indicatorSeriesPrimary: 'Primary Transformation',
  indicatorSeriesSecondary: 'Secondary Transformation',
  indicatorSeriesFailedToDeleteSeries: 'Failed to delete series',
  indicatorSeriesErrorDeleteSerie:
    'It was not possible to delete series, please try again',
  indicatorSeriesModalDelete: 'Are you sure?',
  indicatorSeriesModalDeleteText:
    'Doing so will permanently delete this series and all of its information.',
  indicatorSeriesMaintenance: 'This will put the serie in maintenance status',
  indicatorSeriesActive: 'This will put the series in active status',
};

export default indicatorSeriesENTranslate;
