import React, { useCallback, useContext, useEffect, useState } from 'react';

import {
  ArrowDown,
  ArrowUp,
  ArrowsDownUp,
  Eye,
  EyeClosed,
  MagnifyingGlass,
  NotePencil,
  Pencil,
  Swap,
} from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { Button } from 'src/components/Button';
import { Card } from 'src/components/Card';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Input } from 'src/components/Input';
import { Table, Tbody, Td, Th, Thead, Tr } from 'src/components/Table';
import { Column, Y } from 'src/models/redux/reducers/Project';
import api from 'src/models/service/api';
import { RootState } from 'src/redux/store';
import { queryClient } from 'src/service/queryClient';
import light from 'src/styles/themes/light';
import { getChartColor } from 'src/utils/colors/getChartColor';
import { escapeRegExp } from 'src/utils/escapeRegExp';
import { formatCompactNotation } from 'src/utils/numbers/formatCompactNotation';
import { translateTransformationText } from 'src/utils/translateTransformationText';
import { MenuContainer, MenuOption } from 'src/components/Menu/styles';
import { Status } from 'src/components/Status';

import { ProjectOverviewContext, sortedColumn } from '../..';
import { DependentVariablesChart } from './DependentVariablesChart';
import computer from '../../../../../assets/computer.svg';
import {
  ActionsContainer,
  ButtonIcon,
  ContainerDependentVariable,
  ContentChart,
  ContentTableDependentVariables,
  MenuOverlay,
  SelectVariable,
  TableSortButton,
} from './styles';

interface SelectedVariables {
  id: string;
  label: string;
}

export type ColumnsAsObject = {
  [column in Column]: number | string | null | undefined;
};

interface DependentVariable extends ColumnsAsObject {
  label: string;
  id: string;
}

interface Chart {
  x: string[];
  y: number[];
  type: string;
}

interface ActualForecast {
  level: { forecast: Chart | undefined; historical: Chart };
}
export interface VariablesCharts extends ActualForecast {
  name: string;
}

interface ErrorProps {
  message: string;
  quantityLetters: number;
}

type TableColumns = 'MAPE' | 'WMAPE' | 'MPE' | 'RMSE' | 'MASE' | 'MASEs';

interface SortTable {
  type: 'asc' | 'desc';
  column: TableColumns;
}

export interface SelectedModel {
  selected_model:
    | {
        model_type: string | undefined;
      }
    | undefined;
  MASE: number | string | null | undefined;
  MAPE: number | string | null | undefined;
  MASEs: number | string | null | undefined;
  WMAPE: number | string | null | undefined;
  transformation: number | string | null | undefined;
  RMSE: number | string | null | undefined;
  MPE: number | string | null | undefined;
}

export const DependentVariables: React.FC = () => {
  const { projectYsSuccess, openModalEditColumns, openModalRenameVariables } =
    useContext(ProjectOverviewContext);

  const {
    project,
    auth: { user },
  } = useSelector((state: RootState) => state);
  const [dependentVariables, setDependentVariables] = useState<
    DependentVariable[]
  >([]);
  const [selectedVariables, setSelectedVariables] = useState<
    SelectedVariables[]
  >([]);
  const [variableCharts, setVariableCharts] = useState<VariablesCharts[]>([]);
  const [loadingChart, setLoadingChart] = useState(false);
  const [errorChart, setErrorChart] = useState(false);

  const [menuVisible, setMenuVisible] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const [searchTimer, setSearchTimer] = useState(250);
  const [searchError, setSearchError] = useState<ErrorProps>();
  const [searchAllowed, setSearchAllowed] = useState(false);
  const [timeOutActive, setTimeOutActive] = useState(false);
  const [lastSearch, setLastSearch] = useState('');

  const [dependentVariablesSearched, setDependentVariablesSearched] = useState<
    Y[]
  >([]);

  const [sortTable, setSortTable] = useState<SortTable>({} as SortTable);

  const [hasChartZoom, setHasChartZoom] = useState(false);

  const { t: translate } = useTranslation();

  const loadDependentVariables = useCallback(
    async (index: number): Promise<SelectedModel> => {
      let dataAux: SelectedModel;

      const contentQuery = queryClient.getQueryData<SelectedModel>([
        'project overview',
        'dependent-variable',
        project.id,
        dependentVariablesSearched[index].id,
      ]);

      try {
        if (contentQuery) {
          dataAux = contentQuery;
        } else {
          dataAux = await queryClient.fetchQuery<SelectedModel>(
            [
              'project overview',
              'dependent-variable',
              project.id,
              dependentVariablesSearched[index].id,
            ],
            // eslint-disable-next-line no-loop-func
            async () => {
              const { data } = await api.get(
                `/projects/${project.id}/${dependentVariablesSearched[index].id}/model-in-production/selected_model`,
              );
              return data;
            },
            {
              staleTime: 1000 * 60 * 20,
            },
          );
        }
      } catch (err) {
        return {
          selected_model: undefined,
          MASE: undefined,
          MAPE: undefined,
          MASEs: undefined,
          WMAPE: undefined,
          transformation: undefined,
          RMSE: undefined,
          MPE: undefined,
        };
      }

      return dataAux;
    },
    [project.id, dependentVariablesSearched],
  );

  useEffect(() => {
    let canLoad = true;

    async function load() {
      for (let i = 0; i < dependentVariablesSearched.length; i++) {
        const dataAux = await loadDependentVariables(i);

        const dependentVariable: ColumnsAsObject = {
          MAPE: '--',
          MASE: '--',
          MASEs: '--',
          Model: '--',
          MPE: '--',
          RMSE: '--',
          Transformation: '--',
          WMAPE: '--',
        };

        if (!canLoad) {
          return;
        }

        setDependentVariables((state) => {
          project.columns.forEach((column) => {
            if (column === 'Transformation') {
              if (dataAux?.transformation) {
                dependentVariable.Transformation = dataAux.transformation;
              }
            } else if (column === 'Model') {
              if (dataAux?.selected_model?.model_type) {
                dependentVariable.Model = dataAux.selected_model.model_type;
              }
            } else if (dataAux?.[column])
              dependentVariable[column] = dataAux?.[column];
          });

          return [
            ...state,
            {
              label: dependentVariablesSearched[i].label,
              id: dependentVariablesSearched[i].id,
              ...dependentVariable,
            },
          ];
        });
      }
    }

    load();

    return () => {
      canLoad = false;
      setDependentVariables([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project.columns, project.id, dependentVariablesSearched]);

  useEffect(() => {
    (async () => {
      if (!selectedVariables.length) {
        return;
      }

      setErrorChart(false);
      setLoadingChart(true);
      const variableChartsAux: VariablesCharts[] = [];
      for (let i = 0; i < selectedVariables.length; i++) {
        let dataAux: ActualForecast;

        const contentQuery = queryClient.getQueryData<ActualForecast>([
          'project overview',
          'dependent-variable',
          'actual_forecast',
          project.id,
          selectedVariables[i].id,
        ]);

        try {
          if (contentQuery) {
            dataAux = contentQuery;
          } else {
            dataAux = await queryClient.fetchQuery<ActualForecast>(
              [
                'project overview',
                'dependent-variable',
                'actual_forecast',
                project.id,
                selectedVariables[i].id,
              ],
              async () => {
                const { data } = await api.get<ActualForecast>(
                  `/projects/${project.id}/${selectedVariables[i].id}/model-in-production/actual_forecast`,
                );

                const historical = data.level.historical;
                const forecast = data.level.forecast;

                if (forecast?.x.length && forecast.y.length) {
                  if (historical?.x.length && historical.x.length) {
                    forecast.x = [
                      historical.x[historical.x.length - 1],
                      ...forecast.x,
                    ];

                    forecast.y = [
                      historical.y[historical.y.length - 1],
                      ...forecast.y,
                    ];
                  }
                }

                return {
                  level: { historical, forecast },
                };
              },
              {
                staleTime: 1000 * 60 * 20,
              },
            );
          }

          variableChartsAux.push({
            ...dataAux,
            name: selectedVariables[i].label,
          });
        } catch {
          setErrorChart(true);
        }
      }

      setVariableCharts(variableChartsAux);
      setLoadingChart(false);
    })();

    return () => {
      setLoadingChart(true);
    };
  }, [project.id, selectedVariables]);

  useEffect(() => {
    (() => {
      if (!projectYsSuccess.length) {
        return 0;
      }

      setSelectedVariables([
        {
          id: projectYsSuccess[0].id,
          label: projectYsSuccess[0].label,
        },
      ]);
    })();
  }, [projectYsSuccess]);

  function handleCustomizeTable() {
    setMenuVisible(false);
    openModalEditColumns();
  }

  function handleRenameVariable() {
    setMenuVisible(false);
    openModalRenameVariables();
  }

  function addTooltipSomeColumns(column: string): string | undefined {
    if (column === 'MAPE') {
      return translate('MAPEExplanationTooltip');
    }

    if (column === 'WMAPE') {
      return translate('WMAPEExplanationTooltip');
    }

    if (column === 'MPE') {
      return translate('MPEExplanationTooltip');
    }

    if (column === 'RMSE') {
      return translate('RMSEExplanationTooltip');
    }

    if (column === 'MASE') {
      return translate('MASEExplanationTooltip');
    }

    if (column === 'MASEs') {
      return translate('MASEsExplanationTooltip');
    }

    return undefined;
  }

  function adjustColumnName(column: string): string {
    if (column === 'Transformation') {
      return translate('projectOverviewTransformation');
    }
    if (column === 'Model') {
      return translate('projectOverviewModel');
    }
    if (column === 'MAPE' || column === 'WMAPE' || column === 'MPE') {
      return `${column} (%)`;
    }
    return column;
  }

  function isVariableSelected(id: string): boolean {
    return !!selectedVariables.find(
      (selectedVariable) => selectedVariable.id === id,
    );
  }

  function selectOrDeselectVariable(id: string, label: string): void {
    const zoomButton = document.getElementsByClassName('highcharts-reset-zoom');

    if (zoomButton.length) {
      setHasChartZoom(true);
    } else {
      setHasChartZoom(false);
    }

    const aux = selectedVariables;
    if (isVariableSelected(id)) {
      setSelectedVariables([
        ...aux.filter((selectVariable) => selectVariable.id !== id),
      ]);
    } else if (aux.length < 4) {
      setSelectedVariables([...aux, { id, label }]);
    }
  }

  function getColorOfSelectedVariableById(id: string): string {
    const index = selectedVariables.findIndex(
      (selectedVariable) => selectedVariable.id === id,
    );
    return getChartColor(index) ?? light.colors.primaryLight;
  }

  function handleSearchDependentVariable(value: string) {
    setSortTable({} as SortTable);
    setSearchValue(value);

    if (value.length > 50) {
      setSearchError({
        message: 'searchMaxCharactersError',
        quantityLetters: 50,
      });
      return;
    }

    setSearchError({
      message: '',
      quantityLetters: 0,
    });

    if (value !== searchValue) {
      setSearchTimer(250);
      setTimeOutActive(true);
    }
  }

  useEffect(() => {
    if (timeOutActive) {
      setTimeout(() => {
        if (searchTimer > 0) {
          setSearchTimer(searchTimer - 250);
        } else {
          setTimeOutActive(false);
        }
      }, 250);
    } else {
      searchTimer === 0 && setSearchAllowed(true);
    }
  }, [searchTimer, searchValue, timeOutActive]);

  useEffect(() => {
    if (!searchError?.message && searchAllowed) {
      const regex = new RegExp(escapeRegExp(searchValue), 'i');

      const updatedVariables = searchValue.length
        ? projectYsSuccess.filter(({ label }) => regex.test(label))
        : projectYsSuccess;

      setDependentVariablesSearched(updatedVariables);

      setSearchAllowed(false);
      setTimeOutActive(false);
      setSearchTimer(250);
      setLastSearch(searchValue);
    }
  }, [searchError, searchAllowed, searchValue, projectYsSuccess]);

  useEffect(() => {
    setDependentVariablesSearched(projectYsSuccess);
  }, [projectYsSuccess]);

  const handleSortTable = useCallback(
    (column: TableColumns) => {
      const { type: currentType, column: currentColumn } = sortTable;

      const type = column !== currentColumn ? 'asc' : 'desc';

      const sortOption = {} as SortTable;
      let sortedValues: DependentVariable[];

      if (type !== currentType || column !== currentColumn) {
        sortOption.type = type;
        sortOption.column = column;

        sortedValues = dependentVariables.sort((a, b) => {
          if (a[column] === '--') return 1;
          if (b[column] === '--') return -1;

          let valueA = Number(a[column]);
          let valueB = Number(b[column]);

          if (column === 'MPE') {
            valueA = Math.abs(valueA);
            valueB = Math.abs(valueB);
          }

          if (valueA > valueB) {
            return type === 'asc' ? 1 : -1;
          }
          if (valueA < valueB) {
            return type === 'asc' ? -1 : 1;
          }
          return 0;
        });
      } else {
        const labels = projectYsSuccess.map(({ label }) => label);

        sortedValues = dependentVariables.sort((a, b) => {
          const labelA = a.label;
          const labelB = b.label;

          const indexA = labels.indexOf(labelA);
          const indexB = labels.indexOf(labelB);

          return indexA - indexB;
        });
      }

      setDependentVariables(sortedValues);
      setSortTable(sortOption);
    },
    [sortTable, dependentVariables, projectYsSuccess],
  );

  useEffect(() => {
    setSortTable({} as SortTable);
  }, [project.columns]);

  const tableIsLoading =
    dependentVariablesSearched.length - dependentVariables.length > 0;

  return (
    <ContainerDependentVariable
      className="containerLinear"
      data-testid="container-dependent-variable"
    >
      <div>
        <Card textCard={translate('projectOverviewTitleTable')} />

        <ActionsContainer>
          <Input
            icon={<MagnifyingGlass size="1.25rem" />}
            testid="search-dependent-variables"
            placeholder={translate(
              'projectOverviewSearchDependentVariablePlaceholder',
            )}
            onChange={(event) => {
              handleSearchDependentVariable(event.target.value);
            }}
            error={
              searchError?.message &&
              translate(searchError.message).replace(
                'XX',
                String(searchError.quantityLetters),
              )
            }
          />
          <Button
            buttonType="secondary"
            icon={<Pencil size="1.125rem" />}
            onClick={() => setMenuVisible(true)}
            data-testid="button-adjust-settings"
            data-cy="button-adjust-settings"
          >
            {translate('projectOverviewAdjustSettings')}
          </Button>
        </ActionsContainer>
        {menuVisible && (
          <MenuContainer visible style={{ top: '4rem', right: '1.5rem' }}>
            <MenuOverlay
              data-testid="menu-overlay"
              visible
              onClick={() => setMenuVisible(false)}
            />

            <MenuOption
              position="start"
              type="button"
              data-testid="menu-button-customize-your-table"
              data-cy="menu-button-customize-your-table"
              onClick={handleCustomizeTable}
            >
              <NotePencil size="1.313rem" />
              <p>{translate('projectOverviewCustomizeYourTable')}</p>
            </MenuOption>
            <MenuOption
              position="end"
              type="button"
              data-testid="menu-button-rename-variables"
              data-cy="menu-button-rename-variables"
              onClick={handleRenameVariable}
            >
              <Swap size="1.313rem" />
              <p>{translate('projectOverviewRenameVariables')}</p>
            </MenuOption>
          </MenuContainer>
        )}
      </div>

      {!project?.id && !projectYsSuccess.length ? (
        <ContainerSkeleton />
      ) : (
        <>
          <ContentTableDependentVariables data-cy="div-table-dependent-variables">
            <Tooltip id="dependents-tooltip" className="customTooltipTheme" />

            {searchValue.length >= 1 &&
            !dependentVariablesSearched.length &&
            projectYsSuccess.length ? (
              <Status
                type="noSearchResults"
                title={`${translate(
                  'projectOverviewSearchDependentVariableNotFound',
                )} "${lastSearch}".`}
                dataCy="dependent-variable-not-found"
              />
            ) : (
              <Table data-cy="table-dependent-variables">
                <Thead>
                  <Tr>
                    <Th
                      key="th-column-dependent-variable"
                      // style={{ width: '40%' }}
                    >
                      {translate('projectOverviewFirstColumn')}
                    </Th>
                    {sortedColumn.map((column) =>
                      project.columns.includes(column) ? (
                        <Th key={`th-column-${column}`}>
                          <div>
                            <p
                              data-tooltip-id="dependents-tooltip"
                              data-tooltip-html={addTooltipSomeColumns(column)}
                            >
                              {adjustColumnName(column)}
                            </p>

                            {(column === 'MAPE' ||
                              column === 'WMAPE' ||
                              column === 'MPE' ||
                              column === 'MASE' ||
                              column === 'MASEs' ||
                              column === 'RMSE') && (
                              <TableSortButton
                                active={sortTable?.column === column}
                                onClick={() => handleSortTable(column)}
                                data-testid={`button-sort-by-${column
                                  .replaceAll(' ', '-')
                                  .toLocaleLowerCase()}`}
                                data-cy={`button-sort-by-${column
                                  .replaceAll(' ', '-')
                                  .toLocaleLowerCase()}`}
                                disabled={tableIsLoading}
                              >
                                {sortTable?.type === 'asc' &&
                                sortTable.column === column ? (
                                  <ArrowDown
                                    size="1rem"
                                    weight="bold"
                                    data-cy="sorted-by-asc"
                                    data-testid="sorted-by-asc"
                                  />
                                ) : sortTable?.type === 'desc' &&
                                  sortTable.column === column ? (
                                  // eslint-disable-next-line react/jsx-indent
                                  <ArrowUp
                                    size="1rem"
                                    weight="bold"
                                    data-cy="sorted-by-desc"
                                    data-testid="sorted-by-desc"
                                  />
                                ) : (
                                  <ArrowsDownUp
                                    size="1rem"
                                    weight="bold"
                                    data-cy="unsorted"
                                    data-testid="unsorted"
                                  />
                                )}
                              </TableSortButton>
                            )}
                          </div>
                        </Th>
                      ) : (
                        <></>
                      ),
                    )}
                  </Tr>
                </Thead>
                <Tbody>
                  {dependentVariables.map((dependentVariable, i) => (
                    <Tr
                      key={`tr-${dependentVariable.id}`}
                      data-cy={`dependent-variable-line-${i}`}
                      data-testid={`dependent-variable-line-${i}`}
                    >
                      <Td
                        data-cy={`dependent-variable-${dependentVariable.label
                          .toLowerCase()
                          .replaceAll(' ', '-')}-variable`}
                        data-testid={`dependent-variable-${dependentVariable.label
                          .toLowerCase()
                          .replaceAll(' ', '-')}-variable`}
                      >
                        <div>
                          <ButtonIcon
                            type="button"
                            data-testid={`button-select-or-deselect-variable-${dependentVariable.label}`}
                            data-cy={`button-select-or-deselect-variable-${dependentVariable.label}`}
                            color={
                              isVariableSelected(dependentVariable.id)
                                ? getColorOfSelectedVariableById(
                                    dependentVariable.id,
                                  )
                                : light.colors.gray4
                            }
                            disabled={
                              selectedVariables.length >= 4
                                ? !isVariableSelected(dependentVariable.id)
                                : false
                            }
                            onClick={() =>
                              selectOrDeselectVariable(
                                dependentVariable.id,
                                dependentVariable.label,
                              )
                            }
                          >
                            {isVariableSelected(dependentVariable.id) ? (
                              <Eye size="1.125rem" />
                            ) : (
                              <EyeClosed
                                size="1.125rem"
                                data-tooltip-id="dependents-tooltip"
                                data-tooltip-html={
                                  selectedVariables.length >= 4 &&
                                  !isVariableSelected(dependentVariable.id)
                                    ? translate('projectOverviewOnly4Variables')
                                    : undefined
                                }
                              />
                            )}
                          </ButtonIcon>

                          {dependentVariable.label}
                        </div>
                      </Td>
                      {sortedColumn.map((column, index) =>
                        project.columns.includes(column) ? (
                          <Td
                            key={`td-row-${
                              dependentVariable.id
                            }-${index.toString()}`}
                          >
                            {typeof dependentVariable[column] === 'number'
                              ? formatCompactNotation(
                                  dependentVariable[column] as number,
                                )
                              : column === 'Transformation'
                              ? translateTransformationText(
                                  (dependentVariable?.[column] ??
                                    '--') as string,
                                  user.language,
                                )
                              : dependentVariable[column]}
                          </Td>
                        ) : (
                          <></>
                        ),
                      )}
                    </Tr>
                  ))}

                  {Array.from(
                    {
                      length:
                        dependentVariablesSearched.length -
                        dependentVariables.length,
                    },
                    (_, index) => (
                      <Tr
                        key={`tr-loading-${index.toString()}`}
                        data-testid={`tr-loading-${index.toString()}`}
                      >
                        {Array.from(
                          { length: project.columns.length + 1 },
                          (__, indexAux) => (
                            <Td
                              key={`td-loading-${index.toString()}-${indexAux.toString()}`}
                            >
                              <ContainerSkeleton
                                withLoading={false}
                                style={{
                                  height: '16px',
                                }}
                              />
                            </Td>
                          ),
                        )}
                      </Tr>
                    ),
                  )}
                </Tbody>
              </Table>
            )}
          </ContentTableDependentVariables>
          <ContentChart>
            {errorChart ? (
              <ContainerMaintenance
                content={translate('projectOverviewChart')}
              />
            ) : !selectedVariables.length ? (
              <SelectVariable>
                <img src={computer} alt="" />
                <h4>{translate('projectOverviewSelectAVariable')}</h4>
                <p>{translate('projectOverviewSelectAVariableDescription')}</p>
              </SelectVariable>
            ) : loadingChart && selectedVariables.length === 1 ? (
              <ContainerSkeleton />
            ) : (
              <DependentVariablesChart
                variablesCharts={variableCharts}
                hasZoom={hasChartZoom}
              />
            )}
          </ContentChart>
        </>
      )}
    </ContainerDependentVariable>
  );
};
