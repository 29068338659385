import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Button } from 'src/components/Button';
import { CheckBox } from 'src/components/CheckBox';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Modal } from 'src/components/Modal';
import api from 'src/feature-store/service/api';
import { RootState } from 'src/redux/store';
import { ModalFooter } from 'src/components/Modal/Footer/styles';

import { DataSeriesIndicatorsInfo } from '../types';
import { ReviewSeries } from './ReviewSeries';
import {
  Container,
  Content,
  Description,
  Filter,
  FilterContent,
  FilterSeries,
  WarningMessage,
} from './styles';
import { DataDomains, DomainsProps, OpeningsProps } from './types';

interface CreateSeriesProps {
  visible: boolean;
  setVisible(visible: boolean): void;
  indicator: DataSeriesIndicatorsInfo;
}

export const CreateSeries: React.FC<CreateSeriesProps> = ({
  visible,
  setVisible,
  indicator,
}) => {
  const [regionsChecked, setRegionsChecked] = useState<DataDomains[]>([]);
  const [aggregationsChecked, setAggregationsChecked] = useState<DataDomains[]>(
    [],
  );
  const [primariesChecked, setPrimariesChecked] = useState<DataDomains[]>([]);
  const [secondariesChecked, setSecondariesChecked] = useState<DataDomains[]>(
    [],
  );

  const [modalReviewSeries, setModalReviewSeries] = useState<boolean>(false);
  const [openings, setOpenings] = useState<OpeningsProps[]>();

  const { language } = useSelector((state: RootState) => state.auth.user);
  const { t: translate } = useTranslation();
  const {
    data: domains,
    isLoading,
    isFetching,
    isError,
  } = useQuery(['data create series', indicator?.indicator_code], async () => {
    const { data } = await api.get<DomainsProps>('/domains');

    return data;
  });

  const handleSelectRegion = (
    name: {
      'pt-br': string;
      'en-us': string;
    },
    abbreviation: string,
  ) => {
    if (
      regionsChecked
        .map((region) => region.name['en-us'])
        .includes(name['en-us'])
    ) {
      setRegionsChecked(
        regionsChecked.filter(
          (region) => region.name['en-us'] !== name['en-us'],
        ),
      );
    } else {
      setRegionsChecked([...regionsChecked, { name, abbreviation }]);
    }
  };

  const handleSelectAggregation = (
    name: {
      'pt-br': string;
      'en-us': string;
    },
    abbreviation: string,
  ) => {
    if (
      aggregationsChecked
        .map((aggregation) => aggregation.name['en-us'])
        .includes(name['en-us'])
    ) {
      setAggregationsChecked(
        aggregationsChecked.filter(
          (aggregation) => aggregation.name['en-us'] !== name['en-us'],
        ),
      );
    } else {
      setAggregationsChecked([...aggregationsChecked, { name, abbreviation }]);
    }
  };

  const handleSelectPrimary = (
    name: {
      'pt-br': string;
      'en-us': string;
    },
    abbreviation: string,
  ) => {
    if (
      primariesChecked
        .map((primary) => primary.name['en-us'])
        .includes(name['en-us'])
    ) {
      setPrimariesChecked(
        primariesChecked.filter(
          (primary) => primary.name['en-us'] !== name['en-us'],
        ),
      );
    } else {
      setPrimariesChecked([...primariesChecked, { name, abbreviation }]);
    }
  };

  const handleSelectSecondary = (
    name: {
      'pt-br': string;
      'en-us': string;
    },
    abbreviation: string,
  ) => {
    if (
      secondariesChecked
        .map((secondary) => secondary.name['en-us'])
        .includes(name['en-us'])
    ) {
      setSecondariesChecked(
        secondariesChecked.filter(
          (secondary) => secondary.name['en-us'] !== name['en-us'],
        ),
      );
    } else {
      setSecondariesChecked([...secondariesChecked, { name, abbreviation }]);
    }
  };

  useEffect(() => {
    const filters: OpeningsProps[] = [];

    regionsChecked.forEach((region) => {
      aggregationsChecked.forEach((aggregation) => {
        primariesChecked.forEach((primary) => {
          secondariesChecked.forEach((secondary) => {
            filters.push({
              region,
              aggregation,
              primary,
              secondary,
            });
          });
        });
      });
    });

    setOpenings(filters);
  }, [
    aggregationsChecked,
    primariesChecked,
    regionsChecked,
    secondariesChecked,
  ]);

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <Container
        style={{ maxWidth: '1280px' }}
        data-testid="container-create-series"
      >
        {isError ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <ContainerMaintenance
              content="projects"
              text={translate('addNewSerieUnableToLoad')}
              data-testid="unable-to-load"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            />
          </div>
        ) : isLoading || isFetching ? (
          <>
            <ContainerSkeleton
              withLoading={false}
              style={{
                height: '100px',
              }}
            />
            <ContainerSkeleton
              withLoading={false}
              style={{
                height: '490px',
                marginTop: '10px',
              }}
            />
          </>
        ) : (
          <Content>
            <div>
              <Description>
                <h2>
                  {translate('addNewSerie').replace(
                    'XX',
                    indicator.name['en-us'],
                  )}
                </h2>
                <p>{translate('addNewSerieText')}</p>
              </Description>
              <FilterContent>
                <Filter style={{ marginLeft: '0px' }} data-cy="region">
                  <CheckBox
                    label={translate('addNewSerieRegion')}
                    onChange={({ target }) =>
                      domains?.regions && target.checked
                        ? setRegionsChecked(
                            domains?.regions.map((region) => ({
                              name: {
                                'en-us': region.name['en-us'],
                                'pt-br': region.name['pt-br'],
                              },
                              abbreviation: region.abbreviation,
                            })),
                          )
                        : setRegionsChecked([])
                    }
                    checked={regionsChecked.length === domains?.regions?.length}
                  />
                  <FilterSeries>
                    {domains?.regions.map((filter) => (
                      <CheckBox
                        key={filter.abbreviation}
                        label={`${filter.abbreviation} - ${
                          filter.name[language] ?? filter.name['en-us']
                        }`}
                        style={{ marginTop: '16px' }}
                        onChange={() =>
                          handleSelectRegion(
                            {
                              'pt-br': filter.name['pt-br'],
                              'en-us': filter.name['en-us'],
                            },
                            filter.abbreviation,
                          )
                        }
                        checked={regionsChecked.some(
                          (key) => key.abbreviation === filter.abbreviation,
                        )}
                      />
                    ))}
                  </FilterSeries>
                </Filter>
                <Filter data-cy="aggregation">
                  <CheckBox
                    label={translate('addNewSerieAggregation')}
                    onChange={({ target }) =>
                      domains?.aggregation && target.checked
                        ? setAggregationsChecked(
                            domains?.aggregation.map((aggregations) => ({
                              name: {
                                'en-us': aggregations.name['en-us'],
                                'pt-br': aggregations.name['pt-br'],
                              },
                              abbreviation: aggregations.abbreviation,
                            })),
                          )
                        : setAggregationsChecked([])
                    }
                    checked={
                      aggregationsChecked.length ===
                      domains?.aggregation?.length
                    }
                  />
                  <FilterSeries>
                    {domains?.aggregation.map((filter) => (
                      <CheckBox
                        key={filter.abbreviation}
                        label={`${filter.abbreviation} - ${
                          filter.name[language] ?? filter.name['en-us']
                        }`}
                        style={{ marginTop: '16px' }}
                        onChange={() =>
                          handleSelectAggregation(
                            filter.name,
                            filter.abbreviation,
                          )
                        }
                        checked={aggregationsChecked.some(
                          (key) => key.abbreviation === filter.abbreviation,
                        )}
                      />
                    ))}
                  </FilterSeries>
                </Filter>
                <Filter data-cy="transformPrimary">
                  <CheckBox
                    label={translate('addNewSeriePrimary')}
                    onChange={({ target }) =>
                      domains?.primary_transformations && target.checked
                        ? setPrimariesChecked(
                            domains?.primary_transformations.map((primary) => ({
                              name: {
                                'en-us': primary.name['en-us'],
                                'pt-br': primary.name['pt-br'],
                              },
                              abbreviation: primary.abbreviation,
                            })),
                          )
                        : setPrimariesChecked([])
                    }
                    checked={
                      primariesChecked.length ===
                      domains?.primary_transformations?.length
                    }
                  />
                  <FilterSeries>
                    {domains?.primary_transformations.map((filter) => (
                      <CheckBox
                        key={filter.abbreviation}
                        label={`${filter.abbreviation} - ${
                          filter.name[language] ?? filter.name['en-us']
                        }`}
                        style={{ marginTop: '16px' }}
                        onChange={() =>
                          handleSelectPrimary(filter.name, filter.abbreviation)
                        }
                        checked={primariesChecked.some(
                          (key) => key.abbreviation === filter.abbreviation,
                        )}
                      />
                    ))}
                  </FilterSeries>
                </Filter>
                <Filter
                  data-cy="transformSecondary"
                  style={{ border: 'unset' }}
                >
                  <CheckBox
                    label={translate('addNewSerieSecondary')}
                    onChange={({ target }) =>
                      domains?.secondary_transformations && target.checked
                        ? setSecondariesChecked(
                            domains?.secondary_transformations.map(
                              (secondary) => ({
                                name: {
                                  'en-us': secondary.name['en-us'],
                                  'pt-br': secondary.name['pt-br'],
                                },
                                abbreviation: secondary.abbreviation,
                              }),
                            ),
                          )
                        : setSecondariesChecked([])
                    }
                    checked={
                      secondariesChecked.length ===
                      domains?.secondary_transformations?.length
                    }
                  />
                  <FilterSeries>
                    {domains?.secondary_transformations.map((filter) => (
                      <CheckBox
                        key={filter.abbreviation}
                        label={`${filter.abbreviation} - ${
                          filter.name[language] ?? filter.name['en-us']
                        }`}
                        style={{ marginTop: '16px' }}
                        onChange={() =>
                          handleSelectSecondary(
                            filter.name,
                            filter.abbreviation,
                          )
                        }
                        checked={secondariesChecked.some(
                          (key) => key.abbreviation === filter.abbreviation,
                        )}
                      />
                    ))}
                  </FilterSeries>
                </Filter>
              </FilterContent>
            </div>
          </Content>
        )}
        {openings && openings.length >= 150 ? (
          <ModalFooter style={{ justifyContent: 'space-between' }}>
            <WarningMessage>
              <p data-testid="warning-message">
                {translate('addNewSeriesWarning')}
              </p>
            </WarningMessage>
            <div style={{ display: 'flex', gap: '2rem' }}>
              <Button
                data-testid="button-cancel"
                buttonType="naked"
                onClick={() => {
                  setVisible(false);
                }}
              >
                {translate('cancel')}
              </Button>
              <Button
                buttonType="primary"
                data-testid="button-proceed"
                disabled={
                  regionsChecked.length === 0 ||
                  aggregationsChecked.length === 0 ||
                  primariesChecked.length === 0 ||
                  secondariesChecked.length === 0 ||
                  openings.length >= 30 ||
                  isLoading ||
                  isFetching
                }
              >
                {translate('addNewSerieContinue')}
              </Button>
            </div>
          </ModalFooter>
        ) : (
          <ModalFooter>
            <Button
              data-testid="button-cancel"
              buttonType="naked"
              onClick={() => {
                setVisible(false);
              }}
            >
              {translate('cancel')}
            </Button>
            <Button
              buttonType="primary"
              onClick={() => {
                setModalReviewSeries(true);
              }}
              data-testid="button-proceed"
              disabled={
                regionsChecked.length === 0 ||
                aggregationsChecked.length === 0 ||
                primariesChecked.length === 0 ||
                secondariesChecked.length === 0 ||
                isLoading ||
                isFetching
              }
            >
              {translate('addNewSerieContinue')}
            </Button>
          </ModalFooter>
        )}
      </Container>

      {modalReviewSeries && (
        <ReviewSeries
          visible={modalReviewSeries}
          setVisible={() => {
            setModalReviewSeries(false);
            setVisible(false);
          }}
          openings={openings}
          indicator_code={indicator?.indicator_code}
        />
      )}
    </Modal>
  );
};
