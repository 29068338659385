import styled from 'styled-components';
import light from 'src/styles/themes/light';

const LEVEL_COLORS = {
  great: light.colors.teal4,
  good: light.colors.green4,
  average: 'rgba(214, 158, 46, 1)',
  poor: light.colors.red4,
} as const;

const LEVEL_BACKGROUND = {
  great: 'rgba(56, 178, 172, 0.12)',
  good: 'rgba(72, 187, 120, 0.12)',
  average: 'rgba(214, 158, 46, 0.12)',
  poor: 'rgba(245, 101, 101, 0.12);',
} as const;

type LevelProps = {
  level: keyof typeof LEVEL_COLORS;
};

export const Container = styled.div<LevelProps>`
  display: flex;
  padding: 0.25rem 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;

  border-radius: 62.4375rem;

  color: ${({ level }) => LEVEL_COLORS[level]};
  background: ${({ level }) => LEVEL_BACKGROUND[level]};

  p {
    font-family: Inter;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
