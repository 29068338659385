import styled from 'styled-components';

export const Container = styled.div`
  width: 1.75rem;
  height: 1.75rem;

  position: relative;
`;

interface ChartTooltipProps {
  signal: 'positive' | 'negative';
  visible: boolean;
}

export const ChartTooltip = styled.div<ChartTooltipProps>`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  max-width: 12.813rem;
  /* max-width: 165px; */
  min-height: 2.5rem;
  height: auto !important;
  position: absolute;
  z-index: 4;
  opacity: 1 !important;

  border: 1px solid ${({ theme }) => theme.colors.black} !important;
  background: white;

  p {
    color: ${({ theme }) => theme.colors.black} !important;
    font-size: 0.875rem !important;
    font-weight: 400 !important;
    z-index: 6 !important;
  }

  > div {
    position: relative;
    width: 100%;
    height: auto !important;
    /* padding: 2px 2px 2px 6px; */
    padding: 0.125rem 0.125rem 0.375rem 0.375rem;
    background: ${({ theme, signal }) =>
      signal === 'positive' ? theme.colors.primary : theme.colors.secondary};

    p::after {
      display: block;
      content: '';
      position: absolute;
      /* left: -5px;
      top: 35%; */
      bottom: -0.375rem;
      left: 47%;
      z-index: 4;

      width: 0.625rem;
      height: 0.625rem;

      /* border-left: 1px solid black; */
      border-right: 1px solid black;
      border-bottom: 1px solid black;
      transform: rotate(45deg);
      background: ${({ theme, signal }) =>
        signal === 'positive' ? theme.colors.primary : theme.colors.secondary};
    }
  }
`;
