import styled from 'styled-components';

export const SelectSeriesContainer = styled.div`
  flex: 1;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  margin-top: 1rem;

  height: 30rem;
  overflow-y: auto;

  max-height: 30rem;
`;

type SerieCardProps = {
  tagColor: string;
};

export const SerieCard = styled.button<SerieCardProps>`
  display: flex;
  align-items: center;

  padding: 1rem;

  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 0.5rem;

  label {
    padding-left: 2.125rem;
    pointer-events: none;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.25rem;

    h4 {
      padding: 0.25rem 0.75rem;
      background: ${({ tagColor }) => tagColor}1E;
      border-radius: 9999px;

      font-weight: 500;
      font-size: 0.875rem;

      color: ${({ tagColor }) => tagColor};
    }

    p {
      font-weight: 400;
      font-size: 0.75rem;
      text-align: start;

      color: ${({ theme }) => theme.colors.gray4};
    }
  }

  :disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;
