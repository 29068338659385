export const AIChatPTBRTranslations = {
  aiChatWelcomeTitle: 'Bem-vindo ao chat da 4intelligence!',
  // aiChatWelcomeDescription:
  //   'Digite o que precisa na caixa abaixo ou selecione algum dos exemplos de pergunta:',
  aiChatWelcomeDescription: 'Digite o que precisa na caixa abaixo',
  aiChatFirstSampleQuestion:
    'Quais variáveis climáticas podem influenciar as vendas do setor de alimentos e bebidas?',
  aiChatSecondSampleQuestion:
    'Quais variáveis estão mais correlacionadas com o resultado da PMC?',
  aiChatQuestionPlaceholder: 'Me pergunte algo...',
  aiChatTitleError: 'Não foi possível carregar este conteúdo',
  aiChatDescriptionError:
    'No momento essa funcionalidade não está disponível. Tente novamente mais tarde.',
  aiChatQuestionCharacters: 'Número máximo de caracteres: 2000',
  aiChatQuestionLimitTitle: 'Você atingiu o limite mensal de perguntas',
  aiChatQuestionLimitDescription:
    'Não se preocupe, você poderá continuar conversando com o Chat 4i nos próximos dias.',
  aiChatNoDataResponse: 'Não temos dados para responder essa pergunta',
};
