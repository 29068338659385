import styled from 'styled-components';

export const ResultsWaterfallContainer = styled.div`
  padding: 1.5rem;

  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 8px;
`;

export const SeeOthersVars = styled.div`
  div {
    margin-bottom: 0;
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;

    p {
      margin-right: 0;
    }
  }
`;
