import React, { useContext } from 'react';

import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { FeatureStoreSidebarContext } from 'src/feature-store/Contexts/NavigationContext';

import { FavoriteContainer } from './styles';
import { FavoriteProps } from './types';

export const Favorite: React.FC<FavoriteProps> = ({
  isActive,
  isLoading,
  idFavoriteViewing,
  name,
  favoriteType,
}) => {
  const {
    setterIdUserFavoriteViewingHome,
    setterIdHighlightsFavoriteViewingHome,
  } = useContext(FeatureStoreSidebarContext);

  if (isLoading) {
    return (
      <FavoriteContainer isActive={false} isLoading={isLoading}>
        <ContainerSkeleton
          withLoading={false}
          style={{
            width: `${Math.random() * (270 - 120) + 120}px`,
            height: '39px',
          }}
          data-testid="skeleton-favorite"
        />
      </FavoriteContainer>
    );
  }

  return (
    <FavoriteContainer
      isActive={isActive}
      onClick={() => {
        favoriteType === 'user'
          ? setterIdUserFavoriteViewingHome(idFavoriteViewing)
          : setterIdHighlightsFavoriteViewingHome(idFavoriteViewing);
      }}
      data-testid={`container-favorite-${name}`
        .replaceAll(' ', '-')
        .toLocaleLowerCase()}
    >
      {name}
    </FavoriteContainer>
  );
};
