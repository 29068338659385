import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useFormatDateLanguage } from 'src/hooks/useFormatDateLanguage';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';

import {
  Author,
  Container,
  Content,
  DateTime,
  FooterContainer,
} from './styles';
import { FlowMessageProps } from './types';

export const FlowMessage: React.FC<FlowMessageProps> = ({
  isLoading,
  author,
  dateTime,
  message,
}) => {
  const [showCompletedMessage, setShowCompletedMessage] = useState(false);

  const { t: translate } = useTranslation();
  const translateFormat = useFormatDateLanguage();

  const handleSeeCompletedMessage = () => {
    setShowCompletedMessage(true);
  };

  const handleSeeShortMessage = () => {
    setShowCompletedMessage(false);
  };

  const informationsEmpty = !dateTime || !message || !author;

  if (informationsEmpty || (isLoading && informationsEmpty)) {
    return (
      <ContainerSkeleton
        withLoading={false}
        style={{ width: '25rem', height: '4.438rem' }}
        data-testid="loading-message"
      />
    );
  }

  const formattedDataTime = format(
    new Date(dateTime),
    `${translateFormat}, HH:mm`,
  );

  const showSeeMoreButton = message.length > 75 || message.includes('\n');
  const breakLinePosition = message.indexOf('\n');

  let shortMessage = message;

  if (showSeeMoreButton) {
    if (breakLinePosition !== -1 && breakLinePosition <= 75) {
      shortMessage = `${message.substring(0, breakLinePosition)}...`;
    } else {
      shortMessage = `${message.substring(0, 75)}...`;
    }
  }

  return (
    <Container data-testid="container-flow-message">
      <Content>
        <p data-testid="text-message">
          &quot;{showCompletedMessage ? message : shortMessage}&quot;{' '}
          {showSeeMoreButton && !showCompletedMessage && (
            <button
              type="button"
              onClick={handleSeeCompletedMessage}
              data-testid="button-see-more"
            >
              {translate('workspaceOverviewPlanningFlowSeeMore')}
            </button>
          )}
          {showCompletedMessage && (
            <button
              type="button"
              onClick={handleSeeShortMessage}
              data-testid="button-see-less"
            >
              {translate('workspaceOverviewPlanningFlowSeeLess')}
            </button>
          )}
        </p>
      </Content>

      <FooterContainer>
        <Author>
          <div data-testid="container-author-letter">
            {author[0].toUpperCase()}
          </div>

          <p data-testid="text-author">{author}</p>
        </Author>

        <DateTime data-testid="text-datetime">{formattedDataTime}</DateTime>
      </FooterContainer>
    </Container>
  );
};
