const overviewPTBR = {
  overview: 'Visão Geral',
  data_view: 'Visualizar Dados',
  historical_data: 'Dados Históricos',
  correlation: 'Correlação',
  potential_outliers: 'Potenciais Outliers (valores atípicos/anomalias)',
  arima_method: 'Método Arima',
  sarima_method: 'Método Sarima',
  cooksd_method: 'Método Distância de Cook',
  stl_decomposition: 'Decomposição STL',
  trend: 'Tendência',
  seasonal: 'Sazonalidade',
  remainder: 'Resíduo',
  historicalDataYAxisTitle: 'Valor',
  historicalDate: 'Data',
  historicalValue: 'Valor',
  historicalTransformation: 'Transformação',
  overviewNoExplanatoryVariableSelected:
    'Nenhuma variável explicativa selecionada.',
  decompositionDate: 'Data',
  decompositionSeasonal: 'Sazonalidade',
  decompositionTrend: 'Tendência',
  decompositionRemainder: 'Resíduo',
  dataViewErrorSelectAtLeastOne: 'Selecione pelo menos uma transformação.',
  dataViewPotenciasOutliersNotIdentified: 'Não identificado',
};

export default overviewPTBR;
