import styled from 'styled-components';

export const ContentLatestData = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;

  > div {
    > div {
      text-align: right;

      p {
        margin-right: 0px;
      }
    }
  }

  input {
    display: block !important;
    margin-left: auto !important;
  }
`;
