const metricsByPeriodPTBRTranslations = {
  metricsByPeriodTitle: 'Métricas por período',
  metricsByPeriodDescription:
    'Veja a performance de cada modelo agregada por período de tempo.',
  metricsFilterTitle: 'Período',
  metricsMonthsAhead: '"X" meses a frente',
  accuracyLevel: 'Nível de acerto',
  metricLabel: 'Métrica',
};

export default metricsByPeriodPTBRTranslations;
