import styled from 'styled-components';

interface ContainerProps {
  isErrorModal?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 1.5rem 0px;
  gap: 0.75rem;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 24px;
  max-width: 43.75rem;

  svg {
    color: ${({ theme, isErrorModal }) =>
      isErrorModal ? theme.colors.red5 : theme.colors.yellow6};
  }

  h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem !important;
    line-height: 2rem;
    color: ${({ theme }) => theme.colors.gray6};
    text-align: center;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray5};
    text-align: center;
  }
`;

export const ButtonsContent = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.gray2};
  background: ${({ theme }) => theme.colors.gray0};
  width: calc(100% + 3rem);
  height: 5.875rem;
  padding: 1.5rem 1.5rem;
  border-radius: 0 0 8px 8px;
  margin: 3rem 0 0 0;

  display: flex;
  justify-content: flex-end;

  button {
    display: flex;
    justify-content: center;
  }
`;
