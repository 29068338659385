export const ModalManagerENTranslate = {
  modalManagerEditManager: 'Edit managers',
  modalManager: 'Managers',
  modalManagerErrorLoadManagers: 'Unable to load managers.',
  modalManagerProductsAccess: 'Product access',
  modalManagerRemoveManager: 'Delete manager',
  modalManagerAddManager: '+ Add manager',
  modalManagerRemoveManagerButton: '- Remove manager',
  modalManagerErrorAddNewManagerTitle: 'Unable to add new manager',
  modalManagerErrorAddNewManagerDescription:
    'This email is already registered for another customer',
  modalConfirmDeleteDescription:
    'Are you sure you want to remove this manager?',
  modalConfirmDeleteErrorTitle: 'Error deleting manager',

  modalConfirmDeleteErrorDescription: 'Unable to remove this manager',
  modalManagerErrorEditManager:
    'An error occurred when trying to edit a manager',
};
