const modalAdvancedPTBRTranslations = {
  rankingCriteria: 'Critério de classificação',
  rankingCriteriaDescr:
    'Customize as medidas estatísticas usadas para avaliar o desempenho dos modelos.',
  accuracyCriteriaLabel: 'Critério de acurácia',
  accuracyCriteriaPlaceholder: 'Choose a variable',
  accuracyCriteriaInfo:
    'Qual critério para medir a precisão da previsão durante a validação cruzada (pode ser MAPE, WMAPE, MPE ou RMSE)',
  crossValidationSummaryLabel: 'Resumo da validação-cruzada',
  crossValidationSummaryPlaceholder: 'Escolha uma variável',
  crossValidationSummaryInfo:
    'Devemos calcular a estatística-resumo das medidas de acurácia usando a média ou a mediana? A média é a mais usual; no entanto, a mediana é mais robusta à outliers, podendo ser uma métrica mais adequada quando o período de validação cruzada for afetada por situações extremas (como a pandemia de covid 19).',
  crossValidationSummaryOptMedian: 'Mediana',
  crossValidationSummaryOptMean: 'Média',

  explanatoryFeatures: 'Variáveis explicativas',
  explanatoryFeaturesDescr:
    'Opções adicionais sobre como tratamos o conjunto de variáveis explicativas (features).',
  avoidCollinearity: 'Impedir colinearidade',
  avoidCollinearityInfo: `A presença colinearidade prejudica a interpretação dos coeficientes; por exemplo, pode levar a sinais de coeficiente errados. De fato, se duas variáveis forem altamente correlacionadas, elas compartilham muitas informações; nossos algoritmos levam isso em consideração, evitando variáveis altamente correlacionadas na mesma estimação.`,
  explVarAutoForecast: 'Projeção automática das variáveis explicativas',
  explVarAutoForecastInfo:
    'Preveja variáveis explicativas para evitar valores ausentes no período de previsão.',
  allowDrift: 'Permitir drift',
  allowDriftInfo:
    'Parâmetro que quando TRUE permite que os modelos ARIMA busquem a melhor especificação levando em consideração o termo de "drift" (isto é, uma constante adicionada a todos os períodos da série já diferenciada).',

  configureExclusion: 'Configurar exclusões',
  configureExclusionDescr:
    'Defina até 3 grupos de variáveis explicativas que não devem entrar juntas em um mesmo modelo. Cada grupo definido deve conter pelo menos 2 variáveis.',
  group1: 'Grupo 1',
  group2: 'Grupo 2',
  group3: 'Grupo 3',
  exclusionsPlaceholder: 'Variáveis',

  featureSelection: 'Seleção de variáveis',
  featureSelectionDescr:
    'Customize a forma como as variáveis serão pré-selecionadas antes de serem testadas pelos modelos. A configuração afeta apenas modelos lineares.',
  lasso: 'Lasso',
  lassoInfo:
    'Variáveis são selecionadas utilizando os coeficientes diferentes de zero na estimação de um Lasso (método paramétrico).',
  randomForest: 'Random Forest',
  randomForestInfo:
    'Variáveis são selecionadas a partir do resultado no ranking de importância obtida por meio de permutação de features de um Random Forest (método não paramétrico).',
  correlationFilter: 'Filtro de correlação',
  correlationFilterInfo: `Variáveis são selecionadas a partir de uma algoritmo que leva em conta as correlações de Pearson `,
  mathodsDisabledMessage: 'Selecione pelo menos um método.',
  goldenVariables: 'Variáveis de ouro',
  goldenVariablesPlaceholder: 'Variáveis',
  goldenVariablesInfo:
    'Aqui você pode adicionar variáveis que gostaria de ver, com certeza, em ao menos um dos modelos estimados.',
  goldenVariablesWarning: `Definir variáveis de ouro pode prejudicar os resultados finais de precisão. Os impactos são mais fortes quanto mais variáveis douradas você escolher.`,
  additionalConfigurationTitle: 'Configuração Adicional',
  additionalConfigurationTitleDescription:
    'Defina os parâmetros da validação cruzada e a configuração técnica de seu processo de modelagem.',
};

export default modalAdvancedPTBRTranslations;
