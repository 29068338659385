import React, { useCallback, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Card } from 'src/components/Card';
import { Input } from 'src/components/Input';
import {
  ArrowDown,
  ArrowUp,
  ArrowsDownUp,
  MagnifyingGlass,
} from 'phosphor-react';
import { Select } from 'src/components/Select';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { useQuery } from 'react-query';
import api from 'src/models/service/api';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Tooltip } from 'react-tooltip';
import { Status } from 'src/components/Status';
import { formatCompactNotation } from 'src/utils/numbers/formatCompactNotation';

import {
  ActiveTR,
  ContainerHead,
  TableContent,
  TableSortButton,
} from './styles';

type TableSort = {
  column: string;
  sort: 'crescent' | 'descend';
};

type PeriodData = {
  mape: number;
  mpe: number;
  rmse: number;
  accuracy: number;
};

type PerformanceByPeriodData = {
  variable: string;
  updated: boolean;
  periods: (PeriodData | null)[];
}[];

type Metric = 'accuracy' | 'mape' | 'mpe' | 'rmse';

const options: Metric[] = ['accuracy', 'mape', 'mpe', 'rmse'];

export const PerformanceByPeriodTable: React.FC = () => {
  const { t: translate } = useTranslation();
  const { project } = useSelector((state: RootState) => state);

  const [metric, setMetric] = useState<Metric>('accuracy');
  const [searchValue, setSearchValue] = useState('');
  const [sortTableBy, setSortTableBy] = useState<TableSort>();

  const {
    data: performanceByPeriodData,
    isError,
    isFetching,
    isLoading,
  } = useQuery<PerformanceByPeriodData>(
    ['performance by period', project?.id],
    async () => {
      const { data } = await api.get<PerformanceByPeriodData>(
        `/projects/${project?.id}/performance/performance-by-period`,
      );

      return data;
    },
    {
      staleTime: 1000 * 60 * 10,
      enabled: !!project.id,
    },
  );

  const sortTable = useCallback(
    (
      items: PerformanceByPeriodData,
      column: string,
      order: 'crescent' | 'descend',
    ) => {
      const itemsToSort = [...items];
      let orderedItems = [...items];

      switch (column) {
        case 'dependent variable':
          orderedItems = itemsToSort.sort((itemA, itemB) =>
            order === 'crescent'
              ? itemA.variable.localeCompare(itemB.variable)
              : itemB.variable.localeCompare(itemA.variable),
          );
          break;
        case '1M':
          if (order === 'crescent') {
            orderedItems = itemsToSort.sort((itemA, itemB) => {
              if (
                itemA.periods !== null &&
                itemB.periods !== null &&
                itemA.periods[0] &&
                itemB.periods[0]
              ) {
                return metric === 'mape'
                  ? itemA.periods[0].mape - itemB.periods[0].mape
                  : metric === 'mpe'
                  ? itemA.periods[0].mpe - itemB.periods[0].mpe
                  : metric === 'rmse'
                  ? itemA.periods[0].rmse - itemB.periods[0].rmse
                  : itemA.periods[0].accuracy - itemB.periods[0].accuracy;
              }
              return 0;
            });
          } else {
            orderedItems = itemsToSort.sort((itemA, itemB) => {
              if (
                itemA.periods !== null &&
                itemB.periods !== null &&
                itemA.periods[0] &&
                itemB.periods[0]
              ) {
                return metric === 'mape'
                  ? itemB.periods[0].mape - itemA.periods[0].mape
                  : metric === 'mpe'
                  ? itemB.periods[0].mpe - itemA.periods[0].mpe
                  : metric === 'rmse'
                  ? itemB.periods[0].rmse - itemA.periods[0].rmse
                  : itemB.periods[0].accuracy - itemA.periods[0].accuracy;
              }
              return 0;
            });
          }

          break;
        case '3M':
          if (order === 'crescent') {
            orderedItems = itemsToSort.sort((itemA, itemB) => {
              if (
                itemA.periods !== null &&
                itemB.periods !== null &&
                itemA.periods[1] &&
                itemB.periods[1]
              ) {
                return metric === 'mape'
                  ? itemA.periods[1].mape - itemB.periods[1].mape
                  : metric === 'mpe'
                  ? itemA.periods[1].mpe - itemB.periods[1].mpe
                  : metric === 'rmse'
                  ? itemA.periods[1].rmse - itemB.periods[1].rmse
                  : itemA.periods[1].accuracy - itemB.periods[1].accuracy;
              }
              return 0;
            });
          } else {
            orderedItems = itemsToSort.sort((itemA, itemB) => {
              if (
                itemA.periods !== null &&
                itemB.periods !== null &&
                itemA.periods[1] &&
                itemB.periods[1]
              ) {
                return metric === 'mape'
                  ? itemB.periods[1].mape - itemA.periods[1].mape
                  : metric === 'mpe'
                  ? itemB.periods[1].mpe - itemA.periods[1].mpe
                  : metric === 'rmse'
                  ? itemB.periods[1].rmse - itemA.periods[1].rmse
                  : itemB.periods[1].accuracy - itemA.periods[1].accuracy;
              }
              return 0;
            });
          }

          break;
        case '6M':
          if (order === 'crescent') {
            orderedItems = itemsToSort.sort((itemA, itemB) => {
              if (
                itemA.periods !== null &&
                itemB.periods !== null &&
                itemA.periods[2] &&
                itemB.periods[2]
              ) {
                return metric === 'mape'
                  ? itemA.periods[2].mape - itemB.periods[2].mape
                  : metric === 'mpe'
                  ? itemA.periods[2].mpe - itemB.periods[2].mpe
                  : metric === 'rmse'
                  ? itemA.periods[2].rmse - itemB.periods[2].rmse
                  : itemA.periods[2].accuracy - itemB.periods[2].accuracy;
              }
              return 0;
            });
          } else {
            orderedItems = itemsToSort.sort((itemA, itemB) => {
              if (
                itemA.periods !== null &&
                itemB.periods !== null &&
                itemA.periods[2] &&
                itemB.periods[2]
              ) {
                return metric === 'mape'
                  ? itemB.periods[2].mape - itemA.periods[2].mape
                  : metric === 'mpe'
                  ? itemB.periods[2].mpe - itemA.periods[2].mpe
                  : metric === 'rmse'
                  ? itemB.periods[2].rmse - itemA.periods[2].rmse
                  : itemB.periods[2].accuracy - itemA.periods[2].accuracy;
              }
              return 0;
            });
          }

          break;
        case '12M':
          if (order === 'crescent') {
            orderedItems = itemsToSort.sort((itemA, itemB) => {
              if (
                itemA.periods !== null &&
                itemB.periods !== null &&
                itemA.periods[3] &&
                itemB.periods[3]
              ) {
                return metric === 'mape'
                  ? itemA.periods[3].mape - itemB.periods[3].mape
                  : metric === 'mpe'
                  ? itemA.periods[3].mpe - itemB.periods[3].mpe
                  : metric === 'rmse'
                  ? itemA.periods[3].rmse - itemB.periods[3].rmse
                  : itemA.periods[3].accuracy - itemB.periods[3].accuracy;
              }
              return 0;
            });
          } else {
            orderedItems = itemsToSort.sort((itemA, itemB) => {
              if (
                itemA.periods !== null &&
                itemB.periods !== null &&
                itemA.periods[3] &&
                itemB.periods[3]
              ) {
                return metric === 'mape'
                  ? itemB.periods[3].mape - itemA.periods[3].mape
                  : metric === 'mpe'
                  ? itemB.periods[3].mpe - itemA.periods[3].mpe
                  : metric === 'rmse'
                  ? itemB.periods[3].rmse - itemA.periods[3].rmse
                  : itemB.periods[3].accuracy - itemA.periods[3].accuracy;
              }
              return 0;
            });
          }

          break;
        default:
          break;
      }

      return orderedItems;
    },
    [metric],
  );

  const performanceByPeriodDataQueried = useMemo(() => {
    if (searchValue !== '' && performanceByPeriodData) {
      const result = performanceByPeriodData.filter((data) =>
        data.variable.includes(searchValue),
      );

      if (sortTableBy) {
        return sortTable(result, sortTableBy.column, sortTableBy.sort);
      }
      return result;
    }
    if (searchValue === '') {
      if (sortTableBy && performanceByPeriodData) {
        return sortTable(
          performanceByPeriodData,
          sortTableBy.column,
          sortTableBy.sort,
        );
      }
      return performanceByPeriodData ?? [];
    }
  }, [performanceByPeriodData, searchValue, sortTable, sortTableBy]);

  const selectMetric = (value: Metric) => {
    setMetric(value);
  };

  const handleSortTable = (columnName: string) => {
    if (sortTableBy?.sort === 'descend' && columnName === sortTableBy?.column) {
      setSortTableBy(undefined);
      return;
    }

    if (sortTableBy?.column === columnName) {
      setSortTableBy({
        column: columnName,
        sort: 'descend',
      });
    } else {
      setSortTableBy({
        column: columnName,
        sort: 'crescent',
      });
    }
  };

  function formatNumberToShow(value: number) {
    if (metric !== 'rmse') {
      return `${formatCompactNotation(value * 100)}%`;
    }
    return formatCompactNotation(value);
  }

  return (
    <div className="containerLinear">
      <ContainerHead>
        <Card
          textCard={translate('performanceByPeriodTitle')}
          textDescription={translate('performanceByPeriodDescription')}
        />
        <Input
          icon={<MagnifyingGlass size="1.25rem" />}
          testid="input-search-variables"
          onChange={(event) => setSearchValue(event.target.value)}
          placeholder={translate('performanceByPeriodSearchPlaceholder')}
          style={{ minWidth: '17.5rem', maxWidth: '17.5rem' }}
          value={searchValue}
          disabled={isError || isLoading}
        />
      </ContainerHead>
      <Select
        label={translate('performanceByPeriodMetricLabel')}
        information={translate('performanceByPeriodTooltip')}
        options={options.map((option) => ({
          label:
            option === 'accuracy'
              ? translate('accuracyLevel')
              : option.toUpperCase(),
          value: option,
        }))}
        onChange={(option: any) => selectMetric(option.value)}
        value={{
          label:
            metric !== 'accuracy'
              ? metric.toUpperCase()
              : translate('accuracyLevel'),
          value: metric,
        }}
        style={{
          margin: '1.5rem 0',
          maxWidth: '17.0625rem',
        }}
        isDisabled={isError || isLoading}
      />

      {isError ? (
        <ContainerMaintenance
          content="table"
          data-testid="performance-by-period-error"
        />
      ) : isLoading || isFetching || !performanceByPeriodData ? (
        <TableContent>
          <table>
            <thead>
              <tr>
                {Array.from({ length: 5 }).map((_, index) => (
                  <th key={`loading-th-${index + 1}`} aria-label="loading">
                    <ContainerSkeleton
                      withLoading={false}
                      style={{ width: '133px', height: '22px' }}
                      data-testid={`loading-performance-by-period-${index}`}
                    />
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Array.from({ length: 9 }).map((_, index) => (
                <tr key={`loading-tr-${index + 1}`}>
                  {Array.from({ length: 5 }).map((_TD, indexTD) => (
                    <td key={`loading-td-${indexTD + 1}`} aria-label="loading">
                      <ContainerSkeleton
                        withLoading={false}
                        style={{
                          width: indexTD === 0 ? '70px' : '20px',
                          height: '22px',
                        }}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </TableContent>
      ) : (
        <TableContent>
          <table>
            <thead>
              <tr>
                <th>
                  <div>
                    <p>{translate('performanceByPeriodDepVariable')}</p>
                    <TableSortButton
                      active={sortTableBy?.column === 'dependent variable'}
                      onClick={() => handleSortTable('dependent variable')}
                      data-testid="sort-by-dependent-variable"
                    >
                      {sortTableBy?.sort === 'crescent' &&
                      sortTableBy.column === 'dependent variable' ? (
                        <ArrowDown
                          size="0.5625rem"
                          weight="bold"
                          data-testid="sorted-by-lower"
                        />
                      ) : sortTableBy?.sort === 'descend' &&
                        sortTableBy.column === 'dependent variable' ? (
                        // eslint-disable-next-line react/jsx-indent
                        <ArrowUp
                          size="0.5625rem"
                          weight="bold"
                          data-testid="sorted-by-higher"
                        />
                      ) : (
                        <ArrowsDownUp
                          size="0.5625rem"
                          weight="bold"
                          data-testid="unsorted"
                        />
                      )}
                    </TableSortButton>
                  </div>
                </th>
                <th>
                  <div>
                    <p>
                      {translate('metricsMonthsAhead')
                        .replace('"X"', '1')
                        .replace('months', 'month')
                        .replace('meses', 'mês')}
                    </p>
                    <TableSortButton
                      active={sortTableBy?.column === '1M'}
                      onClick={() => handleSortTable('1M')}
                      data-testid="sort-by-1m"
                    >
                      {sortTableBy?.sort === 'crescent' &&
                      sortTableBy.column === '1M' ? (
                        <ArrowDown
                          size="0.5625rem"
                          weight="bold"
                          data-testid="sorted-by-lower"
                        />
                      ) : sortTableBy?.sort === 'descend' &&
                        sortTableBy.column === '1M' ? (
                        // eslint-disable-next-line react/jsx-indent
                        <ArrowUp
                          size="0.5625rem"
                          weight="bold"
                          data-testid="sorted-by-higher"
                        />
                      ) : (
                        <ArrowsDownUp
                          size="0.5625rem"
                          weight="bold"
                          data-testid="unsorted"
                        />
                      )}
                    </TableSortButton>
                  </div>
                </th>
                <th>
                  <div>
                    <p>{translate('metricsMonthsAhead').replace('"X"', '3')}</p>
                    <TableSortButton
                      active={sortTableBy?.column === '3M'}
                      onClick={() => handleSortTable('3M')}
                      data-testid="sort-by-3m"
                    >
                      {sortTableBy?.sort === 'crescent' &&
                      sortTableBy.column === '3M' ? (
                        <ArrowDown
                          size="0.5625rem"
                          weight="bold"
                          data-testid="sorted-by-lower"
                        />
                      ) : sortTableBy?.sort === 'descend' &&
                        sortTableBy.column === '3M' ? (
                        // eslint-disable-next-line react/jsx-indent
                        <ArrowUp
                          size="0.5625rem"
                          weight="bold"
                          data-testid="sorted-by-higher"
                        />
                      ) : (
                        <ArrowsDownUp
                          size="0.5625rem"
                          weight="bold"
                          data-testid="unsorted"
                        />
                      )}
                    </TableSortButton>
                  </div>
                </th>
                <th>
                  <div>
                    <p>{translate('metricsMonthsAhead').replace('"X"', '6')}</p>
                    <TableSortButton
                      active={sortTableBy?.column === '6M'}
                      onClick={() => handleSortTable('6M')}
                      data-testid="sort-by-6m"
                    >
                      {sortTableBy?.sort === 'crescent' &&
                      sortTableBy.column === '6M' ? (
                        <ArrowDown
                          size="0.5625rem"
                          weight="bold"
                          data-testid="sorted-by-lower"
                        />
                      ) : sortTableBy?.sort === 'descend' &&
                        sortTableBy.column === '6M' ? (
                        // eslint-disable-next-line react/jsx-indent
                        <ArrowUp
                          size="0.5625rem"
                          weight="bold"
                          data-testid="sorted-by-higher"
                        />
                      ) : (
                        <ArrowsDownUp
                          size="0.5625rem"
                          weight="bold"
                          data-testid="unsorted"
                        />
                      )}
                    </TableSortButton>
                  </div>
                </th>
                <th>
                  <div>
                    <p>
                      {translate('metricsMonthsAhead').replace('"X"', '12')}
                    </p>
                    <TableSortButton
                      active={sortTableBy?.column === '12M'}
                      onClick={() => handleSortTable('12M')}
                      data-testid="sort-by-12m"
                    >
                      {sortTableBy?.sort === 'crescent' &&
                      sortTableBy.column === '12M' ? (
                        <ArrowDown
                          size="0.5625rem"
                          weight="bold"
                          data-testid="sorted-by-lower"
                        />
                      ) : sortTableBy?.sort === 'descend' &&
                        sortTableBy.column === '12M' ? (
                        // eslint-disable-next-line react/jsx-indent
                        <ArrowUp
                          size="0.5625rem"
                          weight="bold"
                          data-testid="sorted-by-higher"
                        />
                      ) : (
                        <ArrowsDownUp
                          size="0.5625rem"
                          weight="bold"
                          data-testid="unsorted"
                        />
                      )}
                    </TableSortButton>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {performanceByPeriodDataQueried &&
                performanceByPeriodDataQueried.map((data, index) => (
                  <ActiveTR
                    active={data.updated}
                    key={`line-${data.variable
                      .toLocaleLowerCase()
                      .replaceAll(' ', '-')}-${index + 1}`}
                  >
                    {data.updated ? (
                      <td>{data.variable}</td>
                    ) : (
                      <td
                        data-tooltip-id="performance-by-period-variable"
                        data-tooltip-html={translate(
                          'perforamnceByPeriodVariableTooltip',
                        )}
                      >
                        {data.variable}
                      </td>
                    )}
                    <td>
                      {data.periods[0] && data.periods[0] !== null
                        ? typeof data.periods[0]?.[metric] === 'number'
                          ? formatNumberToShow(data.periods[0][metric])
                          : '--'
                        : '--'}
                    </td>
                    <td>
                      {data.periods[1] && data.periods[1] !== null
                        ? typeof data.periods[1]?.[metric] === 'number'
                          ? formatNumberToShow(data.periods[1][metric])
                          : '--'
                        : '--'}
                    </td>
                    <td>
                      {data.periods[2] && data.periods[2] !== null
                        ? typeof data.periods[2]?.[metric] === 'number'
                          ? formatNumberToShow(data.periods[2][metric])
                          : '--'
                        : '--'}
                    </td>
                    <td>
                      {data.periods[3] && data.periods[3] !== null
                        ? typeof data.periods[3]?.[metric] === 'number'
                          ? formatNumberToShow(data.periods[3][metric])
                          : '--'
                        : '--'}
                    </td>
                  </ActiveTR>
                ))}
              {performanceByPeriodDataQueried &&
                performanceByPeriodDataQueried.length > 0 &&
                performanceByPeriodDataQueried.length < 9 &&
                Array.from({
                  length: 9 - performanceByPeriodDataQueried.length,
                }).map((_, index) => (
                  <tr key={`complete-tr-${index + 1}`}>
                    <td aria-label="complete tr">
                      <div />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </TableContent>
      )}
      {searchValue !== '' &&
        performanceByPeriodDataQueried &&
        performanceByPeriodDataQueried.length === 0 && (
          <Status
            type="noSearchResults"
            title={translate('performanceByPeriodNoResults').replace(
              'SEARCH',
              searchValue,
            )}
            dataCy="search-not-matches"
          />
        )}
      <Tooltip
        id="performance-by-period-variable"
        className="customTooltipTheme"
      />
    </div>
  );
};
