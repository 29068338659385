import React, { useEffect, useMemo, useState, createContext } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Card } from 'src/components/Card';
import { FailedModal } from 'src/components/Modal/Failed';
import { Column, Y } from 'src/models/redux/reducers/Project';
import { RootState } from 'src/redux/store';

import { DependentVariables } from './components/DependentVariables';
import { ModalEditColumns } from './components/ModalEditColumns';
import { ModalRenameVariables } from './components/ModalRenameVariables';
import { Projections } from './components/Projections';
import { ProjectSummary } from './components/ProjectSummary';
import { ContainerProjectOverview } from './styles';

export const sortedColumn: Column[] = [
  'Model',
  'MAPE',
  'WMAPE',
  'MPE',
  'RMSE',
  'MASE',
  'MASEs',
  'Transformation',
];

interface ErrorInfo {
  title: string;
  description: string;
}

interface ProjectOverviewContextType {
  projectYsSuccess: Y[];
  openModalEditColumns: () => void;
  closeModalEditColumns: () => void;
  openModalRenameVariables: () => void;
  closeModalRenameVariables: () => void;
  reportError: (error: ErrorInfo) => void;
}

export const ProjectOverviewContext = createContext(
  {} as ProjectOverviewContextType,
);

export const ProjectOverview: React.FC = () => {
  const { t: translate } = useTranslation();
  const { project } = useSelector((state: RootState) => state);

  const [modalEditColumnsVisible, setModalEditColumnsVisible] = useState(false);
  const [modalRenameVariableVisible, setModalRenameVariableVisible] =
    useState(false);
  const [errorInfo, setErrorInfo] = useState<ErrorInfo | null>(null);

  const projectYsSuccess = useMemo(
    () => project.y.filter((y) => y.status === 'success'),
    [project.y],
  );

  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      modalEditColumnsVisible || modalRenameVariableVisible
        ? (body.style.overflow = 'hidden')
        : (body.style.overflow = 'auto');
    }
  }, [modalEditColumnsVisible, modalRenameVariableVisible]);

  function openModalEditColumns(): void {
    setModalEditColumnsVisible(true);
  }

  function closeModalEditColumns(): void {
    setModalEditColumnsVisible(false);
  }

  function openModalRenameVariables(): void {
    setModalRenameVariableVisible(true);
  }

  function closeModalRenameVariables(): void {
    setModalRenameVariableVisible(false);
  }

  function reportError(error: ErrorInfo) {
    setErrorInfo(error);
  }

  function closeModalError() {
    setErrorInfo(null);
  }

  return (
    <ProjectOverviewContext.Provider
      value={{
        projectYsSuccess,
        openModalEditColumns,
        closeModalEditColumns,
        openModalRenameVariables,
        closeModalRenameVariables,
        reportError,
      }}
    >
      <ContainerProjectOverview>
        <Card
          textCard={translate('projectOverviewTitle')}
          textDescription={translate('projectOverviewDescription')}
          className="containerLinear"
        />
        <ProjectSummary />

        <Projections />

        <DependentVariables />
      </ContainerProjectOverview>

      {modalEditColumnsVisible && <ModalEditColumns />}

      {modalRenameVariableVisible && <ModalRenameVariables />}

      {errorInfo && (
        <FailedModal
          visible
          setVisible={closeModalError}
          errorInfo={{
            title: errorInfo.title,
            description: errorInfo.description,
          }}
        />
      )}
    </ProjectOverviewContext.Provider>
  );
};
