export const ConfigFiltersENUSTranslate = {
  configFiltersOptions: 'Options',
  configFiltersFilter: 'Filter',
  configFiltersFilterName: 'Filter name',
  configFiltersAdd: 'Add',
  configFiltersGroupVariablesTitle: 'Group variables for each of the options',
  configFiltersSearchVariablePlaceholder: 'Search variable',
  configFiltersSelectAllVariables: 'Select all',
  configFiltersSelectAtLeastOneVariable: 'Select at least one variable',
  configFiltersSelectAtLeastOneOption:
    'Select an option to add the marked variables',
  configFiltersOptionName: 'Option name',
  configFiltersNoOptionCreated: 'Any option was created',
  configFiltersVariableError: 'Variable with modeling error',
  configFiltersDeleteFilterTitle: 'Are you sure?',
  configFiltersDeleteFilterDescription: 'You will lose all settings for filter',
  nameAlreadyExists: 'Name already exists',
};
