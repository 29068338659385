import React, { useEffect, useMemo, useRef } from 'react';

import { format } from 'date-fns';
import { enUS, ptBR } from 'date-fns/locale';
import ms from 'ms';
import { X } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { RootState } from 'src/redux/store';
import { addThousandSeparator } from 'src/utils/numbers/addThousandSeparator';
import { formatCompactNotation } from 'src/utils/numbers/formatCompactNotation';
import { changeShowChangeHistory } from 'src/workspaces/redux/reducers/WorkspaceOverviewOptions';
import apiWorkspace from 'src/workspaces/service/api';
import { useQueryReleaseData } from 'src/workspaces/hooks/useQueryReleaseData';

import { Adjustments, HistoricalCard } from '../HistoricalCard';
import {
  Container,
  Content,
  DateText,
  Header,
  MessageError,
  Step,
  StepHeader,
} from './styles';

type LogAdjustments = {
  after: number;
  before: number;
  date: string;
  id: number;
  y: string;
};

type Logs = {
  data: {
    adjustments?: LogAdjustments[];
    after?: string;
    approval_message?: string[];
    awaiting_approval_message?: string[];
    before?: string;
    disapproval_messages?: string[];
    inserted_user?: string;
    profile?: 'approver' | 'editor';
    removed_user?: string;
    status: 'approved' | 'awaiting_approval' | 'adjusting' | 'baseline';
    step: number;
    correlation_id?: string;
  };
  id: string;
  user: string;
  timestamp: string;
  action:
    | 'added_step'
    | 'added_user'
    | 'added_user_step'
    | 'adjusted'
    | 'created'
    | 'discarded_adjusted'
    | 'finished_release'
    | 'removed_step'
    | 'removed_user'
    | 'removed_user_step'
    | 'renamed_step'
    | 'updated_deadlines_step'
    | 'updated_step';
  role: 'manager' | 'editor' | null;
};

type LogsByStepAndDate = {
  step: {
    value: number;
    date: {
      value: string;
      logs: Logs[];
    }[];
  };
};

type Steps = {
  step: number | string;
  data: {
    value: string;
    cards: {
      adjustments: Adjustments[];
      approvalMessage: string | null;
      awaitingApprovalMessage: string | null;
      deadLine: DeadLineUpdateType;
      disapprovalMessage: string | null;
      discardedAdjustments: boolean;
      email: string;
      hasEditionStartedMessage: boolean;
      hasStepBeenRemoved: boolean;
      id: string;
      isBaseLine: boolean;
      isNewStage: boolean;
      manageUser: ManageActionUser;
      renamedStep: RenameStep;
      tag: 'approved' | 'edition' | 'governance' | 'revision' | 'reproved';
      versionFinalized: boolean;
    }[];
  }[];
}[];

type ResponseLogs = {
  limit: number;
  records: Logs[];
  skip: number;
  total: number;
};

export type ManageActionUser = {
  action: 'added_user_step' | 'removed_user_step' | 'finished_release';
  insertedUser?: string;
  profile?: 'approver' | 'editor';
  removedUser?: string;
  role: 'manager' | 'editor';
} | null;

export type RenameStep = {
  after?: string;
  before?: string;
} | null;

type DeadLine = {
  date: { start: string; end: string };
  type: 'editor' | 'approver';
};

type UpdateDeadLine = {
  editor: DeadLine[];
  approver: DeadLine[];
};

type DeadlineUpdate = {
  from: DeadLine;
  to: DeadLine;
};

export type DeadLineUpdateType = {
  editorUpdateDeadLine: DeadlineUpdate | null;
  approverUpdateDeadLine: DeadlineUpdate | null;
};

type GroupedDeadlines = {
  [key: string]: DeadLine[];
};

type GroupedAdjustments = {
  correlationId: string;
  adjustments: LogAdjustments[];
  step: number;
  timestamp: string;
  user: string;
};

const QUANTITY_ITEMS_PAGE = 100;
const MAX_VALUE = 999999999.99;

export const Historical: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    workspace: { id, releaseSelected, frequency },
    workspaceOverviewOptions: { showChangeHistory },
    auth: { user },
  } = useSelector((state: RootState) => state);

  const { data: releaseData } = useQueryReleaseData(id, releaseSelected?.id);

  useEffect(() => {
    function isElementVisible(element: HTMLElement) {
      const rect = element.getBoundingClientRect();
      return rect.bottom >= 0 && rect.top <= window.innerHeight;
    }

    function handleScroll() {
      const footer = document.getElementById('footer');

      if (footer) {
        if (isElementVisible(footer)) {
          if (containerRef.current) {
            containerRef.current.style.height = `calc(100vh - (100vh - ${
              footer.getBoundingClientRect().top
            }px) - 74px)`;
          }
        } else if (containerRef.current) {
          containerRef.current.style.height = `calc(100vh - 74px)`;
        }
      }
    }

    handleScroll(); //executa a primeira vez pra definir a
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const {
    data,
    isError,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ['workspace', 'logs', id, 'releases', releaseSelected?.id],
    async ({ pageParam = 0 }) => {
      const response = await apiWorkspace.get<ResponseLogs>(
        `/workspaces/${id}/releases/${releaseSelected!.id}/logs?skip=${
          pageParam * QUANTITY_ITEMS_PAGE
        }&limit=${QUANTITY_ITEMS_PAGE}`,
      );

      return response.data;
    },
    {
      enabled:
        !!id &&
        !!releaseSelected?.id &&
        !!releaseSelected.data.approval_flow?.enable,
      getNextPageParam: (lastPage, pages) => {
        if (pages.length * QUANTITY_ITEMS_PAGE < lastPage.total) {
          return pages.length;
        }
        return undefined;
      },
      staleTime: ms('5 min'),
      refetchIntervalInBackground: true,
      refetchOnWindowFocus:
        releaseData?.status !== 'finished' ? 'always' : false,
      refetchInterval: releaseData?.status !== 'finished' ? ms('1 min') : false,
    },
  );

  const fetchNextPageOnScroll = (
    event: React.UIEvent<HTMLDivElement>,
  ): void => {
    const isBottom =
      Math.floor(
        event.currentTarget.scrollHeight - event.currentTarget.scrollTop,
      ) <= event.currentTarget.clientHeight;

    if (isBottom && !isLoading && !isFetching && !isError && hasNextPage) {
      fetchNextPage();
    }
  };

  const formatValue = (value: number) => {
    if (value >= MAX_VALUE * -1 && value <= MAX_VALUE) {
      return addThousandSeparator(value, user.language);
    }

    return formatCompactNotation(value, 2, user.language);
  };

  const dateFormatter = (date: string): string => {
    let dateFormat = 'MMM/yy';

    if (frequency) {
      if (['annual', 'yearly'].includes(frequency)) {
        dateFormat = 'yyyy';
      } else if (
        frequency === 'daily' ||
        frequency === 'fortnightly' ||
        frequency === 'weekly'
      ) {
        dateFormat = user.language === 'en-us' ? 'MM/dd/yy' : 'dd/MM/yy';
      }
    }

    return format(new Date(date.replace(':00Z', '')), dateFormat, {
      locale: user.language === 'en-us' ? enUS : ptBR,
    });
  };

  function handleClose() {
    dispatch(changeShowChangeHistory(false));
  }

  const dateFormat = user.language === 'en-us' ? 'MM/dd/yyyy' : 'dd/MM/yyyy';

  const logsByStepAndDate: LogsByStepAndDate[] = useMemo(() => {
    const allLogs: LogsByStepAndDate[] = [];
    const groupedAdjustments: GroupedAdjustments = {
      correlationId: '',
      adjustments: [],
      step: 0,
      timestamp: '',
      user: '',
    };

    const addLog = (
      log: Logs,
      addGroupedAdjustments: boolean,
      addCurrentLog = true,
    ) => {
      const stepValue = log.data.step ?? 'finishedRelease';

      const stepIndex = allLogs.findIndex(
        (aux) => aux.step.value === stepValue,
      );

      const groupedAdjustmentsLog: Logs = {
        action: 'adjusted',
        data: {
          adjustments: groupedAdjustments.adjustments,
          status: 'adjusting',
          step: groupedAdjustments.step,
        },
        id: '',
        role: null,
        timestamp: groupedAdjustments.timestamp,
        user: groupedAdjustments.user,
      };

      if (stepIndex !== -1) {
        const dateIndex = allLogs[stepIndex].step.date.findIndex(
          (aux) => aux.value === format(new Date(log.timestamp), dateFormat),
        );

        if (dateIndex !== -1) {
          if (addGroupedAdjustments) {
            allLogs[stepIndex].step.date[dateIndex].logs.push(
              groupedAdjustmentsLog,
            );
          }
          if (addCurrentLog) {
            allLogs[stepIndex].step.date[dateIndex].logs.push(log);
          }
        } else {
          const logs: Logs[] = [];

          if (addCurrentLog) {
            logs.push(log);
          }

          if (addGroupedAdjustments) {
            logs.push(groupedAdjustmentsLog);
          }

          allLogs[stepIndex].step.date.push({
            value: format(new Date(log.timestamp), dateFormat),
            logs,
          });
        }
      } else {
        const logs: Logs[] = [];

        if (addCurrentLog) {
          logs.push(log);
        }

        if (addGroupedAdjustments) {
          logs.push(groupedAdjustmentsLog);
        }

        allLogs.push({
          step: {
            value: stepValue,
            date: [
              {
                value: format(new Date(log.timestamp), dateFormat),
                logs,
              },
            ],
          },
        });
      }

      if (groupedAdjustments.correlationId) {
        groupedAdjustments.correlationId = '';
        groupedAdjustments.adjustments = [];
      }
    };

    data?.pages.forEach((page, pageIndex) =>
      page.records.forEach((log, logIndex) => {
        if (log.action === 'adjusted') {
          const correlationId = log.data.correlation_id;

          if (
            !!correlationId &&
            groupedAdjustments.correlationId === correlationId &&
            log.data.adjustments?.length
          ) {
            groupedAdjustments.adjustments = [
              ...groupedAdjustments.adjustments,
              ...log.data.adjustments,
            ];
          } else if (
            !groupedAdjustments.correlationId &&
            log.data.adjustments?.length &&
            !!correlationId
          ) {
            groupedAdjustments.correlationId = correlationId;
            groupedAdjustments.adjustments = [...log.data.adjustments];
            groupedAdjustments.step = log.data.step;
            groupedAdjustments.timestamp = log.timestamp;
            groupedAdjustments.user = log.user;
          } else if (groupedAdjustments.correlationId) {
            addLog(log, true, false);

            groupedAdjustments.correlationId = correlationId ?? '';
            groupedAdjustments.adjustments = [...(log.data.adjustments ?? [])];
            groupedAdjustments.step = log.data.step;
            groupedAdjustments.timestamp = log.timestamp;
            groupedAdjustments.user = log.user;
          } else {
            groupedAdjustments.correlationId = '';
            groupedAdjustments.adjustments = [...(log.data.adjustments ?? [])];
            groupedAdjustments.step = log.data.step;
            groupedAdjustments.timestamp = log.timestamp;
            groupedAdjustments.user = log.user;

            addLog(log, true, false);
          }
        } else {
          const addGroupedAdjustments = !!groupedAdjustments.correlationId;

          addLog(log, addGroupedAdjustments);

          groupedAdjustments.correlationId = '';
          groupedAdjustments.adjustments = [];
          groupedAdjustments.step = 0;
          groupedAdjustments.timestamp = '';
          groupedAdjustments.user = '';
        }

        if (
          pageIndex + 1 === data.pages.length &&
          logIndex + 1 === page.records.length &&
          log.action === 'adjusted'
        ) {
          fetchNextPage();
        }
      }),
    );

    return allLogs;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data?.pages), dateFormat, fetchNextPage]);

  const groupDeadLinesByType = (
    afterStep: DeadLine[],
    beforeStep: DeadLine[],
  ) => {
    const deadLines: GroupedDeadlines = {
      after: afterStep,
      before: beforeStep,
    };

    const groupedData: GroupedDeadlines = {};

    Object.values(deadLines).forEach((array) => {
      array.forEach((item) => {
        if (!groupedData[item.type]) {
          groupedData[item.type] = [];
        }
        groupedData[item.type].push(item);
      });
    });

    return groupedData as unknown as UpdateDeadLine;
  };

  const steps: Steps = logsByStepAndDate.map((logByStep) => ({
    step: logByStep.step.value,
    data: logByStep.step.date.map((logByStepDate) => ({
      value: logByStepDate.value,
      cards: logByStepDate.logs.map((log) => {
        const adjustments: Adjustments[] = [];

        log.data.adjustments?.forEach((adjustment) => {
          let hierarchies = '';

          if (
            Object.values(releaseSelected?.data.y_filters?.[adjustment.y] || {})
              .length
          ) {
            hierarchies = Object.values(
              releaseSelected?.data.y_filters?.[adjustment.y] ?? {},
            )
              .filter((hierarchy) => hierarchy)
              .toString()
              .replaceAll(',', ' -> ');
          }

          const name = hierarchies
            ? `${adjustment.y} -> ${hierarchies}`
            : adjustment.y;

          const index = adjustments.findIndex((aux) => aux.name === name);

          const tooltip = `${formatValue(adjustment.before)} -> ${formatValue(
            adjustment.after,
          )}`;

          const adjustmentDate = dateFormatter(adjustment.date);

          const adjustmentAux = {
            after: adjustment.after,
            before: adjustment.before,
            id: adjustment.id,
            date: adjustmentDate,
            tooltip,
          };

          if (index !== -1) {
            adjustments[index].ys.push(adjustmentAux);
          } else {
            adjustments.push({
              name,
              ys: [adjustmentAux],
            });
          }
        });

        const approvalMessage = log.data.approval_message?.length
          ? log.data.approval_message[0]
          : null;

        const awaitingApprovalMessage = log.data.awaiting_approval_message
          ?.length
          ? log.data.awaiting_approval_message[0]
          : null;

        const disapprovalMessage = log.data.disapproval_messages?.length
          ? log.data.disapproval_messages[0]
          : null;

        const isNewStage = log.action === 'added_step';

        const discardedAdjustments = log.action === 'discarded_adjusted';

        const hasStepBeenRemoved = log.action === 'removed_step';

        const { action, role, data: dataLog } = log;
        const { status, after, before } = dataLog;

        const isBaseLine = status === 'baseline';
        const isUpdateDeadLineStep = action === 'updated_deadlines_step';

        const groupedDeadLines =
          isUpdateDeadLineStep &&
          groupDeadLinesByType(
            after as unknown as DeadLine[],
            before as unknown as DeadLine[],
          );

        const hasUpdateDeadLineEditor =
          groupedDeadLines &&
          !groupedDeadLines.editor.every(
            (update) =>
              update.date.start === groupedDeadLines.editor[0].date.start &&
              update.date.end === groupedDeadLines.editor[0].date.end,
          );

        const hasUpdateDeadLineApprover =
          groupedDeadLines &&
          !groupedDeadLines.approver.every(
            (update) =>
              update.date.start === groupedDeadLines.approver[0].date.start &&
              update.date.end === groupedDeadLines.approver[0].date.end,
          );

        const deadLine: DeadLineUpdateType = {
          editorUpdateDeadLine: hasUpdateDeadLineEditor
            ? {
                from: groupedDeadLines.editor[1],
                to: groupedDeadLines.editor[0],
              }
            : null,
          approverUpdateDeadLine: hasUpdateDeadLineApprover
            ? {
                from: groupedDeadLines.approver[1],
                to: groupedDeadLines.approver[0],
              }
            : null,
        };

        const isDisapproval = !!dataLog.disapproval_messages?.length;

        const isEdition =
          action === 'adjusted' ||
          (status === 'adjusting' && !isDisapproval) ||
          status === 'baseline' ||
          action === 'discarded_adjusted';

        const isApproved = status === 'approved';

        const isRevision = status === 'awaiting_approval';

        const tag = isEdition
          ? 'edition'
          : isApproved
          ? 'approved'
          : isRevision
          ? 'revision'
          : isDisapproval
          ? 'reproved'
          : 'governance';

        const manageUser: ManageActionUser =
          action === 'added_user_step' ||
          action === 'removed_user_step' ||
          action === 'finished_release'
            ? {
                action,
                role: role ?? 'manager',
                profile: dataLog.profile,
                insertedUser: dataLog.inserted_user,
                removedUser: dataLog.removed_user,
              }
            : null;

        const isRenamedStep = action === 'renamed_step';
        const renamedStep: RenameStep = isRenamedStep
          ? {
              after: dataLog.after,
              before: dataLog.before,
            }
          : null;

        const hasEditionStartedMessage =
          action === 'updated_step' && status === 'adjusting';

        const versionFinalized = action === 'finished_release';

        return {
          adjustments,
          approvalMessage,
          awaitingApprovalMessage,
          deadLine,
          disapprovalMessage,
          discardedAdjustments,
          email: log.user,
          hasEditionStartedMessage,
          hasStepBeenRemoved,
          id: log.id,
          isBaseLine,
          isNewStage,
          manageUser,
          renamedStep,
          tag,
          versionFinalized,
        };
      }),
    })),
  }));

  return (
    <Container ref={containerRef} isOpen={showChangeHistory}>
      <Tooltip
        id="change-history-sidebar-tooltip"
        className="customTooltipTheme"
      />

      <Header>
        <h3>{t('workspaceOverviewLogsTitle')}</h3>
        <p>{t('workspaceOverviewLogsDescription')}</p>

        <button
          type="button"
          onClick={handleClose}
          data-testid="change-history-button-close"
          data-cy="change-history-button-close"
          aria-label="close feature store variables button"
        >
          <X size={16} />
        </button>
      </Header>

      <Content onScroll={fetchNextPageOnScroll}>
        {isError ? (
          <MessageError data-testid="change-history-message-error">
            {t('workspaceOverviewLogsMessageError')}
          </MessageError>
        ) : (
          steps.map((step, stepIndex) => (
            <Step key={`step-${stepIndex.toString()}`}>
              <StepHeader>
                <h4>
                  {step.step.toString() !== 'finishedRelease'
                    ? releaseData?.data.steps?.[Number(step.step) - 1]?.name ??
                      `${t('workspaceOverviewLogsStage')} ${step.step}`
                    : t('workspaceOverviewLogsRelease')}
                </h4>
              </StepHeader>
              {step.data.map((date, stepDataIndex) => (
                <React.Fragment
                  key={`step-${stepIndex.toString()}-data-${stepDataIndex.toString()}`}
                >
                  <DateText>{date.value}</DateText>
                  {date.cards.map((card, cardIndex) => (
                    <HistoricalCard
                      adjustments={card.adjustments}
                      approvalMessage={card.approvalMessage}
                      awaitingApprovalMessage={card.awaitingApprovalMessage}
                      deadLine={card.deadLine}
                      disapprovalMessage={card.disapprovalMessage}
                      discardedAdjustments={card.discardedAdjustments}
                      email={card.email}
                      hasEditionStartedMessage={card.hasEditionStartedMessage}
                      hasStepBeenRemoved={card.hasStepBeenRemoved}
                      isBaseLine={card.isBaseLine}
                      isNewStage={card.isNewStage}
                      key={`${card.id}-${cardIndex.toString()}`}
                      tag={card.tag}
                      manageUser={card.manageUser}
                      renamedStep={card.renamedStep}
                      versionFinalized={card.versionFinalized}
                    />
                  ))}
                </React.Fragment>
              ))}
            </Step>
          ))
        )}

        {isLoading || isFetching || isFetchingNextPage ? (
          Array.from({ length: 3 }).map((_, index) => (
            <div
              key={`logs-card-loading-${index.toString()}`}
              data-testid={`logs-card-loading-${index.toString()}`}
              style={{ marginTop: '2rem' }}
            >
              <ContainerSkeleton
                withLoading={false}
                style={{
                  width: '4rem',
                  height: '1rem',
                  marginBottom: '1rem',
                }}
              />
              <ContainerSkeleton
                withLoading={false}
                style={{ height: '9rem', marginBottom: '1rem' }}
              />
            </div>
          ))
        ) : steps.length === 0 ? (
          <MessageError data-testid="change-history-message-no-changes">
            {t('workspaceOverviewLogsStillNoChanges')}
          </MessageError>
        ) : null}
      </Content>
    </Container>
  );
};
