export const RegisterNewUserENTranslate = {
  RegisterNewUserTitle: 'Invite new user',
  RegisterNewUserDescriptionSingular:
    'This will consume you one of the <b>remaining XXX license<b/>',
  RegisterNewUserDescriptionPlural:
    'This will consume you one of the <b>remaining XXX licenses<b/>',
  RegisterNewUserEmailUser: `Enter the user's e-mail`,
  RegisterNewUserMessageStatus409:
    'The requested resource is already registered.',
};
