import React, { Fragment, useEffect, useState } from 'react';

import { AxiosError } from 'axios';
import { CaretDown, MagnifyingGlass, X } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from 'react-query';
import { useSelector } from 'react-redux';
import Maintenance from 'src/assets/maintenance.svg';
import { Button } from 'src/components/Button';
import { CheckBox } from 'src/components/CheckBox';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Input } from 'src/components/Input';
import { Modal } from 'src/components/Modal';
import { FailedModal } from 'src/components/Modal/Failed';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import { ModalLoading } from 'src/components/Modal/Loading';
import api from 'src/feature-store/service/api';
import { RootState } from 'src/redux/store';
import { queryClient } from 'src/service/queryClient';

import { InsertUserGroupPremium } from '../InsertUserGroupPremium';
import {
  ContentInputsModal,
  ContentModal,
  DivAreaContainer,
  DivButton,
  DivErrorGroupPremium,
  DivIndicatorsSelected,
  ItemSelected,
} from './styles';
import {
  ErrorProps,
  IndicatorsProps,
  InsertIndicatorGroupPremiumProps,
  RequestDataErrorProps,
  ResponseDataErrorProps,
  indicatorSelectedProps,
} from './types';

export const InsertIndicatorGroupPremium: React.FC<
  InsertIndicatorGroupPremiumProps
> = ({
  visible,
  setVisible,
  idGroup,
  indicatorInfo,
  openModalUser = true,
  access_type,
}) => {
  const [modalAddUser, setModalAddUser] = useState<boolean>(false);
  const [responseLoading, setResponseLoading] = useState<boolean>(false);
  const [failedModalVisible, setFailedModalVisible] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchError, setSearchError] = useState<ErrorProps>();
  const [searchTimer, setSearchTimer] = useState(1000);
  const [timeOutActive, setTimeOutActive] = useState(false);
  const [requestAllowed, setRequestAllowed] = useState(true);
  const [responseDataError, setResponseDataError] =
    useState<ResponseDataErrorProps>();
  const [indicatorSelected, setIndicatorSelected] = useState<
    indicatorSelectedProps[]
  >([]);
  const [indicatorsAdded, setIndicatorsAdded] = useState<
    indicatorSelectedProps[]
  >([]);

  const { language } = useSelector((state: RootState) => state.auth.user);
  const { t: translate } = useTranslation();

  const QUANTITY_ITEMS_PAGE = 100;

  const {
    data: indicatorsData,
    isLoading,
    isFetching,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ['indicators to create group', searchValue],
    async ({ pageParam = 0 }) => {
      const { data } = await api.get<IndicatorsProps>(
        searchValue.length >= 3
          ? `/indicators?access_type=${access_type}&skip=${
              pageParam * QUANTITY_ITEMS_PAGE
            }&text=${searchValue}&limit=${QUANTITY_ITEMS_PAGE}`
          : `/indicators?access_type=${access_type}&skip=${
              pageParam * QUANTITY_ITEMS_PAGE
            }&limit=${QUANTITY_ITEMS_PAGE}`,
      );

      return data;
    },
    {
      enabled: requestAllowed || searchValue === '',
      onSettled: () => {
        setRequestAllowed(false);
        setTimeOutActive(false);
        setSearchTimer(1000);
      },
      getNextPageParam: (lastPage, pages) => {
        if (pages.length * 100 < lastPage.total) {
          return pages.length;
        }
        return undefined;
      },
    },
  );

  useEffect(() => {
    setIndicatorsAdded(
      indicatorInfo.map((info) => ({
        ...info,
        disable: true,
      })),
    );
  }, [indicatorInfo]);

  useEffect(() => {
    if (timeOutActive && searchValue.length >= 3) {
      setTimeout(() => {
        if (searchTimer > 0) {
          setSearchTimer(searchTimer - 1000);
        } else {
          setTimeOutActive(false);
        }
      }, 1000);
    } else {
      searchTimer === 0 && setRequestAllowed(true);
    }
  }, [searchTimer, searchValue, timeOutActive]);

  function handleSearchIndicatorPremium(value: string) {
    setSearchValue(value);
    if (value.length > 50) {
      setSearchError({
        message: 'searchMaxCharactersError',
        quantityLetters: 50,
      });
      return;
    }

    if (value.length < 3 && value.length > 0) {
      setSearchError({
        message: 'searchMinCharactersError',
        quantityLetters: 3,
      });
      return;
    }
    setSearchError({
      message: '',
      quantityLetters: 0,
    });

    if (value !== searchValue) {
      setSearchTimer(1000);
      setTimeOutActive(true);
    }
  }

  const handleIndicatorSelect = (
    indicator_code: string,
    name: {
      'en-us': string;
      'pt-br': string;
    },
  ) => {
    if (
      indicatorSelected.some(
        (indicator) =>
          indicator.indicator_code === indicator_code &&
          indicator.name?.['en-us'] === name['en-us'],
      )
    ) {
      setIndicatorSelected(
        indicatorSelected.filter(
          (selected) =>
            selected.indicator_code !== indicator_code &&
            selected.name?.['en-us'] !== name['en-us'],
        ),
      );
    } else {
      setIndicatorSelected([
        ...indicatorSelected,
        {
          indicator_code,
          name,
        },
      ]);
    }
  };

  const handleAddIndicatorGroup = async () => {
    setResponseLoading(true);
    try {
      for (let i = 0; i < indicatorSelected.length; i++) {
        await api.post(`/access-groups/${idGroup}/indicators`, {
          indicator_code: indicatorSelected[i].indicator_code,
        });
      }
      setResponseLoading(false);
      if (openModalUser) {
        setModalAddUser(true);
      } else {
        setVisible(false);
      }
    } catch (err) {
      const error = err as AxiosError<RequestDataErrorProps>;
      if (error.response && error.response.status) {
        setResponseDataError({
          title: 'Error',
          description:
            error?.response?.data?.message === 'The resource already exists.'
              ? language === 'pt-br'
                ? 'Esse indicador já existe nesse grupo'
                : 'This indicator already exists in this group'
              : error?.response?.data?.message,
        });
        setFailedModalVisible(true);
        setResponseLoading(false);
      }
    }
  };

  const handleSelectIndicatorPremium = () => {
    if (indicatorSelected) {
      setIndicatorsAdded([
        ...indicatorInfo.map((info) => ({
          ...info,
          disable: true,
        })),
        ...indicatorSelected,
      ]);
    }
  };

  const handleRemoveIndicatorsAddedIndicator = (indicator_code: string) => {
    setIndicatorSelected(
      indicatorSelected.filter(
        (selected) => selected.indicator_code !== indicator_code,
      ),
    );

    setIndicatorsAdded(
      indicatorsAdded.filter(
        (selected) => selected.indicator_code !== indicator_code,
      ),
    );
  };

  const handleOnScroll = (event: React.UIEvent<HTMLDivElement>): void => {
    const isBottom =
      event.currentTarget.scrollHeight - event.currentTarget.scrollTop ===
      event.currentTarget.clientHeight;

    if (isBottom && !isLoading && !isFetching && !isError && hasNextPage) {
      fetchNextPage();
    }
  };

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <ContentModal
        style={{ height: '764px' }}
        data-testid="modal-insert-indicator-group"
      >
        <ContentInputsModal>
          <h4>{translate('accessAddIndicatorInGroupTitle')}</h4>
          <p>
            {translate('accessAddIndicatorInGroupDescription').replace(
              'XX',
              access_type === 'premium'
                ? 'Premium'
                : translate('privateContent'),
            )}
          </p>
          <Input
            placeholder={translate('accessSearchIndicator')}
            icon={<MagnifyingGlass size="1rem" color="#A0AEC0" />}
            onChange={(event) => {
              handleSearchIndicatorPremium(event.target.value);
            }}
            error={
              searchError?.quantityLetters === 3
                ? translate(searchError.message).replace('XX', '3')
                : searchError?.quantityLetters === 50
                ? translate(searchError.message).replace('XX', '50')
                : undefined
            }
          />
          {isError ? (
            <DivErrorGroupPremium>
              <img src={Maintenance} alt="image_maintenance" />
              <strong>{translate('accessError')}</strong>
              <p>{translate('accessLoadIndicatorsError')}</p>
            </DivErrorGroupPremium>
          ) : (isLoading || isFetching || timeOutActive) &&
            !isFetchingNextPage ? (
            // eslint-disable-next-line react/jsx-indent
            <DivAreaContainer>
              {Array.from({ length: 4 }, (_, index) => (
                <div
                  style={{ display: 'flex', flexDirection: 'row' }}
                  key={index}
                >
                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      height: '20px',
                      width: '20px',
                    }}
                  />
                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      height: '20px',
                      width: `${Math.random() * 200 + 300}px`,
                      marginLeft: '15px',
                    }}
                  />
                </div>
              ))}
            </DivAreaContainer>
          ) : (
            <>
              <DivAreaContainer onScroll={handleOnScroll}>
                {indicatorsData?.pages.map((indicatorS, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Fragment key={index + 1}>
                    {indicatorS.data?.map((indicator) => (
                      <CheckBox
                        key={`${indicator.indicator_code}-${index.toString()}`}
                        label={
                          indicator.name?.[language] ??
                          indicator.name?.['en-us']
                        }
                        data-testid={indicator.indicator_code}
                        data-cy={`checkbox-${indicator.name?.['en-us']
                          .replaceAll(' ', '-')
                          .toLocaleLowerCase()}`}
                        onChange={() =>
                          handleIndicatorSelect(
                            indicator.indicator_code,
                            indicator.name,
                          )
                        }
                        checked={indicatorSelected.some(
                          (selected) =>
                            selected.indicator_code ===
                              indicator.indicator_code &&
                            selected.name['en-us'] ===
                              indicator.name?.['en-us'],
                        )}
                        disabled={indicatorsAdded.some(
                          (selected) =>
                            selected.indicator_code ===
                              indicator.indicator_code &&
                            selected.name['en-us'] ===
                              indicator.name?.['en-us'],
                        )}
                      />
                    ))}
                  </Fragment>
                ))}

                {isLoading ||
                  (isFetching &&
                    Array.from({ length: 100 }, (_, index) => (
                      <div
                        style={{ display: 'flex', flexDirection: 'row' }}
                        key={index}
                      >
                        <ContainerSkeleton
                          withLoading={false}
                          style={{
                            height: '20px',
                            width: '20px',
                          }}
                        />
                        <ContainerSkeleton
                          withLoading={false}
                          style={{
                            height: '20px',
                            width: `${Math.random() * 200 + 300}px`,
                            marginLeft: '15px',
                          }}
                        />
                      </div>
                    )))}
              </DivAreaContainer>
            </>
          )}
          <DivButton>
            <div>
              <CaretDown
                size="1.25rem"
                onClick={() => handleSelectIndicatorPremium()}
                data-testid="button-added-indicators"
                data-cy="button-added-indicators"
              />
            </div>
          </DivButton>
          <DivIndicatorsSelected>
            {indicatorsAdded.map((send) => (
              <ItemSelected
                key={`${send.indicator_code}-added`}
                data-cy={`${send.indicator_code}-added`}
                data-testid={`${send.indicator_code}-added`}
                disabled={!!send.disable}
              >
                <p>{send.name[language] ?? send.name['en-us']}</p>
                <X
                  size="0.875rem"
                  onClick={() =>
                    !send.disable &&
                    handleRemoveIndicatorsAddedIndicator(send.indicator_code)
                  }
                />
              </ItemSelected>
            ))}
          </DivIndicatorsSelected>
        </ContentInputsModal>
      </ContentModal>
      <ModalFooter>
        <Button
          buttonType="naked"
          onClick={() => setVisible(false)}
          data-testid="button-cancelar"
          data-cy="button-cancelar"
        >
          {translate('cancel')}
        </Button>
        <Button
          buttonType="primary"
          onClick={() => {
            handleAddIndicatorGroup();
            queryClient.removeQueries('indicatorsFromGroup');
            queryClient.removeQueries('groupsPremiumInfo');
          }}
          data-testid="button-add"
          data-cy="button-add"
          disabled={
            indicatorsAdded.filter((added) => !added.disable).length === 0
          }
        >
          {translate('accessContinue')}
        </Button>
      </ModalFooter>
      {modalAddUser && (
        <InsertUserGroupPremium
          visible={modalAddUser}
          setVisible={() => {
            setModalAddUser(false);
            setVisible(false);
          }}
          idGroup={idGroup}
        />
      )}
      {responseLoading && (
        <ModalLoading
          data-testid="loading-add-indicators-premium"
          visible={responseLoading}
        />
      )}
      {responseDataError && (
        <FailedModal
          visible={failedModalVisible}
          errorInfo={responseDataError}
          setVisible={setFailedModalVisible}
        />
      )}
    </Modal>
  );
};
