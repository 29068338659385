export const sideBarFeatureStorePTBRTranslate = {
  sidebarFSSearchDescription: 'Explore toda nossa base',
  sidebarFSFavoritesDescription: 'Acesse suas séries preferidas',
  sideBarFSGroupPremiumDescription: 'Selecione um grupo premium',
  indicatorsSearchFiltersDescription:
    'Pesquise indicadores mais facilmente usando filtros',
  sidebarFSSearchTitle: 'Pesquisar Indicadores',
  sidebarFSFavoritesTitle: 'Grupos Favoritos',
  sidebarFSPremiumTitle: 'Grupos Premium',
  sidebarFSSectionTitle: 'SEÇÕES',
  sidebarFSOpenFilters: 'Abrir Filtros',
  sideBarFSAIChat: 'Chat 4i',
  sideBarFSAIChatDescription:
    'Deixe nossa IA te ajudar a encontrar exatamente o que você precisa.',
};
