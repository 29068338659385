const VariableImpactsPTBRTranslations = {
  variableImpactsTitle: 'Impacto de Variáveis',
  variableImpactsDescription:
    'Mostra como mudanças no valor de uma variável impactam a saída do modelo.',
  variableImpactsErrorMessage:
    'Para este tipo de modelo essa métrica não é calculada.',
  variableImpactsSelectLabel: 'Classe Positiva',

  variableImpactsVarIncreaseTargetIncreaseTooltipMessage:
    'Quando o valor da variável <b>"VARIABLE" aumenta</b>, a probabilidade de <b>"TARGET"</b> ser <b>"CLASS"</b> aumenta.<br><b>Valor</b>: "VALUE".',
  variableImpactsVarIncreaseTargetDecreaseTooltipMessage:
    'Quando o valor da variável <b>"VARIABLE" aumenta</b>, a probabilidade de <b>"TARGET"</b> ser <b>"CLASS"</b> diminui.<br><b>Valor</b>: "VALUE".',
  variableImpactsVarDecreaseTargetDecreaseTooltipMessage:
    'Quando o valor da variável <b>"VARIABLE" diminui</b>, a probabilidade de <b>"TARGET"</b> ser <b>"CLASS"</b> diminui.<br><b>Valor</b>: "VALUE".',
  variableImpactsVarDecreaseTargetIncreaseTooltipMessage:
    'Quando o valor da variável <b>"VARIABLE" diminui</b>, a probabilidade de <b>"TARGET"</b> ser <b>"CLASS"</b> aumenta.<br><b>Valor</b>: "VALUE".',
  variableImpactsVarSameValue:
    'A relação entre os valores  de <b>"VARIABLE" </b>e as probabilidades de <b>"TARGET"</b> podem não ser contínuas.<br><b>Valor</b>: "VALUE".',
  variableImpactsIncrease: 'Aumenta a probabilidade de "TARGET" ser "CLASS"',
  variableImpactsDecrease: 'Diminui a probabilidade de "TARGET" ser "CLASS"',
};

export default VariableImpactsPTBRTranslations;
