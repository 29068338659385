import styled, { css } from 'styled-components';

export const AllCardsContainer = styled.div`
  width: 100%;
  min-height: 34rem;
  padding: 1.5rem 0;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.875rem;

  .feature-store-card {
    max-height: 19rem;
  }

  @media (max-width: 1000px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 850px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

interface ContainerProps {
  disabled?: boolean;
}

export const CardContainer = styled.div<ContainerProps>`
  display: flex;
  width: 100%;
  max-height: 21rem;

  position: relative;

  ${({ disabled }) =>
    disabled &&
    css`
      img,
      p,
      span,
      h5 {
        opacity: 0.7;
      }
    `}
`;

interface ContentProps {
  disabled?: boolean;
}

export const CardContent = styled.div<ContentProps>`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  width: 100%;
  height: 100%;

  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 8px;
  box-sizing: border-box;

  word-break: break-word;

  img {
    position: absolute;
    top: 1.25rem;
    left: 1.25rem;
    width: 4rem;
    height: 4rem;
    border-radius: 6.4px;

    background: white;
  }

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

interface CardHeadProps {
  projectType?: string;
}

export const CardHead = styled.div<CardHeadProps>`
  width: 100%;
  min-height: 4rem;

  padding: 1.5rem 1rem;

  background: ${({ projectType, theme }) =>
    projectType === 'classification'
      ? 'rgba(242, 125, 180, 0.2);'
      : projectType === 'loading'
      ? theme.colors.gray3
      : 'rgba(76, 148, 255, 0.2)'};
  border-radius: 7px 7px 0px 0px;
`;

export const CardBody = styled.div`
  width: 100%;

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 2.25rem 1.25rem 1.25rem 1.25rem;

  h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.688rem;
    color: ${({ theme }) => theme.colors.gray6};
  }

  span {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray4};

    margin-top: 0.75rem;
  }

  div {
    display: flex;
    flex-direction: row;
  }

  p {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray5};

    margin-top: 0.5rem;

    span {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 150%;
      color: ${({ theme }) => theme.colors.gray5};

      margin-top: 0.5rem;
      margin-right: 0.25rem;
    }
  }
`;

export const CardProjectTypeContent = styled.div<CardHeadProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  gap: 0.25rem;

  margin-right: 1.75rem;

  p {
    font-style: normal;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1rem;
    color: ${({ projectType, theme }) =>
      projectType === 'classification'
        ? theme.colors.secondary
        : theme.colors.primary};
  }

  svg {
    color: ${({ projectType, theme }) =>
      projectType === 'classification'
        ? theme.colors.secondary
        : theme.colors.primary} !important;
  }
`;

export const CardOptionsButton = styled.button<CardHeadProps>`
  position: absolute;
  top: 1.45rem;
  right: 1rem;

  z-index: 1000;

  width: 1.25rem;
  height: 1.25rem;

  svg {
    color: ${({ projectType, theme }) =>
      projectType === 'classification'
        ? theme.colors.secondary
        : theme.colors.primary};
  }
`;

interface MenuOverlayProps {
  visible: boolean;
}

export const CardMenuOverlay = styled.div<MenuOverlayProps>`
  width: 100vw;
  height: 100vh;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
`;
