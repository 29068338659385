import styled from 'styled-components';

export const Container = styled.div``;

export const ContentTitle = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 1.5rem;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.gray4};
    transition: 0.2s color;

    &:hover {
      color: ${({ theme }) => theme.colors.gray5};
    }
  }
`;
