import React, { useContext, useMemo, useRef, useState } from 'react';

import { ColDef, ExcelStyle } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { format } from 'date-fns';
import { DownloadSimple, PencilSimple } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useQueries } from 'react-query';
import { useSelector } from 'react-redux';
import { AgGridTable } from 'src/components/AgGridTable';
import { Button } from 'src/components/Button';
import { Card } from 'src/components/Card';
import { SelectedFilters } from 'src/components/SelectedFilters';
import { SelectedFilterOptions } from 'src/components/SelectedFilters/types';
import { WorkspaceProjectionsContext } from 'src/workspaces/contexts/WorkspaceProjectionsContext';
import apiWorkspace from 'src/workspaces/service/api';
import { RootState } from 'src/redux/store';
import light from 'src/styles/themes/light';
import { transformUppercaseFirstLetter } from 'src/utils/strings/transformUppercaseFirstLetter';

import { Container } from './styles';

export interface ResponseExplanatory {
  forecast: {
    date: (string | number)[];
    value: number[];
  };
  historical: {
    date: (string | number)[];
    value: number[];
  };
}

interface ResponseVariableY {
  historical: {
    dates: string[];
    values: number[];
  };
  forecast: {
    dates: string[];
    values: number[];
  };
}

type YAdjustedToQuery = {
  name: string;
  isExplanatory: boolean;
};

type RowDataObj = {
  [key: string]: {
    dates: string[];
    values: number[];
  };
};

export const Export: React.FC = () => {
  const [tableRendered, setTableRendered] = useState(false);

  const {
    auth: { user },
    workspace,
    workspaceProjectionsOptions: {
      inflation,
      frequency,
      forecastType,
      transformations,
    },
  } = useSelector((state: RootState) => state);

  const { listOfVariables, isLoadingListOfVariables } = useContext(
    WorkspaceProjectionsContext,
  );

  const { t: translate } = useTranslation();

  const levelAgGridRef = useRef<AgGridReact>(null);
  const variationAgGridRef = useRef<AgGridReact>(null);

  const selectedFilterInflation =
    user.language === 'pt-br'
      ? `${translate('value')} ${inflation}`
      : `${transformUppercaseFirstLetter(inflation)} ${translate(
          'value',
        ).toLowerCase()}`;

  const selectedFilters: SelectedFilterOptions[] = [
    { type: 'frequency', selected: translate(frequency) },
  ];

  const hasForecastLabel =
    workspace.frequency === 'monthly' &&
    workspace.releaseSelected?.id !== workspace.releasePreview;

  hasForecastLabel &&
    selectedFilters.push({
      type: 'other',
      id: 'forecast',
      icon: <PencilSimple />,
      selected: translate(
        forecastType === 'adjusted'
          ? 'workspaceProjectionsMostRecent'
          : forecastType,
      ),
    });

  const hasInflationLabel = workspace.releaseSelected?.data.ys.some(
    (y) => y.is_inflated,
  );

  hasInflationLabel &&
    selectedFilters.push({
      type: 'inflation',
      selected: selectedFilterInflation,
    });

  const excelBorders = {
    borderBottom: {
      color: light.colors.gray3,
    },
    borderTop: {
      color: light.colors.gray3,
    },
    borderLeft: {
      color: light.colors.gray3,
    },
    borderRight: {
      color: light.colors.gray3,
    },
  };

  const defaultOptions: ExcelStyle[] = [
    {
      id: 'header',
      alignment: {
        horizontal: 'Center',
      },
      borders: excelBorders,
    },
    {
      id: 'forecast-values',
      font: {
        color: light.colors.secondary,
      },
      interior: {
        color: '#FEF5F9',
        pattern: 'Solid',
      },
      borders: excelBorders,
    },
    {
      id: 'historical-values',
      borders: excelBorders,
    },
  ];

  const defaultColDef: ColDef = {
    flex: 1,
    minWidth: 90,
    sortable: false,
    unSortIcon: true,
    resizable: false,
    suppressMovable: true,
  };

  const columnDefs = useMemo(() => {
    if (listOfVariables) {
      const columnsDefsAux = [
        {
          headerName: 'period',
          field: 'period',
        },
        {
          headerName: listOfVariables.date,
          field: listOfVariables.date,
        },
        {
          headerName: listOfVariables.y,
          field: listOfVariables.y,
        },
      ];

      listOfVariables.values.forEach((y) => {
        columnsDefsAux.push({
          headerName: y.label[user.language],
          field: y.label[user.language],
        });
      });

      return columnsDefsAux;
    }

    return [];
  }, [listOfVariables, user.language]);

  const ysAdjustedToQueries: YAdjustedToQuery[] = [];

  if (listOfVariables) {
    ysAdjustedToQueries.push({
      name: listOfVariables.y,
      isExplanatory: false,
    });

    listOfVariables.values.forEach((value) => {
      ysAdjustedToQueries.push({
        name: value.value,
        isExplanatory: true,
      });
    });
  }

  const levelResponses = useQueries(
    ysAdjustedToQueries.map((y) => ({
      queryKey: [
        'workspace',
        'projections export',
        workspace.id,
        workspace.releaseSelected?.id,
        y.name,
        frequency,
        inflation,
        forecastType,
      ],
      queryFn: async () => {
        const inflationAux = inflation === 'nominal' ? 'inflate' : 'original';

        if (y.isExplanatory) {
          const { data } = await apiWorkspace.get<ResponseExplanatory>(
            `/workspaces/${workspace.id}/releases/${workspace.releaseSelected?.id}/ys/${workspace.ySelected?.y_label}/models/${workspace.ySelected?.model_id}/business/variables/${y.name}?frequency=${frequency}&transformation=level`,
          );

          return {
            historical: {
              dates: data.historical.date,
              values: data.historical.value,
            },
            forecast: {
              dates: data.forecast.date,
              values: data.forecast.value,
            },
          };
        }

        const { data } = await apiWorkspace.get<ResponseVariableY>(
          `/workspaces/${workspace.id}/releases/${workspace.releaseSelected?.id}/ys/${workspace.ySelected?.y_label}/y/${y.name}?frequency=${frequency}&transformation=level&inflation=${inflationAux}&data_type=${forecastType}`,
        );

        return data;
      },
      staleTime: Infinity,
    })),
  );

  const variationResponses = useQueries(
    ysAdjustedToQueries.map((y) => ({
      queryKey: [
        'workspace',
        'projections export',
        workspace.id,
        workspace.releaseSelected?.id,
        y.name,
        frequency,
        inflation,
        forecastType,
        transformations.includes('variationPoP')
          ? 'variationPoP'
          : 'variationYoY',
      ],
      queryFn: async () => {
        const inflationAux = inflation === 'nominal' ? 'inflate' : 'original';

        if (y.isExplanatory) {
          const { data } = await apiWorkspace.get<ResponseExplanatory>(
            `/workspaces/${workspace.id}/releases/${
              workspace.releaseSelected?.id
            }/ys/${workspace.ySelected?.y_label}/models/${
              workspace.ySelected?.model_id
            }/business/variables/${
              y.name
            }?frequency=${frequency}&transformation=variation&variation_method=${
              transformations.includes('variationPoP')
                ? 'over_before'
                : 'year_over_year'
            }`,
          );

          return {
            historical: {
              dates: data.historical.date,
              values: data.historical.value,
            },
            forecast: {
              dates: data.forecast.date,
              values: data.forecast.value,
            },
          };
        }

        const { data } = await apiWorkspace.get<ResponseVariableY>(
          `/workspaces/${workspace.id}/releases/${
            workspace.releaseSelected?.id
          }/ys/${workspace.ySelected?.y_label}/y/${
            y.name
          }?frequency=${frequency}&transformation=variation&inflation=${inflationAux}&data_type=${forecastType}&variation_method=${
            transformations.includes('variationPoP')
              ? 'over_before'
              : 'year_over_year'
          }`,
        );

        return data;
      },
      staleTime: Infinity,
    })),
  );

  const levelIsLoading =
    levelResponses.every((response) => response.isLoading === true) ||
    isLoadingListOfVariables;

  const variationIsLoading =
    variationResponses.every((response) => response.isLoading === true) ||
    isLoadingListOfVariables;

  const levelRowData = useMemo(() => {
    if (listOfVariables && !levelIsLoading) {
      const ys = [
        listOfVariables.y,
        ...listOfVariables.values.map((value) => value.label[user.language]),
      ];

      const rowDataObj: RowDataObj = levelResponses.reduce(
        (acc, response, index) => {
          const historicalValues: number[] = [];
          const historicalDates: (string | number)[] = [];

          const forecastValues: number[] = [];
          const forecastDates: (string | number)[] = [];

          if (response.data?.historical) {
            historicalValues.push(...response.data.historical.values);
            historicalDates.push(...response.data.historical.dates);
          }

          if (response.data?.forecast) {
            forecastValues.push(...response.data?.forecast.values);
            forecastDates.push(...response.data.forecast.dates);
          }

          return {
            ...acc,
            [ys[index]]: {
              dates: [...historicalDates, ...forecastDates],
              values: [...historicalValues, ...forecastValues],
            },
          };
        },
        {},
      );

      const keys = Object.keys(rowDataObj);

      const quantityHistorical =
        levelResponses[0].data?.historical.dates.length ?? 0;
      const quantityForecast =
        levelResponses[0].data?.forecast.dates.length ?? 0;

      const rowDataAux = [];

      for (let i = 0; i < quantityHistorical + quantityForecast; i++) {
        let row: Record<string, number | string> = {};
        keys.forEach((key) => {
          row = { ...row, [key]: rowDataObj[key].values[i] };
        });

        row[listOfVariables.date] = format(
          new Date(rowDataObj[keys[0]].dates[i].replace('Z', '')),
          'yyyy-MM-dd',
        );

        row.period = i < quantityHistorical ? 'historical' : 'forecast';

        rowDataAux.push(row);
      }

      return rowDataAux;
    }
    return [];
  }, [levelIsLoading, listOfVariables, levelResponses, user.language]);

  const variationRowData = useMemo(() => {
    if (listOfVariables && !variationIsLoading) {
      const ys = [
        listOfVariables.y,
        ...listOfVariables.values.map((value) => value.label[user.language]),
      ];

      const rowDataObj: RowDataObj = variationResponses.reduce(
        (acc, response, index) => {
          const historicalValues: number[] = [];
          const historicalDates: (string | number)[] = [];

          const forecastValues: number[] = [];
          const forecastDates: (string | number)[] = [];

          if (response.data?.historical) {
            historicalValues.push(...response.data.historical.values);
            historicalDates.push(...response.data.historical.dates);
          }

          if (response.data?.forecast) {
            forecastValues.push(...response.data?.forecast.values);
            forecastDates.push(...response.data.forecast.dates);
          }

          return {
            ...acc,
            [ys[index]]: {
              dates: [...historicalDates, ...forecastDates],
              values: [...historicalValues, ...forecastValues],
            },
          };
        },
        {},
      );

      const keys = Object.keys(rowDataObj);

      const quantityHistorical =
        variationResponses[0].data?.historical.dates.length ?? 0;
      const quantityForecast =
        variationResponses[0].data?.forecast.dates.length ?? 0;

      const rowDataAux = [];

      for (let i = 0; i < quantityHistorical + quantityForecast; i++) {
        let row: Record<string, number | string> = {};
        keys.forEach((key) => {
          row = { ...row, [key]: rowDataObj[key].values[i] };
        });

        row[listOfVariables.date] = format(
          new Date(rowDataObj[keys[0]].dates[i].replace('Z', '')),
          'yyyy-MM-dd',
        );

        row.period = i < quantityHistorical ? 'historical' : 'forecast';

        rowDataAux.push(row);
      }

      return rowDataAux;
    }
    return [];
  }, [variationIsLoading, listOfVariables, variationResponses, user.language]);

  function download() {
    const spreadsheets: any[] = [];

    if (levelAgGridRef?.current && transformations.includes('level')) {
      spreadsheets.push(
        levelAgGridRef.current.api.getSheetDataForExcel({
          sheetName: translate('level'),
          suppressRowOutline: true,
          exportedRows: 'all',
        }),
      );
    }

    if (
      variationAgGridRef?.current &&
      (transformations.includes('variationPoP') ||
        transformations.includes('variationYoY'))
    ) {
      const sheetName = `${translate('variation')} % (${
        transformations.includes('variationPoP') ? 'PoP' : 'YoY'
      })`;

      const variationDict = {
        monthly: 'MoM',
        bimonthly: 'BoB',
        quarterly: 'QoQ',
        'half-year': 'HoH',
        annual: 'YoY',
        yearly: 'YoY',
        other: 'PoP',
      } as any;

      const variationPoPFrequency =
        (frequency === 'original' ? workspace.frequency : frequency) ?? 'other';

      spreadsheets.push(
        variationAgGridRef.current.api.getSheetDataForExcel({
          sheetName: sheetName.replace(
            'PoP',
            variationDict[variationPoPFrequency] ?? 'PoP',
          ),
          suppressRowOutline: true,
          exportedRows: 'all',
        }),
      );
    }

    levelAgGridRef.current!.api.exportMultipleSheetsAsExcel({
      data: spreadsheets,
      fileName: `${workspace.name?.replaceAll('.', ' ')} - ${
        workspace.releaseSelected?.label?.replaceAll('.', ' ') ??
        workspace.releaseSelected?.name?.replaceAll('.', ' ')
      } - ${workspace.ySelected?.y_label?.replaceAll('.', ' ')}`,
    });
  }

  return (
    <>
      <Container className="containerLinear">
        <div>
          <Card
            textCard={translate('workspaceProjectionsExportTitle')}
            textDescription={translate('workspaceProjectionsExportDescription')}
          />

          <SelectedFilters
            filters={selectedFilters}
            data-testid="selected-filters-results"
          />
        </div>

        <Button
          buttonType="secondary"
          icon={<DownloadSimple size="1.125rem" weight="bold" />}
          data-testid="ia-selection-export-button"
          data-cy="ia-selection-export-button"
          onClick={download}
          loading={levelIsLoading || variationIsLoading}
          disabled={!tableRendered}
        >
          {translate('workspaceProjectionsExportDownload')}
        </Button>
      </Container>
      <AgGridTable
        tableRef={levelAgGridRef}
        rowData={levelRowData}
        columnDefs={columnDefs}
        excelStyles={defaultOptions}
        defaultColDef={defaultColDef}
        onFirstDataRendered={() => setTableRendered(true)}
        checkDomLayoutType={tableRendered}
        containerStyle={{
          display: 'none',
        }}
      />
      <AgGridTable
        tableRef={variationAgGridRef}
        rowData={variationRowData}
        columnDefs={columnDefs}
        excelStyles={defaultOptions}
        defaultColDef={defaultColDef}
        onFirstDataRendered={() => setTableRendered(true)}
        checkDomLayoutType={tableRendered}
        containerStyle={{
          display: 'none',
        }}
      />
    </>
  );
};
