import React, { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Card } from 'src/components/Card';
import { CheckBox } from 'src/components/CheckBox';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ChartBar, ChartLine } from 'phosphor-react';
import { getChartColor } from 'src/utils/colors/getChartColor';
import { useQuery } from 'react-query';
import api from 'src/models/service/api';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { HCharts, HChartsOptions, HChartsSeries } from 'src/components/HCharts';
import { formatCompactNotation } from 'src/utils/numbers/formatCompactNotation';
import { ChartTypeButton } from 'src/components/ChartTypeButton';

import { ChartButton, ChartHeader } from './styles';
import { ErrorDataView } from '../../types';

interface Transformations {
  name: string;
  data_tidy: string[];
  value: number[];
}

export const HistoricalData: React.FC = () => {
  const [transformationOptionSelect, setTransformationOptionSelect] = useState<
    string[]
  >(['orig']);

  const [chartType, setChartType] = useState<'line' | 'column'>('line');

  const id = useSelector(
    (state: RootState) =>
      state.modelling.form?.fileDataSet.dataset_id ?? state.project.id,
  );

  const { t: translate } = useTranslation();

  const {
    data: transformationsData,
    isLoading: isTransformationsLoading,
    isFetching: isTransformationsFetching,
    isError: isTransformationsErrored,
    error: transformationsError,
  } = useQuery<Transformations[], ErrorDataView>(
    ['dataview', 'transformations', id],
    async () => {
      const { data } = await api.get<Transformations[]>(
        `/projects/${id}/dataview/y/transformations`,
      );

      if (data) {
        const hasDessaz = data.some(
          (transformation) => transformation.name === 'sa',
        );

        hasDessaz && setTransformationOptionSelect(['orig', 'sa']);
      }

      return data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled: !!id,
    },
  );

  function changechartType() {
    if (chartType === 'line') setChartType('column');
    else setChartType('line');
  }

  const series: HChartsSeries[] = useMemo(() => {
    const seriesAux: HChartsSeries[] = transformationOptionSelect?.map(
      (optionSelected): HChartsSeries => {
        const transformation = transformationsData?.find(
          (trans) => trans.name === optionSelected,
        );

        const transformationIndex = transformationsData?.indexOf(
          transformation!,
        );

        return {
          type: chartType,
          color: getChartColor(transformationIndex!),
          marker: {
            symbol: 'circle',
          },
          dashStyle: transformationIndex === 0 ? 'Solid' : 'Dash',
          name: transformation?.name,
          data: transformation?.data_tidy.map((data, dataIndex) => ({
            x: new Date(`${data}T00:00`).getTime(),
            y: transformation.value[dataIndex],
            custom: {
              value: formatCompactNotation(transformation.value[dataIndex]),
            },
          })),
        };
      },
    );

    return seriesAux;
  }, [chartType, transformationOptionSelect, transformationsData]);

  const options: HChartsOptions = {
    chart: {
      height: 300,
    },
    tooltip: {
      pointFormat:
        `<tr><td><b>${translate('date')}:</b> </td>` +
        `<td style="text-align: right">{point.x: ${' %d/%m/%Y'}}</td></tr>` +
        `<tr><td><b>${translate('value')}:</b> </td>` +
        '<td style="text-align: right">{point.custom.value}</td></tr>',
    },
  };

  return (
    <div className="containerLinear" data-cy="historical-data">
      <Card textCard={translate('historical_data')} />
      {isTransformationsErrored ? (
        <ContainerMaintenance
          content="chart"
          text={
            transformationsError?.response?.data?.detail?.detail ??
            transformationsError?.response?.data?.detail?.description
          }
          data-testid="transformations-error-container"
          data-cy="transformations-error-container"
        />
      ) : isTransformationsLoading ||
        isTransformationsFetching ||
        !transformationsData ? (
        // eslint-disable-next-line react/jsx-indent
        <ContainerSkeleton
          data-testid="loading-transformations"
          data-cy="loading-transformations"
        />
      ) : (
        transformationsData && (
          <>
            <ChartHeader>
              <div>
                {transformationsData.map((transformation) => (
                  <CheckBox
                    label={transformation.name}
                    key={`checkbox-${transformation.name}`}
                    checked={transformationOptionSelect.includes(
                      transformation.name,
                    )}
                    onChange={({ target }) =>
                      target.checked
                        ? setTransformationOptionSelect([
                            ...transformationOptionSelect,
                            transformation.name,
                          ])
                        : setTransformationOptionSelect(
                            transformationOptionSelect.filter(
                              (value) => value !== transformation.name,
                            ),
                          )
                    }
                  />
                ))}
              </div>
              <ChartButton>
                <ChartTypeButton
                  icon={<ChartBar />}
                  active={chartType === 'column'}
                  onClick={changechartType}
                  data-cy="button-bar"
                  data-testid="button-bar"
                />

                <ChartTypeButton
                  icon={<ChartLine />}
                  active={chartType === 'line'}
                  onClick={changechartType}
                  data-cy="button-line"
                  data-testid="button-line"
                />
              </ChartButton>
            </ChartHeader>

            {transformationOptionSelect.length > 0 ? (
              <HCharts
                series={series}
                options={options}
                dataCy="chart-transformations"
                resizeWidthWithSidebar
              />
            ) : (
              <ContainerMaintenance
                content="chart"
                text={translate('dataViewErrorSelectAtLeastOne')}
                data-testid="transformations-no-checkbox"
              />
            )}
          </>
        )
      )}
    </div>
  );
};
