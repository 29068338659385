import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { AgGridReact } from 'ag-grid-react';
import ms from 'ms';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { AgGridTable } from 'src/components/AgGridTable';
import apiWorkspace from 'src/workspaces/service/api';
import { RootState } from 'src/redux/store';
import { queryClient } from 'src/service/queryClient';
import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  EditableCallbackParams,
  ExcelExportParams,
  ExcelStyle,
  GetRowIdParams,
  IAggFuncParams,
  ICellRendererParams,
  IRowNode,
  RowClassRules,
  RowHeightParams,
  ValueFormatterParams,
} from 'ag-grid-enterprise';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Status } from 'src/components/Status';
import { WorkspaceProjectionsForecastType } from 'src/workspaces/redux/reducers/WorkspaceProjectionsOptions';
import {
  Step,
  StepStatus,
  changeEditionModeEnabled,
  changeIsCreatingStep,
  changeIsDiscardingFirstStep,
  changeLastLogTime,
  changeReviewModeEnabled,
  changeWorkspaceOverviewBimonthlyPeriod,
  changeWorkspaceOverviewForecast,
  changeWorkspaceOverviewHalfYearPeriod,
  changeWorkspaceOverviewOriginalPeriod,
  changeWorkspaceOverviewQuarterlyPeriod,
  changeWorkspaceOverviewStep,
  changeWorkspaceOverviewYearlyPeriod,
} from 'src/workspaces/redux/reducers/WorkspaceOverviewOptions';
import { addMonths, subMonths } from 'date-fns';
import { AxiosError } from 'axios';
import { Button } from 'src/components/Button';
import {
  ArrowsClockwise,
  CheckCircle,
  DownloadSimple,
  FloppyDisk,
  LockSimple,
  PaperPlaneTilt,
  PencilSimple,
  SignOut,
  Trash,
  Warning,
  X,
} from 'phosphor-react';
import { Tooltip } from 'react-tooltip';
import { sleep } from 'src/utils/sleep';
import { updateCanSyncAdjust } from 'src/workspaces/redux/reducers/Workspace';
import { SelectedFilters } from 'src/components/SelectedFilters';
import { SelectedFilterOptions } from 'src/components/SelectedFilters/types';
import { transformUppercaseFirstLetter } from 'src/utils/strings/transformUppercaseFirstLetter';
import { Card } from 'src/components/Card';
import { Modal } from 'src/components/Modal';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import { ButtonRounded } from 'src/components/ButtonRounded';
import light from 'src/styles/themes/light';
import { ISetFilterParams } from '@ag-grid-community/core';
import { unlockEdition } from 'src/workspaces/utils/lockUnlockEdition';
import { useQueryYTypes } from 'src/workspaces/hooks/useQueryYTypes';

import { GroupRowCell } from '../TableRenderer/GroupRowCell';
import { ValueCell } from '../TableRenderer/ValueCell';
import { SessionExpiredModal } from '../Modals/SessionExpired';
import {
  ButtonUpdate,
  Container,
  Content,
  DiscardingAdjustmentsContainer,
  HeaderRightContent,
  SelectedFiltersExportContainer,
  SubmitApprovalContainer,
  TableButtonsContainer,
  TableContainer,
  TableError,
  TableStatus,
  Tag,
} from './styles';
import {
  Columns,
  DateIds,
  EditedValue,
  EditedValuesByYs,
  ExternalFilter,
  Frequency,
  FrequencyIntervalInMonths,
  MarketShareAggValues,
  MarketShareRow,
  RowData,
  SaveEditionBody,
  SaveEditionResponse,
  SerieData,
  SerieOptions,
  StartEndYForecastDates,
  UpdateStepStatusBody,
  YsDateIds,
  YsResponse,
} from './types';
import { aggFuncs } from './utils/aggFuncs';
import {
  dateFormatter,
  numberFormatter,
  variableTypeFormatter,
} from './utils/valueFormatter';
import { getSeriesQueryKey } from './utils/queryKeys';
import { useQueryStepLog } from '../../../../hooks/useQueryStepLog';
import { useQueryReleaseData } from '../../../../hooks/useQueryReleaseData';
import { NoPermissionToEditModal } from '../../../../components/NoPermissionToEdit';
import { FlowMessage } from '../FlowMessage';
import { getSelectedFrequencyAndPeriod } from '../../utils/getSelectedFrequencyAndPeriod';
import { RequestApproval } from '../RequestApproval';
import { ActionConfirmationModal } from '../Modals/ActionConfirmation';
import { LogRecord } from '../../../../hooks/useQueryStepLog/types';
import { ApproveOrDisapproveModal } from '../Modals/ApproveOrDisapprove';
import { UnsavedYsModal } from '../Modals/UnsavedYs';
import { HierarchicalFilter } from '../HierarchicalFilter';

const frequencyIntervalInMonths: FrequencyIntervalInMonths = {
  daily: {
    historical: 3,
    forecast: 1,
  },
  weekly: {
    historical: 13,
    forecast: 1,
  },
  fortnightly: {
    historical: 12,
    forecast: 2,
  },
  monthly: {
    historical: 12,
    forecast: 12,
  },
  bimonthly: {
    historical: 24,
    forecast: 12,
  },
  quarterly: {
    historical: 12,
    forecast: 6,
  },
  'half-year': {
    historical: 24,
    forecast: 24,
  },
  yearly: {
    historical: 48,
    forecast: 24,
  },
  annual: {
    historical: 48,
    forecast: 24,
  },
};

export const Results: React.FC = memo(() => {
  const [tableRendered, setTableRendered] = useState(false);

  const [rowData, setRowData] = useState<RowData[]>([]);
  const [columns, setColumns] = useState<Columns>([]);

  const [ysDateIds, setYsDateIds] = useState<YsDateIds>({});
  const [marketShareRows, setMarketShareRows] = useState<MarketShareRow[]>([]);
  const [marketShareAggValues, setMarketShareAggValues] = useState<
    MarketShareAggValues[]
  >([]);

  const [isLoadingSeriesData, setIsLoadingSeriesData] = useState(false);
  const [isLoadingMarketShare, setIsLoadingMarketShare] = useState(false);

  const [startEndYForecastDates, setStartEndYForecastDates] =
    useState<StartEndYForecastDates>({});

  const [buttonUpdateStatus, setButtonUpdateStatus] = useState<
    'opened' | 'closed' | null
  >(null);

  const [loadingEnableEdition, setLoadingEnableEdition] = useState(false);
  const [showUserIsEditingModal, setShowUserIsEditingModal] = useState('');
  const [showWorkspaceIsPublishing, setShowWorkspaceIsPublishing] =
    useState(false);
  const [editionExpired, setEditionExpired] = useState(false);

  const [showUpdateStatusError, setShowUpdateStatusError] = useState(false);

  const [editedValues, setEditedValues] = useState<EditedValue[]>([]);
  const [invalidValues, setInvalidValues] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [unsavedYs, setUnsavedYs] = useState<string[]>([]);

  const [isDiscardingAdjustments, setIsDiscardingAdjustments] = useState(false);
  const [
    showDiscardAdjustmentsConfirmation,
    setShowDiscardAdjustmentsConfirmation,
  ] = useState(false);
  const [showRequestApproval, setShowRequestApproval] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showDisapproveModal, setShowDisapproveModal] = useState(false);

  const [externalFilters, setExternalFilters] = useState<ExternalFilter[]>([]);
  const [
    externalFiltersSelectOptionsBellow,
    setExternalFiltersSelectOptionsBellow,
  ] = useState(true);
  const [
    externalFiltersSelectOptionsDisableWhenEditionMode,
    setExternalFiltersSelectOptionsDisableWhenEditionMode,
  ] = useState(false);

  const [needRefetchLogs, setNeedRefetchLogs] = useState(false);

  const tableRef = useRef<AgGridReact>(null);
  const abortControllerRef = useRef<AbortController | null>(null);

  const {
    auth: { user },
    workspace: {
      id: workspaceId,
      name: workspaceName,
      releaseSelected,
      frequency: workspaceFrequency,
      userRole,
      releasePreview,
    },
    workspaceOverviewOptions: {
      frequency,
      transformation,
      forecast: forecastType,
      period,
      editionModeEnabled,
      inflation,
      step,
      lastLogTimeByStep,
      reviewModeEnabled,
      isDiscadingFirstStep,
      isEditingPlanningFlow,
      isCreatingStep,
    },
  } = useSelector((state: RootState) => state);

  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  const { data: releaseData, isLoading: releaseIsLoading } =
    useQueryReleaseData(workspaceId, releaseSelected?.id);

  const { data: updatedStepLogData, isLoading: updatedStepLogIsLoading } =
    useQueryStepLog(
      workspaceId,
      releaseSelected?.id ?? null,
      step?.number ?? null,
      'updated_step',
      0,
      1,
      needRefetchLogs,
    );

  const { data: adjustedStepLogData } = useQueryStepLog(
    workspaceId,
    releaseSelected?.id ?? null,
    step?.number ?? null,
    'adjusted',
    0,
    1,
    needRefetchLogs,
  );

  const { data: discardedAdjustedStepLogData } = useQueryStepLog(
    workspaceId,
    releaseSelected?.id ?? null,
    step?.number ?? null,
    'discarded_adjusted',
    0,
    1,
    needRefetchLogs,
  );

  const { data: removedStepLogData } = useQueryStepLog(
    workspaceId,
    releaseSelected?.id ?? null,
    step?.number ?? null,
    'removed_step',
    0,
    1,
    needRefetchLogs,
  );

  const {
    data: ysInfoData,
    isLoading: ysInfoIsLoading,
    isFetching: ysInfoIsFetching,
    isError: ysInfoIsError,
  } = useQuery(
    ['workspace', 'ys info', workspaceId, releaseSelected?.id],
    async () => {
      const { data } = await apiWorkspace.get<YsResponse>(
        `workspaces/${workspaceId}/releases/${releaseSelected?.id}/ys`,
      );

      return data;
    },
    {
      enabled: !!workspaceId && !!releaseSelected?.id,
      staleTime: ms('1 day'),
    },
  );

  const { data: dataTypes } = useQueryYTypes();

  const dataTypesDict: { [key: string]: string } =
    dataTypes?.reduce(
      (obj, value) => ({
        ...obj,
        [value.code]: value.label,
      }),
      {},
    ) ?? {};

  const selectedFilters = useMemo(() => {
    const selectedFilterInflation =
      user.language === 'pt-br'
        ? `${translate('value')} ${inflation}`
        : `${transformUppercaseFirstLetter(inflation)} ${translate(
            'value',
          ).toLowerCase()}`;

    const variationDict = {
      monthly: 'MoM',
      bimonthly: 'BoB',
      quarterly: 'QoQ',
      'half-year': 'HoH',
      annual: 'YoY',
      yearly: 'YoY',
      other: 'PoP',
    } as any;

    const variationPoPFrequency =
      (frequency === 'original' ? workspaceFrequency : frequency) ?? 'other';

    const filters: SelectedFilterOptions[] = [
      {
        type: 'frequency',
        selected: getSelectedFrequencyAndPeriod(
          translate(frequency),
          period[frequency === 'monthly' ? 'original' : frequency],
          user.language,
        ),
      },
      {
        type: 'transformation',
        selected: translate(transformation).replace(
          'PoP',
          variationDict[variationPoPFrequency],
        ),
      },
    ];

    const hasForecastLabel =
      workspaceFrequency === 'monthly' &&
      !releaseData?.data.approval_flow?.enable &&
      releaseSelected?.id !== releasePreview;

    hasForecastLabel &&
      filters.push({
        type: 'other',
        id: 'forecast',
        icon: <PencilSimple />,
        selected: translate(
          forecastType === 'adjusted'
            ? 'workspaceProjectionsMostRecent'
            : forecastType,
        ),
      });

    const hasInflationLabel = releaseSelected?.data?.ys.some(
      (y) => y.is_inflated,
    );

    hasInflationLabel &&
      filters.push({
        type: 'inflation',
        selected: selectedFilterInflation,
      });

    return filters;
  }, [
    forecastType,
    frequency,
    inflation,
    period,
    releaseData?.data.approval_flow?.enable,
    releaseSelected?.id,
    releasePreview,
    transformation,
    translate,
    user.language,
    workspaceFrequency,
    releaseSelected?.data.ys,
  ]);

  const rowClassRules = useMemo<RowClassRules>(
    () => ({
      'marketshare-cell': (params) =>
        !!params.node.id?.startsWith('marketshare-') ||
        !!params.node.id?.startsWith('row-group-y_type-marketshare-'),
    }),
    [],
  );

  const excelStyles: ExcelStyle[] = useMemo(() => {
    if (ysInfoData) {
      const excelBorders = {
        borderBottom: {
          color: light.colors.gray3,
        },
        borderTop: {
          color: light.colors.gray3,
        },
        borderLeft: {
          color: light.colors.gray3,
        },
        borderRight: {
          color: light.colors.gray3,
        },
      };

      const defaultOptions: ExcelStyle[] = [
        {
          id: 'header',
          alignment: {
            horizontal: 'Center',
          },
          borders: excelBorders,
        },
        {
          id: 'forecast-values',
          font: {
            color: light.colors.secondary,
          },
          interior: {
            color: '#FEF5F9',
            pattern: 'Solid',
          },
          borders: excelBorders,
        },
        {
          id: 'historical-values',
          borders: excelBorders,
        },
        {
          id: 'planning-horizon-values',
          font: {
            color: light.colors.primary,
          },
          interior: {
            color: '#EAF2FC',
            pattern: 'Solid',
          },
          borders: excelBorders,
        },
      ];

      return defaultOptions;
    }

    return [];
  }, [ysInfoData]);

  const defaultColDef: ColDef = useMemo(
    () => ({
      flex: 1,
      minWidth: 90,
      sortable: false,
      unSortIcon: true,
      resizable: false,
      suppressMovable: true,
    }),
    [],
  );

  const autoGroupColumnDef: ColDef = useMemo(
    () => ({
      headerName: translate('workspaceOverviewResultsGroup'),
      field: 'y_label',
      pinned: 'left',
      flex: 1,
      minWidth: 200,
      width: 250,
      autoHeight: true,
      suppressMovable: true,
      lockPinned: true,
      suppressHeaderMenuButton: true,
      enableRowGroup: true,
      resizable: true,
      cellRendererParams: {
        suppressCount: true,
      },
      sort: 'asc',
      comparator: (valueA, valueB, nodeA, nodeB) => {
        let yA = valueA;
        let yB = valueB;
        if (nodeA.allLeafChildren?.length) {
          yA = nodeA.allLeafChildren[0].id;
        }
        if (nodeB.allLeafChildren?.length) {
          yB = nodeB.allLeafChildren[0].id;
        }
        return yA?.localeCompare(yB);
      },
      cellRenderer: (row: ICellRendererParams) => (
        <GroupRowCell
          row={row}
          isLoading={isLoadingSeriesData}
          isMarketShareLoading={isLoadingMarketShare}
          hasYTypeColumn={!!ysInfoData?.hierarchies.length}
        />
      ),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoadingSeriesData, isLoadingMarketShare, ysInfoData?.hierarchies.length],
  );

  const getRowId = useCallback(
    (params: GetRowIdParams) => params.data.row_id,
    [],
  );

  const getRowHeight = useCallback(
    (params: RowHeightParams) => {
      const hasYType = ysInfoData?.hierarchies.length;

      if (hasYType && params.node.level === 0) {
        return 1;
      }

      return 44;
    },
    [ysInfoData?.hierarchies.length],
  );

  const getYTypeCalc = useCallback(
    (yType: string) => {
      if (!ysInfoData?.aggregation?.options?.hierarchical.length) {
        return ysInfoData?.aggregation?.enable ? 'sum' : 'not_aggregated';
      }

      return (
        ysInfoData.aggregation.options?.hierarchical.find(
          ({ y_type }) => yType === y_type,
        )?.frequency_method ?? 'sum'
      );
    },
    [
      ysInfoData?.aggregation?.options?.hierarchical,
      ysInfoData?.aggregation?.enable,
    ],
  );

  const getSerieData = useCallback(
    async (
      yLabel: string,
      aggregationQuery: string[] = [],
      originalOrAdjusted: WorkspaceProjectionsForecastType,
      isInflated: boolean,
      yType: string | undefined = undefined,
      stepNumberAux?: number,
    ) => {
      const stepNumber = stepNumberAux ?? step?.number ?? 1;

      const showYType = ysInfoData?.hierarchies.length;

      const queryKey = getSeriesQueryKey(
        workspaceId ?? '',
        releaseSelected?.id ?? '',
        aggregationQuery.toString().replaceAll(',', ' ') || yLabel,
        frequency,
        transformation,
        inflation,
        originalOrAdjusted,
        stepNumber,
        showYType ? yType : undefined,
      );

      let queryData: SerieData;

      const response = queryClient.getQueryData<SerieData>(queryKey);
      const queryState = queryClient.getQueryState(queryKey);

      let serieInflation: 'inflate' | 'original' = 'inflate';

      if (
        inflation === 'real' ||
        (!aggregationQuery.length && isInflated === false)
      ) {
        serieInflation = 'original';
      }

      if (response && !queryState?.isInvalidated && !queryState?.isFetching) {
        queryData = response;
      } else {
        let route = '';

        const queryParams = new URLSearchParams();

        queryParams.append('frequency', frequency);

        if (transformation.includes('variation')) {
          queryParams.append('transformation', 'variation');
          queryParams.append(
            'variation_method',
            transformation === 'variationYoY'
              ? 'year_over_year'
              : 'over_before',
          );
        } else {
          queryParams.append('transformation', transformation);
        }

        queryParams.append('inflation', serieInflation);

        queryParams.append('data_type', originalOrAdjusted);

        queryParams.append('step', stepNumber.toString());

        if (aggregationQuery.length || yType) {
          let hierarchy = '';
          aggregationQuery.forEach((aggregation) => {
            hierarchy = hierarchy.concat(
              `hierarchy=${encodeURI(aggregation)}&`,
            );
          });

          if (yType === 'marketshare') {
            route = `/workspaces/${workspaceId}/releases/${
              releaseSelected?.id
            }/series/calculated?calc=marketshare&${hierarchy}${queryParams.toString()}`;
          } else {
            const typeAux =
              showYType && yType ? encodeURI(`filter=y_type=${yType}&`) : '';

            let calc = 'sum';

            if (
              ysInfoData?.aggregation?.enable &&
              ysInfoData.aggregation.options?.hierarchical.length
            ) {
              calc = 'aggregation';
            }

            route = `/workspaces/${workspaceId}/releases/${
              releaseSelected?.id
            }/series/calculated?calc=${calc}&${hierarchy}${typeAux}${queryParams.toString()}`;
          }
        } else {
          route = `/workspaces/${workspaceId}/releases/${
            releaseSelected?.id
          }/series/raw/${yLabel}?${queryParams.toString()}`;
        }

        try {
          queryData = await queryClient.fetchQuery<SerieData>(
            queryKey,
            async () => {
              const { data } = await apiWorkspace.get<SerieData>(route);

              return {
                serie: yLabel,
                historical: data.historical,
                forecast: data?.forecast,
              };
            },
            {
              staleTime: ms('1 day'),
              cacheTime: ms('1 day'),
            },
          );
        } catch {
          queryData = {
            serie: yLabel,
            historical: {
              dates: [],
              values: [],
              ids: [],
            },
          };
        }
      }

      return queryData;
    },
    [
      frequency,
      inflation,
      releaseSelected?.id,
      transformation,
      workspaceId,
      step?.number,
      ysInfoData?.hierarchies.length,
      ysInfoData?.aggregation?.enable,
      ysInfoData?.aggregation?.options?.hierarchical.length,
    ],
  );

  const getYsPromises = useCallback(
    (startIndex: number, endIndex: number) => {
      const originalYsPromises: Promise<SerieData>[] = [];
      const adjustedYsPromises: Promise<SerieData>[] = [];

      if (ysInfoData) {
        for (let j = startIndex; j < endIndex; j++) {
          if (ysInfoData.ys.length > j) {
            const label = ysInfoData.ys[j].y_label;
            const isInflated = ysInfoData.ys[j].is_inflated;
            originalYsPromises.push(
              getSerieData(label, [], 'original', isInflated),
            );
            adjustedYsPromises.push(
              getSerieData(label, [], 'adjusted', isInflated),
            );
          } else {
            break;
          }
        }
      }

      return { originalYsPromises, adjustedYsPromises };
    },
    [getSerieData, ysInfoData],
  );

  const getAggPromises = useCallback(
    (startIndex: number, endIndex: number) => {
      const aggsPromises: Promise<SerieData>[] = [];

      if (ysInfoData && ysInfoData.aggregation?.enable) {
        for (let j = startIndex; j < endIndex; j++) {
          if (ysInfoData.ys.length > j) {
            const label = ysInfoData.ys[j].y_label;
            const type = ysInfoData.ys[j].type;
            const isInflated = ysInfoData.ys[j].is_inflated;

            const hierarchy = ysInfoData.ys[j].hierarchy.map(
              (aux) => aux.value,
            );
            for (let i = 1; i <= hierarchy.length; i++) {
              aggsPromises.push(
                getSerieData(
                  label,
                  hierarchy.slice(0, i),
                  'original',
                  isInflated,
                  type,
                ),
                getSerieData(
                  label,
                  hierarchy.slice(0, i),
                  'adjusted',
                  isInflated,
                  type,
                ),
              );
            }
          } else {
            break;
          }
        }
      }

      return { aggsPromises };
    },
    [getSerieData, ysInfoData],
  );

  const hasPlanningHorizon =
    !!releaseData?.data.approval_flow?.enable &&
    !!releaseData.data.approval_flow.horizon_dates?.start &&
    !!releaseData.data.approval_flow.horizon_dates?.end &&
    !!releaseData.data.steps?.length;

  const isPlanningHorizon = useCallback(
    (date: Date) => {
      const { start, end } =
        releaseData?.data.approval_flow?.horizon_dates ?? {};

      return (
        !!start &&
        !!end &&
        date >= new Date(start.replace(':00Z', '')) &&
        date <= new Date(end.replace(':00Z', ''))
      );
    },
    [releaseData?.data.approval_flow?.horizon_dates],
  );

  const isExternalSerie = useCallback(
    (label: string) =>
      !!ysInfoData?.ys.some(
        (y) => y.y_label === label && y.source === 'external_series',
      ),
    [ysInfoData],
  );

  const defaultColumnCellClass = useCallback(
    (params: CellClassParams) => {
      const isAggregation = !params.data?.y_label;

      const isPlanningHorizonColumn = params.colDef.type?.includes(
        'planningHorizonColumn',
      );
      const isForecastColumn = params.colDef.type?.includes('forecastColumn');

      if (isAggregation && isPlanningHorizonColumn) {
        return 'planning-horizon-values';
      }

      if (isForecastColumn && isAggregation) {
        return 'forecast-values';
      }

      const rowId = params.data?.row_id;
      const date = new Date(params.colDef.field ?? '');

      const { startDate } = startEndYForecastDates[rowId] ?? {};

      const isForecast = !!startDate && date >= new Date(startDate);

      const rowIsExternalSerie = isExternalSerie(rowId);

      if (isPlanningHorizonColumn && (isForecast || rowIsExternalSerie)) {
        return 'planning-horizon-values';
      }

      if (rowIsExternalSerie && isForecastColumn && hasPlanningHorizon) {
        return 'forecast-values';
      }

      return isForecast ? 'forecast-values' : 'historical-values';
    },
    [isExternalSerie, startEndYForecastDates, hasPlanningHorizon],
  );

  const isForecast = useCallback(
    (yLabel: string, date: Date) => {
      const { startDate, endDate } = startEndYForecastDates[yLabel] ?? {};

      return (
        !!startDate &&
        !!endDate &&
        date >= new Date(startDate) &&
        date <= new Date(endDate)
      );
    },
    [startEndYForecastDates],
  );

  const editable = useCallback(
    (yLabel: string, date: Date, id: string) => {
      if (isExternalSerie(yLabel)) {
        return (
          editionModeEnabled && hasPlanningHorizon && isPlanningHorizon(date)
        );
      }

      return (
        editionModeEnabled &&
        !id.startsWith('row-group-y_type-marketshare') &&
        isForecast(yLabel, date) &&
        (!hasPlanningHorizon || (hasPlanningHorizon && isPlanningHorizon(date)))
      );
    },

    [
      isForecast,
      editionModeEnabled,
      hasPlanningHorizon,
      isPlanningHorizon,
      isExternalSerie,
    ],
  );

  const getHierarchies = useCallback(
    (rowNode: IRowNode | null, rowNodeId?: string): string[] => {
      const hierarchies: string[] = [];
      let rowAux = rowNode;

      const rowAux2 = rowNode;

      while (rowAux && rowAux.key) {
        hierarchies.unshift(rowAux.key);
        rowAux = rowAux.parent;
      }

      const hasYTypeColumn = ysInfoData?.hierarchies.length;

      if (
        ysInfoData?.ys.some((y) => {
          if (hasYTypeColumn && hierarchies.length <= 1) return false;

          return y.hierarchy
            .map((hierarchy) => hierarchy.value)
            .toString()
            .startsWith(hierarchies.slice(Number(hasYTypeColumn)).toString());
        })
      ) {
        return hierarchies;
      }

      const yLabel = rowAux2?.allLeafChildren?.length
        ? rowAux2.allLeafChildren.find((children) => children.id === rowNodeId)
            ?.data.row_id || rowAux2?.allLeafChildren[0]?.data?.row_id
        : null;

      if (yLabel) {
        const yHierarchy = ysInfoData?.ys.find(
          (y) => y.y_label === yLabel,
        )?.hierarchy;

        if (yHierarchy) {
          const hierarchyIndex = yHierarchy?.findIndex((hierarchy, index) => {
            if (rowNode?.key?.includes(' -> ')) {
              const aux = yHierarchy
                .map((hierarchyAux) => hierarchyAux.value)
                .slice(0, index + 1)
                .toString()
                .replaceAll(',', ' -> ');
              return aux.includes(rowNode?.key);
            }

            return hierarchy.value === rowNode?.key;
          });

          if (typeof hierarchyIndex === 'number' && hierarchyIndex !== -1) {
            const hierarchiesAux = [];
            if (hasYTypeColumn) {
              hierarchiesAux.push(hierarchies[0]);
            }
            hierarchiesAux.push(
              ...yHierarchy
                .slice(0, hierarchyIndex + 1)
                .map((hierarchy) => hierarchy.value),
            );
            return hierarchiesAux;
          }
        }

        if (
          ysInfoData?.series.length &&
          ysInfoData.series[0].name === 'marketshare'
        ) {
          const marketShareHierarchies = ysInfoData.series[0].hierarchies.find(
            (hierarchiesAux) => {
              let rowId = 'marketshare';
              hierarchiesAux.forEach((hierarchyAux) => {
                rowId = `${rowId}-${hierarchyAux.name}-${hierarchyAux.value}`;
              });

              return rowId === yLabel;
            },
          );

          if (marketShareHierarchies) {
            const hierarchyIndex = marketShareHierarchies?.findIndex(
              (hierarchy, index) => {
                if (rowNode?.key?.includes(' -> ')) {
                  const aux = marketShareHierarchies
                    .map((hierarchyAux) => hierarchyAux.value)
                    .slice(0, index + 1)
                    .toString()
                    .replaceAll(',', ' -> ');
                  return aux.includes(rowNode?.key);
                }
                return hierarchy.value === rowNode?.key;
              },
            );

            if (typeof hierarchyIndex === 'number' && hierarchyIndex !== -1) {
              const hierarchiesAux = ['marketshare'];
              hierarchiesAux.push(
                ...marketShareHierarchies
                  .slice(0, hierarchyIndex + 1)
                  .map((hierarchy) => hierarchy.value),
              );

              return hierarchiesAux;
            }
            if (hierarchies.length === 1 && hasYTypeColumn) {
              const hierarchiesAux = ['marketshare'];
              hierarchiesAux.push(
                ...marketShareHierarchies.map((hierarchy) => hierarchy.value),
              );
              hierarchiesAux.pop();

              return hierarchiesAux;
            }
          }
        }
      }

      return [];
    },
    [ysInfoData],
  );

  const getOriginalAdjustedValue = useCallback(
    (
      rowNode: IRowNode,
      dateColumn: string,
      originalOrAdjusted: 'original' | 'adjusted',
    ) => {
      let yLabel = '';
      let isAggregation = false;
      let aggregationKey: string[] = [];
      let aggregationKeyString = '';

      const isMarketShare =
        ysInfoData?.market_share?.enable &&
        (!!rowNode.id?.startsWith('marketshare-') ||
          !!rowNode.id?.startsWith('row-group-y_type-marketshare-'));

      if (rowNode.data?.y_label) {
        yLabel = rowNode.data?.y_label;

        if (isMarketShare) {
          aggregationKey = getHierarchies(rowNode.parent, rowNode.id);

          aggregationKey.push(yLabel);
        }
      } else {
        yLabel =
          (rowNode.key?.includes(' -> ')
            ? rowNode.key.split(' -> ').at(-1)
            : rowNode.key) || '';

        isAggregation = true;
        aggregationKey = getHierarchies(rowNode)
          .toString()
          .replaceAll(' -> ', ',')
          .split(',');
      }

      const yType =
        rowNode.aggData?.y_type ?? rowNode.data?.y_type ?? aggregationKey[0];

      if (ysInfoData?.hierarchies.length) {
        aggregationKey = aggregationKey.slice(1);

        const calc = getYTypeCalc(yType);

        if (calc === 'not_aggregated' && !isMarketShare && isAggregation) {
          return null;
        }
      }

      aggregationKeyString = aggregationKey.toString().replaceAll(',', ' ');

      let originalValue: null | number = null;

      if (!aggregationKeyString && !yLabel) return 0;

      if (yLabel) {
        const isY = !isAggregation && !isMarketShare;

        const queryKey = getSeriesQueryKey(
          workspaceId ?? '',
          releaseSelected?.id ?? '',
          !isY ? aggregationKeyString : yLabel,
          frequency,
          transformation,
          inflation,
          originalOrAdjusted,
          step?.number ?? 1,
          isY || !ysInfoData?.hierarchies.length ? undefined : yType,
        );

        const response: SerieData | undefined =
          queryClient.getQueryData(queryKey);
        const queryState = queryClient.getQueryState(queryKey);

        if (
          response &&
          response.historical.dates &&
          response.forecast?.dates &&
          !queryState?.isFetching
        ) {
          if (isAggregation && transformation.includes('variation')) {
            const historicalIndex = response.historical?.dates.findIndex(
              (dateAux) => dateAux.replace(':00Z', '') === dateColumn,
            );

            if (historicalIndex !== -1) {
              originalValue = response.historical.values[historicalIndex];
            }
          }

          if (!originalValue) {
            const forecastIndex = response.forecast?.dates.findIndex(
              (dateAux) => dateAux.replace(':00Z', '') === dateColumn,
            );

            if (forecastIndex !== -1) {
              originalValue = response.forecast.values[forecastIndex];
            } else {
              const historicalIndex = response.historical?.dates.findIndex(
                (dateAux) => dateAux.replace(':00Z', '') === dateColumn,
              );

              if (historicalIndex !== -1) {
                originalValue = response.historical.values[historicalIndex];
              }
            }
          }
        }
      }

      if (typeof originalValue === 'number') return originalValue;

      return 0;
    },
    [
      frequency,
      inflation,
      releaseSelected?.id,
      transformation,
      workspaceId,
      ysInfoData?.market_share?.enable,
      ysInfoData?.hierarchies.length,
      step?.number,
      getHierarchies,
      getYTypeCalc,
    ],
  );

  const backendAgg = useCallback(
    (params: IAggFuncParams) => {
      const value = getOriginalAdjustedValue(
        params.rowNode,
        params.colDef?.field ?? '',
        'adjusted',
      );

      return value === 0 ? null : value;
    },
    [getOriginalAdjustedValue],
  );

  const avgAgg = useCallback((params: IAggFuncParams) => {
    let sum = 0;
    let qtty = 0;

    if (params.values.every((value) => Number.isNaN(Number(value)))) {
      return undefined;
    }

    params.values.forEach((value) => {
      if (value !== undefined && value !== null) {
        sum += value || 0;
        qtty++;
      }
    });

    if (qtty === 0) {
      return null;
    }

    return sum / qtty;
  }, []);

  const sumAgg = useCallback((params: IAggFuncParams) => {
    let sum = 0;

    if (params.values.every((value) => Number.isNaN(Number(value)))) {
      return undefined;
    }

    params.values.forEach((value) => {
      sum += value || 0;
    });

    return sum;
  }, []);

  const marketShareAgg = useCallback(
    (params: IAggFuncParams) => {
      const hierarchy = getHierarchies(params.rowNode).slice(1);

      if (hierarchy.length) {
        const marketShareValues = marketShareAggValues.find(
          (information) =>
            information.hierarchy.toString() === hierarchy.toString(),
        );

        if (marketShareValues) {
          const dateColumn = params.colDef.field ?? '';

          const allDates = [
            ...(marketShareValues.historical?.dates ?? []),
            ...(marketShareValues.forecast?.dates ?? []),
          ];
          const allValues = [
            ...(marketShareValues.historical?.values ?? []),
            ...(marketShareValues.forecast?.values ?? []),
          ];

          const valueIndex = allDates.findIndex(
            (date) => date.replace(':00Z', '') === dateColumn,
          );

          return valueIndex === -1 ? null : allValues[valueIndex];
        }
      }

      return null;
    },
    [marketShareAggValues, getHierarchies],
  );

  const aggFunc = useCallback(
    (params: IAggFuncParams) => {
      const dateColumn = params.colDef.field ?? '';
      const dateFormatted = new Date(dateColumn);

      if (!ysInfoData?.aggregation?.enable) {
        return null;
      }

      const freqPeriod =
        period[frequency === 'monthly' ? 'original' : frequency];

      if (
        !freqPeriod[0] ||
        !freqPeriod[1] ||
        dateFormatted < freqPeriod[0] ||
        dateFormatted > freqPeriod[1]
      ) {
        return null;
      }

      if (params.rowNode.id?.startsWith(`row-group-y_type-marketshare`)) {
        return marketShareAgg(params);
      }

      if (transformation.includes('variation')) {
        return null;
      }

      const yType = params.rowNode.aggData?.y_type ?? params.data?.y_type;

      const calc = getYTypeCalc(yType);

      if (calc === 'not_aggregated') {
        return null;
      }

      if (calc === 'sum') {
        return sumAgg(params);
      }

      if (editionModeEnabled) {
        return avgAgg(params);
      }

      return backendAgg(params);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      ysInfoData?.aggregation?.enable,
      transformation,
      sumAgg,
      marketShareAgg,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      period[frequency === 'monthly' ? 'original' : frequency],
      editionModeEnabled,
    ],
  );

  const getMarketShareData = useCallback(
    async (signal: AbortSignal, stepNumberAux?: number) => {
      if (marketShareRows.length) {
        const stepNumber = stepNumberAux ?? step?.number;

        setIsLoadingMarketShare(true);

        const originalPromises: Promise<SerieData>[] = [];
        const adjustedPromises: Promise<SerieData>[] = [];
        const originalAggPromises: Promise<SerieData>[] = [];
        const adjustedAggPromises: Promise<SerieData>[] = [];

        const aggValues: MarketShareAggValues[] = [];

        for (let i = 0; i < marketShareRows.length; i++) {
          const { hierarchy, isAggregation, rowId } = marketShareRows[i];

          const queryKeyAdjusted = getSeriesQueryKey(
            workspaceId ?? '',
            releaseSelected?.id ?? '',
            hierarchy.toString().replaceAll(',', ' '),
            frequency,
            transformation,
            inflation,
            'adjusted',
            stepNumber,
            'marketshare',
          );

          const queryKeyOriginal = getSeriesQueryKey(
            workspaceId ?? '',
            releaseSelected?.id ?? '',
            hierarchy.toString().replaceAll(',', ' '),
            frequency,
            transformation,
            inflation,
            'original',
            stepNumber,
            'marketshare',
          );

          await queryClient.invalidateQueries(queryKeyAdjusted);
          await queryClient.invalidateQueries(queryKeyOriginal);

          if (!isAggregation) {
            originalPromises.push(
              getSerieData(
                rowId ?? '',
                hierarchy,
                'original',
                false,
                'marketshare',
                stepNumber,
              ),
            );

            adjustedPromises.push(
              getSerieData(
                rowId ?? '',
                hierarchy,
                'adjusted',
                false,
                'marketshare',
                stepNumber,
              ),
            );
          } else {
            adjustedAggPromises.push(
              getSerieData(
                rowId ?? '',
                hierarchy,
                'adjusted',
                false,
                'marketshare',
                stepNumber,
              ),
            );
            originalAggPromises.push(
              getSerieData(
                rowId ?? '',
                hierarchy,
                'original',
                false,
                'marketshare',
                stepNumber,
              ),
            );

            aggValues.push({ hierarchy });
          }
        }

        let index = 0;

        while (index < originalPromises.length && !signal.aborted) {
          try {
            const originalData = await Promise.all(
              originalPromises.slice(index, index + 100),
            );
            const adjustedData = await Promise.all(
              adjustedPromises.slice(index, index + 100),
            );

            if (!signal.aborted) {
              const data =
                forecastType === 'adjusted' ? adjustedData : originalData;

              for (let i = 0; i < data.length; i++) {
                const row: RowData = {};

                data[i].historical.dates.forEach((date, j) => {
                  const updatedDate = date.replace(':00Z', '');

                  row[updatedDate] = data[i].historical.values[j];
                });

                data[i].forecast?.dates.forEach((date, j) => {
                  const updatedDate = date.replace(':00Z', '');

                  row[updatedDate] = data[i].forecast?.values[j] ?? 0;
                });

                const rowNode = tableRef.current?.api!.getRowNode(
                  data[i].serie,
                );

                const forecastDates = data[i].forecast?.dates;

                let startDate: null | string = null;
                let endDate: null | string = null;

                if (forecastDates?.length) {
                  startDate = forecastDates[0].replace(':00Z', '');
                  endDate = forecastDates[forecastDates?.length - 1].replace(
                    ':00Z',
                    '',
                  );
                }

                setStartEndYForecastDates((prev) => ({
                  ...prev,
                  [data[i].serie]: {
                    startDate,
                    endDate,
                  },
                }));

                if (rowNode) {
                  rowNode.updateData({
                    ...rowNode.data,
                    ...row,
                  });
                }
              }
            }
            // eslint-disable-next-line no-empty
          } catch {}

          index += 100;
        }

        index = 0;

        while (index < originalAggPromises.length && !signal.aborted) {
          try {
            const originalData = await Promise.all(
              originalAggPromises.slice(index, index + 100),
            );
            const adjustedData = await Promise.all(
              adjustedAggPromises.slice(index, index + 100),
            );

            if (!signal.aborted) {
              const data =
                forecastType === 'adjusted' ? adjustedData : originalData;

              for (let i = 0; i < data.length; i++) {
                aggValues[index + i].historical = data[i].historical;
                aggValues[index + i].forecast = data[i].forecast;
              }
            }
            // eslint-disable-next-line no-empty
          } catch {}

          index += 100;
        }

        setMarketShareAggValues(aggValues);
        setIsLoadingMarketShare(false);
      }
    },
    [
      workspaceId,
      releaseSelected?.id,
      marketShareRows,
      frequency,
      forecastType,
      inflation,
      transformation,
      step?.number,
      getSerieData,
    ],
  );

  const refetchLogs = async (stepNumber?: number) => {
    queryClient.refetchQueries({
      queryKey: ['workspace', 'logs'],
    });

    await queryClient.refetchQueries([
      'workspace',
      workspaceId,
      'releases',
      releaseSelected?.id,
      'logs',
      stepNumber ?? step?.number ?? 1,
    ]);
  };

  const invalidateChartDataAndAggregationAndMarketShareData = async (
    stepNumber?: number,
    invalidateAgg?: boolean,
  ) => {
    queryClient.refetchQueries(['workspace', 'series data chart']);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();

    if (ysInfoData?.market_share?.enable) {
      await getMarketShareData(abortControllerRef.current.signal, stepNumber);
    }

    if (ysInfoData?.aggregation?.enable && invalidateAgg) {
      ysInfoData?.ys.forEach((y) => {
        const hierarchy = y.hierarchy.map((aux) => aux.value);

        for (let i = 1; i <= hierarchy.length; i++) {
          const queryKeyAdjustedNominal = getSeriesQueryKey(
            workspaceId ?? '',
            releaseSelected?.id ?? '',
            hierarchy.slice(0, i).toString().replaceAll(',', ' '),
            'original',
            'level',
            'nominal',
            'adjusted',
            stepNumber ?? step?.number ?? 1,
          );

          queryClient.invalidateQueries(queryKeyAdjustedNominal.slice(0, 7));

          const queryKeyAdjustedReal = getSeriesQueryKey(
            workspaceId ?? '',
            releaseSelected?.id ?? '',
            hierarchy.slice(0, i).toString().replaceAll(',', ' '),
            'original',
            'level',
            'real',
            'adjusted',
            stepNumber ?? step?.number ?? 1,
          );

          queryClient.invalidateQueries(queryKeyAdjustedReal.slice(0, 7));
        }
      });
    }
  };

  const handleUpdateData = async (
    stepNumber?: number,
    clickedOnButton = true,
  ) => {
    if (ysInfoData) {
      for (let i = 0; i < ysInfoData.ys.length; i++) {
        const queryKey = getSeriesQueryKey(
          workspaceId ?? '',
          releaseSelected?.id ?? '',
          ysInfoData.ys[i].y_label,
          frequency,
          transformation,
          ysInfoData.ys[i].is_inflated ? 'nominal' : 'real',
          'adjusted',
          stepNumber ?? step?.number ?? 1,
        );

        queryClient.removeQueries(queryKey.slice(0, 7));
      }
    }

    await invalidateChartDataAndAggregationAndMarketShareData(
      stepNumber ?? step?.number ?? 1,
    );

    const tableApi = tableRef?.current?.api;

    setYsDateIds({});
    setIsLoadingSeriesData(false);

    if (columns.length && tableApi) {
      const resetTableColumns = [...columns];
      resetTableColumns[0].children = [];
      resetTableColumns[1].children = [];
      resetTableColumns[2].children = [];
      resetTableColumns[3].children = [];
      resetTableColumns[4].children = [];

      if (!tableApi?.isDestroyed()) {
        tableApi!.setGridOption('columnDefs', resetTableColumns);
      }
    }

    if (tableRendered) {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      abortControllerRef.current = new AbortController();

      setIsLoadingSeriesData(true);
      getAllSeriesData(abortControllerRef.current.signal);
    }

    if (
      adjustedStepLogData?.records &&
      discardedAdjustedStepLogData?.records &&
      removedStepLogData?.records &&
      step?.number !== undefined
    ) {
      const joinArrayAdjustedAndDiscard = [
        ...adjustedStepLogData.records,
        ...discardedAdjustedStepLogData.records,
        ...removedStepLogData.records,
      ].sort(
        (a, b) =>
          new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
      );

      const adjustedLastLogTime = new Date(
        joinArrayAdjustedAndDiscard[0].timestamp,
      ).getTime();

      dispatch(
        changeLastLogTime({
          step: stepNumber ?? step?.number ?? 1,
          value: adjustedLastLogTime,
        }),
      );
    }

    if (clickedOnButton) {
      setButtonUpdateStatus('closed');
    }
  };

  const lockEdition = async () => {
    try {
      await apiWorkspace.patch(`/workspaces/${workspaceId}/edit`);

      return true;
    } catch (err) {
      const error = err as AxiosError;
      if (error.response?.status === 400) {
        if (
          error.response?.data?.detail?.detail?.startsWith(
            'Workspace already locked for editing by ',
          )
        ) {
          setShowUserIsEditingModal(
            error.response.data.detail.detail.replace(
              'Workspace already locked for editing by ',
              '',
            ),
          );
        } else if (
          error.response?.data?.detail?.detail ===
          'You cannot perform this action, Workspace is publishing a new version.'
        ) {
          setShowWorkspaceIsPublishing(true);
        }
      }

      return false;
    }
  };

  const qttyOfApprovers = () => {
    const allUsersEmail = Object.keys(currentStepInfo?.users?.profiles ?? {});

    if (allUsersEmail.length === 0) {
      return 1;
    }

    let qttyOfApproverUsers = 0;

    allUsersEmail.forEach((email) => {
      const userInfo = currentStepInfo?.users?.profiles[email];

      const isApprover = userInfo?.some(({ type }) => type === 'approver');

      if (isApprover) {
        qttyOfApproverUsers++;
      }
    });

    return qttyOfApproverUsers;
  };

  const updateStepStatus = async (
    status: StepStatus,
    message?: string,
  ): Promise<boolean> => {
    if (!step || step.status === status) return true;

    try {
      const updatedStep: Step = { ...step, status, selectedStatus: status };

      const body: UpdateStepStatusBody = { status };

      if (status === 'awaiting_approval' && message) {
        updatedStep.awaiting_approval_messages = [
          message,
          ...updatedStep.awaiting_approval_messages,
        ];

        body.awaiting_approval_messages = [message];
      }

      if (status === 'approved' && message) {
        updatedStep.approval_messages = [
          message,
          ...updatedStep.approval_messages,
        ];

        const qttyOfApproverUsers = qttyOfApprovers();
        const currentApprover = currentStepInfo?.flow?.status?.approver ?? 1;

        if (qttyOfApproverUsers !== currentApprover) {
          updatedStep.selectedStatus = 'awaiting_approval';
          updatedStep.status = 'awaiting_approval';
        }

        body.approval_messages = [message];
      }

      if (status === 'adjusting' && message) {
        updatedStep.disapproval_messages = [
          message,
          ...updatedStep.disapproval_messages,
        ];

        body.disapproval_messages = [message];
      }

      await apiWorkspace.put<Step>(
        `/workspaces/${workspaceId}/releases/${releaseSelected?.id}/approval-flow/steps/${step?.number}`,
        body,
      );

      dispatch(changeWorkspaceOverviewStep(updatedStep));

      if (status === 'awaiting_approval') {
        await unlockEdition(workspaceId ?? '');
      }

      refetchLogs();

      queryClient.refetchQueries({
        queryKey: ['workspace', workspaceId, 'releases', releaseSelected?.id],
        exact: true,
      });

      return true;
    } catch {
      setShowUpdateStatusError(true);

      return false;
    }
  };

  const handleEnableEdition = async () => {
    setLoadingEnableEdition(true);

    const success = await lockEdition();

    if (success) {
      if (
        step &&
        (step.number === 1 ||
          releaseData?.data?.steps?.[step.number - 1].status === 'baseline')
      ) {
        updateStepStatus('adjusting');
      }

      if (!editionModeEnabled) {
        dispatch(changeEditionModeEnabled(true));
      }
    }

    setEditionExpired(false);
    setLoadingEnableEdition(false);
  };

  const invalidateEditedAggregations = async (ys: string[]) => {
    if (ysInfoData?.aggregation?.enable) {
      const invalidatedHierarchies: string[] = [];

      for (let i = 0; i < ys.length; i++) {
        const yInfo = ysInfoData?.ys.find(({ y_label }) => y_label === ys[i]);

        if (yInfo) {
          const hierarchy = yInfo.hierarchy.map((aux) => aux.value);

          const calc = getYTypeCalc(yInfo?.type ?? 'others');

          for (let j = 1; j <= hierarchy.length; j++) {
            const currentHierarchy = hierarchy
              .slice(0, j)
              .toString()
              .replaceAll(',', ' ');

            if (!invalidatedHierarchies.includes(currentHierarchy)) {
              const currentQueryKey = getSeriesQueryKey(
                workspaceId ?? '',
                releaseSelected?.id ?? '',
                currentHierarchy,
                'original',
                'level',
                inflation,
                'adjusted',
                step?.number ?? 1,
                yInfo.type,
              );

              queryClient.invalidateQueries(currentQueryKey.slice(0, 7));

              if (calc === 'average') {
                await queryClient.refetchQueries(currentQueryKey);
              }

              if (inflation === 'nominal') {
                const queryKeyAdjustedReal = getSeriesQueryKey(
                  workspaceId ?? '',
                  releaseSelected?.id ?? '',
                  currentHierarchy,
                  'original',
                  'level',
                  'real',
                  'adjusted',
                  step?.number ?? 1,
                );

                queryClient.invalidateQueries(queryKeyAdjustedReal.slice(0, 7));
              } else {
                const queryKeyAdjustedNominal = getSeriesQueryKey(
                  workspaceId ?? '',
                  releaseSelected?.id ?? '',
                  currentHierarchy,
                  'original',
                  'level',
                  'nominal',
                  'adjusted',
                  step?.number ?? 1,
                );

                queryClient.invalidateQueries(
                  queryKeyAdjustedNominal.slice(0, 7),
                );
              }

              invalidatedHierarchies.push(currentHierarchy);
            }
          }
        }
      }
    }
  };

  const handleSaveEdition = async () => {
    try {
      await apiWorkspace.patch(`/workspaces/${workspaceId}/edit`);

      const editedValuesByYs: EditedValuesByYs = {};
      const labels: string[] = [];

      editedValues.forEach((edited) => {
        const label = edited.yLabel;

        const editedValuesY = editedValuesByYs[label];

        if (!editedValuesY) {
          labels.push(label);
          editedValuesByYs[label] = [];
        }

        editedValuesByYs[label].push({
          date: `${edited.date}:00Z`,
          value: edited.adjustedValue,
          id: ysDateIds[label][edited.date],
        });
      });

      let errorToSave = false;

      let logCorrelationId = '';

      for (let i = 0; i < labels.length; i++) {
        const yInflation = inflation === 'nominal' ? 'inflate' : 'original';

        try {
          const body: SaveEditionBody = { values: editedValuesByYs[labels[i]] };

          if (logCorrelationId) {
            body.log_correlation_id = logCorrelationId;
          }

          const { data } = await apiWorkspace.put<SaveEditionResponse>(
            `/workspaces/${workspaceId}/releases/${
              releaseSelected?.id
            }/series/raw/${labels[i]}?inflation=${yInflation}&step=${
              step?.number ?? 1
            }`,
            body,
          );

          if (!logCorrelationId) {
            logCorrelationId = data.log_correlation_id;
          }

          const queryKey = getSeriesQueryKey(
            workspaceId ?? '',
            releaseSelected?.id ?? '',
            labels[i],
            'original',
            'level',
            'nominal',
            forecastType,
            step?.number ?? 1,
          );

          queryClient.invalidateQueries(queryKey.slice(0, 7));
        } catch {
          errorToSave = true;

          setUnsavedYs((prev) => [...prev, labels[i]]);
        }
      }

      if (step) {
        await queryClient.refetchQueries({
          queryKey: [
            'workspace',
            workspaceId,
            'releases',
            releaseSelected?.id,
            'logs',
            step.number,
          ],
        });

        queryClient.refetchQueries(['workspace', 'logs']);
      }

      await invalidateEditedAggregations(labels);

      invalidateChartDataAndAggregationAndMarketShareData(undefined, false);

      if (!errorToSave) {
        dispatch(changeEditionModeEnabled(false));
        dispatch(updateCanSyncAdjust(true));
        setEditedValues([]);

        await unlockEdition(workspaceId ?? '');
      }
    } catch (err) {
      const error = err as AxiosError;
      if (error.response?.status === 400) {
        if (
          error.response?.data?.detail?.detail?.startsWith(
            'Workspace already locked for editing by ',
          )
        ) {
          setShowUserIsEditingModal(
            error.response.data.detail.detail.replace(
              'Workspace already locked for editing by ',
              '',
            ),
          );
        } else if (
          error.response?.data?.detail?.detail ===
          'You cannot perform this action, Workspace is publishing a new version.'
        ) {
          setShowWorkspaceIsPublishing(true);
        }
      }
    }

    setIsSaving(false);
  };

  const handleEditedValue = async (
    yLabel: string,
    date: string,
    value: number,
    oldValue: number,
    rowId: string,
  ) => {
    if (yLabel) {
      const variableIndex = editedValues.findIndex(
        (y) => y.yLabel === yLabel && y.date === date,
      );

      const updatedEditedValues = [...editedValues];

      if (variableIndex === -1) {
        updatedEditedValues.push({
          yLabel,
          date,
          originalValue: oldValue,
          adjustedValue: value,
          rowId,
        });
      } else {
        updatedEditedValues[variableIndex].adjustedValue = value;
      }

      setEditedValues(updatedEditedValues);

      if (value <= 0 && oldValue > 0 && yLabel) {
        setInvalidValues(invalidValues + 1);
      }

      if (oldValue <= 0 && value > 0 && yLabel) {
        setInvalidValues(invalidValues - 1);
      }
    }
  };

  const handleExitEdition = async () => {
    editedValues.forEach((y) => {
      const rowNode = tableRef.current!.api.getRowNode(y.rowId)!;

      rowNode.setDataValue(y.date, y.originalValue);
    });

    setEditedValues([]);
    dispatch(changeEditionModeEnabled(false));

    await unlockEdition(workspaceId ?? '');

    setEditionExpired(false);
  };

  const handleDiscardAllAdjustments = async () => {
    if (!step) return;

    const success = await lockEdition();

    if (success) {
      try {
        await apiWorkspace.patch<Step>(
          `/workspaces/${workspaceId}/releases/${releaseSelected?.id}/approval-flow/steps/${step?.number}`,
        );

        dispatch(
          changeWorkspaceOverviewStep({
            ...step,
            approval_messages: [],
            awaiting_approval_messages: [],
            disapproval_messages: [],
          }),
        );

        queryClient.refetchQueries(['workspace', 'logs']);

        queryClient.refetchQueries({
          queryKey: ['workspace', workspaceId, 'releases', releaseSelected?.id],
          exact: true,
        });

        setIsDiscardingAdjustments(true);
        setTableRendered(false);

        // eslint-disable-next-line no-empty
      } catch {}

      await unlockEdition(workspaceId ?? '');
    }
  };

  const handleStartReview = () => {
    dispatch(changeReviewModeEnabled(true));
  };

  const handleExitReview = () => {
    dispatch(changeReviewModeEnabled(false));
  };

  const currentStepInfo = releaseData?.data?.steps?.[(step?.number ?? 1) - 1];

  const handleApproveReview = async (message: string) => {
    await updateStepStatus('approved', message);

    setShowApproveModal(false);
    dispatch(changeReviewModeEnabled(false));
  };

  const handleDisapproveReview = async (message: string) => {
    await updateStepStatus('adjusting', message);

    setShowDisapproveModal(false);
    dispatch(changeReviewModeEnabled(false));
  };

  const handleDownloadTable = () => {
    if (tableRef?.current) {
      const allColumns = tableRef.current?.api.getGridOption('columnDefs');
      const dateColumnsToExport: string[] = [];

      allColumns?.forEach((col) => {
        if (col.headerName) {
          const column = col as ColGroupDef;

          column?.children?.forEach(({ field }: ColDef, i) => {
            if (field) {
              const dateColumn = column.children[i] as ColDef;

              if (!dateColumn.hide) {
                dateColumnsToExport.push(dateColumn.field ?? '');
              }
            }
          });
        }
      });

      const config: ExcelExportParams = {
        sheetName: translate('workspaceOverviewResultsTitle'),
        fileName: `${workspaceName?.replaceAll('.', ' ')} - ${
          releaseSelected?.label?.replaceAll('.', ' ') ??
          releaseSelected?.name?.replaceAll('.', ' ')
        }`,
        exportedRows: 'filteredAndSorted',
        suppressRowOutline: true,
        allColumns: false,
        columnKeys: [
          'y_type',
          ...(ysInfoData?.hierarchies ?? []),
          'ag-Grid-AutoColumn',
          ...dateColumnsToExport,
        ],
        shouldRowBeSkipped(params) {
          if (
            params.node.level === 0 &&
            params.node.field === 'y_type' &&
            params.node.id === `row-group-y_type-${params.node.key}`
          ) {
            return true;
          }

          if (params.node?.data?._hide) {
            return true;
          }

          return !(
            Object.keys(params.node?.aggData ?? {}).length >= 1 ||
            Object.keys(params.node?.data ?? {}).length >= 1
          );
        },
        processCellCallback(params) {
          const colId = params.column.getColId();

          if (colId === 'y_type') {
            return variableTypeFormatter(params.value, dataTypesDict);
          }

          if (colId === 'ag-Grid-AutoColumn') {
            if (
              ysInfoData?.market_share?.enable &&
              params?.node?.data?.y_type === 'marketshare'
            ) {
              return 'total';
            }

            return params.node?.data?.y_label ?? 'total';
          }

          if (
            ysInfoData?.hierarchies.includes(colId) &&
            params?.value?.includes(' -> ')
          ) {
            const node = params.node;
            const nodeData = node?.data
              ? node.data
              : node?.allLeafChildren?.length
              ? node?.allLeafChildren?.[0].data
              : null;
            return (
              ysInfoData?.ys
                .find((y) => y.y_label === nodeData?.y_label)
                ?.hierarchy?.find((hierarchy) => hierarchy.name === colId)
                ?.value ?? 'total'
            );
          }

          const isMarketShareRow =
            ysInfoData?.market_share?.enable &&
            params.node?.id?.startsWith('marketshare-');

          if (
            params.value === undefined &&
            ysInfoData?.hierarchies.includes(colId)
          ) {
            let node = params.node;

            if (node && isMarketShareRow && !node.key && node.data?._nickname) {
              const hierarchyValues = node.data._nickname.split(' -> ');

              const marketShareHierarchy =
                ysInfoData.series[0].hierarchies.find(
                  (hierarchy) =>
                    hierarchy.map(({ value }) => value).toString() ===
                    hierarchyValues.toString(),
                );

              if (marketShareHierarchy) {
                return (
                  marketShareHierarchy.find(({ name }) => name === colId)
                    ?.value ?? 'total'
                );
              }
            }

            while (node?.parent) {
              if (
                node?.data?._nickname ||
                node?.key?.includes(' -> ') ||
                node?.key === null
              ) {
                if (isMarketShareRow && node?.key === null) {
                  const hierarchyIndex = ysInfoData.hierarchies.findIndex(
                    (hierarchy) => hierarchy === colId,
                  );

                  if (
                    hierarchyIndex !== -1 &&
                    hierarchyIndex + 1 === params.node?.level
                  ) {
                    return params.node.data?.y_label ?? 'total';
                  }
                }

                const nodeData = node?.data
                  ? node.data
                  : node?.allLeafChildren?.length
                  ? node?.allLeafChildren?.[0].data
                  : null;

                return (
                  ysInfoData?.ys
                    .find((y) => y.y_label === nodeData?.y_label)
                    ?.hierarchy?.find((hierarchy) => hierarchy.name === colId)
                    ?.value ?? 'total'
                );
              }

              if (node.field === colId) {
                return node?.key ?? 'total';
              }

              node = node.parent;
            }

            return 'total';
          }

          if (params.value) return params.value;

          if (params.node?.data?.[colId]) return params.node?.data?.[colId];

          if (transformation.includes('variation') && params.node) {
            return getOriginalAdjustedValue(
              params.node,
              params.column.getColId(),
              forecastType,
            );
          }

          return null;
        },
        processGroupHeaderCallback(params) {
          const columnName = params.api.getDisplayNameForColumnGroup(
            params.columnGroup,
            null,
          );

          if (['Historical', 'Histórico'].includes(columnName))
            return translate('Historical');

          if (['Forecast', 'Projeção'].includes(columnName))
            return translate('Forecast');

          if (
            ['Planning horizon', 'Horizonte de planejamento'].includes(
              columnName,
            )
          )
            return translate('workspaceOverviewPlanningHorizon');

          return columnName;
        },
        processHeaderCallback(params) {
          if (params.column.getColDef().field === 'y_label') {
            return translate('variable');
          }

          return (
            params.column?.getUserProvidedColDef()?.headerName ??
            params.column.getColDef().field!
          );
        },
      };

      tableRef.current!.api.exportDataAsExcel(config);
    }
  };

  const getDefaultDateInterval = (serieData: SerieData) => {
    const lastHistoricalDate = serieData.historical.dates.at(-1);
    const firstForecastDate = serieData.forecast?.dates[0];

    const selectedFrequency: Frequency =
      frequency === 'original' ? workspaceFrequency ?? 'monthly' : frequency;

    if (!lastHistoricalDate && !firstForecastDate) {
      if (frequency === 'yearly') {
        dispatch(changeWorkspaceOverviewYearlyPeriod([null, null]));
      } else if (frequency === 'quarterly') {
        dispatch(changeWorkspaceOverviewQuarterlyPeriod([null, null]));
      } else if (frequency === 'bimonthly') {
        dispatch(changeWorkspaceOverviewBimonthlyPeriod([null, null]));
      } else if (frequency === 'half-year') {
        dispatch(changeWorkspaceOverviewHalfYearPeriod([null, null]));
      } else {
        dispatch(changeWorkspaceOverviewOriginalPeriod([null, null]));
      }

      return;
    }

    const horizonDates = releaseData?.data.approval_flow?.horizon_dates;

    let firstDate = subMonths(
      new Date(
        (lastHistoricalDate ?? firstForecastDate ?? '').replace(':00Z', ''),
      ),
      selectedFrequency === 'monthly'
        ? frequencyIntervalInMonths[selectedFrequency].historical - 1
        : frequencyIntervalInMonths[selectedFrequency].historical,
    );

    if (
      hasPlanningHorizon &&
      horizonDates?.start &&
      firstDate > new Date(horizonDates.start.replace(':00Z', ''))
    ) {
      firstDate = subMonths(
        new Date(horizonDates.start.replace(':00Z', '')),
        selectedFrequency === 'monthly'
          ? frequencyIntervalInMonths[selectedFrequency].historical - 1
          : frequencyIntervalInMonths[selectedFrequency].historical,
      );
    }

    let lastDate = new Date();

    if (hasPlanningHorizon && horizonDates?.end) {
      lastDate = new Date(horizonDates?.end);
    } else {
      lastDate = addMonths(
        new Date(
          (firstForecastDate ?? lastHistoricalDate ?? '').replace(':00Z', ''),
        ),
        selectedFrequency === 'monthly'
          ? frequencyIntervalInMonths[selectedFrequency].forecast - 1
          : frequencyIntervalInMonths[selectedFrequency].forecast,
      );
    }

    if (frequency === 'yearly') {
      dispatch(changeWorkspaceOverviewYearlyPeriod([firstDate, lastDate]));
    } else if (frequency === 'quarterly') {
      dispatch(changeWorkspaceOverviewQuarterlyPeriod([firstDate, lastDate]));
    } else if (frequency === 'bimonthly') {
      dispatch(changeWorkspaceOverviewBimonthlyPeriod([firstDate, lastDate]));
    } else if (frequency === 'half-year') {
      dispatch(changeWorkspaceOverviewHalfYearPeriod([firstDate, lastDate]));
    } else {
      dispatch(changeWorkspaceOverviewOriginalPeriod([firstDate, lastDate]));
    }
  };

  const addDateColumn = (
    type: 'historicalColumn' | 'forecastColumn',
    historicalColumnsBeforeHorizon: ColDef[],
    forecastColumnsBeforeHorizon: ColDef[],
    planningHorizonColumns: ColDef[],
    historicalColumnsAfterHorizon: ColDef[],
    forecastColumnsAfterHorizon: ColDef[],
    allDates: Set<string>,
    date: string,
    isFirstY: boolean,
    isPlanningHorizonDate?: boolean,
  ) => {
    const headerName = dateFormatter(
      date,
      frequency,
      workspaceFrequency,
      user.language,
    );

    const formattedDate = new Date(date);

    const periodFrequency =
      period[frequency === 'monthly' ? 'original' : frequency];

    let hide = true;

    if (periodFrequency[0] && periodFrequency[1]) {
      hide =
        formattedDate < periodFrequency[0] ||
        formattedDate > periodFrequency[1];
    }

    const column = {
      field: date,
      headerName,
      hide,
      type: ['defaultColumn'],
    };

    let historicalColumns = historicalColumnsBeforeHorizon;
    let forecastColumns = forecastColumnsBeforeHorizon;

    const horizonStartDate =
      releaseData?.data.approval_flow?.horizon_dates?.start;

    if (
      hasPlanningHorizon &&
      horizonStartDate &&
      new Date(date) >= new Date(horizonStartDate.replace(':00Z', ''))
    ) {
      historicalColumns = historicalColumnsAfterHorizon;
      forecastColumns = forecastColumnsAfterHorizon;
    }

    if (isPlanningHorizonDate) {
      column.type = ['planningHorizonColumn', ...column.type];
    }

    let historicalPosition = -1;
    let forecastPosition = -1;

    if (isFirstY) {
      column.type = [type, ...column.type];
    } else if (!allDates.has(date)) {
      historicalPosition = historicalColumns.findIndex(
        ({ field }) => new Date(field as string) > new Date(date),
      );

      if (historicalPosition !== -1) {
        column.type = ['historicalColumn', ...column.type];
      } else {
        forecastPosition = forecastColumns.findIndex(
          ({ field }) => new Date(field as string) > new Date(date),
        );

        column.type = ['forecastColumn', ...column.type];
      }
    }

    const isHistorical =
      (isFirstY && type === 'historicalColumn') || historicalPosition !== -1;

    if (!allDates.has(date)) {
      if (isPlanningHorizonDate) {
        const planningHorizonPosition = planningHorizonColumns.findIndex(
          ({ field }) => new Date(field as string) > new Date(date),
        );

        if (historicalPosition !== -1) {
          planningHorizonColumns.splice(planningHorizonPosition, 0, column);
        } else {
          planningHorizonColumns.push(column);
        }
      } else if (isHistorical) {
        if (historicalPosition !== -1) {
          historicalColumns.splice(historicalPosition, 0, column);
        } else {
          historicalColumns.push(column);
        }
      } else if (forecastPosition !== -1) {
        forecastColumns.splice(forecastPosition, 0, column);
      } else {
        forecastColumns.push(column);
      }
    }

    allDates.add(date);
  };

  const addSeriesDataToTable = (
    seriesData: SerieData[],
    allDates: Set<string>,
  ) => {
    const tableApi = tableRef.current?.api;

    if (tableApi) {
      for (let i = 0; i < seriesData.length; i++) {
        const isFirstY = allDates.size === 0 && i === 0;

        const data = seriesData[i];

        const updatedColumns = tableApi!.getGridOption('columnDefs') ?? [];

        const historicalBeforeHorizon =
          (updatedColumns[0] as ColGroupDef)?.children ?? [];
        const forecastBeforeHorizon =
          (updatedColumns[1] as ColGroupDef)?.children ?? [];
        const planningHorizon =
          (updatedColumns[2] as ColGroupDef)?.children ?? [];
        const historicalAfterHorizon =
          (updatedColumns[3] as ColGroupDef)?.children ?? [];
        const forecastAfterHorizon =
          (updatedColumns[4] as ColGroupDef)?.children ?? [];

        const row: RowData = {};

        const periodFrequency =
          period[frequency === 'monthly' ? 'original' : frequency];

        const horizonDates =
          releaseData?.data.approval_flow?.horizon_dates ?? {};

        if (isFirstY && (!periodFrequency[0] || !periodFrequency[1])) {
          getDefaultDateInterval(data);
        }

        const historicalYDateIds: DateIds = {};

        data.historical.dates.forEach((date, index) => {
          const updatedDate = date.replace(':00Z', '');

          const isPlanningHorizonDate =
            hasPlanningHorizon &&
            new Date(updatedDate) >=
              new Date(horizonDates.start!.replace(':00Z', '')) &&
            new Date(updatedDate) <=
              new Date(horizonDates.end!.replace(':00Z', ''));

          const isForecastColumn =
            isExternalSerie(data.serie) &&
            hasPlanningHorizon &&
            new Date(updatedDate) >
              new Date(horizonDates.end!.replace(':00Z', ''));

          addDateColumn(
            isForecastColumn ? 'forecastColumn' : 'historicalColumn',
            historicalBeforeHorizon,
            forecastBeforeHorizon,
            planningHorizon,
            historicalAfterHorizon,
            forecastAfterHorizon,
            allDates,
            updatedDate,
            isFirstY,
            isPlanningHorizonDate,
          );

          row[updatedDate] = data.historical.values[index];

          if (isPlanningHorizonDate || !hasPlanningHorizon) {
            historicalYDateIds[updatedDate] =
              data.historical?.ids?.[index] ?? 0;
          }
        });

        const forecastYDateIds: DateIds = {};

        data.forecast?.dates.forEach((date, index) => {
          const updatedDate = date.replace(':00Z', '');

          const isPlanningHorizonDate =
            hasPlanningHorizon &&
            new Date(updatedDate) >=
              new Date(horizonDates.start!.replace(':00Z', '')) &&
            new Date(updatedDate) <=
              new Date(horizonDates.end!.replace(':00Z', ''));

          addDateColumn(
            'forecastColumn',
            historicalBeforeHorizon,
            forecastBeforeHorizon,
            planningHorizon,
            historicalAfterHorizon,
            forecastAfterHorizon,
            allDates,
            updatedDate,
            isFirstY,
            isPlanningHorizonDate,
          );

          row[updatedDate] = data.forecast?.values[index] ?? 0;

          if (isPlanningHorizonDate || !hasPlanningHorizon) {
            forecastYDateIds[updatedDate] = data.forecast?.ids?.[index] ?? 0;
          }
        });

        const rowNode = tableApi!.getRowNode(data.serie);

        let dateIds = { ...forecastYDateIds };

        if (!data.forecast?.ids.length) {
          dateIds = { ...historicalYDateIds, ...dateIds };
        }

        setYsDateIds((prev) => ({
          ...prev,
          [data.serie]: dateIds,
        }));

        const forecastDates = data.forecast?.dates;

        let startDate: null | string = null;
        let endDate: null | string = null;

        if (forecastDates?.length) {
          startDate = forecastDates[0].replace(':00Z', '');
          endDate = forecastDates[forecastDates?.length - 1].replace(
            ':00Z',
            '',
          );
        }

        setStartEndYForecastDates((prev) => ({
          ...prev,
          [data.serie]: {
            startDate,
            endDate,
          },
        }));

        if (rowNode) {
          rowNode.updateData({
            ...rowNode.data,
            ...row,
          });
        }
      }
    }
  };

  const getAllSeriesData = async (signal: AbortSignal) => {
    const allDates: Set<string> = new Set();

    if (ysInfoData) {
      let index = 0;

      while (index < ysInfoData.ys.length && !signal.aborted) {
        const { originalYsPromises, adjustedYsPromises } = getYsPromises(
          index,
          index + 100,
        );

        try {
          const originalYsData = await Promise.all(originalYsPromises);
          const adjustedYsData = await Promise.all(adjustedYsPromises);

          if (!signal.aborted) {
            const data =
              forecastType === 'adjusted' ? adjustedYsData : originalYsData;

            addSeriesDataToTable(data, allDates);
          }
          // eslint-disable-next-line no-empty
        } catch {}

        index += 100;
      }

      index = 0;

      while (index < ysInfoData.ys.length && !signal.aborted) {
        try {
          const { aggsPromises } = getAggPromises(index, index + 100);
          await Promise.all(aggsPromises);
          // eslint-disable-next-line no-empty
        } catch {}

        index += 100;
      }

      if (!signal.aborted) {
        setIsLoadingSeriesData(false);

        getMarketShareData(signal);
      }
    }
  };

  useEffect(() => {
    const tableApi = tableRef?.current?.api;

    if (tableRendered) {
      setIsLoadingSeriesData(true);

      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      abortControllerRef.current = new AbortController();

      getAllSeriesData(abortControllerRef.current.signal);
    }

    return () => {
      setYsDateIds({});
      setIsLoadingSeriesData(false);
      setMarketShareAggValues([]);

      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      if (columns.length && tableApi) {
        const resetColumns = [...columns];
        resetColumns[0].children = [];
        resetColumns[1].children = [];
        resetColumns[2].children = [];
        resetColumns[3].children = [];
        resetColumns[4].children = [];

        if (!tableApi?.isDestroyed()) {
          tableApi.setGridOption('columnDefs', resetColumns);
        }
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tableRendered,
    releaseSelected?.id,
    step?.number,
    frequency,
    transformation,
    inflation,
    forecastType,
  ]);

  useEffect(() => {
    const getDefaultColumns = () => {
      if (ysInfoData) {
        const defaultColumns: (ColGroupDef | ColDef)[] = [
          {
            headerName: translate('Historical'),
            children: [],
          },
          {
            headerName: translate('Forecast'),
            children: [],
          },
          {
            headerName: translate('workspaceOverviewPlanningHorizon'),
            children: [],
          },
          {
            headerName: translate('Historical'),
            children: [],
          },
          {
            headerName: translate('Forecast'),
            children: [],
          },
        ];

        if (ysInfoData?.hierarchies.length) {
          defaultColumns.push({
            field: 'y_type',
            headerName: translate('workspaceOverviewResultsType'),
            rowGroup: true,
            hide: false,
            suppressHeaderMenuButton: true,
            pinned: 'left',
            flex: 1,
            width: 115,
            valueFormatter: (params) =>
              variableTypeFormatter(params.value, dataTypesDict),
            type: 'variableTypeColumn',
            aggFunc: 'variableType',
            filter: 'agSetColumnFilter',
            menuTabs: ['filterMenuTab'],
            sort: 'asc',
            comparator: (valueA, valueB) => {
              const defaultOrder = {
                marketsize: 1,
                sellout: 2,
                marketshare: 3,
              } as any;

              const aType = defaultOrder[valueA?.toLowerCase()] || 0;
              const bType = defaultOrder[valueB?.toLowerCase()] || 0;

              if (aType && bType) {
                return aType - bType;
              }

              if (aType) {
                return 1;
              }

              if (bType) {
                return -1;
              }

              return valueA?.localeCompare(valueB);
            },
            filterParams:
              ysInfoData?.hierarchies.length || ysInfoData?.market_share?.enable
                ? ({
                    treeList: true,
                    treeListPathGetter: (value) => {
                      if (value) {
                        return [variableTypeFormatter(value, dataTypesDict)];
                      }
                    },

                    keyCreator: (params: any) => params.value,
                  } as ISetFilterParams)
                : undefined,
          });
        }

        ysInfoData.hierarchies.forEach((key) => {
          defaultColumns.push({
            field: key,
            rowGroup: true,
            hide: true,
            filter: true,
          });
        });

        if (!ysInfoData.hierarchies.length) {
          defaultColumns.push({
            field: 'y_label',
            rowGroup: true,
            hide: true,
            filter: true,
          });
        }

        setColumns(defaultColumns);
      }
    };

    const getDefaultRows = () => {
      if (ysInfoData) {
        const rows: RowData[] = [];

        ysInfoData.ys.forEach((y) => {
          const data: RowData = {};

          if (ysInfoData?.hierarchies.length) {
            data.y_type = y.type;
          }

          ysInfoData.hierarchies.forEach((name, index) => {
            if (y.hierarchy.length - 1 >= index) {
              data[name] = y.hierarchy[index].value;
            }
          });

          data.y_label = y.y_label;
          data.row_id = y.y_label;

          rows.push(data);
        });

        if (ysInfoData.market_share?.enable && ysInfoData.series.length) {
          const marketShareSerie = ysInfoData.series.find(
            (serie) => serie.name === 'marketshare',
          );

          const allMarketShareRows: MarketShareRow[] = [];

          marketShareSerie?.hierarchies.forEach((hierarchy) => {
            const data: RowData = { y_type: 'marketshare' };
            let rowId = 'marketshare';

            const marketShareHierarchy: string[] = [];

            hierarchy.forEach(({ name, value }, index) => {
              rowId += `-${name}-${value}`;

              marketShareHierarchy.push(value);

              if (index === hierarchy.length - 1) {
                data.y_label = value;
                data.row_id = rowId;

                allMarketShareRows.push({
                  rowId,
                  isAggregation: false,
                  hierarchy: [...marketShareHierarchy],
                });
              } else {
                data[name] = value;
                allMarketShareRows.push({
                  isAggregation: true,
                  hierarchy: [...marketShareHierarchy],
                });
              }
            });

            rows.push(data);
          });

          setMarketShareRows(allMarketShareRows);
        }

        setRowData(rows);
      }
    };

    if (ysInfoData) {
      getDefaultColumns();
      getDefaultRows();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ysInfoData]);

  useEffect(() => {
    const formatDateField = (columnChildren: ColDef[]) =>
      columnChildren.map((column: ColDef) => {
        if (!column.field) return { ...column };

        return {
          ...column,
          headerName: dateFormatter(
            column.field,
            frequency,
            workspaceFrequency,
            user.language,
          ),
        };
      });

    const translateAutoGroupColumn = () => {
      if (!tableRef.current?.api?.isDestroyed()) {
        tableRef.current?.api.setGridOption('autoGroupColumnDef', {
          ...autoGroupColumnDef,
          headerName: translate('workspaceOverviewResultsGroup'),
        });
      }

      const yTypeColumn = tableRef.current?.api.getColumn('y_type');

      if (yTypeColumn) {
        yTypeColumn.getColDef().headerName = translate(
          'workspaceOverviewResultsType',
        );
      }

      tableRef.current?.api.refreshHeader();
    };

    const translateColumns = () => {
      const allColumns = tableRef.current?.api.getGridOption('columnDefs');

      if (allColumns) {
        if (ysInfoData?.hierarchies.length) {
          (allColumns[5] as ColDef).headerName = translate(
            'workspaceOverviewResultsType',
          );
        }

        const historicalBeforeHorizon = allColumns[0] as ColGroupDef;
        const forecastBeforeHorizon = allColumns[1] as ColGroupDef;
        const planningHorizon = allColumns[2] as ColGroupDef;
        const historicalAfterHorizon = allColumns[3] as ColGroupDef;
        const forecastAfterHorizon = allColumns[4] as ColGroupDef;

        historicalBeforeHorizon.headerGroupComponent = () => (
          <div data-testid="historical-header" className="grouped-header">
            <LockSimple data-testid="historical-icon" />
            <p>{translate('Historical')}</p>
          </div>
        );

        historicalBeforeHorizon.children = formatDateField(
          historicalBeforeHorizon.children,
        );

        forecastBeforeHorizon.headerGroupComponent = () => (
          <div data-testid="forecast-header" className="grouped-header">
            <LockSimple data-testid="forecast-icon" />
            <p>{translate('Forecast')}</p>
          </div>
        );

        forecastBeforeHorizon.children = formatDateField(
          forecastBeforeHorizon.children,
        );

        planningHorizon.headerGroupComponent = () => (
          <div data-testid="planning-horizon-header" className="grouped-header">
            <PencilSimple data-testid="planning-horzion-icon" />
            <p>{translate('workspaceOverviewPlanningHorizon')}</p>
          </div>
        );

        planningHorizon.children = formatDateField(planningHorizon.children);

        historicalAfterHorizon.headerGroupComponent = () => (
          <div data-testid="historical-header" className="grouped-header">
            <LockSimple data-testid="historical-icon" />
            <p>{translate('Historical')}</p>
          </div>
        );

        historicalAfterHorizon.children = formatDateField(
          historicalAfterHorizon.children,
        );

        forecastAfterHorizon.headerGroupComponent = () => (
          <div data-testid="forecast-header" className="grouped-header">
            <LockSimple data-testid="forecast-icon" />
            <p>{translate('Forecast')}</p>
          </div>
        );

        forecastAfterHorizon.children = formatDateField(
          forecastAfterHorizon.children,
        );
      }

      if (!tableRef.current?.api?.isDestroyed()) {
        tableRef.current?.api.setGridOption('columnDefs', allColumns);
      }
    };

    if (tableRendered) {
      translateAutoGroupColumn();
      translateColumns();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableRendered, translate]);

  useEffect(() => {
    if (tableRendered && !isLoadingSeriesData) {
      let hasColumn = false;

      const allColumns = tableRef.current?.api.getGridOption('columnDefs');

      const allColumnsVisible: string[] = [];

      allColumns?.forEach((col) => {
        if (col.headerName) {
          const column = col as ColGroupDef;

          column?.children?.forEach(({ field }: ColDef, i) => {
            if (field) {
              hasColumn = true;

              const date = new Date(field);

              const periodFrequency =
                period[frequency === 'monthly' ? 'original' : frequency];

              if (
                periodFrequency[0] &&
                periodFrequency[1] &&
                date >= periodFrequency[0] &&
                date <= periodFrequency[1]
              ) {
                (column.children[i] as ColDef).hide = false;

                allColumnsVisible.push(
                  (column.children[i] as ColDef).field ?? '',
                );
              } else {
                (column.children[i] as ColDef).hide = true;
              }
            }
          });
        }
      });

      if (hasColumn && !tableRef.current?.api?.isDestroyed()) {
        tableRef.current?.api.setGridOption('columnDefs', allColumns);
      }
    }
  }, [tableRendered, isLoadingSeriesData, frequency, period]);

  useEffect(() => {
    if (isSaving) {
      let editingCell = tableRef.current?.api.getEditingCells() ?? [];

      while (editingCell.length > 0) {
        sleep(100);

        editingCell = tableRef.current?.api.getEditingCells() ?? [];
      }

      sleep(100);

      handleSaveEdition();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaving]);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (!editionExpired && editionModeEnabled) {
      interval = setInterval(() => setEditionExpired(true), ms('5 min'));
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [editionExpired, editionModeEnabled]);

  useEffect(() => {
    const updateStepStatusToAdjusting = async (stepNumber: number) => {
      if (step?.status === 'adjusting') {
        try {
          await apiWorkspace.put<Step>(
            `/workspaces/${workspaceId}/releases/${releaseSelected?.id}/approval-flow/steps/${stepNumber}`,
            { status: 'adjusting' },
          );

          // eslint-disable-next-line no-empty
        } catch {}

        if (step.number === stepNumber) {
          dispatch(
            changeWorkspaceOverviewStep({
              ...step,
              status: 'adjusting',
              selectedStatus: 'adjusting',
            }),
          );
        }

        refetchLogs();
      }
    };

    const checkIfStatusHasBeenUpdated = async () => {
      await sleep(ms('15s'));

      queryClient.refetchQueries({
        queryKey: ['workspace', workspaceId, 'releases', releaseSelected?.id],
        exact: true,
      });
    };

    const reversedSteps = [...(releaseData?.data?.steps ?? [])].reverse();

    const lastStepIndex =
      reversedSteps.findIndex(({ status }) => status !== 'created') + 1 || 1;

    const isRemovingFirstStep =
      releaseData?.status === 'removing_step' &&
      releaseData?.data?.steps?.[0]?.status === 'baseline';

    if (
      (isDiscardingAdjustments ||
        releaseData?.status === 'discarding_step' ||
        isRemovingFirstStep ||
        isDiscadingFirstStep) &&
      !releaseIsLoading
    ) {
      if (
        (releaseData?.status === 'discarding_step' &&
          step?.number === lastStepIndex) ||
        isRemovingFirstStep ||
        (isDiscadingFirstStep && tableRendered)
      ) {
        setTableRendered(false);
        setIsDiscardingAdjustments(true);
        checkIfStatusHasBeenUpdated();
      } else {
        if (step && lastStepIndex === step.number) {
          handleUpdateData(lastStepIndex, false);
        }

        if (step) {
          dispatch(
            changeLastLogTime({
              step: step.number,
              value: null,
            }),
          );
        }

        refetchLogs(lastStepIndex);

        setIsDiscardingAdjustments(false);
        dispatch(changeIsDiscardingFirstStep(false));

        if (
          lastStepIndex !== 1 &&
          releaseData?.data.steps?.[lastStepIndex - 1]?.status === 'baseline'
        ) {
          try {
            updateStepStatusToAdjusting(lastStepIndex);
            // eslint-disable-next-line no-empty
          } catch {}
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isDiscardingAdjustments,
    releaseIsLoading,
    releaseData?.status,
    workspaceId,
    releaseSelected?.id,
    step?.number,
    isDiscadingFirstStep,
  ]);

  useEffect(() => {
    const checkIfStatusHasBeenUpdated = async () => {
      await sleep(ms('15s'));

      queryClient.refetchQueries({
        queryKey: ['workspace', workspaceId, 'releases', releaseSelected?.id],
        exact: true,
      });
    };

    if (
      releaseData?.status === 'creating_step' &&
      step?.status !== 'approved'
    ) {
      setTableRendered(false);
      dispatch(changeIsCreatingStep(true));
      checkIfStatusHasBeenUpdated();
    } else {
      const reversedSteps = [...(releaseData?.data?.steps ?? [])].reverse();

      const stepCreatedIndex = reversedSteps.findIndex(
        ({ status }) => status !== 'created',
      );

      if (step) {
        dispatch(
          changeLastLogTime({
            step: stepCreatedIndex,
            value: null,
          }),
        );
      }

      refetchLogs(stepCreatedIndex);

      dispatch(changeIsCreatingStep(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [releaseData?.status, isCreatingStep, dispatch]);

  useEffect(() => {
    if (isCreatingStep && step?.status !== 'approved' && tableRendered) {
      setTableRendered(false);
    }
  }, [tableRendered, isCreatingStep, step?.status]);

  useEffect(() => {
    if (
      adjustedStepLogData?.records &&
      discardedAdjustedStepLogData?.records &&
      removedStepLogData?.records &&
      step?.number !== undefined
    ) {
      const joinArrayAdjustedAndDiscard = [
        ...adjustedStepLogData.records,
        ...discardedAdjustedStepLogData.records,
        ...removedStepLogData.records,
      ].sort(
        (a, b) =>
          new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
      );

      if (joinArrayAdjustedAndDiscard.length > 0) {
        const adjustedLastLogTime = new Date(
          joinArrayAdjustedAndDiscard[0].timestamp,
        ).getTime();

        if (typeof lastLogTimeByStep?.[step.number] !== 'number') {
          dispatch(
            changeLastLogTime({
              step: step.number,
              value: adjustedLastLogTime,
            }),
          );
        } else if (
          //@ts-expect-error: typescript bugou
          lastLogTimeByStep[step.number] < adjustedLastLogTime &&
          user.email !== joinArrayAdjustedAndDiscard[0].user &&
          !isDiscardingAdjustments
        ) {
          setButtonUpdateStatus('opened');
        } else {
          setButtonUpdateStatus(null);
        }
      } else if (lastLogTimeByStep?.[step.number] !== 0) {
        dispatch(
          changeLastLogTime({
            step: step.number,
            value: 0,
          }),
        );
        setButtonUpdateStatus(null);
      }
    }
  }, [
    adjustedStepLogData,
    discardedAdjustedStepLogData,
    removedStepLogData,
    dispatch,
    isDiscardingAdjustments,
    lastLogTimeByStep,
    step,
    user.email,
  ]);

  useEffect(() => {
    if (!ysInfoData) {
      setTableRendered(false);
    }
    if (ysInfoData?.aggregation?.enable === false) {
      setExternalFiltersSelectOptionsBellow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ysInfoData]);

  useEffect(() => {
    (async () => {
      if (tableRendered && ysInfoData) {
        setTableRendered(false);

        await sleep(50);

        setTableRendered(true);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [releaseSelected?.id]);

  useEffect(() => {
    if (releaseData?.data.approval_flow?.enable) {
      if (
        step?.selectedStatus === 'baseline' &&
        step.number === 1 &&
        step.status !== 'baseline'
      ) {
        dispatch(changeWorkspaceOverviewForecast('original'));
      } else {
        dispatch(changeWorkspaceOverviewForecast('adjusted'));
      }
    }
  }, [step, dispatch, releaseData?.data.approval_flow]);

  useEffect(() => {
    if (
      isDiscardingAdjustments &&
      step?.number === releaseData?.data.steps?.length
    ) {
      setTableRendered(false);
    }
  }, [step?.number, isDiscardingAdjustments, releaseData?.data.steps]);

  useEffect(() => {
    if (
      step &&
      (step.status === 'awaiting_approval' || step.status === 'approved') &&
      editionModeEnabled
    ) {
      dispatch(changeEditionModeEnabled(false));
    }

    if (step && step.status !== 'awaiting_approval' && reviewModeEnabled) {
      dispatch(changeReviewModeEnabled(false));
    }
  }, [step, editionModeEnabled, dispatch, reviewModeEnabled]);

  const createExternalFilterAndSerieOptions = useCallback(() => {
    const options: SerieOptions[] = [];
    const externalFilterAux: ExternalFilter[] = [];

    if (ysInfoData) {
      ysInfoData.ys
        .sort((a, b) => a.y_label.localeCompare(b.y_label))
        .forEach((y) => {
          let currentLevelOptions = options;

          let level = 1;

          const hierarchyOption: string[] = [];

          y.hierarchy.forEach((filter, index) => {
            const existsOption = currentLevelOptions.find(
              (option) => option.name === filter.value,
            );

            hierarchyOption.push(filter.value);

            if (existsOption) {
              currentLevelOptions = existsOption.children ?? [];
            } else {
              const canSelect = ysInfoData.aggregation?.enable;

              const newOption = {
                name: filter.value,
                level: index + 1,
                children: [],
                canSelect,
                hierarchy: filter.name,
              };

              currentLevelOptions.push(newOption);
              currentLevelOptions = newOption.children;

              externalFilterAux.push({
                label: hierarchyOption.toString().replaceAll(',', ' -> '),
                value: [...hierarchyOption],
                hierarchy: filter.name,
              });
            }

            level++;
          });

          currentLevelOptions.push({
            name: y.y_label,
            level,
            children: [],
            canSelect: true,
            hierarchy: null,
          });

          hierarchyOption.push(y.y_label);

          externalFilterAux.push({
            label: y.y_label,
            value: [...hierarchyOption],
            hierarchy: null,
          });
        });
    }

    return { options, externalFilterAux };
  }, [ysInfoData]);

  const serieOptions: SerieOptions[] = useMemo(() => {
    const { externalFilterAux, options } =
      createExternalFilterAndSerieOptions();

    setExternalFilters(externalFilterAux);

    return options;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ysInfoData, createExternalFilterAndSerieOptions]);

  const reorganizeTableWithExternalFilters = useCallback(
    (externalFilterAux: ExternalFilter[]) => {
      if (ysInfoData && tableRef.current?.api) {
        const ysAux = ysInfoData.ys.map((y) => {
          const dataY =
            JSON.parse(
              JSON.stringify(tableRef.current?.api.getRowNode(y.y_label)?.data),
            ) ?? {};

          if (!externalFilterAux.some((filter) => filter.label === y.y_label)) {
            dataY._hide = true;
          } else if (dataY?._hide) {
            delete dataY._hide;
          }

          y.hierarchy.forEach((hierarchy, hierarchyIndex) => {
            const hierarchyConcat = y.hierarchy
              .map((hierarchyAux) => hierarchyAux.value)
              .slice(0, hierarchyIndex + 1)
              .toString()
              .replaceAll(',', ' -> ');

            const ancientHierarchies: string[] = [];

            y.hierarchy
              .slice(0, hierarchyIndex + 1)
              .forEach((hierarchyAux, hierarchyIndexAux2) => {
                const hierarchyConcatAux = y.hierarchy
                  .slice(0, hierarchyIndexAux2 + 1)
                  .map((hierarchyAux2) => hierarchyAux2.value)
                  .toString()
                  .replaceAll(',', ' -> ');

                if (
                  !externalFilterAux.some(
                    (filter) => filter.label === hierarchyConcatAux,
                  )
                ) {
                  ancientHierarchies.push(hierarchyAux.value);
                }
              });

            if (
              !externalFilterAux.some(
                (filter) => filter.label === hierarchyConcat,
              )
            ) {
              delete dataY[hierarchy.name];

              if (
                ancientHierarchies.length &&
                // hierarchyIndex > 0 &&
                !dataY?.[y.hierarchy[hierarchyIndex].name]
              ) {
                const nickname = `${ancientHierarchies
                  .toString()
                  .replaceAll(',', ' -> ')} -> ${dataY.y_label}`;
                dataY._nickname = nickname;
              }
            } else {
              let valueAux = '';

              if (
                ancientHierarchies.length &&
                hierarchyIndex > 0 &&
                !dataY?.[y.hierarchy[hierarchyIndex - 1].name]
              ) {
                valueAux = valueAux.concat(
                  ancientHierarchies.toString().replaceAll(',', ' -> '),
                );

                valueAux = valueAux.concat(' -> ');
              }
              valueAux = valueAux.concat(hierarchy.value);

              dataY[hierarchy.name] = valueAux;

              if (dataY?._nickname) {
                delete dataY._nickname;
              }
            }
          });
          return dataY;
        });

        const ysMarketShare: { [key: string]: any }[] = [];

        if (
          ysInfoData.series.length &&
          ysInfoData.series[0].name === 'marketshare' &&
          ysInfoData.series[0].hierarchies.length
        ) {
          ysInfoData.series[0].hierarchies.forEach((hierarchiesAux) => {
            let rowId = 'marketshare';
            const hierarchiesValues: string[] = [];

            hierarchiesAux.forEach((hierarchy) => {
              rowId += `-${hierarchy.name}-${hierarchy.value}`;
              hierarchiesValues.push(hierarchy.value);
            });

            const dataY = JSON.parse(
              JSON.stringify(tableRef.current?.api.getRowNode(rowId)?.data),
            );

            if (
              !externalFilterAux.some(
                (filter) =>
                  filter.label ===
                  hierarchiesValues.toString().replaceAll(',', ' -> '),
              )
            ) {
              dataY._hide = true;
            } else if (dataY?._hide) {
              delete dataY._hide;
            }

            const hierarchiesAuxRemovedLast = [...hierarchiesAux];
            hierarchiesAuxRemovedLast.pop();

            hierarchiesAuxRemovedLast.forEach((hierarchy, hierarchyIndex) => {
              const hierarchyConcat = hierarchiesAux
                .map((hierarchyAux2) => hierarchyAux2.value)
                .slice(0, hierarchyIndex + 1)
                .toString()
                .replaceAll(',', ' -> ');

              const ancientHierarchies: string[] = [];

              hierarchiesAuxRemovedLast
                .slice(0, hierarchyIndex + 1)
                .forEach((hierarchyAux2, hierarchyIndexAux2) => {
                  const hierarchyConcatAux = hierarchiesAuxRemovedLast
                    .slice(0, hierarchyIndexAux2 + 1)
                    .map((hierarchyAux3) => hierarchyAux3.value)
                    .toString()
                    .replaceAll(',', ' -> ');

                  if (
                    !externalFilterAux.some(
                      (filter) => filter.label === hierarchyConcatAux,
                    )
                  ) {
                    ancientHierarchies.push(hierarchyAux2.value);
                  }
                });

              if (
                !externalFilterAux.some(
                  (filter) => filter.label === hierarchyConcat,
                )
              ) {
                delete dataY[hierarchy.name];

                if (
                  ancientHierarchies.length &&
                  !dataY?.[hierarchiesAuxRemovedLast[hierarchyIndex].name]
                ) {
                  const nickname = `${ancientHierarchies
                    .toString()
                    .replaceAll(',', ' -> ')} -> ${dataY.y_label}`;
                  dataY._nickname = nickname;
                }
              } else {
                let valueAux = '';

                if (
                  ancientHierarchies.length &&
                  hierarchyIndex > 0 &&
                  !dataY?.[hierarchiesAuxRemovedLast[hierarchyIndex - 1].name]
                ) {
                  valueAux = valueAux.concat(
                    ancientHierarchies.toString().replaceAll(',', ' -> '),
                  );

                  valueAux = valueAux.concat(' -> ');
                }
                valueAux = valueAux.concat(hierarchy.value);

                dataY[hierarchy.name] = valueAux;

                if (dataY?._nickname) {
                  delete dataY._nickname;
                }
              }
            });

            ysMarketShare.push(dataY);
          });
        }

        if (!tableRef.current?.api?.isDestroyed()) {
          tableRef.current?.api?.setGridOption('rowData', [
            ...ysAux,
            ...ysMarketShare,
          ]);
        }
      }
    },
    [ysInfoData],
  );

  function adjustHeightFilterYType(filter: any) {
    const agMenu = document.querySelector('.ag-menu');
    if (agMenu) {
      //@ts-expect-error: ignora
      agMenu.style.width = `13rem`;
    }
    if (filter) {
      const component = document.querySelector('.ag-set-filter-list');
      if (component) {
        component.classList.add('filter-y-type');
      }
    }
  }

  useEffect(() => {
    if (editionModeEnabled) {
      const { externalFilterAux } = createExternalFilterAndSerieOptions();
      const actualExternalFilter = [...externalFilters];

      const newExternalFilterAux: ExternalFilter[] = [];

      // Função auxiliar para transformar o valor em string padronizada
      const formatFilterValue = (value: string[]) =>
        value.toString().replaceAll(',', ' -> ');

      // Mapeia valores formatados para lookup rápido
      const formattedActualFilterMap = new Map(
        actualExternalFilter.map((actual) => [
          formatFilterValue(actual.value),
          actual,
        ]),
      );

      // Mapeia os filtros auxiliares para evitar repetição
      const formattedAuxFilterSet = new Set();

      externalFilterAux.forEach((externalAux) => {
        const formattedExternalAuxValue = formatFilterValue(externalAux.value);

        if (formattedActualFilterMap.has(formattedExternalAuxValue)) {
          externalFilterAux.forEach((externalAux2) => {
            const formattedExternalAux2Value = formatFilterValue(
              externalAux2.value,
            );

            if (
              formattedExternalAux2Value.includes(formattedExternalAuxValue) &&
              !formattedAuxFilterSet.has(formattedExternalAux2Value)
            ) {
              newExternalFilterAux.push(externalAux2);
              formattedAuxFilterSet.add(formattedExternalAux2Value); // Adiciona ao set para evitar duplicação
            }
          });
        }
      });

      setExternalFilters(newExternalFilterAux);
      reorganizeTableWithExternalFilters(newExternalFilterAux);
      setExternalFiltersSelectOptionsDisableWhenEditionMode(true);
    } else {
      setExternalFiltersSelectOptionsDisableWhenEditionMode(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editionModeEnabled, createExternalFilterAndSerieOptions]);

  useEffect(() => {
    if (
      releaseData?.status !== 'finished' &&
      releaseData?.data?.steps?.length
    ) {
      setNeedRefetchLogs(true);
    } else {
      setNeedRefetchLogs(false);
    }
  }, [releaseData?.status, releaseData?.data.steps?.length]);

  const showRow = async (row: ExternalFilter) => {
    const externalFiltersAux = [...externalFilters, row];

    if (row.hierarchy) {
      const yChildren = ysInfoData?.ys.filter((y) =>
        y.hierarchy
          .map((hierarchy) => hierarchy.value)
          .toString()
          .includes(row.value.toString()),
      );

      if (yChildren?.length) {
        yChildren.forEach((yChild) => {
          if (
            !externalFiltersAux.some(
              (filter) => yChild.y_label === filter.label,
            )
          ) {
            externalFiltersAux.push({
              hierarchy: null,
              label: yChild.y_label,
              value: [
                ...yChild.hierarchy.map((hierarchy) => hierarchy.value),
                yChild.y_label,
              ],
            });
          }

          if (externalFiltersSelectOptionsBellow) {
            yChild.hierarchy.forEach((yChildHierarchy, index) => {
              const hierarchyValues = yChild.hierarchy
                .slice(0, index + 1)
                .map((hierarchy) => hierarchy.value);
              const label = hierarchyValues.toString().replaceAll(',', ' -> ');

              if (
                label.startsWith(row.label) &&
                !externalFiltersAux.some((filter) => filter.label === label)
              ) {
                externalFiltersAux.push({
                  hierarchy: yChildHierarchy.name,
                  label,
                  value: hierarchyValues,
                });
              }
            });
          }
        });
      }
    } else if (ysInfoData && ysInfoData?.aggregation?.enable === false) {
      const y = ysInfoData.ys.find((yAux) => yAux.y_label === row.label);

      if (y) {
        y.hierarchy.forEach((yChildHierarchy, index) => {
          const hierarchyValues = y.hierarchy
            .slice(0, index + 1)
            .map((hierarchy) => hierarchy.value);
          const label = hierarchyValues.toString().replaceAll(',', ' -> ');

          if (!externalFiltersAux.some((filter) => filter.label === label)) {
            externalFiltersAux.push({
              hierarchy: yChildHierarchy.name,
              label,
              value: hierarchyValues,
            });
          }
        });
      }
    }

    reorganizeTableWithExternalFilters(externalFiltersAux);
    setExternalFilters(externalFiltersAux);
  };

  const hideRow = async (row: ExternalFilter) => {
    const rowValueStr = row.value.toString();

    let externalFiltersAux = externalFilters.filter(
      ({ value }) => value.toString() !== rowValueStr,
    );

    const labelsToRemove = new Set<string>();

    if (externalFiltersSelectOptionsBellow) {
      externalFiltersAux.forEach(({ value, label }) => {
        if (value.toString().startsWith(rowValueStr)) {
          if (
            row.hierarchy ||
            (row.hierarchy === null && !value.toString().endsWith(label))
          ) {
            labelsToRemove.add(label);
          }
        }
      });
    }

    if (!row.hierarchy) {
      externalFiltersAux.forEach(({ value, label, hierarchy }) => {
        if (hierarchy) {
          const hasYChildren = externalFiltersAux.some(
            ({ hierarchy: childHierarchy, value: childValue }) =>
              !childHierarchy &&
              childValue.toString().includes(value.toString()),
          );

          if (!hasYChildren) {
            labelsToRemove.add(label);
          }
        }
      });
    }

    externalFiltersAux = externalFiltersAux.filter(
      ({ label, value, hierarchy }) => {
        if (labelsToRemove.has(label)) return false;

        if (hierarchy) {
          const hasSomeYChild = externalFiltersAux.some(
            ({
              hierarchy: childHierarchy,
              value: childValue,
              label: childLabel,
            }) =>
              !childHierarchy &&
              !labelsToRemove.has(childLabel) &&
              childValue.toString().startsWith(value.toString()),
          );

          if (!hasSomeYChild) return false;
        }

        return true;
      },
    );

    reorganizeTableWithExternalFilters(externalFiltersAux);
    setExternalFilters(externalFiltersAux);
  };

  function handleExternalFilterSelectOptionsBellow(value: boolean) {
    setExternalFiltersSelectOptionsBellow(value);
  }

  const releaseIsFinished = releaseData?.status === 'finished';

  const userIsManager = userRole === 'manager';

  const isApprovalFlow =
    releaseData?.data?.approval_flow?.enable && releaseData.data.steps?.length;

  const stepUsers = currentStepInfo?.users?.profiles;

  const hasUserStepProfiles = !!Object.keys(
    currentStepInfo?.users?.profiles ?? {},
  ).length;

  const userStepRoles = stepUsers?.[user.email ?? ''];

  const userIsEditor = hasUserStepProfiles
    ? userStepRoles?.some(({ type }) => type === 'editor')
    : true;

  const userIsApprover = hasUserStepProfiles
    ? userStepRoles?.find(({ type }) => type === 'approver')
    : userIsManager
    ? { level: 1 }
    : undefined;
  const currentApprover = currentStepInfo?.flow?.status?.approver ?? 1;
  const userCanApprove =
    !!userIsApprover && userIsApprover.level === currentApprover;

  const monthlyWorkspace = workspaceFrequency === 'monthly';
  const releaseIsPreview = releaseSelected?.id === releasePreview;

  const isShowingAdjustedData = forecastType === 'adjusted';

  const stepStatusIsAdjusting = step?.status === 'adjusting';
  const stepStatusIsAwaitingApproval = step?.status === 'awaiting_approval';
  const stepStatusIsApproved = step?.status === 'approved';

  const hasDisapprovalMessage = !!step?.disapproval_messages?.length;
  const stepStatusIsDisapproved =
    !!stepStatusIsAdjusting && !!hasDisapprovalMessage;

  const isLoadingTableData = isLoadingSeriesData || isLoadingMarketShare;

  const hasNoAdjustments =
    new Date(updatedStepLogData?.records?.[0]?.timestamp ?? '5000-01-01') >
    new Date(adjustedStepLogData?.records?.[0]?.timestamp ?? '1000-01-01');

  const showMakeAdjustmentsButton =
    hasNoAdjustments &&
    stepStatusIsDisapproved &&
    !editionModeEnabled &&
    isShowingAdjustedData &&
    userIsEditor &&
    !releaseIsFinished;

  const shouldUpdateData = buttonUpdateStatus === 'opened';

  const hasAtLeastOneProjectY = releaseData?.data?.ys?.some(
    ({ source }) => source === 'projects',
  );

  const canEdit =
    frequency === 'original' &&
    monthlyWorkspace &&
    transformation === 'level' &&
    isShowingAdjustedData &&
    !releaseIsFinished &&
    (hasAtLeastOneProjectY || hasPlanningHorizon);

  const editionModeDisabled =
    releaseIsPreview ||
    loadingEnableEdition ||
    isLoadingTableData ||
    shouldUpdateData ||
    isEditingPlanningFlow;

  const showEditionModeButtons =
    canEdit &&
    (!step || stepStatusIsAdjusting || step.status === 'baseline') &&
    !showMakeAdjustmentsButton &&
    ((isApprovalFlow && userIsEditor) || !isApprovalFlow);

  const showDiscardAdjustmentsAndSubmitApprovalButton =
    stepStatusIsAdjusting &&
    !editionModeEnabled &&
    isShowingAdjustedData &&
    !showMakeAdjustmentsButton &&
    ((isApprovalFlow && userIsEditor) || !isApprovalFlow) &&
    !releaseIsFinished;

  const showAwaitingApprovalTag =
    stepStatusIsAwaitingApproval &&
    !userCanApprove &&
    isShowingAdjustedData &&
    !releaseIsFinished;

  const showReviewScenarioButton =
    stepStatusIsAwaitingApproval &&
    isShowingAdjustedData &&
    !reviewModeEnabled &&
    userCanApprove &&
    !releaseIsFinished;

  const showDisapproveApproveButtons =
    reviewModeEnabled && isShowingAdjustedData && !releaseIsFinished;

  const showApprovedTag = stepStatusIsApproved && isShowingAdjustedData;

  const discardAdjustmentsDisabled =
    hasNoAdjustments || shouldUpdateData || isEditingPlanningFlow;

  const submitForApprovalDisabled = shouldUpdateData || isEditingPlanningFlow;
  const reviewScenarioDisabled = shouldUpdateData || isEditingPlanningFlow;

  const stepStatusHasMessage =
    (stepStatusIsAwaitingApproval && !!userIsApprover) ||
    stepStatusIsApproved ||
    stepStatusIsDisapproved;

  const showFlowMessage =
    !releaseIsPreview &&
    stepStatusHasMessage &&
    step.selectedStatus !== 'baseline';
  const flowMessageInformation: LogRecord | undefined = stepStatusHasMessage
    ? updatedStepLogData?.records[0]
    : undefined;

  const flowMessagesByStatus = {
    baseline: '',
    adjusting: flowMessageInformation?.data?.disapproval_messages?.[0],
    awaiting_approval:
      flowMessageInformation?.data?.awaiting_approval_message?.[0] ??
      step?.awaiting_approval_messages?.at(-1),
    approved: flowMessageInformation?.data?.approval_message?.[0],
  };

  const flowMessage =
    flowMessagesByStatus[
      step?.status === 'created' ? 'baseline' : step?.status ?? 'adjusting'
    ];

  const tableStatus: TableStatus = !isShowingAdjustedData
    ? null
    : releaseIsFinished && step?.status !== 'approved'
    ? null
    : editionModeEnabled
    ? 'editing'
    : reviewModeEnabled
    ? 'reviewing'
    : step?.status === 'awaiting_approval'
    ? 'awaiting_approval'
    : step?.status === 'approved'
    ? 'approved'
    : hasNoAdjustments && stepStatusIsDisapproved
    ? 'disapproved'
    : null;

  const isLoadingYsInfo =
    ysInfoIsLoading || ysInfoIsFetching || !columns.length;

  return (
    <Container
      data-testid="results-description-card"
      className="containerLinear"
    >
      <Card
        textCard={translate('workspaceOverviewResultsTitle')}
        textDescription={translate('workspaceOverviewResultsDescription')}
        style={{ width: showFlowMessage ? 'calc(100% - 27rem)' : '100%' }}
      />

      <ButtonUpdate
        id="button-update-data"
        onClick={() => handleUpdateData()}
        status={buttonUpdateStatus}
        data-testid="button-update-data"
      >
        <ArrowsClockwise size={16} />
        {translate('workspaceOverviewUpdateData')}
      </ButtonUpdate>

      <HeaderRightContent>
        {showFlowMessage && (
          <FlowMessage
            isLoading={updatedStepLogIsLoading}
            message={flowMessage}
            author={flowMessageInformation?.user}
            dateTime={flowMessageInformation?.timestamp}
          />
        )}
      </HeaderRightContent>

      <SelectedFiltersExportContainer>
        <SelectedFilters filters={selectedFilters} />

        <ButtonRounded
          label={translate('export')}
          icon={<DownloadSimple />}
          onClick={handleDownloadTable}
          disabled={
            isLoadingSeriesData ||
            isLoadingMarketShare ||
            editionModeEnabled ||
            isLoadingYsInfo ||
            !tableRendered
          }
          data-testid="download-results-button"
          data-tooltip-id="results-table"
          data-tooltip-content={
            isLoadingSeriesData || isLoadingMarketShare
              ? translate('workspaceOverviewResultsLoadingData')
              : editionModeEnabled
              ? translate('workspaceOverviewResultsEditionBlockDownload')
              : undefined
          }
        />
      </SelectedFiltersExportContainer>

      {ysInfoIsError ? (
        <Status
          type="error"
          title={translate('workspaceOverviewResultsErrorTitle')}
          description={translate('workspaceOverviewResultsErrorDescription')}
        />
      ) : isLoadingYsInfo ? (
        <ContainerSkeleton
          data-testid="loading-results-table"
          style={{ height: '22rem' }}
        />
      ) : isDiscardingAdjustments &&
        step?.number ===
          (releaseData?.data.steps?.findIndex(
            ({ status }) => status !== 'approved',
          ) ?? 0) +
            1 ? (
        // eslint-disable-next-line react/jsx-indent
        <DiscardingAdjustmentsContainer data-testid="loading-discarding-adjustments">
          <ContainerSkeleton style={{ height: 'fit-content' }} />

          <p>
            {translate('workspaceOverviewPlanningFlowDiscardingAdjustments')}
          </p>
        </DiscardingAdjustmentsContainer>
      ) : isCreatingStep && step?.status !== 'approved' ? (
        <DiscardingAdjustmentsContainer data-testid="loading-configuring-step">
          <ContainerSkeleton style={{ height: 'fit-content' }} />

          <p>{translate('workspaceOverviewPlanningFlowConfiguringStep')}</p>
        </DiscardingAdjustmentsContainer>
      ) : (
        <Content>
          <HierarchicalFilter
            options={serieOptions}
            selected={externalFilters}
            optionAdded={showRow}
            optionRemoved={hideRow}
            onChangeSelectOptionsBellow={
              handleExternalFilterSelectOptionsBellow
            }
            selectOptionsDisableWhenEditionMode={
              externalFiltersSelectOptionsDisableWhenEditionMode
            }
            selectOptionsBellow={externalFiltersSelectOptionsBellow}
            hasHierarchy={!!ysInfoData?.aggregation?.enable}
          />

          <TableContainer status={tableStatus}>
            <TableButtonsContainer>
              {showAwaitingApprovalTag ? (
                <Tag
                  data-testid="container-waiting-approval"
                  className="waiting-approval-tag"
                >
                  {translate('workspaceOverviewPlanningFlowWaitingApproval')}
                </Tag>
              ) : showReviewScenarioButton ? (
                <Button
                  buttonType="primary"
                  icon={<CheckCircle />}
                  onClick={handleStartReview}
                  disabled={reviewScenarioDisabled}
                  data-testid="button-review-scenario"
                  className="button-review-scenario"
                  data-tooltip-id="results-table"
                  data-tooltip-content={
                    buttonUpdateStatus === 'opened'
                      ? translate(
                          'workspaceOverviewUpdateDataToEnableReviewScenario',
                        )
                      : isEditingPlanningFlow
                      ? translate(
                          'workspaceOverviewPlanningConfigurationEnabled',
                        )
                      : undefined
                  }
                >
                  {translate('workspaceOverviewPlanningFlowReviewScenario')}
                </Button>
              ) : showDisapproveApproveButtons ? (
                <>
                  <Button
                    buttonType="secondary"
                    icon={<SignOut />}
                    onClick={handleExitReview}
                    data-testid="button-exit-review"
                    className="button-disapprove-or-exit"
                  >
                    {translate('workspaceOverviewResultsDiscardEdition')}
                  </Button>
                  <Button
                    buttonType="secondary"
                    icon={<X />}
                    onClick={() => setShowDisapproveModal(true)}
                    data-testid="button-disapprove"
                    className="button-disapprove-or-exit"
                  >
                    {translate('workspaceOverviewPlanningFlowDisapprove')}
                  </Button>
                  <Button
                    buttonType="primary"
                    icon={<CheckCircle />}
                    onClick={() => setShowApproveModal(true)}
                    data-testid="button-approve"
                    className="button-approve"
                  >
                    {translate('workspaceOverviewPlanningFlowApprove')}
                  </Button>
                </>
              ) : showApprovedTag ? (
                <Tag data-testid="container-approved" className="approved-tag">
                  {translate('workspaceOverviewPlanningFlowApproved')}
                </Tag>
              ) : (
                showMakeAdjustmentsButton &&
                canEdit && (
                  <Button
                    buttonType="primary"
                    icon={<PencilSimple />}
                    onClick={handleEnableEdition}
                    loading={loadingEnableEdition}
                    disabled={
                      loadingEnableEdition ||
                      isLoadingSeriesData ||
                      isEditingPlanningFlow
                    }
                    data-testid="button-new-adjustments"
                    className="button-new-adjustments"
                    data-tooltip-id="results-table"
                    data-tooltip-content={
                      isEditingPlanningFlow
                        ? translate(
                            'workspaceOverviewPlanningConfigurationEnabled',
                          )
                        : undefined
                    }
                  >
                    {translate('workspaceOverviewPlanningFlowMakeAdjustments')}
                  </Button>
                )
              )}

              {showEditionModeButtons && (
                <>
                  {!editionModeEnabled ? (
                    <Button
                      buttonType="secondary"
                      icon={<PencilSimple />}
                      onClick={handleEnableEdition}
                      loading={loadingEnableEdition}
                      disabled={editionModeDisabled}
                      data-testid="enable-edition-button"
                      className="enable-edition-button"
                      data-tooltip-id="results-table"
                      data-tooltip-content={
                        buttonUpdateStatus === 'opened'
                          ? translate(
                              'workspaceOverviewUpdateDataToEnableEditingMode',
                            )
                          : releaseIsPreview
                          ? translate(
                              'workspaceOverviewResultsEditionDisabledTooltip',
                            )
                          : isLoadingTableData
                          ? translate('workspaceOverviewResultsLoadingData')
                          : isEditingPlanningFlow
                          ? translate(
                              'workspaceOverviewPlanningConfigurationEnabled',
                            )
                          : undefined
                      }
                    >
                      {translate('workspaceOverviewResultsEnableEditMode')}
                    </Button>
                  ) : (
                    <>
                      <Button
                        buttonType="secondary"
                        icon={<SignOut />}
                        onClick={handleExitEdition}
                        data-testid="discard-edition-button"
                        className="discard-edition-button"
                      >
                        {translate('workspaceOverviewResultsDiscardEdition')}
                      </Button>
                      <Button
                        buttonType="primary"
                        icon={<FloppyDisk />}
                        onClick={() => setIsSaving(true)}
                        loading={isSaving}
                        disabled={invalidValues > 0 || isSaving}
                        data-testid="save-edition-button"
                        className="save-edition-button"
                      >
                        {translate('workspaceOverviewResultsSaveEdition')}
                      </Button>
                    </>
                  )}
                </>
              )}

              {showDiscardAdjustmentsAndSubmitApprovalButton && (
                <>
                  <Button
                    buttonType="secondary"
                    icon={<Trash />}
                    onClick={() => setShowDiscardAdjustmentsConfirmation(true)}
                    disabled={discardAdjustmentsDisabled}
                    data-testid="button-discard-adjustments"
                    className="button-discard-adjustments"
                    data-tooltip-id="results-table"
                    data-tooltip-content={
                      buttonUpdateStatus === 'opened'
                        ? translate(
                            'workspaceOverviewUpdateDataToEnableDiscardAdjustments',
                          )
                        : hasNoAdjustments
                        ? translate(
                            'workspaceOverviewPlanningFlowNoAdjustments',
                          )
                        : isEditingPlanningFlow
                        ? translate(
                            'workspaceOverviewPlanningConfigurationEnabled',
                          )
                        : undefined
                    }
                  >
                    {translate(
                      'workspaceOverviewPlanningFlowDiscardAdjustments',
                    )}
                  </Button>

                  <SubmitApprovalContainer>
                    <Button
                      buttonType="secondary"
                      icon={<PaperPlaneTilt />}
                      onClick={() =>
                        setShowRequestApproval(!showRequestApproval)
                      }
                      disabled={submitForApprovalDisabled}
                      data-testid="button-submit-for-approval"
                      className="button-submit-for-approval"
                      data-tooltip-id="results-table"
                      data-tooltip-content={
                        buttonUpdateStatus === 'opened'
                          ? translate(
                              'workspaceOverviewUpdateDataToEnableSubmitForApproval',
                            )
                          : isEditingPlanningFlow
                          ? translate(
                              'workspaceOverviewPlanningConfigurationEnabled',
                            )
                          : undefined
                      }
                    >
                      {translate('workspaceOverviewPlanningFlowSubmitApproval')}
                    </Button>

                    <RequestApproval
                      visible={showRequestApproval}
                      lockEdition={lockEdition}
                      onRequestApproval={updateStepStatus}
                      closeRequestApproval={() => setShowRequestApproval(false)}
                    />
                  </SubmitApprovalContainer>
                </>
              )}
            </TableButtonsContainer>

            {invalidValues > 0 && (
              <TableError visible data-testid="invalid-values-error">
                <Warning weight="fill" />
                <p>{translate('workspaceOverviewResultsInvalidValue')}</p>
              </TableError>
            )}

            <AgGridTable
              animateRows={false}
              tableRef={tableRef}
              rowData={rowData}
              columnDefs={columns}
              defaultColDef={defaultColDef}
              autoGroupColumnDef={autoGroupColumnDef}
              aggFuncs={aggFuncs}
              getRowId={getRowId}
              getRowHeight={getRowHeight}
              checkDomLayoutType={tableRendered}
              maxRemHeight={38}
              containerStyle={{
                height: '38rem',
              }}
              onFirstDataRendered={() => setTableRendered(true)}
              groupRemoveSingleChildren={!ysInfoData?.hierarchies.length}
              groupDefaultExpanded={
                !ysInfoData?.aggregation?.enable
                  ? -1
                  : ysInfoData?.hierarchies.length
                  ? 1
                  : 0
              }
              rowClassRules={
                ysInfoData?.market_share?.enable ? rowClassRules : undefined
              }
              columnTypes={{
                defaultColumn: {
                  aggFunc,
                  valueFormatter: (params: ValueFormatterParams) =>
                    numberFormatter(params.value, user.language),
                  editable: (params: EditableCallbackParams) =>
                    editable(
                      params.data.y_label,
                      new Date(params.colDef.field ?? ''),
                      params.node.id ?? '',
                    ),
                  cellEditor: 'agNumberCellEditor',
                  cellClass: defaultColumnCellClass,
                  cellRenderer: (params: ICellRendererParams) => {
                    const isAggCell =
                      !params.data?.row_id && ysInfoData?.aggregation?.enable;

                    const id = `${params.node.id}-${params.colDef?.field}`;

                    const isMarketShare =
                      id.startsWith('row-group-y_type-marketshare') ||
                      !!params.node?.parent?.id?.startsWith(
                        'row-group-y_type-marketshare',
                      );

                    const isForecastCell =
                      (isAggCell &&
                        !!params.colDef?.type?.includes('forecastColumn')) ||
                      isForecast(
                        isMarketShare ? params.node.id : params.data?.y_label,
                        new Date(params.colDef?.field ?? ''),
                      );

                    const isVariationAgg =
                      isAggCell && transformation.includes('variation');

                    const dateIsPlanningHorizon = isPlanningHorizon(
                      new Date(params.colDef?.field ?? ''),
                    );

                    const needBaselineValueToExternalSerie =
                      isExternalSerie(params.data?.row_id) &&
                      hasPlanningHorizon &&
                      dateIsPlanningHorizon;

                    const needBaselineValue =
                      isForecastCell ||
                      (hasPlanningHorizon && dateIsPlanningHorizon) ||
                      isVariationAgg ||
                      needBaselineValueToExternalSerie;

                    const loadingData =
                      isLoadingSeriesData ||
                      (isLoadingMarketShare && isMarketShare);

                    if (!needBaselineValue || loadingData) {
                      const value =
                        loadingData || params.value === null
                          ? undefined
                          : params.value;

                      return (
                        <ValueCell
                          id={id}
                          baselineValue={value}
                          adjustedValue={value}
                          isForecast={false}
                          showValueError={false}
                          isMarketShare={isMarketShare}
                          showBaselineValue={false}
                        />
                      );
                    }

                    const baselineValue = getOriginalAdjustedValue(
                      params.node,
                      params.colDef?.field ?? '',
                      'original',
                    );

                    if (
                      isVariationAgg &&
                      params.value !== baselineValue &&
                      params.value === null
                    ) {
                      const rowNode = params.api.getRowNode(
                        params.node.id ?? '',
                      )!;

                      rowNode.setDataValue(
                        params.colDef?.field ?? '',
                        baselineValue,
                      );
                    }

                    let adjustedValue = isVariationAgg
                      ? getOriginalAdjustedValue(
                          params.node,
                          params.colDef?.field ?? '',
                          'adjusted',
                        )
                      : params.value;

                    if (
                      step?.number === 1 &&
                      step.selectedStatus === 'baseline' &&
                      step.status !== 'baseline'
                    ) {
                      adjustedValue = baselineValue;
                    }

                    return (
                      <ValueCell
                        id={id}
                        baselineValue={baselineValue}
                        adjustedValue={adjustedValue}
                        isForecast={isForecastCell || dateIsPlanningHorizon}
                        showValueError={editionModeEnabled}
                        isMarketShare={isMarketShare}
                        showBaselineValue={
                          editionModeEnabled ||
                          (reviewModeEnabled && isShowingAdjustedData) ||
                          (stepStatusIsAwaitingApproval && !userIsManager) ||
                          (stepStatusIsApproved && isShowingAdjustedData)
                        }
                      />
                    );
                  },
                },
                historicalColumn: {},
                forecastColumn: {},
                planningHorizonColumn: {},
                variableTypeColumn: {},
              }}
              onCellEditingStopped={(params) =>
                handleEditedValue(
                  params.data.y_label,
                  params.colDef.field ?? '',
                  params.newValue,
                  params.oldValue,
                  params.node.id ?? '',
                )
              }
              excelStyles={excelStyles}
              groupAllowUnbalanced
              suppressGroupRowsSticky
              isExternalFilterPresent={() => true}
              doesExternalFilterPass={(node) => !node.data?._hide}
              onFilterOpened={(params) => {
                if (params.column.getId() === 'y_type') {
                  params.api
                    .getColumnFilterInstance('y_type')
                    .then(adjustHeightFilterYType);
                }
              }}
            />
          </TableContainer>
        </Content>
      )}

      {(!!showUserIsEditingModal || showWorkspaceIsPublishing) && (
        <NoPermissionToEditModal
          setVisible={() => {
            setShowUserIsEditingModal('');
            setShowWorkspaceIsPublishing(false);
          }}
          emailEditing={showUserIsEditingModal}
          errorDescription={
            editionModeEnabled
              ? translate(
                  'workspaceOverviewResultsEditionLockedDescription',
                ).replace('XXX', showUserIsEditingModal)
              : showWorkspaceIsPublishing
              ? translate('workspaceOverviewResultsWorkspaceIsPublishing')
              : undefined
          }
        />
      )}

      {editionExpired && editionModeEnabled && (
        <SessionExpiredModal
          handleBlockEdition={handleEnableEdition}
          loadingBlockEdition={loadingEnableEdition}
          handleDiscardChanges={handleExitEdition}
        />
      )}

      {showDiscardAdjustmentsConfirmation && (
        <ActionConfirmationModal
          setVisible={setShowDiscardAdjustmentsConfirmation}
          title={translate(
            'workspaceOverviewDiscardAdjustmentsConfirmationModalTitle',
          )}
          description={translate(
            step?.number === 1
              ? 'workspaceOverviewDiscardAdjustmentsConfirmationModalDescriptionFirstStep'
              : 'workspaceOverviewDiscardAdjustmentsConfirmationModalDescriptionOtherStep',
          )}
          onConfirm={handleDiscardAllAdjustments}
        />
      )}

      {showApproveModal && (
        <ApproveOrDisapproveModal
          approveOrDisapprove="approve"
          onConfirm={handleApproveReview}
          onCancel={() => setShowApproveModal(false)}
        />
      )}

      {showDisapproveModal && (
        <ApproveOrDisapproveModal
          approveOrDisapprove="disapprove"
          onConfirm={handleDisapproveReview}
          onCancel={() => setShowDisapproveModal(false)}
        />
      )}

      {showUpdateStatusError && (
        <Modal
          visible
          setVisible={setShowUpdateStatusError}
          style={{ width: '30rem' }}
          dataCy="modal-update-status-error"
        >
          <Status
            type="cloudWarning"
            title={translate('workspaceOverviewPlanningFlowUpdateErrorTitle')}
            description={translate(
              'workspaceOverviewPlanningFlowUpdateErrorDescription',
            )}
            isModal
          />

          <ModalFooter>
            <Button
              buttonType="primary"
              onClick={() => setShowUpdateStatusError(false)}
            >
              Ok
            </Button>
          </ModalFooter>
        </Modal>
      )}

      {unsavedYs.length > 0 && (
        <UnsavedYsModal ys={unsavedYs} setVisible={() => setUnsavedYs([])} />
      )}

      <Tooltip id="results-table" className="customTooltipTheme" />
    </Container>
  );
});
