import React, { ReactElement } from 'react';

import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

const Auth0ProviderWithHistory: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  const envAuth0 = process.env.REACT_APP_ENV ?? '';

  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain ?? ''}
      clientId={clientId ?? ''}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience,
      }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation={envAuth0 === 'development' ? 'localstorage' : 'memory'}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
