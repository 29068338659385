export const homeFeatureStorePTBR = {
  homeFeatureStoreMyFavorites: 'Meus Favoritos',
  homeFeatureStoreHighlights: 'Destaques 4i',
  homeFeatureStoreCreateYourFirstFavorite: 'Crie seu primeiro grupo favorito',
  serieInfoRegion: 'Região',
  serieInfoFrequency: 'Frequência',
  serieInfoPrimaryTransformation: 'Transformação Primária',
  serieInfoSecondaryTransformation: 'Transformação Secundária',
  serieInfoSource: 'Fonte: ',
  serieInfoFooterExpand: 'Expandir',
  indicatorsForAnalyzeHeaderTitle: 'Indicadores para Análise',
  indicatorsForAnalyzeDescription:
    'Grupos de indicadores para entender grandes cenários.',
  homeFeatureStoryPremiumTitle: 'Indicadores Premium',
  homeFeatureStoryPremiumDescription:
    'Expanda a base de dados disponíveis com essas coleções de indicadores especializados.',
  indicatorsForAnalyzeErrorDescription:
    'Não foi possível carregar os indicadores para analise.',
  indicatorsForAnalyzeErrorTitle:
    'Ocorreu um erro interno ao processar a solicitação.',
  serieInfoGoToIndicator: 'Ir para o indicador',
  listFavoritesGoToAllFavorites: 'Acessar todos os grupos',
};
