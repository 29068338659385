import styled from 'styled-components';

export const ChartHeader = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
  justify-content: space-between;

  label {
    margin-right: 1rem;
  }

  > div {
    display: flex;
  }
`;

export const ChartButton = styled.div`
  display: flex;
  gap: 1rem;
`;
