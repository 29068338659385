import styled from 'styled-components';

export const ContentCrossValidationWindows = styled.div`
  > h3 {
    font-size: 1.125rem;
    color: ${({ theme }) => theme.colors.gray6};
    font-weight: 400;
    text-align: center;
  }
`;
