import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PayloadGroupPremium {
  groupPremiumId: string;
  groupPremiumName: {
    'en-us': string;
    'pt-br': string;
  };
  groupPremiumDescription: {
    'en-us': string;
    'pt-br': string;
  };
  iconUrl: string;
  page: number;
}

const initialState: PayloadGroupPremium = {
  groupPremiumId: '',
  groupPremiumName: {
    'en-us': '',
    'pt-br': '',
  },
  groupPremiumDescription: {
    'en-us': '',
    'pt-br': '',
  },
  iconUrl: '',
  page: 1,
};

export const groupPremiumState = createSlice({
  name: 'group premium',
  initialState,
  reducers: {
    setGroupPremium: (state, action: PayloadAction<PayloadGroupPremium>) => {
      state.groupPremiumId = action.payload.groupPremiumId;
      state.groupPremiumName = action.payload.groupPremiumName;
      state.groupPremiumDescription = action.payload.groupPremiumDescription;
      state.iconUrl = action.payload.iconUrl;
      state.page = action.payload.page;
    },

    resetGroupPremium: (state) => {
      state.groupPremiumId = '';
      state.groupPremiumName['en-us'] = '';
      state.groupPremiumName['pt-br'] = '';
      state.groupPremiumDescription['en-us'] = '';
      state.groupPremiumDescription['pt-br'] = '';
      state.iconUrl = '';
      state.page = 1;
    },

    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
  },
});

export const { setGroupPremium, resetGroupPremium, setPage } =
  groupPremiumState.actions;

export default groupPremiumState.reducer;
