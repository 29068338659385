const ShareUpdateModalENUSTranslations = {
  shareUpdateTitle: 'Share updates',
  shareUpdateDescription: 'Share updates to your project with users.',
  shareUpdateStatusFinished: 'Finished',
  shareUpdateStatusError: 'Error',
  shareUpdateStatusCopying: 'In progress',
  shareUpdateStatusNotSent: 'Not shared',
  shareUpdateTableUsers: 'Users',
  shareUpdateTableStatus: 'Sharing status',
  shareUpdateSendUpdateButton: 'Share',
  shareUpdateErrorTitle: 'Failed to share Update',
  shareUpdateErrorDescription:
    'It was not possible to share the project update, if the problem persists, please contact support.',
  shareUpdateNoSharesDescription:
    'This project is not shared with any user yet, share it by creating a service desk request.',
};

export default ShareUpdateModalENUSTranslations;
