import styled from 'styled-components';

export const Container = styled.div`
  width: 70vw;
  height: 80vh;
  max-height: 43rem;
  margin-bottom: -1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Content = styled.div`
  height: calc(100% - 6rem);
`;

interface ThProps {
  width?: string | number;
  valueWidthInMediaQueryAuto?: string | number;
}

export const ContainerTable = styled.div<ThProps>`
  height: calc(100% - 7rem);
  position: relative;
  overflow-y: auto;
  ::-webkit-scrollbar-track {
    margin-top: 2rem;
  }

  > table {
    word-break: break-word;
    > thead {
      > tr {
        position: sticky;
        top: 0px;
        z-index: 99;
        background: ${({ theme }) => theme.colors.white};

        th {
          label {
            p {
              color: ${({ theme }) => theme.colors.gray6};
              font-weight: 700;
              font-size: 0.75rem;
              line-height: 1.375rem;
              text-align: left;
            }
          }
        }

        > th {
          min-width: 12.5rem;
          max-width: 12.5rem;
          position: relative;
        }
        > th:last-child {
          min-width: 8.25rem;
          max-width: 8.25rem;
          position: relative;
        }
      }
    }
    tbody {
      > tr {
        > td {
          p {
            padding-left: 1rem;
            &:first-child {
              padding-left: 0;
            }
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
`;
