export const WorkspaceCardPTBRTranslations = {
  workspaceCardCanView: 'Você pode colaborar',
  workspaceCardCanEdit: 'Você pode editar',
  workspaceCardCanManager: 'Você pode gerenciar',
  workspaceCardDelete: 'Deletar da sua conta',
  workspaceCardDeleteDescription:
    'Seu workspace será permanentemente removido de sua conta. Por favor, escreva <b>XXX</b> para confirmar.',
  workspaceCardDeleteWrongNameError: 'Escreva o nome correto do workspace',
  workspaceCardDeleteErrorTitle: 'Falha ao deletar',
  workspaceCardDeleteErrorDescription:
    'Ocorreu um erro ao deletar o workspace da sua conta. Por favor, tente novamente mais tarde.',
  workspaceCardNoPermissionToDeleteTitle: 'Último gestor',
  workspaceCardNoPermissionToDeleteDescription:
    'Você é o último gestor, remova todos os visualizadores antes de deletar o workspace da sua conta.',
};
