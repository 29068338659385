const FeatureStoreEN = {
  featureStoreIntegrationTitle: 'Feature Store Groups',
  featureStoreIntegrationTitleDescription:
    'You can select an entire group or click in the group to select the series separately.',
  preDefinedTitleTab: '4i favorites',
  myGroupTitleTab: 'My favorites',
  placeholderInputSearchGroup: 'Search group',
  errorMessageInput3Caracteres: 'Search must be at least 3 characters.',
  errorMessageInputMax30Caracteres: 'Search must be at most 30 characters.',
  errorMessageFindAnyGroup: 'We couldn’t find any group containing',
  nameGroup: 'Name',
  createdAtGroup: 'Created at',
  lastUpdatedGroup: 'Last updated',
  textGroups: 'groups',
  textSeries: 'series',
  errorLoadMyGroups: 'Unable to load my favorites groups.',
  errorLoadPreDefinedGroups: 'Unable to load 4i favorites groups.',
  noGroupsFound: 'No groups found.',
  errorToSendVariablesTitle: 'Error',
  errorToSendVariablesMessage: 'Error on send variables.',
  pick4MeTitleTab: 'Pick4me',
  pick4Me1StepTitle: 'Automatic Feature Discovery',
  pick4Me1StepDescription:
    'Let our state-of-the-art algorithm do the feature selection job for you.',
  pick4Me1StepButton: 'Start',
  pick4Me2StepTitle: 'Our Algorithm is doing its magic!',
  pick4Me2StepDescription:
    "Just hang on! In a few seconds, we'll recommend several series that may help your project.",
  FeatureStoreIntegrationColumnName: 'Name',
  FeatureStoreIntegrationColumnSource: 'Source',
  FeatureStoreIntegrationColumnLastUpdated: 'Last updated',
  FeatureStoreIntegrationColumnStartDate: 'Start date',
  FeatureStoreIntegrationColumnEndDate: 'End date',
  FeatureStoreIntegrationColumnRegion: 'Region',
  FeatureStoreIntegrationColumnPrimary: 'Primary Tr.',
  FeatureStoreIntegrationColumnSecondary: 'Secondary Tr.',
  pick4Me3StepColumnProbability: 'Probability',
  pick4Me3StepColumnProbabilityInformation:
    'AI Certainty that a variable will bring useful information to your models',
  pick4MeErrorRecommend: `Sorry, we couldn't find any feature to recommend.`,
  textButtonCancel: 'Cancel',
  textButtonSendToFaas: 'Send series',
  messageInformsIncompatible:
    '*Groups and Series with incompatible frequencies with your dataset have been disabled',

  someVariables: 'Some of the variables ',
  messageAboutSeriePremium:
    "are useful for your modeling, but you don't have access.",
  aboutMore: 'About more.',
};

export default FeatureStoreEN;
