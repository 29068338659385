import React, { ReactElement, createContext, useEffect, useState } from 'react';

import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import api from 'src/models/service/api';
import ms from 'ms';
import { sleep } from 'src/utils/sleep';

interface FeatureStoreVariablesContextTypes {
  isOpen: boolean;
  canDeleteElement: boolean;
  total: number;
  isError: boolean;
  isLoading: boolean;
  open: () => void;
  close: () => void;
}

type Translate = {
  'en-us'?: string;
  'pt-br'?: string;
};

export interface FSVariables {
  indicator: {
    code: string;
    short_name: Translate;
    is_active: boolean;
  };
  serie: {
    region: Translate;
    aggregation: Translate;
    primary_transformation: Translate;
    second_transformation: Translate;
    tag: string;
    code: string;
  };
}

export interface FeatureStoreVariablesRequest {
  limit: number;
  skip: number;
  total: number;
  variables: FSVariables[];
}

export const FeatureStoreVariablesContext = createContext(
  {} as FeatureStoreVariablesContextTypes,
);

export const FeatureStoreVariablesProvider: React.FC<{
  children: ReactElement;
}> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [total, setTotal] = useState(0);
  const [canDeleteElement, setCanDeleteElement] = useState(true);

  const { id, selectedY } = useSelector((state: RootState) => state.project);

  const { data, isError, isLoading } = useQuery<FeatureStoreVariablesRequest>(
    ['variable-feature-store', id, selectedY?.id, 5, 0],
    async () => {
      const response = await api.get(
        `/projects/${id}/${selectedY?.id}/variables/feature-store?limit=5&skip=0`,
      );

      return response.data;
    },
    {
      enabled: !!id && !!selectedY?.id,
      staleTime: ms('1 day'),
    },
  );

  useEffect(() => {
    setTotal(0);
  }, [id, selectedY?.id]);

  useEffect(() => {
    if (data) {
      if (data.total <= 5 && data.variables.length !== data.total) {
        // Tem caso em que o usuario pode adicionar manualmente as tags da FS
        // na explanatory variable e isso buga pq ele pensa que e uma variavel da FS mas na vdd nao eh
        return;
      }

      if (data.total >= 5 && data.variables.length !== 5) {
        return;
      }

      setTotal(data.total);
    }
  }, [data]);

  function open() {
    setIsOpen(true);
    setCanDeleteElement(false);
  }

  async function close() {
    setIsOpen(false);
    await sleep(1000);
    setCanDeleteElement(true);
  }

  return (
    <FeatureStoreVariablesContext.Provider
      value={{
        isOpen,
        canDeleteElement,
        total,
        isError,
        isLoading,
        open,
        close,
      }}
    >
      {children}
    </FeatureStoreVariablesContext.Provider>
  );
};
