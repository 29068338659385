import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Plus } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/redux/store';
import { Button } from 'src/components/Button';
import {
  changeFilterQuantity,
  deletedAllFilters,
  setHasChanges,
  setInitialFilters,
} from 'src/models/redux/reducers/DependentVariablesConfig';
import api from 'src/models/service/api';
import { Y, changeFilters, changeY } from 'src/models/redux/reducers/Project';
import { FailedModal } from 'src/components/Modal/Failed';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';

import { ButtonsContainer, Container, FiltersContainer } from './styles';
import { ConfigFilters } from '../ConfigFilters';
import {
  FilterConfigProps,
  FiltersProps,
  OptionsConfigProps,
  YFiltersProps,
  YIdDictProps,
} from './types';

export const CreateFilters: React.FC = () => {
  const [yIdLabel, setYIdLabel] = useState<YIdDictProps>({});
  const [yIdStatus, setYIdStatus] = useState<YIdDictProps>({});

  const [saveLoading, setSaveLoading] = useState(false);
  const [saveError, setSaveError] = useState(false);

  const {
    project,
    dependentVariablesConfig: {
      filters,
      quantity,
      deletedAllFilters: allFiltersAreDeleted,
      hasChanges,
    },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const { t: translate } = useTranslation();

  useEffect(() => {
    if (project?.y?.length) {
      const idLabel: YIdDictProps = {};
      const idStatus: YIdDictProps = {};

      project.y.forEach(({ id, label, status }) => {
        idLabel[id] = label;
        idStatus[id] = status;
      });

      setYIdLabel(idLabel);
      setYIdStatus(idStatus);
    }
  }, [project]);

  const filterNamesIsValid = (
    name1?: string,
    name2?: string,
    name3?: string,
  ) => {
    if (name3 && name2 && name1) {
      return (
        name3 !== name2 &&
        name3 !== name1 &&
        name2 !== name1 &&
        name1.length <= 50 &&
        name2.length <= 50 &&
        name3.length <= 50
      );
    }

    if (name2 && name1) {
      return name2 !== name1 && name1.length <= 50 && name2.length <= 50;
    }

    if (name1) {
      return name1.length <= 50;
    }

    return false;
  };

  const formatFilter = (filter: FilterConfigProps) => ({
    name: filter.name,
    options: filter.options.map((option) => option.name),
  });

  const formatYFilters = (
    options: OptionsConfigProps,
    yFilters: YFiltersProps,
    level: 'level_1' | 'level_2' | 'level_3',
    ys: Y[],
  ) => {
    for (const option of options) {
      for (const id of option.yIds) {
        if (level === 'level_1') {
          yFilters[id] = {
            level_1: option.name,
          };
        } else {
          yFilters[id] = {
            ...yFilters[id],
            [level]: option.name,
          };
        }
      }
    }

    let hasNotDefinedOption = false;

    const updatedYs = ys.map((y) => {
      const newY = { ...y };

      if (!yFilters[y.id]) {
        const option = { level_1: translate('createFiltersNotDefined') };

        yFilters[y.id] = option;
        newY.filters = option;

        hasNotDefinedOption = true;
      } else if (!yFilters[y.id][level]) {
        const option = { [level]: translate('createFiltersNotDefined') };

        yFilters[y.id] = { ...yFilters[y.id], ...option };
        newY.filters = { ...newY.filters, ...option };

        hasNotDefinedOption = true;
      } else {
        newY.filters = {
          ...newY.filters,
          [level]: yFilters[y.id][level],
        };
      }

      return newY;
    });

    return { yFilters, ys: updatedYs, hasNotDefinedOption };
  };

  const optionsAreValid = () => {
    const { level1, level2, level3 } = filters;

    for (const option of level1.options) {
      if (option.error.message) {
        return false;
      }
    }

    for (const option of level2.options) {
      if (option.error.message) {
        return false;
      }
    }

    for (const option of level3.options) {
      if (option.error.message) {
        return false;
      }
    }

    return true;
  };

  const handleSaveConfig = async () => {
    const { level1, level2, level3 } = filters;

    const finalFilters: FiltersProps = {} as FiltersProps;
    let yFilters: YFiltersProps = {};
    let updatedY: Y[] = [...project.y];

    if (
      (filterNamesIsValid(level1.name, level2.name, level3.name) &&
        optionsAreValid()) ||
      allFiltersAreDeleted
    ) {
      setSaveLoading(true);
      setSaveError(false);

      if (allFiltersAreDeleted) {
        updatedY = updatedY.map((y) => ({ ...y, filters: {} }));
      } else {
        finalFilters.level_1 = formatFilter(level1 as FilterConfigProps);
        const response = formatYFilters(
          level1.options,
          yFilters,
          'level_1',
          updatedY,
        );

        yFilters = response.yFilters;
        updatedY = response.ys;

        if (
          response.hasNotDefinedOption &&
          !finalFilters.level_1.options.includes(
            translate('createFiltersNotDefined'),
          )
        ) {
          finalFilters.level_1.options.push(
            translate('createFiltersNotDefined'),
          );
        }

        if (level2.name !== undefined) {
          finalFilters.level_2 = formatFilter(level2 as FilterConfigProps);
          const resp = formatYFilters(
            level2.options,
            yFilters,
            'level_2',
            updatedY,
          );

          yFilters = resp.yFilters;
          updatedY = resp.ys;

          if (
            resp.hasNotDefinedOption &&
            !finalFilters.level_2.options.includes(
              translate('createFiltersNotDefined'),
            )
          ) {
            finalFilters.level_2.options.push(
              translate('createFiltersNotDefined'),
            );
          }
        }
        if (level3.name !== undefined) {
          finalFilters.level_3 = formatFilter(level3 as FilterConfigProps);
          const resp = formatYFilters(
            level3.options,
            yFilters,
            'level_3',
            updatedY,
          );

          yFilters = resp.yFilters;
          updatedY = resp.ys;

          if (
            resp.hasNotDefinedOption &&
            !finalFilters.level_3.options.includes(
              translate('createFiltersNotDefined'),
            )
          ) {
            finalFilters.level_3.options.push(
              translate('createFiltersNotDefined'),
            );
          }
        }
      }

      try {
        await api.put(`/projects/${project.id}/ys/filters`, {
          filters: finalFilters,
          y_filters: yFilters,
        });

        dispatch(changeFilters(finalFilters));
        dispatch(changeY(updatedY));

        setSaveLoading(false);
        dispatch(setHasChanges(false));
      } catch (err) {
        setSaveError(true);
        setSaveLoading(false);

        dispatch(setInitialFilters({ filters: project.filters, y: project.y }));
      }

      dispatch(deletedAllFilters(false));
    }
  };

  useEffect(() => {
    if (allFiltersAreDeleted) {
      handleSaveConfig();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFiltersAreDeleted, dispatch]);

  return (
    <Container data-testid="create-filters">
      {saveLoading && allFiltersAreDeleted ? (
        <ContainerSkeleton data-testid="loading-save-config" />
      ) : (
        <>
          <FiltersContainer>
            {Array.from(Array(quantity).keys()).map((value) => (
              <ConfigFilters
                key={value}
                yIdLabel={yIdLabel}
                yIdStatus={yIdStatus}
                filterLevel={(value + 1) as 1 | 2 | 3}
              />
            ))}
          </FiltersContainer>

          <ButtonsContainer>
            {quantity < 3 && (
              <Button
                buttonType="secondary"
                icon={<Plus size="1.25rem" />}
                onClick={() =>
                  dispatch(changeFilterQuantity({ value: quantity + 1 }))
                }
                data-testid="add-new-filter"
              >
                {translate('createFiltersAddNew')}
              </Button>
            )}
            <Button
              buttonType="primary"
              onClick={handleSaveConfig}
              loading={saveLoading}
              disabled={!hasChanges}
              data-testid="save-filter-config"
            >
              {translate('createFiltersSave')}
            </Button>
          </ButtonsContainer>
        </>
      )}

      <FailedModal
        visible={saveError}
        setVisible={setSaveError}
        errorInfo={{
          title: translate('createFiltersSaveErrorTitle'),
          description: translate('createFiltersSaveErrorDescription'),
        }}
      />
    </Container>
  );
};
