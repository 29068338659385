const modelPerformancePTBRTranslations = {
  modelPerformanceAccuracyTooltip: `A proporção de acertos sobre quantidade total de observações no dataset de treino.<br>“Quantas previsões estão corretas?”`,
  modelPerformancePrecisionTooltip: `A proporção de verdadeiros positivos entre verdadeiros e falsos positivos (também conhecida como valor preditivo positivo).<br>“Quantos elementos selecionados são positivos?”`,
  modelPerformanceRecallTooltip: `A Proporção de verdadeiros positivos sobre verdadeiros positivos e falsos negativos (também conhecido como sensibilidade)<br>“Quantos elementos positivos foram selecionados?”`,
  modelPerformanceROCAUCTooltip: `A área sob a curva característica de operação do receptor. Esta plota a taxa de verdadeiros positivos versus a taxa de falsos positivos (um-vs-resto médio para casos multiclasse). Melhor valor possível é 1.`,
  modelPerformanceF1ScoreTooltip:
    'A média harmônica entre precisão e recall. Melhor valor possível é 1.',
};

export default modelPerformancePTBRTranslations;
