const colors = [
  '#DC83C8',
  '#FF8B8B',
  '#4C94FF',
  '#F27DB4',
  '#ED8936',
  '#F56565',
  '#38A169',
  '#ECC94B',
  '#9F7AEA',
  '#38B2AC',
  '#0BC5EA',
  '#2265C9',
  '#C74F88',
  '#a8224a',
  '#81B3FF',
  '#FFABD3',
  '#D6BCFA',
];

export function getUserColor(index: number): string {
  let chartColor;
  if (index <= 16) {
    chartColor = colors[index];
  } else {
    chartColor = colors[index - Math.floor(index / 17) * 17];
  }
  return chartColor;
}
