import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: stretch;

  z-index: 1000;
  background-color: #fff;

  height: 6rem;
  flex-shrink: 0;

  margin-bottom: 0.5rem;
`;

type ContentProps = {
  isFocus: 'onFocus' | 'outFocus';
};

export const Content = styled.div<ContentProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;

  width: 60vw !important;
  height: 4rem;
  flex-shrink: 0;

  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 0px 8px 32px 0px rgba(113, 128, 150, 0.12);

  box-sizing: content-box;

  ${({ isFocus }) =>
    isFocus === 'onFocus' &&
    css`
      border: 3px solid ${({ theme }) => theme.colors.primary};
    `}

  div {
    border: none;
    font-size: 1rem;
  }

  div:hover {
    border: none;
  }

  div svg {
    width: 1.5rem !important;
    height: 1.5rem !important;

    ${({ isFocus }) =>
      isFocus === 'onFocus' &&
      css`
        color: ${({ theme }) => theme.colors.primary};
      `}
  }

  p {
    padding-left: 1rem;
    transform: translateY(-1rem);
  }

  input {
    font-size: 1.125rem !important;
    font-weight: 500;
    line-height: 1.6875rem !important;
  }
`;

export const AskToChat = styled.div`
  height: 1rem;
  flex-shrink: 0;

  img {
    margin-right: 1.25rem;
    cursor: pointer;

    border-left: 2px solid ${({ theme }) => theme.colors.gray2};
    padding-left: 1.25rem;
  }
`;
