import React, { useContext } from 'react';

import { Card } from 'src/components/Card';
import { useQuery } from 'react-query';
import { AISelectionContext } from 'src/models/contexts/AISelectionContext';
import api from 'src/models/service/api';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { AxiosError } from 'axios';
import { translateSomeMessagesFromBackend } from 'src/i18n';
import { useTranslation } from 'react-i18next';
import { formatCompactNotation } from 'src/utils/numbers/formatCompactNotation';

import {
  AnnualSummaryContainer,
  ContentTable,
  Legend,
  TdLabel,
  TdValue,
  Th,
} from './styles';

interface Error {
  detail?: {
    description?: string;
    detail?: string;
  };
}

interface Chart {
  date: string[] | number[];
  value: number[];
}

interface ResponseSummary {
  forecast: Chart;
  historical: Chart;
  simulated: Chart;
}

export const AnnualSummary: React.FC = () => {
  const {
    project: { id, selectedY },
    auth: { user },
  } = useSelector((state: RootState) => state);

  const { listOfVariables, isLoadingListOfVariables, errorListOfVariables } =
    useContext(AISelectionContext);

  const { t: translate } = useTranslation();

  const {
    data: dataLevel,
    isLoading: isLoadingLevel,
    isFetching: isFetchingLevel,
    isError: isErrorLevel,
    error: errorLevel,
  } = useQuery<ResponseSummary, AxiosError<Error>>(
    ['AI-selection', 'annual-summary', id, selectedY?.id, 'level'],
    async () => {
      const response = await api.get<ResponseSummary>(
        `/projects/${id}/${selectedY?.id}/models/business/variables/${listOfVariables?.y}?frequency=yearly&transformation=level`,
      );

      const historical = response.data.historical;
      const forecast = response.data.forecast;

      return {
        historical,
        forecast,
        simulated: response.data.simulated,
      };
    },
    {
      enabled: !!id && !!selectedY?.id && !!listOfVariables?.y,
      staleTime: 1000 * 60 * 20,
    },
  );

  const {
    data: dataVariation,
    isLoading: isLoadingVariation,
    isFetching: isFetchingVariation,
    isError: isErrorVariation,
  } = useQuery<ResponseSummary, AxiosError<Error>>(
    ['AI-selection', 'annual-summary', id, selectedY?.id, 'variation'],
    async () => {
      const response = await api.get<ResponseSummary>(
        `/projects/${id}/${selectedY?.id}/models/business/variables/${listOfVariables?.y}?frequency=yearly&transformation=variation`,
      );

      const historical = response.data.historical;
      const forecast = response.data.forecast;

      return {
        historical,
        forecast,
        simulated: response.data.simulated,
      };
    },
    {
      enabled: !!id && !!selectedY?.id && !!listOfVariables?.y,
      staleTime: 1000 * 60 * 20,
    },
  );

  function returnMessageError() {
    let messageError = errorLevel?.response?.data?.detail?.detail;

    if (
      messageError ===
      'Annual series summary is only available for series with at least 1 year of observation.'
    ) {
      return messageError;
    }

    messageError =
      errorListOfVariables?.response?.data?.detail?.description ??
      errorLevel?.response?.data?.detail?.description;

    if (
      messageError === 'No data is available.' ||
      messageError === 'The requested resource does not exist.'
    ) {
      return 'Annual Series Rate is not available for this model';
    }

    return messageError;
  }

  return (
    <AnnualSummaryContainer
      className="containerLinear"
      data-testid="annual-summary-container"
    >
      <Card textCard={translate('AIselectionAnnualSummaryTitle')} />
      {isErrorLevel || errorListOfVariables ? (
        <ContainerMaintenance
          content="chart"
          size="sm"
          text={translateSomeMessagesFromBackend(
            returnMessageError() ?? '',
            user.language,
          )}
        />
      ) : isLoadingListOfVariables ||
        isLoadingLevel ||
        isFetchingLevel ||
        !dataLevel ? (
        // eslint-disable-next-line prettier/prettier, react/jsx-indent
        <ContainerSkeleton
          style={{ height: '180px' }}
          data-testid="annual-summary-container-loading"
        />
      ) : (
        <>
          <ContentTable>
            <table>
              <thead>
                <tr>
                  <Th isProjection={false}>{}</Th>
                  {dataLevel.historical.date.map((year) => (
                    <Th isProjection={false} key={`annual-summary-th-${year}`}>
                      {year}
                    </Th>
                  ))}
                  {dataLevel.forecast.date.map((year) => (
                    <Th isProjection key={`annual-summary-th-${year}`}>
                      {year}
                    </Th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <TdLabel>
                    {translate('AIselectionAnnualSummaryLevel')}
                  </TdLabel>
                  {dataLevel.historical.value.map((value, index) => (
                    <TdValue
                      isProjection={false}
                      key={`annual-summary-level-td-${value}-${index.toString()}`}
                    >
                      <div>
                        <span>{formatCompactNotation(value)}</span>
                      </div>
                    </TdValue>
                  ))}
                  {dataLevel.forecast.value.map((value, index) => (
                    <TdValue
                      isProjection
                      key={`annual-summary-level-td-${value}-${index.toString()}`}
                    >
                      <div>
                        <span>{formatCompactNotation(value)}</span>
                      </div>
                    </TdValue>
                  ))}
                </tr>
                {isErrorVariation ? (
                  <tr>
                    <TdLabel>
                      {translate('AIselectionAnnualSummaryVariation')}
                    </TdLabel>
                    {Array.from(
                      { length: dataLevel.historical.value.length },
                      (_, index) => (
                        <TdValue
                          isProjection={false}
                          key={`annual-summary-variation-historical-td-error-${index}`}
                        >
                          <div>
                            <span>--</span>
                          </div>
                        </TdValue>
                      ),
                    )}

                    {Array.from(
                      { length: dataLevel.forecast.value.length },
                      (_, index) => (
                        <TdValue
                          isProjection={false}
                          key={`annual-summary-variation-forecast-td-error-${index}`}
                        >
                          <div>
                            <span>--</span>
                          </div>
                        </TdValue>
                      ),
                    )}
                  </tr>
                ) : isLoadingVariation ||
                  isFetchingVariation ||
                  !dataVariation ? (
                  // eslint-disable-next-line react/jsx-indent
                  <tr>
                    {Array.from(
                      {
                        length:
                          dataLevel.historical.value.length +
                          dataLevel.forecast.value.length +
                          1,
                      },
                      (_, index) => (
                        <TdValue
                          isProjection={false}
                          key={`annual-summary-variation-td-loading-${index}`}
                        >
                          <ContainerSkeleton
                            withLoading={false}
                            style={{ width: '65px', height: '28px' }}
                          />
                        </TdValue>
                      ),
                    )}
                  </tr>
                ) : (
                  <tr>
                    <TdLabel>
                      {translate('AIselectionAnnualSummaryVariation')}
                    </TdLabel>
                    {dataVariation.historical.date.length !==
                      dataLevel.historical.date.length && (
                      <TdValue isProjection={false}>
                        <div>
                          <span>--</span>
                        </div>
                      </TdValue>
                    )}
                    {dataVariation.historical.value.map((value, index) => (
                      <TdValue
                        isProjection={false}
                        key={`annual-summary-variation-td-${value}-${index.toString()}`}
                      >
                        <div>
                          <span>{formatCompactNotation(value)}%</span>
                        </div>
                      </TdValue>
                    ))}
                    {dataVariation.forecast.date.length !==
                      dataLevel.forecast.date.length && (
                      <TdValue isProjection>
                        <div>
                          <span>--</span>
                        </div>
                      </TdValue>
                    )}
                    {dataVariation.forecast.value.map((value, index) => (
                      <TdValue
                        isProjection
                        key={`annual-summary-variation-td-${value}-${index.toString()}`}
                      >
                        <div>
                          <span>{formatCompactNotation(value)}%</span>
                        </div>
                      </TdValue>
                    ))}
                  </tr>
                )}
              </tbody>
            </table>
          </ContentTable>
          <Legend>
            {!!dataLevel?.historical?.date?.length && (
              <div data-testid="historical-legend">
                <div />
                <span>{translate('AIselectionAnnualSummaryHistorical')}</span>
              </div>
            )}

            {!!dataLevel?.forecast?.date?.length && (
              <div data-testid="forecast-legend">
                <div />
                <span>{translate('AIselectionAnnualSummaryForecast')}</span>
              </div>
            )}
          </Legend>
        </>
      )}
    </AnnualSummaryContainer>
  );
};
