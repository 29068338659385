import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { X } from 'phosphor-react';
import { Modal } from 'src/components/Modal';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import { Button } from 'src/components/Button';
import { Card } from 'src/components/Card';

import { TutorialProps } from './types';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Content, FooterContent, Page } from './styles';
import { Step3 } from './Step3';
import { Step4 } from './Step4';
import { Step5 } from './Step5';
import { Step6 } from './Step6';

export const Tutorial: React.FC<TutorialProps> = ({
  visible,
  setVisible,
  showLastPage = true,
}) => {
  const [step, setStep] = useState(1);

  const { t: translate } = useTranslation();

  const stepContent = () => {
    if (step === 1) return <Step1 />;
    if (step === 2) return <Step2 />;
    if (step === 3) return <Step3 />;
    if (step === 4) return <Step4 />;
    if (step === 5) return <Step5 />;

    return <Step6 />;
  };

  useEffect(() => {
    const content = document.getElementById('config-tutorial-content');

    if (content) {
      content.scrollTo({ top: 0 });
    }
  }, [step]);

  useEffect(() => {
    setStep(1);
  }, [visible]);

  const lastPageNumber = showLastPage ? 6 : 5;

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      hasFooter
      style={{ width: '41rem', height: '31rem' }}
    >
      <Content
        id="config-tutorial-content"
        data-testid={
          visible ? 'config-tutorial-content' : 'config-tutorial-content-closed'
        }
      >
        <Card
          textCard={translate(`tutorialTitleStep${step}`)}
          textDescription={
            step !== 2 ? translate(`tutorialDescriptionStep${step}`) : undefined
          }
        />

        {step !== 1 && step !== lastPageNumber && (
          <Button
            buttonType="naked"
            onClick={() => setVisible(false)}
            className="tutorial-close-button"
            data-testid="tutorial-close-button"
          >
            <X />
          </Button>
        )}

        {stepContent()}
      </Content>

      <ModalFooter>
        <FooterContent showPage={step !== 1}>
          {step !== 1 && (
            <Page data-testid="tutorial-page">
              {step - 1} {translate('tutorialOf')} {lastPageNumber - 1}
            </Page>
          )}

          <div>
            <Button
              buttonType="naked"
              onClick={() => {
                if (step === 1) setVisible(false);
                else setStep(step - 1);
              }}
              data-testid={`tutorial-${step === 1 ? 'cancel' : 'back'}-button`}
            >
              {step === 1 ? translate('cancel') : translate('back')}
            </Button>

            <Button
              buttonType="primary"
              onClick={() => {
                if (step === lastPageNumber) setVisible(false);
                else setStep(step + 1);
              }}
              data-testid={`tutorial-${
                step === lastPageNumber
                  ? 'finish'
                  : step === 1
                  ? 'start'
                  : 'next'
              }-button`}
            >
              {step === lastPageNumber
                ? translate('tutorialEnd')
                : step === 1
                ? translate('tutorialStart')
                : translate('next')}
            </Button>
          </div>
        </FooterContent>
      </ModalFooter>
    </Modal>
  );
};
