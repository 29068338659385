const modelCrossValidationENTranslations = {
  crossValidationTitle: 'Cross Validation',
  crossValidationDescription:
    'Examine model accuracy by analyzing predictions in multiple testing windows.',
  modelInfoTitle: 'Model Info',
  modelInfoForecastHorizonTitle: 'Forecast horizon',
  modelInfoTestWindowsTitle: 'Test windows',
  modelInfoMAPETitle: 'MAPE',
  modelInfoWMAPETitle: 'WMAPE',
  modelInfoMPETitle: 'MPE',
  modelInfoRMSETitle: 'RMSE',
  modelInfoMASETitle: 'MASE',
  modelInfoMASEsTitle: 'MASEs',
  crossValidationWindowsTitle: 'Cross Validation Windows',
  crossValidationWindowsLegendActual: 'Actual',
  crossValidationWindowsLegendForecast: 'Forecast',
  crossValidationPercentageErrorMetrics: 'Percentage Error Metrics',
  crossValidationScaledErrorMetrics: 'Scaled Error Metrics',
  crossValidationXAXISTitleWindow: 'Window',
  crossValidationMeanPercMPE: 'Mean Percentage Error (MPE)',
  crossValidationLevelErrorMetric: 'Level Error Metric',
  crossValidationMeansAbsoluteScaledMASE: 'Mean Absolute Scaled Error (MASE)',
  crossValidationMetrics: 'Metrics',
  crossValidationPercentageErrorMetricsDescription:
    'Metrics that represent the percent deviation of forecasts from observed values, providing distinct insights based on the calculation method.',
  crossValidationScaledErrorMetricsDescription:
    "Scaled error metrics compare the model's accuracy with a baseline model, and are scale independent: 1 indicates that the model performs equally, while a value less than 1 means it outperforms the baseline.",
  crossValidationLevelErrorMetricDescription:
    'A metric expressed in the same unit as the predicted and observed values, which means that comparing the values across datasets with different scales may be misleading.',
  crossValidationWindowsDescription:
    'Actual and forecast values for each of the cross validation windows, including the accuracy measures.',
};

export default modelCrossValidationENTranslations;
