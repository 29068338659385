import styled from 'styled-components';

export const ContainerModalEditColumns = styled.div`
  padding: 1.5rem 1.5rem 0;
  width: 27.563rem;
  > h4 {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
    color: ${({ theme }) => theme.colors.gray6};
    margin-bottom: 0.5rem;
  }
  > p {
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray5};
    margin-bottom: 1.5rem;
  }
`;

export const PinDependentVariable = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;

  svg {
    color: ${({ theme }) => theme.colors.gray4};
  }
  p {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.188rem;
    color: ${({ theme }) => theme.colors.gray4};
  }
`;

export const ContentCheckBoxes = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  > label {
    width: min-content;
  }
`;
