import React from 'react';

import { useTranslation } from 'react-i18next';
import { WarningModal } from 'src/components/Modal/Warning';

import { NoPermissionToEditModalProps } from './types';

export const NoPermissionToEditModal: React.FC<
  NoPermissionToEditModalProps
> = ({ setVisible, emailEditing, errorDescription }) => {
  const { t: translate } = useTranslation();

  return (
    <WarningModal
      visible
      setVisible={setVisible}
      errorInfo={{
        description:
          errorDescription ??
          translate('workspaceControlPanelEditionErrorDescription').replace(
            'XXX',
            emailEditing,
          ),
      }}
    />
  );
};
