import React, { useEffect, useMemo, useState } from 'react';

import { Annotations } from 'plotly.js';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Card } from 'src/components/Card';
import { ChartPloty } from 'src/components/ChartPloty';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Head } from 'src/components/Head';
import { Select } from 'src/components/Select';
import api from 'src/models/service/api';
import { RootState } from 'src/redux/store';
import light from 'src/styles/themes/light';
import { getTextWidth } from 'src/utils/getTextWidth';
import { truncateStrings } from 'src/utils/strings/truncateStrings';

import {
  Container,
  ContainerChartBetaView,
  ContainerWhite,
  ContenChartBetaView,
  LegendBetaView,
  XAXIX,
  YAXIX,
} from '../../styles';
import { BetaView } from '../types';
import { ForecastDispersion } from '../components/ForecastDispersion';

const font = '400 12px IBM Plex Sans';

interface WindowScreen {
  height: number | undefined;
  width: number | undefined;
}

export const ARIMAModelDispersion: React.FC = () => {
  const { project, sideBar } = useSelector((state: RootState) => state);
  const [selectQuantityBetaView, setSelectQuantityBetaView] =
    useState<number>();

  const [windowSize, setWindowSize] = useState<WindowScreen>({
    width: undefined,
    height: undefined,
  });

  const { t: translate } = useTranslation();

  const {
    data: dataBetaView,
    isLoading: isLoadingBetaView,
    isFetching: isFetchingBetaView,
    isError: isErrorBetaView,
  } = useQuery(
    [
      'arima model dispersion beta view',
      project.id,
      project.selectedY?.id,
      selectQuantityBetaView,
    ],
    async () => {
      const { data } = await api.get<BetaView>(
        `/projects/${project.id}/${project.selectedY?.id}/models/betaview/${selectQuantityBetaView}`,
      );

      return data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled:
        !!project.id &&
        !!project.selectedY?.id &&
        !!project.model &&
        !!selectQuantityBetaView,
    },
  );

  const quantityModels = useMemo(
    () =>
      project?.model?.model_list.find((model) => model.type === 'ARIMA')
        ?.quantity ?? 1,
    [project],
  );

  useEffect(() => {
    if (quantityModels > 10) {
      setSelectQuantityBetaView(10);
    } else {
      setSelectQuantityBetaView(quantityModels);
    }
  }, [quantityModels]);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const widthBiggestVariableY = useMemo(() => {
    let widthVariables = 0;
    if (dataBetaView?.variables.quantity) {
      widthVariables = truncateStrings(dataBetaView.variables.y)
        .map((word) => getTextWidth(word, font))
        .reduce((widthBiggestWord, word) => {
          if (widthBiggestWord < word) {
            return word;
          }
          return widthBiggestWord;
        });
    }

    let widthAccuracy = 0;
    if (dataBetaView?.accuracy.quantity) {
      widthAccuracy = truncateStrings(dataBetaView.accuracy.y)
        .map((word) => getTextWidth(word, font))
        .reduce((widthBiggestWord, word) => {
          if (widthBiggestWord < word) {
            return word;
          }
          return widthBiggestWord;
        });
    }

    if (widthVariables >= widthAccuracy) {
      return widthVariables;
    }

    return widthAccuracy;
  }, [dataBetaView]);

  const widthBiggestVariableX = useMemo(() => {
    let widthVariables = 0;
    if (dataBetaView?.variables.quantity) {
      const array = Array.from(
        { length: dataBetaView.variables.quantity },
        (_, number) => (number + 1).toString(),
      );
      widthVariables = array
        .map((word) => getTextWidth(word.toString(), font))
        .reduce((widthBiggestWord, word) => {
          if (widthBiggestWord < word) {
            return word;
          }
          return widthBiggestWord;
        });
    }

    let widthAccuracy = 0;
    if (dataBetaView?.accuracy.quantity) {
      const array = Array.from(
        { length: dataBetaView.accuracy.quantity },
        (_, number) => (number + 1).toString(),
      );
      widthAccuracy = array
        .map((word) => getTextWidth(word.toString(), font))
        .reduce((widthBiggestWord, word) => {
          if (widthBiggestWord < word) {
            return word;
          }
          return widthBiggestWord;
        });
    }

    if (widthVariables >= widthAccuracy) {
      return widthVariables;
    }

    return widthAccuracy;
  }, [dataBetaView]);

  const width = useMemo(() => {
    const widthAux = windowSize.width ?? window.screen.width;
    let size = widthAux - 430 - Number(widthBiggestVariableY);

    size += sideBar.isExpanded ? 0 : 7 * 16 + 38;

    if (!dataBetaView?.variables.quantity && !dataBetaView?.accuracy.quantity) {
      return size;
    }

    if (dataBetaView?.variables.quantity * 40 > size) {
      return dataBetaView?.variables.quantity * 40;
    }

    if (dataBetaView?.accuracy.quantity * 40 > size) {
      return dataBetaView?.accuracy.quantity * 40;
    }

    return size;
  }, [dataBetaView, widthBiggestVariableY, windowSize.width, sideBar]);

  const height1 =
    dataBetaView?.variables.y.length === 1
      ? 72
      : (dataBetaView?.variables.y.length ?? 0) * 40;
  const height2 = (dataBetaView?.accuracy.y.length ?? 0) * 40;

  const layout1 = useMemo(() => {
    const layoutAux: Partial<Plotly.Layout> = {
      annotations: [],
      xaxis: {
        visible: false,
      },

      yaxis: {
        visible: false,
        autorange: 'reversed',
      },
      margin: {
        t: 0,
        r: 0,
        b: 0,
        l: 0,
        pad: 0,
      },
      width,
    };
    if (dataBetaView) {
      // eslint-disable-next-line no-shadow
      dataBetaView.variables.data.forEach((_, index) => {
        for (let i = 0; i < dataBetaView.variables.quantity; i++) {
          for (let j = 0; j < dataBetaView.variables?.y.length; j++) {
            if (dataBetaView.variables.data[index].coef[j][i] !== 'null') {
              let numberString =
                dataBetaView.variables.data[index].coef[j][i].toString();
              if (
                Number(dataBetaView.variables.data[index].coef[j][i]) >=
                  1000000 ||
                Number(dataBetaView.variables.data[index].coef[j][i]) <=
                  -1000000
              ) {
                if (
                  Math.floor(
                    Number(dataBetaView.variables.data[index].coef[j][i]) /
                      1000000,
                  ).toString().length > 2
                ) {
                  numberString = `${(
                    Number(dataBetaView.variables.data[index].coef[j][i]) /
                    1000000
                  ).toFixed(0)}M`;
                } else {
                  numberString = `${(
                    Number(dataBetaView.variables.data[index].coef[j][i]) /
                    1000000
                  ).toFixed(1)}M`;
                }
              } else if (
                Number(dataBetaView.variables.data[index].coef[j][i]) >= 1000 ||
                Number(dataBetaView.variables.data[index].coef[j][i]) <= -1000
              ) {
                if (
                  Math.floor(
                    Number(dataBetaView.variables.data[index].coef[j][i]) /
                      1000,
                  ).toString().length > 2
                ) {
                  numberString = `${(
                    Number(dataBetaView.variables.data[index].coef[j][i]) / 1000
                  ).toFixed(0)}K`;
                } else {
                  numberString = `${(
                    Number(dataBetaView.variables.data[index].coef[j][i]) / 1000
                  ).toFixed(1)}K`;
                }
              } else if (
                Number(dataBetaView.variables.data[index].coef[j][i]) > -1000 &&
                Number(dataBetaView.variables.data[index].coef[j][i]) < 0
              ) {
                numberString = `${Number(
                  dataBetaView.variables.data[index].coef[j][i],
                )}`;
              } else if (
                Number(dataBetaView.variables.data[index].coef[j][i]) >= 100
              ) {
                numberString = `${Number(
                  dataBetaView.variables.data[index].coef[j][i],
                ).toFixed(1)}`;
              }

              const result: Partial<Annotations> = {
                xref: 'x',
                yref: 'y',
                x: i + 1,
                y: dataBetaView.variables.y[j],
                text: numberString,
                showarrow: false,
                font: {
                  size: 12,
                  color: light.colors.white,
                },
                hoverlabel: {
                  align: 'left',
                },
                hovertext:
                  `${translate(
                    'arimaDispersionModelLabelPanelDeterminants',
                  )}  ${i + 1}<br>` +
                  `${translate(
                    'arimaDispersionVariableLabelPanelDeterminants',
                  )}  ${dataBetaView.variables.y[j]}<br>` +
                  `${translate(
                    'arimaDispersionValue',
                  )} ${dataBetaView.variables.data[index].coef[j][
                    i
                  ]?.toString()}<br>` +
                  `${translate(
                    'arimaDispersionSignificanceLabelPanelDeterminants',
                  )}  ${dataBetaView.variables.data[index].significance[j][
                    i
                  ]?.toString()}<br>`,
              };
              layoutAux.annotations?.push(result);
            }
          }
        }
      });
    }

    return layoutAux;
  }, [dataBetaView, width, translate]);

  const layout2 = useMemo(() => {
    const layoutAux: Partial<Plotly.Layout> = {
      annotations: [],
      xaxis: {
        visible: false,
      },
      yaxis: {
        visible: false,
        autorange: 'reversed',
      },
      margin: {
        t: 0,
        r: 0,
        b: 0,
        l: 0,
        pad: 0,
      },
      width,
    };
    if (dataBetaView) {
      for (let i = 0; i < dataBetaView.accuracy.quantity; i++) {
        for (let j = 0; j < dataBetaView.accuracy?.y.length; j++) {
          if (dataBetaView.accuracy.data[j][i]) {
            let numberString = dataBetaView.accuracy.data[j][i].toString();
            if (
              Number(dataBetaView.accuracy.data[j][i]) >= 1000000 ||
              Number(dataBetaView.accuracy.data[j][i]) <= -1000000
            ) {
              if (
                Math.floor(
                  Number(dataBetaView.accuracy.data[j][i]) / 1000000,
                ).toString().length > 2
              ) {
                numberString = `${(
                  Number(dataBetaView.accuracy.data[j][i]) / 1000000
                ).toFixed(0)}M`;
              } else {
                numberString = `${(
                  Number(dataBetaView.accuracy.data[j][i]) / 1000000
                ).toFixed(1)}M`;
              }
            } else if (
              Number(dataBetaView.accuracy.data[j][i]) >= 1000 ||
              Number(dataBetaView.accuracy.data[j][i]) <= -1000
            ) {
              if (
                Math.floor(
                  Number(dataBetaView.accuracy.data[j][i]) / 1000,
                ).toString().length > 2
              ) {
                numberString = `${(
                  Number(dataBetaView.accuracy.data[j][i]) / 1000
                ).toFixed(0)}K`;
              } else {
                numberString = `${(
                  Number(dataBetaView.accuracy.data[j][i]) / 1000
                ).toFixed(1)}K`;
              }
            } else if (
              Number(dataBetaView.accuracy.data[j][i]) > -1000 &&
              Number(dataBetaView.accuracy.data[j][i]) < 0
            ) {
              numberString = `${Number(
                dataBetaView.accuracy.data[j][i],
              ).toFixed(1)}`;
            } else if (Number(dataBetaView.accuracy.data[j][i]) >= 100) {
              numberString = `${Number(
                dataBetaView.accuracy.data[j][i],
              ).toFixed(1)}`;
            }

            const result: Partial<Annotations> = {
              xref: 'x',
              yref: 'y',
              x: i + 1,
              y: dataBetaView.accuracy.y[j],
              text: numberString,
              showarrow: false,
              font: {
                size: 12,
                color: light.colors.gray6,
              },
            };
            layoutAux.annotations?.push(result);
          }
        }
      }
    }

    return layoutAux;
  }, [dataBetaView, width]);

  const defineColor = (type: string): string | undefined => {
    if (type === '0 to 0.01') {
      return '#2265C9';
    }
    if (type === '0.01 to 0.05') {
      return '#4C94FF';
    }
    if (type === '0.05 to 0.10') {
      return '#81B3FF';
    }
    if (type === '0.10 to 0.25') {
      return '#FFABD3';
    }
    if (type === '0.25 to 1') {
      return '#DC82C8';
    }
  };

  return (
    <Container>
      <Head title={translate('arimaDispersionPageTitle')} />
      <Card
        textCard={translate('arimaDispersionTitle')}
        textDescription={translate('arimaDispersionDescription')}
        className="containerLinear"
      />
      <div className="containerLinear">
        <Card
          textCard={translate('arimaDispersionTitlePanelDeterminants')}
          textDescription={translate(
            'arimaDispersionDescriptionPanelDeterminants',
          )}
        />
        {selectQuantityBetaView ? (
          <Select
            label={translate('arimaDispersionSelectLabelPanelDeterminants')}
            options={(() => {
              const quantity =
                project.model?.model_list.find(
                  (model) => model.type === 'ARIMA',
                )?.quantity ?? 1;

              const values = Array.from(
                {
                  length: quantity / 10,
                },
                (_, number) => (number + 1) * 10,
              );

              if (!values.includes(quantity)) {
                values.push(quantity);
              }

              return values.map((value) => ({
                label: value,
                value,
              }));
            })()}
            value={{
              label: selectQuantityBetaView,
              value: selectQuantityBetaView,
            }}
            onChange={(select: any) => setSelectQuantityBetaView(select.value)}
            isDisabled={
              selectQuantityBetaView === 0 ||
              isLoadingBetaView ||
              isFetchingBetaView
            }
            defaultValue={{ value: 1, label: '1' }}
            style={{ marginBottom: '24px', width: '320px', zIndex: 1999 }}
          />
        ) : (
          <Select
            label={translate('arimaDispersionSelectLabelPanelDeterminants')}
            isDisabled
            style={{ marginBottom: '24px', width: '320px', zIndex: 1999 }}
          />
        )}

        {project.projectError ? (
          <ContainerMaintenance
            content="chart"
            data-testid="container-betaview-error"
          />
        ) : !project.model?.model_list ? (
          <ContainerSkeleton data-testid="container-betaview-loading" />
        ) : (
          <>
            {isErrorBetaView ||
            (dataBetaView?.variables.y.length === 0 &&
              dataBetaView?.variables.data.length === 0 &&
              dataBetaView?.accuracy.data.length === 0) ? (
              // eslint-disable-next-line react/jsx-indent
              <ContainerMaintenance
                content="chart"
                text={translate('arimaNoExplanatoryVariableWasFound')}
              />
            ) : isLoadingBetaView || isFetchingBetaView || !dataBetaView ? (
              <ContainerSkeleton data-testid="container-betaview-chart-loading" />
            ) : dataBetaView.variables.y.length !== 0 &&
              dataBetaView.variables.data.length !== 0 ? (
              // eslint-disable-next-line react/jsx-indent
              <>
                <ContainerChartBetaView>
                  <ContenChartBetaView
                    marginLeftScroll={Number(widthBiggestVariableY)}
                    marginBottomScroll={Number(widthBiggestVariableX)}
                    style={{
                      height: window.screen.height * 0.6,
                    }}
                  >
                    <YAXIX
                      heights={[height1, height2]}
                      data-testid="chart-betaview-yaxix"
                    >
                      <div>
                        <p style={{ height: height1 }}>
                          {translate(
                            'arimaDispersionVariablesLabelPanelDeterminants',
                          )}
                        </p>
                        <p style={{ height: height2 }}>
                          {translate(
                            'arimaDispersionMetricsLabelPanelDeterminants',
                          )}
                        </p>
                      </div>

                      <div>
                        <div>
                          {truncateStrings(dataBetaView.variables.y).map(
                            (y) => (
                              <p key={`yaxis-${y}`}>{y}</p>
                            ),
                          )}
                        </div>
                        <div>
                          {truncateStrings(dataBetaView.accuracy.y).map((y) => (
                            <p key={`yaxis-${y}`}>{y}</p>
                          ))}
                        </div>
                      </div>
                    </YAXIX>
                    <div>
                      <ChartPloty
                        data={[
                          {
                            x: Array.from(
                              { length: dataBetaView.variables.quantity },
                              // eslint-disable-next-line no-shadow
                              (_, number) => number + 1,
                            ),
                            // eslint-disable-next-line no-shadow
                            y: dataBetaView.variables.y,
                            z: Array.from(
                              { length: dataBetaView.variables.y.length },
                              () =>
                                Array.from(
                                  { length: dataBetaView.variables.quantity },
                                  () => 1,
                                ),
                            ),
                            colorscale: [
                              [0, light.colors.gray1],
                              [1, light.colors.gray1],
                            ],
                            xgap: 2,
                            ygap: 2,
                            type: 'heatmap',
                            showscale: false,
                            hoverinfo: 'none',
                          },
                          //@ts-expect-error: TS bugando
                          ...dataBetaView.variables.data.map((dataAux) => ({
                            x: Array.from(
                              { length: dataBetaView.variables.quantity },
                              // eslint-disable-next-line no-shadow
                              (_, number) => number + 1,
                            ),
                            name: dataAux.type,
                            y: dataBetaView.variables.y,
                            z: dataAux.coef,
                            opacity: 1,
                            colorscale: [
                              [0, defineColor(dataAux.type)],
                              [1, defineColor(dataAux.type)],
                            ],
                            showscale: false,
                            showlegend: false,
                            xgap: 2,
                            ygap: 2,
                            type: 'heatmap',
                            hoverongaps: false,
                            meta: dataAux.significance,
                            hoverinfo: 'none',
                          })),
                        ]}
                        layout={layout1}
                        style={{ height: `${height1}px` }}
                        config={{ staticPlot: true }}
                        dataCy="chart-betaview-variables"
                      />
                      <ChartPloty
                        data={[
                          {
                            x: Array.from(
                              { length: dataBetaView.accuracy.quantity },
                              // eslint-disable-next-line no-shadow
                              (_, number) => number + 1,
                            ),
                            // eslint-disable-next-line no-shadow
                            y: dataBetaView.accuracy.y,
                            z: Array.from(
                              { length: dataBetaView.accuracy.y.length },
                              () =>
                                Array.from(
                                  { length: dataBetaView.accuracy.quantity },
                                  () => 1,
                                ),
                            ),
                            colorscale: [
                              [0, light.colors.gray1],
                              [1, light.colors.gray1],
                            ],
                            type: 'heatmap',
                            showscale: false,
                            xgap: 2,
                            ygap: 2,
                            hoverinfo: 'none',
                          },
                          {
                            x: Array.from(
                              { length: dataBetaView.accuracy.quantity },
                              //  eslint-disable-next-line no-shadow
                              (_, number) => number + 1,
                            ),
                            y: dataBetaView.accuracy.y,
                            z: dataBetaView.accuracy.data,
                            colorscale: [
                              [0, light.colors.gray1],
                              [1, light.colors.gray1],
                            ],
                            xgap: 2,
                            ygap: 2,
                            showscale: false,
                            showlegend: false,
                            type: 'heatmap',
                            hoverinfo: 'none',
                          },
                        ]}
                        layout={layout2}
                        config={{ staticPlot: true }}
                        style={{
                          marginTop: '48px',
                          height: `${height2}px`,
                        }}
                        dataCy="chart-betaview-accuracy"
                      />
                      <XAXIX
                        style={{ width }}
                        data-testid="chart-betaview-xaxis"
                      >
                        <div>
                          {Array.from(
                            { length: dataBetaView.variables.quantity },
                            // eslint-disable-next-line no-shadow
                            (_, number) => (
                              <p key={`xaxis-${number + 1}`}>{number + 1}</p>
                            ),
                          )}
                        </div>
                        <p>
                          {translate(
                            'arimaDispersionModelsLabelPanelDeterminants',
                          )}
                        </p>
                      </XAXIX>
                    </div>
                  </ContenChartBetaView>
                  <ContainerWhite
                    sizeY={Number(widthBiggestVariableY)}
                    sizeX={Number(widthBiggestVariableX)}
                  />
                </ContainerChartBetaView>
                <LegendBetaView data-testid="chart-betaview-variables-legend">
                  {dataBetaView.variables.data.map((data) => (
                    <div key={data.type}>
                      <div style={{ background: defineColor(data.type) }} />
                      <p>{data.type}</p>
                    </div>
                  ))}
                </LegendBetaView>
              </>
            ) : (
              <ContainerChartBetaView>
                <ContenChartBetaView
                  marginLeftScroll={Number(widthBiggestVariableY)}
                  marginBottomScroll={Number(widthBiggestVariableX)}
                  style={{
                    height: `${height2 + 85}px`,
                  }}
                >
                  <YAXIX
                    heights={[height2, 0]}
                    data-testid="chart-betaview-yaxix"
                  >
                    <div>
                      <p style={{ height: height2 }}>
                        {translate(
                          'arimaDispersionMetricsLabelPanelDeterminants',
                        )}
                      </p>
                    </div>
                    <div>
                      <div>
                        {[...dataBetaView.accuracy.y].reverse().map((y) => (
                          <p key={`yaxis-${y}`}>{y}</p>
                        ))}
                      </div>
                    </div>
                  </YAXIX>
                  <div>
                    <ChartPloty
                      data={[
                        {
                          x: Array.from(
                            { length: dataBetaView.accuracy.quantity },
                            //  eslint-disable-next-line no-shadow
                            (_, number) => number + 1,
                          ),
                          y: dataBetaView.accuracy.y,
                          z: dataBetaView.accuracy.data,
                          colorscale: [
                            [0, light.colors.gray1],
                            [1, light.colors.gray1],
                          ],
                          xgap: 2,
                          ygap: 2,
                          showscale: false,
                          showlegend: false,
                          type: 'heatmap',
                          hoverinfo: 'none',
                        },
                      ]}
                      dataCy="chart-betaview-accuracy"
                      layout={layout2}
                      config={{ staticPlot: true }}
                      style={{ height: `${height2}px` }}
                    />
                    <XAXIX style={{ width }} data-testid="chart-betaview-xaxis">
                      <div>
                        {Array.from(
                          { length: dataBetaView.accuracy.quantity },
                          // eslint-disable-next-line no-shadow
                          (_, number) => (
                            <p key={`xaxis-${number + 1}`}>{number + 1}</p>
                          ),
                        )}
                      </div>
                      <p>
                        {translate(
                          'arimaDispersionModelsLabelPanelDeterminants',
                        )}
                      </p>
                    </XAXIX>
                  </div>
                </ContenChartBetaView>
                <ContainerWhite
                  sizeY={Number(widthBiggestVariableY)}
                  sizeX={Number(widthBiggestVariableX)}
                />
              </ContainerChartBetaView>
            )}
          </>
        )}
      </div>

      <ForecastDispersion
        model="arima"
        quantityModels={quantityModels}
        hasSelect
      />
    </Container>
  );
};
