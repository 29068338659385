import styled from 'styled-components';

export const Container = styled.div`
  > div {
    margin-top: 0.5rem;
  }
`;

type OptionsProps = {
  flexDirection: 'row' | 'column';
};

export const Options = styled.div<OptionsProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  gap: ${({ flexDirection }) => (flexDirection === 'row' ? '1.5rem' : '1rem')};

  .loading-checkbox-option {
    label {
      cursor: progress;
    }
  }
`;
