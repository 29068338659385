import styled from 'styled-components';

interface ContainerProps {
  isOpen: boolean;
}

export const Container = styled.aside<ContainerProps>`
  position: fixed;
  top: 4.625rem;
  left: -22rem;
  z-index: 1002;

  display: flex;
  flex-direction: column;

  @media (max-width: 835px) {
    bottom: 14.1875rem;
  }

  width: 22rem;
  padding: 1.5rem 0;

  background: ${({ theme }) => theme.colors.white};

  border-right: 1px solid ${({ theme }) => theme.colors.gray2};
  box-shadow: 5px 6px 12px 0px rgba(113, 128, 150, 0.16);

  transition: left 1s;

  left: ${({ isOpen }) => (isOpen ? '0rem' : '-23rem')};
`;

export const Header = styled.div`
  padding: 0 1.5rem;

  > h3 {
    font-size: 1.5rem;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.gray6};
    text-wrap: nowrap;
    margin-bottom: 0.5rem;
  }

  > p {
    font-size: 0.875rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gray4};
    line-height: 1.375rem;
    margin-bottom: 2rem;
  }

  > button {
    position: absolute;
    top: 6.25rem;
    right: -1rem;

    width: 2rem;
    height: 2rem;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${({ theme }) => theme.colors.white};

    border: 1px solid ${({ theme }) => theme.colors.gray2};
    border-radius: 100%;

    box-shadow: 5px 6px 12px 0px rgba(113, 128, 150, 0.16);

    color: ${({ theme }) => theme.colors.gray4};
    transition: all 0.2s ease-out;

    &:hover {
      color: ${({ theme }) => theme.colors.gray5};
      border-color: ${({ theme }) => theme.colors.gray3};
    }
  }
`;

export const Content = styled.div`
  padding: 0px 1.5rem;

  overflow-y: auto;
`;

export const Step = styled.div`
  position: relative;

  padding: 1rem 0 1rem 1.5rem;

  &:last-child {
    padding-bottom: 0px;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0rem;
    top: 0px;

    height: 100%;
    width: 1px;
    background: ${({ theme }) => theme.colors.gray2};
  }
`;

export const StepHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  margin-bottom: 1rem;

  &::before {
    content: '';
    position: absolute;
    left: -1.875rem;
    width: 0.75rem;
    height: 0.75rem;
    background: ${({ theme }) => theme.colors.white};
    z-index: 2;
  }

  &::after {
    content: '';
    position: absolute;
    left: -1.625rem;

    width: 0.25rem;
    height: 0.25rem;
    background: ${({ theme }) => theme.colors.gray2};
    border-radius: 50%;
    z-index: 3;
  }

  > h4 {
    color: ${({ theme }) => theme.colors.gray5};
    font-size: 1rem;
    font-weight: 600;
  }
`;

export const DateText = styled.span`
  position: relative;
  display: block;
  color: ${({ theme }) => theme.colors.gray4};
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 8%;
  margin-bottom: 1rem;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    left: -1.875rem;
    width: 0.75rem;
    height: 0.75rem;
    background: ${({ theme }) => theme.colors.white};
    z-index: 2;
  }

  &::after {
    content: '';
    position: absolute;
    top: calc(50%);
    left: -1.625rem;

    transform: translateY(-50%);

    width: 0.25rem;
    height: 0.25rem;
    background: ${({ theme }) => theme.colors.gray2};
    border-radius: 50%;
    z-index: 3;
  }
`;

export const MessageError = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray5};
  line-height: 1.375rem;
`;
