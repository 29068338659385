import React from 'react';

import { AISelectionProvider } from 'src/models/contexts/AISelectionContext';
import { RootState } from 'src/redux/store';
import { useSelector } from 'react-redux';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { useTranslation } from 'react-i18next';

import { AISelectionContainer, ContainerLoadingStatusSuccess } from './styles';
import { Information } from './components/Information';
import { Results } from './components/Results';
import { ExplanatoryVariables } from './components/ExplanatoryVariables';
import { AISelectionExport } from './components/AISelectionExport';
import { PotentialImpacts } from './components/PotentialImpacts';
import { AnnualSummary } from './components/AnnualSummary';

export const AISelection: React.FC = () => {
  const { selectedY, yBusiness } = useSelector(
    (state: RootState) => state.project,
  );
  const yBusinessStatus = yBusiness.find(
    (yBus) => yBus.y === selectedY?.id,
  )?.status;

  const { t: translate } = useTranslation();

  return (
    <AISelectionProvider>
      <AISelectionContainer>
        <Information />
        {yBusinessStatus === 'processing' ? (
          <ContainerLoadingStatusSuccess className="containerLinear">
            <ContainerSkeleton />
            <h4>{translate('AISelectionLoadingTitle')}</h4>
            <p>{translate('AISelectionLoadingDescription')}</p>
          </ContainerLoadingStatusSuccess>
        ) : (
          <>
            <AnnualSummary />
            <Results />
            <PotentialImpacts />
            <ExplanatoryVariables />
            <AISelectionExport />
          </>
        )}
      </AISelectionContainer>
    </AISelectionProvider>
  );
};
