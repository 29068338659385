import styled from 'styled-components';

export const ContentModal = styled.div`
  /* margin: -1.5rem; */
  width: 47rem;
  max-height: 70vh;
  overflow-y: auto;

  .css-4ljt47-MenuList,
  .css-26l3qy-menu {
    z-index: 3000;
  }
`;

export const ContentInputsModal = styled.div`
  padding: 0.5rem 0.5rem 0;
  margin-right: 0.5rem;
  h4 {
    font-size: 1.5rem;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.gray6};
    margin-bottom: 1.5rem;
  }
  > div {
    margin-bottom: 1.5rem;
  }
`;

export const LoadingInputSelect = styled.div`
  p {
    font-weight: 500;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.gray5};
    margin-bottom: 0.5rem;
  }
  > div {
    height: 2.625rem;
  }
`;

export const ContentSelectWithDeleteButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > div {
    width: 100%;
  }

  > button {
    height: 1.25rem;
    margin-left: 1.5rem;
    margin-top: 2.375rem;
    color: ${({ theme }) => theme.colors.gray5};
    transition: 0.2s color;
    &:hover {
      color: ${({ theme }) => theme.colors.gray6};
    }
  }
`;
