const confusionMatrixENUSTranslations = {
  confusionMatrixTitle: 'Confusion Matrix',
  confusionMatrixDescription:
    'Evaluate the performance of your model through its confusion matrix.',
  truePositive: 'True Positive',
  trueNegative: 'True Negative',
  falsePositive: 'False Positive',
  falseNegative: 'False Negative',
  confusionMatrixActual: 'Actual',
  confusionMatrixPredicted: 'Predicted',
  confusionMatrixCount: 'Matrix Displayed Values',
  confusionMatrixPositiveClass: 'Positive Class',
  confusionMatrixPercentage: 'Percentage',
  confusionMatrixMulticlassDisabled:
    'Visualization only available for binary cases.',
};

export default confusionMatrixENUSTranslations;
