const FeatureStorePTBR = {
  featureStoreIntegrationTitle: 'Grupos da Feature Store',
  featureStoreIntegrationTitleDescription:
    'Você pode selecionar um grupo completo ou clicar no grupo para selecionar as séries individualmente.',
  preDefinedTitleTab: 'Favoritos 4i',
  myGroupTitleTab: 'Meus favoritos',
  placeholderInputSearchGroup: 'Procurar grupo',
  errorMessageInput3Caracteres: 'Pesquisa deve ter pelo menos 3 caracteres.',
  errorMessageInputMax30Caracteres:
    'A pesquisa deve ter no máximo 30 caracteres.',
  errorMessageFindAnyGroup: 'Não foi possível encontrar nenhum grupo contendo',
  nameGroup: 'Nome',
  createdAtGroup: 'Criado em',
  lastUpdatedGroup: 'Última atualização',
  textGroups: 'grupos',
  textSeries: 'séries',
  errorLoadMyGroups: 'Não foi possível carregar meu grupos favoritos.',
  errorLoadPreDefinedGroups:
    'Não foi possível carregar os grupos 4i favoritos.',
  noGroupsFound: 'Nenhum grupo encontrado.',
  errorToSendVariablesTitle: 'Erro',
  errorToSendVariablesMessage: 'Erro ao enviar variáveis.',
  pick4MeTitleTab: 'Pick4me',
  pick4Me1StepTitle: 'Escolha automática de features',
  pick4Me1StepDescription:
    'Deixe que nosso algoritmo no estado-da-arte faça o trabalho de escolha de variáveis por você.',
  pick4Me1StepButton: 'Iniciar',
  pick4Me2StepTitle: 'Nosso algoritmo está fazendo sua mágica!',
  pick4Me2StepDescription:
    'Aguarde! Em alguns segundos, iremos recomendar diversas séries que poderão ajudar no seu projeto.',
  FeatureStoreIntegrationColumnName: 'Nome',
  FeatureStoreIntegrationColumnSource: 'Fonte',
  FeatureStoreIntegrationColumnLastUpdated: 'Última atualização',
  FeatureStoreIntegrationColumnStartDate: 'Data Inicial',
  FeatureStoreIntegrationColumnEndDate: 'Data Final',
  FeatureStoreIntegrationColumnRegion: 'Região',
  FeatureStoreIntegrationColumnPrimary: 'Transformação Primária',
  FeatureStoreIntegrationColumnSecondary: 'Transformação Secundária',
  pick4Me3StepColumnProbability: 'Probabilidade',
  pick4Me3StepColumnProbabilityInformation:
    'Confiança da Inteligência Artificial de que uma variável trará informações úteis para seus modelos',
  pick4MeErrorRecommend:
    'Desculpe, não foi encontrado nenhuma variável para ser recomendada.',
  textButtonCancel: 'Cancelar',
  textButtonSendToFaas: 'Enviar variáveis',
  messageInformsIncompatible:
    '*Grupos e séries com frequências incompatíveis com seu conjunto de dados foram desabilitados',
  someVariables: 'Algumas das variáveis',
  messageAboutSeriePremium:
    'são úteis para sua modelagem, porém você não tem acesso.',
  aboutMore: 'Saiba mais.',
};

export default FeatureStorePTBR;
