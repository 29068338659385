import { Button } from 'src/components/Button';
import styled, { css } from 'styled-components';

export const UnderstandingResultText = styled.div`
  padding: 1.5rem;

  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 8px;

  margin-bottom: 1.5rem;

  p {
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray5};
  }

  > div {
    margin-top: 1rem;
    button {
      display: block;
      margin-left: auto;

      height: auto;
      padding: 0;
    }
  }
`;

interface ButtonCopyProps {
  isCopied: boolean;
}

export const ButtonCopy = styled(Button)<ButtonCopyProps>`
  ${({ isCopied }) =>
    isCopied &&
    css`
      color: ${({ theme }) => theme.colors.green4};
      cursor: pointer;
    `}
`;
