import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0.5rem;

  svg {
    width: 3rem;
    height: 3rem;

    color: ${({ theme }) => theme.colors.yellow4};

    margin-bottom: 1.5rem;
  }

  h3 {
    text-align: center;

    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;

    color: ${({ theme }) => theme.colors.gray6};
  }

  p {
    text-align: center;

    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;

    color: ${({ theme }) => theme.colors.gray5};
  }

  h3 + p {
    margin-top: 1rem;
  }
`;
