import React from 'react';

import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { TableV2 } from 'src/components/TableV2';

interface TableLoadingProps {
  actions: number;
}
export const TableLoading: React.FC<TableLoadingProps> = ({ actions }) => (
  <TableV2.Tr data-testid="series-table-loading">
    <TableV2.Td>
      <ContainerSkeleton
        withLoading={false}
        style={{ height: '24px', width: '200px' }}
      />
    </TableV2.Td>
    {Array.from({
      length: 8,
    }).map((_, index) => (
      <TableV2.Td key={`${index + 1}`}>
        <ContainerSkeleton withLoading={false} style={{ height: '24px' }} />
      </TableV2.Td>
    ))}
    <TableV2.Td
      style={{
        width: '70px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      {Array.from({ length: actions }).map((_, index) => (
        <ContainerSkeleton
          key={`${index + 1}`}
          withLoading={false}
          style={{ width: '20px', height: '20px' }}
        />
      ))}
    </TableV2.Td>
  </TableV2.Tr>
);
