const modellingPTBRTranslations = {
  modelingTitleHeader: 'Criar Projeto',
  backButtons: 'Voltar',
  step1BeginProject: 'Inicie o seu Projeto',
  step1BeginProjectDescr:
    'Comece nomeando seu projeto e selecionando um arquivo de dados para upload',
  step1ProjectIcon: 'Ícone do projeto',
  step1ProjectIconTootlip:
    'Esse ícone vai te ajudar a identificar rapidamente o seu projeto.',
  step1ProjectNameInputLabel: 'Nome do projeto',
  step1ProjectNameInputInfo: 'Não use caracteres especiais',
  step1ProjectNameInputPlaceholder: 'Digite o nome do projeto',
  step1UploadDatasetInputTitle: 'Carregue seu arquivo de dados',
  step1UploadDatasetInputLabel: 'Base de dados',
  step1UploadDatasetInputInfo: `Apenas extensões '.rds', '.xlsx' e '.csv' estão disponíveis.`,
  step1SelectVariablesButton: 'Selecionar variáveis',
  step1IconModalTitle: 'Escolha o ícone do seu projeto',
  step1IconModalDescr:
    'Esse ícone vai te ajudar a identificar rapidamente o seu projeto.',
  step1MessageErrorCouldNotBeOpened: 'O arquivo não pôde ser aberto.',
  step1MessageErrorMustHave1DataVariable:
    'O conjunto de dados deve ter pelo menos 1 variável de data possível',
  step1MessageErrorMustHave1Column1:
    'O conjunto de dados deve ter mais de 1 coluna, uma para data e uma y',
  // Step 2 Texts
  step2SelectVariablesTitle: 'Selecionar Variáveis',
  step2SelectVariablesDescr:
    'Selecione qual variável você deseja projetar, defina o conjunto de variáveis explicativas, e confirme qual série contém informações sobre o período das observações.',
  step2DependentVariableInputLabel: 'Variável resposta',
  step2DependentVariableInputTooltip: 'Qual variável você deseja projetar?',
  step2DateTimeFeatureLabel: 'Variável indicativa de data',
  step2FormatDateSelectLabel: 'Formato',
  step2FormatDateSelectInfo: `Formatos de data:
  <br />yyyy: ano com 4 dígitos (1982)
  <br />MM: mês com 2 dígitos (01)
  <br />dd: dia do mês com 2 dígitos (13)`,
  step2DatasetSample: 'Amostra da base de dados',
  step2SampleFormatted: 'Formatada:',
  step2Frequency: 'Frequência:',
  step2ExplanatoryVariablesLabel: 'Variáveis explicativas',
  step2ExplanatoryVariablesInfo:
    'Variáveis que podem ajudar na previsão da variável dependente',
  step2LagsButton: 'Variáveis Defasadas',
  step2DownloadDatasetButton: 'Baixar conjunto de dados',
  step2FinalSettingsButton: 'Configurações finais',
  step2VisualizeDataTitle: 'Visualizar Dados',
  step2VisualizeDataDescr:
    'Dê uma olhada melhor em seus dados e obtenha os primeiros insights antes de estimar seus modelos.',
  step2DataviewButton: 'Visualizar dados',
  step2EnrichYourDataset: 'Enriqueça seu Conjunto de Dados',
  step2EnrichYourDatasetDescr:
    'Fácil acesso a variáveis pré-selecionadas para ajudar a impulsionar suas projeções e a qualidade geral do modelo.',
  step2OnlyAvailableForMonthlyData: 'Disponível apenas para dados mensais',
  step2FSButton: 'Feature Store',
  step2MessageErrorPleaseAddMoreObservation:
    "Adicione mais observações ao seu conjunto de dados. O número de observações deve ser maior que (de acordo com a frequência) 'diário' -> 180, 'semanal' -> 52, 'quinzenal' -> 24, 'mensal' -> 36, 'bimestral' -> 24, 'trimestral' -> 24, 'semestral' -> 24, 'anual' -> 12.",
  step2MessageSuccessToAddSeriesByFSTitle: 'sucesso',
  step2MessageSuccessToAddSeriesByFSDescription:
    'Todas as séries foram incluídas para serem mescladas com sucesso.',
  step2MessageYouDoNotHaveAccessToTheFeatureStore:
    'Desculpe, você não tem acesso à Feature Store',
  step2MessageErrorTryingToFormat:
    'Erro ao tentar formatar date_var com date_format',
  step2MessageErrorDependentVariableWasRemoved:
    'A variável dependente foi removida do dataset',
  step2LagButtonIsDisabledHover:
    'Disponível quando há pelo menos uma variável explicativa',
  step2MessageErrorInvalidPrefixVariableName:
    "Nome de variável com prefixo inválido ('d4i' ou 'do_')",

  // Step 3 Texts
  step3Title: 'Configurações Finais',
  step3Descr:
    'Temos todas as informações necessárias para iniciar a mágica e gerar ótimas previsões. No entanto, sinta-se à vontade para ajustar o processo de modelagem caso precise de mais controle dos resultados finais.',
  step3ForecastHorLabel: 'Horizonte de projeção',
  step3ForecastHorPlaceholder: 'Digite o número de horizonte de projeção',
  step3ForecastHorInfo:
    'Na validação cruzada, qual horizonte de projeções devemos utilizar? (Se 3, três meses à frente; se 12, doze meses à frente, etc.)',
  step3NWindows:
    'Número de conjuntos de teste de validação cruzada (n_windows)',
  step3NWindowsPlaceholder:
    'Digite o número de janelas de testes no processo de validação cruzada',
  step3NWindowsINfo: `O parâmetro define quantas janelas distintas usaremos para testar previsões fora da amostra para o Horizonte de Projeção definido pelo usuário. Essa definição é um parâmetro chave para avaliação de desempenho, impactando no tempo computacional necessário para completar o processo de modelagem.`,
  step3ForecastWarn: `Recomendamos que 'n_steps + n_windows - 1' não ultrapasse 30% do tamanho dos seus dados, no entanto, ainda aceitamos se esse número for no máximo 50% do tamanho dos seus dados.`,
  step3RecommendedNWindows: 'Número recomendado de janelas:',
  step3ApplyLog: 'Aplicar log?',
  step3ApplyLogInfo:
    'Se SIM, aplicaremos uma transformação logarítmica em todas as variáveis, com exceção de dummies de séries que possuam valores menores ou iguais à zero.',
  step3SeasDummies: 'Adicionar controles para sazonalidade?',
  step3SeasDummiesInfo: `Se SIM, adicionaremos variáveis binárias aos modelos de acordo com a frequência identificada no passo anterior: para uma frequência "m", são adicionadas  "m-1" variáveis binárias.`,
  step3FineTunning: 'Ajustes finos',
  step3ModelingButton: 'Modelar',
  step3ForecastOptimizedPeriod: 'Previsão do Período Otimizado',
  step3CustomPeriod: 'Período personalizado',
  step3AHead: 'à frente',
  step3SelectAPeriod: 'Select a period',
  step3ChartHistorical: 'Histórico',
  step3ChartForecast: 'Projeção',
  step3ChartOptimized: 'Otimizado',
  step3ChartTotal: 'Total',
  step3ModalVariablesWarningTitle: 'Processando dados...',
  step3ModalVariablesWarningErrorToProcessInput: 'Erro ao processar dados',
  step3ModalVariablesWarningRemovingVariables:
    'Removendo variáveis devido a uma alta porcentagem de observações faltantes:',
  step3ModalVariablesWarningVariablesThatMightBeRemoved:
    'Variáveis que podem ser removidas devido à imputação errônea de valores ausentes:',
  step3ModalVariablesWarningNumberOfObservations:
    'Número de observações por período/data.',
  step3ModalVariablesWarningMinimumDataRequirements:
    'Requisito mínimo de dados',
  step3ModalVariablesWarningThereIsMoreThanOneObservation:
    'Há mais de uma observação por período; certifique-se de não ter mais de uma.',
  step3ModalVariablesWarningLaggedVariablesThatMightBeRemoved:
    'Variáveis defasadas que podem ser removidas (devido a pontos de dados insuficientes ou outros problemas de pré-processamento):',
  step3ModalVariablesRemovingRedundantVariables:
    'Removendo variáveis redundantes. Podemos descartá-las com segurança, pois suas informações estão totalmente contidas em outras variáveis:',
  step3ModalVariablesMessageDatasetDoesNotContainForecastPeriod:
    'O dataset não contém um período de previsão. Para obter previsões, a Variável Dependente deve conter valores vazios (NA) para o período de previsão.',
  step3ModalVariablesMessageDatasetDidNotReachRecommendedNumberOfObservations:
    'O dataset não alcançou o número recomendado de observações. Seja cauteloso ao analisar os resultados.',
  step3ModalVariablesSomeExplanatoryVariablesContainMissingValues:
    "Algumas Variáveis Explicativas contêm valores ausentes no período de previsão. Revise o dataset ou defina o ajuste fino 'Projeção automática das Variáveis Explicativas' como 'ON' para utilizá-las na previsão.",

  //Functions Texts
  resetLagsWarning: 'Defasagens removidas!',
  resetLagsWarnDescription:
    'Foi necessário remover sua seleção de variáveis ​​de atraso. Por favor, configure novamente.',
  uploadErrorDescr: 'Erro ao enviar arquivo.',
  invalidDataset: 'Arquivo inválido.',
  sendVariablesError: 'Ocorreu um erro ao enviar variáveis.',
  downloadError: 'Houve um erro ao baixar arquivo.',
  validationError: 'Houve um erro ao validar a base de dados.',
  resetLagsError:
    'Falha ao resetar suas defasagens, por favor tente novamente.',
  modelingErrorTitle: 'Algo deu errado',
  modelingErrorDescr:
    'Infelizmente não foi possível criar seu projeto. Por favor, tente novamente. Se o problema persistir, entre em contato com o suporte.',
  featStoreResetWarnTitle: 'Variáveis da Feature Store foram resetadas! ',
  featStoreResetWarnDescr:
    'Foi necessário resetar suas variáveis da Feature Store. Por favor configure novamente.',

  //Yup Validations
  requiredField: 'Campo obrigatório',
  projectErrorNoSpecialCharacters: 'Caracteres especiais não são permitidos',
  projectErrorFirstCharacter: 'O primeiro caractere deve ser uma letra',
  projectErrorLastCharacter: 'O último caractere não pode ser espaço',
  projectErrorMaxCharacters: 'Não deve ter mais do que 50 caracteres',
  projectErrorMinCharacters: 'Deve conter mais do que 3 caracteres',
  projectErrorValidFileFormat: 'Arquivo valido',
  projectErrorInvalideFileFormat: 'Formato de arquivo inválido',
  projectErrorInvalidFile: 'Arquivo inválido',
  projectErrorDateFormat: 'Formato inválido',
  projectErrorSpecifyNumber: 'Você deve especificar um número',
  projectErrorIntegerNumber: 'O número deve ser inteiro',
  projectErrorMinLags: 'Campo deve ser maior ou igual a 1',
  projectErrorMaxLags: 'Campo deve ser menor ou igual a',
  projectErrorMinNSteps: 'Campo deve ser maior ou igual a 1',
  projectErrorMaxNSteps: 'Campo deve ser menor ou igual a',
  projectErrorMinNWindows: 'Campo deve ser maior ou igual a 1',
  projectErrorMaxNWindows: 'Campo deve ser menor ou igual a',
  projectErrorGoldenVarsMax: 'Você pode escolher no máximo 14 variáveis',
  modellingModalWarmingUpTheEnginesTitle: 'Aquecendo os motores...',
  modellingModalWarmingUpTheEnginesDescription: 'Isso pode demorar um pouco.',
};

export default modellingPTBRTranslations;
