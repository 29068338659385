import React, { useState } from 'react';

import { CaretLeft } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import rocketTakingOff from 'src/assets/rocket_taking_off.svg';
import { Button } from 'src/components/Button';
import { Card } from 'src/components/Card';
import { ErrorObject, FailedModal } from 'src/components/Modal/Failed';
import { ModalLoading } from 'src/components/Modal/Loading';
import api from 'src/models/service/api';
import { queryClient } from 'src/service/queryClient';

import { ContainerComponents } from '../../../styles';
import { ConclusionModal } from '../../../TimeSeries/components/Modal/Conclusion';
import { ProgressInDots } from '../../../TimeSeries/components/ProgressInDots';
import { ContainerItens, ContentButtons } from '../../../TimeSeries/styles';
import { ContainerFineTuning } from './styles';
import { SendModelingProps } from './types';
import { ModalWarningVariables } from '../Modals/ModalWarningVariables';

export const SendModeling: React.FC<SendModelingProps> = ({
  getValues,
  setStep,
  validateWarning,
  setValidateWarning,
}) => {
  const [conclusionModalVisible, setConclusionModalVisible] = useState(false);
  const [failedModalInfo, setFailedModalInfo] = useState<ErrorObject>();
  const [sendModelingRequestIsRunning, setSendModelingRequestIsRunning] =
    useState(false);

  const { t: translate } = useTranslation();

  async function handleSendModeling() {
    setSendModelingRequestIsRunning(true);

    try {
      const { data } = await api.post(
        `/classification/modeling/${getValues('datasetFile').dataset_id}`,
        {},
      );

      data &&
        (setSendModelingRequestIsRunning(false),
        setConclusionModalVisible(true),
        queryClient.removeQueries('projects'));
    } catch {
      setFailedModalInfo({
        title: translate('modelingErrorTitle'),
        description: translate('modelingErrorDescr'),
      });
    }

    setSendModelingRequestIsRunning(false);
  }

  return (
    <>
      <ContainerComponents>
        <Card
          textCard={translate('step3Title')}
          textDescription={translate('claasStep3Description')}
        />
        <ContainerItens>
          <ContainerFineTuning>
            <img src={rocketTakingOff} alt="rocket" />
            <h3>{translate('claasStep3InformativeTitle')}</h3>
            <p>{translate('claasStep3InformativeDescription')}</p>
          </ContainerFineTuning>
        </ContainerItens>
        <ContentButtons>
          <Button
            onClick={() => setStep(2)}
            buttonType="naked"
            data-cy="button-back-to-2"
            data-testid="button-back-to-2"
            disabled={sendModelingRequestIsRunning}
            icon={<CaretLeft size="1.125rem" />}
          >
            {translate('step2SelectVariablesTitle')}
          </Button>
          <ProgressInDots
            step={3}
            backStep1={() => {
              setStep(1);
            }}
            backStep2={() => setStep(2)}
          />
          <Button
            buttonType="primary"
            data-testid="button-send-modeling"
            data-cy="button-modeling"
            disabled={sendModelingRequestIsRunning}
            loading={sendModelingRequestIsRunning}
            onClick={handleSendModeling}
          >
            {translate('claasConclusionSettingsButton')}
          </Button>
        </ContentButtons>
      </ContainerComponents>
      <ModalLoading
        visible={sendModelingRequestIsRunning}
        message={translate('modellingModalWarmingUpTheEnginesTitle')}
        subMessage={translate('modellingModalWarmingUpTheEnginesDescription')}
      />
      {conclusionModalVisible && (
        <ConclusionModal
          visible={conclusionModalVisible}
          setVisible={setConclusionModalVisible}
          title={translate('claasModelingConclusionTitle')}
          description={translate('claasStep3ConclusionDescription')}
          featureSelectionActive={false}
        />
      )}
      <FailedModal
        visible={!!failedModalInfo}
        setVisible={(visible) =>
          visible === false && setFailedModalInfo(undefined)
        }
        errorInfo={failedModalInfo}
      />
      {validateWarning && (
        <ModalWarningVariables
          visible={!!validateWarning?.info?.warning_list}
          title={translate('claasWarningTitle')}
          setVisible={(value: boolean) =>
            value === false && setValidateWarning(undefined)
          }
          text={
            validateWarning?.info?.warning_list?.removed_cols_type?.text &&
            translate('claasRemovedColsTypeWarning')
          }
          variables={
            validateWarning?.info?.warning_list?.removed_cols_type?.variables
          }
          text2={
            validateWarning?.info?.warning_list?.removed_cols_missing?.text &&
            translate('claasRemovedColsMissingWarning')
          }
          variables2={
            validateWarning?.info?.warning_list?.removed_cols_missing?.variables
          }
          text3={
            validateWarning?.info?.warning_list?.removed_rows?.text &&
            translate('claasRemovedRowsWarning')
              .replace(
                '"X"',
                String(validateWarning?.info?.warning_list?.removed_rows?.rows),
              )
              .replace(
                'lines',
                validateWarning?.info?.warning_list?.removed_rows?.rows > 1
                  ? 'lines'
                  : 'line',
              )
              .replace(
                'linhas serão removidas devido aos valores ausentes.',
                validateWarning?.info?.warning_list?.removed_rows?.rows > 1
                  ? 'linhas serão removidas devido aos valores ausentes.'
                  : 'linha será removida devido aos valores ausentes.',
              )
          }
          variables3={[]}
          text4={
            validateWarning?.info?.warning_list?.removed_classes?.text &&
            translate('claasRemovedClassesWarning')
          }
          variables4={
            validateWarning?.info?.warning_list?.removed_classes?.variables
          }
        />
      )}
    </>
  );
};
