import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  margin-bottom: 2rem;

  svg {
    width: 1.125rem;
    height: 1.125rem;
  }

  button {
    height: fit-content;
    width: fit-content;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;

    padding: 0.375rem;

    border-radius: 0.5rem;
    border: 1px solid ${({ theme }) => theme.colors.gray3};

    transition: all 0.2s;

    svg {
      transition: all 0.2s;
      color: ${({ theme }) => theme.colors.gray5};
      margin: 0;
    }

    &:hover:not(:disabled) {
      border: 1px solid ${({ theme }) => `${theme.colors.gray4}CC`};

      svg {
        color: ${({ theme }) => `${theme.colors.gray6}E9`};
      }
    }

    &:disabled {
      border: 1px solid ${({ theme }) => theme.colors.gray2};

      svg {
        color: ${({ theme }) => theme.colors.gray3};
      }
    }
  }
`;

export const CurrentVersionContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  flex: 1;

  padding: 0.5rem 1rem;

  border-radius: 8px;
  background: rgba(129, 179, 255, 0.12);

  svg {
    color: ${({ theme }) => theme.colors.primary};
  }

  p {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: ${({ theme }) => theme.colors.primary};
    text-align: center;
  }

  strong {
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  > div {
    height: 1.125rem;
  }
`;
