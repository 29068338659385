import {
  addMonths,
  format,
  isAfter,
  isBefore,
  parseISO,
  subDays,
  subMonths,
  subYears,
} from 'date-fns';
import { frequencyLatestData } from 'src/utils/charts/getLatestData';

interface ChartData {
  dates: string[];
  values: number[];
  label: string;
}

export const getLatestData = (
  data: ChartData[] | undefined,
  frequency:
    | 'daily'
    | 'weekly'
    | 'fortnightly'
    | 'monthly'
    | 'bimonthly'
    | 'quarterly'
    | 'half-year'
    | 'annual'
    | 'yearly',
): ChartData[] => {
  if (data) {
    const frequencyCount = frequencyLatestData[frequency];

    const latestData = data[0].dates[data[0].dates.length - 1];

    const quantityForecast = data
      .slice(1)
      .reduce((previousValue, currentValue) => {
        if (currentValue.dates.length > previousValue) {
          return currentValue.dates.length;
        }
        return previousValue;
      }, 0);

    let startDate = '';
    let endDate = '';

    const frequencyEnd =
      quantityForecast > frequencyCount / 3
        ? frequencyCount / 3
        : quantityForecast;

    const frequencyStart = frequencyCount - frequencyEnd;

    switch (frequency) {
      case 'daily':
        startDate = format(
          subDays(parseISO(latestData), frequencyStart),
          'yyyy-MM-dd',
        );
        endDate = format(
          subDays(parseISO(latestData), frequencyEnd),
          'yyyy-MM-dd',
        );
        break;
      case 'weekly':
        startDate = format(
          subDays(parseISO(latestData), frequencyStart * 7),
          'yyyy-MM-dd',
        );
        endDate = format(
          addMonths(parseISO(latestData), frequencyEnd * 7),
          'yyyy-MM-dd',
        );
        break;
      case 'fortnightly':
        startDate = format(
          subDays(parseISO(latestData), frequencyStart * 15),
          'yyyy-MM-dd',
        );
        endDate = format(
          addMonths(parseISO(latestData), frequencyEnd * 15),
          'yyyy-MM-dd',
        );
        break;
      case 'monthly':
        startDate = format(
          subMonths(parseISO(latestData), frequencyStart),
          'yyyy-MM-dd',
        );
        endDate = format(
          addMonths(parseISO(latestData), frequencyEnd),
          'yyyy-MM-dd',
        );
        break;
      case 'bimonthly':
        startDate = format(
          subMonths(parseISO(latestData), frequencyStart * 2),
          'yyyy-MM-dd',
        );
        endDate = format(
          addMonths(parseISO(latestData), frequencyEnd * 2),
          'yyyy-MM-dd',
        );
        break;
      case 'quarterly':
        startDate = format(
          subMonths(parseISO(latestData), frequencyStart * 3),
          'yyyy-MM-dd',
        );
        endDate = format(
          addMonths(parseISO(latestData), frequencyEnd * 3),
          'yyyy-MM-dd',
        );
        break;
      case 'half-year':
        startDate = format(
          subMonths(parseISO(latestData), frequencyStart * 6),
          'yyyy-MM-dd',
        );
        endDate = format(
          addMonths(parseISO(latestData), frequencyEnd * 6),
          'yyyy-MM-dd',
        );
        break;
      case 'annual':
        startDate = format(
          subYears(parseISO(latestData), frequencyStart),
          'yyyy-MM-dd',
        );
        endDate = format(
          addMonths(parseISO(latestData), frequencyEnd),
          'yyyy-MM-dd',
        );
        break;
      case 'yearly':
        startDate = format(
          subYears(parseISO(latestData), frequencyStart),
          'yyyy-MM-dd',
        );
        endDate = format(
          addMonths(parseISO(latestData), frequencyEnd),
          'yyyy-MM-dd',
        );
        break;
      default:
        break;
    }

    const result = data.map((info) => {
      let startIndex = -1;
      let endIndex = -1;

      startIndex = info.dates.findIndex((date) =>
        isAfter(parseISO(date), parseISO(startDate)),
      );

      for (let i = info.dates?.length - 1; i > 0 && endIndex === -1; i--) {
        if (isBefore(parseISO(info.dates[i]), parseISO(endDate))) {
          endIndex = i;
        }
      }

      return {
        dates: info.dates.filter(
          (date) =>
            isBefore(parseISO(date), parseISO(endDate)) &&
            isAfter(parseISO(date), parseISO(startDate)),
        ),
        values: isBefore(parseISO(info.dates[0]), parseISO(startDate))
          ? info.values.slice(startIndex, endIndex + 1)
          : info.values,
        label: info.label,
      };
    });

    return result;
  }

  return [];
};
