export const workspaceProjectionsResultsUnderstandingENTranslate = {
  workspaceProjectionsResultsUnderstandingGetInsights: 'Get Insights!',
  workspaceProjectionsResultsUnderstandingTitle: 'Understanding the Result',
  workspaceProjectionsResultsUnderstandingSeeMoreImpacts: 'See more impacts',
  workspaceProjectionsResultsUnderstandingSeeLessImpacts: 'See less impacts',
  workspaceProjectionsResultsUnderstandingWhenYFrequencyIsInvalid:
    'Interpretation of results for this aggregation is only available for data with monthly original data.',
  workspaceProjectionsResultsUnderstandingCopyText: 'Copy text',
  workspaceProjectionsResultsUnderstandingTextCopied: 'Copied!',
  workspaceProjectionsResultsUnderstandingWhenFrequencyIsOriginalLow:
    'Interpretation of results is not available for daily, weekly or fortnightly data.',
  workspaceProjectionsResultsUnderstandingSeeOtherVariables:
    'See other variables',
  workspaceProjectionsResultsUnderstandingInitialValue: 'Initial value',
  workspaceProjectionsResultsUnderstandingTotalValue: 'Total value',
  workspaceProjectionsResultsUnderstandingOthers: 'others',
  workspaceProjectionsResultsUnderstandingCards: 'Cards',
  workspaceProjectionsResultsUnderstandingWaterfall: 'Waterfall',
};
