import React, { useState } from 'react';

import { AxiosError } from 'axios';
import { ArrowUpRight, CaretLeft, CaretRight, Info } from 'phosphor-react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { Card } from 'src/components/Card';
import { Input } from 'src/components/Input';
import { Loading } from 'src/components/Loading';
import { ErrorObject, FailedModal } from 'src/components/Modal/Failed';
import { ModalIcons } from 'src/components/Modal/ModalIcons';
import { DataError } from 'src/interface/axios';
import api from 'src/models/service/api';
import { RootState } from 'src/redux/store';
import { Tooltip } from 'react-tooltip';

import { DocsModal } from '../Modals/DocsModal';
import { regexFirstCharacter, regexInputProjectName } from '../..';
import { ContainerComponents } from '../../../styles';
import { InputFile } from '../../../TimeSeries/components/InputFile';
import { ProgressInDots } from '../../../TimeSeries/components/ProgressInDots';
import {
  ContainerItens,
  ContentButtons,
  ContentIcon,
  ContentInput,
  DivIcon,
  DivLabel,
} from '../../../TimeSeries/styles';
import { DatasetInfo, ClassificationBeginProjectProps } from './types';
import { ForwardButtonsContent, SeeDocsButton } from './styles';

export const ClassificationBeginProject: React.FC<
  ClassificationBeginProjectProps
> = ({
  control,
  getValues,
  setValue,
  errors,
  trigger,
  datasetFile,
  setDatasetFile,
  setStep,
  reset,
}) => {
  const [selectVariablesButtonDisabled, setSelectVariablesButtonDisabled] =
    useState(false);
  const [iconModalVisible, setIconModalVisible] = useState(false);
  const [, setIcon] = useState<string | null>(null);
  const [failedModalInfo, setFailedModalInfo] = useState<ErrorObject>();
  const [docsModalVisible, setDocsModalVisible] = useState(false);

  const { user } = useSelector((state: RootState) => state.auth);

  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  async function handleSubmitDataset(): Promise<void> {
    setSelectVariablesButtonDisabled(true);

    if (
      (
        await Promise.all([
          trigger('projectName'),
          trigger('iconUrl'),
          trigger('file'),
        ])
      ).includes(false)
    ) {
      setSelectVariablesButtonDisabled(false);
      return;
    }

    if (datasetFile && datasetFile.size > 100000000) {
      setSelectVariablesButtonDisabled(false);
      setFailedModalInfo({
        title: translate('claasUploadFileSizeTitle'),
        description: translate('claasUploadFileSizeDescription').replace(
          '"X"',
          '100MB',
        ),
      });

      return;
    }

    try {
      const requestPayload = new FormData();
      datasetFile && requestPayload.append('file', datasetFile);
      requestPayload.append('project_name', getValues('projectName'));
      requestPayload.append('icon_url', getValues('iconUrl'));

      const { data } = await api.post<DatasetInfo>(
        '/classification/upload',
        requestPayload,
      );

      if (data) {
        setValue('datasetFile', {
          dataset_id: data.dataset_id,
          variables: data.variables,
          y_details: data.y_details,
        });

        setStep(2);
      }
    } catch (err) {
      const error: AxiosError<DataError> | any = err;
      const errorMessage =
        error.response?.data?.detail?.detail?.y_options ??
        error.response?.data?.detail?.detail?.x_options ??
        error.response?.data?.detail?.detail ??
        translate('unknownRequestErrorDescription');

      setFailedModalInfo({
        title: translate('unknownRequestErrorTitle'),
        description: errorMessage,
      });
    }

    setSelectVariablesButtonDisabled(false);
  }

  const canUserCreateTimeSeriasAndClaas = (): boolean => {
    if (
      user.isClaaS &&
      user.isFaaS &&
      user.permissions.includes('create:project-claas') &&
      user.permissions.includes('create:projects')
    ) {
      return true;
    }
    return false;
  };

  const redirectToBackPage = () => {
    if (canUserCreateTimeSeriasAndClaas()) {
      navigate('/models/projects/new');
      return;
    }

    navigate('/models/projects');
  };

  return (
    <>
      <ContainerComponents>
        <Card
          textCard={translate('step1BeginProject')}
          textDescription={translate('startProjectClaas')}
        />
        <ContainerItens>
          <ContentIcon>
            <DivLabel>
              <p>{translate('step1ProjectIcon')}</p>
              <Info
                size="1.125rem"
                data-tooltip-id="modelling-classification-step-1"
                data-tooltip-html={translate('step1ProjectIconTootlip')}
              />
            </DivLabel>

            <Tooltip
              id="modelling-classification-step-1"
              className="customTooltipTheme"
            />
            <DivIcon
              data-testid="button-show-icons"
              onClick={() => setIconModalVisible(true)}
            >
              {getValues('iconUrl') ? (
                <img
                  data-testid="selected-icon"
                  src={getValues('iconUrl') ?? ''}
                  alt="icon_selected"
                />
              ) : (
                <Loading style={{ marginTop: '16px' }} />
              )}
            </DivIcon>
          </ContentIcon>
          <ContentInput>
            <Controller
              name="projectName"
              key="projectName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  data-testid="project-name"
                  label={translate('step1ProjectNameInputLabel')}
                  information={translate('step1ProjectNameInputInfo')}
                  placeholder={translate('step1ProjectNameInputPlaceholder')}
                  value={value}
                  onChange={({ target: { value: val } }) => {
                    onChange(val);
                    if (
                      val.length < 3 &&
                      val.match(regexFirstCharacter) &&
                      val.match(regexInputProjectName) &&
                      !errors.projectName?.message
                    ) {
                      return;
                    }
                    trigger('projectName');
                  }}
                  error={translate(errors?.projectName?.message)}
                />
              )}
            />

            <Controller
              name="fileDataSet"
              key="fileDataSet"
              control={control}
              render={({ field: { value } }) => (
                <InputFile
                  label={translate('step1UploadDatasetInputLabel')}
                  title={translate('step1UploadDatasetInputTitle')}
                  description={translate('claasUploadDatasetDescription')}
                  information={translate('claasUploadDatasetInfo')}
                  file={datasetFile}
                  setFile={(file) => setDatasetFile(file)}
                  value={value}
                  fileFormats={['.xlsx', '.csv']}
                  error={translate(
                    errors.file?.message ??
                      errors.fileDataSet?.dataset_id?.message,
                  )}
                  onChange={() => {
                    const auxName = getValues('projectName');
                    const iconAux = getValues('iconUrl');
                    reset();
                    setValue('projectName', auxName);
                    setValue('iconUrl', iconAux);
                  }}
                  isDisabled={selectVariablesButtonDisabled}
                  isClassification
                  style={{ marginTop: '1.5rem' }}
                  dataCy="dataset-input"
                />
              )}
            />
          </ContentInput>
        </ContainerItens>

        <ContentButtons>
          <Button
            buttonType="naked"
            data-testid="button-back"
            data-cy="button-back"
            onClick={() => redirectToBackPage()}
            disabled={selectVariablesButtonDisabled}
            icon={<CaretLeft size="1.125rem" />}
          >
            {canUserCreateTimeSeriasAndClaas()
              ? translate('modellingStartTitle')
              : translate('backButtons')}
          </Button>
          <ProgressInDots step={1} />
          <ForwardButtonsContent>
            <SeeDocsButton
              onClick={() => setDocsModalVisible(true)}
              data-testid="see-docs-button"
              data-cy="see-docs-button"
            >
              <ArrowUpRight size="1.125rem" />
              <p>{translate('claasCallAPIButton')}</p>
            </SeeDocsButton>
            <Button
              buttonType="primary"
              data-testid="button-select-variables"
              data-cy="button-select-variables"
              onClick={handleSubmitDataset}
              loading={selectVariablesButtonDisabled}
              disabled={selectVariablesButtonDisabled}
              iconInvert
              icon={
                !selectVariablesButtonDisabled ? (
                  <CaretRight size="1.125rem" />
                ) : undefined
              }
            >
              {translate('step1SelectVariablesButton')}
            </Button>
          </ForwardButtonsContent>
        </ContentButtons>
      </ContainerComponents>

      <Controller
        name="iconUrl"
        control={control}
        render={({ field: { onChange, value } }) => (
          <ModalIcons
            visible={iconModalVisible}
            setIcon={setIcon}
            setVisible={setIconModalVisible}
            onChangHookForm={onChange}
            value={value}
            content={{
              title: translate('step1IconModalTitle'),
              description: translate('step1IconModalDescr'),
            }}
          />
        )}
      />

      <FailedModal
        visible={!!failedModalInfo}
        setVisible={(visible) =>
          visible === false && setFailedModalInfo(undefined)
        }
        errorInfo={failedModalInfo}
      />

      <DocsModal
        visible={docsModalVisible}
        setVisible={(value) => setDocsModalVisible(value)}
      />
    </>
  );
};
