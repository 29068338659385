import React, { useContext, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Card } from 'src/components/Card';
import { PlanningFlow } from 'src/workspaces/components/PlanningFlow';
import {
  Deadline,
  StepProps,
  StepStatus,
  User,
} from 'src/workspaces/components/PlanningFlow/types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { useQueryReleaseData } from 'src/workspaces/hooks/useQueryReleaseData';
import { Button } from 'src/components/Button';
import {
  CheckCircle,
  ClockCounterClockwise,
  FloppyDisk,
  PencilSimple,
  X,
} from 'phosphor-react';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import {
  changeIsCreatingStep,
  changeIsEditingPlanningFlow,
  changeShowChangeHistory,
  changeWorkspaceOverviewStep,
} from 'src/workspaces/redux/reducers/WorkspaceOverviewOptions';
import { queryClient } from 'src/service/queryClient';
import apiWorkspace from 'src/workspaces/service/api';
import {
  lockEdition,
  unlockEdition,
} from 'src/workspaces/utils/lockUnlockEdition';
import { NewReleaseConfigurationContext } from 'src/workspaces/contexts/NewReleaseConfigurationContext';
import { Modal } from 'src/components/Modal';
import { Status } from 'src/components/Status';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import ms from 'ms';
import { SessionExpiredModal } from 'src/workspaces/components/SessionExpired';
import { NoPermissionToEditModal } from 'src/workspaces/components/NoPermissionToEdit';
import { Tooltip } from 'react-tooltip';

import { EditionModeEnabledModal } from '../Modals/EditionModeEnabled';
import { Container, ButtonsContainer } from './styles';
import { ReviewModeEnabledModal } from '../Modals/ReviewModeEnabled';
import { ActionConfirmationModal } from '../Modals/ActionConfirmation';

export const Planning: React.FC = () => {
  const [showEditionModeEnabledModal, setShowEditionModeEnabledModal] =
    useState(false);
  const [showReviewModeEnabledModal, setShowReviewModeEnabledModal] =
    useState(false);
  const [showUserIsEditingModal, setShowUserIsEditingModal] = useState('');
  const [showWorkspaceIsPublishing, setShowWorkspaceIsPublishing] =
    useState(false);
  const [showPlanningFlowConfiguration, setShowPlanningFlowConfiguration] =
    useState(false);

  const [saveError, setSaveError] = useState(false);
  const [isSavingConfiguration, setIsSavingConfiguration] = useState(false);

  const [loadingEditionLock, setLoadingEditionLock] = useState(false);
  const [editionExpired, setEditionExpired] = useState(false);

  const [showFinalizeVersionConfirmation, setShowFinalizeVersionConfirmation] =
    useState(false);
  const [finalizeVersionError, setFinalizeVersionError] = useState(false);

  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  const {
    workspace,
    workspaceOverviewOptions: {
      showChangeHistory,
      step: selectedStep,
      editionModeEnabled,
      reviewModeEnabled,
    },
  } = useSelector((state: RootState) => state);

  const {
    steps: stepsConfiguration,
    showPlanningErrors,
    handleAddNewStep,
    handleDeleteLastStep,
    handleRenameStep,
    handleAddUser,
    handleRemoveUser,
    handleChangeDeadline,
    updateSteps,
    saveConfiguration,
    setShowPlanningErrors,
  } = useContext(NewReleaseConfigurationContext);

  const { data: releaseData, isLoading: releaseIsLoading } =
    useQueryReleaseData(workspace.id, workspace.releaseSelected?.id);

  async function handleChangeHistory() {
    dispatch(changeShowChangeHistory(true));
  }

  const steps: StepProps[] = useMemo(
    () =>
      releaseData?.data.steps?.map((step) => {
        const stepUsers: User[] = [];

        const userProfiles = step.users?.profiles ?? {};

        const emails = Object.keys(userProfiles);

        emails.forEach((userEmail) => {
          const profiles = userProfiles[userEmail];

          profiles.forEach(({ type, level }) => {
            const user: User = { user: userEmail, profile: type };

            if (type === 'approver') {
              user.level = level;
            }

            stepUsers.push(user);
          });
        });

        return {
          ...step,
          id: step.created,
          users: stepUsers,
        };
      }) ?? [],
    [releaseData?.data.steps],
  );

  const stepsQtty = releaseData?.data.steps
    ? releaseData?.data.steps.length
    : 0;

  const updateStepStatus = async (stepNumber: number, status: StepStatus) => {
    try {
      await apiWorkspace.put(
        `/workspaces/${workspace.id}/releases/${workspace.releaseSelected?.id}/approval-flow/steps/${stepNumber}`,
        { status },
      );
      // eslint-disable-next-line no-empty
    } catch {}

    await queryClient.refetchQueries({
      queryKey: [
        'workspace',
        workspace.id,
        'releases',
        workspace.releaseSelected?.id,
      ],
      exact: true,
    });

    await queryClient.refetchQueries({
      queryKey: [
        'workspace',
        workspace.id,
        'releases',
        workspace.releaseSelected?.id,
        'logs',
        stepNumber,
      ],
    });
  };

  const updateCreatedBaselineStepStatus = async (
    stepNumber: number,
    currentStatus: StepStatus,
    isFirstStep: boolean,
    stepHasBaseline: boolean,
  ) => {
    let status = currentStatus;

    if (isFirstStep && !stepHasBaseline) {
      status = 'baseline';

      await updateStepStatus(stepNumber, 'baseline');
    } else if (!isFirstStep && !stepHasBaseline) {
      status = 'adjusting';

      await updateStepStatus(stepNumber, 'baseline');
      await updateStepStatus(stepNumber, 'adjusting');
    }

    return status;
  };

  const updateSelectedStepToLastStepActive = async () => {
    if (releaseData && releaseData.data.steps) {
      const allSteps = releaseData.data.steps;

      let activeStep = allSteps[allSteps.length - 1];
      let activeStepNumber = allSteps.length;

      for (let i = 0; i < allSteps.length; i++) {
        if (allSteps[i].status === 'created') {
          const previousStep = allSteps[i - 1];

          if (i === 0 || previousStep.status === 'approved') {
            activeStep = allSteps[i];
            activeStepNumber = i + 1;
            break;
          } else {
            activeStep = previousStep;
            activeStepNumber = i;
            break;
          }
        }
      }

      const isFirstStep = activeStepNumber === 1;
      const stepHasBaseline = activeStep.status !== 'created';

      const status = await updateCreatedBaselineStepStatus(
        activeStepNumber,
        activeStep.status,
        isFirstStep,
        stepHasBaseline,
      );

      queryClient.removeQueries([
        'workspace',
        workspace.id,
        'releases',
        workspace?.releaseSelected?.id,
        'logs',
        activeStepNumber,
      ]);

      dispatch(
        changeWorkspaceOverviewStep({
          number: activeStepNumber,
          name: activeStep.name,
          status,
          approval_messages: activeStep.approval_messages ?? [],
          awaiting_approval_messages:
            activeStep.awaiting_approval_messages ?? [],
          disapproval_messages: activeStep.disapproval_messages ?? [],
          selectedStatus: status,
        }),
      );
    }
  };

  const updateSelectedStepStatus = async () => {
    const allSteps = releaseData?.data.steps;

    const currentStepNumber = selectedStep?.number;

    if (currentStepNumber && allSteps?.length) {
      if (currentStepNumber <= allSteps.length) {
        const updatedStep = allSteps[currentStepNumber - 1];

        const isFirstStep = selectedStep.number === 1;
        const stepHasBaseline = selectedStep.status !== 'created';

        const status = await updateCreatedBaselineStepStatus(
          selectedStep.number,
          updatedStep.status,
          isFirstStep,
          stepHasBaseline,
        );

        dispatch(
          changeWorkspaceOverviewStep({
            ...updatedStep,
            number: currentStepNumber,
            status,
            selectedStatus: status,
          }),
        );
      } else {
        updateSelectedStepToLastStepActive();
      }
    }
  };

  const handleAccessStep = async (stepId: string, status: StepStatus) => {
    const stepIndex = steps.findIndex((step) => step.id === stepId);

    if (editionModeEnabled) {
      setShowEditionModeEnabledModal(true);
    } else if (
      selectedStep?.number !== stepIndex + 1 ||
      selectedStep?.selectedStatus !== status
    ) {
      const selectedStepInfo = steps[stepIndex];

      if (status === 'adjusting' && selectedStepInfo.status === 'created') {
        if (stepIndex !== 0) {
          dispatch(changeIsCreatingStep(true));
        }

        await updateStepStatus(stepIndex + 1, 'baseline');
        await updateStepStatus(stepIndex + 1, 'adjusting');
      }

      dispatch(
        changeWorkspaceOverviewStep({
          number: stepIndex + 1,
          name: selectedStepInfo.name,
          status: selectedStepInfo.status,
          approval_messages: [],
          awaiting_approval_messages: [],
          disapproval_messages: [],
          selectedStatus: status,
        }),
      );
    }

    queryClient.refetchQueries([
      'workspace',
      workspace.id,
      'releases',
      workspace.releaseSelected?.id,
      'logs',
      stepIndex + 1,
    ]);
  };

  const canEditPlanningFlow = async () => {
    const { canEdit, error } = await lockEdition(workspace.id ?? '');

    if (canEdit) {
      return true;
    }
    if (error) {
      if (error.isPublishing) {
        setShowWorkspaceIsPublishing(true);
      } else {
        setShowUserIsEditingModal(error.userIsEditing ?? '');
      }

      return false;
    }
  };

  const handleEditPlanningFlow = async () => {
    if (editionModeEnabled) {
      setShowEditionModeEnabledModal(true);

      return;
    }

    if (reviewModeEnabled) {
      setShowReviewModeEnabledModal(true);

      return;
    }

    setLoadingEditionLock(true);

    const canEdit = await canEditPlanningFlow();

    if (canEdit) {
      dispatch(changeIsEditingPlanningFlow(true));
      updateSteps(steps);
      setShowPlanningFlowConfiguration(true);
    }

    setEditionExpired(false);
    setLoadingEditionLock(false);
  };

  const handleCancelEdition = async () => {
    await unlockEdition(workspace.id ?? '');

    setShowPlanningFlowConfiguration(false);
    dispatch(changeIsEditingPlanningFlow(false));
  };

  const stepHasEditorAndApprover = (users: User[]) => {
    let hasEditor = false;
    let hasApprover = false;

    for (let j = 0; j < users.length; j++) {
      if (users[j].profile === 'editor') {
        hasEditor = true;
      } else {
        hasApprover = true;
      }

      if (hasEditor && hasApprover) {
        break;
      }
    }

    return hasEditor && hasApprover;
  };

  const stepDeadlineIsValid = (
    deadlines: Deadline[] | null,
    lastStepDeadlines?: Deadline[] | null,
  ) => {
    const editorDeadline = deadlines?.find(
      ({ type }) => type === 'editor',
    )?.date;
    const approverDeadline = deadlines?.find(
      ({ type }) => type === 'approver',
    )?.date;

    if (!editorDeadline?.end) return false;
    if (!approverDeadline?.start) return false;

    const editorDateTime = new Date(editorDeadline.end.slice(0, 10)).getTime();
    const approverDateTime = new Date(
      approverDeadline.start.slice(0, 10),
    ).getTime();

    if (approverDateTime < editorDateTime) return false;

    if (!lastStepDeadlines?.length) {
      return true;
    }

    const lastApproverDeadline = lastStepDeadlines.find(
      ({ type }) => type === 'approver',
    )?.date;

    if (!lastApproverDeadline?.end) return false;

    const lastApproverDateTime = new Date(
      lastApproverDeadline.end.slice(0, 10),
    ).getTime();

    if (editorDateTime < lastApproverDateTime) return false;

    return true;
  };

  const planningInfoIsValid = () => {
    let isValid = true;

    for (let i = 0; i < stepsConfiguration.length; i++) {
      if (stepsConfiguration[i].status !== 'approved') {
        const lastStepDeadline =
          i === 0 ? undefined : stepsConfiguration[i - 1].deadlines;

        if (
          !stepDeadlineIsValid(
            stepsConfiguration[i].deadlines,
            lastStepDeadline,
          )
        ) {
          isValid = false;
          break;
        }

        const users = stepsConfiguration[i].users;

        if (!stepHasEditorAndApprover(users)) {
          isValid = false;
          break;
        }
      }
    }

    return isValid;
  };

  const handleSaveEdition = async () => {
    const isValid = planningInfoIsValid();

    if (!isValid) {
      setShowPlanningErrors(true);
      return;
    }

    setIsSavingConfiguration(true);

    const canEdit = await canEditPlanningFlow();

    if (canEdit) {
      const { success } = await saveConfiguration();

      if (success) {
        await queryClient.invalidateQueries([
          'workspace',
          workspace.id,
          'releases',
          workspace.releaseSelected?.id,
        ]);

        await queryClient.invalidateQueries([
          'workspace',
          'logs',
          workspace.id,
          'releases',
          workspace.releaseSelected?.id,
        ]);

        setShowPlanningFlowConfiguration(false);
        dispatch(changeIsEditingPlanningFlow(false));
      } else {
        setSaveError(true);
      }
    }

    setIsSavingConfiguration(false);
  };

  const handleCloseSaveErrorModal = () => {
    setSaveError(false);
  };

  const handleFinalizeVersion = async () => {
    try {
      await apiWorkspace.patch(
        `workspaces/${workspace.id}/releases/${workspace.releaseSelected?.id}/finish`,
      );

      await queryClient.invalidateQueries([
        'workspace',
        workspace.id,
        'releases',
        workspace.releaseSelected?.id,
      ]);

      await queryClient.invalidateQueries([
        'workspace',
        'releases on sidebar',
        workspace.id,
      ]);
    } catch {
      setFinalizeVersionError(true);
    }
  };

  useEffect(() => {
    if (!selectedStep) {
      updateSelectedStepToLastStepActive();
    } else {
      updateSelectedStepStatus();
    }

    if (selectedStep?.status === 'approved') {
      const nextStep = releaseData?.data?.steps?.[selectedStep.number];

      if (nextStep?.status === 'created') {
        dispatch(changeIsCreatingStep(true));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(releaseData?.data.steps), dispatch, stepsQtty]);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (!editionExpired && showPlanningFlowConfiguration) {
      interval = setInterval(() => setEditionExpired(true), ms('5 min'));
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [editionExpired, showPlanningFlowConfiguration]);

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [
        'workspace',
        'results',
        workspace.id,
        workspace.releaseSelected?.id,
      ],
    });
    queryClient.invalidateQueries({
      queryKey: [
        'workspace',
        'projections',
        'series data',
        workspace.id,
        workspace.releaseSelected?.id,
      ],
    });
  }, [workspace.id, workspace.releaseSelected?.id]);

  const isPreview = workspace.releaseSelected?.id === workspace.releasePreview;

  const userIsManager = workspace.userRole === 'manager';

  const releaseIsFinished = releaseData?.status === 'finished';

  const canFinalizeVersion = userIsManager && !releaseIsFinished && !isPreview;

  const userCanEditPlanningFlow =
    userIsManager && !releaseIsFinished && !isPreview;

  const isRemovingStep = releaseData?.status === 'removing_step';
  const isCreatingStep = releaseData?.status === 'creating_step';
  const isDiscardingAdjustments = releaseData?.status === 'discarding_step';

  const editPlanningFlowIsDisabled =
    loadingEditionLock ||
    isRemovingStep ||
    isCreatingStep ||
    isDiscardingAdjustments;

  return (
    <Container className="containerLinear">
      <Card
        textCard={translate('workspaceOverviewPlanningFlowTitle')}
        textDescription={translate('workspaceOverviewPlanningFlowDescription')}
      />

      <ButtonsContainer>
        {showPlanningFlowConfiguration ? (
          <>
            <Button
              buttonType="secondary"
              data-testid="button-cancel-configuration"
              onClick={handleCancelEdition}
            >
              <X size={16} />
              {translate('cancel')}
            </Button>

            {userCanEditPlanningFlow && (
              <Button
                buttonType="primary"
                data-testid="button-save-configuration"
                disabled={!!isSavingConfiguration}
                loading={!!isSavingConfiguration}
                onClick={handleSaveEdition}
                style={{ color: '#fff' }}
              >
                <FloppyDisk size={16} />
                {translate('workspaceOverviewPlanningFlowSaveEdition')}
              </Button>
            )}
          </>
        ) : (
          <>
            {!isPreview && (
              <Button
                buttonType="secondary"
                data-testid="button-open-change-history"
                disabled={showChangeHistory}
                onClick={handleChangeHistory}
              >
                <ClockCounterClockwise size={16} />
                {translate('workspaceOverviewLogsButton')}
              </Button>
            )}

            {userCanEditPlanningFlow && (
              <Button
                buttonType="secondary"
                data-testid="button-edit"
                disabled={editPlanningFlowIsDisabled}
                loading={loadingEditionLock}
                onClick={handleEditPlanningFlow}
                data-tooltip-id="tooltip-planning-flow-edition"
                data-tooltip-content={
                  isRemovingStep
                    ? translate(
                        'workspaceOverviewPlanningConfigurationRemovingStep',
                      )
                    : isCreatingStep
                    ? translate(
                        'workspaceOverviewPlanningConfigurationCreatingStep',
                      )
                    : isDiscardingAdjustments
                    ? translate(
                        'workspaceOverviewPlanningConfigurationDiscardingStep',
                      )
                    : undefined
                }
              >
                <PencilSimple size={16} />
                {translate('edit')}
              </Button>
            )}

            {canFinalizeVersion && (
              <Button
                buttonType="primary"
                onClick={() => setShowFinalizeVersionConfirmation(true)}
                data-testid="button-finalize-version"
                style={{ color: '#fff' }}
              >
                <CheckCircle size={16} />
                {translate('workspaceOverviewPlanningFlowFinalizeVersion')}
              </Button>
            )}
          </>
        )}
      </ButtonsContainer>

      {!steps && releaseIsLoading ? (
        <ContainerSkeleton
          withLoading={false}
          data-testid="container-loading-release-data"
          style={{ height: '8.938rem' }}
        />
      ) : (
        <PlanningFlow
          steps={showPlanningFlowConfiguration ? stepsConfiguration : steps}
          selectedStep={
            selectedStep
              ? {
                  number: selectedStep.number,
                  status: selectedStep.selectedStatus,
                }
              : undefined
          }
          handleAccessStep={handleAccessStep}
          showErrors={showPlanningErrors}
          handleAddNewStep={handleAddNewStep}
          handleDeleteLastStep={handleDeleteLastStep}
          handleRenameStep={handleRenameStep}
          handleAddUser={handleAddUser}
          handleRemoveUser={handleRemoveUser}
          handleChangeDeadline={handleChangeDeadline}
          isPublishedConfiguration={showPlanningFlowConfiguration}
        />
      )}

      {showEditionModeEnabledModal && (
        <EditionModeEnabledModal setVisible={setShowEditionModeEnabledModal} />
      )}

      {showReviewModeEnabledModal && (
        <ReviewModeEnabledModal setVisible={setShowReviewModeEnabledModal} />
      )}

      {saveError && (
        <Modal
          visible={saveError}
          setVisible={setSaveError}
          style={{ width: '30rem' }}
        >
          <Status
            type="cloudWarning"
            isModal
            title={translate(
              'workspaceOverviewPlanningConfigurationSaveErrorTitle',
            )}
            description={translate(
              'workspaceOverviewPlanningConfigurationSaveErrorDescription',
            )}
          />

          <ModalFooter>
            <Button
              buttonType="primary"
              onClick={handleCloseSaveErrorModal}
              data-testid="button-ok-error"
            >
              Ok
            </Button>
          </ModalFooter>
        </Modal>
      )}

      {editionExpired && showPlanningFlowConfiguration && (
        <SessionExpiredModal
          handleBlockEdition={handleEditPlanningFlow}
          handleExitEdition={() => {
            setShowPlanningFlowConfiguration(false);
            dispatch(changeIsEditingPlanningFlow(false));
          }}
          loadingBlockEdition={loadingEditionLock}
          workspaceId={workspace.id ?? ''}
        />
      )}

      {(!!showUserIsEditingModal || showWorkspaceIsPublishing) && (
        <NoPermissionToEditModal
          setVisible={() => {
            setShowUserIsEditingModal('');
            setShowWorkspaceIsPublishing(false);
          }}
          emailEditing={showUserIsEditingModal}
          errorDescription={
            showWorkspaceIsPublishing
              ? translate('workspaceOverviewResultsWorkspaceIsPublishing')
              : undefined
          }
        />
      )}

      {showFinalizeVersionConfirmation && (
        <ActionConfirmationModal
          title={translate(
            'workspaceOverviewFinalizeVersionConfirmationModalTitle',
          )}
          description={translate(
            'workspaceOverviewFinalizeVersionConfirmationModalDescription',
          ).replace(
            'version_name',
            `<b>${
              workspace.releaseSelected?.label ??
              workspace.releaseSelected?.name ??
              ''
            }</b>`,
          )}
          onConfirm={handleFinalizeVersion}
          textToConfirm={
            workspace.releaseSelected?.label ??
            workspace.releaseSelected?.name ??
            ''
          }
          setVisible={setShowFinalizeVersionConfirmation}
        />
      )}

      {finalizeVersionError && (
        <Modal
          visible={finalizeVersionError}
          setVisible={setFinalizeVersionError}
          style={{ width: '30rem' }}
        >
          <Status
            type="cloudWarning"
            isModal
            title={translate(
              'workspaceOverviewPlanningConfigurationFinalizeVersionErrorTitle',
            )}
            description={translate(
              'workspaceOverviewPlanningConfigurationFinalizeVersionErrorDescription',
            )}
          />

          <ModalFooter>
            <Button
              buttonType="primary"
              onClick={() => setFinalizeVersionError(false)}
              data-testid="button-ok-error"
            >
              Ok
            </Button>
          </ModalFooter>
        </Modal>
      )}

      <Tooltip
        id="tooltip-planning-flow-edition"
        className="white-tooltip-theme"
      />
    </Container>
  );
};
