export const workspaceProjectionsResultsUnderstandingPTBRTranslate = {
  workspaceProjectionsResultsUnderstandingGetInsights: 'Obter Insights!',
  workspaceProjectionsResultsUnderstandingTitle: 'Compreendendo o Resultado',
  workspaceProjectionsResultsUnderstandingSeeMoreImpacts: 'Veja mais impactos',
  workspaceProjectionsResultsUnderstandingSeeLessImpacts: 'Ver menos impactos',
  workspaceProjectionsResultsUnderstandingWhenYFrequencyIsInvalid:
    'A interpretação dos resultados para essa agregação está disponível apenas para dados em que a frequência original é mensal. ',
  workspaceProjectionsResultsUnderstandingCopyText: 'Copiar texto',
  workspaceProjectionsResultsUnderstandingTextCopied: 'Copiado!',
  workspaceProjectionsResultsUnderstandingWhenFrequencyIsOriginalLow:
    'A interpretação dos resultados não está disponível para dados com frequência diária, semanal ou quinzenal.',
  workspaceProjectionsResultsUnderstandingSeeOtherVariables:
    'Visualizar outras variáveis',
  workspaceProjectionsResultsUnderstandingInitialValue: 'Valor inicial',
  workspaceProjectionsResultsUnderstandingTotalValue: 'Valor total',
  workspaceProjectionsResultsUnderstandingOthers: 'outros',
  workspaceProjectionsResultsUnderstandingCards: 'Cartões',
  workspaceProjectionsResultsUnderstandingWaterfall: 'Cascata',
};
