import styled from 'styled-components';
import { Info as InfoPhosphor } from 'phosphor-react';

export const ExplanatoryVariableContainer = styled.div`
  position: relative;
`;

export const ContentSelectExplanatoryVariable = styled.div`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;

  width: 100%;
  max-width: 20rem;

  @media (max-width: 1160px) {
    position: unset;
  }
`;

export const ContainerChart = styled.div`
  > div:first-child {
    display: flex;
    justify-content: space-between;

    h4 {
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.688rem;
      color: ${({ theme }) => theme.colors.gray6};
    }
  }
`;

export const ContentLatestData = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;

  > div {
    > div {
      text-align: right;

      p {
        margin-right: 0px;
      }
    }
  }

  input {
    display: block !important;
    margin-left: auto !important;
  }
`;

export const Info = styled(InfoPhosphor)`
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  color: ${({ theme }) => theme.colors.gray4};
  z-index: 1;
`;
