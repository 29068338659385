const modelOverviewENUSTranslations = {
  modelOverviewTitle: 'Variable Overview',
  modelOverviewTitleDescription:
    'Scan the portfolio of models for a given dependent variable, comparing accuracy across methods and checking projections from competing approaches.',
  modelOverviewSummary: `Models's Summary`,
  modelOverviewType: 'Model Type',
  modelOverviewQuantity: 'Quantity',
  modelOverviewAccuracyCriteria: 'Accuracy Criteria',
  modelOverviewModelsSorting: `Models's Sorting`,
  modelOverviewForecastDispersion: 'Forecast Dispersion',
  modelOverviewForecastDispersionDescription:
    'Visualize the predictions from the best models available.',
  modelOverviewShowNBestModels: 'Show N best models',
};

export default modelOverviewENUSTranslations;
