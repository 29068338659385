import styled from 'styled-components';

export const ExplanatoryVariableContainer = styled.div`
  position: relative;
`;

export const ContentSelectExplanatoryVariable = styled.div`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;

  width: 100%;
  max-width: 20rem;

  @media (max-width: 1160px) {
    position: unset;
  }
`;

export const ContainerChart = styled.div`
  > div:first-child {
    display: flex;
    justify-content: space-between;

    h4 {
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.688rem;
      color: ${({ theme }) => theme.colors.gray6};
    }
  }
`;

export const Options = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  flex-wrap: wrap;

  > div {
    > span {
      display: block;
      margin-bottom: 0.5rem;

      font-weight: 500;
      font-size: 0.875rem;
      line-height: 150%;
      color: ${({ theme }) => theme.colors.gray5};
    }

    div {
      display: flex;
      gap: 1.5rem;
    }
  }
`;

export const ChartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  margin: 3.5rem 0 1.5rem;
`;

export const ContentLatestData = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;

  > div {
    > div {
      text-align: right;

      p {
        margin-right: 0px;
      }
    }
  }

  input {
    display: block !important;
    margin-left: auto !important;
  }
`;
