import React, { useState } from 'react';

import { Controller, useForm } from 'react-hook-form';
import ApproveModalImage from 'src/assets/approve_modal_image.svg';
import disapprove_modal_image from 'src/assets/disapprove_modal_image.svg';
import { useTranslation } from 'react-i18next';
import { Modal } from 'src/components/Modal';
import { Button } from 'src/components/Button';
import { TextArea } from 'src/components/TextArea';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { Container, Content, Footer } from './styles';

type ApproveOrDisapproveProps = {
  approveOrDisapprove: 'approve' | 'disapprove';
  onConfirm: (feedbackMessage: string) => Promise<void>;
  onCancel: () => void;
};

type ApproveOrDisapproveForm = {
  feedbackMessage: string;
};

export const ApproveOrDisapproveModal: React.FC<ApproveOrDisapproveProps> = ({
  approveOrDisapprove,
  onConfirm,
  onCancel,
}) => {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const formSchema = Yup.object().shape({
    feedbackMessage: Yup.string()
      .trim()
      .min(
        3,
        'workspaceOverviewPlanningFlowMessageDescriptionMinCharactersError',
      )
      .max(
        300,
        'workspaceOverviewPlanningFlowMessageDescriptionMaxCharactersError',
      )
      .required(),
  });

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    trigger,
  } = useForm<ApproveOrDisapproveForm>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      feedbackMessage: '',
    },
  });

  async function handleConfirm({ feedbackMessage }: ApproveOrDisapproveForm) {
    setLoading(true);

    try {
      await onConfirm(feedbackMessage);
      // eslint-disable-next-line no-empty
    } catch {}

    setLoading(false);
  }

  const img =
    approveOrDisapprove === 'approve'
      ? ApproveModalImage
      : disapprove_modal_image;

  const title =
    approveOrDisapprove === 'approve'
      ? t('workspaceOverviewApproveModalTitle')
      : t('workspaceOverviewDisapproveModalTitle');

  const description =
    approveOrDisapprove === 'approve'
      ? t('workspaceOverviewApproveModalDescription')
      : t('workspaceOverviewDisapproveModalDescription');

  const confirmButtonDisabled = !isValid || loading;

  return (
    <Modal
      visible
      style={{ width: '30rem', padding: 0 }}
      setVisible={onCancel}
      dataCy={`modal-${approveOrDisapprove}`}
    >
      <Container>
        <Content>
          <img src={img} alt="" />
          <h3>{title}</h3>
          <p>{description}</p>

          <div style={{ height: '8.125rem' }}>
            <Controller
              control={control}
              name="feedbackMessage"
              render={({ field: { value, onChange } }) => (
                <TextArea
                  placeholder={t(
                    'workspaceOverviewApproveOrDisapproveModalPlaceholder',
                  )}
                  value={value}
                  onChange={({ target: { value: val } }) => {
                    onChange(val);

                    if (
                      val.length > 2 &&
                      val.length <= 300 &&
                      !errors.feedbackMessage?.message
                    ) {
                      return;
                    }

                    trigger('feedbackMessage');
                  }}
                  error={
                    errors.feedbackMessage?.message
                      ? t(errors.feedbackMessage?.message)
                      : undefined
                  }
                  data-testid="textarea-approve-or-disapprove-modal"
                />
              )}
            />
          </div>
        </Content>
        <Footer>
          <Button
            buttonType="naked"
            data-testid="button-cancel-approve-or-disapprove-modal"
            onClick={onCancel}
            style={{ padding: 0 }}
          >
            {t('cancel')}
          </Button>
          <Button
            buttonType="primary"
            data-testid="button-confirm-approve-or-disapprove-modal"
            onClick={handleSubmit(handleConfirm)}
            disabled={confirmButtonDisabled}
            loading={loading}
          >
            {t('confirm')}
          </Button>
        </Footer>
      </Container>
    </Modal>
  );
};
