import styled from 'styled-components';

export const Container = styled.div`
  max-width: 71.25rem;
  margin: 0 auto;
`;
export const ContainerComponents = styled.div`
  border-radius: 8px;
  padding: 1.5rem;
  border: ${({ theme }) => `1px solid${theme.colors.gray3}`};
  flex-wrap: wrap;
  background: ${({ theme }) => theme.colors.white};

  margin-bottom: 24px;
`;

interface ContentButtonProps {
  hasMargin?: boolean;
}

export const ContentButton = styled.div<ContentButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 3rem;
  border-top: 1px solid ${({ theme }) => theme.colors.gray2};
  padding-top: 1.5rem;
  gap: 0.75rem;

  button:first-child {
    padding: 0;
  }
`;

export const ContentModelTypes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 2rem 0;
  margin: 0 auto;
  max-width: 45.625rem;
`;
interface SelectedModelButtonProps {
  selected: boolean;
}

export const SelectModelButton = styled.button<SelectedModelButtonProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 2rem;

  width: 20rem;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    gap: 1.5rem;

    width: 20rem;
    height: 20rem;

    transition: 0.6s;

    background: ${({ selected }) =>
      selected ? 'rgba(76, 148, 255, 0.12)' : 'transparent'};

    border: 3px solid
      ${({ theme, selected }) =>
        selected ? theme.colors.primary : theme.colors.gray4};
    border-radius: 8px;

    svg {
      color: ${({ theme, selected }) =>
        selected ? theme.colors.primary : theme.colors.gray4};
    }

    img {
      color: ${({ theme, selected }) =>
        selected ? theme.colors.primary : theme.colors.gray3};
    }
  }

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.438rem;

    color: ${({ theme, selected }) =>
      selected ? theme.colors.gray8 : theme.colors.gray6};
  }

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.125rem;
    text-align: center;
    margin-top: -1rem;

    color: ${({ theme, selected }) =>
      selected ? theme.colors.gray7 : theme.colors.gray5};
  }
`;
