export const ModalManagerPTBRTranslate = {
  modalManagerEditManager: 'Editar gerentes',
  modalManager: 'Gerentes',
  modalManagerErrorLoadManagers: 'Não foi possível carregar os gerentes.',
  modalManagerProductsAccess: 'Acesso ao produto',
  modalManagerRemoveManager: 'Deletar gerente',
  modalManagerAddManager: '+ Adicionar gerente',
  modalManagerRemoveManagerButton: '- Remover gerente',
  modalManagerErrorAddNewManagerTitle:
    'Não foi possível adicionar novo gerente',
  modalManagerErrorAddNewManagerDescription:
    'Esse email ja está cadastrado para outro cliente',
  modalConfirmDeleteDescription:
    'Tem certeza de que deseja remover este gerente ?',
  modalConfirmDeleteErrorTitle: 'Erro ao remover gerente',
  modalConfirmDeleteErrorDescription: 'Não foi possível remover este gerente',
  modalManagerErrorEditManager: 'Ocorreu um erro ao tentar editar um gerente',
};
