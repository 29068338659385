export const projectOverviewProjectionsENTranslate = {
  ProjectOverviewProjectionsTitle: 'Projections',
  ProjectOverviewProjectionsDescription:
    'Access the projections of your variables over time.',
  ProjectOverviewProjectionsFrequency: 'Frequency',
  ProjectOverviewProjectionsOriginal: 'Original',
  ProjectOverviewProjectionsAnnual: 'Annual',
  ProjectOverviewProjectionsTransformation: 'Transformation',
  ProjectOverviewProjectionsLevel: 'Level',
  ProjectOverviewProjectionsVariation: 'Variation (%)',
  ProjectOverviewProjectionsHistorical: 'Historical',
  ProjectOverviewProjectionsForecast: 'Forecast',
  ProjectOverviewProjectionsVariables: 'variables',
  ProjectOverviewProjectionsOnlyForMonthlyFrequency:
    'Only for monthly frequency',
  ProjectOverviewProjectionsTable: 'table',
};
