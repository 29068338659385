export const modalSeeRequirementsTimeSeriesENTranslate = {
  seeRequirementsTimeSeriesTitle: 'Dataset Requirements',
  seeRequirementsTimeSeriesDescription:
    'Please, guarantee that your dataset fulfill the following requirements:',
  seeRequirementsTimeSeriesFirstRow: 'First row with the <b>column names</b>',
  seeRequirementsTimeSeriesFileHas:
    'File has <b>minimum 2 columns</b>: date column and a numeric target/dependent column',
  seeRequirementsTimeSeriesDateColumn: 'Date Column:',
  seeRequirementsTimeSeriesItMustHave:
    "It must have only one observation per date (for example, the dataset shouldn't have two rows with information for January 2020)",
  seeRequirementsTimeSeriesWeAccept:
    'We accept these frequencies: daily, weekly, fortnightly (biweekly), monthly, bimonthly, quarterly, half-year and annual',
  seeRequirementsTimeSeriesDependingOnTheFrequency:
    'Depending on the frequency, the file needs a certain amount of historical data points of the dependent variable (number of rows excluding the forecast period)',
  seeRequirementsTimeSeriesDaily: 'Daily',
  seeRequirementsTimeSeriesWeekly: 'Weekly',
  seeRequirementsTimeSeriesFortnightly: 'Fortnightly',
  seeRequirementsTimeSeriesMonthly: 'Monthly',
  seeRequirementsTimeSeriesBimonthly: 'Bimonthly',
  seeRequirementsTimeSeriesQuarterly: 'Quarterly',
  seeRequirementsTimeSeriesHalfYear: 'Half-year',
  seeRequirementsTimeSeriesAnnual: 'Annual',
  seeRequirementsTimeSeriesOptionalExtraColumns:
    '<b>Optional:</b> Extra columns with predictor/explanatory variables (numeric only)',
  seeRequirementsTimeSeriesOptionalDownloadExample: 'Download Example',
};
