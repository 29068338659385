import styled from 'styled-components';

export const BackToFSHome = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;

  gap: 0.5rem;

  color: ${({ theme }) => theme.colors.gray5};
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  cursor: pointer;

  svg {
    width: 0.875rem;
    height: 0.875rem;
  }
`;
