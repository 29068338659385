const overviewEN = {
  overview: 'Overview',
  data_view: 'Data View',
  historical_data: 'Historical Data',
  correlation: 'Correlation',
  potential_outliers: 'Potential Outliers',
  arima_method: 'Arima Method',
  sarima_method: 'Sarima Method',
  cooksd_method: 'Cooksd Method',
  stl_decomposition: 'STL Decomposition',
  trend: 'Trend',
  seasonal: 'Seasonal',
  remainder: 'Remainder',
  historicalDataYAxisTitle: 'Value',
  historicalDate: 'Date',
  historicalValue: 'Value',
  historicalTransformation: 'Transformation',
  overviewNoExplanatoryVariableSelected: 'No explanatory variables selected.',
  decompositionDate: 'Date',
  decompositionSeasonal: 'Seasonal',
  decompositionTrend: 'Trend',
  decompositionRemainder: 'Remainder',
  dataViewErrorSelectAtLeastOne: 'Select at least one transformation.',
  dataViewPotenciasOutliersNotIdentified: 'Not Identified',
};

export default overviewEN;
