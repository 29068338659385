import React, { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { getChartColor } from 'src/utils/colors/getChartColor';
import { RootState } from 'src/redux/store';
import { useSelector } from 'react-redux';
import { formatCompactNotation } from 'src/utils/numbers/formatCompactNotation';
import { HCharts, HChartsOptions, HChartsSeries } from 'src/components/HCharts';

import { ExplanatoryProjections } from '../../types';
import { frequencyLatestData } from '../..';

interface ExplanatoryVariablesChartProps {
  projectionExplanatoryData: ExplanatoryProjections;
  isLatestDataActive: boolean;
  defaultEqualSimulated: boolean;
}

export const ExplanatoryVariablesChart: React.FC<
  ExplanatoryVariablesChartProps
> = ({
  projectionExplanatoryData,
  isLatestDataActive,
  defaultEqualSimulated,
}) => {
  const { selectedY } = useSelector((state: RootState) => state.project);

  const { t: translate } = useTranslation();

  const getLatestData = useCallback(
    (
      quantity: number,
      isVariation = false,
      initialDate?: string | number,
    ): ExplanatoryProjections => {
      if (projectionExplanatoryData) {
        const total =
          projectionExplanatoryData.historical.y.length +
          (projectionExplanatoryData.original?.y.length ?? 0);

        if (quantity > total) {
          return projectionExplanatoryData;
        }

        const originalDate =
          projectionExplanatoryData.original?.x.slice(
            0,
            quantity / 3 + (isVariation ? 1 : 0),
          ) ?? [];

        const originalValue =
          projectionExplanatoryData.original?.y.slice(
            0,
            quantity / 3 + (isVariation ? 1 : 0),
          ) ?? [];

        const simulatedDate =
          projectionExplanatoryData.simulated?.x.slice(
            0,
            quantity / 3 + (isVariation ? 1 : 0),
          ) ?? [];

        const simulatedValue =
          projectionExplanatoryData.simulated?.y.slice(
            0,
            quantity / 3 + (isVariation ? 1 : 0),
          ) ?? [];

        const historicalLength = projectionExplanatoryData.historical.x.length;

        let initialIndex = 0;

        if (initialDate) {
          initialIndex = projectionExplanatoryData.historical.x.findIndex(
            (date) => date === initialDate,
          );
        } else {
          initialIndex =
            historicalLength -
            quantity +
            (originalDate.length + (isVariation ? -1 : 0));
        }

        const historicalDate = projectionExplanatoryData.historical.x.slice(
          initialIndex,
          historicalLength,
        );

        const historicalValue = projectionExplanatoryData.historical.y.slice(
          initialIndex,
          historicalLength,
        );

        return {
          historical: {
            x: historicalDate,
            y: historicalValue,
            type: projectionExplanatoryData.historical.type,
          },
          original: {
            x: originalDate,
            y: originalValue,
            type: projectionExplanatoryData.original.type,
          },
          simulated: {
            x: simulatedDate,
            y: simulatedValue,
            type: projectionExplanatoryData.simulated.type,
          },
        };
      }

      return {
        historical: {
          x: [],
          y: [],
          type: '',
        },
        original: {
          x: [],
          y: [],
          type: '',
        },
        simulated: {
          x: [],
          y: [],
          type: '',
        },
      };
    },
    [projectionExplanatoryData],
  );

  const dataAdjusted: ExplanatoryProjections = useMemo(() => {
    let dataAdjustedAux: ExplanatoryProjections = {
      historical: {
        type: projectionExplanatoryData.historical.type,
        x: [],
        y: [],
      },
      original: {
        type: projectionExplanatoryData.original.type,
        x: [],
        y: [],
      },
      simulated: {
        type: projectionExplanatoryData.simulated.type,
        x: [],
        y: [],
      },
    };

    if (!isLatestDataActive) {
      dataAdjustedAux = projectionExplanatoryData;
    } else {
      switch (selectedY?.info?.frequency) {
        case 'daily':
          dataAdjustedAux = getLatestData(frequencyLatestData.daily);

          break;
        case 'weekly':
          dataAdjustedAux = getLatestData(frequencyLatestData.weekly);

          break;
        case 'fortnightly':
          dataAdjustedAux = getLatestData(frequencyLatestData.fortnightly);

          break;
        case 'monthly':
          dataAdjustedAux = getLatestData(frequencyLatestData.monthly);

          break;
        case 'bimonthly':
          dataAdjustedAux = getLatestData(frequencyLatestData.bimonthly);

          break;
        case 'quarterly':
          dataAdjustedAux = getLatestData(frequencyLatestData.quarterly);

          break;
        case 'half-year':
          dataAdjustedAux = getLatestData(frequencyLatestData['half-year']);

          break;
        case 'annual':
          dataAdjustedAux = getLatestData(frequencyLatestData.annual);

          break;
        default:
          dataAdjustedAux = projectionExplanatoryData;
          break;
      }
    }

    return dataAdjustedAux;
  }, [
    getLatestData,
    isLatestDataActive,
    projectionExplanatoryData,
    selectedY?.info?.frequency,
  ]);

  const series: HChartsSeries[] = useMemo(() => {
    const seriesAux: HChartsSeries[] = [
      {
        type: 'column',
        name: translate('modelInProductionLevel'),
        data: [
          ...dataAdjusted.historical.x.map((x, index) => ({
            x: new Date(`${x}T00:00`).getTime(),
            y: dataAdjusted.historical.y[index],
            custom: {
              keyValue: translate('Historical'),
              value: formatCompactNotation(
                dataAdjusted.historical.y[index] as number,
              ),
            },
          })),
          ...dataAdjusted.original.x.map((x, index) => ({
            x: new Date(`${x}T00:00`).getTime(),
            y: dataAdjusted.original.y[index],
            custom: {
              keyValue: translate('Forecast'),
              value: formatCompactNotation(
                dataAdjusted.original.y[index] as number,
              ),
            },
            color: `${getChartColor(0)}7E`,
          })),
        ],
        color: getChartColor(0),
      },
    ];

    if (!defaultEqualSimulated) {
      seriesAux.push({
        type: 'column',
        name: translate(dataAdjusted.simulated?.type),
        data: dataAdjusted.simulated.x.map((x, index) => ({
          x: new Date(`${x}T00:00`).getTime(),
          y: dataAdjusted.simulated.y[index],
          custom: {
            keyValue: translate('value'),
            value: formatCompactNotation(
              dataAdjusted.simulated.y[index] as number,
            ),
          },
        })),
        color: getChartColor(1),
      });
    }

    return seriesAux;
  }, [dataAdjusted, defaultEqualSimulated, translate]);

  const options: HChartsOptions = useMemo(
    () => ({
      chart: {
        height: 300,
      },
      series,
      yAxis: {
        title: {
          text: '',
        },
      },
      tooltip: {
        pointFormat:
          `<tr><td><b>${translate('date')}:</b> </td>` +
          `<td style="text-align: right">{point.x: ${
            selectedY?.info?.frequency === 'annual' ? '%Y' : ' %d/%m/%Y'
          }}</td></tr>` +
          `<tr><td><b>{point.custom.keyValue}:</b> </td>` +
          '<td style="text-align: right">{point.custom.value}</td></tr>',
      },
      plotOptions: {
        column: {
          grouping: true,
          groupPadding: !defaultEqualSimulated ? 0 : 0.17,
          pointPadding: !defaultEqualSimulated ? 0 : 0.1,
        },
      },
    }),
    [defaultEqualSimulated, selectedY?.info?.frequency, series, translate],
  );

  return (
    <HCharts
      series={series}
      options={options}
      dataCy="explanatory-variables-chart"
      resizeWidthWithSidebar
      sleepTimerToResize
      animationToResize
    />
  );
};
