const predictionsENUSTranslations = {
  getPredictionsTitle: 'Get Predictions',
  predictionsTitle: 'Predictions',
  predictionsDescription:
    'Upload new observations of your data to get predictions for your response variables.',
  predictUploadTitle: 'Upload your dataset',
  predictUploadLabel: 'Dataset',
  predictUploadDescription:
    'Drag and drop your file here or click to select manually. <b>You must bring the same variables</br>used in the modeling process.</b>',
  predictUploadButton: 'Upload',
  predictChangeDataset: 'Change Dataset',
  predictProbability: 'Probability',
  predictErrorMessage: 'Please, try again later.',
  predictDownload: 'Download',
  predictionsResultDescription:
    'Access predictions and the associated probabilities for your new data points. Predictions using "X".',
  predictColumnSingular: 'Column',
  predictMissingColumnWarning: 'Mandatory columns are missing for prediction.',
  predictExtraColumnWarning:
    'Some columns are not affecting the prediction due to not being in the fitted model.',
  predictColumnPlural: 'Columns',
  predictWarningMeaningDefinition: 'and more',
  predictFileSizeErrorTitle: 'Something went wrong',
  predictFileSizeErrorDescription:
    'Your dataset needs to have a size equal to or less than "X" to perform the prediction.',
  previewingFirst: 'Previewing the first',
  predictFileExtensionErrorDescription: `Only '.xlsx' and '.csv' extension available.`,
  predictAPIModalTitle: 'Call Predict via API',
  predictAPIModalDescription:
    'Plug in the predictions in your systems with our API.',
  predictAPIModalSeeDocumentation: 'See Documentation',
  predictAPIModalProjectID: 'PROJECT ID',
  predictSeeDocumentation: 'See Documentation',
};

export default predictionsENUSTranslations;
