import styled from 'styled-components';

export const ContentTableProjectSummary = styled.div`
  overflow-x: auto;

  @media (max-width: 900px) {
    padding-bottom: 0.5rem;
  }
  table {
    width: 100%;
    text-align: center;
    border-collapse: separate;
    border: 1px solid ${({ theme }) => theme.colors.gray1};
    border-spacing: 0px;
    border-radius: 5px;
    thead {
      th {
        padding: 1rem;

        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.375rem;
        color: ${({ theme }) => theme.colors.gray6};
        border-right: 1px solid ${({ theme }) => theme.colors.gray1};
        border-bottom: 1px solid ${({ theme }) => theme.colors.gray1};

        &:last-child {
          border-right: 0;
        }
      }
    }

    tbody {
      td {
        padding: 1.313rem;

        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.375rem;
        color: ${({ theme }) => theme.colors.gray6};

        border-right: 1px solid ${({ theme }) => theme.colors.gray1};

        &:last-child {
          border-right: 0;
        }
      }
    }
  }
`;
