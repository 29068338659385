export const FiltersSidebarPTBRTranslation = {
  filtersSidebarDescription:
    'Configure as visualizações dos gráficos e tabelas da página inteira.',
  filterSidebarTransformation: 'Transformação do dado',
  filterSidebarTooltipTransformation:
    'Os dados podem ser visualizados em nível (com os valores absolutos) ou em variação (comparando com o XXX anterior ou com o mesmo XXX do ano anterior).',
  filterSidebarTooltipTransformationToYearlyFreq:
    'Os dados podem ser visualizados em nível (com os valores absolutos) ou em variação (comparando com o mesmo período do ano anterior).',
  filterSidebarVariationYoY: 'Variação % (YoY)',
  filterSidebarVariationPoP: 'Variação % (PoP)',
  filterSidebarMonth: 'mês',
  filterSidebarBimonth: 'bimestre',
  filterSidebarQuarter: 'trimestre',
  filterSidebarHalfYear: 'semestre',
  filterSidebarOther: 'período',
};
