const Step3ClaaSPTBRTranslations = {
  claasStep3Description: 'Experimente ou ajuste os detalhes de modelagem.',
  claasStep3InformativeTitle: 'Tudo pronto',
  claasStep3InformativeDescription:
    'Temos todas as informações necessárias para gerar ótimas previsões. Em versões futuras, você poderá refinar o processo de modelagem caso precise de mais controle dos resultados finais.',
  claasModelingConclusionTitle: 'A modelagem começou',
  claasStep3ConclusionDescription: `Você receberá um e-mail informando quando o trabalho estiver concluído.`,
  claasConclusionSettingsButton: 'Iniciar',

  claasWarningTitle: 'Processando dados..',
  claasRemovedColsTypeWarning:
    'Variáveis que serão removidas devido ao excesso de valores únicos:',
  claasRemovedColsMissingWarning:
    'Variáveis que serão removidas devido aos valores ausentes:',
  claasRemovedRowsWarning:
    '"X" linhas serão removidas devido aos valores ausentes.',
  claasRemovedClassesWarning:
    'Os seguintes valores aparecem menos do que o mínimo necessário (6 vezes) na coluna alvo e serão removidos:',
};

export default Step3ClaaSPTBRTranslations;
