import React from 'react';

import { ArrowLeft } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Card } from 'src/components/Card';
import { Head } from 'src/components/Head';

import { Container, ContentTitle } from './styles';
import { HistoricalData } from './components/HistoricalData';
import { Correlation } from './components/Correlation';
import { PotentialOutliers } from './components/PotentialOutliers';
import { STLDecomposition } from './components/STLDecomposition';

export const Overview: React.FC = () => {
  const { t: translate } = useTranslation();

  const navigate = useNavigate();

  return (
    <Container>
      <Head title={`${translate('overview')} | ${translate('data_view')}`} />
      <ContentTitle
        data-testid="div-dataview"
        data-cy="div-dataview"
        className="containerLinear"
      >
        <ArrowLeft
          size="2rem"
          onClick={() => navigate('/models/projects/new/time-series')}
          data-cy="button-back"
          data-testid="button-back"
        />
        <Card
          textCard={`${translate('data_view')} - ${translate('overview')}`}
          style={{ marginBottom: 0 }}
        />
      </ContentTitle>

      <HistoricalData />

      <Correlation />

      <PotentialOutliers />

      <STLDecomposition />
    </Container>
  );
};
