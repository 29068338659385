import React from 'react';

import { Head } from 'src/components/Head';

import { ListFavorites } from './ListFavorites';
import { ListIndicatorsForAnalyze } from './ListIndicatorsForAnalyze';
// import { ListPremium } from './ListPremium';
import { Container, GridContainer } from './styles';

export const HomeFeatureStore: React.FC = () => (
  <Container>
    <Head title="Feature Store" />

    <GridContainer>
      <ListFavorites />

      {/* <ListPremium /> */}
    </GridContainer>

    <ListIndicatorsForAnalyze data-testid="list-indicators-for-analyze-home" />
  </Container>
);
