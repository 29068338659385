import { Button } from 'src/components/Button';
import styled from 'styled-components';

export const WorkspaceContainer = styled.div`
  width: 100%;
  position: relative;

  .workspace-body {
    padding-top: 0rem !important;
  }

  .workspace-description-title {
    margin-top: 0rem !important;
  }
`;

export const WorkspaceHead = styled.div`
  width: 100%;
  padding: 1.25rem 1.25rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.25rem;

    flex: 1;

    margin-right: 3.25rem;
  }

  img {
    position: relative;
    top: 0;
    left: 0;
  }

  h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.688rem;
    color: ${({ theme }) => theme.colors.gray6};
  }
`;

export const UserRoleContainer = styled.div`
  position: absolute;
  bottom: 1.25rem;
  right: 1.25rem;

  width: 1.5rem;
  height: 1.5rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;

    color: ${({ theme }) => theme.colors.gray4};
  }
`;

export const ContainerModal = styled.div`
  width: 30rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  > svg {
    color: ${({ theme }) => theme.colors.yellow4};
    margin-bottom: 1.875rem;
    margin-top: 0.5rem;
  }

  > h1 {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.gray6};

    margin-bottom: 0.75rem;
    margin-top: -0.5rem;
  }

  > p {
    color: ${({ theme }) => theme.colors.gray5};
    font-weight: normal;
    font-size: 1rem;
    line-height: 150%;
    margin-bottom: 1.5rem;
  }
`;

export const ButtonOpenMenu = styled(Button)`
  position: absolute;
  top: calc(1.5rem + 1rem);
  right: 1rem;
  z-index: 1000;

  height: fit-content !important;
  padding: 0 !important;

  svg {
    width: 2rem;
    height: 2rem;

    color: ${({ theme }) => theme.colors.gray5};

    margin: 0 !important;
  }
`;
