import React, { useEffect } from 'react';

import { Check, DownloadSimple, XCircle } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeStep,
  changeIsError,
  cleanExport,
  changeIsFinish,
} from 'src/models/redux/reducers/ProjectOverviewExportTimeSeries';
import api from 'src/models/service/api';
import { RootState } from 'src/redux/store';
import { sleep } from 'src/utils/sleep';

import { ContainerExport, ContentExport } from './style';

interface ResponseGetProgress {
  status: string;
}

interface IResponseHeadersDowload {
  'content-disposition'?: string;
}

export const ExportProjectOverview: React.FC = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const {
    projectOverviewExportTimeSeries: { step, visibleModal, isError },
    project,
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    (async () => {
      let isDownloadReady = false;
      let error = false;
      if (step === 2) {
        try {
          while (!isDownloadReady && !error) {
            const { data } = await api.get<ResponseGetProgress>(
              `projects/${project.id}/export`,
            );
            if (data.status === 'success') {
              isDownloadReady = true;
            } else if (data.status === 'error') {
              error = true;
            } else await sleep(3000);
          }
          if (error) {
            dispatch(changeIsError(true));
            await sleep(3000);
            dispatch(cleanExport());
          } else if (isDownloadReady) {
            dispatch(changeStep(3));
          }
        } catch (err) {
          dispatch(changeIsError(true));
          await sleep(3000);
          dispatch(cleanExport());
        }
      } else if (step === 3) {
        try {
          const { data, headers } = await api.get(
            `projects/${project.id}/export/download`,
            {
              responseType: 'blob',
            },
          );

          if (data) {
            const fileURL = window.URL.createObjectURL(
              new Blob([data], { type: 'text/xlsx' }),
            );

            const contentDisposition = (headers as IResponseHeadersDowload)[
              'content-disposition'
            ];

            const link = document.createElement('a');

            const name =
              contentDisposition?.slice(
                contentDisposition.indexOf('filename="') + 10,
                contentDisposition.length - 1,
              ) ?? `${project.name}.xlsx`;

            if (link.download !== undefined) {
              link.setAttribute('href', fileURL);
              link.setAttribute('download', name);
              link.setAttribute('data-testid', 'download-start-excel');
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
            }

            dispatch(changeStep(4));
          }
        } catch (err) {
          dispatch(changeIsError(true));
          await sleep(3000);
          dispatch(cleanExport());
        }
      } else if (step === 4) {
        await sleep(1000);
        dispatch(changeIsFinish(true));
        dispatch(cleanExport());
      }
    })();
  }, [dispatch, project.id, project.name, step]);

  if (!visibleModal) {
    return <></>;
  }

  return (
    <ContainerExport>
      <ContentExport step={step} isError={isError}>
        <div>
          {/* div icone */}
          {isError ? <XCircle /> : step !== 4 ? <DownloadSimple /> : <Check />}
        </div>

        <div>
          {/* Div Text */}
          {isError ? (
            <h4>{translate('exportProjectOverviewDownloadError')}</h4>
          ) : step === 1 ? (
            <>
              <h4>{translate('exportProjectOverviewStep1Title')}</h4>
              <p>{translate('exportProjectOverviewStep1Description')}</p>
            </>
          ) : step === 2 || step === 3 ? (
            <h4>{translate('exportProjectOverviewStep2And3Title')}</h4>
          ) : (
            <h4>{translate('exportProjectOverviewStep4Title')}</h4>
          )}
        </div>
      </ContentExport>
    </ContainerExport>
  );
};
