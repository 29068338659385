import React from 'react';

import { CheckCircle } from 'phosphor-react';

import Error from '../../../assets/error.svg';
import { Button } from '../../Button';
import { Modal } from '../index';
import { ContainerModal, ContentButtons } from './styles';

type PropsModal = {
  visible: boolean;
  setVisible: (value: boolean) => void;
  title: string | undefined;
  message: string | undefined;
};

export const ModalStatus: React.FC<PropsModal> = ({
  visible,
  setVisible,
  title,
  message,
}) => (
  <Modal
    visible={visible}
    style={{ padding: 0 }}
    data-testid={`modal-${title}`}
  >
    <ContainerModal>
      {title === 'success' || title === 'sucesso' ? (
        <>
          <CheckCircle color="#00FF00" size="3rem" />
          <h1 data-testid={title}>
            {title &&
              title.slice(0, 1).toUpperCase() + title.slice(1).toLowerCase()}
          </h1>
          <p>{message}</p>
          <ContentButtons>
            <Button
              buttonType="primary"
              data-testid="modalConclusionOkButton"
              onClick={() => {
                setVisible(false);
              }}
            >
              Ok
            </Button>
          </ContentButtons>
        </>
      ) : (
        <>
          <img src={Error} alt="error" />

          <h1>
            {title &&
              title.slice(0, 1).toUpperCase() + title.slice(1).toLowerCase()}
          </h1>
          <p>{message}</p>
          <ContentButtons>
            <Button
              buttonType="primary"
              data-testid="modalConclusionOkButton"
              onClick={() => {
                setVisible(false);
              }}
            >
              Ok
            </Button>
          </ContentButtons>
        </>
      )}
    </ContainerModal>
  </Modal>
);
