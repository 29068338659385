export const AIChatENUSTranslations = {
  aiChatWelcomeTitle: 'Welcome to 4intelligence chat!',
  // aiChatWelcomeDescription:
  //   'Type what you need in the box below or select one of the sample questions:',
  aiChatWelcomeDescription: 'Type what you need in the box below',
  aiChatFirstSampleQuestion:
    'What climatic features can influence sales in the food and beverage industry?',
  aiChatSecondSampleQuestion:
    'Which variables are most correlated with the PMC result?',
  aiChatQuestionPlaceholder: 'Ask me something...',
  aiChatTitleError: 'Unable to load this content',
  aiChatDescriptionError:
    'This functionality is currently not available. Try again later.',
  aiChatQuestionCharacters: 'Maximum number of characters: 2000',
  aiChatQuestionLimitTitle: 'You have reached the monthly question limit',
  aiChatQuestionLimitDescription:
    "Don't worry, you will be able to continue chatting with 4i Chat over the next few days.",
  aiChatNoDataResponse: 'We do not have data to answer this question',
};
