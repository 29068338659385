import React, { useState } from 'react';

import { Trash, Warning, XCircle } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';
import { ModalLoading } from 'src/components/Modal/Loading';
import api from 'src/feature-store/service/api';
import { queryClient } from 'src/service/queryClient';
import { ModalFooter } from 'src/components/Modal/Footer/styles';

import { Container, ContainerError, FooterModalDelete } from './styles';

interface IModalDeleteSerie {
  serieToDelete: string;
  setSerieToDelete: (serieCode: string | null) => void;
}

type ParamsProps = {
  id: string;
};

interface IResponseDeleteSerie {
  _id: string;
  status: string;
}

export const ModalDeleteSerie: React.FC<IModalDeleteSerie> = ({
  serieToDelete,
  setSerieToDelete,
}) => {
  const { id } = useParams<ParamsProps>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const { t: translate } = useTranslation();

  async function handleDeleteSeriesFromIndicador() {
    try {
      setLoading(true);
      const { data } = await api.delete<IResponseDeleteSerie>(
        `/indicators/${id}/series/${serieToDelete}`,
      );
      if (data) {
        for (let i = 0; i < 10; i++) {
          const { data: dataTask } = await api.get<IResponseDeleteSerie>(
            `/tasks/${data._id}`,
          );

          await new Promise<void>((resolve) =>
            setTimeout(() => {
              resolve();
            }, 1000),
          );
          if (dataTask.status === 'processed') {
            setSerieToDelete(null);
            queryClient.removeQueries(['indicator data', id]);
            break;
          }
        }
      }
      setLoading(false);
      setError(true);
    } catch (err) {
      setLoading(false);
      setError(true);
    }
  }

  if (loading) {
    return (
      <ModalLoading
        visible
        style={{ padding: 0, width: '280px' }}
        data-testid="container-loading"
      />
    );
  }

  if (error) {
    return (
      <Modal
        visible
        setVisible={() => setSerieToDelete(null)}
        style={{ padding: 0 }}
      >
        <ContainerError data-testid="container-error">
          <XCircle size="3rem" />
          <h4>{translate('indicatorSeriesFailedToDeleteSeries')}</h4>
          <p>{translate('indicatorSeriesErrorDeleteSerie')}</p>
        </ContainerError>
        <FooterModalDelete>
          <Button
            buttonType="primary"
            onClick={() => setSerieToDelete(null)}
            data-testid="button-back"
            data-cy="button-back"
          >
            {translate('back')}
          </Button>
        </FooterModalDelete>
      </Modal>
    );
  }

  return (
    <Modal visible setVisible={() => setSerieToDelete(null)}>
      <Container data-testid="modal-delete-serie">
        <Warning size="2.5rem" />
        <h2>{translate('indicatorSeriesModalDelete')}</h2>
        <p>{translate('indicatorSeriesModalDeleteText')}</p>
      </Container>
      <ModalFooter>
        <Button
          buttonType="naked"
          onClick={() => setSerieToDelete(null)}
          data-testid="button-cancel"
          data-cy="button-cancel"
        >
          {translate('cancel')}
        </Button>
        <Button
          buttonType="primary"
          icon={<Trash size="1.125rem" />}
          onClick={handleDeleteSeriesFromIndicador}
          data-testid="button-delete"
          data-cy="button-delete"
        >
          {translate('delete')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
