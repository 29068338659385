import React from 'react';

import parseHTML from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { Card } from 'src/components/Card';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Tooltip } from 'react-tooltip';

import { ModelInfo as ModelInfos } from '../../types';
import {
  Container,
  ModelDescription,
  ModelInfoError,
  ParametersContainer,
} from './styles';

interface ModelInfoProps {
  selectedModel?: string;
  infos: ModelInfos;
  isLoading: boolean;
  isErrored: boolean;
}

export const ModelInfo: React.FC<ModelInfoProps> = ({
  selectedModel,
  infos,
  isLoading,
  isErrored,
}) => {
  const { t: translate } = useTranslation();

  return (
    <Container className="containerLinear">
      <Tooltip
        id="models-classification-model-info"
        className="customTooltipTheme"
      />

      <Card textCard={translate('modelInfoTitle')} />
      <ModelDescription>
        <p>
          {selectedModel &&
            (selectedModel.includes('Gradient Boosting')
              ? parseHTML(translate('gBoostDescription'))
              : selectedModel.includes('Logistic Regression')
              ? parseHTML(translate('logRegressionDescription'))
              : selectedModel.includes('SVM')
              ? parseHTML(translate('svmDescription'))
              : selectedModel.includes('XGBoost')
              ? parseHTML(translate('xGBoostDescription'))
              : selectedModel.includes('KNeighbors')
              ? parseHTML(translate('kneighborsDescription'))
              : selectedModel.includes('Random Forest')
              ? parseHTML(translate('randomForestDescription'))
              : selectedModel.includes('LightGBM')
              ? parseHTML(translate('lgbmDescription'))
              : undefined)}
        </p>
      </ModelDescription>
      <ParametersContainer>
        {isErrored ? (
          <ModelInfoError>{translate('modelInfoError')}</ModelInfoError>
        ) : !selectedModel ? (
          <ContainerSkeleton />
        ) : selectedModel.includes('Gradient Boosting') ? (
          <>
            <h3>{translate('hiperparameters')}</h3>
            <div>
              <div
                data-tooltip-id="models-classification-model-info"
                data-tooltip-html={translate('gBoostNEstimatorsTooltip')}
              >
                <h4>{translate('gBoostNEstimatorsLabel')}</h4>
                {isLoading ? (
                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      width: '70px',
                      height: '18px',
                      marginBottom: '16px',
                    }}
                  />
                ) : (
                  <p
                    data-testid="model-info-n-estimators"
                    data-cy="model-info-n-estimators"
                  >
                    {infos?.n_estimators ?? '--'}
                  </p>
                )}
              </div>
              <div
                data-tooltip-id="models-classification-model-info"
                data-tooltip-html={translate('minSamplesSplitTooltip')}
              >
                <h4>{translate('minSamplesSplitLabel')}</h4>
                {isLoading ? (
                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      width: '70px',
                      height: '18px',
                      marginBottom: '16px',
                    }}
                  />
                ) : (
                  <p
                    data-testid="model-info-min-samples-split"
                    data-cy="model-info-min-samples-split"
                  >
                    {infos?.min_samples_split ?? '--'}
                  </p>
                )}
              </div>
            </div>
            <div>
              <div
                data-tooltip-id="models-classification-model-info"
                data-tooltip-html={translate('gBoostMaxDepthTooltip')}
              >
                <h4>{translate('gBoostMaxDepthLabel')}</h4>
                {isLoading ? (
                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      width: '70px',
                      height: '18px',
                      marginBottom: '16px',
                    }}
                  />
                ) : (
                  <p
                    data-testid="model-info-max-depth"
                    data-cy="model-info-max-depth"
                  >
                    {infos?.max_depth ?? '--'}
                  </p>
                )}
              </div>
              <div
                data-tooltip-id="models-classification-model-info"
                data-tooltip-html={translate('gBoostMinSamplesLeafTooltip')}
              >
                <h4>{translate('gBoostMinSamplesLeafLabel')}</h4>
                {isLoading ? (
                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      width: '70px',
                      height: '18px',
                      marginBottom: '16px',
                    }}
                  />
                ) : (
                  <p
                    data-testid="model-info-min-samples-leaf"
                    data-cy="model-info-min-samples-leaf"
                  >
                    {infos?.min_samples_leaf ?? '--'}
                  </p>
                )}
              </div>
            </div>
            <div>
              <div
                data-tooltip-id="models-classification-model-info"
                data-tooltip-html={translate('gBoostMaxFeaturesTooltip')}
              >
                <h4>{translate('gBoostMaxFeaturesLabel')}</h4>
                {isLoading ? (
                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      width: '70px',
                      height: '18px',
                      marginBottom: '16px',
                    }}
                  />
                ) : (
                  <p
                    data-testid="model-info-max-features"
                    data-cy="model-info-max-features"
                  >
                    {infos?.max_features ?? '--'}
                  </p>
                )}
              </div>
            </div>
          </>
        ) : selectedModel.includes('Logistic Regression') ? (
          <>
            <h3>{translate('hiperparameters')}</h3>

            <div>
              <div
                data-tooltip-id="models-classification-model-info"
                data-tooltip-html={translate('logRPenaltyTooltip')}
              >
                <h4>{translate('logRPenaltyLabel')}</h4>
                {isLoading ? (
                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      width: '70px',
                      height: '18px',
                      marginBottom: '16px',
                    }}
                  />
                ) : (
                  <p
                    data-testid="model-info-penalty"
                    data-cy="model-info-penalty"
                  >
                    {infos?.penalty ?? '--'}
                  </p>
                )}
              </div>
              <div
                data-tooltip-id="models-classification-model-info"
                data-tooltip-html={translate('logRSolverTooltip')}
              >
                <h4>{translate('logRSolverLabel')}</h4>
                {isLoading ? (
                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      width: '70px',
                      height: '18px',
                      marginBottom: '16px',
                    }}
                  />
                ) : (
                  <p
                    data-testid="model-info-solver"
                    data-cy="model-info-solver"
                  >
                    {infos?.solver ?? '--'}
                  </p>
                )}
              </div>
              <div
                data-tooltip-id="models-classification-model-info"
                data-tooltip-html={translate('logRL1RatioTooltip')}
              >
                <h4>{translate('logRL1RatioLabel')}</h4>
                {isLoading ? (
                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      width: '70px',
                      height: '18px',
                      marginBottom: '16px',
                    }}
                  />
                ) : (
                  <p
                    data-testid="model-info-l1-ratio"
                    data-cy="model-info-l1-ratio"
                  >
                    {infos?.l1_ratio ?? '--'}
                  </p>
                )}
              </div>
            </div>
          </>
        ) : selectedModel === 'SVM' ? (
          <>
            <h3>{translate('hiperparameters')}</h3>
            <div>
              <div
                data-tooltip-id="models-classification-model-info"
                data-tooltip-html={translate('svmCTooltip')}
              >
                <h4>{translate('svmCLabel')}</h4>
                {isLoading ? (
                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      width: '70px',
                      height: '18px',
                      marginBottom: '16px',
                    }}
                  />
                ) : (
                  <p data-testid="model-info-c" data-cy="model-info-c">
                    {infos?.C ?? '--'}
                  </p>
                )}
              </div>
              <div
                data-tooltip-id="models-classification-model-info"
                data-tooltip-html={translate('svmKernelTooltip')}
              >
                <h4>{translate('svmKernelLabel')}</h4>
                {isLoading ? (
                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      width: '70px',
                      height: '18px',
                      marginBottom: '16px',
                    }}
                  />
                ) : (
                  <p
                    data-testid="model-info-kernel"
                    data-cy="model-info-kernel"
                  >
                    {infos?.kernel ?? '--'}
                  </p>
                )}
              </div>
              <div
                data-tooltip-id="models-classification-model-info"
                data-tooltip-html={translate('svmDegreTooltip')}
              >
                <h4>{translate('svmDegreLabel')}</h4>
                {isLoading ? (
                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      width: '70px',
                      height: '18px',
                      marginBottom: '16px',
                    }}
                  />
                ) : (
                  <p
                    data-testid="model-info-degree"
                    data-cy="model-info-degree"
                  >
                    {infos?.degree ?? '--'}
                  </p>
                )}
              </div>
              <div
                data-tooltip-id="models-classification-model-info"
                data-tooltip-html={translate('svmGammaTooltip')}
              >
                <h4>{translate('svmGammaLabel')}</h4>
                {isLoading ? (
                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      width: '70px',
                      height: '18px',
                      marginBottom: '16px',
                    }}
                  />
                ) : (
                  <p data-testid="model-info-gamma" data-cy="model-info-gamma">
                    {infos?.gamma ?? '--'}
                  </p>
                )}
              </div>
            </div>
          </>
        ) : selectedModel.includes('XGBoost') ? (
          <>
            <h3>{translate('hiperparameters')}</h3>
            <div>
              <div
                data-tooltip-id="models-classification-model-info"
                data-tooltip-html={translate('xgNEstimatorsTooltip')}
              >
                <h4>{translate('xgNEstimatorsLabel')}</h4>
                {isLoading ? (
                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      width: '70px',
                      height: '18px',
                      marginBottom: '16px',
                    }}
                  />
                ) : (
                  <p
                    data-testid="model-info-n-estimators"
                    data-cy="model-info-n-estimators"
                  >
                    {infos?.n_estimators ?? '--'}
                  </p>
                )}
              </div>
              <div
                data-tooltip-id="models-classification-model-info"
                data-tooltip-html={translate('xgMaxDepthTooltip')}
              >
                <h4>{translate('xgMaxDepthLabel')}</h4>
                {isLoading ? (
                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      width: '70px',
                      height: '18px',
                      marginBottom: '16px',
                    }}
                  />
                ) : (
                  <p
                    data-testid="model-info-max-depth"
                    data-cy="model-info-max-depth"
                  >
                    {infos?.max_depth ?? '--'}
                  </p>
                )}
              </div>
            </div>
            <div>
              <div
                data-tooltip-id="models-classification-model-info"
                data-tooltip-html={translate('xgMinChildWeightTooltip')}
              >
                <h4>{translate('xgMinChildWeightLabel')}</h4>
                {isLoading ? (
                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      width: '70px',
                      height: '18px',
                      marginBottom: '16px',
                    }}
                  />
                ) : (
                  <p
                    data-testid="model-info-min-child-weight"
                    data-cy="model-info-min-child-weight"
                  >
                    {infos?.min_child_weight ?? '--'}
                  </p>
                )}
              </div>
              <div
                data-tooltip-id="models-classification-model-info"
                data-tooltip-html={translate('xgBoosterTooltip')}
              >
                <h4>{translate('xgBoosterLabel')}</h4>
                {isLoading ? (
                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      width: '70px',
                      height: '18px',
                      marginBottom: '16px',
                    }}
                  />
                ) : (
                  <p
                    data-testid="model-info-booster"
                    data-cy="model-info-booster"
                  >
                    {infos?.booster ?? '--'}
                  </p>
                )}
              </div>
            </div>
            <div>
              <div
                data-tooltip-id="models-classification-model-info"
                data-tooltip-html={translate('xgRegAlphatTooltip')}
              >
                <h4>{translate('xgRegAlphatLabel')}</h4>
                {isLoading ? (
                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      width: '70px',
                      height: '18px',
                      marginBottom: '16px',
                    }}
                  />
                ) : (
                  <p
                    data-testid="model-info-reg-alpha"
                    data-cy="model-info-reg-alpha"
                  >
                    {infos?.reg_alpha ?? '--'}
                  </p>
                )}
              </div>
              <div
                data-tooltip-id="models-classification-model-info"
                data-tooltip-html={translate('xgRegLambdaTooltip')}
              >
                <h4>{translate('xgRegLambdaLabel')}</h4>
                {isLoading ? (
                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      width: '70px',
                      height: '18px',
                      marginBottom: '16px',
                    }}
                  />
                ) : (
                  <p
                    data-testid="model-info-reg-lambd"
                    data-cy="model-info-reg-lambd"
                  >
                    {infos?.reg_lambd ?? '--'}
                  </p>
                )}
              </div>
            </div>
          </>
        ) : selectedModel.includes('KNeighbors') ? (
          <>
            <h3>{translate('hiperparameters')}</h3>
            <div>
              <div
                data-tooltip-id="models-classification-model-info"
                data-tooltip-html={translate('knNNeighborsTooltip')}
              >
                <h4>{translate('knNNeighborsLabel')}</h4>
                {isLoading ? (
                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      width: '70px',
                      height: '18px',
                      marginBottom: '16px',
                    }}
                  />
                ) : (
                  <p
                    data-testid="model-info-n-neighbors"
                    data-cy="model-info-n-neighbors"
                  >
                    {infos?.n_neighbors ?? '--'}
                  </p>
                )}
              </div>
              <div
                data-tooltip-id="models-classification-model-info"
                data-tooltip-html={translate('knWeightsTooltip')}
              >
                <h4>{translate('knWeightsLabel')}</h4>
                {isLoading ? (
                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      width: '70px',
                      height: '18px',
                      marginBottom: '16px',
                    }}
                  />
                ) : (
                  <p
                    data-testid="model-info-weights"
                    data-cy="model-info-weights"
                  >
                    {infos?.weights ?? '--'}
                  </p>
                )}
              </div>
              <div
                data-tooltip-id="models-classification-model-info"
                data-tooltip-html={translate('knPTooltip')}
              >
                <h4>{translate('knPLabel')}</h4>
                {isLoading ? (
                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      width: '70px',
                      height: '18px',
                      marginBottom: '16px',
                    }}
                  />
                ) : (
                  <p data-testid="model-info-p" data-cy="model-info-p">
                    {infos?.p ?? '--'}
                  </p>
                )}
              </div>
            </div>
          </>
        ) : selectedModel.includes('Random Forest') ? (
          <>
            <h3>{translate('hiperparameters')}</h3>
            <div>
              <div
                data-tooltip-id="models-classification-model-info"
                data-tooltip-html={translate('rfMaxFeaturesTooltip')}
              >
                <h4>{translate('rfMaxFeaturesLabel')}</h4>
                {isLoading ? (
                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      width: '70px',
                      height: '18px',
                      marginBottom: '16px',
                    }}
                  />
                ) : (
                  <p
                    data-testid="model-info-max-features"
                    data-cy="model-info-max-features"
                  >
                    {infos?.max_features ?? '--'}
                  </p>
                )}
              </div>
              <div
                data-tooltip-id="models-classification-model-info"
                data-tooltip-html={translate('rfMinSamplesLeafTooltip')}
              >
                <h4>{translate('rfMinSamplesLeafLabel')}</h4>
                {isLoading ? (
                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      width: '70px',
                      height: '18px',
                      marginBottom: '16px',
                    }}
                  />
                ) : (
                  <p
                    data-testid="model-info-min-samples-leaf"
                    data-cy="model-info-min-samples-leaf"
                  >
                    {infos?.min_samples_leaf ?? '--'}
                  </p>
                )}
              </div>
            </div>
            <div>
              <div
                data-tooltip-id="models-classification-model-info"
                data-tooltip-html={translate('rfNEstimatorsTooltip')}
              >
                <h4>{translate('rfNEstimatorsLabel')}</h4>
                {isLoading ? (
                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      width: '70px',
                      height: '18px',
                      marginBottom: '16px',
                    }}
                  />
                ) : (
                  <p
                    data-testid="model-info-n-estimators"
                    data-cy="model-info-n-estimators"
                  >
                    {infos?.n_estimators ?? '--'}
                  </p>
                )}
              </div>
              <div
                data-tooltip-id="models-classification-model-info"
                data-tooltip-html={translate('rfMaxSamplesTooltip')}
              >
                <h4>{translate('rfMaxSamplesLabel')}</h4>
                {isLoading ? (
                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      width: '70px',
                      height: '18px',
                      marginBottom: '16px',
                    }}
                  />
                ) : (
                  <p
                    data-testid="model-info-max-samples"
                    data-cy="model-info-max-samples"
                  >
                    {infos?.max_samples ?? '--'}
                  </p>
                )}
              </div>
              <div
                data-tooltip-id="models-classification-model-info"
                data-tooltip-html={translate('rfMaxDepthTooltip')}
              >
                <h4>{translate('rfMaxDepthLabel')}</h4>
                {isLoading ? (
                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      width: '70px',
                      height: '18px',
                      marginBottom: '16px',
                    }}
                  />
                ) : (
                  <p
                    data-testid="model-info-max-depth"
                    data-cy="model-info-max-depth"
                  >
                    {infos?.max_depth ?? '--'}
                  </p>
                )}
              </div>
            </div>
          </>
        ) : (
          selectedModel.includes('LightGBM') && (
            <>
              <h3>{translate('hiperparameters')}</h3>
              <div>
                <div
                  data-tooltip-id="models-classification-model-info"
                  data-tooltip-html={translate('lgbmSubSampleTooltip')}
                >
                  <h4>{translate('lgbmSubSampleLabel')}</h4>
                  {isLoading ? (
                    <ContainerSkeleton
                      withLoading={false}
                      style={{
                        width: '70px',
                        height: '18px',
                        marginBottom: '16px',
                      }}
                    />
                  ) : (
                    <p
                      data-testid="model-info-subsample"
                      data-cy="model-info-subsample"
                    >
                      {infos?.subsample ?? '--'}
                    </p>
                  )}
                </div>
                <div
                  data-tooltip-id="models-classification-model-info"
                  data-tooltip-html={translate('lgbmSubSampleFreqTooltip')}
                >
                  <h4>{translate('lgbmSubSampleFreqLabel')}</h4>
                  {isLoading ? (
                    <ContainerSkeleton
                      withLoading={false}
                      style={{
                        width: '70px',
                        height: '18px',
                        marginBottom: '16px',
                      }}
                    />
                  ) : (
                    <p
                      data-testid="model-info-subsample-freq"
                      data-cy="model-info-subsample-freq"
                    >
                      {infos?.subsample_freq ?? '--'}
                    </p>
                  )}
                </div>
              </div>
              <div>
                <div
                  data-tooltip-id="models-classification-model-info"
                  data-tooltip-html={translate('lgbmNumLeavesTooltip')}
                >
                  <h4>{translate('lgbmNumLeavesLabel')}</h4>
                  {isLoading ? (
                    <ContainerSkeleton
                      withLoading={false}
                      style={{
                        width: '70px',
                        height: '18px',
                        marginBottom: '16px',
                      }}
                    />
                  ) : (
                    <p
                      data-testid="model-info-num-leaves"
                      data-cy="model-info-num-leaves"
                    >
                      {infos?.num_leaves ?? '--'}
                    </p>
                  )}
                </div>
                <div
                  data-tooltip-id="models-classification-model-info"
                  data-tooltip-html={translate('lgbmMinChildSamplesTooltip')}
                >
                  <h4>{translate('lgbmMinChildSamplesLabel')}</h4>
                  {isLoading ? (
                    <ContainerSkeleton
                      withLoading={false}
                      style={{
                        width: '70px',
                        height: '18px',
                        marginBottom: '16px',
                      }}
                    />
                  ) : (
                    <p
                      data-testid="model-info-min-child-samples"
                      data-cy="model-info-min-child-samples"
                    >
                      {infos?.min_child_samples ?? '--'}
                    </p>
                  )}
                </div>
                <div
                  data-tooltip-id="models-classification-model-info"
                  data-tooltip-html={translate('lgbmNEstimatorsTooltip')}
                >
                  <h4>{translate('lgbmNEstimatorsLabel')}</h4>
                  {isLoading ? (
                    <ContainerSkeleton
                      withLoading={false}
                      style={{
                        width: '70px',
                        height: '18px',
                        marginBottom: '16px',
                      }}
                    />
                  ) : (
                    <p
                      data-testid="model-info-n-estimators"
                      data-cy="model-info-n-estimators"
                    >
                      {infos?.n_estimators ?? '--'}
                    </p>
                  )}
                </div>
              </div>
              <div>
                <div
                  data-tooltip-id="models-classification-model-info"
                  data-tooltip-html={translate('lgbmRegAlphaLabel')}
                >
                  <h4>{translate('lgbmRegAlpha')}</h4>
                  {isLoading ? (
                    <ContainerSkeleton
                      withLoading={false}
                      style={{
                        width: '70px',
                        height: '18px',
                        marginBottom: '16px',
                      }}
                    />
                  ) : (
                    <p
                      data-testid="model-info-reg-alpha"
                      data-cy="model-info-reg-alpha"
                    >
                      {infos?.reg_alpha ?? '--'}
                    </p>
                  )}
                </div>
                <div
                  data-tooltip-id="models-classification-model-info"
                  data-tooltip-html={translate('lgbmLambdaLabel')}
                >
                  <h4>{translate('lgbmLambda')}</h4>
                  {isLoading ? (
                    <ContainerSkeleton
                      withLoading={false}
                      style={{
                        width: '70px',
                        height: '18px',
                        marginBottom: '16px',
                      }}
                    />
                  ) : (
                    <p
                      data-testid="model-info-reg-lambda"
                      data-cy="model-info-reg-lambda"
                    >
                      {infos?.reg_lambda ?? '--'}
                    </p>
                  )}
                </div>
              </div>
            </>
          )
        )}
      </ParametersContainer>
    </Container>
  );
};
