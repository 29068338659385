import styled from 'styled-components';

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  gap: 2rem;

  padding: 1.5rem;

  margin: 1.5rem -1.5rem -1.5rem -1.5rem;

  border-top: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 0 0 8px 8px;

  background: ${({ theme }) => theme.colors.gray0};

  .naked-button {
    padding: 0 !important;
  }

  .naked-button-blue {
    color: ${({ theme }) => theme.colors.primary};

    transition: all 0.2s;

    &:hover {
      color: ${({ theme }) => theme.colors.primaryDark};
    }
  }
`;
