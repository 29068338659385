const arimaDispersionEN = {
  arimaDispersionPageTitle: 'ARIMA | Model Dispersion',
  arimaDispersionTitle: 'ARIMA - Model Dispersion',
  arimaDispersionDescription:
    'Explore forecasts and parameter estimations from several models.',

  arimaDispersionTitlePanelDeterminants: 'Panel of Determinants',
  arimaDispersionDescriptionPanelDeterminants:
    'Explore how different models evaluate factors that can help explain the phenomenon of interest. Check the estimated parameter values and their statistical significance.',
  arimaDispersionSelectLabelPanelDeterminants: 'Show N models',
  arimaDispersionVariablesLabelPanelDeterminants: 'Variables',
  arimaDispersionVariableLabelPanelDeterminants: 'Variable',
  arimaDispersionMetricsLabelPanelDeterminants: 'Metrics',
  arimaDispersionSignificanceLabelPanelDeterminants: 'Significance',
  arimaDispersionModelLabelPanelDeterminants: 'Model',
  arimaDispersionModelsLabelPanelDeterminants: 'Models',

  arimaDispersionTitleForecastDispersion: 'Forecast Dispersion',
  arimaDispersionDescriptionForecastDispersion:
    'Visualize the predictions from the best models available.',

  arimaDispersionDate: 'Date',
  arimaDispersionValue: 'Value',
  arimaNoExplanatoryVariableWasFound: 'No explanatory variable was found.',
};

export default arimaDispersionEN;
