import React, { useEffect, useState } from 'react';

import { MagnifyingGlass, PencilSimple, Plus, Trash } from 'phosphor-react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { Button } from 'src/components/Button';
import { Card } from 'src/components/Card';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Head } from 'src/components/Head';
import { Input } from 'src/components/Input';
import { Table, Tbody, Td, Th, Thead, Tr } from 'src/components/Table';
import api from 'src/feature-store/service/api';
import { RootState } from 'src/redux/store';
import { Pagination } from 'src/components/Pagination';
import { useTranslation } from 'react-i18next';

import { ModalCreateIndicator } from './components/ModalCreateIndicator';
import {
  ButtonIcon,
  Container,
  ContainerTable,
  Content,
  ContentSearchAndButtonCreate,
} from './styles';
import { AdminIndicators, DataAdminIndicators } from './types';
import { ContainerWithoutGroups } from '../Groups/styles';

interface ErrorProps {
  message: string;
  quantityLetters: number;
}

const QUANTITY_ITEMS_PAGE = 20;

export const Admin: React.FC = () => {
  const [height, setHeight] = useState(window.innerHeight);
  const [modalVisible, setModalVisible] = useState(false);
  const [indicators, setIndicators] = useState<AdminIndicators>();
  const [indicatorInformationToEdit, setIndicatorInformationToEdit] =
    useState<DataAdminIndicators | null>(null);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchError, setSearchError] = useState<ErrorProps>();
  const [searchTimer, setSearchTimer] = useState(1000);
  const [timeOutActive, setTimeOutActive] = useState(false);
  const [requestAllowed, setRequestAllowed] = useState(true);
  const [lastSearch, setLastSearch] = useState('');
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const { language } = useSelector((state: RootState) => state.auth.user);
  useEffect(() => {
    function handleResize() {
      setHeight(window.innerHeight);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      modalVisible
        ? (body.style.overflow = 'hidden')
        : (body.style.overflow = 'auto');
    }
  }, [modalVisible]);

  const {
    data: indicatorsData,
    isLoading,
    isFetching,
    isError,
  } = useQuery(
    ['admin', 'indicators', page, searchValue],
    async () => {
      const searchQuery = searchValue;

      const { data } = await api.get<AdminIndicators>(
        searchValue.length >= 3
          ? `/indicators?text=${searchQuery}&skip=${
              (page - 1) * QUANTITY_ITEMS_PAGE
            }&limit=${QUANTITY_ITEMS_PAGE}`
          : `/indicators?skip=${
              (page - 1) * QUANTITY_ITEMS_PAGE
            }&limit=${QUANTITY_ITEMS_PAGE}`,
      );

      return data;
    },
    {
      staleTime: 1000 * 60,
      enabled: requestAllowed || searchValue === '',
    },
  );

  useEffect(() => {
    if (indicatorsData) {
      setIndicators(indicatorsData);
    }
    setRequestAllowed(false);
    setTimeOutActive(false);
    setSearchTimer(1000);
    setLastSearch(searchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indicatorsData]);

  useEffect(() => {
    if (timeOutActive && searchValue.length >= 3) {
      setTimeout(() => {
        if (searchTimer > 0) {
          setSearchTimer(searchTimer - 1000);
        } else {
          setTimeOutActive(false);
        }
      }, 1000);
    } else {
      searchTimer === 0 && setRequestAllowed(true);
    }
  }, [searchTimer, searchValue, timeOutActive]);

  function handleSearchIndicator(value: string) {
    setSearchValue(value);

    if (value.length > 50) {
      setSearchError({
        message: 'searchMaxCharactersError',
        quantityLetters: 50,
      });
      return;
    }

    if (value.length < 3 && value.length > 0) {
      setSearchError({
        message: 'searchMinCharactersError',
        quantityLetters: 3,
      });
      return;
    }
    setSearchError({
      message: '',
      quantityLetters: 0,
    });

    if (value !== searchValue) {
      setSearchTimer(1000);
      setTimeOutActive(true);

      value.length >= 3 && setPage(1);
    }
  }

  const handleChangePage = (pageAux: number): void => {
    setRequestAllowed(true);
    setPage(pageAux);
  };

  return (
    <Container data-testid="container-modal-create-indicators">
      <Head title="Feature Store Admin" />
      <Tooltip id="feature-store-admin" className="customTooltipTheme" />
      <Card
        textCard="Feature Store Admin"
        textDescription={translate('adminDescription')}
        className="containerLinear"
      />
      <Content className="containerLinear">
        <Card
          textCard={translate('adminIndicators')}
          textDescription={translate('adminDescriptionList')}
        />
        <ContentSearchAndButtonCreate>
          <Input
            placeholder={translate('adminSearchIndicator')}
            icon={<MagnifyingGlass size="1rem" />}
            onChange={(event) => {
              handleSearchIndicator(event.target.value);
              setPage(1);
            }}
            error={
              searchError?.quantityLetters === 3
                ? translate(searchError.message).replace('XX', '3')
                : searchError?.quantityLetters === 50
                ? translate(searchError.message).replace('XX', '50')
                : undefined
            }
          />
          <Button
            buttonType="primary"
            icon={<Plus size="1.125rem" />}
            onClick={() => setModalVisible(true)}
            data-testid="button-criar-novo-indicador"
            data-cy="button-criar-novo-indicador"
          >
            {translate('adminCreateNewIndicator').replace('XX', lastSearch)}
          </Button>
        </ContentSearchAndButtonCreate>
        {isError ? (
          <ContainerMaintenance
            content="table"
            data-testid="container-maintenance"
          />
        ) : indicators?.total === 0 && searchValue.length >= 1 ? (
          <ContainerWithoutGroups>
            <ContainerMaintenance
              content="project"
              text={translate('adminFailedSearchIndicator').replace(
                'XX',
                lastSearch,
              )}
            />
          </ContainerWithoutGroups>
        ) : (
          <ContainerTable style={{ height: `${height - 240}px` }}>
            <Table data-testid="table">
              <Thead>
                <Tr>
                  <Th>
                    <p>{translate('adminCode')}</p>
                  </Th>
                  <Th>
                    <p>{translate('adminName')}</p>
                  </Th>
                  <Th>
                    <p>{translate('adminShortName')}</p>
                  </Th>
                  <Th>
                    <p>{translate('adminShortDescription')}</p>
                  </Th>
                  <Th>
                    <p>{translate('adminFullDescription')}</p>
                  </Th>
                  <Th>
                    <p>{translate('adminCountry')}</p>
                  </Th>
                  <Th>
                    <p>{translate('adminSource')}</p>
                  </Th>
                  <Th>
                    <p>{translate('adminProjection')}</p>
                  </Th>
                  <Th>
                    <p>{translate('adminSector')}</p>
                  </Th>
                  <Th>
                    <p>{translate('adminRegion')}</p>
                  </Th>
                  <Th>
                    <p>{translate('adminAccessType')}</p>
                  </Th>
                  <Th>
                    <p>{translate('adminAGPER')}</p>
                  </Th>
                  <Th>
                    <p>{translate('adminTP')}</p>
                  </Th>
                  <Th>
                    <p>{translate('adminTS')}</p>
                  </Th>
                  <Th>
                    <p>{translate('adminActive')}</p>
                  </Th>
                  <Th>
                    <p>{translate('adminActions')}</p>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {isLoading || isFetching || !indicators
                  ? Array.from({ length: 25 }, (_, numberLoadingTr) => (
                      // eslint-disable-next-line react/jsx-indent
                      <Tr
                        key={`loading-tr-${numberLoadingTr + 1}`}
                        data-testid={`loading-tr-${numberLoadingTr + 1}`}
                      >
                        {Array.from({ length: 15 }, (__, numberLoadingTd) => (
                          // eslint-disable-next-line prettier/prettier
                          <Td
                            key={`loading-td-${numberLoadingTr + 1}-${
                              numberLoadingTd + 1
                            }`}
                            data-testid={`loading-td-${numberLoadingTr + 1}-${
                              numberLoadingTd + 1
                            }`}
                          >
                            <ContainerSkeleton
                              withLoading={false}
                              style={{
                                height: '21px',
                              }}
                            />
                          </Td>
                        ))}
                        <Td>
                          <div>
                            <ContainerSkeleton
                              withLoading={false}
                              style={{
                                height: '20px',
                                width: '20px',
                                float: 'right',
                                marginLeft: '16px',
                                marginRight: '24px',
                              }}
                            />
                            <ContainerSkeleton
                              withLoading={false}
                              style={{
                                height: '20px',
                                width: '20px',
                                float: 'right',
                                marginLeft: '16px',
                              }}
                            />

                            <ContainerSkeleton
                              withLoading={false}
                              style={{
                                height: '20px',
                                width: '20px',
                                float: 'right',
                              }}
                            />
                          </div>
                        </Td>
                      </Tr>
                    ))
                  : indicators.data.map((indicator, index) => (
                      // eslint-disable-next-line react/jsx-indent
                      <Tr key={`indicator-${index + 1}`}>
                        <Td
                          data-cy={`indicator-code-${indicator.indicator_code}`}
                        >
                          {indicator?.indicator_code ?? '--'}
                        </Td>
                        <Td>
                          {indicator?.name?.[language] ??
                            indicator?.name?.['en-us'] ??
                            '--'}
                        </Td>
                        <Td>
                          {indicator?.short_name?.[language] ??
                            indicator?.short_name?.['en-us'] ??
                            '--'}
                        </Td>
                        <Td>
                          <p
                            data-tooltip-id="feature-store-admin"
                            data-tooltip-html={
                              indicator?.description?.[language] ??
                              indicator?.description?.['en-us']
                            }
                          >
                            {indicator?.description?.[language] ??
                              indicator?.description?.['en-us'] ??
                              '--'}
                          </p>
                        </Td>
                        <Td>
                          <p
                            data-tooltip-id="feature-store-admin"
                            data-tooltip-html={
                              indicator?.description_full?.[language] ??
                              indicator?.description_full?.['en-us']
                            }
                          >
                            {indicator?.description_full?.[language] ??
                              indicator?.description_full?.['en-us'] ??
                              '--'}
                          </p>
                        </Td>

                        <Td>{indicator?.country ?? '--'}</Td>
                        <Td>
                          {indicator?.source?.[language] ??
                            indicator?.source?.['en-us'] ??
                            '--'}
                        </Td>
                        <Td>
                          {indicator?.projections === '' ||
                          indicator?.projections === ' '
                            ? '--'
                            : indicator?.projections}
                        </Td>
                        <Td>{indicator?.sector?.code ?? '--'}</Td>
                        <Td>
                          {indicator?.region?.length
                            ? Array.from(
                                new Set(
                                  indicator?.region?.map(
                                    (region) => region.code,
                                  ),
                                ),
                              ).toString()
                            : '--'}
                        </Td>
                        <Td>
                          {indicator?.access_type === 'default'
                            ? 'Básico'
                            : indicator?.access_type === 'premium'
                            ? 'Premium'
                            : indicator?.access_type === 'freemium'
                            ? 'Freemium'
                            : indicator?.access_type === 'private'
                            ? 'Privado'
                            : '--'}
                        </Td>
                        <Td>
                          {indicator?.transformations?.length
                            ? Array.from(
                                new Set(
                                  indicator?.transformations?.map(
                                    (transformation) =>
                                      transformation?.aggregation?.code,
                                  ),
                                ),
                              ).toString()
                            : '--'}
                        </Td>
                        <Td>
                          {indicator?.transformations?.length
                            ? Array.from(
                                new Set(
                                  indicator?.transformations?.map(
                                    (transformation) =>
                                      transformation?.primary_transformation
                                        ?.code,
                                  ),
                                ),
                              ).toString()
                            : '--'}
                        </Td>
                        <Td>
                          {indicator?.transformations?.length
                            ? Array.from(
                                new Set(
                                  indicator?.transformations?.map(
                                    (transformation) =>
                                      transformation?.secondary_transformation
                                        ?.code,
                                  ),
                                ),
                              ).toString()
                            : '--'}
                        </Td>
                        <Td>
                          {indicator.is_active !== undefined
                            ? indicator.is_active
                              ? 'SIM'
                              : 'NÃO'
                            : '--'}
                        </Td>
                        <Td>
                          <div>
                            <ButtonIcon
                              disabled
                              style={{
                                marginLeft: '1rem',
                                marginRight: '1.5rem',
                              }}
                            >
                              <Trash size="1.25rem" />
                            </ButtonIcon>
                            <ButtonIcon
                              data-testid={`button-edit-${indicator.indicator_code}`}
                              data-cy={`button-edit-${indicator.indicator_code}`}
                              onClick={() => {
                                setIndicatorInformationToEdit(indicator);
                                setModalVisible(true);
                              }}
                              style={{ marginLeft: '16px' }}
                            >
                              <PencilSimple size="1.25rem" />
                            </ButtonIcon>
                            <ButtonIcon
                              data-cy={`button-to-view-${indicator.indicator_code}`}
                            >
                              <MagnifyingGlass
                                size="1.25rem"
                                onClick={() =>
                                  navigate(
                                    `/feature-store/admin/${indicator.indicator_code}`,
                                  )
                                }
                              />
                            </ButtonIcon>
                          </div>
                        </Td>
                      </Tr>
                    ))}
              </Tbody>
            </Table>
          </ContainerTable>
        )}
        {indicators?.total && (
          <Pagination
            page={page}
            setPage={handleChangePage}
            total={indicators.total}
            quantityItemsPerPage={QUANTITY_ITEMS_PAGE}
            name={translate('adminIndicators')}
          />
        )}
      </Content>
      {modalVisible && (
        <ModalCreateIndicator
          visible={modalVisible}
          setVisible={() => {
            setModalVisible(false);
            setIndicatorInformationToEdit(null);
          }}
          indicatorInformationToEdit={indicatorInformationToEdit}
          searchValue={searchValue}
          page={page}
          setRequestAllowed={setRequestAllowed}
        />
      )}
    </Container>
  );
};
