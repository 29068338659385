import styled from 'styled-components';

export const ContainerRenameVariables = styled.div`
  width: 50rem;
  padding: 1.5rem 1.5rem 0;

  > h4 {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
    color: ${({ theme }) => theme.colors.gray6};
    margin-bottom: 0.5rem;
  }
  > p {
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray5};
    margin-bottom: 1.5rem;
  }
`;

export const ContentVariables = styled.form`
  max-height: 40vh;
  overflow-y: auto;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem 1.5rem;

  padding-right: 0.5rem;
`;
