const modelExplorerPTBRTranslations = {
  claasModelListDescription:
    'Lista de modelos organizados pelo ROC AUC Score. Acesse as informações mais relevantes do seu modelo, obtenha previsões e muito mais.',
  modelListErrorMessage: 'Não foi possível carregar a lista de modelos.',
  accuracyMetricsTitle: 'Performance',
  accuracyMetricsDescription:
    'Avalie as métricas essenciais do modelo escolhido.',
  accuracyMetricsAccuracy: 'Acurácia',
  accuracyMetricsPrecision: 'Precisão',
  modelPerformanceInfo:
    'As métricas são calculadas por meio de um método denominado repeated stratified k-fold cross-validation, que envolve a divisão dos dados em vários subconjuntos aleatórios, garantindo que a distribuição das classes seja semelhante em cada um deles. Esse processo é repetido várias vezes para garantir que o desempenho do modelo seja consistente em diferentes subconjuntos de dados.',
  featureImportanceTitle: 'Importância das variáveis',
  featureImportanceDescr:
    'Entenda quais são as variáveis mais relevantes para seu modelo.',
  featureImportanceAlert:
    'Para este tipo de modelo essa métrica não é calculada',
  claasModelToProductionTitle: 'Modelo para produção',
  claasModelToProductionDescr:
    'Otimize sua tomada de decisão com base nos resultados de seu modelo favorito. Acesse as informações mais relevantes do seu modelo, obtenha previsões e muito mais.',
  classModelToProductionButton: 'Enviar para produção',
  claasSendToProdFailMessage:
    'Ocorreu um erro ao enviar o modelo para produção.',
};

export default modelExplorerPTBRTranslations;
