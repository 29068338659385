const FilterIndicatorSeriesPTBRTranslations = {
  filterSelectOpenings: 'Selecione as aberturas',
  filterFinishedContinue:
    'Quando finalizar, clique em "Continuar" para selecionar um grupo',
  filterRegion: 'Região',
  filterFrequency: 'Frequência',
  filterPrimaryTransformation: 'Transformação primária',
  filterSecondaryTransformation: 'Transformação secundária',
  filterWarningRegionNoOpening: 'XXX não possui a abertura YYY',
  filterNumberSeriesSelected:
    'Você selecionou <b>XXX</b> séries de <b>YYY</b> disponíveis nesse indicador',
  filterButtonCancel: 'Cancelar',
  filterButtonContinue: 'Continuar',
};

export default FilterIndicatorSeriesPTBRTranslations;
