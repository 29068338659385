import React, { useEffect, useRef, useState } from 'react';

import { Label } from 'src/components/Label';
import { CaretDown, MagnifyingGlass, X } from 'phosphor-react';
import { Input } from 'src/components/Input';
import { Spinner } from 'src/components/Button/styles';
import { useTranslation } from 'react-i18next';
import { CheckBox } from 'src/components/CheckBox';
import { Tooltip } from 'react-tooltip';
import light from 'src/styles/themes/light';
import { RadioButton } from 'src/components/RadioButton';

import {
  ActionsContainer,
  Container,
  InputContainer,
  MenuContainer,
  NoOption,
  Option,
  OptionsContainer,
  OptionsMenu,
  RadioContainer,
  SelectedContainer,
} from './styles';
import { HierarchicalSelectProps, MenuItemProps, Options } from './types';

export const HierarchicalSelect: React.FC<HierarchicalSelectProps> = ({
  label,
  placeholder,
  isRadioButton = false,
  selected,
  setSelected,
  options,
  optionsDisabled,
  loadingOptions = false,
  loadingSelectedData = false,
  optionAdded,
  optionRemoved,
  maxNumberOptionsSelected,
  isDisabled = false,
  ...props
}) => {
  const selectedContainerRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const [openMenu, setOpenMenu] = useState(false);
  const [openOptions, setOpenOptions] = useState<string[]>([]);
  const [optionsSearched, setOptionsSearched] = useState<Options[]>([]);

  const [search, setSearch] = useState('');

  const { t: translate } = useTranslation();

  const handleOpenCloseMenuItem = (tree: string) => {
    if (openOptions.includes(tree)) {
      setOpenOptions(openOptions.filter((option) => option !== tree));
    } else {
      setOpenOptions([...openOptions, tree]);
    }
  };

  const handleClickOption = (
    tree: string,
    optionLabel: string,
    optionValue: string[],
    canSelect: boolean,
  ) => {
    if (canSelect) {
      const alreadySelected = selected?.find(
        (option) => option.value.toString() === optionValue.toString(),
      );

      if (alreadySelected) {
        optionRemoved({ label: optionLabel, value: optionValue });
      } else {
        optionAdded({ label: optionLabel, value: optionValue });
      }
    } else {
      handleOpenCloseMenuItem(tree);
    }
  };

  const handleOpenMenu = () => {
    if (!loadingOptions && !isDisabled) {
      setOpenMenu(!openMenu);
    }
  };

  const MenuItem = ({
    subMenu,
    level,
    optionLabel,
    optionValue,
    treeLabel,
    treeValue,
    canSelect,
    disabled,
  }: MenuItemProps) => {
    const treeString = JSON.stringify(treeValue);

    const currentTreeDataTestid = treeValue
      .toString()
      .replaceAll(' ', '-')
      .replaceAll(',', '-')
      .toLowerCase();

    const canSelectOption = canSelect || !subMenu.length;

    const optionIsSelected = !!selected?.find(
      ({ value }) => JSON.stringify(value) === treeString,
    );

    const selectedMaxNumber =
      selected?.length === maxNumberOptionsSelected &&
      !optionIsSelected &&
      canSelectOption;

    const optionIsDisabled =
      disabled || optionsDisabled?.includes(treeString) || selectedMaxNumber;

    const isMarketShare = treeValue.includes('Market Share (%)');

    return (
      <OptionsContainer
        data-testid={`group-${currentTreeDataTestid}`}
        style={{
          display:
            openOptions.includes(
              JSON.stringify(treeValue.slice(0, treeValue.length - 1)),
            ) || level === 1
              ? 'flex'
              : 'none',
        }}
      >
        <Option
          key={`${level}${optionValue}`}
          data-testid={`level-${level}-option-${currentTreeDataTestid}`}
        >
          <RadioContainer
            onClick={() =>
              handleClickOption(
                treeString,
                subMenu.length || isMarketShare
                  ? treeLabel.toString()
                  : optionLabel,
                treeValue,
                canSelectOption,
              )
            }
            disabled={optionIsDisabled}
            data-tooltip-id="hierarchical-select-tooltip"
            data-tooltip-html={
              selectedMaxNumber && maxNumberOptionsSelected
                ? translate(
                    'workspaceOverviewResultsComparisonSelectOnly4Series',
                  ).replace('XXX', maxNumberOptionsSelected.toString())
                : ''
            }
            style={{ paddingLeft: `${(level * 16) / 16}rem` }}
            data-testid={`checkbox-container-${optionValue
              .replaceAll(' ', '-')
              .toLowerCase()}`}
          >
            {canSelectOption ? (
              isRadioButton ? (
                <RadioButton
                  label={optionLabel}
                  defaultChecked={optionIsSelected}
                  disabled={optionIsDisabled}
                />
              ) : (
                <CheckBox
                  label={optionLabel}
                  defaultChecked={optionIsSelected}
                  disabled={optionIsDisabled}
                />
              )
            ) : (
              <p
                data-testid={`without-checkbox-${optionValue
                  .replaceAll(' ', '-')
                  .toLowerCase()}`}
              >
                {optionLabel}
              </p>
            )}
          </RadioContainer>

          {!!subMenu.length && (
            <button
              type="button"
              onClick={() => handleOpenCloseMenuItem(treeString)}
              className={
                openOptions.includes(treeString) ? 'close-menu' : 'open-menu'
              }
              data-testid={`show-${currentTreeDataTestid}-children`}
              aria-label="close/open menu button"
            >
              <CaretDown />
            </button>
          )}
        </Option>

        {!!subMenu.length &&
          subMenu.map((option) => (
            <MenuItem
              key={`${option.level}${option.value}`}
              subMenu={option.children}
              optionValue={option.value}
              optionLabel={option.label}
              level={option.level}
              treeValue={[...treeValue, option.value]}
              treeLabel={[...treeLabel, option.label]}
              canSelect={!!option.canSelect}
              disabled={!!option.disabled}
            />
          ))}
      </OptionsContainer>
    );
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (
        !selectedContainerRef?.current?.contains(e?.target as Node) &&
        !menuRef?.current?.contains(e?.target as Node)
      ) {
        setOpenMenu(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, []);

  useEffect(() => {
    const filterOptions = (currentOption: Options[]): Options[] =>
      currentOption
        .map((option) => {
          const filteredChildren = filterOptions(option.children);

          if (
            option.label.toLowerCase().includes(search) ||
            filteredChildren?.length > 0
          ) {
            return {
              ...option,
              children:
                filteredChildren.length > 0
                  ? filteredChildren
                  : option.children,
            } as Options;
          }
          return {} as Options;
        })
        .filter((option) => !!option?.label);

    if (!search) {
      setOptionsSearched(options);
    } else {
      setOptionsSearched(filterOptions(options));
    }
  }, [search, options]);

  return (
    <Container
      {...props}
      style={{ marginBottom: openMenu && isRadioButton ? '18rem' : '0rem' }}
    >
      <Tooltip
        id="hierarchical-select-tooltip"
        className="customTooltipTheme"
      />

      <Label data-testid="select-label">{label}</Label>

      <SelectedContainer
        ref={selectedContainerRef}
        loading={loadingOptions}
        style={{
          borderColor: openMenu ? light.colors.primary : '',
        }}
      >
        <InputContainer
          style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
          onClick={handleOpenMenu}
        >
          <Input
            id="hierarchical-select-input"
            placeholder={placeholder}
            value={
              selected
                ?.map(({ label: selectedLabel }) =>
                  selectedLabel.replaceAll(',', '  ->  '),
                )
                .toString() ?? ''
            }
            disabled
            data-testid="input-selected-value"
          />
        </InputContainer>

        <ActionsContainer>
          {loadingSelectedData && (
            <Spinner
              hasIcon
              spinnerType="secondary"
              data-testid="loading-selected-data"
            />
          )}

          {!!selected && !isRadioButton && (
            <button
              type="button"
              onClick={() => setSelected([])}
              data-testid="clear-select"
              aria-label="clear option selected"
              disabled={isDisabled}
            >
              <X />
            </button>
          )}
          <button
            type="button"
            onClick={handleOpenMenu}
            className={openMenu ? 'close-menu' : 'open-menu'}
            data-testid="open-select-menu"
            aria-label="open select menu"
            disabled={isDisabled}
          >
            <CaretDown />
          </button>
        </ActionsContainer>
      </SelectedContainer>

      {openMenu && (
        <MenuContainer ref={menuRef} data-testid="options-menu">
          <Input
            placeholder={translate('agGridTableSearch')}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            icon={<MagnifyingGlass />}
            data-testid="search-option"
          />

          <OptionsMenu>
            {optionsSearched.length ? (
              optionsSearched.map((option) => (
                <MenuItem
                  key={`${option.level}${option.value}`}
                  subMenu={option.children}
                  optionValue={option.value}
                  optionLabel={option.label}
                  level={option.level}
                  treeValue={[option.value]}
                  treeLabel={[option.label]}
                  canSelect={!!option.canSelect}
                  disabled={!!option.disabled}
                />
              ))
            ) : (
              <NoOption data-testid="no-option-found">
                {translate('selectNoOptions')}
              </NoOption>
            )}
          </OptionsMenu>
        </MenuContainer>
      )}
    </Container>
  );
};
