import React from 'react';

import { Loading } from 'src/components/Loading';
import { Modal } from 'src/components/Modal';

import { Container, SubMessage } from './styles';

type LoadingProps = {
  visible: boolean;
  message?: string;
  subMessage?: string;
} & React.InputHTMLAttributes<HTMLDivElement>;

export const ModalLoading: React.FC<LoadingProps> = ({
  visible,
  message,
  subMessage,

  ...rest
}) => (
  <Modal visible={visible} style={{ padding: 0 }}>
    <Container {...rest}>
      <Loading style={{ width: '60px', height: '60px' }} />
      <h3>{message}</h3>
      {subMessage && <SubMessage>{subMessage}</SubMessage>}
    </Container>
  </Modal>
);
