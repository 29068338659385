import styled, { css } from 'styled-components';

type TableSeriesContainerProps = {
  isLoading: boolean;
};

export const TableSeriesContainer = styled.div<TableSeriesContainerProps>`
  min-height: 30rem;
  width: 100%;
  display: flex;
  flex-direction: column;

  .ag-center-cols-viewport,
  .ag-header-viewport {
    border-left: unset;
    border-right: unset;
  }

  .ag-cell,
  .ag-pinned-right-header {
    border-left: unset !important;
  }

  .ag-center-cols-viewport {
    min-height: 100% !important;
  }

  //height
  .ag-root-wrapper,
  .ag-root-wrapper-body,
  .ag-center-cols-container {
    height: 100% !important;
  }

  .ag-grid-table-container,
  .ag-root-wrapper {
    max-height: 30rem;
  }

  .ag-row-selected {
    --ag-selected-row-background-color: ${({ theme }) => theme.colors.white};
  }

  .ag-root-wrapper {
    border-radius: 8px 8px 0 0 !important;
  }

  > div:first-child {
    width: 100%;

    flex: 1;
  }

  ${({ isLoading }) =>
    isLoading &&
    css`
      .ag-body-horizontal-scroll,
      .ag-sticky-bottom::after {
        display: none;
      }
    `}

  [col-id='isSelected'] {
    border-right: 1px solid ${({ theme }) => theme.colors.gray2};
  }

  [col-id='delete'] {
    border-left: 1px solid ${({ theme }) => theme.colors.gray2} !important;
  }
`;

export const FooterContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  gap: 0.5rem;

  padding: 1rem 1.5rem;

  border-left: 1px solid ${({ theme }) => theme.colors.gray2};
  border-right: 1px solid ${({ theme }) => theme.colors.gray2};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray2};

  border-radius: 0 0 8px 8px;

  > button:not(:first-of-type):last-of-type {
    background: ${({ theme }) => theme.colors.primary};

    transition: all 0.2s;

    p,
    svg {
      color: ${({ theme }) => theme.colors.white} !important;
    }

    &:hover {
      background: ${({ theme }) => theme.colors.primaryDark};
    }
  }
`;
