import styled from 'styled-components';

interface ContentTitleAndModelInformationProps {
  tableVisible: boolean;
}

export const ContentTitleAndModelInformation = styled.div<ContentTitleAndModelInformationProps>`
  > div:nth-child(2) {
    max-height: ${({ tableVisible }) => (tableVisible ? `25rem` : '0px')};
    padding-bottom: ${({ tableVisible }) => (tableVisible ? '0.5rem' : 0)};
    transition: max-height 0.5s ease-in;

    overflow-y: hidden;
    overflow-x: auto;

    table {
      width: 100%;
      text-align: center;
      border-collapse: separate;
      border: 1px solid ${({ theme }) => theme.colors.gray1};
      border-spacing: 0px;
      border-radius: 5px;

      thead {
        th {
          padding: 1rem;

          font-weight: 600;
          font-size: 0.875rem;
          line-height: 1.375rem;
          color: ${({ theme }) => theme.colors.gray6};
          border-right: 1px solid ${({ theme }) => theme.colors.gray1};
          border-bottom: 1px solid ${({ theme }) => theme.colors.gray1};

          &:last-child {
            border-right: 0;
          }
        }
      }

      tbody {
        td {
          padding: 1.313rem;

          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1.375rem;
          color: ${({ theme }) => theme.colors.gray6};

          border-right: 1px solid ${({ theme }) => theme.colors.gray1};

          &:last-child {
            border-right: 0;
          }
        }
      }
    }
  }

  button {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    transition: margin-top 0.5s ease-in;
    margin-top: ${({ tableVisible }) => (tableVisible ? '1rem' : '0px')};

    span {
      font-weight: 600;
      font-size: 1rem;
      color: ${({ theme }) => theme.colors.primary};

      transition: color 0.5s ease-in;
    }

    svg {
      color: ${({ theme }) => theme.colors.primary};
      transition: all 0.5s ease-in;
      transform: ${({ tableVisible }) =>
        tableVisible ? 'scaleY(-1)' : 'scaleY(1)'};
    }

    &:disabled {
      span,
      svg {
        color: ${({ theme }) => theme.colors.gray3};
      }
      cursor: not-allowed;
    }
  }
`;
