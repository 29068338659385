import React, { ReactElement, createContext, useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import api from '../service/api';
import {
  YBusinessStatus,
  changeYBusinessStatus,
} from '../redux/reducers/Project';

interface Error {
  detail?: {
    description?: string;
  };
}

interface ListOfVariables {
  date: string;
  explanatories: string[];
  values: {
    label: {
      'en-us': string;
      'pt-br': string;
    };
    value: string;
  }[];
  y: string;
}

interface AISelectionContextType {
  listOfVariables: ListOfVariables | undefined;
  isLoadingListOfVariables: boolean;
  errorListOfVariables: AxiosError<Error> | null;
  yBusinessStatus: YBusinessStatus | undefined;
}

interface ResponseStatusYs {
  y_status: {
    y: string;
    status: YBusinessStatus;
  }[];
}

export const AISelectionContext = createContext({} as AISelectionContextType);

export const AISelectionProvider: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  const { id, selectedY, yBusiness } = useSelector(
    (state: RootState) => state.project,
  );

  const yBusinessStatus = useMemo(
    () => yBusiness.find((yBus) => yBus.y === selectedY?.id)?.status,
    [selectedY?.id, yBusiness],
  );

  const dispatch = useDispatch();

  const {
    data: listOfVariables,
    isLoading: isLoadingListOfVariables,
    error: errorListOfVariables,
  } = useQuery<ListOfVariables, AxiosError<Error>>(
    [
      'AI-selection',
      'explanatory-variables',
      'list-of-variables',
      id,
      selectedY?.id,
    ],
    async () => {
      const response = await api.get(
        `/projects/${id}/${selectedY?.id}/models/business/variables`,
      );
      return response.data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled: !!id && !!selectedY?.id,
    },
  );

  useEffect(() => {
    (async () => {
      if (yBusinessStatus === 'processing') {
        const { data } = await api.get<ResponseStatusYs>(
          `/projects/${id}/business`,
        );

        dispatch(changeYBusinessStatus(data.y_status));
      }
    })();
  }, [dispatch, id, yBusinessStatus]);

  return (
    <AISelectionContext.Provider
      value={{
        listOfVariables,
        isLoadingListOfVariables,
        errorListOfVariables,
        yBusinessStatus,
      }}
    >
      {children}
    </AISelectionContext.Provider>
  );
};
