import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { TextArea } from 'src/components/TextArea';
import { Button } from 'src/components/Button';
import { CheckCircle } from 'phosphor-react';

import { Container } from './styles';
import { RequestApprovalProps } from './types';

export const RequestApproval: React.FC<RequestApprovalProps> = ({
  visible,
  lockEdition,
  onRequestApproval,
  closeRequestApproval,
}) => {
  const [message, setMessage] = useState('');
  const [loadingStepUpdate, setLoadingStepUpdate] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const { t: translate } = useTranslation();

  const handleMessageChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  };

  const handleRequestApproval = async () => {
    setLoadingStepUpdate(true);

    const success = await lockEdition();

    if (success) {
      const requestSuccessfully = await onRequestApproval(
        'awaiting_approval',
        message.trim(),
      );

      if (requestSuccessfully) {
        setMessage('');
      }

      setLoadingStepUpdate(false);

      closeRequestApproval();
    } else {
      setLoadingStepUpdate(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const clickedContainer = event.target as Node;

      const clickedContainerIsSubmitForApprovalButton =
        clickedContainer instanceof HTMLElement &&
        clickedContainer.nodeName === 'BUTTON' &&
        clickedContainer.textContent?.includes(
          translate('workspaceOverviewPlanningFlowSubmitApproval'),
        );

      const clickedContainerIsSubmitForApprovalDiv =
        clickedContainer instanceof HTMLElement &&
        clickedContainer.nodeName === 'DIV' &&
        clickedContainer.textContent ===
          translate('workspaceOverviewPlanningFlowSubmitApproval');

      const warningIds = [
        'warning-modal-container',
        'container-modal-overlay',
        'warning-modal-button',
        'warning-modal-back-button',
      ];

      const clickedContainerIsModal =
        (clickedContainer instanceof HTMLElement ||
          clickedContainer instanceof SVGElement) &&
        (warningIds.includes(clickedContainer?.id) ||
          warningIds.includes(clickedContainer?.parentElement?.id ?? ''));

      if (
        ref.current &&
        !clickedContainerIsSubmitForApprovalButton &&
        !clickedContainerIsSubmitForApprovalDiv &&
        !ref.current.contains(clickedContainer) &&
        !loadingStepUpdate &&
        !clickedContainerIsModal
      ) {
        closeRequestApproval();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closeRequestApproval, loadingStepUpdate, translate]);

  const messageError =
    message.trim().length > 300
      ? translate(
          'workspaceOverviewPlanningFlowMessageDescriptionMaxCharactersError',
        )
      : message.trim().length && message.trim().length < 3
      ? translate(
          'workspaceOverviewPlanningFlowMessageDescriptionMinCharactersError',
        )
      : undefined;

  const messageIsEmpty = !message.trim();

  const disableRequestApprovalButton =
    !!messageError || messageIsEmpty || loadingStepUpdate;

  return (
    <Container
      ref={ref}
      style={{ display: visible ? 'flex' : 'none' }}
      data-testid="container-request-approval"
    >
      <TextArea
        label={translate('workspaceOverviewPlanningFlowMessage')}
        onChange={handleMessageChange}
        placeholder={translate(
          'workspaceOverviewPlanningFlowMessageDescription',
        )}
        error={messageError}
        data-testid="textarea-message"
      />

      <Button
        buttonType="primary"
        icon={<CheckCircle />}
        onClick={handleRequestApproval}
        loading={loadingStepUpdate}
        disabled={disableRequestApprovalButton}
        data-testid="button-request-approval"
      >
        {translate('workspaceOverviewPlanningFlowRequestApproval')}
      </Button>
    </Container>
  );
};
