interface ParameterChart {
  date: (string | number)[];
  value: number[];
  aggregation_count?: number[];
}

interface ParameterResult {
  forecast: ParameterChart;
  historical: ParameterChart;
  simulated: ParameterChart;
}

interface Chart {
  date: (string | number)[];
  value: number[];
  aggregation_count: number[];
}

interface ResponseResult {
  forecast: Chart;
  historical: Chart;
  simulated: Chart;
}

export const frequencyLatestData = {
  daily: 180,
  weekly: 52,
  fortnightly: 24,
  monthly: 36,
  bimonthly: 18,
  quarterly: 12,
  'half-year': 12,
  annual: 6,
  yearly: 6,
};

export const getLatestData = (
  data: ParameterResult | undefined,
  frequency:
    | 'daily'
    | 'weekly'
    | 'fortnightly'
    | 'monthly'
    | 'bimonthly'
    | 'quarterly'
    | 'half-year'
    | 'annual',
  isVariation = false,
  initialDate?: string | number,
): ResponseResult => {
  if (data) {
    const quantity = frequencyLatestData[frequency];
    const total = data.historical.value.length + data.forecast.value.length;

    if (quantity > total) {
      return data as ResponseResult;
    }

    const forecastLenght = quantity / 3 + (isVariation ? 1 : 0);

    const forecastDate = data.forecast.date.slice(0, forecastLenght);
    const forecastValue = data.forecast.value.slice(0, forecastLenght);
    const forecastAggregation = data.forecast?.aggregation_count?.slice(
      0,
      forecastLenght,
    );

    const historicalLength = data.historical.date.length;

    let initialIndex = 0;

    if (initialDate) {
      initialIndex = data.historical.date.findIndex(
        (date) => date === initialDate,
      );

      initialIndex = initialIndex === -1 ? 0 : initialIndex;
    } else {
      initialIndex =
        historicalLength -
        quantity +
        (forecastDate.length + (isVariation ? -1 : 0));
    }

    const historicalDate = data.historical.date.slice(
      initialIndex,
      historicalLength,
    );
    const historicalValue = data.historical.value.slice(
      initialIndex,
      historicalLength,
    );
    const historicalAggregation = data.historical?.aggregation_count?.slice(
      initialIndex,
      historicalLength,
    );

    return {
      historical: {
        date: historicalDate,
        value: historicalValue,
        aggregation_count: historicalAggregation || [],
      },
      forecast: {
        date: forecastDate,
        value: forecastValue,
        aggregation_count: forecastAggregation || [],
      },
      simulated: {
        date: [],
        value: [],
        aggregation_count: [],
      },
    };
  }

  return {
    historical: {
      date: [],
      value: [],
      aggregation_count: [],
    },
    forecast: {
      date: [],
      value: [],
      aggregation_count: [],
    },
    simulated: {
      date: [],
      value: [],
      aggregation_count: [],
    },
  };
};
