import styled from 'styled-components';

export const RequirementRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.594rem;

  svg {
    color: ${({ theme }) => theme.colors.gray3};
  }

  p {
    flex: 1;
  }
`;
