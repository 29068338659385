import React from 'react';

import { useTranslation } from 'react-i18next';
import { Modal } from 'src/components/Modal';
import { Warning } from 'phosphor-react';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import { Button } from 'src/components/Button';

import { ModalWarningCategorizationProps } from './types';
import { Content } from './styles';

export const ModalWarningCategorization: React.FC<
  ModalWarningCategorizationProps
> = ({ visible, setVisible }) => {
  const { t: translate } = useTranslation();

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      dataCy="modal-warning-categorization"
      style={{ width: '35rem' }}
    >
      <Content>
        <Warning />

        <h3 data-testid="status-title">
          {translate('categorizationWarningTitle')}
        </h3>

        <p data-testid="status-description">
          {translate('categorizationWarningDescription')}
        </p>
      </Content>

      <ModalFooter>
        <Button
          buttonType="primary"
          onClick={() => setVisible(false)}
          data-testid="button-ok"
        >
          Ok
        </Button>
      </ModalFooter>
    </Modal>
  );
};
