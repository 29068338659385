export const homeFeatureStoreENUS = {
  homeFeatureStoreMyFavorites: 'My Favorites',
  homeFeatureStoreHighlights: 'Highlights 4i',
  homeFeatureStoreCreateYourFirstFavorite: 'Create your first favorite group',
  serieInfoRegion: 'Region',
  serieInfoFrequency: 'Frequency',
  serieInfoPrimaryTransformation: 'Primary Transformation',
  serieInfoSecondaryTransformation: 'Secondary Transformation',
  serieInfoSource: 'Source: ',
  serieInfoFooterExpand: 'Expand',
  indicatorsForAnalyzeHeaderTitle: 'Indicators for Analyze',
  indicatorsForAnalyzeDescription:
    'Groups of indicators to understand big scenarios.',
  homeFeatureStoryPremiumTitle: 'Premium Indicators',
  homeFeatureStoryPremiumDescription:
    'Expand the database available with these indicator collections specialists.',
  indicatorsForAnalyzeErrorDescription:
    'Unable to load indicators for Analyze.',
  indicatorsForAnalyzeErrorTitle:
    'An internal error has occurred while processing the request.',
  serieInfoGoToIndicator: 'Go to indicator',
  listFavoritesGoToAllFavorites: 'Access all groups',
};
