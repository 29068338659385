import styled, { css } from 'styled-components';

export const ContentModel = styled.div`
  width: 26rem;
`;

export const Title = styled.div`
  margin-bottom: 1.5rem;
  h3 {
    font-weight: 700;
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.gray6};
  }

  div {
    width: 23.75rem;

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 1rem;
      line-height: 150%;
      color: ${({ theme }) => theme.colors.gray5};
      margin-top: 0.5rem;
    }
  }
`;

export const ContentIcons = styled.div`
  width: 23rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

type Icon = {
  selected?: boolean;
};

export const Icon = styled.img<Icon>`
  height: 5rem;
  width: 5rem;
  opacity: 0.6;
  cursor: pointer;
  border-radius: 8px;

  ${({ theme, selected }) =>
    selected &&
    css`
      border: 4px solid ${theme.colors.secondary};
      opacity: 1;
    `};

  transition: opacity 0.2s ease-in-out;
`;
