const modelInfoENUSTranslations = {
  randomForestDescription:
    'A <b>random forest</b> combines the predictions from many different trees to arrive at a more accurate prediction. This is because the forest averages out the errors made by individual trees, so the final prediction is more robust and reliable.',
  lgbmDescription:
    'The <b>LightGBM</b> (Light Gradient-Boosting Machines) model is a gradient boosting algorithm that uses a technique called "leaf-wise" tree growth. This means that the algorithm chooses the leaf node with the highest gain during each split, rather than the traditional depth-wise approach used in other gradient boosting implementations.',
  svmDescription:
    'The <b>support vector machines</b> (SVM) classifier works by identifying a hyperplane in the input feature space that best separates the positive and negative classes. The hyperplane is chosen in a way that maximizes the margin between the closest points from each class, allowing the algorithm to generalize well to new, unseen data.',
  gBoostDescription:
    'The <b>gradient boosting</b> model works by iteratively adding weak learners (decision trees) that are trained on the residual errors of previous trees, so that each subsequent tree focuses on predicting the errors that the previous tree made. This way, the model gradually improves in accuracy with each iteration.',
  xGBoostDescription:
    'The <b>XGBoost</b> (eXtreme Gradient-Boosting) model works by building an initial decision tree model and calculating the errors between the predicted values and the actual values, assigning weights to each observation based on the size of its error. After that, a series of additional decision trees are added to the model, with each new tree focusing on the observations with the highest errors from the previous tree.',
  kneighborsDescription:
    'The <b>K-Nearest Neighbors</b> classifier works by identifying the k nearest neighbors to a new observation in the training data, based on a set of input features. The class of the new observation is then determined by a majority vote of the classes of its k nearest neighbors.',
  logRegressionDescription:
    'A <b>logistic regression</b> works by modeling the relationship between a set of input features and a binary outcome (one-vs-rest in multiclass cases). The model uses a mathematical function called the logistic function to transform the output of a linear equation into a probability score between 0 and 1 for each available outcome.',
  hiperparameters: 'Hyperparameters',

  lgbmLambdaL1Label: 'Lambda L1',
  lgbmLambdaL2Label: 'Lambda L2',
  lgbmSubSampleLabel: 'Subsample',
  lgbmSubSampleFreqLabel: 'Subsample frequency',
  lgbmNumLeavesLabel: 'N. leaves',
  lgbmMinChildSamplesLabel: 'Minimum sample sheet',
  lgbmNEstimatorsLabel: 'N. estimators',
  logRPenaltyLabel: 'Penalty',
  logRSolverLabel: 'Optimization algorithm',
  logRL1RatioLabel: 'L1 Elastic Net',
  svmCLabel: 'Regularization parameter',
  svmKernelLabel: 'Kernel algorithm',
  svmDegreLabel: 'Polynomial degree',
  svmGammaLabel: 'Kernel gamma',
  knNNeighborsLabel: 'N. Neighbors',
  knWeightsLabel: 'Weights',
  knPLabel: 'Minkowski parameter',
  rfMaxFeaturesLabel: 'Max Features',
  rfMinSamplesLeafLabel: 'Min. samples leaf',
  rfNEstimatorsLabel: 'N. of trees',
  rfMaxSamplesLabel: 'N. variables X',
  rfMaxDepthLabel: 'Max. tree',
  gBoostNEstimatorsLabel: 'N. Estimators',
  minSamplesSplitLabel: 'N. minimun of samples',
  gBoostMaxDepthLabel: 'Depth of the estimators',
  gBoostMinSamplesLeafLabel: 'Min. samples leaf',
  gBoostMaxFeaturesLabel: 'N. best division',
  xgNEstimatorsLabel: 'N. of trees',
  xgMaxDepthLabel: 'Max depth',
  xgMinChildWeightLabel: 'Min weight instances',
  xgBoosterLabel: 'Boosting algorithm',
  xgRegAlphatLabel: 'L1 regularization',
  xgRegLambdaLabel: 'L2 regularization',

  lgbmRegAlpha: 'L1 Regularization Term',
  lgbmRegAlphaLabel: 'Regularization term L1 in model weights',
  lgbmLambda: 'L2 Regularization Term',
  lgbmLambdaLabel: 'Regularization term L2 in model weights',
  lgbmSubSampleTooltip: 'Training instance subsample ratio',
  lgbmSubSampleFreqTooltip: 'Subsample frequency',
  lgbmNumLeavesTooltip: 'Maximum number of leaves in the base trees',
  lgbmMinChildSamplesTooltip:
    'Minimum number of data required in a leaf generated by a terminal node',
  lgbmNEstimatorsTooltip: 'Number of "boosted" trees to be estimated',
  logRPenaltyTooltip: 'Type of penalty to be applied in the regression',
  logRSolverTooltip: 'Algorithm used in the optimization process',
  logRL1RatioTooltip:
    'L1 mixing parameter within Elastic-Net (only used if the penalty parameter is "Elastic-Net")',
  svmCTooltip:
    'Regularization parameter. The strength of regularization is inversely proportional to C',
  svmKernelTooltip: 'The type of kernel to be used in the algorithm',
  svmDegreTooltip:
    'Degree of the polynomial function (used by the "poly" kernel)',
  svmGammaTooltip: 'Coefficient for the "rbf", "poly" and "sigmoid" kernels',
  knNNeighborsTooltip: 'Number of neighbors used in the method',
  knWeightsTooltip: 'Weight function used in the forecast',
  knPTooltip: 'Power parameter for the Minkowski metric',
  rfMaxFeaturesTooltip:
    'Number of variables considered in the search for the best division',
  rfMinSamplesLeafTooltip:
    'Minimum number of data required in a leaf generated by a terminal node',
  rfNEstimatorsTooltip: 'Number of trees in the forest',
  rfMaxSamplesTooltip:
    'Number of samples to be taken from the X variables for each base estimator',
  rfMaxDepthTooltip: 'Maximum depth of the tree',
  gBoostNEstimatorsTooltip: 'Number of boosting stages to be performed',
  minSamplesSplitTooltip: 'Minimum number of samples needed to split a node',
  gBoostMaxDepthTooltip:
    'The maximum depth of individual regression estimators',
  gBoostMinSamplesLeafTooltip:
    'Minimum number of data required in a leaf generated by a terminal node',
  gBoostMaxFeaturesTooltip:
    'Number of variables considered in the search for the best division',
  xgNEstimatorsTooltip: 'Number of trees to estimate in random forest',
  xgMaxDepthTooltip: 'Maximum depth of base estimators',
  xgMinChildWeightTooltip:
    'Minimum sum of weight (hessian) instances required in a node/leaf',
  xgBoosterTooltip: 'Specifies the boosting algorithm to be used',
  xgRegAlphatTooltip: 'Regularization term L1 in model weights',
  xgRegLambdaTooltip: 'Regularization term L2 in model weights',

  modelInfoError: 'Unable to load model info.',
};

export default modelInfoENUSTranslations;
