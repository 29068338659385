import styled from 'styled-components';

type TabProps = {
  isActive: boolean;
};

export const Tab = styled.div<TabProps>`
  display: flex;
  height: 40px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 5px;

  background: ${({ isActive }) =>
    isActive ? 'rgba(76, 148, 255, 0.12)' : 'none'};
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.primary : theme.colors.gray4};

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  white-space: nowrap;

  cursor: pointer;
`;
