import React from 'react';

import { ButtonRoundedProps } from './types';
import { Button } from './styles';

export const ButtonRounded: React.FC<ButtonRoundedProps> = ({
  icon,
  label,
  disabled,
  onClick,
  ...props
}) => (
  <Button
    disabled={disabled}
    onClick={onClick}
    hasLabel={!!label}
    {...props}
    className="button-rounded"
  >
    {icon}

    {label && <p>{label}</p>}
  </Button>
);
