import React, { useContext, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import {
  CheckCircle,
  DotOutline,
  MagnifyingGlass,
} from '@phosphor-icons/react';
import { Label } from 'src/components/Label';
import { ButtonRounded } from 'src/components/ButtonRounded';
import { Plus } from 'phosphor-react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Calendar } from 'src/components/Calendar';
import { addDays, format } from 'date-fns';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import apiWorkspace from 'src/workspaces/service/api';
import ms from 'ms';

import { StatusProps, UserOption } from '../types';
import {
  Button,
  CalendarContainer,
  Container,
  DeadlineInfo,
  Icon,
  LineIcon,
  UsersContainer,
  UsersInfo,
} from '../styles';
import { UserSelect } from '../../UserSelect';
import { PlanningFlowContext } from '../..';
import { UserProps } from '../../UserSelect/types';
import { LevelContainer, UserLevelContainer } from './styles';

export const ApprovedStatus: React.FC<
  StatusProps & { currentApproverLevel?: number }
> = ({ isSelected, isDisabled, stepId, currentApproverLevel }) => {
  const [showNewUserSelect, setShowNewUserSelect] = useState(false);

  const { t: translate } = useTranslation();

  const { id } = useParams();

  const { language } = useSelector((state: RootState) => state.auth.user);

  const {
    steps,
    isConfigurationMode,
    showErrors,
    handleAddUser,
    handleRemoveUser,
    handleChangeDeadline,
    handleAccessStep,
  } = useContext(PlanningFlowContext);

  const { data: userData } = useQuery(
    ['workspace users', id],
    async () => {
      const { data } = await apiWorkspace.get<UserProps[]>(
        `/workspaces/${id}/users`,
      );

      return data;
    },
    {
      staleTime: ms('3 min'),
      enabled: !!id && isConfigurationMode,
    },
  );

  const step = useMemo(
    () => steps.find((value) => value.id === stepId),
    [steps, stepId],
  );

  const users = step?.users ?? [];

  const approverUsersInfo = users
    .filter((user) => user.profile === 'approver')
    .sort((a, b) =>
      !a.level || !b.level
        ? 0
        : a.level > b.level
        ? 1
        : a.level < b.level
        ? -1
        : 0,
    );

  const approverUsers = approverUsersInfo.map(({ user }) => user);

  const handleAddNewUserSelect = () => {
    setShowNewUserSelect(true);
  };

  const handleChangeSelectedUser = (
    option: UserOption,
    currentUser: string,
    level: number,
  ) => {
    if (option?.value !== currentUser) {
      if (!option) {
        handleRemoveUser!(stepId, currentUser, 'approver', level);

        if (approverUsers.length === 1) {
          setShowNewUserSelect(false);
        }
      } else {
        handleAddUser!(stepId, option.value, 'approver', level);

        if (currentUser) {
          handleRemoveUser!(stepId, currentUser, 'approver');
        }
      }
    }
  };

  const onCalendarClose = () => {
    if (approverDeadline?.start && !approverDeadline.end) {
      handleChangeDeadline!(stepId, 'approver', [
        new Date(approverDeadline.start.replace(':00Z', '')),
        new Date(approverDeadline.start.replace(':00Z', '')),
      ]);
    }
  };

  const userDataEditorManager = useMemo(
    () => userData?.filter((user) => user.role !== 'reader'),
    [userData],
  );

  const selectQtty =
    approverUsers.length +
    (showNewUserSelect ? 1 : 0) +
    (!approverUsers.length ? 1 : 0);

  const canEdit = step?.status !== 'approved';

  const canAddMoreUsers =
    userDataEditorManager &&
    userDataEditorManager.length > selectQtty &&
    selectQtty < 3 &&
    canEdit;

  const addMoreUsersDisabled = showNewUserSelect || !approverUsers.length;

  const approverDeadline = step?.deadlines?.find(
    ({ type }) => type === 'approver',
  )?.date;
  const editorDeadline = step?.deadlines?.find(
    ({ type }) => type === 'editor',
  )?.date;

  const stepIsApproved = step?.status === 'approved';

  const showUserError =
    !approverUsers.length && !!showErrors && !stepIsApproved;

  const invalidDeadline =
    !!editorDeadline?.end &&
    !!approverDeadline?.start &&
    new Date(editorDeadline.end.slice(0, 10)).getTime() >
      new Date(approverDeadline.start.slice(0, 10)).getTime();

  const showDeadlineError =
    (!approverDeadline && !!showErrors && !stepIsApproved) || invalidDeadline;

  const currentApproverEmail =
    currentApproverLevel &&
    approverUsersInfo.find(({ level }) => level === currentApproverLevel)?.user;

  return (
    <Container
      status="approved"
      selected={isSelected}
      isDisabled={isDisabled}
      isConfigurationMode={isConfigurationMode}
      style={{ marginRight: '0' }}
      data-testid="container-approved-state"
    >
      <Icon data-testid="icon-approved-state">
        <CheckCircle weight="fill" />
      </Icon>

      <LineIcon>
        {isDisabled || isSelected || isConfigurationMode ? (
          <DotOutline weight="fill" style={{ width: '2rem', height: '2rem' }} />
        ) : (
          <Button
            type="button"
            onClick={() =>
              handleAccessStep && handleAccessStep(stepId, 'approved')
            }
            data-testid="button-approved-state"
          >
            <MagnifyingGlass />
          </Button>
        )}
      </LineIcon>

      <p data-testid="text-approved-state">
        {translate('workspacePlanningFlowApproved')}
      </p>

      {isConfigurationMode ? (
        <>
          <CalendarContainer
            error={showDeadlineError}
            data-testid={
              showDeadlineError
                ? 'container-deadline-error'
                : 'container-deadline'
            }
          >
            <Calendar
              label={translate('date')}
              frequency="daily"
              dateFormat={language === 'en-us' ? 'MM/dd/yy' : 'dd/MM/yy'}
              selectsRange
              startDate={
                approverDeadline?.start
                  ? new Date(approverDeadline.start.replace(':00Z', ''))
                  : null
              }
              endDate={
                approverDeadline?.end
                  ? new Date(approverDeadline.end.replace(':00Z', ''))
                  : null
              }
              onChange={(date: [Date | null, Date | null]) =>
                handleChangeDeadline!(stepId, 'approver', date)
              }
              popperProps={{
                strategy: 'fixed',
              }}
              placeholderText={translate(
                'workspacePlanningFlowChooseDatePlaceholder',
              )}
              minDate={addDays(new Date(), 1)}
              inputTestId="deadline-approved"
              error={
                invalidDeadline
                  ? translate('workspacePlanningFlowApproverDeadlineError')
                  : ''
              }
              isDisabled={!canEdit}
              onCalendarClose={onCalendarClose}
            />
          </CalendarContainer>

          <UsersContainer
            error={showUserError}
            data-testid={`container-approver-user${
              showUserError ? '-error' : ''
            }`}
          >
            <Label>{translate('workspacePlanningFlowApprovers')}</Label>

            {approverUsers?.map((user, index) => (
              <UserLevelContainer
                key={user}
                data-testid={`container-approver-user-${index + 1}`}
              >
                <LevelContainer>
                  <p>{translate(`workspacePlanningFlow${index + 1}`)}</p>
                </LevelContainer>

                <UserSelect
                  profile="approver"
                  usersAlreadySelected={approverUsers}
                  selectedUser={user}
                  onChange={(option) =>
                    handleChangeSelectedUser(
                      option as UserOption,
                      user,
                      index + 1,
                    )
                  }
                  isDisabled={!canEdit}
                />
              </UserLevelContainer>
            ))}

            {(showNewUserSelect || !approverUsers.length) && (
              <UserLevelContainer
                data-testid={`container-approver-user-${
                  approverUsers.length + 1
                }`}
              >
                <LevelContainer>
                  <p>
                    {translate(
                      `workspacePlanningFlow${approverUsers.length + 1}`,
                    )}
                  </p>
                </LevelContainer>

                <UserSelect
                  profile="approver"
                  selectedUser={undefined}
                  usersAlreadySelected={approverUsers}
                  onChange={(option) => {
                    setShowNewUserSelect(false);
                    handleChangeSelectedUser(
                      option as UserOption,
                      '',
                      approverUsers.length + 1,
                    );
                  }}
                  isDisabled={!canEdit}
                />
              </UserLevelContainer>
            )}

            {canAddMoreUsers && (
              <ButtonRounded
                type="button"
                icon={<Plus />}
                disabled={addMoreUsersDisabled}
                onClick={handleAddNewUserSelect}
                data-testid="button-add-approver-user"
              />
            )}
          </UsersContainer>
        </>
      ) : (
        <>
          {!!approverDeadline?.start && !!approverDeadline.end && (
            <DeadlineInfo data-testid="text-approver-deadline">
              {format(
                new Date(approverDeadline.start.replace(':00Z', '')),
                language === 'en-us' ? 'MM/dd/yy' : 'dd/MM/yy',
              )}{' '}
              -{' '}
              {format(
                new Date(approverDeadline.end.replace(':00Z', '')),
                language === 'en-us' ? 'MM/dd/yy' : 'dd/MM/yy',
              )}
            </DeadlineInfo>
          )}

          {!!currentApproverEmail && step?.status !== 'approved' && (
            <UsersInfo>
              <p
                data-tooltip-id="planning-flow-users"
                data-tooltip-content={
                  currentApproverEmail.length > 20 ? currentApproverEmail : ''
                }
                data-testid="text-approver-users"
              >
                {currentApproverEmail.length > 20
                  ? `${currentApproverEmail.slice(0, 17)}...`
                  : currentApproverEmail}
              </p>
            </UsersInfo>
          )}
        </>
      )}
    </Container>
  );
};
