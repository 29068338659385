const modelInfoPTBRTranslations = {
  randomForestDescription:
    'Uma floresta aleatória (<b>random forest</b>) combina as previsões de muitas árvores diferentes para chegar a uma previsão mais precisa. Isso ocorre porque a floresta calcula a média dos erros cometidos por árvores individuais, de modo que a previsão final é mais robusta e confiável.',
  lgbmDescription:
    'O modelo <b>LightGBM</b> (Light Gradient-Boosting Machines) é um algoritmo de gradient boosting que utiliza uma técnica chamada crescimento de árvore em folha. Isso significa que o algoritmo escolhe o nó folha com o maior ganho durante cada divisão, em vez da abordagem tradicional de profundidade usada em outras implementações de gradient boosting.',
  svmDescription:
    'O <b>support vector machines</b> (SVM) funciona identificando um híperplano no espaço das variáveis de entrada que separa melhor as classes positivas e negativas. O híperplano é escolhido de forma a maximizar a margem entre os pontos mais próximos de cada classe, permitindo que o algoritmo generalize bem para dados novos e não vistos anteriormente.',
  gBoostDescription:
    'O modelo <b>gradient boosting</b> funciona adicionando de forma iterativa modelos de previsão fracos (árvores de decisão) que são treinados nos erros residuais das árvores anteriores, de modo que cada árvore subsequente se concentre em prever os erros cometidos pela árvore anterior. Dessa forma, o modelo melhora gradualmente em precisão a cada iteração.',
  xGBoostDescription:
    'O modelo <b>XGBoost</b> (eXtreme Gradient-Boosting) funciona construindo um modelo de árvore de decisão inicial e calculando os erros entre os valores previstos e os valores reais, atribuindo pesos a cada observação com base no tamanho de seu erro. Depois disso, uma série de árvores de decisão adicionais são adicionadas ao modelo, com cada nova árvore focando nas observações com os maiores erros da árvore anterior.',
  kneighborsDescription:
    'O <b>K-Nearest Neighbors</b> funciona identificando os K vizinhos mais próximos de uma nova observação nos dados de treinamento, com base em um conjunto de variáveis de entrada. A classe da nova observação é então determinada pelo voto majoritário das classes de seus K vizinhos mais próximos.',
  logRegressionDescription:
    'Uma regressão logística (<b>logistic regression</b>)  funciona modelando o relacionamento entre um conjunto de variáveis de entrada e um resultado binário (um-contra-todos em casos multiclasse). O modelo usa uma função matemática chamada função logística para transformar a saída de uma equação linear em uma pontuação de probabilidade entre 0 e 1 para cada resultado disponível.',
  hiperparameters: 'Hiperparâmetros',

  lgbmLambdaL1Label: 'Termo regularização L1',
  lgbmLambdaL2Label: 'Termo regularização L2',
  lgbmSubSampleLabel: 'Proporção subamostra',
  lgbmSubSampleFreqLabel: 'Frequência da subamostra',
  lgbmNumLeavesLabel: 'Max n. folhas',
  lgbmMinChildSamplesLabel: 'Mínimo amostras folha',
  lgbmNEstimatorsLabel: 'N. árvore estimadas',
  logRPenaltyLabel: 'Penalidade',
  logRSolverLabel: 'Algoritmo otimização',
  logRL1RatioLabel: 'L1 Elastic Net',
  svmCLabel: 'Parâmetro de regularização',
  svmKernelLabel: 'Algorítimo Kernel',
  svmDegreLabel: 'Grau do polimônio',
  svmGammaLabel: 'Gamma do kernel',
  knNNeighborsLabel: 'N. vizinhos',
  knWeightsLabel: 'Pesos',
  knPLabel: 'Parâmetro de Minkowski',
  rfMaxFeaturesLabel: 'Max. Features',
  rfMinSamplesLeafLabel: 'Min. amostra folha',
  rfNEstimatorsLabel: 'N. de árvores',
  rfMaxSamplesLabel: 'N. variáveis X',
  rfMaxDepthLabel: 'Max. árvore',
  gBoostNEstimatorsLabel: 'N. estimadores',
  minSamplesSplitLabel: 'N. mínimo de amostras',
  gBoostMaxDepthLabel: 'Profundidade dos estimadores',
  gBoostMinSamplesLeafLabel: 'Min. amostra folha',
  gBoostMaxFeaturesLabel: 'N. melhor divisão',
  xgNEstimatorsLabel: 'N. de árvores',
  xgMaxDepthLabel: 'Max profundidade',
  xgMinChildWeightLabel: 'Min instâncias de peso',
  xgBoosterLabel: 'Algoritmo de boosting',
  xgRegAlphatLabel: 'Regularização L1',
  xgRegLambdaLabel: 'Regularização L2',

  lgbmRegAlpha: 'Termo regularização L1',
  lgbmRegAlphaLabel: 'Termo de regularização L1 nos pesos do modelo',
  lgbmLambda: 'Termo regularização L2',
  lgbmLambdaLabel: 'Termo de regularização L2 nos pesos do modelo',
  lgbmSubSampleTooltip: 'Proporção de subamostra da instância de treino',
  lgbmSubSampleFreqTooltip: 'Frequência da subamostra',
  lgbmNumLeavesTooltip: 'Máximo número de folhas nas árvores base',
  lgbmMinChildSamplesTooltip:
    'Mínimo número de dados necessários em uma folha gerada por um nó terminal',
  lgbmNEstimatorsTooltip: 'Número de árvores "boosted" a serem estimadas',
  logRPenaltyTooltip: 'Tipo de penalidade a ser aplicada na regressão',
  logRSolverTooltip: 'Algoritmo utilizado no processo de otimização',
  logRL1RatioTooltip:
    'Parâmetro de mistura do L1 dentro do Elastic-Net (utilizado apenas se o parâmetro penalty for "Elastic-Net")',
  svmCTooltip:
    'Parâmetro de regularização. A força da regularização é inversamente proporcional a C',
  svmKernelTooltip: 'O tipo de kernel a ser utilizado no algoritmo',
  svmDegreTooltip: 'Grau da função polinomial (utilizado pelo kernel "poly")',
  svmGammaTooltip: 'Coeficiente para os kernels "rbf", "poly" e "sigmoid"',
  knNNeighborsTooltip: 'Número de vizinhos utilizado no método',
  knWeightsTooltip: 'Função de pesos utilizada na previsão',
  knPTooltip: 'Parâmetro de potência para a métrica de Minkowski',
  rfMaxFeaturesTooltip:
    'Número de variáveis consideradas na procura pela melhor divisão',
  rfMinSamplesLeafTooltip:
    'Mínimo número de dados necessários em uma folha gerada por um nó terminal',
  rfNEstimatorsTooltip: 'Número de árvores na floresta',
  rfMaxSamplesTooltip:
    'Número de amostras a serem retiradas das variáveis X para cada estimador base',
  rfMaxDepthTooltip: 'Máxima profundidade da árvore',
  gBoostNEstimatorsTooltip: 'Número de estágios de boosting a serem executados',
  minSamplesSplitTooltip:
    'Número mínimo de amostras necessárias para dividir um nó',
  gBoostMaxDepthTooltip:
    'A profundidade máxima dos estimadores de regressão individuais',
  gBoostMinSamplesLeafTooltip:
    'Mínimo número de dados necessários em uma folha gerada por um nó terminal',
  gBoostMaxFeaturesTooltip:
    'Número de variáveis consideradas na procura pela melhor divisão',
  xgNEstimatorsTooltip: 'Número de árvores para estimar na floresta aleatória',
  xgMaxDepthTooltip: 'Máxima profundidade dos estimadores base',
  xgMinChildWeightTooltip:
    'Mínima soma das instâncias de peso (hessiana) necessárias em um nó/folha',
  xgBoosterTooltip: 'Especifica o algoritmo de boosting a ser utilizado',
  xgRegAlphatTooltip: 'Termo de regularização L1 nos pesos do modelo',
  xgRegLambdaTooltip: 'Termo de regularização L2 nos pesos do modelo',

  modelInfoError: 'Não foi possível carregar as informações do modelo.',
};

export default modelInfoPTBRTranslations;
