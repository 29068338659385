import styled from 'styled-components';

export const Container = styled.div`
  width: 30rem;
  height: 10.625rem;
  h4 {
    font-size: 1.5rem;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.gray6};
    margin-bottom: 0.5rem;
    line-height: 2rem;
  }
  > p {
    margin-bottom: 1.5rem;

    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray5};

    b {
      font-weight: 700;
    }
  }
`;
