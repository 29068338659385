const metricsByPeriodENUSTranslations = {
  metricsByPeriodTitle: 'Metrics by period',
  metricsByPeriodDescription:
    'See the performance for each model aggregated by time period.',
  metricsFilterTitle: 'Period',
  metricsMonthsAhead: '"X" months ahead',
  accuracyLevel: 'Accuracy level',
  metricLabel: 'Metric',
};

export default metricsByPeriodENUSTranslations;
