const editUnitPTBRTranslate = {
  editSerieTitle: 'Editar uma série',
  editSerieUnitPT: 'Unidade (português)',
  editSerieUnitPTPlaceholder: 'Escreva o nome da unidade em português',
  editSerieUnitEN: 'Unidade (inglês)',
  editSerieUnitENPlaceholder: 'Escreva o nome da unidade em inglês',
  editSerieMaintenance: 'Manutenção?',
  editSerieMax: 'Máximo 50 caracteres',
  editSerieMin: 'Deve ser maior ou igual a 1 dígitos',
  editSerieButton: 'Editar série',
  editSerieFailedTitle: 'Não foi possível editar a série',
  editSerieFailedText: 'Tente novamente mais tarde',
};

export default editUnitPTBRTranslate;
