/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useMemo, useState } from 'react';

import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card } from 'src/components/Card';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Head } from 'src/components/Head';
import { ErrorObject, FailedModal } from 'src/components/Modal/Failed';
import { Select } from 'src/components/Select';
import { Table, Tbody, Td, Th, Thead, Tr } from 'src/components/Table';
import { DataError } from 'src/interface/axios';
import { changeIsFinish } from 'src/models/redux/reducers/ProjectOverviewExportTimeSeries';
import { RootState } from 'src/redux/store';
import { queryClient } from 'src/service/queryClient';
import { translateTransformationText } from 'src/utils/translateTransformationText';
import { resetUserSelectionOptions } from 'src/models/redux/reducers/AIUserSelectionOptions';
import { Tooltip } from 'react-tooltip';

import api from '../../../../service/api';
import {
  Container,
  ContainerModelInfo,
  ContainerSendProduction,
  ContainerTable,
  ContentButton,
  ContentSpecificationAndResiduals,
  ModelInfoListInformation,
  ModelList,
  ModelSpecification,
  SendButton,
} from '../../styles';
import {
  ArimaModelInfo,
  ArimaAnnualVariation,
  ArimaAnnualLevel,
  ArimaModelSpec,
  ArimaCheckResiduals,
} from './types';
import { Breakdown } from './components/Breakdown';
import { ActualForecast } from '../components/ActualForecast';
import { AnnualVariationChart } from '../components/AnnualVariationChart';
import { AnnualLevelChart } from '../components/AnnualLevelChart';
import { ResidualsChart } from '../components/ResidualsChart';
import { ResidualsAcfChart } from '../components/ResidualsAcfChart';
import { ResidualHistogramChart } from '../components/ResidualHistogram';
import { ActualVSModelFit } from '../components/ActualVSModelFit';

export const ArimaModelSpecifics: React.FC = () => {
  const [modelId, setModelId] = useState(1);

  const [sendToProdDisabled, setSendToProdDisabled] = useState(true);
  const [sendToProdLoading, setSendToProdLoading] = useState(false);
  const [failedModalVisible, setFailedModalVisible] = useState(false);
  const [failedModalInfo, setFailedModalInfo] = useState<ErrorObject>();

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    project,
    auth: { user },
  } = useSelector((state: RootState) => state);

  const { t: translate } = useTranslation();

  const {
    data: modelInfoData,
    isLoading: modelInfoIsLoading,
    isError: modelInfoError,
  } = useQuery(
    ['arima model info', project.id, project.selectedY?.id, modelId],
    async () => {
      const response = await api.get<ArimaModelInfo>(
        `/projects/${project.id}/${project.selectedY?.id}/models/arima/${modelId}/info`,
      );

      return response.data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled:
        !!project.id && !!project.selectedY?.id && !!modelId && !!project.model,
    },
  );

  const {
    data: modelSpecData,
    isLoading: modelSpecLoading,
    isError: modelSpecError,
  } = useQuery(
    ['arima model spec', project.id, project.selectedY?.id, modelId],
    async () => {
      const response = await api.get<ArimaModelSpec[]>(
        `/projects/${project.id}/${project.selectedY?.id}/models/arima/${modelId}/specification`,
      );

      return response.data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled:
        !!project.id && !!project.selectedY?.id && !!modelId && !!project.model,
    },
  );

  const {
    data: modelResidualsData,
    isLoading: modelResidualsLoading,
    isError: modelResidualsError,
  } = useQuery(
    ['arima model residuals', project.id, project.selectedY?.id, modelId],
    async () => {
      if (project.selectedY?.id) {
        const response = await api.get<ArimaCheckResiduals>(
          `/projects/${project.id}/${project.selectedY?.id}/models/arima/${modelId}/check_residuals`,
        );

        return response.data;
      }
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled: !!project.id && !!modelId,
    },
  );

  const {
    data: annualVariationData,
    isLoading: annualVariationLoading,
    isError: annualVariationError,
  } = useQuery(
    ['arima model annualVariation', project.id, project.selectedY?.id, modelId],
    async () => {
      const response = await api.get<ArimaAnnualVariation[]>(
        `/projects/${project.id}/${project.selectedY?.id}/models/arima/${modelId}/annual_variation`,
      );

      return response.data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled:
        !!project.id && !!project.selectedY?.id && !!modelId && !!project.model,
    },
  );

  const {
    data: annualLevelData,
    isLoading: annualLevelLoading,
    isError: annualLevelError,
  } = useQuery(
    ['arima model annualLevel', project.id, project.selectedY?.id, modelId],
    async () => {
      const response = await api.get<ArimaAnnualLevel[]>(
        `/projects/${project.id}/${project.selectedY?.id}/models/arima/${modelId}/annual_level`,
      );

      return response.data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled:
        !!project.id && !!project.selectedY?.id && !!modelId && !!project.model,
    },
  );

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );

  useEffect(() => {
    const modelNumber = searchParams.get('model_number');
    const summary = project.model?.model_summary.find(
      (modelSummary) => modelSummary.type === 'ARIMA',
    );

    if (modelNumber && summary && Number(modelNumber) <= summary.quantity) {
      setModelId(Number(modelNumber));
    } else {
      setModelId(1);
    }
  }, [project.model, searchParams]);

  useEffect(() => {
    if (project.id && project.selectedY && modelId) {
      setSendToProdDisabled(false);
    } else {
      setSendToProdDisabled(true);
    }
  }, [modelId, project.id, project.selectedY]);

  async function handleSendToProduction() {
    setSendToProdLoading(true);
    setSendToProdDisabled(true);

    try {
      const { data } = await api.post(
        `/projects/${project.id}/${project.selectedY?.id}/model-in-production/arima/${modelId}`,
      );

      if (data) {
        navigate(`/models/time-series/${project.id}/user-selection`);
        queryClient.removeQueries('model production');
        queryClient.removeQueries('project overview');
        dispatch(changeIsFinish(false));
        dispatch(resetUserSelectionOptions());
      }
    } catch (err) {
      const error: AxiosError<DataError> | any = err;
      const errorMessage =
        error?.response?.data?.detail?.detail ??
        translate('sendToProdFailMessage');

      setFailedModalInfo({
        title: translate('requestFailed'),
        description: errorMessage,
      });
      setFailedModalVisible(true);
    }

    setSendToProdLoading(false);
    setSendToProdDisabled(false);
  }

  return (
    <Container data-cy="containerModelSpecifics">
      <Head
        title={`${translate('modelSpecTitle')} | ${translate(
          'modelExplorerText',
        )}`}
      />
      <Card
        textCard={`ARIMA - ${translate('modelSpecTitle')}`}
        textDescription={translate('modelSpecDescription')}
        className="containerLinear"
      />
      <section>
        <ModelList className="containerLinear">
          <Card
            textCard={translate('selectModelTitle')}
            // textDescription="Sumary for... (cobrar curado do texto)"
          />
          {project.projectError ? (
            <Select
              label={translate('selectModelLabel')}
              placeholder={translate('selectModelPlaceHolder')}
              options={[{ label: 1, value: '1' }]}
              value={{ label: modelId, value: modelId }}
              onChange={(option: any) => {
                setModelId(option.value);
              }}
              isDisabled
            />
          ) : project.model?.model_list ? (
            <Select
              label={translate('selectModelLabel')}
              placeholder={translate('selectModelPlaceHolder')}
              options={Array.from(
                {
                  length:
                    project.model?.model_list.find(
                      (model) => model.type === 'ARIMA',
                    )?.quantity ?? 1,
                },
                (_, number) => ({
                  value: number + 1,
                  label: number + 1,
                }),
              )}
              value={{ label: modelId, value: modelId }}
              onChange={(option: any) => {
                setModelId(option.value);
              }}
            />
          ) : (
            <ContainerSkeleton style={{ height: '180px' }} />
          )}

          {/* <Button data-cy="button-cloose-this-model">Choose this model</Button> */}
        </ModelList>

        <ContainerSendProduction className="containerLinear">
          <Card
            textCard={translate('sendModelToProdTitle')}
            textDescription={translate('sendModelToProdDescr')}
          />
          <ContentButton>
            <SendButton
              buttonType="primary"
              onClick={handleSendToProduction}
              disabled={sendToProdDisabled}
              loading={sendToProdLoading}
              data-cy="button-send-to-production"
            >
              {translate('sendToProductionButtonText')}
            </SendButton>
          </ContentButton>
        </ContainerSendProduction>

        <ContainerModelInfo className="containerLinear">
          <Tooltip
            id="arima-model-info-tooltip"
            className="customTooltipTheme"
          />

          <Card textCard={translate('modelInfoTitle')} />
          {project.projectError || modelInfoError ? (
            <ModelInfoListInformation>
              <div>
                {/* <div>
                  <h4>Row ID</h4>
                  <p data-cy="data-info-row-id" data-testid="data-info-row-id">
                    --
                  </p>
                </div> */}
                <div>
                  <h4>{translate('sample')}</h4>
                  <p data-cy="data-info-sample" data-testid="data-info-sample">
                    --
                  </p>
                </div>
                <div>
                  <h4>{translate('transformation')}</h4>
                  <p
                    data-cy="data-info-transformation"
                    data-testid="data-info-transformation"
                  >
                    --
                  </p>
                </div>
              </div>
              <div>
                <div>
                  <h4>Ljung-Box p-value</h4>
                  <p
                    data-cy="data-info-LJungBoxValue"
                    data-testid="data-info-LJungBoxValue"
                  >
                    --
                  </p>
                </div>
                <div>
                  <h4>{translate('modelSpecArimaOrder')}</h4>
                  <p
                    data-cy="data-info-arima-order"
                    data-testid="data-info-arima-order"
                  >
                    --
                  </p>
                </div>
              </div>
              <div>
                <div
                  data-tooltip-id="arima-model-info-tooltip"
                  data-tooltip-html={translate('MAPEExplanationTooltip')}
                >
                  <h4>MAPE</h4>
                  <p data-cy="data-info-mape" data-testid="data-info-mape">
                    --
                  </p>
                </div>
                <div
                  data-tooltip-id="arima-model-info-tooltip"
                  data-tooltip-html={translate('WMAPEExplanationTooltip')}
                >
                  <h4>WMAPE</h4>
                  <p data-cy="data-info-wmape" data-testid="data-info-wmape">
                    --
                  </p>
                </div>
                <div
                  data-tooltip-id="arima-model-info-tooltip"
                  data-tooltip-html={translate('MPEExplanationTooltip')}
                >
                  <h4>MPE</h4>
                  <p data-cy="data-info-mpe" data-testid="data-info-mpe">
                    --
                  </p>
                </div>
              </div>
              <div>
                <div
                  data-tooltip-id="arima-model-info-tooltip"
                  data-tooltip-html={translate('RMSEExplanationTooltip')}
                >
                  <h4>RMSE</h4>
                  <p data-cy="data-info-rmse" data-testid="data-info-rmse">
                    --
                  </p>
                </div>
                <div
                  data-tooltip-id="arima-model-info-tooltip"
                  data-tooltip-html={translate('MASEExplanationTooltip')}
                >
                  <h4>MASE</h4>
                  <p data-cy="data-info-mase" data-testid="data-info-mase">
                    --
                  </p>
                </div>
                <div
                  data-tooltip-id="arima-model-info-tooltip"
                  data-tooltip-html={translate('MASEsExplanationTooltip')}
                >
                  <h4>MASEs</h4>
                  <p data-cy="data-info-mases" data-testid="data-info-mases">
                    --
                  </p>
                </div>
              </div>
            </ModelInfoListInformation>
          ) : modelInfoIsLoading || !modelInfoData ? (
            <ContainerSkeleton style={{ height: '180px' }} />
          ) : (
            modelInfoData && (
              <ModelInfoListInformation>
                <div>
                  {/* <div>
                    <h4>Row ID</h4>
                    <p
                      data-cy="data-info-row-id"
                      data-testid="data-info-row-id"
                    >
                      {modelInfoData.row_id ?? '--'}
                    </p>
                  </div> */}
                  <div>
                    <h4>{translate('sample')}</h4>
                    <p
                      data-cy="data-info-sample"
                      data-testid="data-info-sample"
                    >
                      {modelInfoData?.sample ?? '--'}
                    </p>
                  </div>
                  <div>
                    <h4>{translate('transformation')}</h4>
                    <p
                      data-cy="data-info-transformation"
                      data-testid="data-info-transformation"
                    >
                      {translateTransformationText(
                        modelInfoData?.transformation ?? '--',
                        user.language,
                      )}
                    </p>
                  </div>
                </div>
                <div>
                  <div>
                    <h4>Ljung-Box p-value</h4>
                    <p
                      data-cy="data-info-LJungBoxValue"
                      data-testid="data-info-LJungBoxValue"
                    >
                      {modelInfoData?.ljung
                        ? Number(modelInfoData.ljung).toFixed(4)
                        : '--'}
                    </p>
                  </div>
                  <div>
                    <h4>{translate('modelSpecArimaOrder')}</h4>
                    <p
                      data-cy="data-info-arima-order"
                      data-testid="data-info-arima-order"
                    >
                      {modelInfoData.arima_order ?? '--'}
                    </p>
                  </div>
                </div>
                <div>
                  <div
                    data-tooltip-id="arima-model-info-tooltip"
                    data-tooltip-html={translate('MAPEExplanationTooltip')}
                  >
                    <h4>MAPE</h4>
                    <p data-cy="data-info-mape" data-testid="data-info-mape">
                      {modelInfoData?.MAPE
                        ? `${modelInfoData?.MAPE.toFixed(2)}%`
                        : '--'}
                    </p>
                  </div>
                  <div
                    data-tooltip-id="arima-model-info-tooltip"
                    data-tooltip-html={translate('WMAPEExplanationTooltip')}
                  >
                    <h4>WMAPE</h4>
                    <p data-cy="data-info-wmape" data-testid="data-info-wmape">
                      {modelInfoData?.WMAPE
                        ? `${modelInfoData?.WMAPE.toFixed(2)}%`
                        : '--'}
                    </p>
                  </div>
                  <div
                    data-tooltip-id="arima-model-info-tooltip"
                    data-tooltip-html={translate('MPEExplanationTooltip')}
                  >
                    <h4>MPE</h4>
                    <p data-cy="data-info-mpe" data-testid="data-info-mpe">
                      {modelInfoData?.MPE
                        ? `${modelInfoData?.MPE.toFixed(2)}%`
                        : '--'}
                    </p>
                  </div>
                </div>
                <div>
                  <div
                    data-tooltip-id="arima-model-info-tooltip"
                    data-tooltip-html={translate('RMSEExplanationTooltip')}
                  >
                    <h4>RMSE</h4>
                    <p data-cy="data-info-rmse" data-testid="data-info-rmse">
                      {modelInfoData?.RMSE
                        ? modelInfoData.RMSE.toFixed(2)
                        : '--'}
                    </p>
                  </div>
                  <div
                    data-tooltip-id="arima-model-info-tooltip"
                    data-tooltip-html={translate('MASEExplanationTooltip')}
                  >
                    <h4>MASE</h4>
                    <p data-cy="data-info-mase" data-testid="data-info-mase">
                      {modelInfoData?.MASE
                        ? modelInfoData.MASE.toFixed(2)
                        : '--'}
                    </p>
                  </div>
                  <div
                    data-tooltip-id="arima-model-info-tooltip"
                    data-tooltip-html={translate('MASEsExplanationTooltip')}
                  >
                    <h4>MASEs</h4>
                    <p data-cy="data-info-mases" data-testid="data-info-mases">
                      {modelInfoData?.MASEs
                        ? modelInfoData.MASEs.toFixed(2)
                        : '--'}
                    </p>
                  </div>
                </div>
              </ModelInfoListInformation>
            )
          )}
        </ContainerModelInfo>
      </section>
      <ContentSpecificationAndResiduals>
        <ModelSpecification className="containerLinear">
          <Card textCard={translate('modelSpecificsSpecification')} />
          {project.projectError || modelSpecError ? (
            <ContainerTable>
              <Table>
                <Thead>
                  <Tr>
                    <Th>{translate('variable')}</Th>
                    <Th>{translate('modelSpecificsCoef')}</Th>
                    <Th>{translate('modelSpecificsPValue')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>--</Td>
                    <Td>--</Td>
                    <Td>--</Td>
                  </Tr>
                </Tbody>
              </Table>
            </ContainerTable>
          ) : !modelSpecData || modelSpecLoading ? (
            <ContainerSkeleton style={{ height: '700px' }} />
          ) : (
            modelSpecData && (
              <ContainerTable>
                <Table>
                  <Thead>
                    <Tr>
                      <Th>{translate('variable')}</Th>
                      <Th>{translate('modelSpecificsCoef')}</Th>
                      <Th>{translate('modelSpecificsPValue')}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {modelSpecData.map((modelData, index) => (
                      <Tr key={`${modelData.variable}`}>
                        <Td data-cy={`model-specification-variable-${index}`}>
                          {modelData.variable}
                        </Td>
                        <Td data-cy={`model-coefficient-variable-${index}`}>
                          {modelData.coefficient}
                        </Td>
                        <Td data-cy={`model-value-variable-${index}`}>
                          {modelData.p_value}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </ContainerTable>
            )
          )}
        </ModelSpecification>

        <div className="containerLinear">
          <Card textCard={translate('modelSpecCheckResiduals')} />
          {project.projectError || modelResidualsError ? (
            <ContainerMaintenance content="chart" />
          ) : !modelResidualsData || modelResidualsLoading ? (
            <ContainerSkeleton style={{ height: '700px' }} />
          ) : (
            modelResidualsData && (
              <>
                <ResidualsChart residuals={modelResidualsData.residuals} />

                <section className="residuals-section">
                  <ResidualsAcfChart acf={modelResidualsData.acf} />

                  <ResidualHistogramChart
                    histogram={modelResidualsData.histogram}
                  />
                </section>
              </>
            )
          )}
        </div>
      </ContentSpecificationAndResiduals>

      <ActualVSModelFit modelId={modelId} type="arima" />

      <ActualForecast modelId={modelId} type="arima" />
      <section>
        <div className="containerLinear">
          <Card textCard={translate('modelSpecAnnualVariationTitle')} />
          {project.projectError || annualVariationError ? (
            <ContainerMaintenance
              text={translate('modelSpecErrors').replace(
                'XXX',
                translate('modelSpecAnnualVariation'),
              )}
              content="chart"
            />
          ) : annualVariationLoading || !annualVariationData ? (
            <ContainerSkeleton />
          ) : (
            <AnnualVariationChart data={annualVariationData} />
          )}
        </div>

        <div className="containerLinear">
          <Card textCard={translate('modelSpecAnnualLevelTitle')} />
          {project.projectError || annualLevelError ? (
            <ContainerMaintenance
              text={translate('modelSpecErrors').replace(
                'XXX',
                translate('modelSpecAnnualLevel'),
              )}
              content="chart"
            />
          ) : !annualLevelData || annualLevelLoading ? (
            <ContainerSkeleton />
          ) : (
            <AnnualLevelChart data={annualLevelData} />
          )}
        </div>
      </section>
      <Breakdown modelId={modelId} />
      <FailedModal
        errorInfo={failedModalInfo}
        visible={failedModalVisible}
        setVisible={setFailedModalVisible}
      />
    </Container>
  );
};
