import React, { useEffect } from 'react';

import { Info } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';

import { ContainerModal, ContentButtons } from './styles';

type PropsModal = {
  visible: boolean;
  setVisible: (value: boolean) => void;
  timeOut?: number;
  showNextModal?: (value: boolean) => void;
};

export const FeatureSelectionModal: React.FC<PropsModal> = ({
  visible,
  setVisible,
  timeOut,
  showNextModal,
}) => {
  const { t: translate } = useTranslation();

  useEffect(() => {
    if (timeOut) {
      setTimeout(() => setVisible(false), timeOut * 1000);
    }
  }, [setVisible, timeOut]);

  return (
    <Modal visible={visible} style={{ padding: 0 }}>
      <ContainerModal>
        <Info color="#4C94FF" size="3rem" />

        <h1>{translate('featSelectionTitle')}</h1>
        <p>{translate('featSelectionDescr')}</p>
        <br />
        <p>{translate('featSelectionDescrCont')}</p>

        <ContentButtons>
          <Button
            buttonType="primary"
            data-testid="featureSelectionOkBtn"
            onClick={() => {
              setVisible(false);
              showNextModal && showNextModal(true);
            }}
          >
            Ok
          </Button>
        </ContentButtons>
      </ContainerModal>
    </Modal>
  );
};
