import React from 'react';

import { useTranslation } from 'react-i18next';
import { Warning } from 'phosphor-react';
import { Modal } from 'src/components/Modal';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import { Button } from 'src/components/Button';

import { Container } from './styles';
import { SessionExpiredModalProps } from './types';

export const SessionExpiredModal: React.FC<SessionExpiredModalProps> = ({
  handleBlockEdition,
  loadingBlockEdition,
  handleDiscardChanges,
}) => {
  const { t: translate } = useTranslation();

  return (
    <Modal visible>
      <Container data-testid="session-expired-modal">
        <Warning size="3rem" data-testid="x-circle-icon" />

        <p data-testid="warning-modal-description">
          {translate('editWorkspaceEditionExpired')}
        </p>
      </Container>

      <ModalFooter>
        <Button
          buttonType="naked"
          onClick={handleDiscardChanges}
          disabled={loadingBlockEdition}
          data-testid="exit-edition-button"
        >
          {translate('editWorkspaceExitEdition')}
        </Button>

        <Button
          buttonType="primary"
          onClick={handleBlockEdition}
          loading={loadingBlockEdition}
          disabled={loadingBlockEdition}
          data-testid="block-workspace-edition-button"
        >
          {translate('yes')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
