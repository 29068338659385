import styled, { css } from 'styled-components';

type Favorite = {
  isActive: boolean;
  isLoading?: boolean;
};

export const FavoriteContainer = styled.div<Favorite>`
  display: flex;
  padding: ${({ isLoading }) => (isLoading ? 0 : `0.5rem 0.75rem`)};
  align-items: center;
  gap: 0.5rem;

  cursor: pointer;

  border-radius: 0.5rem;
  border: ${({ theme, isActive }) =>
    isActive ? 'none' : `1px solid ${theme.colors.gray2}`};

  ${({ isLoading }) =>
    isLoading &&
    css`
      border: none;
    `};

  box-sizing: border-box;

  background: ${({ theme, isActive }) =>
    isActive ? 'rgba(76, 148, 255, 0.12)' : theme.colors.white};

  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.primary : theme.colors.gray5};

  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;

  &:hover {
    ${({ theme, isActive }) =>
      isActive
        ? css`
            color: ${theme.colors.primaryDark};
          `
        : css`
            color: ${theme.colors.gray6};
          `};
  }
`;
