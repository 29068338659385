import React from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Maintenance from '../../assets/maintenance.svg';
import { Container } from './styles';
import textEN from './translate/en-us';

interface ContainerMaintenanceProps
  extends React.HTMLAttributes<HTMLInputElement> {
  content: string;
  text?: string;
  description?: string;
  size?: 'sm' | 'md';
}

export const ContainerMaintenance: React.FC<ContainerMaintenanceProps> = ({
  content,
  text,
  description,
  size = 'md',
  ...rest
}) => {
  const { t: translate } = useTranslation();
  const location = useLocation();

  return (
    <Container size={size} {...rest}>
      <img src={Maintenance} alt="image_maintenance" />
      {!text ? (
        location.pathname.includes('/models') ? (
          <>
            <strong>{translate('defaultTitle')}</strong>
            <p>
              {translate('defaultMessage').replace('XXXX', content)}
              <br />
              {translate('textTryAgain')}
            </p>
          </>
        ) : (
          <>
            <strong>{textEN.defaultTitle}</strong>
            <p>
              {textEN.defaultMessage.replace('XXXX', content)}
              <br />
              {textEN.textTryAgain}
            </p>
          </>
        )
      ) : (
        <>
          <strong>{text}</strong>
          {description && <p>{description}</p>}
        </>
      )}
    </Container>
  );
};
