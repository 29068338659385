import styled from 'styled-components';

export const AISelectionExportContainer = styled.div`
  display: flex;

  align-items: center;
  gap: 1.5rem;

  margin-bottom: 24px !important;

  > div {
    flex: 1;
  }

  button {
    white-space: nowrap;
  }
`;
