import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { Input } from 'src/components/Input';
import { Loading } from 'src/components/Loading';
import { Modal } from 'src/components/Modal';
import { ErrorObject, FailedModal } from 'src/components/Modal/Failed';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import { ModalIcons } from 'src/components/Modal/ModalIcons';
import api from 'src/feature-store/service/api';
import { queryClient } from 'src/service/queryClient';
import { arrayIcon, checkIconURL } from 'src/utils/icons/handleProjectIcon';
import * as Yup from 'yup';

import { Container, IconContainer, IconLabelContainer } from './styles';
import { CreateGroup, CreateGroupModalProps, FormCreateGroups } from './types';

const regexFirstCharacter = /^[a-zA-Z0-9\S]/;
const regexLastCharacter = /[a-zA-Z0-9\S_-]$/;

export const CreateGroupModal: React.FC<CreateGroupModalProps> = ({
  visible,
  setVisible,
  setPage,
  predefined,
  groupToEdit,
  categoryType,
}) => {
  const [iconModalVisible, setIconModalVisible] = useState(false);
  const [requestRunning, setRequestRunning] = useState(false);
  const [, setIcon] = useState<string | null>(null);
  const [failedModalVisible, setFailedModalVisible] = useState(false);
  const [failedModalInfo, setFailedModalInfo] = useState<ErrorObject>();

  const { t: translate } = useTranslation();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const createGroupFormSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'createGroupModalNameMustLeast3Characters')
      .matches(
        regexFirstCharacter,
        'createGroupModalNameFirstCharacterMustNotSpace',
      )
      .matches(
        regexLastCharacter,
        'createGroupModalNameLastCharacterMustNotSpace',
      )
      .max(30, 'createGroupModalNameMax30Caracteres')
      .required('createGroupModalRequiredField'),
    icon_url: Yup.string().required('createGroupModalRequiredField'),
    description: predefined
      ? Yup.string().required('createGroupModalRequiredField')
      : Yup.string(),
    is_predefined: Yup.boolean(),
  });

  const {
    control,
    getValues,
    setValue,
    formState: { errors },
    trigger,
    reset,
  } = useForm<FormCreateGroups>({
    resolver: yupResolver(createGroupFormSchema),
  });

  const isIndicatorsPage = pathname.includes('indicators');

  useEffect(() => {
    if (groupToEdit) {
      setValue(
        'icon_url',
        checkIconURL(groupToEdit?.icon_url)
          ? groupToEdit?.icon_url
          : arrayIcon[0],
      );
      setValue('name', groupToEdit.name);
      setValue('description', groupToEdit?.description);
      setValue('is_predefined', groupToEdit?.is_predefined);
    }
  }, [groupToEdit, setValue]);

  async function handleCreateGroup() {
    const hasErrorOnInputs = await Promise.all([
      trigger('name'),
      trigger('icon_url'),
      trigger('description'),
    ]);

    if (!hasErrorOnInputs.includes(false)) {
      setRequestRunning(true);

      const groupData = {
        name: getValues('name'),
        icon_url: !predefined
          ? getValues('icon_url')
          : 'https://storage.googleapis.com/bkt-prod-4casthub/icons/2f9f5ebb-0ac6-461d-b816-49e33149b5b8.png',
        description:
          getValues('description') === undefined
            ? ' '
            : getValues('description'),
        is_predefined: predefined,
        category: categoryType,
      };

      try {
        const { data } = await api.post<CreateGroup>('/groups', groupData);

        if (data) {
          setRequestRunning(false);
          setVisible(false);
          setPage && setPage(1);

          queryClient.invalidateQueries(['favorites', categoryType]);
          queryClient.refetchQueries('my groups page modal');

          if (data?.id && !isIndicatorsPage) {
            navigate(`/feature-store/favorites/${data?.id}`);
          }
        }
      } catch (error) {
        setRequestRunning(false);

        setFailedModalInfo({
          title: translate('groupSeriesCreateRequestFailedTitle'),
          description: translate('groupSeriesCreateRequestFailedDescription'),
        });
        setFailedModalVisible(true);
      }
    }
  }

  async function handleEditGroup() {
    const hasErrorOnInputs = await Promise.all([
      trigger('name'),
      trigger('icon_url'),
      trigger('description'),
    ]);

    if (!hasErrorOnInputs.includes(false) && groupToEdit) {
      setRequestRunning(true);

      const groupData = {
        name: getValues('name'),
        icon_url: !predefined
          ? getValues('icon_url')
          : 'https://storage.googleapis.com/bkt-prod-4casthub/icons/2f9f5ebb-0ac6-461d-b816-49e33149b5b8.png',
        description: getValues('description'),
        is_predefined: predefined,
        category: categoryType,
      };

      try {
        const { data } = await api.put(`/groups/${groupToEdit.id}`, groupData);

        if (data) {
          setRequestRunning(false);
          setVisible(false);
          setPage && setPage(1);

          queryClient.refetchQueries(['favorites', categoryType]);
          queryClient.refetchQueries(['group', groupToEdit.id]);
        }
      } catch (error) {
        setRequestRunning(false);

        setFailedModalInfo({
          title: translate('groupSeriesEditRequestFailedTitle'),
          description: translate('groupSeriesEditRequestFailedDescription'),
        });
        setFailedModalVisible(true);
      }
    }
  }

  function handleCancel() {
    setValue('name', '');
    setValue('description', '');
    setValue('icon_url', '');
    setValue('is_predefined', false);
    setIcon(null);
    reset();
    setVisible(false);
  }

  return (
    <>
      <Modal visible={visible} setVisible={setVisible}>
        <Container>
          {!groupToEdit ? (
            <h3>{translate('createGroupModalCreateTitle')}</h3>
          ) : (
            <h3>{translate('createGroupModalEditTitle')}</h3>
          )}

          <IconLabelContainer>
            <p>{translate('createGroupModalGroupIcon')}</p>
          </IconLabelContainer>
          <IconContainer
            data-testid="select-icon-button"
            onClick={() => !predefined && setIconModalVisible(true)}
            disabled={predefined}
          >
            {getValues('icon_url') ? (
              <img
                data-testid="selected-icon"
                src={
                  predefined
                    ? 'https://storage.googleapis.com/bkt-prod-4casthub/icons/2f9f5ebb-0ac6-461d-b816-49e33149b5b8.png'
                    : getValues('icon_url') ?? ''
                }
                alt="selected-icon"
              />
            ) : (
              <Loading
                style={{ width: '50px', height: '50px', margin: '14px 14px' }}
              />
            )}
          </IconContainer>

          <Controller
            name="name"
            key="name"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Input
                label={translate('name')}
                placeholder={translate('createGroupModalPlaceholderName')}
                data-testid="input-group-name"
                data-cy="input-group-name"
                style={{ marginTop: '24px' }}
                value={value}
                onChange={({ target: { value: val } }) => {
                  onChange(val);
                  if (
                    val.length < 3 &&
                    val.match(regexFirstCharacter) &&
                    !errors.name?.message
                  ) {
                    return;
                  }
                  trigger('name');
                }}
                //@ts-expect-error typescript bugando
                error={translate(errors.name?.message)}
              />
            )}
          />

          <Controller
            name="description"
            key="description"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Input
                label={translate('description')}
                placeholder={translate(
                  'createGroupModalPlaceholderDescription',
                )}
                data-testid="input-group-description"
                data-cy="input-group-description"
                style={{ marginTop: '24px' }}
                value={value}
                onChange={({ target: { value: val } }) => {
                  onChange(val);
                  trigger('description');
                }}
                //@ts-expect-error typescript bugando
                error={translate(errors.description?.message)}
              />
            )}
          />
        </Container>

        <ModalFooter>
          <Button
            buttonType="naked"
            data-testid="create-group-cancel-button"
            data-cy="create-group-cancel-button"
            onClick={handleCancel}
            disabled={requestRunning}
          >
            {translate('cancel')}
          </Button>

          {!groupToEdit ? (
            <Button
              buttonType="primary"
              data-testid="create-group-confirm-button"
              data-cy="create-group-confirm-button"
              onClick={handleCreateGroup}
              disabled={requestRunning}
            >
              {translate('create')}
            </Button>
          ) : (
            <Button
              buttonType="primary"
              data-testid="edit-group-confirm-button"
              data-cy="edit-group-confirm-button"
              onClick={handleEditGroup}
              disabled={requestRunning}
            >
              {translate('confirm')}
            </Button>
          )}
        </ModalFooter>
      </Modal>

      <Controller
        name="icon_url"
        key="icon_url"
        control={control}
        render={({ field: { onChange, value } }) => (
          <ModalIcons
            visible={iconModalVisible}
            setIcon={setIcon}
            setVisible={setIconModalVisible}
            onChangHookForm={onChange}
            value={value}
            content={{
              title: translate('createGroupModalChooseIconTitle'),
              description: translate('createGroupModalChooseIconDescrip'),
            }}
          />
        )}
      />

      <FailedModal
        errorInfo={failedModalInfo}
        visible={failedModalVisible}
        setVisible={setFailedModalVisible}
      />
    </>
  );
};
