import styled from 'styled-components';

export const Size = {
  sm: '1.125rem',
};

type ContainerProps = {
  background: string;
  size: keyof typeof Size;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  gap: 0.5rem;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    width: ${({ size }) => Size[size]};
    height: ${({ size }) => Size[size]};

    background-color: ${({ background }) => background};

    border-radius: 50%;

    font-weight: 700;
    font-size: 0.5rem;
    color: ${({ theme }) => theme.colors.white};
  }

  p {
    font-weight: 500;
    font-size: 0.75rem;
    color: ${({ theme }) => theme.colors.gray5};
  }
`;
