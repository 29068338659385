import React from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';
import { queryClient } from 'src/service/queryClient';

import { Content, ButtonDiv } from './styles';

interface ModalSuccessFailedProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  setModalAction: (value: boolean) => void;
  icon: React.ReactElement;
  title: string;
  message: string;
  status?: string;
  groupSelectedId?: string;
  is4ifavoritesGroup: boolean;
}

export const ModalSuccessFail: React.FC<ModalSuccessFailedProps> = ({
  visible,
  setVisible,
  icon,
  setModalAction,
  title,
  message,
  status,
  groupSelectedId,
  is4ifavoritesGroup,
}) => {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();

  async function handleSendToGroup() {
    setVisible(false);
    await queryClient.invalidateQueries(['group', groupSelectedId]);
    if (is4ifavoritesGroup) {
      navigate(`/feature-store/favorites/${groupSelectedId}`);
    } else {
      navigate(`/feature-store/favorites/${groupSelectedId}`);
    }
  }

  return (
    <Modal visible={visible} setVisible={setVisible} style={{ padding: 0 }}>
      <Content>
        {icon}
        <h2 data-testid="title-status">{title}</h2>
        <p>{message}</p>
      </Content>
      {status === 'success' ? (
        <ButtonDiv>
          <Button
            buttonType="naked"
            onClick={() => {
              setVisible(false);
              setModalAction(false);
            }}
            style={{ marginRight: '2rem' }}
          >
            {translate('backToIndicator')}
          </Button>
          <Button
            buttonType="primary"
            data-testid="button-go-to-group"
            style={{ maxWidth: '12.5rem' }}
            onClick={async () => handleSendToGroup()}
          >
            {translate('goToGroup')}
          </Button>
        </ButtonDiv>
      ) : (
        <ButtonDiv>
          <Button
            buttonType="primary"
            data-testid="button-back-modal-success-fail"
            onClick={() => setVisible(false)}
            style={{ width: '6.875rem' }}
          >
            {translate('sendBack')}
          </Button>
        </ButtonDiv>
      )}
    </Modal>
  );
};
