import React from 'react';

import { useTranslation } from 'react-i18next';
import { Check, DownloadSimple, XCircle } from 'phosphor-react';

import { ContainerExport, ContentExport } from './styles';

export type DownloadStatus = 'started' | 'success' | 'error' | 'running';

interface DownloadWidgetProps {
  status: DownloadStatus;
}

export const DownloadWidget: React.FC<DownloadWidgetProps> = ({ status }) => {
  const { t: translate } = useTranslation();

  return (
    <ContainerExport>
      <ContentExport status={status}>
        <div>
          {status === 'error' ? (
            <XCircle />
          ) : status === 'running' ? (
            <DownloadSimple />
          ) : (
            <Check />
          )}
        </div>

        <div>
          {status === 'error' ? (
            <h4>{translate('widgetDownloadError')}</h4>
          ) : status === 'started' ? (
            <>
              <h4>{translate('widgetDownloadStartTitle')}</h4>
              <p>{translate('widgetDownloadStartDescription')}</p>
            </>
          ) : status === 'running' ? (
            <h4>{translate('widgetDownloadRunning')}</h4>
          ) : (
            <h4>{translate('widgetDownloadFinished')}</h4>
          )}
        </div>
      </ContentExport>
    </ContainerExport>
  );
};
