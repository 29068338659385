const layoutPTBRTranslations = {
  layoutTextCookie:
    'Utilizamos cookies para proporcionar uma melhor experiência, melhorar o desempenho e analisar a forma como interage com o nosso site. Ao clicar no botão, você consente com os cookies e que concorda com os',
  layoutTextRead: 'Leia a',
  layoutTextPrivacyPolicy: 'Política de Privacidade',
  layoutTextTerms: 'Termos de Uso',
  layoutTextAcceptCookie: 'Aceitar cookies',
};

export default layoutPTBRTranslations;
