import styled from 'styled-components';

export const Container = styled.div`
  width: 40vw;
  height: 60vh;
  padding: 2rem 2rem 0;
  display: flex;
  flex-direction: column;

  > h1 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.gray6};
  }

  > p {
    color: ${({ theme }) => theme.colors.gray5};
    font-weight: 400;
    margin-bottom: 1.5rem;
    font-size: 1rem;
  }
`;

export const Content = styled.div`
  overflow: auto;
`;

export const DivStatus = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const Label = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  p {
    margin-left: 0.5rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray6};
  }
`;

export const StatusContent = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  height: 8.75rem;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 8px;
  margin-top: 0.5rem;
  overflow-x: auto;
  text-align: left;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;

  p:first-child {
    margin-top: 1rem;
  }

  p:last-child {
    margin-bottom: 1rem;
  }

  p {
    margin-left: 1rem;
    margin-bottom: 0.5rem;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray5};
  }
`;

export const ButtonDiv = styled.div`
  display: flex;
  height: 5.5rem;
  justify-content: end;
  background: ${({ theme }) => theme.colors.gray0};
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  padding: 1.5rem 2rem;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;
