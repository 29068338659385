const deleteGroupModalPTBRTranslate = {
  deleteGroupModalTitle: 'Você tem certeza absoluta?',
  deleteGroupModalDescription:
    'Essa ação excluirá permanentemente este grupo. Por favor, digite <b>XXX</b> para confirmar.',
  deleteGroupModalMessageError: 'Deve escrever o nome do grupo correto',
  deleteGroupModalMessageRequestFailedDescription:
    'Houve um erro ao deletar o grupo.',
};

export default deleteGroupModalPTBRTranslate;
