import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ModelProdNavigationProps = {
  userCameFromModelProd: boolean;
};

const initialState: ModelProdNavigationProps = {
  userCameFromModelProd: false,
};

const modelProdNavigation = createSlice({
  name: 'model prod navigation',
  initialState,
  reducers: {
    setModelProdNavigation: (
      state,
      action: PayloadAction<ModelProdNavigationProps>,
    ) => {
      state.userCameFromModelProd = action.payload.userCameFromModelProd;
    },
    clearModelProdNavigation: (state) => {
      state.userCameFromModelProd = false;
    },
  },
});

export const { setModelProdNavigation, clearModelProdNavigation } =
  modelProdNavigation.actions;

export default modelProdNavigation.reducer;
