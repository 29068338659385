import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowUpRight,
  CaretLeft,
  GearSix,
  PencilSimple,
  Warning,
} from 'phosphor-react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Head } from 'src/components/Head';
import { Button } from 'src/components/Button';
import apiWorkspace from 'src/workspaces/service/api';
import { RootState } from 'src/redux/store';
import { arrayIcon, checkIconURL } from 'src/utils/icons/handleProjectIcon';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { queryClient } from 'src/service/queryClient';
import { Tooltip } from 'react-tooltip';
import { AxiosError } from 'axios';
import {
  updateReleaseSelected,
  updateStatus,
} from 'src/workspaces/redux/reducers/Workspace';
import { FailedModal } from 'src/components/Modal/Failed';
import { Modal } from 'src/components/Modal';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import { ButtonRounded } from 'src/components/ButtonRounded';
import ProcessingImg from 'src/assets/performing-our-magic.svg';
import { sleep } from 'src/utils/sleep';
import ms from 'ms';
import apiSeries from 'src/models/service/apiSeries';

import {
  Container,
  WorkspaceInfoContainer,
  Footer,
  WorkspaceVariablesContainer,
  ContainerModal,
  WorkspaceBasicInformationContainer,
  NameDescriptionContainer,
} from './styles';
import {
  ExternalSerie,
  ParamsProps,
  ReleaseData,
  ReleasesResponse,
  StagingArea,
  WorkspaceData,
  WorkspaceRelease,
} from './types';
import { useQueryWorkspaceData } from '../../hooks/useQueryWorkspaceData';
import { NoPermissionToEditModal } from '../../components/NoPermissionToEdit';
import { ReleaseErrorModal } from './components/Modal/ReleaseError';
import { EditBasicInformationsModal } from './components/Modal/EditBasicInformations';
import { ShareWorkspace } from './components/ShareWorkspace';
import { SelectedVariables } from './components/SelectedVariables';

export const ControlPanel: React.FC = () => {
  const [getWorkspaceInfo, setGetWorkspaceInfo] = useState(false);

  const [userUpdateLoading, setUserUpdateLoading] = useState(false);

  const [showUserIsEditingModal, setShowUserEditingModal] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingEditionPermission, setLoadingEditionPermission] =
    useState(false);

  const [publishLoading, setPublishLoading] = useState(false);
  const [publishSuccess, setPublishSuccess] = useState(false);
  const [publishError, setPublishError] = useState(false);
  const [publishIsPreview, setPublishIsPreview] = useState<boolean | undefined>(
    undefined,
  );

  const [currentReleaseWithError, setCurrentReleaseWithError] =
    useState<WorkspaceRelease>();

  const [showReleaseError, setShowReleaseError] = useState(false);
  const [showReleaseErrorButton, setShowReleaseErrorButton] = useState(false);

  const [showEditBasicInformationsModal, setShowEditBasicInformationsModal] =
    useState(false);

  const { id: workspaceId } = useParams<ParamsProps>();

  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    auth: {
      user: { email },
    },
    workspace,
  } = useSelector((state: RootState) => state);

  const {
    data: stagingAreaData,
    isLoading: isLoadingStagingArea,
    isFetching: isFetchingStagingArea,
    isError: isErrorStagingArea,
  } = useQuery(
    ['workspace staging area', workspaceId],
    async () => {
      const { data } = await apiWorkspace.get<StagingArea>(
        `/workspaces/${workspaceId}/staging-area`,
      );

      return data;
    },
    {
      staleTime: ms('3 min'),
      enabled: !!workspace.id,
    },
  );

  const {
    data: releasesData,
    isLoading: isLoadingReleases,
    isFetching: isFetchingReleases,
  } = useQuery(
    ['workspace', workspaceId, 'releases'],
    async () => {
      const response = await apiWorkspace.get<ReleasesResponse>(
        `/workspaces/${workspaceId}/releases`,
      );

      return response.data;
    },
    {
      staleTime: ms('3 min'),
      enabled: !!workspaceId,
    },
  );

  const { isLoading: isLoadingWorkspace, isFetching: isFetchingWorkspace } =
    useQueryWorkspaceData(workspaceId ?? '', getWorkspaceInfo, email ?? '');

  const {
    data: currentWorkspaceData,
    isLoading: isLoadingCurrentWorkspace,
    isFetching: isFetchingCurrentWorkspace,
    isError: isErrorCurrentWorkspace,
  } = useQuery(
    ['workspace', workspaceId, 'releases', workspace.releaseCurrent],
    async () => {
      const { data } = await apiWorkspace.get<ReleaseData>(
        `/workspaces/${workspaceId}/releases/${workspace.releaseCurrent}`,
      );

      return data;
    },
    {
      staleTime: ms('3 min'),
      enabled: !!workspace.releaseCurrent,
    },
  );

  const {
    data: previewWorkspaceData,
    isLoading: isLoadingPreviewWorkspace,
    isFetching: isFetchingPreviewWorkspace,
    isError: isErrorPreviewWorkspace,
  } = useQuery(
    ['workspace', workspaceId, 'releases', workspace.releasePreview],
    async () => {
      const { data } = await apiWorkspace.get<ReleaseData>(
        `/workspaces/${workspaceId}/releases/${workspace.releasePreview}`,
      );

      return data;
    },
    {
      staleTime: ms('3 min'),
      enabled: !!workspace.releasePreview,
    },
  );

  const {
    data: releaseWithErrorData,
    isLoading: isLoadingReleaseWithError,
    isFetching: isFetchingReleaseWithError,
    isError: isErrorReleaseWithError,
  } = useQuery(
    ['workspace', workspaceId, 'releases', currentReleaseWithError?.id],
    async () => {
      const { data } = await apiWorkspace.get<ReleaseData>(
        `/workspaces/${workspaceId}/releases/${currentReleaseWithError?.id}`,
      );

      return data;
    },
    {
      staleTime: ms('3 min'),
      enabled: !!currentReleaseWithError?.id,
    },
  );

  const removeDeletedExternalSeriesFromStagingArea = async () => {
    const allExternalSeries = stagingAreaData?.data.ys.filter(
      (y) => y.source === 'external_series',
    );

    if (allExternalSeries?.length && stagingAreaData) {
      const externalSeriesIds = allExternalSeries.map((y) => y.series_id);

      const { data } = await apiSeries.post<ExternalSerie[]>(
        '/series',
        externalSeriesIds,
      );

      const deletedExternalSerie = data
        .filter((y) => y.status === 'removed')
        .map((y) => y.id);

      if (deletedExternalSerie.length) {
        const allTypes = new Set();

        const updatedStagingAreaData = {
          data: {
            ...stagingAreaData.data,
            ys: stagingAreaData.data.ys.filter((y) => {
              const exists =
                !deletedExternalSerie.includes(y.series_id) ||
                y.source === 'projects';

              if (exists) {
                allTypes.add(y.type);
              }

              return exists;
            }),
          },
        };

        if (
          updatedStagingAreaData.data.aggregation?.options?.hierarchical.length
        ) {
          updatedStagingAreaData.data.aggregation.options.hierarchical =
            updatedStagingAreaData.data.aggregation.options.hierarchical.filter(
              ({ y_type }) => allTypes.has(y_type),
            );
          updatedStagingAreaData.data.aggregation.options.temporal =
            updatedStagingAreaData.data.aggregation.options.temporal.filter(
              ({ y_type }) => allTypes.has(y_type),
            );
        }

        await apiWorkspace.put(`/workspaces/${workspaceId}/staging-area`, {
          data: updatedStagingAreaData.data,
        });

        const yFilters = updatedStagingAreaData.data.y_filters;
        const labels = Object.keys(yFilters ?? {});

        if (yFilters && labels.length) {
          const deletedExternalSerieLabel = stagingAreaData.data.ys
            .filter(
              (y) =>
                y.source === 'external_series' &&
                deletedExternalSerie.includes(y.series_id),
            )
            .map((y) => y.y_label);

          for (let i = 0; i < labels.length; i++) {
            const label = labels[i];

            if (deletedExternalSerieLabel.includes(label)) {
              delete yFilters[label];
            }
          }

          await apiWorkspace.patch(
            `/workspaces/${workspaceId}/staging-area/hierarchies`,
            {
              data: {
                filters: updatedStagingAreaData.data.filters,
                y_filters: yFilters,
              },
            },
          );
        }

        if (updatedStagingAreaData.data.aggregation?.options) {
          await apiWorkspace.patch(
            `/workspaces/${workspaceId}/staging-area/aggregation`,
            {
              ...updatedStagingAreaData.data.aggregation.options,
            },
          );
        }

        await queryClient.refetchQueries({
          queryKey: ['workspace staging area', workspaceId],
          exact: true,
        });
      }
    }
  };

  const handleEditWorkspace = async () => {
    if (!loadingEditionPermission) {
      setLoadingEditionPermission(true);

      try {
        await apiWorkspace.patch(`/workspaces/${workspaceId}/edit`);

        queryClient.removeQueries(['workspace staging area', workspaceId]);

        navigate('edition');
      } catch (err) {
        const error = err as AxiosError;

        if (
          error.response?.status === 400 &&
          error.response?.data?.detail?.detail?.startsWith(
            'Workspace already locked for editing by ',
          )
        ) {
          setShowUserEditingModal(
            error.response.data.detail.detail.replace(
              'Workspace already locked for editing by ',
              '',
            ),
          );
        }
      }

      setLoadingEditionPermission(false);
    }
  };

  const handlePublishWorkspace = async (isPreview: boolean) => {
    setPublishIsPreview(isPreview);
    setPublishLoading(true);

    try {
      await apiWorkspace.patch(`/workspaces/${workspaceId}/edit`);
    } catch (err) {
      const error = err as AxiosError;

      if (
        error.response?.status === 400 &&
        error.response?.data?.detail?.detail?.startsWith(
          'Workspace already locked for editing by ',
        )
      ) {
        setShowUserEditingModal(
          error.response.data.detail.detail.replace(
            'Workspace already locked for editing by ',
            '',
          ),
        );
      }
    }

    try {
      await removeDeletedExternalSeriesFromStagingArea();

      await apiWorkspace.post(`/workspaces/${workspaceId}/publish`, {
        preview: isPreview,
      });

      dispatch(updateStatus('publishing'));
      queryClient.invalidateQueries(['workspace', workspaceId]);
      queryClient.invalidateQueries([
        'workspace',
        'releases on sidebar',
        workspaceId,
      ]);

      if (previewDiffStaging) {
        setPublishSuccess(true);
      }
    } catch (err) {
      const error = err as AxiosError;

      const workspaceLockedMessage = 'Workspace already locked for editing by ';
      const WorkspaceIsPublishingNewVersion =
        'You cannot perform this action, Workspace is publishing a new version.';

      if (
        error.response?.status === 400 &&
        error.response?.data?.detail?.detail?.startsWith(workspaceLockedMessage)
      ) {
        const userEmailPublishing = error.response.data.detail.detail.replace(
          workspaceLockedMessage,
          '',
        );

        const errorMessageWorkspaceLocked = `${translate(
          'workspaceControlPanelPublishLocked',
        )} ${userEmailPublishing}`;

        setErrorMessage(errorMessageWorkspaceLocked);
      }

      if (
        error.response?.status === 400 &&
        error.response?.data?.detail?.detail?.startsWith(
          WorkspaceIsPublishingNewVersion,
        )
      ) {
        setErrorMessage(translate('workspaceControlPanelPublishingNewVersion'));
      }

      setPublishError(true);
    }

    setPublishLoading(false);
  };

  useEffect(() => {
    if (workspace.userRole === 'reader') {
      navigate('/workspaces');
    }

    if (!workspace.id) {
      setGetWorkspaceInfo(true);
    }
  }, [workspace, navigate]);

  useEffect(() => {
    const checkStatus = async () => {
      let status = 'publishing';

      while (status === 'publishing') {
        try {
          const { data } = await apiWorkspace.get<WorkspaceData>(
            `/workspaces/${workspaceId}`,
          );

          status = data?.status;

          if (status !== 'publishing') {
            dispatch(updateStatus(status));

            await queryClient.invalidateQueries(['workspaces']);

            setPublishSuccess(false);
          } else {
            await sleep(ms('30 s'));
          }
          // eslint-disable-next-line no-empty
        } catch {}
      }

      queryClient.removeQueries([
        ['workspace', 'releases on sidebar', workspaceId],
      ]);
      queryClient.removeQueries(['workspace', workspaceId, 'releases']);
      queryClient.removeQueries(['workspace data', workspaceId]);
      setGetWorkspaceInfo(true);

      if (!publishIsPreview) {
        queryClient.removeQueries([
          'workspace',
          workspaceId,
          'releases',
          workspace.releaseCurrent,
        ]);
      }

      queryClient.removeQueries([
        'workspace',
        workspaceId,
        'releases',
        workspace.releasePreview,
      ]);
    };

    if (workspace.status === 'publishing') {
      checkStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    workspace.status,
    dispatch,
    workspaceId,
    workspace.releaseCurrent,
    workspace.releasePreview,
  ]);

  useEffect(() => {
    if (!isLoadingReleases && !isFetchingReleases && releasesData?.records) {
      const release = releasesData.records.find(
        ({ status }) => status === 'processing',
      );

      if (release) {
        setPublishIsPreview(release.preview);
      } else if (publishIsPreview === undefined) {
        setPublishIsPreview(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [releasesData, isLoadingReleases, isFetchingReleases]);

  useEffect(() => {
    if (
      releasesData?.records?.[0]?.status === 'error' &&
      email &&
      workspaceId
    ) {
      const workspaceReleaseId = `${workspaceId}-${releasesData.records[0].id}`;

      const worskpaceErrosShowed = JSON.parse(
        localStorage.getItem('workspace-releases-errors') ?? '{}',
      );

      const worskpaceErrosShowedEmail = worskpaceErrosShowed?.[email] ?? [];

      if (!worskpaceErrosShowedEmail.includes(workspaceReleaseId)) {
        localStorage.setItem(
          'workspace-releases-errors',
          JSON.stringify({
            ...worskpaceErrosShowed,
            [email]: [workspaceReleaseId, ...worskpaceErrosShowedEmail],
          }),
        );

        setShowReleaseError(true);
      }
    }
  }, [releasesData, workspaceId, email]);

  const handleAccessWorkspace = () => {
    if (currentWorkspaceData) {
      dispatch(
        updateReleaseSelected({
          releaseSelected: currentWorkspaceData,
          ySelected: currentWorkspaceData.data.ys[0],
        }),
      );

      navigate(`/workspaces/${workspaceId}/overview`);
    }
  };

  const previewIsEmpty = !workspace.releasePreview && workspace.id;

  const currentWorkspaceLoading =
    isLoadingCurrentWorkspace || isFetchingCurrentWorkspace;
  const previewWorkspaceLoading =
    isLoadingPreviewWorkspace || isFetchingPreviewWorkspace;

  const currentWorkspaceDataAdjustedToCompare = currentWorkspaceData?.data
    ? {
        aggregation: currentWorkspaceData.data.aggregation,
        market_share: currentWorkspaceData.data.market_share,
        y_filters: currentWorkspaceData.data.y_filters,
        filters: currentWorkspaceData.data.filters,
        ys: currentWorkspaceData.data.ys.map((y) => ({
          model_id: y.model_id,
          project_id: y.project_id,
          y: y.y,
          y_label: y.y_label,
          is_inflated: y.is_inflated,
        })),
      }
    : null;

  const previewWorkspaceDataAdjustedToCompare = previewWorkspaceData?.data
    ? {
        aggregation: previewWorkspaceData.data.aggregation,
        market_share: previewWorkspaceData.data.market_share,
        y_filters: previewWorkspaceData.data.y_filters,
        filters: previewWorkspaceData.data.filters,
        ys: previewWorkspaceData.data.ys.map((y) => ({
          model_id: y.model_id,
          project_id: y.project_id,
          y: y.y,
          y_label: y.y_label,
          is_inflated: y.is_inflated,
        })),
      }
    : null;

  const stagingAreaDataAdjustedToCompare = stagingAreaData
    ? {
        aggregation: stagingAreaData.data.aggregation,
        market_share: stagingAreaData.data.market_share,
        y_filters: stagingAreaData.data.y_filters,
        filters: stagingAreaData.data.filters,
        ys: stagingAreaData?.data.ys.map((y) => ({
          model_id: y.model_id,
          project_id: y.project_id,
          y: y.y,
          y_label: y.y_label,
          is_inflated: y.is_inflated,
        })),
      }
    : null;

  const currentDiffStaging =
    !isErrorCurrentWorkspace &&
    !isErrorStagingArea &&
    JSON.stringify(currentWorkspaceDataAdjustedToCompare) !==
      JSON.stringify(stagingAreaDataAdjustedToCompare);

  const previewDiffStaging =
    !isErrorStagingArea &&
    !isErrorPreviewWorkspace &&
    JSON.stringify(previewWorkspaceDataAdjustedToCompare) !==
      JSON.stringify(stagingAreaDataAdjustedToCompare);

  useEffect(() => {
    if (releasesData?.records) {
      const releaseWithErrorIndex = releasesData.records.findIndex(
        (release) => release.status === 'error',
      );

      const releaseWithError =
        releaseWithErrorIndex === -1
          ? undefined
          : releasesData.records[releaseWithErrorIndex];

      const hasNoReleaseWithSuccessAfterError =
        releaseWithError &&
        !releasesData?.records?.find(
          (release, index) =>
            release.status === 'success' &&
            release.preview === releaseWithError.preview &&
            index < releaseWithErrorIndex,
        );

      setCurrentReleaseWithError(
        hasNoReleaseWithSuccessAfterError ? releaseWithError : undefined,
      );
    }
  }, [releasesData]);

  useEffect(() => {
    if (releaseWithErrorData && stagingAreaData) {
      const ysWithError = releaseWithErrorData.data.ys
        .filter((y) => y.status === 'error')
        .map((y) => {
          const updatedY = { ...y };

          delete updatedY.status;

          return JSON.stringify(updatedY);
        });

      let hasYWithError = false;

      stagingAreaData.data.ys.forEach((y) => {
        if (ysWithError.includes(JSON.stringify(y))) {
          hasYWithError = true;
        }
      });

      if (hasYWithError) {
        setShowReleaseErrorButton(true);
      }
    }
  }, [releaseWithErrorData, stagingAreaData]);

  const isLoadingWorkspaceBasicInformations =
    !workspace.id ||
    ((isLoadingWorkspace || isFetchingWorkspace) && !workspace.id);

  const userIsManager = workspace.userRole === 'manager';

  const managerHasAlreadyConfiguredTheNewRelease =
    !!stagingAreaData?.data.label && stagingAreaData.data.description;

  const canViewPreview = !previewDiffStaging && previewWorkspaceData?.data;

  const canPublish =
    canViewPreview && userIsManager && managerHasAlreadyConfiguredTheNewRelease;

  const canCreatePreview =
    !!previewIsEmpty || previewDiffStaging || !currentDiffStaging;

  const stagingAreaLoading = isLoadingStagingArea || isFetchingStagingArea;

  const workspaceStatusIsPublishing = workspace.status === 'publishing';

  const releaseConfigurationButtonIsDisabled =
    !userIsManager ||
    !canViewPreview ||
    workspace.status === undefined ||
    workspaceStatusIsPublishing ||
    publishLoading ||
    currentWorkspaceLoading;

  const publishButtonIsDisabled =
    workspace.status === undefined ||
    workspaceStatusIsPublishing ||
    publishLoading ||
    stagingAreaLoading ||
    currentWorkspaceLoading ||
    !canPublish;

  const isPublishing =
    (workspaceStatusIsPublishing || publishLoading) &&
    publishIsPreview === false;

  let publishTooltip = '';

  if (
    !stagingAreaLoading &&
    !currentWorkspaceLoading &&
    !previewWorkspaceLoading
  ) {
    if (!canPublish) {
      if (!userIsManager) {
        publishTooltip = translate('workspaceControlPanelPublishNoPermission');
      } else if (!canViewPreview) {
        publishTooltip = translate(
          'workspaceControlPanelGeneratePreviewTooltip',
        );
      } else if (!managerHasAlreadyConfiguredTheNewRelease) {
        publishTooltip = translate('workspaceControlPanelConfigureNewRealease');
      }
    }
  }

  return (
    <>
      <Head title={translate('workspaceControlPanelHeadTitle')} />

      <Container style={{ cursor: userUpdateLoading ? 'wait' : 'auto' }}>
        <WorkspaceInfoContainer>
          <WorkspaceBasicInformationContainer className="containerLinear">
            {isLoadingWorkspaceBasicInformations ? (
              <>
                <ContainerSkeleton
                  withLoading={false}
                  style={{ width: '4rem', height: '4rem' }}
                />

                <NameDescriptionContainer data-testid="workspace-info-loading">
                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      width: '60%',
                      height: '1.5rem',
                      marginBottom: '0.5rem',
                    }}
                  />
                  <ContainerSkeleton
                    withLoading={false}
                    style={{
                      width: '100%',
                      height: '1.5rem',
                    }}
                  />
                </NameDescriptionContainer>
              </>
            ) : (
              <>
                <img
                  src={
                    workspace.icon && checkIconURL(workspace.icon)
                      ? workspace.icon
                      : arrayIcon[0]
                  }
                  alt="Workspace icon"
                  data-testid="workspace-icon"
                />

                <NameDescriptionContainer data-testid="workspace-info">
                  <div>
                    <h2 data-testid="workspace-name">{workspace.name}</h2>

                    <div style={{ position: 'relative' }}>
                      <ButtonRounded
                        icon={<PencilSimple />}
                        label={translate('edit')}
                        onClick={() =>
                          setShowEditBasicInformationsModal(
                            !showEditBasicInformationsModal,
                          )
                        }
                        disabled={false}
                        data-testid="button-edit-basic-informations"
                      />

                      <EditBasicInformationsModal
                        visible={showEditBasicInformationsModal}
                        setVisible={setShowEditBasicInformationsModal}
                      />
                    </div>
                  </div>

                  <p data-testid="workspace-description">
                    {workspace.description}
                  </p>
                </NameDescriptionContainer>
              </>
            )}
          </WorkspaceBasicInformationContainer>

          <WorkspaceVariablesContainer
            className="containerLinear"
            style={{ marginBottom: '0' }}
          >
            <SelectedVariables
              publishLoading={publishLoading}
              loadingEditionPermission={loadingEditionPermission}
              handleEditWorkspace={handleEditWorkspace}
            />

            <div style={{ width: '100%', textAlign: 'end' }}>
              {!!workspace.releaseCurrent && (
                <Button
                  buttonType="naked"
                  onClick={handleAccessWorkspace}
                  data-testid="access-workspace-button"
                  disabled={
                    ((workspace.status === 'publishing' || publishLoading) &&
                      publishIsPreview === false) ||
                    currentWorkspaceLoading ||
                    isErrorCurrentWorkspace
                  }
                  loading={currentWorkspaceLoading}
                  icon={<ArrowUpRight />}
                  className="naked-button-control-panel"
                  style={{
                    marginTop: '1rem',
                    width: 'fit-content',
                    alignSelf: 'self-end',
                  }}
                >
                  {translate('workspaceControlPanelAccess')}
                </Button>
              )}

              <Footer>
                <Button
                  buttonType="naked"
                  icon={<CaretLeft />}
                  onClick={() => navigate('/workspaces')}
                  className="naked-button-blue"
                >
                  {translate('workspaceControlPanelReturnHomePage')}
                </Button>

                <div>
                  <Button
                    buttonType="naked"
                    icon={<GearSix />}
                    onClick={() => navigate('release-configuration')}
                    className="naked-button-control-panel"
                    disabled={releaseConfigurationButtonIsDisabled}
                    data-testid="button-adjust-settings"
                    data-tooltip-content={
                      isLoadingWorkspaceBasicInformations ||
                      previewWorkspaceLoading
                        ? ''
                        : !userIsManager
                        ? translate(
                            'workspaceControlPanelAdjustSettingsNoPermission',
                          )
                        : !canViewPreview
                        ? translate(
                            'workspaceControlPanelAdjustSettingsCreatePreview',
                          )
                        : ''
                    }
                    data-tooltip-id="workspace-control-panel-tooltip"
                  >
                    {translate('workspaceControlPanelAdjustSettingsButton')}
                  </Button>

                  {showReleaseErrorButton && (
                    <Button
                      className="warning-button"
                      buttonType="naked"
                      icon={<Warning weight="duotone" />}
                      onClick={() => setShowReleaseError(true)}
                    />
                  )}

                  {!canViewPreview ||
                  previewWorkspaceLoading ||
                  stagingAreaLoading ? (
                    <Button
                      buttonType="tertiary"
                      onClick={() => handlePublishWorkspace(true)}
                      disabled={
                        workspace.status === undefined ||
                        workspace.status === 'publishing' ||
                        publishLoading ||
                        stagingAreaLoading ||
                        previewWorkspaceLoading ||
                        !canCreatePreview ||
                        currentWorkspaceLoading
                      }
                      loading={
                        (workspace.status === 'publishing' || publishLoading) &&
                        publishIsPreview
                      }
                      data-testid="create-preview-button"
                    >
                      {translate('workspaceControlPanelCreatePreview')}
                    </Button>
                  ) : (
                    <Button
                      buttonType="tertiary"
                      onClick={() => {
                        dispatch(
                          updateReleaseSelected({
                            releaseSelected: previewWorkspaceData,
                            ySelected: previewWorkspaceData.data.ys[0],
                          }),
                        );

                        navigate(`/workspaces/${workspaceId}/overview`);
                      }}
                      data-testid="visualize-preview-button"
                    >
                      {translate('workspaceControlPanelPreview')}
                    </Button>
                  )}

                  <Button
                    buttonType="primary"
                    onClick={() => handlePublishWorkspace(false)}
                    disabled={publishButtonIsDisabled}
                    loading={isPublishing}
                    data-testid="publish-button"
                    data-tooltip-content={publishTooltip}
                    data-tooltip-id="workspace-control-panel-tooltip"
                  >
                    {translate('workspaceControlPanelPublish')}
                  </Button>
                </div>
              </Footer>
            </div>
          </WorkspaceVariablesContainer>
        </WorkspaceInfoContainer>

        <ShareWorkspace
          isLoading={isLoadingWorkspace || isFetchingWorkspace}
          userUpdateLoading={userUpdateLoading}
          setUserUpdateLoading={setUserUpdateLoading}
        />
      </Container>

      <Tooltip
        id="workspace-control-panel-tooltip"
        className="white-tooltip-theme"
      />
      <Tooltip
        id="workspace-control-panel-variable-tooltip"
        className="customTooltipTheme customTooltipThemeMaxWidth50"
        style={{ wordBreak: 'break-all' }}
      />

      {!!showUserIsEditingModal && (
        <NoPermissionToEditModal
          setVisible={() => setShowUserEditingModal('')}
          emailEditing={showUserIsEditingModal}
        />
      )}

      {publishError && (
        <FailedModal
          errorInfo={{
            title: translate('workspaceControlPanelPublishErrorTitle'),
            description: errorMessage,
          }}
          visible
          setVisible={setPublishError}
        />
      )}

      {showReleaseError && (
        <ReleaseErrorModal
          setVisible={() => setShowReleaseError(false)}
          isPreview={!!releasesData?.records?.[0]?.preview}
          publishedBy={releasesData?.records?.[0]?.published_by ?? ''}
          ys={
            releaseWithErrorData?.data?.ys?.filter(
              (y) => y?.status === 'error',
            ) ?? []
          }
          loadingError={
            isLoadingReleaseWithError ||
            isFetchingReleaseWithError ||
            (!releaseWithErrorData && !isErrorReleaseWithError)
          }
        />
      )}

      {publishSuccess && (
        <Modal visible setVisible={setPublishSuccess}>
          <ContainerModal data-testid="publishing-creating-preview-workspace">
            <img src={ProcessingImg} alt="performing-our-magic" />

            <h1>{translate('workspaceControlPanelPublishTitle')}</h1>
            <p>
              {publishIsPreview
                ? translate('workspaceControlPanelPreviewDescription')
                : translate('workspaceControlPanelPublishDescription')}
            </p>
          </ContainerModal>

          <ModalFooter>
            <Button
              buttonType="primary"
              onClick={() => setPublishSuccess(false)}
              data-testid="button-ok"
            >
              Ok
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};
