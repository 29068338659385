import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 0 !important;
  margin: 2.25rem 0px 1.5rem;
  width: 100%;
  height: 100%;
`;

interface Triangulo {
  value: number;
  maxValue: number;
}

export const ContentTriangulo = styled.div`
  position: relative;
  width: 100%;
  height: 8.75rem;
  margin-bottom: 1.5rem;
  background: rgb(255, 255, 255);
`;

export const Triangulo = styled.div`
  width: 100%;
  height: 8.75rem;
  background: linear-gradient(
    165deg,
    rgba(255, 255, 255, 1) 50%,
    #e2e8f0 50%,
    rgba(255, 255, 255) 100%
  );
`;

export const TrianguloSelected = styled.div<Triangulo>`
  position: absolute;
  bottom: 0px;
  left: 0px;

  width: ${({ value, maxValue }) => `${(value / maxValue) * 100}%`};
  height: ${({ value, maxValue }) => `${(value / maxValue) * 100}%`};
  background: ${({ theme }) =>
    `linear-gradient(165deg,rgba(255, 255, 255, 1) 50%,${theme.colors.primaryLight} 50%, rgba(255, 255, 255) 100%)`};
`;

export const Marks = styled.div`
  position: relative;
  display: flex;

  > button {
    position: absolute;

    display: flex;
    flex-direction: column;
    align-items: center;

    width: 1.875rem;

    font-size: 0.75rem;
    color: ${({ theme }) => theme.colors.gray5};

    > div {
      width: 0.125rem;
      height: 0.375rem;
      background: ${({ theme }) => theme.colors.gray3};
    }
  }
`;

export const Slider = styled.input.attrs(() => ({
  type: 'range',
}))`
  height: 1.25rem;
  width: 100%;
  -webkit-appearance: none;
  cursor: pointer;
  transform: skew(15deg, -15deg);
  position: absolute;
  bottom: 3.875rem;
  background: transparent;
  transform: skew(15deg, -15deg);
  z-index: 1;

  &:focus {
    outline: none;
  }

  /*webkit*/
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    position: absolute;
    width: 0.938rem;
    height: 0.938rem;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.primary};
    border: none;
    margin-top: calc(max((1em) * 0.5, 0px) - 0.938rem * 0.5);

    left: ${({ value, max }) =>
      `calc(${
        (Number(value ?? 0) / Number(max ?? 1)) * 100
      }% - 7.5px)`} !important;
  }

  &::-webkit-slider-runnable-track {
    height: 1em;
    border: 0;
    border-radius: 0.5em;
    background: transparent;
    box-shadow: none;
  }

  &::-webkit-slider-thumb:active {
    box-shadow: 0 0 5px ${({ theme }) => theme.colors.primaryLight};
  }

  /*mozilla*/
  &::-moz-range-thumb {
    width: 0.938rem;
    height: 0.938rem;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.primary};
    border: none;
  }

  &::-moz-range-track {
    height: max(calc(1em - 0.063rem - 0.063rem), 0px);
    border: 0;
    border-radius: 0.5em;
    background: transparent;
    box-shadow: none;
  }

  &::-moz-range-thumb:active {
    box-shadow: 0 0 5px ${({ theme }) => theme.colors.primaryLight};
  }

  /*ms*/
  &::-ms-fill-upper {
    background: transparent;
    border-color: transparent;
  }

  &::-ms-fill-lower {
    background: transparent;
    border-color: transparent;
  }

  &::-ms-thumb {
    width: 0.938rem;
    height: 0.938rem;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.primary};
    border: none;
    margin-top: 0;
    box-sizing: border-box;
  }

  &::-ms-track {
    height: 1em;
    border: 0;
    background: transparent;
    box-shadow: none;
    box-sizing: border-box;
  }

  &::-ms-thumb:active {
    box-shadow: 0 0 5px ${({ theme }) => theme.colors.primaryLight};
  }
`;

interface TooltipValue {
  value: number;
  maxValue: number;
  widthText: number;
}

export const TooltipValue = styled.div<TooltipValue>`
  position: absolute;

  bottom: ${({ value, maxValue }) =>
    `calc(${(value / maxValue) * 100}% + 1.5rem)`};

  ${({ value, maxValue, widthText }) =>
    widthText + 4 > 21
      ? css`
          left: ${`calc(${(value / maxValue) * 100}% - ${
            (widthText + 4) / 2 / 16
          }rem)`};
        `
      : css`
          left: ${`calc(${(value / maxValue) * 100}% - 0.656rem)`};
        `}

  min-width: 1.313rem;
  background: ${({ theme }) => theme.colors.primary};
  padding: 0.375rem 0.125rem;
  border-radius: 6px;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;

  &:after {
    content: '';
    position: absolute;
    bottom: -0.25rem;
    left: ${({ widthText }) =>
      widthText + 4 > 21 ? ((widthText + 4) / 2 - 4) / 16 : 6.5 / 16}rem;
    width: 0;
    height: 0;

    border-top: 4px solid ${({ theme }) => theme.colors.primary};
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
  }
`;
