import styled from 'styled-components';

export const Container = styled.div`
  section {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    > div {
      flex: 1 1 18.75rem;
    }
  }

  .select {
    width: 20rem;
    margin-bottom: 1.5rem;

    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }

  .relative {
    position: relative;
  }

  .css-26l3qy-menu {
    z-index: 700;
  }
`;

export const ShowModelButton = styled.button`
  display: flex;
  padding-left: 0 !important;
  margin-top: 1.5rem;

  font-weight: 600;
  font-size: 1rem;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.primary};

  :hover {
    color: ${({ theme }) => theme.colors.primaryDark};
  }
`;
