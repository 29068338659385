const modelExclusionPTBR = {
  exclusionTitle: 'Você tem certeza?',
  exclusionDescription:
    'Seu projeto será permanentemente deletado e todas as suas informações. Por favor, escreva <b>XXX</b> para confirmar.',
  exclusionCancelButton: 'Cancelar',
  exclusionDeleteButton: 'Confirmar',
  exclusionDeleteRequiredField: 'Campo obrigatório',
  exclusionDeleteButtonWrongNameError: 'Escreva o nome correto do arquivo',
  exclusionDeleteRequestFailed: 'Falha na requisição!',
  exclusionDeleteError: 'Ocorreu um erro ao excluir o arquivo.',
};

export default modelExclusionPTBR;
