const AccessGroupEN = {
  accessRequiredField: 'Required field',
  accessGroup: 'groups',
  accessError: 'Error',
  accessGroupTitle: 'Feature Store Access Group.',
  accessGroupDescription:
    'Create, edit, delete and view all Feature Store Groups.',
  accessGroupList: 'Group List',
  accessGroupCreateGroup: 'Create new group',
  accessGroupError: 'Unable to create group.',
  accessCode: 'Code',
  accessName: 'Name',
  accessActions: 'Actions',
  accessIndicators: 'indicators',
  accessIndicatorsList: 'Indicators',
  accessDescription: 'Description',
  accessSource: 'Source',
  AccessClients: 'Client List',
  accessErrorTitle: 'Error',
  accessNoGroupTitle: 'Add indicators to this premium group.',
  accessNoGroupDescription:
    'Add the first indicator in this group by clicking the button above.',
  accessErrorDescription: 'Unable to load groups.',
  accessRegisterGroup: 'Register new group',
  accessEditGroup: 'Edit group',
  accessRegisterGroupDescription:
    'Write the name and description of the Premium Group or Private, in Portuguese and English.',
  accessRegisterNamePT: 'Name in Portuguese (Portuguese)',
  accessRegisterNameEN: 'Name in English (English)',
  accessRegisterNamePTPlaceHolder: 'Enter group name (Portuguese)',
  accessRegisterNameENPlaceHolder: 'Enter group name (English)',
  accessRegisterDescriptionPTPlaceHolder:
    'Enter the group description (Portuguese)',
  accessRegisterDescriptionENPlaceHolder:
    'Enter the group description (English)',
  accessRegisterDescriptionPT: 'Group description in Portuguese (Portuguese)',
  accessRegisterDescriptionEN: 'Group description in English (English)',
  accessContinue: 'Continue',
  accessAddIndicatorInGroupTitle: 'Insert indicator in group',
  accessAddIndicatorInGroupDescription:
    'Search and add the indicators to the XX Group.',
  accessLoadIndicatorsError: 'Error loading premium indicators.',
  accessSearchIndicator: 'Search Indicator',
  accessNewUserTitle: 'Add new user to group',
  accessNewUserDescription:
    'Add customers who are allowed access to the Premium Group.',
  accessNewUser: 'Clients',
  accessInputPlaceHolder: 'Enter the customers email here',
  accessAddIndicators: 'Add Indicators',
  accessAddUser: 'Add Users',
  accessErrorIndicators: 'Unable to load indicators for this group.',
  accessErrorUsers: 'Unable to load users for this group.',
  accessDelete: 'Delete',
  accessUnableToFindIndicator: 'This group does not have any indicators.',
  accessUnableToFindUser: 'This group does not have any user.',
  accessUsers: 'users',
  deleteUserTitle: 'Are you sure?',
  deleteUserDescription:
    'This will permanently delete this user from this group.',
  deleteUserDescriptionPlural:
    'This will permanently delete these users from this group.',
  deleteUserFailedTitle: 'Failed to delete user',
  deleteUserFailedDescription: 'Unable to delete user, please try again',
  deleteIndicatorTitle: 'Are you sure?',
  deleteIndicatorDescription:
    'This will permanently delete this indicator from this group.',
  deleteIndicatorDescriptionPlural:
    'This will permanently delete these indicators from this group.',
  deleteIndicatorFailedTitle: 'Failed to delete indicator',
  deleteGroupTitle: 'Are you sure?',
  deleteGroupDescription: 'This will permanently delete this group.',
  deleteIndicatorFailedDescription:
    'Unable to delete indicator, please try again.',
  deleteGroupFailedTitle: 'Failed to delete group.',
  deleteGroupFailedDescription: 'Unable to delete group, please try again.',
  editErrorDescription: 'Unable to edit this group.',
};

export default AccessGroupEN;
