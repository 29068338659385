import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50rem;
  padding: 1.5rem;

  @media (max-height: 760px) {
    height: 70vh;
  }

  > div {
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    h4 {
      font-size: 1.5rem;
      font-weight: 700;
      color: ${({ theme }) => theme.colors.gray6};

      margin-bottom: 0.5rem;
    }

    p {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;

      color: ${({ theme }) => theme.colors.gray5};
    }

    b {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.primary};
    }

    > div {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-top: 1.5rem;
    }
  }
`;

interface RequirementRowProps {
  subItem?: boolean;
}

export const RequirementRow = styled.div<RequirementRowProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.594rem;

  @media (max-height: 760px) {
    margin-right: 0.75rem;
  }

  ${({ subItem }) =>
    subItem &&
    css`
      margin-left: 1.625rem;
    `}

  svg {
    color: ${({ theme }) => theme.colors.gray3};
  }
`;

export const ButtonsContent = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.gray2};
  background: ${({ theme }) => theme.colors.gray0};
  width: 100%;
  height: 5.875rem;
  padding: 1.5rem 1.5rem;
  border-radius: 0 0 8px 8px;

  display: flex;
  justify-content: space-between;

  button {
    display: flex;
    justify-content: center;
  }

  a {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.375rem;
    gap: 0.625rem;
    color: ${({ theme }) => theme.colors.gray6};

    :hover {
      color: ${({ theme }) => theme.colors.gray7};
    }
  }
`;
