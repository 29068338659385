import styled, { css, keyframes } from 'styled-components';

interface WarningContainerProps {
  visible: boolean;
  hasIcon?: boolean;
}

const warnAppearanceAnimation = keyframes`
  from{
    opacity: 0;
  }
  to{
    opacity:1;
  }
`;

export const WarningContainer = styled.div<WarningContainerProps>`
  padding: 1rem;
  margin-top: 1.5rem;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.yellow2};
  background: ${({ theme }) => theme.colors.yellow0};

  ${({ visible }) =>
    visible &&
    css`
      animation: ${warnAppearanceAnimation} 0.7s;
    `}

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  svg {
    color: ${({ theme }) => theme.colors.yellow5};
  }

  p {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: ${({ theme }) => theme.colors.yellow5};
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;
