import { Tabs } from 'src/components/Tabs';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 70vw;
  height: 80vh;
  max-height: 43rem;
  max-width: 62.25rem;
  margin-bottom: -1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  section {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.gray6};
  }

  p {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.375rem;
    /* or 1.5rem */

    /* Neutral/Gray/500 */

    color: ${({ theme }) => theme.colors.gray5};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const DescriptionSearch = styled.div`
  display: flex;
  justify-content: space-between;
  div {
    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 2rem;
      color: ${({ theme }) => theme.colors.gray6};
      margin-bottom: 0.5rem;
    }
    p:first-child {
      margin: 0.5rem 0;
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 150%;
      color: ${({ theme }) => theme.colors.gray5};
    }
  }
`;

export const SearchDiv = styled.div`
  display: flex;
  margin-top: 0.938rem;

  svg:first-of-type {
    margin-left: 0px !important;
    color: ${({ theme }) => theme.colors.gray4} !important;
  }

  .input-search {
    width: calc(100% - 20px);
  }

  .input-search-error {
    width: calc(100% - 20px - 16px - 16px);
  }
`;

export const ModifiedTabs = styled(Tabs)`
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  flex: 1;

  > div:nth-child(2) {
    width: 100%;
    height: calc(80vh - 15.313rem);
    max-height: 27.688rem;
    overflow: auto;
    position: relative;
  }
`;

export const ContainerWithoutGroups = styled.div`
  width: 100%;
  padding: 1.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GroupsContainer = styled.div`
  min-height: 31.25rem;
  padding: 1rem 0;
  padding-right: 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 1rem;
  @media (max-width: 1500px) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 1rem;
  }
  @media (max-width: 1366px) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 1rem;
  }
`;

export const GroupCardLoading = styled.div`
  display: flex;
  flex: 1 0 17.5rem;

  align-items: flex-start;
  flex-direction: column;
  max-width: 21.875rem;
  padding: 0.875rem 0.875rem 1.5rem;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  box-sizing: border-box;
  border-radius: 8px;
  position: relative;
  max-height: 15.625rem;

  img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 4px;
  }

  span {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 150%;

    color: ${({ theme }) => theme.colors.gray5};
    margin-top: 1rem;
  }

  p {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray7};
    margin-top: 0.5rem;
  }
`;

export const DivPagination = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: auto;
  padding: 0px 1.25rem;
`;

export const MessageDisable = styled.div`
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray5};
  }
`;

export const ContainerPickForMe = styled.div`
  height: 100%;
  width: 100%;
`;

export const ContainerPickForMeStepOne = styled.div`
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > img {
    width: 8.75rem;
    height: 10rem;

    margin-bottom: 2rem;
  }

  p {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.938rem;
    color: ${({ theme }) => theme.colors.gray7};

    margin-bottom: 1rem;
  }

  span {
    max-width: 29.813rem;
    text-align: center;
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray5};
    margin-bottom: 1.5rem;
  }

  button {
    display: flex;
    flex-direction: row;
    align-items: center;

    height: 3rem;

    padding-right: 1.5rem;
    background: ${({ theme }) => theme.colors.primary};
    border-radius: 5px;

    font-size: 0.875rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.white};
    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.93);
    }

    img {
      padding: 0.75rem;
      background: ${({ theme }) => theme.colors.primaryLight};
      margin-right: 1.5rem;
      border-radius: 5px 0 0 5px;
    }
  }
`;

export const ContainerPickForMeStepTwo = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > div {
    width: 4.75rem;
    height: 5rem;
    margin-bottom: 2rem;
  }

  p {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.688rem;
    color: ${({ theme }) => theme.colors.gray7};

    margin-bottom: 1rem;
  }

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    text-align: center;
    color: ${({ theme }) => theme.colors.gray5};
    max-width: 25rem;
  }
`;

export const ContainerPickForMeStepThree = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 0.063rem);

  padding-right: 0.5rem;
  table {
    border-spacing: 0 2rem;
    margin: -2rem 0;
    thead {
      position: sticky;
      top: -0.063rem;
      z-index: 99;
      background: ${({ theme }) => theme.colors.white};
      tr {
        th {
          padding-top: 2rem;
        }
      }
    }
  }
`;

interface NameColumnPickForMe {
  isTitle?: boolean;
}

export const NameColumnPickForMe = styled.div<NameColumnPickForMe>`
  > label {
    p {
      margin-left: 2rem;
      ${({ isTitle }) =>
        isTitle
          ? css`
              color: ${({ theme }) => theme.colors.gray6};
              font-style: normal;
              font-weight: 700;
              font-size: 0.75rem;
              line-height: 1.375rem;
            `
          : css`
              color: ${({ theme }) => theme.colors.gray5};
            `}
    }
    span {
      margin: 0;
    }
  }
`;

export const ColumnProbability = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-left: 0.5rem;
  }
`;

export const DivInfoSeriesPremium = styled.div`
  display: flex;
  margin-bottom: 0.5rem;

  p {
    font-size: 0.875rem !important;
  }

  img {
    vertical-align: middle;
    width: 0.875rem;
    height: 0.875rem;
    margin-left: 0.125rem;
    margin-bottom: 0.188rem;
  }

  span {
    color: ${({ theme }) => theme.colors.yellow4};
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.313rem;
  }

  a {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: underline;
  }
`;
