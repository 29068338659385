const ROCCurvePTBRTranslations = {
  rocCurveTitle: 'Curva ROC',
  rocCurveDescription:
    'Avalie a performance do seu modelo de acordo com sua curva ROC nos dados de treino.',
  rocCurvePositiveClassLabel: 'Classe positiva',
  rocCurveSelectedModel: 'Modelo selecionado',
  rocCurveRandomModel: 'Modelo aleatório',
  rocCurveXChartLabel: 'Taxa de Falsos Positivos',
  rocCurveYChartLabel: 'Taxa de Verdadeiros Positivos',
  rocCurveAUCLabel: 'Área sob a curva',
};

export default ROCCurvePTBRTranslations;
