import React, { ChangeEvent, useEffect, useState } from 'react';

import { Info, Question, X } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import csvFileImg from 'src/assets/csv-file.svg';
import rdsFileImg from 'src/assets/rds-file.svg';
import uploadImg from 'src/assets/uploadProject.svg';
import xlsxFileImg from 'src/assets/xlsx-file.svg';

import { RequirementsModal } from '../../../Classification/components/Modals/RequirementsModal';
import { ModalSeeRequirementsTimeSeries } from '../Modal/SeeRequirementsTimeSeries';
import {
  ButtonSeeRequirements,
  Container,
  InfoContent,
  InputFileContainer,
  LabelContainer,
  SpreadsheetContainer,
  SpreadsheetContent,
} from './styles';
import { InputFileProps } from './types';

export const InputFile: React.FC<InputFileProps> = ({
  label,
  information,
  title,
  file,
  setFile,
  value,
  onChange = () => null,
  fileFormats,
  error,
  isDisabled = false,
  style,
  dataCy,
  rowLayout,
  isTimeSeries = false,
  isClassification = false,
  children,
}) => {
  const [codeError, setCodeError] = useState<number | null>(null);
  const [modalVisibleSeeRequirements, setModalVisibleSeeRequirements] =
    useState(false);

  const [dragging, setDragging] = useState(false);

  const drop = React.useRef<HTMLDivElement>(null);

  const { t: translate } = useTranslation();

  const errors: Record<number, string> = {
    1: translate('fileInputOnlyOneFile'),
    2: `${translate('fileInputValidExtensions')}: ${fileFormats
      .toString()
      .replaceAll(',', ', ')}`,
  };

  const handleUploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.files?.[0]);
    setCodeError(null);
    onChange(null);
  };

  useEffect(() => {
    const formatIsAllowed = (name: string) =>
      fileFormats.some((format) => name.endsWith(format));

    const preventDefaultStopPropagation = (e: Event) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDragEnter = (e: DragEvent) => {
      preventDefaultStopPropagation(e);

      setDragging(true);
    };

    const handleDragLeave = (e: DragEvent) => {
      preventDefaultStopPropagation(e);

      setDragging(false);
    };

    const handleDragOver = (e: DragEvent) => {
      preventDefaultStopPropagation(e);
    };

    const handleDrop = (e: DragEvent) => {
      preventDefaultStopPropagation(e);

      setDragging(false);
      setCodeError(null);

      const { files } = e.dataTransfer as DataTransfer;

      if (files && files.length && !isDisabled) {
        const uploadFile = files[0];

        if (files.length > 1) setCodeError(1);
        else if (!formatIsAllowed(uploadFile.name.toLowerCase()))
          setCodeError(2);
        else {
          setFile(uploadFile);
          onChange(null);
        }
      }
    };

    const dropDiv = drop.current;

    if (dropDiv) {
      dropDiv.addEventListener('dragover', handleDragOver);
      dropDiv.addEventListener('dragenter', handleDragEnter);
      dropDiv.addEventListener('dragleave', handleDragLeave);

      dropDiv.addEventListener('drop', handleDrop);

      return () => {
        dropDiv.removeEventListener('dragover', handleDragOver);
        dropDiv.removeEventListener('dragenter', handleDragEnter);
        dropDiv.removeEventListener('dragleave', handleDragLeave);

        dropDiv.removeEventListener('drop', handleDrop);
      };
    }
  }, [setFile, fileFormats, onChange, isDisabled]);

  return (
    <>
      <Container data-cy={dataCy} style={style}>
        <LabelContainer>
          <label>{label}</label>

          {information && (
            <>
              <Info
                size="1.125rem"
                data-tooltip-id="input-file-tooltip"
                data-tooltip-content={information}
                data-cy={`input-information-${label?.replaceAll(' ', '-')}`}
                data-testid="inputInfo"
              />

              <Tooltip id="input-file-tooltip" className="customTooltipTheme" />
            </>
          )}
        </LabelContainer>

        <InputFileContainer
          ref={drop}
          dragging={dragging}
          isDisabled={isDisabled}
          error={!!codeError || !!error}
          data-testid="file-drop"
          data-cy="file-drop"
          rowLayout={rowLayout}
        >
          <div>
            <input
              id="project"
              type="file"
              accept={fileFormats.toString()}
              onClick={(e) => {
                e.currentTarget.value = '';
              }}
              onChange={handleUploadFile}
              disabled={isDisabled}
              data-testid={dataCy}
              data-cy="input-file"
            />

            <img
              src={uploadImg}
              alt="Imagem de realizar upload de um arquivo"
            />

            <h1>{title}</h1>

            <p>{translate('dragDrop')}</p>
          </div>

          {(file || value) && (
            <SpreadsheetContainer
              data-testid="selected-file"
              data-cy="selected-file"
              rowLayout={rowLayout}
              className="selected-file"
            >
              <SpreadsheetContent>
                <img
                  src={
                    file?.name.endsWith('.csv') ||
                    value?.dataset_name.endsWith('.csv')
                      ? csvFileImg
                      : file?.name.endsWith('.xlsx') ||
                        value?.dataset_name.endsWith('.xlsx')
                      ? xlsxFileImg
                      : rdsFileImg
                  }
                  alt="Imagem de um arquivo"
                  data-testid="extension-img"
                  data-cy="extension-img"
                />

                <InfoContent>
                  <b data-testid="file-name" data-cy="file-name">
                    {file?.name || value?.dataset_name}
                  </b>
                  <p data-testid="file-size" data-cy="file-size">
                    {file?.size &&
                      (file.size >= 1000000
                        ? `${(file.size / 1000000).toFixed(2)} MB`
                        : file.size >= 1000
                        ? `${(file.size / 1000).toFixed(2)} kB`
                        : `${file.size} B`)}
                  </p>
                </InfoContent>
              </SpreadsheetContent>

              <button
                type="button"
                onClick={() => {
                  setFile(undefined);
                  onChange(null);
                }}
                disabled={isDisabled}
                data-testid="remove-file"
                data-cy="remove-file"
                aria-label="remove file button"
              >
                <X weight="bold" />
              </button>
            </SpreadsheetContainer>
          )}

          {(codeError || error) && (
            <span data-testid="file-error" data-cy="file-error">
              {codeError ? errors[codeError] : error}
            </span>
          )}

          {(isTimeSeries || isClassification) && (
            <ButtonSeeRequirements
              onClick={() => setModalVisibleSeeRequirements(true)}
              data-testid="button-see-requirements"
              data-cy="button-see-requirements"
            >
              <Question size="1.125rem" weight="bold" />
              {translate('fileInputSeeRequirements')}
            </ButtonSeeRequirements>
          )}

          {children && children}
        </InputFileContainer>
      </Container>
      {isTimeSeries && modalVisibleSeeRequirements && (
        <ModalSeeRequirementsTimeSeries
          setVisible={setModalVisibleSeeRequirements}
        />
      )}

      {isClassification && modalVisibleSeeRequirements && (
        <RequirementsModal
          visible
          setVisible={setModalVisibleSeeRequirements}
        />
      )}
    </>
  );
};
