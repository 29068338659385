import styled from 'styled-components';

export const Container = styled.div`
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    color: ${({ theme }) => theme.colors.gray5};
    p {
      font-weight: 500;
      font-size: 0.875rem;
      margin-right: 1.5rem;
      line-height: 1.375rem;
      color: ${({ theme }) => theme.colors.gray5};
    }
  }
`;

interface InputToogle {
  optionName: {
    true: string;
    false: string;
  };
}

export const InputToogle = styled.input<InputToogle>`
  /*Adiciona border-box para o input e para os pseudo-elementos*/
  &[type='checkbox'],
  &[type='checkbox']::after,
  &[type='checkbox']::before {
    box-sizing: border-box;
  }

  /*Estiliza e remove a aparencia padrão do elemento*/
  &[type='checkbox'] {
    outline: none;
    position: relative;
    z-index: 1;
    margin: 0.125rem;
    padding: 0;
    cursor: pointer;
    width: 3rem;
    height: 1.25rem;
    overflow: hidden;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  /*cria os elementos before e after*/
  &[type='checkbox']::before,
  &[type='checkbox']::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

    /*efeito animado*/
    transition: left 0.15s cubic-bezier(0.25, 0.8, 0.25, 0.1),
      transform 0.15s ease-in;
  }

  /*Cor padrão de quando o elemento não esta selecionado*/
  &[type='checkbox']::before {
    font-weight: bold;
    font-size: 0.625rem;
    padding-top: 0.25rem;
    padding-left: 1.375rem;
    color: ${({ theme }) => theme.colors.white};

    content: '${({ optionName }) => optionName.false}';
    background-color: ${({ theme }) => theme.colors.gray3};
    width: 100%;
    height: 100%;
    border-radius: 28px;
  }

  /*estiliza para parecer um botão toggle*/
  &[type='checkbox']::after {
    margin: 0.125rem 0 0 0.188rem;
    background: ${({ theme }) => theme.colors.white};
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  }

  /*troca a cor se estiver com a propriedade checked no html ou o usuário "checar"*/
  &[type='checkbox']:checked::before {
    font-weight: bold;
    font-size: 0.625rem;
    padding-top: 0.25rem;
    padding-left: 0.5rem;
    color: ${({ theme }) => theme.colors.white};
    content: '${({ optionName }) => optionName.true}';

    background-color: ${({ theme }) => theme.colors.primary};
  }

  /*muda a posição do botão toggle se estiver checado*/
  &[type='checkbox']:checked::after {
    left: 1.625rem;
  }

  /*Efeito opicional de quando preciona o botão*/
  &[type='checkbox']:active::after {
    transform: scale(1.15, 0.85);
  }

  /*Se o input tiver com o atributo disabled a cor é alterada*/

  &[type='checkbox']:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;
