import React, { CSSProperties } from 'react';

import { ArrowLeft } from 'phosphor-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { BackToFSHome } from './styles';

export type BackToHomeProps = {
  style?: CSSProperties;
};

export const BackToHome: React.FC<BackToHomeProps> = ({ style }) => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const routeFavorite = location.pathname.startsWith(
    '/feature-store/favorites',
  );

  return (
    <BackToFSHome
      style={style}
      data-testid="back-to-fs-home"
      onClick={() => {
        if (location.key === 'default' || routeFavorite) {
          navigate('/feature-store/home');
        } else navigate(-1);
      }}
    >
      <ArrowLeft />{' '}
      {routeFavorite ? translate('backToHome') : translate('back')}
    </BackToFSHome>
  );
};
