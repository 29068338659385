import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: relative;

  max-width: 335px;

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0.313rem;
    height: 0.313rem;
    padding: 0.063rem;
  }

  ::-webkit-scrollbar-track {
    transition: 0.2s;
    margin: 0.188rem;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  width: 100%;

  position: sticky;
  top: 0;

  background: ${({ theme }) => theme.colors.white};
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;

  margin-bottom: 1rem;

  h5 {
    color: ${({ theme }) => theme.colors.gray6};
    font-family: Inter;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const ShowAllIndicators = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  height: 2.25rem;

  padding-bottom: 0.5rem;
`;

export const ListAllIndicatorsButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  color: ${({ theme }) => theme.colors.primary};

  cursor: pointer;

  text-align: center;

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.gray4};
  }
`;

export const AccessGroupsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  margin-top: 1rem;
  margin-bottom: 1rem;
`;

type ActiveCard = {
  isActive: boolean;
};

export const AccessGroupContainer = styled.div<ActiveCard>`
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid
    ${({ theme, isActive }) =>
      isActive ? theme.colors.primary : theme.colors.gray2};
  background: ${({ theme }) => theme.colors.white};
`;

export const AccessGroupIcon = styled.div`
  img {
    width: 40px;
    height: 40px;

    border-radius: 4.8px;
  }
`;

export const AccessGroupContent = styled.div`
  display: flex;
  gap: 16px;

  cursor: pointer;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;

  h5 {
    color: ${({ theme }) => theme.colors.gray6};

    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }

  p {
    color: ${({ theme }) => theme.colors.gray6};

    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
`;
