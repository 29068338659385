import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { AxiosError } from 'axios';
import { addDays, addMonths, format, parseISO, subMonths } from 'date-fns';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsStock from 'highcharts/modules/stock';
import { Action } from 'history';
import ms from 'ms';
import { ChartBar, ChartLine, DownloadSimple, Heart } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import {
  useLocation,
  useNavigate,
  useNavigationType,
  useParams,
} from 'react-router-dom';
import { ChartTypeButton } from 'src/components/ChartTypeButton';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Head } from 'src/components/Head';
import { BackToHome } from 'src/feature-store/components/BackToHome';
import { FeatureStoreSidebarContext } from 'src/feature-store/Contexts/NavigationContext';
import { clearSerieInfo } from 'src/feature-store/redux/reducers/SerieToView';
import api from 'src/feature-store/service/api';
import NotFound from 'src/pages/404';
import { RootState } from 'src/redux/store';
import light from 'src/styles/themes/light';
import { getChartColor } from 'src/utils/colors/getChartColor';
import { formatCompactNotation } from 'src/utils/numbers/formatCompactNotation';

import { ReactComponent as ChartArea } from '../../../assets/chart_area.svg';
import { Select } from '../../../components/Select';
import { BlurChart } from './components/BlurChart';
import { ExportSeries } from './components/ExportSeries';
import FilterIndicatorSeries from './components/FilterIndicatorSeries';
import { IndicatorInfo } from './components/IndicatorInfo';
import {
  ActionButton,
  ActionButtonContainer,
  ChartContainer,
  ChartContent,
  Container,
  ContainerErrorChart,
  GridContainer,
  Header,
  HeaderChart,
  SelectContainer,
  TypeChartContainer,
} from './styles';
import {
  Aggregation,
  ChartDataType,
  DataGraph,
  DataIndicatorInfo,
  DataIndicatorsSeries,
  DataIndicatorType,
  DefaultSerie,
  ParamsType,
  PrimaryTransformation,
  Region,
  SecondaryTransformation,
  SelectOptions,
  Serie,
  SerieInfo,
  Series,
  ValueByLanguage,
} from './types';

export type ErrorObject = {
  title?: string;
  description?: string;
  cloudIcon?: boolean;
};

type ErrorObservationProjectionProps = {
  response: {
    status: number;
  };
};

HighchartsStock(Highcharts);

export const VisualizeFeatures: React.FC = () => {
  const [type, setType] = useState<'line' | 'column' | 'area'>('line');
  const [dataIndicatorsSeries, setDataIndicatorsSeries] = useState<Serie[]>();

  const [codeSerieOnChart, setCodeSerieOnChart] = useState<string>('');
  const [serieOnChart, setSerieOnChart] = useState<Serie[]>([]);

  const [regionOptions, setRegionOptions] = useState<SelectOptions[]>();
  const [aggregationOptions, setAggregationOptions] =
    useState<SelectOptions[]>();
  const [transformationPrimaryOptions, setTransformationPrimaryOptions] =
    useState<SelectOptions[]>();
  const [transformationSecondaryOptions, setTransformationSecondaryOptions] =
    useState<SelectOptions[]>();

  const [allRegionOptions, setAllRegionOptions] = useState<SelectOptions[]>();
  const [allAggregationOptions, setAllAggregationOptions] =
    useState<SelectOptions[]>();
  const [allTransformationPrimaryOptions, setAllTransformationPrimaryOptions] =
    useState<SelectOptions[]>();
  const [
    allTransformationSecondaryOptions,
    setAllTransformationSecondaryOptions,
  ] = useState<SelectOptions[]>();

  const [serieActive, setSerieActive] = useState<boolean>(false);
  const [serieSelectedByParams, setSerieSelectedByParams] = useState(false);

  const [selectedRegion, setSelectedRegion] = useState<SelectOptions>();
  const [selectedAggregation, setSelectedAggregation] =
    useState<SelectOptions>();
  const [selectedPrimaryTransformation, setSelectedPrimaryTransformation] =
    useState<SelectOptions>();
  const [selectedSecondaryTransformation, setSelectedSecondaryTransformation] =
    useState<SelectOptions>();

  const [chartData, setChartData] = useState<ChartDataType[]>([]);

  const [modalFilters, setModalFilters] = useState<boolean>(false);
  const [modalExportSeries, setModalExportSeries] = useState<boolean>(false);

  const { serie } = useSelector((state: RootState) => state.serieToView);
  const [startDateFilter, setStartDateFilter] = useState<number>();
  const [endDateFilter, setEndDateFilter] = useState<number>();

  const [indicatorActive, setIndicatorActive] = useState<boolean>(true);

  const chartRef = useRef<any>(null);
  const { t: translate } = useTranslation();

  const navigate = useNavigate();
  const navType = useNavigationType();
  const location = useLocation();
  const dispatch = useDispatch();
  const { language } = useSelector((state: RootState) => state.auth.user);
  const { id: idSerieToView } = useSelector(
    (state: RootState) => state.serieToView,
  );

  const { openIndicatorFilters, openPremiumFilters } = useContext(
    FeatureStoreSidebarContext,
  );

  const { id } = useParams<ParamsType>();

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );

  const hasParams =
    !!searchParams.get('region') &&
    !!searchParams.get('aggregation') &&
    !!searchParams.get('primary') &&
    !!searchParams.get('second');

  const {
    data: indicator,
    isError: isErrorIndicatorInfo,
    isLoading: isLoadingIndicatorInfo,
    isFetching: isFetchingIndicatorInfo,
  } = useQuery<DataIndicatorInfo, AxiosError>(
    ['data indicators info', id],
    async () => {
      const { data } = await api.get(`/indicators/${id}`);

      return data;
    },
    {
      staleTime: ms('20m'),
      onSuccess: (data) =>
        data.is_active === false && setIndicatorActive(false),
      onError: ({ response }) =>
        response?.status === 404 && setIndicatorActive(false),
    },
  );

  const {
    data: defaultSerieIndicator,
    isLoading: isLoadingDefaultSerie,
    isFetching: isFetchingDefaultSerie,
  } = useQuery(
    ['default serie of indicator', id],
    async () => {
      const { data } = await api.get<DefaultSerie>(
        `/indicators/${id}/series/default`,
      );

      data?.code && setCodeSerieOnChart(data.code);

      return data;
    },
    {
      staleTime: ms('20m'),
      enabled: !!indicator && !hasParams,
    },
  );

  const {
    data: observations,
    isLoading: isLoadingObservations,
    isFetching: isFetchingObservations,
    isError: isErrorObservation,
    error: errorObservation,
  } = useQuery<DataGraph[][], ErrorObservationProjectionProps>(
    ['observations', codeSerieOnChart, id],
    async () => {
      const allDataObservation: DataGraph[] = [];
      const allDataProjections: DataGraph[] = [];
      const serieCode = codeSerieOnChart || defaultSerieIndicator?.code;

      const { data: observationData } = await api.get<DataIndicatorType>(
        `/indicators/${id}/series/${serieCode}/observations?limit=1000`,
      );

      allDataObservation.push(...observationData.data);

      const sizeIndex = Math.ceil(observationData.total / 1000);
      if (sizeIndex) {
        const arrayLoop = Array.from(
          Array(sizeIndex),
          (_, index) => (index + 1) * 1000,
        );

        const responses = await Promise.all(
          arrayLoop.map((skip) =>
            api.get<DataIndicatorType>(
              `/indicators/${id}/series/${serieCode}/observations?limit=1000&skip=${skip}`,
            ),
          ),
        );

        const responseData = responses.flatMap(
          (response) => response.data.data,
        );

        allDataObservation.push(...responseData);
      }

      const startDateProjection = format(
        addDays(
          parseISO(
            allDataObservation[allDataObservation.length - 1].date.slice(0, 10),
          ),
          1,
        ),
        'yyyy-MM-dd',
      );

      if (startDateProjection) {
        const yearOfLastHistoricalDate = Number(
          allDataObservation[allDataObservation.length - 1].date.slice(0, 4),
        );

        const { data: projectionData } = await api.get<DataIndicatorType>(
          `/indicators/${id}/series/${serieCode}/projections?limit=1000&start=${startDateProjection}&end=${
            yearOfLastHistoricalDate + 4
          }-12-31`,
        );

        allDataProjections.push(...projectionData.data);

        const sizeIndexProjections = Math.ceil(projectionData.total / 1000);

        if (sizeIndexProjections) {
          const arrayLoop = Array.from(
            Array(sizeIndexProjections),
            (_, index) => (index + 1) * 1000,
          );

          const responses = await Promise.all(
            arrayLoop.map((skip) =>
              api.get<DataIndicatorType>(
                `/indicators/${id}/series/${serieCode}/projections?limit=1000&skip=${skip}&start=${startDateProjection}&end=${
                  yearOfLastHistoricalDate + 4
                }-12-31`,
              ),
            ),
          );

          const responseData = responses.flatMap(
            (response) => response.data.data,
          );

          allDataProjections.push(...responseData);
        }
      }

      return [allDataObservation, allDataProjections];
    },
    {
      staleTime: ms('20m'),
      enabled: !!defaultSerieIndicator || hasParams,
    },
  );

  const {
    data: series,
    isLoading: isLoadingSeries,
    isError: isErrorSeries,
  } = useQuery(
    ['series of indicator', id],
    async () => {
      const allDataIndicators: Serie[] = [];
      const LIMIT = 1000;

      const { data: indicatorsData } = await api.get<Series>(
        `/indicators/${id}/series?skip=0&limit=${LIMIT}`,
      );

      allDataIndicators.push(...indicatorsData.data);

      const totalItens = indicatorsData.total;
      const sizeIndex = Math.ceil(totalItens / LIMIT);

      if (sizeIndex > 1) {
        const arrayLoop = Array.from(
          Array(sizeIndex),
          (_, index) => (index + 1) * LIMIT,
        );

        const responses = await Promise.all(
          arrayLoop.map((skip) =>
            api.get<DataIndicatorsSeries>(
              `/indicators/${id}/series?skip=${skip}&limit=${LIMIT}`,
            ),
          ),
        );

        const responseData = responses.flatMap(
          (response) => response.data.data,
        );

        allDataIndicators.push(...responseData);

        return allDataIndicators;
      }

      return allDataIndicators;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled: (!!defaultSerieIndicator && !!observations) || hasParams,
    },
  );

  const searchCombination = useCallback(
    (
      region: string,
      aggregation: string,
      primary: string,
      second: string,
    ): Serie | null => {
      if (series) {
        const exists = series.find(
          (_serie) =>
            _serie.region['en-us'] === region &&
            _serie.aggregation['en-us'] === aggregation &&
            _serie.primary_transformation['en-us'] === primary &&
            _serie.second_transformation['en-us'] === second,
        );

        if (exists) {
          return exists;
        }
      }
      return null;
    },
    [series],
  );

  useEffect(() => {
    if (!hasParams) {
      return;
    }

    const region = String(searchParams.get('region'));
    const aggregation = String(searchParams.get('aggregation'));
    const primary = String(searchParams.get('primary'));
    const second = String(searchParams.get('second'));

    const exists = !!searchCombination(region, aggregation, primary, second);

    if (exists) {
      setSerieSelectedByParams(true);
    }
  }, [hasParams, searchCombination, searchParams]);

  useEffect(() => {
    if (
      (defaultSerieIndicator || series) &&
      serieActive === false &&
      selectedRegion &&
      selectedAggregation &&
      selectedPrimaryTransformation &&
      selectedSecondaryTransformation &&
      !idSerieToView
    ) {
      if (defaultSerieIndicator) {
        setSelectedRegion({
          label: defaultSerieIndicator.region,
          value: defaultSerieIndicator.region['en-us'],
        });
        setSelectedAggregation({
          label: defaultSerieIndicator.aggregation,
          value: defaultSerieIndicator.aggregation['en-us'],
        });
        setSelectedPrimaryTransformation({
          label: defaultSerieIndicator.primary_transformation,
          value: defaultSerieIndicator.primary_transformation['en-us'],
        });
        setSelectedSecondaryTransformation({
          label: defaultSerieIndicator.second_transformation,
          value: defaultSerieIndicator.second_transformation['en-us'],
        });
      }

      if (series) {
        const isActive = series.find((active) => active.status === 'active');

        if (isActive) {
          setSelectedRegion({
            label: isActive.region,
            value: isActive.region['en-us'],
          });
          setSelectedAggregation({
            label: isActive.aggregation,
            value: isActive.aggregation['en-us'],
          });
          setSelectedPrimaryTransformation({
            label: isActive.primary_transformation,
            value: isActive.primary_transformation['en-us'],
          });
          setSelectedSecondaryTransformation({
            label: isActive.second_transformation,
            value: isActive.second_transformation['en-us'],
          });
          setSerieActive(true);
        }
      }
    }
  }, [
    series,
    idSerieToView,
    searchParams,
    selectedAggregation,
    selectedPrimaryTransformation,
    selectedRegion,
    selectedSecondaryTransformation,
    serieActive,
    serieSelectedByParams,
    defaultSerieIndicator,
  ]);

  useEffect(
    () => () => {
      if (navType === Action.Pop) {
        if (serie) {
          dispatch(clearSerieInfo());
        }
        if (location.pathname === '/feature-store/indicators') {
          navigate(location.pathname, {
            state: {
              isReturningFromPageIndicatorId: true,
            },
            replace: true,
          });
        }
      }
    },
    [navigate, location, serie, dispatch, navType],
  );

  useEffect(() => {
    if (series) {
      setDataIndicatorsSeries(series);
    }
  }, [series]);

  useEffect(() => {
    if (series) {
      const allRegion: Region[] = [];

      series.forEach((key) => {
        if (
          !allRegion.some(
            (region) => region.name['en-us'] === key.region['en-us'],
          )
        ) {
          allRegion.push({
            access_type: key.access_type,
            name: {
              'en-us': key.region['en-us'],
              'pt-br': key.region['pt-br'],
            },
          });
        }
      });

      const allRegionOptionsAux = Array.from(allRegion).map((key) => ({
        label: key.name,
        value: key.name['en-us'],
        access_type: key.access_type,
      }));

      setAllRegionOptions(allRegionOptionsAux);

      const allAggregation: Aggregation[] = [];

      series.forEach((key) => {
        if (
          !allAggregation.some(
            (aggregation) =>
              aggregation.name['en-us'] === key.aggregation['en-us'],
          )
        ) {
          allAggregation.push({
            access_type: key.access_type,
            name: {
              'en-us': key.aggregation['en-us'],
              'pt-br': key.aggregation['pt-br'],
            },
          });
        }
      });

      const allAggregationOptionsAux = Array.from(allAggregation).map(
        (key) => ({
          label: key.name,
          value: key.name['en-us'],
          access_type: key.access_type,
        }),
      );

      setAllAggregationOptions(allAggregationOptionsAux);

      const allPrimaryTransformation: PrimaryTransformation[] = [];

      series.forEach((key) => {
        if (
          !allPrimaryTransformation.some(
            (primaryTransformation) =>
              primaryTransformation.name['en-us'] ===
              key.primary_transformation['en-us'],
          )
        ) {
          allPrimaryTransformation.push({
            access_type: key.access_type,
            name: {
              'en-us': key.primary_transformation['en-us'],
              'pt-br': key.primary_transformation['pt-br'],
            },
          });
        }
      });

      const allPrimaryTransformationOptionsAux = Array.from(
        allPrimaryTransformation,
      ).map((key) => ({
        label: key.name,
        value: key.name['en-us'],
        access_type: key.access_type,
      }));

      setAllTransformationPrimaryOptions(allPrimaryTransformationOptionsAux);

      const allSecondaryTransformation: SecondaryTransformation[] = [];

      series.forEach((key) => {
        if (
          !allSecondaryTransformation.some(
            (secondaryTransformation) =>
              secondaryTransformation.name['en-us'] ===
              key.second_transformation['en-us'],
          )
        ) {
          allSecondaryTransformation.push({
            access_type: key.access_type,
            name: {
              'en-us': key.second_transformation['en-us'],
              'pt-br': key.second_transformation['pt-br'],
            },
          });
        }
      });

      const allSecondaryTransformationOptionsAux = Array.from(
        allSecondaryTransformation,
      ).map((key) => ({
        label: key.name,
        value: key.name['en-us'],
        access_type: key.access_type,
      }));

      setAllTransformationSecondaryOptions(
        allSecondaryTransformationOptionsAux,
      );
    }
  }, [series]);

  useEffect(() => {
    if (series) {
      const regions: ValueByLanguage[] = [];

      series.forEach((key) => {
        if (
          !regions.some((region) => region['en-us'] === key.region['en-us'])
        ) {
          regions.push(key.region);
        }
      });

      const regionOptionsAux = Array.from(regions).map((key) => ({
        label: key,
        value: key['en-us'],
      }));

      setRegionOptions(regionOptionsAux);
    }
  }, [series]);

  useEffect(() => {
    if (series && selectedRegion) {
      const getAggregationOptions: ValueByLanguage[] = [];

      series
        ?.filter((key) => key.region['en-us'] === selectedRegion.value)
        .forEach((key) => {
          if (
            !getAggregationOptions.some(
              (aggregationOption) =>
                aggregationOption['en-us'] === key.aggregation['en-us'],
            )
          ) {
            getAggregationOptions.push(key.aggregation);
          }
        });

      const aggregationOptionsAux = Array.from(getAggregationOptions).map(
        (key) => ({
          label: key,
          value: key['en-us'],
        }),
      );

      setAggregationOptions(aggregationOptionsAux);

      if (
        serie &&
        aggregationOptionsAux.some(
          (frequency) => frequency.value === serie.frequency,
        )
      ) {
        setSelectedAggregation(
          aggregationOptionsAux.find(
            (aggregationOptAux) => aggregationOptAux.value === serie.frequency,
          ),
        );
      } else if (
        serieSelectedByParams &&
        aggregationOptionsAux.some(
          (frequency) => frequency.value === searchParams.get('aggregation'),
        )
      ) {
        setSelectedAggregation(
          aggregationOptionsAux.find(
            (aggregationOptAux) =>
              aggregationOptAux.value === searchParams.get('aggregation'),
          ),
        );
      } else {
        setSelectedAggregation(aggregationOptionsAux[0]);
      }
    }
  }, [series, searchParams, selectedRegion, serie, serieSelectedByParams]);

  useEffect(() => {
    if (series && selectedRegion && selectedAggregation) {
      const transformationPrimarys: ValueByLanguage[] = [];
      series
        .filter(
          (key) =>
            key.region['en-us'] === selectedRegion.value &&
            key.aggregation['en-us'] === selectedAggregation.value,
        )
        .forEach((key) => {
          if (
            !transformationPrimarys.some(
              (transformationPrimary) =>
                transformationPrimary['en-us'] ===
                key.primary_transformation['en-us'],
            )
          ) {
            transformationPrimarys.push(key.primary_transformation);
          }
        });

      const transformationPrimaryAux = Array.from(transformationPrimarys).map(
        (key) => ({
          label: key,
          value: key['en-us'],
        }),
      );

      setTransformationPrimaryOptions(transformationPrimaryAux);

      if (
        serie &&
        transformationPrimaryAux.some(
          (primaryTransformation) =>
            primaryTransformation.value === serie.primaryTransformation,
        )
      ) {
        setSelectedPrimaryTransformation(
          transformationPrimaryAux.find(
            (transfPrimaryAux) =>
              transfPrimaryAux.value === serie.primaryTransformation,
          ),
        );
      } else if (
        serieSelectedByParams &&
        transformationPrimaryAux.some(
          (primaryTransformation) =>
            primaryTransformation.value === searchParams.get('primary'),
        )
      ) {
        setSelectedPrimaryTransformation(
          transformationPrimaryAux.find(
            (transfPrimaryAux) =>
              transfPrimaryAux.value === searchParams.get('primary'),
          ),
        );
      } else {
        setSelectedPrimaryTransformation(transformationPrimaryAux[0]);
      }
    }
  }, [
    series,
    searchParams,
    selectedAggregation,
    selectedRegion,
    serie,
    serieSelectedByParams,
  ]);

  useEffect(() => {
    if (regionOptions && regionOptions[0]) {
      if (serie) {
        setSelectedRegion(
          regionOptions.find((region) => region.value === serie.region),
        );
      } else if (serieSelectedByParams) {
        setSelectedRegion(
          regionOptions.find(
            (region) => region.value === searchParams.get('region'),
          ),
        );
      } else {
        setSelectedRegion(regionOptions[0]);
      }
    }
  }, [regionOptions, searchParams, serie, serieSelectedByParams]);

  useEffect(() => {
    if (
      series &&
      selectedRegion &&
      selectedAggregation &&
      selectedPrimaryTransformation
    ) {
      const transformationSecondarys: ValueByLanguage[] = [];
      series
        .filter(
          (key) =>
            key.region['en-us'] === selectedRegion.value &&
            key.aggregation['en-us'] === selectedAggregation.value &&
            key.primary_transformation['en-us'] ===
              selectedPrimaryTransformation.value,
        )
        .forEach((key) => {
          if (
            !transformationSecondarys.some(
              (transformationSecondary) =>
                transformationSecondary['en-us'] ===
                key.second_transformation['en-us'],
            )
          ) {
            transformationSecondarys.push(key.second_transformation);
          }
        });

      const transformationSecondaryAux = Array.from(
        transformationSecondarys,
      ).map((key) => ({
        label: key,
        value: key['en-us'],
      }));

      setTransformationSecondaryOptions(transformationSecondaryAux);

      if (
        serie &&
        transformationSecondaryAux.some(
          (secondaryTransformation) =>
            secondaryTransformation.value === serie.secondaryTransformation,
        )
      ) {
        setSelectedSecondaryTransformation(
          transformationSecondaryAux.find(
            (transfSecondaryAux) =>
              transfSecondaryAux.value === serie.secondaryTransformation,
          ),
        );
      } else if (
        serieSelectedByParams &&
        transformationSecondaryAux.some(
          (secondaryTransformation) =>
            secondaryTransformation.value === searchParams.get('second'),
        )
      ) {
        setSelectedSecondaryTransformation(
          transformationSecondaryAux.find(
            (transfSecondaryAux) =>
              transfSecondaryAux.value === searchParams.get('second'),
          ),
        );
      } else {
        setSelectedSecondaryTransformation(transformationSecondaryAux[0]);
      }
    }
  }, [
    series,
    searchParams,
    selectedAggregation,
    selectedPrimaryTransformation,
    selectedRegion,
    serie,
    serieSelectedByParams,
  ]);

  useEffect(() => {
    if (dataIndicatorsSeries) {
      const serieSelected = dataIndicatorsSeries.filter(
        (eachIndicator) =>
          eachIndicator.region['en-us'] === selectedRegion?.value &&
          eachIndicator.aggregation['en-us'] === selectedAggregation?.value &&
          eachIndicator.primary_transformation['en-us'] ===
            selectedPrimaryTransformation?.value &&
          eachIndicator.second_transformation['en-us'] ===
            selectedSecondaryTransformation?.value,
      );

      const selectedSerieCode = serieSelected[0]?.code;
      if (selectedSerieCode) {
        setSerieOnChart(serieSelected as Serie[]);
        setCodeSerieOnChart(selectedSerieCode);
      }
    }
  }, [
    dataIndicatorsSeries,
    selectedRegion,
    selectedAggregation,
    selectedPrimaryTransformation,
    selectedSecondaryTransformation,
  ]);

  useEffect(() => {
    openIndicatorFilters(false);
    openPremiumFilters(false);
  }, [openIndicatorFilters, openPremiumFilters]);

  const { data: serieDataInfo, isError: isErrorSerieDataInfo } = useQuery(
    ['serie data info', codeSerieOnChart],
    async () => {
      const { data } = await api.get<SerieInfo>(`/series/${codeSerieOnChart}`);

      return data;
    },
    {
      staleTime: ms('20 minutes'),
      enabled: codeSerieOnChart?.length >= 1,
    },
  );

  useEffect(() => {
    if (observations) {
      const auxChart: ChartDataType[] = [];

      if (observations[1].length) {
        auxChart.push({
          x: observations[1].map((data) => data.date),
          y: observations[1].map((data) => data.value),
          name: translate('viewFeatureProjection'),
        });
      }

      if (observations[0].length) {
        auxChart.push({
          x: observations[0]?.map((data) => data.date),
          y: observations[0]?.map((data) => data.value),
          name: translate('viewFeatureHistorical'),
        });
      }

      if (observations[0].length && observations[1].length) {
        if (type !== 'column') {
          auxChart[0].x = [
            auxChart[1].x[auxChart[1].x.length - 1],
            ...auxChart[0].x,
          ];

          auxChart[0].y = [
            auxChart[1].y[auxChart[1].y.length - 1],
            ...auxChart[0].y,
          ];
        }
      }

      setChartData(auxChart);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observations, language, type]);

  // eslint-disable-next-line func-names
  const tooltipFormatter = function (this: any) {
    /* eslint-disable react/no-this-in-sfc */
    const formattedDate = isYearlySerie
      ? format(addMonths(new Date(this.x), 11), 'dd/MM/yyyy')
      : format(new Date(this.x), 'dd/MM/yyyy');

    return `
    <p><b>${translate('date')}:</b> ${formattedDate}</p>  
    <p><b>${this.points[0].series.name}:</b> ${formatCompactNotation(
      this.points[0].y,
    )}</p>
    `;
  };

  const isYearlySerie = selectedAggregation?.label['en-us']
    .toLowerCase()
    .includes('yearly');

  useEffect(() => {
    const endDate =
      serieDataInfo?.data?.date_end ?? defaultSerieIndicator?.date_end;

    if (!endDate) return;

    const startDate = new Date(endDate);
    const endDateNew = new Date(endDate);

    startDate.setFullYear(startDate.getFullYear() - 3);
    endDateNew.setFullYear(endDateNew.getFullYear() + 2);

    setStartDateFilter(startDate.getTime());
    setEndDateFilter(endDateNew.getTime());

    if (chartRef.current?.chart) {
      loadChart(chartRef.current.chart);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    serieDataInfo?.data?.date_end,
    defaultSerieIndicator?.date_end,
    chartRef,
  ]);

  const loadChart = (chart: Highcharts.Chart) => {
    if (startDateFilter !== null && endDateFilter !== null) {
      chart.xAxis[0].setExtremes(startDateFilter, endDateFilter);
    }
  };

  function zoomChart(
    event: Highcharts.AxisSetExtremesEventObject,
    chart: Highcharts.Axis,
  ) {
    //@ts-expect-error: nao reconhe a propriedade chart
    if (event.target.chart?.yAxis?.length) {
      //@ts-expect-error: nao reconhe a propriedade chart
      const min = event.target.chart?.yAxis[0]?.dataMin;
      //@ts-expect-error: nao reconhe a propriedade chart
      const max = event.target.chart?.yAxis[0]?.dataMax;

      if (typeof min === 'number' && typeof max === 'number') {
        chart.chart.yAxis[0].setExtremes(min > 0 ? min - 0.0001 : min, max);
      }
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let seriesHC: Highcharts.SeriesOptionsType[] = [];

  if (chartData.length === 1 && chartData[0]?.x && chartData[0]?.y) {
    if (
      chartData[0].name === 'Historical' ||
      chartData[0].name === 'Histórico'
    ) {
      seriesHC.push({
        name: translate('viewFeatureHistorical'),
        data: chartData[0].x.map((date, index) => {
          const previousValueIsNullOrUndefined =
            chartData[0].y[index - 1] === null ||
            chartData[0].y[index - 1] === undefined;

          const nextValueIsNullOrUndefined =
            chartData[0].y[index + 1] === null ||
            chartData[0].y[index + 1] === undefined;

          const hasMarker =
            previousValueIsNullOrUndefined && nextValueIsNullOrUndefined;

          return {
            x: isYearlySerie
              ? new Date(subMonths(new Date(date), 11)).getTime()
              : new Date(date).getTime(),
            y: chartData[0].y[index],
            marker: hasMarker
              ? {
                  enabled: true,
                  radius: 2,
                }
              : undefined,
          };
        }),
        color: getChartColor(0),
        type,
        dataGrouping: {
          enabled: false, // Desativa o agrupamento automático
        },
      });
    }
  }

  if (chartData.length === 2) {
    seriesHC.push({
      name: translate('viewFeatureHistorical'),
      data: chartData[1].x.map((date, index) => {
        const previousValueIsNullOrUndefined =
          chartData[1].y[index - 1] === null ||
          chartData[1].y[index - 1] === undefined;

        const nextValueIsNullOrUndefined =
          chartData[1].y[index + 1] === null ||
          chartData[1].y[index + 1] === undefined;

        const hasMarker =
          previousValueIsNullOrUndefined && nextValueIsNullOrUndefined;

        return {
          x: isYearlySerie
            ? new Date(subMonths(new Date(date), 11)).getTime()
            : new Date(date).getTime(),
          y: chartData[1].y[index],
          marker: hasMarker
            ? {
                enabled: true,
                radius: 2,
              }
            : undefined,
        };
      }),
      color: getChartColor(0),
      type,
      dataGrouping: {
        enabled: false, // Desativa o agrupamento automático
      },
    });

    seriesHC.push({
      name: translate('viewFeaturesProjection'),
      data: chartData[0].x.map((date, index) => {
        const previousValueIsNullOrUndefined =
          chartData[0].y[index - 1] === null ||
          chartData[0].y[index - 1] === undefined;

        const nextValueIsNullOrUndefined =
          chartData[0].y[index + 1] === null ||
          chartData[0].y[index + 1] === undefined;

        const hasMarker =
          previousValueIsNullOrUndefined && nextValueIsNullOrUndefined;

        return {
          x: isYearlySerie
            ? new Date(subMonths(new Date(date), 11)).getTime()
            : new Date(date).getTime(),
          y: chartData[0].y[index],
          marker: hasMarker
            ? {
                enabled: true,
                radius: 2,
              }
            : undefined,
        };
      }),
      color: getChartColor(1),
      type,
      dataGrouping: {
        enabled: false, // Desativa o agrupamento automático
      },
    });
  }

  if (type === 'area') {
    //@ts-expect-error: o typescript do hightcharts nao reconhce
    seriesHC = seriesHC.map((serieAux, index) => ({
      ...serieAux,
      fillColor: {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
        },
        stops: [
          [0, `${getChartColor(index)}EE`],
          [1, `${getChartColor(index)}25`],
        ],
      },
    }));
  }

  useEffect(() => {
    const hasProjection = chartData.length === 2;

    if (chartRef.current && hasProjection && ['line', 'area'].includes(type)) {
      const historicalIsVisible = chartRef.current.chart.series[0].visible;

      if (!historicalIsVisible) {
        chartRef.current.chart.series[1].removePoint(0, true);
      }
    }
  }, [type, chartData, seriesHC]);

  useEffect(() => {
    const chart = chartRef?.current?.chart;

    if (chart) {
      const min = chart?.yAxis[0]?.dataMin;
      const max = chart?.yAxis[0]?.dataMax;

      if (min !== undefined && max !== undefined) {
        chart.yAxis[0].setExtremes(min, max);
      }
    }
  }, [
    selectedRegion,
    selectedAggregation,
    selectedPrimaryTransformation,
    selectedSecondaryTransformation,
    chartData,
  ]);

  const options: Highcharts.Options = {
    time: {
      useUTC: true,
      timezoneOffset: -1 * -180, //UTC-3:00 time zone
    },
    lang: {
      numericSymbols: ['k', 'M', 'B', 'T', 'P', 'E'],
    },
    chart: {
      events: {
        load() {
          loadChart(this);
          this.redraw(false);
        },
      },
      spacingTop: 32,
      spacingLeft: 1,
      type,
      zooming: {
        type: 'xy',
        resetButton: {
          theme: {
            stroke: '#f1f7ff',
            'stroke-width': 1,
            padding: 8,
            fill: '#f1f7ff',
            style: {
              color: light.colors.primary,
              fontStyle: 'normal',
              fontSize: '0.75rem',
              fontWeight: '500',
              fontFamily: "'Inter', sans-serif",
            },
            states: {
              hover: {
                fill: '#edf4ff',
                stroke: '#edf4ff',
                style: {
                  color: light.colors.primaryDark,
                  fontWeight: '500',
                },
              },
            },
          },
        },
      },
    },
    credits: {
      enabled: false,
    },
    yAxis: {
      lineWidth: 0,
      gridLineWidth: 1,
      gridLineColor: light.colors.gray2,
      showLastLabel: true,
      labels: {
        style: {
          fontSize: '0.75rem',
          fontFamily: "'Inter', sans-serif",
        },
      },
      opposite: false,
    },
    xAxis: {
      events: {
        afterSetExtremes(event) {
          zoomChart(event, this);
        },
      },
      type: 'datetime',
      minTickInterval:
        selectedAggregation?.label['en-us'].toLowerCase().includes('daily') ||
        selectedAggregation?.label['en-us'].toLowerCase().includes('weekly')
          ? 24 * 3600 * 1000
          : selectedAggregation?.label['en-us'].toLowerCase().includes('yearly')
          ? 365 * 24 * 3600 * 1000
          : 30 * 24 * 3600 * 1000,
      labels: {
        style: {
          fontSize: '0.75rem',
          fontFamily: "'Inter', sans-serif",
          textAlign: 'center',
        },
        y: 26,
      },
      dateTimeLabelFormats: {
        day: '%b %d <br/> %Y',
        week: '%b %d <br/> %Y',
        month: '%b %Y',
        year: '%Y',
      },
    },
    title: {
      text: '',
    },
    tooltip: {
      valueDecimals: 2,
      formatter: tooltipFormatter,
      style: {
        fontStyle: 'normal',
        fontSize: '0.875rem',
        fontWeight: '400',
        color: light.colors.gray7,
        fontFamily: "'Inter', sans-serif",
      },
      borderRadius: 8,
      useHTML: true,
    },
    legend: {
      enabled: true,
      align: 'center',
      verticalAlign: 'bottom',
      layout: 'horizontal',
      itemStyle: {
        marginTop: '0.625rem',
        fontStyle: 'normal',
        fontSize: '0.875rem',
        fontWeight: '400',
        fontFamily: "'Inter', sans-serif",
      },
    },
    rangeSelector: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    navigator: {
      xAxis: {
        gridLineWidth: 0,
      },
      series: {
        type: 'line',
      },
    },
    series: seriesHC,
    plotOptions: {
      line: {
        lineWidth: 3,
      },
      area: {
        lineWidth: 3,
      },
      column: {
        pointPadding: 0.1,
        grouping: false,
      },
      series: {
        turboThreshold: 0,
        showInNavigator: true,
        events: {
          legendItemClick() {
            const legendClicked = this.name;
            const isShowing = this.visible;
            const data = chartData[0];

            if (
              legendClicked === translate('viewFeatureHistorical') &&
              data.name === translate('viewFeatureProjection') &&
              ['line', 'area'].includes(type)
            ) {
              if (isShowing) {
                this.chart.series[1].removePoint(0, false);
              } else {
                const lastHistoricalDate = chartData[1].x.at(-1);
                const lastHistoricalValue = chartData[1].y.at(-1);

                if (lastHistoricalDate && lastHistoricalValue) {
                  this.chart.series[1].addPoint(
                    {
                      x: new Date(lastHistoricalDate).getTime(),
                      y: lastHistoricalValue,
                    },
                    false,
                  );
                }
              }
            }
          },
        },
      },
    },
  };

  const ptBrLang: Highcharts.LangOptions = {
    numericSymbols: ['k', 'M', 'B', 'T', 'P', 'E'],
    shortMonths: [
      'Jan',
      'Fev',
      'Mar',
      'Abr',
      'Mai',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez',
    ],
    decimalPoint: '.',
    thousandsSep: ',',
  };

  const enUsLang: Highcharts.LangOptions = {
    numericSymbols: ['k', 'M', 'B', 'T', 'P', 'E'],
    shortMonths: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    decimalPoint: '.',
    thousandsSep: ',',
  };

  const labelName = () => {
    const pattern = /BRANB/;
    const indicatorCode = id ?? '';
    const isAnbima = pattern.test(indicatorCode);
    return isAnbima
      ? translate('viewFeaturesVariable')
      : translate('viewFeaturesRegion');
  };

  const aggregationsIsReady = !!(
    selectedRegion &&
    regionOptions &&
    selectedAggregation &&
    aggregationOptions &&
    selectedPrimaryTransformation &&
    transformationPrimaryOptions &&
    selectedSecondaryTransformation &&
    language &&
    transformationSecondaryOptions
  );

  Highcharts.setOptions({ lang: language === 'pt-br' ? ptBrLang : enUsLang });

  if (!indicatorActive) {
    return <NotFound />;
  }

  return (
    <Container>
      <Head title={translate('viewFeaturesTitle')} />

      {isErrorSeries && isErrorIndicatorInfo && (
        <>
          <Header>
            <BackToHome />
          </Header>

          <ContainerErrorChart>
            <ContainerMaintenance
              content="data"
              text={translate('viewFeaturesUnableSerie')}
            />
          </ContainerErrorChart>
        </>
      )}

      <>
        <Header>
          <BackToHome />

          <h4>
            {indicator ? (
              indicator.name[language] ?? indicator.name['en-us']
            ) : (
              <ContainerSkeleton
                withLoading={false}
                style={{ width: '18.75rem', height: '2.4375rem' }}
              />
            )}
          </h4>

          <ActionButtonContainer>
            <ActionButton
              onClick={() => {
                setModalFilters(true);
              }}
              data-cy="button-send-to-group"
              data-testid="button-send-to-group"
              disabled={
                chartData.length === 0 ||
                isLoadingSeries ||
                !aggregationsIsReady
              }
            >
              <Heart /> {translate('viewFeaturesSaveToGroup')}
            </ActionButton>

            <ActionButton
              onClick={() => {
                setModalExportSeries(true);
              }}
              data-testid="button-export"
              data-cy="button-export"
              disabled={
                isErrorSeries || isLoadingSeries || !aggregationsIsReady
              }
            >
              <DownloadSimple /> {translate('export')}
            </ActionButton>
          </ActionButtonContainer>
        </Header>

        <GridContainer>
          <ChartContainer>
            {defaultSerieIndicator || hasParams ? (
              <SelectContainer>
                <Select
                  label={labelName()}
                  defaultValue={{
                    label: regionOptions
                      ? regionOptions[0].label[language] ??
                        regionOptions[0].label['en-us']
                      : defaultSerieIndicator?.region[language] ?? '',
                    value: regionOptions
                      ? regionOptions[0].label[language] ??
                        regionOptions[0].label['en-us']
                      : defaultSerieIndicator?.region[language] ?? '',
                  }}
                  options={
                    regionOptions
                      ? regionOptions.map((regionOption) => ({
                          label:
                            regionOption.label[language] ??
                            regionOptions[0].label['en-us'],
                          value: regionOption.value,
                        }))
                      : [
                          {
                            label:
                              defaultSerieIndicator?.region[language] ?? '',
                            value:
                              defaultSerieIndicator?.region[language] ?? '',
                          },
                        ]
                  }
                  onChange={(option: any) => {
                    if (
                      selectedRegion &&
                      regionOptions &&
                      option.value !== selectedRegion.value
                    ) {
                      setSelectedRegion(
                        regionOptions.find(
                          (regionOption) => regionOption.value === option.value,
                        ),
                      );
                      setSelectedAggregation(undefined);
                      setSelectedPrimaryTransformation(undefined);
                      setSelectedSecondaryTransformation(undefined);
                    }
                  }}
                  value={
                    selectedRegion
                      ? {
                          label:
                            selectedRegion.label[language] ??
                            selectedRegion.label['en-us'] ??
                            '',
                          value: selectedRegion.value ?? '',
                        }
                      : {
                          label: defaultSerieIndicator?.region[language] ?? '',
                          value: defaultSerieIndicator?.region[language] ?? '',
                        }
                  }
                  information={
                    !aggregationsIsReady
                      ? translate('viewFeaturesLoadingSeries')
                      : undefined
                  }
                  style={{ width: '15.625rem' }}
                  isLoading={
                    !aggregationsIsReady ||
                    isLoadingObservations ||
                    isFetchingObservations
                  }
                  isDisabled={
                    !aggregationsIsReady ||
                    isLoadingObservations ||
                    isFetchingObservations
                  }
                />

                <Select
                  label={translate('viewFeaturesFrequency')}
                  defaultValue={{
                    label: aggregationOptions
                      ? aggregationOptions[0].label[language]
                      : '',

                    value: aggregationOptions
                      ? aggregationOptions[0].value
                      : '',
                  }}
                  options={
                    aggregationOptions
                      ? aggregationOptions.map((aggregationOption) => ({
                          label:
                            aggregationOption.label[language] ??
                            aggregationOption.label['en-us'],
                          value: aggregationOption.value,
                        }))
                      : []
                  }
                  onChange={(option: any) => {
                    if (
                      selectedAggregation &&
                      aggregationOptions &&
                      option.value !== selectedAggregation.value
                    ) {
                      setSelectedAggregation(
                        aggregationOptions.find(
                          (aggregationOption) =>
                            aggregationOption.value === option.value,
                        ),
                      );
                      setSelectedPrimaryTransformation(undefined);
                      setSelectedSecondaryTransformation(undefined);
                    }
                  }}
                  value={{
                    label: selectedAggregation
                      ? selectedAggregation.label[language] ??
                        selectedAggregation.label['en-us']
                      : defaultSerieIndicator?.aggregation[language] ?? '',
                    value: selectedAggregation ? selectedAggregation.value : '',
                  }}
                  information={
                    !aggregationsIsReady
                      ? translate('viewFeaturesLoadingSeries')
                      : undefined
                  }
                  style={{ width: '15.625rem' }}
                  isLoading={
                    !aggregationsIsReady ||
                    isLoadingObservations ||
                    isFetchingObservations
                  }
                  isDisabled={
                    !aggregationsIsReady ||
                    isLoadingObservations ||
                    isFetchingObservations
                  }
                />

                <Select
                  label={translate('viewFeaturesPrimaryTr')}
                  defaultValue={{
                    label: transformationPrimaryOptions
                      ? transformationPrimaryOptions[0].label[language]
                      : '',
                    value: transformationPrimaryOptions
                      ? transformationPrimaryOptions[0].value
                      : '',
                  }}
                  options={
                    transformationPrimaryOptions
                      ? transformationPrimaryOptions.map(
                          (transformationPrimaryOption) => ({
                            label:
                              transformationPrimaryOption.label[language] ??
                              transformationPrimaryOption.label['en-us'],
                            value: transformationPrimaryOption.value,
                          }),
                        )
                      : []
                  }
                  onChange={(option: any) => {
                    if (
                      selectedPrimaryTransformation &&
                      transformationPrimaryOptions &&
                      option.value !== selectedPrimaryTransformation.value
                    ) {
                      setSelectedPrimaryTransformation(
                        transformationPrimaryOptions.find(
                          (transformationPrimaryOption) =>
                            transformationPrimaryOption.value === option.value,
                        ),
                      );
                      setSelectedSecondaryTransformation(undefined);
                    }
                  }}
                  value={{
                    label: selectedPrimaryTransformation
                      ? selectedPrimaryTransformation.label[language] ??
                        selectedPrimaryTransformation.label['en-us']
                      : defaultSerieIndicator?.primary_transformation[
                          language
                        ] ?? '',
                    value: selectedPrimaryTransformation
                      ? selectedPrimaryTransformation.value
                      : '',
                  }}
                  information={
                    !aggregationsIsReady
                      ? translate('viewFeaturesLoadingSeries')
                      : undefined
                  }
                  style={{ width: '15.625rem' }}
                  isLoading={
                    !aggregationsIsReady ||
                    isLoadingObservations ||
                    isFetchingObservations
                  }
                  isDisabled={
                    !aggregationsIsReady ||
                    isLoadingObservations ||
                    isFetchingObservations
                  }
                />

                <Select
                  label={translate('viewFeaturesSecondaryTr')}
                  defaultValue={{
                    label: transformationSecondaryOptions
                      ? transformationSecondaryOptions[0].label[language]
                      : '',
                    value: transformationSecondaryOptions
                      ? transformationSecondaryOptions[0].value
                      : '',
                  }}
                  options={
                    transformationSecondaryOptions
                      ? transformationSecondaryOptions.map(
                          (transformationSecondaryOption) => ({
                            label:
                              transformationSecondaryOption.label[language] ??
                              transformationSecondaryOption.label['en-us'],
                            value: transformationSecondaryOption.value,
                          }),
                        )
                      : []
                  }
                  onChange={(option: any) => {
                    if (
                      selectedSecondaryTransformation &&
                      transformationSecondaryOptions &&
                      option.value !== selectedSecondaryTransformation.value
                    ) {
                      setSelectedSecondaryTransformation(
                        transformationSecondaryOptions.find(
                          (transformationSecondaryOption) =>
                            transformationSecondaryOption.value ===
                            option.value,
                        ),
                      );
                    }
                  }}
                  value={{
                    label: selectedSecondaryTransformation
                      ? selectedSecondaryTransformation.label[language] ??
                        selectedSecondaryTransformation.label['en-us']
                      : defaultSerieIndicator?.second_transformation[
                          language
                        ] ?? '',
                    value: selectedSecondaryTransformation
                      ? selectedSecondaryTransformation.value
                      : '',
                  }}
                  information={
                    !aggregationsIsReady
                      ? translate('viewFeaturesLoadingSeries')
                      : undefined
                  }
                  style={{ width: '15.625rem' }}
                  isLoading={
                    !aggregationsIsReady ||
                    isLoadingObservations ||
                    isFetchingObservations
                  }
                  isDisabled={
                    !aggregationsIsReady ||
                    isLoadingObservations ||
                    isFetchingObservations
                  }
                />
              </SelectContainer>
            ) : (
              <SelectContainer>
                {[...Array(4)].map((_, index) => (
                  <ContainerSkeleton
                    key={`${index + 1}`}
                    withLoading={false}
                    style={{ width: '250px', height: '76px' }}
                  />
                ))}
              </SelectContainer>
            )}

            {isLoadingObservations ||
            isFetchingObservations ||
            isLoadingDefaultSerie ||
            isFetchingDefaultSerie ||
            isLoadingIndicatorInfo ||
            isFetchingIndicatorInfo ? (
              <ContainerSkeleton
                withLoading={false}
                style={{ width: '100%', height: '27.5rem' }}
              />
            ) : errorObservation?.response?.status === 402 ? (
              <ChartContent>
                <BlurChart />
              </ChartContent>
            ) : errorObservation?.response?.status === 503 ? (
              <ContainerErrorChart>
                <ContainerMaintenance
                  content="data"
                  text={translate('viewFeaturesUpdateInfo')}
                />
              </ContainerErrorChart>
            ) : isErrorSeries || isErrorObservation || isErrorSerieDataInfo ? (
              // eslint-disable-next-line react/jsx-indent
              <ContainerErrorChart>
                <ContainerMaintenance
                  content="data"
                  text={translate('viewFeaturesUnableSerie')}
                />
              </ContainerErrorChart>
            ) : (
              chartData &&
              chartData.length > 0 && (
                // eslint-disable-next-line react/jsx-indent
                <>
                  <ChartContent data-cy="serie-chart">
                    <HeaderChart>
                      <h4 data-cy="unit-chart" data-testid="unit-chart">
                        {serieOnChart[0]
                          ? serieOnChart[0]?.unit?.[language] ??
                            serieOnChart[0]?.unit?.['en-us']
                          : defaultSerieIndicator?.unit[language] ??
                            defaultSerieIndicator?.unit['en-us']}
                      </h4>

                      <TypeChartContainer>
                        <ChartTypeButton
                          onClick={() => {
                            setType('line');
                          }}
                          active={type === 'line'}
                          icon={<ChartLine />}
                          data-cy="button-line"
                          data-testid="button-line"
                        />
                        <ChartTypeButton
                          onClick={() => {
                            setType('column');
                          }}
                          active={type === 'column'}
                          icon={<ChartBar />}
                          data-cy="button-column"
                          data-testid="button-column"
                        />
                        <ChartTypeButton
                          onClick={() => {
                            setType('area');
                          }}
                          active={type === 'area'}
                          icon={<ChartArea />}
                          data-cy="button-area"
                          data-testid="button-area"
                        />
                      </TypeChartContainer>
                    </HeaderChart>

                    <HighchartsReact
                      highcharts={Highcharts}
                      options={options}
                      constructorType="stockChart"
                      ref={chartRef}
                    />
                  </ChartContent>
                </>
              )
            )}
          </ChartContainer>

          {indicator && (serieDataInfo || defaultSerieIndicator) ? (
            <IndicatorInfo
              description={
                indicator.description[language] ??
                indicator.description['en-us']
              }
              source={indicator.source[language] ?? indicator.source['en-us']}
              startDateObservation={
                serieDataInfo
                  ? serieDataInfo?.data.date_start
                  : defaultSerieIndicator?.date_start
              }
              endDateObservation={
                serieDataInfo
                  ? serieDataInfo?.data.date_end
                  : defaultSerieIndicator?.date_end
              }
              endDateProjection={
                serieDataInfo
                  ? serieDataInfo?.data.date_end_projection
                  : defaultSerieIndicator?.date_end_projection
              }
              lastUpdateObservation={
                serieDataInfo
                  ? serieDataInfo?.data.last_observation_updated
                  : defaultSerieIndicator?.last_observation_updated
              }
              lastUpdateProjection={
                serieDataInfo
                  ? serieDataInfo?.data.last_projection_updated
                  : defaultSerieIndicator?.last_projection_updated
              }
              buttonCopyIsDisabled={isErrorSeries}
              seriesCodes={codeSerieOnChart}
              projection={indicator?.projections}
              isProjectionByAI={
                serieDataInfo
                  ? serieDataInfo?.data.projection_by_ai
                  : defaultSerieIndicator?.projection_by_ai
              }
              level={serieDataInfo?.data.projection_by_ai_confidence_level}
            />
          ) : (
            <ContainerSkeleton
              withLoading={false}
              style={{ height: '606px' }}
            />
          )}
        </GridContainer>
      </>

      {modalFilters && (
        <FilterIndicatorSeries
          visible={modalFilters}
          setVisible={setModalFilters}
          series={dataIndicatorsSeries}
          regionOption={allRegionOptions}
          aggregationOption={allAggregationOptions}
          transformationPrimaryOption={allTransformationPrimaryOptions}
          transformationSecondaryOption={allTransformationSecondaryOptions}
          defaultOptions={{
            region: selectedRegion?.value,
            frequency: selectedAggregation?.value,
            primary: selectedPrimaryTransformation?.value,
            secondary: selectedSecondaryTransformation?.value,
          }}
        />
      )}

      {modalExportSeries && (
        <ExportSeries
          visible={modalExportSeries}
          setVisible={setModalExportSeries}
          series={dataIndicatorsSeries}
          idIndicator={id ?? ''}
          regionOption={allRegionOptions}
          aggregationOption={allAggregationOptions}
          transformationPrimaryOption={allTransformationPrimaryOptions}
          transformationSecondaryOption={allTransformationSecondaryOptions}
          defaultOptions={{
            region: selectedRegion?.value,
            frequency: selectedAggregation?.value,
            primary: selectedPrimaryTransformation?.value,
            secondary: selectedSecondaryTransformation?.value,
          }}
        />
      )}
    </Container>
  );
};
