const preDefinedSeriesEN = {
  preDefinedSeriesTitle: 'Series',
  preDefinedSeriesDescription:
    'Access each series individually or export the information.',
  preDefinedName: 'Name',
  preDefinedSource: 'Source',
  preDefinedLastUpdate: 'Last Update',
  preDefinedStartDate: 'Start Date',
  preDefinedEndDate: 'End Date',
  preDefinedFrequency: 'Frequency',
  preDefinedRegion: 'Region',
  preDefinedPrimaryTr: 'Primary Tr.',
  preDefinedSecondaryTr: 'Secondary Tr.',
  preDefinedSearchSeries: 'Search series',
  preDefinedSearchIndicators: 'Search Indicators',
  preDefinedDescriptionIndicator:
    'Browse our database, explore thousands of variables and create your own groups of series',
  preDefinedSearch: 'Search',

  preDefinedUnableGroup: 'Unable to load series from this group.',
  preDefinedAddSeries: 'Add your first series.',
  preDefinedAddSeriesDescription:
    'Select an indicator and send new series to this group by clicking on the button below.',

  preDefinedDownloadError: 'There was an error downloading file.',
  preDefinedExcludedError: 'There was an error deleting series.',
  preDefinedAnySeries: 'We couldn’t find any series containing',
  preDefinedSearchMaxCharactersError: 'Search must be at most XX characters.',

  preDefinedDeleteTitle: 'Are you sure?',
  preDefinedDeleteDescription:
    'This will permanently delete this series from your group.',
  groupViewHeaderMacroIndicator: 'Macro indicators',
  groupViewHeaderModelSeries: 'Modeling series',
  groupViewAddNew: 'Add New',
};

export default preDefinedSeriesEN;
