export const AISelectionResultsUnderstandingENTranslate = {
  AISelectionResultsUnderstandingGetInsights: 'Get Insights!',
  AISelectionResultsUnderstandingTitle: 'Understanding the Result',
  AISelectionResultsUnderstandingSeeMoreImpacts: 'See more impacts',
  AISelectionResultsUnderstandingSeeLessImpacts: 'See less impacts',
  AISelectionResultsUnderstandingWhenYFrequencyIsInvalid:
    'Interpretation of results for this aggregation is only available for data with monthly original data.',
  AISelectionResultsUnderstandingCopyText: 'Copy text',
  AISelectionResultsUnderstandingTextCopied: 'Copied!',
  AISelectionResultsUnderstandingWhenFrequencyIsOriginalLow:
    'Interpretation of results is not available for daily, weekly or fortnightly data.',
  AISelectionResultsUnderstandingSeeOtherVariables: 'See other variables',
  AISelectionResultsUnderstandingInitialValue: 'Initial value',
  AISelectionResultsUnderstandingTotalValue: 'Total value',
  AISelectionResultsUnderstandingOthers: 'others',
  AISelectionResultsUnderstandingCards: 'Cards',
  AISelectionResultsUnderstandingWaterfall: 'Waterfall',
};
