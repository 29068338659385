/* eslint-disable react/jsx-indent */
import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';
import {
  generateRandomIcon,
  arrayIcon,
  groupsPremiumIcons,
} from 'src/utils/icons/handleProjectIcon';

import { ContentIcons, ContentModel, Icon, Title } from './styles';
import { ModalFooter } from '../Footer/styles';

type ModalIcons = {
  visible: boolean;
  setVisible: (value: boolean) => void;
  value: string | null;
  onChangHookForm: (value: string | null) => void;
  setIcon: (value: string | null) => void;
  content: {
    title: string;
    description: string;
  };
  isAccessGroupIcons?: boolean;
};

export const ModalIcons: React.FC<ModalIcons> = ({
  visible,
  setVisible,
  value,
  onChangHookForm,
  setIcon,
  content,
  isAccessGroupIcons: isGroupsIcons = false,
}) => {
  const [select, setSelect] = useState<string | null>(null);
  const { t: translate } = useTranslation();

  const handleConfirm = () => {
    if (select) {
      onChangHookForm(select);
      setVisible(false);
    }
  };

  useEffect(() => {
    if (value) {
      setSelect(value);
      setIcon(value);
    } else {
      const iconRandom = generateRandomIcon();
      setSelect(iconRandom);
      onChangHookForm(iconRandom);
      setIcon(iconRandom);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, onChangHookForm]);

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      style={{ width: '26rem' }}
      dataCy="icon-modal"
    >
      <ContentModel>
        <Title>
          <h3>{content.title}</h3>
          <div>
            <p>{content.description}</p>
          </div>
        </Title>
        <ContentIcons>
          {isGroupsIcons
            ? groupsPremiumIcons.map((icon, index) => (
                <Icon
                  data-testid={`icon-${index}`}
                  src={icon}
                  alt={`icon${index.toString()}`}
                  key={`icons-${index.toString()}`}
                  onClick={() => setSelect(icon)}
                  selected={icon === select}
                />
              ))
            : arrayIcon.map((icon, index) => (
                <Icon
                  data-testid={`icon-${index}`}
                  src={icon}
                  alt={`icon${index.toString()}`}
                  key={`icons-${index.toString()}`}
                  onClick={() => setSelect(icon)}
                  selected={icon === select}
                />
              ))}
        </ContentIcons>
      </ContentModel>
      <ModalFooter>
        <Button
          data-testid="buttonCancelIcon"
          buttonType="naked"
          onClick={() => setVisible(false)}
        >
          {translate('cancel')}
        </Button>
        <Button
          buttonType="primary"
          data-testid="buttonConfirmIcon"
          onClick={() => handleConfirm()}
        >
          {translate('confirm')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
