import styled from 'styled-components';

export const ContentModal = styled.div`
  width: 47rem;
  max-height: 70vh;
  overflow-y: auto;
`;
export const ContentInputModal = styled.div`
  padding: 0.5rem 0.5rem 0;
  margin-right: 0.5rem;
  h4 {
    font-size: 1.5rem;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.gray6};
    margin-bottom: 1.5rem;
  }
  > p {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.gray5};
    margin-bottom: 2rem;
  }
  > div {
    margin-bottom: 1.5rem;
  }
`;

export const DivInput = styled.div`
  display: flex;
  flex-direction: column;
  p {
    font-weight: 500;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.gray5};
    margin-bottom: 0.625rem;
  }
`;
