import styled from 'styled-components';

export const SimulationsSliderHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  margin: 2.25rem 0 1rem;

  input {
    outline: none;
    max-width: 3.125rem;
    margin-left: auto;

    color: ${({ theme }) => theme.colors.gray6};
    font-weight: 600;
    font-size: 0.75rem;
    text-align: right;
    padding: 0.125rem 0.25rem;
    border-radius: 4px;

    border: 1px solid ${({ theme }) => theme.colors.gray3};
    opacity: 0.8;
  }
`;
