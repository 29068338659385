import styled, { css } from 'styled-components';

type PropsTextArea = {
  icon: boolean;
  disabled?: boolean;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.gray5};

  flex: 0 0 auto;
`;

type PropsContainerTextArea = {
  error: boolean;
  disabled?: boolean;
};

export const ContainerTextArea = styled.div<PropsContainerTextArea>`
  flex: 1;

  border-radius: 8px;

  background: ${({ disabled, theme }) =>
    disabled ? theme.colors.gray0 : theme.colors.white};

  textarea {
    background: ${({ disabled, theme }) =>
      disabled ? theme.colors.gray0 : theme.colors.white};
  }

  p {
    color: ${({ theme }) => theme.colors.red2} !important;
    line-height: 1rem;
    font-style: normal;
    font-size: ${({ error }) => (error ? '0.875rem' : '0')};
    opacity: 0;

    transition: opacity 0.3s ease-in-out;
    transition: margin-top 0.3s ease-in-out;

    ${({ error }) =>
      error &&
      css`
        margin-top: 0.5rem;
        font-size: 0.875rem;
        opacity: 1;
      `}
  }

  > div {
    border: ${({ theme, error }) =>
      `1px solid ${error ? theme.colors.red2 : theme.colors.gray2}`};
    transition: border 0.2s ease-in-out;

    &:hover {
      border: ${({ theme, error, disabled }) =>
        `1px solid ${
          error
            ? theme.colors.red2
            : disabled
            ? theme.colors.gray2
            : theme.colors.gray3
        }`};
    }

    svg {
      color: ${({ theme }) => theme.colors.gray4};
      margin-left: 0 !important;

      width: 1rem !important;
      height: 1rem !important;
    }

    .textarea-icon-error {
      margin-left: 0.75rem !important;
      color: ${({ theme }) => theme.colors.red2};
    }
  }
`;

export const ContentTextArea = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  border-radius: 8px;

  height: 100%;
`;

export const TextArea = styled.textarea<PropsTextArea>`
  width: 100%;
  font-size: 0.875rem !important;
  color: ${({ theme }) => theme.colors.gray6};
  padding-left: ${({ icon }) => (icon ? '1rem' : '0')};
  border: 0;
  outline: 0;
  font-weight: 400;
  line-height: 150%;

  height: 100%;
  resize: none;

  flex: 1;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray4};
  }

  ::-webkit-scrollbar {
    width: 0.313rem;
    height: 0.313rem;
    padding: 0.063rem;
  }

  ::-webkit-scrollbar-track {
    transition: 0.2s;
    margin: 0.188rem;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }
`;
