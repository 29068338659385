const modelExclusionEn = {
  exclusionTitle: 'Are you absolutely sure?',
  exclusionDescription:
    'This will permanently delete this project’s and all of it’s information. Please type <b>XXX</b> to confirm.',
  exclusionCancelButton: 'Cancel',
  exclusionDeleteButton: 'Confirm',
  exclusionDeleteRequiredField: 'Required field',
  exclusionDeleteButtonWrongNameError: 'Must write the correct project name',
  exclusionDeleteRequestFailed: 'Request Failed!',
  exclusionDeleteError: 'There was an error deleting file.',
};

export default modelExclusionEn;
