import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;

  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;

    color: ${({ theme }) => theme.colors.gray6};
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;

    color: ${({ theme }) => theme.colors.gray5};

    margin-bottom: 1.5rem;
  }

  h2 + p {
    margin-top: 0.75rem;
  }

  div {
    width: 100%;
  }
`;
