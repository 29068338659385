import React, { useCallback, useEffect, useState } from 'react';

import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger,
  FieldErrors,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Button';
import { Input } from 'src/components/Input';
import { Modal } from 'src/components/Modal';
import { Select } from 'src/components/Select';
import { Tabs } from 'src/components/Tabs';
import { ToggleSwitch } from 'src/components/ToggleSwitch';
import { ContainerWarning } from 'src/models/components/ContainerWarning';
import { getForecastWarning } from 'src/utils/handleProjectHorizons';

import { RecommendedNWindows } from '../../../styles';
import { FormUpload, Validation } from '../../../types';
import {
  ContainerModal,
  DivButtons,
  ContainerToggle,
  DivGroup,
  ContainerTab,
  ContentLabel,
  ErrorMethodsFlag,
  ContentApplyLogAndAddDummies,
} from './styles';

type ModalAdvanced = {
  visible: boolean;
  setVisible: (value: boolean) => void;
  control: Control<any>;
  getValues: UseFormGetValues<any>;
  setValue: UseFormSetValue<any>;
  goldenVariables?: string[];
  removedVars?: string[];
  trigger: UseFormTrigger<FormUpload>;
  errors: FieldErrors<FormUpload>;
  featureSelection?: boolean;
  validationData: Validation | undefined;
  setForecastWarningVisible: (value: boolean) => void;
  forecastWarningVisible: boolean;
  recommendedNWindows: number;
  maxNWindows: number;
};

interface OldAdvancedOptionsValues {
  informationCriteria: string;
  accuracyCriteria: string;
  crossValidationSummary: string;
  explanatoryCollinearity: boolean;
  explanatoryVarAutoForecast: boolean;
  allowDrift: boolean;
  group1: string[];
  group2: string[];
  group3: string[];
  featSelectionLasso: boolean;
  featSelectionRandomForest: boolean;
  featCorrelationFilter: boolean;
  goldenVariables: string[];
  NWindows: number;
  applyLog: boolean;
  seasonalDummies: boolean;
}

export const ModalAdvanced: React.FC<ModalAdvanced> = ({
  visible,
  setVisible,
  control,
  getValues,
  setValue,
  goldenVariables,
  removedVars,
  trigger,
  errors,
  featureSelection,
  validationData,
  setForecastWarningVisible,
  forecastWarningVisible,
  recommendedNWindows,
  maxNWindows,
}) => {
  const [oldInputValues, setOldInputValues] =
    useState<OldAdvancedOptionsValues>();
  const [varsToTest, setVarsToTest] = useState<string[]>([]);

  const [, setGroup1Vars] = useState<string[]>([]);
  const [, setGroup2Vars] = useState<string[]>([]);
  const [, setGroup3Vars] = useState<string[]>([]);

  const [methodErrorVisible, setMethodErrorVisible] = useState<boolean>(false);
  const [goldenVariablesErrored, setGoldenVariablesErrored] =
    useState<boolean>(false);
  const [NWindowsErrored, setNWidowsErrored] = useState<boolean>(false);

  const { t: translate } = useTranslation();

  useEffect(() => {
    if (goldenVariables) {
      if (removedVars) {
        const goldenVarWithoutRemovedVars = goldenVariables.filter(
          (variable) => !removedVars.includes(variable),
        );

        setVarsToTest(goldenVarWithoutRemovedVars);
        setGroup1Vars(goldenVarWithoutRemovedVars);
        setGroup2Vars(goldenVarWithoutRemovedVars);
        setGroup3Vars(goldenVarWithoutRemovedVars);
      } else {
        setVarsToTest(goldenVariables);
        setGroup1Vars(goldenVariables);
        setGroup2Vars(goldenVariables);
        setGroup3Vars(goldenVariables);
      }
    }
  }, [featureSelection, goldenVariables, removedVars]);

  useEffect(() => {
    if (visible) {
      const oldValues = {
        informationCriteria: getValues('informationCriteria'),
        accuracyCriteria: getValues('accuracyCriteria'),
        crossValidationSummary: getValues('crossValidationSummary'),
        explanatoryCollinearity: getValues('explCollinearity'),
        explanatoryVarAutoForecast: getValues('explVarAutoForecast'),
        allowDrift: getValues('allowDrift'),
        group1: getValues('group1'),
        group2: getValues('group2'),
        group3: getValues('group3'),
        featSelectionLasso: getValues('featSelectionLasso'),
        featSelectionRandomForest: getValues('featSelectionRandomForest'),
        featCorrelationFilter: getValues('featCorrelationFilter'),
        goldenVariables: getValues('goldenVariables'),
        NWindows: getValues('NWindows'),
        applyLog: getValues('applyLog'),
        seasonalDummies: getValues('seasonalDummies'),
      };

      setOldInputValues(oldValues);
    }
  }, [getValues, visible]);

  const handleNWindowsError = useCallback(async () => {
    const isNWindowsCorrect = await trigger('NWindows');
    if (isNWindowsCorrect) {
      setNWidowsErrored(false);
    } else {
      setNWidowsErrored(true);
    }
  }, [trigger]);

  useEffect(() => {
    (async () => {
      handleNWindowsError();
      validationData?.info?.info_list?.nrows_training &&
        setForecastWarningVisible(
          getForecastWarning(
            validationData?.info?.info_list?.nrows_training,
            Number(getValues('forecastHorizon')),
            Number(getValues('NWindows')),
          ),
        );
    })();
  }, [
    getValues,
    handleNWindowsError,
    setForecastWarningVisible,
    validationData?.info?.info_list?.nrows_training,
    visible,
  ]);

  function handleCheckFeatureSelectionMethods() {
    let hasAnyMethodEnabled = true;

    if (
      !getValues('featSelectionLasso') &&
      !getValues('featSelectionRandomForest') &&
      !getValues('featCorrelationFilter')
    ) {
      hasAnyMethodEnabled = false;
    }

    if (goldenVariablesErrored === true) {
      setMethodErrorVisible(!hasAnyMethodEnabled);
    } else {
      setMethodErrorVisible(!hasAnyMethodEnabled);
    }
  }

  async function handleGoldenVariablesError() {
    const isGoldenVariablesCorrect = await trigger('goldenVariables');

    if (isGoldenVariablesCorrect) {
      setGoldenVariablesErrored(false);
    } else {
      setGoldenVariablesErrored(true);
    }
  }

  function handleCancelChanges() {
    setValue('informationCriteria', oldInputValues?.informationCriteria);
    setValue('accuracyCriteria', oldInputValues?.accuracyCriteria);
    setValue('crossValidationSummary', oldInputValues?.crossValidationSummary);
    setValue('explCollinearity', oldInputValues?.explanatoryCollinearity);
    setValue('explVarAutoForecast', oldInputValues?.explanatoryVarAutoForecast);
    setValue('allowDrift', oldInputValues?.allowDrift);
    setValue('group1', oldInputValues?.group1 ?? []);
    setValue('group2', oldInputValues?.group2 ?? []);
    setValue('group3', oldInputValues?.group3 ?? []);
    setValue('featSelectionLasso', oldInputValues?.featSelectionLasso);
    setValue(
      'featSelectionRandomForest',
      oldInputValues?.featSelectionRandomForest,
    );
    setValue('featCorrelationFilter', oldInputValues?.featCorrelationFilter);
    setValue('goldenVariables', oldInputValues?.goldenVariables ?? []);
    setValue('NWindows', oldInputValues?.NWindows);
    setValue('applyLog', oldInputValues?.applyLog);
    setValue('seasonalDummies', oldInputValues?.seasonalDummies);

    setMethodErrorVisible(false);
    trigger('NWindows');
    trigger('goldenVariables');
  }

  const ContentButtons = () => {
    const someExclusionGroupHasOnly1Item =
      getValues('group1')?.length === 1 ||
      getValues('group2')?.length === 1 ||
      getValues('group3')?.length === 1;

    const isButtonEnabled =
      !NWindowsErrored &&
      !methodErrorVisible &&
      !goldenVariablesErrored &&
      !someExclusionGroupHasOnly1Item;

    return (
      <DivButtons>
        <Button
          data-testid="modalAdvCancelBtn"
          buttonType="naked"
          onClick={() => {
            setVisible(false);
            handleCancelChanges();
          }}
          data-cy="button-modal-advanced-cancel"
          style={{ border: 0 }}
        >
          {translate('cancel')}
        </Button>

        {isButtonEnabled ? (
          <Button
            buttonType="primary"
            data-testid="modalAdvConfirmBtnEnabled"
            data-cy="button-modal-advanced-ok"
            onClick={() => setVisible(false)}
          >
            {translate('confirm')}
          </Button>
        ) : (
          <Button
            buttonType="primary"
            data-testid="modalAdvConfirmBtnDisabled"
            disabled
            data-cy="button-modal-advanced-ok"
          >
            {translate('confirm')}
          </Button>
        )}
      </DivButtons>
    );
  };

  return (
    <Modal
      cancel={handleCancelChanges}
      visible={visible}
      setVisible={setVisible}
      style={{ padding: 0 }}
    >
      <ContainerModal data-testid="advSettingsModalRenderization">
        <Tabs
          data={[
            {
              name: translate('additionalConfigurationTitle'),
              render: () => (
                <ContainerTab>
                  <div>
                    <ContentLabel>
                      <div>
                        <h4>{translate('additionalConfigurationTitle')}</h4>
                      </div>
                      <p>
                        {translate('additionalConfigurationTitleDescription')}
                      </p>
                    </ContentLabel>
                    <Controller
                      name="NWindows"
                      key="NWindows"
                      control={control}
                      defaultValue={1}
                      render={({ field: { onChange, value } }) => (
                        <Input
                          data-testid="nWindowsInput"
                          label={translate('step3NWindows')}
                          information={translate('step3NWindowsINfo')}
                          placeholder={translate('step3NWindowsPlaceholder')}
                          type="number"
                          value={value}
                          onChange={(e) => {
                            onChange(e.target.value);

                            validationData?.info?.info_list?.nrows_training &&
                              setForecastWarningVisible(
                                getForecastWarning(
                                  validationData?.info?.info_list
                                    ?.nrows_training,
                                  Number(getValues('forecastHorizon')),
                                  Number(e.target.value),
                                ),
                              );

                            handleNWindowsError();
                          }}
                          error={
                            errors.NWindows?.message ===
                            'projectErrorMaxNWindows'
                              ? `${translate(
                                  errors.NWindows?.message,
                                )} ${maxNWindows}`
                              : // @ts-expect-error typescript bug
                                translate(errors.NWindows?.message)
                          }
                          testid="datasetInput"
                        />
                      )}
                    />

                    {forecastWarningVisible && (
                      <ContainerWarning
                        visible={forecastWarningVisible}
                        text={translate('step3ForecastWarn')}
                      />
                    )}
                    {recommendedNWindows < getValues('NWindows') && (
                      <RecommendedNWindows>
                        <strong>
                          {translate('step3RecommendedNWindows')}{' '}
                        </strong>
                        {recommendedNWindows}
                      </RecommendedNWindows>
                    )}
                    <ContentApplyLogAndAddDummies>
                      <Controller
                        name="applyLog"
                        key="applyLog"
                        control={control}
                        defaultValue
                        render={({ field: { onChange, value } }) => (
                          <ToggleSwitch
                            data-testid="applyLogToggle"
                            label={translate('step3ApplyLog')}
                            information={translate('step3ApplyLogInfo')}
                            onChange={(val) => onChange(val)}
                            checked={value}
                            data-cy="toggle-switch-apply-log"
                          />
                        )}
                      />

                      <Controller
                        name="seasonalDummies"
                        key="seasonalDummies"
                        control={control}
                        defaultValue
                        render={({ field: { onChange, value } }) => (
                          <ToggleSwitch
                            data-testid="seasonalDummiesToggle"
                            label={translate('step3SeasDummies')}
                            information={translate('step3SeasDummiesInfo')}
                            onChange={(val) => onChange(val)}
                            checked={value}
                            data-cy="toggle-switch-add-seasonal-dummies"
                          />
                        )}
                      />
                    </ContentApplyLogAndAddDummies>
                  </div>
                  <ContentButtons />
                </ContainerTab>
              ),
            },
            {
              name: translate('rankingCriteria'),
              render: () => (
                <ContainerTab>
                  <div>
                    <ContentLabel>
                      <div>
                        <h4>{translate('rankingCriteria')}</h4>
                        {/* <Info size={16} data-tip="texto aqui" /> */}
                        {/* <ReactTooltip /> */}
                      </div>
                      <p>{translate('rankingCriteriaDescr')}</p>
                    </ContentLabel>

                    {/* <Controller
                      name="informationCriteria"
                      control={control}
                      key="informationCriteria"
                      defaultValue="AIC"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          label="Information Criteria"
                          placeholder="Choose a variable"
                          information="What information criteria are used to evaluate models: AIC or BIC"
                          style={{ marginBottom: '24px' }}
                          value={{ label: value, value }}
                          onChange={(event: any) => onChange(event.value)}
                          options={[
                            {
                              label: 'AIC',
                              value: 'AIC',
                            },
                            {
                              label: 'BIC',
                              value: 'BIC',
                            },
                          ]}
                        />
                      )}
                    /> */}
                    <Controller
                      name="accuracyCriteria"
                      control={control}
                      key="accuracyCriteria"
                      defaultValue="MAPE"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          label={translate('accuracyCriteriaLabel')}
                          placeholder={translate('accuracyCriteriaPlaceholder')}
                          information={translate('accuracyCriteriaInfo')}
                          value={{ label: value, value }}
                          onChange={(event: any) => onChange(event.value)}
                          style={{ marginBottom: '24px' }}
                          options={[
                            {
                              label: 'MAPE',
                              value: 'MAPE',
                            },
                            {
                              label: 'WMAPE',
                              value: 'WMAPE',
                            },
                            {
                              label: 'MPE',
                              value: 'MPE',
                            },
                            {
                              label: 'RMSE',
                              value: 'RMSE',
                            },
                          ]}
                        />
                      )}
                    />
                    <Controller
                      name="crossValidationSummary"
                      key="crossValidationSummary"
                      control={control}
                      defaultValue="Mean"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          label={translate('crossValidationSummaryLabel')}
                          placeholder={translate(
                            'crossValidationSummaryPlaceholder',
                          )}
                          information={translate('crossValidationSummaryInfo')}
                          value={{
                            label: value
                              ? translate(`crossValidationSummaryOpt${value}`)
                              : value,
                            value,
                          }}
                          onChange={(event: any) => onChange(event.value)}
                          options={[
                            {
                              label: translate('crossValidationSummaryOptMean'),
                              value: 'Mean',
                            },
                            {
                              label: translate(
                                'crossValidationSummaryOptMedian',
                              ),
                              value: 'Median',
                            },
                          ]}
                        />
                      )}
                    />
                  </div>

                  <ContentButtons />
                </ContainerTab>
              ),
            },
            {
              name: translate('explanatoryFeatures'),
              render: () => (
                <ContainerTab>
                  <div>
                    <ContentLabel>
                      <div>
                        <h4>{translate('explanatoryFeatures')}</h4>
                        {/* <Info size={16} data-tip="texto aqui" /> */}
                        {/* <ReactTooltip /> */}
                      </div>
                      <p>{translate('explanatoryFeaturesDescr')}</p>
                    </ContentLabel>
                    <ContainerToggle>
                      <Controller
                        name="explCollinearity"
                        key="explCollinearity"
                        defaultValue
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <ToggleSwitch
                            data-testid="collinearityToggle"
                            label={translate('avoidCollinearity')}
                            information={translate('avoidCollinearityInfo')}
                            onChange={(val) => onChange(val)}
                            checked={value}
                            data-cy="toggle-switch-explanatory-avoid-collinearity"
                          />
                        )}
                      />

                      <Controller
                        name="explVarAutoForecast"
                        key="explVarAutoForecast"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <ToggleSwitch
                            data-testid="explVarsAutoForecast"
                            label={translate('explVarAutoForecast')}
                            information={translate('explVarAutoForecastInfo')}
                            onChange={(val) => onChange(val)}
                            checked={value}
                            data-cy="toggle-switch-explanatory-variables"
                          />
                        )}
                      />

                      <Controller
                        name="allowDrift"
                        key="allowDrift"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <ToggleSwitch
                            data-testid="toggle-switch-drift"
                            data-cy="toggle-switch-drift"
                            label={translate('allowDrift')}
                            information={translate('allowDriftInfo')}
                            onChange={(val) => onChange(val)}
                            checked={value}
                          />
                        )}
                      />
                    </ContainerToggle>
                  </div>
                  <ContentButtons />
                </ContainerTab>
              ),
            },
            {
              name: translate('configureExclusion'),
              render: () => (
                <ContainerTab>
                  <div>
                    <ContentLabel>
                      <div>
                        <h4>{translate('configureExclusion')}</h4>
                        {/* <Info size={16} data-tip="texto aqui" /> */}
                        {/* <ReactTooltip /> */}
                      </div>
                      <p>{translate('configureExclusionDescr')}</p>
                    </ContentLabel>
                    {/* <Controller
                      name="varsCanBeTestedSimultaneously"
                      control={control}
                      key="varsCanBeTestedSimultaneously"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          label="Variables that can be tested simultaneously"
                          placeholder="Variables"
                          information="teste"
                          value={{ label: value, value }}
                          onChange={(event: any) => {
                            onChange(event.value);
                          }}
                          options={getValues('explanatoryVariables').map(
                            (variable: string) => ({
                              label: variable,
                              value: variable,
                            }),
                          )}
                          style={{ height: '100px' }}
                        />
                      )}
                    /> */}

                    <DivGroup>
                      <Controller
                        name="group1"
                        key="group1"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            label={translate('group1')}
                            isMulti
                            style={{ maxWidth: '234.6px' }}
                            placeholder={translate('exclusionsPlaceholder')}
                            options={
                              varsToTest &&
                              varsToTest
                                ?.filter(
                                  (variable: string) =>
                                    !getValues('group2')?.includes(variable) &&
                                    !getValues('group3')?.includes(variable),
                                )
                                ?.map((variable) => ({
                                  label: variable,
                                  value: variable,
                                }))
                            }
                            value={value?.map((val: any) => ({
                              label: val,
                              value: val,
                            }))}
                            onChange={(eventArray: any) => {
                              onChange(eventArray.map((val: any) => val.value));

                              setGroup2Vars((oldValue) =>
                                oldValue.filter(
                                  (variable) =>
                                    !getValues('group1')?.includes(variable) &&
                                    !getValues('group3')?.includes(variable),
                                ),
                              );

                              setGroup3Vars((oldValue) =>
                                oldValue.filter(
                                  (variable) =>
                                    !getValues('group1')?.includes(variable) &&
                                    !getValues('group2')?.includes(variable),
                                ),
                              );
                            }}
                          />
                        )}
                      />
                      <Controller
                        name="group2"
                        key="group2"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            label={translate('group2')}
                            placeholder={translate('exclusionsPlaceholder')}
                            style={{ maxWidth: '234.6px' }}
                            isMulti
                            options={
                              varsToTest &&
                              varsToTest
                                ?.filter(
                                  (variable: string) =>
                                    !getValues('group1')?.includes(variable) &&
                                    !getValues('group3')?.includes(variable),
                                )
                                ?.map((variable) => ({
                                  label: variable,
                                  value: variable,
                                }))
                            }
                            value={value?.map((val: any) => ({
                              label: val,
                              value: val,
                            }))}
                            onChange={(valArray: any) => {
                              onChange(valArray.map((val: any) => val.value));

                              setGroup1Vars((oldValue) =>
                                oldValue.filter(
                                  (variable) =>
                                    !getValues('group2')?.includes(variable) &&
                                    !getValues('group3')?.includes(variable),
                                ),
                              );

                              setGroup3Vars((oldValue) =>
                                oldValue.filter(
                                  (variable) =>
                                    !getValues('group1')?.includes(variable) &&
                                    !getValues('group2')?.includes(variable),
                                ),
                              );
                            }}
                          />
                        )}
                      />
                      <Controller
                        name="group3"
                        key="group3"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            label={translate('group3')}
                            placeholder={translate('exclusionsPlaceholder')}
                            isMulti
                            style={{ maxWidth: '234.6px' }}
                            options={
                              varsToTest &&
                              varsToTest
                                ?.filter(
                                  (variable: string) =>
                                    !getValues('group1')?.includes(variable) &&
                                    !getValues('group2')?.includes(variable),
                                )
                                ?.map((variable) => ({
                                  label: variable,
                                  value: variable,
                                }))
                            }
                            value={value?.map((val: any) => ({
                              label: val,
                              value: val,
                            }))}
                            onChange={(valArray: any) => {
                              onChange(valArray.map((val: any) => val.value));

                              setGroup1Vars((oldValue) =>
                                oldValue.filter(
                                  (variable) =>
                                    !getValues('group2')?.includes(variable) &&
                                    !getValues('group3')?.includes(variable),
                                ),
                              );

                              setGroup2Vars((oldValue) =>
                                oldValue.filter(
                                  (variable) =>
                                    !getValues('group1')?.includes(variable) &&
                                    !getValues('group3')?.includes(variable),
                                ),
                              );
                            }}
                          />
                        )}
                      />
                    </DivGroup>
                    {/* <ReactTooltip
                      type="light"
                      multiline
                      className="customTooltipTheme"
                    /> */}
                  </div>
                  <ContentButtons />
                </ContainerTab>
              ),
            },
            {
              name: translate('featureSelection'),
              render: () => (
                <ContainerTab>
                  <div>
                    <ContentLabel>
                      <div>
                        <h4>{translate('featureSelection')}</h4>
                        {/* <Info size={16} data-tip="texto aqui" /> */}
                        {/* <ReactTooltip /> */}
                      </div>
                      <p>{translate('featureSelectionDescr')}</p>
                    </ContentLabel>
                    <DivGroup>
                      <div>
                        <Controller
                          name="featSelectionLasso"
                          key="featSelectionLasso"
                          control={control}
                          defaultValue
                          render={({ field: { onChange, value } }) => (
                            <ToggleSwitch
                              data-testid="toggleLasso"
                              label={translate('lasso')}
                              information={translate('lassoInfo')}
                              checked={value}
                              onChange={(val) => {
                                onChange(val);
                                handleCheckFeatureSelectionMethods();
                              }}
                              disabled={!featureSelection}
                              style={{ marginBottom: '24px', width: '30%' }}
                              data-cy="toggle-switch-feature-avoid-collinearity"
                            />
                          )}
                        />

                        <Controller
                          name="featSelectionRandomForest"
                          key="featSelectionRandomForest"
                          control={control}
                          defaultValue
                          render={({ field: { onChange, value } }) => (
                            <ToggleSwitch
                              data-testid="toggleRF"
                              label={translate('randomForest')}
                              information={translate('randomForestInfo')}
                              checked={value}
                              onChange={(val) => {
                                onChange(val);
                                handleCheckFeatureSelectionMethods();
                              }}
                              disabled={!featureSelection}
                              style={{ marginBottom: '24px', width: '30%' }}
                              data-cy="toggle-switch-random-forest"
                            />
                          )}
                        />

                        <Controller
                          name="featCorrelationFilter"
                          key="featCorrelationFilter"
                          control={control}
                          defaultValue
                          render={({ field: { onChange, value } }) => (
                            <ToggleSwitch
                              data-testid="toggleCorrFilter"
                              label={translate('correlationFilter')}
                              information={translate('correlationFilterInfo')}
                              checked={value}
                              onChange={(val) => {
                                onChange(val);
                                handleCheckFeatureSelectionMethods();
                              }}
                              disabled={!featureSelection}
                              style={{ marginBottom: '24px', width: '30%' }}
                              data-cy="toggle-switch-correlation-filter"
                            />
                          )}
                        />
                        {methodErrorVisible && (
                          <ErrorMethodsFlag>
                            {translate('mathodsDisabledMessage')}
                          </ErrorMethodsFlag>
                        )}
                      </div>
                      <div>
                        <Controller
                          name="goldenVariables"
                          key="goldenVariables"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              label={translate('goldenVariables')}
                              placeholder={translate(
                                'goldenVariablesPlaceholder',
                              )}
                              className="goldenVariables"
                              error={translate(
                                //@ts-expect-error typescript bug
                                errors?.goldenVariables?.message,
                              )}
                              information={translate('goldenVariablesInfo')}
                              isMulti
                              value={value?.map((val: any) => ({
                                label: val,
                                value: val,
                              }))}
                              onChange={async (valArray: any) => {
                                // await trigger('goldenVariables');
                                if (!value) {
                                  onChange(
                                    valArray.map((val: any) => val.value),
                                  );
                                } else if (value.length + 1 <= 14) {
                                  onChange(
                                    valArray.map((val: any) => val.value),
                                  );
                                } else {
                                  onChange(
                                    valArray.map((val: any) => val.value),
                                  );
                                }
                                await trigger('goldenVariables');
                                handleGoldenVariablesError();
                              }}
                              options={
                                goldenVariables && removedVars
                                  ? goldenVariables
                                      .filter(
                                        (variable) =>
                                          !removedVars.includes(variable),
                                      )
                                      .map((variable) => ({
                                        label: variable,
                                        value: variable,
                                      }))
                                  : goldenVariables &&
                                    goldenVariables.map((variable) => ({
                                      label: variable,
                                      value: variable,
                                    }))
                              }
                            />
                          )}
                        />

                        {getValues('goldenVariables').length > 0 && (
                          <ContainerWarning
                            visible={getValues('goldenVariables').length > 0}
                            text={translate('goldenVariablesWarning')}
                          />
                        )}
                      </div>
                    </DivGroup>
                  </div>
                  <ContentButtons />
                </ContainerTab>
              ),
            },
          ]}
        />
      </ContainerModal>
    </Modal>
  );
};
