import React, { useEffect, useMemo, useRef } from 'react';

import { HCharts, HChartsOptions, HChartsSeries } from 'src/components/HCharts';
import { getChartColor } from 'src/utils/colors/getChartColor';
import { useTranslation } from 'react-i18next';
import { formatCompactNotation } from 'src/utils/numbers/formatCompactNotation';
import HighchartsReact from 'highcharts-react-official';

import { VariablesCharts } from '..';

interface DependentVariablesChartProps {
  variablesCharts: VariablesCharts[];
  hasZoom: boolean;
}

export const DependentVariablesChart: React.FC<
  DependentVariablesChartProps
> = ({ variablesCharts, hasZoom }) => {
  const { t: translate } = useTranslation();

  const chartRef = useRef<HighchartsReact.RefObject>(null);

  // useEffect(() => {
  //   if (variablesCharts.length > 1) {
  //     chartRef.current?.chart.zoomOut();
  //   }
  // }, [variablesCharts]);

  const series: HChartsSeries[] = useMemo(
    () =>
      variablesCharts.flatMap((variableChart, index) => [
        {
          type: 'line',
          name: variableChart.name,
          color: getChartColor(index),
          dashStyle: 'Solid',
          data: variableChart.level.historical.x.map((x, xIndex) => ({
            x: new Date(`${x}T00:00`).getTime(),
            y: variableChart.level.historical.y[xIndex],
            custom: {
              value: formatCompactNotation(
                variableChart.level.historical.y[xIndex],
              ),
              keyValue: translate('Historical'),
            },
          })),
          id: String(index),
          marker: {
            enabledThreshold: 2,
            radius: 4,
            symbol: 'circle',
          },
        },
        {
          type: 'line',
          name: variableChart.name,
          color: getChartColor(index),
          dashStyle: 'Dash',
          data:
            variableChart.level.forecast?.x.map((x, xIndex) => ({
              x: new Date(`${x}T00:00`).getTime(),
              y: variableChart.level.forecast?.y[xIndex],
              custom: {
                value: formatCompactNotation(
                  variableChart.level.forecast?.y[xIndex] ?? 0,
                ),
                keyValue:
                  xIndex !== 0
                    ? translate('Forecast')
                    : translate('Historical'),
              },
            })) ?? [],
          linkedTo: String(index),
          marker: {
            enabledThreshold: 2,
            radius: 4,
            symbol: 'circle',
          },
        },
      ]),
    [translate, variablesCharts],
  );

  // const initialDefaultLoad = useMemo(() => {
  //   if (selectedY?.info?.frequency && !!variablesCharts.length) {
  //     const totalHistorical =
  //       variablesCharts[0].level.historical?.x.length || 0;
  //     const totalForecast = variablesCharts[0].level.forecast?.x.length || 0;

  //     const wantedQuantityForecast =
  //       frequencyLatestData[selectedY.info.frequency] / 3;

  //     const quantityForecast =
  //       totalForecast >= wantedQuantityForecast
  //         ? wantedQuantityForecast
  //         : totalForecast;

  //     const quantityHistorical =
  //       frequencyLatestData[selectedY?.info?.frequency] - quantityForecast;

  //     const min =
  //       variablesCharts[0].level.historical.x[
  //         totalHistorical >= quantityHistorical
  //           ? totalHistorical - quantityHistorical
  //           : 0
  //       ];

  //     const max = quantityForecast
  //       ? variablesCharts[0].level.forecast?.x[quantityForecast - 1]
  //       : variablesCharts[0].level.historical.x.at(-1);

  //     return [
  //       new Date(`${min}T00:00`).getTime(),
  //       new Date(`${max}T00:00`).getTime(),
  //     ];
  //   }

  //   return null;
  // }, [selectedY?.info?.frequency, variablesCharts]);

  const options: HChartsOptions = useMemo(
    () => ({
      chart: {
        height: 500,
        // events: {
        //   load() {
        //     if (initialDefaultLoad) {
        //       // eslint-disable-next-line react/no-this-in-sfc
        //       this.xAxis.forEach((axis) => {
        //         axis.setExtremes(initialDefaultLoad[0], initialDefaultLoad[1]);
        //       });
        //     }
        //   },
        // },
      },

      tooltip: {
        pointFormat:
          `<tr><td><b>${translate('date')}:</b> </td>` +
          `<td style="text-align: right">{point.x: %d/%m/%Y}</td></tr>` +
          `<tr><td><b>{point.custom.keyValue}:</b> </td>` +
          '<td style="text-align: right">{point.custom.value}</td></tr>',
      },
      navigator: {
        enabled: true,
        xAxis: {
          gridLineWidth: 0,
        },
      },
    }),
    [translate],
  );

  useEffect(() => {
    if (chartRef) {
      const chart = chartRef.current?.chart;

      if (chart) {
        const { min, max } = chart.xAxis[0].getExtremes();

        chart.zoomOut();

        if (hasZoom) {
          chart.showResetZoom();

          chart.xAxis[0].setExtremes(min, max, true, false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variablesCharts]);

  return (
    <HCharts
      ref={chartRef}
      series={series}
      options={options}
      dataCy="chart-dependent-variables"
      zoomOutAfterChangingSeries={false}
      resizeWidthWithSidebar
    />
  );
};
