/* eslint-disable prettier/prettier */
/* eslint-disable no-useless-escape */
export const sample = {
  python: `import numpy as np
import pandas as pd
import requests

AUTH_TOKEN = "API TOKEN"
url = "{APIURL}/projects/{ORIGINAL_PROJECT_ID}/updates"

"COMMENT"
"DATASET_FILE_NAMES"
"YS"

"DATALIST"

update = {
  "datasets": datasets
}

headers = {
  "Authorization": f"Bearer {AUTH_TOKEN}"
}
  
response = requests.post(url, headers=headers, json=update)
print(response.text)`,
  r: `library(httr)
library(jsonlite)
  
AUTH_TOKEN <- "API TOKEN"
url <- "{APIURL}/projects/{ORIGINAL_PROJECT_ID}/updates"

"COMMENT"
"NEW_DATASET_FILENAME"
"YS"

"DATA_LIST"

names(datasets) = c("CYS")

update <- list(
  "datasets" = datasets
)

headers <- c(
  "Authorization" = paste0("Bearer ", AUTH_TOKEN)
)

response <- POST(url, add_headers(headers), body = update, encode = "json")
print(content(response, "text"))


`,
};
