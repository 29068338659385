import { ToggleSwitch } from 'src/components/ToggleSwitch';
import styled from 'styled-components';

export const CardTooltip = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 1.75rem;

  > svg {
    margin-top: 0 !important;
  }

  > div {
    width: max-content;

    display: flex;
    flex-direction: column;
    align-items: end;

    p {
      margin-right: 0;
    }
  }
`;

export const Options = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const Frequencies = styled.div`
  > span {
    display: block;
    margin-bottom: 0.5rem;

    font-weight: 500;
    font-size: 0.875rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray5};
  }

  div {
    display: flex;
    gap: 1.5rem;
  }
`;

export const ToggleLatestData = styled(ToggleSwitch)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  margin-left: auto;

  p {
    margin-right: 0 !important;
  }
`;
