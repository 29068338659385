const lagsModalPTBRTranslations = {
  lagsModalTitle: 'Busca de defasagens',
  lagsModalDescr: `Crie defasagens para as variáveis explicativas. Avaliaremos todas as possibilidades até o valor máximo indicado, selecionando o valor de defasagem ótimo para cada variável.`,
  lagsModalDescripPreviousReplaced:
    'Configuração antiga de defasagens foi removida.',
  lagNumberLabel: 'Número de defasagens',
  lagVariablesLabel: 'Escolha as variáveis',
};

export default lagsModalPTBRTranslations;
