import styled, { css } from 'styled-components';

export const AdminPanelContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.5rem;
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  max-width: 80rem;
`;

export const HeaderAdminPanel = styled.div`
  display: flex;
  align-items: center;
  > svg {
    margin-bottom: 0.75rem;
    margin-right: 1.5rem;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.gray4};
    transition: 0.2s color;

    &:hover {
      color: ${({ theme }) => theme.colors.gray5};
    }
  }
`;

export const ContentTable = styled.div``;

export const MessageLicenseAndButtonAdd = styled.div`
  position: absolute;
  top: 2.5rem;
  right: 1.5rem;

  display: flex;
  align-items: center;

  span {
    font-weight: 400;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.gray5};
    margin-right: 1.5rem;
    b {
      font-weight: 600;

      color: ${({ theme }) => theme.colors.secondary};
    }
  }
`;

export const RemainingLicensesMessage = styled.span``;

interface ButtonDeleteUserProps {
  disabled?: boolean;
}

export const ButtonDeleteUser = styled.button<ButtonDeleteUserProps>`
  ${({ disabled }) =>
    !disabled
      ? css`
          color: ${({ theme }) => theme.colors.gray5};
          transition: 0.2s color;
          &:hover {
            color: ${({ theme }) => theme.colors.gray6};
          }
        `
      : css`
          color: ${({ theme }) => theme.colors.gray4};
          cursor: not-allowed;
        `};
`;
