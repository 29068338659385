export const workspaceProjectionsPotencialImpactsENTranslate = {
  workspaceProjectionsPotencialImpactsTitle: 'Potential Impacts',
  workspaceProjectionsPotencialImpactsDescription:
    'Estimates of the impacts associated with conditioning factors on the variable of interest',
  workspaceProjectionsPotencialImpactsSeeMore: 'See more impacts',
  workspaceProjectionsPotencialImpactsLessMore: 'See less impacts',
  workspaceProjectionsPotencialImpactsUnderstandingImpact:
    'Understanding the Impact',
  workspaceProjectionsImpactsError0Variables:
    'No explanatory variable to be displayed.',
  workspaceProjectionsImpactsTooltipInfo:
    'If logarithm was not applied to the dependent variable, impacts are inflated according to the IPCA value in the last in sample month (normalized to 01-2018)',
};
