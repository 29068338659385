import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 47rem;

  position: relative;

  h3 {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
    color: ${({ theme }) => theme.colors.gray6};
    margin-bottom: 0.5rem;
  }

  > p {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray5};
  }
`;

export const ProjectIDContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 1rem;
  margin-top: 1.5rem;
  width: 100%;

  div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    > p {
      color: ${({ theme }) => theme.colors.primary};
      font-family: Inter;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.07rem;
      text-transform: uppercase;
    }

    > span {
      color: ${({ theme }) => theme.colors.gray5};
      font-family: Inter;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
`;

export const LanguageSelector = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 47rem;
  margin-top: 1.5rem;

  > div {
    display: flex;
    gap: 0.5rem !important;
  }
`;

interface GetTokenButtonProps {
  copied?: boolean;
}

export const GetTokenButton = styled.button<GetTokenButtonProps>`
  display: flex;
  padding: 0.375rem 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.375rem;
  border: ${({ theme, copied }) =>
    copied
      ? `0.0625rem solid rgba(72, 187, 120, 0.12)`
      : `0.0625rem solid ${theme.colors.gray2}`};
  background: ${({ theme, copied }) =>
    copied ? 'rgba(72, 187, 120, 0.12)' : theme.colors.white};
  transition: all 0.5s;

  svg {
    color: ${({ theme, copied }) =>
      copied ? theme.colors.green4 : theme.colors.gray5};
  }

  p {
    color: ${({ theme, copied }) =>
      copied ? theme.colors.green4 : theme.colors.gray5};
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375rem;
  }

  :hover {
    border: ${({ theme, copied }) =>
      !copied && `0.0625rem solid ${theme.colors.primary}`};

    * {
      color: ${({ theme, copied }) =>
        copied ? theme.colors.green4 : theme.colors.primary};
    }
  }
`;

interface LanguageButtonProps {
  selected: boolean;
}

export const LanguageButton = styled.button<LanguageButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1rem;
  min-width: 3.625rem;
  border-radius: 5px;
  width: auto !important;
  background: ${({ theme, selected }) =>
    selected ? 'rgba(76, 148, 255, 0.08)' : theme.colors.white};
  border: 1px solid
    ${({ theme, selected }) =>
      selected ? theme.colors.primary : theme.colors.gray4};

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: ${({ theme, selected }) =>
      selected ? theme.colors.primary : theme.colors.gray4};
  }

  transition: all 0.5s;

  :hover {
    border: 1px solid ${({ theme }) => theme.colors.primary};

    p {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const CodeBlock = styled.div`
  position: relative;
  min-width: 47rem;
  max-height: 18.75rem;
  font-family: 'Fira Code';
  font-style: normal;
  font-weight: 400;
  font-size: 0.938rem;
  line-height: 150%;
  margin-top: 0.75rem;
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.gray2};

  * {
    overflow-x: unset;
  }

  span {
    color: ${({ theme }) => theme.colors.gray5} !important;
    font-size: 0.9375rem !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 150% !important;
  }

  div {
    width: 47rem !important;
    max-height: 18.75rem;
    overflow-y: auto;
    border: none;
    border-radius: 0.5rem;
  }

  @media (max-height: 750px) {
    max-height: 150px;
    div {
      max-height: 150px;
    }
  }

  code {
    width: 47rem !important;
  }
`;

interface CopyCodeButtonProps {
  copied?: boolean;
}

export const CopyCodeButton = styled.button<CopyCodeButtonProps>`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  background: ${({ theme }) => theme.colors.white};

  border-radius: 0.375rem;
  transition: all 0.5s;

  > div {
    width: auto !important;
    display: flex;
    padding: 0.375rem 0.75rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    border: 0.125rem solid
      ${({ theme, copied }) =>
        copied ? 'rgba(72, 187, 120, 0.12)' : theme.colors.gray2};
    background: ${({ theme, copied }) =>
      copied ? 'rgba(72, 187, 120, 0.12)' : theme.colors.white};
  }

  svg {
    color: ${({ theme, copied }) =>
      copied ? theme.colors.green4 : theme.colors.gray5};
  }

  p {
    color: ${({ theme, copied }) =>
      copied ? theme.colors.green4 : theme.colors.gray5};
  }

  :hover {
    > div {
      border: 0.125rem solid
        ${({ theme, copied }) =>
          copied ? 'rgba(72, 187, 120, 0.12)' : theme.colors.primary};
    }

    * {
      color: ${({ theme, copied }) =>
        copied ? theme.colors.green4 : theme.colors.primary};
    }
  }
`;

export const CloseModalButton = styled.button`
  position: absolute;
  right: 0rem;
  top: 0rem;

  width: 1.875rem;
  height: 1.875rem;

  svg {
    color: ${({ theme }) => theme.colors.gray4};
    transition: 0.2s;

    :hover {
      color: ${({ theme }) => theme.colors.gray5};
    }
  }
`;
