export const Step2ClaaSPTBRTranslations = {
  step2ClaasDependentVariableInputLabel: 'Variável dependente',
  step2SelectVariablesTitleClaas:
    'Selecione a variável de interesse e conjunto de features explicativas.',
  step2DependentVariableInputTooltipClaas:
    'Variáveis a serem previstas. Variáveis binárias e de multiclasses estão disponíveis. ',
  step2DependentVariableChartYAxis: 'Número de observações',
  step2DependentVariableChartObservations: 'Nº de observações',
  step2BalanceWarning:
    'Seu conjunto de dados parece ser desbalanceado. Nossos algoritmos podem lidar com isso ou os dados fornecidos podem ser revisados.',
  step2ClassExclusionWarning:
    'Classes de "TARGET" com menos de 6 ocorrências foram excluídas do processo de modelagem.',
};

export default Step2ClaaSPTBRTranslations;
