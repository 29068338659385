import React from 'react';

import { Warning, XCircle } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';

import { ButtonsContent, Container } from './styles';

interface ModalProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  message: string;
  columns: string[];
  error?: boolean;
}

export const WarningModal: React.FC<ModalProps> = ({
  visible,
  setVisible,
  message,
  columns,
  error,
}) => {
  const { t: translate } = useTranslation();

  return (
    <Modal visible={visible} setVisible={setVisible} style={{ padding: 0 }}>
      <Container
        isErrorModal={error}
        data-cy={error ? 'fail-modal' : 'warning-modal'}
      >
        {error ? <XCircle size="3rem" /> : <Warning size="3rem" />}
        <h4>{message}</h4>
        <p>
          <strong>
            {columns.length > 1
              ? `${translate('predictColumnPlural')}: `
              : `${translate('predictColumnSingular')}: `}
          </strong>
          {columns.map((column, index) =>
            index + 1 === columns.length && index <= 19
              ? `${column}.`
              : index <= 19
              ? `${column}, `
              : index === 20 &&
                ` ${translate('predictWarningMeaningDefinition')} ${
                  columns.length - 20
                }.`,
          )}
        </p>

        <ButtonsContent>
          <Button
            buttonType="primary"
            data-testid="warning-modal-button"
            onClick={() => setVisible(false)}
            data-cy="button-modal-failed-ok"
          >
            Ok
          </Button>
        </ButtonsContent>
      </Container>
    </Modal>
  );
};
