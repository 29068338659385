import React, { useState } from 'react';

import { CaretRight, ChartLine, CaretLeft } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ClassificationIcon from 'src/assets/classification.svg';
import ClassificationSelectedIcon from 'src/assets/classification_selected.svg';
import { Button } from 'src/components/Button';
import { Card } from 'src/components/Card';

import {
  Container,
  ContainerComponents,
  ContentButton,
  ContentModelTypes,
  SelectModelButton,
} from './styles';

export const ModellingStart: React.FC = () => {
  const [selectedModelingType, setSelectedModelingType] = useState<
    'time-series' | 'classification'
  >('time-series');

  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  return (
    <Container>
      <ContainerComponents>
        <Card
          textCard={translate('modellingStartTitle')}
          textDescription={translate('modellingStartSubTitle')}
        />

        <ContentModelTypes>
          <SelectModelButton
            selected={selectedModelingType === 'time-series'}
            data-testid="select-time-series-button"
            data-cy="select-time-series-button"
            onClick={() => setSelectedModelingType('time-series')}
          >
            <div>
              <ChartLine size="4.688rem" />
            </div>
            <p>{translate('newTimeSeriesTitle')}</p>
            <span>{translate('newTimeSeriesSubTitle')}</span>
          </SelectModelButton>
          <SelectModelButton
            selected={selectedModelingType === 'classification'}
            data-testid="select-classification-button"
            data-cy="select-classification-button"
            onClick={() => setSelectedModelingType('classification')}
          >
            <div>
              <img
                src={
                  selectedModelingType === 'classification'
                    ? ClassificationSelectedIcon
                    : ClassificationIcon
                }
                alt="classification-icon"
              />
            </div>
            <p>{translate('newClassificationTitle')}</p>
            <span>{translate('newClassificationSubTitle')}</span>
          </SelectModelButton>
        </ContentModelTypes>
        <ContentButton>
          <Button
            buttonType="naked"
            data-testid="button-back-modeling-type"
            data-cy="button-back"
            onClick={() => navigate('/models/projects')}
            icon={<CaretLeft size="1.125rem" />}
          >
            {translate('backButtons')}
          </Button>
          <Button
            buttonType="primary"
            data-testid="button-submit-modeling-type"
            data-cy="button-submit-modeling-type"
            onClick={() => {
              selectedModelingType === 'time-series'
                ? navigate('/models/projects/new/time-series')
                : navigate('/models/projects/new/classification');
            }}
            icon={<CaretRight size="1.125rem" />}
            iconInvert
          >
            {translate('modellingStartButtonText')}
          </Button>
        </ContentButton>
      </ContainerComponents>
    </Container>
  );
};
