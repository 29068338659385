import React from 'react';

import { ContainerProgressInDots, Dot } from './styles';

interface ProgressInDots {
  step: 1 | 2 | 3;
  backStep1?: () => void;
  backStep2?: () => void;
  isLoadingAnotherStep?: boolean;
}

export const ProgressInDots: React.FC<ProgressInDots> = ({
  step,
  backStep1,
  backStep2,
  isLoadingAnotherStep = false,
}) => (
  <ContainerProgressInDots>
    <div>
      <Dot
        selected={step === 1}
        disabled={step <= 1 || isLoadingAnotherStep}
        onClick={backStep1}
      />
      <Dot
        selected={step === 2}
        disabled={step <= 2 || isLoadingAnotherStep}
        onClick={backStep2}
      />
      <Dot selected={step === 3} disabled={step <= 3 || isLoadingAnotherStep} />
    </div>
  </ContainerProgressInDots>
);
