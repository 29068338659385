import styled from 'styled-components';

export const Content = styled.div`
  position: relative;

  overflow-y: auto;

  .tutorial-close-button {
    position: absolute;
    top: 0.25rem;
    right: 0;

    padding: 0;

    width: 1.5rem;
    height: 1.5rem;

    svg {
      width: 1.5rem;
      height: 1.5rem;

      margin: 0;
    }
  }
`;

type FooterContentProps = {
  showPage: boolean;
};

export const FooterContent = styled.div<FooterContentProps>`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ showPage }) => (showPage ? 'space-between' : 'end')};

  button + button {
    margin-left: 2rem;
  }
`;

export const Page = styled.p`
  color: ${({ theme }) => theme.colors.gray5};

  font-size: 0.875rem;
  font-weight: 400;
  line-height: 2rem;
`;

export const StepContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > div + div {
    margin-top: 1rem;
  }

  > div {
    align-self: flex-start;
  }

  p {
    color: ${({ theme }) => theme.colors.gray5};

    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;
  }

  img {
    width: 30rem;

    margin-top: 2rem;
  }
`;

export const Step = styled.span`
  width: 2rem;
  height: 2rem;
  border-radius: 100%;

  background: ${({ theme }) => `${theme.colors.primary}14`};

  font-size: 1.125rem;
  font-weight: 500;
  line-height: 2rem;

  color: ${({ theme }) => theme.colors.primary};

  text-align: center;
`;
