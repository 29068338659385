export const AISelectionExplanatoryVariablesPTTranslate = {
  AISelectionExplanatoryVariablesTitle: 'Projeção de Variáveis Explicativas',
  AISelectionExplanatoryVariablesVariable: 'Variável',
  AISelectionExplanatoryNoVariables:
    'Nenhuma variável explicativa a ser apresentada.',
  AISelectionExplanatoryVariablesLevel: 'Nível',
  AISelectionExplanatoryVariablesVariation: 'Variação',
  AISelectionExplanatoryVariablesFrequency: 'Frequência',
  AISelectionExplanatoryVariablesTransformation: 'Transformação',
  AISelectionExplanatoryVariablesOriginal: 'Original',
  AISelectionExplanatoryVariablesMonthly: 'Mensal',
  AISelectionExplanatoryVariablesQuarterly: 'Trimestral',
  AISelectionExplanatoryVariablesAnnual: 'Anual',
  AISelectionExplanatoryVariablesVariationOriginal:
    'Variação (comparado ao período anterior)',
  AISelectionExplanatoryVariablesVariationMonthlyQuarterly:
    'Variação (comparado ao mesmo período do ano anterior)',
  AISelectionExplanatoryVariablesVariationAnnual:
    'Variação (comparado ao ano anterior)',
  AISelectionExplanatoryVariablesNotEnoughObservationsVariation:
    'Não há observações suficientes para calcular a variação.',
};
