export const favoriteStatusPTTranslate = {
  favoriteStatusAddSerie: 'Adicione uma série',
  favoriteStatusSaveSerie: 'Salve suas séries aqui',
  favoriteStatusEmptyTitle: 'Seu grupo ainda está vazio',
  favoriteStatusNonexistentTitle: 'Você não possui nenhum favorito',
  favoriteStatusEmptyMessage:
    'Navegue pela plataforma e descubra novas séries para salvar aqui.',
  favoriteStatusNonexistentMessage:
    'Clique no botão e crie um grupo para salvar suas séries favoritas',
  favoriteStatusButtonCreateFavorite: 'Criar novo grupo',
  favoriteStatusButtonAddSerie: 'Procurar Indicadores',
};
