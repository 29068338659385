import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DefaultOptions {
  isLatestDataActive: boolean;
}

export interface PayloadModelExplorerOptions {
  modelSpecifics: {
    arima: DefaultOptions;
  };
}

const initialState: PayloadModelExplorerOptions = {
  modelSpecifics: {
    arima: { isLatestDataActive: true },
  },
};

type ModelExplorer = 'modelSpecifics';
type ModelType = 'arima';

interface ChangeOption<T> {
  selection: ModelExplorer;
  type: ModelType;
  value: T;
}

export const ModelExplorerOptionsState = createSlice({
  name: 'modelExplorerTimeSeries',
  initialState,
  reducers: {
    changeModelExplorerIsLatestDataActive: (
      state,
      action: PayloadAction<ChangeOption<boolean>>,
    ) => {
      state[action.payload.selection][action.payload.type].isLatestDataActive =
        action.payload.value;
    },
    resetModelExplorerOptions: (state) => {
      state.modelSpecifics.arima.isLatestDataActive = true;
    },
  },
});

export const {
  changeModelExplorerIsLatestDataActive,
  resetModelExplorerOptions,
} = ModelExplorerOptionsState.actions;
export default ModelExplorerOptionsState.reducer;
