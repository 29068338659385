import { ContainerWarning } from 'src/models/components/ContainerWarning';
import styled from 'styled-components';

export const ForecastDispersionContainer = styled.div``;

export const ForecastDispersionContainerWarning = styled(ContainerWarning)`
  width: max-content;
`;

export const Options = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;

export const ContentLatestData = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;

  > div {
    > div {
      text-align: right;

      p {
        margin-right: 0px;
      }
    }
  }

  input {
    display: block !important;
    margin-left: auto !important;
  }
`;
