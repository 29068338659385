import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.75rem;
  margin-top: 1.375rem;

  @media (max-width: 600px) {
    align-items: center;
  }

  > div {
    display: flex;
    > span {
      color: ${({ theme }) => theme.colors.gray4};

      font-size: 0.75rem;
      font-weight: 400;
      line-height: 0.813rem;
      opacity: 0.8;
    }
  }
`;

export const NavButton = styled.button`
  color: ${({ theme }) => theme.colors.gray5};
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.375rem;

  &:first-child {
    margin-right: 1.5rem;
  }

  &:last-child {
    margin-left: 1.5rem;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      color: ${({ theme }) => theme.colors.gray3};
    `}
`;

interface PageButtonProps {
  selected?: boolean;
}

export const PageButton = styled.button<PageButtonProps>`
  border-radius: 4px;
  margin: 0 0.188rem;

  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.375rem;

  padding: 0.25rem 0.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  ${({ selected }) =>
    selected
      ? css`
          background-color: ${({ theme }) => `${theme.colors.primary}1F`};
          color: ${({ theme }) => theme.colors.primary};
          font-weight: 700;
        `
      : css`
          color: ${({ theme }) => theme.colors.gray5};
        `};
`;
