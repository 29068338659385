import React from 'react';

import { getUserColor } from 'src/utils/colors/getUserColor';

import { Container, Size } from './styles';

interface AvatarProps {
  email: string;
  size: keyof typeof Size;
  hideEmail?: boolean;
}

export const Avatar: React.FC<AvatarProps> = ({
  email,
  size,
  hideEmail = false,
}) => {
  const background = getUserColor((email.charCodeAt(0) - 97) % 17);

  return (
    <Container
      background={background}
      size={size}
      data-testid={`avatar-container-${email}`}
    >
      <div data-testid={`avatar-first-letter-${email}`}>
        {email.charAt(0).toUpperCase()}
      </div>

      {!hideEmail && <p data-testid={`avatar-email-${email}`}>{email}</p>}
    </Container>
  );
};
