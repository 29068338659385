import React, { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Card } from 'src/components/Card';
import { ArrowLeft, ArrowRight } from 'phosphor-react';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Select } from 'src/components/Select';

import {
  ChartContainer,
  ChartDivisor,
  ChartLine,
  ChartRow,
  ChartSubtitle,
  Container,
} from './styles';
import ChartItem from './ChartItem';
import { VariableImpacts as VariableImpactsData } from '../../ModelExplorer/types';

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

interface VariableImpactsProps {
  data: VariableImpactsData | undefined;
  isLoading: boolean;
  isFetching: boolean;
  isErrored: boolean;
  targetVariable: string;
}

export const VariableImpactsChart: React.FC<VariableImpactsProps> = ({
  data,
  isLoading,
  isFetching,
  isErrored,
  targetVariable,
}) => {
  const { t: translate } = useTranslation();
  const chartLineRef = useRef(null);

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [chartLineWidth, setChartLineWidth] = useState(0);
  const [limit, setLimit] = useState(0);
  const [positiveClass, setPositiveClass] = useState('');

  useEffect(() => {
    data && setPositiveClass(data?.variable_impacts[0].class_name ?? '');
  }, [data]);

  const positiveClassIndex =
    data?.variable_impacts.findIndex(
      (classes) => classes.class_name === positiveClass,
    ) === -1
      ? 0
      : data?.variable_impacts.findIndex(
          (classes) => classes.class_name === positiveClass,
        ) ?? 0;

  useEffect(() => {
    let scaleLimit = 0;

    if (data) {
      for (
        let i = 0;
        i < data.variable_impacts[positiveClassIndex].values.length;
        i++
      ) {
        const { positive, negative } =
          data.variable_impacts[positiveClassIndex].values[i];

        const valuesComparison =
          Math.abs(positive) > Math.abs(negative)
            ? Math.abs(positive)
            : Math.abs(negative);
        scaleLimit =
          valuesComparison > scaleLimit ? valuesComparison : scaleLimit;
      }
    }

    setLimit(scaleLimit * 1.3);
  }, [data, positiveClassIndex]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (chartLineRef.current) {
      // @ts-expect-error typescript duvida de mim.
      const widthInPixels = chartLineRef.current.offsetWidth;

      setChartLineWidth(widthInPixels);
    }
  }, [windowSize, data]);

  return (
    <Container className="containerLinear">
      <Card
        textCard={translate('variableImpactsTitle')}
        textDescription={translate('variableImpactsDescription')}
      />
      {isErrored ? (
        <ContainerMaintenance
          content="chart"
          text={translate('variableImpactsErrorMessage')}
          data-testid="variable-impacts-fail"
        />
      ) : isFetching || isLoading || !data ? (
        <ContainerSkeleton data-testid="variable-impacts-loading" />
      ) : (
        data && (
          <>
            <Select
              label={translate('variableImpactsSelectLabel')}
              value={{ label: positiveClass, value: positiveClass }}
              onChange={(opt: any) => setPositiveClass(opt.value)}
              options={data.variable_impacts.map((classInfo) => ({
                label: classInfo.class_name,
                value: classInfo.class_name,
              }))}
              style={{ maxWidth: '250px', marginBottom: '16px' }}
              data-testid="var-impacts-positive-class-select"
              data-cy="var-impacts-positive-class-select"
            />
            <ChartContainer
              className="container externo"
              data-testid="variable-impacts-chart"
            >
              <ChartDivisor />
              {data.variable_impacts[positiveClassIndex].values.map(
                (variable, index) =>
                  index < 5 && (
                    <ChartRow key={`${index * 10}`}>
                      <div>
                        <p>{variable.variable_name}</p>
                      </div>
                      <div>
                        <ChartLine
                          ref={chartLineRef}
                          currentWidth={chartLineWidth}
                          orientation="left"
                          percent={(variable.negative / limit) * 100}
                          signal="negative"
                        >
                          <ChartItem
                            signal="negative"
                            index={index}
                            tooltipMessage={translate(
                              (variable.positive / limit) * 100 <
                                (variable.negative / limit) * 100 + 1 &&
                                (variable.positive / limit) * 100 + 1 >
                                  (variable.negative / limit) * 100
                                ? 'variableImpactsVarSameValue'
                                : (variable.positive / limit) * 100 + 1 >
                                  (variable.negative / limit) * 100
                                ? 'variableImpactsVarDecreaseTargetDecreaseTooltipMessage'
                                : 'variableImpactsVarDecreaseTargetIncreaseTooltipMessage',
                            )
                              .replace('"VARIABLE"', variable.variable_name)
                              .replace(
                                '"CLASS"',
                                data.variable_impacts[positiveClassIndex]
                                  .class_name,
                              )
                              .replace('"VALUE"', String(variable.negative))
                              .replace('"TARGET"', targetVariable)}
                          />
                          <div />
                        </ChartLine>
                        <ChartLine
                          orientation="right"
                          currentWidth={chartLineWidth}
                          percent={(variable.positive / limit) * 100}
                          signal="positive"
                        >
                          <ChartItem
                            signal="positive"
                            index={index}
                            tooltipMessage={translate(
                              (variable.positive / limit) * 100 <
                                (variable.negative / limit) * 100 + 1 &&
                                (variable.positive / limit) * 100 + 1 >
                                  (variable.negative / limit) * 100
                                ? 'variableImpactsVarSameValue'
                                : (variable.positive / limit) * 100 >
                                  (variable.negative / limit) * 100 + 1
                                ? 'variableImpactsVarIncreaseTargetIncreaseTooltipMessage'
                                : 'variableImpactsVarIncreaseTargetDecreaseTooltipMessage',
                            )
                              .replace('"VARIABLE"', variable.variable_name)
                              .replace(
                                '"CLASS"',
                                data.variable_impacts[positiveClassIndex]
                                  .class_name,
                              )
                              .replace('"VALUE"', String(variable.positive))
                              .replace('"TARGET"', targetVariable)}
                          />
                          <div />
                        </ChartLine>
                      </div>
                    </ChartRow>
                  ),
              )}
              <ChartRow>
                <ChartSubtitle>
                  <div>
                    <div>
                      <ArrowLeft size="1.25rem" />
                    </div>
                    <p>
                      {translate('variableImpactsDecrease')
                        .replace(
                          '"CLASS"',
                          data.variable_impacts[positiveClassIndex].class_name,
                        )
                        .replace('"TARGET"', targetVariable)}
                    </p>
                  </div>
                  <div>
                    <p>
                      {translate('variableImpactsIncrease')
                        .replace(
                          '"CLASS"',
                          data.variable_impacts[positiveClassIndex].class_name,
                        )
                        .replace('"TARGET"', targetVariable)}
                    </p>
                    <div>
                      <ArrowRight size="1.25rem" />
                    </div>
                  </div>
                  {/* </div> */}
                </ChartSubtitle>
              </ChartRow>
            </ChartContainer>
            {/* <ChartSubtitle lineWidth={chartLineWidth}>
              <div>
                <div>
                  <div>
                    <ArrowLeft size="1.25rem" />
                  </div>
                  <p>
                    {translate('variableImpactsIncrease')
                      .replace(
                        '"CLASS"',
                        data.variable_impacts[positiveClassIndex].class_name,
                      )
                      .replace('"TARGET"', targetVariable)}
                  </p>
                </div>
                <div>
                  <p>
                    {translate('variableImpactsDecrease')
                      .replace(
                        '"CLASS"',
                        data.variable_impacts[positiveClassIndex].class_name,
                      )
                      .replace('"TARGET"', targetVariable)}
                  </p>
                  <div>
                    <ArrowRight size="1.25rem" />
                  </div>
                </div>
              </div>
            </ChartSubtitle> */}
          </>
        )
      )}
    </Container>
  );
};
