import styled, { css } from 'styled-components';

export interface SpinnerProps {
  hasIcon?: boolean;
  spinnerType: 'primary' | 'secondary' | 'naked' | 'tertiary';
}

export const Spinner = styled.div<SpinnerProps>`
  border: 3px solid
    ${({ theme, spinnerType }) =>
      spinnerType === 'primary'
        ? theme.colors.primary
        : spinnerType === 'secondary'
        ? theme.colors.gray3
        : spinnerType === 'tertiary' && theme.colors.gray2};
  border-left-color: ${({ theme, spinnerType }) =>
    spinnerType === 'primary'
      ? theme.colors.gray3
      : (spinnerType === 'secondary' || spinnerType === 'tertiary') &&
        theme.colors.primary};
  border-radius: 50%;
  width: 0.875rem;
  height: 0.875rem;
  animation: spin 0.8s linear infinite;

  ${({ hasIcon }) =>
    hasIcon === true &&
    css`
      margin-right: 0.125rem;
    `}

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;
