import styled, { css } from 'styled-components';

type ContainerProps = {
  disabled?: boolean;
};

export const Container = styled.div<ContainerProps>`
  width: fit-content;
  height: fit-content;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;

  padding: 0.25rem 0.5rem;

  background: ${({ theme }) => `${theme.colors.primary}1F`};
  border-radius: 8px;

  ${({ disabled }) =>
    disabled &&
    css`
      background: ${({ theme }) => theme.colors.gray2};

      cursor: not-allowed;

      p,
      svg {
        color: ${({ theme }) => theme.colors.gray5} !important;
      }
    `}

  p {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 150%;

    color: ${({ theme }) => theme.colors.primary};
  }

  button {
    width: 1rem;
    height: 1rem;

    padding: 0;

    svg {
      width: 1rem;
      height: 1rem;

      margin: 0;

      color: ${({ theme }) => theme.colors.primary};

      transition: all 0.2s;

      &:hover {
        color: ${({ theme }) => theme.colors.primaryDark};
      }
    }
  }

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;

    > svg {
      width: 1rem;
      height: 1rem;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

type CheckboxProps = {
  disabled?: boolean;
};

export const Checkbox = styled.label<CheckboxProps>`
  display: flex;
  align-items: center;
  padding-left: 0.75rem;
  position: relative;

  user-select: none;

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  input {
    display: none;
  }

  span {
    display: inline-block;
    width: 1.125rem;
    height: 1.125rem;
    background: white;
    position: absolute;
    left: 0;
    border: ${({ theme }) => `1px solid ${theme.colors.primary}`};
    border-radius: 4px;
  }

  input:checked + span {
    background-color: ${({ theme }) => theme.colors.primary};
    border: ${({ theme }) => `2px solid ${theme.colors.primary}`};
    border-radius: 4px;
  }

  input:indeterminate + span {
    background-color: #1390e5;
    border: ${({ theme }) => `2px solid ${theme.colors.gray8}`};
    border-radius: 4px;
  }

  input:checked + span:after {
    content: '';
    position: absolute;
    height: 0.313rem;
    width: 0.5rem;
    border-left: ${({ theme }) => `3px solid ${theme.colors.white}`};
    border-bottom: ${({ theme }) => `3px solid ${theme.colors.white}`};
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  input:checked:disabled + span {
    border: 1px solid grey;
    background-color: grey;
  }

  input:disabled + span {
    border: 1px solid grey;
  }

  input:indeterminate + span:after {
    content: '';
    position: absolute;
    height: 0px;
    width: 0.688rem;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(180deg);
  }
`;
