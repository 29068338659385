import React from 'react';

import { Calendar, ChartLine, Clock, CurrencyDollar } from 'phosphor-react';

import { DefinedFilterOptions, SelectedFiltersProps } from './types';
import { Container, Content } from './styles';

export const SelectedFilters: React.FC<SelectedFiltersProps> = ({
  filters,
  ...rest
}) => {
  const getIcon = (type: DefinedFilterOptions) => {
    if (type === 'frequency') {
      return <Calendar data-testid="calendar-icon" />;
    }
    if (type === 'transformation') {
      return <ChartLine data-testid="chart-line-icon" />;
    }
    if (type === 'inflation') {
      return <CurrencyDollar data-testid="currency-dollar-icon" />;
    }
    return <Clock data-testid="clock-icon" />;
  };

  return (
    <Container {...rest}>
      {filters.map((filter) => (
        <Content
          key={filter?.id ?? filter.type}
          data-testid={`selected-${filter?.id ?? filter.type}`}
        >
          {filter.type === 'other' ? filter.icon : getIcon(filter.type)}
          <p>{filter.selected}</p>
        </Content>
      ))}
    </Container>
  );
};
