import styled from 'styled-components';

export const ContainerLoadingModalRegisterEditCustomer = styled.div``;

interface LoadingInput {
  heightDiv?: string;
  widthDiv?: string;
}
export const LoadingInput = styled.div<LoadingInput>`
  p {
    font-weight: 500;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.gray5};
    margin-bottom: 0.5rem;
  }
  > div {
    height: ${({ heightDiv }) => heightDiv || '2.625rem'};
    width: ${({ widthDiv }) => widthDiv || '100%'};
  }
`;
