import React, { useState } from 'react';

import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { ErrorObject } from 'src/components/Modal/Failed';
import { DataError } from 'src/interface/axios';
import api from 'src/models/service/api';
import { queryClient } from 'src/service/queryClient';
import { ConfirmDeletionModal } from 'src/components/Modal/ConfirmDeletion';

interface DeleteProjectModalProps {
  project: {
    id: string;
    name: string;
    type: string;
  };
  visible: boolean;
  setVisible: (value: boolean) => void;
  setError: (value: ErrorObject) => void;
}

export const DeleteProjectModal: React.FC<DeleteProjectModalProps> = ({
  project,
  visible,
  setVisible,
  setError,
}) => {
  const [loading, setLoading] = useState(false);
  const { t: translate } = useTranslation();

  if (!project) {
    return null;
  }

  async function handleDeleteProject() {
    setLoading(true);
    try {
      await api.delete(
        project.type === 'classification'
          ? `/classification/projects/${project.id}`
          : `/projects/${project.id}`,
      );
      await queryClient.invalidateQueries('projects');
      queryClient.refetchQueries('projects');
    } catch (err) {
      const error: AxiosError<DataError> | any = err;
      const errorMessage =
        error?.response?.data?.detail?.detail ??
        error?.response?.data?.detail?.description ??
        translate('exclusionDeleteError');

      setError({
        title: translate('exclusionDeleteRequestFailed'),
        description: errorMessage,
      });
    }
    setLoading(false);
    setVisible(false);
  }

  return (
    <ConfirmDeletionModal
      visible={visible}
      setVisible={setVisible}
      textToConfirm={project.name}
      description={translate('exclusionDescription')}
      errorMessage={translate('exclusionDeleteButtonWrongNameError')}
      isLoading={loading}
      handleDelete={handleDeleteProject}
    />
  );
};
