import React from 'react';

import { useSelector } from 'react-redux';
import EnglishExample from 'src/assets/english-filter-example.svg';
import PortugueseExample from 'src/assets/portuguese-filter-example.svg';
import { RootState } from 'src/redux/store';

import { StepContent } from '../styles';

export const Step1: React.FC = () => {
  const { language } = useSelector((state: RootState) => state.auth.user);

  return (
    <StepContent data-testid="tutorial-step-1">
      <img
        src={language === 'en-us' ? EnglishExample : PortugueseExample}
        alt="filter example"
      />
    </StepContent>
  );
};
