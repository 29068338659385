import styled, { css } from 'styled-components';

interface ContainerProps {
  hasErrorOnInputs: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 18.75rem;
  max-height: 70vh;
  overflow-y: auto;

  ${({ hasErrorOnInputs }) =>
    hasErrorOnInputs
      ? css`
          @media (max-height: 982px) {
            padding-right: 1rem;
            overflow-y: scroll;
          }
        `
      : css`
          @media (max-height: 879px) {
            padding-right: 1rem;
          }
        `}

  h4 {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2rem;
    color: ${({ theme }) => theme.colors.gray6};
    margin-bottom: 1.5rem;
  }
  > div {
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

interface ContentProducts {
  disabled: boolean;
}

export const ContentProducts = styled.div<ContentProducts>`
  display: flex;
  flex-direction: column;

  > p {
    font-weight: 500;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.gray5};
    margin-bottom: 0.5rem;
  }

  > div {
    margin-bottom: 0.75rem;
    > label {
      height: 1.25rem;
    }

    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

export const ContentRadioButtons = styled.div`
  margin-top: 0.5rem;
  margin-left: 1.75rem;

  label:first-child {
    margin-bottom: 0.25rem;
  }

  p {
    font-size: 0.875rem;
  }
`;

export const DivNewManager = styled.div`
  p {
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
  }
`;

export const ButtonAddManager = styled.button`
  display: flex;
  align-items: flex-start;

  p {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const ErrorProducts = styled.div`
  p {
    color: #ff6b6b !important;
    line-height: 1rem;
    font-style: normal;
    font-size: 0.875rem;
  }
`;
