import { Button } from 'src/components/Button';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.5rem;
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  max-width: 80rem;
`;

export const ContentTable = styled.div`
  tbody {
    td:last-child {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
    }
  }

  @media (max-width: 700px) {
    overflow-x: scroll;
    overflow-y: hidden;
  }
`;

export const ButtonCreateNewCustomer = styled(Button)`
  position: absolute;
  top: 2.5rem;
  right: 1.5rem;
`;

export const ButtonIcon = styled.button`
  float: right;
  color: ${({ theme }) => theme.colors.gray5};
  transition: 0.2s color;
  &:hover {
    color: ${({ theme }) => theme.colors.gray6};
  }
`;
