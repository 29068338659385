import React from 'react';

import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import PortugueseImg from 'src/assets/portuguese-step-5.svg';
import EnglishImg from 'src/assets/english-step-5.svg';

import { StepContent } from '../styles';

export const Step5: React.FC = () => {
  const { language } = useSelector((state: RootState) => state.auth.user);

  return (
    <StepContent data-testid="tutorial-step-5">
      <img
        src={language === 'en-us' ? EnglishImg : PortugueseImg}
        alt="Create your filter"
        style={{ marginTop: '0.5rem', width: '100%' }}
      />
    </StepContent>
  );
};
