const ShareUpdateModalPTBRTranslations = {
  shareUpdateTitle: 'Compartilhar atualizações',
  shareUpdateDescription:
    'Compartilhe atualizações do seu projeto com usuários.',
  shareUpdateStatusFinished: 'Finalizado',
  shareUpdateStatusError: 'Erro',
  shareUpdateStatusCopying: 'Em andamento',
  shareUpdateStatusNotSent: 'Não compartilhado',
  shareUpdateTableUsers: 'Usuários',
  shareUpdateTableStatus: 'Status de compartilhamento',
  shareUpdateSendUpdateButton: 'Compartilhar',
  shareUpdateErrorTitle: 'Falha ao compartilhar o Update',
  shareUpdateErrorDescription:
    'Não foi possível compartilhar o update do projeto, caso o problema persista, favor entrar em contato com o suporte.',
  shareUpdateNoSharesDescription:
    'Não há compartilhamento com nenhum usuário ainda, compartilhe fazendo uma solicitação no service desk.',
};

export default ShareUpdateModalPTBRTranslations;
