import styled from 'styled-components';

export const Container = styled.div`
  width: 70vw;
  height: 80vh;
  max-height: 43rem;
  max-width: 62.25rem;
  margin-bottom: -1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  section {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.gray8};
  }
`;

export const Content = styled.div`
  overflow-y: auto;
  padding-bottom: 1.5rem;
`;

export const DescriptionSearch = styled.div`
  display: flex;
  justify-content: space-between;
  div {
    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 2rem;
      color: ${({ theme }) => theme.colors.gray6};
    }
    p {
      margin: 0.5rem 0;
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 150%;
      color: ${({ theme }) => theme.colors.gray5};
    }
  }
`;

export const ContainerWithoutGroups = styled.div`
  width: 100%;
  height: 37.188rem;
  padding: 1.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GroupsContainer = styled.div`
  min-height: 31.25rem;
  padding: 1rem 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 1rem;
  @media (max-width: 1500px) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 1rem;
  }
  @media (max-width: 1366px) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 1rem;
  }
`;

export const GroupCardLoading = styled.div`
  display: flex;
  flex: 0 0 17.5rem;
  align-items: flex-start;
  flex-direction: column;
  padding: 0.875rem 0.875rem 1.5rem;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  box-sizing: border-box;
  border-radius: 8px;
  position: relative;
  max-height: 15.625rem;

  img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 4px;
  }

  span {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray6};
    margin-top: 1rem;
  }

  p {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray7};
    margin-top: 0.5rem;
  }
`;

export const DivPagination = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: auto;
  padding: 1.25rem 1.25rem;
  margin-bottom: 1.25rem;
`;

export const CardCreateGroupContainer = styled.div`
  display: flex;
  flex: 0 0 19.688rem;
  height: 18.375rem;
  position: relative;

  @media (max-width: 1440px) {
    flex: 0 0 18.75rem;
    width: 18.75rem;
  }

  @media (max-width: 1350px) {
    flex: 0 0 17.5rem;
    width: 17.5rem;
  }

  @media (max-width: 1259px) {
    flex: 0 0 17.5rem;
  }

  @media (max-width: 837px) {
    max-width: 100%;
  }
`;

type CardCreateGroupContentProps = {
  allowed?: boolean;
};

export const CardCreateGroupContent = styled.div<CardCreateGroupContentProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  border: 1px solid ${({ theme }) => theme.colors.gray2};
  box-sizing: border-box;
  border-radius: 8px;

  cursor: ${({ allowed }) => (allowed ? 'pointer' : 'not-allowed')};

  min-height: 17.75rem;
  max-height: 17.75rem;
  width: 100%;

  svg {
    color: ${({ theme }) => theme.colors.gray2};
  }

  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray5};
    margin: 0.75rem 0 0.25rem;
  }

  p {
    width: 75%;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray4};
    text-align: center;
  }
`;
