import React, { useState } from 'react';

import Maintenance from 'src/assets/maintenance.svg';
import { Modal } from 'src/components/Modal';
import { Table, Tbody, Td, Th, Thead, Tr } from 'src/components/Table';
import { Trash } from 'phosphor-react';
import { Controller, useForm } from 'react-hook-form';
import { Input } from 'src/components/Input';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Button } from 'src/components/Button';
import { ToggleSwitch } from 'src/components/ToggleSwitch';
import { apiAdminV2 } from 'src/service/apiAdminV2';
import { useQuery } from 'react-query';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { AxiosError } from 'axios';
import { DataError } from 'src/interface/axios';
import { queryClient } from 'src/service/queryClient';
import { WarningModal } from 'src/components/Modal/Warning';
import { ModalFooter } from 'src/components/Modal/Footer/styles';

import {
  ButtonAddManager,
  Container,
  ContainerError,
  ContentTable,
} from './styles';
import { Customer } from '../..';
import { ModalDeleteManager } from './components/ModalDeleteManager';

interface FormRegisterManager {
  managerEmail: string;
  managerId?: string;
  accessToModule?: boolean;
  accessToModuleNewManager?: boolean;
}

interface ResponseFromUserProps {
  limit: number;
  skip: number;
  total: number;
  records: UsersFromClientProps[];
}

interface UsersFromClientProps {
  client_id: string;
  created_at: string;
  discount_license: boolean;
  email: string;
  id: string;
  is_blocked: false;
  role: string;
  updated_at: string;
}

interface ManagerToEdit {
  id: string;
  value: boolean;
}

interface ManagerModalProps {
  visible: boolean;
  closeModalManager: () => void;
  idClient: string;
  customer: Customer | null;
}

interface InfoFailedRegisterNewManager {
  title: string;
  description: string;
}
export const ModalManager: React.FC<ManagerModalProps> = ({
  visible,
  closeModalManager,
  idClient,
  customer,
}) => {
  const [managerVisible, setManagerVisible] = useState<boolean>(false);
  const [managerToEdit, setManagerToEdit] = useState<ManagerToEdit[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalDeleteConfirm, setModalDeleteConfirm] = useState<boolean>(false);
  const [managerToDelete, setManagerToDelete] = useState<string>('');
  const [licenses, setLicenses] = useState<number>(
    customer?.remaining_licenses ?? 0,
  );

  const [
    infoFailedToRegisterOrEditManager,
    setInfoFailedToRegisterOrEditManager,
  ] = useState<InfoFailedRegisterNewManager>();
  const { t: translate } = useTranslation();

  const schema = Yup.object().shape({
    managerEmail: managerVisible
      ? Yup.string()
          .trim()
          .email('modalRegisterNewCustomerAdminValidEmail')
          .required('requiredField')
      : Yup.string().trim().email('modalRegisterNewCustomerAdminValidEmail'),
    accessToModuleNewManager: Yup.boolean(),
  });

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<FormRegisterManager>({
    resolver: yupResolver(schema),
  });

  const { data, isLoading, isFetching, isError } =
    useQuery<ResponseFromUserProps>(['manager-list'], async () => {
      const response = await apiAdminV2.get(
        `/clients/${idClient}/users?role=manager`,
      );
      return response.data;
    });

  const handleRegisterNewManager = async ({
    managerEmail,
    accessToModuleNewManager,
  }: FormRegisterManager) => {
    setLoading(true);
    if (managerToEdit) {
      try {
        for (let i = 0; i < managerToEdit.length; i++) {
          await apiAdminV2.put(
            `/clients/${idClient}/users/${managerToEdit[i].id}/discount-license`,
            {
              discount_license: managerToEdit[i].value,
            },
          );
        }
        queryClient.removeQueries('manager-list');
        queryClient.removeQueries('customers-list');
        setLoading(false);
        closeModalManager();
      } catch (err) {
        const error = err as AxiosError<DataError>;
        if (error?.response?.status === 409) {
          setInfoFailedToRegisterOrEditManager({
            title: 'Warning',
            description: translate('modalWarningManagerMessage'),
          });
          setLoading(false);
        } else {
          setInfoFailedToRegisterOrEditManager({
            title: translate('requestFailed'),
            description:
              error.response?.data?.detail?.description ??
              translate('modalManagerErrorEditManager'),
          });
          setLoading(false);
        }
      }
    }

    if (managerVisible && managerEmail) {
      try {
        await apiAdminV2.post(`/clients/${idClient}/managers`, {
          email: managerEmail,
          discount_license: accessToModuleNewManager,
        });

        queryClient.removeQueries('manager-list');
        queryClient.removeQueries('customers-list');
        setLoading(false);
        closeModalManager();
      } catch (err) {
        const error = err as AxiosError<DataError>;
        if (error?.response?.status === 409) {
          const managersData = data?.records.find(
            (managerData) => managerData.email === managerEmail,
          );
          try {
            await apiAdminV2.put(
              `/clients/${idClient}/users/${managersData?.id}/is-blocked`,
              {
                is_blocked: false,
              },
            );
            queryClient.removeQueries('manager-list');
            queryClient.removeQueries('customers-list');
            setLoading(false);
            closeModalManager();
          } catch (er) {
            setLoading(false);
            setInfoFailedToRegisterOrEditManager({
              title: translate('modalManagerErrorAddNewManagerTitle'),
              description: translate(
                'modalManagerErrorAddNewManagerDescription',
              ),
            });
          }
          setLoading(false);
        }
      }
    }
  };

  const handleManagerLicenses = (value: boolean, id: string) => {
    if (
      data?.records.some(
        (users) => users.id === id && value !== users.discount_license,
      )
    ) {
      setManagerToEdit((state) => [
        ...state,
        {
          id,
          value,
        },
      ]);
    } else {
      setManagerToEdit((state) => {
        const stateAux = [...state];
        return stateAux.filter((manager) => manager.id !== id);
      });
    }
  };

  const verifyLicenses = (value: any) => {
    setLicenses((state) => (value ? state - 1 : state + 1));
  };

  const handleRemoveManager = () => {
    setManagerVisible(false);
    setValue('managerEmail', '');
    const discount = getValues('accessToModuleNewManager');
    if (discount) {
      setValue('accessToModuleNewManager', false);
      setLicenses(licenses + 1);
    }
  };

  return (
    <Modal visible={visible} setVisible={closeModalManager}>
      <Container>
        <h4>{translate('modalManagerEditManager')}</h4>
        <h5>{translate('modalManager')}</h5>
        {isError ? (
          <ContainerError>
            <img src={Maintenance} alt="image_maintenance" />
            <span>{translate('modalManagerErrorLoadManagers')}</span>
          </ContainerError>
        ) : (
          <ContentTable>
            <Table>
              <Thead>
                <Tr>
                  <Th style={{ width: '20%' }}>E-mail</Th>
                  <Th>{translate('modalManagerProductsAccess')}</Th>
                  <Th>{translate('modalManagerRemoveManager')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {isLoading || isFetching || !data ? (
                  Array.from({ length: 3 }, (_, index) => (
                    // eslint-disable-next-line react/jsx-indent
                    <Tr
                      key={`tr-loading-${index}`}
                      data-testid={`tr-loading-${index}`}
                    >
                      <Td>
                        <ContainerSkeleton
                          withLoading={false}
                          style={{
                            width: '180px',
                            height: '15px',
                          }}
                        />
                      </Td>
                      <Td>
                        <ContainerSkeleton
                          withLoading={false}
                          style={{
                            width: '30%',
                            height: '15px',
                          }}
                        />
                      </Td>
                      <Td>
                        <ContainerSkeleton
                          withLoading={false}
                          style={{
                            width: '20%',
                            height: '15px',
                          }}
                        />
                      </Td>
                    </Tr>
                  ))
                ) : (
                  <>
                    {data.records.map(
                      (managers) =>
                        !managers.is_blocked &&
                        managers.role === 'manager' && (
                          // eslint-disable-next-line react/jsx-indent
                          <Tr key={managers.id} data-testid={managers.email}>
                            <Td style={{ width: '40%' }}>{managers.email}</Td>
                            <Td>
                              <ToggleSwitch
                                data-testid={`toggle-switch-manager-access-${managers.email}`}
                                style={{ marginTop: '10px' }}
                                onChange={(val) => {
                                  verifyLicenses(val.target.checked);
                                  handleManagerLicenses(
                                    val.target.checked,
                                    managers.id,
                                  );
                                }}
                                checked={
                                  managerToEdit.find(
                                    (edit) => edit.id === managers.id,
                                  )?.value ?? managers.discount_license
                                }
                                data-cy={`toggle-switch-manager-access-${managers.email}`}
                                useYesOrNo
                                disabled={loading}
                              />
                            </Td>
                            <Td>
                              <Trash
                                size="1.25rem"
                                data-testid={`delete-manager-${managers.email}`}
                                onClick={() => {
                                  setModalDeleteConfirm(true);
                                  setManagerToDelete(managers.id);
                                }}
                                style={{ cursor: 'pointer' }}
                              />
                            </Td>
                          </Tr>
                        ),
                    )}
                  </>
                )}
              </Tbody>
            </Table>
          </ContentTable>
        )}

        {!managerVisible && (
          <ButtonAddManager
            type="button"
            onClick={() => {
              setManagerVisible(true);
            }}
            data-testid="buttonAddManager"
          >
            <p>{translate('modalManagerAddManager')}</p>
          </ButtonAddManager>
        )}

        {managerVisible && (
          <>
            <Controller
              name="managerEmail"
              key="managerEmail"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  label={translate('modalRegisterNewCustomerUserManager')}
                  placeholder={translate(
                    'modalRegisterNewCustomerPlaceholderManagerUser',
                  )}
                  style={{ marginTop: '20px' }}
                  data-testid="input-new-manager"
                  // @ts-expect-error bug typescript
                  error={translate(errors.managerEmail?.message)}
                  onChange={onChange}
                  value={value}
                  disabled={loading}
                />
              )}
            />

            <Controller
              name="accessToModuleNewManager"
              key="accessToModuleNewManager"
              control={control}
              render={({ field: { onChange, value } }) => (
                <ToggleSwitch
                  data-testid="toggle-switch-manager-access"
                  label={translate('modalRegisterNewCustomerManagerWithAccess')}
                  style={{ marginTop: '10px' }}
                  onChange={(val) => {
                    onChange(val);
                    verifyLicenses(val.target.checked);
                  }}
                  checked={value}
                  data-cy="toggle-switch-manager-access"
                  useYesOrNo
                />
              )}
            />
          </>
        )}

        {managerVisible && (
          <ButtonAddManager type="button" onClick={() => handleRemoveManager()}>
            <p>{translate('modalManagerAddManager')}</p>
          </ButtonAddManager>
        )}
      </Container>
      <ModalFooter>
        <Button
          buttonType="naked"
          onClick={() => closeModalManager()}
          disabled={loading}
          data-testid="button-cancel"
        >
          {translate('modalRegisterNewCustomerButtonCancel')}
        </Button>
        <Button
          buttonType="primary"
          onClick={handleSubmit(handleRegisterNewManager)}
          loading={loading}
          disabled={licenses < 0 || loading}
          data-testid="button-confirm"
          data-cy="button-confirm"
        >
          {translate('confirm')}
        </Button>
      </ModalFooter>

      {infoFailedToRegisterOrEditManager && (
        <WarningModal
          visible={!!infoFailedToRegisterOrEditManager}
          setVisible={(value) => {
            value === false && setInfoFailedToRegisterOrEditManager(undefined);
          }}
          errorInfo={infoFailedToRegisterOrEditManager}
        />
      )}

      {modalDeleteConfirm && (
        <ModalDeleteManager
          setVisible={setModalDeleteConfirm}
          visible={modalDeleteConfirm}
          managerToDelete={managerToDelete}
          idClient={idClient}
          closeModalManager={closeModalManager}
        />
      )}
    </Modal>
  );
};
