import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Card } from 'src/components/Card';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import api from 'src/models/service/api';
import { useTranslation } from 'react-i18next';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { AxiosError } from 'axios';
import { AISelectionContext } from 'src/models/contexts/AISelectionContext';
import { translateSomeMessagesFromBackend } from 'src/i18n';
import { ToggleSwitch } from 'src/components/ToggleSwitch';
import {
  AIUserSelectionFrequency,
  AIUserSelectionTransformation,
  changeAIUserSelectionResultsFrequency,
  changeAIUserSelectionResultsIsLatestDataActive,
  changeAIUserSelectionResultsTransformations,
} from 'src/models/redux/reducers/AIUserSelectionOptions';
import {
  frequencyLatestData,
  getLatestData,
} from 'src/utils/charts/getLatestData';
import { sleep } from 'src/utils/sleep';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';

import { ChartHeader, ContentLatestData, ResultContainer } from './styles';
import { ResultsChart } from './ResultsChart';
import { ResultsOptions } from './ResultsOptions';
import { ResultsUnderstanding } from './ResultsUnderstanding';

interface Error {
  detail?: {
    description?: string;
    detail?: string;
  };
}

interface XSelected {
  x: string;
  date: string;
}

interface Chart {
  date: (string | number)[];
  value: number[];
  aggregation_count: number[];
}

interface ResponseResult {
  forecast: Chart;
  historical: Chart;
  simulated: Chart;
}

interface AISelectionResultsContextTypes {
  isYFrequencyValid: boolean;
  xSelected: XSelected | null;
  isLoadingChart: boolean;
  dataLevel: ResponseResult | undefined;
  dataVariation: ResponseResult | undefined;
  isLatestDataDisabled: boolean;
  canSelectForecast: boolean;
  selectX: (x: string) => void;
  selectFrequency: (frequency: AIUserSelectionFrequency) => void;
  selectTransformation: (
    Transformations: AIUserSelectionTransformation,
  ) => void;
}

export const AISelectionResultsContext = createContext(
  {} as AISelectionResultsContextTypes,
);

export const Results: React.FC = () => {
  const [xSelected, setXSelected] = useState<XSelected | null>(null);

  const {
    auth: { user },
    project: { id, selectedY, appInfo },
    AIUserSelectionOptions: {
      AISelection: {
        results: { frequency, isLatestDataActive, transformations },
      },
    },
  } = useSelector((state: RootState) => state);

  const [chartFrequency, setChartFrequency] =
    useState<AIUserSelectionFrequency>(frequency);
  const [chartIsLatestDataActive, setChartIsLatestDataActive] =
    useState(isLatestDataActive);

  const { listOfVariables, isLoadingListOfVariables, errorListOfVariables } =
    useContext(AISelectionContext);

  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      await sleep(50);
      setChartFrequency(frequency);
    })();
  }, [frequency]);

  useEffect(() => {
    (async () => {
      await sleep(50);
      setChartIsLatestDataActive(isLatestDataActive);
    })();
  }, [isLatestDataActive]);

  const isYFrequencyValid = useMemo(() => {
    if (!selectedY?.info?.frequency) {
      return false;
    }

    const yFrequency = selectedY?.info?.frequency;

    if (frequency === 'original') {
      const frequencyAvailableForOriginal = [
        'monthly',
        'bimonthly',
        'quarterly',
        'half-year',
        'annual',
      ];

      return frequencyAvailableForOriginal.includes(yFrequency);
    }

    return yFrequency === 'monthly';
  }, [frequency, selectedY?.info?.frequency]);

  const {
    data: dataVariation,
    isLoading: isLoadingVariation,
    error: errorVariation,
  } = useQuery<ResponseResult, AxiosError<Error>>(
    ['AI-selection', 'results', id, selectedY?.id, frequency, 'variation'],
    async () => {
      const response = await api.get<ResponseResult>(
        `/projects/${id}/${selectedY?.id}/models/business/variables/${listOfVariables?.y}?frequency=${frequency}&transformation=variation`,
      );

      const historical = response.data.historical;
      const forecast = response.data.forecast;

      if (forecast.date.length && forecast.value.length) {
        if (historical.date.length && historical.date.length) {
          forecast.date = [
            historical.date[historical.date.length - 1],
            ...forecast.date,
          ];

          forecast.value = [
            historical.value[historical.value.length - 1],
            ...forecast.value,
          ];

          forecast.aggregation_count = [
            historical.aggregation_count[
              historical.aggregation_count.length - 1
            ],
            ...forecast.aggregation_count,
          ];
        }
      }

      return {
        historical,
        forecast,
        simulated: response.data.simulated,
      };
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled: !!id && !!selectedY?.id && !!listOfVariables,
    },
  );

  const {
    data: dataLevel,
    isLoading: isLoadingLevel,
    error: errorLevel,
  } = useQuery<ResponseResult, AxiosError<Error>>(
    ['AI-selection', 'results', id, selectedY?.id, frequency, 'level'],
    async () => {
      const response = await api.get<ResponseResult>(
        `/projects/${id}/${selectedY?.id}/models/business/variables/${listOfVariables?.y}?frequency=${frequency}&transformation=level`,
      );

      const historical = response.data.historical;
      const forecast = response.data.forecast;

      return {
        historical,
        forecast,
        simulated: response.data.simulated,
      };
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled: !!id && !!selectedY?.id && !!listOfVariables,
    },
  );

  const isLoadingChart = useMemo(
    () =>
      isLoadingLevel ||
      isLoadingVariation ||
      isLoadingListOfVariables ||
      (!dataLevel && !dataVariation),
    [
      dataLevel,
      dataVariation,
      isLoadingLevel,
      isLoadingListOfVariables,
      isLoadingVariation,
    ],
  );

  useEffect(() => {
    function checkIfTransformationIsAvailable() {
      const availableTransformation: AIUserSelectionTransformation[] = [
        ...transformations,
      ];
      if (!isLoadingChart) {
        if (!dataLevel && availableTransformation.includes('Level')) {
          const index = availableTransformation.findIndex(
            (transformation) => transformation === 'Level',
          );
          availableTransformation.splice(index, 1);
        }
        if (!dataVariation && availableTransformation.includes('Variation')) {
          const index = availableTransformation.findIndex(
            (transformation) => transformation === 'Variation',
          );
          availableTransformation.splice(index, 1);
        }

        if (availableTransformation.length !== transformations.length) {
          dispatch(
            changeAIUserSelectionResultsTransformations({
              selection: 'AISelection',
              value: availableTransformation,
            }),
          );
        }
      }
    }

    checkIfTransformationIsAvailable();
  }, [dataLevel, dataVariation, dispatch, isLoadingChart, transformations]);

  const adjustDateToXSelected = useCallback(
    (x: string): string => {
      let date = '';

      if (frequency === 'quarterly') {
        const [year, quarterly] = x.split(' Q');
        const month = String(Number(quarterly) * 3).padStart(2, '0');
        date = `${year}-${month}-01`;
      } else if (frequency === 'yearly') {
        const year = x;
        date = `${year}-12-01`;
      } else {
        date = x;
      }

      return date;
    },
    [frequency],
  );

  const selectX = useCallback(
    (x: string) => {
      if (
        isYFrequencyValid &&
        (dataLevel?.historical.date.some((date) => date === x) ||
          dataLevel?.forecast.date.some((date) => date === x))
      ) {
        setXSelected({ x, date: adjustDateToXSelected(x) });
      }
    },
    [adjustDateToXSelected, dataLevel, isYFrequencyValid],
  );

  useEffect(() => {
    function setDefaultXAndYSelected() {
      if (dataLevel) {
        const lastIndex = dataLevel.historical.date.length - 1;

        const x = dataLevel.historical.date[lastIndex] as string;

        setXSelected({
          x,
          date: adjustDateToXSelected(x),
        });
      }
    }

    if (isYFrequencyValid) {
      setDefaultXAndYSelected();
    }
  }, [adjustDateToXSelected, dataLevel, isYFrequencyValid]);

  const isLatestDataDisabled = useMemo(() => {
    const total =
      (dataLevel?.historical.value.length ?? 0) +
      (dataLevel?.forecast.value.length ?? 0);

    if (frequency === 'original') {
      return (
        total <=
        frequencyLatestData[
          selectedY?.info?.frequency as keyof typeof frequencyLatestData
        ]
      );
    }
    if (frequency === 'monthly') {
      return total <= frequencyLatestData.monthly;
    }
    if (frequency === 'quarterly') {
      return total <= frequencyLatestData.quarterly;
    }

    if (frequency === 'yearly') {
      return total <= frequencyLatestData.annual;
    }

    return true;
  }, [
    dataLevel?.forecast.value.length,
    dataLevel?.historical.value.length,
    frequency,
    selectedY?.info?.frequency,
  ]);

  const error = useMemo(
    () => (!!errorLevel && !!errorVariation) || errorListOfVariables,
    [errorLevel, errorListOfVariables, errorVariation],
  );

  useEffect(() => {
    if ((!isLoadingChart && isLatestDataDisabled) || error) {
      dispatch(
        changeAIUserSelectionResultsIsLatestDataActive({
          selection: 'AISelection',
          value: false,
        }),
      );
    }
  }, [dispatch, error, isLatestDataDisabled, isLoadingChart]);

  function selectFrequency(frequencyAux: AIUserSelectionFrequency) {
    if (frequency !== frequencyAux) {
      dispatch(
        changeAIUserSelectionResultsFrequency({
          selection: 'AISelection',
          value: frequencyAux,
        }),
      );
    }
  }

  function selectTransformation(
    transformationAux: AIUserSelectionTransformation,
  ) {
    if (transformations.includes(transformationAux)) {
      dispatch(
        changeAIUserSelectionResultsTransformations({
          selection: 'AISelection',
          value: transformations.filter(
            (transformation) => transformation !== transformationAux,
          ),
        }),
      );

      return;
    }

    dispatch(
      changeAIUserSelectionResultsTransformations({
        selection: 'AISelection',
        value: [...transformations, transformationAux],
      }),
    );
  }

  function handleActiveLatestData(value: boolean) {
    dispatch(
      changeAIUserSelectionResultsIsLatestDataActive({
        selection: 'AISelection',
        value,
      }),
    );
  }

  function returnMessageError() {
    let messageError = errorLevel?.response?.data?.detail?.detail;

    if (
      messageError ===
        'Annual series summary is only available for series with at least 1 year of observation.' ||
      messageError ===
        'Quarterly series summary is only available for series with at least 1 quarter of observation.' ||
      messageError ===
        'Monthly aggregation is not available for dataset with missing values.'
    ) {
      return messageError;
    }

    messageError =
      errorListOfVariables?.response?.data?.detail?.description ??
      errorLevel?.response?.data?.detail?.description ??
      errorVariation?.response?.data?.detail?.description;

    if (
      messageError === 'No data is available.' ||
      messageError === 'The requested resource does not exist.'
    ) {
      if (frequency === 'quarterly') {
        return 'Quarterly Series Rate is not available for this model';
      }
      if (frequency === 'yearly') {
        return 'Annual Series Rate is not available for this model';
      }
    }

    return messageError;
  }

  const { dataLevelAdjusted, dataVariationAdjusted } = useMemo(() => {
    let dataLevelAdjustedAux: ResponseResult = {
      historical: {
        date: [],
        value: [],
        aggregation_count: [],
      },
      forecast: {
        date: [],
        value: [],
        aggregation_count: [],
      },
      simulated: {
        date: [],
        value: [],
        aggregation_count: [],
      },
    };

    let dataVariationAdjustedAux: ResponseResult = {
      historical: {
        date: [],
        value: [],
        aggregation_count: [],
      },
      forecast: {
        date: [],
        value: [],
        aggregation_count: [],
      },
      simulated: {
        date: [],
        value: [],
        aggregation_count: [],
      },
    };

    if (!isLatestDataActive) {
      if (dataLevel) {
        dataLevelAdjustedAux = dataLevel;
      }
      if (dataVariation) {
        dataVariationAdjustedAux = dataVariation;
      }
    } else if (frequency === 'original') {
      switch (selectedY?.info?.frequency) {
        case 'daily':
          dataLevelAdjustedAux = getLatestData(dataLevel, 'daily');
          dataVariationAdjustedAux = getLatestData(
            dataVariation,
            'daily',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        case 'weekly':
          dataLevelAdjustedAux = getLatestData(dataLevel, 'weekly');
          dataVariationAdjustedAux = getLatestData(
            dataVariation,
            'weekly',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        case 'fortnightly':
          dataLevelAdjustedAux = getLatestData(dataLevel, 'fortnightly');
          dataVariationAdjustedAux = getLatestData(
            dataVariation,
            'fortnightly',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        case 'monthly':
          dataLevelAdjustedAux = getLatestData(dataLevel, 'monthly');
          dataVariationAdjustedAux = getLatestData(
            dataVariation,
            'monthly',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        case 'bimonthly':
          dataLevelAdjustedAux = getLatestData(dataLevel, 'bimonthly');
          dataVariationAdjustedAux = getLatestData(
            dataVariation,
            'bimonthly',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        case 'quarterly':
          dataLevelAdjustedAux = getLatestData(dataLevel, 'quarterly');
          dataVariationAdjustedAux = getLatestData(
            dataVariation,
            'quarterly',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        case 'half-year':
          dataLevelAdjustedAux = getLatestData(dataLevel, 'half-year');
          dataVariationAdjustedAux = getLatestData(
            dataVariation,
            'half-year',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        case 'annual':
          dataLevelAdjustedAux = getLatestData(dataLevel, 'annual');
          dataVariationAdjustedAux = getLatestData(
            dataVariation,
            'annual',
            true,
            dataLevelAdjustedAux.historical.date[0],
          );
          break;
        default:
          if (dataLevel) {
            dataLevelAdjustedAux = dataLevel;
          }
          if (dataVariation) {
            dataVariationAdjustedAux = dataVariation;
          }
          break;
      }
    } else if (frequency === 'monthly') {
      dataLevelAdjustedAux = getLatestData(dataLevel, 'monthly');
      dataVariationAdjustedAux = getLatestData(
        dataVariation,
        'monthly',
        true,
        dataLevelAdjustedAux.historical.date[0],
      );
    } else if (frequency === 'quarterly') {
      dataLevelAdjustedAux = getLatestData(dataLevel, 'quarterly');
      dataVariationAdjustedAux = getLatestData(
        dataVariation,
        'quarterly',
        true,
        dataLevelAdjustedAux.historical.date[0],
      );
    } else {
      dataLevelAdjustedAux = getLatestData(dataLevel, 'annual');
      dataVariationAdjustedAux = getLatestData(
        dataVariation,
        'annual',
        true,
        dataLevelAdjustedAux.historical.date[0],
      );
    }

    return {
      dataLevelAdjusted: dataLevelAdjustedAux,
      dataVariationAdjusted: dataVariationAdjustedAux,
    };
  }, [
    dataLevel,
    dataVariation,
    frequency,
    isLatestDataActive,
    selectedY?.info?.frequency,
  ]);

  const canSelectForecast = useMemo(
    () => Number(appInfo?.engine.version.replaceAll('.', '')) >= 50,
    [appInfo],
  );

  return (
    <AISelectionResultsContext.Provider
      value={{
        isYFrequencyValid,
        xSelected,
        isLoadingChart,
        dataLevel: dataLevelAdjusted,
        dataVariation: dataVariationAdjusted,
        isLatestDataDisabled,
        canSelectForecast,
        selectX,
        selectFrequency,
        selectTransformation,
      }}
    >
      <ResultContainer className="containerLinear">
        <Card
          textCard={translate('AISelectionResultTitle')}
          textDescription={translate('AISelectionResultDescription')}
        />

        <ChartHeader>
          <ResultsOptions />

          <ContentLatestData>
            <ToggleSwitch
              label={translate('AISelectionResultLatestData')}
              checked={isLatestDataActive}
              onChange={(e) => handleActiveLatestData(e.target.checked)}
              disabled={isLatestDataDisabled || !!error || !!isLoadingChart}
              data-cy="results-latest-data"
            />
          </ContentLatestData>
        </ChartHeader>

        {error ? (
          <ContainerMaintenance
            content="chart"
            text={translateSomeMessagesFromBackend(
              returnMessageError() ?? '',
              user.language,
            )}
            size="sm"
          />
        ) : (
          <>
            {frequency === chartFrequency &&
            isLatestDataActive === chartIsLatestDataActive ? (
              <ResultsChart chartFrequency={chartFrequency} />
            ) : (
              <ContainerSkeleton data-testid="result-loading" />
            )}

            <ResultsUnderstanding />
          </>
        )}
      </ResultContainer>
    </AISelectionResultsContext.Provider>
  );
};
