import React, { useEffect, useState } from 'react';

import { Info } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { Button } from 'src/components/Button';
import { Card } from 'src/components/Card';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Head } from 'src/components/Head';
import { ErrorObject, FailedModal } from 'src/components/Modal/Failed';
import { RadioButton } from 'src/components/RadioButton';
import { insertProject } from 'src/models/redux/reducers/ClaasProject';
import api from 'src/models/service/api';
import { RootState } from 'src/redux/store';
import { queryClient } from 'src/service/queryClient';
import { clearModelProdNavigation } from 'src/models/redux/reducers/ModelProdNavigation';

import { ConfusionMatrixChart } from '../components/ConfusionMatrix';
import { FeatureImportanceChart } from '../components/FeatureImportance';
import { ModelPerformance } from '../components/ModelPerformance';
import { ROCCurvesChart } from '../components/RocCurves';
import { ModelInfo } from './components/ModelInfo';
import {
  Container,
  ContentButton,
  ModelList,
  ModelListError,
  ModelPerformanceContainer,
  ModelPerformanceInfo,
} from './styles';
import {
  ModelInformation,
  FeatureImportance,
  Model,
  ConfusionMatrix,
  ROCCurve,
  ModelRecipe,
  VariableImpacts,
} from './types';
import { ModelDetails } from '../components/ModelDetails';
import { VariableImpactsChart } from '../components/VariableImpacts';

export const ClaaSModelExplorer: React.FC = () => {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const {
    id: projectId,
    modelList,
    projectError,
    name,
    targetVariable,
    modelInProduction,
  } = useSelector((state: RootState) => state.claasProject);

  const { userCameFromModelProd } = useSelector(
    (state: RootState) => state.modelProdNavigation,
  );

  const [selectedModel, setSelectedModel] = useState<Model>();
  const [confusionMatrixCount, setConfusionMatrixCount] = useState<
    'absolut' | 'percentage'
  >('absolut');
  const [sendToProdDisabled, setSendToProdDisabled] = useState(false);
  const [failModalInfo, setFailModalInfo] = useState<ErrorObject>();

  useEffect(() => {
    modelList && setSelectedModel(modelList[0] ?? undefined);
  }, [modelList]);

  useEffect(() => {
    if (userCameFromModelProd === true && modelInProduction && modelList) {
      const modelToSet = modelList.find((model) =>
        model.code.includes(modelInProduction.code),
      );
      modelToSet && setSelectedModel(modelToSet);

      dispatch(clearModelProdNavigation());
    }
  }, [dispatch, modelInProduction, modelList, userCameFromModelProd]);

  const {
    data: modelInfoData,
    isFetching: isFetchingModelInfo,
    isLoading: isLoadingModelInfo,
    isError: isModelInfoErrored,
  } = useQuery(
    ['model explorer', 'model info', projectId, selectedModel],
    async () => {
      const { data } = await api.get<ModelInformation>(
        `/classification/projects/${projectId}/models/${selectedModel?.type}/info`,
      );

      return data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled: !!projectId && !!selectedModel?.type,
    },
  );

  const {
    data: modelDetailsData,
    isFetching: isFetchingModelDetails,
    isLoading: isLoadingModelDetails,
    isError: isModelDetailsErrored,
  } = useQuery(
    ['model explorer', 'model recipe', projectId, selectedModel],
    async () => {
      const { data } = await api.get<ModelRecipe>(
        `/classification/projects/${projectId}/models/${selectedModel?.type}/recipe`,
      );

      return data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled: !!projectId && !!selectedModel?.type,
    },
  );

  const {
    data: featImportanceData,
    isFetching: isFetchingFeatImportance,
    isLoading: isLoadingFeatImportance,
    isError: isFeatImportanceErrored,
  } = useQuery(
    ['model-explorer', 'feature-importance', projectId, selectedModel],
    async () => {
      const { data } = await api.get<FeatureImportance>(
        `/classification/projects/${projectId}/models/${selectedModel?.type}/feature-importance`,
      );

      return data.feature_importance
        ?.sort((a, b) => b.value - a.value)
        ?.filter((_, index) => index < 5);
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled: !!projectId && !!selectedModel?.type,
    },
  );

  const {
    data: variableImpactsData,
    isFetching: isFetchingVariableImpacts,
    isLoading: isLoadingVariableImpacts,
    isError: isVariableImpactsErrored,
  } = useQuery(
    ['model-explorer', 'variable-impacts', projectId, selectedModel],
    async () => {
      const { data } = await api.get<VariableImpacts>(
        `/classification/projects/${projectId}/models/${selectedModel?.type}/variable-impacts`,
      );

      return {
        variable_impacts: data.variable_impacts.map((varImp) => ({
          ...varImp,
          values: varImp.values
            .filter((values) =>
              featImportanceData?.some(
                (feature) => feature.name === values.variable_name,
              ),
            )
            .sort((a, b) => {
              const order =
                featImportanceData?.map((feature) => feature.name) ?? [];
              return (
                order.indexOf(a.variable_name) - order.indexOf(b.variable_name)
              );
            }),
        })),
      };
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled: !!projectId && !!selectedModel?.type && !!featImportanceData,
    },
  );

  const {
    data: confusionMatrixData,
    isFetching: isFetchingConfusionMatrix,
    isLoading: isLoadingConfusionMatrix,
    isError: isConfusionMatrixErrored,
  } = useQuery(
    ['model-explorer', 'confusion-matrix', projectId, selectedModel],
    async () => {
      const { data } = await api.get<ConfusionMatrix>(
        `/classification/projects/${projectId}/models/${selectedModel?.type}/confusion-matrix?count=${confusionMatrixCount}`,
      );

      return data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled: !!projectId && !!selectedModel?.type && !!confusionMatrixCount,
    },
  );

  const {
    data: ROCCurveData,
    isFetching: isFetchingROCCurve,
    isLoading: isLoadingROCCurve,
    isError: isROCCurveErrored,
  } = useQuery(
    ['model-explorer', 'ROC-curve', projectId, selectedModel],
    async () => {
      const { data } = await api.get<ROCCurve>(
        `/classification/projects/${projectId}/models/${selectedModel?.type}/roc-curve`,
      );

      return data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled: !!projectId && !!selectedModel?.type,
    },
  );

  async function handleSendToProduction(model: Model) {
    setSendToProdDisabled(true);

    try {
      const { data } = await api.post(
        `/classification/projects/${projectId}/model-in-production/${model.code}`,
      );

      if (data) {
        dispatch(
          insertProject({
            id: projectId,
            name,
            modelList,
            targetVariable,
            modelInProduction: model,
            projectError: false,
          }),
        );

        queryClient.removeQueries('model in production');
        queryClient.removeQueries(['claas project', projectId]);
        navigate(`/models/classification/${projectId}/model-in-production`);
      }
    } catch {
      setFailModalInfo({
        title: translate('requestFailed'),
        description: translate('claasSendToProdFailMessage'),
      });
    }

    setSendToProdDisabled(false);
  }

  return (
    <Container>
      <Head title={translate('labelModelExplorer')} />
      <section>
        <ModelList className="containerLinear">
          <Card
            textCard={translate('selectModelTitle')}
            textDescription={translate('claasModelListDescription')}
          />
          <div>
            {modelList && modelList.length > 0 ? (
              <>
                {modelList.map((model) => (
                  <RadioButton
                    key={model.code}
                    checked={selectedModel?.code === model?.code}
                    label={model.name}
                    onClick={() => setSelectedModel(model)}
                    data-cy={`radio-button-${model.name.replaceAll(' ', '-')}`}
                    data-testid={`radio-button-${model.name
                      .replaceAll(' ', '-')
                      .toLocaleLowerCase()}`}
                  />
                ))}
              </>
            ) : !modelList && !projectError ? (
              <>
                {Array.from({ length: 2 }).map((_, index) => (
                  <div key={`${index + 1}`}>
                    <RadioButton checked={false} label="" disabled />
                    <ContainerSkeleton
                      data-testid={`radio-button-loading-${index}`}
                      withLoading={false}
                      style={{
                        height: '20px',
                        marginLeft: '24px',
                        width: '150px',
                      }}
                    />
                  </div>
                ))}
              </>
            ) : (
              projectError === true && (
                <>
                  <ModelListError>
                    {translate('modelListErrorMessage')}
                  </ModelListError>
                </>
              )
            )}
          </div>
          <ContentButton>
            <Button
              buttonType="primary"
              data-cy="button-send-to-production"
              data-testid="button-send-to-production"
              onClick={() =>
                selectedModel && handleSendToProduction(selectedModel)
              }
              loading={sendToProdDisabled}
              disabled={
                sendToProdDisabled ||
                !modelList ||
                (modelList && modelList.length < 1)
              }
            >
              {translate('classModelToProductionButton')}
            </Button>
          </ContentButton>
        </ModelList>

        <ModelInfo
          selectedModel={selectedModel?.name}
          infos={modelInfoData && modelInfoData.model_info}
          isLoading={isLoadingModelInfo || isFetchingModelInfo}
          isErrored={isModelInfoErrored || projectError}
        />
      </section>
      <ModelPerformanceContainer className="containerLinear">
        <Card
          textCard={translate('accuracyMetricsTitle')}
          textDescription={translate('accuracyMetricsDescription')}
        />
        <ModelPerformanceInfo>
          <Info
            size="1.25rem"
            data-tooltip-id="models-classification-model-explorer"
            data-tooltip-html={translate('modelPerformanceInfo')}
          />
        </ModelPerformanceInfo>
        <ModelPerformance
          data={modelInfoData}
          isFetching={isFetchingModelInfo}
          isLoading={isLoadingModelInfo}
          isErrored={isModelInfoErrored || projectError}
        />

        <ModelDetails
          data={modelDetailsData}
          isFetching={isFetchingModelDetails}
          isLoading={isLoadingModelDetails}
          isErrored={isModelDetailsErrored || projectError}
        />
      </ModelPerformanceContainer>

      <FeatureImportanceChart
        data={featImportanceData}
        isLoading={isLoadingFeatImportance}
        isFetching={isFetchingFeatImportance}
        isErrored={isFeatImportanceErrored || projectError}
      />

      <VariableImpactsChart
        data={variableImpactsData}
        targetVariable={targetVariable ?? ''}
        isFetching={isFetchingVariableImpacts}
        isLoading={isLoadingVariableImpacts}
        isErrored={
          isVariableImpactsErrored || isFeatImportanceErrored || projectError
        }
      />

      <section>
        <ConfusionMatrixChart
          data={
            confusionMatrixData && {
              labels: confusionMatrixData.labels,
              values:
                confusionMatrixCount === 'absolut'
                  ? confusionMatrixData.values_absolute
                  : confusionMatrixData.values_percentage,
            }
          }
          isLoading={isLoadingConfusionMatrix}
          isFetching={isFetchingConfusionMatrix}
          isErrored={isConfusionMatrixErrored || projectError}
          count={confusionMatrixCount}
          setCount={setConfusionMatrixCount}
        />

        <ROCCurvesChart
          data={ROCCurveData}
          isLoading={isLoadingROCCurve}
          isFetching={isFetchingROCCurve}
          isErrored={isROCCurveErrored || projectError}
        />
      </section>
      <Tooltip
        id="models-classification-model-explorer"
        className="customTooltipTheme"
      />

      <FailedModal
        visible={!!failModalInfo}
        setVisible={(visible) =>
          visible === false && setFailModalInfo(undefined)
        }
        errorInfo={failModalInfo}
      />
    </Container>
  );
};
