import styled, { css } from 'styled-components';
import { MenuContainer, MenuOption } from 'src/components/Menu/styles';
import { Spinner } from 'src/components/Spinner';

export const ModelIdContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

export const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
`;

export const MenuContainerStyled = styled(MenuContainer)`
  top: 100%;
  right: 0px;
  box-shadow: unset;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  padding: 0.25rem 0;
`;

type OtherModelContentProps = {
  canSeeOtherModelId: boolean;
};

export const OtherModelContent = styled.div<OtherModelContentProps>`
  margin-top: ${({ canSeeOtherModelId }) =>
    canSeeOtherModelId ? '0.5rem' : '0rem'};

  max-height: ${({ canSeeOtherModelId }) =>
    canSeeOtherModelId ? '200px' : '0px'};

  overflow: hidden;

  transition: max-height 0.5s ease-in-out;

  > div {
    position: relative;
  }
`;

export const MenuOptionAdjusted = styled(MenuOption)`
  padding: 0.75rem 1rem;
  > p {
    font-weight: ${({ selected }) => (selected ? 600 : 400)} !important;
    font-size: 0.875rem !important;
  }

  ${({ selected }) =>
    !selected &&
    css`
      &:hover {
        background: ${({ theme }) => theme.colors.gray1} !important;

        > p {
          color: ${({ theme }) => theme.colors.gray5} !important;
        }
      }
    `}
`;

interface ButtonCleanProps {
  isProject?: boolean;
}

export const ButtonClean = styled.button<ButtonCleanProps>`
  color: ${({ theme }) => theme.colors.gray6};

  display: flex;

  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;

  div {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    font-size: 0.875rem;

    text-wrap: nowrap;
    text-align: left;

    span {
      font-size: 0.75rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.gray4};
      margin-top: 0.25rem;
    }
  }

  &:not(:disabled) {
    div {
      font-weight: ${({ isProject }) => (isProject ? 500 : 400)};
    }
  }

  svg {
    color: ${({ theme }) => theme.colors.gray4};

    polyline {
      stroke-width: 36px;
    }
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.gray4};
    cursor: not-allowed;
  }
`;

export const SpinnerAdjusted = styled(Spinner)`
  position: absolute;
  right: 0.5rem;
  top: 0.875rem;
`;
