export const commonTextsENUS = {
  confirm: 'Confirm',
  cancel: 'Cancel',
  back: 'Back',
  backToHome: 'Back to home',
  close: 'Close',
  select: 'Select...',
  selectAll: 'Select all',
  daily: 'Daily',
  weekly: 'Weekly',
  fortnightly: 'Fortnightly',
  monthly: 'Monthly',
  bimonthly: 'Bimonthly',
  quarterly: 'Quarterly',
  'half-year': 'Half-year',
  annual: 'Annual',
  yearly: 'Annual',
  sample: 'Sample',
  transformation: 'Transformation',
  time: 'Time',
  date: 'Date',
  value: 'Value',
  variable: 'Variable',
  data: 'Data',
  percentage: 'Percentage',
  absolut: 'Absolute',
  type: 'Type',
  method: 'Method',
  requestFailed: 'Something went wrong',
  searchMinCharactersError: 'Search must be at least XX characters',
  searchMaxCharactersError: 'Must not be more than XX characters',
  start: 'Start',
  end: 'End',
  export: 'Export',
  stock: 'Stock',
  flow: 'Flow',
  Random_Forest: 'Random Forest',
  Forecast_Combination: 'Forecast Combination',
  Tree_Models: 'Tree-based Model',
  Regularized_Regression: 'Regularized Regression',
  Elementary: 'Elementary',
  delete: 'Delete',
  name: 'Name',
  createdAt: 'Created at',
  lastUpdated: 'Last updated',
  edit: 'Edit',
  description: 'Description',
  create: 'Create',
  groups: 'groups',
  Historical: 'Historical',
  Forecast: 'Forecast',
  model: 'Model',
  model_fit: 'Model fit',
  Seasonally_Adjusted: 'Seasonally Adjusted',
  Actual: 'Actual',
  Simulated: 'Simulated',
  requiredField: 'Required field',
  yes: 'Yes',
  no: 'No',
  Active: 'Active',
  Blocked: 'Blocked',
  classification: 'Classification',
  timeSeries: 'Time Series',
  download: 'Download',
  class: 'Class',
  day: 'Day',
  days: 'Days',
  week: 'Week',
  weeks: 'Weeks',
  fortnight: 'Fortnight',
  fortnights: 'Fortnights',
  month: 'Month',
  months: 'Months',
  bimonth: 'Bimonth',
  quarter: 'Quarter',
  halfYear: 'Half-Year',
  year: 'Year',
  years: 'Years',
  save: 'Save',
  unknownRequestErrorTitle: 'Something went wrong',
  unknownRequestErrorDescription:
    'Please, try again. If the problem persists, contact our support.',
  lines: 'lines',
  loading: 'Loading',
  source: 'Source',
  projection: 'Forecast',
  indicator: 'Indicators',
  latestData: 'Latest data',
  copied: 'Copied',
  copy: 'Copy',
  resourcePaid: 'This is a paid feature.',
  resourcePaidMakeUpgrade: 'This is a paid feature, upgrade to unlock.',
  upgradeToAccess: 'Upgrade to get access!',
  contactUs: 'Contact us',
  frequency: 'Frequency',
  email: 'E-mail',
  status: 'Status',
  actions: 'Actions',
  next: 'Next',
  ranking: 'Ranking',
  upgrade: 'Upgrade',
  MAPEExplanationTooltip:
    'Mean Absolute Percentage Error measures the average percentage of the absolute difference between predicted and observed values.',
  WMAPEExplanationTooltip:
    'Weighted Mean Absolute Percentage Error measures the average percentage of the absolute difference between predicted and observed values, taking into account the relative importance of different data points.',
  MPEExplanationTooltip:
    'Mean Percentage Error measures the average percentage difference between predicted and observed values, indicating the overall direction and magnitude of the errors.',
  RMSEExplanationTooltip:
    'Root Mean Square Error measures the average magnitude of errors between predicted and observed values, preserving the same unit as the dependent variable.',
  MASEExplanationTooltip:
    "Mean Absolute Scaled Error compares the model's accuracy to a baseline model. It is scale-independent: 1 indicating that the model performs equally, while a value less than 1 means that it outperforms the baseline.",
  MASEsExplanationTooltip:
    "Seasonal Mean Absolute Scaled Error compares the model's accuracy to a seasonal baseline model. It is scale-independent: 1 indicating that the model performs equally, while a value less than 1 means that it outperforms the seasonal baseline.",
  favoritesTitle: 'Favorites',
  notAvailable: 'Not available.',
  histogram: 'Histogram',
  temporarily_unavailable: 'Temporarily unavailable',
  privateContent: 'Private',
  selectTheAccessType: 'Select the access type',
  accuracy: 'Accuracy',
  variables: 'variables',
  continue: 'Continue',
  exit: 'Exit',
  version: 'Version',
  preview: 'Preview',
  filters: 'Filters',
  original: 'Original',
  level: 'Level',
  variation: 'Variation',
  adjusted: 'Adjusted',
  period: 'Period',
  csvFile: 'CSV file',
  serie: 'Serie',
  manualAdjust: 'Manual adjust',
  discard: 'Discard',
  icon: 'Icon',
  fullForecast: 'Full Forecast',
  others: 'Others',
  variationYoY: 'Variation % (YoY)',
  variationPoP: 'Variation % (PoP)',
  approval: 'Approval',
  edition: 'Edition',
  governance: 'Governance',
  revision: 'Revision',
  reproved: 'Reproved',
  approved: 'Approved',
  series: 'series',
  click: 'click',
  here: 'here',
  project: 'project',
};

export const commonTextsPTBR = {
  confirm: 'Confirmar',
  cancel: 'Cancelar',
  back: 'Voltar',
  backToHome: 'Voltar para página inicial',
  close: 'Fechar',
  select: 'Selecionar...',
  selectAll: 'Selecionar todas',
  daily: 'Diário',
  weekly: 'Semanal',
  fortnightly: 'Quinzenal',
  monthly: 'Mensal',
  bimonthly: 'Bimestral',
  quarterly: 'Trimestral',
  'half-year': 'Semestral',
  annual: 'Anual',
  yearly: 'Anual',
  sample: 'Amostra',
  transformation: 'Transformação',
  time: 'Tempo',
  date: 'Data',
  value: 'Valor',
  variable: 'Variável',
  data: 'Dado',
  percentage: 'Porcentagem',
  absolut: 'Absoluto',
  type: 'Tipo',
  method: 'Método',
  requestFailed: 'Algo deu errado',
  searchMinCharactersError: 'Pesquisa precisa ter pelo menos XX caracteres',
  searchMaxCharactersError: 'Não deve conter mais do que XX caracteres',
  start: 'Início',
  end: 'Fim',
  export: 'Exportar',
  stock: 'Estoque',
  flow: 'Fluxo',
  Random_Forest: 'Random Forest',
  Forecast_Combination: 'Combinação de Modelos',
  Tree_Models: 'Modelo de Árvore',
  Regularized_Regression: 'Regressão Regularizada',
  Elementary: 'Elementar',
  delete: 'Deletar',
  name: 'Nome',
  createdAt: 'Criado em',
  lastUpdated: 'Última atualização',
  edit: 'Editar',
  description: 'Descrição',
  create: 'Criar',
  groups: 'grupos',
  Historical: 'Histórico',
  Forecast: 'Projeção',
  model: 'Modelo',
  model_fit: 'Ajuste do modelo',
  Seasonally_Adjusted: 'Ajustado Sazonalmente',
  Actual: 'Realizado',
  Simulated: 'Simulado',
  requiredField: 'Campo obrigatório',
  yes: 'Sim',
  no: 'Não',
  Active: 'Ativo',
  Blocked: 'Bloqueado',
  classification: 'Classificação',
  timeSeries: 'Série temporal',
  download: 'Baixar',
  class: 'Classe',
  day: 'Dia',
  days: 'Dias',
  week: 'Semana',
  weeks: 'Semanas',
  fortnight: 'Quinzena',
  fortnights: 'Quinzenas',
  month: 'Mês',
  months: 'Meses',
  bimonth: 'Bimestre',
  quarter: 'Trimestre',
  halfYear: 'Semestre',
  year: 'Ano',
  years: 'Anos',
  save: 'Salvar',
  unknownRequestErrorTitle: 'Algo deu errado',
  unknownRequestErrorDescription:
    'Por favor, tente novamente. Se o problema persistir, entre em contato com nosso suporte.',
  lines: 'linhas',
  loading: 'Carregando',
  source: 'Fonte',
  projection: 'Projeção',
  indicator: 'Indicadores',
  latestData: 'Dados recentes',
  copied: 'Copiado',
  copy: 'Copiar',
  resourcePaid: 'Esse é um recurso pago',
  resourcePaidMakeUpgrade:
    'Esse é um recurso pago, faça upgrade para desbloquear.',
  upgradeToAccess: 'Faça upgrade para ter acesso!',
  contactUs: 'Fale Conosco',
  frequency: 'Frequência',
  email: 'Email',
  status: 'Status',
  actions: 'Ações',
  next: 'Próximo',
  ranking: 'Classificação',
  upgrade: 'Fazer upgrade',
  MAPEExplanationTooltip:
    'O Erro Percentual Absoluto Médio calcula a média percentual da diferença absoluta entre os valores previstos e observados.',
  WMAPEExplanationTooltip:
    'O Erro Percentual Absoluto Médio Ponderado calcula a média percentual da diferença absoluta entre os valores previstos e observados, levando em consideração a importância relativa de diferentes pontos de dados.',
  MPEExplanationTooltip:
    'O Erro Percentual Médio calcula a média percentual da diferença entre os valores previstos e observados, indicando a direção e magnitude dos erros.',
  RMSEExplanationTooltip:
    'O Erro Médio Quadrático calcula a magnitude média dos erros entre os valores previstos e observados, mantendo a mesma unidade da variável dependente.',
  MASEExplanationTooltip:
    'O Erro Médio Absoluto Escalonado compara a precisão do modelo com um modelo de referência. É independente de escala: o valor 1 indica que o modelo tem um desempenho igual, enquanto um valor menor que 1 significa que ele performa melhor que o modelo de referência.',
  MASEsExplanationTooltip:
    'O Erro Médio Absoluto Escalonado Sazonal compara a precisão do modelo com um modelo de referência sazonal. É independente de escala: o valor 1 indica que o modelo tem um desempenho igual, enquanto um valor menor que 1 significa que ele performa melhor que o modelo sazonal de referência.',
  favoritesTitle: 'Favoritos',
  notAvailable: 'Não disponível.',
  histogram: 'Histograma',
  temporarily_unavailable: 'Temporariamente indisponível',
  privateContent: 'Privado',
  selectTheAccessType: 'Selecione o tipo de acesso',
  accuracy: 'Acurácia',
  variables: 'variáveis',
  continue: 'Continuar',
  exit: 'Sair',
  version: 'Versão',
  preview: 'Prévia',
  filters: 'Filtros',
  original: 'Original',
  level: 'Nível',
  variation: 'Variação',
  adjusted: 'Ajustado',
  period: 'Período',
  csvFile: 'Arquivo CSV',
  serie: 'Série',
  manualAdjust: 'Ajuste manual',
  discard: 'Descartar',
  icon: 'Ícone',
  fullForecast: 'Projeção completa',
  others: 'Outros',
  variationYoY: 'Variação % (YoY)',
  variationPoP: 'Variação % (PoP)',
  approval: 'Aprovação',
  edition: 'Edição',
  governance: 'Governança',
  revision: 'Revisão',
  reproved: 'Reprovado',
  approved: 'Aprovado',
  series: 'séries',
  click: 'clique',
  here: 'aqui',
  project: 'projeto',
};
