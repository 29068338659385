export function getMedian(values: number[]): number {
  values.sort((a: number, b: number) => a - b);

  const half = Math.floor(values.length / 2);

  if (values.length % 2) {
    return values[half];
  }

  return (values[half - 1] + values[half]) / 2.0;
}

// A function that recognizes the desired frequency of data
export function getDateFrequency(dateSample: Date[]): string {
  // Sort the date object
  const dateSampleSorted = dateSample.sort((a: any, b: any) => a - b);

  const dateSampleTime = dateSampleSorted.map((elem: any) => elem.getTime());

  // First detect the frequency of data
  // The idea is to get the median of differences in dates from dataset and check
  // how many days it has

  const dates1 = dateSampleTime.slice(1); // 2:ultima
  const dates2 = dateSampleTime.slice(0, -1); // 1:penultima

  const datesDiff = dates1.map((num: any, idx: number) => {
    // To calculate the time difference of two dates
    const diff = num - dates2[idx];
    // To calculate the no. of days between two dates
    return diff / (1000 * 3600 * 24);
  });

  const medianDates = getMedian(datesDiff);

  let frequency;

  if (medianDates < 5) {
    frequency = 'Daily';
  } else if (medianDates >= 5 && medianDates <= 10) {
    frequency = 'Weekly';
  } else if (medianDates > 10 && medianDates < 20) {
    frequency = 'Fortnightly';
  } else if (medianDates >= 2 && medianDates < 40) {
    frequency = 'Monthly';
  } else if (medianDates >= 40 && medianDates < 80) {
    frequency = 'Bimonthly';
  } else if (medianDates >= 80 && medianDates < 110) {
    frequency = 'Quarterly';
  } else if (medianDates >= 110 && medianDates < 220) {
    frequency = 'Half-year';
  } else if (medianDates >= 220 && medianDates < 440) {
    frequency = 'Annual';
  } else {
    frequency = 'Erro!';
  }

  return frequency;
}
