import React from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Card } from 'src/components/Card';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { RootState } from 'src/redux/store';
import { translateSomeMessagesFromBackend } from 'src/i18n';
import { useQuery } from 'react-query';
import api from 'src/models/service/api';

import { ContainerPotentialOutliers } from './styles';
import { ErrorDataView } from '../../types';

interface ReturnPotencialOutliers {
  arima: string[];
  sarima: string[] | string;
  cooksd: string;
}

export const PotentialOutliers: React.FC = () => {
  const id = useSelector(
    (state: RootState) =>
      state.modelling.form?.fileDataSet.dataset_id ?? state.project.id,
  );

  const { user } = useSelector((state: RootState) => state.auth);

  const { t: translate } = useTranslation();

  const {
    data: dataOutliers,
    isLoading: isLoadingOutliers,
    isFetching: isFetchingOutliers,
    isError: isErrorOutliers,
    error: errorOutliers,
  } = useQuery<ReturnPotencialOutliers, ErrorDataView>(
    ['dataview', 'outliers', id],
    async () => {
      const { data } = await api.get(`projects/${id}/dataview/outliers`);
      return data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled: !!id,
    },
  );

  return (
    <div className="containerLinear" data-cy="potential-outliers">
      <Card textCard={translate('potential_outliers')} />

      {isErrorOutliers ? (
        <ContainerMaintenance
          text={
            errorOutliers?.response?.data?.detail?.detail ??
            errorOutliers?.response?.data?.detail?.description
          }
          content="information"
          data-testid="container-outliers-error"
          data-cy="container-outliers-error"
        />
      ) : isFetchingOutliers || isLoadingOutliers || !dataOutliers ? (
        <ContainerSkeleton
          data-testid="div-loading-potential-outliers"
          data-cy="div-loading-potential-outliers"
          style={{ height: '12.5rem' }}
        />
      ) : (
        <ContainerPotentialOutliers
          data-testid="div-potential-outliers"
          data-cy="div-potential-outliers"
        >
          <p data-cy="potential-outliers-arima">
            {translate('arima_method')}:{' '}
            {translateSomeMessagesFromBackend(
              dataOutliers?.arima.toString().replace(/,/g, '; '),
              user.language,
            )}
          </p>
          <p data-cy="potential-outliers-sarima">
            {translate('sarima_method')}:{' '}
            {translateSomeMessagesFromBackend(
              dataOutliers.sarima.toString().replace(/,/g, '; '),
              user.language,
            )}
          </p>
          <p data-cy="potential-outliers-cooksd">
            {translate('cooksd_method')}:{' '}
            {translateSomeMessagesFromBackend(
              dataOutliers?.cooksd.toString().replace(/,/g, '; '),
              user.language,
            )}
          </p>
        </ContainerPotentialOutliers>
      )}
    </div>
  );
};
