import React from 'react';

import { useTranslation } from 'react-i18next';
import { Card } from 'src/components/Card';
import { Head } from 'src/components/Head';

import { PerformanceByPeriodTable } from './components/PerformanceByPeriod';
import { Container } from './styles';

export const OverallPerformance: React.FC = () => {
  const { t: translate } = useTranslation();

  return (
    <Container>
      <Head title={translate('labelOverallPerformance')} />

      <div className="containerLinear page-title">
        <Card
          textCard={translate('labelOverallPerformance')}
          textDescription={translate('labelOverallPerformanceDescription')}
        />
      </div>

      <PerformanceByPeriodTable />
    </Container>
  );
};
