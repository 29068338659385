import { add, getDaysInMonth } from 'date-fns';

export function convertQuarterlyToDate(quarterly: string): Date {
  const [year, quarterlyNumber] = quarterly.split(' Q');

  const date = `${year}-${String(Number(quarterlyNumber) * 3).padStart(
    2,
    '0',
  )}-01`;

  const dateAux = new Date(`${date}T00:00`);

  return add(dateAux, { days: getDaysInMonth(dateAux) - 1 });
}
