export const performanceByPeriodTablePTBRTranslations = {
  performanceByPeriodTitle: 'Performance por período',
  performanceByPeriodDescription:
    'Analise a performance dos modelos da <b>Seleção do Usuário</b> para as variáveis do projeto.',
  performanceByPeriodDepVariable: 'Variável dependente',
  performanceByPeriodSearchPlaceholder: 'Procurar variáveis dependentes',
  performanceByPeriodMetricLabel: 'Métrica',
  performanceByPeriodTooltip:
    'Cada coluna reflete o desempenho médio de todos os modelos que possuem datas fora da amostra suficientes para o período referido.',
  perforamnceByPeriodVariableTooltip: 'Variável não atualizada.',
  performanceByPeriodNoResults:
    'Não foi possível encontrar nenhuma variável contendo "SEARCH".',
};
