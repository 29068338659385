import { RefObject } from 'react';

import HighchartsReact from 'highcharts-react-official';

import { sleep } from '../sleep';

export const resizeWidth = async (
  chartRef: RefObject<HighchartsReact.RefObject>,
  sidebarExpanded: boolean,
  timer = false,
  animation = false,
  removeWidth?: number,
): Promise<void> => {
  const chartWidth = chartRef.current?.chart.chartWidth;

  const sidebarWidth = sidebarExpanded ? 20 * 16 : 7 * 16;
  const contentPadding = 24 * 2;

  let width = window.innerWidth - sidebarWidth - contentPadding * 2 - 16;

  if (removeWidth) {
    width -= removeWidth;
  }

  if (!chartWidth || (chartWidth <= width + 40 && chartWidth >= width - 40)) {
    return;
  }

  if (timer) {
    await sleep(750); // Tempo para abrir/fechar sidebar

    chartRef.current?.chart.setSize(null, null, animation);
  } else {
    chartRef.current?.chart.setSize(width, null, {
      duration: !sidebarExpanded ? 750 : 400,
    });
  }
};
