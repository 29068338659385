import styled from 'styled-components';

export const Container = styled.div`
  section {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    > div {
      flex: 1 1 18.75rem;
    }
  }

  .page-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
    flex-wrap: wrap;
    > div:first-child {
      margin-bottom: 0;
      max-width: 70%;
    }
  }

  .select {
    width: 20rem;
    margin-bottom: 1.5rem;

    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }

  .relative {
    position: relative;
  }

  h5 {
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    color: ${({ theme }) => theme.colors.gray6};
  }

  .css-26l3qy-menu {
    z-index: 700;
  }
`;
