import React, { useEffect, useState } from 'react';

import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from 'src/components/Button';
import { CheckBox } from 'src/components/CheckBox';
import { Input } from 'src/components/Input';
import { Modal } from 'src/components/Modal';
import { ModalLoading } from 'src/components/Modal/Loading';
import { Table, Th, Thead, Tr, Tbody, Td } from 'src/components/Table';
import api from 'src/feature-store/service/api';
import { RootState } from 'src/redux/store';
import { ModalFooter } from 'src/components/Modal/Footer/styles';

import { Description } from '../styles';
import { OpeningsProps } from '../types';
import { ModalStatusSeries } from './ModalStatusSeries';
import { Container, ContainerTable, Content } from './styles';
import { ResponseDataErrorProps, ResponseDataSuccessProps } from './types';

interface ReviewSeriesProps {
  visible: boolean;
  setVisible(visible: boolean): void;
  openings?: OpeningsProps[];
  indicator_code?: string;
}

interface OpeningWithUnit extends OpeningsProps {
  isChecked: boolean;
  error: {
    'en-us': string | null;
    'pt-br': string | null;
  };
  unit: {
    'en-us': string | null;
    'pt-br': string | null;
  };
}

interface RequestDataErrorProps {
  message: string;
  status: number;
}

interface RequestCreateSeries {
  message: string;
}

export const ReviewSeries: React.FC<ReviewSeriesProps> = ({
  visible,
  setVisible,
  openings,
  indicator_code,
}) => {
  const [responseDataSuccess, setResponseDataSuccess] = useState<
    ResponseDataSuccessProps[]
  >([]);
  const [responseDataError, setResponseDataError] = useState<
    ResponseDataErrorProps[]
  >([]);
  const [modalStatus, setModalStatus] = useState<boolean>(false);
  const [responseLoading, setResponseLoading] = useState<boolean>(false);
  const [unitFromOpenings, setUnitFromOpenings] = useState<OpeningWithUnit[]>(
    [],
  );
  const { t: translate } = useTranslation();
  const { language } = useSelector((state: RootState) => state.auth.user);

  useEffect(() => {
    if (openings) {
      setUnitFromOpenings(
        openings.map((open) => ({
          ...open,
          isChecked: true,
          error: {
            'en-us': null,
            'pt-br': null,
          },
          unit: {
            'en-us': null,
            'pt-br': null,
          },
        })),
      );
    }
  }, [openings]);

  const handleSelectCombination = (index: number) => {
    const unitFromOpeningsAux = [...unitFromOpenings];
    unitFromOpeningsAux.splice(index, 1, {
      ...unitFromOpenings[index],
      isChecked: !unitFromOpenings[index].isChecked,
    });

    setUnitFromOpenings(unitFromOpeningsAux);
  };

  const handleCreateSeries = async () => {
    setResponseLoading(true);
    const newResponseDataSuccess: ResponseDataSuccessProps[] = [];
    const newResponseDataError: ResponseDataErrorProps[] = [];
    for (let i = 0; i < unitFromOpenings.length; i++) {
      if (unitFromOpenings[i].isChecked === true) {
        try {
          const { data, status } = await api.post<RequestCreateSeries>(
            `/indicators/${indicator_code}/series`,
            {
              region: unitFromOpenings[i].region.abbreviation,
              aggregation: unitFromOpenings[i].aggregation.abbreviation,
              primary_transformation: unitFromOpenings[i].primary.abbreviation,
              second_transformation: unitFromOpenings[i].secondary.abbreviation,
              unit: unitFromOpenings[i].unit,
            },
          );

          if (data) {
            newResponseDataSuccess.push({
              message: data.message,
              status,
            });
          }
        } catch (err) {
          const error = err as AxiosError<RequestDataErrorProps>;
          if (error.response && error.response.status) {
            newResponseDataError.push({
              message: error.response?.data.message,
              status: error.response?.status,
              code: `${indicator_code}${unitFromOpenings[i].region.abbreviation}${unitFromOpenings[i].aggregation.abbreviation}${unitFromOpenings[i].primary.abbreviation}${unitFromOpenings[i].secondary.abbreviation}`,
            });
          }
        }
      }
    }
    setResponseDataSuccess(newResponseDataSuccess);
    setResponseDataError(newResponseDataError);
    setResponseLoading(false);
    setModalStatus(true);
  };
  const handleCheckUnit = (
    newUnit: string,
    obj: 'en-us' | 'pt-br',
    index: number,
  ) => {
    const unitAux = [...unitFromOpenings];
    unitAux.splice(index, 1, {
      region: unitAux[index].region,
      aggregation: unitAux[index].aggregation,
      primary: unitAux[index].primary,
      secondary: unitAux[index].secondary,
      isChecked: unitAux[index].isChecked,
      error: {
        ...unitAux[index].error,
        [obj]: newUnit.length > 0 ? validadeUnitText(newUnit) : null,
      },
      unit: {
        ...unitAux[index].unit,
        [obj]: newUnit.length > 0 ? newUnit : null,
      },
    });

    setUnitFromOpenings(unitAux);
  };

  const validadeUnitText = (unitTarget: string) => {
    let error = null;
    if (unitTarget.length < 1) {
      error = 'Deve ser maior ou igual a 1 dígitos';
    } else if (unitTarget.length > 50) {
      error = 'Máximo 50 caracteres';
    }
    return error;
  };

  const validateDisableButton = () => {
    if (
      !unitFromOpenings.some(
        (open) =>
          open.isChecked &&
          (open.unit['en-us'] !== null || open.unit['en-us'] !== null),
      )
    ) {
      return true;
    }
    if (
      unitFromOpenings.some(
        (unitValidade) =>
          (unitValidade.unit['en-us'] === null ||
            unitValidade.unit['pt-br'] === null) &&
          unitValidade.isChecked === true,
      )
    ) {
      return true;
    }

    if (
      unitFromOpenings.some(
        (errorValidadeUnit) =>
          errorValidadeUnit.error['en-us'] !== null ||
          errorValidadeUnit.error['pt-br'] !== null,
      )
    ) {
      return true;
    }

    return false;
  };

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <Container
        style={{ width: '75vw' }}
        data-testid="container-review-series"
      >
        <Content>
          <Description>
            <h2>{translate('reviewCreateSeriesText')}</h2>
            <p>{translate('reviewCreateSeriesSubText')}</p>
          </Description>
          <ContainerTable>
            <Table data-testid="table-review-series">
              <Thead>
                <Tr>
                  <Th>
                    <CheckBox
                      label={translate('reviewCreateRegion')}
                      checked={
                        openings?.length ===
                        unitFromOpenings.filter((open) => open.isChecked).length
                      }
                      onChange={({ target }) => {
                        setUnitFromOpenings(
                          unitFromOpenings.map((open) => ({
                            ...open,
                            isChecked: target.checked,
                          })),
                        );
                      }}
                    />
                  </Th>
                  <Th>{translate('reviewCreateAggregation')}</Th>
                  <Th>
                    <p>{translate('reviewCreatePrimary')}</p>
                  </Th>
                  <Th>
                    <p>{translate('reviewCreateSecondary')}</p>
                  </Th>
                  <Th>
                    <p>{translate('reviewCreateUnitEn')}</p>
                  </Th>
                  <Th>
                    <p>{translate('reviewCreateUnitPt')}</p>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {unitFromOpenings?.map((opening, index) => (
                  <Tr key={`review-table-${index + 1}`}>
                    <Td>
                      <CheckBox
                        label={
                          opening.region.name[language] ??
                          opening.region.name['en-us']
                        }
                        checked={opening.isChecked}
                        onChange={() => handleSelectCombination(index)}
                      />
                    </Td>
                    <Td
                      data-testid={`aggregation-${opening.aggregation.abbreviation}-${index}`}
                    >
                      <p>
                        {opening.aggregation.name[language] ??
                          opening.aggregation.name['en-us']}
                      </p>
                    </Td>
                    <Td
                      data-testid={`primary-${opening.primary.abbreviation}-${index}`}
                    >
                      <p>
                        {opening.primary.name[language] ??
                          opening.primary.name['en-us']}
                      </p>
                    </Td>
                    <Td
                      data-testid={`secondary-${opening.secondary.abbreviation}-${index}`}
                    >
                      <p>
                        {opening.secondary.name[language] ??
                          opening.secondary.name['en-us']}
                      </p>
                    </Td>
                    <Td style={{ minWidth: '20px' }}>
                      <Input
                        onChange={(e) =>
                          handleCheckUnit(e.target.value, 'en-us', index)
                        }
                        value={opening.unit['en-us'] ?? undefined}
                        placeholder={translate('reviewCreateUnitEn')}
                        error={opening.error['en-us'] ?? undefined}
                        data-testid={`input-unit-en-${index}`}
                        style={{ width: '170px' }}
                      />
                    </Td>
                    <Td>
                      <Input
                        onChange={(e) =>
                          handleCheckUnit(e.target.value, 'pt-br', index)
                        }
                        value={opening.unit['pt-br'] ?? undefined}
                        placeholder={translate('reviewCreateUnitPt')}
                        error={opening.error['pt-br'] ?? undefined}
                        data-testid={`input-unit-pt-${index}`}
                        style={{ width: '170px' }}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </ContainerTable>
        </Content>
        <ModalFooter>
          <Button
            data-testid="button-cancel"
            buttonType="naked"
            onClick={() => {
              setVisible(false);
            }}
          >
            {translate('cancel')}
          </Button>
          <Button
            buttonType="primary"
            data-testid="add-series"
            disabled={validateDisableButton()}
            onClick={() => handleCreateSeries()}
          >
            {translate('reviewCreateAddSeries')}
          </Button>
        </ModalFooter>
      </Container>
      {responseLoading && (
        <ModalLoading
          data-testid="loading-add-series"
          visible={responseLoading}
        />
      )}
      {(responseDataSuccess || responseDataError) && (
        <ModalStatusSeries
          visible={modalStatus}
          setVisible={() => {
            setModalStatus(false);
            setVisible(false);
          }}
          responseDataSuccess={responseDataSuccess}
          responseDataError={responseDataError}
        />
      )}
    </Modal>
  );
};
