export const arrayIcon = [
  'https://storage.googleapis.com/bkt-prod-4casthub/icons/ce02ed9b-db83-4df1-8650-42132f884edb.png',
  'https://storage.googleapis.com/bkt-prod-4casthub/icons/76a72645-e1bf-4c5b-98db-64e08cb2a223.png',
  'https://storage.googleapis.com/bkt-prod-4casthub/icons/83c63ac2-ff58-43ff-bbf5-110cb43b140f.png',
  'https://storage.googleapis.com/bkt-prod-4casthub/icons/c8a24c16-6f80-4457-bde4-d219d941a831.png',
  'https://storage.googleapis.com/bkt-prod-4casthub/icons/d1427a06-ff2a-4034-b919-fefaad57a0b8.png',
  'https://storage.googleapis.com/bkt-prod-4casthub/icons/b2992612-ce1b-4275-a1d0-bba097420f1e.png',
  'https://storage.googleapis.com/bkt-prod-4casthub/icons/ef976b5b-7bdd-4ee0-a301-628e1aecc8e9.png',
  'https://storage.googleapis.com/bkt-prod-4casthub/icons/218e8730-78eb-4aa2-931c-d97a7c071d77.png',
  'https://storage.googleapis.com/bkt-prod-4casthub/icons/eac92aa3-e5f2-412c-925f-f71361e32a60.png',
  'https://storage.googleapis.com/bkt-prod-4casthub/icons/ae941ca7-dc0a-421e-ac24-a28f5534fd41.png',
  'https://storage.googleapis.com/bkt-prod-4casthub/icons/49bf3a8b-eba7-42d1-9447-37086e9dd9b5.png',
  'https://storage.googleapis.com/bkt-prod-4casthub/icons/35e67fb1-40c4-4c37-9f61-b916565394b2.png',
];

export const groupsPremiumIcons = [
  'https://storage.googleapis.com/bkt-prod-4casthub/icons/varejo.png',
  'https://storage.googleapis.com/bkt-prod-4casthub/icons/governabilidade.png',
  'https://storage.googleapis.com/bkt-prod-4casthub/icons/climatica.png',
  'https://storage.googleapis.com/bkt-prod-4casthub/icons/agro.png',
  ...arrayIcon,
];

export const checkIconURL = (value: string | null | undefined): boolean => {
  if (!value) {
    return false;
  }
  const iconsArray = [...arrayIcon, ...groupsPremiumIcons];
  return iconsArray.includes(value);
};

export const generateRandomIcon = (): string => {
  const index = Math.floor(Math.random() * arrayIcon.length);
  return arrayIcon[index];
};
