import styled, { css, keyframes } from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

type ProjectDetailsProps = {
  expanded: boolean;
};

const fadeInOut = keyframes`
  0% {
    display: flex;
  }
  100% {
    display: none;
  }
`;

const fadeIn = keyframes`
  0% {
    display: none;
  }
  100% {
    display: flex;
  }
`;

export const ProjectDetails = styled.div<ProjectDetailsProps>`
  display: flex;
  flex-direction: row;

  margin-left: ${({ expanded }) => (expanded ? '0' : '0.8rem')};

  margin-bottom: ${({ expanded }) => (expanded ? '1rem' : '1.5rem')};

  transition: margin-left 0.5s;

  cursor: pointer;

  ${({ expanded }) =>
    expanded &&
    css`
      width: 16.938rem !important;
    `}

  img {
    width: 3rem;
    height: 3rem;

    border-radius: 8px;
  }

  div:last-of-type {
    display: flex;
    flex-direction: column;

    margin-left: 1rem;

    max-height: ${({ expanded }) => (expanded ? '10rem' : '3rem')} !important;

    opacity: ${({ expanded }) => (expanded ? 1 : 0)} !important;

    animation: ${({ expanded }) => (expanded ? fadeIn : fadeInOut)} 0.5s
      ease-in-out forwards;

    overflow: hidden !important;

    transition: max-height 0.2s, opacity 0.5s;

    h4 {
      color: ${({ theme }) => theme.colors.gray6};
      font-size: 1rem;
      line-height: 150%;
      font-weight: 600;
      max-width: 11.25rem;

      word-break: break-all;
    }

    p {
      color: ${({ theme }) => theme.colors.gray4};
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1.375rem;

      margin-top: 0.25rem;
    }
  }
`;

interface ProjectMenuButton {
  projectIsChild: boolean;
  disabled?: boolean;
}

export const ProjectMenuButton = styled.button<ProjectMenuButton>`
  width: 1.25rem;
  height: 1.25rem;

  position: absolute;
  top: ${({ projectIsChild }) => (projectIsChild ? '2.25rem' : '0.25rem')};
  right: -0.75rem;

  svg {
    color: ${({ theme }) => theme.colors.gray4};
  }

  :disabled {
    cursor: not-allowed;
  }
`;

export const MenuOverlay = styled.div`
  width: 100vw;
  height: 100vh;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
`;

export const ModelUpdateReturnButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.625rem;
  margin-bottom: 1rem;

  svg {
    color: ${({ theme }) => theme.colors.gray4};
  }

  p {
    color: ${({ theme }) => theme.colors.gray4};
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
