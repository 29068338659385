import styled from 'styled-components';

export const ContentSelectFrequency = styled.div`
  margin-bottom: 1.5rem;
  span {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.313rem;
    color: ${({ theme }) => theme.colors.gray5};
  }

  > div {
    margin-top: 0.5rem;
    display: flex;
    gap: 1.5rem;
  }
`;

export const ChartHeader = styled.div`
  display: flex;
  justify-content: space-between;

  flex-wrap: wrap;
  margin-bottom: 1.5rem;
`;

export const ContentLatestData = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;

  > div {
    > div {
      text-align: right;

      p {
        margin-right: 0px;
      }
    }
  }

  input {
    display: block !important;
    margin-left: auto !important;
  }
`;
