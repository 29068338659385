import React from 'react';

import { Card } from 'src/components/Card';
import { useQuery } from 'react-query';
import api from 'src/models/service/api';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { AxiosError } from 'axios';
import { translateSomeMessagesFromBackend } from 'src/i18n';
import { useTranslation } from 'react-i18next';
import { formatCompactNotation } from 'src/utils/numbers/formatCompactNotation';

import {
  AnnualSummaryContainer,
  ContentTable,
  Legend,
  TdLabel,
  TdValue,
  Th,
} from './styles';

interface Error {
  detail?: {
    description?: string;
    detail?: string;
  };
}

interface ResponseSummaryLevel {
  historical: {
    y_all: number[];
    year: string[];
  };
  forecast?: {
    y_all: number[];
    year: string[];
  };
}

interface ResponseSummaryVariation {
  historical: {
    percent: number[];
    year: string[];
  };
  forecast?: {
    percent: number[];
    year: string[];
  };
}

export const AnnualSummary: React.FC = () => {
  const {
    project: { id, selectedY },
    auth: { user },
  } = useSelector((state: RootState) => state);

  const { t: translate } = useTranslation();

  const {
    data: dataLevel,
    isLoading: isLoadingLevel,
    isFetching: isFetchingLevel,
    isError: isErrorLevel,
    error: errorLevel,
  } = useQuery<ResponseSummaryLevel, AxiosError<Error>>(
    ['model production', 'annual-summary', id, selectedY?.id, 'level'],
    async () => {
      const response = await api.get<ResponseSummaryLevel>(
        `/projects/${id}/${selectedY?.id}/model-in-production/annual_series`,
      );

      return response.data;
    },
    {
      enabled: !!id && !!selectedY?.id,
      staleTime: 1000 * 60 * 20,
    },
  );

  const {
    data: dataVariation,
    isLoading: isLoadingVariation,
    isFetching: isFetchingVariation,
    isError: isErrorVariation,
  } = useQuery<ResponseSummaryVariation, AxiosError<Error>>(
    ['model production', 'annual-summary', id, selectedY?.id, 'variation'],
    async () => {
      const response = await api.get<ResponseSummaryVariation>(
        `/projects/${id}/${selectedY?.id}/model-in-production/annual_growth_rate`,
      );

      return response.data;
    },
    {
      enabled: !!id && !!selectedY?.id,
      staleTime: 1000 * 60 * 20,
    },
  );

  function returnMessageError() {
    let messageError = errorLevel?.response?.data?.detail?.detail;

    if (
      messageError ===
      'Annual series summary is only available for series with at least 1 year of observation.'
    ) {
      return messageError;
    }

    messageError = errorLevel?.response?.data?.detail?.description;

    if (
      messageError === 'No data is available.' ||
      messageError === 'The requested resource does not exist.'
    ) {
      return 'Annual Series Rate is not available for this model';
    }

    return messageError;
  }

  return (
    <AnnualSummaryContainer
      className="containerLinear"
      data-testid="annual-summary-container"
    >
      <Card textCard={translate('modelInProductionAnnualSummaryTitle')} />
      {isErrorLevel ? (
        <ContainerMaintenance
          content="chart"
          size="sm"
          text={translateSomeMessagesFromBackend(
            returnMessageError() ?? '',
            user.language,
          )}
          data-testid="annual-summary-container-error"
        />
      ) : isLoadingLevel || isFetchingLevel || !dataLevel ? (
        // eslint-disable-next-line prettier/prettier
        <ContainerSkeleton
          style={{ height: '180px' }}
          data-testid="annual-summary-container-loading"
        />
      ) : (
        <>
          <ContentTable>
            <table>
              <thead>
                <tr>
                  <Th isProjection={false}>{}</Th>
                  {dataLevel.historical.year.map((year) => (
                    <Th isProjection={false} key={`annual-summary-th-${year}`}>
                      {year}
                    </Th>
                  ))}
                  {dataLevel.forecast?.year.map((year) => (
                    <Th isProjection key={`annual-summary-th-${year}`}>
                      {year}
                    </Th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <TdLabel>
                    {translate('modelInProductionAnnualSummaryLevel')}
                  </TdLabel>
                  {dataLevel.historical.y_all.map((value, index) => (
                    <TdValue
                      isProjection={false}
                      key={`annual-summary-level-td-${value}-${index.toString()}`}
                    >
                      <div>
                        <span>{formatCompactNotation(value)}</span>
                      </div>
                    </TdValue>
                  ))}
                  {dataLevel.forecast?.y_all.map((value, index) => (
                    <TdValue
                      isProjection
                      key={`annual-summary-level-td-${value}-${index.toString()}`}
                    >
                      <div>
                        <span>{formatCompactNotation(value)}</span>
                      </div>
                    </TdValue>
                  ))}
                </tr>
                {isErrorVariation ? (
                  <tr>
                    <TdLabel>
                      {translate('modelInProductionAnnualSummaryVariation')}
                    </TdLabel>
                    {Array.from(
                      { length: dataLevel.historical.y_all.length },
                      (_, index) => (
                        <TdValue
                          isProjection={false}
                          key={`annual-summary-variation-historical-td-error-${index}`}
                        >
                          <div>
                            <span>--</span>
                          </div>
                        </TdValue>
                      ),
                    )}

                    {Array.from(
                      { length: dataLevel.forecast?.y_all.length ?? 0 },
                      (_, index) => (
                        <TdValue
                          isProjection={false}
                          key={`annual-summary-variation-forecast-td-error-${index}`}
                        >
                          <div>
                            <span>--</span>
                          </div>
                        </TdValue>
                      ),
                    )}
                  </tr>
                ) : isLoadingVariation ||
                  isFetchingVariation ||
                  !dataVariation ? (
                  // eslint-disable-next-line react/jsx-indent
                  <tr>
                    {Array.from(
                      {
                        length:
                          dataLevel.historical.y_all.length +
                          (dataLevel.forecast?.y_all.length ?? 0) +
                          1,
                      },
                      (_, index) => (
                        <TdValue
                          isProjection={false}
                          key={`annual-summary-variation-td-loading-${index}`}
                        >
                          <ContainerSkeleton
                            withLoading={false}
                            style={{ width: '65px', height: '28px' }}
                          />
                        </TdValue>
                      ),
                    )}
                  </tr>
                ) : (
                  <tr>
                    <TdLabel>
                      {translate('modelInProductionAnnualSummaryVariation')}
                    </TdLabel>
                    {dataVariation.historical.year.length !==
                      dataLevel.historical.year.length && (
                      <TdValue isProjection={false}>
                        <div>
                          <span>--</span>
                        </div>
                      </TdValue>
                    )}
                    {dataVariation.historical.percent.map((value, index) => (
                      <TdValue
                        isProjection={false}
                        key={`annual-summary-variation-td-${value}-${index.toString()}`}
                      >
                        <div>
                          <span>{formatCompactNotation(value)}%</span>
                        </div>
                      </TdValue>
                    ))}
                    {(dataVariation.forecast?.year.length ?? 0) !==
                      (dataLevel.forecast?.year.length ?? 0) && (
                      <TdValue isProjection>
                        <div>
                          <span>--</span>
                        </div>
                      </TdValue>
                    )}
                    {dataVariation.forecast?.percent.map((value, index) => (
                      <TdValue
                        isProjection
                        key={`annual-summary-variation-td-${value}-${index.toString()}`}
                      >
                        <div>
                          <span>{formatCompactNotation(value)}%</span>
                        </div>
                      </TdValue>
                    ))}
                  </tr>
                )}
              </tbody>
            </table>
          </ContentTable>
          <Legend>
            <div>
              <div />
              <span>
                {translate('modelInProductionAnnualSummaryHistorical')}
              </span>
            </div>

            <div>
              <div />
              <span>{translate('modelInProductionAnnualSummaryForecast')}</span>
            </div>
          </Legend>
        </>
      )}
    </AnnualSummaryContainer>
  );
};
