import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LockSimple } from 'phosphor-react';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { getIndicatorUrlIcon } from 'src/feature-store/utils/getIndicatorUrlIcon';
import { RootState } from 'src/redux/store';
import CrownSimple from 'src/assets/crownSimple.svg';
import StarFour from 'src/assets/StarFour.svg';
import { ModalInfoGroupPremium } from 'src/feature-store/pages/Indicators/ModalInfoGroupPremium';

import {
  AccessTypeIconsContainer,
  BasicIcon,
  Container,
  Content,
  Dot,
  IconContainer,
  IconsAccess,
  IndicatorContent,
  InfoContainer,
  LockIconBasic,
  LockIconPremium,
  PremiumIcon,
  Tag,
  Tags,
} from './styles';
import { IndicatorCardProps } from './types';

export const IndicatorCard: React.FC<IndicatorCardProps> = ({
  indicator,
  loading = false,
  openIndicatorInNewTab = false,
  isPremium,
  ...props
}) => {
  const [modalInfoGroupPremium, setModalInfoGroupPremium] =
    useState<boolean>(false);
  const [indicatorPremiumInfo, setIndicatorPremiumInfo] = useState('');

  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const { language, isFreemium } = useSelector(
    (state: RootState) => state.auth.user,
  );

  if (loading) {
    return (
      <Container
        data-testid="card-indicator-loading"
        {...props}
        style={{ cursor: 'default' }}
      >
        <IndicatorContent>
          <ContainerSkeleton
            withLoading={false}
            style={{
              width: '3.563rem',
              height: '3.563rem',
            }}
          />

          <Content style={{ flex: 1 }}>
            <ContainerSkeleton
              withLoading={false}
              style={{
                width: '100%',
                height: '1.875rem',
              }}
            />

            <ContainerSkeleton
              withLoading={false}
              style={{
                width: '65%',
                height: '1.875rem',
              }}
            />

            <ContainerSkeleton
              withLoading={false}
              style={{
                width: '80%',
                height: '1.875rem',
              }}
            />
          </Content>
        </IndicatorContent>
      </Container>
    );
  }

  return (
    <Container
      data-testid={`card-indicator-${indicator?.name['en-us']
        .replaceAll(' ', '-')
        .toLocaleLowerCase()}`}
      {...props}
    >
      <IndicatorContent
        onClick={() => {
          if (openIndicatorInNewTab) {
            window.open(
              `/feature-store/indicators/${indicator?.indicator_code}`,
              '_blank',
            );
          } else {
            navigate(`/feature-store/indicators/${indicator?.indicator_code}`, {
              state: {
                isPremium: !!isPremium,
              },
            });
          }
        }}
      >
        <IconContainer
          typeIndicator={indicator?.tree[1]?.name?.['en-us']
            .trim()
            ?.toLowerCase()}
          data-testid={`icon-container-indicator-${indicator?.name['en-us']
            .replaceAll(' ', '-')
            .toLocaleLowerCase()}`}
        >
          <img
            src={getIndicatorUrlIcon(
              indicator?.tree && indicator.tree.length > 1
                ? indicator?.tree[1]?.name?.['en-us']
                : indicator?.tree[0]?.name?.['en-us'],
            )}
            alt=""
          />
        </IconContainer>

        <Content
          data-testid={`indicator-card-content-${indicator?.name['en-us']
            .replaceAll(' ', '-')
            .toLocaleLowerCase()}`}
        >
          <span>{indicator?.name[language] ?? indicator?.name['en-us']}</span>

          <Tags
            data-testid={`tags-indicator-${indicator?.name['en-us']
              .replaceAll(' ', '-')
              .toLocaleLowerCase()}`}
          >
            {indicator?.tree.map((node, index) => (
              <Tag
                key={node?.id}
                typeTag={indicator?.tree[1]?.name?.['en-us']
                  .trim()
                  ?.toLowerCase()}
                data-testid={`tag-indicator-${indicator?.name['en-us']
                  .replaceAll(' ', '-')
                  .toLocaleLowerCase()}`}
              >
                {node?.name?.['en-us']?.trim()?.length > 0 && (
                  <>
                    {index > 0 && <Dot>•</Dot>}
                    {node?.name?.[language] ?? node?.name?.['en-us']}
                  </>
                )}
              </Tag>
            ))}
          </Tags>

          <InfoContainer
            data-testid={`info-indicator-${indicator?.name['en-us']
              .replaceAll(' ', '-')
              .toLocaleLowerCase()}`}
          >
            <div>
              <label
                data-testid={`source-label-indicator-${indicator?.name['en-us']
                  .replaceAll(' ', '-')
                  .toLocaleLowerCase()}`}
              >
                {translate('indicatorsSource')}:{' '}
              </label>
              <span
                data-testid={`source-indicator-${indicator?.name['en-us']
                  .replaceAll(' ', '-')
                  .toLocaleLowerCase()}`}
              >
                {indicator?.source[language] ?? indicator?.source['en-us']}
              </span>

              {indicator?.projections?.trim().length !== 0 && (
                <>
                  <label
                    data-testid={`projection-label-indicator-${indicator?.name[
                      'en-us'
                    ]
                      .replaceAll(' ', '-')
                      .toLocaleLowerCase()}`}
                  >
                    {translate('indicatorsProjections')}:{' '}
                  </label>
                  <span
                    data-testid={`projection-indicator-${indicator?.name[
                      'en-us'
                    ]
                      .replaceAll(' ', '-')
                      .toLocaleLowerCase()}`}
                  >
                    {indicator?.projections}
                  </span>
                </>
              )}
            </div>
          </InfoContainer>
        </Content>
      </IndicatorContent>

      <AccessTypeIconsContainer
        data-testid={`access-type-icons-indicator-${indicator?.name['en-us']
          .replaceAll(' ', '-')
          .toLocaleLowerCase()}`}
      >
        <IconsAccess>
          {indicator?.is_accessible === false &&
            indicator?.access_type === 'premium' && (
              <LockIconPremium
                data-testid={`icon-locked-indicator-${indicator?.name['en-us']
                  .replaceAll(' ', '-')
                  .toLocaleLowerCase()}`}
              >
                <LockSimple size="1.25rem" />
              </LockIconPremium>
            )}

          {indicator?.access_type === 'default' && isFreemium && (
            <LockIconBasic
              data-testid={`icon-basic-locked-indicator-${indicator?.name[
                'en-us'
              ]
                .replaceAll(' ', '-')
                .toLocaleLowerCase()}`}
            >
              <LockSimple size="1.25rem" />
            </LockIconBasic>
          )}
          {indicator?.access_type === 'premium' && (
            <PremiumIcon
              onClick={() => {
                if (indicator.access_groups) {
                  setModalInfoGroupPremium(true);
                  setIndicatorPremiumInfo(
                    indicator.access_groups[0]?.access_group_id,
                  );
                }
              }}
              data-testid={`premium-indicator-${indicator?.name['en-us']
                .replaceAll(' ', '-')
                .toLocaleLowerCase()}`}
            >
              {indicator.access_groups !== null &&
              indicator.access_groups?.length > 0 ? (
                <>
                  <img src={CrownSimple} alt="" />

                  {indicator.access_groups.length > 1 ? (
                    <span
                      data-testid={`access-group-name-indicator-${indicator?.name[
                        'en-us'
                      ]
                        .replaceAll(' ', '-')
                        .toLocaleLowerCase()}`}
                    >
                      {`${
                        indicator?.access_groups[0]?.name[language] ??
                        indicator?.access_groups[0]?.name['en-us']
                      } +
                              ${indicator?.access_groups.length - 1}`}
                    </span>
                  ) : (
                    <span
                      data-testid={`access-group-name-indicator-${indicator?.name[
                        'en-us'
                      ]
                        .replaceAll(' ', '-')
                        .toLocaleLowerCase()}`}
                    >
                      {indicator?.access_groups[0]?.name[language] ??
                        indicator?.access_groups[0]?.name['en-us']}
                    </span>
                  )}
                </>
              ) : (
                <img src={CrownSimple} alt="" />
              )}
            </PremiumIcon>
          )}

          {indicator?.access_type === 'default' && isFreemium && (
            <BasicIcon
              data-testid={`premium-indicator-${indicator?.name['en-us']
                .replaceAll(' ', '-')
                .toLocaleLowerCase()}`}
            >
              <>
                <img src={StarFour} alt="" />
                <span>Business</span>
              </>
            </BasicIcon>
          )}
        </IconsAccess>
      </AccessTypeIconsContainer>

      {modalInfoGroupPremium && (
        <ModalInfoGroupPremium
          idGroup={indicatorPremiumInfo}
          visible={modalInfoGroupPremium}
          setVisible={setModalInfoGroupPremium}
        />
      )}
    </Container>
  );
};
