const commonModelExplorerENUSTranslations = {
  selectModelTitle: 'Model List',
  selectModelLabel: 'N. on the list',
  selectModelPlaceHolder: 'Choose a number',
  sendModelToProdTitle: 'User Selection',
  sendModelToProdDescr:
    'Boost your decision-making based on the outputs of your favorite model. Access forecasts, decompose historical results, simulate alternative scenarios, and more.',
  sendToProductionButtonText: 'Send to user selection',
  nOfCombinedModels: 'No. of combined models',
  nOfTrees: 'No. of Trees',
  nOfVarsRandomSampled: 'No. of variables randomly sampled',
  sendToProdFailMessage: 'An error occurred when sending model to production',
  modelExplorerText: 'Model Explorer',
  regularizedRegressionText: 'Regularized Regression',
  forecastCombinationText: 'Forecast Combination',
  elementaryText: 'Elementary',
};

export default commonModelExplorerENUSTranslations;
