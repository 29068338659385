import React, { useState } from 'react';

import { Trash, Warning, XCircle } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';
import { ModalLoading } from 'src/components/Modal/Loading';
import api from 'src/feature-store/service/api';
import { queryClient } from 'src/service/queryClient';
import { ModalFooter } from 'src/components/Modal/Footer/styles';

import { Container, ContainerError, FooterModalDelete } from './styles';
import { GroupProps } from '../..';

interface IModalDeleteGroup {
  setVisible: (value: GroupProps) => void;
  group: GroupProps;
}

export const ModalDeleteGroup: React.FC<IModalDeleteGroup> = ({
  setVisible,
  group,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const { t: translate } = useTranslation();

  const handleDeleteUsersFromGroup = async () => {
    try {
      setLoading(true);
      const response = await api.delete(`/access-groups/${group.id}`);
      await new Promise<void>((resolve) =>
        setTimeout(() => {
          resolve();
        }, 1000),
      );
      if (response.status === 202) {
        setLoading(false);
        queryClient.removeQueries('groupsPremium');
        setVisible({
          delete: false,
          id: '',
        });
      }
    } catch (err) {
      setLoading(false);
      setError(true);
    }
  };

  if (loading) {
    return (
      <ModalLoading
        visible
        style={{ padding: 0, width: '280px' }}
        data-testid="container-loading"
      />
    );
  }

  if (error) {
    return (
      <Modal
        visible
        setVisible={() =>
          setVisible({
            delete: false,
            id: '',
          })
        }
        style={{ padding: 0 }}
      >
        <ContainerError data-testid="container-error">
          <XCircle size="3rem" />
          <h4>{translate('deleteGroupFailedTitle')}</h4>
          <p>{translate('deleteGroupFailedDescription')}</p>
        </ContainerError>
        <FooterModalDelete>
          <Button
            buttonType="primary"
            onClick={() =>
              setVisible({
                delete: false,
                id: '',
              })
            }
            data-testid="button-back"
            data-cy="button-back"
          >
            {translate('back')}
          </Button>
        </FooterModalDelete>
      </Modal>
    );
  }

  return (
    <Modal
      visible
      setVisible={() =>
        setVisible({
          delete: false,
          id: '',
        })
      }
    >
      <Container data-testid="modal-delete-group">
        <Warning size="2.5rem" />
        <h2>{translate('deleteGroupTitle')}</h2>
        <p>{translate('deleteGroupDescription')}</p>
      </Container>
      <ModalFooter>
        <Button
          buttonType="naked"
          onClick={() =>
            setVisible({
              delete: false,
              id: '',
            })
          }
          data-testid="button-cancel"
          data-cy="button-cancel"
        >
          {translate('cancel')}
        </Button>
        <Button
          buttonType="primary"
          icon={<Trash size="1.125rem" />}
          onClick={() => handleDeleteUsersFromGroup()}
          data-testid="button-delete-modal-delete-group"
          data-cy="button-delete-modal-delete-group"
        >
          {translate('delete')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
