import React from 'react';

import { Warning } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';
import { ModalFooter } from 'src/components/Modal/Footer/styles';

import { ContainerModal } from './styles';

type PropsModal = {
  visible?: boolean;
  setVisible: (value: boolean) => void;
  NWindows: number;
  onClickValidadeSetting: () => void;
};

export const ModalWarningNWindows: React.FC<PropsModal> = ({
  visible,
  setVisible,
  NWindows,
  onClickValidadeSetting,
}) => {
  const { t: translate } = useTranslation();

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <ContainerModal hasImage={false}>
        <Warning size="3rem" data-testid="x-circle-icon" />
        <p>{translate('modalWarningNWindowsText1')}</p>
        <span>
          {translate('modalWarningNWindowsText2').replace(
            'XXX',
            NWindows.toString(),
          )}
        </span>
      </ContainerModal>

      <ModalFooter>
        <Button
          buttonType="naked"
          data-testid="button-modal-warning-n-windows-validade-setting"
          data-cy="button-modal-warning-n-windows-validade-setting"
          onClick={onClickValidadeSetting}
        >
          {translate('modalWarningNWindowsCheckChange')}
        </Button>
        <Button
          buttonType="primary"
          onClick={() => setVisible(false)}
          data-cy="button-modal-warning-n-windows-ok"
          data-testid="button-modal-warning-n-windows-ok"
        >
          Ok
        </Button>
      </ModalFooter>
    </Modal>
  );
};
